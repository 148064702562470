import Api from '@/api/ObBaseApi';

export default {

  getNews() {
    return Api()
    // .get("/pps-web/getQuickview2?limit=200")
        .get('/fsp/feeds/getFeeds2?limit=200')
        .then((response) => {
          return response.data;
        });
  },

};