<template>
  <div class="playerSection" v-if="playerListInfo != null">
    <!-- My Players -->
    <div class="playerListBox">
      <div class="boxHeaderSection">
        <div class="boxTitle">Your Selected Players</div>
        <div class="boxSubTitle">{{ playerBoxSubText }}</div>

        <!-- My Players Filters -->
        <div class="filterBox">
          <div class="checkboxContainer">
            <SquareCheckbox :checked="showStartedFilter" @click="toggleStartedFilter" />
            <div class="checkboxText">Show Started</div>
          </div>
          <select class="filterItem" v-model="toDropSort">
            <option value="">Sort By</option>
            <option v-for="(option, index) in sortOptions" :value="option.value" :key="index">
              {{ option.name }}
            </option>
          </select>
          <div class="filterItem inputContainer">
            <i class="fas fa-search"></i>
            <input type="text" v-model="toDropSearchFilter"
              placeholder="Search..."
            >
          </div>
        </div>
      </div>

      <!-- My Players Table -->
      <div ref="myPlayersList" class="playerListContainer ob-scroll">
        <table aria-label="My Players" :class="{hasPlayerSelected: toDrop != null}">
          <tr>
            <th class="playerHeader">Player</th>
            <th class="statsHeader">Proj</th>
            <th class="statsHeader">Salary</th>
            <th class="addDropHeader"></th>
          </tr>
          <template v-for="player in sortedMyPlayers">
            <tr v-if="canShowPlayer(player, filteredMyPlayersMap)"
              :class="{ selectedPlayer: isSelectedDrop(player), lockedPlayer: !canDrop(player) }"
              :key="player.lineupPosition + player.id"
              @click="selectToDrop(player)"
            >
              <td>
                <div class="playerInfoContainer">
                  <div class="position">{{ player.lineupPosition }}</div>
                  <PlayerHeadshot size="40"
                    class="playerHeadshot" :id="player.id"
                    :sport="sport" :team="player.teamAlias"
                    :showLogo="true"
                  />
                  <div class="nameContainer">
                    <div class="playerName">
                      <PlayerStatus height="12" :status="player.status" />
                      <div @click.stop="openPlayerCard(player.id)" class="nameText">{{ player.name.longName() }}</div>
                    </div>
                    <div class="gameInfo">
                      {{ playerNextGameText(player) }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="statSection ptsSection" :class="playerProjClass(player)">{{ playerNextGameProj(player) }}</td>
              <td class="statSection salarySection">{{ player.salary.formatMoney() }}</td>
              <td style="text-align: center;">
                <i v-if="isToDrop(player)" class="selectIcon redText fal fa-times-circle"></i>
                <i v-else-if="canDrop(player)" class="selectIcon redText fal fa-minus-circle"></i>
                <i v-else class="selectIcon lockIcon greyText far fa-lock-alt"></i>
              </td>
            </tr>
          </template>
          <tr v-if="filteredMyPlayers.length == 0">
            <td class="noPlayersRow" colspan="99">No players found</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- All Players -->
    <div class="playerListBox">
      <div class="boxHeaderSection">
        <div class="boxTitle">Available Players</div>
        <div class="boxSubTitle">{{ playerBoxSubText }}</div>

        <!-- All Players Filters -->
        <div class="filterBox">
          <select class="filterItem" v-model="toAddPositionFilter">
            <option value="">All Pos.</option>
            <option v-for="(option, index) in positionOptions" :key="index">{{ option }}</option>
          </select>
          <select class="filterItem" v-model="toAddSort">
            <option value="">Sort By</option>
            <option v-for="(option, index) in sortOptions" :value="option.value" :key="index">
              {{ option.name }}
            </option>
          </select>
          <div class="filterItem inputContainer">
            <i class="fas fa-search"></i>
            <input type="text" v-model="toAddSearchFilter"
              placeholder="Search..."
            >
          </div>
        </div>
      </div>

      <!-- All Players Table -->
      <div ref="allPlayersList" class="playerListContainer ob-scroll">
        <table aria-label="All Players" :class="{hasPlayerSelected: toAdd != null}">
          <tr>
            <th class="playerHeader">Player</th>
            <th class="statsHeader">Proj</th>
            <th class="statsHeader">Salary</th>
            <th class="addDropHeader"></th>
          </tr>
          <template v-for="player in sortedAllPlayers">
            <tr v-show="canShowPlayer(player, filteredAllPlayersMap)" :class="{ selectedPlayer: isSelectedAdd(player), lockedPlayer: !canAdd(player) }"
              @click="selectToAdd(player)" :key="player.draftPos + player.id"
            >
              <td>
                <div class="playerInfoContainer">
                  <div class="position">{{ player.draftPos }}</div>
                  <PlayerHeadshot size="40"
                    class="playerHeadshot" :id="player.id"
                    :sport="sport" :team="player.teamAlias"
                    :showLogo="true"
                  />
                  <div class="nameContainer">
                    <div class="playerName">
                      <PlayerStatus height="12" :status="player.status" />
                      <div @click.stop="openPlayerCard(player.id)" class="nameText">{{ player.name.longName() }}</div>
                    </div>
                    <div class="gameInfo">
                      {{ playerNextGameText(player) }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="statSection ptsSection" :class="playerProjClass(player)">{{ playerNextGameProj(player) }}</td>
              <td class="statSection salarySection"
                :class="{cantAfford: !canAffordToAdd(player)}"
              >
                {{ player.salary.formatMoney() }}
              </td>
              <td style="text-align: center;">
                <i v-if="isToAdd(player)" class="selectIcon redText fal fa-times-circle"></i>
                <i v-else-if="canAdd(player)" class="selectIcon greenText fal fa-plus-circle"></i>
                <i v-else class="selectIcon lockIcon greyText far fa-lock-alt"></i>
              </td>
            </tr>
          </template>
          <tr v-if="filteredAllPlayers.length == 0">
            <td class="noPlayersRow" colspan="99">No players found</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div v-else class="playerSection">
    <ObLoading :size="70" />
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import ObLoading from '@/components/ObLoading';
import PlayerStatus from '@/components/PlayerStatus';
import SquareCheckbox from '@/components/buttons/SquareCheckbox';
import EventBus from '@/event-bus';

export default {
  props: {
    selectedSlate: {type: Object, default: null},
    playerListInfo: {type: Object, default: null},
    swapPlayers: {type: Object, default: null},
  },

  components: {
    PlayerHeadshot,
    ObLoading,
    PlayerStatus,
    SquareCheckbox,
  },

  data() {
    return {
      sortOptions: [
        {value: 'proj', name: 'Projection'},
        {value: 'salaryH', name: 'Salary (High)'},
        {value: 'salaryL', name: 'Salary (Low)'},
        {value: 'statusH', name: 'Status (Active)'},
        {value: 'statusL', name: 'Status (Inactive)'},
        {value: 'name', name: 'Name'},
      ],

      toAddPositionFilter: '',
      toAddSort: '',
      toAddSearchFilter: '',

      showStartedFilter: true,
      toDropSort: '',
      toDropSearchFilter: '',
    };
  },

  computed: {
    sport() {
      return this.selectedSlate.sport;
    },

    playerBoxSubText() {
      if (!this.selectedSlate) {
        return '';
      }

      return this.selectedSlate.name;
    },

    allPlayers() {
      return this.playerListInfo?.allPlayers || [];
    },

    sortedAllPlayers() {
      return this.allPlayers.slice().sort((p1, p2) => {
        let sortType = this.toAddSort;
        if (sortType == '') {
          sortType = 'salaryH';
        }
        const compareVal = this.comparePlayers(p1, p2, sortType, true);
        if (compareVal == 0) {
          return this.compareName(p1.name, p2.name);
        }

        return compareVal;
      });
    },

    filteredAllPlayers() {
      return this.sortedAllPlayers.slice().filter((p) => {
        // Filter name search
        if (this.toAddSearchFilter !== '' && this.toAddSearchFilter != null &&
          !this.isNameInFilter(p.name, this.toAddSearchFilter)
        ) {
          return false;
        }

        // Don't allow player that is being dropped to be added
        if (this.toDrop && p.id == this.toDrop.id) {
          return false;
        }

        // Filter position
        return this.toAddPositionFilter == '' || this.toAddPositionFilter == null ||
          this.playerInPosFilter(p);
      });
    },

    filteredAllPlayersMap() {
      const map = {};
      for (const player of this.filteredAllPlayers) {
        this.$set(map, player.id, true);
      }

      return map;
    },

    myPlayers() {
      return this.playerListInfo?.myPlayers || [];
    },

    sortedMyPlayers() {
      return this.myPlayers.slice().sort((p1, p2) => {
        let sortType = this.toDropSort;
        if (sortType == '') {
          sortType = 'salaryH';
        }

        const compareVal = this.comparePlayers(p1, p2, sortType);
        if (compareVal == 0) {
          return this.compareName(p1.name, p2.name);
        }

        return compareVal;
      });
    },

    filteredMyPlayers() {
      return this.sortedMyPlayers.slice().filter((p) => {
        // Filter name search
        if (this.toDropSearchFilter !== '' && this.toDropSearchFilter != null &&
          !this.isNameInFilter(p.name, this.toDropSearchFilter)
        ) {
          return false;
        }

        return this.showStartedFilter || this.canDrop(p);
      });
    },

    filteredMyPlayersMap() {
      const map = {};
      for (const player of this.filteredMyPlayers) {
        this.$set(map, player.id, true);
      }

      return map;
    },

    toDrop() {
      return this.swapPlayers?.drop;
    },

    toAdd() {
      return this.swapPlayers?.add;
    },

    positionOptions() {
      const positionObject = {};
      const posList = [];
      for (const pos of this.selectedSlate.draftPositions) {
        if (!positionObject[pos]) {
          this.$set(positionObject, pos, true);
          posList.push(pos);
        }
      }

      return posList;
    },
  },

  methods: {
    toggleStartedFilter() {
      this.showStartedFilter = !this.showStartedFilter;
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    canShowPlayer(player, playerMap) {
      return this.playerNextGame(player) != null && playerMap[player.id];
    },

    isSelectedAdd(player) {
      if (!this.toAdd) {
        return false;
      }

      return this.toAdd.id == player.id;
    },

    isSelectedDrop(player) {
      if (!this.toDrop) {
        return false;
      }

      return this.toDrop.id == player.id && this.toDrop.lineupPosition == player.lineupPosition;
    },

    playerNextGame(player) {
      if (!player?.gamesByWeek || !player.gamesByWeek.length) {
        return null;
      }

      let nextGame = null;
      for (const game of player.games) {
        if (game.game && game.ts) {
          nextGame = game;
          break;
        }
      }

      return nextGame;
    },

    playerNextGameText(player) {
      const nextGame = this.playerNextGame(player);
      if (!nextGame) {
        return 'No Game';
      }
      let gameText = '';
      let gameName = nextGame.game;

      if (!nextGame) {
        return null;
      }

      if (nextGame.game.charAt(0) != '@') {
        gameText = 'vs ';
      } else {
        gameText = '@ ';
        gameName = gameName.slice(1);
      }

      gameName = this.$TeamInfo.getDisplayAlias(this.sport, gameName);
      gameText = gameText + gameName + ' ' + this.$moment(nextGame.ts).format('ddd h:mma');
      return gameText;
    },

    playerNextGameProj(player) {
      const nextGame = this.playerNextGame(player);
      if (!nextGame) {
        return 0;
      }

      return nextGame.proj;
    },

    playerProjClass(player) {
      const proj = this.playerNextGameProj(player);
      if (proj > 0) {
        return 'blueText';
      } else if (proj < 0) {
        return 'redText';
      }
    },

    canDrop(player) {
      const firstGame = this.playerNextGame(player);
      if (!firstGame) {
        return true;
      }
      const currentTs = this.$moment().valueOf();

      return firstGame.ts > currentTs;
    },

    isToDrop(player) {
      return this.toDrop != null && this.toDrop.id == player.id;
    },

    canAdd(player) {
      if (!this.toDrop || !this.canSelect(this.toDrop, player)) {
        return false;
      }

      const firstGame = this.playerNextGame(player);
      if (!firstGame) {
        return true;
      }
      const currentTs = this.$moment().valueOf();

      return firstGame.ts > currentTs;
    },

    canSelect(toDrop, toAdd) {
      if (!toAdd) {
        return true;
      }
      return this.canAfford(toAdd, toDrop) &&
        (!this.toDrop || this.playerInPosFilter(toAdd, toDrop?.lineupPosition));
    },

    canAffordToAdd(player) {
      return this.canAfford(player, this.toDrop);
    },

    canAfford(player, playerWithMax) {
      if (!playerWithMax || !player) {
        return true;
      }

      return player.salary <= playerWithMax.salary;
    },

    isToAdd(player) {
      return this.toAdd != null && this.toAdd.id == player.id;
    },

    selectToDrop(player) {
      if (this.isToDrop(player)) {
        this.toAddPositionFilter = '';
        this.$emit('selectSwap', null, null);
      } else if (this.canDrop(player)) {
        this.toAddPositionFilter = player.lineupPosition;


        this.$emit('selectSwap', player, null);
      }
      this.scrollAllListToTop();
    },

    selectToAdd(player) {
      const toDrop = this.toDrop;
      if (this.isToAdd(player)) {
        if (!this.toDrop) {
          this.toAddPositionFilter = '';
        }
        this.$emit('selectSwap', toDrop, null);
        return;
      }
      if (this.canAdd(player)) {
        this.$emit('selectSwap', toDrop, player);
      }
    },

    formatMoney(value) {
      if (!value) {
        return '$0';
      }
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value.toFixed(2)).removeCentsIfZero();
    },

    comparePlayers(p1, p2, selectedSort, filterCanAfford) {
      if (selectedSort == '') {
        return 0;
      }

      if (filterCanAfford && this.toDrop) {
        const canAffordP1 = this.canAffordToAdd(p1);
        const canAffordP2 = this.canAffordToAdd(p2);

        if (!canAffordP2 && canAffordP1) {
          return -1;
        } else if (!canAffordP1 && canAffordP2) {
          return 1;
        }
      }

      switch (selectedSort) {
        case 'salaryH':
          return p2.salary - p1.salary;
        case 'salaryL':
          return p1.salary - p2.salary;
        case 'statusH':
          return this.compareStatus(p1.status, p2.status);
        case 'statusL':
          return this.compareStatus(p2.status, p1.status);
        case 'name':
          return this.compareName(p1.name, p2.name);
        case 'proj':
          return this.playerNextGameProj(p2) - this.playerNextGameProj(p1);
        default:
          return 0;
      }
    },

    compareName(p1Name, p2Name) {
      if (!p1Name && p2Name) {
        return 1;
      }
      if (!p2Name && p1Name) {
        return -1;
      }
      if (!p1Name && !p2Name) {
        return 0;
      }
      return p1Name.longName().localeCompare(p2Name.longName());
    },

    getStatusColor(status) {
      switch (status) {
        case 'ACT': return 'GREEN';
        case 'AVA':
        case 'TBA':
        case 'BKUP': return 'YELLOW';
        case 'OUT':
        case 'DNP':
        case 'SUSP':
        case 'INJ':
        case 'MIN': return 'RED';
      }
      return 'RED';
    },

    compareStatus(s1, s2) {
      const s1String = this.getStatusColor(s1);
      const s2String = this.getStatusColor(s2);
      const statusValues = {
        GREEN: 2,
        YELLOW: 1,
        RED: 0,
      };

      return statusValues[s2String] - statusValues[s1String];
    },

    isNameInFilter(name, searchFilter) {
      const pname = name.toLowerCase();
      const nfilter = searchFilter.toLowerCase();
      const nFilters = nfilter.split(' ');

      for (const index in nFilters) {
        const n = nFilters[index];
        if (pname.indexOf(n) != 0 && pname.indexOf(', ' + n) == -1) {
          return false;
        }
      }

      return true;
    },

    playerInPosFilter(p, posFilter) {
      const draftPos = p.draftPos;
      const isGuard = this.$SportInfo.draftPosIsGuard(draftPos, this.sport);
      const isForward = this.$SportInfo.draftPosIsForward(draftPos, this.sport);
      const isUtility = this.$SportInfo.draftPosIsUtility(draftPos, this.sport);

      const isFlex = this.$SportInfo.draftPosIsFlex(draftPos, this.sport);
      const isSuperFlex = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.sport);

      if (!posFilter) {
        posFilter = this.toAddPositionFilter;
      }

      if (isGuard && posFilter == this.$SportInfo.guard()) {
        return true;
      }
      if (isForward && posFilter == this.$SportInfo.forward()) {
        return true;
      }
      if (isUtility && (posFilter == this.$SportInfo.utility() || posFilter == this.$SportInfo.utilityMultiplier())) {
        return true;
      }

      if (isFlex && posFilter == 'F') {
        return true;
      }
      if (isSuperFlex && posFilter == 'SF') {
        return true;
      }
      if (isSuperFlex && posFilter == this.$SportInfo.superFlexMultiplier()) {
        return true;
      }
      return p.draftPos == posFilter;
    },

    scrollAllListToTop() {
      const ref = this.$refs.allPlayersList;
      ref.scrollTo({top: 0});
    },
  },
};
</script>

<style lang="scss" scoped>
.playerSection {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px ;
  width: 100%;
  flex: 1;
  min-height: 0;

  .playerListBox {
    flex: 1;
    border-radius: 5px;
    background: var(--obcolor-background-6);
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    box-sizing: border-box;
  }

  .boxHeaderSection {
    padding: 10px 10px 0 10px;
  }

  .boxTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .boxSubTitle {
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }

  .filterBox {
    padding: 3px 0 5px 0;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
    height: 40px;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    background: var(--obcolor-background-2);
    padding-left: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 30px;
    width: 160px;

    > input {
      flex: 1;
      min-width: 0;
    }

    > i {
      padding-right: 3px;
    }
  }

  .filterItem {
    flex: 1;
    min-width: 0;
    height: 30px;
    box-sizing: border-box;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .checkboxText {
    color: var(--obcolor-font-secondary);
    font-size: 14px;
  }

  .blueCheckbox {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background: var(--obcolor-background-2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      filter: brightness(1.3);
    }

    .selectedCheck {
      color: white;
      font-size: 14px;
    }

    &.selected {
      background: var(--obcolor-ob-blue);
    }

    &:not(.selected) .selectedCheck {
      display: none;
    }
  }

  .playerListContainer {
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow: auto;
    position: relative;

    table {
      width: 100%;

      tr:first-child {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      tr:not(:first-child):not(.lockedPlayer) {
        cursor: pointer;

        &:hover {
          background: var(--obcolor-background-5);
        }
      }

      th, td {
        border-bottom: 1px solid var(--obcolor-background-4);
      }

      th {
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: var(--obcolor-font-secondary);
        background: var(--obcolor-background-4);
        border-bottom: 1px solid var(--obcolor-background-4);
      }

      td {
        height: 55px;
        font-size: 14px;
      }

      .playerHeader {
        padding-left: 50px;
      }

      .statsHeader {
        width: 60px;
        text-align: center;
      }

      .addDropHeader {
        width: 40px;
      }
    }

    .noPlayersRow {
      padding-left: 10px;
      font-size: 14px;
      color: var(--obcolor-font-secondary);
    }

    table.hasPlayerSelected tr:not(:first-child):not(.selectedPlayer) {
      filter: opacity(0.6);

      .position, .nameContainer, .ptsSection, .salarySection {
        color: var(--obcolor-font-secondary);
      }
    }

    .nameContainer {
      margin-left: 10px;
      .playerName {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: bold;

        .nameText {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .gameInfo {
        margin-top: 3px;
        margin-left: 15px;
        font-size: 14px;
        color: var(--obcolor-font-secondary);
      }
    }

    .playerInfoContainer {
      display: flex;
      align-items: center;

      .position {
        text-align: center;
        font-weight: bold;
        width: 40px;
        margin-right: 10px;
      }
    }

    .statSection {
      text-align: center;
      font-weight: bold;

      &.salarySection {
        color: var(--obcolor-green);

        &.cantAfford {
          color: var(--obcolor-red);
        }
      }
    }
  }
}

.selectIcon {
  font-size: 20px;

  &.lockIcon {
    font-size: 18px;
  }
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.redText {
  color: var(--obcolor-red);
}

.greenText {
  color: var(--obcolor-green);
}

.greyText {
  color: var(--obcolor-font-secondary)
}
</style>