<template>
  <div class="ob-page">
    <div class="leftContainer">
      <!-- Title box, general contest info -->
      <div class="nameSection">
        <img class="llIcon" :src="require('@/assets/contestbadge/lightning_lineups.png')" alt="Lightning Lineups">
        <div class="nameTextContainer">
          <div class="headerText">Lightning Lineups</div>
          <div class="dateText">
            {{ sport }}
            <span v-if="hasLineups">- {{ firstGameTimeFormatted }}</span>
          </div>
          <!-- How to play popup along with clickable text -->
          <!-- Clickaway is around the button and the popup so that clicking the button doesn't immediately open/close -->
          <div v-on-clickaway="closeHowToPlay">
            <div class="howToPlayText" @click="toggleHowToPlay">Contest Details</div>
            <div v-if="showHowToPlay" class="modal-mask" @click="closeHowToPlay()"></div>
            <LightningHowToPlayPopup v-if="showHowToPlay" @close="closeHowToPlay()" :contest="lineupInfo" />
          </div>
        </div>
      </div>

      <!-- Sport and Slate -->
      <div class="sportSelector">
        <div class="sportSelectorTitle">Select Sport and Slate</div>
        <!-- Sport Selector -->
        <div class="sportsContainer">
          <div v-for="(sportOption, index) in sportOptions" :key="index" class="sportTile"
            :class="{noClick: !loaded, selected: sportOption == sport, closed: isSportClosed(sportOption)}" @click.stop="selectSport(sportOption)"
          >
            <div>
              <img class="sportImg" :src="require('@/assets/contestbadge/' + sportOption + '.png')"
                :alt="sportOption"
              >
            </div>
            <div>{{ sportOption }}</div>
          </div>
        </div>

        <!-- Date Selector -->
        <DropdownButton v-if="hasDateOptions && !showExistingLineup" class="dateSelector" id="LL_SELECT_DATE">
          <div class="sportDropdownBtn" slot="button">
            <span class="slateTitle">Slate</span>
            <span v-if="!selectedDate">Select Date</span>
            <div v-else class="dateNameContainer">
              <div class="dateName">{{ selectedDate.name }}</div>
              <i class="fas fa-chevron-down chevronDown"></i>
            </div>
          </div>
          <template slot="optionList">
            <div v-for="option in dateOptions" :key="option.id" class="dateOptionDropdown"
              @click.stop="selectDate(option)"
            >
              {{ option.name }}
            </div>
          </template>
        </DropdownButton>
      </div>

      <!-- Current selected lineup -->
      <div class="yourLineupSection">
        <div class="titleSection">
          <div class="titleText">Your Lineup</div>
          <div v-if="selectedLineup" class="yourProj">{{ selectedLineup.projection.toFixed(2) }}</div>
        </div>
        <div class="yourLineupContent">
          <div v-if="selectedLineup !== null" class="yourLineup">
            <template v-for="(player, index) in selectedLineup.players">
              <LightningPlayerTile :key="index + 'tile'" class="playerTile"
                :player="player" :sport="sport" headshotSize="50"
              />
              <div v-if="index < selectedLineup.players.length - 1" class="playerSeparator" :key="index + 'line'"></div>
            </template>
          </div>
          <div v-else>
            <div class="emptyLineupText">Your selected lineup will appear here.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="centerContainer">
      <div class="builderContainer">
        <!-- Left half of view (LL selector, title, etc) -->
        <div class="lineupContainer">
          <!-- Header links -->
          <div class="lineupHeaderSection">
            <div class="sportBadgeContainer">
              <img v-if="sport" class="sportBadge" :src="require('@/assets/contestbadge/' + sport + '.png')"
                :alt="sport"
              >
            </div>
          </div>
          <LightningLineupSelector class="lineupSelector" :sport="sport" :lineupInfo="lineupInfo"
            :numLineups="numLineups" :loaded="loaded" :hasLineups="hasLineups"
            :selectedLineupIndex="selectedLineupIndex" :lineupErrorMessage="getLineupErrorMessage"
            :lockedPlayerId="lockedPlayerId"
          />
          <div class="entrySection">
            <div class="entryContainer">
              <div class="entryTextBox">
                <span class="entryText">Entry</span>
                <div class="entryValue" :class="{promoText: promoCodeActive}">
                  <span v-if="isRealMoney" class="entryInput">$</span>
                  <img v-else :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                    alt="Ownersbucks"
                  >
                  <span v-if="promoCodeActive" class="entryInput">{{ entryValueDollars }}</span>
                  <input v-else class="entryInput entryInputValue" :style="{ width: entryInputWidth }"
                    v-model="entryValueDollars" placeholder="0.00"
                  >
                </div>
              </div>
              <!-- OwnersBucks/Real Money buttons -->
              <div v-if="!promoCodeActive" class="entryButtonsList moneyTypeButtons">
                <div class="entryButton" :class="{ selected: isRealMoney }" @click="setIsRealMoney(true)">
                  $
                </div>
                <div class="entryButton" :class="{ selected: !isRealMoney }" @click="setIsRealMoney(false)">
                  <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="Ownersbucks">
                </div>
              </div>
              <!-- Add or display tickets buttons -->
              <div class="entryButtonsList moneyTypeButtons" style="align-items: center;">
                <div class="promoBtnContainer">
                  <div v-if="promoCode == null" class="entryButton" @click="openPromoModal()">
                    <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Ticket Icon">
                  </div>
                  <div v-else class="ob-btn-grey promoCodeBtn removePromo" @click="removePromoCode()">
                    <span>{{ promoCode }} Applied</span>
                    <i class="fas fa-times-circle" style="margin-left: 4px;"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="respinText">
              Click to re-spin
            </div>
            <div class="toWinContainer">
              <!-- Error Messages -->
              <div class="errorContainer">
                {{ submitError }}
              </div>
              <div class="entryTextBox">
                <div class="entryText">To Win</div>
                <div class="toWinValueContainer" :class="{realMoney: isRealMoney}">
                  <span v-if="isRealMoney" class="entryInput">$</span>
                  <div v-else class="entryInput">
                    <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                      alt="Ownersbucks"
                    >
                  </div>
                  <span class="entryInput toWinValue">{{ toWinValue }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Confirm button -->
      <div v-if="isSubmitting" class="ob-btn-grey confirmBtn disabledBtn">
        <ObLoading :size="38" style="margin-left: -10px; margin-top: -2px;" />
        Submitting...
      </div>
      <div v-else-if="!canSubmit" class="ob-btn-grey disabledBtn confirmBtn">
        {{ promoCode == null ? 'Submit' : 'Submit Free Entry' }}
      </div>
      <div v-else class="ob-btn confirmBtn" @click="submitLineup()">
        {{ promoCode == null ? 'Submit' : 'Submit Free Entry' }}
      </div>
    </div>
    <div class="rightContainer">
      <SponsorImage class="sponsorBox" sponsorKey="ll-right-side" height="151px"
        width="288px"
      />

      <!-- Battle Pass Challenges -->
      <BattlePassChallengeList class="boxBody" :battlePass="battlePass" :lightningLineup="true"
        title="Lightning Lineups Challenges"
      />

      <LightningHowToPlaySection :contest="lineupInfo" />
    </div>

    <AutoApplyPromoModal />
    <ApplyTicketsModal contestType="LIGHTNING_LINEUPS" bottomNote="Lightning Lineups tickets will apply their entry fee and currency type to a contest." />
    <LightningSubmitModal />
    <LightningTestResultsModal />
  </div>
</template>

<script>
import ApplyTicketsModal from '@/components/tickets/ApplyTicketsModal.vue';
import AutoApplyPromoModal from '@/components/modals/AutoApplyPromoModal.vue';
import LightningSubmitModal from './LightningSubmitModal.vue';
import LightningTestResultsModal from '../TestLineups/LightningTestResultsModal.vue';
import LightningLineupSelector from './LightningLineupSelector.vue';
import LightningPlayerTile from './LightningPlayerTile.vue';
import LightningHowToPlayPopup from './LightningHowToPlayPopup.vue';
import LightningHowToPlaySection from './LightningHowToPlaySection.vue';
import BattlePassChallengeList from '@/views/BattlePass/BattlePassChallengeList';
import DropdownButton from '@/components/buttons/DropdownButton';
import ObLightningLineupsApi from '@/api/ObLightningLineupsApi';
import ObPromoCodeApi from '@/api/ObPromoCodeApi';
import ObLoading from '@/components/ObLoading.vue';
import EventBus from '@/event-bus';
import Decimal from 'decimal.js';
import SponsorImage from '@/components/sponsors/SponsorImage';
import {mixin as clickaway} from 'vue-clickaway2';
import {mapState} from 'vuex';

export default {
  components: {
    AutoApplyPromoModal,
    ApplyTicketsModal,
    LightningSubmitModal,
    LightningTestResultsModal,
    LightningLineupSelector,
    LightningPlayerTile,
    LightningHowToPlayPopup,
    LightningHowToPlaySection,
    ObLoading,
    DropdownButton,
    BattlePassChallengeList,
    SponsorImage,
  },

  mixins: [clickaway],

  data() {
    return {
      lineupInfo: null,
      sport: null,
      // If the getLineups call has run
      loaded: false,
      generatingNewLineup: false,
      isSubmitting: false,
      showExistingLineup: false,
      submitError: null,
      dateOptions: null,
      selectedDate: null,
      existingLineup: null,

      // If the getLineups call has run, but had an error (no games)
      hasLineups: false,
      getLineupErrorMessage: '',
      numLineups: 3,
      selectedLineupIndex: null,

      isRealMoney: true,
      entryValueDollars: null,
      promoCode: null,
      promoCodeSport: null,
      showHowToPlay: false,
      lockedPlayerId: null,
      sportOptions: [
        'NFL',
        'NBA',
        'NHL',
        'MLB',
      ],
      isSquadRide: false,
    };
  },

  created() {
    this.getSportInfo();
    this.sport = this.$route.params.sport;
    this.existingLineup = this.$route.query.lineup;

    if (this.existingLineup != null) {
      this.getExistingLineup();
    } else {
      this.setupPresetLineup();
      this.getDateOptions(!this.$route.params.presetLineup);
    }

    this.getAutoAppliedPromoCode();

    EventBus.$on('LL_SELECT_LINEUP', this.selectLineup);
    EventBus.$on('LL_CLEAR_ENTRY_FEE', this.clearEntryFee);
    EventBus.$on('LL_GENERATE_NEW_LINEUP', this.getLineup);
    EventBus.$on('LL_ADD_LINEUP', this.addLineup);
    EventBus.$on('LL_REMOVE_LINEUP', this.removeLineup);
    EventBus.$on('LL_TIMER_FINISHED', this.handleFinishedTimer);
    EventBus.$on('APPLY_TICKET', this.applyTicket);
    EventBus.$on('LL_SET_LOCK_PLAYER', this.setLockedPlayer);
    EventBus.$emit('LOAD_BATTLE_PASS');
  },

  destroyed() {
    EventBus.$off('LL_SELECT_LINEUP', this.selectLineup);
    EventBus.$off('LL_CLEAR_ENTRY_FEE', this.clearEntryFee);
    EventBus.$off('LL_GENERATE_NEW_LINEUP', this.getLineup);
    EventBus.$off('LL_ADD_LINEUP', this.addLineup);
    EventBus.$off('LL_REMOVE_LINEUP', this.removeLineup);
    EventBus.$off('LL_TIMER_FINISHED', this.handleFinishedTimer);
    EventBus.$off('APPLY_TICKET', this.applyTicket);
    EventBus.$off('LL_SET_LOCK_PLAYER', this.setLockedPlayer);
  },

  watch: {
    // Remove any part of the entryValue that doesn't follow the decimal format
    entryValueDollars(newVal, oldVal) {
      const regexInteger = /[^\d^\.]/g;
      const regexDecimal = /(\d*\.\d{0,2}).*/g;
      let replaceVal = String(newVal);
      replaceVal = replaceVal.replace(regexInteger, '');
      replaceVal = replaceVal.replace(regexDecimal, '$1');
      this.entryValueDollars = replaceVal;
    },

    '$route.params.sport'(newVal) {
      this.sport = newVal;
      this.loaded = false;
      this.hasLineups = false;
      if (!newVal) {
        this.setSportIfNull();
        return;
      }

      // When switching sport remove ticket if it is not valid for the new sport
      if (this.promoCodeSport && this.promoCodeSport !== this.sport) {
        this.removePromoCode();
      }

      if (this.promoCode == null) {
        this.getAutoAppliedPromoCode();
      }
      this.lineupInfo = null;
      this.clearDateOptions();
      this.getDateOptions(true);
    },

    sportNumContests(_newVal, oldVal) {
      if (!this.hasSportNumContestsData(oldVal)) {
        this.setSportIfNull();
      }
    },
  },

  computed: {
    ...mapState(['sportNumContests', 'battlePass']),

    payoutMultiplier() {
      if (!this.lineupInfo || !this.lineupInfo.payouts || !this.lineupInfo.payouts[this.numLineups]) {
        return null;
      }
      return this.lineupInfo.payouts[this.numLineups];
    },

    entryFeeOptions() {
      if (!this.lineupInfo || !this.lineupInfo.entryFeeOptions) {
        return null;
      }
      return this.lineupInfo.entryFeeOptions;
    },

    toWinValue() {
      let entryValueDollarsDecimal = new Decimal(0);
      try {
        entryValueDollarsDecimal = new Decimal(this.entryValueDollars);
        entryValueDollarsDecimal = entryValueDollarsDecimal.times(this.payoutMultiplier);
      } catch (error) {
        // Do nothing, use 0
      }
      return Number(entryValueDollarsDecimal).toFixed(2);
    },

    entryValue() {
      let entryDecimal = new Decimal(0);
      try {
        entryDecimal = new Decimal(this.entryValueDollars);
      } catch (error) {
        // Do nothing, use 0
      }
      entryDecimal = entryDecimal.times(100);
      return Number(entryDecimal);
    },

    entryInputWidth() {
      if (!this.entryValueDollars) {
        return '33px';
      }

      const entryLength = this.entryValueDollars.toString().replace(/\./g, '').length;
      return (entryLength * 10 + 3) + 'px';
    },

    hasDateOptions() {
      return this.dateOptions && this.dateOptions.length > 0;
    },

    selectedDateId() {
      return this.selectedDate ? this.selectedDate.id : null;
    },

    selectedLineup() {
      if (!this.lineupInfo || !this.lineupInfo.lineups || this.selectedLineupIndex === null) {
        return null;
      }
      return this.lineupInfo.lineups[this.selectedLineupIndex];
    },

    canSubmit() {
      return this.selectedLineupIndex != null && this.entryValue > 0;
    },

    firstGameTime() {
      let firstGameTime = null;
      for (let i=0; i<this.numLineups; i++) {
        const lineup = this.lineupInfo.lineups[i];
        for (const player of lineup.players) {
          const gameTime = player.game.date;
          if (!gameTime) {
            continue;
          }
          if (!firstGameTime || gameTime < firstGameTime) {
            firstGameTime = gameTime;
          }
        }
      }
      return firstGameTime;
    },

    firstGameTimeFormatted() {
      if (!this.firstGameTime) {
        return '';
      }
      return this.$moment(this.firstGameTime).format('ddd h:mma');
    },

    isTestMode() {
      return this.$route.params.mode == 'testing';
    },

    promoCodeActive() {
      return this.promoCode !== null;
    },
  },

  methods: {
    resetData() {
      this.numLineups = 3;
      this.selectedLineupIndex = null;
      this.submitError = null;
      this.getLineupErrorMessage = '';
      this.showExistingLineup = false;
      this.isSquadRide = false;
    },

    clearEntryFee() {
      this.entryValueDollars = null;
    },

    setSportIfNull() {
      if (this.sport != null) {
        return;
      }

      if (!this.hasSportNumContestsData(this.sportNumContests)) {
        return;
      }

      const newSport = this.$UserInfo.mostRelevantLLSport();

      this.$router.push({params: {sport: newSport}});
    },

    hasSportNumContestsData(sportNumContests) {
      return Object.keys(sportNumContests).length > 0;
    },

    selectDate(option) {
      const selectedId = this.selectedDateId;
      const newId = option ? option.id : null;
      this.selectedDate = option;
      EventBus.$emit('CLOSE_DROPDOWNS');
      if (selectedId != newId) {
        this.getLineup();
      }
    },

    clearDateOptions() {
      this.dateOptions = null;
      this.selectedDate = null;
    },

    selectFirstDate() {
      if (!this.dateOptions || this.dateOptions.length == 0) {
        this.selectedDate = null;
        return;
      }
      this.selectedDate = this.dateOptions[0];
    },

    setupPresetLineup() {
      const presetLineup = this.$route.params.presetLineup;
      if (!presetLineup) {
        return;
      }
      this.$nextTick(() => {
        EventBus.$emit('LL_NEW_LINEUPS_GENERATED', {new: presetLineup.lineup, old: this.lineupInfo});
        this.lineupInfo = presetLineup.lineup;
        this.isRealMoney = presetLineup.realMoney;
        if (presetLineup.selected != null) {
          this.selectedLineupIndex = presetLineup.selected;
        }
        if (presetLineup.entryFee) {
          this.entryValueDollars = presetLineup.entryFee;
        }
        this.loaded = true;
        this.hasLineups = true;
      });
    },

    getDateOptions(loadLineup) {
      if (!this.sport) {
        return;
      }
      const curSport = this.sport;
      ObLightningLineupsApi.getDateOptions(curSport)
          .then((response) => {
            // Prevent using date options if sport has changed
            if (curSport === this.sport && response.options) {
              this.dateOptions = response.options;
              this.selectFirstDate();
            }
            if (loadLineup) {
              this.getLineup();
            }
          })
          .catch((_error) => {
            // No date options, load standard lineup
            this.clearDateOptions();
            if (loadLineup) {
              this.getLineup();
            }
          });
    },

    reloadDateOptions(processErrors, forceGetLineup = false) {
      if (!this.sport) {
        return;
      }
      const curSport = this.sport;
      ObLightningLineupsApi.getDateOptions(curSport)
          .then((response) => {
            // Prevent using date options if sport has changed
            if (curSport !== this.sport) {
              return;
            }

            // Update date options
            const previousDateId = this.selectedDateId;
            this.dateOptions = response.options;

            // If the selected date is null, or no longer exists, select the first option and generate lineup
            if (!this.selectedDateExists()) {
              this.selectFirstDate();
              if ((this.selectedDateId !== previousDateId && processErrors) || forceGetLineup) {
                this.getLineup();
                return;
              }
            }

            // Selected option is still valid, update date options but don't generate again
            // Just show the lineup generation error, this avoids an infinite loop
            if (processErrors) {
              this.showLineupError();
            }
          })
          .catch((_error) => {
            // No date options, show lineup generation error
            this.clearDateOptions();
            if (processErrors) {
              this.showLineupError();
            }
          });
    },

    selectedDateExists() {
      const selectedId = this.selectedDateId;
      if (!selectedId || !this.dateOptions) {
        return false;
      }
      for (const option of this.dateOptions) {
        if (option.id === selectedId) {
          return true;
        }
      }
      return false;
    },

    getExistingLineup() {
      this.loaded = false;

      if (!this.sport) {
        this.showLineupError();
        return;
      }

      const curSport = this.sport;
      ObLightningLineupsApi.getExistingLineup(this.existingLineup)
          .then((response) => {
            // Prevent a lineup from loading in the wrong sport
            // For example if they changed a URL parameter midway through loading
            if (curSport != this.sport) {
              return;
            }
            EventBus.$emit('LL_NEW_LINEUPS_GENERATED', {new: response, old: this.lineupInfo});
            this.lineupInfo = response;
            this.loaded = true;
            this.hasLineups = true;
            this.showExistingLineup = true;
            this.isSquadRide = response.hasContestEntry;
            if (!response.allowLockedPlayers) {
              this.setLockedPlayer(null);
            }

            // Select a lineup if query param is set
            const selected = parseInt(this.$route.query.selected);
            if (selected) {
              this.selectLineup(selected - 1);
            }

            // Sets entry value if query param is set
            const entryAmount = this.$route.query.entry;
            if (entryAmount && this.promoCode == null) {
              this.entryValueDollars = entryAmount;
            }

            // Sets entry value if query param is set
            const numLineups = Number(this.$route.query.numLineups);
            if (numLineups) {
              this.numLineups = numLineups;
            }

            // Remove query params so user cannot load contest again
            this.$router.replace({query: {}});

            // On succesful lineup generation reload date options
            this.reloadDateOptions(false);
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              this.getLineupErrorMessage = error.response.data;
              this.setLockedPlayer(null);
            }

            // Reload date options, generate new lineup if needed, and handle error display
            this.reloadDateOptions(true, true);
          });
    },

    getLineup() {
      this.resetData();
      this.loaded = false;

      if (!this.sport) {
        this.showLineupError();
        return;
      }

      const curSport = this.sport;
      ObLightningLineupsApi.getLineup(curSport, this.selectedDateId, this.isTestMode, this.lockedPlayerId)
          .then((response) => {
            // Prevent a lineup from loading in the wrong sport
            // For example if they changed a URL parameter midway through loading
            if (curSport != this.sport) {
              return;
            }
            EventBus.$emit('LL_NEW_LINEUPS_GENERATED', {new: response, old: this.lineupInfo});
            this.lineupInfo = response;
            this.loaded = true;
            this.hasLineups = true;
            if (!response.allowLockedPlayers) {
              this.setLockedPlayer(null);
            }
            // On succesful lineup generation reload date options
            this.reloadDateOptions(false);
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              this.getLineupErrorMessage = error.response.data;
              this.setLockedPlayer(null);
            }
            // Reload date options, generate new lineup if needed, and handle error display
            this.reloadDateOptions(true);
          });
    },

    showLineupError() {
      this.loaded = true;
      this.hasLineups = false;
    },

    handleFinishedTimer() {
      this.getLineup();
    },

    selectSport(sport) {
      if (this.loaded && this.sport !== sport && !this.isSportClosed(sport)) {
        this.$router.push({params: {sport: sport}});
        this.setLockedPlayer(null);
      }
    },

    selectLineup(lineupIndex) {
      this.selectedLineupIndex = lineupIndex;
    },

    toggleHowToPlay() {
      this.showHowToPlay = !this.showHowToPlay;
    },

    closeHowToPlay() {
      this.showHowToPlay = false;
    },

    setIsRealMoney(val) {
      // User cannot set currency type when using a ticket
      if (this.promoCode == null) {
        this.isRealMoney = val;
      }
    },

    addLineup() {
      this.numLineups++;
    },

    removeLineup() {
      this.numLineups--;
      if (this.selectedLineupIndex >= this.numLineups) {
        this.selectedLineupIndex = null;
      }
    },

    submitLineup() {
      this.submitError = null;
      this.isSubmitting = true;
      if (this.selectedLineupIndex == null) {
        this.isSubmitting = false;
        return;
      }
      if (this.isTestMode) {
        EventBus.$emit('LL_SHOW_TEST_RESULTS', this.lineupInfo, this.selectedLineupIndex, this.numLineups);
        this.isSubmitting = false;
        return;
      }

      EventBus.$emit('LL_PAUSE_TIMER');

      const params = {
        id: this.lineupInfo.id,
        entryFee: this.entryValue,
        numLineups: this.numLineups,
        selectedLineup: this.selectedLineupIndex,
        realMoney: this.isRealMoney,
        promo: this.promoCode,
      };
      this.submitEntry(params);
    },

    submitEntry(params) {
      const entryInfo = this.createEntryTrackingJSON();
      ObLightningLineupsApi.submitEntry(params)
          .then((response) => {
            entryInfo.contestId = response;
            this.$Analytics.trackLightningLineupsEntry(entryInfo, true, this.promoCode);
            this.isSubmitting = false;
            const params = {
              lineupInfo: this.lineupInfo,
              selectedLineupIndex: this.selectedLineupIndex,
              numLineups: this.numLineups,
              isRealMoney: this.isRealMoney,
              entryValueDollars: this.entryValueDollars,
              toWinValue: this.toWinValue,
              firstGameTime: this.firstGameTime,
              lockedPlayerId: this.lockedPlayerId,
            };
            EventBus.$emit('LL_OPEN_SUBMIT_MODAL', params);
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            this.removePromoCode();
            this.setLockedPlayer(null);
          }).catch((error) => {
            const errorMsg = error?.response?.data?.errorMessage ?? 'Error submitting entry';
            if (errorMsg) {
              entryInfo.failureReason = errorMsg;
            }
            this.$Analytics.trackLightningLineupsEntry(entryInfo, false, this.promoCode);
            this.isSubmitting = false;
            EventBus.$emit('LL_UNPAUSE_TIMER');

            // Show modal if this is a location error
            const locationError = error?.response?.data?.locationError;
            if (locationError) {
              this.submitError = null;
              EventBus.$emit('SHOW_LOCATION_ERROR_MODAL', locationError);
            } else {
              this.submitError = errorMsg;
            }
          });
    },

    openPromoModal() {
      EventBus.$emit('OPEN_APPLY_TICKETS_MODAL', this.promoCode, this.sport);
    },

    applyTicket(ticket) {
      // Apply entry fee values from server validation
      if (ticket && ticket.id && ticket.feeValue) {
        this.promoCode = ticket.id;
        this.promoCodeSport = ticket.sport || null;
        this.entryValueDollars = ticket.feeValue.centsToDollars().removeCentsIfZero();
        this.isRealMoney = ticket.realMoney;
      } else {
        this.removePromoCode();
      }
    },

    getAutoAppliedPromoCode() {
      // Don't get auto-apply codes until sport has been set
      if (!this.sport) {
        return;
      }
      ObPromoCodeApi.getAutoAppliedLLPromoCode(this.sport)
          .then((result) => {
            if (result.isValid && this.promoCode == null) {
              EventBus.$emit('OPEN_PROMO_AUTO_APPLY_MODAL', result.promo);
              this.applyTicket(result.promo);
            }
          })
          .catch((_error) => {
            // Unable to get Tickets to auto apply
          });
    },

    removePromoCode() {
      this.promoCode = null;
      this.promoCodeSport = null;
      this.clearEntryFee();
    },

    createEntryTrackingJSON() {
      const contest = {};
      contest.lineupId = this.lineupInfo.id;
      contest.fee = this.entryValueDollars;
      contest.sport = this.lineupInfo.sport;
      contest.lineupCount = this.numLineups;
      contest.payout = this.toWinValue;
      contest.payoutMultiplier = this.lineupInfo.payouts[this.numLineups];
      contest.players = [];
      contest.failureReason = '';
      const userPlayers = this.lineupInfo.lineups[this.selectedLineupIndex].players;
      for (const player of userPlayers) {
        contest.players.push(player.name);
      }
      contest.realMoney = this.isRealMoney;
      contest.isPlayerLocked = this.lockedPlayerId !== null;
      contest.isSquadRide = this.isSquadRide;
      return contest;
    },

    setLockedPlayer(lockedPlayerId) {
      this.lockedPlayerId = lockedPlayerId;
    },

    getSportInfo() {
      EventBus.$emit('FETCH_SPORT_NUM_CONTESTS');
    },

    isSportClosed(sport) {
      return !this.sportNumContests?.[sport]?.llContestOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.ob-page {
  display: flex;
  flex-direction: row;
  align-items: top;
  padding-bottom: 0;
  width: 1406px;
}

.titleText {
  font-size: 16px;
}

.sportSelectorTitle {
  font-size: 16px;
}

.leftContainer {
  margin-right: 6px;
}

.sponsorBox {
  padding-bottom: 10px;
}

.centerContainer {
  width: 818px;
  height: 645px;
  display: flex;
  flex-direction: column;
}

.builderContainer {
  width: 818px;
  height: 593px;
  border-radius: 4px;
  background: var(--obcolor-background-4);
  display: flex;
  flex-direction: column;
}

.lineupContainer {
  flex: 1;
  padding: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
}

.lineupHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  height: 20px;
  min-height: 20px;
  overflow: hidden;
  position: relative;
}

.sportSelector {
  width: 268px;
  margin-top: 10px;
  background: var(--obcolor-background-5);
  padding: 10px;
  border-radius: 4px;
}

.sportsContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 15px 0px;
}

.sportTile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  opacity: 0.75;
  font-size: 14px;

  width: 60.61px;
  height: 63px;

  background: var(--obcolor-background-4);

  &:not(.closed):hover {
    background: var(--obcolor-background-2);
    opacity: 1;
  }

  &.noClick {
    cursor: not-allowed;
  }

  &.selected {
    background: var(--obcolor-background-1);
    opacity: 1;
    font-weight: bold;
  }

  &.closed {
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--obcolor-font-secondary);
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.slateTitle {
    color: var(--obcolor-font-secondary);
}

.sportBadgeContainer {
  --imgWidth: 120px;
  min-height: 0;
  height: 20px;
  position: absolute;
  left: calc(50% - var(--imgWidth)/2);

  .sportBadge {
    margin-top: -25px;
    width: 120px;
    opacity: 0.4;
  }
}

.sportImg {
  width: 44px;
  height: 40px;
}

.sportDropdownBtn {
  width: 268px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background: var(--obcolor-background-4);
  height: 40px;
  align-items: center;
  padding: 0 12px;
  border-radius: 4px;

  &:hover {
    background: var(--obcolor-background-2);
  }
  &.noClick {
    cursor: not-allowed;
    color: var(--obcolor-font-secondary);
  }
}

.dateNameContainer {
  display: flex;
}

.dateName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.dateOptionDropdown {
  white-space: normal;
  width: 268px;
}

.lineupSelector {
  flex: 1;
}

.nameSection {
  display: flex;
  width: 268px;
  background: var(--obcolor-background-5);
  padding: 10px;
  border-radius: 4px;
}

.llIcon {
  width: 69px;
  height: 72px;
  margin-left: -5px;
  margin-right: -5px;
}

.nameTextContainer {
  padding: 10px 0 0 10px;

  .headerText {
    font-size: 16px;
    font-weight: bold;
  }

  .dateText {
    font-size: 14px;
    padding: 5px 0;
  }

  .howToPlayText {
    cursor: pointer;
    font-size: 14px;
    color: var(--obcolor-font-secondary);
    text-decoration: underline;
  }
}

.yourLineupSection {
  background: var(--obcolor-background-5);
  margin: 10px 0;
  width: 288px;
  border-radius: 4px;

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .yourProj {
    font-weight: bold;
    color: var(--obcolor-ob-blue);
  }

  .yourLineupContent {
    padding: 5px;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .yourLineup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;
  }

  .playerSeparator {
    align-self: stretch;
    width: 1px;
    background: var(--obcolor-background-4);
  }

  .emptyLineupText {
    color: var(--obcolor-font-secondary);
    font-size: 12px;
    font-weight: bold;
  }
}

::v-deep {
  .yourLineupSection .playerTile {
    padding: 5px;
    flex: 1;
    min-width: 0;
    box-sizing: border-box;

    .nextGame {
      font-size: 11px;
    }
  }
}

.entrySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 52px;
}

.entryButtonsList {
  display: flex;
}

.entryButton {
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  margin: 7px 0 7px 5px;
  background: var(--obcolor-background-2);
  cursor: pointer;

  &:not(.selected):hover {
    background: var(--obcolor-background-1);
  }

  &.selected {
    border: 1px solid #00AEEF;
  }
}

.entryTextBox {
  height: 38px;
  padding: 0 5px;
  width: 160px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 7px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--obcolor-background-2);

  font-size: 14px;
}

.entryValue {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.entryInput {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0;
  background: none;
  margin-left: 4px;
  height: 20px;
  display: flex;
  align-items: center;
}

.entryInputValue {
  min-width: 33px;
  max-width: 85px;
  line-height: 20px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.errorContainer {
  font-size: 12px;
  width: 180px;
  height: 52px;
  text-align: center;
  display: flex;
  align-items: center;
  color: red;
  margin-bottom: 5px;
}

.confirmBtn {
  margin-top: 4px;
  padding: 13px 0;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledBtn {
  cursor: not-allowed;
  color: var(--obcolor-font-secondary);
}

.entryContainer {
  display: flex;
  flex-direction: row;
  width: 340px;
}

.promoBtnContainer {
  flex: 1;
  display: flex;
}

.promoCodeBtn {
  font-size: 12px;
  line-height: 16px;
  padding: 3px 5px;
  margin: 10px 0px 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 4px;
}

.removePromo {
  background: var(--obcolor-ob-orange) !important;
}

.respinText {
  font-size: 12px;
  margin-top: 30px;
  color: var(--obcolor-font-secondary);
}

.toWinContainer {
  display: flex;
  justify-content: right;
  width: 340px;
}

.entryText {
  color: var(--obcolor-font-secondary);
}

.toWinValueContainer {
  display: flex;
  flex-direction: row;
}

.toWinValue {
  max-width: 75px;
  line-height: 20px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.realMoney {
  color: var(--obcolor-realmoney);
}

.dateSelector {
  width: auto;
  font-size: 14px;
  white-space: nowrap;
  justify-content: flex-end;
  cursor: pointer;
}

.chevronDown {
  margin-left: 9px;
}

.promoText {
  color: var(--obcolor-ob-orange);
}

.ownersbucksIcon {
  height: 10px;
}

.ticketIcon {
  height: 12px;
}

.challengesBox {
  background: var(--obcolor-background-6);
  border-radius: 0 0 5px 5px;
  height: 250px;
}

.rightContainer {
  margin-left: 6px;
  ::v-deep {
    .boxBody {
      font-size: 12px;
      background: var(--obcolor-background-6);
      max-width: 274px;
      margin-bottom: 10px;
      border-radius: 5px;
      max-height: 200px;
    }

    .typeBtn {
      padding: 5px;
      min-width: 20px;
    }

    .label,
    .progressChallenge,
    .rewardContainer {
      font-size: 11px;
    }

    .rewardContainer{
      width: 40px;
    }

    .challengeContentContainer {
      min-width: 120px;
      max-width: 160px;
    }
  }
}
</style>