<template>
  <div>
    <table class="gamesTable" aria-label="Player Card Games">
      <template v-if="sport != 'NFL'">
        <tr>
          <th class="leftInfo tableTitle" scope="col">PROJECTION</th>
          <template v-if="projGames">
            <th v-for="(game, index) in projGames[0]" :key="index" style="font-size: 12px;">
              <div>{{ game.date | moment("ddd") }}</div>
              <div>{{ game.date | moment("MMM D") }}</div>
            </th>
          </template>
          <template v-else>
            <th v-for="(game, index) in playerSummary.games" :key="index" style="font-size: 12px;">
              <div>{{ game.date | moment("ddd") }}</div>
              <div>{{ game.date | moment("MMM D") }}</div>
            </th>
          </template>
          <th style="width: 60px;">PG</th>
          <th style="width: 60px;">AVG</th>
          <th style="width: 70px;">PROJ</th>
        </tr>

        <tr v-for="(games, week) in projGames" :key="week">
          <td class="leftInfo">Week {{ numberNames[week + 1] }}</td>
          <template v-for="(game, day) in games">
            <td :key="day" style="font-size: 12px;">
              <div v-if="game.game">
                <div :style="game.excluded ? 'color: red;' : ''">
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div>{{ getGameSymbol(game.game) }}</div>
                    <img class="teamLogo" :src="getTeamLogo(game.game)" :alt="game.game">
                  </div>
                  <div>Proj {{ game.proj.toFixed(0) }}</div>
                </div>
              </div>
              <div v-if="game.gameDH" :style="game.excludedDH ? 'color: red;' : ''" style="margin-top: 8px;">
                <div style="display: flex; align-items: center; justify-content: center;">
                  <div>{{ getGameSymbol(game.gameDH) }}</div>
                  <img class="teamLogo" :src="getTeamLogo(game.gameDH)" :alt="game.gameDH">
                </div>
                <div>Proj {{ game.projDH.toFixed(0) }}</div>
              </div>
            </td>
          </template>
          <td>{{ playerSummary.wStats[week].pg.toFixed(0) }}</td>
          <td>{{ playerSummary.wStats[week].avg.toFixed(0) }}</td>
          <td>{{ playerSummary.wStats[week].proj.toFixed(0) }}</td>
        </tr>
      </template>

      <!-- NFL Version, One week Only -->
      <template v-else>
        <tr>
          <th class="leftInfo tableTitle" scope="col">PROJECTION</th>
          <template v-if="nflWeekGames">
            <th v-for="(game, index) in nflWeekGames" :key="index" style="font-size: 12px;">
              <template v-if="game.date">
                <div>{{ game.date | moment("ddd") }}</div>
                <div>{{ game.date | moment("MMM D") }}</div>
              </template>
              <div v-else>BYE</div>
            </th>
          </template>
          <th style="width: 60px;">AVG</th>
          <th style="width: 70px;">PROJ</th>
        </tr>
        <tr v-if="nflWeekGames">
          <td class="leftInfo"></td>
          <td v-for="(game, index) in nflWeekGames" :key="'g'+index" :class="isToday(game) ? 'todayTd' : ''"
            style="font-size: 12px;"
          >
            <div v-if="game.game">
              <div :style="game.excluded ? 'color: red;' : ''">
                <div style="display: flex; align-items: center; justify-content: center;">
                  <div>{{ getGameSymbol(game.game) }}</div>
                  <img class="teamLogo" :src="getTeamLogo(game.game)" :alt="game.game">
                </div>
                <div v-if="game.ts" style="margin-bottom: 3px;">{{ parseInt(game.ts) | moment("h:mma") }}</div>
                <div>Proj {{ game.proj.toFixed(0) }}</div>
              </div>
            </div>
            <div v-else style="color: var(--obcolor-font-secondary);">-</div>
          </td>
          <td>{{ totNflStats.avg.toFixed(0) }}</td>
          <td>{{ totNflStats.proj.toFixed(0) }}</td>
        </tr>
      </template>
    </table>

    <template v-if="showExpand()">
      <div class="showMore" v-if="!isExpanded" @click="setIsExpanded(true)">Show more...</div>
      <div class="showMore" v-else @click="setIsExpanded(false)">Show less</div>
    </template>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    playerSummary: Object,
    sport: String,
  },

  data() {
    return {
      numberNames: ['ZERO', 'One', 'Two', 'Three', 'Four'],
      minimizedLimit: 1,
      isExpanded: false,
    };
  },

  computed: {
    projGames() {
      if (!this.playerSummary || !this.playerSummary.gamesByWeek) {
        return null;
      }

      if (this.isExpanded) {
        return this.playerSummary.gamesByWeek;
      }

      return this.playerSummary.gamesByWeek.slice(0, this.minimizedLimit);
    },

    nflWeekGames() {
      if (this.sport != 'NFL' || this.playerSummary == null ||
        this.playerSummary.gamesByWeek == null
      ) {
        return null;
      }

      let weekGames = null;

      if (this.playerSummary && this.playerSummary.gamesByWeek) {
        weekGames = this.playerSummary.gamesByWeek;
      } else {
        weekGames = this.playerSummary.games;
      }

      const gamesList = [];


      for (const week of weekGames) {
        let hasGame = false;
        for (const game of week) {
          if (game.game) {
            gamesList.push(game);
            hasGame = true;
          }
        }

        if (!hasGame) {
          gamesList.push({});
        }
      }

      return gamesList;
    },

    totNflStats() {
      const totStats = {
        avg: 0,
        proj: 0,
      };

      if (this.nflWeekGames == null) {
        return totStats;
      }

      for (const stat of this.playerSummary.wStats) {
        if (stat.proj) {
          totStats.proj += stat.proj;
        }
      }

      // Don't include bye weeks in AVG
      let gameCount = 0;
      for (const game of this.nflWeekGames) {
        if (game.proj) {
          gameCount++;
        }
      }
      if (gameCount > 0) {
        totStats.avg = totStats.proj / gameCount;
      }

      return totStats;
    },
  },

  methods: {
    getTeamLogo(alias) {
      alias = alias.replace('@', '');
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getGameSymbol(game) {
      return game.indexOf('@') != -1 ? '@' : 'vs';
    },

    showExpand() {
      if (this.sport === 'NFL') {
        return false;
      }
      return this.playerSummary.gamesByWeek && this.playerSummary.gamesByWeek.length > this.minimizedLimit;
    },

    setIsExpanded(isExpanded) {
      this.isExpanded = isExpanded;
      EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'projection');
    },

    isToday(game) {
      if (game && game.date) {
        const gDate = this.$moment(game.date).format('MM/DD/YYYY');
        const today = this.$moment().format('MM/DD/YYYY');
        return gDate === today;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.leftInfo.tableTitle {
  font-size: 14px;
  width: 110px;
  color: var(--obcolor-font-secondary);
}

.leftInfo {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding: 12px 15px;
  max-width: 100px;
  width: 100px;
  white-space: nowrap;
}

.teamLogo {
  height: 21px;
  margin-bottom: 4px;
  margin-left: 2px;
}

.gamesTable {
  border-radius: 5px;
  width: 100%;
}

tr {
  background: var(--obcolor-background-2);
}

tr:not(:first-child) {
  background: var(--obcolor-background-6);
}

tr:not(:first-child):nth-child(odd) {
  background: var(--obcolor-background-5);
}

th {
  color: var(--obcolor-font-secondary);
}

td {
  padding: 6px;
  border-top: 3px solid var(--obcolor-background-6);
  border-bottom: 3px solid var(--obcolor-background-6);
}

th:not(.leftInfo), td:not(.leftInfo) {
  min-width: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}

.todayTd {
  background: var(--obcolor-background-2);
}

.showMore {
  padding: 5px 10px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  border-top: 1px solid var(--obcolor-background-2);
  cursor: pointer;
}
</style>