<template>
  <div v-show="(isDepositVerified == null || evsCheckSSN == true) && !isLoading" slot="footer" class="footer">
    <!-- Error -->
    <div v-if="errorMessage != ''" class="errorMessage" style="flex: 1;">
      {{ errorMessage }}
    </div>

    <button class="ob-btn-grey footerBtn" @click="closeVerify()">Cancel</button>

    <button v-if="sending" disabled class="ob-btn-grey footerBtn">
      <ObLoading :size="40" style="margin-left: -8px;" />
      <div style="margin-left: -6px; margin-top: 2px;">Verifying...</div>
    </button>

    <button v-else-if="!canVerify" disabled class="ob-btn-grey footerBtn">Verify</button>

    <button v-else class="ob-btn footerBtn" @click="verifyAccount()">Verify</button>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ObLoading,
  },

  created() {
    EventBus.$on('SET_VERIFY_IS_SENDING', this.setIsSending);
    EventBus.$on('SET_VERIFY_IS_VERIFIED', this.setIsVerified);
    EventBus.$on('SET_VERIFY_IS_CHECK_SSN', this.setEvsCheckSSN);
    EventBus.$on('SET_VERIFY_ERROR_MESSAGE', this.setErrorMessage);
    EventBus.$on('SET_VERIFY_CAN_VERIFY', this.setCanVerify);
    EventBus.$on('SET_VERIFY_IS_LOADING', this.setIsLoading);
  },

  destroyed() {
    EventBus.$off('SET_VERIFY_IS_SENDING', this.setIsSending);
    EventBus.$off('SET_VERIFY_IS_VERIFIED', this.setIsVerified);
    EventBus.$off('SET_VERIFY_IS_CHECK_SSN', this.setEvsCheckSSN);
    EventBus.$off('SET_VERIFY_ERROR_MESSAGE', this.setErrorMessage);
    EventBus.$off('SET_VERIFY_CAN_VERIFY', this.setCanVerify);
    EventBus.$off('SET_VERIFY_IS_LOADING', this.setIsLoading);
  },

  data() {
    return {
      sending: false,
      errorMessage: '',
      canVerify: false,
      isLoading: true,

      isDepositVerified: null,
      evsCheckSSN: null,
    };
  },

  methods: {
    closeVerify() {
      EventBus.$emit('CLOSE_VERIFY_COMPONENT');
    },

    verifyAccount() {
      EventBus.$emit('VERIFY_ACCOUNT');
    },

    setIsSending(isSending) {
      this.sending = isSending;
    },

    setIsVerified(isVerified) {
      this.isDepositVerified = isVerified;
    },

    setEvsCheckSSN(evsCheckSSN) {
      this.evsCheckSSN = evsCheckSSN;
    },

    setErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
    },

    setCanVerify(canVerify) {
      this.canVerify = canVerify;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  color: red;
}

.footer {
  font-size: 13px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footerBtn {
  width: 105px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerBtn:not(:last-child) {
  margin-right: 10px;
}
</style>