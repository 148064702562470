<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 600px; padding: 0;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);"
  >
    <div slot="header" class="confirmHeader">OwnersBox Ranking System</div>
    <div slot="body" class="confirmBody">
      <table aria-label="Rank Modal">
        <tr>
          <th style="width: 50px;">Icon</th>
          <th style="text-align: left; padding-left: 15px;">Rank Name</th>
          <th>Contest Entry Requirement</th>
        </tr>
        <tr v-for="info in rankInfo" :key="info.num">
          <td><RankLogo :size="40" :rankNum="info.num" :rankColor="'blue'" /></td>
          <td style="text-align: left; padding-left: 15px;">{{ info.name }}</td>
          <td style="width: 140px;">{{ info.entryRequirement }}</td>
        </tr>
      </table>
      <div class="bottomSection">
        <div class="subTitle">Last 10 contest heat rating</div>
        <div class="lastTenList">
          <div class="lastTenVal">
            <RankLogo :size="40" :rankNum="3" :rankColor="'blue'" />
            <div style="margin-top: 5px;">0-4</div>
            <div>Wins</div>
          </div>
          <div class="lastTenVal">
            <RankLogo :size="40" :rankNum="3" :rankColor="'yellow'" />
            <div style="margin-top: 5px;">5</div>
            <div>Wins</div>
          </div>
          <div class="lastTenVal">
            <RankLogo :size="40" :rankNum="3" :rankColor="'orange'" />
            <div style="margin-top: 5px;">6-7</div>
            <div>Wins</div>
          </div>
          <div class="lastTenVal">
            <RankLogo :size="40" :rankNum="3" :rankColor="'red'" />
            <div style="margin-top: 5px;">8+</div>
            <div>Wins</div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import RankLogo from '@/components/RankLogo';

export default {
  components: {
    Modal,
    RankLogo,
  },
  data() {
    return {
      isOpen: false,
      rankInfo: [
        {num: 1, name: 'Rookie', entryRequirement: '0 - 50'},
        {num: 2, name: 'Pro', entryRequirement: '51-250'},
        {num: 3, name: 'Veteran', entryRequirement: '251 - 500'},
        {num: 4, name: 'Hall-of-Famer', entryRequirement: '501 - 1000'},
        {num: 8, name: 'G.O.A.T', entryRequirement: '1000+'},
      ],
    };
  },
  created() {
    EventBus.$on('OPEN_PROFILE_RANK_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_PROFILE_RANK_MODAL', this.openModal);
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}
.confirmBody {
  flex: 1;
  padding: 15px 10px;
  box-sizing: border-box;
}

table {
  width: calc(100% - 20px);
  text-align: center;
  // margin-bottom: 10px;
  margin: 0 10px 10px 10px;

  th, td {
    padding: 5px;
  }

  th {
    font-size: 14px;
  }

  td {
    font-size: 14px;
  }
}

.bottomSection {
  border-top: 2px solid var(--obcolor-background-2);
  padding: 10px 20px;
}

.subTitle {
  margin-bottom: 10px;
  font-weight: bold;
}

.lastTenList {
  display: flex;
  justify-content: space-between;
}

.lastTenVal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
</style>