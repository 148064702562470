<template>
  <div class="ob-page" style="margin-top: -4px;"
    :class="{ swappingTeamsPage: swappingTeams }"
  >
    <!-- Loading -->
    <template v-if="!swappingTeams">
      <div v-if="league == null" class="loadingBox">
        <ObLoading />
      </div>

      <!-- Headers -->
      <div style="margin-bottom: 5px;" v-else>
        <div style="display: flex; align-items: center;">
          <div>
            <img :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="league.sport" style="height: 30px; margin-top: 5px;">
          </div>
          <div class="ob-btn" style="padding: 4px; font-size: 12px; margin-left: 10px; margin-right: 10px;">
            {{ league.playersMax }}-Team {{ league.fee }}
          </div>
          <div>
            <div v-if="league.contestName" style="font-size: 12px; margin-bottom: 2px;">{{ league.contestName }}</div>
            <div style="font-size: 11px; color: var(--obcolor-font-secondary);">#{{ league.id }}</div>
          </div>

          <div @click.stop="openBracketModal()" class="topBarButton" style="margin-left: 10px;">
            <img :src="require('@/assets/icons/bracket_icon.png')" alt="Bracket Icon" style="height: 17px; margin-right: 4px;">Bracket
          </div>

          <div style="flex: 1;"></div>
          <div @click="openTransactionsModal()" class="topBarButton">
            <i class="fas fa-exchange-alt" style="font-size: 14px; margin-right: 6px; margin-top: -1px;"></i>Transactions
          </div>
          <div @click="openDraftSummary()" class="topBarButton">
            <i class="fas fa-clipboard-list" style="font-size: 14px; margin-right: 6px; margin-top: -1px;"></i>Draft Summary
          </div>
          <div @click="openContestChat()" class="topBarButton">
            <i class="fas fa-comment-alt-lines" style="font-size: 14px; margin-right: 6px;"></i>Contest Chat
          </div>
        </div>
      </div>

      <!-- Matchup Header -->
      <div style="display: flex;" v-if="league != null">
        <div class="ob-box" style="flex: 1;">
          <!-- Matchup Score -->
          <MatchupHeader :league="league" :maxGames="maxGames" :week="week"
            :team1Roster="team1Roster"
            :team2Roster="team2Roster"
            :team1GP="team1WeekGP"
            :team2GP="team2WeekGP"
            :team1PossibleGP="team1PossibleGP"
            :team2PossibleGP="team2PossibleGP"
            :team1Proj="team1Proj"
            :team2Proj="team2Proj"
          />

          <div class="statsDayContainer">
            <!-- Matchup Stats -->
            <MatchupStats :league="league" :selectedDay="selectedDay"
              :team1Roster="team1Roster" :team2Roster="team2Roster"
              :team1Totals="team1Totals" :team2Totals="team2Totals"
              :team1Fpts="team1Fpts" :team2Fpts="team2Fpts"
              :scoringStats="scoringStats"
            />
            <!-- Day selector -->
            <MatchupDaySelector :selectedDay="selectedDay" :league="league"
              :team1Roster="team1Roster" :team2Roster="team2Roster"
              :week="week" :winnerPrize="winnerPrize"
            />
          </div>
        </div>
      </div>

      <!-- Submenu Navbar: Lineup, Stats, Summary -->
      <div v-if="league != null" class="navbar">
        <div class="navBtnContainer" style="margin-right: 15px;">
          <div @click="setSelectedTab('schedule1')" :class="{navAct: selectedTab === 'schedule1'}" class="navBtn">Lineup</div>
        </div>
        <div class="navBtnContainer" style="margin-right: 15px;">
          <div @click="setSelectedTab('stats1')" :class="{navAct: selectedTab === 'stats1'}" class="navBtn">Stats</div>
        </div>
        <!-- Waivers only show for your team, does not show when there is no future weeks (2 team contests, or finals) -->
        <div v-if="league.roster1.myTeam && league.matchup.hasWaivers" class="navBtnContainer" style="width: 130px; margin-right: 15px;">
          <div @click="setSelectedTab('waivers')" :class="{navAct: selectedTab === 'waivers'}" class="navBtn">
            <i class="fas fa-stopwatch" style="margin-right: 7px;"></i>Waivers
          </div>
        </div>

        <div class="navBtnContainer summaryBtn">
          <div @click="setSelectedTab('summary')" :class="{navAct: selectedTab === 'summary'}" class="navBtn">Summary</div>
        </div>

        <!-- Empty placeholder to keep centered, cannot see opponents waivers -->
        <div v-if="league.roster1.myTeam && league.matchup.hasWaivers" style="width: 130px;"></div>

        <div class="navBtnContainer" style="margin-left: 15px;">
          <div @click="setSelectedTab('stats2')" :class="{navAct: selectedTab === 'stats2'}" class="navBtn">Stats</div>
        </div>
        <div class="navBtnContainer" style="margin-left: 15px;">
          <div @click="setSelectedTab('schedule2')" :class="{navAct: selectedTab === 'schedule2'}" class="navBtn">Lineup</div>
        </div>
      </div>
    </template>

    <!-- Main Content -->
    <div class="ob-box" style="min-width: 100%; box-sizing: border-box; display: inline-block;" v-if="league != null && !swappingTeams">
      <!-- Waivers -->
      <WaiversView v-if="selectedTab === 'waivers'" :leagueData="league" />

      <!-- Schedule / Lineup -->
      <TeamSchedule v-if="selectedTab === 'schedule1'"
        :leagueData="league" :leagueRoster="league.roster1" :isUsersTeam="league.roster1.myTeam"
        :playedGames="team1AllWeekPlayedGames" :agByPosition="team1AvailableGames" :rosterGR="team1RosterGR"
        :rosterPossibleGR="team1RosterPossibleGR"
      />
      <TeamSchedule v-if="selectedTab === 'schedule2'"
        :leagueData="league" :leagueRoster="league.roster2" :isUsersTeam="league.roster2.myTeam"
        :playedGames="team2AllWeekPlayedGames" :agByPosition="team2AvailableGames" :rosterGR="team2RosterGR"
        :rosterPossibleGR="team2RosterPossibleGR"
      />

      <!-- Stats -->
      <MatchupStatsTable v-if="selectedTab === 'stats1'" :roster="league.roster1" :league="league"
        :isMyTeam="league.roster1.myTeam" :score="league.matchup.team1Score" :selectedDay="selectedDay"
        :playedGames="team1PlayedGames" :agByPosition="team1AvailableGames" :rosterGR="team1RosterGR"
        :rosterPossibleGR="team1RosterPossibleGR" :scoringStats="scoringStats"
      />
      <MatchupStatsTable v-if="selectedTab === 'stats2'" :roster="league.roster2" :league="league"
        :isMyTeam="league.roster2.myTeam" :score="league.matchup.team2Score" :selectedDay="selectedDay"
        :playedGames="team2PlayedGames" :agByPosition="team2AvailableGames" :rosterGR="team2RosterGR"
        :rosterPossibleGR="team2RosterPossibleGR" :scoringStats="scoringStats"
      />

      <!-- Summary -->
      <div v-if="selectedTab === 'summary'" class="matchupSummary">
        <div class="matchupSummaryTeam">
          <MatchupSummaryTable :league="league" :roster="league.roster1" :selectedDay="selectedDay"
            :playedGames="team1PlayedGames" :isMyTeam="league.roster1.myTeam"
          />
        </div>
        <div class="matchupSummaryTeam">
          <MatchupSummaryTable :league="league" :roster="league.roster2" :selectedDay="selectedDay"
            :playedGames="team2PlayedGames" :isMyTeam="league.roster2.myTeam"
          />
        </div>
      </div>
    </div>

    <TeamSwapView v-else-if="swappingTeams"
      :toDrop="toDrop"
      :leagueData="league"
      :rosterPossibleGR="team1RosterPossibleGR"
    />
    <DraftSummaryModalView :loading="draftLoading" />
    <TransactionsModalView />
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObDraftApi from '@/api/ObDraftApi';
import MatchupHeader from './Header/MatchupHeader.vue';
import MatchupSummaryTable from './MatchupSummary/MatchupSummaryTable.vue';
import MatchupStatsTable from './MatchupStats/MatchupStatsTable.vue';
import MatchupStats from './Header/MatchupStats.vue';
import MatchupDaySelector from './Header/MatchupDaySelector.vue';
import DraftSummaryModalView from '@/views/Draft/DraftSummaryModal/DraftSummaryModalView';
import TeamSchedule from './MatchupSchedule/TeamSchedule.vue';
import TeamSwapView from './TeamSwap/TeamSwapView.vue';
import TransactionsModalView from '@/views/Contest/TransactionsModal/TransactionsModalView';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';
import WaiversView from './Waivers/WaiversView.vue';
import {mapState} from 'vuex';

export default {
  name: 'ContestMatchup',

  components: {
    DraftSummaryModalView,
    TeamSchedule,
    TeamSwapView,
    TransactionsModalView,
    MatchupHeader,
    MatchupStatsTable,
    MatchupDaySelector,
    MatchupStats,
    MatchupSummaryTable,
    ObLoading,
    WaiversView,
  },

  data: function() {
    return {
      league: null,
      leagueId: null,
      matchId: null,
      week: 1,
      selectedDay: null,
      showDropAddModal: false,
      toDropId: null,
      toDrop: null,
      swappingTeams: false,
      draftLoading: false,

      scoringStats: [],
      gameLimits: {},
      maxGames: 0,
      weekPrize: 0,

      team1Roster: {},
      team1Totals: {},
      team1Fpts: {},
      team1Score: 0,
      team1GP: 0,
      team1WeekGP: 0,
      team1PossibleGP: 0,
      team1RosterGR: {},
      team1RosterPossibleGR: {},
      team1PlayedGames: {},
      team1AllWeekPlayedGames: {},
      team1Proj: '0',
      team1AvailableGames: {},

      team2Roster: {},
      team2Totals: {},
      team2Fpts: {},
      team2Score: 0,
      team2GP: 0,
      team2WeekGP: 0,
      team2PossibleGP: 0,
      team2RosterGR: {},
      team2RosterPossibleGR: {},
      team2PlayedGames: {},
      team2AllWeekPlayedGames: {},
      team2Proj: '0',
      team2AvailableGames: {},

      transactions: [],
      selectedTab: 'summary',

      socketSubbed: false,
    };
  },

  watch: {
    '$route.params.matchId'(to, from) {
      this.matchId = this.$route.params.matchId;
      if (to != from && (!this.league || !this.league.matchup || this.league.matchup.id != to)) {
        this.loadPage();
      }
    },

    '$route.params.leagueId'(to, from) {
      this.leagueId = this.$route.params.leagueId;
      EventBus.$emit('LEAGUE_LOADED', this.$route.params.leagueId);
      if (to != from) {
        this.loadPage();
      }
    },

    '$route.params.playerId'(to, from) {
      this.toDropId = this.$route.params.playerId;
      this.setPlayerId();
    },

    '$route.params.selectedTab'(to) {
      this.selectedTab = to;
    },
  },

  computed: {
    ...mapState(['userId']),
  },

  created() {
    this.leagueId = this.$route.params.leagueId;
    this.matchId = this.$route.params.matchId;
    this.toDropId = this.$route.params.playerId;
    this.loadPage();

    EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$on('RELOAD_MATCHUP_PAGE', this.loadPage);
    EventBus.$on('MATCHUP_SELECT_DAY', this.selectDay);
    EventBus.$on('PLAYER_DEACTIVATED', this.recalculateValues);
    EventBus.$on('COMPONENT_ADD_DROP_OPEN', this.openAddDrop);
    EventBus.$on('CANCEL_SWAPPING_TEAMS', this.cancelSwap);
  },

  mounted() {
    EventBus.$emit('LEAGUE_LOADED', this.$route.params.leagueId);
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('DASHBOARD_' + this.userId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('RELOAD_MATCHUP_PAGE', this.loadPage);
    EventBus.$off('MATCHUP_SELECT_DAY', this.selectDay);
    EventBus.$off('PLAYER_DEACTIVATED', this.recalculateValues);
    EventBus.$off('COMPONENT_ADD_DROP_OPEN', this.openAddDrop);
    EventBus.$off('CANCEL_SWAPPING_TEAMS', this.cancelSwap);
    EventBus.$emit('LEAGUE_UNLOADED');
  },

  methods: {
    openContestChat() {
      EventBus.$emit('OPEN_LEAGUE_CHAT', this.leagueId, false);
    },

    openDraftSummary() {
      this.draftLoading = true;

      ObDraftApi.loadDraft(this.league.draftId, false)
          .then((data) => {
            this.draftLoading = false;
            EventBus.$emit('OPEN_DRAFT_SUMMARY_MODAL', data.draft, data.teamrosters, data.users, data.authUser, false);
          });
    },

    // Function that we can use for EventBus.$on and EventBus.$off
    loadPage() {
      this.loadMatchup();
      this.selectedTab = this.$route.params.selectedTab;
    },

    onSocketBroadcast(data) {
      if (data != null && data.type === 'FSP_DASHBOARD') {
        const id = data.json.id;
        if (id === this.leagueId) {
          this.loadMatchup();
        }
      }
    },

    openTransactionsModal() {
      EventBus.$emit('OPEN_TRANSACTIONS_MODAL', this.league);
    },

    openBracketModal() {
      EventBus.$emit('SHOW_BRACKET_MODAL', this.league);
    },

    openAddDrop(entry) {
      this.swappingTeams = true;
      this.toDrop = entry;
    },

    cancelSwap() {
      this.swappingTeams = false;
      this.toDrop = null;
      // TODO - figure out url fix, this causes matchup to reload
      this.$router.push({params: {leagueId: this.leagueId, matchId: this.matchId, playerId: null}}).catch((err) => {});
    },

    setPlayerId() {
      const playerId = this.toDropId;

      if (playerId == null) {
        this.cancelSwap();
        return;
      }

      let player = null;
      if (this.league.roster1.myTeam) {
        this.league.roster = this.league.roster1;
        player = this.league.roster1.players.find((p) => {
          return p.id == playerId;
        });
      } else if (this.league.roster2.myTeam) {
        this.league.roster = this.league.roster2;
        player = this.league.roster2.players.find((p) => {
          return p.id == playerId;
        });
      }

      if (player != null) {
        this.swappingTeams = true;
        this.toDrop = player;
      } else {
        this.$router.push({params: {leagueId: this.league.id, matchId: this.league.matchup.id, playerId: null}});
      }
    },

    setSelectedTab(tabName) {
      this.$router.push({params: {selectedTab: tabName}});
    },

    loadMatchup() {
      ObLeagueApi.getLeagueMatchup(this.leagueId, this.matchId)
          .then((data) => {
            if (!this.socketSubbed) {
              this.socketSubbed = true;
              this.$SocketController.subscribeToRoom('DASHBOARD_' + this.userId);
            }

            this.league = data;
            if (data.matchup && data.matchup.week) {
              this.week = data.matchup.week;
            }
            if (data.bracket) {
              this.winnerPrize = data.bracket[this.week - 1].winnerPrize;
            }
            this.gameLimits = data.gameLimits;
            this.maxGames = data.gameLimits.total;
            this.scoringStats = this.$ScoringStats.getMatchupSummaryStatsList(data.sport);

            this.team1Roster = data.roster1;
            this.team2Roster = data.roster2;
            this.setUsedGames(1, this.team1Roster);
            this.setUsedGames(2, this.team2Roster);
            this.team1PossibleGP = this.getPossibleGP(1);
            this.team2PossibleGP = this.getPossibleGP(2);

            this.team1RosterGR = this.getRosterGR(1);
            this.team2RosterGR = this.getRosterGR(2);
            this.team1RosterPossibleGR = this.getPossibleRosterGR(1);
            this.team2RosterPossibleGR = this.getPossibleRosterGR(2);

            this.team1Proj = this.calcWeekProjection(this.team1Roster);
            this.team2Proj = this.calcWeekProjection(this.team2Roster);

            this.team1AvailableGames = this.calcAvailableGames(this.team1Roster);
            this.team2AvailableGames = this.calcAvailableGames(this.team2Roster);
            this.toDrop = null;
            this.swappingTeams = false;

            this.setPlayerId();
          })
          .catch((_error) =>{
            // Do nothing
          });
    },

    recalculateValues() {
      if (this.team1Roster && this.team2Roster) {
        this.team1Proj = this.calcWeekProjection(this.team1Roster);
        this.team2Proj = this.calcWeekProjection(this.team2Roster);

        this.team1AvailableGames = this.calcAvailableGames(this.team1Roster);
        this.team2AvailableGames = this.calcAvailableGames(this.team2Roster);

        this.team1RosterGR = this.getRosterGR(1);
        this.team2RosterGR = this.getRosterGR(2);
      }
    },

    calcAvailableGames(roster) {
      const fullRoster = roster.players.concat(roster.dropped);
      const agByPosition = {};
      for (const player of fullRoster) {
        let ag = agByPosition[player.draftPos] || 0;
        ag += this.sumPlayedOrRemaining(player);
        agByPosition[player.draftPos] = ag;
      }
      return agByPosition;
    },

    sumPlayedOrRemaining(player) {
      const games = player.fspGames;
      if (!games) {
        return 0;
      }
      let gp = 0;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameDate = new Date(gJSON.date);
        if (gJSON.inRange && gJSON.played && !this.gameDeactivated(player, gJSON)) {
          gp += 1;
        } else if (gJSON.inRange && gameDate >= today && !this.gameDeactivated(player, gJSON) && !gJSON.played) {
          gp += 1;
        }
      }
      return gp;
    },
    sumRemaining(player) {
      const games = player.fspGames;
      if (!games) {
        return 0;
      }
      let gp = 0;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameDate = new Date(gJSON.date);
        if (gJSON.inRange && gameDate >= today && !this.gameDeactivated(player, gJSON) && !gJSON.played &&
          (gJSON.ProjPoints == null || gJSON.ProjPoints.total != 0)
        ) {
          gp += 1;
        }
      }
      return gp;
    },
    sumPlayed(player, teamNum) {
      let playedGames = null;
      const games = player.fspGames;
      if (!games) {
        return 0;
      }
      let gp = 0;
      const today = new Date();
      if (teamNum == 1) {
        playedGames = this.team1PlayedGames;
      } else {
        playedGames = this.team2PlayedGames;
      }
      today.setHours(0, 0, 0, 0);

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const playedGameIndex = player.id + '_' + gJSON.id;
        if (gJSON.inRange && gJSON.played && !this.gameDeactivated(player, gJSON) && playedGames[playedGameIndex]) {
          gp += 1;
        }
      }
      return gp;
    },

    // Calculate overall projection
    calcWeekProjection(roster) {
      // Current points + remaining projected points
      // Apply game limits by position
      const fullRoster = roster.players.concat(roster.dropped);
      const gamesRemaining = {};
      const projByPosition = {};
      const fptsByPosition = {};

      const gLimits = this.gameLimits;
      for (const pos in gLimits) {
        if (pos !== 'total') {
          gamesRemaining[pos] = gLimits[pos];
          projByPosition[pos] = [];
          fptsByPosition[pos] = [];
        }
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      for (const entry of fullRoster) {
        // For Each game add projections
        // Factor in deactivated games
        for (const gameKey in entry.fspGames) {
          const game = entry.fspGames[gameKey];
          if (!game.inRange || this.gameDeactivated(entry, game)) {
            continue;
          }
          const ts = parseInt(game.timestamp);
          const gJSON = {};
          gJSON.ts = ts;

          if (game.played) {
            if (game.ObPoints) {
              gJSON.fpts = game.ObPoints.total;
            }
            if (game.ProjPoints) {
              gJSON.proj = game.ProjPoints.total;
            }
            if (game.ObPoints && game.ProjPoints) {
              gamesRemaining[entry.draftPos] -= 1;
              fptsByPosition[entry.draftPos].push(gJSON);
            }
          } else if (ts > today.getTime() && game.ProjPoints) {
            gJSON.proj = game.ProjPoints.total;
            projByPosition[entry.draftPos].push(gJSON);
          }
        }
      }

      this.sortAndLimitGames(fptsByPosition, gLimits);
      this.sortAndLimitGames(projByPosition, gamesRemaining);

      // Sum Actual Points
      let totalProj = 0;
      for (const pos in fptsByPosition) {
        for (const gJSON of fptsByPosition[pos]) {
          totalProj += gJSON.fpts || 0;
        }
      }

      // Add Game Projections
      for (const pos in projByPosition) {
        for (const gJSON of projByPosition[pos]) {
          totalProj += gJSON.proj || 0;
        }
      }

      return totalProj.toFixed(0);
    },

    gameDeactivated(player, game) {
      return player.deactGames[game.id] != null;
    },

    addPlayerValidGames(player, validGames, getSum = false, day = this.selectedDay) {
      const games = player.fspGames;

      if (day != null && getSum === false) {
        if (games[day - 1] != null) {
          const gJSON = games[day - 1];
          this.addGameIfValid(gJSON, validGames, player);
        }
        if (games[(day - 1) + 'DH'] != null) {
          const gJSON = games[(day - 1) + 'DH'];
          this.addGameIfValid(gJSON, validGames, player);
        }
      } else {
        for (const gameIndex in games) {
          if (day == null || gameIndex.charAt(0) < day) {
            const gJSON = games[gameIndex];
            this.addGameIfValid(gJSON, validGames, player);
          }
        }
      }
    },

    addGameIfValid(gJSON, validGames, player) {
      const pos = player.draftPos;
      if (gJSON.inRange && gJSON.played &&
          !this.gameDeactivated(player, gJSON)) {
        const entry = {};
        entry.id = player.id + '_' + gJSON.id;
        entry.ts = parseInt(gJSON.timestamp);
        entry.proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : null;
        entry.fpts = gJSON.ObPoints ? gJSON.ObPoints.total : null;
        entry.ObPoints = gJSON.ObPoints;
        entry.stats = gJSON.stats;

        if (validGames[pos] == null) {
          validGames[pos] = [];
        }
        validGames[pos].push(entry);
      }
    },

    setUsedGames(teamNum, teamRoster) {
      const teamInfo = this.calcUsedGames(teamNum, teamRoster);
      if (teamNum === 1) {
        this.team1Score = teamInfo.totalPoints;
        this.team1Totals = teamInfo.totals;
        this.team1Fpts = teamInfo.fpoints;
        this.team1GP = teamInfo.totalGP;
        this.team1WeekGP = teamInfo.weekGP;
        this.team1PlayedGames = teamInfo.teamPlayedGames;
        this.team1AllWeekPlayedGames = teamInfo.allWeekTeamPlayedGames;
      } else {
        this.team2Score = teamInfo.totalPoints;
        this.team2Totals = teamInfo.totals;
        this.team2Fpts = teamInfo.fpoints;
        this.team2GP = teamInfo.totalGP;
        this.team2WeekGP = teamInfo.weekGP;
        this.team2PlayedGames = teamInfo.teamPlayedGames;
        this.team2AllWeekPlayedGames = teamInfo.allWeekTeamPlayedGames;
      }
    },

    calcUsedGames(teamNum, teamRoster, getSum = false, day = this.selectedDay) {
      const gameLimit = this.gameLimits;

      // Create list of valid games (Played, not deactivated, not dropped)
      // Order by time, then projected points
      // {id:PLAYERID_GAMEID, ts: timestamp, pos: F, proj: 12.40 }
      const validGames = {};
      const allWeekValidGames = {};

      for (const pIndex in teamRoster.players) {
        const player = teamRoster.players[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
        // Setup allWeekValidGames array
        for (const gameIndex in player.fspGames) {
          const gJSON = player.fspGames[gameIndex];
          this.addGameIfValid(gJSON, allWeekValidGames, player);
        }
      }

      for (const pIndex in teamRoster.dropped) {
        const player = teamRoster.dropped[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
        // Setup allWeekValidGames array
        for (const gameIndex in player.fspGames) {
          const gJSON = player.fspGames[gameIndex];
          this.addGameIfValid(gJSON, allWeekValidGames, player);
        }
      }

      // Sort and Limit validGames
      this.sortAndLimitGames(validGames, gameLimit);
      this.sortAndLimitGames(allWeekValidGames, gameLimit);

      // Create lookup table
      const teamPlayedGames = {};
      for (const pos in validGames) {
        const gamesByPos = validGames[pos];
        for (const game of gamesByPos) {
          const id = game.id;
          teamPlayedGames[id] = true;
        }
      }

      // Create lookup table
      const allWeekTeamPlayedGames = {};
      let weekTotalGP = 0;
      for (const pos in allWeekValidGames) {
        const gamesByPos = allWeekValidGames[pos];
        for (const game of gamesByPos) {
          const id = game.id;
          allWeekTeamPlayedGames[id] = true;
          weekTotalGP += 1;
        }
      }

      // Calc total points
      const totals = {};
      const fpoints = {};

      let totalPoints = new Decimal(0);
      let gamesPlayed = 0;

      // Totals for selected day (Or week if no day selected)
      for (const pos in validGames) {
        const gamesByPos = validGames[pos];
        for (const game of gamesByPos) {
          gamesPlayed += 1;
          totalPoints = totalPoints.plus(new Decimal(game.fpts));

          // -- Totals --
          const stats = game.stats;
          const fpts = game.ObPoints;

          for (const path of this.scoringStats) {
            if (totals[path] == null) {
              totals[path] = new Decimal(0);
            }
            if (fpoints[path] == null) {
              fpoints[path] = new Decimal(0);
            }
            totals[path] = totals[path].plus(new Decimal(this.getValue(stats, path, '0')));
            fpoints[path] = fpoints[path].plus(new Decimal(this.getValue(fpts, path, '0')));
          }
        }
      }

      return {
        totalPoints: totalPoints,
        totalGP: gamesPlayed,
        weekGP: weekTotalGP,
        totals: totals,
        fpoints: fpoints,
        teamPlayedGames: teamPlayedGames,
        allWeekTeamPlayedGames: allWeekTeamPlayedGames,
      };
    },

    sortAndLimitGames(games, gameLimit) {
      for (const pos in games) {
        let gamesByPos = games[pos];
        let limit = gameLimit[pos];

        if (limit < 0) {
          limit = 0;
        }

        gamesByPos.sort(function(a, b) {
          if (a.proj != 0 && b.proj == 0) {
            return -1;
          } else if (b.proj != 0 && a.proj == 0) {
            return 1;
          }
          // Sort by game timestamp
          if (a.ts > b.ts) {
            return 1;
          } else if (b.ts > a.ts) {
            return -1;
          }
          // Secondary sort by proj (highest takes proirity)
          if (a.proj > b.proj) {
            return -1;
          } else if (b.proj > a.proj) {
            return 1;
          }
          return 0;
        });

        gamesByPos = gamesByPos.slice(0, limit);
        games[pos] = gamesByPos;
      }
    },

    getPossibleGP(teamNum) {
      let totalGames = 0;
      const totalGameCategories = {};
      let teamRoster = {};

      if (teamNum === 1) {
        teamRoster = this.team1Roster;
      } else if (teamNum === 2) {
        teamRoster = this.team2Roster;
      } else {
        return 0;
      }
      for (const playerIndex in teamRoster.players) {
        const player = teamRoster.players[playerIndex];
        if (!totalGameCategories[player.draftPos]) {
          totalGameCategories[player.draftPos] = 0;
        }

        for (const gameIndex in player.fspGames) {
          const game = player.fspGames[gameIndex];
          if (player.deactGames[game.id] === undefined &&
            game.inRange &&
            (!game.result || game.played) &&
            totalGameCategories[player.draftPos] < this.gameLimits[player.draftPos]) {
            totalGameCategories[player.draftPos]++;
            totalGames++;
          }
        }
      }
      for (const playerIndex in teamRoster.dropped) {
        const player = teamRoster.dropped[playerIndex];
        for (const gameIndex in player.fspGames) {
          const game = player.fspGames[gameIndex];
          if (player.deactGames[game.id] === undefined &&
            game.inRange &&
            (!game.result || game.played) &&
            totalGameCategories[player.draftPos] < this.gameLimits[player.draftPos]) {
            totalGameCategories[player.draftPos]++;
            totalGames++;
          }
        }
      }
      if (totalGames > this.maxGames) {
        return this.maxGames;
      }
      return totalGames;
    },

    getRosterGR(teamNum) {
      let roster = null;
      if (teamNum == 1) {
        roster = this.team1Roster;
      } else {
        roster = this.team2Roster;
      }
      const fullRoster = roster.players.concat(roster.dropped);
      const grByPosition = {};
      for (const player of fullRoster) {
        let ag = grByPosition[player.draftPos] || 0;
        ag += this.sumRemaining(player);
        grByPosition[player.draftPos] = ag;
      }
      return grByPosition;
    },
    getPossibleRosterGR(teamNum) {
      let roster = null;
      if (teamNum == 1) {
        roster = this.team1Roster;
      } else {
        roster = this.team2Roster;
      }
      const fullRoster = roster.players.concat(roster.dropped);
      const grByPosition = {};
      for (const player of fullRoster) {
        let ag = grByPosition[player.draftPos] || 0;
        ag = ag + this.sumPlayed(player, teamNum);
        grByPosition[player.draftPos] = ag;
      }
      for (const pos in grByPosition) {
        grByPosition[pos] = this.gameLimits[pos] - grByPosition[pos];
      }
      return grByPosition;
    },

    selectDay(dayNum) {
      this.selectedDay = dayNum;
      this.setUsedGames(1, this.team1Roster);
      this.setUsedGames(2, this.team2Roster);
    },

    // Utility JSON tool
    getValue: function(json, path, defaultValue) {
      if (defaultValue === undefined) {
        defaultValue = '-';
      }
      if (json == null || path == null) {
        return defaultValue;
      }
      try {
        return path.split('.').reduce(function(obj, i) {
          if (obj[i] == null || obj[i] == '') {
            return defaultValue;
          }
          return obj[i];
        }, json);
      } catch (e) {
        // Do nothing
      }
      return defaultValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.swappingTeamsPage {
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-out;
}

.loadingBox {
  text-align: center;
  padding: 20px;
}

.topBarButton {
  background: var(--obcolor-background-6);
  color: var(--obcolor-font-secondary);
  font-weight: bold;
  border-radius: 6px;
  font-size: 12px;
  height: 27px;
  margin-right: 6px;
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.topBarButton:hover {
  background: var(--obcolor-background-2);
}

.transactionContainer {
  margin-left: 15px;
  width: 300px;
  min-width: 300px;
  height: 261px;
}

.transactionTitle {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.transactionList {
  height: calc(100% - 10px); overflow: auto;
}

.transaction {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 10px;
  border-bottom: 1px solid var(--obcolor-background-2);
}

.transactionNameDate {
  margin-left: 10px; text-align: center;
}

.transactionPlayers {
  text-align: center;
  flex: 1;
}

.statsDayContainer {
  display: flex;
  justify-content: space-between;
}

.navbar {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  height: 55px;
  padding-left: 5px;
  padding-right: 5px;
}

.summaryBtn {
  flex: 1; text-align: center;
}

.navBtn {
  background: var(--obcolor-background-6);
  display: inline-block;
  color: var(--obcolor-font-secondary);
  padding: 6px 17px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
}

.navAct {
  color: white !important;
  background: var(--obcolor-ob-blue);
}

.navBtn:hover {
  color: var(--obcolor-ob-blue);
}

.matchupSummary {
  display: flex;
}

.matchupSummaryTeam {
  flex: 1;
}

.matchupSummaryTeam:first-child {
  margin-right: 10px;
  padding-right: 10px;
}
</style>