<template>
  <div class="slateContainer" v-if="slate" style="margin-bottom: 5px;">
    <!-- Slate Header -->
    <div class="slateHeader" :class="{headerExpanded: expanded}" @click="expandSlate()">
      <img :src="getContestBadge(slate.sport)" :alt="slate.sport" class="sportBadge">
      <div class="slateNameInfo headerInfo">
        <div>{{ slate.name }}</div>
        <div v-if="!slateIsLive">{{ $moment(slate.contestStart).format('dddd MMM D, h:mma') }}</div>
        <div v-else class="liveText greenText">Live</div>
      </div>
      <div style="flex: 1;"></div>
      <div class="headerInfo">
        <div>{{ slate.numLineups }}</div>
        <div>Lineups</div>
      </div>
      <div class="headerInfo">
        <div>{{ slate.numEntries }}</div>
        <div>Entries</div>
      </div>
      <div class="headerInfo">
        <div>
          <span v-if="slate.entryFees">
            ${{ formatCurrency(slate.entryFees) }}
          </span>
          <span v-if="slate.entryFees && slate.ownersbucksEntryFees"> + </span>
          <span v-if="slate.ownersbucksEntryFees" class="blueText">
            <img :src="require('@/assets/icons/ownersbucks.png')"
              class="ownersbucksIcon" alt="OwnersBucks Icon"
            >
            {{ formatOwnersbucks(slate.ownersbucksEntryFees) }}
          </span>
          <span v-if="!slate.entryFees && !slate.ownersbucksEntryFees">$0</span>
        </div>
        <div>Entry Fees</div>
      </div>
      <div class="headerInfo" style="min-width: 90px;">
        <div>
          <span v-if="liveData.winnings" class="greenText">
            <img class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
              alt="Money Bag"
            >
            ${{ formatCurrency(liveData.winnings) }}
          </span>
          <span v-if="liveData.winnings && liveData.obWinnings"> + </span>
          <span v-if="liveData.obWinnings" class="blueText">
            <img :src="require('@/assets/icons/ownersbucks.png')"
              class="ownersbucksIcon" alt="OwnersBucks Icon"
            >
            {{ formatOwnersbucks(liveData.obWinnings) }}
          </span>
          <span v-if="!liveData.winnings && !liveData.obWinnings" class="greenText">
            $0
          </span>
        </div>
        <div>Winning{{ isCompleted ? 's' : '' }}</div>
      </div>
      <div class="dropdownBtn">
        <i class="fas" :class="expanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      </div>
    </div>

    <div v-if="expanded">
      <div v-for="lineup, index in slate.lineups" :key="'lineup' + index" class="lineupContainer">
        <!-- Lineup Details -->
        <div class="lineupHeader">
          <div class="headerInfo">
            <div>{{ lineup.numContests }}</div>
            <div>Contests</div>
          </div>
          <div class="headerInfo">
            <div>{{ lineup.numEntries }}</div>
            <div>Entries</div>
          </div>
          <div class="headerInfo">
            <div>${{ lineup.roster.salaryRem }}</div>
            <div>Rem Salary</div>
          </div>
          <div class="headerInfo" v-if="!slateStarted">
            <div>{{ lineup.roster.proj }}</div>
            <div>Projected</div>
          </div>
          <div class="headerInfo" v-else>
            <div class="blueText">{{ liveData.lineups[getRosterId(lineup)].score }}</div>
            <div>Points</div>
          </div>
          <div class="headerInfo" v-if="slateStarted">
            <div>
              <span v-if="liveData.lineups[getRosterId(lineup)].winnings" class="greenText">
                <img class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
                  alt="Money Bag"
                >
                ${{ formatCurrency(liveData.lineups[getRosterId(lineup)].winnings) }}
              </span>
              <span v-if="liveData.lineups[getRosterId(lineup)].winnings && liveData.lineups[getRosterId(lineup)].obWinnings"> + </span>
              <span v-if="liveData.lineups[getRosterId(lineup)].obWinnings" class="blueText">
                <img :src="require('@/assets/icons/ownersbucks.png')"
                  class="ownersbucksIcon" alt="OwnersBucks Icon"
                >
                {{ formatOwnersbucks(liveData.lineups[getRosterId(lineup)].obWinnings) }}
              </span>
              <span v-if="!liveData.lineups[getRosterId(lineup)].winnings && !liveData.lineups[getRosterId(lineup)].obWinnings" class="greenText">
                $0
              </span>
            </div>
            <div>Winning{{ isCompleted ? 's' : '' }}</div>
          </div>
          <div style="flex: 1;"></div>
          <div class="rightSideButtons">
            <router-link v-if="lineup.lineupId" class="ob-btn-grey lineupBtn" :to="'/salarycap/edit-lineup/' + lineup.lineupId">Edit Lineup</router-link>
            <div v-if="!slateStarted" class="ob-btn-grey lineupBtn" @click="openQuickEntry(lineup)">Enter Contests</div>
          </div>
        </div>

        <!-- Roster -->
        <div class="rosterContainer">
          <div v-for="p in lineup.roster.players" :key="p.id" class="playerInfo">
            <div class="headshotPosContainer">
              <div class="posText">{{ p.draftPos }}</div>
              <PlayerHeadshot size="50" :id="p.id" :sport="slate.sport"
                :team="p.player.team" :showLogo="true"
              />
            </div>
            <div class="playerName" @click="openPlayerCard(p.id)">
              <PlayerStatus height="11" :status="p.fspGames[0] ? p.fspGames[0].rollingStatus : null" class="statusLight" />
              {{ p.player.name.shortName() }}<br>
            </div>
            <div class="gameSummary">
              {{ $SportInfo.getGameSummary(slate.sport, fspOrLive(p.fspGames[0]), p.player.teamId, $moment, true, true, false) }}
            </div>
          </div>
        </div>

        <!-- Contest Info -->
        <div class="contestsContainer">
          <div v-if="lineup.numEntries > 0" class="viewEntriesBtn" @click="toggleViewEntries(lineup)">
            <div>{{ viewingEntries(lineup) ? 'Hide' : 'View' }} Entries</div>
            <i class="fas" :class="{'fa-chevron-down': !viewingEntries(lineup), 'fa-chevron-up': viewingEntries(lineup)}"></i>
          </div>
          <SlateContestTable v-if="viewingEntries(lineup)" :contests="contests" :lineup="lineup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import PlayerStatus from '@/components/PlayerStatus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import SlateContestTable from '@/views/SalaryCapLineups/SlateContestTable';

export default {

  components: {
    PlayerStatus,
    PlayerHeadshot,
    SlateContestTable,
  },

  props: {
    slate: {type: Object, default: null},
    teams: {type: Object, default: null},
    expanded: {type: Boolean, default: false},
    isCompleted: {type: Boolean, default: false},
    lastCheckedTs: {type: Number, default: null},
  },

  data() {
    return {
      contests: [],
      viewEntries: {},
    };
  },

  created() {
    if (this.expanded) {
      this.getContests();
    }
    EventBus.$on('RELOAD_LINEUPS_PAGE', this.reloadContests);
    EventBus.$on('SOCKET_BROADCAST', this.handleSocketUpdate);
    this.$SocketController.subscribeToRoom('FSP_LOBBY');
  },

  destroyed() {
    EventBus.$off('RELOAD_LINEUPS_PAGE', this.reloadContests);
    EventBus.$off('SOCKET_BROADCAST', this.handleSocketUpdate);
    this.$SocketController.unsubscribeFromRoom('FSP_LOBBY');
  },

  computed: {
    ...mapState(['rankNum', 'todaysGames']),

    liveData() {
      const totals = {
        placing: 0,
        winnings: 0,
        obWinnings: 0,
        lineups: {},
      };
      for (const lineup of this.slate.lineups) {
        this.addLiveDataForLineup(lineup, totals);
      }
      return totals;
    },

    // Timestamp is updated every second to check, since $moment() is not reactive
    slateStarted() {
      return this.slate.firstGameTime < this.lastCheckedTs;
    },

    slateIsLive() {
      const todayTs = this.lastCheckedTs;
      return this.slate.contestStart < todayTs && todayTs < this.slate.contestEnd;
    },
  },

  watch: {
    expanded(newVal) {
      if (newVal) {
        this.getContests();
      }
    },
  },

  methods: {
    addLiveDataForLineup(lineup, totals) {
      // Sum live data for this lineup
      // Add to the totals json to get the slate live data
      const lineupTotals = {
        placing: 0,
        winnings: 0,
        obWinnings: 0,
        score: 0,
      };

      for (const teamId of lineup.teams) {
        const team = this.teams[teamId];
        if (team) {
          lineupTotals.score = team.score;
          if (team.prizeValue > 0) {
            totals.placing += 1;
            lineupTotals.placing += 1;
          }
          if (team.prize.charAt(0) == '$') {
            totals.winnings += team.prizeValue;
            lineupTotals.winnings += team.prizeValue;
          } else {
            totals.obWinnings += team.prizeValue;
            lineupTotals.obWinnings += team.prizeValue;
          }
        }
      }

      this.$set(totals.lineups, this.getRosterId(lineup), lineupTotals);
    },

    fspOrLive(fspGame) {
      if (!fspGame) {
        return null;
      }
      return this.todaysGames[fspGame.id] || fspGame;
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.slate.sport);
    },

    expandSlate() {
      EventBus.$emit('EXPAND_SLATE', this.slate.id);
    },

    getContestBadge(sport) {
      return require('@/assets/contestbadge/' + sport + '.png');
    },

    reloadContests() {
      // Reload contest data after quick-entry
      // In case we've entered a new contest that wasn't originally in the list
      if (this.expanded) {
        this.getContests(true);
      }
    },

    getContests(reload = false) {
      if (!reload) {
        this.viewEntries = {};
      }
      ObSalaryCapApi.getEnteredContestsForSlate(this.slate.id)
          .then((response) => {
            this.contests = response.contests;
          }).catch((_error) => {
            // Do nothing
          });
    },

    formatCurrency(cents) {
      const dollars = cents.centsToDollars();
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(dollars).removeCentsIfZero();
    },

    formatOwnersbucks(cents) {
      if (cents == 0) {
        return '';
      }
      const dollars = cents.centsToDollars();
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(dollars).removeCentsIfZero();
    },

    getRosterId(lineup) {
      if (!lineup?.roster?.players) {
        return null;
      }
      let idString = '';
      for (const pIndex in lineup.roster.players) {
        const p = lineup.roster.players[pIndex];
        if (pIndex > 0) {
          idString += '_';
        }
        idString += p.id;
      }
      return idString;
    },

    viewingEntries(lineup) {
      const rId = this.getRosterId(lineup);
      return this.viewEntries[rId];
    },

    toggleViewEntries(lineup) {
      const rId = this.getRosterId(lineup);
      if (this.viewEntries[rId] == null) {
        this.$set(this.viewEntries, rId, true);
      } else {
        this.$set(this.viewEntries, rId, !this.viewEntries[rId]);
      }
    },

    handleSocketUpdate(data) {
      if (!data.json || !data.UPDATE_TYPE) {
        return;
      }
      if (data.UPDATE_TYPE !== 'lobby_update' && data.UPDATE_TYPE !== 'lobby_update_sc') {
        return;
      }
      if (data?.json?.rookieLeague && this.rankNum != 1) {
        return;
      }
      this.socketUpdateContests(data);
    },

    socketUpdateContests(data) {
      const contestId = data.json.id;
      const batchId = data?.json?.userCreatedBatchId;
      let index = null;

      // For grouped user created contests, match the contest using batch id and entry fee
      // The contest id can change as the grouped contests start to fill
      if (batchId) {
        const entryFee = data?.json?.fee;
        index = this.getContestIndexForBatch(batchId, entryFee);
      } else {
        index = this.getContestIndexForId(contestId);
      }

      if (index != null) {
        // Merge in my entries, if data exists
        // My entry data is not included in lobby socket updates since data is sent to all users
        const existingContest = this.contests[index];
        if (existingContest.myEntries != null) {
          data.json.myEntries = existingContest.myEntries;
        }
        if (existingContest.myEntriesRemaining != null) {
          data.json.myEntriesRemaining = existingContest.myEntriesRemaining;
        }

        // Update json in contest list
        this.$set(this.contests, index, data.json);
      }
    },

    getContestIndexForId(contestId) {
      for (let i=0; i < this.contests.length; i++) {
        if (this.contests[i].id === contestId) {
          return i;
        }
      }
      return null;
    },

    getContestIndexForBatch(batchId, fee) {
      for (let i=0; i < this.contests.length; i++) {
        const contest = this.contests[i];
        if (contest.userCreatedBatchId === batchId && contest.fee === fee) {
          return i;
        }
      }
      return null;
    },

    openQuickEntry(lineup) {
      const sport = this.slate?.sport;
      const players = lineup?.roster?.players;
      EventBus.$emit('OPEN_LINEUP_QUICK_ENTRY', sport, players, this.slate?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.slateContainer {
  min-width: 1000px;
}

.slateHeader {
  background: var(--obcolor-background-4);
  padding: 10px;
  margin-bottom: 2px;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--obcolor-background-2);
  }
}

.liveText {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: relative;
    display: block;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: var(--obcolor-green);
  }
}

.headerInfo.slateNameInfo {
  text-align: left;
}

.headerInfo {
  text-align: center;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  > :first-child {
    font-weight: bold;
  }

  > :last-child {
    color: var(--obcolor-font-secondary);
  }
}

.dropdownBtn {
  font-size: 20px;
  padding-right: 10px;
}

.headerExpanded {
  background: var(--obcolor-background-2);
}

.lineupContainer {
  background: var(--obcolor-background-6);
  padding: 10px 10px 0 10px;
  margin-bottom: 2px;
}

.lineupHeader {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--obcolor-background-2);

  .rightSideButtons {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.sportBadge {
  width: 60px;
  height: 50px;
}

.rosterContainer {
  display: flex;
  justify-content: space-around;

  .playerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;
    font-size: 14px;
  }

  .headshotPosContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;

    .posText {
      // Make text right align to headshot container, and expand to the left
      position: absolute;
      width: 0;
      right: 55px;
      display: flex;
      justify-content: flex-end;
      text-align: center;
      font-weight: bold;
      color: var(--obcolor-font-secondary);
      margin-bottom: 10px;
    }
  }

  .playerName {
    margin-top: 2px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .gameSummary {
    color: var(--obcolor-font-secondary);
  }

  .statusLight {
    margin-left: -5px;
    margin-top: -1px;
    margin-right: 4px;
  }
}

.contestsContainer {
  padding: 10px 0;
}

.contestInfo {
  display: flex;
  gap: 20px;
  padding: 5px;
}

.greenText {
  color: var(--obcolor-green) !important;
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}

.moneyIcon {
  height: 18px;
  margin-bottom: -3px;
}

.ownersbucksIcon {
  height: 10px;
}

.viewEntriesBtn {
  cursor: pointer;
  color: var(--obcolor-font-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;

  &:hover {
    color: var(--obcolor-font-primary);
  }
}

.lineupBtn {
  font-size: 14px;
}
</style>