<template>
  <div class="scoreBoardContainer" v-if="gameIsLiveOrComplete">
    <h1 class="sectionHeader">Scoreboard</h1>
    <div class="scoreBoardTableContainer">
      <table class="scoreBoardTable scoreBoardLeftColumn" aria-label="Game Score Board (Team Score)">
        <tr class="scoreBoardHeaderRow">
          <th class="scoreBoardPeriodHeader">{{ sportsPeriod[this.sport].header }}</th>
        </tr>
        <tr class="scoreBoardAwayRow">
          <td>
            <div class="scoreBoardTeamName">
              <img class="teamImg" :src="getTeamLogo('visitor_team')" alt="Team Logo">
              {{ getTeamAlias(gameData.matchup.visitor_team) }}
            </div>
          </td>
        </tr>
        <tr class="scoreBoardHomeRow">
          <td>
            <div class="scoreBoardTeamName">
              <img class="teamImg" :src="getTeamLogo('home_team')" alt="Team Logo">
              {{ getTeamAlias(gameData.matchup.home_team) }}
            </div>
          </td>
        </tr>
      </table>
      <div class="scoreBoardPeriods ob-scroll">
        <table class="scoreBoardTable" aria-label="Game Score Board (Team Score)">
          <tr class="scoreBoardHeaderRow">
            <th v-for="periodLabel in periodLabels"
              :key="'period_' + periodLabel"
              :class="sport !== 'MLB' ? 'nonMLBWidth' : 'MLBWidth'"
            >
              {{ periodLabel }}
            </th>
          </tr>
          <tr class="scoreBoardAwayRow">
            <td v-for="(score, index2) of scoresArray.awayteam" :key="'away_team_score_' + index2">
              {{ score === 'null' ? '-' : score }}
            </td>
            <td v-if="displayAdditionalTiedColumn">
              {{ this.gameData.matchup.live.awayteam.score - scoresArray.awayteam.reduce((a,b) => parseInt(a)+parseInt(b), 0) }}
            </td>
            <td v-for="index3 in (Math.max(0, sportsPeriod[this.sport].amount - scoresArray.awayteam.length))"
              :key="'away_team_score_missing_' + index3"
            >
              -
            </td>
          </tr>
          <tr class="scoreBoardHomeRow">
            <td v-for="(score, index2) of scoresArray.hometeam" :key="'home_team_score_' + index2">
              {{ score === 'null' ? '-' : score }}
            </td>
            <td v-if="displayAdditionalTiedColumn">
              {{ this.gameData.matchup.live.hometeam.score - scoresArray.hometeam.reduce((a,b) => parseInt(a)+parseInt(b), 0) }}
            </td>
            <td v-for="index3 in (Math.max(0, sportsPeriod[this.sport].amount - scoresArray.hometeam.length))"
              :key="'home_team_score_missing_' + index3"
            >
              -
            </td>
          </tr>
        </table>
      </div>
      <table class="scoreBoardTable" aria-label="Game Score Board (Team Score)">
        <tr class="scoreBoardHeaderRow">
          <th :class="sport !== 'MLB' ? 'nonMLBWidth' : 'MLBWidth'">{{ this.sport === 'MLB' ? 'R' : 'T' }}</th>
          <th class="MLBWidth" v-if="this.sport === 'MLB'">H</th>
          <th class="MLBWidth" v-if="this.sport === 'MLB'">E</th>
        </tr>
        <tr class="scoreBoardAwayRow">
          <td>
            {{ gameData.matchup.live.awayteam.score ? gameData.matchup.live.awayteam.score : 0 }}
          </td>
          <td>
            {{ gameData.matchup.liveStats.awayteam.hits }}
          </td>
          <td>
            {{ gameData.matchup.liveStats.awayteam.errors }}
          </td>
        </tr>
        <tr class="scoreBoardHomeRow">
          <td>
            {{ gameData.matchup.live.hometeam.score ? gameData.matchup.live.hometeam.score : 0 }}
          </td>
          <td>
            {{ gameData.matchup.liveStats.hometeam.hits }}
          </td>
          <td>
            {{ gameData.matchup.liveStats.hometeam.errors }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  data() {
    return {
      sportsPeriod: {
        'MLB': {header: 'Inning', liveField: 'inning', amount: 9},
        'NHL': {header: 'Period', liveField: 'period', amount: 3},
        'NBA': {header: 'Quarter', liveField: 'period', amount: 4},
        'NFL': {header: 'Quarter', liveField: 'period', amount: 4},
      },
      scoresArray: {
        awayteam: this.gameData?.matchup?.live?.awayteam?.scores !== '' ? this.gameData?.matchup?.live?.awayteam?.scores?.split(',') : [],
        hometeam: this.gameData?.matchup?.live?.hometeam?.scores !== '' ? this.gameData?.matchup?.live?.hometeam?.scores?.split(',') : [],
      },
      scoresPeriod: this.gameData?.matchup?.live?.period ? (parseInt(this.gameData?.matchup?.live?.period)) : 0,
    };
  },

  computed: {
    gameIsLiveOrComplete() {
      return this.gameData?.matchup?.live?.status === 'mid-event' || this.gameData?.matchup?.live?.status === 'post-event';
    },
    displayAdditionalTiedColumn() {
      return (this.sport === 'NFL' && this.scoresPeriod > this.sportsPeriod[this.sport].amount) || (this.sport === 'NHL' && this.scoresPeriod > this.sportsPeriod[this.sport].amount + 1);
    },
    periodLabels() {
      const maxPeriod = Math.max(this.sportsPeriod[this.sport].amount, this.scoresArray.awayteam.length, this.scoresPeriod);
      const periodNums = Array.from({length: maxPeriod}, (_, index) => index + 1);
      return periodNums.map((periodNum) => this.getPeriodLabel(periodNum));
    },
  },

  methods: {
    getTeamAlias(team) {
      return this.$TeamInfo.getDisplayAlias(this.sport, team.team_alias);
    },

    getTeamLogo(awayHome) {
      const teamName = this.gameData.matchup[awayHome].team_alias.toLowerCase();
      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ teamName +'.png');
    },

    getPeriodLabel(periodNum) {
      switch (this.sport) {
        case 'NHL': return this.getPeriodLabelNHL(periodNum);
        case 'NFL': case 'NBA': return this.getPeriodLabelNFLNBA(periodNum);
        case 'MLB': default: return periodNum;
      }
    },

    getPeriodLabelNHL(periodNum) {
      const isPostSeason = this.gameData?.matchup?.game_type === 'Post Season';
      if (periodNum <= 3) {
        return periodNum;
      }
      if (periodNum == 4) {
        return 'OT';
      }
      if (isPostSeason) {
        return 'OT' + (periodNum - 3);
      }
      return 'SO';
    },

    getPeriodLabelNFLNBA(periodNum) {
      if (periodNum <= 4) {
        return periodNum;
      }
      if (periodNum == 5) {
        return 'OT';
      }
      return 'OT' + (periodNum - 4);
    },
  },
};
</script>

<style lang="scss" scoped>
.scoreBoardContainer {
  margin: 0 20px;
  padding-bottom: 17px;
  margin-bottom: 19px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.scoreBoardTableContainer {
  display: flex;
}
.scoreBoardLeftColumn {
  flex: 1;
}
.scoreBoardTable {
  overflow: hidden;
  text-align: center;
  font-size: 14px;
}
.scoreBoardHeaderRow {
  border-bottom: 1px solid var(--obcolor-bg-hover);
  margin-bottom: 100px;
}
.scoreBoardPeriods {
  overflow-x: auto;
  margin-bottom: -7px;
}
.scoreBoardPeriodHeader {
  text-align: left;
  min-width: 250px;
  padding-left: 0;
}
th {
  color: var(--obcolor-font-secondary);
  padding: 10px;
}
.nonMLBWidth {
  width: 60px;
}
.MLBWidth {
  width: 25px;
}
td {
  height: 30px;
}
.scoreBoardTeamName {
  display: flex;
  align-items: center;
}
.scoreBoardAwayRow {
  padding-bottom: 10px;
}
.teamImg {
  height: 30px;
  margin-right: 5px;
  opacity: 0.8;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}
</style>