<template>
  <div v-if="(myBatterComputed != null && myBatterComputed != '')" class="batterList">
    <div v-for="batterIndex in numBatters" :key="batterIndex" class="batterCircle"
      :class="getCircleClass(batterIndex)"
    >
      <div class="currentArrow" v-if="isAtBat(batterIndex)"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldState: {type: Object, default: null},
    myBatterIndex: {type: String, default: null},
    currentBatterIndex: {type: String, default: null},
  },

  data() {
    return {
      numBatters: 9,
    };
  },

  computed: {
    myBatterComputed() {
      if (this.fieldState && this.fieldState.myBatterIndex != null) {
        return this.fieldState.myBatterIndex;
      }
      return this.myBatterIndex;
    },

    currentBatterComputed() {
      if (this.fieldState && this.fieldState.currentBatterIndex != null) {
        return this.fieldState.currentBatterIndex;
      }
      return this.currentBatterIndex;
    },
  },

  methods: {
    getCircleClass(batterIndex) {
      const isMyBatter = this.myBatterComputed != null && Number(this.myBatterComputed) == batterIndex;
      const isAtBat = this.isAtBat(batterIndex);

      if (isMyBatter && isAtBat) {
        return 'orangeCircle';
      } else if (isMyBatter) {
        return 'blueCircle';
      }

      return '';
    },

    isAtBat(batterIndex) {
      if (!batterIndex) {
        return false;
      }
      return this.currentBatterComputed != null && Number(this.currentBatterComputed) == batterIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.batterList {
  display: flex;
  height: 10px;
}

.batterCircle {
  height: 6px;
  width: 6px;
  margin-right: 2px;
  border-radius: 3px;
  background-color: var(--obcolor-background-1);

  &.blueCircle {
    background-color: var(--obcolor-ob-blue);
  }
  &.orangeCircle {
    background-color: #F15A25;
  }

  .currentArrow {
    margin-top: 7px;
    margin-left: -1px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--obcolor-font-secondary);
  }
}
</style>