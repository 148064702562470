<template>
  <div class="ob-page">
    <ObLoading v-if="processing" :size="80" />
    <i v-else-if="isSuccess" class="statusIcon successIcon fal fa-check-circle"></i>
    <i v-else class="statusIcon errorIcon fal fa-times-circle"></i>
    <div class="integrationStatus">
      <div v-if="processing">
        Linking to {{ integrationMap[integrationName] }}
      </div>
      <div v-else-if="isSuccess">
        Account linked successfully to {{ integrationMap[integrationName] }}
      </div>
      <div v-else>
        Failed to link {{ integrationMap[integrationName] }} account
      </div>
    </div>
    <div v-if="!processing && isSuccess" class="subText">You may close this window</div>
    <div v-else-if="!processing" class="subText">{{ errorMessage }}</div>
  </div>
</template>

<script>
import ObIntegrationsApi from '@/api/ObIntegrationsApi';
import ObLoading from '@/components/ObLoading.vue';
export default {
  components: {ObLoading},

  data() {
    return {
      integrationCode: null,
      integrationName: null,

      isSuccess: null,
      processing: true,
      errorMessage: null,

      integrationMap: {
        kroger: 'Kroger',
      },
    };
  },

  created() {
    this.integrationName = this.$route.params?.integrationName;
    this.integrationCode = this.$route.query?.code;
    this.saveCodeToUser();
  },

  methods: {
    saveCodeToUser() {
      this.processing = true;

      if (!this.integrationCode || !this.integrationName) {
        this.isSuccess = false;
        this.processing = false;
        return;
      }

      ObIntegrationsApi.saveUserCode(this.integrationName, this.integrationCode)
          .then((response) => {
            this.isSuccess = true;
            this.processing = false;
            this.$store.commit('setKrogerId', response.krogerId);
          }).catch((e) => {
            if (e?.response?.data) {
              this.errorMessage = e.response.data;
            }
            this.isSuccess = false;
            this.processing = false;
          });
    },

    closeTab() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  gap: 10px;
}
.integrationStatus {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

.statusIcon {
  font-size: 40px;
  margin-bottom: 10px;
  &.successIcon {
    color: var(--obcolor-green);
  }
  &.errorIcon {
    color: var(--obcolor-red);
  }
}

.subText {
  font-size: 16px;
  color: var(--obcolor-font-secondary);
}
</style>