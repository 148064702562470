import { render, staticRenderFns } from "./BattlePassNotification.vue?vue&type=template&id=5b5161bd&scoped=true"
import script from "./BattlePassNotification.vue?vue&type=script&lang=js"
export * from "./BattlePassNotification.vue?vue&type=script&lang=js"
import style0 from "./BattlePassNotification.vue?vue&type=style&index=0&id=5b5161bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5161bd",
  null
  
)

export default component.exports