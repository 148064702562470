<template>
  <div>
    <canvas ref="chart" :width="size" :height="size"></canvas>
    <!-- Very minor hack lets me use computed colours in canvas -->
    <div v-for="(cName, index) in chartColor" :key="index" :ref="cName"
      style="display: none" :style="'color: ' + cName"
    ></div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  props: {
    chartId: String,
    slices: Array,
    size: {type: String, default: '22'},
    // Example:
    // [
    //   { color: 'red', percent: '50' },
    //   { color: (background color), percent: '50' }
    // ]
  },

  mounted() {
    this.renderChart();
  },

  computed: {
    ...mapState(['theme']),

    chartData() {
      if (!this.slices) {
        return [];
      }
      return this.slices.map((slice) => slice.percent);
    },

    chartColor() {
      if (!this.slices) {
        return [];
      }
      return this.slices.map((slice) => slice.color);
    },

    // Get colour values from color-coded hidden refs
    scssColors() {
      if (this.chartColor == null) {
        return [];
      }

      return this.chartColor.map((cName) => {
        const cElement = this.$refs[cName];
        if (cElement && cElement[0] && cElement[0]) {
          const el = cElement[0];
          const cElementStyle = window.getComputedStyle(el, null);
          return cElementStyle.getPropertyValue('color');
        } else {
          return '';
        }
      });
    },
  },

  watch: {
    chartData() {
      this.renderChart();
    },

    chartColor() {
      this.renderChart();
    },

    theme() {
      this.renderChart();
    },
  },

  methods: {
    renderChart() {
      const canvas = this.$refs.chart;
      const ctx = canvas.getContext('2d');
      // lastend of 0 starts at the very right. Rotate 0.75 of 360 to start at the top
      let lastend = Math.PI*1.5;
      const data = this.chartData;
      let total = 0;
      const color = this.scssColors;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 0.5;

      for (const value of data) {
        total += value;
      }

      const bgColor = this.theme === 'dark' ? '#474F6D' : '#C4C4C4';

      for (let i = 0; i < data.length; i++) {
        ctx.fillStyle = (this.slices[i].color == 'background' ? bgColor : color[i]);
        ctx.beginPath();
        ctx.moveTo(canvas.width/2, canvas.height/2);
        ctx.arc(canvas.width/2, canvas.height/2, canvas.height/2, lastend, lastend+(Math.PI*2*(data[i]/total)), false);
        ctx.lineTo(canvas.width/2, canvas.height/2);
        ctx.fill();
        lastend += Math.PI*2*(data[i]/total);
      }
    },
  },
};
</script>