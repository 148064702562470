<template>
  <div class="entryContainer" v-if="entry">
    <router-link :to="getContestLink()" class="selectContest">
      <!-- Section showing title, contest info, etc -->
      <div class="contentSection titleSection">
        <div class="llLogo">
          <img :src="require('@/assets/contestbadge/lightning_lineups.png')" alt="Lightning Lineups">
        </div>
        <div>
          <div class="sportName">{{ entry.sport }}</div>
          <div v-if="entry.state != 'CANCELLED'" class="prizeText" :class="entry.realMoney ? 'greenText' : 'blueText'">
            Prize:
            <img v-if="!entry.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
              style="height: 8px;"
            >
            {{ entry.payout.removeCentsIfZero() }}
          </div>
          <div v-else class="prizeText redText">Cancelled</div>
          <div class="entryText">
            Entry: {{ entry.fee.removeCentsIfZero() }}
          </div>
          <div class="payoutLineupRemoved entryText">
            <span>Payout:</span>
            <span v-if="hasNewMultiplier()" class="payoutCrossout">{{ entry.originalPayoutMultiplier }}x</span>
            <span>{{ entry.payoutMultiplier }}x</span>
          </div>
          <div class="entryText">{{ getContestStart() }}</div>
          <div v-if="!hasContestStarted" class="shareContest" @click.prevent="openInviteModal()">Share Contest</div>
        </div>
      </div>

      <!-- Section showing live data such as position, remaining games, points, winnings -->
      <div v-if="entry.state != 'CANCELLED'" class="contentSection liveSection">
        <!-- 2 columns to lay out data -->
        <div class="infoRow">
          <!-- Current position in contest -->
          <div class="infoField">
            <template v-if="userTeam && userTeam.rank != null">
              <div v-if="userTeam.rank == 1 && !userTeam.isTied" style="color: var(--obcolor-trophy-1st)"><i class="fas fa-trophy"></i></div>
              <div v-else-if="userTeam.rank > 0">{{ getRank(userTeam) }}</div>
              <div v-else>-</div>
            </template>
            <div class="infoTitle">Position</div>
          </div>
          <!-- Display current points -->
          <div class="infoField">
            <div class="infoText pointsText" :style="getUpdateStyle()" :class="{redText: userTeam.fpts < 0}">{{ userTeam.fpts.toFixed(2) }}</div>
            <div class="infoTitle">Points</div>
          </div>
        </div>
        <div class="infoRow">
          <!-- Display played/upcoming games in pie chart with label -->
          <div class="infoField" v-if="!isCompleted">
            <div v-if="userTeam && userTeam.players">
              <PieChart size="18" :slices="getTeamPieSlices(userTeam)" />
            </div>
            <div class="infoTitle" v-if="userTeam && userTeam.players">
              {{ playersByGameStatus(userTeam).upcoming.length }}/{{ userTeam.players.length }} Rem.
            </div>
          </div>
          <!-- Display winnings if user is in 1st -->
          <div class="infoField">
            <div class="greenText infoText" :class="entry.realMoney ? 'greenText' : 'blueText'">
              {{ userWinnings }}
            </div>
            <div class="infoTitle">Winning{{ isCompleted ? 's' : '' }}</div>
          </div>
        </div>
      </div>
      <!-- Replaces info section if contest cancelled -->
      <div v-else class="contentSection liveSection">
        <div class="cancelledText redText">Cancelled</div>
        <div class="cancelledSubText redText">{{ entry.cancellationNotes }}</div>
      </div>
    </router-link>
    <!-- Section showing user's team -->
    <div class="contentSection playerSection">
      <template v-if="userTeam && userTeam.players">
        <!-- Show each player - Headshot, name, position/team, proojection/points -->
        <div v-for="(player, index) in userTeam.players" :key="index" class="playerContainer"
          @click="openPlayerCard(player.id)"
        >
          <!-- Show all info in a centered column -->
          <div class="playerInfo">
            <PlayerHeadshot :id="player.id" :sport="sport" :team="player.teamAlias"
              size="45" :showLogo="true" class="playerHeadshot"
            />
            <div class="playerName">
              {{ player.name.shortName() }}
            </div>
            <div class="playerPosition">
              {{ player.position }} - {{ getTeamAlias(player) }}
            </div>
            <template v-if="entry.state != 'CANCELLED'">
              <div v-if="gameStarted(player) || isCompleted" class="myPlayerGame playerPoints" :class="{redText: player.game.fpts < 0}">
                {{ player.game.fpts != null ? player.game.fpts.toFixed(2) : '0.00' }}
              </div>
              <div v-else class="myPlayerGame playerProj">
                {{ player.game.projection != null ? player.game.projection : '0.00' }}
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>

    <!-- Section showing opposing CPU teams -->
    <div class="contentSection teamsSection">
      <LiveLLTeamsList :entry="entry" :isCompleted="isCompleted" />
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import LiveLLTeamsList from './LiveLLTeamsList';
import EventBus from '@/event-bus';

export default {
  props: {
    entry: {type: Object, default: null},
    scoreUpdated: {type: Object, default: null},
    isCompleted: {type: Boolean, default: false},
  },

  components: {
    PieChart,
    PlayerHeadshot,
    LiveLLTeamsList,
  },

  computed: {
    userTeam() {
      if (!this.entry || !this.entry.userTeam) {
        return null;
      }
      return this.entry.userTeam;
    },

    sport() {
      if (!this.entry || !this.entry.sport) {
        return null;
      }
      return this.entry.sport;
    },

    userWinnings() {
      if (!this.entry || !this.entry.userWinningsFormatted) {
        return '0';
      }
      return this.entry.userWinningsFormatted.removeCentsIfZero();
    },

    hasContestStarted() {
      if (!(this.entry?.contestStart)) {
        return true;
      }

      return this.$moment().diff(this.$moment(this.entry.contestStart), 'seconds') >= 0;
    },
  },

  methods: {
    getContestStart() {
      if (!this.entry) {
        return '';
      }
      const dateCheckFormat = 'MMM D YYYY';
      const contestStart = this.entry.contestStart;
      const contestDate = this.$moment(contestStart).format(dateCheckFormat);
      const todaysDate = this.$moment().format(dateCheckFormat);
      if (contestDate == todaysDate) {
        return 'Today, ' + this.$moment(contestStart).format('h:mma');
      }
      return this.$moment(contestStart).format('MMM D, h:mma');
    },

    getTeamAlias(player) {
      return this.$TeamInfo.getDisplayAlias(this.sport, player.teamAlias);
    },

    getUpdateStyle() {
      if (this.scoreUpdated) {
        const status = this.scoreUpdated[this.entry.id];
        if (status === '+') {
          return 'color: var(--obcolor-green);';
        } else if (status === '-') {
          return 'color: var(--obcolor-red);';
        }
      }
      return '';
    },

    getRank(userTeam) {
      const tiedPrefix = userTeam.isTied ? 'T-' : '';
      return tiedPrefix + String(userTeam.rank).addSuffix();
    },

    playersByGameStatus(team) {
      const playerGameStatus = {
        upcoming: [],
        completed: [],
        // Catch all for players who don't have games or games don't have status
        unplayed: [],
      };
      if (!team && !team.players) {
        return playerGameStatus;
      }

      // Sort players into categories based on their game's status
      for (const player of team.players) {
        if (player.game && player.game.gameCompleted === true) {
          playerGameStatus.completed.push(player);
        } else if (player.game && player.game.gameCompleted === false) {
          playerGameStatus.upcoming.push(player);
        } else {
          playerGameStatus.unplayed.push(player);
        }
      }
      return playerGameStatus;
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_LL', this.entry);
    },

    getTeamPieSlices(team) {
      const slices = [];
      const teamInfo = this.playersByGameStatus(team);
      const teamSize = team.players ? team.players.length : 0;
      const pct = Math.round( teamInfo.upcoming.length / teamSize * 100 );

      const baseColor = 'var(--obcolor-ob-blue)';
      const emptyColor = 'var(--obcolor-background-2)';

      slices.push({color: emptyColor, percent: 100 - pct});
      slices.push({color: baseColor, percent: pct});
      return slices;
    },

    gameStarted(player) {
      if (!player || !player.game) {
        return false;
      }
      return player.game.gameCompleted || new Date().getTime() > player.game.date;
    },

    openPlayerCard(id) {
      EventBus.$emit('OPEN_PLAYER_CARD', id, this.sport);
    },

    hasNewMultiplier() {
      return this.entry.originalPayoutMultiplier !== this.entry.payoutMultiplier;
    },

    getContestLink() {
      return '/lightning-lineups/live/' + this.entry.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid var(--obcolor-background-page);
}

.selectContest {
  display: flex;
  cursor: pointer;
}
.selectContest:hover {
  background: var(--obcolor-background-4);
}

.titleSection {
  width: 200px;
  min-width: 200px;
  justify-content: flex-start;
  padding-left: 15px;

  &:hover {
    background: var(--obcolor-background-4);
  }

  .llLogo {
    img {
      margin-left: -7px;
      margin-right: 13px;
      width: 52px;
    }
  }

  .sportName {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .prizeText {
    font-weight: bold;
  }

  .prizeText, .entryText, .payoutText {
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .entryText, .payoutText {
    color: var(--obcolor-font-secondary);
  }
}

.payoutLineupRemoved {
  font-size: 12px;
  display: flex;
  gap: 2px;
  color: var(--obcolor-font-secondary);
}

.payoutCrossout {
  text-decoration: line-through;
  margin: 0 0 3px 0;
}

.liveSection {
  width: 124px;
  min-width: 124px;
  flex-direction: column;
  justify-content: center;
  gap: 3px;

  .infoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .infoField {
    width: 60px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 8px;
  }

  .infoTitle {
    color: var(--obcolor-font-secondary);
    font-size: 14px;
    padding-top: 3px;
  }

  .infoText {
    font-weight: bold;
    font-size: 14px;
  }

  .ownersbucksIcon {
    height: 12px;
  }
}

.playerContainer {
  padding: 3px 0;
  cursor: pointer;
  border-radius: 4px;
}
.playerContainer:hover {
  background: var(--obcolor-background-4);
}

.playerSection {
  width: 320px;
  display: flex;
  justify-content: space-around;
  flex: 1;

  .playerInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: 14px;
  }

  .playerName {
    padding-top: 8px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
  }
  .playerPosition {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    text-transform: uppercase;
  }

  .myPlayerGame {
    padding-top: 2px;
    &.playerPoints {
      color: var(--obcolor-ob-blue);
      font-weight: bold;
    }
    &.playerProj {
      color: var(--obcolor-font-secondary);
    }
  }
}

.shareContest {
  color: var(--obcolor-font-primary);
  font-size: 12px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.contentSection.teamsSection {
  padding: 0;
  height: 100%;
  width: 295px;
  align-items: flex-start;
}

.cancelledText {
  font-size: 16px;
  font-weight: bold;
}

.cancelledSubText {
  font-size: 12px;
  text-align: center;
  margin-top: 2px;
}

.greenText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.redText {
  color: var(--obcolor-red) !important;
}

.pointsText {
  color: var(--obcolor-ob-blue);
  transition-duration: 400ms;
}
</style>