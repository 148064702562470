<template>
  <!-- scoringPlays for scoring summary -->
  <div v-if="sport !== 'NBA' && scoringPlaysToDisplay.length >= 1" class="scoringSummarySection">
    <h1 class="sectionHeader">Scoring Summary</h1>
    <template v-for="(period, index3) in playsPeriodMap.periodList">
      <div v-if="sport !== 'MLB'" class="periodPartition" :key="'header_' + index3">{{ period.label }}</div>
      <div v-if="hasNoScoring(period.num)" :key="'no_scoring_' + index3" class="noScoring">
        No Scoring
      </div>
      <div class="scoringSummary" v-for="(scoringPlay, index4) in playsPeriodMap.playsByPeriod[period.num]"
        :key="'scoring_play_' + index3 + '_' + index4"
      >
        <div class="scoringPlay" :class="{borderedSummary: !scorerHasPosition}">
          <div class="scoringPlayFlex">
            <div>
              <PlayerHeadshot size="45" v-if="getScorer(scoringPlay)" :id="getScorer(scoringPlay).id"
                :sport="sport" :team="getScorer(scoringPlay).team" :showLogo="true"
              />
              <PlayerHeadshot size="45" v-else :sport="sport"
                :showLogo="true"
              />
              <div v-if="scorerHasPosition" class="scorerPosition">
                {{ getScorer(scoringPlay) ? getScorerPosition(scoringPlay) : '' }}
              </div>
            </div>
            <div class="scoringPlayerScorer">
              <div v-if="getScorer(scoringPlay)" class="scoringPlayCentered">
                <span class="playerStatus">
                  <PlayerStatus height="12" status="ACT" />
                </span>
                <div class="ob-link" @click.stop="openPlayerCard(getScorer(scoringPlay).id)">
                  {{ getScorer(scoringPlay).name.shortName() }}
                </div>
                <p class="scoringPlayType" v-if="displayScoringPlayType(scoringPlay)"> - {{ displayScoringPlayType(scoringPlay) }}</p>
              </div>
              <div v-else-if="scoringPlay.stype === 'Safety'" style="color: var(--obcolor-font-secondary);">
                {{ scoringPlay.stype }}
              </div>
              <div v-else class="scoringPlayType">
                {{ scoringPlay.team_alias.toUpperCase() + ' ' + displayScoringPlayType(scoringPlay) }}
              </div>
              <p class="scoringPlaySubtext">{{ displayScoringPlaySubtext(scoringPlay) }}</p>
            </div>
          </div>
          <div class="scoringSummaryScore">
            {{ displayScore(scoringPlay) }}
            <p v-if="!isShootout(scoringPlay)" class="scoringSummaryScoreSubheader">
              {{ getLiveGameState(scoringPlay) }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <div v-if="showMoreEnabled" class="showMoreButton">
      <p class="ob-link" @click="clickShowMore()">
        {{ showMore ? 'Show Less...' : 'Show More...' }}
      </p>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  data() {
    return {
      showMore: false,
      scoringPlayScorer: {
        'MLB': 'batter',
        'NFL': 'scorer',
        'NHL': 'scorer',
      },
    };
  },

  created() {
    this.showMore = false;
  },

  computed: {
    isPostSeason() {
      return this.gameData?.matchup?.game_type === 'Post Season';
    },
    showMoreEnabled() {
      if (this.sport === 'MLB') {
        return this.scoringPlaysToDisplay.length > 3;
      } else {
        return this.maxPeriodPlayed > this.maxPeriodDisplayed || this.scoringPlaysToDisplay.length > 3;
      }
    },
    maxPeriodPlayed() {
      return this.gameData?.matchup?.live?.period ? (parseInt(this.gameData?.matchup?.live?.period)) : 0;
    },
    maxPeriodDisplayed() {
      return this.scoringPlaysToDisplay.slice(0, 3).reduce((maxPeriod, play) => {
        const period = play.period || play.inning || 1;
        return period > maxPeriod ? period : maxPeriod;
      }, 1);
    },
    scorerHasPosition() {
      return this.sport === 'NFL' || this.sport === 'NHL';
    },
    scoringPlaysWithBatter() {
      return this.sortedScoringPlays.filter((sp) => {
        return sp.batter != null;
      });
    },

    scoringPlaysWithoutPAT() {
      const filteredScoringPlays = [...this.sortedScoringPlays];
      for (let i = 1; i < filteredScoringPlays.length; i++) {
        if ((filteredScoringPlays[i].play_type === 'two-point-conversion' ||
          filteredScoringPlays[i].play_type === 'extra-point')
        ) {
          filteredScoringPlays[i - 1].extraPoint = {
            extraPointTaker: filteredScoringPlays[i].scorer,
            extraPointType: filteredScoringPlays[i].stype,
          };
          filteredScoringPlays[i - 1].filtered_score_away = filteredScoringPlays[i].score_away;
          filteredScoringPlays[i - 1].filtered_score_home = filteredScoringPlays[i].score_home;
        }
      }
      return filteredScoringPlays.filter((sp) => {
        return sp.play_type != 'two-point-conversion' && sp.play_type != 'extra-point';
      });
    },

    scoringPlaysToDisplay() {
      switch (this.sport) {
        case 'MLB':
          return this.scoringPlaysWithBatter;
        case 'NFL':
          return this.scoringPlaysWithoutPAT;
        case 'NHL':
          return this.sortedScoringPlays;
        default:
          return [];
      }
    },

    slicedScoringPlays() {
      return this.showMore ? this.scoringPlaysToDisplay : this.scoringPlaysToDisplay.slice(0, 3);
    },

    sortedScoringPlays() {
      const scoringPlays = [...this.gameData.scoringplays];
      const validScoringPlays = scoringPlays.filter((sp) => {
        return !sp.recalled;
      });

      if (this.sport === 'NFL' || this.sport === 'NHL') {
        return validScoringPlays.sort((a, b) => {
          return (parseInt(a.score_home) + parseInt(a.score_away)) - (parseInt(b.score_home) + parseInt(b.score_away));
        });
      } else {
        return validScoringPlays.sort((a, b) => {
          return (parseInt(b.score_home) + parseInt(b.score_away)) - (parseInt(a.score_home) + parseInt(a.score_away));
        });
      }
    },

    playsPeriodMap() {
      const playsByPeriod = {};
      const periodList = [];

      const addPeriod = (period) => {
        const periodName = this.$SportInfo.getPeriodLabel(period, this.sport, this.isPostSeason);
        periodList.push({num: period, label: periodName});
      };

      for (const play of this.slicedScoringPlays) {
        const period = play.period || play.inning || 1;
        if (!playsByPeriod[period]) {
          this.$set(playsByPeriod, period, []);
          if (this.sport === 'MLB') addPeriod(period);
        }
        playsByPeriod[period].push(play);
      }

      if (this.sport !== 'MLB') {
        const maxPeriod = (this.showMore && this.sport != 'MLB') ? this.maxPeriodPlayed : this.maxPeriodDisplayed;
        for (let period = 1; period <= maxPeriod; period++) {
          addPeriod(period);
        }
      }
      return {playsByPeriod: playsByPeriod, periodList: periodList};
    },
  },

  methods: {
    hasNoScoring(periodNum) {
      return !(this.playsPeriodMap.playsByPeriod[periodNum] && this.playsPeriodMap.playsByPeriod[periodNum].length !== 0);
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    sumArray(arrayStr) {
      const array = arrayStr.split(',');
      return array.map((str) => parseInt(str)).reduce((sum, curr) => sum + curr, 0);
    },

    displayScore(scoringPlay) {
      let awayScore = scoringPlay.filtered_score_away ? scoringPlay.filtered_score_away : scoringPlay.score_away;
      let homeScore = scoringPlay.filtered_score_home ? scoringPlay.filtered_score_home : scoringPlay.score_home;
      if (this.isShootout(scoringPlay)) {
        const tiedSum = this.sumArray(this.gameData.matchup.live?.awayteam?.scores);
        awayScore = awayScore - tiedSum;
        homeScore = homeScore - tiedSum;
      }
      return awayScore + '-' + homeScore;
    },

    getScorer(scoringPlay) {
      if (scoringPlay) {
        return scoringPlay[this.scoringPlayScorer[this.sport]];
      }
    },

    getLiveGameState(scoringPlay) {
      let gameInfo = '';
      if (this.sport === 'MLB') {
        if (scoringPlay.inning_half && scoringPlay.inning) {
          gameInfo += scoringPlay.inning_half === 'top' ? 'Top ' : 'Bottom ';
          gameInfo += scoringPlay.inning.addSuffix();
        }
        return gameInfo;
      }

      if (this.sport === 'NFL' && scoringPlay.time_remaining) {
        gameInfo += scoringPlay.time_remaining;
        return gameInfo;
      }

      if (this.sport === 'NHL' && scoringPlay.time_elapsed) {
        gameInfo += scoringPlay.time_elapsed;
        return gameInfo;
      }

      return gameInfo;
    },

    displayScoringPlayType(scoringPlay) {
      const scoringPlayType = [];
      switch (this.sport) {
        case 'MLB':
          return scoringPlay.play_type;
        case 'NFL':
          return scoringPlay.stypeDetailed ? scoringPlay.stypeDetailed : scoringPlay.stype;
        case 'NHL':
          if (scoringPlay.strength === 'power-play') {
            scoringPlayType.push('PP');
          } else if (scoringPlay.strength === 'short-handed') {
            scoringPlayType.push('SH');
          }
          if (scoringPlay.goal_type && scoringPlay.goal_type === 'empty-net') {
            scoringPlayType.push('EN');
          }
          return scoringPlayType.join(' ');
        default:
          return '';
      }
    },

    displayScoringPlaySubtext(scoringPlay) {
      switch (this.sport) {
        case 'MLB':
          return this.displayRuns(scoringPlay.runs);
        case 'NFL':
          return this.displayNFLScoreDetail(scoringPlay);
        case 'NHL':
          return this.displayNHLScoreDetail(scoringPlay);
        default:
          return '';
      }
    },

    displayRuns(runs) {
      const runsString = [];
      for (const run of runs) {
        runsString.push(run.name.shortName());
      }
      if (runsString.length === 1) {
        return runsString[0] + ' scores';
      } else if (runsString.length === 2) {
        return runsString.join(' and ') + ' score';
      } else if (runsString.length > 2) {
        runsString[runsString.length - 1] = 'and ' + runsString[runsString.length - 1];
        return runsString.join(', ') + ' score';
      } else {
        return 'No One Scored';
      }
    },

    displayNFLScoreDetail(scoringPlay) {
      if (!scoringPlay?.scorer?.name) return '';
      let resultStr = '';
      if (scoringPlay?.td_type === 'pass') {
        let scoringPlayDesc = scoringPlay?.desc ? scoringPlay?.desc : '';
        scoringPlayDesc = scoringPlay?.assister?.name ? scoringPlayDesc + ' ' : scoringPlayDesc.replace(' from', '');
        const assisterName = scoringPlay?.assister?.name?.shortName() ? scoringPlay?.assister?.name?.shortName() : '';
        resultStr += scoringPlay?.scorer?.name?.shortName() + ' ' + scoringPlayDesc + assisterName;
      } else if (scoringPlay?.stype === 'Safety') {
        return 'Safety Scored';
      } else {
        resultStr += scoringPlay?.scorer?.name?.shortName() + ' ' + scoringPlay?.desc;
      }

      if (!scoringPlay?.extraPoint?.extraPointTaker?.name) return resultStr;
      if (scoringPlay?.extraPoint?.extraPointType === 'Extra Point') {
        resultStr += '. ' + scoringPlay.extraPoint.extraPointTaker.name.shortName() + ' made PAT';
      } else if (scoringPlay?.extraPoint?.extraPointType === '2PT Conversion') {
        resultStr += '. ' + scoringPlay.extraPoint.extraPointTaker.name.shortName() + ' 2pt conversion';
      }
      return resultStr;
    },

    displayNHLScoreDetail(scoringPlay) {
      if (this.isShootout(scoringPlay)) {
        return '';
      }
      if (!scoringPlay?.assister1 && !scoringPlay?.assister2) {
        return 'Unassisted';
      }
      let resultStr = 'Assisted by ';
      if (scoringPlay?.assister1) {
        resultStr += scoringPlay?.assister1.name.shortName();
      }
      if (scoringPlay?.assister2) {
        resultStr += ' and ' + scoringPlay?.assister2.name.shortName();
      }
      return resultStr;
    },

    clickShowMore() {
      this.showMore = !this.showMore;
    },

    getScorerPosition(scoringPlay) {
      if (this.sport === 'NHL' && (scoringPlay.scorer?.position === 'RW' || scoringPlay.scorer?.position === 'LW' || scoringPlay.scorer?.position === 'C')) {
        return 'F';
      }
      return scoringPlay.scorer?.position;
    },

    isShootout(scoringPlay) {
      return this.sport === 'NHL' && !this.isPostSeason && parseInt(scoringPlay.period) === 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.scoringSummarySection {
  margin: 0px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.scoringSummary {
  margin-bottom: 10px;
}
.scoringPlay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 8px;
}
.periodPartition {
  color: var(--obcolor-font-secondary);
  margin-bottom: 10px;
  padding-bottom: 2px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.scoringPlayCentered {
  display: flex;
  align-items: center;
  padding-top: 3px;
}
.scorerPosition {
  text-align: center;
  margin-top: 2px;
  color: var(--obcolor-font-secondary);
}
.scoringPlayerScorer {
  margin-left: 20px;
  text-align: left;
}
.scoringPlayFlex {
  display: flex;
}
.scoringPlaySubtext {
  font-weight: initial;
  margin-top: 5px;
  margin-bottom: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}
.scoringSummaryScore {
  text-align: right;
  font-weight: bold;
}
.scoringSummaryScoreSubheader {
  font-weight: normal;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
}
.scoringPlayType {
  font-weight: initial;
  margin: 0;
  margin-left: 5px;
  color: var(--obcolor-font-secondary);
}
.showMoreButton {
  display: flex;
  justify-content: center;
  color: var(--obcolor-font-secondary);
}
.noScoring {
  display: flex;
  justify-content: center;
  color: var(--obcolor-font-secondary);
  margin: 10px 0;
}
.ob-link {
  margin: 0;
}

.borderedSummary {
  border-top: 1px solid var(--obcolor-bg-hover);
  padding-top: 10px;
}
</style>