<template>
  <div class="notificationAnimationContainer">
    <div class="notificationBox" :style="'--revealDuration: ' + revealDuration + '; --endDuration: ' + endDuration"
      :class="{revealing: isRevealPlaying, ending: isEndPlaying}" @click="goToBattlePass"
    >
      <div class="labelContainer">
        <img class="challengeIcon" :src="getChallengeBadge(shownInfo.icon, theme)" :alt="shownInfo.icon">
        <div class="progressLabel">{{ shownInfo.label }}</div>
        <div class="progressNum">{{ progressString }}</div>
      </div>
      <div class="progressBar">
        <div class="progress" :style="'width: ' + shownInfo.progressPercent + '%'">
          <div class="progress-pulse"></div>
          <NotificationConfetti v-if="playingConfetti" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationConfetti from '@/components/battlepass/notifications/NotificationConfetti';
import {getChallengeBadge} from '@/utils/shared/BattlePassUtils';
import {mapState} from 'vuex';

export default {
  components: {
    NotificationConfetti,
  },

  props: {
    notification: {type: Object, default: null},
  },

  data() {
    return {
      notificationTimeout: null,
      notificationRevealTimeout: null,
      notificationEndTimeout: null,
      confettiTimeout: null,

      showOldProgress: false,
      playingConfetti: false,

      revealDurationMs: 500,
      confettiWaitMs: 2000,
      endDurationMs: 500,
      notificationDurationMs: 8000,
    };
  },

  created() {
    this.playRevealAnimation();
  },

  destroyed() {
    clearTimeout(this.notificationTimeout);
    clearTimeout(this.notificationRevealTimeout);
    clearTimeout(this.notificationEndTimeout);
    clearTimeout(this.confettiTimeout);
    this.$emit('animationEnded', this.notification?.id);
  },

  computed: {
    ...mapState(['theme']),

    revealDuration() {
      return this.revealDurationMs + 'ms';
    },

    endDuration() {
      return this.endDurationMs + 'ms';
    },

    newInfo() {
      return this.notification?.new;
    },

    oldInfo() {
      return this.notification?.old;
    },

    shownInfo() {
      const info = {...this.newInfo};
      if (this.isRevealPlaying) {
        info.progressPercent = this.oldInfo.progressPercent;
      }

      return info;
    },

    isRevealPlaying() {
      return this.notificationRevealTimeout != null;
    },

    isEndPlaying() {
      return this.notificationEndTimeout != null;
    },

    progressString() {
      if (this.newInfo?.progressCurrent === undefined || this.newInfo?.progressGoal === undefined) {
        return '';
      }
      const newProgress = this.newInfo?.progressCurrent || 0;
      const newGoal = this.newInfo?.progressGoal || 0;
      return newProgress + '/' + newGoal;
    },
  },

  methods: {
    playRevealAnimation() {
      this.notificationRevealTimeout = setTimeout(() => {
        this.endRevealAnimation();
      }, this.revealDurationMs);
    },

    endRevealAnimation() {
      clearTimeout(this.notificationRevealTimeout);
      this.notificationRevealTimeout = null;
      this.notificationTimeout = setTimeout(() => {
        this.playRemoveAnimation();
      }, this.notificationDurationMs);
      this.confettiTimeout = setTimeout(() => {
        this.playConfettiAnimation();
      }, this.confettiWaitMs);
    },

    playConfettiAnimation() {
      if (this.shownInfo?.progressPercent == 100) {
        this.playingConfetti = true;
      }
    },

    playRemoveAnimation() {
      clearTimeout(this.notificationTimeout);
      this.notificationTimeout = null;
      this.notificationEndTimeout = setTimeout(() => {
        this.endRemoveAnimation();
      }, this.endDurationMs);
    },

    endRemoveAnimation() {
      this.$emit('animationEnded', this.notification?.id);
      clearTimeout(this.notificationEndTimeout);
      this.notificationEndTimeout = null;
    },

    goToBattlePass() {
      this.$router.push('/battlepass');
    },

    getChallengeBadge,
  },
};
</script>

<style lang="scss" scoped>
.notificationAnimationContainer {
  position: relative;
  width: 300px;
  height: 55px;
  margin-right: 5px;
}

.notificationBox {
  cursor: pointer;
  position: absolute;
  right: 0;
  background: var(--obcolor-background-2);
  width: 300px;
  height: 100%;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 3px black;
  font-size: 14px;
  white-space: nowrap;
  z-index: 2;

  &:hover {
    background: var(--obcolor-background-3);
  }

  &.revealing {
    animation: slide-in var(--revealDuration) linear forwards;
  }

  &.ending {
    animation: slide-out var(--endDuration) linear forwards;
  }
}

@keyframes slide-in {
  0% {
    right: -350px;
    animation-timing-function: ease-out;
  }

  100% {
    right: 0;
  }
}

@keyframes slide-out {
  0% {
    right: 0;
    animation-timing-function: ease-in;
  }

  100% {
    right: -350px;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  > div {
    white-space: nowrap;
    max-width: 100%;
  }

  .challengeIcon {
    width: 18px;
    padding-right: 8px;
  }

  .progressLabel {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .progressNum {
    color: var(--obcolor-font-secondary);
  }
}

.progressBar {
  $progress-size: 8px;
  background: var(--obcolor-background-1);
  height: $progress-size;
  border-radius: $progress-size;
  width: 100%;
  margin-top: 5px;

  .progress {
    position: relative;
    border-radius: $progress-size;
    height: 100%;
    background: var(--obcolor-ob-blue);

    transition: width 2s ease-in-out;
  }

  .progress-pulse {
    position: absolute;
    right: 0;
    width: $progress-size;
    height: $progress-size;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: var(--obcolor-ob-blue);
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
  }
}

@keyframes pulse-ring {
  0% {
    // transform: scale(1);
  }
  80%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>