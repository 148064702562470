<template>
  <tr @click.stop="$emit('selectPlayer', player)" :class="{ hoverable: !showPlus && !showMinus }" :style="showMinus ? 'background: rgba(229, 37, 44, 0.3);' : ''">
    <!-- Player Info -->
    <td>
      <div class="playerNameCol">
        <i v-if="showPlus" class="fa fa-plus"></i>
        <i v-else-if="showMinus" class="fa fa-minus"></i>
        <PlayerHeadshot :id="player.id" :sport="leagueData.sport" :team="player.team"
          :showLogo="true" size="50" :lazyLoad="true"
        />
        <div style="margin-left: 10px;">
          <div style="display: flex;">
            <div v-if="!statusMap" style="width: 16px; margin-top: 1px; color: var(--obcolor-font-secondary);">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
            <PlayerStatus v-else-if="!statusMap[player.id]" class="playerStatus" height="16px;"
              style="margin-top: -1px;" :status="'OUT'"
            />
            <PlayerStatus v-else class="playerStatus" height="16px;"
              style="margin-top: -1px;" :status="statusMap[player.id].status"
            />
            <div class="playerName ob-link" @click.stop="openPlayerCard(player.id)">{{ player.name.shortName() }}</div>
            <!-- Heat Icons -->
            <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold); margin-left: 5px;" class="fas fa-icicles"
              v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
            ></i>
            <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot); margin-left: 5px;" class="fab fa-hotjar"
              v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
            ></i>
          </div>
          <div class="playerInfo" style="margin-left: 17px;">{{ player.number ? "#" + player.number : "" }} {{ player.position }}</div>
        </div>
      </div>
    </td>

    <!-- Schedule -->
    <template v-if="selectedDataField == 'schedule'">
      <td v-if="leagueData.sport != 'NFL'">{{ player.wGP[selectedWeek] || 0 }}</td>
      <td>{{ player.wFPTS[selectedWeek] || 0 }}</td>
      <td v-if="leagueData.sport != 'NFL'">{{ player.wGR[selectedWeek] || 0 }}</td>
      <td>{{ player.wPROJ[selectedWeek] || 0 }}</td>
      <td class="endSection">{{ player.sAvg.toFixed(1) }}</td>

      <td v-for="(entry, index) in player.gamesByWeek[selectedWeek]" :key="index" style="padding: 0; min-width: 105px;">
        <div v-if="entry.opp">
          <div style="display: flex; align-items: center; justify-content: center;">
            <img v-if="entry.opp" style="height: 22px; margin-right: 4px; margin-left: -4px;" :src="getTeamLogo(entry.opp)"
              :alt="entry.opp"
            >
            <span v-if="entry.score">{{ entry.score }}</span>
            <span v-else-if="entry.ts" :style="gameHasStarted(entry.ts) ? 'color: red;' : ''">{{ parseInt(entry.ts) | moment("h:mma") }}</span>
          </div>
          <div style="height: 16px; margin-top: 4px; display: flex; align-items: center; justify-content: center;">
            <span v-if="entry.fpts" style="margin-left: -3px;" :style="entry.fpts >= 0 ? 'color: var(--obcolor-accent);' : 'color: red;'">
              {{ entry.fpts >=0 ? "+" : "" }}{{ entry.fpts }} PTS
            </span>
            <span v-else-if="entry.proj">Proj {{ entry.proj }}</span>
            <span v-else style="color: var(--obcolor-text-secondary);">0</span>
          </div>
        </div>
      </td>
    </template>

    <!-- Season Stats -->
    <template v-else>
      <template v-for="(category, index) in getScoringStats(toDrop)">
        <td v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
          :class="{endSection: index1 == category.stats.length - 1 && index != getScoringStats(toDrop).length - 1}"
        >
          {{ getStat(player, stat.path) }}
        </td>
      </template>
    </template>

    <!-- Width of scrollbar, for alignment with 2nd (scrollable) table -->
    <td v-if="addRightSpacer" style="width: 7px; padding: 0;"></td>
  </tr>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    player: Object,
    leagueData: Object,
    selectedDataField: String,
    statusMap: Object,
    selectedWeek: Number,
    toDrop: Object,
    showPlus: Boolean,
    showMinus: Boolean,
    addRightSpacer: Boolean,
  },

  methods: {

    gameHasStarted(timestamp) {
      return new Date().getTime() >= timestamp;
    },

    openPlayerCard(playerId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId, this.leagueData.sport, this.leagueData.id);
    },

    getScoringStats(player) {
      if (!player || !player.player || !player.player.position) {
        return {};
      }
      return this.$ScoringStats.getSeasonStats(this.leagueData.sport, player.player.position);
    },

    getStat(player, path) {
      if (!player || !player.statistics || !path) {
        return '-';
      }
      const statistics = player.statistics;
      const playerRole = path.substr(0, path.indexOf('.'));
      const statType = path.substr(path.indexOf('.') + 1);
      if (!statistics[playerRole]) {
        return '-';
      }
      const stat = statistics[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '-';
      }
      return stat;
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.leagueData.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

  },

};
</script>

<style lang="scss" scoped>

  th, td {
    padding: 10px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  tr th:not(:first-child) {
    text-align: center;
    width: 60px;
  }

  td:not(:first-child) {
    text-align: center;
    width: 60px;
  }

  th {
    color: var(--obcolor-font-secondary);
    background: var(--obcolor-navi-bar);
  }

  tr:not(:first-child).hoverable {
    cursor: pointer;
  }

  tr:not(:first-child).hoverable:hover td {
    background: var(--obcolor-background-4);
  }

  tr:not(:first-child) th:not(.unselectable) {
    cursor: pointer;
  }

  tr:not(:first-child) th:not(.unselectable):hover {
    background: var(--obcolor-background-2);
  }

  .playerListTable tr:not(.headerRow) {
    cursor: pointer;
  }
  .playerListTable tr:hover td {
    background: var(--obcolor-background-2) !important;
  }

  .categoryHeaderRow th {
    height: 23px;
    top: 0;
  }

  .columnHeaderRow th {
    height: 46px;
    top: 23px;
  }

  .playerListTable tr:nth-child(even) td {
    background: var(--obcolor-background-5);
  }

  .playerListTable tr:nth-child(odd) td {
    background: var(--obcolor-background-6);
  }

  .playerNameCol {
    display: flex;
    align-items: center;
  }

  .playerNameCol .fa {
    font-size: 18px;
    margin-right: 10px;
    &.fa-minus {
      color: var(--obcolor-red);
    }
    &.fa-plus {
      color: var(--obcolor-green);
    }
  }
  .playerNameCol .playerStatus {
    padding-right: 5px;
  }
  .playerNameCol .playerName {
    font-size: 14px;
  }

  .playerNameCol > div {
    justify-content: flex-start !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
    span {
      margin-right: 5px;
    }
  }

  .endSection {
    border-right: 4px solid var(--obcolor-background-3);
  }

</style>
