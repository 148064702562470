<template>
  <div class="stickyBox">
    <div class="ob-box selectedPlayerBox">
      <template v-if="player">
        <div style="width: 25px; margin-right: 10px; text-align: center;">{{ player.draftPos }}</div>
        <div class="headshotContainer">
          <PlayerHeadshot size="70" :id="player.id" :sport="contest.sport"
            :team="player.team" :showLogo="true"
          />
        </div>
        <div style="min-width: 140px; margin-left: 5px; margin-right: 10px; white-space: nowrap;">
          <div @click.stop="openPlayerCard()" class="ob-link" style="font-weight: bold;">{{ player.name.shortName() }}</div>
          <div class="playerInfo">
            <span>#{{ player.number }}</span>
            <span>{{ player.position }}</span>
            <span>{{ player.lineInfo }}</span>
          </div>
          <div class="statusLoading" v-if="!statusMap">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <PlayerStatus v-else-if="!statusMap[player.id]" :showText="true" height="12px;"
            :status="'OUT'"
          />
          <PlayerStatus v-else :showText="true" height="12px;"
            :status="statusMap[player.id].status"
          />
        </div>
        <div style="flex: 1;"></div>
        <table class="weekBox" aria-label="Selected Player">
          <tr>
            <th v-for="g in player.games" :key="g.date">
              <div>{{ g.day }}</div>
              <div v-if="g.date">{{ [g.date, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
          </tr>
          <tr>
            <td v-for="g in player.games" :key="g.date"
              class="draftGame" style="color: var(--obcolor-text-primary);"
              :style="g['gameDH'] ? 'width: 85px;' : ''"
            >
              <div class="gameContainer">
                <div style="flex: 1;" :style="g.excluded ? 'color: red;' : ''">
                  <div>{{ convertAlias(g.game) }}</div>
                  <div class="gameProj">{{ roundStat(g.proj) }}</div>
                </div>
                <div v-if="g.gameDH" :style="g.excludedDH ? 'color: red;' : ''" style="width: 2px; height: 24px; background: var(--obcolor-background-4);"></div>
                <div v-if="g.gameDH" style="flex: 1;">
                  <div>{{ g.gameDH }}</div>
                  <div class="gameProj">{{ roundStat(g.projDH) }}</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td v-for="g in player.games" :key="g.date"
              class="moneyText" style="padding-top: 5px; height: 15px;"
            >
              {{ g.game && !g.excluded && g.proj > 0 ? dollarFormat(player.salaryPG) : '' }}
            </td>
          </tr>
        </table>
        <div style="flex: 1;"></div>
        <div style="min-width: 180px; margin-left: 20px;">
          <div class="statRow">
            <div class="statField">
              <div class="statVal blueText">{{ getStat('proj') }}</div>
              <div class="statTitle">Projection</div>
            </div>
            <div class="statField">
              <div class="statVal moneyText">{{ playerCostText }}</div>
              <div class="statTitle">Salary</div>
            </div>
          </div>
          <div class="statRow">
            <div class="statField">
              <div class="statVal">{{ getStat('pg') }} / {{ getStat('ag') }}</div>
              <div class="statTitle">PG / AG</div>
            </div>
            <div class="statField">
              <div class="statVal">{{ player.sAvg.toFixed(1) }}</div>
              <div class="statTitle">sAVG</div>
            </div>
          </div>

          <div class="addButtonContainer">
            <button disabled class="ob-btn-grey" v-if="hasNoGames">No Games</button>
            <button class="ob-btn" v-else-if="!isPicked() && !picksFull()" @click="pickPlayer()">Add to Lineup</button>
            <button class="ob-btn-red" v-else-if="isPicked()" @click="removePlayer()">Remove from Lineup</button>
            <button disabled class="ob-btn-grey" v-else-if="picksFull()">Roster Full</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    player: Object,
    contest: Object,
    salaryMultiplier: Number,
    pickedPlayerIds: Object,
    picks: Object,
    totSpent: Number,
    statusMap: Object,
  },

  computed: {
    playerCost() {
      return this.player.salaryPG * (this.getStat('pg') || 0);
    },

    playerCostText() {
      return this.dollarFormat(this.playerCost);
    },

    hasNoGames() {
      return this.getStat('pg') === 0;
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },
  },

  methods: {
    getStat(path) {
      if (this.player && this.player.wStats && this.player.wStats[0]) {
        return this.player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    pickPlayer() {
      EventBus.$emit('SALARY_CAP_PICK_PLAYER', this.player);
    },

    removePlayer() {
      EventBus.$emit('SALARY_CAP_REMOVE_PLAYER', this.player);
    },

    canAfford() {
      return this.playerCost + this.totSpent < this.contest.salaryCap;
    },

    isPicked() {
      return this.pickedPlayerIds && this.pickedPlayerIds[this.player.id];
    },

    rosterAtPos() {
      const position = this.player.draftPos;
      return this.roster.find((r) => {
        return r.short == position;
      });
    },

    picksFull() {
      if (!this.player) {
        return false;
      }
      const rPos = this.rosterAtPos();
      return rPos.num <= this.picks[this.player.draftPos].length;
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    convertAlias(game) {
      if (!game) {
        return '';
      }
      if (game.indexOf('@') != -1) {
        return '@' + this.$TeamInfo.getDisplayAlias(this.contest.sport, game.replace('@', ''));
      }
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, game);
    },

    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },
  },
};
</script>

<style lang="scss" scoped>
  .stickyBox {
    position: sticky;
    top: calc(110px + var(--ob-nav-offset));
    margin-top: -15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    background: var(--obcolor-background-page);
    transition: top ease-out 0.3s;
    z-index: 10;
  }

  .selectedPlayerBox {
    height: 140px;
    min-height: 140px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .headshotContainer {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 3px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .statusLoading {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .statRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .statRow:not(:last-child) {
    margin-bottom: 10px;
  }

  .statField {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80px;
  }

  .statVal {
    font-size: 16px;
    font-weight: bold;
  }

  .statTitle {
    font-size: 12px;
  }

  .blueText {
    color: var(--obcolor-ob-blue) !important;
    font-weight: bold;
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }

  table.weekBox {
    height: 63px;
    text-align: center;
    font-size: 11px;
    margin: 0 10px;

    .draftGame {
      padding-bottom: 0px;
      height: 50px;
    }

    td, th {
      width: 60px;
      min-width: 60px;
    }

    th {
      color: var(--obcolor-font-secondary);
      text-transform: uppercase;
      padding-bottom: 3px;
    }

    td {
      padding: 4px;
      color: var(--obcolor-font-secondary);
    }

    td > .gameContainer {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--obcolor-background-1);
      margin-right: 2px;
    }
  }

  .gameProj {
    font-size: 16px;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: -2px;
  }

  .addButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      font-size: 14px;
      width: 160px;
      border-radius: 0;
    }
  }
</style>