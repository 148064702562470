import {MATCHUP_NFL_OFF, MATCHUP_NFL_DEF} from '../stats/matchup/MatchupSummaryNFL';
import {MATCHUP_MLB_PITCHER, MATCHUP_MLB_BATTER} from '../stats/matchup/MatchupSummaryMLB';
import {MATCHUP_NHL} from '../stats/matchup/MatchupSummaryNHL';
import {MATCHUP_NBA} from '../stats/matchup/MatchupSummaryNBA';

import {
  HEADER_NFL_QB,
  HEADER_NFL_RB,
  HEADER_NFL_WR_TE,
  HEADER_NFL_DEF,
  HEADER_NFL_K,
} from '../stats/header/HeaderNFL';
import {HEADER_MLB_PITCHER, HEADER_MLB_BATTER} from '../stats/header/HeaderMLB';
import {HEADER_NHL_SKATER, HEADER_NHL_GOALIE} from '../stats/header/HeaderNHL';
import {HEADER_NBA_PLAYER} from '../stats/header/HeaderNBA';

import {PREGAME_MLB_PITCHER, PREGAME_MLB_BATTER} from '../stats/pregame/PregameMLB';
import {PREGAME_NFL_PASSING, PREGAME_NFL_RUSHING, PREGAME_NFL_RECEIVING} from '../stats/pregame/PregameNFL';
import {INGAME_MLB_PITCHER, INGAME_MLB_BATTER} from '../stats/ingame/IngameMLB';
import {INGAME_NFL_PASSING, INGAME_NFL_RUSHING, INGAME_NFL_RECEIVING} from '../stats/ingame/IngameNFL';
import {INGAME_NBA_STATS} from '../stats/ingame/IngameNBA';
import {PREGAME_NHL_GOALIES, PREGAME_NHL_SKATERS} from '@/stats/pregame/PregameNHL';
import {INGAME_NHL_SKATERS, INGAME_NHL_GOALIES} from '@/stats/ingame/IngameNHL';

import {
  SEASON_NFL_QB,
  SEASON_NFL_RB,
  SEASON_NFL_WR_TE,
  SEASON_NFL_DEF,
  SEASON_NFL_K,
} from '../stats/season/SeasonNFL';
import {SEASON_MLB_PITCHER, SEASON_MLB_BATTER} from '../stats/season/SeasonMLB';
import {SEASON_NHL_SKATER, SEASON_NHL_GOALIE} from '../stats/season/SeasonNHL';
import {SEASON_NBA_PLAYER} from '../stats/season/SeasonNBA';

import {
  LIVE_NFL_QB,
  LIVE_NFL_RB,
  LIVE_NFL_WR_TE,
  LIVE_NFL_DEF,
  LIVE_NFL_K,
} from '../stats/live/LiveNFL';
import {LIVE_MLB_PITCHER, LIVE_MLB_BATTER} from '../stats/live/LiveMLB';
import {LIVE_NHL_SKATER, LIVE_NHL_GOALIE} from '../stats/live/LiveNHL';
import {LIVE_NBA_PLAYER} from '../stats/live/LiveNBA';
import {PREGAME_NBA_PLAYER} from '../stats/pregame/PregameNBA';
import {NBA_POSITIONS} from '../stats/positions/PositionsNBA';

const ScoringStats = {};

ScoringStats.install = function(Vue, options) {
  // --- NHL ---
  const STATS_NHL = [
    {label: 'G', path: 'skater.G', tooltip: 'Goals', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'A', path: 'skater.A', tooltip: 'Assists', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal', thresholds: [
      {value: 4, heat: 3}, {value: 3, heat: 1}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
    ]},
    {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: '+/-', path: 'skater.plus_minus', tooltip: 'Plus Minus', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}, {value: -1, heat: -1}, {value: -2, heat: -2}, {value: -3, heat: -3},
    ]},
    {label: 'SV', path: 'goalie.SV', tooltip: 'Saves', thresholds: [
      {value: 43, heat: 3}, {value: 38, heat: 2}, {value: 33, heat: 1}, {value: 28, heat: 0}, {value: 23, heat: -1}, {value: 18, heat: -2}, {value: 13, heat: -3},
    ]},
    {label: 'GA', path: 'goalie.GA', tooltip: 'Goals Allowed', thresholds: [
      {value: 0, heat: 3}, {value: 1, heat: 2}, {value: 2, heat: 0}, {value: 3, heat: -1}, {value: 4, heat: -2}, {value: 5, heat: -3},
    ]},
    {label: 'W', path: 'goalie.G_W', tooltip: 'Goalie Wins', thresholds: [
      {value: 1, heat: 3}, {value: 0, heat: 0},
    ]},
    {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts', thresholds: [
      {value: 1, heat: 3}, {value: 0, heat: 0},
    ]},
  ];
  const STATS_NHL_PLAYER = [
    {label: 'G', path: 'skater.G', tooltip: 'Goals', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'A', path: 'skater.A', tooltip: 'Assists', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal', thresholds: [
      {value: 4, heat: 3}, {value: 3, heat: 1}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
    ]},
    {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: '+/-', path: 'skater.plus_minus', tooltip: 'Plus Minus', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}, {value: -1, heat: -1}, {value: -2, heat: -2}, {value: -3, heat: -3},
    ]},
  ];
  const STATS_NHL_GOALIE = [
    {label: 'SV', path: 'goalie.SV', tooltip: 'Saves', thresholds: [
      {value: 43, heat: 3}, {value: 38, heat: 2}, {value: 33, heat: 1}, {value: 28, heat: 0}, {value: 23, heat: -1}, {value: 18, heat: -2}, {value: 13, heat: -3},
    ]},
    {label: 'GA', path: 'goalie.GA', tooltip: 'Goals Allowed', thresholds: [
      {value: 0, heat: 3}, {value: 1, heat: 2}, {value: 2, heat: 0}, {value: 3, heat: -1}, {value: 4, heat: -2}, {value: 5, heat: -3},
    ]},
    {label: 'W', path: 'goalie.G_W', tooltip: 'Goalie Wins', thresholds: [
      {value: 1, heat: 3}, {value: 0, heat: 0},
    ]},
    {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts', thresholds: [
      {value: 1, heat: 3}, {value: 0, heat: 0},
    ]},
  ];

  // --- NBA ---
  const STATS_NBA = [
    {label: 'PTS', path: 'player.PTS', thresholds: [
      {value: 45, heat: 3}, {value: 35, heat: 2}, {value: 25, heat: 1}, {value: 15, heat: 0}, {value: 10, heat: -1}, {value: 5, heat: -2}, {value: 0, heat: -3},
    ]},
    {label: 'AST', path: 'player.AST', thresholds: [
      {value: 8, heat: 3}, {value: 7, heat: 2}, {value: 6, heat: 1}, {value: 5, heat: 0}, {value: 4, heat: -1}, {value: 3, heat: -2}, {value: 2, heat: -3},
    ]},
    {label: 'REB', path: 'player.REB', thresholds: [
      {value: 8, heat: 3}, {value: 7, heat: 2}, {value: 6, heat: 1}, {value: 5, heat: 0}, {value: 4, heat: -1}, {value: 3, heat: -2}, {value: 2, heat: -3},
    ]},
    {label: 'BLK', path: 'player.BLK', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'STL', path: 'player.STL', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'TO', path: 'player.TO', thresholds: [
      {value: 0, heat: 1}, {value: 1, heat: 0}, {value: 2, heat: -1}, {value: 3, heat: -2}, {value: 4, heat: -3},
    ]},
  ];

  // --- NFL ---
  const STATS_NFL = [
    {label: 'P YD', path: 'passing.PASS_YDS', tooltip: 'Passing Yards', thresholds: [
      {value: 350, heat: 3}, {value: 300, heat: 2}, {value: 250, heat: 1}, {value: 200, heat: 0}, {value: 150, heat: -1}, {value: 100, heat: -2}, {value: 50, heat: -3},
    ]},
    {label: 'P TD', path: 'passing.PASS_TD', tooltip: 'Passing Touchdowns', thresholds: [
      {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
    ]},
    {label: 'P INT', path: 'passing.PASS_INT', tooltip: 'Pass Interceptions', thresholds: [
      {value: 0, heat: 1}, {value: 1, heat: 0}, {value: 2, heat: -1}, {value: 3, heat: -2}, {value: 4, heat: -3},
    ]},
    {label: 'SK A', path: 'sacks.SCK_A', thresholds: [
      {value: 0, heat: 1}, {value: 1, heat: 0}, {value: 2, heat: -1}, {value: 3, heat: -2}, {value: 4, heat: -3},
    ]},
    {label: 'R YD', path: 'rushing.RUS_YDS', thresholds: [
      {value: 150, heat: 3}, {value: 125, heat: 2}, {value: 100, heat: 1}, {value: 75, heat: 0}, {value: 50, heat: -1}, {value: 25, heat: -2}, {value: 0, heat: -3},
    ]},
    {label: 'R TD', path: 'rushing.RUS_TD', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
    {label: 'REC', path: 'passing.REC', thresholds: [
      {value: 7, heat: 3}, {value: 6, heat: 2}, {value: 5, heat: 1}, {value: 4, heat: 0}, {value: 3, heat: -1}, {value: 2, heat: -2}, {value: 1, heat: -3},
    ]},
    {label: 'REC YD', path: 'passing.REC_YDS', thresholds: [
      {value: 150, heat: 3}, {value: 125, heat: 2}, {value: 100, heat: 1}, {value: 75, heat: 0}, {value: 50, heat: -1}, {value: 25, heat: -2}, {value: 0, heat: -3},
    ]},
    {label: 'REC TD', path: 'passing.REC_TD', thresholds: [
      {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
    ]},
  ];

  // --- MLB ---
  const STATS_MLB = [
    {label: '1B', path: 'batter.1B', tooltip: 'Single', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: '2B', path: 'batter.2B', tooltip: 'Double', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: '3B', path: 'batter.3B', tooltip: 'Triple', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'HR', path: 'batter.HR', tooltip: 'Homerun', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'RBI', path: 'batter.RBI', tooltip: 'Runs Batted In', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'BB', path: 'batter.BB', tooltip: 'Walk', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'HBP', path: 'batter.HBP', tooltip: 'Hit by Pitch', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'R', path: 'batter.R', tooltip: 'Runs', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'SB', path: 'batter.SB', tooltip: 'Stolen Bases', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'CS', path: 'batter.CS', tooltip: 'Caught Stealing', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'K', path: 'batter.K', tooltip: 'Strikeout', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'PK', path: 'batter.PO', tooltip: 'Picked Off', thresholds: [
      {value: 0, heat: 0},
    ]},
  ];

  const STATS_MLB_PITCHER = [
    {label: 'IP', path: 'pitcher.IP', tooltip: 'Innings Pitched', thresholds: [
      {value: 7, heat: 3}, {value: 6, heat: 2}, {value: 5, heat: 1},
    ]},
    {label: 'ER', path: 'pitcher.ER', tooltip: 'Earned Runs', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'K', path: 'pitcher.K', tooltip: 'Outs', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'W', path: 'pitcher.W', tooltip: 'Wins', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'SO', path: 'pitcher.SO', tooltip: 'Shutout', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'SV', path: 'pitcher.SV', tooltip: 'Saves', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'HA', path: 'pitcher.HA', tooltip: 'Hit Allowed', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'BB', path: 'pitcher.BB', tooltip: 'Walk Allowed', thresholds: [
      {value: 0, heat: 0},
    ]},
    {label: 'HB', path: 'pitcher.HB', tooltip: 'Hit Batter', thresholds: [
      {value: 0, heat: 0},
    ]},
  ];

  // Add-Drop
  const TABLE_NBA = [
    {
      label: 'Stats',
      stats: [
        {label: 'TOC', path: 'player.MIN_PG'},
        {label: 'GP', path: 'player.GP'},
        {label: 'PTS', path: 'player.PTS_PG'},
        {label: 'AST', path: 'player.AST_PG'},
        {label: 'REB', path: 'player.REB_PG'},
        {label: 'FG%', path: 'player.FGPct'},
        {label: '3P%', path: 'player.TPPct'},
        {label: 'FT%', path: 'player.FTPct'},
        {label: 'BLK', path: 'player.BLK_PG'},
        {label: 'STL', path: 'player.STL_PG'},
        {label: 'TO', path: 'player.TO_PG'},
      ],
    },
  ];

  const TABLE_NFL_RB = [
    {
      label: 'Overall',
      stats: [
        {label: 'GP', path: 'player.GP'},
        {label: 'TYD', path: 'scoring.TYD'},
        {label: 'TTD', path: 'scoring.TTD'},
      ],
    },
    {
      label: 'Rushing',
      stats: [
        {label: 'CAR', path: 'rushing.RUS_ATT'},
        {label: 'YDS', path: 'rushing.RUS_YDS'},
        {label: 'TD', path: 'rushing.RUS_TD'},
      ],
    },
    {
      label: 'Receiving',
      stats: [
        {label: 'TAR', path: 'passing.REC_TAR'},
        {label: 'REC', path: 'passing.REC'},
        {label: 'YDS', path: 'passing.REC_YDS'},
        {label: 'TD', path: 'passing.REC_TD'},
      ],
    },
    {
      label: 'Returning',
      stats: [
        {label: 'CAR', path: ''}, // Not sure where returning stats are
        {label: 'YDS', path: ''}, // Not sure where returning stats are
        {label: 'TD', path: ''}, // Not sure where returning stats are
      ],
    },
  ];

  const TABLE_NFL_QB = [
    {
      label: 'Overall',
      stats: [
        {label: 'GP', path: 'player.GP'},
        {label: 'TYD', path: 'scoring.TYD'},
        {label: 'TTD', path: 'scoring.TTD'},
      ],
    },
    {
      label: 'Passing',
      stats: [
        {label: 'ATT', path: 'passing.PASS_ATT'},
        {label: 'CMP', path: 'passing.PASS_M'},
        {label: 'YDS', path: 'passing.PASS_YDS'},
        {label: 'TD', path: 'passing.PASS_TD'},
        {label: 'INT', path: 'passing.PASS_INT'},
        {label: 'SCK', path: 'sacks.SCK_A'},
      ],
    },
    {
      label: 'Rushing',
      stats: [
        {label: 'CAR', path: 'rushing.RUS_ATT'},
        {label: 'YDS', path: 'rushing.RUS_YDS'},
        {label: 'TD', path: 'rushing.RUS_TD'},
      ],
    },
    {
      label: '',
      stats: [
        {label: 'FUM', path: 'fumbles.FBL'},
      ],
    },
  ];

  const TABLE_NFL_WR_TE = [
    {
      label: 'Overall',
      stats: [
        {label: 'GP', path: 'player.GP'},
        {label: 'TYD', path: 'scoring.TYD'},
        {label: 'TTD', path: 'scoring.TTD'},
      ],
    },
    {
      label: 'Receiving',
      stats: [
        {label: 'TAR', path: 'passing.REC_TAR'},
        {label: 'REC', path: 'passing.REC'},
        {label: 'YDS', path: 'passing.REC_YDS'},
        {label: 'TD', path: 'passing.REC_TD'},
      ],
    },
    {
      label: 'Rushing',
      stats: [
        {label: 'CAR', path: 'rushing.RUS_ATT'},
        {label: 'YDS', path: 'rushing.RUS_YDS'},
        {label: 'TD', path: 'rushing.RUS_TD'},
      ],
    },
    {
      label: 'Returning',
      stats: [
        {label: 'CAR', path: ''}, // Not sure where returning stats are
        {label: 'YDS', path: ''}, // Not sure where returning stats are
        {label: 'TD', path: ''}, // Not sure where returning stats are
      ],
    },
  ];

  const TABLE_NFL_DEF = [
    {
      label: 'Defense',
      stats: [
        {label: 'GP', path: 'player.GP'},
        {label: 'TK', path: 'defense.TCK_T'},
        {label: 'SCK', path: 'defense.SCK'},
        {label: 'INT', path: 'defense.INT'},
        {label: 'TD', path: 'defense.INT_TD'},
        {label: 'FR', path: 'fumbles.FBL_REC'},
        {label: 'FF', path: 'fumbles.FBL_F'},
        {label: 'PD', path: 'defense.PD'},
        {label: 'BLK', path: 'defense.BLK'},
        {label: 'STY', path: 'scoring.TD_SFT'},
      ],
    },
  ];

  const TABLE_NFL_K = [
    {
      label: 'Kicking',
      stats: [
        {label: 'GP', path: 'player.gp'},
        {label: 'FGA', path: 'scoring.FGA'},
        {label: 'FGM', path: 'scoring.FGM'},
        {label: 'LNG', path: 'scoring.FG_LNG'},
        {label: '<30', path: 'scoring.FG29_M'},
        {label: '<40', path: 'scoring.FG30_M'},
        {label: '<50', path: 'scoring.FG40_M'},
        {label: '50+', path: 'scoring.FG50_M'},
        {label: 'BLK', path: ''}, // Can't find
        {label: 'XP', path: 'scoring.EPM'},
        {label: 'PTS', path: ''}, // Can't find
      ],
    },
  ];

  // Used in new Add/Drop component, for season stats
  const TABLE_NHL_PLAYER = [
    {
      label: '',
      stats: [
        {label: 'TOI', path: 'skater.TOI'},
        {label: 'GP', path: 'skater.GP'},
        {label: 'G', path: 'skater.G'},
        {label: 'A', path: 'skater.A'},
        {label: '+/-', path: 'skater.plus_minus'},
        {label: 'SOG', path: 'skater.SOG'},
        {label: 'BLK', path: 'skater.BK'},
      ],
    },
  ];

  const TABLE_NHL_GOALIE = [
    {
      label: 'Stats',
      stats: [
        {label: 'GP', path: 'skater.GP'},
        {label: 'GAA', path: 'goalie.GAA'},
        {label: 'SV%', path: 'goalie.SVPct'},
        {label: 'SV', path: 'goalie.SV'},
        {label: 'SA', path: 'goalie.SA'},
        {label: 'GA', path: 'goalie.GA'},
        {label: 'W', path: 'goalie.W'},
        {label: 'L', path: 'goalie.L'},
        {label: 'OTL', path: 'goalie.OTL'},
        {label: 'SO', path: 'goalie.SO'},
      ],
    },
  ];

  // TODO: Verify all of these stats since we don't have them for this season
  const TABLE_MLB_PITCHER = [
    {
      label: 'Stats',
      stats: [
        {label: 'IP', path: 'pitcher.IP'},
        {label: 'W', path: 'pitcher.W'},
        {label: 'L', path: 'pitcher.L'},
        {label: 'K', path: 'pitcher.K'},
        {label: 'BB', path: 'pitcher.BB'},
        {label: 'HR', path: 'pitcher.HR'},
        {label: 'ERA', path: 'pitcher.ERA'},
        {label: 'WHIP', path: 'pitcher.WHIP'},
        {label: 'SV', path: 'pitcher.SV'},
        {label: 'BS', path: ''}, // Can't find
        {label: 'HLD', path: ''}, // Can't find
        {label: 'GS', path: 'pitcher.GS'},
        {label: 'QS', path: ''}, // Can't find
        {label: 'CG', path: ''}, // Can't find
        {label: 'SO', path: ''}, // Can't find
      ],
    },
  ];

  // TODO: Verify all of these stats since we don't have them for this season
  const TABLE_MLB_BATTER = [
    {
      label: 'Stats',
      stats: [
        {label: 'H', path: 'batter.H'},
        {label: 'AB', path: 'batter.AB'},
        {label: 'R', path: 'batter.R'},
        {label: '2B', path: 'batter.2B'}, // Can't find
        {label: '3B', path: 'batter.2B'}, // Can't find
        {label: 'HR', path: 'batter.HR'},
        {label: 'AB/HR', path: ''}, // Can't find
        {label: 'RBI', path: 'batter.RBI'},
        {label: 'K', path: 'batter.K'},
        {label: 'AB/K', path: ''}, // Can't find
        {label: 'BB', path: 'batter.BB'},
        {label: 'SB', path: 'batter.SB'},
        {label: 'AVG', path: 'batter.AVG'},
        {label: 'OPS', path: ''}, // Can't find
      ],
    },
  ];

  const H2H_NFL_ALL_STATS = [
    {label: 'Passing Yards', short: 'Pass Yds', path: 'passing.PASS_YDS'},
    {label: 'Passing Touchdowns', short: 'Pass TDs', path: 'passing.PASS_TD'},
    {label: 'Receptions', short: 'RECs', path: 'passing.REC'},
    {label: 'Receiving Yards', short: 'Rec Yds', path: 'passing.REC_YDS'},
    {label: 'Receiving Touchdowns', short: 'Rec TDs', path: 'passing.REC_TD'},
    {label: 'Rushing Yards', short: 'Rush Yds', path: 'rushing.RUS_YDS'},
    {label: 'Rushing Touchdowns', short: 'Rush TDs', path: 'rushing.RUS_TD'},
    {label: 'Return Yards', short: 'Ret Yds', path: 'special.RET_YDS'},
    {label: 'Return Touchdowns', short: 'Ret TDs', path: 'special.RET_TD'},
    {label: 'Interceptions', short: 'INTs', path: 'passing.PASS_INT'},
    {label: 'Sacks', short: 'SCKs', path: 'sacks.SCK_A'},
    {label: 'Fumbles Lost', short: 'FUMs', path: 'fumbles.FBL_L'},
    {label: 'Two-Point Conversions', short: '2pt Convs', path: 'scoring.TOTAL_TPC'},
    {label: 'Extra-Point Conversions', short: 'PAT', path: 'scoring.EPM'},
    {label: 'Field Goals', short: 'FGs', path: 'scoring.FG_YDS'},
    {label: 'Interceptions', short: 'INTs', path: 'defense.INT'},
    {label: 'Sacks', short: 'SCKs', path: 'defense.SCK'},
    {label: 'Safeties', short: 'SFTYs', path: 'defense.STY'},
    {label: 'Solo Tackles', short: 'Solo', path: 'defense.TCK_SOLO'},
    {label: 'Passes Defended', short: 'PDEF', path: 'defense.PD'},
    {label: 'Forced Fumbles', short: 'FF', path: 'fumbles.FBL_F'},
    {label: 'Fumbles Recovered', short: 'FR', path: 'fumbles.FBL_REC'},
  ];

  const SALARYCAP_NFL_ALL_STATS = [
    {label: 'Passing Yards', short: 'Pass Yds', path: 'passing.PASS_YDS'},
    {label: 'Passing Touchdowns', short: 'Pass TDs', path: 'passing.PASS_TD'},
    {label: 'Receptions', short: 'RECs', path: 'passing.REC'},
    {label: 'Receiving Yards', short: 'Rec Yds', path: 'passing.REC_YDS'},
    {label: 'Receiving Touchdowns', short: 'Rec TDs', path: 'passing.REC_TD'},
    {label: 'Rushing Yards', short: 'Rush Yds', path: 'rushing.RUS_YDS'},
    {label: 'Rushing Touchdowns', short: 'Rush TDs', path: 'rushing.RUS_TD'},
    {label: 'Return Yards', short: 'Ret Yds', path: 'special.RET_YDS'},
    {label: 'Return Touchdowns', short: 'Ret TDs', path: 'special.RET_TD'},
    {label: 'Interceptions', short: 'INTs', path: 'passing.PASS_INT'},
    {label: 'Sacks', short: 'SCKs', path: 'sacks.SCK_A'},
    {label: 'Fumbles Lost', short: 'FUMs', path: 'fumbles.FBL_L'},
    {label: 'Two-Point Conversions', short: '2pt Convs', path: 'scoring.TOTAL_TPC'},
  ];

  const NBA_ALL_STATS = [
    {label: 'Points', short: 'Points', path: 'player.PTS'},
    {label: 'Rebounds', short: 'Rebounds', path: 'player.REB'},
    {label: 'Assists', short: 'Assists', path: 'player.AST'},
    {label: 'Blocks', short: 'BLK', path: 'player.BLK'},
    {label: 'Steals', short: 'Steals', path: 'player.STL'},
    {label: 'Turnovers', short: 'Turnovers', path: 'player.TO'},
  ];

  const NHL_ALL_STATS = [
    {label: 'Goals', short: 'Goals', path: 'skater.G'},
    {label: 'Assists', short: 'Assists', path: 'skater.A'},
    {label: 'Shots on Goal', short: 'SOG', path: 'skater.SOG'},
    {label: 'Blocks', short: 'BLK', path: 'skater.BK'},
    {label: '+/-', short: '+/-', path: 'skater.plus_minus'},
    {label: 'Saves', short: 'Saves', path: 'goalie.SV'},
    {label: 'Goals Against', short: 'GA', path: 'goalie.GA'},
    {label: 'Wins', short: 'Wins', path: 'goalie.G_W'},
    {label: 'Shutouts', short: 'Shutouts', path: 'goalie.SO'},
  ];

  const MLB_ALL_STATS = [
    {label: 'Singles', short: 'Singles', path: 'batter.1B'},
    {label: 'Walks', short: 'Walks', path: 'batter.BB_U'},
    {label: 'Intentional Walks', short: 'Intentional Walks', path: 'batter.BB_I'},
    {label: 'Hit By Pitch', short: 'HBP', path: 'batter.HBP'},
    {label: 'Doubles', short: 'Doubles', path: 'batter.2B'},
    {label: 'Triples', short: 'Triples', path: 'batter.3B'},
    {label: 'Home Runs', short: 'HR', path: 'batter.HR'},
    {label: 'Runs Batted In', short: 'RBIs', path: 'batter.RBI'},
    {label: 'Runs Scored', short: 'Runs', path: 'batter.R'},
    {label: 'Stolen Bases', short: 'SB', path: 'batter.SB'},
    {label: 'Strikeouts', short: 'K', path: 'batter.K'},
    {label: 'Caught Stealing', short: 'CS', path: 'batter.CS'},
    {label: 'Picked Off', short: 'PO', path: 'batter.PK'},
    {label: 'Outs', short: 'Outs', path: 'pitcher.IP_1'},
    {label: 'Strikeouts', short: 'K', path: 'pitcher.K'},
    {label: 'Wins', short: 'Win', path: 'pitcher.W'},
    {label: 'Shutouts', short: 'Shutouts', path: 'pitcher.SO'},
    {label: 'Saves', short: 'Saves', path: 'pitcher.SV'},
    {label: 'Hits Allowed', short: 'H', path: 'pitcher.H'},
    {label: 'Intentional Walks', short: 'Intentional Walk', path: 'pitcher.BB_I'},
    {label: 'Walks Allowed', short: 'Walks', path: 'pitcher.BB_U'},
    {label: 'Hit Batters', short: 'HB', path: 'pitcher.HBP'},
    {label: 'Earned Runs', short: 'ER', path: 'pitcher.ER'},
  ];

  const HEAT_COLOURS = {
    '-3': 'var(--obcolor-heatmap-c3)',
    '-2': 'var(--obcolor-heatmap-c2)',
    '-1': 'var(--obcolor-heatmap-c1)',
    '0': 'var(--obcolor-heatmap-h0)',
    '1': 'var(--obcolor-heatmap-h1)',
    '2': 'var(--obcolor-heatmap-h2)',
    '3': 'var(--obcolor-heatmap-h3)',
  };

  const MAX_LEAD_NHL = 100;
  const MAX_LEAD_NBA = 200;
  const MAX_LEAD_NFL = 100;
  const MAX_LEAD_MLB = 100;

  const ScoringStats = {
    getStatBackground(score, stat) {
      let thresholdComparator = '>';
      if (score == null || score == undefined || score == '') {
        return 'none';
      }
      if (!stat.thresholds) {
        // If threshold is not set, don't highlight column
        return '';
      }

      if (stat.thresholds[0] != undefined && stat.thresholds[1] != undefined &&
        stat.thresholds[0].value < stat.thresholds[1].value
      ) {
        thresholdComparator = '<';
      }
      for (const thresholdIndex in stat.thresholds) {
        const threshold = stat.thresholds[thresholdIndex];
        if (
          (thresholdComparator === '>' && score >= threshold.value) ||
          (thresholdComparator === '<' && score <= threshold.value)
        ) {
          return HEAT_COLOURS[threshold.heat];
        }

        if (thresholdIndex == stat.thresholds.length - 1) {
          return HEAT_COLOURS[threshold.heat];
        }
      }
    },

    getSeasonStats(sport, position) {
      switch (sport) {
        case 'NHL': return position === 'G' ? SEASON_NHL_GOALIE : SEASON_NHL_SKATER;
        case 'NBA': return SEASON_NBA_PLAYER;
        case 'MLB': return position === 'P' || position === 'SP' ? SEASON_MLB_PITCHER : SEASON_MLB_BATTER;
        case 'NFL':
          switch (position) {
            case 'QB': return SEASON_NFL_QB;
            case 'RB': case 'FB': return SEASON_NFL_RB;
            case 'WR': case 'TE': return SEASON_NFL_WR_TE;

            case 'K': case 'P': return SEASON_NFL_K;

            case 'S': case 'CB': case 'LB': case 'DT': case 'DE': case 'G': case 'NT': case 'DL': case 'DB':
              return SEASON_NFL_DEF;
          }
      }
      return [];
    },

    getAllLiveStats(sport) {
      switch (sport) {
        case 'NHL': return [
          {label: 'Goalies', isPosition: (pos) => pos === 'G', liveStats: LIVE_NHL_GOALIE},
          {label: 'Skaters', isPosition: (pos) => pos !== 'G', liveStats: LIVE_NHL_SKATER},
        ];
        case 'NBA': return [
          {label: 'Guards', isPosition: (pos) => pos === 'PG' || pos === 'SG', liveStats: LIVE_NBA_PLAYER},
          {label: 'Forwards', isPosition: (pos) => pos === 'PF' || pos === 'SF', liveStats: LIVE_NBA_PLAYER},
          {label: 'Centers', isPosition: (pos) => pos === 'C', liveStats: LIVE_NBA_PLAYER},
        ];
        case 'MLB': return [
          {label: 'Pitchers', isPosition: (pos) => pos === 'P' || pos === 'SP', liveStats: LIVE_MLB_PITCHER},
          {label: 'Batters', isPosition: (pos) => pos !== 'P' && pos !== 'SP', liveStats: LIVE_MLB_BATTER},
        ];
        case 'NFL': return [
          {label: 'Quarterbacks', isPosition: (pos) => pos === 'QB', liveStats: LIVE_NFL_QB},
          {label: 'Running Backs', isPosition: (pos) => pos === 'RB' || pos === 'FB', liveStats: LIVE_NFL_RB},
          {label: 'Receivers', isPosition: (pos) => pos === 'WR' || pos === 'TE', liveStats: LIVE_NFL_WR_TE},
          {label: 'Kickers', isPosition: (pos) => pos === 'K' || pos === 'P', liveStats: LIVE_NFL_K},
          {label: 'Defense',
            isPosition: (pos) => {
              return pos === 'S' || pos === 'CB' || pos === 'LB' ||
                pos === 'DT' || pos === 'DE' || pos === 'G' || pos === 'NT' ||
                pos == 'DL' || pos == 'DB';
            },
            liveStats: LIVE_NFL_DEF,
          },
        ];
      }
      return [];
    },

    getBoxScoreStats(sport) {
      switch (sport) {
        case 'NHL': return [
          {label: 'Goalies', isPosition: (pos) => pos === 'G', liveStats: LIVE_NHL_GOALIE},
          {label: 'Skaters', isPosition: (pos) => pos !== 'G', liveStats: LIVE_NHL_SKATER},
        ];
        case 'NBA': return [
          {label: 'Players', isPosition: (pos) => true, liveStats: LIVE_NBA_PLAYER},
        ];
        case 'MLB': return [
          {label: 'Pitchers', isPosition: (pos) => pos === 'P' || pos === 'SP', liveStats: LIVE_MLB_PITCHER},
          {label: 'Batters', isPosition: (pos) => pos !== 'P' && pos !== 'SP', liveStats: LIVE_MLB_BATTER},
        ];
        case 'NFL': return [
          {label: 'Quarterbacks', isPosition: (pos) => pos === 'QB', liveStats: LIVE_NFL_QB},
          {label: 'Running Backs', isPosition: (pos) => pos === 'RB' || pos === 'FB', liveStats: LIVE_NFL_RB},
          {label: 'Receivers', isPosition: (pos) => pos === 'WR' || pos === 'TE', liveStats: LIVE_NFL_WR_TE},
          {label: 'Kickers', isPosition: (pos) => pos === 'K' || pos === 'P', liveStats: LIVE_NFL_K},
          {label: 'Defense',
            isPosition: (pos) => {
              return pos === 'S' || pos === 'CB' || pos === 'LB' ||
                pos === 'DT' || pos === 'DE' || pos === 'G' || pos === 'NT' ||
                pos == 'DL' || pos == 'DB';
            },
            liveStats: LIVE_NFL_DEF,
          },
        ];
      }
      return [];
    },

    getLiveStats(sport, position) {
      switch (sport) {
        case 'NHL': return position === 'G' ? LIVE_NHL_GOALIE : LIVE_NHL_SKATER;
        case 'NBA': return LIVE_NBA_PLAYER;
        case 'MLB': return position === 'P' || position === 'SP' ? LIVE_MLB_PITCHER : LIVE_MLB_BATTER;
        case 'NFL':
          switch (position) {
            case 'QB': return LIVE_NFL_QB;
            case 'RB': case 'FB': return LIVE_NFL_RB;
            case 'WR': case 'TE': return LIVE_NFL_WR_TE;

            case 'K': case 'P': return LIVE_NFL_K;
            // case "P":    return LIVE_NFL_PUNT;

            case 'S': case 'CB': case 'LB': case 'DT': case 'DE': case 'G': case 'NT': case 'DL': case 'DB':
              return LIVE_NFL_DEF;
          }
      }
      return [];
    },

    getHeaderScoringStats(sport, position) {
      switch (sport) {
        case 'NHL': return position === 'G' ? HEADER_NHL_GOALIE : HEADER_NHL_SKATER;
        case 'NBA': return HEADER_NBA_PLAYER;
        case 'MLB': return position === 'P' || position === 'SP' ? HEADER_MLB_PITCHER : HEADER_MLB_BATTER;
        case 'NFL':
          switch (position) {
            case 'QB': return HEADER_NFL_QB;
            case 'RB': case 'FB': return HEADER_NFL_RB;
            case 'WR': case 'TE': return HEADER_NFL_WR_TE;

            case 'K': case 'P': return HEADER_NFL_K;

            case 'S': case 'CB': case 'LB': case 'DT': case 'DE': case 'G': case 'NT': case 'DL': case 'DB':
              return HEADER_NFL_DEF;
          }
      }
      return [];
    },

    // Pregame scoring stats for boxscore modal (Only MLB is )
    getPregameScoringStats(sport, position) {
      switch (sport) {
        case 'MLB': return position === 'P' || position === 'SP' ? PREGAME_MLB_PITCHER : PREGAME_MLB_BATTER;
        case 'NBA': return PREGAME_NBA_PLAYER;
        case 'NFL':
          switch (position) {
            case 'QB': return PREGAME_NFL_PASSING;
            case 'RB': case 'FB': return PREGAME_NFL_RUSHING;
            case 'WR': case 'TE': return PREGAME_NFL_RECEIVING;
          }
          return [];
        case 'NHL':
          switch (position) {
            case 'LW': case 'C': case 'RW': case 'D': return PREGAME_NHL_SKATERS;
            case 'G': return PREGAME_NHL_GOALIES;
          }
          return [];
      }
      return [];
    },

    getIngameScoringStats(sport, position) {
      switch (sport) {
        case 'MLB': return position === 'P' || position === 'SP' ? INGAME_MLB_PITCHER : INGAME_MLB_BATTER;
        case 'NFL':
          switch (position) {
            case 'QB': return INGAME_NFL_PASSING;
            case 'RB': case 'FB': return INGAME_NFL_RUSHING;
            case 'WR': case 'TE': return INGAME_NFL_RECEIVING;
          }
          return [];
        case 'NBA': return INGAME_NBA_STATS;
        case 'NHL':
          switch (position) {
            case 'LW': case 'C': case 'RW': case 'D': return INGAME_NHL_SKATERS;
            case 'G': return INGAME_NHL_GOALIES;
          }
          return [];
      }
      return [];
    },

    getTableScoringStats(sport, position) {
      switch (sport) {
        case 'NHL': return position === 'G' ? TABLE_NHL_GOALIE : TABLE_NHL_PLAYER;
        case 'NBA': return TABLE_NBA;
        case 'MLB': return position === 'P' || position === 'SP' ? TABLE_MLB_PITCHER : TABLE_MLB_BATTER;
        case 'NFL':
          switch (position) {
            case 'QB': return TABLE_NFL_QB;
            case 'RB': case 'FB': return TABLE_NFL_RB;
            case 'WR': case 'TE': return TABLE_NFL_WR_TE;

            case 'KICK':
            case 'K': case 'P': return TABLE_NFL_K;

            case 'DEF': case 'LB': case 'DE': case 'DL': case 'DB': case 'OL': case 'OT':
            case 'DT': case 'CB': case 'NT': case 'C': case 'G':
            case 'S': case 'LS': return TABLE_NFL_DEF;
          }
      }
      return [];
    },

    getPositions(sport) {
      if (sport === 'NBA') return NBA_POSITIONS;
      return null;
    },

    getAllH2hNflStats() {
      return H2H_NFL_ALL_STATS;
    },

    getAllSalaryCapNflStats() {
      return SALARYCAP_NFL_ALL_STATS;
    },

    getAllStats(sport, isSalaryCap) {
      switch (sport) {
        case 'NFL':
          return isSalaryCap ? this.getAllSalaryCapNflStats() : this.getAllH2hNflStats();
        case 'NHL':
          return NHL_ALL_STATS;
        case 'NBA':
          return NBA_ALL_STATS;
        case 'MLB':
          return MLB_ALL_STATS;
      }

      return [];
    },

    // List of all stats from all matchup summary sections, used for total calculations
    getMatchupSummaryStatsList(sport) {
      const allStats = [];
      const statsByCategory = this.getMatchupFilters(sport);
      for (const section of statsByCategory) {
        for (const category of section.categories) {
          for (const stat of category.stats) {
            if (stat.path) {
              allStats.push(stat.path);
            }
            if (stat.sum) {
              allStats.push(stat.sum);
            }
            if (stat.denom) {
              allStats.push(stat.denom);
            }
          }
        }
      }
      return allStats;
    },

    getMatchupFilters(sport) {
      switch (sport) {
        case 'NHL': return [
          {
            label: 'Skater',
            categories: MATCHUP_NHL,
          },
        ];
        case 'NBA': return [
          {
            label: 'Player',
            categories: MATCHUP_NBA,
          },
        ];
        case 'MLB': return [
          {
            label: 'Batting',
            categories: MATCHUP_MLB_BATTER,
          },
          {
            label: 'Pitching',
            categories: MATCHUP_MLB_PITCHER,
          },
        ];
        case 'NFL': return [
          {
            label: 'Offense',
            categories: MATCHUP_NFL_OFF,
          },
          {
            label: 'Def & Kicking',
            categories: MATCHUP_NFL_DEF,
          },
        ];
      }
      return [];
    },

    getLeadPercentage(sport, team1Score, team2Score) {
      const pointDiff = Math.abs(team1Score - team2Score);
      let maxLead = 0;
      let leadPercentage = 0;
      switch (sport) {
        case 'NHL':
          maxLead = MAX_LEAD_NHL;
          break;
        case 'NBA':
          maxLead = MAX_LEAD_NBA;
          break;
        case 'NFL':
          maxLead = MAX_LEAD_NFL;
          break;
        case 'MLB':
          maxLead = MAX_LEAD_MLB;
          break;
      }
      if (maxLead === 0 || pointDiff === 0) {
        return 50;
      }
      leadPercentage = (pointDiff / maxLead) * 50;
      if (leadPercentage > 50) {
        leadPercentage = 50;
      }
      if (team1Score > team2Score) {
        return leadPercentage + 50;
      } else {
        return 50 - leadPercentage;
      }
    },
  };

  Vue.prototype.$ScoringStats = ScoringStats;
};

export default ScoringStats;