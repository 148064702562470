<template>
  <tr class="playerRow" @click="selectPlayer()">
    <td style="width: 50px; min-width: 50px;">{{ player.draftPos }}</td>
    <td>
      <div class="playerName">
        <div v-if="!statusMap" class="statusLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <PlayerStatus v-else-if="!status" class="playerStatus" height="16px;"
          status="'OUT'"
        />
        <PlayerStatus v-else class="playerStatus" height="16px;"
          :status="status"
        />

        <span @click.stop="openPlayerCard()" class="ob-link" style="margin-right: 6px;"
          :style="getPlayerTextColour"
        >
          {{ player.name.longName() }}
        </span>

        <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
          :style="getPlayerTextColour"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
        ></i>
        <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
          :style="getPlayerTextColour"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
        ></i>
      </div>
      <div class="playerInfo" :class="{'pickedPlayer': picked}">
        <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }}</span>
        <span>#{{ player.number }}</span>
        <span>{{ player.position }}</span>
        <span>{{ player.lineInfo }}</span>
      </div>
    </td>

    <!-- Stats -->
    <template v-if="selectedView === 'stats'">
      <td v-if="getStat('pg') && getStat('ag') && contest.format == 'WEEKLY'">
        {{ getStat('pg') }}/{{ getStat('ag') }}
      </td>
      <td v-else-if="contest.format == 'WEEKLY'">-</td>

      <template v-if="contest.format == 'DAILY'">
        <!-- Upcoming game opponent logo -->
        <td>
          <div v-if="nextGame" class="logoBox">
            <div v-if="nextGame.game.includes('@')" style="margin-bottom: 3px;">@</div>
            <img :src="getTeamLogo(nextGame.game)" style="height: 24px;" :alt="nextGame.game">
          </div>
          <div v-else>-</div>
        </td>
        <!-- Upcoming game time -->
        <td class="gameTimeCol">
          <div v-if="nextGame && nextGame.ts != null">{{ $moment(nextGame.ts).format('ddd h:mma') }}</div>
          <div v-else>-</div>
        </td>
        <!-- Opponent Rank (OPRK) -->
        <td v-if="contest.sport == 'NFL'">
          <div v-if="nextGame && nextGame.oprk != null" class="oprk" :style="'color: ' + getOprkColour(nextGame.oprk)">{{ nextGame.oprk }}</div>
          <div v-else>-</div>
        </td>
      </template>
      <td>
        {{ getStat('avg').toFixed(0) || '-' }}
      </td>
      <td>{{ player.sAvg.toFixed(1) || '-' }}</td>
      <td v-if="contest.format == 'WEEKLY'">{{ getStat('proj') }}</td>
    </template>

    <!-- Schedule -->
    <template v-else>
      <td v-for="g in player.games" :key="g.date" style="padding: 2px; min-width: 55px; vertical-align: top;">
        <div class="scheduleBox" :style="!g.game || g.proj === 0 ? 'opacity: 0.4;': ''">
          <div v-if="g.game" :style="g.proj === 0 ? 'color: var(--obcolor-font-secondary);' : ''">
            <div class="logoBox">
              <div v-if="g.game.includes('@')">@</div>
              <img :src="getTeamLogo(g.game)" style="height: 24px;" :alt="g.game">
            </div>
            <div style="margin-top: 1px;">{{ roundStat(g.proj) }}</div>
          </div>
        </div>
        <div v-if="g.gameDH" class="scheduleBox" :style="g.projDH === 0 ? 'opacity: 0.4;': ''">
          <div :style="g.projDH === 0 ? 'color: var(--obcolor-font-secondary);' : ''">
            <div class="logoBox">
              <div v-if="g.gameDH.includes('@')">@</div>
              <img :src="getTeamLogo(g.gameDH)" :alt="g.gameDH" style="height: 24px;">
            </div>
            <div style="margin-top: 1px;">{{ roundStat(g.projDH) }}</div>
          </div>
        </div>
      </td>
      <td style="min-width: 4px;"></td>
    </template>

    <!-- Salary -->
    <template v-if="contest.format == 'WEEKLY'">
      <td class="moneyText endSection" :style="!canAfford ? 'color: red' : getPlayerTextColour">
        {{ dollarFormat(player.salaryPG) }}
      </td>
      <td style="width: 25px; min-width: 25px; padding: 0;" :style="getPlayerTextColour">x {{ getStat('pg') }}</td>
    </template>
    <td class="moneyText" :style="!canAfford ? 'color: red' : getPlayerTextColour">
      {{ dollarFormat(player.salary) }}
    </td>
    <td class="btnCol" @click.stop="togglePlayer">
      <i v-if="playerIsOnTeam" class="fal fa-minus-circle addBtn"></i>
      <i v-else-if="getStat('pg') == 0" :key="'tooltip_' + player.id" style="height: 22px;"
        class="fal fa-times-circle addBtn disabledBtn"
        v-tooltip.bottom="{ content: 'You cannot add a player with zero projected games to your lineup.', class: 'ObTooltip'}"
      ></i>
      <i v-else-if="!canPickPlayer" class="fal fa-times-circle addBtn disabledBtn"></i>
      <i v-else class="fal fa-plus-circle addBtn disabledBtn"></i>
    </td>
  </tr>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';

export default {
  props: {
    player: Object,
    picked: Boolean,
    picks: Object,
    statusMap: Object,
    status: String,
    contest: Object,
    totSpent: Number,
    selectedView: String,
  },

  components: {
    PlayerStatus,
  },

  computed: {
    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      return this.contest.draftRoster.roster;
    },

    canAfford() {
      const playerCost = this.player.salary;
      return (playerCost + this.totSpent <= this.contest.salaryCap) || this.picked !== undefined;
    },

    canPickPlayer() {
      // Safeguard, player id and position required
      if (this.picks == null || this.player.id == null || this.player.draftPos == null) {
        return false;
      }

      // Can't Select players with no projected games
      if (this.getStat('pg') <= 0) {
        return false;
      }

      const draftPos = this.player.draftPos;
      let flexOpen = false;
      let superflexOpen = false;

      // NFL Flex and SuperFlex positions can fit multiple slots
      if (this.contest.isFlexContest) {
        if (this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport)) {
          flexOpen = this.draftSpotIsOpen('F');
        }
        if (this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport)) {
          superflexOpen = this.draftSpotIsOpen('SF');
        }
      }

      return this.draftSpotIsOpen(draftPos) || flexOpen || superflexOpen;
    },

    getPlayerTextColour() {
      if (!this.player || !this.player.id) {
        return 'color: var(--obcolor-text-faded);';
      }
      if (this.player.salary === 0) {
        return 'color: var(--obcolor-text-faded);';
      }
      if (this.getStat('pg') == 0) {
        return 'color: var(--obcolor-text-secondary);';
      }
      return this.picked !== undefined ? 'color: var(--obcolor-ob-blue);' : '';
    },

    playerIsOnTeam() {
      return this.picked !== undefined;
    },

    nextGame() {
      if (!this.player.games) {
        return null;
      }

      return this.player.games.find((g) => g.ts != null);
    },
  },

  methods: {
    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getOprkColour(oprk) {
      if (oprk <= 10) {
        return 'var(--obcolor-red)';
      } else if (oprk <= 20) {
        return 'var(--obcolor-yellow)';
      }

      return 'var(--obcolor-green)';
    },

    getStat(path) {
      if (this.player && this.player.wStats && this.player.wStats[0]) {
        return this.player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    rosterAtPos(position) {
      return this.roster.find((r) => {
        return r.short == position;
      });
    },

    draftSpotIsOpen(draftPos) {
      if (this.picks[draftPos] == null) {
        return false;
      }
      const rPos = this.rosterAtPos(draftPos);
      return rPos.num > this.picks[draftPos].length;
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    togglePlayer() {
      if (this.playerIsOnTeam) {
        EventBus.$emit('SALARY_CAP_REMOVE_PLAYER', this.player);
      } else if (this.canPickPlayer) {
        EventBus.$emit('SALARY_CAP_PICK_PLAYER', this.player);
      }
    },

    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },

    selectPlayer() {
      // NFL doesn't have "selected" player, so treat this like the plus/minus button instead
      if (this.contest.format == 'DAILY') {
        this.togglePlayer();
        return;
      }

      EventBus.$emit('SALARY_CAP_SELECT_PLAYER', this.player);
    },
  },
};
</script>

<style lang="scss" scoped>
  td {
    padding: 8px 4px 8px 4px;
    min-width: 80px;
    border-bottom: 1px solid var(--obcolor-background-2);
    cursor: pointer;
  }

  .playerName {
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .statusLoading {
    width: 16px;
    margin-top: -1px;
    margin-right: 6px;
    margin-left: -3px;
    color: var(--obcolor-font-secondary);
  }

  .playerStatus {
    margin-top: -2px;
    margin-right: 8px;
  }

  .pickedPlayer {
    color: var(--obcolor-text-faded) !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 20px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .scheduleBox {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    background: var(--obcolor-background-2);
    // border: 1px solid var(--obcolor-background-2);
    min-height: 47px;
    font-size: 12px;
    font-weight: bold;
  }
  .logoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-font-secondary);

    div {
      margin-left: -11px;
      margin-right: 2px;
      margin-top: 5px;
    }
  }

  .oprk {
    font-weight: bold;
  }

  .gameTimeCol {
    white-space: nowrap;
  }

  .endSection {
    border-left: 2px solid var(--obcolor-background-2)
  }

  .btnCol {
    width: 20px;
    min-width: 20px;
    padding-right: 10px;
    text-align: right;
    .addBtn {
      font-size: 22px;
      color: var(--obcolor-ob-blue);
    }

    .addBtn.disabledBtn {
      color: var(--obcolor-text-faded);
    }
  }
</style>