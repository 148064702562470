<template>
  <div class="feedBox">
    <div class="feedHeader">
      <!-- Chat Disabled -->
      <div v-if="!chatEnabled" style="font-weight: bold; flex: 1; display: flex; align-items: center;">
        Feed
      </div>

      <!-- Selected Feed | Chat -->
      <div v-else style="font-weight: bold; flex: 1; display: flex; align-items: center;">
        <div class="selectableField" @click="selectDataType('feed')" :style="selectedData != 'feed' ? 'color: var(--obcolor-font-secondary)' : ''">Feed</div>
        <div style="margin: 0 5px;">|</div>
        <!-- Chat temporarily disabled -->
      </div>

      <!-- Content -->
      <template v-if="selectedData == 'feed'">
        <div @click="setFilter(true)" class="ob-link" :style="filterMyPlayers ? 'color: var(--obcolor-font-primary); font-weight:bold;' : ''">
          My Players
        </div>
        <div style="margin: 0 5px; color: var(--obcolor-font-secondary);">|</div>
        <div @click="setFilter(false)" class="ob-link" :style="!filterMyPlayers ? 'color: var(--obcolor-font-primary); font-weight:bold;' : ''">
          All
        </div>
        <div class="filterBtn" @click="toggleExtraFilters()">
          <i class="fa fa-filter" :style="isExtraFilterSet() ? 'color: var(--obcolor-ob-blue) !important;' : ''"></i>
        </div>
        <div v-if="showExtraFilters" class="extraFilters" v-on-clickaway="() => showExtraFilters = false">
          <div style="display: flex;">
            <FilterButton v-for="(sport, index) in extraFilters.sport"
              :selected="sport.active"
              :label="sport.name" :sport="sport.name"
              :key="index" @click="setExtraFilter('sport', sport.name, !sport.active)"
            />
          </div>
          <div style="display: flex; align-items: center; padding: 5px 0; border-bottom: 1px solid var(--obcolor-background-3);" v-for="(type, index) in extraFilters.type" :key="index">
            <div style="flex: 1; font-size: 14px;">{{ type.name }}</div>
            <Checkbox v-model="type.active" type="switch" @input="setExtraFilter('type', type.name, type.active)" />
          </div>
        </div>
      </template>
    </div>
    <div class="feedBody ob-scroll">
      <!-- Loading -->
      <div v-if="!feedsLoaded" class="loadingBox">
        <ObLoading />
      </div>
      <!-- My Players News -->
      <div v-else-if="filterMyPlayers" ref="myNewsContainer">
        <FeedEntry v-for="entry in filteredMyNews" :key="entry.id"
          :entry="entry" :contestIds="myplayers[entry.player.id]"
        />
      </div>
      <!-- All News -->
      <div v-else ref="allNewsContainer">
        <FeedEntry v-for="entry in filteredAllNews" :key="entry.id"
          :entry="entry" :contestIds="myplayers[entry.player.id]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ObFeedsApi from '@/api/ObFeedsApi';
import FeedEntry from './FeedEntry.vue';
import ObLoading from '@/components/ObLoading.vue';
import FilterButton from '@/components/buttons/FilterButton';
import Checkbox from '@/components/Checkbox';
import {mixin as clickaway} from 'vue-clickaway2';

export default {

  components: {
    FeedEntry,
    ObLoading,
    FilterButton,
    Checkbox,
  },

  mixins: [clickaway],

  data: function() {
    return {
      // Chat temporarily disabled
      chatEnabled: false,

      allNews: [],
      myNews: [],
      myplayers: {},
      filterMyPlayers: true,
      filterCookieKey: 'ob-feeds-filter-myplayers',
      extraFilterCookieKey: 'ob-feeds-filter-extra',
      feedsLoaded: false,

      selectedData: 'feed',

      showExtraFilters: false,
      extraFilters: {
        sport: [
          {name: 'NHL', active: false},
          {name: 'NFL', active: false},
          {name: 'MLB', active: false},
          {name: 'NBA', active: false},
        ],
        type: [
          {name: 'Scoring Plays', active: false},
          {name: 'Status', active: false},
          {name: 'News', active: false},
          {name: 'Transaction', active: false},
        ],
      },
    };
  },

  computed: {
    filteredMyNews() {
      const self = this;
      return this.myNews.filter((entry) => {
        let noSports = true;
        let noTypes = true;
        for (const sIndex in self.extraFilters.sport) {
          if (self.extraFilters.sport[sIndex].active) {
            noSports = false;
          }
        }

        for (const tIndex in self.extraFilters.type) {
          if (self.extraFilters.type[tIndex].active) {
            noTypes = false;
          }
        }

        if (!noSports) {
          for (const sIndex in self.extraFilters.sport) {
            if (self.extraFilters.sport[sIndex].name == entry.sport && !self.extraFilters.sport[sIndex].active) {
              return false;
            }
          }
        }

        if (!noTypes) {
          let typeName = 'News';

          switch (entry.news.type) {
            case 'News': case 'Tweet':
              typeName = 'News';
              break;
            case 'Status Change':
              typeName = 'Status';
              break;
            case 'Transaction': case 'Trade':
              typeName = 'Transaction';
              break;
            case 'Game Update':
              typeName = 'Scoring Plays';
              break;
          }

          for (const tIndex in self.extraFilters.type) {
            if (self.extraFilters.type[tIndex].name == typeName && !self.extraFilters.type[tIndex].active) {
              return false;
            }
          }
        }

        return true;
      });
    },

    filteredAllNews() {
      const self = this;
      return this.allNews.filter((entry) => {
        let noSports = true;
        let noTypes = true;
        for (const sIndex in self.extraFilters.sport) {
          if (self.extraFilters.sport[sIndex].active) {
            noSports = false;
          }
        }

        for (const tIndex in self.extraFilters.type) {
          if (self.extraFilters.type[tIndex].active) {
            noTypes = false;
          }
        }

        if (!noSports) {
          for (const sIndex in self.extraFilters.sport) {
            if (self.extraFilters.sport[sIndex].name == entry.sport && !self.extraFilters.sport[sIndex].active) {
              return false;
            }
          }
        }

        if (!noTypes) {
          let typeName = 'News';

          switch (entry.news.type) {
            case 'News': case 'Tweet':
              typeName = 'News';
              break;
            case 'Status Change':
              typeName = 'Status';
              break;
            case 'Transaction': case 'Trade':
              typeName = 'Transaction';
              break;
            case 'Game Update':
              typeName = 'Scoring Plays';
              break;
          }

          for (const tIndex in self.extraFilters.type) {
            if (self.extraFilters.type[tIndex].name == typeName && !self.extraFilters.type[tIndex].active) {
              return false;
            }
          }
        }

        return true;
      });
    },
  },

  created() {
    this.selectedData = 'feed';
    this.loadFilterSettings();
    this.getFeedsFromStore();
    this.loadFeeds();
    this.$SocketController.startRoom('FSP_FEED', this.onSocketBroadcast);
  },

  destroyed() {
    this.$SocketController.stopRoom('FSP_FEED', this.onSocketBroadcast);
  },

  methods: {
    loadFilterSettings() {
      const filterCookie = localStorage.getItem(this.filterCookieKey);
      if (filterCookie !== null) {
        this.filterMyPlayers = filterCookie === 'true';
      }

      let extraFilterCookie = localStorage.getItem(this.extraFilterCookieKey);
      if (extraFilterCookie != null) {
        extraFilterCookie = JSON.parse(extraFilterCookie);

        // Set any values in the filter list to the cookie values if the cookie values are present
        // This lets us update the filter list without the old values from the cookie overriding them
        for (const key in this.extraFilters) {
          for (const filter in this.extraFilters[key]) {
            if (extraFilterCookie[key] && extraFilterCookie[key][filter] != null && extraFilterCookie[key][filter].active) {
              this.extraFilters[key][filter] = extraFilterCookie[key][filter];
            }
          }
        }
      }
    },

    getFeedsFromStore() {
      const nf = this.$store.getters.newsFeed;
      if (nf) {
        this.allNews = nf.news;
        this.myNews = nf.mynews;
        this.myplayers = nf.myplayers;
        this.feedsLoaded = true;
      }
    },

    loadFeeds() {
      ObFeedsApi.getNews()
          .then((data) => {
          // If no news to show for my team, default to show all
            if (this.filterMyPlayers && data.mynews.length == 0) {
              this.filterMyPlayers = false;
            }
            this.allNews = data.news;
            this.myNews = data.mynews;
            this.myplayers = data.myplayers;
            this.feedsLoaded = true;
            this.$store.commit('setNewsFeed', data);
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    onSocketBroadcast(data) {
      if (this.feedsLoaded && data != null && data.type === 'FSP_FEED' && data.json) {
        // Add to all news section
        this.allNews.unshift(data.json);

        // Check player id to determine if news should also appear in myNews
        if (data.json.player && data.json.player.id) {
          const pid = data.json.player.id;
          if (this.myplayers[pid]) {
            this.myNews.unshift(data.json);
          }
        }
      }
    },

    setFilter(value) {
      this.filterMyPlayers = value;
      localStorage.setItem(this.filterCookieKey, value);
      this.$nextTick(() => {
        if (this.filterMyPlayers) {
          this.$refs.myNewsContainer.scrollTop = 0;
        } else {
          this.$refs.allNewsContainer.scrollTop = 0;
        }
      });
    },

    toggleExtraFilters() {
      this.showExtraFilters = !this.showExtraFilters;
    },

    setExtraFilter(key, name, value) {
      this.extraFilters[key] = this.extraFilters[key].map((filter) => {
        if (filter.name == name) {
          filter.active = value;
        }

        return filter;
      });

      this.extraFilters[key].active = value;

      localStorage.setItem(this.extraFilterCookieKey, JSON.stringify(this.extraFilters));
    },

    isExtraFilterSet() {
      for (const sIndex in this.extraFilters.sport) {
        if (this.extraFilters.sport[sIndex].active) {
          return true;
        }
      }

      for (const tIndex in this.extraFilters.type) {
        if (this.extraFilters.type[tIndex].active) {
          return true;
        }
      }

      return false;
    },

    selectDataType(type) {
      this.selectedData = type;
    },
  },

};
</script>

<style lang="scss" scoped>
.ob-link {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
}

.loadingBox {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}

.feedBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.feedHeader {
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.selectableField {
  cursor: pointer;
}

.feedBody {
  overflow-y: scroll;
  flex: 1;
}

.filterBtn {
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: -7px;
  margin-right: -5px;
  margin-top: -5px;
  padding: 5px;
}

.filterBtn:hover i {
  color: var(--obcolor-font-primary);
}

.filterBtn i {
  color: var(--obcolor-font-secondary);
}

.extraFilters {
  position: absolute;
  top: 37px;
  z-index: 100;
  left: 0;
  width: 100%;
  background: var(--obcolor-background-4);
  padding: 0 8px;
  box-sizing: border-box;
}

.filterBtnContainer:not(:last-child) {
  margin-right: 5px;
}
</style>
