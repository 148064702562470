<template>
  <div class="draftContainer" @mousemove="mouseMoved">
    <!-- Loading -->
    <div v-if="draftJSON == null || players == null" class="loadingBox">
      <ObLoading />
    </div>

    <!-- Draft -->
    <template v-else>
      <div style="height: 100%;">
        <div class="picksContainer" :style="'top: ' + picksTopPos">
          <div class="menuArrow" :style="menuOpen ? 'opacity: 0;' : ''">
            <i class="fas fa-chevron-down"></i>
          </div>
          <DraftPicks :draftJSON="draftJSON" :curUser="curUser" :users="users"
            :serverOffset="serverOffset"
          />
        </div>
      </div>
      <div class="columnLayout" :style="'top: ' + contentTopPos">
        <div class="col1">
          <DraftAutoPick :autoPickOn="autoPickOn" :teamRosters="teamRosters"
            :pickQueue="filteredPickQueue" :players="players" :draftJSON="draftJSON"
            :curUser="curUser"
          />
          <div class="ob-box ob-scroll queueContainer">
            <div>Queue</div>
            <DraftQueue class="draftQueueWide"
              :pickQueue="filteredPickQueue"
              :pickedPlayerIds="draftJSON.pickedPlayers"
              :sport="sport"
              :draftState="draftState"
            />
          </div>
          <div class="openInfoBtn" style="padding: 10px;" @click="openDraftInfo()">
            <i class="fa fa-info-circle" style="margin-right: 5px;"></i> Contest Information
          </div>
        </div>
        <div class="col2">
          <div class="ob-box draftSelected">
            <DraftSelected :draftState="draftState"
              :sport="sport"
              :teamRosters="teamRosters"
              :curUserId="curUser"
            />
          </div>
          <div class="ob-box" style="overflow: hidden; flex: 1;">
            <DraftPlayersTable :players="players"
              :selectedPlayer="selectedPlayer"
              :pickQueueIds="pickQueue !== null ? pickQueue.ids : {}"
              :roster="teamRosters !== null && curUser !== null ? teamRosters[curUser] : []"
              :teamRosters="teamRosters"
              :curUserId="curUser"
              :pickedPlayerIds="draftJSON.pickedPlayers"
              :numWeeks="draftJSON.numWeeks"
              :sport="sport"
              :draftState="draftState"
            />
          </div>
        </div>
        <div class="col3">
          <div class="draftStartBox" v-if="draftJSON.state === 'NOT_STARTED'">
            <div>Draft Starting In</div>
            <div>
              <CountdownTimer class="countdownTimer" :end="draftJSON.startTime" :min="0"
                :clockFormat="true" :serverOffset="serverOffset"
              />
            </div>
          </div>
          <div class="draftEndBox" v-else-if="draftJSON.state == 'COMPLETE'">
            <router-link to="/dashboard" class="ob-btn" style="height: max-content; margin-right: 15px;">Dashboard</router-link>
            <button v-if="draftJSON.matchups != null" class="ob-btn" style="height: max-content;"
              @click="openDraftSummary()"
            >
              Draft Summary
            </button>
          </div>
          <div class="ob-box resultsContainer" style="margin-bottom: 50px;">
            <div class="rcolOptions">
              <div @click="rightColTab = 'results'"
                class="rcolTab"
                :class="{tabActive: rightColTab === 'results'}"
              >
                Results
              </div>
              <div @click="rightColTab = 'queue'"
                class="rcolTab queueTab"
                :class="{tabActive: rightColTab === 'queue'}"
              >
                Queue
              </div>
            </div>
            <DraftResults class="draftResults" v-show="rightColTab === 'results'"
              :draftJSON="draftJSON"
              :users="users"
              :curUser="curUser"
              :teamRosters="teamRosters"
              :userInfo="userInfo"
              :sport="sport"
              :draftState="draftState"
            />
            <!-- autopick settings in queue tab --->
            <div v-show="rightColTab === 'queue'" class="openInfoBtn hideSmall" style="padding: 18px 18px 0 18px;"
              @click="openDraftInfo()"
            >
              <i class="fa fa-info-circle" style="margin-right: 5px;"></i> Contest Information
            </div>
            <DraftAutoPick class="autoPick" v-show="rightColTab === 'queue'"
              :autoPickOn="autoPickOn" :teamRosters="teamRosters" :pickQueue="filteredPickQueue"
              :players="players" :draftJSON="draftJSON" :curUser="curUser"
            />
            <DraftQueue class="draftQueue ob-scroll"
              v-show="rightColTab === 'queue'"
              :pickQueue="filteredPickQueue"
              :pickedPlayerIds="draftJSON.pickedPlayers"
              :sport="sport" :draftState="draftState"
            />
          </div>
        </div>
      </div>
    </template>
    <DraftSummaryModalView />
    <DraftBoardModal :draftJSON="draftJSON" :curUser="curUser" :users="users"
      :serverOffset="serverOffset"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObDraftApi from '@/api/ObDraftApi';
import DraftAutoPick from './DraftAutoPick';
import DraftPlayersTable from './DraftPlayersTable';
import DraftResults from './DraftResults';
import DraftQueue from './DraftQueue';
import DraftSelected from './DraftSelected';
import DraftPicks from './DraftPicks';
import DraftSummaryModalView from '@/views/Draft/DraftSummaryModal/DraftSummaryModalView';
import CountdownTimer from '@/components/CountdownTimer';
import Vue from 'vue';
import ObLoading from '@/components/ObLoading';
import DraftBoardModal from '@/views/Draft/DraftSummaryModal/DraftBoardModal';


export default {
  name: 'Draft',

  components: {
    DraftPlayersTable,
    DraftResults,
    DraftQueue,
    DraftSelected,
    DraftPicks,
    CountdownTimer,
    DraftSummaryModalView,
    DraftAutoPick,
    ObLoading,
    DraftBoardModal,
  },

  data: function() {
    return {
      draftId: null,
      draftJSON: null,
      players: null,
      selectedPlayer: null,
      sport: null,
      teamRosters: null,
      users: null,
      curUser: null,
      pickQueue: null,
      userInfo: null,
      autoPickOn: false,
      draftTimeout: null,

      socketDraftId: null,

      rightColTab: 'results',
      mobileTab: 'draft',

      picksTopPos: '5px',
      contentTopPos: '15px',
      menuOpen: false,
      pickInProgress: false,

      updateNumber: -1,
      serverOffset: 0,
    };
  },

  created() {
    this.routerDraftId = this.$route.params.draftId;
    if (this.routerDraftId != null) {
      this.draftId = this.routerDraftId;
    }
    this.syncSystemClock();
    this.loadDraft(true);
    this.loadPickQueue();
    this.loopLoadDraft(30000);

    // New Socket Setup
    EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);

    EventBus.$on('QUEUE_AUTOPICK_CHANGED', this.changeAutopick);
    EventBus.$on('QUEUE_PLAYER_SELECTED', this.changePickQueue);
    EventBus.$on('QUEUE_ORDER_CHANGED', this.changePickQueueOrder);
    EventBus.$on('PLAYER_SELECTED', this.playerSelected);
    EventBus.$on('PLAYER_DRAFTED', this.playerDrafted);
  },

  destroyed() {
    // Stops getting draft every 30 seconds when this component is destroyed.
    // Without this, if we went to the draft page and left, the getDraft() function would be constantly getting called unnecessarily.
    clearTimeout(this.draftTimeout);

    this.$SocketController.unsubscribeFromRoom(this.socketDraftId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);

    EventBus.$off('QUEUE_AUTOPICK_CHANGED', this.changeAutopick);
    EventBus.$off('QUEUE_PLAYER_SELECTED', this.changePickQueue);
    EventBus.$off('QUEUE_ORDER_CHANGED', this.changePickQueueOrder);
    EventBus.$off('PLAYER_SELECTED', this.playerSelected);
    EventBus.$off('PLAYER_DRAFTED', this.playerDrafted);

    EventBus.$emit('LEAGUE_UNLOADED');
  },

  computed: {
    filteredPickQueue() {
      if (!this.pickQueue) {
        return null;
      }
      if (!this.draftJSON || !this.draftJSON.pickedPlayers) {
        return this.pickQueue;
      }

      return {players: this.pickQueue.players.filter((player) => {
        if (this.draftJSON.pickedPlayers[player.id]) {
          return false;
        }

        return !this.teamRosterIsFull(player);
      }),
      ids: this.pickQueue.ids,
      };
    },
    draftState() {
      if (!this.draftJSON) {
        return {};
      }
      return {
        isMyTurn: this.draftJSON.turn === this.curUser,
        selectedPlayer: this.selectedPlayer,
        pickedPlayerIds: this.draftJSON.pickedPlayers,
        pickInProgress: this.pickInProgress,
        pickQueue: this.filteredPickQueue,
        roster: this.teamRosters[this.curUser],
      };
    },
  },

  watch: {
    draftState(newVal) {
      EventBus.$emit('DRAFT_STATE_CHANGED', newVal);
    },

    draftJSON(newVal, oldVal) {
      if (oldVal != null && newVal != null && oldVal.state != null && oldVal.state != 'COMPLETE' && newVal.state == 'COMPLETE') {
        EventBus.$emit('CLOSE_DRAFT_BOARD_MODAL');
        EventBus.$emit('OPEN_DRAFT_SUMMARY_MODAL', this.draftJSON, this.teamRosters, this.users, this.curUser, true);
        // Stop getting draft every 30 seconds
        clearTimeout(this.draftTimeout);
      }
    },

    '$route.params.draftId'(newVal, oldVal) {
      if (oldVal != null && newVal != null) {
        this.initDraftPage();
      }
    },
  },

  methods: {
    initDraftPage() {
      if (this.draftTimeout != null) {
        clearTimeout(this.draftTimeout);
      }
      if (this.socketDraftId != null) {
        this.$SocketController.unsubscribeFromRoom(this.socketDraftId);
      }
      this.clearDraftData();

      this.routerDraftId = this.$route.params.draftId;
      if (this.routerDraftId != null) {
        this.draftId = this.routerDraftId;
      }

      this.syncSystemClock();
      this.loadDraft(true);
      this.loadPickQueue();
      this.loopLoadDraft(30000);
    },

    clearDraftData() {
      this.draftId = null;
      this.draftJSON = null;
      this.players = null;
      this.selectedPlayer = null;
      this.sport = null;
      this.teamRosters = null;
      this.users = null;
      this.curUser = null;
      this.pickQueue = null;
      this.userInfo = null;
      this.autoPickOn = false;
      this.draftTimeout = null;

      this.socketDraftId = null;

      this.rightColTab = 'results';
      this.mobileTab = 'draft';
      this.menuOpen = false;
      this.pickInProgress = false;

      this.updateNumber = -1;
      this.serverOffset = 0;
    },

    teamRosterIsFull(player) {
      if (player === null) {
        return false;
      }
      for (const rosterIndex in this.teamRosters[this.curUser]) {
        const roster = this.teamRosters[this.curUser][rosterIndex];
        if (roster.short === player.draftPos && roster.num === roster.players.length) {
          return true;
        }
      }
      return false;
    },
    syncSystemClock() {
      const reqTime = new Date().getTime();
      ObDraftApi.getServerTime()
          .then((data) => {
            const respTime = new Date().getTime();
            const networkTime = (respTime - reqTime) / 2;
            let offset = new Date().getTime() - parseFloat(data);
            offset = offset - networkTime;
            this.serverOffset = offset;
          })
          .catch((error) => {
            this.serverOffset = 0;
          });
    },

    changeAutopick(isAutopick) {
      if (isAutopick !== this.autoPickOn) {
        this.autoPickOn = isAutopick;
        ObDraftApi.updateDraftSettings(this.draftId, this.autoPickOn);
      }
    },

    mouseMoved(event) {
      if (event.pageY < 35) {
        this.menuOpen = true;
        this.picksTopPos = 'calc(var(--ob-nav-offset) - 15px)';
        this.contentTopPos = 'calc(var(--ob-nav-offset) - 5px)';
      } else if (event.pageY > 100) {
        this.menuOpen = false;
        this.picksTopPos = '5px';
        this.contentTopPos = '15px';
      }
    },

    playerSelected(player) {
      this.selectedPlayer = player;
    },

    playerDrafted(player) {
      const self = this;
      this.pickInProgress = true;
      ObDraftApi.draftPlayer(this.draftId, player.id)
          .then((response) => {
            self.updateDraft(response.data);
            self.pickInProgress = false;
          })
          .catch((error) =>{
            self.pickInProgress = false;
          });
    },

    // --- Draft Updates ---
    loopLoadDraft(time) {
      const self = this;
      this.draftTimeout = setTimeout(function() {
        self.loadDraft(false);
        self.loopLoadDraft(time);
      }, time);
    },

    loadDraft(loadPlayers) {
      if (this.draftId == null) {
        return;
      }
      ObDraftApi.loadDraft(this.draftId, loadPlayers)
          .then((data) => {
            if (this.routerDraftId == data.draft.id) {
              this.updateDraft(data);
              if (loadPlayers) {
                this.initSocket();
                if (data.authUser && data.userInfo) {
                  this.autoPickOn = data.userInfo[data.authUser] != null && data.userInfo[data.authUser].autopick;
                }
                // Open chat
                if (data.draft && data.draft.leagueId) {
                  EventBus.$emit('OPEN_LEAGUE_CHAT', data.draft.leagueId);
                  EventBus.$emit('LEAGUE_LOADED', data.draft.leagueId);
                }
              }

              if (data.draft && data.draft.state == 'COMPLETE') {
                clearTimeout(this.draftTimeout);
              }
            }
          });
    },

    updateDraft(data) {
      if (data.updateNum != null) {
        if (data.updateNum < this.updateNumber) {
          return;
        }
        this.updateNumber = data.updateNum;
      }
      if (data.authUser) {
        this.curUser = data.authUser;
      }
      if (data.draft) {
        this.draftJSON = data.draft;
        this.sport = this.draftJSON.sport;
      }
      if (data.players) {
        this.players = data.players;
        if (this.selectedPlayer == null) {
          this.selectedPlayer = data.players[0];
        }
      }
      if (data.teamrosters) {
        this.teamRosters = data.teamrosters;
      }
      if (data.users) {
        this.users = data.users;
      }
      if (data.userInfo) {
        this.userInfo = data.userInfo;
      }

      if (data.userInfoId && data.userInfoEntry && this.userInfo != null) {
        this.userInfo[data.userInfoId] = data.userInfoEntry;
      }
    },
    openDraftSummary() {
      EventBus.$emit('OPEN_DRAFT_SUMMARY_MODAL', this.draftJSON, this.teamRosters, this.users, this.curUser);
    },

    // --- Socket Connection ---
    initSocket() {
      this.chatLog = [];
      if (this.socketDraftId !== null) {
        this.$SocketController.unsubscribeFromRoom(this.socketDraftId);
      }
      this.socketDraftId = this.draftId;
      this.$SocketController.subscribeToRoom(this.draftId);
    },
    onSocketBroadcast(json) {
      const id = json.id;
      if (id != null && id === this.draftId) {
        this.updateDraft(json);
      }
    },

    // --- Player Queue ---
    loadPickQueue() {
      const initDraftId = this.draftId;

      if (this.draftId == null) {
        return;
      }
      ObDraftApi.loadPickQueue(this.draftId)
          .then((data) => {
            if (this.routerDraftId == initDraftId) {
              this.pickQueue = data;
            }
          });
    },

    changePickQueueOrder(newPickQueue) {
      const playerIds = newPickQueue.players.map((player) => player.id);

      this.pickQueue = {ids: {}, players: []};
      this.pickQueue.ids = newPickQueue.ids;
      this.pickQueue.players = newPickQueue.players;

      ObDraftApi.updatePickQueue(this.draftId, playerIds);
    },

    changePickQueue(player) {
      const tempPickQueue = this.pickQueue;
      if (tempPickQueue.ids[player.id] !== undefined) {
        Vue.delete(tempPickQueue.ids, player.id);
        tempPickQueue.players = tempPickQueue.players.filter(function(tempPlayer) {
          return !tempPlayer.id === player.id;
        });
      } else {
        let highestId = 0;
        for (const idIndex in tempPickQueue.ids) {
          if (highestId < tempPickQueue.ids[idIndex]) {
            highestId = tempPickQueue.ids[idIndex];
          }
        }
        Vue.set(tempPickQueue.ids, player.id, highestId + 1);
        tempPickQueue.players.push(player);
      }

      const playerIds = tempPickQueue.players.map((player) => player.id);
      this.pickQueue = {ids: {}, players: []};
      this.pickQueue.ids = tempPickQueue.ids;
      this.pickQueue.players = tempPickQueue.players;

      ObDraftApi.updatePickQueue(this.draftId, playerIds)
          .catch((_error) =>{
            // Do Nothing
          });
    },
    openDraftInfo() {
      EventBus.$emit('OPEN_DRAFT_LEAGUE_MODAL', this.draftJSON.leagueId);
    },
  },

};
</script>

<style lang="scss" scoped>
.loadingBox {
  text-align: center;
  padding: 20px;
}
.loadingDraft{
  margin-top: 10px; font-size: 14px;
}
.draftContainer {
  height: calc(100% - 150px);
}
.startCountdown {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 3px;
  left: 0;
  padding-left: 15px;
  width: 160px;
  height: 20px;
  z-index: 99;
  color: var(--obcolor-font-primary);
  background: var(--obcolor-background-6);
}
.draftStartBox {
  background: var(--obcolor-ob-blue);
  color: white;
  margin-bottom: 10px;
  height: 60px;
  min-height: 60px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.countdownTimer {
  display: inline-block;
}
.draftEndBox {
  background: var(--obcolor-background-6);
  color: white;
  margin-bottom: 10px;
  height: 60px;
  min-height: 60px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.menuArrow {
  position: absolute;
  font-size: 16px;
  top: 1px;
  left: 0;
  right:0;
  text-align: center;
  color: var(--obcolor-text-faded);
  transition: opacity 500ms;
}

// --- Picks Bar ---
.picksContainer {
  height: 145px;
  background: var(--obcolor-background-6);
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 90;
  transition: 500ms top;
  // position: sticky;
  // top: 75px;
  // z-index: 99;
}
.pickBox {
  width: 140px;
  height: 125px;
  background: var(--obcolor-background-2);
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

// --- Tabs (only at certain width) ---
.rcolOptions {
  display: flex;
  border-radius: 10px;
}
.rcolTab {
  flex: 1;
  padding: 6px;
  padding-left: 10px;
  cursor: pointer;
  background: var(--obcolor-background-2);
  color: var(--obcolor-font-secondary);
}
.rcolTab:first-child {
  border-radius: 5px 0 0 0;
}
.rcolTab:last-child {
  border-radius: 0 5px 0 0;
}
.rcolTab:hover {
  background: var(--obcolor-background-5);
}
.tabActive {
  background: var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
}
.tabActive:hover {
  background: var(--obcolor-background-6);
}

// --- Page Layout ---
.columnLayout {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  margin-top: 150px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 10px;
  bottom: 10px;
  transition: 500ms top;
}
.col1 {
  width: 350px;
  min-width: 350px;
  margin-right: 15px;
}
.col2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.col3 {
  width: 350px;
  min-width: 350px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

// --- Scrollable Content ---
.resultsContainer {
  padding: 0;
  // Applies the border radius to inner components
  overflow: hidden;
  // max-height: calc(100%);
  display: flex;
  flex-direction: column;
}

.draftSelected {
  height: 130px;
  min-width: 580px;
  margin-bottom: 15px;
}
.draftQueueWide {
  margin-top: -20px !important;
}
.draftQueue {
  margin-top: 0px !important;
  padding: 8px !important;
}
.openInfoBtn {
  cursor: pointer;
}
.openInfoBtn:hover {
  color: var(--obcolor-font-secondary);
}
.autoPick {
  padding: 8px;
}

.queueContainer {
  margin-top: 10px;
  max-height: calc(100% - 58px - 28px - 38px);
  overflow: auto;
}
@media only screen and (min-width: 1338px) {
  .queueTab {
    display: none !important;
  }
  .draftResults {
    display: flex !important;
  }
  .hideSmall {
    display: none !important;
  }
  .autoPick {
    display: none !important;
  }
  .draftQueue {
    display: none !important;
  }
  // --- Tabs (only at certain width) ---
  .rcolTab {
    background: var(--obcolor-background-6) !important;
    color: var(--obcolor-font-primary) !important;
    cursor: auto !important;
  }
}
@media only screen and (max-width: 1337px) {
  .col1 {
    display: none;
  }
  .queueTab {
    display: block !important;
  }
}
</style>
