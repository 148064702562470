<template>
  <Modal v-if="isOpen" v-model="isOpen" :disableClickaway="true"
    containerStyle="width: 1000px; height: 800px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-page); overflow: auto !important;"
  >
    <div slot="header" class="modalHeader">
      {{ swapTitleText }}
    </div>

    <div slot="body" class="modalBody">
      <div v-if="isLoading">
        <ObLoading :size="80" />
      </div>
      <!-- First step -->
      <QuickSwapSlateSelect v-else-if="step == 1" :selectedSlate="selectedSlate" :slateList="slateList"
        @selectSlate="selectSlate"
      />
      <QuickSwapPlayerSelect v-else-if="step == 2" :selectedSlate="selectedSlate" :playerListInfo="playerListInfo"
        :swapPlayers="swapPlayers" @selectSwap="selectSwap"
      />
      <QuickSwapConfirm v-else :selectedSlate="selectedSlate"
        :swapPlayers="swapPlayers" :validationState="validationState" :submitState="submitState"
      />
    </div>

    <div slot="footer" class="modalFooter">
      <template v-if="step < 4">
        <button class="optionBtn ob-btn-grey" :class="{disabled: !canClickBack}" @click="goBack">Back</button>
        <button class="optionBtn ob-btn-grey disabled" v-if="!canSubmit">{{ submitText }}</button>
        <button class="optionBtn ob-btn" v-else @click="nextStep">{{ submitText }}</button>
      </template>
      <template v-else>
        <button class="optionBtn ob-btn-grey" @click="makeNewSwap">Make Another Swap</button>
        <button class="optionBtn ob-btn" @click="goToLobby">Go to Lobby</button>
      </template>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import QuickSwapSlateSelect from './QuickSwapSlateSelect';
import QuickSwapPlayerSelect from './QuickSwapPlayerSelect';
import QuickSwapConfirm from './QuickSwapConfirm';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    QuickSwapSlateSelect,
    QuickSwapPlayerSelect,
    QuickSwapConfirm,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      isLoading: true,
      selectedSlate: null,
      slateList: null,

      playerListInfo: null,
      swapPlayers: {
        drop: null,
        add: null,
      },

      validationState: {
        isSending: false,
        result: null,
      },

      submitState: {
        isSending: false,
        result: null,
      },

      selectedTeams: [],
    };
  },

  watch: {
    isOpen(newVal) {
      // Reset the data when modal is closed
      // Data is also reset when opening the modal, but that occurs before the isOpen value is set
      if (!newVal) {
        this.resetData();
      }
    },
  },

  created() {
    EventBus.$on('OPEN_QUICK_SWAP_MODAL', this.openModal);
    EventBus.$on('QUICK_SWAP_SET_SELECTED_TEAMS', this.setSelectedTeams);
  },

  destroyed() {
    EventBus.$off('OPEN_QUICK_SWAP_MODAL', this.openModal);
    EventBus.$off('QUICK_SWAP_SET_SELECTED_TEAMS', this.setSelectedTeams);
  },

  computed: {
    swapTitleText() {
      let title = 'Quick Swap - ';
      if (!this.selectedSlate) {
        return title + 'Select Slate';
      }

      title += this.selectedSlate.name;

      return title;
    },

    canClickBack() {
      return this.selectedSlate != null;
    },

    canSubmit() {
      const noTeamsSelected = this.validationState.result && !this.selectedTeams.length;
      const isSending = this.validationState.isSending || this.submitState.isSending;
      const hasAddAndDrop = this.swapPlayers?.drop && this.swapPlayers?.add;
      return !noTeamsSelected && !isSending && hasAddAndDrop;
    },

    submitText() {
      if (this.step > 2) {
        return 'Submit Swap';
      }
      return 'Verify Swap';
    },

    step() {
      if (this.selectedSlate == null) {
        return 1;
      } else if (!this.validationState.result) {
        return 2;
      } else if (!this.submitState.result) {
        return 3;
      }
      return 4;
    },
  },

  methods: {
    resetData() {
      this.isLoading = true;
      this.selectedSlate = null;
      this.slateList = null;
      this.playerListInfo = null;
      this.swapPlayers.add = null;
      this.swapPlayers.drop = null;
      this.validationState.isSending = false;
      this.validationState.result = null;
      this.submitState.isSending = false;
      this.submitState.result = null;
      this.selectedTeams = [];
    },

    makeNewSwap() {
      this.resetData();
      this.getQuickSwapSlates();
    },

    openModal() {
      this.resetData();
      this.isOpen = true;
      this.getQuickSwapSlates();
    },

    closeModal() {
      this.isOpen = false;
    },

    getQuickSwapSlates() {
      this.slateList = [];
      ObSalaryCapApi.getQuickSwapSlates()
          .then((response) => {
            this.isLoading = false;
            this.slateList = response.gameSlates;
          }).catch((_error) => {
            this.isLoading = false;
            // Suppress error message
          });
    },

    loadPlayers() {
      if (!this.selectedSlate) {
        return;
      }
      this.playerListInfo = null;
      ObSalaryCapApi.getQuickSwapPlayers(this.selectedSlate.id)
          .then((response) => {
            this.playerListInfo = response;
          }).catch((_error) => {
            // Suppress error message
          });
    },

    nextStep() {
      if (this.step == 2) {
        this.validateSwap();
      } else {
        this.submitSwap();
      }
    },

    validateSwap() {
      this.validationState.isSending = true;
      ObSalaryCapApi.validateQuickSwap(this.selectedSlate.id, this.swapPlayers)
          .then((response) => {
            this.validationState.result = response;
            this.validationState.isSending = false;
          }).catch((_error) => {
            this.validationState.isSending = false;
            // Suppress error message
          });
    },

    submitSwap() {
      this.submitState.isSending = true;
      ObSalaryCapApi.submitQuickSwap(this.selectedTeams, this.swapPlayers)
          .then((response) => {
            this.submitState.result = response;
            this.submitState.isSending = false;
            EventBus.$emit('QUICK_SWAP_SUCCESS');
          }).catch((_error) => {
            this.submitState.isSending = false;
            // Suppress error message
          });
    },

    setSelectedTeams(selectedTeams) {
      this.selectedTeams = selectedTeams;
    },

    selectSlate(slate) {
      this.selectedSlate = slate;
      this.playerListInfo = null;
      this.loadPlayers();
    },

    selectSwap(toDrop, toAdd) {
      this.swapPlayers.drop = toDrop;
      this.swapPlayers.add = toAdd;
    },

    goBack() {
      switch (this.step) {
        case 1:
          break;
        case 2:
          this.selectedSlate = null;
          this.swapPlayers.add = null;
          this.swapPlayers.drop = null;
          break;
        case 3:
          this.validationState.result = null;
          break;
        default:
          return;
      }
    },

    goToLobby() {
      if (this.$route.name !== 'lobby') {
        this.$router.push('/lobby');
      }
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
}

.modalBody {
  display: flex;
  justify-content: center;
  flex: 1;
  min-height: 0;
}

.modalFooter {
  padding: 0 15px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;

  > .optionBtn {
    font-size: 14px;
  }

  > .optionBtn.disabled {
    color: var(--obcolor-font-secondary);
    cursor: not-allowed;
  }
}
</style>