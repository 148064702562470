<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 1200px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);"
  >
    <div slot="body" style="width: calc(100% - 1px)">
      <div style="display: flex;">
        <div style="flex: 1;">
          <table aria-label="Lineup Editor Calendar">
            <tr>
              <th colspan="3" class="titleHeader">Calendar View</th>
              <th style="width: 25px;">GR</th>
              <th style="width: 45px;">Salary</th>
              <th style="width: 45px;">sAVG</th>
              <th style="width: 45px;">PROJ</th>
              <th v-for="(day, index) in contest.days" :key="index" class="dayHeader">
                <div>{{ $moment(day, 'YYYY-MM-DD').format('ddd') }}</div>
                {{ $moment(day, 'YYYY-MM-DD').format('MMM D') }}
              </th>
              <th style="width: 10px;"></th>
            </tr>
          </table>
          <div v-for="(entry, index) in rosterLimits" :key="index" class="posGroup">
            <div style="flex: 1;">
              <table aria-label="Calender Modal Player">
                <CalendarModalPlayerRow v-for="(player, index1) in picks[entry.short]" :key="index1"
                  :player="getPlayerOrSwap(player)" :swap="getSwap(player)"
                  :statusMap="statusMap"
                  :status="statusMap && statusMap[player.id] ? statusMap[player.id].status : null"
                  :contest="contest"
                />
              </table>
            </div>
            <div class="filledGames" :style="'background: ' + getGameLimitColor(entry.short) + '; color: ' + getGameLimitColor(entry.short, true) + ';'">
              <div :style="'border-bottom: 1px solid ' + getGameLimitColor(entry.short, true)">{{ getProjGamesByPos(entry.short) }}</div>
              <div>{{ rosterCalc.gameLimits[entry.short] }}</div>
            </div>
          </div>
        </div>
        <div style="margin-left: 4px;">
          <!-- Same height as header -->
          <div style="height: 40px;"></div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import CalendarModalPlayerRow from './CalendarModalPlayerRow';
import ObPlayersApi from '@/api/ObPlayersApi';

export default {
  components: {
    Modal,
    CalendarModalPlayerRow,
  },

  data() {
    return {
      contest: null,
      roster: null,
      picks: null,
      pendingDrops: null,
      pendingSwaps: null,
      grByPosition: null,
      isOpen: false,
      statusMap: {},
    };
  },

  created() {
    EventBus.$on('LINEUP_EDITOR_OPEN_CALENDAR', this.openModal);
  },

  destroyed() {
    EventBus.$off('LINEUP_EDITOR_OPEN_CALENDAR', this.openModal);
  },

  computed: {
    displayGroups() {
      if (!this.contest) {
        return [];
      }

      return this.$SportInfo.getScDisplayGroups(this.contest.sport, this.contest.format == 'DAILY');
    },

    rosterLimits() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },
  },

  methods: {
    resetData() {
      this.contest = null;
      this.picks = null;
      this.pendingDrops = null;
      this.pendingSwaps = null;
      this.statusMap = {};

      this.grByPosition = null;
    },

    openModal(contest, picks, rosterCalc, pendingDrops, pendingSwaps) {
      this.contest = contest;
      this.rosterCalc = rosterCalc;
      this.picks = picks;
      this.pendingDrops = pendingDrops;
      this.pendingSwaps = pendingSwaps;
      this.loadStatuses();
      this.calcGrByPosition();
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.resetData();
    },

    loadStatuses() {
      ObPlayersApi.getGlobalStatusMap(this.contest.sport)
          .then((data) => {
            this.statusMap = data;
          })
          .catch((_error) => {
          // Do nothing
          });
    },

    getGamesRemaining(player) {
      const now = new Date().getTime();
      let gr = 0;
      for (const game of player.games) {
        if (game.ts > now && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && game.tsDH > now && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    calcGrByPosition() {
      const grByPosition = {};

      for (const entry of this.pendingSwaps) {
        let gamesRemaining = grByPosition[entry.pos];
        if (gamesRemaining == null) {
          gamesRemaining = 0;
        }

        if (entry.drop) {
          const gr = this.getGamesRemaining(entry.drop);
          gamesRemaining -= gr;
        }

        if (entry.add) {
          const gr = this.getGamesRemaining(entry.add);
          gamesRemaining += gr;
        }

        grByPosition[entry.pos] = gamesRemaining;
      }

      this.grByPosition = grByPosition;
    },

    getPlayerOrSwap(player) {
      if (this.getSwap(player)) {
        return this.getSwap(player).add != null ? this.getSwap(player).add : player;
      }

      return player;
    },

    getSwap(player) {
      if (this.pendingDrops[player.id] != null) {
        const swapIndex = this.pendingDrops[player.id];
        if (this.pendingSwaps[swapIndex] != null) {
          return this.pendingSwaps[swapIndex];
        }
      }

      return null;
    },

    getProjGamesByPos(pos) {
      if (!this.rosterCalc || !this.grByPosition) {
        return '';
      }

      const pg = this.rosterCalc.projGames[pos];
      const pgChange = this.grByPosition[pos] || 0;
      return pg + pgChange;
    },

    getGameLimitColor(pos, text = false) {
      if (!this.rosterCalc) {
        return '';
      }
      const numGames = this.getProjGamesByPos(pos);
      const maxGames = this.rosterCalc.gameLimits[pos];

      if (numGames > maxGames) {
        return text ? 'white' : 'orange';
      }
      if (numGames < maxGames) {
        return text ? 'white' : 'var(--obcolor-red)';
      }
      return text ? 'var(--obcolor-font-primary)' : 'var(--obcolor-background-2)';
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  font-size: 14px;
  text-align: center;
}

th {
  height: 40px;
  padding: 0 10px;
  color: var(--obcolor-font-secondary);
}

.titleHeader {
  font-size: 16px;
  text-align: left;
  color: var(--obcolor-font-primary);
}

.filledGames {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  width: 20px;
  align-self: stretch;

  div:not(:last-child) {
    margin-bottom: 1px;
  }
}

.dayHeader {
  width: 95px;
  font-size: 14px;
  box-sizing: border-box;
}

.posGroup {
  display: flex;
}

.posGroup:not(:last-child) {
  margin-bottom: 5px;
}
</style>