<template>
  <div class="progressBarContainer">
    <div class="progressSection" v-for="index in numGameSegments()" :key="index"
      :style="'width: ' + 100 / numGameSegments() + '%'"
    >
      <div class="progressSectionFill" :style="'width: ' + getGameProgressByIndex(index) * 100 + '%;'"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: Object,
    fspGame: Object,
    sport: String,
    dayHasDoubleHeader: Boolean,
    disabled: Boolean,
  },

  methods: {
    isPastGame() {
      // Check using FspGame data since it will never be null
      if (this.fspGame.timestamp) {
        return parseInt(this.fspGame.timestamp) < new Date().getTime();
      }
      return false;
    },

    // Gets the progress of the current period
    getSectionProgressFraction(sectionPeriod, periodLengthMins, liveJSON) {
      // Baseball logic
      if (liveJSON.inning) {
        if (liveJSON.outs == null && liveJSON.inning_half == 'top') {
          return 0;
        } else if (liveJSON.outs == null && liveJSON.inning_half == 'bottom') {
          return 0.5;
        }

        if (liveJSON.inning == sectionPeriod && liveJSON.inning_half) {
          if (liveJSON.inning_half == 'top') {
            return parseInt(liveJSON.outs) / 3 * 0.5;
          } else {
            return (parseInt(liveJSON.outs) / 3 * 0.5) + 0.5;
          }
        }

        return liveJSON.inning >= sectionPeriod ? 1 : 0;
      }

      // Every other sport logic
      let periodMins = 0;

      if (liveJSON.period == null) {
        return 0;
      }

      if (liveJSON.period > sectionPeriod) {
        return 1;
      } else if (liveJSON.period < sectionPeriod) {
        return 0;
      }

      if (liveJSON.gametime) {
        try {
          const ms = liveJSON.gametime.split(':');
          periodMins = parseInt(ms[0]) + (parseInt(ms[1]) / 60);

          if (periodMins > periodLengthMins) {
            return 1;
          } else if (periodMins < 0) {
            return 0;
          }

          return (periodLengthMins - periodMins) / periodLengthMins;
        } catch (e) {}
      }

      return 1;
    },

    numGameSegments() {
      switch (this.sport) {
        case 'MLB': return this.dayHasDoubleHeader ? 7 : 9;
        case 'NHL': return 3;
        case 'NFL': return 4;
        case 'NBA': return 4;
      }
      return 1;
    },

    getGameProgressByIndex(index) {
      const game = this.game;

      if (this.disabled) {
        return 1;
      }

      if (game == null || game.live == null) {
        return this.isPastGame() ? 1 : 0;
      }

      if (game.live && game.live.status == 'post-event') {
        return 1;
      }

      if (game.live && game.live.status == 'pre-event') {
        return 0;
      }

      let gameVal = 0;

      if (this.sport === 'MLB' && game.live.inning) {
        gameVal = this.getSectionProgressFraction(index, null, game.live);
      } else if (this.sport === 'NHL' && game.live.period != null) {
        gameVal = this.getSectionProgressFraction(index, 20, game.live);
      } else if (this.sport === 'NFL' && game.live.period != null) {
        gameVal = this.getSectionProgressFraction(index, 15, game.live);
      } else if (this.sport === 'NBA' && game.live.period != null) {
        gameVal = this.getSectionProgressFraction(index, 12, game.live);
      }

      if (gameVal > 1) {
        gameVal = 1;
      } else if (gameVal < 0) {
        gameVal = 0;
      }

      return gameVal;
    },
  },
};
</script>

<style lang="scss" scoped>
  .progressBarContainer {
    overflow: hidden;

    height: 8px;
    width: 100%;
    border-radius: 4px;
    display: flex;
  }

  .progressSection {
    height: 100%;
    background: var(--obcolor-ob-blue);
  }

  .progressSection:not(:first-child) {
    margin-left: 4px;
  }

  .progressSectionFill {
    height: 100%;
    background: var(--obcolor-timeline);
  }
</style>