import TeamInfo from './TeamInfo';
import moment from 'moment';

// List of draftable positions by sport
// Note - This is specifically only used for the PlayerListView.vue file
// (The global list of players that can be viewed from the website at /players)
const POSITIONS_NHL = [
  'G', 'F', 'D',
];
const POSITIONS_NBA = [
  'PG', 'SG', 'SF', 'PF', 'C',
];
const POSITIONS_MLB = [
  'P', 'IF', 'OF',
];
const POSITIONS_NFL = [
  'QB', 'RB', 'WR', 'TE', 'K', 'D',
];

const GUARD = 'G';
const FORWARD = 'F';

const UTILITY = 'Util';
const UTILITY_MULTIPLIER = 'Util (1.5x)';

const FLEX = 'F';
const SUPER_FLEX = 'SF';
const SUPER_FLEX_MULTIPLIER = 'SF (1.5x)';

// Full name conversion for positions
const positionNamesMLB = {
  'SP': 'Starting Pitcher',
  'P': 'Pitcher',
  'C': 'Catcher',
  'DH': 'Designated Hitter',
  '1B': 'First Base',
  '2B': 'Second Base',
  '3B': 'Third Base',
  'SS': 'Shortstop',
  'LF': 'Left Field',
  'CF': 'Center Field',
  'RF': 'Right Field',
  'OF': 'Outfielder',
  'IF': 'Infielder',
};

const positionNamesNFL = {
  'QB': 'Quarterback',
  'WR': 'Wide Receiver',
  'RB': 'Running Back',
  'LB': 'Linebacker',
  'DE': 'Defensive End',
  'DB': 'Defensive Back',
  'OL': 'Offensive Line',
  'OT': 'Offensive Tackle',
  'DL': 'Defensive Line',
  'DT': 'Defensive Tackle',
  'FB': 'Fullback',
  'CB': 'Cornerback',
  'P': 'Punter',
  'TE': 'Tight End',
  'NT': 'Nose Tackle',
  'C': 'Center',
  'G': 'Guard',
  'K': 'Kicker',
  'S': 'Safety',
  'LS': 'Longsnapper',
};

const positionNamesNHL = {
  'C': 'Center',
  'RW': 'Right Wing',
  'LW': 'Left Wing',
  'D': 'Defense',
  'G': 'Goalie',
};

const positionNamesNBA = {
  'C': 'Center',
  'PF': 'Power Forward',
  'SG': 'Shooting Guard',
  'SF': 'Small Forward',
  'PG': 'Point Guard',
  'G': 'Guard',
  'F': 'Forward',
};

// Controls the layout of roster for salary cap lineup builder
const salaryCapDisplayGroups = {
  weekly: {
    NHL: [
      ['F'],
      ['D'],
      ['G'],
    ],
    NFL: [
      ['QB', 'RB'],
      ['WR', 'TE'],
      ['F', 'SF'],
    ],
    MLB: [
      ['IF'],
      ['OF'],
      ['P'],
    ],
    NBA: [
      ['PG'],
      ['SG'],
      ['SF'],
      ['PF'],
      ['C'],
    ],
  },

  daily: {
    NHL: [
      [SUPER_FLEX_MULTIPLIER, 'F', 'D', 'G', 'SF'],
    ],
    NFL: [
      ['QB', 'RB'],
      ['WR', 'TE'],
      [SUPER_FLEX_MULTIPLIER, 'F', 'SF'],
    ],
    MLB: [
      [SUPER_FLEX_MULTIPLIER, 'IF', 'OF', 'P', 'F', 'SF'],
    ],
    NBA: [
      [UTILITY_MULTIPLIER, 'PG', 'SG', GUARD, 'SF', 'PF', FORWARD, 'C', UTILITY],
    ],
  },
};

// Controls the layout of the roster for Daily format contests
const salaryCapDisplayGroupsDaily = {
  NHL: [
    {pos: SUPER_FLEX_MULTIPLIER, label: 'SuperFlex', flexPos: '(F/D/G)'},
    {pos: 'F', label: 'Forward'},
    {pos: 'D', label: 'Defense'},
    {pos: 'G', label: 'Goalie'},
    {pos: 'SF', label: 'SuperFlex', flexPos: '(F/D/G)'},
  ],
  MLB: [
    {pos: SUPER_FLEX_MULTIPLIER, label: 'SuperFlex', flexPos: '(IF/OF/P)'},
    {pos: 'IF', label: 'Infielder'},
    {pos: 'OF', label: 'Outfielder'},
    {pos: 'P', label: 'Pitcher'},
    {pos: 'F', label: 'Flex', flexPos: '(IF/OF)'},
    {pos: 'SF', label: 'SuperFlex', flexPos: '(IF/OF/P)'},
  ],
  NBA: [
    {pos: UTILITY_MULTIPLIER, label: 'Utility', flexPos: '(PG/SG/SF/PF/C)'},
    {pos: 'PG', label: 'Point Guard'},
    {pos: 'SG', label: 'Shooting Guard'},
    {pos: 'G', label: 'Guard', flexPos: '(PG/SG)'},
    {pos: 'SF', label: 'Small Forward'},
    {pos: 'PF', label: 'Power Forward'},
    {pos: 'F', label: 'Forward', flexPos: '(SF/PF)'},
    {pos: 'C', label: 'Center'},
    {pos: UTILITY, label: 'Utility', flexPos: '(PG/SG/SF/PF/C)'},
  ],
  NFL: [
    {pos: SUPER_FLEX_MULTIPLIER, label: 'SuperFlex', flexPos: '(QB/RB/WR/TE)'},
    {pos: 'QB', label: 'Quarterback'},
    {pos: 'RB', label: 'Runningback'},
    {pos: 'WR', label: 'Wide Receiver'},
    {pos: 'TE', label: 'Tight End'},
    {pos: 'F', label: 'Flex', flexPos: '(RB/WR/TE)'},
    {pos: 'SF', label: 'SuperFlex', flexPos: '(QB/RB/WR/TE)'},
  ],
};

const nflFlexPositions = ['RB', 'WR', 'TE'];

const nflSuperFlexPositions = ['QB', 'RB', 'WR', 'TE'];

const mlbFlexPositions = ['IF', 'OF'];

const mlbSuperFlexPositions = ['IF', 'OF', 'P'];

const nhlSuperFlexPositions = ['F', 'D', 'G'];

const nbaSuperFlexPositions = ['G', 'FC'];

const nbaGuardPositions = ['PG', 'SG'];

const nbaForwardPositions = ['SF', 'PF'];

const nbaUtilityPositions = ['PG', 'SG', 'SF', 'PF', 'C'];

const SportInfo = {

  getPositions(sport) {
    switch (sport) {
      case 'NHL': return POSITIONS_NHL;
      case 'NBA': return POSITIONS_NBA;
      case 'NFL': return POSITIONS_NFL;
      case 'MLB': return POSITIONS_MLB;
    }
    return [];
  },

  getPositionName(position, sport) {
    switch (sport) {
      case 'NHL': return positionNamesNHL[position] || position;
      case 'NBA': return positionNamesNBA[position] || position;
      case 'NFL': return positionNamesNFL[position] || position;
      case 'MLB': return positionNamesMLB[position] || position;
    }
    return position;
  },

  getScDisplayGroups(sport, isDaily = false) {
    if (salaryCapDisplayGroups[isDaily ? 'daily' : 'weekly'][sport]) {
      return salaryCapDisplayGroups[isDaily ? 'daily' : 'weekly'][sport];
    }
    return [];
  },

  getScDisplayGroupsDaily(sport) {
    if (salaryCapDisplayGroupsDaily[sport]) {
      return salaryCapDisplayGroupsDaily[sport];
    }
    return [];
  },

  draftPosIsGuard(draftPos, sport) {
    return sport == 'NBA' && nbaGuardPositions.includes(draftPos);
  },

  draftPosIsForward(draftPos, sport) {
    return sport == 'NBA' && nbaForwardPositions.includes(draftPos);
  },

  draftPosIsUtility(draftPos, sport) {
    return sport == 'NBA' && nbaUtilityPositions.includes(draftPos);
  },

  draftPosIsFlex(draftPos, sport) {
    switch (sport) {
      case 'NFL':
        return this.draftPosIsNflFlex(draftPos);
      case 'MLB':
        return mlbFlexPositions.includes(draftPos);
    }
    return false;
  },

  draftPosIsSuperFlex(draftPos, sport) {
    switch (sport) {
      case 'NFL':
        return nflSuperFlexPositions.includes(draftPos);
      case 'MLB':
        return mlbSuperFlexPositions.includes(draftPos);
      case 'NHL':
        return nhlSuperFlexPositions.includes(draftPos);
    }
    return false;
  },

  isDraftPositionMultiplier(draftPos) {
    return draftPos === SUPER_FLEX_MULTIPLIER || draftPos === UTILITY_MULTIPLIER;
  },

  guard() {
    return GUARD;
  },

  forward() {
    return FORWARD;
  },

  utility() {
    return UTILITY;
  },

  utilityMultiplier() {
    return UTILITY_MULTIPLIER;
  },

  flex() {
    return FLEX;
  },

  superFlex() {
    return SUPER_FLEX;
  },

  superFlexMultiplier() {
    return SUPER_FLEX_MULTIPLIER;
  },

  // NFL Flex and SuperFlex helpers
  draftPosIsNflFlex(draftPos) {
    return nflFlexPositions.includes(draftPos);
  },

  getFieldState(sport, player, fspGame, liveGame = null) {
    if (sport === 'NFL') {
      return this.getNflFieldState(player, liveGame);
    }

    if (sport === 'NHL') {
      let isPowerPlay = false;

      if (!fspGame || !liveGame || !liveGame.live) {
        return {};
      }

      const homeAway = fspGame.home === 'true' ? 'home' : 'away';
      const liveState = liveGame.liveStats;
      if (liveGame.live && liveGame.live.status === 'mid-event' && liveState != null) {
        isPowerPlay = liveState.powerplay === homeAway;
      }

      return {isPowerPlay: isPowerPlay};
    }

    if (sport === 'MLB') {
      const state = {};

      if (fspGame && fspGame.info) {
        state.myBatterIndex = fspGame.info.lineup;
      }

      if (!liveGame || !liveGame.live || liveGame.live.status !== 'mid-event') {
        return state;
      }

      if (liveGame.live.batter) {
        // Current batter ID for comparison, batter object for display info
        state.currentBatter = liveGame.live.batter.id;
        state.batter = liveGame.live.batter;

        // Only show current batter if the current batter is on the selected player's team
        if (player.player.teamId == liveGame.live.batter.teamId ||
          player.player.team == liveGame.live.batter.teamId
        ) {
          state.currentBatterIndex = liveGame.live.batter.lineup;
        }
      }

      state.onBase = [
        liveGame.live.runner_1st == 'true',
        liveGame.live.runner_2nd == 'true',
        liveGame.live.runner_3rd == 'true',
      ];

      let ballsStrikes = null;
      let outs = null;

      // Remove strikes/balls if there isn't a batter currently
      if ((liveGame.live.balls == '-' || liveGame.live.strikes == '-') && liveGame.live.outs != null) {
        ballsStrikes = '0-0';
        outs = liveGame.live.outs + ' Out' + (liveGame.live.outs > 1 ? 's' : '');
      } else if (liveGame.live.balls && liveGame.live.strikes && liveGame.live.outs != null) {
        ballsStrikes = liveGame.live.balls + '-' + liveGame.live.strikes;
        outs = liveGame.live.outs + ' Out' + (liveGame.live.outs > 1 ? 's' : '');
      }

      if (ballsStrikes && outs) {
        state.ballsStrikes = ballsStrikes;
        state.outsText = outs;
        state.count = ballsStrikes + ', ' + outs;
      }

      // Only show the current pitcher if the players team is batting
      if (liveGame.live.pitcher) {
        const isHomeTeam = fspGame ? (fspGame.home === 'true' || fspGame.home === true) : null;
        const inningHalf = liveGame.live.inning_half;
        if (isHomeTeam !== null && (isHomeTeam && inningHalf === 'bottom' || !isHomeTeam && inningHalf === 'top')) {
          state.pitcher = liveGame.live.pitcher;
        }
        state.currentPitcher = liveGame.live.pitcher.id;
      }

      state.inning = this.getGamePeriodMLB(liveGame);

      return state;
    }
    return {};
  },

  getNflFieldState(player, game) {
    if (!player || !player.player || !game) {
      return {};
    }

    const live = game && game.live ? game.live : null;

    // LL player json differs from SC in terms of key used to define the team ID, for LL it is defined as team and SC it is teamId
    const teamId = player.player.teamId != null ? player.player.teamId : player.player.team;

    let isHomeTeam = null;
    if (teamId && game && game.home_team && game.home_team.team_id == teamId) {
      isHomeTeam = true;
    } else if (teamId && game && game.visitor_team && game.visitor_team.team_id == teamId) {
      isHomeTeam = false;
    }

    if (live && live.status === 'mid-event') {
      const down = live.down;
      const dist = live.dist_first;
      const fieldside = live.fieldside;
      const yardline = live.yardline;
      const possession = live.team_possession;
      let sideAlias = fieldside === 'home' ? game.home_team.team_alias : game.visitor_team.team_alias;
      sideAlias = sideAlias != null ? sideAlias.toUpperCase() : '';

      let downInfo = '';
      if (down && (down !== '0' || dist !== '0')) {
        downInfo = down.addSuffix() + ' & ' + dist;
      }

      let aliasYardLine = sideAlias;
      if (yardline) {
        aliasYardLine += ' ' + yardline;
      }
      let statLine = downInfo != '' ? downInfo + ' | ' + aliasYardLine : aliasYardLine;

      const teamHasBall = possession == (isHomeTeam ? 'home' : 'away');
      const inRedZone = fieldside !== possession && yardline <= 20 && teamHasBall;

      // End of quarter
      const gameTime = live.gametime;
      if (live.period && (gameTime === '00:00' || gameTime === '0:00')) {
        const period = live.period ? live.period : '';
        if (period == '4') {
          this.teamHasBall = null;
          this.statLine = '';
        }
        statLine = period == '2' ? 'Halftime' : 'End of ' + period.addSuffix();
      }

      return {
        statLine: statLine,
        teamHasBall: teamHasBall,
        inRedZone: inRedZone,
        downInfo: downInfo,
        aliasYardLine: aliasYardLine,
        possession: possession,
        isHomeTeam: isHomeTeam,
        fieldside: fieldside,
        yardline: yardline,
      };
    }

    return {};
  },

  // Formatting game summary (vs TEAM 2-1 Bot 1st)
  getGameSummary(sport, game, teamId, moment, includeOpponent=true, includePeriod=true, includeDateString = true) {
    if (!game || !moment) {
      return '';
    }

    const liveJSON = game && game.live ? game.live : null;

    // Past games are in a different format, and required the 'home' parameter check
    // Otherwise check the team id compared to the contest teams
    let isHomeTeam = false;
    if (game.home != null) {
      isHomeTeam = game.home === 'true';
    } else {
      isHomeTeam = game && game.home_team && game.home_team.team_id == teamId;
    }

    // Setup opponent (vs TEAM or @ TEAM)
    let oppAlias = game.opp_alias ? game.opp_alias : '';

    if (!oppAlias && game.visitor_team) {
      oppAlias = isHomeTeam ? game.visitor_team.team_alias : game.home_team.team_alias;
    }
    if (!oppAlias && game.opponent) {
      oppAlias = game.opponent;
    }

    oppAlias = TeamInfo.getDisplayAlias(sport, oppAlias);

    let opponent = isHomeTeam ? 'vs ' : '@ ';
    opponent += oppAlias.toUpperCase();

    if (!includeOpponent) {
      opponent = '';
    }

    // Setup date & time
    let gameTimestamp;
    if (game.date) {
      gameTimestamp = game.date;
    } else if (game.date_utc) {
      gameTimestamp = game.date_utc;
    } else {
      gameTimestamp = game.timestamp;
    }
    let gameDate = moment(gameTimestamp).format('MMM D, h:mma');
    const today = moment().format('ddd MMM D');
    if (gameDate === today || !includeDateString) {
      gameDate = moment(gameTimestamp).format('h:mma');
    }

    // Previous day games on live view will not have liveJSON
    // This is required for this case
    if (game.score && game.score !== '') {
      return opponent + ' ' + game.score + ' Final';
    }

    // Game is not live, show game date / time
    if (!liveJSON || !liveJSON.hometeam || !liveJSON.awayteam) {
      return opponent + ' ' + gameDate;
    }

    // Setup score
    let score = '';
    let homeScore = liveJSON.hometeam.score;
    let awayScore = liveJSON.awayteam.score;
    homeScore = homeScore ? homeScore : '0';
    awayScore = awayScore ? awayScore : '0';

    if (isHomeTeam) {
      score = homeScore + '-' + awayScore;
    } else {
      score = awayScore + '-' + homeScore;
    }

    // Game is over, show as Final
    if (liveJSON.status === 'post-event') {
      return opponent + ' ' + score + ' Final';
    }

    if (liveJSON.status === 'mid-event') {
      const period = includePeriod ? this.getGamePeriod(sport, game) : '';
      // Include clock time for NFL
      if (sport === 'NFL' && liveJSON.gametime) {
        return opponent + ' ' + score + ' ' + liveJSON.gametime + ' ' + period;
      }
      return opponent + ' ' + score + ' ' + period;
    }

    return opponent + ' ' + gameDate;
  },

  // Formatting Period to account for Overtime
  getGamePeriod(sport, gameJSON) {
    switch (sport) {
      case 'NHL': return this.getGamePeriodNHL(gameJSON);
      case 'NBA': return this.getGamePeriodNBA(gameJSON);
      case 'NFL': return this.getGamePeriodNFL(gameJSON);
      case 'MLB': return this.getGamePeriodMLB(gameJSON);
    }
    return '';
  },

  getGamePeriodNHL(gameJSON) {
    if (!gameJSON || !gameJSON.live || !gameJSON.live.period) {
      return '';
    }
    const isPostSeason = gameJSON.game_type === 'Post Season';
    const period = gameJSON.live.period;
    const periodValue = period != null ? parseInt(period) : 1;

    if (isPostSeason) {
      if (periodValue > 4) {
        return 'OT' + (periodValue - 3);
      }
      if (periodValue == 4) {
        return 'OT';
      }
      return periodValue.addSuffix();
    }

    if (periodValue == 5) {
      return 'SHOOTOUT';
    }
    if (periodValue == 4) {
      return 'OT';
    }

    return periodValue.addSuffix();
  },

  getGamePeriodNBA(gameJSON) {
    if (!gameJSON || !gameJSON.live || !gameJSON.live.period) {
      return '';
    }
    const period = gameJSON.live.period;
    const periodValue = period != null ? parseInt(period) : 1;

    if (periodValue > 5) {
      return 'OT' + (periodValue - 4);
    }
    if (periodValue == 5) {
      return 'OT';
    }
    return period.addSuffix();
  },

  getGamePeriodNFL(gameJSON) {
    if (!gameJSON || !gameJSON.live || !gameJSON.live.period) {
      return '';
    }
    const period = gameJSON.live.period;
    const periodValue = period != null ? parseInt(period) : 1;

    if (periodValue > 5) {
      return 'OT' + (periodValue - 4);
    }
    if (periodValue == 5) {
      return 'OT';
    }
    return period.addSuffix();
  },

  getGamePeriodMLB(gameJSON) {
    if (!gameJSON || !gameJSON.live || !gameJSON.live.inning || !gameJSON.live.inning_half) {
      return '';
    }
    const inning = gameJSON.live.inning;
    const inningHalf = gameJSON.live.inning_half;
    const outs = gameJSON.live.outs;

    if (outs === '3' && inningHalf === 'bottom') {
      return 'End ' + inning.addSuffix();
    }
    if (inningHalf == 'top') {
      return 'Top ' + inning.addSuffix();
    }
    return 'Bot ' + inning.addSuffix();
  },

  getPeriodLabelNHL(periodNum, isPostSeason) {
    if (periodNum <= 3) {
      return periodNum.addSuffix() + ' Period';
    }
    if (isPostSeason) {
      return (periodNum - 3).addSuffix() + ' Overtime';
    }
    if (periodNum === 4) {
      return 'Overtime';
    }
    return 'Shootout Goals';
  },

  getPeriodLabelNFLNBA(periodNum) {
    if (periodNum <= 4) {
      return periodNum.addSuffix() + ' Quarter';
    }
    return (periodNum - 4).addSuffix() + ' Overtime';
  },

  getPeriodLabel(periodNum, sport, isPostSeason) {
    switch (sport) {
      case 'NHL': return this.getPeriodLabelNHL(periodNum, isPostSeason);
      case 'NFL': case 'NBA': return this.getPeriodLabelNFLNBA(periodNum);
      default: return periodNum.addSuffix() + ' Quarter';
    }
  },

  totalProjections(roster, picks) {
    let tProj = 0;
    for (const posInfo of roster) {
      const {totPlayerGames, projList} = this.getProjectedGames(posInfo, picks);
      const maxGames = posInfo.maxGames < totPlayerGames ? posInfo.maxGames : totPlayerGames;
      for (let index = 0; index < maxGames; index++) {
        tProj += projList[index];
      }
    }
    return tProj.toFixed(0);
  },

  getProjectedGames(posInfo, picks) {
    let totPlayerGames = 0;
    let projList = [];
    for (const player of picks[posInfo.short]) {
      for (const game of player.games) {
        if (game.proj != null) {
          const gameProj = this.getGameProj(game.proj, posInfo.short);
          projList.push(gameProj);
          totPlayerGames++;
        }
        if (game.projDH != null) {
          const gameProj = this.getGameProj(game.projDH, posInfo.short);
          projList.push(gameProj);
          totPlayerGames++;
        }
      }
    }

    projList = projList.sort((proj1, proj2) => proj2 - proj1);
    return {totPlayerGames: totPlayerGames, projList: projList};
  },

  getGameProj(gameProj, pos) {
    if (this.isDraftPositionMultiplier(pos)) {
      gameProj = (gameProj.toFixed(0) * 1.5);
    }
    return gameProj;
  },

  playerGameText(contest, gameFinished, sport, displayTime = true) {
    if (contest?.game == null) {
      return 'No Game';
    }
    const isHome = contest.player.isHome;
    const homeText = isHome ? 'vs' : '@';
    const oppAlias = isHome ? contest?.game?.awayTeam?.alias.toUpperCase() : contest?.game?.homeTeam?.alias.toUpperCase();
    const gameName = TeamInfo.getDisplayAlias(sport, oppAlias);
    const gameTime = gameFinished ? 'FINAL' : moment(contest.game.date).format('h:mma');

    let text = `${homeText} ${gameName}`;
    if (displayTime) {
      text += ' ' + gameTime;
    }
    return text;
  },

};

SportInfo.install = function(Vue, options) {
  Vue.prototype.$SportInfo = SportInfo;
};

export default SportInfo;
