import Api from '@/api/ObBaseApi';

export default {
  getBattlePass() {
    return Api()
        .get('/battlepass/v1.0/getBattlePass')
        .then((response) => {
          if (!response?.data?.id) {
            return null;
          }
          return response.data;
        });
  },
};
