<template>
  <span v-if="realMoney">
    {{ formatted }}
  </span>
  <span v-else class="ob-currency">
    <img v-if="invert" class="ownersbucks" :src="require('@/assets/icons/ownersbucks-inv.png')"
      alt="OwnersBucks Icon"
    >
    <img v-else class="ownersbucks" :src="require('@/assets/icons/ownersbucks.png')"
      alt="OwnersBucks Icon"
    >
    {{ formatted }}
  </span>
</template>

<script>
// @ts-check
import Vue from 'vue';

export default Vue.extend({
  props: {
    realMoney: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    cents: {
      type: Number,
      default: null,
    },
  },

  computed: {
    /**
     * @return {string}
     */
    formatted() {
      if (this.cents === null) {
        if (this.realMoney) {
          return '$';
        }
        return '';
      }
      let style;
      if (this.realMoney) {
        style = 'currency';
      }
      const value = Intl.NumberFormat('en-US', {
        style: style,
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.cents/100);

      if (value.endsWith('.00')) {
        return value.slice(0, -3);
      }

      return value;
    },
  },
});
</script>

<style scoped>
.ob-currency {
  display: inline-flex;
  align-items: center;
  text-decoration: inherit;
}
.ownersbucks {
  display: inline-block;
  height: 0.7em;
  margin-right: 0.25em;
}
</style>
