/**
* Downloads a data string as a file.
* @param {String} fileData - The file's data.
* @param {String} fileName - The file name for the downloaded CSV.
*/
export function downloadFile(fileData, fileName) {
  const fileURL = window.URL.createObjectURL(new Blob([fileData]));
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
  URL.revokeObjectURL(fileLink.href);
}

export default {
  downloadFile,
};