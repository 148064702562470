<template>
  <div>
    <div v-for="(integration, index) in integrations" :key="index" class="integrationContainer">
      <div class="integrationSliderContainer">
        <div class="integrationName">{{ integration.name }}</div>
        <template v-if="integration.url && email">
          <div @click.prevent="linkUnlinkIntegration(integration)">
            <Checkbox v-model="integration.active" type="switch" :noClick="!isDepositVerified" />
          </div>
        </template>
      </div>
      <div class="integrationDescription"> {{ integration.description }}</div>
    </div>
    <div v-if="!isDepositVerified" class="errorPanelContainer">
      <ErrorPanel :message="verifyAccountMessage" :isWarning="true" />
      <router-link to="/verifyaccount?redirectBackTo=integrations" class="ob-btn">Verify Account</router-link>
    </div>
    <ConfirmationModal :title="'Disconnect from Kroger?'"
      :text="`You won't receive additional Kroger Fuel Points by playing on OwnersBox.`" :confirmButtonLabel="'Disconnect'"
      :eventBusName="'OPEN_KROGER_UNLINK_MODAL'" @confirm="confirmUnlinkKroger()"
    />
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import ObIntegrationsApi from '@/api/ObIntegrationsApi';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  props: {},

  components: {
    Checkbox,
    ConfirmationModal,
    ErrorPanel,
  },

  data() {
    return {
      loadingOAuthUrl: true,
      loadingOAuthUrlError: null,
      krogerOAuthUrl: null,

      krogerIdInterval: null,
    };
  },

  created() {
    this.getKrogerOAuthUrl();
  },

  destroyed() {
    clearInterval(this.krogerIdInterval);
  },

  computed: {
    ...mapState(['krogerId', 'email', 'isDepositVerified']),

    integrations() {
      return [
        {
          name: 'Kroger',
          url: this.krogerOAuthUrl,
          active: this.isKrogerActive,
          description: 'Connect your Kroger account with OwnersBox to receive Kroger fuel points as you play Daily Fantasy Sports',
        },
      ];
    },

    isKrogerActive() {
      return this.krogerId != null;
    },

    verifyAccountMessage() {
      return 'You must verify your account to connect with Kroger';
    },
  },

  methods: {
    getKrogerOAuthUrl() {
      this.loadingOAuthUrl = true;
      ObIntegrationsApi.getKrogerOAuthUrl()
          .then((response) => {
            this.krogerOAuthUrl = response;
            this.loadingOAuthUrl = false;
          }).catch((e) => {
            this.loadingOAuthUrlError = true;
            this.loadingOAuthUrl = false;
          });
    },

    linkUnlinkIntegration(integration) {
      if (!this.isDepositVerified) {
        return;
      }
      if (integration.active) {
        this.unlinkKrogerIntegration();
      } else {
        this.applyIntegration(integration);
      }
    },

    applyIntegration(_integration) {
      // Can be changed to a switch statement once we have more integrations
      if (this.krogerId) {
        this.unlinkKrogerIntegration();
      } else {
        this.applyKrogerIntegration();
        this.loopGetKrogerId();
      }
    },

    unlinkKrogerIntegration() {
      EventBus.$emit('OPEN_KROGER_UNLINK_MODAL');
    },

    confirmUnlinkKroger() {
      ObIntegrationsApi.unlinkKrogerIntegration()
          .then(() => {
            this.$store.commit('setKrogerId', null);
          }).catch((_e) => {
            // Hide error
          });
    },

    applyKrogerIntegration() {
      window.open(this.krogerOAuthUrl, 'Kroger Integration', 'width=600, height=820');
    },

    loopGetKrogerId() {
      clearInterval(this.krogerIdInterval);
      // 1 second timer to attempt to fetch kroger id
      this.krogerIdInterval = setInterval(this.getKrogerIdForRefresh, 2000);
    },

    // Intended to get the user's kroger id once they've connected, but since it's in a different window
    // we don't actually know when that will be.
    getKrogerIdForRefresh() {
      ObIntegrationsApi.loadUserKrogerId()
          .then((response) => {
            const krogerId = response != '' ? response : null;
            this.$store.commit('setKrogerId', krogerId);

            // Clear the interval for grabbing the kroger id if the id is found
            // If this never happens, it's cleared when the user leaves the page
            if (krogerId != null) {
              clearInterval(this.krogerIdInterval);
            }
          }).catch((_e) => {
            // Hide error
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.integrationContainer {
  .integrationSliderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    div:first-child {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .integrationName {
      font-size: 20px;
    }
  }

  .integrationDescription {
    font-size: 16px;
    color: var(--obcolor-font-secondary);
    margin-top: 10px;
  }
}

.errorPanelContainer {
  $gap: 20px;
  margin-top: $gap;
  display: flex;
  flex-direction: column;
  gap: $gap;
  align-items: center;

  > .errorPanel {
    width: 100%;
    box-sizing: border-box;
  }
}

.ob-btn {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>