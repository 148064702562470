<template>
  <div class="container">
    <div class="arrowContainer">
      <!-- No Arrows - Keep container for consistent spacing -->
    </div>
    <ReasonsToVerify />

    <div class="buttonContainer">
      <button class="ob-btn-grey" @click="skipVerifyAccount()">Later</button>
      <button class="ob-btn" @click="verifyAccount()">Continue</button>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ReasonsToVerify from '@/components/ReasonsToVerify.vue';

export default {
  components: {
    ReasonsToVerify,
  },

  methods: {
    verifyAccount() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    skipVerifyAccount() {
      EventBus.$emit('ON_BOARDING_EXIT');
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  height: 60px;
}
</style>
