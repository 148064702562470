import Api from '@/api/ObBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

export default {

  getMyPlayers() {
    return Api()
        .get('/fsp/leagues/getMyPlayers')
        .then((response) => {
          return response.data;
        });
  },

  getLeague(leagueId) {
    return Api()
        .get('/fsp/leagues/getLeague?id=' + leagueId)
        .then((response) => {
          return response.data;
        });
  },

  async getLobby() {
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason, null, true);
    return Api()
        .get('/fsp/leagues/getLobby3', config)
        .then((response) => {
          return response.data;
        });
  },

  isContestValidForLocation(contestId) {
    return Api()
        .get('/fsp/leagues/isContestValidForLocation?id=' + contestId)
        .then((response) => {
          return response.data;
        });
  },

  getGameSlate(slateId) {
    const params = '?gameSlateId=' + slateId;
    return Api()
        .get('/fsp/gameslate/getGameSlateData' + params)
        .then((response) => {
          return response.data;
        });
  },

  getDailyCutoffs() {
    return Api()
        .get('/fsp/leagues/getDailyCutoffs')
        .then((response) => {
          return response.data;
        });
  },

  async joinLeague(leagueId, realMoneyLeague) {
    let params = '?id=' + leagueId;
    params += '&ownersbucks=' + !realMoneyLeague;
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/manageLeague/join' + params, {}, config)
        .then((response) => {
          return response.data;
        });
  },

  leaveLeague(leagueId, realMoneyLeague) {
    let params = '?id=' + leagueId;
    params += '&ownersbucks=' + !realMoneyLeague;
    return Api()
        .post('/fsp/manageLeague/leave' + params)
        .then((response) => {
          return response.data;
        });
  },

  disbandLeague(leagueId) {
    const params = {id: leagueId};
    return Api()
        .post('/fsp/manageLeague/disbandLeague', params)
        .then((response) => {
          return response.data;
        });
  },

  inviteUsersToLeague(leagueId, userList) {
    const params = {
      id: leagueId,
      userList: userList,
    };
    return Api()
        .post('/fsp/manageLeague/inviteMultipleUsers', params)
        .then((response) => {
          return response.data;
        });
  },

  loadMyLeagues3() {
    return Api()
        .get('/fsp/leagues/getMyLeagues3')
        .then((response) => {
          return response.data;
        });
  },

  // Replaces v3 to include salary cap contests
  loadMyLeagues4() {
    return Api()
        .get('/fsp/leagues/getMyLeagues4')
        .then((response) => {
          return response.data;
        });
  },

  getCompletedLeagues(year, month) {
    return Api()
        .get('/fsp/leagues/getCompletedLeagues2?year=' + year + '&month=' + month)
        .then((response) => {
          return response.data;
        });
  },

  getLatestCompletedLeague(leagueCount) {
    return Api()
        .get('/fsp/leagues/getLatestCompletedLeague3?leagueCount=' + leagueCount)
        .then((response) => {
          return response.data;
        });
  },

  loadGroupLeagues(groupId) {
    return Api()
        .get('/fsp/leagues/getLeaguesByGroupId?conversationId=' + groupId)
        .then((response) => {
          return response.data;
        });
  },

  getLeagueMatchup(leagueId, matchup) {
    let params = '?league=' + leagueId;
    params += matchup != null ? '&matchup=' + matchup : '';
    return Api()
        .get('/fsp/leagues/getMatchup' + params)
        .then((response) => {
          return response.data;
        });
  },

  getLeagueTransactions(leagueId) {
    const params = '?league=' + leagueId;
    return Api()
        .get('/fsp/leagues/getLeagueTransactions' + params)
        .then((response) => {
          return response.data;
        });
  },

  getLeaguePlayerList(leagueId, position, week) {
    return Api()
        .get('/fsp/leagues/getLeaguePlayers2?position=' + position + '&week=' + week + '&league=' + leagueId)
        .then((response) => {
          return response.data;
        });
  },

  dropAndAddPlayer(leagueId, pos, dropId, addId) {
    const params = {
      pos: pos,
      add: addId,
      drop: dropId,
    };
    return Api()
        .post('/fsp/leagues/addPlayer?league=' + leagueId, params)
        .then((response) => {
          return response.data;
        });
  },

  deactivatePlayer(leagueId, active, pos, player, game) {
    const params = {
      active: active,
      pos: pos,
      player: player,
      game: game,
    };
    return Api()
        .post('/fsp/leagues/deactivatePlayer?league=' + leagueId, params)
        .then((response) => {
          return response.data;
        });
  },

  async createLeague(params) {
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/manageLeague/create', params, config)
        .then((response) => {
          return response.data;
        });
  },

  async createPublicLeague(params) {
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/manageLeague/createPublic', params, config)
        .then((response) => {
          return response.data;
        });
  },

  // Params are sport, num entrants, and draft start TIMESTAMP
  getLeagueStartDate(params) {
    if (!params.sport || !params.numEntrants || !params.draftStart) {
      return null;
    }

    return Api()
        .get('/fsp/manageLeague/getStartDate?sport=' + params.sport + '&numEntrants=' + params.numEntrants + '&draftStart=' + params.draftStart)
        .then((response) => {
          return response.data;
        });
  },

  declineLeagueInvite(leagueId) {
    return Api()
        .post('/fsp/manageLeague/declineInvite?id=' + leagueId)
        .then((response) => {
          return response;
        });
  },

  getPromoBanners() {
    return Api()
        .get('/fsp/images/getBannerToDisplay')
        .then((response) => {
          return response.data;
        });
  },

  getCurrentWeekGamesData(leagueId, matchupId) {
    let queryString = '?leagueId=' + leagueId;

    if (matchupId) {
      queryString = queryString + '&matchupId=' + matchupId;
    }

    return Api()
        .get('/fsp/leagues/getCurrentWeekGamesData' + queryString)
        .then((response) => {
          return response.data;
        });
  },

  getSportNumContests() {
    return Api()
        .get('/fsp-marketing/getSportInfo')
        .then((response) => {
          return response.data;
        });
  },
};
