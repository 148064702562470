<template>
  <ObLoading v-if="!leagueData || !leagueRoster" />
  <div v-else class="ob-box" style="min-width: max-content; padding: 0; overflow: hidden;">
    <!-- Table Headers -->
    <div v-if="league != null">
      <table aria-label="Team Schedule">
        <tr v-if="selectedTab == 'stats' && rosterScoringStats.length > 1">
          <th style="height: 30px;" scope="col"></th>
          <!-- Row header descriptors such as "Defense","Rushing", etc. -->
          <th class="rowSubHeader" style="padding-top: 10px; height: 30px;" v-for="(category, index) in rosterScoringStats"
            :key="index" :colspan="category.stats.length" scope="col"
          >
            {{ category.label }}
          </th>
          <th class="endSpacing" style="height: 30px;" scope="col"></th>
        </tr>
        <tr>
          <th class="playerInfoCol" style="width: 436px;" scope="row">
            <div style="display: flex; align-items: center;">
              <div class="selectViewContainer" style="flex: 1;">
                <div :style="selectedTab == 'schedule' ? 'color: var(--obcolor-ob-blue);' : ''" @click="selectTab('schedule')">Schedule</div>
                <div :style="selectedTab == 'stats' ? 'color: var(--obcolor-ob-blue);' : ''" @click="selectTab('stats')">Stats</div>
                <div :style="selectedTab == 'transactions' ? 'color: var(--obcolor-ob-blue);' : ''" @click="selectTab('transactions')">Transactions</div>
              </div>

              <select v-if="selectedTab == 'stats' && rosterFilters && rosterFilters.length > 1" v-model="selectedFilterIndex" class="statDropDown">
                <option v-for="(roster, index) in rosterFilters" :key="index" :value="index">{{ roster.label }}</option>
              </select>
            </div>
          </th>
          <template v-if="selectedTab == 'schedule' || selectedTab == 'transactions'">
            <th v-for="n in 7" class="scheduleHeader" :key="n"
              :class="{todayHighlight: todaysIndex == n-1}"
            >
              <div class="lineupDay" v-if="league.roster.days != null">{{ [league.roster.days[n-1], 'YYYY-MM-DD'] | moment("ddd MMM D") }}</div>
            </th>
          </template>
          <template v-else-if="selectedTab == 'stats'">
            <!-- Show one heading with the stats if there is only one category -->
            <template v-if="rosterScoringStats.length == 1">
              <th class="rowHeader statsCol" v-for="stat in rosterScoringStats[0].stats" :key="stat.path"
                v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
              >
                {{ stat.label }}
              </th>
            </template>
            <!-- If there are multiple categories, loop through them to show, and put a border to the right at the end of each category -->
            <template v-else>
              <template v-for="(category, index) in rosterScoringStats">
                <th class="rowSubHeader statsCol" v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
                  :class="{endSection: index1 == category.stats.length - 1}"
                  v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
                >
                  {{ stat.label }}
                </th>
              </template>
            </template>
          </template>
          <th v-if="selectedTab != 'transactions'" class="endSpacing"></th>
        </tr>
      </table>

      <!-- Players by Position -->
      <template v-if="selectedTab != 'transactions'">
        <template v-for="(group, index) in teamRosters">
          <div style="display: flex;"
            :style="index >= teamRosters.length-1 ? '' : 'border-bottom: 3px solid var(--obcolor-background-6);'"
            :key="index"
          >
            <div style="flex: 1;">
              <table aria-label="">
                <SalaryTeamRow v-for="entry in group.players" :entry="entry" :key="entry.id"
                  :swapStatus="'swappable'"
                  :todaysIndex="todaysIndex" :leagueId="league.id"
                  :isMyTeam="true" :sport="league.sport"
                  :playedGames="rosterCalc.playedGames" :overSalaryGames="rosterCalc.overSalaryGames" :bestBallGames="rosterCalc.bestBallGames"
                  :matchup="leagueData"
                  :style="entry.hlTableRow ? 'background: var(--obcolor-background-5);' : ''"
                  :selectedTab="selectedTab" :teamId="teamId"
                  :selectedFilterIndex="selectedFilterIndex"
                />
              </table>
            </div>
            <div class="posGroup">
              <div>
                <div class="posGroupText">
                  <div v-if="rosterCalc.projGames[group.pos] < gameLimits[group.pos]">
                    <span style="color: red">{{ rosterCalc.projGames[group.pos] }}</span>
                  </div>
                  <div v-else-if="rosterCalc.projGames[group.pos] > gameLimits[group.pos]">
                    <span style="color: orange">{{ rosterCalc.projGames[group.pos] }}</span>
                  </div>
                  <div v-else>{{ rosterCalc.projGames[group.pos] }}</div>
                </div>

                <div>{{ gameLimits[group.pos] }}</div>
              </div>
            </div>
          </div>
        </template>

        <!-- Dropped Players -->
        <table class="rowHighlight" aria-label="Dropped Players">
          <tr v-if="leagueRoster.dropped.length > 0">
            <th colspan="16" class="rowHeader droppedHeader">
              Dropped Players
            </th>
          </tr>
          <SalaryTeamRow v-for="entry in leagueRoster.dropped" :entry="entry" :key="entry.id"
            :todaysIndex="todaysIndex" :leagueId="league.id" :isMyTeam="true"
            :sport="league.sport"
            :playedGames="rosterCalc.playedGames" :overSalaryGames="rosterCalc.overSalaryGames" :bestBallGames="rosterCalc.bestBallGames"
            :matchup="leagueData" :teamId="teamId"
            :selectedTab="selectedTab"
            :selectedFilterIndex="selectedFilterIndex"
          />
          <tr><th colspan="99" style="padding: 0; height: 6px;"></th></tr>
        </table>
      </template>

      <!-- Transactions Tab -->
      <SalaryTeamTransactions v-else
        :leagueData="leagueData" :leagueRoster="leagueRoster"
        :todaysIndex="todaysIndex" :isMyTeam="true"
        :playedGames="rosterCalc.playedGames" :overSalaryGames="rosterCalc.overSalaryGames"
        :selectedTab="selectedTab" :rosterCalc="rosterCalc"
        :selectedFilterIndex="selectedFilterIndex" :teamId="teamId"
      />
    </div>
  </div>
</template>

<script>
import SalaryTeamRow from './SalaryTeamRow';
import SalaryTeamTransactions from './SalaryTeamTransactions';
import {mixin as clickaway} from 'vue-clickaway2';
import ObLoading from '@/components/ObLoading';

export default {

  components: {
    SalaryTeamRow,
    SalaryTeamTransactions,
    ObLoading,
  },

  props: {
    leagueData: Object,
    leagueRoster: Object,
    rosterCalc: Object,
    customNames: Object,
    myTeams: Array,
    teamId: String,
  },

  mixins: [clickaway],

  data: function() {
    return {
      league: null,
      gameLimits: {},
      todaysIndex: null,
      teamSelectOpen: false,
      selectedTeam: null,
      selectedTab: 'schedule',
      selectedFilterIndex: 0,
    };
  },

  computed: {
    teamRosters() {
      const teamRosters = {};
      const rostersArray = [];
      for (const pIndex in this.league.roster.players) {
        const player = this.league.roster.players[pIndex];
        player.hlTableRow = pIndex % 2 != 0;
        if (teamRosters[player.draftPos] != null) {
          teamRosters[player.draftPos].players.push(player);
        } else {
          const list = {pos: player.draftPos, players: [player]};
          teamRosters[player.draftPos] = list;
          rostersArray.push(list);
        }
      }
      return rostersArray;
    },

    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.league.sport);
    },

    rosterScoringStats() {
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return [];
    },
  },

  watch: {
    leagueRoster(to) {
      this.setupData();
    },
  },

  created() {
    this.setupData();
  },

  methods: {
    // This logic should be improved so we don't loop everytime we need a value
    getSelectedTeam() {
      for (const team of this.myTeams) {
        if (team.teamId == this.teamId) {
          return team;
        }
      }
      return {};
    },

    getTeamName() {
      const tid = this.leagueRoster.id +'_'+ this.leagueRoster.teamNum;
      const cName = this.customNames[tid];
      if (cName && cName != '') {
        return cName;
      }
      return this.leagueRoster.team;
    },

    selectTab(tabName) {
      this.selectedTab = tabName;
    },

    setupData() {
      if (this.leagueData && this.leagueRoster) {
        this.league = null;
        this.league = this.leagueData;
        this.league.roster = this.leagueRoster;
        this.gameLimits = this.leagueData.gameLimits;
        this.setupTodaysIndex();
      }
    },

    setupTodaysIndex() {
      const days = this.league.roster.days;
      const now = this.$moment().format('YYYY-MM-DD');
      for (let i=0; i<days.length; i++) {
        if (days[i] === now) {
          this.todaysIndex = i;
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gameInfoHeader {
  display: flex;
  font-size: 12px;
  height: 60px;
  margin-bottom: 2px;
  align-items: center;
}

.playerInfoCol {
  box-sizing: border-box;
  text-align: left;
  width: 255px;
  padding-left: 6px;
}

.salaryCol {
  width: 120px;
}

.userInfo {
  flex: 1;
  display: flex;
  align-items: center;
  width: 234px;
}

.scheduleHeader {
  box-sizing: border-box;
  min-width: 120px;
}

.selectViewContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  text-transform: uppercase;
  cursor: pointer;
  div:not(:last-child) {
    padding-right: 8px;
    border-right: 1px solid var(--obcolor-font-secondary);
  }
  div:not(:first-child) {
    padding-left: 8px;
  }
  div:hover {
    color: var(--obcolor-ob-blue);
  }
}

.lineupDay {
  box-sizing: border-box;
}

.lineupStatHeader {
  width: 60px;
  text-align: right;
  padding-right: 17px;
}
.lineupStat {
  min-width: 48px;
}
.loadingBox {
  text-align: center;
  padding: 20px;
}
.topBarButton {
  background: var(--obcolor-background-6);
  border-radius: 10px;
  font-size: 12px;
  height: 30px;
  margin-right: 6px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}
.topBarButton:hover {
  background: var(--obcolor-background-2);
}

.matchHeaderTeam {
  font-weight: bold;
}
.matchHeaderUser {
  font-size: 12px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.tabBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 25px;
  cursor: pointer;
}

.tabBtn:hover {
  text-decoration: underline;
}

.tabBtn:first-child {
  border-radius: 4px 0 0 4px;
}

.tabBtn:last-child {
  border-radius: 0 4px 4px 0;
}

.statDropDown {
  width: 110px;
  height: 25px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
}

table {
  text-align: center;
  width: 100%;
  // Fake height needed for inner div to have 100% height in Chrome
  // This is required for game boxes to be top-aligned (for doubleheaders)
  height: 1px;
}

td, th {
  white-space: nowrap;
  box-sizing: border-box;
}

td {
  padding: 8px 6px 8px 6px;
}

th {
  background: var(--obcolor-background-3);
  font-size: 12px;
  height: 45px;
}

td, .rowHeader {
  white-space: nowrap;
}
.rowHeader {
  min-width: 80px;
}
.rowHeader, .rowSubHeader {
  font-size: 13px;
  height: 30px;
}

.droppedHeader {
  text-align: left;
  padding: 0 12px;
  height: 45px;
  border-top: 2px solid var(--obcolor-background-6);
  border-bottom: 2px solid var(--obcolor-background-6);
}

.statsCol {
  min-width: 80px;
}

.borderBottom {
  border-bottom: 1px solid var(--obcolor-background-2);
}

.rowHighlight tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.todayHighlight {
  background: var(--obcolor-background-2);
}

.endSection {
  border-right: 2px solid var(--obcolor-background-3);
}

.posGroup {
  min-width: 30px;
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.posGroupText {
  border-bottom: 2px solid #aaa;
  margin-bottom: 2px
}
.redHighlight {
  color: red !important;
}

.salaryValue {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: -5px;
}

.warnings {
  margin-right: 20px;
  max-width: 270px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.committedContainer {
  padding-right: 15px;
  border-right: 2px solid var(--obcolor-background-2);

  .salaryValue {
    color: var(--obcolor-green);
  }
}

.remainingContainer {
  margin-right: 15px;
  margin-left: 16px;
}

.teamDropdown {
  position: absolute;
  background: var(--obcolor-background-3);
  width: 100%;
  left: 0;
  top: 63px;
  z-index: 80;
  border-radius: 0 0 4px 4px;
}

.dropdownEntry {
  padding: 10px;
  border-top: 1px solid var(--obcolor-background-2);
  display: flex;
  align-items: center;
}
.dropdownEntry:hover {
  color: var(--obcolor-ob-blue) !important;
}

.teamSelect:hover {
  background: var(--obcolor-background-3);
  .arrow {
    color: var(--obcolor-font-primary);
  }
}
.teamSelect {
  cursor: pointer;
  background: var(--obcolor-background-4);
  width: 290px;
  height: 63px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  .arrow {
    font-size: 20px;
    color: var(--obcolor-font-secondary);
    margin-left: 10px;
  }
}

.teamRank {
  text-align: center;
  font-size: 11px;
  margin-top: -5px;
}

.teamScore {
  background: var(--obcolor-background-2);
  font-size: 12px;
  font-weight: bold;
  padding: 4px 6px;
  border-radius: 6px;
  margin-top: 2px;
  min-width: 35px;
  text-align: center;
}

.endSpacing {
  width: 31px;
}
</style>
