<template>
  <!-- Only open the player card if there is no opponent, if there is then we need a separate opponent trigger -->
  <!-- (Since opponent player shows in the same row) -->
  <tr :class="{ hasOpponent: oppPlayer != null }" @click="openPlayerCardIfOpp(false)"
    :style="dropdownOpen ? 'border-bottom: 1px solid var(--obcolor-background-6);' : ''"
  >
    <!-- Headshot, name, status, position -->
    <td class="playerInfoSection">
      <div class="nameHeadshotSection">
        <PlayerHeadshot class="playerHeadshot"
          :id="player.id" :sport="sport" :team="player.teamAlias"
          :size="'50'" :showLogo="true"
        />
        <div class="nameInfoContainer">
          <div class="playerName">
            <PlayerStatus class="playerStatus" :status="getStatus(player)" />
            <div class="nameText" @click="openPlayerCardIfOpp(true, player.id)">
              {{ player.name.shortName() }}
            </div>
            <i v-if="teamHasBall() == true" class="fas fa-football-ball ballIcon"></i>
            <div v-if="inRedZone()" class="redZone">RZ</div>
            <div v-if="isPowerPlay()" class="powerPlay">PP</div>
            <img v-if="isMLB() && isCurrentBatter()" :src="require('@/assets/icons/baseball-bat-orange.png')" alt="Orange Bat"
              class="battingIcon"
            >
            <img v-if="isMLB() && isCurrentPitcher()" :src="require('@/assets/icons/baseball-ball-orange.png')" alt="Orange Ball"
              class="battingIcon"
            >
          </div>
          <div class="playerInfo uppercaseInfo">
            {{ player.position }} - {{ getTeamAlias(player) }}
          </div>
          <template v-if="oppPlayer != null">
            <LightningLiveGameScore class="playerInfo" :liveGame="liveGame()" :player="player"
              :contest="contest" :fieldState="fieldState()"
            />
          </template>
          <div class="playerInfo">
            <MlbBattingOrder v-if="isMLB() && !isPitcher()" :fieldState="fieldState()" />
            <div v-if="sport == 'NFL' && gameIsLive(false)">{{ fieldState(false).statLine }}</div>
          </div>
        </div>
      </div>
    </td>

    <!-- Live game -->
    <td style="text-align: center;">
      <LightningLiveGame v-if="oppPlayer == null" :contest="contest" :player="player"
        :liveGame="liveGame()"
        :fieldState="fieldState()"
      />
      <div v-else class="liveGame">
        <template v-if="sport == 'MLB' && gameIsLive()">
          <MlbOnBase :fieldState="fieldState()" />
          <div>{{ fieldState().count }}</div>
          <div>{{ fieldState().inning }}</div>
        </template>
      </div>
    </td>

    <!-- Points/Proj -->
    <td v-if="!player.isScratched" style="text-align: center;">
      <div class="teamPoints" :class="{ redText: game().fpts < 0 }">{{ game().fpts != null ? game().fpts.toFixed(2) : '0.00' }}</div>
      <div class="teamProj">{{ game().projection.toFixed(2) }}</div>
    </td>
    <td v-else class="noData">
      <div>-</div>
      <div>-</div>
    </td>

    <!-- Scoring dropdown -->
    <td class="dropdownSection" style="padding: 0;" @click.stop="toggleDropdown()">
      <i class="fas" :class="dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </td>

    <!-- Opponent Player (comparison view) -->
    <template v-if="oppPlayer != null">
      <td v-if="!oppPlayer.isScratched" style="text-align: center;">
        <div class="teamPoints" :class="{ redText: game(true).fpts < 0 }">{{ game(true).fpts != null ? game(true).fpts.toFixed(2) : '0.00' }}</div>
        <div class="teamProj">{{ game(true).projection.toFixed(2) }}</div>
      </td>
      <td v-else class="noData">
        <div>-</div>
        <div>-</div>
      </td>

      <!-- Live game -->
      <td style="text-align: center; width: 30px;">
        <div class="liveGame">
          <template v-if="sport == 'MLB' && gameIsLive(true)">
            <MlbOnBase :fieldState="fieldState(true)" />
            <div>{{ fieldState(true).count }}</div>
            <div>{{ fieldState(true).inning }}</div>
          </template>
        </div>
      </td>

      <!-- Headshot, name, status, position (reversed) -->
      <td class="playerInfoSection oppPlayer">
        <div class="nameHeadshotSection">
          <div class="nameInfoContainer">
            <div class="playerName">
              <img v-if="isMLB() && isCurrentBatter(true)" :src="require('@/assets/icons/baseball-bat-orange.png')" alt="Orange Bat"
                class="battingIcon"
              >
              <img v-if="isMLB() && isCurrentPitcher(true)" :src="require('@/assets/icons/baseball-ball-orange.png')" alt="Orange Ball"
                class="battingIcon"
              >
              <i v-if="teamHasBall(true) == true" class="fas fa-football-ball ballIcon"></i>
              <div v-if="inRedZone(true)" class="redZone">RZ</div>
              <div v-if="isPowerPlay(true)" class="powerPlay">PP</div>
              <div class="nameText" @click="openPlayerCardIfOpp(true, oppPlayer.id)">
                {{ oppPlayer.name.shortName() }}
              </div>
              <PlayerStatus class="playerStatus" :status="getStatus(oppPlayer)" style="display: inline-block; margin-top: -2px; margin-right: 2px" />
            </div>
            <div class="playerInfo">
              {{ oppPlayer.position }} - {{ getTeamAlias(oppPlayer) }}
            </div>
            <template>
              <LightningLiveGameScore class="playerInfo" :liveGame="liveGame(true)" :player="oppPlayer"
                :contest="contest" :fieldState="fieldState(true)"
              />
            </template>
            <div class="playerInfo">
              <MlbBattingOrder v-if="isMLB() && !isPitcher(true)" :fieldState="fieldState(true)" />
              <div v-if="sport == 'NFL' && gameIsLive(true)">{{ fieldState(true).statLine }}</div>
            </div>
          </div>
          <PlayerHeadshot class="playerHeadshot"
            :id="oppPlayer.id" :sport="sport" :team="oppPlayer.teamAlias"
            :size="'50'" :showLogo="true"
          />
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import LightningLiveGame from './LightningLiveGame';
import LightningLiveGameScore from './LightningLiveGameScore';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder';
import MlbOnBase from '@/components/charts/MlbOnBase.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    player: {type: Object, default: null},
    contest: {type: Object, default: null},
    oppPlayer: {type: Object, default: null},
    dropdownOpen: {type: Boolean, default: null},

  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
    LightningLiveGame,
    LightningLiveGameScore,
    MlbBattingOrder,
    MlbOnBase,
  },

  computed: {
    ...mapState(['todaysGames']),

    sport() {
      return this.contest.sport;
    },
  },

  methods: {
    game(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      return player.game;
    },

    liveGame(isOppPlayer = false) {
      if (!this.todaysGames[this.game(isOppPlayer).id]) {
        return null;
      }

      return this.todaysGames[this.game(isOppPlayer).id];
    },

    fspGame(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      return player.fspGame;
    },

    fieldState(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      // Need to add "{player: player}"" because that was how previous data was laid out for field state and this code is re-used from salary cap
      const fieldState = this.$SportInfo.getFieldState(this.sport, {player: player}, this.fspGame(isOppPlayer), this.liveGame(isOppPlayer));
      return fieldState;
    },

    isPitcher(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      return player != null && player.position == 'P';
    },

    isCurrentPitcher(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      return this.fieldState(isOppPlayer) && player && this.fieldState(isOppPlayer).currentPitcher === player.id;
    },

    isCurrentBatter(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      return this.fieldState(isOppPlayer) && player && this.fieldState(isOppPlayer).currentBatter === player.id;
    },

    getBatterIndex(isOppPlayer = false) {
      if (!this.liveGame(isOppPlayer) || !this.liveGame(isOppPlayer).info) {
        return null;
      }

      return this.liveGame(isOppPlayer).info.lineup;
    },

    teamHasBall(isOppPlayer = false) {
      const fieldState = this.fieldState(isOppPlayer);

      if (!fieldState) {
        return false;
      }

      return fieldState.teamHasBall || null;
    },

    inRedZone(isOppPlayer = false) {
      const fieldState = this.fieldState(isOppPlayer);

      if (!fieldState) {
        return false;
      }

      return fieldState.inRedZone || null;
    },

    isPowerPlay(isOppPlayer = false) {
      if (this.sport !== 'NHL') {
        return false;
      }

      const game = this.game(isOppPlayer);

      if (!game || !this.liveGame || !this.liveGame.live) {
        return false;
      }

      const homeAway = game.home === 'true' ? 'home' : 'away';
      const liveState = this.liveGame.liveStats;
      if (this.sport === 'NHL' && this.liveGame.live && this.liveGame.live.status === 'mid-event' && liveState != null) {
        return liveState.powerplay === homeAway;
      }

      return false;
    },

    gameIsLive(isOppPlayer = false) {
      return this.liveGame(isOppPlayer) && this.liveGame(isOppPlayer).live &&
        this.liveGame(isOppPlayer).live.status === 'mid-event' && this.fieldState(isOppPlayer) != null;
    },

    isMLB() {
      return this.contest && this.contest.sport == 'MLB';
    },

    toggleDropdown() {
      EventBus.$emit('LL_LIVE_TOGGLE_DROPDOWN', this.player.id);
    },

    openPlayerCardIfOpp(hasOpp, pId) {
      if (!hasOpp && this.oppPlayer == null && this.player) {
        this.openPlayerCard(this.player.id);
      } else if (hasOpp && pId) {
        this.openPlayerCard(pId);
      }
    },

    getStatus(player) {
      if (player && player.fspGame) {
        return player.fspGame.status;
      }
      return null;
    },

    getTeamAlias(player) {
      return this.$TeamInfo.getDisplayAlias(this.sport, player.teamAlias);
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  border-top: 1px solid var(--obcolor-background-page);
  height: 87px;
  box-sizing: border-box;
}

td {
  padding: 10px 15px;
}

.nameHeadshotSection {
  display: flex;
  align-items: center;
}

.nameInfoContainer {
  display: flex;
  flex-direction: column;
}

.position {
  text-align: center;
  width: 35px;
  font-size: 18px;
}

.playerStatus {
  display: inline-block;
}

.playerName {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;

  .nameText {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.playerInfo {
  color: var(--obcolor-font-secondary);
  padding-top: 2px;
  display: flex;

  &.uppercaseInfo {
    text-transform: uppercase;
  }

  .infoText {
    margin: 0 2px;
  }
}

.battingIcon {
  height: 14px;
  margin-top: -2px;
}

.ballIcon {
  color: var(--obcolor-NBA);
}

.redZone,
.powerPlay {
  background: red;
  color: white;
  font-weight: bold;
  padding: 1px 2px;
  border-radius: 4px;
  margin-top: -1px;
}

.liveGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  white-space: nowrap;
  overflow: visible;
  color: var(--obcolor-font-secondary);
}

tr:not(.hasOpponent) {
  cursor: pointer;

  &:hover {
    background: var(--obcolor-background-4);
  }

  .dropdownSection:hover {
    background-color: var(--obcolor-background-2);
  }
}

tr.hasOpponent {
  .nameText {
    cursor: pointer;
  }

  .nameText:hover {
    text-decoration: underline;
  }

  .dropdownSection:hover {
    background-color: var(--obcolor-background-4);
  }
}

.playerInfoSection:not(.oppPlayer) {
  .position {
    padding-right: 15px;
  }

  .playerHeadshot {
    margin-right: 15px;
  }

  .nameInfoContainer {
    align-items: flex-start;
  }

  .playerInfo {
    margin-left: 16px;
  }

  .playerStatus {
    margin-right: 4px;
  }

  .battingIcon {
    margin-left: 3px;
  }

  .redZone, .powerPlay, .ballIcon {
    margin-left: 3px;
  }
}

.playerInfoSection.oppPlayer {
  .position {
    padding-left: 17px;
  }

  .playerHeadshot {
    margin-left: 15px;
  }

  .nameInfoContainer {
    align-items: flex-end;
  }

  .playerInfo {
    margin-right: 16px;
    text-align: right;
  }

  .playerStatus {
    margin-left: 4px;
  }

  .nameHeadshotSection {
    justify-content: flex-end;
  }

  .battingIcon {
    margin-right: 3px;
  }

  .redZone, .powerPlay, .ballIcon {
    margin-right: 3px;
  }
}

.teamPoints {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 16px;
}

.noData {
  text-align: center;
  margin-bottom: 0;
  box-sizing: border-box;
}

.teamProj {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}

.redText {
  color: var(--obcolor-red) !important;
}

.dropdownSection {
  cursor: pointer;
  text-align: center;
  width: 30px;
  font-size: 16px;
}
</style>