<template>
  <div class="payoutBarsContainer">
    <div v-for="index in maxLineups" :key="index" class="payoutBar"
      :class="{bluePayout: isHighlited(index)}"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    maxLineups: {type: Number, default: null},
    numLineups: {type: Number, default: null},
  },

  methods: {
    isHighlited(index) {
      return index >= this.maxLineups - this.numLineups + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.payoutBarsContainer {
  display: flex;
  flex-direction: column;
  max-height: 56px;
  background: var(--obcolor-background-6);
}

.payoutBar {
  width: 2px;
  border-radius: 1px 0 0 1px;
  flex: 1;
  background: var(--obcolor-timeline);
  margin: 1px 0;

  &.bluePayout {
    background: var(--obcolor-ob-blue);
    box-shadow: 0px 0px 4px 0.25px var(--obcolor-ob-blue);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>