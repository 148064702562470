import SportInfo from '../SportInfo';

/**
* Given a draft position and a roster, figures out whether or not more picks can be made for that position
* @param {Object} player
* @param {Object} contestInfo
* @param {Object} roster
* @param {Object} picks
* @param {Object} pickedPlayerIds
* @return {String}
*/
export function hasValidRosterSlot(player, contestInfo, roster, picks, pickedPlayerIds) {
  // Safeguard, player id and position required
  if (picks == null || player.id == null || player.draftPos == null || pickedPlayerIds == null) {
    return false;
  }

  const draftPos = player.draftPos;
  if (draftSpotIsOpen(roster, picks, draftPos)) {
    return draftPos;
  }

  // Flex and SuperFlex positions can fit multiple slots
  if (contestInfo.isFlexContest) {
    return hasValidFlexRosterSlot(contestInfo, roster, picks, draftPos)
  }

  // No valid roster spot for this player
  return null;
}

function hasValidFlexRosterSlot(contestInfo, roster, picks, draftPos) {
  if (SportInfo.draftPosIsUtility(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, SportInfo.utilityMultiplier())) {
    return SportInfo.utilityMultiplier();
  }
  if (SportInfo.draftPosIsGuard(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, SportInfo.guard())) {
    return SportInfo.guard();
  }
  if (SportInfo.draftPosIsForward(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, SportInfo.forward())) {
    return SportInfo.forward();
  }
  if (SportInfo.draftPosIsUtility(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, SportInfo.utility())) {
    return SportInfo.utility();
  }

  if (SportInfo.draftPosIsSuperFlex(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, SportInfo.superFlexMultiplier())) {
    return SportInfo.superFlexMultiplier();
  }
  if (SportInfo.draftPosIsFlex(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, 'F')) {
    return 'F';
  }
  if (SportInfo.draftPosIsSuperFlex(draftPos, contestInfo.sport) && draftSpotIsOpen(roster, picks, 'SF')) {
    return 'SF';
  }

  return null;
}

/**
* Gets the stat in a player's game data given a path
* @param {Object} player
* @param {String} path
* @return {String}
*/
export function getStat(player, path) {
  if (player?.wStats?.[0]) {
    return player.wStats[0][path];
  }
  return null;
}

/**
* Gets the stat in a player's game data given a path
* @param {Object} roster
* @param {Object} picks
* @return {String}
*/
export function totSpent(roster, picks) {
  let tot = 0;
  if (!roster) {
    return tot;
  }
  for (const posInfo of roster) {
    for (const player of picks[posInfo.short]) {
      tot += getPlayerCost(player);
    }
  }
  return tot;
}

/**
* Gets the cost of a player based on either their per game cost or their salary
* @param {Object} player
* @return {Number}
*/
export function getPlayerCost(player) {
  if (getStat(player, 'pg') == null || player.salary == null) {
    return 0;
  }
  return player.salary;
}

/**
* Finds roster info given a specific position
* @param {Array} roster
* @param {String} position
* @return {Object}
*/
export function rosterAtPos(roster, position) {
  return roster.find((r) => {
    return r.short == position;
  });
}

/**
* Given a draft position and a roster, figures out whether or not more picks can be made for that position
* @param {Object} roster
* @param {Object} picks
* @param {String} draftPos
* @return {Boolean}
*/
export function draftSpotIsOpen(roster, picks, draftPos) {
  if (picks[draftPos] == null || !rosterAtPos(roster, draftPos)) {
    return false;
  }
  const rPos = rosterAtPos(roster, draftPos);
  return rPos.num > picks[draftPos].length;
}

/**
* Given an initial list of players and their draft positions, returns a map for lookup
* @param {Array} initialPlayers
* @return {Object}
*/
export function initialPlayersMap(initialPlayers) {
  const rosterMap = {};
  for (const pInfo of initialPlayers) {
    rosterMap[pInfo.id] = pInfo.position;
  }
  return rosterMap;
}

/**
* Given an initial list of players and their draft positions and the current picks,
* determines if the roster has changed
* @param {Array} initialPlayers
* @param {Array} curRoster
* @param {Object} picks
* @return {Boolean}
*/
export function rosterHasChanged(initialPlayers, curRoster, picks) {
  if (!initialPlayers || !curRoster || !picks) {
    return false;
  }
  const initPlayersMap = initialPlayersMap(initialPlayers);
  for (const posInfo of curRoster) {
    for (const player of picks[posInfo.short]) {
      if (initPlayersMap[player.id] != posInfo.short) {
        return true;
      }
    }
  }
  return false;
}

export default {
  getStat,
  getPlayerCost,
  rosterAtPos,
  draftSpotIsOpen,
  totSpent,
  hasValidRosterSlot,
  rosterHasChanged,
};