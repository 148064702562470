<template>
  <div class="container">
    <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
    <OnBoardingProgressBar :customCurrentStep="2" :totalSteps="2" />
    <DepositView onBoardingStep :showConnectKroger="isKrogerAffiliate && showConnections" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import OnBoardingProgressBar from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingProgressBar';
import DepositView from '@/views/Finance/deposit/DepositView';
import {mapState} from 'vuex';

export default {
  components: {
    OnBoardingProgressBar,
    DepositView,
  },

  computed: {
    ...mapState(['isKrogerAffiliate', 'showConnections']),
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },
  },
};
</script>