<template>
  <div class="itemListContainer">
    <div class="itemList" :class="{'itemsWithPayout': payoutInfo !== null}">
      <div v-if="contestTypeOptions.id === 'MULTIPLIER'">
        <p>Multiplier size:</p>
        <select v-model="localDetails.multiplierSize">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in contestTypeOptions.multiplierSizeOptions" :key="index" :label="option.label"
            :value="option.id"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div v-if="contestTypeOptions.id === 'MULTIPLIER'">
        <div class="withTooltip">
          <p>Contest size:</p>
          <div v-tooltip.right="{content: 'Contest size options are based on multiplier size', class:'ObTooltip'}">
            <i class="fas fa-info-circle fa-xs"></i>
          </div>
        </div>
        <select v-model="localDetails.contestSize">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in contestSizeOptions" :key="index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div v-if="contestTypeOptions.id === 'TEN_MAN'">
        <p>Contest size:</p>
        <div class="tenManContestSize">10 Entrants</div>
      </div>
      <div v-if="contestTypeOptions.id !== 'MULTIPLIER' && contestTypeOptions.id !== 'TEN_MAN'">
        <p>Contest size:</p>
        <select v-model="localDetails.contestSize">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in contestTypeOptions.contestSizeOptions" :key="index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div>
        <p>Entry Fee:</p>
        <select v-model="localDetails.entryFee">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in contestTypeOptions.entryFees" :key="index" :value="option">
            {{ entryFee(option) }}
          </option>
        </select>
      </div>
      <div v-if="contestTypeOptions.id === 'BATTLE_ROYALE' || contestTypeOptions.id === 'MULTIPLIER'">
        <div class="withTooltip">
          <p>Max Entry:</p>
          <div v-tooltip.right="{content: 'Max entry options are based on contest size', class:'ObTooltip'}">
            <i class="fas fa-info-circle fa-xs"></i>
          </div>
        </div>
        <select v-model="localDetails.maxEntry">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in maxEntries" :key="index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div v-if="isRookieUser">
        <p>Entry Type:</p>
        <select v-model="localDetails.isRookie">
          <option :value="null" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in rookieOptions" :key="index" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
      <div v-if="contestTypeOptions.id !== 'MULTIPLIER'">
        <p>Prize Structure:</p>
        <select v-model="localDetails.prizeStructure">
          <option :value="{id: null, label:null}" disabled label="Select an Option">
            Select an Option
          </option>
          <option v-for="(option, index) in contestTypeOptions.prizeStructureOptions" :key="index" :value="option">
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="payoutInfo !== null" class="payoutTable">
      <p>Payout Table:</p>
      <div class="payoutBox ob-scroll">
        <div v-for="(payout, index) in payoutInfo" :key="index">
          <div class="payoutRow">
            <p>{{ payout.label }}</p>
            <p class="greenText">{{ payout.prize }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="payoutApiError" class="payoutInfoFail">
      <p>{{ payoutApiErrorMessage }}</p>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    rookieOptions: {type: Array, default: null},
    contestTypeOptions: {type: Object, default: null},
    selectedDetails: {type: Object, default: null},
    payoutInfo: {type: Array, default: null},
    payoutApiError: {type: Boolean, default: false},
    payoutApiErrorMessage: {type: Boolean, default: false},
  },

  created() {
    this.callPayoutApi();
    if (this.contestTypeOptions.id === 'TEN_MAN') {
      this.localDetails.contestSize = 10;
    }
  },

  watch: {
    'localDetails.isRookie'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.emitDetails();
    },

    'localDetails.multiplierSize'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.localDetails.contestSize = null;
      this.localDetails.maxEntry = null;
      this.callPayoutApi();
      this.emitDetails();
    },

    'localDetails.contestSize'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.localDetails.maxEntry = null;
      this.callPayoutApi();
      this.emitDetails();
    },

    'localDetails.entryFee'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.callPayoutApi();
      this.emitDetails();
    },

    'localDetails.maxEntry'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.emitDetails();
    },

    'localDetails.prizeStructure'(newVal, oldVal) {
      if (newVal.id === oldVal.id) {
        return;
      }
      this.callPayoutApi();
      this.emitDetails();
    },
  },

  computed: {
    ...mapState(['rankNum']),

    localDetails: {
      get() {
        return this.selectedDetails;
      },
    },

    isRookieUser() {
      return this.rankNum && this.rankNum === 1;
    },

    maxEntries() {
      const maxEntry = [];
      if (this.localDetails.contestSize) {
        for (let i = 1; i <= this.contestTypeOptions.maxEntryOptions[this.localDetails.contestSize]; i ++) {
          maxEntry.push(i);
        }
      }
      return maxEntry;
    },

    contestSizeOptions() {
      if (this.localDetails.multiplierSize) {
        for (const option of this.contestTypeOptions.contestSizeOptions) {
          // Looping through Contest Size Options for different Multiplier size (Double up, Triple up...) and if
          // option[Double up] exists, return the array which has the correct contest size array
          if (option[this.localDetails.multiplierSize] !== undefined) {
            return option[this.localDetails.multiplierSize];
          }
        }
      }
      return null;
    },
  },

  methods: {
    emitDetails() {
      this.$emit('selectDetails', this.localDetails);
    },

    entryFee(fee) {
      if (!fee) {
        return '$0';
      }
      return (fee / 100).formatMoneyDecimals().removeCentsIfZero();
    },

    callPayoutApi() {
      const validContestSize = this.localDetails.entryFee && this.localDetails.contestSize && this.contestTypeOptions.id;
      const validPrizes = this.localDetails.multiplierSize || this.localDetails.prizeStructure.id;
      if (validContestSize && validPrizes) {
        this.$emit('getCreateContestPayouts');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.itemListContainer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.itemList {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 0 auto;
  margin-bottom: 20px;

  p {
    margin: 0;
    align-self: center;
    margin-right: 20px;
    text-align: left;
  }

  > div:nth-child(1) {
    margin-top: 40px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  select {
    border-radius: 0;
    width: 160px;
    height: 35px;
    background: var(--obcolor-background-6);
    border: 2px solid var(--obcolor-background-4);
  }

  .tenManContestSize {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    padding-left: 5px;
    width: 151px;
    height: 31px;
    background: var(--obcolor-background-6);
    border: 2px solid var(--obcolor-background-4);
  }
}

.itemListWithPayout {
  margin: 0;
}

.payoutTable {
  height: 200px;
  margin-top: 40px;
  > p {
    margin: 0 0 10px 0;
    color: var(--obcolor-font-secondary);
  }
}

.payoutBox {
  padding: 10px;
  width: 280px;
  max-height: 200px;
  overflow: auto;
  background: var(--obcolor-background-6);
  border: 2px solid var(--obcolor-background-4);
}

.payoutRow {
  display: flex;
  justify-content: space-between;
}

.greenText {
  color: var(--obcolor-realmoney);
}

.withTooltip {
  display: flex;
  justify-content: flex-start;
  p {
    margin-right: 5px;
  }
}

.fa-info-circle {
  color: var(--obcolor-font-secondary);
}

.payoutInfoFail p {
  margin-top: 40px;
}
</style>