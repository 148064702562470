<template>
  <TooltipContainer :pos="pos" class="ttContainer">
    <i slot="trigger" :class="iconStyle" class="fa-info-circle infoIcon"></i>
    <div slot="content">
      <TooltipText :textList="$Tooltips.getTooltips(textKey)" />
    </div>
  </TooltipContainer>
</template>

<script>
import TooltipText from '@/components/tooltip/TooltipText';
import TooltipContainer from '@/components/tooltip/TooltipContainer';

export default {
  components: {
    TooltipText,
    TooltipContainer,
  },

  props: {
    pos: {type: String, default: null},
    textKey: {type: String, default: null},
    iconStyle: {type: String, default: 'fas'},
  },
};
</script>

<style lang="scss" scoped>
  .ttContainer {
    margin-left: 5px;
    display: inline-block;
    color: var(--obcolor-text-faded);
    white-space: normal;
  }
  .infoIcon {
    font-size: 14px;
  }
</style>