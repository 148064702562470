<template>
  <div class="ob-page container">
    <h1>Reset Password</h1>
    <p>Enter the email associated with your account and you’ll receive instructions on how to reset your password.</p>
    <div style="padding: 50px 0 10px 0;">Email</div>
    <input placeholder="Enter your email" name="email" type="email"
      v-model="forgotPassEmail"
    >
    <ErrorPanel :message="errorMessage" />

    <button v-if="waitingForSendEmail" class="ob-btn-grey">
      <i class="fad fa-spinner-third fa-spin"></i>Sending email...
    </button>

    <button v-else-if="emailSent" class="ob-btn successBtn">
      <i class="fa fa-check"></i>
      Instructions have been sent to the provided email
    </button>

    <button v-else class="ob-btn" :disabled="!forgotPassEmail"
      @click="resendPassword()"
    >
      Send Reset Password
    </button>
    <div class="rememberedPass">You remember your Password? <router-link to="/login" class="linkText">Login</router-link></div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    ErrorPanel,
  },

  data() {
    return {
      forgotPassEmail: null,
      errorMessage: null,
      waitingForSendEmail: false,
      emailSent: false,
    };
  },

  methods: {
    resendPassword() {
      this.waitingForSendEmail = true;

      if (this.forgotPassEmail.trim().length == 0) {
        this.forgotPassError = 'Please enter a valid email';
        this.waitingForSendEmail = false;
        return;
      } else {
        this.forgotPassError = null;
      }

      const params = {email: this.forgotPassEmail.trim()};

      ObAccountApi.forgotPassword(params)
          .then((success) => {
            this.emailSent = true;
            this.waitingForSendEmail = false;
          })
          .catch((_error) =>{
            this.waitingForSendEmail = false;
            this.errorMessage = 'Wrong email, please enter the correct email';
          });
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

h1 {
  padding: 50px 0 30px 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 705px;
  margin: 0 auto;

  input {
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
    padding: 14px;
    font-size: 18px;
    margin-bottom: 15px;
    height: 22px;
    flex: 1;
  }
  button {
    margin-top: 40px;
    height: 60px;
  }
}

.successBtn {
  background: var(--obcolor-green) !important;
  cursor: default;
}

.ob-btn, .ob-btn-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ob-btn[disabled] {
  background: var(--obcolor-font-secondary);
  color: var(--obcolor-background-2) !important;
  cursor: not-allowed;
}

.rememberedPass {
  padding-top: 35px;
  text-align: center;

  .linkText {
    cursor: pointer;
    font-weight: 900;
    color: var(--obcolor-ob-blue);
  }
}
</style>