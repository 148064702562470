<template>
  <div class="scheduleBox" :style="!game || (proj === 0 && !played) ? 'opacity: 0.4;': ''">
    <div v-if="game">
      <div class="logoBox">
        <div v-if="game.includes('@')">@</div>
        <img :src="getTeamLogo(game)" :alt="`${game} Team`" style="height: 18px;">
      </div>
      <div v-if="fpts != undefined && played" class="blueText" :style="fpts < 0 ? 'color: red;' : ''">{{ formatPoints(fpts) }}</div>
      <div v-else-if="result">DNP</div>
      <div v-else :style="proj === 0 ? 'color: var(--obcolor-font-secondary);' : ''">{{ roundStat(proj) }}</div>
      <div v-if="result" class="gameTime">{{ formatGameResult(result, score) }}</div>
      <div v-else class="gameTime">{{ parseInt(ts) | moment("h:mma") }}</div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    contest: Object,
    entry: Object,
    isDH: Boolean,
  },

  computed: {

    dhFlag() {
      return this.isDH ? 'DH' : '';
    },

    game() {
      return this.entry['game' + this.dhFlag];
    },

    proj() {
      return this.entry['proj' + this.dhFlag];
    },

    played() {
      return this.entry['played' + this.dhFlag];
    },

    fpts() {
      return this.entry['fpts' + this.dhFlag];
    },

    result() {
      return this.entry['result' + this.dhFlag];
    },

    score() {
      return this.entry['score' + this.dhFlag];
    },

    ts() {
      return this.entry['ts' + this.dhFlag];
    },

  },

  methods: {

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    formatPoints(fpts) {
      return fpts < 0 ? fpts : '+' + fpts;
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    formatGameResult(result, score) {
      if (result === 'win') {
        return 'W ' + score;
      }
      if (result === 'loss') {
        return 'L ' + score;
      }
      return score;
    },

  },

};
</script>

<style lang="scss" scoped>
  .scheduleBox {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    background: var(--obcolor-background-2);
    min-height: 52px;
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-primary);
  }

  .blueText {
    color: var(--obcolor-ob-blue);
  }

  .logoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-font-secondary);
    margin-bottom: 2px;

    div {
      margin-left: -11px;
      margin-right: 2px;
      margin-top: 5px;
    }
  }

  .gameTime {
    font-weight: normal;
    font-size: 11px;
    margin-top: 1px;
    color: var(--obcolor-font-secondary);
    white-space: nowrap;
    padding: 0 3px;
  }
</style>
