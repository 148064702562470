<template>
  <div class="rosterBox ob-box" v-if="roster">
    <!-- Header -->
    <div class="statRow">
      <div class="statVal" style="margin-right: 5px;">
        <Dropdown v-model="editLineup" :options="lineupEntries" :text="lineupEntryText"
          style="width: 100px;"
        />
      </div>
      <div class="lineupNameBox">
        <i class="fa fa-edit"></i>
        <div style="flex: 1;">
          <input placeholder="Custom lineup name" v-model="lineupName.name" type="text"
            maxlength="35"
          >
        </div>
      </div>
      <div v-if="contest.format == 'WEEKLY'" class="ob-link calendarBtn" v-tooltip.bottom="{content: 'Calendar View', class:'ObTooltipHeat'}"
        @click="openCalendar()"
      >
        <i class="far fa-calendar-alt" style="margin-top: -1px;"></i>
      </div>
    </div>

    <!-- Total player salary information -->
    <table class="salaryTable" aria-label="Player Salary Information">
      <tr>
        <td>
          <div class="salaryHeader">COMMITTED SALARY</div>
          <div class="salaryVal">{{ dollarFormat(totSpent) }}</div>
        </td>
        <td>
          <div class="salaryHeader">REMAINING SALARY</div>
          <div class="salaryVal moneyText" :style="contest.salaryCap - totSpent < 0 ? 'color: red' : ''">
            {{ dollarFormat(contest.salaryCap - totSpent) }}
          </div>
        </td>
        <td>
          <div class="salaryHeader">PROJECTED</div>
          <div class="salaryVal blueText">{{ totProjected }}</div>
        </td>
      </tr>
      <tr v-if="contest.salaryCap">
        <td>
          <span class="salaryVal" style="font-size: 14px">
            {{ totPicked > 0 ? dollarFormat(totSpent / totPicked) : '$0' }}
          </span>
          <span class="statTitle" style="font-size: 14px;">Average</span>
        </td>
        <td>
          <span class="salaryVal" style="font-size: 14px" :style="avgRemainingSalary < 0 ? 'color: red' : ''">
            {{ dollarFormat(avgRemainingSalary) }}
          </span>
          <span class="statTitle" style="font-size: 14px">Average</span>
        </td>
        <td></td>
      </tr>
    </table>

    <!-- List of boxes showing games/cost per position -->
    <div class="posRow">
      <div class="posField" v-for="(posInfo, index) in roster" :key="index"
        :style="'border: 1px solid ' + posStatusInfo(posInfo).bg"
      >
        <div class="posTitle" :style="'background: ' + posStatusInfo(posInfo).bg + '; color: ' + posStatusInfo(posInfo).fg">
          {{ posInfo.short }}
        </div>
        <div class="posVal">
          <span :style="posStatusInfo(posInfo).text ? 'color: ' + posStatusInfo(posInfo).bg : ''">{{ posFilledGames(posInfo.short) }}</span>
          <span> / {{ posInfo.maxGames }}</span>
        </div>
        <div class="posVal" style="font-size: 12px; color: var(--obcolor-font-secondary)">{{ totCostByPosition(posInfo.short) }}</div>
      </div>
    </div>

    <!-- Warnings/important messages and "submit lineup" box -->
    <div class="statRow">
      <!-- Lineup Warnings -->
      <div>
        <div v-if="hasUnusedOpps" class="infoText" style="color: var(--obcolor-red);">
          <span>Unused Game Opportunities</span>
          <Tooltip pos="bottom" textKey="SalaryCap_UnusedOpps" />
        </div>
        <div v-if="hasBestBall" class="infoText" style="color: var(--obcolor-NBA); margin-top: 2px; margin-bottom: -2px;">
          <span>Best Ball Activated</span>
          <Tooltip pos="bottom" textKey="SalaryCap_BestBall" />
        </div>
      </div>
      <div style="flex: 1;"></div>
    </div>

    <!-- List of picked players in roster -->
    <div class="playerList ob-scroll">
      <!-- Use preset groupings for positions -->
      <div class="playerRow" v-for="(posList, index) in displayGroups" :key="index">
        <!-- Go through each position in grouping array -->
        <template v-for="(pos, index1) in posList">
          <!-- Make a box for each player in that position -->
          <SalaryCapRosterPlayer v-for="(player, index2) in picks[pos]"
            :class="{ selectedPlayer: selectedPlayer.id == player.id }"
            :key="index + '_' + index1 + '_' + index2"
            :player="player" :contest="contest" :pos="pos"
            :statusMap="statusMap"
          />
          <SalaryCapRosterPlayer v-for="index3 in rosterAtPos(pos).num - picks[pos].length"
            :statusMap="statusMap"
            :key="index + '_' + index1 + '_' + index3 + '_empty'" :contest="contest" :pos="pos"
          />
        </template>
      </div>
    </div>
    <!-- Warnings/important messages and "submit lineup" box -->
    <div class="statRow">
      <!-- Import lineups button -->
      <div class="submitButtons" style="flex: 1;">
        <button class="ob-btn-grey ob-btn-med" :disabled="importOpen" @click="openImportLineups()">Import Lineup</button>
      </div>
      <!-- Reset Lineup -->
      <div class="ob-btn-grey ob-btn-med" style="display: flex; flex: 1; justify-content: center; margin-right: 7.5px; margin-left: 7.5px;" @click="resetRoster()">
        <div>Reset</div>
        <div style="margin-left: 6px;"><i class="fas fa-repeat-alt"></i></div>
      </div>
      <!-- Edit Lineup -->
      <div v-if="editingTeam" class="submitButtons" style="flex: 1;">
        <button v-if="submittingLineup" disabled class="ob-btn-grey ob-btn-med">Submitting...</button>
        <button v-else-if="canSubmit" class="ob-btn ob-btn-med" @click="openEditConfirmModal()">Preview</button>
        <button v-else-if="overCap" disabled class="ob-btn-grey ob-btn-med">Over Salary Cap</button>
        <button v-else-if="contestFull" disabled class="ob-btn-grey ob-btn-med">Contest Full</button>
        <button v-else disabled class="ob-btn-grey ob-btn-med">Preview</button>
      </div>
      <!-- Submit New Lineup -->
      <div v-else class="submitButtons" style="flex: 1;">
        <button v-if="submittingLineup" disabled class="ob-btn-grey ob-btn-med">Submitting...</button>
        <button v-else-if="canSubmit" class="ob-btn ob-btn-med" @click="openSubmitConfirmModal()">Submit</button>
        <button v-else-if="overCap" disabled class="ob-btn-grey ob-btn-med">Over Salary Cap</button>
        <button v-else-if="contestFull" disabled class="ob-btn-grey ob-btn-med">Contest Full</button>
        <button v-else-if="maxEntriesReached" disabled class="ob-btn-grey ob-btn-med">Max Entries Reached</button>
        <button v-else disabled class="ob-btn-grey ob-btn-med">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import SalaryCapRosterPlayer from '@/views/SalaryCapGame/SalaryCapRosterPlayer';
import Dropdown from '@/components/Dropdown';
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  components: {
    SalaryCapRosterPlayer,
    Dropdown,
    Tooltip,
  },

  props: {
    picks: Object,
    contest: Object,
    totSpent: Number,
    selectedPlayer: Object,
    statusMap: Object,
    lineupName: Object,
    submittedLineups: Object,
    editingTeam: String,
    promoInfo: Object,
    playerList: Array,
  },

  data() {
    return {
      editLineup: 'New',
      submittingLineup: false,
      importOpen: false,
    };
  },

  computed: {
    displayGroups() {
      if (!this.contest) {
        return [];
      }
      return this.$SportInfo.getScDisplayGroups(this.contest.sport, this.contest.format == 'DAILY');
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    lineupEntries() {
      const entries = ['New'];
      for (let i=0; i<Object.keys(this.submittedLineups).length; i++) {
        entries.push(String(i+1));
      }
      return entries;
    },

    lineupEntryText() {
      const entries = ['New Lineup'];
      for (let i=0; i<Object.keys(this.submittedLineups).length; i++) {
        entries.push('Lineup #' + String(i+1));
      }
      return entries;
    },

    totProjected() {
      let tProj = 0;

      for (const posInfo of this.roster) {
        let projList = [];
        let totPlayerGames = 0;
        for (const player of this.picks[posInfo.short]) {
          for (const game of player.games) {
            if (game.proj != null) {
              projList.push(game.proj);
              totPlayerGames++;
            }
            if (game.projDH != null) {
              projList.push(game.projDH);
              totPlayerGames++;
            }
          }
        }

        projList = projList.sort((proj1, proj2) => proj2 - proj1);
        const maxGames = posInfo.maxGames < totPlayerGames ? posInfo.maxGames : totPlayerGames;
        for (let index = 0; index < maxGames; index++) {
          tProj += projList[index];
        }
      }

      return tProj.toFixed(0);
    },

    totPicked() {
      let tot = 0;
      for (const posInfo of this.roster) {
        if (this.picks[posInfo.short]) {
          tot += this.picks[posInfo.short].length;
        }
      }

      return tot;
    },

    totPicksRemaining() {
      let tot = 0;
      for (const posInfo of this.roster) {
        tot += posInfo.num;
      }
      return tot - this.totPicked;
    },

    avgRemainingSalary() {
      if (this.totPicksRemaining == 0) {
        return 0;
      }
      if (this.totSpent == null) {
        return this.contest.salaryCap / this.totPicksRemaining;
      }
      const remainingAvg = (this.contest.salaryCap - this.totSpent) / this.totPicksRemaining;
      return remainingAvg.toFixed(0);
    },

    canAffordTeam() {
      let totPrice = 0;

      for (const posInfo of this.roster) {
        if (this.picks[posInfo.short]) {
          for (const player of this.picks[posInfo.short]) {
            totPrice += player.salary || 0;
          }
        }
      }

      return totPrice < this.contest.salaryCap;
    },

    canSubmit() {
      return this.totPicksRemaining == 0 && this.contest && this.contest.salaryCap &&
        this.contest.id && !this.overCap && (!this.maxEntriesReached || this.editingTeam) && (!this.contestFull || this.editingTeam);
    },

    overCap() {
      return this.totSpent !== null && this.totSpent > this.contest.salaryCap;
    },

    contestFull() {
      return this.contest.playersCurrent >= this.contest.playersMax;
    },

    maxEntriesReached() {
      if (!this.contest || !this.contest.myEntries || !this.contest.entriesPerUser) {
        return false;
      }

      return this.contest.myEntries >= this.contest.entriesPerUser;
    },

    hasBestBall() {
      for (const posInfo of this.roster) {
        const pos = posInfo.short;
        const posFilledGames = this.posFilledGames(pos);
        if (posFilledGames > posInfo.maxGames) {
          return true;
        }
      }
      return false;
    },

    hasUnusedOpps() {
      for (const posInfo of this.roster) {
        const pos = posInfo.short;
        const posPicks = this.picks[pos];
        const posFilledGames = this.posFilledGames(pos);
        if (posPicks.length == posInfo.num && posInfo.maxGames > posFilledGames) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    editLineup(to, from) {
      EventBus.$emit('SALARY_CAP_SELECT_LINEUP', to);
    },
  },

  created() {
    EventBus.$on('SALARY_CAP_SET_SELECTED_LINEUP', this.setSelectedLineup);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_SET_SELECTED_LINEUP', this.setSelectedLineup);
  },

  methods: {
    resetRoster() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER');
    },

    setSelectedLineup(teamNum) {
      this.editLineup = teamNum;
    },

    openEditConfirmModal() {
      const params = {
        picks: this.picks,
        contest: this.contest,
        totSpent: this.totSpent,
        selectedPlayer: this.selectedPlayer,
        statusMap: this.statusMap,
        lineupName: this.lineupName.name,
        editMode: true,
      };
      EventBus.$emit('OPEN_SALARY_CAP_CONFIRM_MODAL', params);
    },

    openSubmitConfirmModal() {
      const params = {
        picks: this.picks,
        contest: this.contest,
        totSpent: this.totSpent,
        selectedPlayer: this.selectedPlayer,
        statusMap: this.statusMap,
        lineupName: this.lineupName.name,
        editMode: false,
        promoInfo: this.promoInfo,
      };
      EventBus.$emit('OPEN_SALARY_CAP_CONFIRM_MODAL', params);
    },

    openCalendar() {
      EventBus.$emit('OPEN_SALARY_CAP_CALENDAR_MODAL', this.contest, this.picks);
    },

    totCostByPosition(pos) {
      const posRoster = this.picks[pos];
      let tot = 0;
      for (const player of posRoster) {
        tot += player.salary;
      }
      return this.dollarFormat(tot);
    },

    rosterAtPos(pos) {
      return this.roster.find((r) => {
        return r.short == pos;
      });
    },

    posStatusInfo(posInfo) {
      const pos = posInfo.short;
      const posPicks = this.picks[pos];
      const posFilledGames = this.posFilledGames(pos);

      if (posPicks.length == posInfo.num && posInfo.maxGames > posFilledGames) {
        return {bg: 'var(--obcolor-red)', fg: 'white', text: 'Unused Game Opportunities', order: 1};
      } else if (posFilledGames > posInfo.maxGames) {
        return {bg: 'var(--obcolor-NBA)', fg: 'black', text: 'Best Ball Activated', order: 2};
      }

      // bg: background colour (and text colour if used in messaging), fg: foreground colour if background colour is used
      // text: text for error message, order: order in which errors should be shown
      return {bg: 'var(--obcolor-background-2)', fg: 'var(--obcolor-font-secondary)', text: '', order: 3};
    },

    posFilledGames(pos) {
      let totGames = 0;
      for (const player of this.picks[pos]) {
        totGames += this.getStat(player, 'pg') || 0;
      }
      return totGames;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const d = Math.round(dollarAmount);
      return '$' + Intl.NumberFormat('en-US').format(d);
    },

    ptsToCostValue(pts) {
      if (pts == null) {
        return 0;
      }
      return pts * this.salaryMultiplier;
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }
      return null;
    },

    openImportLineups() {
      EventBus.$emit('OPEN_SALARY_CAP_IMPORT_MODAL', this.contest, this.playerList);
    },

    disableImportLineups() {
      this.importOpen = !this.importOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
  .rosterBox {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
  }

  .statRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .statRow:not(:first-child) {
    margin-top: 10px;
  }

  .statField {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    width: 120px;
  }

  .statVal {
    font-size: 16px;
    font-weight: bold;
  }

  .statTitle {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .salaryTable {
    margin-top: 10px;
    width: 100%;

    tr:not(:first-child) td {
      padding-top: 10px;
    }
  }

  .salaryCap {
    font-weight: normal;
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
  }

  .salaryHeader {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    padding-bottom: 2px;
  }

  .salaryVal {
    font-size: 20px;
    font-weight: bold;
  }

  .posRow {
    display: flex;
    justify-content: center;
    margin: 10px -3px 0 -3px;
    width: calc(100% + 6px);
  }

  .posField {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    margin: 0 3px;
    border: 1px solid var(--obcolor-background-2);

    width: 120px;
  }

  .posVal {
    font-size: 16px;
    padding-top: 3px;
    width: 100%;
    box-sizing: border-box;
  }

  .posVal:last-child {
    padding-bottom: 3px;
  }

  .posTitle {
    font-size: 14px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    background: var(--obcolor-background-2);
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
  }

  .infoText {
    font-size: 14px;
    display: flex;
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

  .playerList {
    width: calc(100% + 6px);
    margin: 10px -3px 0 -3px;
    border-top: 2px solid var(--obcolor-background-2);
    padding-top: 8px;
    min-height: 0;
    overflow: auto;
  }

  .playerRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
  }

  .smallBtn {
    padding: 6px 12px;
    font-size: 14px;
  }

  .submitButtons {
    button {
      min-width: 120px;
    }
  }
  .ob-btn {
    border-radius: 0;
  }
  .ob-btn-grey {
    border-radius: 0;
  }

  .lineupNameBox {
    height: 27px;
    flex: 1;
    border: 1px solid var(--obcolor-background-1);
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin-right: 5px;
    font-size: 12px;
    color: var(--obcolor-font-secondary);

    input {
      background: none;
      height: 100%;
      width: 100%;
    }
  }

  .calendarBtn {
    font-size: 18px;
    border: 1px solid var(--obcolor-background-1);
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-font-secondary);
  }
  .calendarBtn:hover {
    background: var(--obcolor-background-2);
    color: var(--obcolor-font-primary);
  }
</style>