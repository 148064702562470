<template>
  <div>
    <Modal v-if="isOpen" v-model="isOpen"
      containerStyle="width: 716px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
      bodyStyle="padding-bottom: 0;" :disableClickaway="true"
    >
      <div slot="body" class="confirmBody">
        <div class="redeemHeader">
          <div class="redeemText">
            <h3>Redeem OwnersBucks for Tickets</h3>
            <div class="redeemInfoContainer">
              Tickets allow you to enter OwnersBox contests for free!
              Use your redeemable OwnersBucks to purchase tickets and win big cash prizes.
            </div>
          </div>
          <div class="redeemableGraph">
            <RedeemableOBucks :ownersbucksTot="ownersbucks" :ownersbucksRedeemable="redeemable" />
          </div>
        </div>
        <div class="ob-box ticketInputContainer">
          <div class="ticketInputList">
            <div class="inputSection">
              <div class="inputTitle">Ticket Value</div>
              <div class="ob-input-container">
                <img src="@/assets/icons/ticket.png" alt="tickets">
                <input class="ob-input" placeholder="Ticket Value" v-model="ticketValue"
                  @input="setQuantityToLimit"
                >
              </div>
            </div>
            <div class="inputSection quantitySection">
              <div class="inputTitle">Quantity</div>
              <input class="ob-input" placeholder="Quantity" v-model="ticketQuantity"
                @input="setValueToLimit"
              >
            </div>
            <div class="inputSection">
              <div class="inputTitle">OwnersBucks</div>
              <div class="ob-input-container">
                <img class="ownersbucksIcon" src="@/assets/icons/ownersbucks.png" alt="ownersbucks">
                <input disabled class="ob-input" placeholder="OwnersBucks"
                  :value="totOwnersBucksCost"
                >
              </div>
            </div>
          </div>
        </div>
        <ErrorPanel :message="redeemError" />
        <div class="modalButtonContainer">
          <button @click="closeModal" class="ob-btn-grey">Cancel</button>
          <button v-if="!isRedeeming" @click="redeemConfirm" class="ob-btn"
            :disabled="!canSubmit"
          >
            Redeem
          </button>
          <button v-else class="ob-btn" disabled>
            <ObLoading customTheme="light" :size="50" class="btnLoadingIcon" />
            Redeeming
          </button>
        </div>
      </div>
    </Modal>
    <OwnersBucksRedeemConfirmModal @confirm="redeemTickets" />
    <OwnersBucksRedeemSuccessModal />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import RedeemableOBucks from '@/components/charts/RedeemableOBucks';
import OwnersBucksRedeemConfirmModal from './OwnersBucksRedeemConfirmModal.vue';
import ObFinanceApi from '@/api/ObFinanceApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import ObLoading from '@/components/ObLoading.vue';
import OwnersBucksRedeemSuccessModal from './OwnersBucksRedeemSuccessModal.vue';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    RedeemableOBucks,
    OwnersBucksRedeemConfirmModal,
    OwnersBucksRedeemSuccessModal,
    ErrorPanel,
    ObLoading,
  },
  data() {
    return {
      isOpen: false,
      profile: null,
      ticketValue: null,
      ticketQuantity: null,
      maxRedeemQuantity: 10,
      maxTicketValue: 1000,
      redeemError: '',

      isRedeeming: false,
    };
  },
  created() {
    EventBus.$on('REDEEM_FOR_TICKETS_OPEN', this.openModal);
  },
  destroyed() {
    EventBus.$off('REDEEM_FOR_TICKETS_OPEN', this.openModal);
  },
  computed: {
    ...mapState(['ownersbucksValue']),

    canSubmit() {
      return this.ticketValueNum > 0 && this.ticketQuantityNum > 0;
    },

    ownersbucks() {
      if (!this.ownersbucksValue) {
        return null;
      }

      return (this.ownersbucksValue / 100).toFixed(2);
    },

    redeemable() {
      if (!this.profile?.convertible) {
        return null;
      }

      let redeemableVal = parseFloat(this.profile?.convertible.replace(/,/g, ''));

      if (redeemableVal > this.ownersbucks) {
        redeemableVal = this.ownersbucks;
      }

      return redeemableVal;
    },

    totOwnersBucksCost() {
      const ownersbucks = this.calcTotOwnersbucksCost(this.ticketValue, this.ticketQuantity);
      return ownersbucks ? Math.round(ownersbucks * 100) / 100 : null;
    },

    ticketValueNum() {
      return parseFloat(this.ticketValue);
    },

    ticketQuantityNum() {
      return parseInt(this.ticketQuantity);
    },

    ticketValueCents() {
      return Math.round(this.ticketValue * 100);
    },
  },

  watch: {
    ticketValue(newVal, oldVal) {
      if (newVal == null || newVal == '') {
        return;
      }

      if (newVal == '.') {
        this.ticketValue = '0.';
        return;
      }

      const roundedVal = Math.floor(parseFloat(newVal) * 100) / 100;
      const valNum = parseFloat(newVal);
      if (valNum != newVal) {
        this.ticketValue = oldVal;
      } else if (valNum > this.maxTicketValue) {
        this.ticketValue = this.maxTicketValue;
        // If going over limit, quantity may be set to a lower amount, reset it to the overall limit
        this.setQuantityToLimit();
      } else if (valNum < 0) {
        this.ticketValue = '';
      } else if (valNum !== roundedVal) {
        this.ticketValue = roundedVal;
      }
    },

    ticketQuantity(newVal, oldVal) {
      if (newVal == null || newVal == '') {
        return;
      }

      const valNum = parseInt(newVal);
      if (valNum != newVal) {
        this.ticketQuantity = oldVal;
      } else if (valNum > this.maxRedeemQuantity) {
        this.ticketQuantity = this.maxRedeemQuantity;
        // If going over limit, value may be set to a lower amount, reset it to the overall limit
        this.setValueToLimit();
      } else if (valNum < 0) {
        this.ticketQuantity = '';
        return;
      }
    },
  },

  methods: {
    calcTotOwnersbucksCost(value, quantity) {
      if (!value || !quantity) {
        return null;
      }

      return value * quantity;
    },

    setQuantityToLimit() {
      if (this.ticketQuantity <= 0) {
        return;
      }
      if (this.ticketValue > this.maxTicketValue) {
        this.ticketValue = this.maxTicketValue;
      }
      const ownersbucks = this.calcTotOwnersbucksCost(this.ticketValue, this.ticketQuantity);
      if (ownersbucks > this.redeemable) {
        this.ticketQuantity = Math.floor(this.redeemable / this.ticketValue);
      }
    },

    setValueToLimit() {
      if (this.ticketValue <= 0) {
        return;
      }
      if (this.ticketQuantity > this.maxRedeemQuantity) {
        this.ticketQuantity = this.maxRedeemQuantity;
      }
      const ownersbucks = this.calcTotOwnersbucksCost(this.ticketValue, this.ticketQuantity);
      if (ownersbucks > this.redeemable) {
        this.ticketValue = Math.floor((this.redeemable / this.ticketQuantity) * 100) / 100;
      }
    },

    resetData() {
      this.ticketValue = null;
      this.ticketQuantity = null;
    },

    openModal(profile) {
      this.resetData();
      this.profile = profile;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('cancel');
    },

    redeemConfirm() {
      EventBus.$emit('OPEN_REDEEM_CONFIRM_MODAL', this.totOwnersBucksCost, this.ticketQuantity, this.ticketValue);
    },

    // Send ticket values in the format [{ valueCents: 100, quantity: 3 }]
    redeemTickets() {
      this.isRedeeming = true;
      const valueList = [{
        valueCents: this.ticketValueCents,
        quantity: this.ticketQuantityNum,
      }];
      ObFinanceApi.redeemTickets(valueList)
          .then(() => {
            EventBus.$emit('OPEN_REDEEM_SUCCESS_MODAL', this.ticketQuantity, this.ticketValue);
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('LOAD_USER_PROFILE');
            this.closeModal();
          })
          .catch((e) => {
            this.redeemError = e?.response?.data || 'Redemption error - Please contact support.';
          })
          .then(() => {
            this.isRedeeming = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Modals/modals.scss';
@import '@/style/DesignSystem/inputs.scss';
@import '@/style/DesignSystem/buttons.scss';

.confirmBody {
  text-align: center;
  padding: 60px 40px 30px 40px;
}

.redeemHeader {
  display: flex;

  > div {
    flex: 1;
  }
}

.redeemText {
  padding-top: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;

  h3 {
    margin: 0;
    font-size: 20px;
  }
}

.ownersbucksIcon {
  height: 20px;
}

.ticketInputContainer {
  padding: 15px;
  background: var(--obcolor-background-6);
  border: 2px solid var(--obcolor-input-border);
  margin-bottom: 15px;

  .ticketInputList {
    display: flex;
    gap: 10px;

    .inputSection {
      flex: 1;
      min-width: 0;

      input {
        width: 100%;
      }
    }

    .inputTitle {
      margin-bottom: 10px;
      text-align: left;
    }
    .quantitySection {
      max-width: 120px;
    }

    .ownersbucksIcon {
      height: 16px;
    }
  }
}

.modalButtonContainer {
  display: flex;
  gap: 10px;
  margin-top: 15px;

  button {
    border-radius: 5px !important;
  }
}

.blueText {
  color: var(--obcolor-ob-blue);
  display: flex;
  align-items: center;
}

.btnLoadingIcon {
  margin-left: -20px;
  margin-right: -10px;
}
</style>