<template>
  <div class="marketTile flexColumn" :class="{selected: marketSelected, disabled: disabled}" data-testid="player-market-tile">
    <DiscountTag v-if="market.discount" class="discountTag" :market="market" />
    <LobbyContestCountdown v-if="market.isDiscounted && isActive" class="discountTimer" :end="market.discount.expiry"
      data-testid="discount-countdown"
    />
    <div @click.stop="openPlayerCard(market.playerId.toString())" class="clickable headshotContainer">
      <PlayerHeadshot class="headshot" :id="market.playerId.toString()"
        size="60" :sport="market.sport" :team="market.player.teamAlias"
        :showLogo="true"
      />
      <PlayerStartingStatus class="startingStatus" :sport="market.sport" :player="market.player" />
    </div>
    <div class="playerGameInfo">
      <div class="nameContainer" @click.stop="openPlayerCard(market.playerId.toString())">
        <PlayerStatus height="12" :status="getPlayerStatus()" />
        <div class="playerName" data-testid="player-name">
          {{ market.player.firstName + ' ' + market.player.lastName }}
        </div>
      </div>
      <div class="subHeader uppercase">
        <span data-testid="player-position">{{ market.player.position }}</span>
        -
        <span data-testid="player-team">
          {{ $TeamInfo.getDisplayAlias(market.sport, market.player.teamAlias) }}
        </span>
        <span data-testid="player-opposing-team">{{ playerGameText }}</span>
      </div>
      <div class="subHeader" :class="{changeFlash: gameTimeChange}">
        <div v-if="market.game.date && isGameStarted">
          Started
        </div>
        <div v-else-if="market.game.date">
          <LobbyContestCountdown :end="market.game.date" :tournament="!isOneHourAway" />
        </div>
      </div>
    </div>
    <div class="playerLineInfo">
      <div class="line">
        <template v-if="isActive">
          <MarketLine :market="market" highlight
            :flashOriginal="lineChange" :flashDiscount="discountChange"
          />
        </template>
        <template v-else>
          Unavailable
        </template>
      </div>
      <div class="playerMarketText">{{ market.marketType.name }}</div>
    </div>
    <div class="moreLess" :class="{clickable: !disabled}">
      <button v-if="lessAvailable" class="less" @click="selectMarket(market, 'UNDER')"
        :disabled="disabled"
        :class="{selected: lessSelected, unSelected: moreSelected}"
      >
        <div class="noPointer">
          Less
        </div>
      </button>
      <button v-if="moreAvailable" class="more" @click="selectMarket(market, 'OVER')"
        :disabled="disabled"
        :class="{selected: moreSelected, unSelected: lessSelected }"
      >
        <div class="noPointer">
          More
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerStartingStatus from '@/components/PlayerPicks/PlayerStartingStatus.vue';
import EventBus from '@/event-bus';
import LobbyContestCountdown from '@/views/Lobby/LobbyContestCountdown.vue';
import DiscountTag from './DiscountTag.vue';
import MarketLine from '@/components/PlayerPicks/MarketLine.vue';
import {marketChangeEvent} from '@/mixins/MarketsMixin';
import TimeMixin from '@/mixins/TimeMixin';
import {isMarketActive} from '@/utils/PlayerPicksUtils';
import {mapGetters} from 'vuex';

/**
 * @typedef {import('@/api/ObPropsApi').Market} Market
 */

export default {
  mixins: [TimeMixin],

  props: {
    market: {type: Object, default: null},
  },

  data() {
    return {
      lineChange: false,
      gameTimeChange: false,
      discountChange: false,
    };
  },

  created() {
    EventBus.$on(marketChangeEvent(this.market), this.onMarketChange);
  },

  destroyed() {
    EventBus.$off(marketChangeEvent(this.market), this.onMarketChange);
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
    PlayerStartingStatus,
    LobbyContestCountdown,
    DiscountTag,
    MarketLine,
  },

  watch: {
    market(to, from) {
      if (marketChangeEvent(to) === marketChangeEvent(from)) {
        return;
      }

      EventBus.$off(marketChangeEvent(this.market), this.onMarketChange);
      EventBus.$on(marketChangeEvent(this.market), this.onMarketChange);
    },
  },

  computed: {
    ...mapGetters('playerPicks', [
      'selectedMarkets',
      'overallMaxPicks',
    ]),

    playerGameText() {
      return this.$SportInfo.playerGameText(this.market, this.gameFinished, this.market.sport, false);
    },

    selectedMarketIndex() {
      return this.selectedMarkets.findIndex((market) => market.id === this.market.id && market.discount?.id === this.market.discount?.id);
    },

    moreSelected() {
      return this.marketSelected && this.selectedMarkets[this.selectedMarketIndex].action == 'OVER';
    },

    lessSelected() {
      return this.marketSelected && this.selectedMarkets[this.selectedMarketIndex].action == 'UNDER';
    },

    marketSelected() {
      return this.selectedMarketIndex !== -1;
    },

    disabled() {
      return (!this.marketSelected && this.overallMaxPicks <= this.selectedMarkets.length) || !this.isActive;
    },

    isActive() {
      return isMarketActive(this.market, this.now);
    },

    moreAvailable() {
      return this.market.pickOptions != 'LESS_ONLY';
    },

    lessAvailable() {
      return this.market.pickOptions != 'MORE_ONLY';
    },

    isOneHourAway() {
      // check if game is within 1 hour in seconds
      return this.$moment(this.market.game.date).diff(this.$moment(this.now), 'seconds') <= 3600;
    },

    isGameStarted() {
      return this.$moment(this.market.game.date).diff(this.$moment(this.now), 'seconds') <= 0;
    },
  },

  methods: {
    selectMarket(market, action) {
      if (this.disabled) {
        return;
      }
      EventBus.$emit('PROPS_SELECT_MARKET', market, action);
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.market.sport);
    },

    getPlayerStatus() {
      return this.market.player.status;
    },

    /**
     * @param {Market} to
     * @param {Market} from
     */
    onMarketChange(to, from) {
      if (to.line.balancedLine !== from.line.balancedLine) {
        this.lineChange = true;
      }
      if (to.game.date !== from.game.date) {
        this.gameTimeChange = true;
      }
      if (to.isDiscounted && to.discount?.discountLine !== from.discount?.discountLine) {
        this.discountChange = true;
      }
      setTimeout(() => {
        this.lineChange = false;
        this.gameTimeChange = false;
        this.discountChange = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.headshotContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .headshot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  .startingStatus {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
  }
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0 4px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
}

.playerName {
  font-weight: 700;
  font-size: 14px;
  margin-left: 4px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subHeader {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--obcolor-font-secondary);
  transition: color 300ms;
}

.playerLineInfo {
  margin-top: 10px;
}

.line {
  font-weight: 700;
  font-size: 16px;
  transition: color 300ms;
}

.playerMarketText {
  font-size: 14px;
  margin: 4px 0;
  color: var(--obcolor-font-secondary);
  padding-bottom: 2.5px;
}

.moreLess {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: calc(100% - 32px);
  align-items: center;
  align-self: center;
  font-weight: 700;
  height: 26px;
  margin-top: 8px;
  margin-bottom: 4px;

  button {
    border: none;
  }

  > :first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  > :last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .less,
  .more {
    background-color: var(--obcolor-background-2);
    display: flex;
    flex: 1;
    justify-content: center;
    font-weight: 700;
    margin-right: 0.5px;
    height: 32px;
    align-items: center;
    border-left: 0.5px solid  var(--obcolor-background-6);
    color: unset;

    &.selected {
      background-color: var(--obcolor-ob-blue);
      color: var(--obcolor-background-6);
    }

    &.unSelected {
      color: var(--obcolor-font-secondary);
    }

    &:hover {
      background-color: var(--obcolor-ob-blue);
      color: var(--obcolor-background-6);
    }

    &:disabled {
      color: unset;
    }
  }
}

.marketTile.disabled .more:hover {
  background-color: var(--obcolor-background-2);
  color: var(--obcolor-font-primary);
}

.marketTile.disabled .less:hover {
  background-color: var(--obcolor-background-2);
  color: var(--obcolor-font-primary);
}

.marketTile {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--obcolor-timeline);
  background-color: var(--obcolor-background-6);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 8px;
  overflow: hidden;

  &.selected {
    border: 1px solid var(--obcolor-ob-blue);
  }
}

.discountTag {
  position: absolute;
  top: 9px;
  left: 12px;
}
.discountTimer {
  top: 0;
  right: 0;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--obcolor-background-2);
  padding: 6px;
  border-bottom-left-radius: 4px;
  min-width: 70px;
  align-self: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.uppercase {
  text-transform: uppercase;
}

.clickable {
  cursor: pointer;
}

.noPointer {
  pointer-events: none;
  user-select: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.changeFlash {
  color: var(--obcolor-flash-orange) !important;
}
</style>