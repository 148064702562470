<template>
  <div>
    <div class="container">
      <div class="depositTitle">We Need Your Info</div>
      <div class="yellowBox">
        <i class="far fa-info-circle"></i>
        <div>
          All withdrawals from your OwnersBox Account must be to a source on which
          <span>you are the named account holder.</span>
        </div>
      </div>
      <label>
        <div class="inputText">First Name</div>
        <input v-model="firstName" placeholder="Enter First Name" type="text">
      </label>
      <label>
        <div class="inputText">Last Name</div>
        <input v-model="lastName" placeholder="Enter First Name" type="text">
      </label>

      <template v-if="showFullAddress">
        <div class="inputText">Address</div>
        <GoogleAutocompleteStreetAddress id="map"
          v-model="streetAddress"
          maxlength="500" placeholder="Street number and name"
          type="text" @placechanged="updateAddressData" :country="['us']"
          :enable-geolocation="true"
        />
        <div class="addressContainer">
          <div style="position: relative;">
            <div class="inputText">Country</div>
            <select v-model="country" :style="country === '-1' ? 'color: var(--obcolor-font-secondary);' : ''">
              <option value="-1" disabled selected>Select Country</option>
              <option value="US">United States</option>
            </select>
            <i class="far fa-chevron-down selectArrow"></i>
          </div>

          <div style="position: relative;">
            <div class="inputText">{{ stateProvinceText }}</div>
            <select v-model="region" :style="region === '-1' ? 'color: var(--obcolor-font-secondary);' : ''">
              <option value="-1" disabled>Select {{ stateProvinceText }}</option>
              <option v-for="(entry, index) in regionList" :value="entry.abbreviation" :key="index">{{ entry.name }}</option>
            </select>
            <i class="far fa-chevron-down selectArrow"></i>
          </div>
        </div>
        <label>
          <div class="inputText">City</div>
          <input v-model="city" placeholder="Enter City" type="text">
        </label>
      </template>
      <label>
        <div class="inputText">{{ zipPostalText }} Code</div>
        <input v-model="postalCode" maxlength="10" :placeholder="'Enter ' + zipPostalText + ' Code'">
      </label>
      <label>
        <div class="inputText">Card Number (Visa / Mastercard Debit)</div>
        <div class="cardNumberContainer">
          <div class="cardBrandContainer">
            <i class="cardBrand" :class="cardBrandClass"></i>
          </div>
          <input v-model="cardNumber" class="cardNumberInput" placeholder="XXXX XXXX XXXX XXXX"
            type="text"
            v-cleave="{
              creditCard: true,
              onCreditCardTypeChanged: setCardBrand
            }"
          >
        </div>
      </label>
      <div class="addressContainer">
        <label>
          <div class="inputText">Expiry Date</div>
          <input v-model="cardExpiry" placeholder="MM/YY" type="text"
            v-cleave="{date: true, datePattern: ['m', 'y']}"
          >
        </label>
        <label>
          <div class="inputText">CVV</div>
          <input v-model="cardCvc" placeholder="CVV" type="text"
            v-cleave="{blocks: [4]}"
          >
        </label>
      </div>
      <div class="container">
        <!-- Error Messages -->
        <ErrorPanel v-if="submitError" :message="submitError" class="withdrawError" />
        <button v-if="withdrawalSent" class="ob-btn withdrawBtn" disabled>
          Withdrawal request sent
        </button>
        <button v-else-if="waitingForResponse" class="ob-btn withdrawBtn" disabled>
          <ObLoading :size="60" style="margin-left: -30px; margin-right: -5px;" />
          <span>Submitting...</span>
        </button>

        <button v-else-if="withdrawalValue < 10" class="ob-btn-grey withdrawBtn" disabled>
          Minimum Withdrawal is $10
        </button>
        <!-- Button to submit the withdrawal -->
        <button v-else-if="canSubmit" class="ob-btn withdrawBtn" @click="confirmSubmit">
          Continue
        </button>

        <!-- Disabled due to missing fields -->
        <button v-else disabled class="ob-btn withdrawBtn">
          Continue
        </button>
      </div>
    </div>
    <WithdrawConfirmationModal
      @confirm="submitWithdrawalProcess"
    >
      <div slot="text">
        Are you sure that you want to withdraw
        <span class="moneyText">{{ withdrawalValueFormatted }}</span>
        to the {{ cardBrand != 'unknown' ? cardBrand : '' }} card ending in
        {{ cardLast4 }}?
      </div>
    </WithdrawConfirmationModal>
    <WithdrawSuccessModal @cancel="goToHome" />
  </div>
</template>

<script>
import GoogleAutocompleteStreetAddress from '@/components/GoogleAutocompleteStreetAddress';
import ObLoading from '@/components/ObLoading';
import ErrorPanel from '@/components/panels/ErrorPanel';
import ObPaymentApi from '@/api/ObPaymentApi';
import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import WithdrawConfirmationModal from '@/components/modals/Financial/WithdrawConfirmationModal.vue';
import WithdrawSuccessModal from '@/components/modals/Financial/WithdrawSuccessModal.vue';
import {validateZipPostal, formatZipPostal} from '@/utils/shared/FinancialUtils';

export default {
  props: {
    withdrawalValue: {type: Number, default: null},
  },

  components: {
    ErrorPanel,
    ObLoading,
    GoogleAutocompleteStreetAddress,
    WithdrawSuccessModal,
    WithdrawConfirmationModal,
  },

  data() {
    return {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardBrand: null,

      firstName: '',
      lastName: '',
      postalCode: '',
      streetAddress: '',
      city: '',
      country: 'US',
      region: '-1',

      waitingForResponse: false,
      withdrawalSent: false,
      submitError: '',
      sessionId: '',
      redirectTimeout: null,
    };
  },

  computed: {
    ...mapState(['isFantasyProsUser', 'userId', 'recipientId', 'balanceValue']),

    showFullAddress() {
      return !this.recipientId;
    },

    cardLast4() {
      const len = this.cardNumber.length;
      if (len < 4) {
        return '';
      }

      return this.cardNumber.slice(len - 4, len);
    },

    withdrawalValueFormatted() {
      if (!this.withdrawalValue) {
        return '';
      }

      const dollarAmount = this.withdrawalValue.toFixed(2);
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount).removeCentsIfZero();
    },

    canSubmit() {
      const canSubmit = this.cardNumber != '' && this.cardNumber.length > 12 && this.cardExpiry && this.cardExpiry.split('/').length > 0 &&
        this.cardCvc != '' && this.postalCode != '' && this.firstName != '' && this.lastName != '';

      return canSubmit;
    },

    regionList() {
      return this.$GeographicInfo.getGeographicInfo(this.country);
    },

    stateProvinceText() {
      if (this.country === '-1') {
        return 'State/Province';
      } else if (this.country === 'CA') {
        return 'Province';
      }
      return 'State';
    },

    zipPostalText() {
      if (this.country === '-1') {
        return 'Zip/Postal';
      } else if (this.country === 'CA') {
        return 'Postal';
      }
      return 'Zip';
    },

    cardBrandClass() {
      let icon = '';
      let brand = this.cardBrand;
      brand = brand || 'unknown';
      const cardBrandToClass = {
        'visa': 'fab fa-cc-visa',
        'mastercard': 'fab fa-cc-mastercard',
        'amex': 'fab fa-cc-amex',
        'discover': 'fab fa-cc-discover',
        'unknown': 'fal fa-credit-card',
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      } else {
        icon = cardBrandToClass.unknown;
      }

      return icon;
    },
  },

  watch: {
    cardNumber() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardExpiry() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardCvc() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    firstName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    lastName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    postalCode() {
      this.setPageTimeout();
      this.submitError = '';
      this.formatZipPostal();
    },
  },

  methods: {
    formatZipPostal() {
      if (!this.postalCode || !this.country) {
        return;
      }

      this.postalCode = formatZipPostal(this.country, this.postalCode);
    },

    validateZipPostal() {
      if (!this.postalCode) {
        return;
      }
      return validateZipPostal(this.country, this.postalCode);
    },

    updateAddressData(addressData, _placeResultData, _id) {
      // Wait for country to change
      if (addressData.country == 'United States') {
        this.country = 'US';
      } else {
        this.country = 'CA';
      }
      // Wait for country to change
      this.$nextTick().then(() => {
        this.region = addressData.administrative_area_level_1;
        this.city = addressData.city;
        let zipCode = addressData.postal_code || '';
        zipCode = zipCode.replace(' ', '');
        this.postalCode = zipCode;
        this.streetAddress = addressData.street_address;
      });
    },

    setCardBrand(type) {
      this.cardBrand = type;
    },

    submitWithdrawalProcess() {
      this.waitingForResponse = true;
      if (!this.recipientId) {
        this.createFiservRecipient();
      } else {
        this.getFiservSessionAndSubmit();
      }
    },

    goToHome() {
      this.$router.push('/');
    },

    // -- Fiserv Processing --
    // Fiserv is done in 3 steps; get a session, tokenize the card info, process the payment
    // Sessions are only valid for 10 minutes, initial design we checked the expiry before processing
    // Now will create the session when user hits the Submit button, so will do all 3 steps upon Submit button click
    // Tokenize card has no return data, if it is successful the backend will have required info from the session id

    createFiservRecipient() {
      // Create a Fiserv User Profile that the withdrawal can be made against
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        address1: this.streetAddress,
        city: this.city,
        region: this.region,
        country: this.country,
        zipPostalCode: this.postalCode,
        userId: this.userId,
      };

      ObPaymentApi.createFiservRecipient(params)
          .then((response) => {
            this.recipientId = response.recipientId;
            this.getFiservSessionAndSubmit();
          }).catch((error) => {
            this.submitFailure(error);
          });
    },

    getFiservSessionAndSubmit() {
      // Get a new session
      ObPaymentApi.getFiservSession()
          .then((response) => {
          // Update session variable and then submit the tokenization and payment process
            this.sessionId = response.sessionId;
            this.submitFiservTokenization();
          }).catch((error) => {
            this.submitFailure(error);
          });
    },


    submitFiservTokenization() {
      let expMonth = null;
      let expYear = null;
      const cardNum = this.cardNumber.replace(/\s+/g, '');

      if (this.cardExpiry && this.cardExpiry.split('/').length > 0) {
        expMonth = this.cardExpiry.split('/')[0].trim();
        expYear = this.cardExpiry.split('/')[1].trim();
      } else {
        return;
      }

      const params = {
        Version: '1',
        SessionId: this.sessionId,
        CardNumber: cardNum,
        Expiration: expMonth + expYear,
        CVN: this.cardCvc,
        AVS: {
          Zip: this.postalCode,
          FirstName: this.firstName,
          LastName: this.lastName,
        },
      };

      ObPaymentApi.tokenizeCard(params)
          .then((response) => {
            // Check the response ErrorCode field, a value not "00" is considered an error
            if (response.ErrorCode != '00') {
              this.submitError = 'Tokenize Card Error, please contact support';
              this.waitingForResponse = false;
            } else {
              // Successful tokenization, now call server withdrawal api
              this.submitWithdrawal();
            }
          }).catch((error) => {
            this.submitFailure(error);
          });
    },

    confirmSubmit() {
      if (this.withdrawalSent) {
        return;
      }

      const zipPostalErrors = this.validateZipPostal();
      if (zipPostalErrors) {
        this.submitError = zipPostalErrors;
        return;
      }

      EventBus.$emit('OPEN_WITHDRAWAL_CONFIRM_MODAL');
    },

    submitWithdrawal() {
      const tAmount = parseFloat(this.withdrawalValue.toFixed(2));
      const amountCents = parseFloat((tAmount * 100).toFixed(2));
      const expMonth = this.cardExpiry.split('/')[0].trim();
      const expYear = this.cardExpiry.split('/')[1].trim();
      const params = {
        amount: amountCents,
        sessionId: this.sessionId,
        postalCode: this.postalCode,
        cardExpirationMonth: expMonth,
        cardExpirationYear: expYear,
        source: 'DEBIT',
        firstName: this.firstName,
        lastName: this.lastName,
      };

      ObPaymentApi.submitWithdrawal(params)
          .then((response) => {
            // Successful withdrawal request, refresh balance
            this.waitingForResponse = false;
            this.withdrawalSent = true;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('WITHDRAWAL_SUCCESS_OPEN_MODAL', params, response);
          }).catch((error) => {
            this.submitFailure(error);
          });
    },

    submitFailure(error) {
      if (error && error.response && error.response.data) {
        this.submitError = error.response.data;
      } else {
        this.submitError = 'Error, please contact support';
      }
      this.waitingForResponse = false;
    },

    setPageTimeout() {
      const self = this;
      clearTimeout(this.redirectTimeout);
      // Redirect in 30 minutes
      this.redirectTimeout = setTimeout(function() {
        self.goToHome();
      }, 1800000);
    },

    getSuccessMessage() {
      return 'Your withdrawal request was successfully sent.';
    },

    logNameMismatch() {
      const errorMessage = 'Deposit Warning: User deposited using first name: ' + this.firstName + ' and last name: ' + this.lastName;
      ObAccountApi.createUserErrorLog(errorMessage)
          .catch(() => {
            // No action
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
.yellowBox {
  opacity: 1;
}

.cardNumberContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .cardNumberInput {
    padding-left: 55px !important;
  }
}

.cardBrandContainer {
  position: absolute;
  height:48px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBrand {
  font-size: 25px;
}

.withdrawError {
  margin-top: 10px;
  margin-bottom: -20px;
}

.moneyText {
  font-weight: bold;
}
</style>