<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 600px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0 10px; background: var(--obcolor-background-3); overflow: unset !important;"
    :disableClickaway="true"
  >
    <div slot="header" class="modalHeader">
      <div>Convert OwnersBucks</div>
    </div>

    <div slot="body" class="modalBody">
      <!-- User Input -->
      <div style="font-size: 14px;">
        <div>
          When you enter a real money contest, a percentage
          of the entry fee from your existing OwnersBucks balance will become convertible into real money. Find out more about the conversion rates
          <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com/hc/en-us/articles/25255328038925-How-do-OwnersBucks-work">here</a>.
        </div>
        <div class="section" style="display: flex;">
          <div class="conversionInfo">
            <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBox Logo">
            <span style="font-weight: bold;">{{ profile.ownersbucks || "0.00" }}</span>
            <div class="fadedSubtitle">OwnersBucks</div>
          </div>
          <div class="conversionInfo">
            <span style="font-weight: bold;">${{ profile.convertible || "0.00" }}</span>
            <div class="fadedSubtitle">Convertible</div>
          </div>
        </div>
        <div class="section">
          <div class="sectionHeader">Enter how much you would like to convert</div>
          <input placeholder="Enter amount..." v-model="convertAmount" v-cleave="{
            numeral: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'none',
            numeralDecimalScale: 2,
          }"
          >
        </div>
        <div class="section">
          <div class="sectionHeader">Conversion Summary</div>
          <div>
            You are converting <span style="font-weight: bold">${{ convertAmount != null && convertAmount !== '.' ? Number(convertAmount).toFixed(2) : "0.00" }}</span> out of your total
            <span style="font-weight: bold">${{ profile.convertible || "0.00" }}</span> convertible OwnersBucks into your real money balance.
          </div>
        </div>
        <div class="section" style="display: flex;">
          <div class="conversionInfo" style="margin-right: 40px;">
            <div style="font-weight: bold;">Previous Balance</div>
            <div class="moneyBox" style="margin-top: 5px; margin-bottom: 5px;">
              <span style="font-weight: bold;">{{ profile.balance || "0.00" }}</span>
            </div>
            <div class="moneyBox">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
              <span style="font-weight: bold;">{{ profile.ownersbucks || "0.00" }}</span>
            </div>
          </div>
          <div class="conversionInfo">
            <div style="font-weight: bold;">Updated Balance</div>
            <div class="moneyBox" style="margin-top: 5px; margin-bottom: 5px;">
              <span style="font-weight: bold;">{{ formattedUpdatedBalance }}</span>
            </div>
            <div class="moneyBox">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
              <span style="font-weight: bold;">{{ updatedOwnersbucks }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="modalFooter">
      <template>
        <button class="ob-btn-grey" style="margin-right: 10px; width: 120px;" @click="closeModal()">Cancel</button>
        <button v-if="!processing && convertAmount > 0" class="ob-btn" style="width: 120px;"
          @click="convert()"
          v-tooltip.top.notrigger="{ content: result, class: 'ObTooltip ob-tooltip-fadeout', visible: result != null }"
        >
          Convert
        </button>
        <button v-else-if="!processing" class="ob-btn-grey" disabled
          style="width: 120px;"
          v-tooltip.top.notrigger="{ content: result, class: 'ObTooltip ob-tooltip-fadeout', visible: result != null }"
        >
          Convert
        </button>
        <button v-else class="ob-btn-grey" style="width: 120px;">
          <i class="fad fa-spinner-third fa-spin" style="margin-right: 1px;"></i>
          Converting...
        </button>
      </template>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      profile: null,
      convertAmount: 0,
      processing: false,
      transactionComplete: true,
      result: null,

      resultTimeout: null,
    };
  },

  created() {
    EventBus.$on('CONVERT_CURRENCY_OPEN', this.openModal);
  },

  destroyed() {
    EventBus.$off('CONVERT_CURRENCY_OPEN', this.openModal);
  },

  computed: {
    ...mapState(['balanceValue', 'ownersbucksValue']),

    updatedOwnersbucks() {
      if (this.ownersbucksValue == null || this.convertAmount == null || this.convertAmount === '.') {
        return this.profile.ownersbucks;
      }
      const newBalance = ((this.ownersbucksValue / 100) - Number(this.convertAmount)).toFixed(2);
      const formatted = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(newBalance);
      return formatted.replace('$', '');
    },

    formattedUpdatedBalance() {
      if (this.balanceValue == null || this.convertAmount == null || this.convertAmount === '.') {
        return this.profile.balance;
      }

      const newBalance = ((this.balanceValue / 100) + Number(this.convertAmount)).toFixed(2);

      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(newBalance);
    },

    conversionRateFormatted() {
      return (Number(this.profile.conversionRate) * 100).toFixed(2) + '%';
    },
  },

  watch: {
    convertAmount(newVal) {
      newVal = Number(newVal);
      // Don't let user convert more than convertible amount
      if (newVal > Number(this.profile.convertible)) {
        this.convertAmount = Number(this.profile.convertible);
      }
    },

    result(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.resultTimeout);
        this.resultTimeout = setTimeout(function() {
          self.resetResult();
        }, 3000);
      }
    },
  },

  methods: {
    openModal(profile) {
      this.profile = profile;
      this.convertAmount = 0;
      this.processing = false;
      this.transactionComplete = false;
      this.result = null;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    convert() {
      this.processing = true;
      const convertString = String(this.convertAmount);
      ObAccountApi.convertOwnersbucks(convertString)
          .then((data) => {
            this.processing = false;
            this.transactionComplete = true;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('LOAD_USER_PROFILE');

            if (data.balance && data.ownersbucks && data.convertible) {
              this.profile.balance = data.balance;
              this.profile.ownersbucks = data.ownersbucks;
              this.profile.convertible = data.convertible;
            }

            this.result = 'Conversion complete';
            this.convertAmount = '0.00';
          })
          .catch((error) => {
            this.processing = false;
            if (error.response && error.response.data) {
              this.result = error.response.data;
            } else {
              this.result = 'Internal Server Error';
            }
          });
    },

    resetResult() {
      this.result = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}

.modalBody {
  flex: 1;
  padding: 10px 10px 0 10px;;
  box-sizing: border-box;
}

.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.conversionInfo {
  text-align: center;
  margin-right: 25px;
}

.fadedSubtitle {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.section {
  margin-top: 15px;
}

.sectionHeader {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.moneyBox {
  border-radius: 5px;
  background: var(--obcolor-background-2);
  padding: 5px 10px;
}

.ownersbucksIcon {
  height: 16px;
  margin-bottom: -3px;
  margin-right: 7px;
}
</style>