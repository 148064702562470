<template>
  <table aria-label="Team Information">
    <tr>
      <th colspan="3" class="teamInfoHeader">
        Team Information
      </th>
    </tr>
    <tr v-for="(matchup, index) in league.bracket[week].matchups" :key="index" @click="selectMatchup(matchup)">
      <td>
        <div class="teamSummary">
          <div class="scoreBox" :class="{winningColour: matchup.team1Score > matchup.team2Score}">
            {{ matchup.team1Score || 0 }}
          </div>
          <UserLogo class="teamLogo" :size="35" :sport="league.sport"
            :id="matchup.team1Id"
          />
          <div class="teamNameContainer">
            <div class="teamName">{{ matchup.team1Team }}</div>
            <div class="teamUserName">{{ matchup.team1Name }}</div>
          </div>
        </div>
        <div class="teamSummary">
          <div class="scoreBox" :class="{winningColour: matchup.team1Score < matchup.team2Score}">
            {{ matchup.team2Score || 0 }}
          </div>
          <UserLogo class="teamLogo" :size="35" :sport="league.sport"
            :id="matchup.team2Id"
          />
          <div class="teamNameContainer">
            <div class="teamName">{{ matchup.team2Team }}</div>
            <div class="teamUserName">{{ matchup.team2Name }}</div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import UserLogo from '@/components/UserLogo';

export default {
  components: {
    UserLogo,
  },
  props: {
    league: Object,
    week: Number,
  },
  methods: {
    selectMatchup(matchup) {
      if (matchup.team1Id && matchup.team2Id) {
        this.$router.push('/contest/matchup/summary/' + this.league.id + '/' + matchup.id);
        this.$emit('selectMatchup');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leagueName {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}
td, th {
  border-bottom: 1px solid var(--obcolor-background-page);
}
td {
  border-right: 1px solid var(--obcolor-background-page);
  cursor: pointer;
}
td:hover {
  background: var(--obcolor-background-2);
}
.teamInfoHeader {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 10px;
}
.teamSummary {
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 14px;
}
.teamLogo {
  margin-left: 10px;
}
.scoreBox {
  padding: 5px 0;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  width: 60px;
  box-sizing: border-box;
  text-align: center;
  background: var(--obcolor-background-page);
  color: var(--obcolor-font-secondary);
}
.winningColour {
  color: var(--obcolor-accent);
}
.teamNameContainer {
  margin-left: 10px;
}
.teamName {
  font-weight: bold;
}
.teamUserName {
  color: var(--obcolor-font-secondary);
}
</style>