/**
 * Opens the app using a deep link generated from route parameters and queries.
 *
 * @param {Object} route - The route object containing parameters and query information.
 * @param {Object} route.params - The route parameters.
 * @param {Object} route.query - The route query parameters.
 */
function openApp(route) {
  const DEEP_LINK_URI = 'ownersboxfsp://';

  const routeParams = route.params;
  const routeQuery = route.query;
  let paramString = '';
  let firstParam = true;

  const allParams = {
    ...routeParams,
    ...routeQuery,
  };

  for (const paramName in allParams) {
    if (allParams[paramName] != null && paramName != 'view') {
      paramString = paramString + (firstParam ? '?' : '&') + paramName + '=' + allParams[paramName];
      firstParam = false;
    }
  }
  const urlString = encodeURI(DEEP_LINK_URI + route.name + paramString);
  window.location = urlString;
};

export default openApp;

