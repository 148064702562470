import Api from '@/api/ObBaseApi';
import {downloadFile} from '@/utils/FileUtils';

export default {
  getTransactions(year, month) {
    return Api()
        .get('/fsp/account/getMyTransactions?year=' + year + '&month=' + month)
        .then((response) => {
          return response.data;
        });
  },

  getMyBonusTransactions(year, month) {
    return Api()
        .get('/fsp/account/getMyBonusTransactions?year=' + year + '&month=' + month)
        .then((response) => {
          return response.data;
        });
  },

  getWithdrawInformation() {
    return Api()
        .get('/fsp/account/getWithdrawInformation')
        .then((response) => {
          return response.data;
        });
  },

  getMyTransactionsCSV(month, year, realMoney) {
    const d = new Date();
    const diff = d.getTimezoneOffset();
    return Api()
        .get('/fsp/account/getMyTransactionsCSV', {
          params: {
            month: month,
            year: year,
            offset: diff,
            realMoney: realMoney,
          },
        })
        .then((response) => {
          const fileName = 'ownersbox-' + (realMoney ? '' : 'ownersbucks-') + 'transactions-';
          downloadFile(response.data, fileName + month + '-' + year + '.csv');
        });
  },

  redeemTickets(ticketValsCents) {
    const params = {
      ticketValuesCents: ticketValsCents,
    };
    return Api()
        .post('/fsp/account/redeemOwnersBucksForTickets', params)
        .then((response) => {
          return response.data;
        });
  },
};
