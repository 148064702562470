import Api from '@/api/ObBaseApi';

export default {
  getTeams(sport) {
    return Api()
        .get('/pps-web/' + sport + '/getTeams')
        .then((response) => {
          return response.data;
        });
  },

  getTodaysGames() {
    return Api()
        .get('/fsp/sportdata/getGames')
        .then((response) => {
          return response.data;
        });
  },

  getTodaysBoxscores(sport) {
    return Api()
        .get('/pps-web/' + sport + '/getBoxscores')
        .then((response) => {
          return response.data;
        });
  },

  getPlayerBoxscore(sport, playerId, gameId) {
    let params = '?sport=' + sport;
    params += '&playerId=' + playerId;
    params += '&gameId=' + gameId;
    return Api()
        .get('/fsp/sportdata/getPlayerBoxscore' + params)
        .then((response) => {
          return response.data;
        });
  },

  getScoreboardCalendarInfo(sport) {
    return Api()
        .get('/pps-web/' + sport + '/getCalendarInfo')
        .then((response) => {
          return response.data;
        });
  },

  getScoreboardDayGames(sport, date) {
    return Api()
        .get('/pps-web/' + sport + '/matchups/' + date)
        .then((response) => {
          return response.data;
        });
  },

  getGameDetails(gameId) {
    return Api()
        .get('/fsp/sportdata/getGameDetails?id=' + gameId)
        .then((response) => {
          return response.data;
        });
  },

  getFullGameDetails(sport, gameId) {
    return Api()
        .get('/pps-web/' + sport + '/gamedetails/' + gameId)
        .then((response) => {
          return response.data;
        });
  },
};
