import { render, staticRenderFns } from "./PropsSubmitModal.vue?vue&type=template&id=f46ec422&scoped=true"
import script from "./PropsSubmitModal.vue?vue&type=script&lang=js"
export * from "./PropsSubmitModal.vue?vue&type=script&lang=js"
import style0 from "./PropsSubmitModal.vue?vue&type=style&index=0&id=f46ec422&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46ec422",
  null
  
)

export default component.exports