<template>
  <div>
    <div v-if="!contestList || contestList.length == 0" class="noContests">
      There are no contests available to enter
    </div>
    <QuickEntryContestBox v-else v-for="(contest, index) in contestList" :key="index"
      :contest="contest" :entries="entriesByContest[contest.id]"
    />
  </div>
</template>

<script>
import QuickEntryContestBox from './QuickEntryContestBox';
export default {
  props: {
    contestList: {type: Array, default: null},
    entriesByContest: {type: Object, default: null},
  },

  components: {
    QuickEntryContestBox,
  },
};
</script>

<style lang="scss" scoped>
.noContests {
  padding: 10px;
  color: var(--obcolor-font-secondary);
  text-align: center;
}
</style>