<template>
  <div v-if="!swapData" class="loadingBody">
    <ObLoading />
    <div>{{ loadMessage }}</div>
    <div>Please Wait... {{ loadProgress }}%</div>
    <div class="progressBar">
      <div class="progress" :style="'width: ' + loadProgress + '%'"></div>
    </div>
  </div>
  <div v-else class="contestsBody">
    <table aria-label="Contests to Upload">
      <!-- Header row -->
      <tr class="headerRow" :class="{errorRow: allSwapsError}">
        <th>
          <div class="uploadedTextContainer">
            <div class="backBtn" @click="prevStep">
              <i class="fas fa-chevron-left"></i>
            </div>
            <div v-if="!swapsSubmitted" class="mainHeader">{{ totalLineups }} Lineups Uploaded Successfully</div>
            <div v-else class="mainHeader">
              <div class="greenText">{{ numValidLineups }} Swaps Succeeded</div>
              <div v-if="numInvalidLineups > 0" class="redText" style="margin-left: 20px;">{{ numInvalidLineups }} Swaps Failed</div>
            </div>
          </div>
        </th>
        <th></th>
        <th class="subheading statField">
          <div v-if="!swapsSubmitted">Select All Lineups</div>
        </th>
        <th class="checkboxField">
          <div v-if="!swapsSubmitted">
            <div class="lineupSelector" :class="{selected: allSwapsSelected}" @click="toggleAllLineups">
              <i class="fas fa-check selectedCheck"></i>
              <i class="fas fa-times errorCheckbox" v-if="allSwapsError"></i>
            </div>
          </div>
        </th>
        <th></th>
      </tr>

      <!-- Rows grouped by contest -->
      <template v-for="contestSwapInfo of contestsWithSwaps">
        <!-- Row contest header -->
        <tr :key="contestSwapInfo.contest.id" class="contestRow" :class="{errorRow: allContestSwapsError(contestSwapInfo.contest.id)}"
          @click="toggleContestDropdown(contestSwapInfo.contest.id)"
        >
          <!-- Sport icon, contest name -->
          <td class="contestInfoField">
            <div class="contestHeadingContainer">
              <div class="sportBadgeContainer">
                <img class="sportBadge" :src="require('@/assets/contestbadge/' + contestSwapInfo.contest.sport + '.png')"
                  :alt="contestSwapInfo.contest.sport"
                >
              </div>
              <div>
                <div class="heading">{{ contestSwapInfo.contest.contestName }}</div>
                <div class="subheading">{{ displayableDate(contestSwapInfo.contest.contestStart) }}</div>
              </div>
            </div>
          </td>

          <!-- Num selected swaps -->
          <!-- If swaps have been submitted, instead show how many succeeded -->
          <td class="statField">
            <template v-if="!swapsSubmitted">
              <div :class="selectedSwapsByContest[contestSwapInfo.contest.id] == contestSwapInfo.swaps.length ? '' : 'greyText'">
                {{ selectedSwapsByContest[contestSwapInfo.contest.id] }} / {{ contestSwapInfo.swaps.length }}
              </div>
              <div class="subheading">Selected</div>
            </template>
            <template v-else>
              <div>{{ numValidLineupsByContest[contestSwapInfo.contest.id] }} / {{ contestSwapInfo.swaps.length }}</div>
              <div class="subheading">Succeeded</div>
            </template>
          </td>

          <!-- Prize pool of the contest -->
          <td class="statField">
            <div class="prizePoolSection" :class="contestSwapInfo.contest.realMoney ? 'greenText' : 'blueText'">
              <img v-if="contestSwapInfo.contest.isGuaranteed" class="guaranteedIcon"
                :src="require('@/assets/contesticons/Guaranteed' + (contestSwapInfo.contest.realMoney ? '' : 'Blue') + '.svg')"
                alt="Guaranteed"
              >
              <div>{{ contestSwapInfo.contest.prizePool.removeCentsIfZero() }}</div>
            </div>
            <div class="subheading">Prize Pool</div>
          </td>

          <!-- Checkbox to select swaps in contest -->
          <!-- If swaps have been submitted, replace checkboxes with success/fail indicators -->
          <td class="checkboxField">
            <div v-if="!swapsSubmitted">
              <div class="lineupSelector" :class="{selected: allContestSwapsSelected(contestSwapInfo.contest.id)}"
                @click.stop="toggleAllSwapsByContest(contestSwapInfo)"
              >
                <i class="fas fa-check selectedCheck"></i>
                <i class="fas fa-times errorCheckbox" v-if="allContestSwapsError(contestSwapInfo.contest.id)"></i>
              </div>
            </div>
            <template v-else>
              <i v-if="numValidLineupsByContest[contestSwapInfo.contest.id] == contestSwapInfo.swaps.length" class="fas fa-check-circle fa-lg submitSuccessIcon"></i>
              <i v-else class="fas fa-exclamation-circle fa-lg submitFailIcon"></i>
            </template>
          </td>

          <!-- Dropdown to show swaps in contest -->
          <td class="dropdownField">
            <div>
              <i class="fas dropdownIcon" :class="'fa-chevron-' + (contestOpenDropdowns[contestSwapInfo.contest.id] ? 'up' : 'down')"></i>
            </div>
          </td>
        </tr>

        <!-- Row for each swap -->
        <template v-if="contestOpenDropdowns[contestSwapInfo.contest.id]">
          <tr class="swapRow" v-for="(swap, index) in contestSwapInfo.swaps" :key="contestSwapInfo.contest.id + '_' + swap.teamId"
            :class="{errorRow: !swap.success}"
          >
            <!-- Sport icon, list of players in contest, error message -->
            <td class="contestInfoField">
              <div class="contestHeadingContainer">
                <div class="sportBadgeContainer">
                  <img class="sportBadge" :src="require('@/assets/icons/sporticon_' + contestSwapInfo.contest.sport + '.png')"
                    :alt="contestSwapInfo.contest.sport"
                  >
                </div>
                <div>
                  <!-- List of players -->
                  <div class="playerNameList">
                    <div v-for="(player, pIndex) in swap.players" :key="swap.contestId + '_' + swap.teamId + '_' +player.id + '_' + pIndex"
                      :class="{redText: !player.isValid}" @click="openPlayerCard(player)"
                    >
                      {{ player.name.shortName() }}
                    </div>
                  </div>
                  <!-- Error message -->
                  <div v-if="!swap.success && swap.error != ''" class="swapError">
                    <i class="fas fa-exclamation-circle fa-lg errorIcon"></i>{{ swap.error }}
                  </div>
                </div>
              </div>
            </td>

            <!-- Total team salary -->
            <td class="statField">
              <div class="statText">
                <span v-if="swap.salaryRem !== null && swap.salaryRem !== undefined" :class="swap.salaryRem >= 0 ? 'greenText' : 'redText'">
                  {{ swap.salaryRem.formatMoney() }}
                </span>
                <span v-else>N/A</span>
              </div>
              <div class="subheading">Rem Salary</div>
            </td>

            <!-- Team projection -->
            <td class="statField">
              <div class="statText">
                <span v-if="swap.projection !== null && swap.projection !== undefined" class="blueText">{{ swap.projection }}</span>
                <span v-else>N/A</span>
              </div>
              <div class="subheading">Proj</div>
            </td>

            <!-- Checkbox showing if included in submit -->
            <!-- If swaps have been submitted, replace checkboxes with success/fail indicators -->
            <td class="checkboxField">
              <div v-if="!swapsSubmitted">
                <div class="lineupSelector" :class="{selected: selectedLineupsByContest[contestSwapInfo.contest.id][index]}"
                  @click.stop="toggleSwap(contestSwapInfo.contest.id, index)"
                >
                  <i class="fas fa-check selectedCheck"></i>
                  <!-- X icon shows if this swap will not be used -->
                  <i class="fas fa-times errorCheckbox" v-if="!swap.success"></i>
                </div>
              </div>
              <template v-else>
                <i v-if="swap.success" class="fas fa-check-circle fa-lg submitSuccessIcon"></i>
                <i v-else class="fas fa-exclamation-circle fa-lg submitFailIcon"></i>
              </template>
            </td>

            <td></td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ObLoading,
  },

  props: {
    swapData: {type: Object, default: null},
    selectedLineupsByContest: {type: Object, default: null},
    loadMessage: {type: String, default: 'Processing'},
    loadProgress: {type: Number, default: 0},
    swapsSubmitted: {type: Boolean, default: false},
  },

  data() {
    return {
      contestOpenDropdowns: {},
    };
  },

  created() {
    this.collapseAllDropdowns();
  },

  computed: {
    sport() {
      if (this.swapData == null || !this.swapData.contests || !this.swapData.contests.length) {
        return;
      }
      return this.swapData.contests[0].contest.sport;
    },

    contestsWithSwaps() {
      return this.swapData == null ? [] : this.swapData.contests;
    },

    totalLineups() {
      return this.swapData == null ? 0 : this.swapData.totalLineups;
    },

    selectedSwapsByContest() {
      const numSelectedObject = {};
      for (const contestSwapInfo of this.contestsWithSwaps) {
        let tot = 0;
        for (const isSelectedLineup of this.selectedLineupsByContest[contestSwapInfo.contest.id]) {
          if (isSelectedLineup) {
            tot++;
          }
        }
        this.$set(numSelectedObject, contestSwapInfo.contest.id, tot);
      }

      return numSelectedObject;
    },

    numValidLineups() {
      let tot = 0;
      for (const contestSwapInfo of this.contestsWithSwaps) {
        tot += this.numValidLineupsByContest[contestSwapInfo.contest.id];
      }
      return tot;
    },

    numInvalidLineups() {
      return this.totalLineups - this.numValidLineups;
    },

    numValidLineupsByContest() {
      const numValidObject = {};
      for (const contestSwapInfo of this.contestsWithSwaps) {
        let tot = 0;
        for (const swap of contestSwapInfo.swaps) {
          if (swap.success) {
            tot++;
          }
        }
        this.$set(numValidObject, contestSwapInfo.contest.id, tot);
      }

      return numValidObject;
    },

    numSelectedSwaps() {
      let tot = 0;
      for (const contestSwapInfo of this.contestsWithSwaps) {
        tot+= this.selectedSwapsByContest[contestSwapInfo.contest.id];
      }
      return tot;
    },

    allSwapsSelected() {
      if (this.numSelectedSwaps == 0) {
        return false;
      }
      return this.numSelectedSwaps >= this.numValidLineups;
    },

    allSwapsError() {
      return this.numValidLineups == 0;
    },
  },

  methods: {
    selectAllSwaps(val = true) {
      this.$emit('selectAllSwaps', val);
    },

    selectAllSwapsByContest(contestSwapInfo, val = true) {
      this.$emit('selectAllSwapsByContest', contestSwapInfo, val);
    },

    toggleSwap(contestId, index) {
      this.$emit('toggleSwap', contestId, index);
    },

    prevStep() {
      EventBus.$emit('GLOBAL_SWAP_PREV_STEP');
    },

    openPlayerCard(player) {
      if (!player.id) {
        return;
      }
      EventBus.$emit('OPEN_PLAYER_CARD', player.id, this.sport);
    },

    collapseAllDropdowns() {
      for (const contestSwapInfo of this.contestsWithSwaps) {
        this.$set(this.contestOpenDropdowns, contestSwapInfo.contest.id, false);
      }
    },

    toggleAllLineups() {
      if (this.allSwapsSelected) {
        this.selectAllSwaps(false);
      } else {
        this.selectAllSwaps();
      }
    },

    toggleAllSwapsByContest(contestSwapInfo) {
      if (this.allContestSwapsSelected(contestSwapInfo.contest.id)) {
        this.selectAllSwapsByContest(contestSwapInfo, false);
      } else {
        this.selectAllSwapsByContest(contestSwapInfo);
      }
    },

    toggleContestDropdown(contestId) {
      const curValue = this.contestOpenDropdowns[contestId];
      this.$set(this.contestOpenDropdowns, contestId, !curValue);
    },

    allContestSwapsSelected(contestId) {
      if (this.selectedSwapsByContest[contestId] == 0) {
        return false;
      }
      return this.selectedSwapsByContest[contestId] === this.numValidLineupsByContest[contestId];
    },

    allContestSwapsError(contestId) {
      return this.numValidLineupsByContest[contestId] == 0;
    },

    displayableDate(timestamp) {
      return this.$moment(timestamp).format('dddd MMMM D, h:mma');
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingBody {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    margin-top: 10px;
  }
}

.contestsBody {
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

table {
  width: 100%;
  min-height: 0;
  font-size: 14px;

  th, td {
    height: 50px;
    padding: 5px 15px;
    box-sizing: border-box;
  }

  td {
    border-top: 1px solid var(--obcolor-background-4);
  }

  th.subheading, th .subheading {
    font-weight: normal;
  }

  td.subheading, td .subheading {
    margin-top: 2px;
  }

  tr.contestRow, tr.swapRow {
    height: 70px;
  }
}

.uploadedTextContainer {
  display: flex;
  align-items: center;
}

.backBtn {
  font-size: 18px;
  width: 20px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--obcolor-font-primary);

  &:hover {
    color: var(--obcolor-font-secondary);
  }
}

.contestRow {
  cursor: pointer;
  &:hover {
    background: var(--obcolor-background-6);
  }
}

.contestRow .sportBadge {
  width: 60px;
  margin-right: 10px;
}

.swapRow .sportBadge {
  width: 25px;
  margin-right: 10px;
}

.contestInfoField {
  .sportBadgeContainer {
    width: 70px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contestHeadingContainer {
    display: flex;
    align-items: center;
    .heading {
      font-weight: bold;
    }
  }

  .playerNameList {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    padding: 5px 0;

    div {
      cursor: pointer;
      white-space: nowrap;
      padding-right: 5px;
      padding-bottom: 2px;

      &:not(:last-child)::after {
        content: ', ';
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .swapError {
    display: flex;
    align-items: center;
    color: var(--obcolor-red);
    padding-bottom: 5px;

    .errorIcon {
      font-size: 12px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }
}

.submitSuccessIcon {
  color: var(--obcolor-green);
  font-size: 16px;
}

.submitFailIcon {
  color: var(--obcolor-red);
  font-size: 16px;
}

.statField {
  width: 160px;
  .prizePoolSection {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .guaranteedIcon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .statText {
    font-weight: bold;
  }
}

.checkboxField {
  width: 10px;
  padding: 5px 10px 5px 5px;
  > div {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.errorRow .checkboxField > div {
  cursor: auto;
}

.lineupSelector {
  border: 2px solid var(--obcolor-font-secondary);
  background: var(--obcolor-background-6);
  height: 18px;
  width: 18px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.selected) > .selectedCheck {
    display: none;
  }

  &.selected {
    background: var(--obcolor-ob-blue);
    border: 2px solid var(--obcolor-ob-blue);
  }
}

.dropdownField {
  width: 40px;

  > div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.greenText {
  color: var(--obcolor-green);
}

.redText {
  color: var(--obcolor-red);
}

.subheading, .greyText {
  color: var(--obcolor-font-secondary);
}

.mainHeader {
  font-size: 16px;
  font-weight: normal;
  margin-top: 2px;
  display: flex;
}

.progressBar {
  height: 10px;
  width: 300px;
  background: var(--obcolor-background-1);
  margin-top: 12px !important;
  border-radius: 10px;
  overflow: hidden;

  .progress {
    height: 100%;
    background: var(--obcolor-ob-blue);
    transition: width 1s;
  }
}
</style>