<template>
  <tr @click.stop="openPlayerModal()">
    <td style="max-width: 240px; min-width: 240px;">
      <MatchupPlayerInfo :league="league" :entry="entry" :isMyTeam="isMyTeam" />
    </td>
    <!-- Total -->
    <template v-if="league.sport != 'NFL'">
      <td v-if="selectedDay === null">{{ sumGamesPlayed(entry) }}</td>
      <td>{{ sumPlayerPoints(entry) }}</td>
      <td v-if="selectedDay === null" style="padding-right: 10px;">
        {{ avgPlayerPoints(entry) }}
      </td>
      <td v-if="selectedDay === null" style="padding-left: 10px;" class="leftBorder">{{ sumGamesRemaining(entry) }}</td>
      <td v-if="selectedDay === null">{{ sumRemainingProj(entry) }}</td>
      <td v-else>{{ getProjForDay(entry).toFixed(0) }}</td>
    </template>
    <template v-else>
      <td style="white-space: break-spaces; max-width: 220px;">{{ statLine }}</td>
      <td style="color: var(--obcolor-font-secondary);">{{ sumPlayerProj(entry) }}</td>
      <td style="padding-right: 10px; color: var(--obcolor-ob-blue); font-weight: bold;">{{ sumPlayerPoints(entry) }}</td>
    </template>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import MatchupPlayerInfo from '@/views/Contest/MatchupPlayerInfo';
import EventBus from '@/event-bus';

export default {
  props: {
    entry: Object,
    league: Object,
    playedGames: Object,
    selectedDay: Number,
    isMyTeam: Boolean,
  },

  components: {
    MatchupPlayerInfo,
  },

  computed: {
    fspGame() {
      const fspGames = this.entry.fspGames;

      for (const gIndex in fspGames) {
        if (fspGames[gIndex].id != null) {
          return fspGames[gIndex];
        }
      }

      return null;
    },

    statLine() {
      if (!this.fspGame || !this.fspGame.stats) {
        return null;
      }

      const scoringStats = this.$ScoringStats.getAllH2hNflStats();
      let stats = '';

      for (const stat of scoringStats) {
        const statString = this.getStatString(stat);
        if (statString != null) {
          stats += stats === '' ? '' : ', ';
          stats += statString;
        }
      }

      return stats;
    },
  },

  methods: {
    openPlayerModal() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.entry.id, this.league.sport, this.league.id);
    },

    getStatString(stat) {
      const statPath = stat.path;
      if (this.getStat(statPath) != null && this.getStat(statPath) != 0) {
        // scoreNum + labelName gives "150 Pass Yds" for example
        const labelScoreNum = this.getStat(statPath);
        let labelName = stat.short;
        if (labelScoreNum == 1) {
          labelName = labelName.removePluralWords();
        } else {
          labelName = stat.short;
        }

        return labelScoreNum + ' ' + labelName;
      }

      return null;
    },

    getStat(statPath) {
      const game = this.fspGame;

      if (!game || !game.stats || !statPath) {
        return null;
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game.stats[playerRole]) {
        return 0;
      }
      const stat = game.stats[playerRole][statType];
      if (stat === null || stat === undefined) {
        return 0;
      }
      return stat;
    },

    getProjForDay(entry) {
      if (entry.fspGames[this.selectedDay - 1]) {
        if (entry.fspGames[this.selectedDay - 1].ProjPoints) {
          return entry.fspGames[this.selectedDay - 1].ProjPoints.total || 0;
        }
      }
      return 0;
    },

    sumGamesPlayed(player) {
      const games = player.fspGames;
      let gp = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        if (this.playedGames[gameKey]) {
          gp += 1;
        }
      }
      return gp;
    },

    sumPlayerPoints(player) {
      const games = player.fspGames;
      let totalPoints = new Decimal(0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        if (this.playedGames[gameKey]) {
          totalPoints = totalPoints.plus(new Decimal(gJSON.ObPoints.total));
        }
      }
      return Number(totalPoints);
    },

    sumPlayerProj(player) {
      const games = player.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        if (gJSON.inRange && gJSON.ProjPoints != null) {
          totalPoints += gJSON.ProjPoints.total;
        }
      }
      return totalPoints;
    },

    avgPlayerPoints(player) {
      const sumPlayed = this.sumGamesPlayed(player);
      if (sumPlayed === 0) {
        return 0;
      }
      return (this.sumPlayerPoints(player)/sumPlayed).toFixed(1);
    },

    sumGamesRemaining(player) {
      const games = player.fspGames;
      let gr = 0;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameDate = new Date(gJSON.date);
        if (gJSON.inRange && gameDate >= today) {
          if (!gJSON.played) {
            gr += 1;
          }
        }
      }
      return gr;
    },

    sumRemainingProj(player) {
      const games = player.fspGames;
      let remainingProj = 0;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameDate = new Date(gJSON.date);
        if (gJSON.inRange && gameDate >= today) {
          if (!gJSON.played && gJSON.ProjPoints != null) {
            remainingProj += gJSON.ProjPoints.total;
          }
        }
      }
      return remainingProj.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
td, th {
  padding: 8px 0;
  white-space: nowrap;
  &:not(:first-child) {
    min-width: 40px;
  }
}
.leftBorder {
  border-left: 1px solid var(--obcolor-background-2);
}
.nonValidGame {
  color: var(--obcolor-text-faded);
}
tr {
  cursor: pointer;
}
tr:hover {
  background: var(--obcolor-background-2) !important;
}
</style>