<template>
  <div class="ob-page">
    <div class="swapBtnContainer">
      <div class="ob-btn-grey swapBtn" @click="openGlobalSwapModal">
        <i class="fas fa-exchange"></i>Global Swap
      </div>
      <div class="ob-btn-grey swapBtn" @click="openQuickSwapModal">
        <i class="fas fa-exchange"></i>Quick Swap
      </div>
      <div class="ob-btn swapBtn" @click="openCreateLineupModal">
        Create Lineup
      </div>
      <div style="flex: 1;"></div>
      <div>
        <span style="margin-right: 15px; font-weight: bold;">Lineup History</span>
        <div :class="showHistory ? 'ob-btn-grey' : 'ob-btn'" style="border-radius: 5px 0 0 5px;" @click="toggleHistory(false)">
          Hide
        </div>
        <div :class="showHistory ? 'ob-btn' : 'ob-btn-grey'" style="border-radius: 0 5px 5px 0;" @click="toggleHistory(true)">
          Show
        </div>
      </div>
    </div>

    <ObLoading v-if="loadingLineups" />
    <template v-else-if="slates && slates.length > 0">
      <SlateContainer v-for="slate, index in slates" :key="'slate-' + index"
        :slate="slate" :teams="teams" :expanded="expandedId == slate.id"
        :lastCheckedTs="lastCheckedTs"
      />
    </template>
    <div class="noLineupsContainer" v-else>
      <div>You do not have any current lineups. Create a lineup or go to the lobby to join a contest.</div>
      <router-link class="ob-btn-grey" to="/lobby">Go to Lobby</router-link>
    </div>

    <div v-if="showHistory" class="completedHeader">Lineup History</div>
    <ObLoading v-if="showHistory && loadingHistory" />
    <template v-else-if="showHistory && completedSlates && completedSlates.length > 0">
      <SlateContainer v-for="slate, index in completedSlates" :key="'completed-slate-' + index"
        :slate="slate" :teams="completedTeams" :expanded="expandedId == slate.id"
        :isCompleted="true" :lastCheckedTs="lastCheckedTs"
      />
    </template>
    <div v-else-if="showHistory" class="noHistoryContainer">
      <span v-if="lineupHistoryError">{{ lineupHistoryError }}</span>
      <span v-else>No recently completed lineups</span>
    </div>

    <CreateLineupModal />
    <LineupQuickEntryModal :showLineupsButton="false" />
    <QuickEntrySuccessModal footerButton="close" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import SlateContainer from './SlateContainer.vue';
import ObLoading from '@/components/ObLoading.vue';
import CreateLineupModal from './CreateLineupModal.vue';
import LineupQuickEntryModal from './LineupQuickEntryModal/LineupQuickEntryModal.vue';
import QuickEntrySuccessModal from '@/views/SalaryCapGame/QuickEntryModal/QuickEntrySuccessModal.vue';

export default {

  components: {
    SlateContainer,
    ObLoading,
    CreateLineupModal,
    LineupQuickEntryModal,
    QuickEntrySuccessModal,
  },

  data() {
    return {
      loadingLineups: true,
      slates: null,
      teams: null,
      contests: null,
      completedSlates: null,
      completedTeams: null,
      expandedId: null,
      lastCheckedTs: null,
      lastCheckedInterval: null,
      showHistory: false,
      lineupHistoryError: null,
      loadingHistory: false,
    };
  },

  created() {
    this.setLastCheckedInterval();
    this.getLineups();
    EventBus.$on('EXPAND_SLATE', this.expandSlate);
    EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$on('RELOAD_LINEUPS_PAGE', this.reloadLineups);
    EventBus.$on('GLOBAL_SWAP_SUCCESS', this.reloadLineups);
    EventBus.$on('QUICK_SWAP_SUCCESS', this.reloadLineups);
  },

  destroyed() {
    this.unsubFromSalaryContestRooms();
    clearInterval(this.lastCheckedInterval);
    EventBus.$off('EXPAND_SLATE', this.expandSlate);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('RELOAD_LINEUPS_PAGE', this.reloadLineups);
    EventBus.$off('QUICK_SWAP_SUCCESS', this.reloadLineups);
    EventBus.$off('GLOBAL_SWAP_SUCCESS', this.reloadLineups);
  },

  methods: {
    // Time for checking whether contests are live - moment doesn't live update so we get the current timestamp every second
    setLastChecked() {
      this.lastCheckedTs = new this.$moment().valueOf();
    },

    setLastCheckedInterval() {
      this.lastCheckedInterval = setInterval(this.setLastChecked, 1000);
    },

    reloadLineups() {
      this.getLineups(true);
    },

    getLineups(isReload = false) {
      if (!isReload) {
        this.loadingLineups = true;
        this.expandedId = null;
      }

      ObSalaryCapApi.getLineups()
          .then((response) => {
            this.slates = response.slates;
            this.teams = response.teams;
            this.contests = response.contests;
            this.loadingLineups = false;
            this.subscribeToSalaryContestRooms();
          }).catch((_error) => {
            this.loadingLineups = false;
          });
    },

    getLineupHistory() {
      this.loadingHistory = true;
      this.lineupHistoryError = null;
      ObSalaryCapApi.getLineupHistory()
          .then((response) => {
            this.completedSlates = response.slates;
            this.completedTeams = response.teams;
            this.loadingHistory = false;
          }).catch((_error) => {
            this.lineupHistoryError = 'Failed to load lineup history';
            this.loadingHistory = false;
          });
    },

    toggleHistory(show) {
      if (!this.showHistory && show) {
        this.getLineupHistory();
      }
      this.showHistory = show;
    },

    openGlobalSwapModal() {
      EventBus.$emit('OPEN_GLOBAL_SWAP_MODAL');
    },

    openQuickSwapModal() {
      EventBus.$emit('OPEN_QUICK_SWAP_MODAL');
    },

    openCreateLineupModal() {
      EventBus.$emit('OPEN_CREATE_LINEUP_MODAL');
    },

    expandSlate(id) {
      this.expandedId = this.expandedId == id ? null : id;
    },

    subscribeToSalaryContestRooms() {
      if (!this.contests) {
        return;
      }
      for (const contestId of this.contests) {
        const roomKey = 'FSP_SC_UPDATE_' + contestId;
        this.$SocketController.subscribeToRoom(roomKey);
      }
    },

    unsubFromSalaryContestRooms() {
      if (!this.contests) {
        return;
      }
      for (const contestId of this.contests) {
        const roomKey = 'FSP_SC_UPDATE_' + contestId;
        this.$SocketController.unsubscribeFromRoom(roomKey);
      }
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'FSP_SC_UPDATE') {
        this.updateContestTeams(data.json.id);
      }
    },

    updateContestTeams(contestId) {
      ObSalaryCapApi.getLeaderboardForUser(contestId)
          .then((response) => {
            for (const team of response) {
              const teamKey = contestId + '_' + team.teamId;
              this.$set(this.teams, teamKey, team);
            }
          })
          .catch((_error) => {
            // Do nothing
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.swapBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  padding-bottom: 15px;
  .ob-btn, .ob-btn-grey {
    font-size: 14px;
  }
}

.swapBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  .fas {
    margin-right: 5px;
  }
}

.completedHeader {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 6px;
  font-weight: bold;
  font-size: 16px;
}

.noHistoryContainer {
  padding: 6px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}

.ownersbucksIcon {
  height: 10px;
}

.noLineupsContainer {
  text-align: center;
  margin-top: 40px;
  > div {
    margin-bottom: 20px;
  }
  .ob-btn-grey {
    font-size: 14px;
  }
}
</style>