<template>
  <div class="playerContainer" :class="{locked: isPlayerLocked}">
    <div class="headshotContainer">
      <PlayerHeadshot :id="player.id" :sport="sport" :team="player.teamAlias"
        :size="headshotSize ? headshotSize : '60'" :showLogo="true"
      />
      <img v-if="allowLockedPlayers" class="lockIcon" @click.stop="setLockPlayer"
        :src="getLockIcon" alt="lock icon"
      >
      <img v-else-if="locked" class="lockIcon" :src="require('@/assets/lightninglineups/lock.png')"
        alt="lock icon"
      >
    </div>
    <div class="playerName">{{ player.position }} - {{ player.name.shortName() }}</div>
    <div class="nextGame">{{ playerGameText }}</div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import EventBus from '@/event-bus';

export default {
  props: {
    sport: {type: String, default: null},
    player: {type: Object, default: null},
    headshotSize: {type: String, default: null},
    allowLockedPlayers: {type: Boolean, default: false},
    lockedPlayerId: {type: String, default: null},
    locked: {type: Boolean, default: false},
  },

  components: {
    PlayerHeadshot,
  },

  watch: {
    player() {
      if (this.player.isLocked && this.allowedLockedPlayers) {
        EventBus.$emit('LL_SET_LOCK_PLAYER', this.player.id);
      }
    },
  },

  computed: {
    playerGameText() {
      if (this.player == null || this.player.game == null) {
        return 'No Game';
      }
      const homeText = this.player.game.home ? 'vs' : '@';
      const oppAlias = this.player.game.oppAlias.toUpperCase();
      const gameName = this.$TeamInfo.getDisplayAlias(this.sport, oppAlias);
      const gameTime = this.$moment(this.player.game.date).format('h:mma');

      return homeText + ' ' + gameName + ' ' + gameTime;
    },

    getLockIcon() {
      if (!this.lockedPlayerId) {
        return require('@/assets/lightninglineups/unlockEnabled.png');
      } else if (this.lockedPlayerId === this.player.id) {
        return require('@/assets/lightninglineups/lock.png');
      } else {
        return require('@/assets/lightninglineups/unlockDisabled.png');
      }
    },

    isPlayerLocked() {
      return this.lockedPlayerId === this.player.id;
    },
  },
  methods: {
    setLockPlayer() {
      if (!this.lockedPlayerId) {
        EventBus.$emit('LL_SET_LOCK_PLAYER', this.player.id);
      } else if (this.isPlayerLocked) {
        EventBus.$emit('LL_SET_LOCK_PLAYER', null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.playerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  font-size: 12px;
  padding: 5px;
  border: 2px solid transparent;
}

.playerContainer.locked {
  border: 2px solid var(--obcolor-accent);
  border-radius: 5px;
  width: 87%;
}

.headshotContainer {
  display: flex;
  flex-direction: row;
}

.playerName {
  margin-top: 8px;
  margin-bottom: 2px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}

.nextGame {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  white-space: nowrap;
}

.lockIcon {
  margin-bottom: 43px;
  margin-right: -17px;
  width: 17px;
  height: fit-content;
}
</style>