<template>
  <div class="contentCards">
    <!-- the style property doesn't accept css custom variables -->
    <!-- @vue-ignore -->
    <div v-for="card of filteredCards" :key="card.id" class="contentCard"
      :style="{ '--aspect-ratio': card.aspectRatio }"
    >
      <a v-if="card.url" :href="card.url" target="_blank">
        <img class="image" :src="card.imageUrl" :alt="card.title">
      </a>
      <img v-else class="image" :src="card.imageUrl"
        :alt="card.title"
      >
    </div>
  </div>
</template>

<script>
// @ts-check

import * as braze from '@braze/web-sdk';
import Vue from 'vue';
import store from '@/store';

export default Vue.extend({
  name: 'ContentCards',

  data() {
    return {
      cards: [],
      cardSub: null,
    };
  },

  props: {
    locationId: {type: String, default: null},
    filter: {
      /** @type {import('vue').PropType<Record<string,string>>} */
      type: Object,
      default: null,
    },
  },

  model: {
    event: 'change',
  },

  watch: {
    filteredCards(to, from) {
      if (to.length != from.length) {
        this.$emit('change', to.length > 0);
      }
    },
  },

  created() {
    braze.requestContentCardsRefresh();
    this.$emit('change', this.filteredCards.length > 0);
  },
  computed: {
    filteredCards() {
      /** @type braze.Card[] */
      let cards = store.getters.contentCards ?? [];

      cards = cards.filter((card) => 'imageUrl' in card && card.imageUrl);

      if (this.locationId !== null) {
        cards = cards.filter((card) => card.extras.locationId === this.locationId);
      }
      if (this.filter) {
        cards = cards.filter((card) => {
          for (const [key, value] of Object.entries(this.filter)) {
            if (card.extras[key] && card.extras[key] !== String(value)) {
              return false;
            }
          }
          return true;
        });
      }
      return cards;
    },
  },
});
</script>

<style lang="scss">
:root {
  --content-card-height: 58px;
}
.contentCards {
  width: 100%;
  overflow: hidden;

  .contentCard {
    .image {
      height: var(--content-card-height);
      width: calc(var(--content-card-height) * var(--aspect-ratio));
    }
  }
}
</style>
