import { render, staticRenderFns } from "./LeagueModalPayout.vue?vue&type=template&id=6b370d70&scoped=true"
import script from "./LeagueModalPayout.vue?vue&type=script&lang=js"
export * from "./LeagueModalPayout.vue?vue&type=script&lang=js"
import style0 from "./LeagueModalPayout.vue?vue&type=style&index=0&id=6b370d70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b370d70",
  null
  
)

export default component.exports