<template>
  <div style="width: 100%;">
    <div class="payoutBar">
      <div class="payoutZone" :style="getPayoutZoneWidth(true)"></div>
      <div class="payoutZoneLabel" :style="getPayoutZoneWidth(false)">Pay Zone</div>
    </div>
    <div class="myEntries">
      <router-link v-for="entry in contest.leaderboard" :key="entry.teamId" :to="getTeamLink(entry.teamId)">
        <div class="entryCircle" :style="getRankPct(entry)">
          <div class="entryGraph">
            <PieChart size="18" :slices="getPieSlices(entry)" />
          </div>
          <div class="entryTriangle"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart';

export default {

  components: {
    PieChart,
  },

  props: {
    contest: {type: Object, default: null},
    teamData: {type: Object, default: null},
  },

  methods: {
    getPayoutZoneWidth(roundBorders) {
      let pct = this.contest.numPayouts / this.contest.playersCurrent * 100;
      if (pct >= 100) {
        pct = 100;
      }
      let css = 'width: ' + pct + '%;';
      if (roundBorders && pct >= 100 ) {
        css += 'border-radius: 6px;';
      }
      return css;
    },

    getRankPct(entry) {
      const pct = (entry.rank - 1) / (this.contest.playersCurrent - 1) * 100;
      return 'right: calc(' + pct + '% - 8px);';
    },

    getTeamLink(teamId) {
      return '/salarycontest/leaderboard/' + this.contest.id + '/' + teamId;
    },

    getPieSlices(team) {
      const slices = [];
      const pct = Math.round(team.gp / this.contest.gameLimits.total * 100);

      let baseColor = 'var(--obcolor-ob-blue)';
      const underLimits = this.teamData?.underLimits;
      const overLimits = this.teamData?.overLimits;

      if (underLimits?.[team.teamId]) {
        baseColor = 'red';
      } else if (overLimits?.[team.teamId]) {
        baseColor = 'orange';
      }

      slices.push({color: '#474F6D', percent: pct});
      slices.push({color: baseColor, percent: 100 - pct});
      return slices;
    },
  },

};
</script>

<style lang="scss" scoped>
.payoutBar {
  margin-top: 3px;
  height: 10px;
  border-radius: 6px;
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  position: relative;
}

.payoutZone {
  background: var(--obcolor-green);
  height: 100%;
  width: 25%;
  border-radius: 0 6px 6px 0;
  position: absolute;
  right: 0;
}

.payoutZoneLabel {
  background: rgba(13, 157, 44, 0.243);
  height: 18px;
  width: 45%;
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2px;
  padding-right: 6px;
  font-size: 12px;
  font-weight: bold;
  color: var(--obcolor-green);
  white-space: nowrap;
  box-sizing: border-box;
}

.myEntries {
  width: calc(100% - 8px);
  margin-left: 4px;
  height: 17px;
  position: relative;
}
.entryCircle.entryCircleHl {
  background: var(--obcolor-ob-blue) !important;
  z-index: 2;
  .entryTriangle {
    border-bottom: 6px solid var(--obcolor-ob-blue) !important;
  }
}
.entryCircle {
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  position: absolute;
  color: white;
  transition-property: right;
  transition-duration: 600ms;
  cursor: pointer;
  border-radius: 50%;
  background: #474F6D;
}
.entryCircle:hover {
  bottom: 33px;
}
.entryGraph {
  height: 18px;
}

.entryTriangle {
  top: 16px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #474F6D;
  position: absolute;
}
</style>