<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 550px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);" :hideCloseButton="true"
  >
    <div slot="header" class="modalHeader">
      <div class="modalTitle">
        Submit Lineups
      </div>
    </div>
    <div slot="body" class="modalBody">
      <div v-if="errorMessage != ''" class="error">
        {{ errorMessage }}
      </div>
      <div v-else-if="confirmLineups">
        You are submitting {{ lineups.length }} lineup(s) for a Total Entry Fee of <span class="moneyText"> ${{ entryFee }}</span>
      </div>
      <div v-else-if="submitting">
        Submitting Lineup(s)...
        <ObLoading />
      </div>
      <div v-else>
        <div>
          Successfully submitted {{ lineupsSubmitted.length }} lineup(s)
        </div>
        <div v-if="errorLineups.length > 0">
          <!-- Table of lineups with errors -->
          <table aria-labelledby="errorSubmittingLineups">
            <tr @click.stop="showErrors = !showErrors">
              <th id="errorSubmittingLineups" class="errorLineupsHeader">
                <i class="fas fa-exclamation-circle fa-lg error"></i>
                Error submitting {{ errorLineups.length }} lineup(s)
              </th>
              <th>
                <div>
                  <i v-if="!showErrors" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </div>
              </th>
            </tr>
            <template v-if="showErrors">
              <tr v-for="(errorLineup, index) in errorLineups" :key="index">
                <td>
                  <div class="lineupContainer">
                    <div v-for="(id, playerIndex) in errorLineup.players" :key="playerIndex" class="player">
                      <div class="playerActive" @click.stop="openPlayerCard(id, sport, contestId)">
                        <span>{{ getPlayerName(id) }}</span>
                        <span v-if="playerIndex !== errorLineup.players.length - 1">, </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-tooltip.bottom="{content: errorLineup.errorMessage, class:'ObTooltip'}">
                    <i class="fas fa-exclamation-circle fa-lg error"></i>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <div v-if="!submitting" slot="footer" class="modalFooter">
      <!-- Submit Buttons -->
      <div class="btnList">
        <template v-if="errorMessage != ''">
          <button class="ob-btn" @click="closeModal()">Close</button>
        </template>
        <template v-else-if="confirmLineups">
          <button class="ob-btn-grey" @click="closeModal()">Cancel</button>
          <button class="ob-btn" @click="confirm()">Confirm</button>
        </template>
        <template v-else>
          <button class="ob-btn-grey" style="width: 120px;" @click="goToLineupBuilder()">Lineup Builder</button>
          <button class="ob-btn" @click="goToLobby()">Lobby</button>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      lineups: [],
      result: [],
      contest: null,
      playerMap: null,
      confirmLineups: false,
      submitting: false,
      canSubmit: false,
      showErrors: false,
      errorMessage: '',
    };
  },

  created() {
    EventBus.$on('OPEN_MULTIPLE_LINEUP_CONFIRM_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_MULTIPLE_LINEUP_CONFIRM_MODAL', this.openModal);
  },

  computed: {
    entryFee() {
      if (!this.contest || !this.contest.feeValue) {
        return 0;
      }

      return this.contest.feeValue * this.lineups.length / 100;
    },

    lineupsSubmitted() {
      const lineups = [];
      for (const lineup of this.result) {
        if (lineup.success) {
          lineups.push(lineup);
        }
      }

      return lineups;
    },

    errorLineups() {
      const lineups = [];
      for (const lineup of this.result) {
        if (!lineup.success) {
          lineups.push(lineup);
        }
      }

      return lineups;
    },

    contestId() {
      if (!this.contest || !this.contest.id) {
        return 0;
      }
      return this.contest.id;
    },

    sport() {
      if (!this.contest || !this.contest.sport) {
        return '';
      }
      return this.contest.sport;
    },
  },

  methods: {
    openModal(entryInfo) {
      if (!entryInfo) {
        return;
      }

      this.lineups = entryInfo.lineups;
      this.contest = entryInfo.contest;
      this.playerMap = entryInfo.playerMap;
      this.resetData();

      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    resetData() {
      this.confirmLineups = true;
      this.canSubmit = true;
      this.submitting = false;
      this.showErrors = false;
      this.result = [];
      this.errorMessage = '';
    },

    getPlayerName(id) {
      if (!id) {
        return '';
      }

      const contestPlayer = this.playerMap[id];
      if (!contestPlayer) {
        return id;
      }

      return contestPlayer.name.shortName();
    },

    openPlayerCard(playerId, sport, contestId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId.toString(), sport, contestId, true);
    },

    goToLobby() {
      this.$router.push('/lobby');
    },

    goToLineupBuilder() {
      EventBus.$emit('CLOSE_IMPORT_MODAL');
      EventBus.$emit('SALARY_CAP_RELOAD_CONTEST_DATA', false);
      this.closeModal();
    },

    userNotVerified(lineups) {
      for (const lineup of lineups) {
        if (!lineup.success && lineup.errorMessage.includes('account must be verified')) {
          return true;
        }
      }

      return false;
    },

    confirm() {
      this.confirmLineups = false;
      this.submitting = true;
      const params = {
        teams: this.lineups,
      };
      this.submitLineups(params);
    },

    submitLineups(params) {
      const id = this.contest.id;
      ObSalaryCapApi.submitLineups(params, id)
          .then((response) => {
            this.submitting = false;

            if (this.userNotVerified(response)) {
              EventBus.$emit('OPEN_VERIFY_ACCOUNT_MODAL', false);
              EventBus.$emit('CLOSE_IMPORT_MODAL');
              this.closeModal();
              return;
            }

            this.result = response;
            if (this.lineupsSubmitted.length > 0) {
              this.$Analytics.trackTournamentEntry(this.contest, true, null, this.lineupsSubmitted.length);
            }
            if (this.errorLineups.length > 0) {
              this.$Analytics.trackTournamentEntry(this.contest, false, null, this.errorLineups.length);
            }

            EventBus.$emit('UPDATE_ACCOUNT_INFO');
          })
          .catch((error) => {
            this.submitting = false;
            this.$Analytics.trackTournamentEntry(this.contest, false, null, this.lineups.length);
            this.errorMessage = error.response.data;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  table {
    width: 506px;
    font-size: 14px;
    text-align: center;
    border-collapse: collapse;
    margin-top: 20px;
  }

  tr:not(:nth-child(1)) {
    border-top: 1px solid var(--obcolor-background-1);
  }

  th {
    font-size: 14px;
    line-height: 35px;
    padding: 3px 8px;
    border-top: 1px solid var(--obcolor-background-1);
    border-bottom: 1px solid var(--obcolor-background-1);
    color: var(--obcolor-text-primary);
  }

  td {
    padding: 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }

  .btnList {
    width: 100%;
    display: flex;
    justify-content: right;

    button:not(:last-child) {
      margin-right: 10px;
    }

    button {
      width: 100px;
      font-size: 14px;
    }
  }

  .error {
    color: var(--obcolor-red);
  }

  .player {
    font-weight: bold;
    font-size: 14px;

    margin-right: 7px;
  }

  .playerActive {
    color: var(--obcolor-text-primary);
  }

  .playerActive:hover {
    text-decoration: underline;
    text-decoration-color: var(--obcolor-text-primary);
    cursor: pointer;
  }

  .modalTitle {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    padding: 10px;
  }

  .modalBody {
    max-height: 300px;
    padding: 20px 10px;
  }

  .lineupContainer {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .modalFooter {
    background: var(--obcolor-background-5);
    padding: 10px 15px;
  }

  .modalHeader {
    background: var(--obcolor-page-header);
  }

  .errorLineupsHeader {
    text-align: left;
  }
</style>