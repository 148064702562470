<template>
  <div>
    <table class="gamesTable" aria-label="Player Card Schedule">
      <template v-if="sport != 'NFL'">
        <tr class="mainHeader">
          <template v-if="gamesByWeek">
            <th v-for="(game, index) in gamesByWeek[isExpanded ? 0 : curWeekIndex]" :key="index" style="font-size: 12px;">
              <div>{{ game.date | moment("ddd") }}</div>
              <div>{{ game.date | moment("MMM D") }}</div>
            </th>
          </template>
        </tr>

        <template v-if="gamesByWeek">
          <template v-for="(games, week) in gamesByWeek">
            <template v-if="isExpanded || week === curWeekIndex">
              <tr v-if="week > 0 && isExpanded" :key="'h'+week">
                <th v-for="(game, index) in games" :key="'dayheader-'+week+'-'+index" style="font-size: 12px; padding: 8px 0px;"
                  scope="row"
                >
                  <div>{{ game.date | moment("ddd") }}</div>
                  <div>{{ game.date | moment("MMM D") }}</div>
                </th>
              </tr>

              <tr :key="week">
                <td v-for="(game, index) in games" :key="'g'+index" :class="isToday(game) ? 'todayTd' : ''"
                  style="min-width: 95px;"
                >
                  <div style="display: flex; flex-direction: column; font-size: 12px;">
                    <!-- Game Info -->
                    <div v-if="game.game" :style="game.excluded ? 'color: red;' : ''">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <div>{{ getGameSymbol(game.game) }}</div>
                        <img class="teamLogo" :src="getTeamLogo(game.game)" :alt="game.game">
                      </div>
                      <div v-if="game.score" style="margin-bottom: 3px;">{{ game.score }}</div>
                      <div v-else-if="game.ts" style="margin-bottom: 3px;">{{ parseInt(game.ts) | moment("h:mma") }}</div>
                      <!-- Note: game.played of null does not mean DNP. Only a value of false. -->
                      <div v-if="gameHourAfterStart(game) && !game.played">DNP</div>
                      <div v-else-if="game.fpts != null" style="color: var(--obcolor-ob-blue);">
                        <span v-if="game.fpts > -1">+{{ game.fpts }} PTS</span>
                        <span v-else style="color: red;">{{ game.fpts }} PTS</span>
                      </div>
                      <div v-else>Proj {{ game.proj.toFixed(0) }}</div>
                    </div>

                    <!-- Doubleheader -->
                    <div v-if="game.gameDH" :style="game.excludedDH ? 'color: red;' : ''" style="margin-top: 12px;">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <div>{{ getGameSymbol(game.gameDH) }}</div>
                        <img class="teamLogo" :src="getTeamLogo(game.gameDH)" :alt="game.gameDH">
                      </div>
                      <div v-if="game.scoreDH" style="margin-bottom: 3px;">{{ game.scoreDH }}</div>
                      <div v-else-if="game.tsDH" style="margin-bottom: 3px;">{{ parseInt(game.tsDH) | moment("h:mma") }}</div>
                      <!-- Note: game.played of null does not mean DNP. Only a value of false. -->
                      <div v-if="gameHourAfterStart(game, true) && !game.playedDH">DNP</div>
                      <div v-else-if="game.fptsDH != null" style="color: var(--obcolor-ob-blue);">
                        <span v-if="game.fptsDH > -1">+{{ game.fptsDH }} PTS</span>
                        <span v-else style="color: red;">{{ game.fptsDH }} PTS</span>
                      </div>
                      <div v-else>Proj {{ game.projDH.toFixed(0) }}</div>
                    </div>

                    <div v-if="!game.game" style="height: 38px;"></div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </template>

      <template v-else>
        <tr class="mainHeader">
          <template v-if="nflWeekGames">
            <th v-for="(game, index) in nflWeekGames" :key="index" style="font-size: 12px;">
              <template v-if="game.date">
                <div>{{ game.date | moment("ddd") }}</div>
                <div>{{ game.date | moment("MMM D") }}</div>
              </template>
              <div style="font-size: 12px; padding:5px;" v-else>BYE</div>
            </th>
          </template>
        </tr>

        <tr v-if="nflWeekGames">
          <td v-for="(game, index) in nflWeekGames" :key="'g'+index" :class="isToday(game) ? 'todayTd' : ''"
            style="min-width: 95px;"
          >
            <div style="display: flex; flex-direction: column; font-size: 12px;">
              <!-- Game Info -->
              <div v-if="game.game" :style="game.excluded ? 'color: red;' : ''">
                <div style="display: flex; align-items: center; justify-content: center;">
                  <div>{{ getGameSymbol(game.game) }}</div>
                  <img class="teamLogo" :src="getTeamLogo(game.game)" :alt="game.game">
                </div>
                <div v-if="game.score" style="margin-bottom: 3px;">{{ game.score }}</div>
                <div v-else-if="game.ts" style="margin-bottom: 3px;">{{ parseInt(game.ts) | moment("h:mma") }}</div>
                <!-- Note: game.played of null does not mean DNP. Only a value of false. -->
                <div v-if="gameHourAfterStart(game) && !game.played">DNP</div>
                <div v-else-if="game.fpts != null" style="color: var(--obcolor-ob-blue);">
                  <span v-if="game.fpts > -1">+{{ game.fpts }} PTS</span>
                  <span v-else style="color: red;">{{ game.fpts }} PTS</span>
                </div>
                <div v-else>Proj {{ game.proj.toFixed(0) }}</div>
              </div>

              <div v-if="!game.game" style="height: 38px; display: flex; align-items: center; justify-content: center;">-</div>
            </div>
          </td>
        </tr>
      </template>
    </table>

    <div v-if="!gamesByWeek" style="height: 71px; font-size: 14px; display: flex; align-items: center; justify-content: center;">
      Loading...
    </div>

    <template v-if="showExpand()">
      <div class="showMore" v-if="!isExpanded" @click="setIsExpanded(true)">Show more...</div>
      <div class="showMore" v-else @click="setIsExpanded(false)">Show less</div>
    </template>
  </div>
</template>

<script>
import ObPlayersApi from '@/api/ObPlayersApi';

export default {
  props: {
    playerId: String,
    leagueId: String,
    sport: String,
    isScContest: Boolean,
  },

  data() {
    return {
      numberNames: ['ZERO', 'One', 'Two', 'Three', 'Four'],
      minimizedLimit: 1,
      isExpanded: false,
      gamesByWeek: null,
      wStats: null,
      curWeekIndex: 0,
    };
  },

  created() {
    this.loadData();
  },

  computed: {
    nflWeekGames() {
      if (this.sport != 'NFL' || this.gamesByWeek == null) {
        return null;
      }

      const gamesList = [];

      for (const week of this.gamesByWeek) {
        let hasGame = false;
        for (const game of week) {
          if (game.game) {
            gamesList.push(game);
            hasGame = true;
          }
        }

        if (!hasGame) {
          gamesList.push({});
        }
      }

      return gamesList;
    },

    totNflStats() {
      const totStats = {
        avg: 0,
        proj: 0,
      };

      if (this.nflWeekGames == null) {
        return totStats;
      }

      for (const stat of this.wStats) {
        if (stat.proj) {
          totStats.proj += stat.proj;
        }
      }

      // Don't include bye weeks in AVG
      let gameCount = 0;
      for (const game of this.nflWeekGames) {
        if (game.proj) {
          gameCount++;
        }
      }
      if (gameCount > 0) {
        totStats.avg = totStats.proj / gameCount;
      }

      return totStats;
    },
  },

  methods: {
    loadData() {
      ObPlayersApi.getPlayerSchedule(this.playerId, this.leagueId, this.isScContest)
          .then((data) => {
            this.determineCurrentWeek(data.gamesByWeek);
            this.gamesByWeek = data.gamesByWeek;
            this.wStats = data.wStats;
          });
    },

    gameHourAfterStart(game, isDH) {
      const now = new Date().getTime();
      const gTime = isDH ? parseInt(game.tsDH) : parseInt(game.ts);
      return now > (gTime + (3600000 * 6));
    },

    determineCurrentWeek(gamesByWeek) {
      if (!gamesByWeek) {
        return;
      }
      const now = this.$moment();
      let weekIndex = -1;
      for (const week of gamesByWeek) {
        const gDate = this.$moment(week[0].date);
        if (now >= gDate) {
          weekIndex += 1;
        }
      }
      if (weekIndex === -1) {
        weekIndex = 0;
      }
      this.curWeekIndex = weekIndex;
    },

    isToday(game) {
      if (game && game.date) {
        const gDate = this.$moment(game.date).format('MM/DD/YYYY');
        const today = this.$moment().format('MM/DD/YYYY');
        return gDate === today;
      }
      return false;
    },

    getTeamLogo(alias) {
      alias = alias.replace('@', '');
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getGameSymbol(game) {
      return game.indexOf('@') != -1 ? '@' : 'vs';
    },

    showExpand() {
      return this.gamesByWeek && this.gamesByWeek.length > this.minimizedLimit && this.sport != 'NFL';
    },

    setIsExpanded(isExpanded) {
      this.isExpanded = isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>

.todayTd {
  background: var(--obcolor-background-2);
}

.teamLogo {
  height: 21px;
  margin-bottom: 3px;
  margin-left: 2px;
}

.gamesTable {
  border-radius: 5px;
  width: 100%;
}

tr {
  background: var(--obcolor-background-2);
}

tr:not(:first-child) {
  background: var(--obcolor-background-6);
}

.mainHeader {
  th {
    background: var(--obcolor-background-2);
  }
}

th {
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-background-3);
}

td {
  padding: 6px;
  border-top: 3px solid var(--obcolor-background-6);
  border-bottom: 3px solid var(--obcolor-background-6);
}

th:not(.leftInfo), td:not(.leftInfo) {
  min-width: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}

.showMore {
  padding: 5px 10px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  cursor: pointer;
  border-top: 1px solid var(--obcolor-background-2);
}
</style>