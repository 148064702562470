<template>
  <div class="entriesContainer ob-scroll" @scroll="onScroll">
    <div class="entryList">
      <div v-if="isLoading && leaderboardPages.length == 0" class="loadingContainer"><ObLoading /></div>
      <div v-else-if="leaderboardPages.length == 0" class="noEntrants">No Entrants</div>
      <div v-else v-for="entry in getLeaderboardEntries()" :key="entry.teamId"
        class="entry" :class="{ myEntry: entry.userId == userId }"
      >
        <UserLogo class="userLogo" :id="entry.userId" :size="36"
          :sport="league.sport"
          :showRank="true" :rankColor="entry.rankColor" :rankNum="entry.rankNum"
        />
        <div class="entryText">
          <div class="entryName">{{ entry.team }}</div>
          <div class="teamNum">{{ entry.name }}</div>
        </div>
        <div v-if="entry.entries> 1">
          <div class="numEntries">{{ entry.teamNum }}</div>
          <div class="numEntriesTitle">ENTRY</div>
        </div>
      </div>
    </div>

    <!-- Lazy Load Spinner -->
    <div v-if="isLoading && leaderboardPages.length > 0" class="pageLoading">
      <em class="fad fa-spinner-third fa-spin"></em>
    </div>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';
import UserLogo from '@/components/UserLogo';
import {mapState} from 'vuex';

export default {
  components: {
    UserLogo,
    ObLoading,
  },

  props: {
    league: {type: Object, default: null},
  },

  data() {
    return {
      isLoading: false,
      leaderboardPages: [],
      lastScrollPos: 0,
      pageLoadDistance: 1200,
    };
  },

  computed: {
    ...mapState(['userId']),
  },

  created() {
    this.getLeaderboardPage(1);
  },

  methods: {
    getLeaderboardPage(page) {
      this.isLoading = true;
      ObSalaryCapApi.getLeaderboardPage(this.league.id, page)
          .then((response) => {
            if (response.page) {
              this.leaderboardPages.push(response);
            }
            this.isLoading = false;
          })
          .catch((_error) => {
            // Leaderboard page load error
            this.isLoading = false;
          });
    },

    getLeaderboardEntries() {
      const entries = [];
      for (const page of this.leaderboardPages) {
        entries.push(...page.leaderboard);
      }
      return entries;
    },

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      // Show more entries once we scroll to the bottom of the table
      // Extra pixel buffer to reduce stuttering (load starts before reaching the end of the list)
      if (!this.isLoading) {
        const scrollingDown = this.lastScrollPos < scrollTop;

        // Scrolled to bottom, load next page
        if (scrollingDown && scrollTop + clientHeight + this.pageLoadDistance >= scrollHeight) {
          const curPage = this.leaderboardPages[this.leaderboardPages.length - 1].page;
          const lastPage = this.leaderboardPages[this.leaderboardPages.length - 1].totalPages;
          if (curPage < lastPage) {
            this.getLeaderboardPage(curPage + 1);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.entriesContainer {
  background: none;
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  overflow-y: auto;
  font-size: 14px;
  padding: 5px 15px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  width: 100%
}

.pageLoading {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15px;
  text-align: center;
  padding: 6px;
  opacity: 0.8;
}

.noEntrants {
  text-align: center;
  padding: 15px;
  width: 100%;
}

.entryList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -3px;
  padding-bottom: 4px;
}

.entry {
  width: calc(50% - 3px);
  padding: 8px 10px 8px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: var(--obcolor-background-3);
  margin-top: 6px;
}

.entry:nth-child(odd) {
  margin-right: 6px;
}

.entryText {
  margin-left: 18px;
  flex: 1;
}

.myEntry .entryName {
  color: var(--obcolor-ob-blue);
}

.entryName {
  font-size: 14px;
  margin-bottom: 2px;
}

.teamNum {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.numEntries, .numEntriesTitle {
  text-align: center;
}

.numEntries {
  font-size: 16px;
  margin-bottom: 2px;
}

.numEntriesTitle {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
}
</style>