<template>
  <tr class="playerRow" :class="{hoverRow: canSelect()}" @click="selectPlayer()"
    :style="isBeingAdded ? 'background: #3b8ed320;' : restrictDroppedPlayer ? 'background: #ff000025;' : ''"
    v-tooltip.top="{content: 'You cannot add a player with zero projected games to your lineup.', class: 'ObTooltip', visible: projRemaining === 0}"
  >
    <td style="width: 50px; min-width: 50px;">{{ player.draftPos }}</td>
    <td>
      <div class="playerName">
        <div v-if="!statusMap" class="statusLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <PlayerStatus v-else-if="!status" class="playerStatus" height="16px;"
          status="'OUT'"
        />
        <PlayerStatus v-else class="playerStatus" height="16px;"
          :status="status"
        />

        <span @click.stop="openPlayerCard()" class="ob-link" style="margin-right: 6px;"
          :style="playerTextColour"
        >
          {{ player.name.longName() }}
          {{ player.nameTag }}
        </span>

        <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
        ></i>
        <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
        ></i>
      </div>
      <div v-if="restrictDroppedPlayer" class="playerInfo">
        <span style="color: red;">Dropped</span>
      </div>
      <div v-else class="playerInfo">
        <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }}</span>
        <span>#{{ player.number }}</span>
        <span>{{ player.position }}</span>
        <span>{{ player.lineInfo }}</span>
      </div>
      <div style="margin-top: 5px" class="playerInfo">
        <MlbBattingOrder v-if="isDailyMLB() && !isPitcher()" :myBatterIndex="getBatterIndex()" />
      </div>
    </td>

    <!-- Stats -->
    <template v-if="selectedView === 'stats'">
      <template v-if="contest.format == 'WEEKLY'">
        <td>{{ gamesPlayed }}</td>
        <td :style="fptsColor">{{ sumFpts }}</td>
        <td>{{ gamesRemaining }}</td>
      </template>
      <template v-else>
        <td>
          <div v-if="nextGame" class="logoBox">
            <div v-if="nextGame.game.includes('@')" style="margin-bottom: 3px;">@</div>
            <img :src="getTeamLogo(nextGame.game)" :alt="`${nextGame.game} Team`" style="height: 24px;">
          </div>
          <div v-else>-</div>
        </td>
        <td class="gameTimeCol">
          <div v-if="nextGame && nextGame.ts != null">{{ $moment(nextGame.ts).format('ddd h:mma') }}</div>
          <div v-else>-</div>
        </td>
        <td v-if="contest.sport == 'NFL'">
          <div v-if="nextGame && nextGame.oprk != null" class="oprk" :style="'color: ' + getOprkColour(nextGame.oprk)">{{ nextGame.oprk }}</div>
          <div v-else>-</div>
        </td>
        <td>{{ projRemaining }}</td>
      </template>
      <td>{{ player.sAvg.toFixed(1) || '-' }}</td>
      <td v-if="contest.format == 'WEEKLY'">{{ projRemaining }}</td>
    </template>

    <!-- Schedule -->
    <template v-else>
      <td v-for="g in player.games" :key="g.date" style="padding: 2px; min-width: 55px; vertical-align: top; position: relative;">
        <LineupEditorGameEntry :contest="contest" :entry="g" :isDH="false" />
        <LineupEditorGameEntry v-if="g.gameDH" :contest="contest" :entry="g"
          :isDH="true"
        />
      </td>
      <td style="min-width: 4px;"></td>
    </template>

    <!-- Salary -->
    <template v-if="contest.format == 'WEEKLY'">
      <td class="moneyText endSection" :style="projRemaining === 0 ? 'color: var(--obcolor-text-faded);' : playerTextColour">
        {{ dollarFormat(player.salaryPG) }}
      </td>
      <td style="width: 25px; min-width: 25px; padding: 0;" :style="playerTextColour">x {{ gamesRemaining }}</td>
    </template>
    <td class="moneyText" :style="projRemaining === 0 ? 'color: var(--obcolor-text-faded);' : playerTextColour">
      {{ dollarFormat(salaryRemaining) }}
    </td>

    <!-- Select Icon -->
    <td style="width: 20px; min-width: 20px; padding-right: 10px; text-align: right;">
      <i v-if="projRemaining === 0" class="fal fa-times-circle addBtn"></i>
      <i v-else-if="restrictDroppedPlayer" class="fal fa-times-circle addBtn"></i>
      <i v-else-if="isBeingAdded" class="fal fa-minus-circle removeBtn"></i>
      <i v-else class="fal fa-plus-circle addBtn"></i>
    </td>
  </tr>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus.vue';
import LineupEditorGameEntry from './LineupEditorGameEntry';
import EventBus from '@/event-bus';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder.vue';

export default {
  props: {
    player: {type: Object, default: null},
    selectedFor: {type: String, default: null},
    statusMap: {type: Object, default: null},
    status: {type: String, default: null},
    contest: {type: Object, default: null},
    totSpent: {type: Number, default: null},
    selectedView: {type: String, default: null},
    disabled: {type: Boolean, default: false},
    dropped: {type: Boolean, default: false},
    isBeingAdded: {type: Boolean, default: false},
  },

  components: {
    PlayerStatus,
    LineupEditorGameEntry,
    MlbBattingOrder,
  },

  computed: {
    isWeeklyContest() {
      return this.contest && this.contest.format === 'WEEKLY';
    },

    restrictDroppedPlayer() {
      // In Daily contests you can add players you've previously dropped
      return this.dropped && this.contest.format !== 'DAILY';
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    fptsColor() {
      if (this.sumFpts >= 0) {
        return 'color: var(--obcolor-ob-blue);';
      }
      if (this.sumFpts < 0) {
        return 'color: red;';
      }
      return '';
    },

    sumFpts() {
      let fpts = 0;
      for (const game of this.player.games) {
        fpts += game.fpts ? game.fpts : 0;
        fpts += game.fptsDH ? game.fptsDH : 0;
      }
      return fpts;
    },

    gamesPlayed() {
      let gp = 0;
      for (const game of this.player.games) {
        gp += game.played ? 1 : 0;
        gp += game.playedDH ? 1 : 0;
      }
      return gp;
    },

    gamesRemaining() {
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && this.player.canSwap;
      const now = new Date().getTime();
      let gr = 0;
      for (const game of this.player.games) {
        if ((isValidDailySwap || game.ts > now) && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && (isValidDailySwap || game.tsDH > now) && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    projRemaining() {
      if (this.gamesRemaining == null || !this.player) {
        return 0;
      }
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && this.player.canSwap;
      const now = new Date().getTime();
      let proj = 0;
      for (const game of this.player.games) {
        proj += this.getGameEntryProjection(game, isValidDailySwap, now);
      }
      return proj;
    },

    salaryRemaining() {
      return this.gamesRemaining * this.player.salaryPG;
    },

    playerTextColour() {
      if (!this.player || !this.player.id) {
        return 'color: var(--obcolor-text-faded);';
      }
      return this.isBeingAdded ? 'color: var(--obcolor-ob-blue);' : '';
    },

    nextGame() {
      if (!this.player.games) {
        return null;
      }
      return this.player.games.find((g) => g.ts != null);
    },
  },

  methods: {
    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getOprkColour(oprk) {
      if (oprk <= 10) {
        return 'var(--obcolor-red)';
      } else if (oprk <= 20) {
        return 'var(--obcolor-yellow)';
      }
      return 'var(--obcolor-green)';
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },

    selectPlayer() {
      if (this.canSelect()) {
        EventBus.$emit('LINEUP_EDITOR_SELECT_TO_ADD', this.player.id);
      }
    },

    canSelect() {
      return !this.restrictDroppedPlayer && this.projRemaining > 0;
    },

    getBatterIndex() {
      if (!this.nextGame || !this.nextGame.info) {
        return null;
      }
      return this.nextGame.info.lineup;
    },

    isPitcher() {
      return this.player && this.player.draftPos == 'P';
    },

    isDailyMLB() {
      return this.contest && this.contest.format == 'DAILY' && this.contest.sport == 'MLB';
    },

    getGameEntryProjection(game, isValidDailySwap, now) {
      if (!game) {
        return 0;
      }
      let proj = 0;
      if (game.ts && (isValidDailySwap || game.ts > now)) {
        proj += game.proj ? Math.round(game.proj) : 0;
      }
      if (game.tsDH && (isValidDailySwap || game.tsDH > now)) {
        proj += game.projDH ? Math.round(game.projDH) : 0;
      }
      return proj;
    },
  },
};
</script>

<style lang="scss" scoped>
  .hoverRow:hover {
    cursor: pointer;
    .addBtn {
      color: var(--obcolor-font-primary);
    }
    .removeBtn {
      color: var(--obcolor-font-primary);
    }
  }

  td {
    padding: 8px 4px 8px 4px;
    min-width: 80px;
    // background: #3b8ed3c0;
  }

  .playerName {
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .statusLoading {
    width: 16px;
    margin-top: -1px;
    margin-right: 6px;
    margin-left: -3px;
    color: var(--obcolor-font-secondary);
  }

  .playerStatus {
    margin-top: -2px;
    margin-right: 8px;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 20px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .removeBtn {
    color: var(--obcolor-ob-blue);
    font-size: 22px;
  }

  .notAllowedBtn {
    color: var(--obcolor-text-faded);
    font-size: 22px;
  }

  .addBtn {
    color: var(--obcolor-text-faded);
    font-size: 22px;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
  }

  .redText {
    color: var(--obcolor-red);
  }

  .scheduleBox {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    background: var(--obcolor-background-2);
    // border: 1px solid var(--obcolor-background-2);
    min-height: 52px;
    font-size: 12px;
    font-weight: bold;
  }
  .logoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-font-secondary);
    margin-bottom: 2px;

    div {
      margin-left: -11px;
      margin-right: 2px;
      margin-top: 5px;
    }
  }

  .gameTimeCol {
    white-space: nowrap;
  }

  .oprk {
    font-weight: bold;
  }

  .endSection {
    border-left: 2px solid var(--obcolor-background-2);
    // background: #ff000049;
  }

</style>