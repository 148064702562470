<template>
  <div class="validationListContainer">
    <ObLoading v-if="submitState.isSending == true" :size="70" />
    <div class="contestListContainer" v-else>
      <table class="headerTable" aria-label="Contest Header Table">
        <tr v-if="curStep == 'validated'">
          <th class="resultsCol">
            <div class="resultsText greyText" v-if="numSelected">
              <i class="infoIcon fal fa-info-circle"></i>
              <div>
                This change will impact {{ numSelected }} lineups.
              </div>
            </div>
            <div class="resultsText redText" v-if="numErrors">
              <i class="infoIcon fas fa-exclamation-circle"></i>
              <div>
                This change cannot be applied to {{ numErrors }} lineups.
              </div>
            </div>
          </th>
          <th>
            <div class="selectAllContainer">
              <div class="greyText">Select All Lineups</div>
              <SquareCheckbox :checked="isAllSelected" size="18" @click="selectAll" />
            </div>
          </th>
          <th class="dropdownCol"></th>
        </tr>
      </table>
      <div class="ob-scroll tableContainer">
        <table aria-label="Contest List" class="contestTable">
          <!-- Contest list -->
          <template v-for="contest in curStepResults.contests">
            <tr class="hoverRow" :key="contest.contest.id" @click="toggleDropdown(contest.contest.id)">
              <!-- Sport icon -->
              <td class="sportCol">
                <div class="sportContainer">
                  <img class="sportBadge" :src="require('@/assets/contestbadge/' + sport + '.png')">
                </div>
              </td>
              <!-- Contest name -->
              <td class="nameCol">
                <div class="contestValue">{{ contest.contest.contestName }}</div>
                <div class="contestLabel">{{ $moment(contest.contest.contestStart).format('dddd MMMM DD, h:mma') }}</div>
              </td>
              <!-- Num successful teams -->
              <td class="statCol">
                <template v-if="curStep == 'submitted'">
                  <div class="contestValue">
                    {{ numSuccessByContest(contest) }}/{{ contest.teams.length }}
                  </div>
                  <div class="contestLabel">Succeeded</div>
                </template>
              </td>
              <!-- Contest prize pool -->
              <td class="statCol">
                <div class="contestValue" :class="contest.contest.realMoney ? 'greenText' : 'blueText'">
                  <img v-if="contest.contest.isGuaranteed" class="guaranteedIcon"
                    :src="require('@/assets/contesticons/Guaranteed' + (contest.contest.realMoney ? '' : 'Blue') + '.svg')"
                    alt="Guaranteed"
                  >
                  <div>{{ contest.contest.prizePool.removeCentsIfZero() }}</div>
                </div>
                <div class="contestLabel">Prize Pool</div>
              </td>
              <!-- Does the contest have errors -->
              <td class="checkboxCol">
                <div class="errorSuccessIcon">
                  <i v-if="(curStep == 'submitted' && contestHasErrors(contest)) || isAllTeamsError(contest)" class="fas fa-exclamation-circle redText"></i>
                  <i v-else-if="curStep == 'submitted'" class="fas fa-check-circle greenText"></i>
                  <SquareCheckbox v-else :checked="isContestSelected(contest)" size="18"
                    @click="selectByContest(contest)"
                  />
                </div>
              </td>
              <td class="dropdownCol">
                <div class="dropdownContainer">
                  <i class="fas" :class="selectedDropdowns[contest.contest.id] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                </div>
              </td>
            </tr>

            <!-- Teams from contest dropdown -->
            <template v-if="selectedDropdowns[contest.contest.id]">
              <tr v-for="team in contest.teams" :key="team.teamKey">
                <td class="sportCol">
                  <div class="sportContainer">
                    <img class="sportIcon" :src="require('@/assets/icons/sporticon_' + sport + '.png')">
                  </div>
                </td>
                <td class="nameCol">
                  <div class="nameList">
                    <div v-for="(player, index) in team.players" :key="index"
                      :class="{redText: !player.isValid}"
                    >
                      {{ player.name }}{{ index < team.players.length - 1 ? ', ' : '' }}
                    </div>
                  </div>
                  <div v-if="!team.success" class="teamError redText">
                    <i class="infoIcon fas fa-exclamation-circle"></i>
                    <div>{{ team.error }}</div>
                  </div>
                </td>
                <td class="statCol">
                  <template v-if="team.salaryRem">
                    <div class="contestValue" :class="{greenText: team.salaryRem > 0, redText: team.salaryRem < 0}">
                      {{ team.salaryRem.formatMoney() }}
                    </div>
                    <div class="contestLabel">Rem Salary</div>
                  </template>
                </td>
                <td class="statCol">
                  <template v-if="team.proj">
                    <div class="contestValue" :class="{blueText: team.proj > 0, redText: team.proj < 0}">
                      {{ team.proj }}
                    </div>
                    <div class="contestLabel">Proj</div>
                  </template>
                </td>
                <td class="checkboxCol">
                  <div class="errorSuccessIcon">
                    <i v-if="!team.success" class="fas fa-exclamation-circle redText"></i>
                    <i v-else-if="curStep == 'submitted'" class="fas fa-check-circle greenText"></i>
                    <SquareCheckbox v-else :checked="selectedSwaps[team.teamKey]" size="18"
                      @click="selectTeam(team)"
                    />
                  </div>
                </td>
                <td class="dropdownCol"></td>
              </tr>
            </template>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SquareCheckbox from '@/components/buttons/SquareCheckbox';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';

export default {
  props: {
    validationState: {type: Object, default: null},
    submitState: {type: Object, default: null},
    selectedSlate: {type: Object, default: null},
  },

  components: {
    SquareCheckbox,
    ObLoading,
  },

  data() {
    return {
      selectedSwaps: {},
      selectedDropdowns: {},
    };
  },

  created() {
    this.setupSelectedPlayers(this.validationState.result);
    this.setupSelectedDropdowns(this.validationState.result);
  },

  destroyed() {},

  computed: {
    sport() {
      return this.selectedSlate.sport;
    },

    curStep() {
      if (this.submitState?.result) {
        return 'submitted';
      }
      return 'validated';
    },

    curStepResults() {
      if (!this.submitState?.loading && this.submitState?.result) {
        return this.submitState.result;
      } else {
        return this.validationState?.result;
      }
    },

    isAllSelected() {
      if (!this.curStepResults?.contests) {
        return false;
      }
      for (const contest of this.curStepResults.contests) {
        if (!this.isContestSelected(contest)) {
          return false;
        }
      }
      return true;
    },

    numSelected() {
      let tot = 0;
      for (const contest of this.curStepResults.contests) {
        for (const team of contest.teams) {
          if (this.selectedSwaps[team.teamKey]) {
            tot++;
          }
        }
      }
      return tot;
    },

    numSuccess() {
      return this.validationState.result.numTeams - this.numErrors;
    },

    numErrors() {
      return this.validationState.result.numFailed;
    },
  },

  watch: {},

  methods: {
    setupSelectedPlayers(result) {
      this.selectedSwaps = {};
      const contests = result.contests;
      for (const contest of contests) {
        for (const team of contest.teams) {
          if (!this.selectedSwaps[team.contestId]) {
            this.$set(this.selectedSwaps, team.teamKey, team.success);
          }
        }
      }

      this.emitSelectedTeams();
    },

    setupSelectedDropdowns(result) {
      this.selectedDropdowns = {};
      for (const contest of result.contests) {
        this.$set(this.selectedDropdowns, contest.contest.id, false);
      }
    },

    selectAll() {
      const allSelected = this.isAllSelected;
      for (const contest of this.curStepResults.contests) {
        for (const team of contest.teams) {
          this.selectTeam(team, !allSelected);
        }
      }
    },

    isContestSelected(contest) {
      for (const team of contest.teams) {
        if (!this.selectedSwaps[team.teamKey] && team.success) {
          return false;
        }
      }

      return true;
    },

    isAllTeamsError(contest) {
      for (const team of contest.teams) {
        if (team.success) {
          return false;
        }
      }

      return true;
    },

    selectByContest(contest) {
      const allSelected = this.isContestSelected(contest);
      const teams = contest.teams;
      for (const team of teams) {
        this.selectTeam(team, !allSelected);
      }
    },

    selectTeam(team, value = null) {
      if (!team.success) {
        return;
      }

      if (value === null) {
        this.selectedSwaps[team.teamKey] = !this.selectedSwaps[team.teamKey];
      } else {
        this.selectedSwaps[team.teamKey] = value;
      }

      this.emitSelectedTeams();
    },

    emitSelectedTeams() {
      const selectedTeams = [];
      for (const contest of this.curStepResults.contests) {
        for (const team of contest.teams) {
          if (this.selectedSwaps[team.teamKey]) {
            selectedTeams.push(team.teamKey);
          }
        }
      }

      EventBus.$emit('QUICK_SWAP_SET_SELECTED_TEAMS', selectedTeams);
    },

    contestHasErrors(contest) {
      for (const team of contest.teams) {
        if (!team.success) {
          return true;
        }
      }

      return false;
    },

    numSuccessByContest(contest) {
      let totSuccess = 0;
      for (const team of contest.teams) {
        if (team.success) {
          totSuccess++;
        }
      }
      return totSuccess;
    },

    toggleDropdown(contestId) {
      this.selectedDropdowns[contestId] = !this.selectedDropdowns[contestId];
    },
  },
};
</script>

<style lang="scss" scoped>
.validationListContainer {
  width: 100%;
  min-height: 0;
  flex: 1;
}

.contestListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tableContainer {
  flex: 1;
  max-height: 100%;
  overflow-y: scroll;
}

.infoIcon {
  margin-right: 5px;
}

.resultsText:not(:first-child) {
  margin-top: 5px;
}

table {
  width: 100%;
  font-size: 14px;
  position: relative;
}

.headerTable {
  tr {
    height: 60px;
    position: sticky;
    top: 0;
    background: var(--obcolor-background-page);
    border-bottom: 2px solid var(--obcolor-background-4);
  }

  .resultsCol {
    padding-left: 15px;
  }

  .resultsCol .infoIcon {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .resultsText {
    display: flex;
    align-items: center;
  }

  .dropdownCol {
    padding-right: 8px;
  }
}

.contestTable {
  tr:not(:first-child) {
    border-top: 2px solid var(--obcolor-background-4);
  }

  tr {
    height: 80px;
  }

  tr:last-child {
    border-bottom: 2px solid var(--obcolor-background-4);
  }

  th {
    font-weight: normal;
  }

  th:first-child, td:first-child {
    padding-left: 10px;
  }

  .hoverRow {
    cursor: pointer;

    &:hover {
      background: var(--obcolor-background-4);
    }
  }
}

.selectAllContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.sportCol {
  width: 55px;
  padding-right: 10px;
}

.sportContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sportIcon {
  height: 30px;
}

.sportBadge {
  width: 100%;
}

.contestValue {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.guaranteedIcon {
  height: 16px;
  margin-right: 5px;
}

.contestLabel {
  margin-top: 3px;
  color: var(--obcolor-font-secondary);
}

.nameCol {
  padding: 10px;
}

.nameList {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  gap: 5px;

  > div {
    white-space: nowrap;
  }
}

.statCol {
  width: 120px;
  .statText {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.teamError {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.checkboxCol {
  width: 40px;
}

.errorSuccessIcon {
  font-size: 22px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dropdownCol {
  width: 45px;
  .dropdownContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.greyText {
  color: var(--obcolor-font-secondary);
}

.redText {
  color: var(--obcolor-red);
}

.greenText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

</style>