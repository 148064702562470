<template>
  <div class="ob-page" style="max-width: 800px;">
    <div class="onBoardingContainer">
      <OnBoardingHeader />
      <OnBoardingEnterNumber v-if="page == 1" :signupData="signupData" />
      <OnBoardingVerifyNumber v-if="page == 2" :signupData="signupData" />
      <OnBoardingChooseUsername v-if="page == 3" />
      <OnBoardingCreateAccount2 v-if="page == 4" />
      <OnBoardingReferral v-if="page == 5" :signupData="signupData" />
      <OnBoardingConditions v-if="page == 6" />
      <OnBoardingCreateAccountErrors v-if="page == 7" :signupData="signupData" />
      <OnBoardingEnableLoc v-if="page == 8" />
      <OnBoardingEnableNotification v-if="page == 9" />
      <OnBoardingAccountSet v-if="page == 10" :signupData="signupData" />
      <OnBoardingAccountVerify v-if="page == 11" />
      <OnBoardingPersonalInfo v-if="page == 12" />
      <OnBoardingVerified v-if="page == 13" />
      <OnBoardingDeposit v-if="page == 14" />
    </div>
    <OnBoardingKrogerModal />
  </div>
</template>

<script>
import OnBoardingHeader from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingHeader';
import OnBoardingEnterNumber from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingEnterNumber';
import OnBoardingVerifyNumber from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingVerifyNumber';
import OnBoardingEnableLoc from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingEnableLoc';
import OnBoardingChooseUsername from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingChooseUsername';
import OnBoardingCreateAccount2 from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingCreateAccount2';
import OnBoardingCreateAccountErrors from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingCreateAccountErrors.vue';
import OnBoardingConditions from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingConditions';
import OnBoardingReferral from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingReferral';
import OnBoardingEnableNotification from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingEnableNotification';
import OnBoardingAccountSet from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingAccountSet';
import OnBoardingAccountVerify from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingAccountVerify';
import OnBoardingPersonalInfo from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingPersonalInfo';
import OnBoardingVerified from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingVerified';
import OnBoardingDeposit from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingDeposit';
import OnBoardingKrogerModal from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingKrogerModal.vue';

import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

// Controls where a user is sent after create account API is triggered
const CREATE_ACCOUNT_ERROR_PAGE = 7;
const CREATE_ACCOUNT_SUCCESS_PAGE = 8;
const MAX_PAGE = 14;

export default {
  components: {
    OnBoardingHeader,
    OnBoardingEnterNumber,
    OnBoardingEnableLoc,
    OnBoardingChooseUsername,
    OnBoardingCreateAccount2,
    OnBoardingCreateAccountErrors,
    OnBoardingVerifyNumber,
    OnBoardingConditions,
    OnBoardingReferral,
    OnBoardingEnableNotification,
    OnBoardingAccountSet,
    OnBoardingAccountVerify,
    OnBoardingPersonalInfo,
    OnBoardingVerified,
    OnBoardingDeposit,
    OnBoardingKrogerModal,
  },

  data() {
    return {
      page: 1,
      signupData: {
        signupErrors: null,
        phoneNumber: null,
        phoneVerificationId: null,
        phoneVerified: false,
        referralCode: null,
        userName: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        dob: '',
        country: '',
        region: '',
        city: '',
        address: '',
        postalCode: '',
      },
    };
  },

  computed: {
    ...mapState(['redirectedLink']),
  },

  watch: {
    '$route.params.page'(newVal) {
      if (newVal) {
        let pNum = parseInt(newVal);
        pNum = this.checkPageLimits(pNum);
        this.page = pNum + (CREATE_ACCOUNT_SUCCESS_PAGE - 1);
      }
    },

    page(newVal) {
      // After account creation change route name and start adding step number
      if (newVal >= CREATE_ACCOUNT_SUCCESS_PAGE) {
        const welcomePage = newVal - (CREATE_ACCOUNT_SUCCESS_PAGE - 1);
        if (welcomePage < 9 && this.$route.params.page !== String(welcomePage)) {
          this.$router.push({name: 'welcome', params: {page: welcomePage}});
        } else if (welcomePage >= 9) {
          this.exitOnBoarding();
        }
      }
    },
  },

  created() {
    EventBus.$on('ON_BOARDING_NEXT', this.nextPage);
    EventBus.$on('ON_BOARDING_BACK', this.prevPage);
    EventBus.$on('ON_BOARDING_EXIT', this.exitOnBoarding);
    EventBus.$on('UPDATE_SIGNUP_DATA', this.updateSignupData);
    EventBus.$on('ON_BOARDING_CREATE_ACCOUNT', this.createAccount);

    if (this.$route.query.r != null) {
      this.signupData.referralCode = this.$route.query.r;
    }

    // Initial route handling
    const initialPage = this.$route.params.page ? parseInt(this.$route.params.page) + (MAX_PAGE - CREATE_ACCOUNT_SUCCESS_PAGE + 1) : 1;
    this.goToPage(initialPage);
  },

  destroyed() {
    EventBus.$off('ON_BOARDING_NEXT', this.nextPage);
    EventBus.$off('ON_BOARDING_BACK', this.prevPage);
    EventBus.$off('ON_BOARDING_EXIT', this.exitOnBoarding);
    EventBus.$off('UPDATE_SIGNUP_DATA', this.updateSignupData);
    EventBus.$off('ON_BOARDING_CREATE_ACCOUNT', this.createAccount);
  },

  methods: {
    exitOnBoarding() {
      if (this.redirectedLink != null) {
        this.$router.push(this.redirectedLink);
      } else {
        this.$router.replace('/start-playing');
      }
    },

    checkPageLimits(pageNum) {
      if (pageNum < 1) {
        pageNum = 1;
      } else if (pageNum > MAX_PAGE) {
        pageNum = MAX_PAGE;
      }
      return pageNum;
    },

    goToPage(pageNum) {
      pageNum = this.checkPageLimits(pageNum);
      this.page = pageNum;
    },

    updateSignupData(key, value) {
      this.$set(this.signupData, key, value);
    },

    prevPage() {
      this.goToPage(this.page - 1);
    },

    nextPage() {
      if (this.page < 14) {
        this.goToPage(this.page + 1);
      } else {
        this.exitOnBoarding();
      }
    },

    getFromCookieOrStorage(key) {
      let value = this.$cookie.get(key);
      if (!value) {
        value = localStorage.getItem(key);
      }
      return value;
    },

    setupAffiliateParams(params) {
      const partnerCode = this.getFromCookieOrStorage('ob-partner');
      const btag = this.getFromCookieOrStorage('btag');
      const fpros = this.getFromCookieOrStorage('fpros');
      const rotogrindersId = this.getFromCookieOrStorage('rotogrindersId');
      const afUserId = this.getFromCookieOrStorage('afUserId');
      const mbsy = this.getFromCookieOrStorage('mbsy');

      if (partnerCode != null && partnerCode != '') {
        params.partnerCode = partnerCode;
      }
      if (btag != null && btag != '') {
        params.btag = btag;
      }
      if (fpros != null && fpros != '') {
        params.fantasyProsUserId = fpros;
      }
      if (rotogrindersId != null && rotogrindersId != '') {
        params.rotoGrindersUserId = rotogrindersId;
      }
      if (afUserId != null && afUserId != '') {
        params.afUserId = afUserId;
      }
      if (mbsy != null && mbsy != '') {
        params.mbsy = mbsy;
      }
    },

    createAccount() {
      const data = this.signupData;
      const params = {
        username: data?.userName?.trim(),
        email: data?.email?.trim(),
        password: data?.password,
        phoneVerificationId: data?.phoneVerificationId,
        referralCode: data?.referralCode,
      };

      this.setupAffiliateParams(params);
      this.$Analytics.trackSignupAttempt(params);

      ObAccountApi.createAccount(params)
          .then((response) => {
            // Tracking Signup
            try {
              this.$Analytics.trackUser(response.userId, params.afUserId);
              this.$Analytics.trackSignup(params, response.userId, true);
            } catch (e) {
              // Suppress error
            }
            // Login user
            if (response.userId) {
              this.$store.commit('setUserState', response.userState);
              this.$store.commit('setNodeToken', response.st);
              this.$store.commit('setLoggedIn', response.userId);
              EventBus.$emit('LOGIN');
              this.goToPage(CREATE_ACCOUNT_SUCCESS_PAGE);

              // Delayed account info update, to get initial bonus
              setTimeout(() => {
                EventBus.$emit('UPDATE_ACCOUNT_INFO');
                EventBus.$emit('CHANGE_PROFILE_PICTURE');
              }, 5000);
            }
          })
          .catch((error) =>{
            // Tracking failed signup
            this.$Analytics.trackSignup(params, '', false);
            // Handle Errors
            const errorMap = error?.response?.data?.errors;
            this.signupData.signupErrors = errorMap;
            // If there is a phone number error, we need to go back to phone verification
            // This should be a rare, but we need to handle it just in case
            const errorPage = errorMap?.phoneNumber ? 1 : CREATE_ACCOUNT_ERROR_PAGE;
            this.goToPage(errorPage);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.onBoardingContainer {
  font-family: 'Archivo', sans-serif;
  margin-top: 50px;
  box-sizing: border-box;
}

::v-deep {
  .container input {
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
    padding: 15px 25px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .container select {
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
  }
  .arrowContainer {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
  .onboardingBackBtn,
  .onboardingForwardBtn {
    width: 45px;
    height: 45px;
    border: 2px solid var(--obcolor-font-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      background: var(--obcolor-background-2);
    }
  }

  h1,
  p {
    margin: 0;
  }

  p {
    line-height: 21px;
  }

  h1 {
    padding: 50px 0 30px 0;
    font-size: 24px;
    line-height: 10px;
    font-weight: bold;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 705px;
    margin: 0 auto;
  }

  .ob-btn[disabled] {
    background: var(--obcolor-font-secondary);
    color: var(--obcolor-background-2) !important;
    cursor: not-allowed;
  }
  .ob-btn:focus{
    border: 2px solid #08299F;
  }
  .ob-btn-white[disabled] {
    color: var(--obcolor-background-2) !important;
    cursor: not-allowed;
  }

  .buttonContainer {
    display: flex;
    gap: 20px;
    max-height: 50px;
    margin-top: 50px;

    button:nth-child(1) {
      width: 180px;
      max-height: 50px;
    }

    button:nth-child(2) {
      flex: 1;
      max-height: 50px;
    }
  }

  .errorBorder {
    border: 2px solid var(--obcolor-red) !important;
  }

  input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }

  input:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }

  .separator {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
  }
  .separator:before, .separator:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
    color: var(--obcolor-font-secondary);
  }
  .separator:before {
    margin-right: 10px
  }
  .separator:after {
    margin-left: 10px
  }

  .progressStep .progressBar {
    width: 340px;
  }
  .progressStepContainer {
    justify-content: space-between;
    padding: 50px 0 10px 0;
  }
}
</style>