<template>
  <div class="wrapper" :class="{ atStart, atEnd }">
    <button class="previous" @click="previous">
      <i class="far fa-chevron-left"></i>
    </button>
    <div class="scroller" ref="scroller" @scroll.passive="onScroll">
      <slot></slot>
    </div>
    <button class="next" @click="next">
      <i class="far fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
// @ts-check

import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      atStart: true,
      atEnd: false,
      observer: null,
    };
  },
  mounted() {
    this.observer = new MutationObserver(() => {
      this.onScroll();
    });

    this.observer.observe(this.$refs['scroller'], {childList: true});
    this.onScroll();
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    next() {
      this.scrollPercent(0.75);
    },
    previous() {
      this.scrollPercent(-0.75);
    },
    scrollPercent(percent) {
      /** @type {HTMLDivElement} */
      const scroller = this.$refs['scroller'];
      const rect = scroller.getBoundingClientRect();
      scroller.scrollBy({
        left: rect.width * percent,
        behavior: 'smooth',
      });
    },

    onScroll() {
      /** @type {HTMLDivElement} */
      const scroller = this.$refs['scroller'];
      const rect = scroller.getBoundingClientRect();
      if (scroller.scrollWidth === rect.width) {
        this.atStart = true;
        this.atEnd = true;
        return;
      }
      this.atStart = scroller.scrollLeft <= 0;
      this.atEnd = scroller.scrollLeft >= Math.floor(scroller.scrollWidth) - Math.ceil(rect.width) - 1;
    },
  },
});
</script>

<style scoped>
.wrapper {
  position: relative;
  overflow: hidden;
}

.scroller {
  overflow-x: auto;
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroller::-webkit-scrollbar {
  display: none;
}

.previous,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: none;
  outline: none;
  height: 100%;
  color: var(--obcolor-font-primary);
  background-color: var(--obcolor-background-page);
  cursor: pointer;
  font-size: 18px;
  padding: 0.5em;
  transition: color 200ms, opacity 200ms;

  &:hover {
    color: var(--obcolor-ob-blue);
  }
}

.previous {
  left: 0;
}

.atStart .previous {
  opacity: 0;
  pointer-events: none;
}

.next {
  right: 0;
}

.atEnd .next {
  opacity: 0;
  pointer-events: none;
}
</style>
