<template>
  <tr v-if="myPlayer">
    <!-- My Player -->
    <td class="iconField myPlayer">
      <PlayerHeadshot :size="'44'" :id="myPlayer.id" :sport="leagueData.sport"
        :team="myPlayer.player.team" :showLogo="true"
      />
    </td>
    <td class="nameField myPlayer">
      <div class="nameContainer">
        <PlayerStatus :status="myPlayer.player.status" style="margin-top: 1px;" />
        <div class="playerName ob-link" style="margin-left: 5px;" @click.stop="openPlayerCard(myPlayer.id)">
          <div>{{ myPlayer.player.name.shortName() }} {{ myPlayer.player.nameTag }}</div>
        </div>
        <img v-if="isDailyMLB() && isCurrentBatter(myFieldState, myPlayer)"
          class="battingIcon" style="margin-left: 3px;"
          :src="require('@/assets/icons/baseball-bat-orange.png')" alt="Baseball Bat Icon"
        >
        <img v-if="isDailyMLB() && isCurrentPitcher(myFieldState, myPlayer)"
          class="battingIcon" style="margin-left: 3px;"
          :src="require('@/assets/icons/baseball-ball-orange.png')" alt="Baseball Ball Icon"
        >
      </div>
      <div v-if="myPlayer.dropped" class="playerInfo" style="color: red;">Dropped</div>
      <div v-else-if="getTodayOrFspGame() == null" class="playerInfo">
        <span>#{{ myPlayer.player.number }}</span>
        <span>{{ myPlayer.player.position }}</span>
        <span>{{ myPlayer.player.lineInfo }}</span>
      </div>
      <div v-if="getTodayOrFspGame() != null" class="playerInfo liveInfo" :style="gameIsLive(getTodayOrFspGame().todayInfo) ? 'color: var(--obcolor-accent);' : 'color: var(--obcolor-font-secondary);'">
        <LiveGameSummary :fieldState="myFieldState" :player="myPlayer" :contest="leagueData"
          :game="getTodayOrFspGame()" class="gameSummary"
        />
        <div v-if="myFieldState.teamHasBall == true"><i class="fas fa-football-ball ballIcon"></i></div>
        <div v-if="myFieldState.inRedZone == true"><div class="redZone">RZ</div></div>
        <div v-if="myFieldState.isPowerPlay" class="powerPlay">PP</div>
      </div>
      <div v-if="isNFL() && myFieldState" class="playerInfo liveInfo">{{ myFieldState.statLine }}</div>
      <MlbBattingOrder v-if="isDailyMLB() && !isPitcher(this.myPlayer)" class="playerInfo battingOrder"
        :fieldState="myFieldState"
      />
    </td>
    <td v-if="isDailyMLB()" class="liveField myPlayer">
      <template v-if="myFieldState.inning != null">
        <div class="liveGame">
          <MlbOnBase :fieldState="myFieldState" />
          <div>{{ myFieldState.count }}</div>
          <div>{{ myFieldState.inning }}</div>
        </div>
      </template>
    </td>
    <td class="pointsField myPlayer">
      <SalaryPlayerPoints :entry="myPlayer" :gameIndex="gameIndex" :todaysGames="todaysGames"
        :league="leagueData" :rosterCalc="rosterCalc"
      />
    </td>

    <!-- Middle dropdown button -->
    <td class="middleField" @click.stop="toggleDropdownOrSwap()">
      <div class="centerIcons">
        <div class="draftPos" :class="{ draftPosBlue: $SportInfo.isDraftPositionMultiplier(myPlayer.draftPos) }">{{ formatPositionAbbr(myPlayer.draftPos) }}</div>
        <div v-if="showDropdownButton()" style="margin-top: 7px; color: var(--obcolor-text-secondary); font-size: 14px;">
          <i v-if="!isDropdownOpen" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </div>
        <div v-else class="minusCircleContainer">
          <i class="fal fa-minus-circle minusCircle" style="font-size: 18px;"></i>
        </div>
      </div>
    </td>

    <!-- Opponents Player -->
    <td class="pointsField oppPlayer">
      <template v-if="playerExists(oppPlayer)">
        <SalaryPlayerPoints :entry="oppPlayer" :gameIndex="gameIndex" :todaysGames="todaysGames"
          :league="leagueData" :rosterCalc="rosterCalc"
        />
      </template>
    </td>
    <td v-if="isDailyMLB()" class="liveField oppPlayer">
      <template v-if="oppFieldState.inning != null">
        <div class="liveGame">
          <MlbOnBase :fieldState="oppFieldState" />
          <div>{{ oppFieldState.count }}</div>
          <div>{{ oppFieldState.inning }}</div>
        </div>
      </template>
    </td>
    <td class="nameField oppPlayer">
      <template v-if="playerExists(oppPlayer)">
        <div class="nameContainer">
          <img v-if="isDailyMLB() && isCurrentBatter(oppFieldState, oppPlayer)"
            class="battingIcon" style="margin-right: 3px;"
            :src="require('@/assets/icons/baseball-bat-orange.png')" alt="Baseball Bat Icon"
          >
          <img v-if="isDailyMLB() && isCurrentPitcher(oppFieldState, oppPlayer)"
            class="battingIcon" style="margin-right: 3px;"
            :src="require('@/assets/icons/baseball-ball-orange.png')" alt="Baseball Ball Icon"
          >
          <div class="playerName ob-link" @click.stop="openPlayerCard(oppPlayer.id)">
            <div>{{ oppPlayer.player.name.shortName() }} {{ oppPlayer.player.nameTag }}</div>
          </div>
          <PlayerStatus :status="oppPlayer.player.status" style="margin-top: 1px;" />
        </div>
        <div v-if="oppPlayer.dropped" class="playerInfo" style="color: red;">Dropped</div>
        <div v-else-if="getTodayOrFspGame(true) == null" class="playerInfo">
          <span>#{{ oppPlayer.player.number }}</span>
          <span>{{ oppPlayer.player.position }}</span>
          <span>{{ oppPlayer.player.lineInfo }}</span>
        </div>
        <div v-if="getTodayOrFspGame(true) != null" class="playerInfo liveInfo" :style="gameIsLive(getTodayOrFspGame(true).todayInfo) ? 'color: var(--obcolor-accent);' : 'color: var(--obcolor-font-secondary);'">
          <div v-if="oppFieldState.isPowerPlay" class="powerPlay">PP</div>
          <div v-if="oppFieldState.inRedZone == true"><div class="redZone">RZ</div></div>
          <div v-if="oppFieldState.teamHasBall == true"><i class="fas fa-football-ball ballIcon"></i></div>
          <LiveGameSummary :fieldState="oppFieldState" :player="oppPlayer" :contest="leagueData"
            :game="getTodayOrFspGame(true)" class="gameSummary"
          />
        </div>
        <div v-if="isNFL() && oppFieldState" class="playerInfo liveInfo">{{ oppFieldState.statLine }}</div>
        <MlbBattingOrder v-if="isDailyMLB() && !isPitcher(this.oppPlayer)" class="playerInfo battingOrder"
          :fieldState="oppFieldState"
        />
      </template>
      <template v-else-if="oppDataLoaded">
        <span class="greyText playerInfo">Not Yet Started</span>
      </template>
      <template v-else>
        <span class="greyText playerInfo">Loading Player</span>
      </template>
    </td>

    <!-- No Player -->
    <td class="iconField oppPlayer">
      <template v-if="playerExists(oppPlayer)">
        <PlayerHeadshot :size="'44'" :id="oppPlayer.id" :sport="leagueData.sport"
          :team="oppPlayer.player.team" :showLogo="true"
        />
      </template>
      <template v-else>
        <PlayerHeadshot :size="'44'" :id="null" :sport="leagueData.sport"
          :team="null" :showLogo="true"
        />
      </template>
    </td>
  </tr>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import SalaryPlayerPoints from '@/views/SalaryContest/Leaderboard/SalaryPlayerPoints.vue';
import MlbOnBase from '@/components/charts/MlbOnBase.vue';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder.vue';
import LiveGameSummary from '@/components/live/LiveGameSummary.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
    SalaryPlayerPoints,
    LiveGameSummary,
    MlbOnBase,
    MlbBattingOrder,
  },

  props: {
    myPlayer: Object,
    oppPlayer: Object,
    rosterCalc: Object,
    oppRosterCalc: Object,
    dataLoaded: Boolean,
    oppDataLoaded: Boolean,
    teamId: String,
    oppTeamId: String,
    leagueData: Object,
    todaysGames: Object,
    gameIndex: Number,
    isDropdownOpen: Boolean,
  },

  computed: {
    myFieldState() {
      const fieldState = this.$SportInfo.getFieldState(this.leagueData.sport, this.myPlayer, this.getFspGame(), this.getTodayOrFspGame());
      return fieldState;
    },

    oppFieldState() {
      const fieldState = this.$SportInfo.getFieldState(this.leagueData.sport, this.oppPlayer, this.getFspGame(true), this.getTodayOrFspGame(true));
      return fieldState;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.leagueData.sport);
    },

    oppIsNull() {
      return true;
    },

    contestIsOver() {
      if (!this.leagueData) {
        return false;
      }
      return this.leagueData.state === 'COMPLETED' || this.leagueData.state === 'FINALIZED';
    },

    showDropdownButton() {
      return this.contestIsOver() || this.getTodayOrFspGame(false, false) != null || this.getTodayOrFspGame(false, true) != null;
    },

    toggleDropdownOrSwap() {
      if (this.showDropdownButton()) {
        EventBus.$emit('SALARY_LIVE_TOGGLE_DROPDOWN', this.myPlayer.id);
      } else {
        EventBus.$emit('SALARY_CONTEST_NAVIGATE_TO_SWAP', this.myPlayer.id);
      }
    },

    getTodayOrFspGame(isOppTeam = false) {
      if (this.todaysGame(isOppTeam)) {
        return this.todaysGame(isOppTeam);
      }

      return this.getFspGame(isOppTeam);
    },

    getFspGame(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      if (!entry || !entry.fspGames ) {
        return null;
      }

      let foundGame = null;
      for (const index in entry.fspGames) {
        if (entry.fspGames[index].timestamp != null && foundGame == null) {
          foundGame = entry.fspGames[index];
        }
      }

      return foundGame;
    },

    todaysGame(isOppTeam = false) {
      const gameJSON = this.getFspGame(isOppTeam);
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    nflGameCounted(isOppTeam = false) {
      const rosterCalc = isOppTeam ? this.oppRosterCalc : this.rosterCalc;
      const game = this.getFspGame(isOppTeam);
      const player = isOppTeam ? this.oppPlayer : this.myPlayer;
      const gameKey = player.id + '_' + game.id;
      return rosterCalc.playedGames[gameKey];
    },

    getTotalFpts(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      if (!entry || !entry.fspGames) {
        return null;
      }
      return entry.ObTotals.total;
    },

    getTotalProj(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      if (!entry || !entry.fspGames) {
        return null;
      }
      return this.sumPlayerProj(isOppTeam);
    },

    sumPlayerPoints(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      const rosterCalc = isOppTeam ? this.oppRosterCalc : this.rosterCalc;
      if (!entry || !entry.fspGames) {
        return null;
      }

      const games = entry.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = entry.id + '_' + gJSON.id;
        if (rosterCalc.playedGames[gameKey]) {
          totalPoints += gJSON.ObPoints.total;
        }
      }
      return totalPoints;
    },

    sumPlayerProj(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;

      if (!entry || !entry.fspGames) {
        return null;
      }

      const games = entry.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        if (gJSON.inRange && gJSON.ProjPoints != null) {
          totalPoints += gJSON.ProjPoints.total;
        }
      }
      return totalPoints.toFixed(0);
    },

    findNflSummaryFspGame(isOppTeam = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      const date = this.$moment().format('YYYY-MM-DD');

      if (!entry || !entry.fspGames) {
        return null;
      }

      for (const gameIndex in entry.fspGames) {
        const game = entry.fspGames[gameIndex];
        if (this.$moment(parseInt(game.timestamp)).format('YYYY-MM-DD') == date) {
          return game;
        }
      }

      return null;
    },

    findNflSummaryGame(isOppTeam = false) {
      const fspGame = this.findNflSummaryFspGame(isOppTeam);

      if (!fspGame) {
        return null;
      }

      for (const game of this.todaysGames) {
        if (game.id == fspGame.id) {
          return game;
        }
      }

      return fspGame;
    },

    formatPositionAbbr(position) {
      if (position == this.$SportInfo.utilityMultiplier()) {
        return this.$SportInfo.utility();
      } else if (position == this.$SportInfo.superFlexMultiplier()) {
        return this.$SportInfo.superFlex();
      }

      return position;
    },

    formatPts(pts) {
      return pts > 0 ? '+' + pts : pts;
    },

    playerExists(playerInfo) {
      return playerInfo != null && playerInfo.player != null;
    },

    gameIsLive(gameInfo) {
      const liveJSON = this.gameInfo && this.gameInfo.live ? this.gameInfo.live : null;
      return liveJSON && (liveJSON.status === 'mid-event');
    },

    isPitcher(playerInfo) {
      return playerInfo != null && playerInfo.origPos == 'P';
    },

    isCurrentPitcher(fieldState, playerInfo) {
      return fieldState && playerInfo && fieldState.currentPitcher === playerInfo.player.id;
    },

    isCurrentBatter(fieldState, playerInfo) {
      return fieldState && playerInfo && fieldState.currentBatter === playerInfo.player.id;
    },

    isDailyMLB() {
      return this.leagueData && this.leagueData.format == 'DAILY' && this.leagueData.sport == 'MLB';
    },

    isNFL() {
      return this.leagueData?.sport == 'NFL';
    },
  },
};
</script>

<style lang="scss" scoped>
.overSalary {
  .moneyText, .obText, .projText {
    color: red !important;
  }

  .moneyCircle {
    color: red;
    margin-left: 4px;
    margin-top: 3px;
  }
}

.moneyText {
  color: var(--obcolor-green);
  font-weight: bold;
  margin: 3px 0;
}


.obText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;

  margin: 3px 0;

  &.whitePoints {
    color: var(--obcolor-font-primary);
  }

  &.greyPoints {
    color: var(--obcolor-font-secondary);
  }

  &.redPoints {
    color: red;
  }
}

.projText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  margin: 3px 0;
}

.negativeText {
  color: red;
}

th, td {
  text-align: center;
}
td {
  height: 60px;
  font-size: 12px;
}
tr {
  td {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
}
tr:last-child:not(:nth-child(10)) {
  td {
    border-bottom: none;
  }
}

tr.playerRow {
  cursor: pointer;
}
tr.playerRow:hover {
  background: var(--obcolor-background-2);
}

.posField {
  width: 50px;
  font-weight: bold;
  font-size: 14px;
}
.iconField {
  width: 45px;

  &.myPlayer {
    padding-left: 17px;
  }

  &.oppPlayer {
    padding-right: 22px;
  }
}
.nameField.myPlayer {
  text-align: left;
  padding-left: 10px;

  .nameContainer {
    display: flex;
    justify-content: flex-start;
  }

  .playerName {
    margin-left: 5px;
  }
}
.nameField.oppPlayer {
  text-align: right;
  padding-right: 10px;

  .nameContainer {
    display: flex;
    justify-content: flex-end;
  }

  .playerName {
    margin-right: 5px;
  }
}
.liveField {
  width: 20px;
  font-size: 11px;
  color: var(--obcolor-font-secondary);
  white-space: nowrap;
}

.myPlayer .liveGame {
  margin-left: -25px;
  padding-right: 0px;
}

.oppPlayer .liveGame {
  margin-right: -25px;
}

.liveGame {
  width: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow:visible;
}

.playerInfo {
  font-size: 12px;
  margin-top: 4px;

  color: var(--obcolor-font-secondary);
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    margin-right: 3px;
    text-overflow: ellipsis;
  }

  &:last-child {
    margin-bottom: -4px;
  }
}

.myPlayer .playerInfo {
  text-align: left;
  margin-left: 16px;
  span {
    margin-right: 3px;
  }
}

.oppPlayer .playerInfo {
  text-align: right;
  margin-right: 16px;
  span {
    margin-left: 3px;
  }
}

.oppPlayer .battingOrder {
  justify-content: flex-end;
}

.battingIcon {
  height: 14px;
  margin-left: 3px;
  margin-top: -2px;
}

.liveInfo {
  display: flex;
  align-items: center;
}

.oppPlayer .liveInfo {
  justify-content: flex-end;
}

.pointsField {
  width: 65px;
}

.myPlayer.pointsField {
  text-align: right;
  padding-right: 13px;
}

.oppPlayer.pointsField {
  text-align: left;
  padding-left: 13px;
}

.minusCircleContainer {
  margin-top: -5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minusCircle {
  color: var(--obcolor-red);
  cursor: pointer;
}

.greyText {
  color: var(--obcolor-font-secondary);
}

.middleField {
  width: 40px;
  background: var(--obcolor-background-6);
  cursor: pointer;

  &:hover {
    background: var(--obcolor-background-3);

    .minusCircle {
      color: white;
    }
  }

  .centerIcons {
    height: 42px;
    padding-top: 10px;
    border-radius: 0 0 10px 10px;
  }

  .draftPos {
    font-size: 14px;
  }

  .draftPosBlue {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }
}

.myPlayer .gameSummary {
  margin-right: 3px;
}

.oppPlayer .gameSummary {
  margin-left: 3px;
}

.ballIcon {
  margin-right: 3px;
  margin-left: 3px;
  color: var(--obcolor-NBA);
}

.myPlayer .redZone {
  margin-left: 3px;
}

.oppPlayer .redZone {
  margin-right: 3px;
}

.redZone {
  background: red;
  color: white;
  font-weight: bold;
  padding: 1px 3px 1px 2px;
  border-radius: 4px;
}

.inactiveGame {
  opacity: 0.5;
}

.powerPlay {
  background: red;
  color: white;
  font-weight: bold;
  padding: 1px 2px;
  border-radius: 4px;
  margin-left: 6px;
}
</style>