<template>
  <div v-if="playerDetails.news">
    <div class="componentTitle">LATEST NEWS</div>

    <div class="newsEntry" v-for="(entry, index) in playerNews" :key="index">
      <!-- Heading -->
      <div style="display: flex; align-items: center; margin-top: 5px;">
        <div class="newsHeadline">{{ entry.headline }}</div>
        <div class="newsDate">{{ $moment(entry.date).format('MMM D h:mma') }}</div>
      </div>
      <!-- Article -->
      <div class="newsBody">
        <div style="margin-bottom: 10px">{{ entry.details }}</div>
        <div v-if="entry.impact" style="margin-bottom: 10px;">{{ entry.impact }}</div>
        <div class="newsSource">{{ entry.source }}</div>
      </div>
    </div>

    <template v-if="showExpand()">
      <div class="showMore" v-if="!isExpanded" @click="setIsExpanded(true)">Show more...</div>
      <div class="showMore" v-else @click="setIsExpanded(false)">Show less</div>
    </template>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    playerDetails: Object,
    sport: String,
  },

  computed: {
    playerNews() {
      if (this.isExpanded === false) {
        return this.playerDetails.news.slice(0, this.minimizedLimit);
      }
      return this.playerDetails.news;
    },
  },

  data() {
    return {
      isExpanded: false,
      minimizedLimit: 1,
    };
  },

  methods: {
    showExpand() {
      return this.playerDetails.news && this.playerDetails.news.length > this.minimizedLimit;
    },

    setIsExpanded(isExpanded) {
      this.isExpanded = isExpanded;
      EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'news');
    },
  },
};
</script>

<style lang="scss" scoped>
.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 12px 15px;
  background: var(--obcolor-background-2);
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.newsEntry {
  padding: 10px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--obcolor-background-2);
  }
}

.newsHeadline {
  font-size: 16px;
  font-weight: bold;
  color: var(--obcolor-font-primary);
  flex: 1;
}

.newsDate {
  text-align: right;
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: bold;
}

.newsBody {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding-top: 10px;
  padding-bottom: 5px;
  width: 100%;
}

.newsSource {
  color: var(--obcolor-font-secondary);
}

.showMore {
  padding: 5px 10px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  cursor: pointer;
}
</style>