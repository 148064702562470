import { render, staticRenderFns } from "./DepositCreditMethod.vue?vue&type=template&id=4862b467&scoped=true"
import script from "./DepositCreditMethod.vue?vue&type=script&lang=js"
export * from "./DepositCreditMethod.vue?vue&type=script&lang=js"
import style0 from "./DepositCreditMethod.vue?vue&type=style&index=0&id=4862b467&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4862b467",
  null
  
)

export default component.exports