<template>
  <div slot="body" class="importLineupsComtainer">
    <div v-if="loading" class="loadingIcon">
      <ObLoading />
    </div>
    <template v-else>
      <div class="inlineCenter">
        <div class="importInstructionContainer">
          <div class="importInstructionHeader">How to Create Lineup via CSV</div>
          <div class="importInstruction">1. <span class="exportPlayerSlate" @click="exportPlayerSlate()">Download a template</span> for the current slate</div>
          <div class="importInstruction">2. Follow the instructions above the player list to build your lineup</div>
          <div class="importInstruction">3. Save the file and upload below</div>
          <div v-if="csvError" class="csvErrorText">{{ csvErrorMessage }}</div>
        </div>
        <div class="importImageContainer">
          <img class="importImage" :src="require('@/assets/importlineups/importCSVLogo.png')" alt="Import CSV File Image">
        </div>
      </div>
      <div @dragover="dragover" @drop="drop">
        <label class="customFileUpload">
          <template>
            <input type="file" accept="csv" @change="uploadFile($event)">
            <i class="fas fa-file-upload fileIcon"></i>
            <div class="fileUploadText">Drag and Drop to Upload File</div>
            <div class="fileUploadText">or</div>
            <div class="ob-btn fileUploadText">
              <i class="fas fa-arrow-alt-from-bottom uploadIcon"></i>
              <span>Upload CSV</span>
            </div>
          </template>
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import {parse} from 'csv-parse/sync';

export default {
  components: {
    ObLoading,
  },

  props: {
    contest: {type: Object, default: null},
  },

  data() {
    return {
      loading: false,
      csvErrorMessage: '',
    };
  },

  created() {
    EventBus.$on('RESET_CSV_ERROR', this.resetData);
  },

  destroyed() {
    EventBus.$off('RESET_CSV_ERROR', this.resetData);
  },

  computed: {
    csvError() {
      return this.csvErrorMessage != '';
    },
  },

  methods: {
    exportPlayerSlate() {
      ObSalaryCapApi.getPlayerSlate(this.contest.id, this.contest.contestName)
          .then(() => {
            this.csvErrorMessage = '';
          })
          .catch(() => {
            this.csvErrorMessage = 'Error downloading the contest player slate';
          });
    },

    dragover(e) {
      // Prevent the browser from downloading the file
      // so client can import and process file
      e.preventDefault();
    },

    drop(e) {
      // Prevent the browser from downloading the file
      e.preventDefault();
      this.loading = true;
      this.readFile(e.dataTransfer.files[0]);
    },

    uploadFile(e) {
      this.loading = true;
      this.readFile(e.target.files[0]);
    },

    readFile(file) {
      if (!file) {
        this.loading = false;
        return;
      }

      // Check file extension
      if (!this.isCSVFile(file.name)) {
        this.loading = false;
        this.csvErrorMessage = 'Only CSV files are permitted';
        return;
      }

      // Read CSV file
      const reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        self.parseFile(e.target.result);
      };
      reader.readAsText(file);
    },

    parseFile(csvFile) {
      const file = parse(csvFile, {relax_column_count: true});
      this.parseLineups(file);
    },

    isCSVFile(filename) {
      if (!filename) {
        return false;
      }

      const fileExtension = filename.split('.').pop();
      return fileExtension == 'csv';
    },

    // Get lineups from csv file imported
    parseLineups(file) {
      const self = this;
      const importedTeams = [];

      // Iterate through each row in the csv file imported to get edited lineup on each row
      file.forEach((row, rowIndex) => {
        // the first row will always be a header row, so skip it
        // Only iterate through rows up to the maximum amount of entries allowed
        if (rowIndex <= 0 || rowIndex > self.maxCSVEntries) {
          return;
        }

        const players = [];

        // Iterate through each column in the csv file row to get player IDs in the lineup
        row.forEach((col, colIndex) => {
          // Get Player ID from file
          const idStr = col.match(/\d+/g);
          const id = idStr != null ? idStr.join('') : null;
          if (id && colIndex < self.contest.draftRoster.rounds) {
            const player = {
              id: id,
              draftPos: self.contest.exportPositions[colIndex],
            };

            players.push(player);
          }
        });

        // If players are found in the row, create a lineup object to be validated
        if (players.length > 0) {
          const lineup = {
            row: rowIndex + 1,
            players: players,
          };

          importedTeams.push(lineup);
        }
      });

      // Validate the lineups found in the csv file imported
      ObSalaryCapApi.validateLineups(this.contest.id, importedTeams)
          .then((response) => {
            const lineups = response;
            this.loading = false;
            let containsError = false;

            // Add submit flag to each lineup validated and
            // check if there is an error in any of the lineups
            for (const lineup of lineups) {
              lineup.submit = false;

              if (!lineup.isValid) {
                containsError = true;
              }
            }

            EventBus.$emit('CSV_LINEUPS_IMPORTED', lineups, containsError);
          }).catch((e) => {
            this.loading = false;
            if (e.response && e.response.data) {
              this.csvErrorMessage = e.response.data;
            } else {
              this.csvErrorMessage = 'Could not validate lineups';
            }
          });
    },

    resetData() {
      this.csvErrorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>

  .exportPlayerSlate {
    color: var(--obcolor-ob-blue);
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  input[type="file"] {
    display: none;
  }

  .customFileUpload {
    border: 2px dashed #404040;
    gap: 20px;
    margin-left: 70px;
    padding: 20px 0px;
    width: 640px;
    height: 180px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
  }

  .fileIcon {
    margin-bottom: 10px;
    font-size: 35px;
  }

  .uploadIcon {
    margin-right: 10px;
  }

  .inlineCenter {
    display: flex;
    margin-bottom: 20px;
  }

  .importInstructionContainer {
    margin-top: 50px;
    margin-left: 80px;
    font-size: 12px;
  }

  .importInstructionHeader {
    font-weight: bold;
    font-size: 18px;
  }

  .importInstruction {
    margin-top: 10px;
    margin-left: 10px;
  }

  .fileUploadText {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .csvErrorText {
    margin-top: 10px;
    margin-left: 10px;
    color: var(--obcolor-red);
  }

  .loadingIcon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .importImageContainer {
    margin-top: 30px;
    margin-right: 80px;
  }

  .importImage {
    height: auto;
    width: 225px;
  }

  .importLineupsComtainer {
    height: 460px;
    width: 789px
  }
</style>