<template>
  <div class="container" :style="getSize()">
    <div class="ob-image-circle" :style="showLogo ? 'background: var(--obcolor-background-2);' : getTeamColor()">
      <img v-if="!id" :src="require('@/assets/noheadshot.png')" alt="Default headshot">
      <img v-else-if="lazyLoad" v-lazy="getHeadshotUrl()" alt="Default headshot">
      <img v-else :src="getHeadshotUrl()" alt="Headshot"
        @error="$event.target.src = require('@/assets/noheadshot.png')"
      >
    </div>
    <div v-if="pos" class="posCircle" :style="'right: ' + ((size || 5) / 8 - 10).toFixed(0) + 'px;'+ getPosSize()"
      :class="getColour()"
    >
      <div style="padding-top: 2px;">{{ pos }}</div>
    </div>
    <div v-if="!pos && team && showLogo" class="posCircle" :style="'right: ' + ((size || 5) / 8 - 8).toFixed(0) + 'px;' + getTeamColor2()
      + getPosSize()
    "
      style="overflow: hidden; border: none;"
    >
      <img style="width: 100%;" :src="getTeamLogo()" :alt="team">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    sport: String,
    pos: String,
    team: String,
    size: String,
    showLogo: {type: Boolean, default: false},
    lazyLoad: {type: Boolean, default: false},
  },
  methods: {
    getTeamLogo() {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ this.team.toLowerCase() +'.png');
    },

    getHeadshotUrl() {
      return 'https://storage.googleapis.com/obimages/hs/'+ this.sport.toLowerCase() +'/' + this.id + '.png';
    },
    getSize() {
      if (this.size == null) {
        return 'width: 60px; height: 60px; min-width: 60px; min-height: 60px;';
      }
      return 'width: ' + this.size + 'px; height: ' + this.size + 'px;' + 'min-width: ' + this.size + 'px; min-height: ' + this.size + 'px;';
    },

    getPosSize() {
      if (this.size == null || Number(this.size) >= 60) {
        return 'width: 23px; height: 23px; min-width: 23px; min-height: 23px;';
      }
      const posSize = this.size * 0.4;
      return 'width: ' + posSize + 'px; height: ' + posSize + 'px;' + 'min-width: ' + posSize + 'px; min-height: ' + posSize + 'px;';
    },

    getTeamColor() {
      if (this.team != null) {
        return 'background: ' + this.$TeamInfo.getTeamColor(this.team, this.sport) + ';';
      }
      return '';
    },
    getTeamColor2() {
      if (this.team != null) {
        return 'background: ' + this.$TeamInfo.getTeamColor(this.team, this.sport) + ';';
      }
      return '';
    },
    getColour() {
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}
.ob-image-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ade3fa;
  display: inline-block;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.posCircle {
  background: black;
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid black;
  color: white;
  font-weight: bold;
  font-size: 13px;
  bottom: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>