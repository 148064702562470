<template>
  <div class="levelProgressContainer">
    <div class="nextRewardText">Next Reward</div>
    <LevelProgressGraph class="levelProgressGraph" :userInfo="userInfo" />
    <div v-if="!isMaxLevel" class="textHeader">{{ currLevelExp }}/{{ nextLevelExp }} XP</div>
    <div v-else class="textHeader">Max Level</div>
  </div>
</template>

<script>
import LevelProgressGraph from '@/components/battlepass/LevelProgressGraph';
import {currLevelExp, nextLevelExp, isMaxLevel} from '@/utils/shared/BattlePassUtils';

export default {
  props: {
    userInfo: {type: Object, default: null},
  },

  computed: {
    currLevelExp() {
      return currLevelExp(this.userInfo);
    },

    nextLevelExp() {
      return nextLevelExp(this.userInfo);
    },

    isMaxLevel() {
      return isMaxLevel(this.userInfo);
    },
  },

  components: {
    LevelProgressGraph,
  },
};
</script>

<style lang="scss" scoped>
.levelProgressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.levelProgressGraph {
  margin-bottom: -6px;
}

.textHeader {
  height: 10px;
  padding-bottom: 5px;
}

.nextRewardText {
  height: 10px;
}
</style>