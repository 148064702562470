<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; padding: 0; overflow: hidden; border-radius: 0 0 10px 10px; border: none;"
    bodyStyle="margin: 0; padding: 15px; background: var(--obcolor-modal2-bg);"
    :disableClickaway="true"
  >
    <div slot="body" class="body">
      <img class="locationIcon" :src="require('@/assets/icons/location-error-' + theme + '.png')" alt="Location Error">
      <div class="title">{{ title }}</div>
      <div class="text">{{ text }}</div>
      <ul class="errorList">
        <li v-for="(error, index) in errors" :key="'errorMsg-'+index">{{ error }}</li>
      </ul>
      <div>
        <a target="_blank" href="https://support.ownersbox.com/hc/en-us/requests/new" rel="noopener">
          <button class="ob-btn">Contact Support</button>
        </a>
      </div>
      <div>
        <router-link to="/start-playing">
          <button class="ob-btn-2">View Supported Locations</button>
        </router-link>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

// Default text to show if the server response does not include these values
const DEFAULT_TITLE = 'Unsupported Location';
const DEFAULT_TEXT = 'Your current location does not support what you are trying to do. We are so sorry about this!';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      title: DEFAULT_TITLE,
      text: DEFAULT_TEXT,
      errors: [],
    };
  },

  computed: {
    ...mapState(['theme']),
  },

  created() {
    EventBus.$on('SHOW_LOCATION_ERROR_MODAL', this.openModal);
    EventBus.$on('ROUTE_CHANGED', this.closeModal);
  },

  destroyed() {
    EventBus.$off('SHOW_LOCATION_ERROR_MODAL', this.openModal);
    EventBus.$off('ROUTE_CHANGED', this.closeModal);
  },

  methods: {
    applyText(locationError) {
      if (!locationError) {
        this.title = DEFAULT_TITLE;
        this.text = DEFAULT_TEXT;
        this.errors = [];
        return;
      }
      this.title = locationError?.title || DEFAULT_TITLE;
      this.text = locationError?.text || DEFAULT_TEXT;
      this.errors = locationError?.errors || [];
    },

    openModal(locationError) {
      this.applyText(locationError);
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .closeBtn {
    color: var(--obcolor-modal2-font);
  }
}

.locationIcon {
  height: 160px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.body {
  text-align: center;
  color: var(--obcolor-modal2-font);
  font-family: 'Archivo', sans-serif;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: var(--obcolor-font-primary);
  margin-bottom: 5px;
}

.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.errorList {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 20px;
  li {
    padding-top: 8px;
    line-height: 20px;
  }
}

.ob-btn, .ob-btn-2 {
  width: 100%;
  height: 40px;
}

.ob-btn-2 {
  margin-top: 15px;
}
</style>