<template>
  <!-- Twitter Updates -->
  <div v-if="entry.tweet" class="newsEntry">
    <div v-if="contestIds" class="myPlayerIndicator"></div>
    <div style="display: flex;">
      <PlayerHeadshot v-if="entry.player" size="50" :id="entry.player.id"
        :sport="entry.sport" :team="entry.player.team"
        :showLogo="true" :lazyLoad="true"
      />
      <div style="margin-left: 15px; flex: 1;">
        <div class="playerName">
          <span class="ob-link" @click.stop="openPlayerModal()">
            {{ entry.player && entry.player.name ? entry.player.name.shortName() : '-' }}
          </span>
        </div>
        <div v-if="entry.player" class="playerInfo">
          <span v-if="entry.player.number" style="margin-right: 5px;">#{{ entry.player.number }}</span>
          <span>{{ getPosition() }}</span>
        </div>
      </div>
      <div class="newsDate">{{ getTime() }}</div>
    </div>
    <div style="font-size: 13px; margin-top: 10px;">{{ entry.tweet.text }}</div>
    <div style="font-size: 13px; margin-top: 8px; color: var(--obcolor-font-secondary);">
      {{ entry.news.source }}
      @{{ entry.tweet.userHandle }}
    </div>
  </div>

  <!-- News -->
  <div v-else class="newsEntry" :class="entry.news.impact ? 'expandable' : ''"
    @click="expanded = !expanded"
  >
    <div v-if="contestIds" class="myPlayerIndicator"></div>
    <div style="display: flex;">
      <PlayerHeadshot v-if="entry.player" size="50" :id="entry.player.id"
        :sport="entry.sport" :team="entry.player.team"
        :showLogo="true" :lazyLoad="true"
      />
      <div style="margin-left: 15px; flex: 1;">
        <div class="playerName">
          <span class="ob-link" @click.stop="openPlayerModal()">
            {{ entry.player && entry.player.name ? entry.player.name.shortName() : '-' }}
          </span>
          <i v-if="entry.player.heat === 'Cold'" style="color: var(--obcolor-player-cold); font-size: 13px; margin-top: 2px; margin-left: 6px;" class="fas fa-icicles"></i>
          <i v-else-if="entry.player.heat === 'Hot'" style="color: var(--obcolor-player-hot); font-size: 13px; margin-top: 2px; margin-left: 6px;" class="fab fa-hotjar"></i>
        </div>
        <div v-if="entry.player" class="playerInfo">
          <span v-if="entry.player.number" style="margin-right: 5px;">#{{ entry.player.number }}</span>
          <span>{{ getPosition() }}</span>
        </div>
      </div>
      <div class="newsDate">{{ getTime() }}</div>
    </div>

    <div style="display: flex; align-items: center;">
      <!-- Game Updates - FPTS Earned -->
      <div v-if="entry.news.type === 'Game Update'" class="fptsContainer">
        <template v-if="entry.extraInfo && entry.extraInfo.fptsEarned">
          <div v-for="stat in entry.extraInfo.fptsEarned" :key="stat.name" style="margin-top: 6px;">
            <span style="margin-right: 8px;">{{ stat.name }}</span>
            <span v-if="stat.value >= 0" class="fptsEarned">+{{ stat.value }}</span>
            <span v-else class="fptsEarned" style="color: red;">{{ stat.value }}</span>
          </div>
        </template>
      </div>

      <!-- Standard News -->
      <div v-else style="flex: 1; font-size: 13px;">
        <div style="margin-top: 10px;">{{ entry.news.headline }}</div>
        <div style="margin-top: 8px;">{{ entry.news.details }}</div>
      </div>

      <!-- Game State, only for Game Updates -->
      <div v-if="entry.extraInfo && entry.extraInfo.game" class="gameStateContainer">
        <div v-if="entry.extraInfo.fpts" style="font-weight: bold; color: var(--obcolor-accent); margin-bottom: 4px;">
          <span :style="entry.extraInfo.fpts < 0 ? 'color: red;' : ''">{{ entry.extraInfo.fpts }} PTS</span>
        </div>
        <div class="gameStateBox">
          <div style="display: flex; align-items: center; justify-content: center;">
            <span>{{ entry.extraInfo.game.home ? 'vs' : '@' }}</span>
            <img v-if="entry.extraInfo.game.opp" class="gameStateLogo" :src="getTeamLogo(entry.extraInfo.game.opp)"
              :alt="entry.extraInfo.game.opp"
            >
            <span>{{ entry.extraInfo.game.score }}</span>
          </div>
          <div v-if="entry.extraInfo.game.gametime !== ''" style="margin-top: 4px;">{{ entry.extraInfo.game.gametime }}</div>
        </div>
      </div>
    </div>

    <div v-if="expanded && entry.news.impact" style="font-size: 13px; margin-top: 8px;">{{ entry.news.impact }}</div>

    <div v-if="entry.news.type !== 'Game Update'" class="newsSource">{{ entry.news.source }}</div>

    <div v-if="contestIds" class="contestIdContainer">
      <div>Contest:</div>
      <div v-for="(cid, index) in limitContestId()" :key="entry.id +'_'+ cid +'_'+ index" style="margin-left: 4px;"
        @mouseover="highlight(cid)" @mouseout="removeHighlight()"
      >
        <router-link class="ob-link" :to="getContestLink(cid)" style="color: var(--obcolor-font-secondary);">
          {{ formatContestId(cid, index) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import EventBus from '@/event-bus';

export default {

  props: {
    entry: Object,
    contestIds: Array,
  },

  data: function() {
    return {
      expanded: false,
    };
  },

  components: {
    PlayerHeadshot,
  },

  methods: {
    getPosition() {
      return this.$SportInfo.getPositionName(this.entry.player.position, this.entry.sport);
    },

    getTeamLogo(team) {
      return require('@/assets/logos/'+ this.entry.sport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },

    getTime() {
      // Show time only if posted today, otherwise show date only
      const now = this.$moment().format('MMM D YYYY');
      const newsDate = this.$moment(this.entry.news.date);
      if (now === newsDate.format('MMM D YYYY')) {
        return newsDate.format('h:mma');
      }
      return newsDate.format('MMM D');
    },

    limitContestId() {
      return this.contestIds.slice(0, 5);
    },

    highlight(id) {
      if (id.substring(0, 1) === 'S') {
        const teamIdStart = id.indexOf('/');
        if (teamIdStart !== -1) {
          id = id.substring(0, teamIdStart);
        }
      }
      EventBus.$emit('HIGHLIGHT_LEAGUE', id);
    },

    removeHighlight() {
      EventBus.$emit('HIGHLIGHT_LEAGUE', null);
    },

    getContestLink(cid) {
      const firstLetter = cid.substring(0, 1);
      if (firstLetter === 'S') {
        // S indicates salary cap contest
        return '/salarycontest/leaderboard/' + cid;
      } else if (firstLetter === 'T') {
        // T indicates lightning lineup contest
        return '/lightning-lineups/live/' + cid;
      }
      // Head to Head contest
      return '/contest/matchup/summary/' + cid;
    },

    formatContestId(cid, index) {
      let id = cid;

      if (cid.substring(0, 1) === 'S') {
        // S indicates salary cap contest
        // Dont display the full team id, just the team number
        const teamIdStart = cid.indexOf('/');
        const teamNumStart = cid.indexOf('_');
        if (teamIdStart !== -1) {
          id = cid.substring(0, 5);
        }
        if (teamNumStart !== -1) {
          const teamNum = cid.substring(teamNumStart + 1);
          if (teamNum !== '1') {
            id += ' (' + teamNum + ')';
          }
        }
      } else {
        id = cid.substring(0, 5);
      }

      // Add comma if not the last entry
      if (index !== this.limitContestId().length - 1) {
        id += ', ';
      }
      return id;
    },

    openPlayerModal() {
      const id = this.entry.player.id;
      const sport = this.entry.sport;
      EventBus.$emit('OPEN_PLAYER_CARD', id, sport);
    },
  },

};
</script>

<style lang="scss" scoped>
.newsEntry {
  margin-bottom: 5px;
  padding: 10px;
  background: var(--obcolor-background-6);
  position: relative;
  overflow: hidden;
}

.newsSource {
  font-size: 13px;
  margin-top: 8px;
  color: var(--obcolor-font-secondary);
}

.expandable {
  cursor: pointer;
}

.expandable:hover {
  background: var(--obcolor-background-4);
}

.playerName {
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
}

.playerInfo {
  font-size: 13px;
  color: var(--obcolor-font-secondary);
}

.myPlayerIndicator {
  $size: 24px;
  height: $size;
  width: $size;
  background: var(--obcolor-sportscolour-nba);
  transform: rotate(45deg);
  position: absolute;
  top: -($size/2);
  left: -($size/2);
}

.newsDate {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.fptsContainer {
  flex: 1;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 5px;
}
.fptsEarned {
  background: var(--obcolor-background-2);
  font-size: 12px;
  border-radius: 4px;
  padding: 1px 6px;
  font-weight: bold;
}

.gameStateContainer {
  font-size: 12px;
  text-align: center;
  margin-top: -8px;
}
.gameStateBox {
  background: var(--obcolor-background-2);
  padding: 5px;
  border-radius: 6px;
  min-width: 70px;
}
.gameStateLogo {
  width: 20px;
  margin-left: 3px;
  margin-right: 5px;
}

.contestIdContainer {
  font-size: 13px;
  margin-top: 5px;
  color: var(--obcolor-font-secondary);
  display: flex;
  flex-wrap: wrap;
}
</style>
