import Api from '@/api/ObBaseApi';

export default {

  getPlayerList(sport) {
    return Api()
        .get('/fsp/player/getPlayerList?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  getPlayerSwapList(leagueId) {
    return Api()
        .get('/fsp/player/getPlayerSwapList?league=' + leagueId)
        .then((response) => {
          return response.data;
        });
  },

  getPlayerSwapList2(leagueId) {
    return Api()
        .get('/fsp/player/getPlayerSwapList2?league=' + leagueId)
        .then((response) => {
          return response.data;
        });
  },

  getSalaryCapPlayerList(leagueId, teamId) {
    return Api()
        .get('/fsp/player/getSalaryCapPlayerList?league=' + leagueId + '&team=' + teamId)
        .then((response) => {
          return response.data;
        });
  },

  getGlobalStatusMap(sport) {
    return Api()
        .get('/fsp/player/getGlobalStatusMap?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  getPlayerSchedule(playerId, leagueId, salaryCapContest) {
    let params = '?player=' + playerId;
    if (leagueId) {
      params += '&league=' + leagueId;
    }
    const url = salaryCapContest ? '/fsp/player/getScPlayerSchedule' : '/fsp/player/getPlayerSchedule';
    return Api()
        .get(url + params)
        .then((response) => {
          return response.data;
        });
  },

};