<template>
  <div class="tooltipContainer">
    <div class="tooltipTrigger" ref="trigger" @mouseover="setPos()">
      <slot name="trigger"></slot>
    </div>
    <div class="tooltip" :style="tooltipStyle"
      :class="{ tooltipBottom: pos == 'bottom', tooltipTop: pos == 'top' }"
    >
      <div class="tooltipArrow"></div>
      <div class="tooltipContent" ref="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pos: String,
  },

  data() {
    return {
      tooltipStyle: null,
    };
  },

  methods: {
    setPos() {
      let triggerRect = null;
      let contentRect = null;

      if (this.$refs && this.$refs.trigger && this.$refs.content) {
        triggerRect = this.$refs.trigger.getBoundingClientRect();
        contentRect = this.$refs.content.getBoundingClientRect();
      } else {
        return;
      }

      if (triggerRect == null || contentRect == null) {
        return;
      }

      this.$nextTick(() => {
        let tooltipStyle = '';
        if (triggerRect.height != null) {
          if (this.pos == 'bottom') {
            tooltipStyle += 'top: ' + (triggerRect.bottom) + 'px;';
          } else {
            tooltipStyle += 'top: ' + (triggerRect.top - contentRect.height - triggerRect.height) + 'px;';
          }
        }
        if (triggerRect.width != null && contentRect.width != null) {
          tooltipStyle += ' left: ' + (triggerRect.left - (contentRect.width/2) + (triggerRect.width/2)) + 'px;';
        }

        this.tooltipStyle = tooltipStyle;
      });
    },

    getTooltipStyle() {

    },
  },
};
</script>

<style lang="scss" scoped>
  .tooltipTrigger {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .tooltipContainer {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .tooltipContainer:hover .tooltip {
    display: flex;
  }

  .tooltip {
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    background: rgb(12, 62, 104);
    color: white;
    border-radius: 10px;
    padding: 10px;
    max-width: 190px;
    margin-left: -10px;
    width: max-content;
    height: max-content;
    line-height: 1.2;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    z-index: 9999;
  }

  .tooltipBottom {
    margin-top: 10px;
  }

  .tooltipTop {
    margin-top: -15px;
  }

  .tooltipArrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  .tooltipTop .tooltipArrow {
    border-top: 10px solid rgb(12, 62, 104);
    bottom: -8px;
  }

  .tooltipBottom .tooltipArrow {
    border-bottom: 10px solid rgb(12, 62, 104);
    top: -8px;
  }
</style>