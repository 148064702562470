<template>
  <div style="max-width: 100%; padding: 0 20px;">
    <div class="selectTeamButtons">
      <div :class="{ selected: selectedTeam == 'away' }"
        style="border-radius: 5px 0 0 5px;"
        class="teamButton"
        @click="selectTeam(false)"
      >
        <img class="teamImg" :src="getTeamLogo('visitor_team')" alt="Team Logo">
        {{ getTeamAlias(matchupData.visitor_team) }} Stats
      </div>
      <div :class="{ selected: selectedTeam == 'home' }"
        style="border-radius: 0 5px 5px 0;"
        class="teamButton"
        @click="selectTeam(true)"
      >
        <img class="teamImg" :src="getTeamLogo('home_team')" alt="Team Logo">
        {{ getTeamAlias(matchupData.home_team) }} Stats
      </div>
    </div>
    <table class="posTable"
      v-for="(positionData, index1) in allScoringStats"
      :key="'table_' + index1" aria-label="Game Box Score"
    >
      <tr class="statHeader" v-if="positionData.liveStats.length > 1">
        <th class="endSection" colspan="2" scope="col"></th>
        <!-- Headers for stat sections (eg. 'Rushing', 'Passing'...) -->
        <th class="endSection catHeader" v-for="(category, index2) in positionData.liveStats" :key="index2"
          :colspan="category.stats.length" scope="col"
        >
          {{ category.label }}
        </th>
      </tr>
      <tr class="statHeader" :key="'subheading_' + index1">
        <!-- Position name (eg. 'Defense', 'Quarterback'...) -->
        <th style="width: 225px; padding-left: 0; text-align: left;" scope="row">{{ positionData.label }}</th>
        <th class="endSection fptsCol"
          v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat' }" scope="row"
        >
          FPTS
        </th>
        <!-- Headings for each stat specific to this position -->
        <!-- Tooltip contains longer name for stat -->
        <template v-for="(category, index2) in positionData.liveStats">
          <th v-for="(stat, index3) in category.stats" :key="index2 + '_' + index3"
            v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltip' }"
            :class="{ endSection: index3 == category.stats.length - 1 }"
          >
            {{ stat.label }}
          </th>
        </template>
      </tr>

      <!-- Rows containing player data -->
      <template v-if="sectionHasPlayers(positionData)">
        <tr v-for="(player, index2) in playersByPosition[positionData.label]" :key="'playerRow_' + index1 + '_' + index2">
          <td>
            <div class="playerInfoField">
              <div v-if="player.isMyPlayer" class="myPlayerIcon"></div>
              <div class="posCol">{{ player.position }}</div>
              <PlayerHeadshot size="35" :id="player.id" :sport="sport"
                :team="player.team"
              />
              <div class="playerName ob-link" @click.stop="openPlayerCard(player.id)">{{ player.name.shortName() }}</div>
            </div>
          </td>
          <!-- Player fantasy points, and then the rest of the player's stats -->
          <td class="endSection fptsField" :class="{ negativePts: player.stats && player.stats.fpts && player.stats.fpts.total < 0 }">
            {{ getPlayerFpts(player) }}
          </td>
          <template v-for="(category, index1) in positionData.liveStats">
            <template v-for="(stat, index2) in category.stats">
              <td :key="index1 + '_' + index2" v-if="getStat(player, stat.path) != '-'"
                :class="{endSection: index2 == category.stats.length - 1}"
              >
                <span>{{ getStat(player, stat.path) }}</span>
              </td>
              <td v-else :key="index1 + '_' + index2">-</td>
            </template>
          </template>
        </tr>
      </template>
      <tr v-else><td colspan="99" class="noPlayers">No Players</td></tr>
    </table>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
  },

  props: {
    gameData: Object,
    sport: String,
    selectedTeam: String,
  },

  computed: {
    allScoringStats() {
      if (!this.sport) {
        return null;
      }

      return this.$ScoringStats.getBoxScoreStats(this.sport);
    },

    matchupData() {
      return this.gameData?.matchup;
    },

    playersByPosition() {
      const teamKey = this.selectedTeam == 'home' ? 'hometeam' : 'awayteam';

      if (this.allScoringStats == null || this.gameData == null ||
        this.gameData.boxscore == null || this.gameData.boxscore[teamKey] == null
      ) {
        return null;
      }

      const playersByPosition = {};

      // Make an object for player arrays by position
      for (const stat of this.allScoringStats) {
        this.$set(playersByPosition, stat.label, []);

        // Add each player to their respective lists
        for (const player of this.gameData.boxscore[teamKey]) {
          if (stat.isPosition(player.position)) {
            playersByPosition[stat.label].push(player);
          }
        }

        // Sort player lists by fantasy points
        playersByPosition[stat.label] = playersByPosition[stat.label].sort((p1, p2) => {
          const p1HasStats = p1 && p1.stats && p1.stats.fpts && p1.stats.fpts.total != null;
          const p2HasStats = p2 && p2.stats && p2.stats.fpts && p2.stats.fpts.total != null;

          if (!p1HasStats) {
            return 1;
          } else if (!p2HasStats) {
            return -1;
          }

          const p1Fpts = parseFloat(p1.stats.fpts.total);
          const p2Fpts = parseFloat(p2.stats.fpts.total);

          return p2Fpts - p1Fpts;
        });
      }
      return playersByPosition;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    selectTeam(isHome) {
      EventBus.$emit('GAME_MODAL_SELECT_TEAM', isHome ? 'home' : 'away');
    },

    getTeamAlias(team) {
      return this.$TeamInfo.getDisplayAlias(this.sport, team.team_alias);
    },

    getTeamLogo(awayHome) {
      const teamName = this.matchupData[awayHome].team_alias.toLowerCase();
      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ teamName +'.png');
    },

    // Check if a given player list is empty
    sectionHasPlayers(positionData) {
      const pList = this.playersByPosition;
      return pList && positionData && pList[positionData.label] && pList[positionData.label].length > 0;
    },

    // Safely find a player stat and return in a printable format. Useful for failing gracefully when stats aren't found.
    getStat(player, statPath) {
      if (!player || !player.stats || !statPath) {
        return '-';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!player.stats[playerRole]) {
        return '0';
      }
      const stat = player.stats[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    // Get printable value for player points, adding a '+' sign when positive
    getPlayerFpts(player) {
      if (!player || !player.stats) {
        return '-';
      }

      if (!player.stats.fpts || !player.stats.fpts.total) {
        return '0';
      }

      let numPrefix = '';

      if (parseInt(player.stats.fpts.total) > 0) {
        numPrefix = '+';
      }

      return numPrefix + player.stats.fpts.total;
    },

    // Check if the player's "time played" stat is > 0
    // Checks a different stat & format per sport, which is why a function is useful
    playedGame(player) {
      let timeVariable = '';
      switch (this.sport) {
        case 'NHL':
          timeVariable = 'TOI';
          break;
        case 'NBA':
          timeVariable = 'MIN';
          break;
        case 'NFL': case 'MLB':
          return player.stats != null;
        default:
          break;
      }
      for (const pIndex in player.stats) {
        const pointsObject = player.stats[pIndex];
        if (typeof pointsObject === 'object') {
          const gameTime = pointsObject[timeVariable];
          if (gameTime != null && gameTime != '-' && gameTime != '' && gameTime != '00:00' && gameTime != '0:00') {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.selectTeamButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.teamButton {
  cursor: pointer;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--obcolor-background-7);
}
.teamButton:hover, .teamButton.selected {
  background: var(--obcolor-background-2);
}
.teamImg {
  height: 30px;
  margin-right: 5px;
  opacity: 0.8;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}
.posTable {
  border-radius: 5px;
  min-width: calc(100% - 1px);
  overflow: hidden;
  margin-bottom: 20px;
}

.catHeader {
  padding-top: 10px;
  padding-bottom: 0px;
  font-size: 12px !important;
}

th {
  color: var(--obcolor-font-secondary);
  border-bottom: 2px solid var(--obcolor-background-6);
  padding: 5px;
  min-width: 10px;
}

td {
  color: var(--obcolor-font-primary);
}

th:not(.leftInfo), td:not(.leftInfo) {
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  min-width: 40px;
}

.posCol {
  width: 30px;
  text-align: left;
}

.fptsCol {
  width: 30px;
}

.fptsField {
  font-weight: bold;
  color: var(--obcolor-ob-blue);
}

.fptsField.negativePts {
  color: var(--obcolor-red);
}

.noPlayers {
  padding: 3px 5px;
  color: var(--obcolor-font-secondary);
}

.playerInfoField {
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  box-sizing: border-box;
  overflow: hidden;

  .myPlayerIcon {
    $iconSize: 18px;
    width: $iconSize;
    height: $iconSize;
    position: absolute;
    top: calc(-#{$iconSize} / 2);
    left: calc(-#{$iconSize} / 2);
    transform: rotate(45deg);
    background: var(--obcolor-sportscolour-nba);
  }
}

.playerName {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

</style>