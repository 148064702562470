<template>
  <div class="ob-page">
    <div class="ob-box waitlistContainer" style="min-width: 200px;">
      <img class="bgPlayer1" :src="require('@/assets/graphics/obfootball-half2.png')" alt="Football player background">
      <img class="bgPlayer2" :src="require('@/assets/graphics/obbaseball-half2.png')" alt="Baseball player background">

      <!-- Logo -->
      <img v-if="theme === 'light'" class="mainLogo" src="@/assets/logo_black.png"
        alt="OwnersBox Logo"
      >
      <img v-else class="mainLogo" src="@/assets/logo_white.png"
        alt="Ownersbox Logo"
      >

      <div class="wlHeader">Closed Beta Waitlist</div>
      <div class="infoText ob-box" style="margin-top: 10px;">
        Thank you for registering for the OwnersBox Beta test.
        You’ve taken the first step towards a new and improved Fantasy Sports experience!
        We will be letting users into the closed beta in stages starting July 31st. MLB, NBA, and NHL contests will be available during the beta.
        You are currently on our waiting list and we will send you an email when you have been selected to participate.
        You can follow the steps below to increase your chances of being activated.
      </div>

      <!-- Steps -->
      <div class="stepsContainer" style="margin-top: 20px;">
        <div class="stepsBox">
          <div class="stepsHeader">1. Refer your friends</div>
          <div class="stepsInfo">
            It’s always more fun to play with friends, share your referral information so that you can experience our platform together!
          </div>
        </div>
        <div class="stepsBox" style="margin-left: 10px; margin-right: 10px;">
          <div class="stepsHeader">2. Increase your chances</div>
          <div class="stepsInfo">
            The more people you refer, the chances of you getting activated will increase. So spread the news using the referral buttons below!
          </div>
        </div>
        <div class="stepsBox">
          <div class="stepsHeader">3. Wait for activation</div>
          <div class="stepsInfo">
            If you are selected, you will receive an activation email with additional details about our game and how to install our app if applicable.
          </div>
        </div>
      </div>

      <!-- Referrals -->
      <div style="margin-top: 30px;">
        <div class="headerOption">
          <div>Referral Link</div>
          <input readonly type="text" :value="referralInfo.referralUrl"
            placeholder="Referral Link"
          >
          <button class="ob-btn" @click="copyToClipboard(referralInfo.referralUrl, 'link')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
          <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyLinkMessage }"></div>
        </div>
        <div class="headerOption">
          <div>Referral Code</div>
          <input readonly type="text" :value="referralInfo.referralCode"
            placeholder="Referral Code"
          >
          <button class="ob-btn" @click="copyToClipboard(referralInfo.referralCode, 'code')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
          <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyCodeMessage }"></div>
        </div>
      </div>

      <div class="ob-btn logoutBtn" style="padding: 10px 40px; margin-top: 20px; margin-bottom: 10px;" @click="logout()">Log Out</div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import {mapState} from 'vuex';

export default {

  data() {
    return {
      referralInfo: {},
      referralEmails: '',
      referralMessage: null,
      showCopyLinkMessage: false,
      showCopyCodeMessage: false,

      sendingReferralEmails: false,
      copyLinkTimeout: null,
      copyCodeTimeout: null,
      referralMessageTimeout: null,
    };
  },

  computed: {
    ...mapState(['theme']),
  },

  created() {
    this.loadMyReferrals();
  },

  methods: {
    loadMyReferrals() {
      ObAccountApi.getReferralProfile()
          .then((data) => {
            this.referralInfo = data;
          });
    },

    logout() {
      ObAccountApi.logoutUser()
          .then((data) => {
            this.$cookie.delete('obauth');
            this.$store.commit('setLoggedOut');
            // Reset chat data when logged out
            this.$cookie.delete('expanded-open-convo-map');
            if (this.$router.currentRoute.path != '/login') {
              this.$router.push('/login');
              this.$SocketController.disconnect();
            }
          });
    },

    copyToClipboard(copyText, copyType) {
      navigator.clipboard.writeText(copyText).then(() => {
        /* clipboard successfully set */
        if (copyType == 'link') {
          this.showCopyLinkMessage = true;
        } else if (copyType == 'code') {
          this.showCopyCodeMessage = true;
        }
      }).catch((e) => {
        /* clipboard write failed */
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.waitlistContainer {
  min-height: 800px;
  overflow: hidden;
  margin: auto;
  text-align: center;
  position: relative;
}
.mainLogo {
  margin-top: 20px;
  margin-bottom: 15px;
  z-index: 70;
  position: relative;
  max-width: 100%;
}
.wlHeader {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}
.bgPlayer1 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.bgPlayer2 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.infoText {
  padding: 10px;
  max-width: 850px;
  margin: auto;
  text-align: left;
  z-index: 70;
  position: relative;
  font-size: 16px;
}
.stepsContainer {
  display: flex;
  justify-content: space-around;
  max-width: 900px;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
}
.stepsBox {
  border-radius: 10px;
  height: 225px;
  width: 225px;
  min-width: 225px;
  background: var(--obcolor-background-2);
  z-index: 70;
  position: relative;
  text-align: left;
  margin-top: 10px;
}
.stepsHeader {
  background: var(--obcolor-background-3);
  border-radius: 10px 10px 0 0;
  height: 35px;
  font-weight: bold;
  padding-left: 15px;
  display: flex;
  align-items: center;
}
.stepsInfo {
  display: flex;
  height: calc(100% - 55px);
  align-items: center;
  padding: 0 15px;
}
.logoutBtn {
  z-index: 70;
  position: relative;
}

.headerOption {
  text-align: left;
  z-index: 70;
  position: relative;
  margin: auto;
  max-width: 670px;

  div {
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    margin-bottom: 2px;
  }

  input {
    padding-left: 10px;
    height: 25px;
    width: calc(100% - 135px);
    margin-right: 10px;
    margin-bottom: 10px;
  }

  button {
    width: 110px;
  }
}

@media only screen and (max-width: 780px) {
  .ob-page {
    padding: 5px;
  }

  .bgPlayer1, .bgPlayer2, .mainLogo {
    display: none;
  }

  .stepsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .infoText {
    text-align: center;
  }
}
</style>
