<template>
  <div slot="body" class="importLineupsComtainer">
    <div class="lineupsContainer">
      <div v-if="!lineups || lineups.length == 0">
        No lineups imported
      </div>
      <table v-else aria-labelledby="importLineupTableHeader">
        <tr>
          <th></th>
          <th id="importLineupTableHeader">Lineup</th>
          <th class="lineupHeader remainingSalary">Rem Salary</th>
          <th class="lineupHeader">Proj</th>
          <th class="checkboxContainer">
            <div class="ob-checkbox-container" @click="addAllLineups()">
              <input type="checkbox" :checked="allLineupsAdded">
              <div class="ob-checkbox"></div>
            </div>
          </th>
        </tr>
        <ImportCSVEntry v-for="lineup in lineups" :key="lineup.row" :lineup="lineup"
          :contest="contest" :playerMap="playerMap" :submit="lineup.submit"
        />
      </table>
    </div>
  </div>
</template>

<script>
import ImportCSVEntry from './ImportCSVEntry';
import EventBus from '@/event-bus';

export default {
  components: {
    ImportCSVEntry,
  },

  props: {
    contest: {type: Object, default: null},
    playerMap: {type: Object, default: null},
    lineups: {type: Array, default: null},
    allLineupsAdded: {type: Boolean, default: false},
  },

  methods: {
    addAllLineups() {
      EventBus.$emit('ADD_ALL_LINEUPS_TO_SUBMIT');
    },
  },
};
</script>

<style lang="scss" scoped>

  table {
    width: 720px;
    font-size: 14px;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px;
  }

  tr:not(:nth-child(1)) {
    border-top: 1px solid var(--obcolor-background-2);
  }

  th {
    font-weight: normal;
    font-size: 14px;
    line-height: 250%;
    color: var(--obcolor-text-primary);
    opacity: 0.5;
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    min-width: 40px;
    text-align: left;
  }

  td {
    padding: 8px 4px 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  .lineupsContainer {
    padding: 10px 40px;
    height: 400px;
  }

  input[type="file"] {
    display: none;
  }

  .lineupHeader {
    text-align: center;
  }

  .importLineupsComtainer {
    height: 460px;
    width: 789px
  }

  .remainingSalary {
    min-width: 72px;
  }

  .checkboxContainer {
    padding-left: 15px;
  }
</style>