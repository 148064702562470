<template>
  <div style="min-width: 715px;">
    <!-- Day Selection -->
    <template v-if="leagueData.format != 'DAILY'">
      <div class="daySelectContainer">
        <div v-for="(date, index) in leagueData.days" :key="date" class="daySelection"
          :style="computedGameIndex == index ? 'background: var(--obcolor-background-3); border-bottom: 1px solid var(--obcolor-background-3);' : ''"
          @click="gameIndex = index"
        >
          <div :style="isToday(date) ? 'color: var(--obcolor-ob-blue); font-weight: bold;' : ''">
            <div>{{ [date, 'YYYY-MM-DD'] | moment("ddd") }}</div>
            <div>{{ [date, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
          </div>
        </div>
        <div class="daySelection" @click="gameIndex = -1"
          :style="computedGameIndex == -1 ? 'background: var(--obcolor-background-3); border-bottom: 1px solid var(--obcolor-background-3);' : ''"
        >
          <div>
            <div>Week</div>
            <div>Overview</div>
          </div>
        </div>
      </div>

      <!-- Only show for your teams, To show for others would need to get myTeams data from leaderboard for that user -->
      <SalaryWeekGraph v-if="showGraph()" :leagueData="leagueData" :leagueRoster="leagueRoster"
        :myTeams="myTeams" :teamId="teamId"
      />
    </template>

    <table v-if="oppTeamId == null" aria-label="League Information">
      <tr v-if="leagueData.format != 'DAILY'">
        <th colspan="3" style="text-align: left; padding: 8px; padding-left: 12px;" scope="col">
          <!-- Sort Selection -->
          <Dropdown :label="'Sort By:'" v-model="selectedSort" :options="['Default', 'FPTS', 'Live', 'Salary']"
            style="width: 130px;"
          />
        </th>
        <th colspan="5" scope="col"></th>
      </tr>

      <tr v-else-if="!leagueRoster" class="teamHeader">
        <th colspan="99" scope="col"><ObLoading style="margin-bottom: -25px; margin-top: -25px;" /></th>
      </tr>

      <tr v-else class="teamHeader">
        <th style="width: 50px;" scope="row"></th>
        <th style="width: 47px;" scope="row">
          <UserLogo class="logo" :id="leagueRoster.userId" :sport="leagueData.sport"
            :size="45" :showRank="true"
            :rankColor="leagueRoster.rankColor" :rankNum="leagueRoster.rankNum"
          />
        </th>
        <th scope="row">
          <div class="teamName">{{ getTeamName(leagueRoster) }}</div>
          <div class="teamSubName">{{ leagueRoster.name }}</div>
        </th>
        <th v-if="leagueData.format == 'DAILY'" class="contestHeaderField" style="width: 80px;"
          scope="row"
        >
          <div class="headerValue moneyText">
            {{ salaryRemaining }}
          </div>
          <div class="subText" style="height: 14px;">Rem Salary</div>
        </th>
        <th v-else class="contestHeaderField" style="width: 80px;"
          scope="row"
        >
          <div class="headerValue" :class="{ moneyText: leagueData.realMoney, blueText: !leagueData.realMoney }" v-if="leagueRoster && leagueRoster.prize">
            <img v-if="!leagueData.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            <img v-else class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
              alt="Money Bag"
            >
            {{ leagueRoster.prize.removeCentsIfZero() }}
          </div>
          <div class="subText" style="height: 14px;">Winning</div>
        </th>
        <th class="contestHeaderField" style="width: 70px; padding-right: 13px;" scope="row">
          <div :class="{negativeText: leagueRoster.score < 0}" class="blueText headerValue">
            {{ leagueRoster.score != null ? leagueRoster.score : '0' }}
          </div>
          <div class="subText" style="height: 14px;">Proj {{ leagueRoster.proj }}</div>
        </th>
        <!-- Allow 2 columns for daily contests since we have one extra - combine columns to allow for our headers that aren't fully aligned -->
        <th style="padding: 0; width: 0;" :colspan="leagueData.format == 'DAILY' ? 2 : 1" scope="row">
          <div v-if="leagueData.format == 'DAILY'" class="mlbHeaderInfo">
            <div>
              <PieChart class="gameOppsPie" size="20" :slices="getPieSlices()" />
              <div class="gameOpps">{{ gamesLeft }} Game{{ gamesLeft != 1 ? 's' : '' }} Left</div>
            </div>
            <div>
              <div :class="{ moneyText: leagueData.realMoney, blueText: !leagueData.realMoney }"
                class="headerValue"
              >
                <img v-if="!leagueData.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
                  alt="OwnersBucks Icon"
                >
                <img v-else class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
                  alt="Money Bag"
                >
                <span v-if="leagueRoster && leagueRoster.prize">{{ leagueRoster.prize.removeCentsIfZero() }}</span>
                <span v-else>{{ leagueData.realMoney ? '$0' : '0' }}</span>
                <!-- Ticket Prizes -->
                <template v-if="leagueRoster.splitTicketPrize || leagueRoster.ticketPrize">
                  <span style="padding: 0 3px;">+</span>
                  <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
                    v-tooltip.bottom="ticketTooltip"
                  >
                </template>
              </div>
              <div class="subText" style="height: 14px;">Winning</div>
            </div>
          </div>
        </th>
      </tr>

      <template v-if="leagueRoster">
        <template v-for="entry in sortedPlayers">
          <SalaryLivePlayerRow :key="entry.id"
            :entry="entry" :sport="leagueData.sport" :leagueId="leagueData.id"
            :league="leagueData"
            :gameIndex="computedGameIndex" :todaysGames="todaysGames" :rosterCalc="rosterCalc"
            :teamId="teamId"
            :isDropdownOpen="showStatsPlayers[entry.id]"
          />
          <SalaryDropdown v-if="showStatsPlayers[entry.id]" :key="entry.id + '_dropdown'" :player="entry"
            :todaysGames="todaysGames" :leagueData="leagueData" :gameIndex="computedGameIndex"
          />
        </template>
      </template>
    </table>

    <!-- Team comparison table -->
    <table v-else aria-label="Team Comparison">
      <SalaryLiveTeamCompareHeader :myTeam="leagueRoster" :oppTeam="oppLeagueRoster" :leagueData="leagueData" />
      <template v-for="(posInfo, index) in leagueData.draftRoster.roster">
        <template v-for="pIndex in posInfo.num">
          <SalaryLiveTeamCompareRow :key="index + '_' + pIndex"
            :leagueData="leagueData" :myPlayer="getDailyPlayerByPos(posInfo, pIndex, false)"
            :rosterCalc="rosterCalc" :dataLoaded="dataLoaded" :teamId="teamId"
            :oppPlayer="getDailyPlayerByPos(posInfo, pIndex, true)" :oppRosterCalc="oppRosterCalc"
            :oppDataLoaded="oppDataLoaded" :oppTeamId="oppTeamId"
            :todaysGames="todaysGames" :isDropdownOpen="showCompareDropdown(posInfo, pIndex, false)"
          />
          <SalaryCompareDropdown v-if="showCompareDropdown(posInfo, pIndex, false) && leagueData.format == 'DAILY'"
            :key="index + '_' + pIndex + '_dropdown_compare'" :myPlayer="getDailyPlayerByPos(posInfo, pIndex, false)"
            :oppPlayer="getDailyPlayerByPos(posInfo, pIndex, true)" :todaysGames="todaysGames" :leagueData="leagueData"
            :gameIndex="computedGameIndex"
          />
        </template>
      </template>
    </table>

    <ObLoading v-if="!leagueRoster && !dataLoaded" style="margin-top: 15px;" />
  </div>
</template>

<script>
import SalaryLivePlayerRow from './SalaryLivePlayerRow';
import SalaryLiveTeamCompareHeader from './SalaryLiveTeamCompareHeader';
import SalaryLiveTeamCompareRow from './SalaryLiveTeamCompareRow';
import SalaryDropdown from './SalaryDropdown';
import SalaryCompareDropdown from './SalaryCompareDropdown';
import SalaryWeekGraph from './SalaryWeekGraph';
import Dropdown from '@/components/Dropdown';
import ObLoading from '@/components/ObLoading';
import UserLogo from '@/components/UserLogo';
import PieChart from '@/components/charts/PieChart.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {

  components: {
    ObLoading,
    SalaryLivePlayerRow,
    SalaryLiveTeamCompareHeader,
    SalaryLiveTeamCompareRow,
    SalaryDropdown,
    SalaryCompareDropdown,
    Dropdown,
    SalaryWeekGraph,
    UserLogo,
    PieChart,
  },

  props: {
    leagueData: {type: Object, default: null},
    leagueRoster: {type: Object, default: null},
    myTeams: {type: Array, default: null},
    rosterCalc: {type: Object, default: null},
    oppRosterCalc: {type: Object, default: null},
    myUserId: {type: String, default: null},
    teamId: {type: String, default: null},
    oppTeamId: {type: String, default: null},
    oppLeagueRoster: {type: Object, default: null},
    dataLoaded: {type: Boolean, default: null},
    oppDataLoaded: {type: Boolean, default: null},
    overLimits: {type: Object, default: null},
    underLimits: {type: Object, default: null},
    customNames: {type: Object, default: null},
  },

  data() {
    return {
      selectedSort: 'Default',
      gameIndex: 0,
      showStatsPlayers: {},
    };
  },

  created() {
    this.setupGameIndex();
    const sortCookie = localStorage.getItem('salary-live-player-table-sort-v2');
    if (sortCookie != null && sortCookie != '' && this.leagueData.format != 'DAILY') {
      this.selectedSort = sortCookie;
    }

    EventBus.$on('SALARY_LIVE_TOGGLE_DROPDOWN', this.toggleDropdown);
  },

  destroyed() {
    EventBus.$off('SALARY_LIVE_TOGGLE_DROPDOWN', this.toggleDropdown);
  },

  watch: {
    selectedSort(newVal) {
      localStorage.setItem('salary-live-player-table-sort-v2', newVal);
    },
  },

  computed: {
    ...mapState(['todaysGames']),

    computedGameIndex() {
      if (this.leagueData.format == 'DAILY') {
        return -1;
      }
      return this.gameIndex;
    },

    salaryRemaining() {
      if (!this.rosterCalc) {
        return '$0';
      }
      return this.rosterCalc.salaryRemaining.formatMoney();
    },

    sortedPlayers() {
      if (!this.leagueRoster || !this.leagueRoster.players) {
        return [];
      }

      let allPlayers = [];
      Array.prototype.push.apply(allPlayers, this.leagueRoster.players);
      Array.prototype.push.apply(allPlayers, this.leagueRoster.dropped);

      // On week overview show all players, including dropped
      // When looking at a specific day, show who was on your team that day
      if (this.computedGameIndex !== -1) {
        const endOfDay = this.$moment(this.leagueData.days[this.computedGameIndex], 'YYYY-MM-DD').endOf('day').valueOf();

        allPlayers = allPlayers.filter((p) => {
          // If the player was dropped, filter out if was dropped
          if (p.dropped) {
            return p.dropped > endOfDay;
          }
          // If there is a droppedPlayer value, this was an added player
          // Filter out if he was added before the end of the selected day
          if (p.droppedPlayer) {
            return p.added < endOfDay;
          }
          return true;
        });
      }

      // No sorting for default, use the order as provided by the server
      if (this.selectedSort === 'Default') {
        return allPlayers.slice();
      }

      return allPlayers.slice().sort((p1, p2) => {
        if (this.selectedSort === 'FPTS') {
          const p1Val = this.computedGameIndex == -1 ? this.getTotalFpts(p1) : this.getPlayerFpts(p1);
          const p2Val = this.computedGameIndex == -1 ? this.getTotalFpts(p2) : this.getPlayerFpts(p2);
          if (p1Val > p2Val) {
            return -1;
          } else if (p1Val < p2Val) {
            return 1;
          }

          const p1Proj = this.getPlayerProj(p1);
          const p2Proj = this.getPlayerProj(p2);
          if (p1Proj > p2Proj) {
            return -1;
          } else if (p1Proj < p2Proj) {
            return 1;
          }
          return 0;
        }

        if (this.selectedSort === 'Live') {
          const p1Game = this.todaysGame(p1);
          const p2Game = this.todaysGame(p2);

          if (p1Game != null && p2Game == null) {
            return -1;
          } else if (p2Game != null && p1Game == null) {
            return 1;
          }

          // Only check the rest of the data if the game data exists
          if (p2Game != null && p1Game != null) {
            // Live games have priority over non-live games
            if (p1Game.live != null && p2Game.live == null) {
              return -1;
            } else if (p2Game.live != null && p1Game.live == null) {
              return 1;
            }

            if (p1Game.date_utc != null && p2Game.date_utc != null) {
              let tDiff1 = this.$moment(p1Game.date_utc);
              let tDiff2 = this.$moment(p2Game.date_utc);

              // Subtract game timestamps by current timestamp
              tDiff1 = tDiff1 - this.$moment().valueOf();
              tDiff2 = tDiff2 - this.$moment().valueOf();

              // If one game is in the future and the other isn't, show the future game
              if (tDiff2 < 0 && tDiff1 > 0) {
                return -1;
              } else if (tDiff1 < 0 && tDiff2 > 0) {
                return 1;
              } else if (tDiff1 < 0 && tDiff2 < 0) {
                // Reverse sorting if in past, so we get the most recent past game instead of the "earliest" game
                return tDiff2 - tDiff1;
              }

              // Return the earliest game in the future
              return tDiff1 - tDiff2;
            }
          }

          // Otherwise default to fpts sorting
          if (this.getPlayerFpts(p1) > this.getPlayerFpts(p2)) {
            return -1;
          } else if (this.getPlayerFpts(p1) < this.getPlayerFpts(p2)) {
            return 1;
          }

          return this.getPlayerProj(p2) - this.getPlayerProj(p1);
        }

        if (this.selectedSort == 'Salary') {
          return p2.salaryPG - p1.salaryPG;
        }

        return 0;
      });
    },

    gamesLeft() {
      if (!this.leagueRoster?.gp) {
        return this.maxGames;
      }
      return this.maxGames - this.leagueRoster.gp;
    },

    maxGames() {
      return this.leagueData?.gameLimits?.total || 1;
    },

    ticketTooltip() {
      let text = '';
      if (this.leagueRoster?.ticketPrize) {
        text = this.leagueRoster?.ticketPrize;
      } else if (this.leagueRoster?.splitTicketPrize) {
        text = this.leagueRoster?.splitTicketPrize;
      }
      return {content: text, class: 'ObTooltip'};
    },
  },

  methods: {

    getTeamName(team) {
      let teamName = this.customNames[team.teamId];
      if (!teamName) {
        teamName = team.team;
      }
      if (team.entries > 1) {
        teamName += ' (' + team.teamNum + ')';
      }
      return teamName;
    },

    showCompareDropdown(posInfo, pIndex, isOppTeam) {
      if (this.leagueData.format == 'WEEKLY') {
        return false;
      }
      const player = this.getDailyPlayerByPos(posInfo, pIndex, isOppTeam);
      if (!player) {
        return false;
      }
      return this.showStatsPlayers[player.id];
    },

    getDailyPlayerByPos(posInfo, pIndex, isOppTeam = false) {
      const playerList = isOppTeam ? this.oppLeagueRoster : this.leagueRoster;
      if (playerList == null || playerList.players == null) {
        return null;
      }
      const posPlayerList = playerList.players.filter((p) => {
        return p.draftPos == posInfo.short;
      });

      return posPlayerList[pIndex - 1] ? posPlayerList[pIndex - 1] : null;
    },

    toggleDropdown(playerId) {
      const newVal = this.showStatsPlayers[playerId] != null ? !this.showStatsPlayers[playerId] : true;
      this.$set(this.showStatsPlayers, playerId, newVal);
    },

    showGraph() {
      if (this.computedGameIndex !== -1) {
        return false;
      }
      return this.isMyTeam();
    },

    getPieSlices() {
      const team = this.leagueRoster;
      const gamesPlayed = team.gp == null ? 0 : team.gp;
      const slices = [];
      const pct = Math.round(gamesPlayed / this.maxGames * 100);

      let baseColor = 'var(--obcolor-ob-blue)';
      if (this.underLimits[team.teamId]) {
        baseColor = 'red';
      } else if (this.overLimits[team.teamId]) {
        baseColor = 'orange';
      }

      slices.push({color: 'background', percent: pct});
      slices.push({color: baseColor, percent: 100 - pct});
      return slices;
    },

    contestIsOver() {
      if (!this.leagueData) {
        return false;
      }
      return this.leagueData.state === 'COMPLETED' || this.leagueData.state === 'FINALIZED';
    },

    isMyTeam() {
      const tid = this.teamId;
      if (!tid) {
        return false;
      }
      const selectedUser = tid.substring(0, tid.indexOf('_'));
      return selectedUser === this.myUserId;
    },

    selectTab(tabName) {
      EventBus.$emit('SALARY_HEADER_SELECT_TAB', tabName);
    },

    openPlayerCard(playerId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId, this.leagueData.sport, this.leagueData.id, true);
    },

    isToday(date) {
      const now = this.$moment().format('YYYY-MM-DD');
      return now === date;
    },

    setupGameIndex() {
      if (!this.leagueData) {
        return;
      }
      const now = this.$moment().format('YYYY-MM-DD');
      let index = -1;
      for (const date of this.leagueData.days) {
        index++;
        if (now === date) {
          this.gameIndex = index;
          return;
        }
      }
      // If past the last day of the contest, default to week overview
      this.gameIndex = -1;
    },

    selectSort(selectedSort) {
      this.selectedSort = selectedSort;
      EventBus.$emit('CLOSE_DROPDOWNS');
    },

    getPlayerFpts(player) {
      const game = this.getFspGame(player);
      if (game != null && game.ObPoints != null && game.ObPoints.total != null) {
        return game.ObPoints.total;
      }
      return 0;
    },

    getPlayerProj(player) {
      const game = this.getFspGame(player);
      if (game != null && game.ProjPoints != null && game.ProjPoints.total != null) {
        return game.ProjPoints.total;
      }
      return 0;
    },

    getTotalFpts(entry) {
      if (!entry) {
        return 0;
      }
      return this.sumPlayerPoints(entry);
    },

    sumPlayerPoints(entry) {
      const games = entry.fspGames;
      let totalPoints = 0;
      for (const computedGameIndex in games) {
        const gJSON = games[computedGameIndex];
        const gameKey = entry.id + '_' + gJSON.id;
        if (this.rosterCalc.playedGames[gameKey]) {
          totalPoints += gJSON.ObPoints.total;
        }
      }
      return totalPoints;
    },

    getFspGame(entry) {
      if (entry.fspGames == null) {
        return null;
      }
      return entry.fspGames[this.computedGameIndex];
    },

    todaysGame(entry) {
      const gameJSON = this.getFspGame(entry);
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },
  },
};
</script>

<style lang="scss" scoped>

.daySelectContainer {
  background: var(--obcolor-background-6);
  height: 47px;
  display: flex;
  font-size: 12px;
  text-align: center;
}
.daySelection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--obcolor-background-page);
  border-bottom: 1px solid var(--obcolor-background-page);
  cursor: pointer;
}
.daySelection:last-child {
  border-right: none;
}
.daySelection:hover {
  background: var(--obcolor-background-3);
}

table {
  width: 100%;
}
th {
  height: 18px;
  padding: 14px 0;
  background: var(--obcolor-background-3);
  border-bottom: 1px solid var(--obcolor-background-page);
}
th, td {
  text-align: center;
}
td {
  height: 60px;
  font-size: 12px;
}
tr:not(:last-child) {
  td {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
}
// tr:nth-child(odd) {
//   background: var(--obcolor-background-5);
// }
tr {
     background: var(--obcolor-background-5);
}
tr.playerRow {
  cursor: pointer;
}
tr.playerRow:hover {
  background: var(--obcolor-background-3);
}

.obText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;
}
.projText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -3px;
}

.teamHeader th {
  height: 65px;
  // background: var(--obcolor-background-6);
}

.teamName {
  text-align: left;
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
}

.teamSubName {
  text-align: left;
  padding-left: 25px;
  font-size: 14px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.contestHeaderField {
  font-size: 16px;
  text-align: right;
}

.mlbHeaderInfo {
  display: flex;
  justify-content: space-around;
  // padding: 0 20px 0 40px;
  color: var(--obcolor-font-secondary);
}

.gameOppsPie {
  height: 22px;
  // margin-top: -4px;
}

.gameOpps {
  font-size: 12px;
  height: 14px;
  padding-top: 1px;
  font-weight: normal;
  white-space: nowrap;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.headerValue {
  height: 20px;
  font-size: 16px;
}

.subText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: normal;
  margin-top: 3px;
}

.ownersBucksIcon {
  height: 12px;
  margin-right: 0px;
}
.moneyIcon {
  height: 20px;
  margin-bottom: -4px;
  margin-top: -3px;
}

.ticketIcon {
  height: 12px;
}
</style>
