<template>
  <div class="weekGraphContainer">
    <!-- PayZone -->
    <div class="payzoneArea" :style="getPayZoneBgHeight()"></div>
    <div class="payzoneText">PAY ZONE</div>

    <!-- Gridlines -->
    <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex;">
      <div v-for="date in leagueData.days" :key="date" class="dayContainer"
        style="position: relative;"
      >
        <div v-if="date === payoutBarDate" class="payoutBar">
          <div class="payoutBarHighlight" :style="getPayZoneBgHeight()"></div>
        </div>

        <!-- My Entries Circles -->
        <template v-if="date === payoutBarDate">
          <div v-for="entry in myTeams" :key="entry.teamId" class="entryCircle"
            :class="{selectedCircle: isSelectedEntry(entry)}"
            :style="'top: ' + (getRankY(entry.rank) - 9) + 'px;'"
            @click="changeTeam(entry.teamId)"
          >
            <div class="entryTriangle"></div>
            <div style="position: absolute;">{{ entry.teamNum }}</div>
          </div>
        </template>
      </div>
      <div class="prizePoolContainer">
        <!-- Set height to same as payzone, so prize pool in centered in the payzone -->
        <div :style="getPayZoneBgHeight()" style="margin-top: 5px; display: flex; align-items: center; justify-content: center;">
          <div class="prizePool blueText" :class="leagueData.realMoney ? 'moneyText' : 'blueText'">
            {{ getTeamWinnings().removeCentsIfZero() }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="leagueRoster" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;">
      <svg width="100%" height="100%">
        <!-- Lines -->
        <template v-for="(date, index) in leagueData.days">
          <line v-if="getRank(date) && getNextRank(index)" :key="'line'+index" class="graphLine"
            :x1="getDayX(index)" :y1="getDateY(date)"
            :x2="getDayX(index+1)" :y2="getRankY(getNextRank(index))"
          />
        </template>

        <!-- Dots -->
        <template v-for="(date, index) in leagueData.days">
          <circle v-if="getRank(date)" :key="'dot'+index" class="graphPoint"
            r="4"
            :cx="getDayX(index)" :cy="getDateY(date)"
            v-tooltip.bottom="{content: getRank(date).addSuffix(), class:'ObTooltip'}"
          />
        </template>
      </svg>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    leagueData: Object,
    leagueRoster: Object,
    myTeams: Array,
    teamId: String,
  },

  data: function() {
    return {
      gWidth: 715,
      gHeight: 125,
      selectedEntryRank: 1,
    };
  },

  computed: {
    payoutBarDate() {
      // Returns the current day of the contest, or the last day if past
      if (!this.leagueData) {
        return '';
      }
      const days = this.leagueData.days;
      const now = this.$moment().format('YYYY-MM-DD');
      for (const date of days) {
        if (date === now) {
          return date;
        }
      }
      // If past the last day of the contest, show bar on the last day
      const lastDay = days[days.length-1];
      const lastDate = this.$moment(lastDay, 'YYYY-MM-DD');
      if (this.$moment().isAfter(lastDate)) {
        return lastDay;
      }
      return '';
    },
  },

  watch: {
    myTeams(to, from) {
      this.setSelectedRank();
    },
    leagueRoster(to, from) {
      this.setSelectedRank();
    },
  },

  created() {
    this.setSelectedRank();
  },

  methods: {

    changeTeam(teamId) {
      const tab = this.$route.params.view;
      this.$router.push('/salarycontest/' + tab + '/' + this.leagueData.id + '/' + teamId);
    },

    isSelectedEntry(entry) {
      if (!this.leagueRoster) {
        return false;
      }
      return String(this.leagueRoster.teamNum) === entry.teamNum;
    },

    isToday(date) {
      const now = this.$moment().format('YYYY-MM-DD');
      return now === date;
    },

    // Required to make the graph points respond to live updates
    setSelectedRank() {
      if (!this.leagueRoster) {
        return;
      }
      for (const entry of this.myTeams) {
        if (String(this.leagueRoster.teamNum) === entry.teamNum) {
          this.selectedEntryRank = entry.rank;
          return;
        }
      }
    },

    getRank(date) {
      if (!this.leagueRoster) {
        return null;
      }
      if (date === this.payoutBarDate) {
        return this.selectedEntryRank;
      }
      return this.leagueRoster.rankByDay[date];
    },

    getNextRank(index) {
      const nextDate = this.leagueData.days[index + 1];
      if (nextDate) {
        return this.getRank(nextDate);
      }
      return null;
    },

    getPayZoneBgHeight() {
      // Payzone height for background shading
      // Remove 10 pixels (5 from top and bottom)
      const pct = this.getPayZonePct();
      const height = (this.gHeight - 10) * (pct/100);
      return 'height: ' + height + 'px;';
    },

    getPayZonePct() {
      let pct = this.getNumWinners() / this.leagueData.playersCurrent * 100;
      if (pct >= 100) {
        pct = 100;
      }
      return pct;
    },

    getNumWinners() {
      let total = 0;
      for (const entry of this.leagueData.payouts) {
        total += entry.payouts;
      }
      return total;
    },

    getDayX(index) {
      return index * (this.gWidth/8) + (this.gWidth/8/2);
    },

    getDateY(date) {
      const rank = this.getRank(date);
      return this.getRankY(rank);
    },

    getRankY(rank) {
      if (!rank) {
        rank = 0;
      }
      let numPlayers = this.leagueData.playersCurrent - 1;
      if (numPlayers < 1) {
        numPlayers = 1;
      }
      const rankPct = (rank - 1) / numPlayers;
      // Add 5 to account for dot radius
      return (rankPct * 115) + 5;
    },

    getTeamWinnings() {
      const dollarSign = this.leagueData.realMoney ? '$' : '';
      if (!this.myTeams || !this.teamId) {
        return dollarSign + '0';
      }

      const team = this.myTeams.find((t) => {
        return t.teamId == this.teamId;
      });

      const prizeVal = team.prizeValue;
      return dollarSign + (prizeVal / 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>

.weekGraphContainer {
  height: 125px;
  position: relative;
  background: var(--obcolor-background-5);
  border-bottom: 1px solid var(--obcolor-background-page);
}

.dayContainer {
  flex: 1;
  border-right: 1px solid var(--obcolor-background-page);
  display: flex;
  align-items: center;
  justify-content: center;
}

.payzoneArea {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 95px;
  background: rgba(0, 255, 0, 0.12);
  border-bottom: 1px solid rgb(10, 138, 10);
}

.payzoneText {
  position: absolute;
  left: 10px;
  top: 8px;
  color: var(--obcolor-green);
  font-size: 14px;
}

.payoutBar {
  width: 8px;
  height: 115px;
  background: var(--obcolor-background-1);
  border-radius: 4px;
  overflow: hidden;
}
.payoutBarHighlight {
  background: var(--obcolor-green);
  border-bottom: 1px solid rgb(10, 138, 10);
}

.graphLine {
  stroke: var(--obcolor-ob-blue);
  stroke-width: 3;
}

.graphPoint {
  cursor: pointer;
  fill: var(--obcolor-ob-blue);
  stroke: rgb(41, 96, 158);
  stroke-width: 1;
}
.graphPoint:hover {
  r: 7;
  fill: red;
  stroke: black;
}

.prizePoolContainer {
  max-width: 89px;
  flex: 1;
  background: var(--obcolor-background-3);
}

.prizePool {
  font-size: 12px;
  font-weight: bold;
  background: rgb(17, 129, 17);
  color: white;
  padding: 5px;
  border-radius: 4px;

  &.blueText {
    background: var(--obcolor-ob-blue);
  }

  &.moneyText {
    background: rgb(17, 129, 17);
  }
}

.entryCircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  z-index: 50;
  left: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  background: #474F6D;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
  transition-property: top;
  transition-duration: 600ms;
}
.entryTriangle {
  position: absolute;
  left: -7px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 18px solid #474F6D;
}

.entryCircle:hover {
  background: var(--obcolor-background-3);
  z-index: 60;
  .entryTriangle {
    border-right: 18px solid var(--obcolor-background-3);
  }
}

.selectedCircle {
  background: var(--obcolor-ob-blue) !important;
  z-index: 51;
  .entryTriangle {
    border-right: 18px solid var(--obcolor-ob-blue) !important;
  }
}

</style>
