<template>
  <tr class="playerRow" @click="selectPlayer()">
    <td class="posCol" style="width: 42px;">{{ player.draftPos }}</td>
    <td>
      <div class="playerContainer">
        <PlayerHeadshot size="42" :id="player.id" :sport="contest.sport"
          :team="player.team" :showLogo="true"
        />
        <div style="margin-left: 10px;">
          <div class="playerName">
            <div v-if="!statusMap" class="statusLoading">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
            <PlayerStatus v-else-if="!status" class="playerStatus" status="'OUT'" />
            <PlayerStatus v-else class="playerStatus" :status="status" />

            <span @click.stop="openPlayerCard()" class="ob-link" style="margin-right: 6px;"
              :style="getPlayerTextColour"
            >
              {{ player.name.longName() }}
              {{ player.nameTag }}
            </span>

            <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
              :style="getPlayerTextColour"
              v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
            ></i>
            <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
              :style="getPlayerTextColour"
              v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
            ></i>
          </div>
          <div class="playerInfo" :class="{'pickedPlayer': picked}">
            <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }}</span>
            <span>#{{ player.number }}</span>
            <span>{{ player.position }}</span>
            <span>{{ player.lineInfo }}</span>
          </div>
          <div style="margin-top: 5px" class="playerInfo">
            <MlbBattingOrder v-if="isDailyMLB() && !isPitcher()" :myBatterIndex="getBatterIndex()" />
          </div>
        </div>
      </div>
    </td>

    <!-- Stats -->

    <!-- Upcoming game opponent logo -->
    <td class="stat">
      <div v-if="nextGame" class="matchupBox">
        <!-- Upcoming matchup -->
        <div class="teamNameBox">
          <div class="teamName">{{ getDisplayAlias(player.team) }}</div>
          <div class="homeAwayText" :style="'color: ' + getOprkColour(nextGame.oprk)">
            {{ nextGame.game.includes('@') ? '@' : 'vs' }}
          </div>
          <div class="teamName" :style="'color: ' + getOprkColour(nextGame.oprk)">
            {{ getDisplayAlias(nextGame.game) }}
          </div>
        </div>
        <!-- Upcoming game time -->
        <div class="gameDateBox">
          <div v-if="nextGame.ts != null">{{ $moment(nextGame.ts).format('ddd h:mma') }}</div>
        </div>
      </div>
      <div v-else>-</div>
    </td>
    <!-- Opponent Rank (OPRK) -->
    <td v-if="contest.sport == 'NFL'" class="statCenter">
      <div v-if="nextGame && nextGame.oprk != null" class="oprk" :style="'color: ' + getOprkColour(nextGame.oprk)">{{ nextGame.oprk }}</div>
      <div v-else>-</div>
    </td>
    <td class="statCenter">{{ player.sAvg.toFixed(1) || '-' }}</td>
    <td class="statCenter proj" :style="getPlayerTextColour">{{ playerProj }}</td>
    <td class="statCenter moneyText" :style="!canAfford ? 'color: red' : getPlayerTextColour">
      {{ playerSalary }}
    </td>
    <td class="btnCol" @click.stop="togglePlayer">
      <i v-if="isGameSlateLineup && !canPickGameSlatePlayer" class="fal fa-times-circle addBtn disabledBtn"></i>
      <i v-else-if="playerIsOnTeam" class="fal fa-minus-circle removeBtn"></i>
      <i v-else-if="getStat('pg') == 0" :key="'tooltip_' + player.id" style="height: 22px;"
        class="fal fa-times-circle addBtn disabledBtn"
        v-tooltip.bottom="{ content: 'You cannot add a player with zero projected games to your lineup.', class: 'ObTooltip'}"
      ></i>
      <i v-else-if="!canPickPlayer" class="fal fa-times-circle addBtn disabledBtn"></i>
      <i v-else class="fal fa-plus-circle addBtn"></i>
    </td>
  </tr>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder';
import EventBus from '@/event-bus';

export default {
  props: {
    player: {type: Object, default: null},
    picked: {type: Boolean, default: null},
    picks: {type: Object, default: null},
    statusMap: {type: Object, default: null},
    status: {type: String, default: null},
    contest: {type: Object, default: null},
    totSpent: {type: Number, default: null},
    selectedView: {type: String, default: null},
    editingGameSlateLineup: {type: Boolean, default: false},
    isGameSlateLineup: {type: Boolean, default: false},
  },

  components: {
    PlayerStatus,
    PlayerHeadshot,
    MlbBattingOrder,
  },

  computed: {
    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    playerProj() {
      // If editing, projection becomes 0 when player cannot be swapped
      if (this.isGameSlateLineup && !this.canPickGameSlatePlayer) {
        return '0';
      }
      return this.getStat('avg').toFixed(0) || '-';
    },

    playerSalary() {
      // If editing, salary becomes $0 when player cannot be swapped
      if (this.isGameSlateLineup && !this.canPickGameSlatePlayer) {
        return '$0';
      }
      return this.dollarFormat(this.player.salary);
    },

    canAfford() {
      const playerCost = this.player.salary;
      return (playerCost + this.totSpent <= this.contest.salaryCap) || this.picked !== null;
    },

    canPickGameSlatePlayer() {
      return !this.editingGameSlateLineup || this.player.canSwap;
    },

    canPickPlayer() {
      // Safeguard, player id and position required
      if (this.picks == null || this.player.id == null || this.player.draftPos == null) {
        return false;
      }

      // Can't Select players with no projected games
      if (this.getStat('pg') <= 0) {
        return false;
      }

      const draftPos = this.player.draftPos;
      return this.draftSpotIsOpen(draftPos) || this.isFlexPositionOpen(draftPos);
    },

    getPlayerTextColour() {
      if (!this.player || !this.player.id) {
        return 'color: var(--obcolor-text-faded);';
      }
      if (this.playerSalary === '$0') {
        return 'color: var(--obcolor-text-faded);';
      }
      if (this.getStat('pg') == 0) {
        return 'color: var(--obcolor-text-secondary);';
      }
      return this.picked !== null ? 'color: var(--obcolor-ob-blue);' : '';
    },

    playerIsOnTeam() {
      return this.picked !== null;
    },

    nextGame() {
      if (!this.player.games) {
        return null;
      }
      return this.player.games.find((g) => g.ts != null);
    },
  },

  methods: {
    getDisplayAlias(alias) {
      alias = alias.replace(/@/, '');
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, alias);
    },

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getOprkColour(oprk) {
      if (oprk <= 10) {
        return 'var(--obcolor-red)';
      } else if (oprk <= 20) {
        return 'var(--obcolor-yellow)';
      }
      return 'var(--obcolor-green)';
    },

    getStat(path) {
      if (this.player && this.player.wStats && this.player.wStats[0]) {
        return this.player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    rosterAtPos(position) {
      return this.roster.find((r) => {
        return r.short == position;
      });
    },

    draftSpotIsOpen(draftPos) {
      if (this.picks == null || this.picks[draftPos] == null) {
        return false;
      }
      const rPos = this.rosterAtPos(draftPos);
      return rPos.num > this.picks[draftPos].length;
    },

    isFlexPositionOpen(draftPos) {
      let guardOpen = false;
      let forwardOpen = false;
      let utilityOpen = false;

      let flexOpen = false;
      let superflexOpen = false;

      // Utility, Flex, and SuperFlex positions can fit multiple slots
      if (this.contest.isFlexContest) {
        if (this.$SportInfo.draftPosIsGuard(draftPos, this.contest.sport)) {
          guardOpen = this.draftSpotIsOpen(this.$SportInfo.guard());
        }
        if (this.$SportInfo.draftPosIsForward(draftPos, this.contest.sport)) {
          forwardOpen = this.draftSpotIsOpen(this.$SportInfo.forward());
        }
        if (this.$SportInfo.draftPosIsUtility(draftPos, this.contest.sport)) {
          utilityOpen = this.draftSpotIsOpen(this.$SportInfo.utility()) || this.draftSpotIsOpen(this.$SportInfo.utilityMultiplier());
        }

        if (this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport)) {
          flexOpen = this.draftSpotIsOpen('F');
        }
        if (this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport)) {
          superflexOpen = this.draftSpotIsOpen('SF') || this.draftSpotIsOpen(this.$SportInfo.superFlexMultiplier());
        }
      }

      return guardOpen || forwardOpen || utilityOpen || flexOpen || superflexOpen;
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    togglePlayer() {
      // Prevent toggling player when editing a lineup, if the player can't be swapped
      if (this.isGameSlateLineup && !this.canPickGameSlatePlayer) {
        return;
      }
      if (this.playerIsOnTeam) {
        EventBus.$emit('SALARY_CAP_REMOVE_PLAYER', this.player);
      } else if (this.canPickPlayer) {
        EventBus.$emit('SALARY_CAP_PICK_PLAYER', this.player);
      }
    },

    openPlayerCard() {
      if (this.isGameSlateLineup) {
        // Use the generic player card when creating a lineup for a slate (not tied to a contest)
        EventBus.$emit('OPEN_PLAYER_CARD', this.player.id, this.contest.sport);
      } else {
        EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
      }
    },

    selectPlayer() {
      // NFL doesn't have "selected" player, so treat this like the plus/minus button instead
      if (this.contest.format == 'DAILY') {
        this.togglePlayer();
        return;
      }
      EventBus.$emit('SALARY_CAP_SELECT_PLAYER', this.player);
    },

    getBatterIndex() {
      if (!this.nextGame || !this.nextGame.info) {
        return null;
      }
      return this.nextGame.info.lineup;
    },

    isPitcher() {
      return this.player && this.player.draftPos == 'P';
    },

    isDailyMLB() {
      return this.contest && this.contest.format == 'DAILY' && this.contest.sport == 'MLB';
    },
  },
};
</script>

<style lang="scss" scoped>
  td {
    cursor: pointer;
    border-bottom: 1px solid var(--obcolor-background-2);
    height: 65px;

    &.stat {
      text-align: left;
    }
    &.statCenter {
      text-align: center;;
    }
    &:not(.posCol):not(.btnCol) {
      min-width: 80px;
    }
  }

  .posCol {
    font-size: 14px;
  }

  .playerContainer {
    display: flex;
    align-items: center;
  }

  .playerName {
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .statusLoading {
    width: 18px;
    margin-top: 0px;
    margin-left: -3px;
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .playerStatus {
    margin-top: -1px;
    margin-right: 4px;
  }

  .pickedPlayer {
    color: var(--obcolor-text-faded) !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 15px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .scheduleBox {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    background: var(--obcolor-background-1);
    // border: 1px solid var(--obcolor-background-1);
    min-height: 47px;
    font-size: 12px;
    font-weight: bold;
  }
  .matchupBox {
    width: 90px;
  }
  .teamNameBox {
    display: flex;
    align-items: flex-end;
    .teamName {
      text-transform: uppercase;
    }
    .teamName:not(:first-child) {
      color: var(--obcolor-green);
    }

    .homeAwayText {
      font-size: 12px;
      color: var(--obcolor-green);
      margin: 0 5px;
    }
  }

  .gameDateBox {
    font-size: 12px;
    margin-top: 3px;
  }

  .oprk {
    font-weight: bold;
  }

  .proj {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

  .endSection {
    border-left: 2px solid var(--obcolor-background-1)
  }

  .btnCol {
    width: 30px;
    min-width: 30px;
    padding-right: 15px;
    text-align: right;
    font-size: 20px;

    .addBtn {
      color: var(--obcolor-green);
    }
    .removeBtn {
      color: var(--obcolor-red);
    }
    .addBtn.disabledBtn {
      color: var(--obcolor-text-faded);
    }
  }
</style>