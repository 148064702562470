<template>
  <div class="ob-page">
    <div class="ob-box pageBox">
      <div class="ob-subheading" style="margin-left: 0">Responsible Play</div>
      <div style="margin-bottom: 10px;">
        Enjoy legal online Fantasy Sports responsibly.
        At OwnersBox, we are offering users a wide variety of options that can be used to manage your gaming activity,
        such as deposit limitations and account lockouts, so that users are in complete control of their playstyle.
        All mentioned limits are explained and can be set in the various sections below when logged in.
      </div>
      <div style="margin: 10px 0;">If you or someone you know has a gambling problem, help is available. Call: 1-800-GAMBLER</div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Set your Limits</div>
        <div class="boxBody" style="flex-direction: column;">
          <div>
            OwnersBox offers two tools that help protect our players should the need arise. With User Limits, users will be able to choose how much they are allowed to deposit within a 24 hour, 7 days, and 31 days period of time. User Lockout gives users the ability to lock themselves out of their account for a set amount of time, so they can take a step away from our platform to recover.
            <br>
            <br>
            Limit your account’s deposit, entries and entry fee limit with the <router-link class="hyperlink" to="/userLimits">User Limits</router-link> settings.
            If you feel like you need to take a break from OwnersBox, use the <router-link class="hyperlink" to="/userLockout">User Lockout</router-link> setting to lock your account for a set amount of time.
          </div>
          <div style="margin-top: 10px;">
            <router-link class="ob-btn" style="margin-right: 10px;" to="/userLimits">User Limits</router-link>
            <router-link class="ob-btn" to="/userLockout">User Lockout</router-link>
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader" style="display: flex; cursor: pointer;" @click="toggleCollapse('stateDeposit')">
          <div style="flex: 1;">State Specific Deposit Limitations</div>
          <i class="greyChevron fas " :class="'fa-chevron-' + (collapsed.stateDeposit ? 'up' : 'down')"></i>
        </div>
        <div class="boxBody" v-if="collapsed.stateDeposit == false" style="flex-direction: column;">
          <div>
            In adherence with respective state regulations, OwnersBox restricts deposits within a 31 day rolling period. The following states have deposit limits applied:
            <br>
            <br>
            For <b>Massachusetts</b> residents, the deposit limit is $1,000 per 31 days
            <br>
            For <b>Maryland</b> residents and people located in Maryland, the deposit limit is $1,000 per 31 days
            <br>
            For <b>Tennessee</b> residents and people located in Tennessee, the deposit limit is $2,500 per 31 days
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader" style="display: flex; cursor: pointer;" @click="toggleCollapse('affiliatedExclusion')">
          <div style="flex: 1;">Affiliated Parties Exclusion</div>
          <i class="greyChevron fas " :class="'fa-chevron-' + (collapsed.affiliatedExclusion ? 'up' : 'down')"></i>
        </div>
        <div class="boxBody" v-if="collapsed.affiliatedExclusion == false" style="flex-direction: column;">
          <div>
            OwnersBox allows qualified affiliates, who have concerns about a player’s ability to manage their play, to request a limitation on that player’s use of OwnersBox.
            Limitations may include restriction or exclusion from play.
            <br>
            <br>
            Affiliated Party Exclusion Requests for an OwnersBox user can be made by requestors who can provide the following as proof:
            <br>
            <br>
            • Requestor is solely or jointly obligated on the credit or debit card associated with the OwnersBox account source of funds deposited
            <br>
            • The OwnersBox user is legally dependent on the requestor under state or federal law
            <br>
            • Requestor is wholly or partially obligated for debts of the OwnersBox user
            <br>
            • OwnersBox user is subject to court order requiring him or her to pay unmet child support
            <br>
            • OwnersBox makes available and prominently publishes and facilitates parental control procedures.
            It is the general principle of OwnersBox to exclude all participation of minors on the platform, and has implemented technologies related to this initiative.
            <br>
            <br>
            At the request of a qualified third party, the OwnersBox account may be excluded from play for a period of 30 to 1827 days or have a deposit limit applied to their daily, weekly, or monthly deposits.
            While under exclusion the player will not receive any notifications or marketing correspondence from OwnersBox.
            <br>
            <br>
            In the event of a qualified third party applying an exclusion, the associated OwnersBox account will receive an email notification of the limits being applied.
            The users will also have the ability to dispute the limitation placement for up to 5 days, if they fail to respond then the limitation will be applied to their account.
            <br>
            <br>
            If you would like to request an exclusion for an OwnersBox account please contact <a href="https://support.ownersbox.com/hc/en-us" class="hyperlink" target="_blank">our Player Support</a>.
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader" style="display: flex; cursor: pointer;" @click="toggleCollapse('parent')">
          <div style="flex: 1;">Parental Controls</div>
          <i class="greyChevron fas " :class="'fa-chevron-' + (collapsed.parent ? 'up' : 'down')"></i>
        </div>
        <div class="boxBody" v-if="collapsed.parent == false" style="flex-direction: column;">
          <div>
            OwnersBox does not allow minors on the platform and will verify all accounts for age and eligibility.
            In the event that OwnersBox finds a minor is accessing an account we will terminate that account.
            If a minor has access to your computer we recommend you install parental control software to prevent unauthorized access.
            If you suspect a minor is participating on OwnersBox, please contact <a href="https://support.ownersbox.com/hc/en-us" class="hyperlink" target="_blank">customer support</a>.
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader" style="display: flex; cursor: pointer;" @click="toggleCollapse('additional')">
          <div style="flex: 1;">Additional Help</div>
          <i class="greyChevron fas " :class="'fa-chevron-' + (collapsed.additional ? 'up' : 'down')"></i>
        </div>
        <div class="boxBody" v-if="collapsed.additional == false" style="flex-direction: column;">
          <div>
            If you are concerned with your gaming behaviour we have provided a number of resources below:
          </div>
          <div class="boxSubHeader" style="margin-top: 20px; margin-bottom: 20px;">National Resources:</div>
          <div>
            <a href="https://www.ncpgambling.org/" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >NCPG</a>
            - Do you or someone you know need help with a gambling problem? The National Council on Problem Gambling offers help and hope.
            <div style="font-weight: bold; padding-left: 52px">
              Call: 1-800-GAMBLER
              <br>
              Chat: ncpgambling.org/chat
              <br>
              Text: 800GAM
            </div>
          </div>
          <div style="margin-top: 10px;">
            <a href="http://www.gamblersanonymous.org/ga/hotlines" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >Gamblers Anonymous</a>
            - Locate a meeting near you or talk to someone now.
          </div>
          <div style="margin-top: 10px;">
            <a href="https://www.gam-anon.org/" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >GAM-ANON</a>
            - Official website of the Gam-Anon® International Service Office
          </div>
          <div style="margin-top: 10px;">
            <a href="https://suicidepreventionlifeline.org/" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >Suicide Prevention Hotline</a>
            - The Lifeline provides 24/7, free and confidential support for people in distress,
            prevention and crisis resources for you or your loved ones, and best practices for professionals.
            <div style="font-weight: bold; padding-left: 52px">
              Call: 1-800-273-TALK(8255)
            </div>
          </div>
          <div class="boxSubHeader" style="margin-top: 20px; margin-bottom: 20px;">Pennsylvania Resources:</div>
          <div>
            <a href="https://www.pacouncil.com/" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >Council on Compulsive Gambling of Pennsylvania</a>
            - Their purpose is to educate and disseminate information on compulsive gambling and to facilitate referrals to help.
          </div>
          <div>
            <a href="https://apps.ddap.pa.gov/gethelpnow/ProviderSearch.aspx?zip" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >PA Department of Drug and Alcohol Programs</a>
            - This provides a list of Drug and Alcohol Programs’ gambling disorder treatment providers.
          </div>
          <div>
            <a href="https://pgcb.pa.gov/SelfExclusion" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >PGCB Self Exclusion Enrollment</a>
            - Enroll in PA's self-exclusion program
          </div>
          <div>
            <a href="https://responsibleplay.pa.gov/wp-content/uploads/Self_Exclusion_Fantasy_Gaming.pdf" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >
              Fantasy Self-Exclusion Brochure
            </a>
          </div>
          <div>
            <a href="http://www.gamblersanonymous.org/ga/locations/state/table/PA/na/na/na/na/10?#gmap-nodemap-gmap0" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >
              PA Gamblers Anonymous
            </a>
            - Search for meetings near you in Pennsylvania.
          </div>
          <div>
            <a href="https://responsibleplay.pa.gov/" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >
              PGCB’s Office of Compulsive and Problem Gambling
            </a>
          </div>
          <div>
            <a href="https://responsibleplay.pa.gov/wp-content/uploads/Signs_Rules_Myth_Fact.pdf" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >
              Problem Gambling – Responsible Gaming brochure
            </a>
          </div>

          <div class="boxSubHeader" style="margin-top: 20px; margin-bottom: 20px;">Iowa Resources:</div>
          <div>
            <a href="https://yourlifeiowa.org/gambling" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >Iowa Department Of Public Health</a>
            - Iowans can chat live, get reliable information and treatment options, and find nearby help.
            <div style="font-weight: bold; padding-left: 52px">
              Call: 1-800-BETS-OFF (1-800-238-7633)
            </div>
          </div>
          <div class="boxSubHeader" style="margin-top: 20px; margin-bottom: 20px;">New York Resources:</div>
          <div>
            <a href="https://www.nyproblemgambling.org" target="_blank" class="hyperlink"
              style="font-weight: bold;" rel="noopener"
            >New York Council On Problem Gambling</a>
            - New Yorkers can visit here for information and resources dedicated to problem gambling.
            <div style="font-weight: bold; padding-left: 52px">
              Call: 1-877-846-7369
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapsed: {
        stateDeposit: false,
        affiliatedExclusion: false,
        parent: false,
        additional: false,
      },
    };
  },

  methods: {
    toggleCollapse(key) {
      this.collapsed[key] = !this.collapsed[key];
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 10px;
}

// re-add hyperlink colours
.hyperlink {
  color: var(--obcolor-ob-blue);
  cursor: pointer;
}

.applyBtn {
  width: 177px;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.btnError {
  width: 177px;
  margin-left: auto;
}

.pageBox {
  padding: 15px 20px;
  font-size: 14px;
}

.categoryBox {
  background: var(--obcolor-background-4);
  overflow: hidden;
  padding: 15px 20px;
}

.categoryBox:not(:last-child) {
  margin-bottom: 15px;
}

.boxHeader {
  background: var(--obcolor-background-3);
  margin: -15px -20px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
}

.boxSubHeader {
  font-size: 16px !important;
  font-weight: bold;
}

.boxBody {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.boxBody > div {
  line-height: 19px;
  font-size: 14px;
}

.bodyLeftSide {
  flex: 1;
  max-width: 720px;
}

.bodyRightSide {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
}

.fieldBox {
  background: var(--obcolor-background-3);
  display: flex;
}

.fieldBox:not(:first-child) {
  margin-top: 15px;
}

.fieldBox:not(:last-child) {
  margin-bottom: 15px;
}

.field {
  padding: 0 5px;
  font-size: 14px;
}

.fieldWarning {
  font-size: 10px;
  color: red;
  padding-top: 5px;
}

.loadingBox {
  text-align: center;
  padding: 20px;
}

.greyChevron {
  color: var(--obcolor-font-secondary);
}
</style>