<template>
  <table aria-label="Matchup Summary">
    <tr style="height: 59px;">
      <template v-if="league.sport != 'NFL'">
        <th class="rowHeader" style="text-align: left;">Current Roster</th>
        <th class="rowHeader" v-if="selectedDay === null"
          v-tooltip.bottom="{content: 'Games Played', class:'ObTooltip'}"
        >
          GP
        </th>
        <th class="rowHeader" style="width: 80px;" :style="league.sport === 'NFL' ? 'padding-right: 10px;' : ''"
          v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltip'}"
        >
          FPTS
        </th>
        <th class="rowHeader" v-if="selectedDay === null && league.sport !== 'NFL'" style="padding-right: 10px;"
          v-tooltip.bottom="{content: 'Average Fantasy Points', class:'ObTooltip'}"
        >
          AVG
        </th>
        <th class="rowHeader" v-if="selectedDay === null" style="padding-left: 10px;"
          v-tooltip.bottom="{content: 'Games Remaining', class:'ObTooltip'}"
        >
          GR
        </th>
        <th class="rowHeader" style="width: 80px;"
          v-tooltip.bottom="{content: 'Projected Fantasy Points', class:'ObTooltip'}"
        >
          PROJ
        </th>
      </template>
      <template v-else>
        <th class="rowHeader" style="text-align: left;">Current Roster</th>
        <th class="rowHeader" v-tooltip.bottom="{content: 'Live Game Stats', class:'ObTooltip'}">STATS</th>
        <th class="rowHeader" style="width: 80px;" v-tooltip.bottom="{content: 'Projected Fantasy Points', class:'ObTooltip'}">PROJ</th>
        <th class="rowHeader" style="width: 80px; padding-right: 10px;" v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltip'}">FPTS</th>
      </template>
    </tr>
    <MatchupTableRow v-for="(entry, index) in roster.players" :entry="entry"
      :league="leagueWithRoster"
      :key="'row1_' + index"
      :selectedDay="selectedDay"
      :playedGames="playedGames"
      :isMyTeam="isMyTeam"
      @showAddModal="showAddPlayerModal(entry)"
    />
    <tr v-if="roster.dropped.length > 0">
      <th class="rowHeader" colspan="12" style="text-align: left;">Dropped Players</th>
    </tr>
    <MatchupTableRow v-for="(entry, index) in roster.dropped" :entry="entry"
      :league="leagueWithRoster"
      :key="'row2_' + index"
      :selectedDay="selectedDay"
      :playedGames="playedGames"
      :isMyTeam="isMyTeam"
    />
  </table>
</template>

<script>
import MatchupTableRow from './MatchupTableRow.vue';

export default {
  props: {
    roster: Object,
    league: Object,
    selectedDay: Number,
    playedGames: Object,
    isMyTeam: Boolean,
  },

  components: {
    MatchupTableRow,
  },

  computed: {
    leagueWithRoster() {
      const tempLeague = this.league;
      tempLeague.roster = this.roster;
      return tempLeague;
    },
  },
};
</script>

<style lang="scss" scoped>
.rowHeader {
  background: var(--obcolor-background-6);
  font-size: 13px;
  border-bottom: 1px solid var(--obcolor-text-faded);
  padding: 18px 6px 12px 6px;
  height: 60px;
  box-sizing: border-box;
}
table {
  text-align: center;
  width: 100%;
}
td, .rowHeader {
  padding: 8px 6px 8px 6px;
  white-space: nowrap;
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}
</style>
