import Api from '@/api/ObBaseApi';

export default {

  sendVerificationCode(phoneNumber) {
    const params = {
      phoneNumber: phoneNumber,
    };
    return Api()
        .post('/fsp/phone-verification/sendVerificationCode', params)
        .then((response) => {
          return response.data;
        });
  },

  resendVerificationCode(id, phoneNumber) {
    const params = {
      id: id,
      phoneNumber: phoneNumber,
    };
    return Api()
        .post('/fsp/phone-verification/resendVerificationCode', params)
        .then((response) => {
          return response.data;
        });
  },

  verifyPhoneNumber(id, code) {
    const params = {
      id: id,
      code: code,
    };
    return Api()
        .post('/fsp/phone-verification/verifyPhoneNumber', params)
        .then((response) => {
          return response.data;
        });
  },

};