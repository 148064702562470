<template>
  <div class="gameSlateContainer">
    <div class="gameSlateBox gameSlateAllGames" @click="selectAllTeams()">All Games ({{ numGames }})</div>
    <div v-if="!flickityScrollValues.scrolledLeft" class="scoreboardPrev" @click="scoreboardPrev()">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div v-else class="scoreboardPrev disabledNextPrev">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div style="flex: 1;">
      <Flickity ref="flickity" style="width: 100%; height: 100%;" :options="flickityOptions">
        <div class="gameSlateBox" v-for="game in gameSlate" :key="game.id">
          <div class="gameDate">{{ formatGameDate(game) }}</div>
          <!-- Away Team -->
          <div class="teamBox" :class="{disabledTeam: !selectedTeams[game.visitor_team.team_id]}" @click="toggleTeam(game.visitor_team.team_id)">
            <img class="logo" :src="getTeamLogo(game.visitor_team.team_alias)" :alt="game.visitor_team.team_alias">
            <div style="flex: 1;">
              <div>{{ game.visitor_team.team_city }}</div>
              <div style="font-weight: bold;">{{ game.visitor_team.team_name }}</div>
            </div>
            <i v-if="selectedTeams[game.visitor_team.team_id]" class="fa fa-check"></i>
          </div>
          <!-- Home Team -->
          <div class="teamBox" :class="{disabledTeam: !selectedTeams[game.home_team.team_id]}" @click="toggleTeam(game.home_team.team_id)">
            <img class="logo" :src="getTeamLogo(game.home_team.team_alias)" :alt="game.home_team.team_alias">
            <div style="flex: 1;">
              <div>{{ game.home_team.team_city }}</div>
              <div style="font-weight: bold;">{{ game.home_team.team_name }}</div>
            </div>
            <i v-if="selectedTeams[game.home_team.team_id]" class="fa fa-check"></i>
          </div>
        </div>
      </Flickity>
    </div>
    <div v-if="!flickityScrollValues.scrolledRight" class="scoreboardNext" @click="scoreboardNext()">
      <i class="fas fa-chevron-right"></i>
    </div>
    <div v-else class="scoreboardNext disabledNextPrev">
      <i class="fas fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';

export default {

  props: {
    contest: {type: Object, default: null},
    contestSlate: {type: Array, default: null},
    value: {type: Object, default: null},
  },

  components: {
    Flickity,
  },

  data() {
    return {
      flickityIndex: 0,
      flickityOptions: {
        pageDots: false,
        cellAlign: 'left',
        selectedAttraction: 0.2,
        friction: 0.8,
        contain: true,
        draggable: false,
        prevNextButtons: false,
      },
      flickityScrollValues: {
        scrolledLeft: true,
        scrolledRight: false,
      },
    };
  },

  computed: {
    // Updates internally when this.value is changed
    // Emits any changes that are made on the input
    // Essentially just works with v-model
    selectedTeams: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    numGames() {
      if (!this.contestSlate) {
        return 0;
      }
      return this.contestSlate.length;
    },

    gameSlate() {
      if (!this.contestSlate) {
        return [];
      }
      const tempSlate = this.contestSlate.slice();
      return tempSlate.sort((g1, g2) => {
        const game1 = this.$moment(g1.date_utc).valueOf();
        const game2 = this.$moment(g2.date_utc).valueOf();
        return game1 - game2;
      });
    },
  },

  watch: {
    contestSlate() {
      this.$refs.flickity.rerender();
    },

    flickityIndex(newVal) {
      this.flickityOptions.initialIndex = newVal;
    },
  },

  methods: {
    scoreboardNext() {
      this.$refs.flickity.next();
      this.setFlickityIndex();
    },

    scoreboardPrev() {
      this.$refs.flickity.previous();
      this.setFlickityIndex();
    },

    setFlickityIndex() {
      const self = this;
      this.$nextTick(() => {
        if (self.$refs.flickity && self.$refs.flickity.selectedIndex() != null) {
          self.flickityIndex = self.$refs.flickity.selectedIndex();
          self.setScrollValues();
        }
      });
    },

    setScrollValues() {
      const self = this;
      this.$nextTick(() => {
        if (!self.$refs.flickity) {
          return;
        }

        const flickityData = self.$refs.flickity.data('flickity');
        const index = flickityData.selectedIndex;
        self.flickityScrollValues.scrolledLeft = index == 0;
        // If the selected cell's position is close to (within 10px of) the width of the container, we have scrolled all the way to the right
        self.flickityScrollValues.scrolledRight = (flickityData.cells[index].target - flickityData.slidesWidth) > 10;
      });
    },

    selectAllTeams() {
      let allTeamsSelected = true;
      // Check to see if all teams are already selected
      for (const game of this.gameSlate) {
        const homeId = game.home_team.team_id;
        const awayId = game.visitor_team.team_id;
        if (!this.selectedTeams[homeId] || !this.selectedTeams[awayId]) {
          allTeamsSelected = false;
        }
      }
      // If not all teams are selected, select all teams, otherwise unselect all teams
      if (!allTeamsSelected) {
        for (const game of this.gameSlate) {
          const homeId = game.home_team.team_id;
          const awayId = game.visitor_team.team_id;
          this.$set(this.selectedTeams, homeId, true);
          this.$set(this.selectedTeams, awayId, true);
        }
      } else {
        for (const game of this.gameSlate) {
          const homeId = game.home_team.team_id;
          const awayId = game.visitor_team.team_id;
          this.$set(this.selectedTeams, homeId, false);
          this.$set(this.selectedTeams, awayId, false);
        }
      }
    },

    toggleTeam(teamId) {
      this.$set(this.selectedTeams, teamId, this.selectedTeams[teamId] ? null : true);
    },

    formatGameDate(game) {
      return this.$moment(game.date_utc).format('ddd MMM D, h:mma');
    },

    getTeamLogo(team) {
      const sportName = this.contest.sport.toLowerCase();
      return require('@/assets/logos/'+ sportName +'_'+ team.toLowerCase() +'.png');
    },

  },

};
</script>

<style lang="scss" scoped>

.gameSlateContainer {
  // Height for flex boxes to adjust to - flickity uses this attribute to calculate container height
  height: 105px;
  // Min-height prevents player list from squishing this box
  min-height: 105px;
  margin-bottom: 10px;
  margin-top: -5px;
  display: flex;
}

.gameSlateBox {
  height: 100%;
  width: 170px;
  min-width: 170px;
  background: var(--obcolor-background-2);
  color: var(--obcolor-text-secondary);
  font-size: 14px;
  margin-right: 8px;
  border-radius: 4px;
}

.gameSlateAllGames {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}
.gameSlateAllGames:hover {
  color: var(--obcolor-text);
}

.logo {
  height: 30px;
  margin-right: 7px;
}

.gameDate {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 12px;
}

.teamBox {
  display: flex;
  align-items: center;
  height: calc(50% - 10px);
  padding-left: 5px;
  padding-right: 7px;
  color: var(--obcolor-text);
  font-size: 12px;
  cursor: pointer;

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}
.teamBox:hover {
  background: var(--obcolor-background-3);
}

.disabledTeam {
  background: var(--obcolor-background-5);
  color: var(--obcolor-text-secondary);
}

.fa-check {
  color: var(--obcolor-ob-blue);
}

.scoreboardNext, .scoreboardPrev {
  height: 100%;
  width: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--obcolor-font-primary);
}

.scoreboardPrev {
  margin-right: 8px;
}

.scoreboardNext {
  margin-left: 8px;
}

.scoreboardNext:not(.disabledNextPrev), .scoreboardPrev:not(.disabledNextPrev) {
  background: var(--obcolor-background-page);
  cursor: pointer;

  &:hover {
    color: var(--obcolor-ob-blue);
  }
}

.disabledNextPrev {
  color: var(--obcolor-font-secondary);
  cursor: not-allowed;
}
</style>