<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; min-height: 270px; max-height: 270px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-6); overflow: unset !important;"
    :disableClickaway="true" zIndex="10100"
  >
    <div slot="header" class="modalHeader">
      <div>DRAFTING NOW</div>
    </div>

    <div slot="body" class="modalBody ob-scroll" style="overflow: auto; padding: 10px; text-align: center;">
      <div style="display: flex; justify-content: center; align-items: center;">
        <img v-if="sport" :src="require('@/assets/icons/sporticon_' + sport + '.png')" :alt="`${sport} Icon`"
          style="height: 35px;"
        >
        <div class="liveBtn">
          <div style="width: 10px; height: 10px; background: white; display: inline-block; border-radius: 50%; margin-left: -2px;"></div>
          <span style="margin-left: 3px; font-weight: bold; margin-top: 1px;">LIVE</span>
        </div>
      </div>
      <div style="margin-top: 15px;">
        {{ message }}
      </div>
      <div style="margin-top: 12px;">You can also join this draft from the top of the dashboard</div>
    </div>

    <div slot="footer" class="modalFooter" style="display: flex;">
      <button class="ob-btn-grey" style="flex: 1; margin-right: 5px;" @click="closeModal()">Close</button>
      <button class="ob-btn" style="flex: 1; margin-left: 5px;" @click="goToDraft()">Enter Draft</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      sport: 'NHL',
      message: '',
      draftId: null,
    };
  },

  created() {
    EventBus.$on('DRAFT_MODAL_OPEN', this.openModal);
  },

  destroyed() {
    EventBus.$off('DRAFT_MODAL_OPEN', this.openModal);
  },

  methods: {
    openModal(notification) {
      if (notification) {
        this.sport = notification.sport;
        this.draftId = notification.draftId;
        this.message = notification.message;
        this.isOpen = true;
      }
    },

    goToDraft() {
      if (this.draftId) {
        this.$router.push('/draft/' + this.draftId);
        this.closeModal();
      }
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-background-3);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}
.modalBody {
  flex: 1;
  padding: 0px;
  box-sizing: border-box;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.liveBtn {
  display: flex;
  align-items: center;
  color: white;
  background: red;
  padding: 2px 6px;
  border-radius: 5px;
  height: 15px;
  font-size: 12px;
  margin-left: 10px;
}
</style>