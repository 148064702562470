<template>
  <div>
    <div class="subheading">Select the entry fee for the contest you would like to create.</div>
    <div class="itemList">
      <div class="selectContainer" v-for="(entryFee, index) in fees"
        :class="{selected: isFeeSelected(entryFee)}" :key="index"
        @click="selectUnselectAmount(entryFee)"
      >
        <div class="entryInfo">
          <div class="checkbox">
            <i class="fas fa-check"></i>
          </div>
          <div class="inputLabel">{{ dollarAmount(entryFee) }}</div>
        </div>
        <div class="entryInfo" :class="{selected: feeAmountMap[entryFee] }">
          <div class="entryButton" @click.stop="incDecAmount(entryFee, false)">
            <i class="fal fa-minus"></i>
          </div>
          <div class="entryAmount">{{ feeAmountMap[entryFee] || 0 }}</div>
          <div class="entryButton" @click.stop="incDecAmount(entryFee, true)">
            <i class="fal fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isRookieUser">
      <div class="heading">
        Choose Entry Type
        <i class="fas fa-info-circle infoBtn" v-tooltip.top="{content: 'You may choose to create rookie only contests. Rookie contests only allow beginner players to join them', class: 'ObTooltip'}"></i>
      </div>
      <div class="itemList">
        <div class="selectContainer" v-for="(rookieOption, index) in rookieOptions"
          :class="{selected: isRookieOptionSelected(rookieOption.value)}"
          @click="selectRookieOption(rookieOption.value)" :key="index"
        >
          <div class="checkbox">
            <i class="fas fa-check"></i>
          </div>
          <div class="inputLabel">{{ rookieOption.label }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    fees: {type: Array, default: null},
    rookieOptions: {type: Array, default: null},
    selectedFees: {type: Array, default: null},
    selectedIsRookie: {type: Boolean, default: null},
  },

  computed: {
    ...mapState(['rankNum']),

    isRookieUser() {
      return this.rankNum && this.rankNum === 1;
    },

    feeAmountMap() {
      const feeMap = {};
      for (const feeInfo of this.selectedFees) {
        this.$set(feeMap, feeInfo.entryFee, feeInfo.contestCount);
      }

      return feeMap;
    },
  },

  methods: {
    initFeeFieldIfNull(fee) {
      const newSelectedFees = this.selectedFees.slice();
      // Add fee field if there isn't one in the list
      if (this.feeAmountMap[fee] === undefined) {
        newSelectedFees.push({
          entryFee: fee,
          contestCount: 1,
        });
        this.$emit('selectFees', newSelectedFees);
        return true;
      }

      return false;
    },

    incDecAmount(fee, isIncrement) {
      if (!this.feeAmountMap[fee] && !isIncrement) {
        return;
      }

      const newSelectedFees = this.selectedFees.slice();
      const fieldWasNull = this.initFeeFieldIfNull(fee);
      if (fieldWasNull) {
        return;
      }

      for (const feeIndex in newSelectedFees) {
        const feeInfo = newSelectedFees[feeIndex];
        if (feeInfo.entryFee === fee && isIncrement) {
          newSelectedFees[feeIndex].contestCount++;
        } else if (feeInfo.entryFee === fee && !isIncrement) {
          newSelectedFees[feeIndex].contestCount--;
        }
      }

      this.$emit('selectFees', newSelectedFees);
    },

    selectUnselectAmount(fee) {
      const newSelectedFees = this.selectedFees.slice();
      const fieldWasNull = this.initFeeFieldIfNull(fee);
      if (fieldWasNull) {
        return;
      }

      for (const feeIndex in newSelectedFees) {
        const feeInfo = newSelectedFees[feeIndex];
        if (feeInfo.entryFee === fee && newSelectedFees[feeIndex].contestCount == 0) {
          newSelectedFees[feeIndex].contestCount = 1;
        } else if (feeInfo.entryFee === fee && newSelectedFees[feeIndex].contestCount > 0) {
          newSelectedFees[feeIndex].contestCount = 0;
        }
      }

      this.$emit('selectFees', newSelectedFees);
    },

    selectRookieOption(isRookie) {
      this.$emit('selectIsRookie', isRookie);
    },

    isFeeSelected(fee) {
      if (this.selectedFees == null) {
        return false;
      }
      return this.feeAmountMap[fee] != 0 && this.feeAmountMap[fee] != null;
    },

    isRookieOptionSelected(isRookie) {
      return this.selectedIsRookie === isRookie;
    },

    dollarAmount(fee) {
      if (!fee) {
        return '$0';
      }
      const dollarAmount = fee / 100;
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.heading, .subheading {
  font-size: 16px;
  text-align: center;
}

.heading {
  font-weight: bold;
  margin-bottom: 20px;
}

.subheading {
  color: var(--obcolor-font-secondary);
  margin: 20px 0;
}

.itemList {
  margin-bottom: 20px;
}

.selectContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  height: 40px;
  margin: 5px;
  padding: 0 10px 0 10px;
}

.entryInfo {
  display: flex;
  align-items: center;
  &:last-child {
    justify-content: flex-end;
  }

  .entryButton {
    font-size: 14px;
    padding: 5px;
  }

  .entryAmount {
    padding: 0 5px;
    width: 20px;
    text-align: center;
  }

  .checkbox {
    margin-right: 10px;
  }

  &:not(.selected) .entryButton,
  &:not(.selected) .entryAmount {
    color: var(--obcolor-font-secondary);
  }
}

.inputLabel {
  font-weight: bold;
  text-align:start;
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid var(--obcolor-background-1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;

  .fa-check {
    display: none;
  }
}

.selected .checkbox {
  border-color: var(--obcolor-ob-blue);
  background: var(--obcolor-ob-blue);

  .fa-check {
    display: block;
  }
}

.infoBtn {
  cursor: pointer;
  margin-left: 2px;
}
</style>