<template>
  <div class="startPlaying">
    <h1>Start Playing</h1>
    <img class="bgImg" :src="require('@/assets/onboarding/bg3.png')" alt="background">

    <div class="loading" v-if="loading">
      <ObLoading :size="60" />
    </div>
    <div v-else v-for="(slide, index) in slides" :key="index">
      <div class="containerBox">
        <img :src="require('@/assets/contestbadge/' + slide.icon + '.png')" class="contestIcon" :alt="slide.altText">
        <div class="containerTxt">
          <div class="titleContainer">
            <div class="title">{{ slide.title }}</div>
            <div class="available" :style="slide.isLocationValid ? 'background: var(--ob-color-green-bg);' : 'background: var(--ob-color-red-bg);'">
              <i class="fas fa-circle" :style="slide.isLocationValid ? 'color: var(--obcolor-green-2)' : 'color: var(--obcolor-red-2);'"></i>
              <span v-if="slide.isLocationValid" style="color: var(--obcolor-green-2);">Available</span>
              <span v-else style="color: var(--obcolor-red-2);">Not Available</span>
            </div>
          </div>

          <div class="description">{{ slide.description }}</div>
          <div v-if="!slide.isLocationValid" class="inaccessibleTxt">
            <i class="far fa-info-circle"></i>
            <span>This game isn’t accessible in your area, but you can still explore.</span>
          </div>
          <div class="btnContainer">
            <router-link :to="slide.playURL">
              <button class="ob-btn">
                {{ slide.isLocationValid ? 'Play' : 'Explore' }}
              </button>
            </router-link>
            <button class="ob-btn-grey" @click="openEligibilityModal(slide.contestType)">
              <i class="fas fa-map-marker-alt" style="padding-right: 10px; font-size: 14px;"></i>
              See Locations
            </button>
          </div>
        </div>
      </div>
    </div>
    <EligibilityMapModal />
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading';
import EligibilityMapModal from './EligibilityMapModal';
import EventBus from '@/event-bus';

export default {
  name: 'StartPlaying',

  components: {
    ObLoading,
    EligibilityMapModal,
  },

  data: function() {
    return {
      loading: true,
      slides: [
        {
          contestType: 'PROPS',
          icon: 'player_picks',
          altText: 'Player Picks',
          title: 'Player Picks',
          description: 'Select "More" or "Less" on your favorite players, and watch live to see if you win.',
          playURL: '/player-picks/lobby',
          isLocationValid: false,
        },
        {
          contestType: 'SalaryCap',
          icon: 'salarycap',
          altText: 'Salary Cap',
          title: 'Salary Cap',
          description: 'Build your dream roster and compete against others for cash prizes.',
          playURL: '/lobby',
          isLocationValid: false,
        },
        {
          contestType: 'LIGHTNING_LINEUPS',
          icon: 'lightning_lineups',
          altText: 'Lightning Lineups',
          title: 'Lightning Lineups',
          description: 'Spin, pick your line-up, and win up to 6x your entry. It’s that easy.',
          playURL: '/lightning-lineups/builder',
          isLocationValid: false,
        },
      ],
    };
  },

  created() {
    this.checkLocation();
  },

  methods: {
    checkLocation() {
      this.loading = true;
      ObAccountApi.checkLocation()
          .then((response) => {
            this.slides.forEach((slide) => {
              // Update the isLocationValid property based on the contest type
              switch (slide.icon) {
                case 'player_picks':
                  slide.isLocationValid = response.eligibleProps;
                  break;
                case 'salarycap':
                  slide.isLocationValid = response.eligibleDFS;
                  break;
                case 'lightning_lineups':
                  slide.isLocationValid = response.eligibleLL;
                  break;
                default:
                  break;
              }
            });
            this.loading = false;
          })
          .catch((_error) => {
            this.loading = false;
            // Do Nothing
          });
    },

    openEligibilityModal(contestType) {
      EventBus.$emit('OPEN_MAP_ELIGIBILITY_MODAL', contestType);
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

h1 {
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  padding: 50px 0 40px 0;
  max-width: 775px;
  margin: 0 auto;
}

.startPlaying {
  font-family: 'Archivo', sans-serif;
  position: relative;
  padding-bottom: 10px;
}

.bgImg {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}

.contestIcon {
  width: 85px;
}

.containerBox {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  background: var(--obcolor-background-2);
  padding: 35px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.available {
  padding: 0 15px;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  > span {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }
  .fa-circle {
    font-size: 8px;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
}

.containerTxt {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.btnContainer {
  display: flex;
  gap: 10px;
  button, a {
    width: 100%;
    flex: 1;
    height: 40px;
  }
}

.inaccessibleTxt {
  background: var(--obcolor-bg-box);
  border-radius: 5px;
  padding: 11px 25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  >i {
    width: 16px;
    font-size: 14px;
    margin-top: -2px;
  }
}
</style>