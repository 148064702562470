<template>
  <div class="ob-page">
    <div v-if="!leagues">
      <ObLoading />
    </div>
    <div v-else class="boxWrapper">
      <SponsorImage class="sponsorBox" height="135px" width="1000px"
        sponsorKey="lobby-banner"
      />
      <div class="emptyBox"></div>
      <div v-if="leagues" class="contestBoxes">
        <!-- Left Column - Filters -->
        <div class="filters">
          <div class="filterBox">
            <div class="createBtn ob-btn-grey" @click="openCreateContest">
              Create a Contest
            </div>
          </div>
          <div class="filterBox">
            <h3>Roster Format</h3>
            <p :class="{whiteText: filterList.rosterFormat == 'All'}" @click="setFilter('rosterFormat', 'All')">All</p>
            <p :class="{whiteText: filterList.rosterFormat == 'Standard'}" @click="setFilter('rosterFormat', 'Standard')">Standard</p>
            <p :class="{whiteText: filterList.rosterFormat == 'PrimeTimeFlex'}" @click="setFilter('rosterFormat', 'PrimeTimeFlex')">Prime Time Flex</p>
          </div>
          <div class="filterBox">
            <h3>Contest Type</h3>
            <p :class="{whiteText: filterList.contestType == 'All'}" @click="setFilter('contestType', 'All')">All</p>
            <p :class="{whiteText: filterList.contestType == 'Guaranteed'}" @click="setFilter('contestType', 'Guaranteed')">Guaranteed</p>
            <p :class="{whiteText: filterList.contestType == 'NonGuaranteed'}" @click="setFilter('contestType', 'NonGuaranteed')">Non-Guaranteed</p>
            <p :class="{whiteText: filterList.contestType == 'Satellite'}" @click="setFilter('contestType', 'Satellite')">Satellite</p>
          </div>
          <div class="filterBox">
            <h3>Entry Style</h3>
            <p :class="{whiteText: filterList.entryStyle == 'All'}" @click="setFilter('entryStyle', 'All')">All</p>
            <p :class="{whiteText: filterList.entryStyle == 'SingleEntry'}" @click="setFilter('entryStyle', 'SingleEntry')">Single Entry</p>
            <p :class="{whiteText: filterList.entryStyle == 'MultiEntry'}" @click="setFilter('entryStyle', 'MultiEntry')">Multi Entry</p>
          </div>
          <div class="filterBox">
            <h3>Entry Type</h3>
            <p :class="{whiteText: getMoneyStyleFilter() == 'All'}" @click="setFilter('moneyStyle', 'All')">All</p>
            <p :class="{whiteText: getMoneyStyleFilter() == 'RealMoney'}" @click="setFilter('moneyStyle', 'RealMoney')">$ (USD)</p>
            <p :class="{whiteText: getMoneyStyleFilter() == 'OwnersBucks'}" @click="setFilter('moneyStyle', 'OwnersBucks')">OwnersBucks</p>
            <p :class="{whiteText: getMoneyStyleFilter() == 'Tickets'}" @click="setFilter('moneyStyle', 'Tickets')">Tickets</p>
            <p :class="{whiteText: getMoneyStyleFilter() == 'Rookie'}" @click="setFilter('moneyStyle', 'Rookie')" v-if="isRookieUser()">Rookie Only</p>
          </div>
          <div class="filterBox">
            <h3>Entry Fee</h3>
            <div class="filterSliderContainer">
              <div class="sliderLabelContainer" style="left: 10px;">
                <div>{{ dollarFormat(feeFilterVals.range[0]) }}</div>
                <div class="sliderLabel">MIN</div>
              </div>
              <div class="filterSliderVertical">
                <RangeSlider class="filterSlider" :value="feeFilterRange" :selectableValues="feeSliderVals" />
              </div>
              <div class="sliderLabelContainer" style="right: 10px; text-align: right;">
                <div>{{ dollarFormat(feeFilterVals.range[1]) }}</div>
                <div class="sliderLabel">MAX</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Right Column - Contests -->
        <div class="contestBox">
          <div class="sportFilters">
            <div v-for="(sport, index) in getSportsList()" :key="index">
              <button class="btnContainer ob-btn-transparent" @click="setFilter('sport', sport.value)" :disabled="!sport.isActive"
                :class="!sport.isActive ? 'btnDisabled' : selectedSport(sport.value) ? 'btnSelected' : ''"
              >
                <i :class="sport.icon"></i>
                {{ sport.label }}
              </button>
            </div>
          </div>
          <div class="slateCarousel" v-if="showSlateFilter" :style="slateContainerStyling ? 'display: flex; justify-content: center;' : ''">
            <div class="slateCarouselContainer">
              <carousel :mouse-drag="false"
                navigation-enabled
                :value="0"
                :perPage="6"
                :navigation-next-label="navigationNext"
                :navigation-prev-label="navigationPrev"
                :pagination-enabled="false"
              >
                <slide v-if="filteredGameSlates.length > 0">
                  <div class="slateBox" @click="filterSlateGame(null)" :class="{activeSlate: !slateId}">
                    <div class="allSlates">All Slates ({{ filteredGameSlates.length }})</div>
                  </div>
                </slide>
                <slide v-for="(data, index) in filteredGameSlates" :key="index">
                  <div class="slateBox" @click="filterSlateGame(data.id)" :class="{activeSlate: slateId === data.id}">
                    <div>{{ data.name }}</div>
                    <div>{{ getContestStartDate(data.contestStart) }}</div>
                    <div @click.stop="showGames(data)">
                      {{ data.numberOfGames }}
                      <span v-if="data.numberOfGames == 1">Game</span>
                      <span v-else>Games</span>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
          <div v-if="filteredLeagues.length > 0">
            <div class="sortingHeader">
              <div @click="setSort('featured')">
                Contest
                <i class="fas" :class="selectedSort == 'featured' ? 'fa-chevron-down' : ''"></i>
              </div>
              <div @click="setSort('contestStartTime')">
                Starts
                <i v-if="selectedSort === 'contestStartTime'" class="fas" :class="highestToLowest ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
              </div>
              <div @click="setSort('numEntrants')">
                Entries
                <i v-if="selectedSort === 'numEntrants'" class="fas" :class="highestToLowest ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
              </div>
              <div @click="setSort('entryFee')">
                Entry Fee
                <i v-if="selectedSort === 'entryFee'" class="fas" :class="highestToLowest ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
              </div>
              <div @click="setSort('prizeValue')">
                Prize Pool
                <i v-if="selectedSort === 'prizeValue'" class="fas" :class="highestToLowest ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
              </div>
              <div></div>
            </div>
            <template v-for="contest in sortedLeagues">
              <LobbyContestBox v-show="contestIsInFilter(contest)" :key="contest.id"
                :league="contest" :entered="entered" :playerJoined="playerJoined[contest.id]"
              />
            </template>
          </div>

          <div v-else class="noContest">
            <p>No contests available with the selected criteria</p>
            <p>Try changing the sport or resetting the filters to see active contests.</p>

            <button class="btnContainer ob-btn-transparent" @click="resetAll()">Reset Filters</button>
          </div>
        </div>
      </div>
    </div>
    <GameSlateModal />
    <CreateContestModal />
  </div>
</template>

<script>
import LobbyContestBox from '@/views/Lobby/LobbyContestBox';
import ObLoading from '@/components/ObLoading';
import RangeSlider from '@/components/RangeSlider';
import GameSlateModal from '@/views/Lobby/GameSlateModalView';
import CreateContestModal from '@/views/Lobby/CreateContestModal/CreateContestModal';
import ObLeagueApi from '@/api/ObLeagueApi';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import {Carousel, Slide} from 'vue-carousel';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  name: 'LobbyView',

  components: {
    ObLoading,
    LobbyContestBox,
    RangeSlider,
    Carousel,
    Slide,
    GameSlateModal,
    CreateContestModal,
    SponsorImage,
  },

  data() {
    return {
      filtersCookieKey: 'lobbynew-filters-v1',
      leagues: null,
      gameSlates: null,
      entered: null,
      usernames: null,
      playerJoined: {},
      removingTimeouts: [],
      removingContests: {},
      feeFilterRange: [0, 11],
      feeSliderVals: [0, 1, 3, 5, 10, 25, 50, 100, 250, 500, 1000, 10000],

      selectedSort: 'featured',
      highestToLowest: true,

      sports: [
        {label: 'Featured', value: 'Featured', icon: 'fas fa-star', isActive: true},
        {label: 'NFL', value: 'NFL', icon: 'fas fa-football-ball', isActive: true},
        {label: 'NBA', value: 'NBA', icon: 'fas fa-basketball-ball', isActive: true},
        {label: 'MLB', value: 'MLB', icon: 'fas fa-baseball-ball', isActive: true},
        {label: 'NHL', value: 'NHL', icon: 'fas fa-hockey-puck', isActive: true},
      ],
      filterList: {
        sport: 'Featured',
        rosterFormat: 'All',
        contestType: 'All',
        entryStyle: 'All',
        moneyStyle: 'All',
      },
      slateId: null,
      showSlateFilter: true,

      // Contest Type Keys
      CTYPE: {
        SALARY_CAP: 'Tournament',
        HEAD_TO_HEAD: 'HEAD_TO_HEAD',
      },
    };
  },

  created() {
    // Clears format param if set
    const sportParam = this.$route.params.sport;
    if (this.$route.params.format != null) {
      this.$router.replace({params: {sport: sportParam, format: null}});
    }

    // Load filters and sport from cookie
    this.getFilterList();

    // If sport route is not set, validate value loaded from the cookie
    // Otherwise validate the value from the sport route
    if (!sportParam) {
      this.validateSportRoute(this.filterList.sport);
    } else {
      this.validateSportRoute(sportParam);
    }

    this.loadData();
    EventBus.$on('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$on('LEAGUE_JOINED', this.leagueJoined);
    EventBus.$on('LEAGUE_LEFT', this.leagueLeft);
    EventBus.$on('LEAGUE_CREATED', this.leagueCreated);
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('FSP_LOBBY');
    EventBus.$off('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$off('LEAGUE_JOINED', this.leagueJoined);
    EventBus.$off('LEAGUE_LEFT', this.leagueLeft);
    EventBus.$off('LEAGUE_CREATED', this.leagueCreated);

    for (const tIndex in this.removingTimeouts) {
      clearTimeout(this.removingTimeouts[tIndex].timeout);
    }
  },

  computed: {
    ...mapState(['contestCutoffs', 'rankNum', 'theme']),

    feeFilterVals() {
      if (!this.feeFilterRange || this.feeFilterRange.length < 2 ||
        !this.feeSliderVals || this.feeSliderVals.length <= this.feeFilterRange[1]
      ) {
        return {};
      }
      return {
        min: this.feeSliderVals[0],
        max: this.feeSliderVals[this.feeSliderVals.length - 1],
        range: [this.feeSliderVals[this.feeFilterRange[0]], this.feeSliderVals[this.feeFilterRange[1]]],
      };
    },

    sortedLeagues() {
      const allContests = this.leagues.slice();
      return allContests.sort(this.sortContests);
    },

    filteredLeagues() {
      const self = this;
      return this.sortedLeagues.filter(function(contest) {
        return self.contestIsInFilter(contest);
      });
    },

    filteredGameSlates() {
      return this.gameSlates.filter((contest) => {
        return contest.sport === this.filterList.sport;
      });
    },

    slateContainerStyling() {
      return this.filteredGameSlates.length > 5;
    },

    // Custom navigation of Vue carousel is not complete yet, this solution works for now
    navigationNext: function() {
      return `<i class="fas fa-chevron-right"></i>`;
    },
    navigationPrev: function() {
      return `<i class="fas fa-chevron-left"></i>`;
    },
  },

  watch: {
    feeFilterRange() {
      this.saveFiltersLocally();
    },

    leagues(newVal) {
      // Update isActive flag for sports whenever contest data changes
      // Sport is considered active if it has at least 1 contest
      const sportsWithContests = {
        'Featured': true,
      };
      for (const contest of newVal) {
        // Set sports to active only if they are salary cap
        if (contest.ctype == 'Tournament') {
          sportsWithContests[contest.sport] = true;
        }
      }
      for (const sport of this.sports) {
        sport.isActive = sportsWithContests[sport.value];
      }
      // If the currently selected sport is no longer active, move to featured
      const curSport = this.filterList.sport;
      if (!sportsWithContests[curSport]) {
        this.filterList.sport = 'Featured';
      }
    },

    '$route.params.sport'(newVal) {
      this.validateSportRoute(newVal);
    },

    '$route.params.format'(newVal) {
      if (newVal != null) {
        this.$router.replace({params: {format: null}});
      }
    },

    'filterList.sport'(newVal) {
      // Hide the slate filter until next tick to force it to re-render
      // This will reset the scroll position back to the left
      this.showSlateFilter = false;
      this.$nextTick(() => {
        this.showSlateFilter = true;
      });
      // Reset to all slates
      this.resetSlateId();
      // Update the route to match the selected sport
      if (this.$route.params.sport !== newVal) {
        this.$router.replace({params: {sport: newVal}});
      }
    },
  },

  methods: {
    validateSportRoute(newSport) {
      if (newSport == 'Featured') {
        this.filterList.sport = 'Featured';
        return;
      }
      // If the new route value is not valid, or is inactive move to featured
      for (const sport of this.sports) {
        if (sport.value === newSport && sport.isActive) {
          this.filterList.sport = newSport;
          return;
        }
      }
      this.$router.replace({params: {sport: 'Featured'}});
    },

    loadData() {
      ObLeagueApi.getLobby()
          .then((data) => {
            this.leagues = data.leagues;
            this.gameSlates = data.gameSlates;
            this.entered = data.enteredIds || {};
            this.$SocketController.subscribeToRoom('FSP_LOBBY');
          })
          .catch((error) => {
            throw (error);
          });
    },

    getSportsList() {
      // Display active sports first, inactives get moved to the end of the list
      const active = [];
      const inactive = [];
      for (const sport of this.sports) {
        if (sport.isActive) {
          active.push(sport);
        } else {
          inactive.push(sport);
        }
      }
      return active.concat(inactive);
    },

    selectedSport(sport) {
      return this.filterList.sport === sport;
    },

    // Open Game Slate Modal
    showGames(data) {
      EventBus.$emit('OPEN_GAMESLATE_MODAL', data);
    },

    openCreateContest() {
      EventBus.$emit('OPEN_CREATE_MODAL');
    },

    resetAll() {
      this.setFilter('rosterFormat', 'All');
      this.setFilter('contestType', 'All');
      this.setFilter('entryStyle', 'All');
      this.setFilter('moneyStyle', 'All');
      this.feeFilterRange = [0, 11];
    },

    resetSlateId() {
      this.slateId = null;
    },

    // Contest Sorting
    setSort(value) {
      if (this.selectedSort === value && value != 'featured') {
        this.highestToLowest = !this.highestToLowest;
      } else {
        this.highestToLowest = true;
      }
      this.selectedSort = value;
    },

    sortContests(a, b) {
      if (this.selectedSort === 'featured' && this.compareRank(a, b) != 0) {
        return this.compareRank(a, b);
      } else if (this.selectedSort === 'prizeValue' && this.comparePrizes(a, b) != 0) {
        return this.comparePrizes(a, b);
      } else if (this.selectedSort === 'contestStartTime' && this.compareDate(a, b) != 0) {
        return this.compareDate(a, b);
      } else if (this.selectedSort === 'numEntrants' && this.compareFilled(a, b) != 0) {
        return this.compareFilled(a, b);
      } else if (this.selectedSort === 'entryFee' && this.compareFee(a, b) != 0) {
        return this.compareFee(a, b);
      }
      return this.secondaryContestSorting(a, b);
    },

    secondaryContestSorting(a, b) {
      if (this.compareRank(a, b) != 0) {
        return this.compareRank(a, b);
      } else if (this.compareFilled(a, b) != 0) {
        return this.compareFilled(a, b, true);
      }
      return 0;
    },

    comparePrizes(a, b) {
      if (this.highestToLowest) {
        return this.getContestPrize(b) - this.getContestPrize(a);
      } else {
        return this.getContestPrize(a) - this.getContestPrize(b);
      }
    },

    getContestPrize(contest) {
      if (contest.numTicketPrizes > 0) {
        return contest.totalTicketPrizeValue + contest.prizeValue;
      }
      return contest.prizeValue;
    },

    compareDate(a, b) {
      if (a.contestStart && !b.contestStart) {
        return -1;
      } else if (b.contestStart && !a.contestStart) {
        return 1;
      } else if (a.contestStart && b.contestStart && this.highestToLowest) {
        return a.contestStart - b.contestStart;
      } else if (a.contestStart && b.contestStart && !this.highestToLowest) {
        return b.contestStart - a.contestStart;
      }
      return 0;
    },

    compareFilled(a, b, isSecondary) {
      if (this.highestToLowest || isSecondary) {
        return b.playersCurrent - a.playersCurrent;
      } else {
        return a.playersCurrent - b.playersCurrent;
      }
    },

    compareFee(a, b) {
      const feeA = this.getContestFee(a);
      const feeB = this.getContestFee(b);

      if (this.highestToLowest) {
        return feeB - feeA;
      } else {
        return feeA - feeB;
      }
    },

    getContestFee(contest) {
      return contest.feeValue;
    },

    compareRank(a, b) {
      // Lobby rank sort
      if (a.lobbyRank && !b.lobbyRank) {
        return -1;
      } else if (b.lobbyRank && !a.lobbyRank) {
        return 1;
      } else if (a.lobbyRank && b.lobbyRank) {
        return a.lobbyRank - b.lobbyRank;
      }

      const CTYPE = this.CTYPE;
      // Tournament contests next
      if (a.ctype == CTYPE.SALARY_CAP && b.ctype != CTYPE.SALARY_CAP) {
        return -1;
      } else if (b.ctype == CTYPE.SALARY_CAP && a.ctype != CTYPE.SALARY_CAP) {
        return 1;
      }

      return 0;
    },

    setFilter(key, value) {
      this.filterList[key] = value;
      this.saveFiltersLocally();
    },

    contestIsInFilter(contest) {
      const contestHasValidParams = this.contestHasValidParams(contest);
      const salaryCapContestFilter = this.filterSalaryCap(contest);
      const sportFilterActive = this.filteredSport(contest);
      const rosterFilterActive = this.filterRoster(contest);
      const typeFilterActive = this.filteredType(contest);
      const styleFilterActive = this.filteredStyle(contest);
      const moneyFilterActive = this.filteredMoney(contest);
      const feeFilter = this.filteredFee(contest);
      const slateFilter = this.filteredSlate(contest);
      return contestHasValidParams && salaryCapContestFilter && sportFilterActive && rosterFilterActive &&
        typeFilterActive && moneyFilterActive && feeFilter && styleFilterActive && slateFilter;
    },

    // Contest has params that should be shown in the lobby (Not that the contest itself is invalid, but that it shouldn't be shown in the lobby)
    contestHasValidParams(contest) {
      if (contest.ctype == this.CTYPE.SALARY_CAP && contest.contestStart && this.$moment().diff(this.$moment(contest.contestStart)) > 0) {
        return false;
      }
      return contest.state !== 'CANCELLED' && contest.state !== 'IN_PROGRESS';
    },

    filterSalaryCap(contest) {
      return contest.ctype === 'Tournament';
    },

    // Filter contests by Slate
    filteredSlate(contest) {
      if (this.slateId !== null) {
        return contest.gameSlateId === this.slateId;
      }
      return true;
    },

    filteredFee(contest) {
      return !(this.feeFilterVals && this.feeFilterVals.range != null &&
      (contest.feeValue < this.feeFilterVals.range[0] * 100 ||
      contest.feeValue > this.feeFilterVals.range[1] * 100));
    },

    filteredSport(contest) {
      if (!this.filterList.sport) {
        return true;
      } else if (this.filterList.sport === 'Featured') {
        return contest.lobbyRank;
      }
      return contest.sport === this.filterList.sport;
    },

    filterRoster(contest) {
      if (!this.filterList.rosterFormat || this.filterList.rosterFormat === 'All') {
        return true;
      } else if (this.filterList.rosterFormat === 'Standard') {
        return !this.isPrimeTimeFlex(contest);
      } else if (this.filterList.rosterFormat === 'PrimeTimeFlex') {
        return this.isPrimeTimeFlex(contest);
      }
    },

    filteredStyle(contest) {
      if (!this.filterList.entryStyle || this.filterList.entryStyle === 'All' || contest.ctype === this.CTYPE.LIGHTNING_LINEUPS) {
        return true;
      }
      if (this.filterList.entryStyle === 'SingleEntry') {
        return contest.entriesPerUser === 1;
      } else if (this.filterList.entryStyle === 'MultiEntry') {
        return contest.entriesPerUser > 1;
      }
    },

    filteredType(contest) {
      if (!this.filterList.contestType || this.filterList.contestType === 'All') {
        return true;
      }
      if (this.filterList.contestType === 'Guaranteed') {
        return contest.isGuaranteed;
      } else if (this.filterList.contestType === 'NonGuaranteed') {
        return !contest.isGuaranteed;
      } else if (this.filterList.contestType === 'Satellite') {
        return contest.isSatelliteContest;
      }
    },

    getMoneyStyleFilter() {
      // If filter is set to rookie but the user is not a rookie, default to show all
      const moneyStyle = this.filterList.moneyStyle;
      if (moneyStyle && moneyStyle === 'Rookie' && !this.isRookieUser()) {
        return 'All';
      }
      return moneyStyle;
    },

    isRookieUser() {
      return this.rankNum && this.rankNum === 1;
    },

    isPrimeTimeFlex(contest) {
      return contest.rosterFormat === 'Prime Time Flex';
    },

    filteredMoney(contest) {
      const moneyStyle = this.getMoneyStyleFilter();
      if (!moneyStyle || moneyStyle === 'All') {
        return true;
      }
      if (moneyStyle === 'RealMoney') {
        return contest.realMoney;
      } else if (moneyStyle === 'OwnersBucks') {
        return !contest.realMoney;
      } else if (moneyStyle === 'Tickets') {
        return this.$UserInfo.canUseTicket(contest);
      }
      return contest.rookieLeague;
    },

    isLocationValid(data) {
      const leagueId = data.json.id;
      ObLeagueApi.isContestValidForLocation(leagueId)
          .then((response) => {
            if (response.isValid === true) {
              this.leagues.push(data.json);
              this.checkForMissingSlates(data.json);
            }
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    socketUpdateContests(data) {
      const leagueId = data.json.id;
      const batchId = data?.json?.userCreatedBatchId;
      let index = null;

      // For grouped user created contests, match the contest using batch id and entry fee
      // The contest id can change as the grouped contests start to fill
      if (batchId) {
        const entryFee = data?.json?.fee;
        index = this.getContestIndexForBatch(batchId, entryFee);
      } else {
        index = this.getContestIndexForId(leagueId);
      }

      if (index != null) {
        // Merge in my entries, if data exists
        // My entry data is not included in lobby socket updates since data is sent to all users
        const existingContest = this.leagues[index];
        if (existingContest.myEntries != null) {
          data.json.myEntries = existingContest.myEntries;
        }
        if (existingContest.myEntriesRemaining != null) {
          data.json.myEntriesRemaining = existingContest.myEntriesRemaining;
        }

        // Update json in contest list
        this.$set(this.leagues, index, data.json);
        this.flashPlayerJoined(data.json.id, true);

        // If the league is full, remove it from the league list after 5 seconds
        if (data.json.playersCurrent == data.json.playersMax || !this.contestHasValidParams(data.json)) {
          const self = this;
          const timeoutInfo = {id: data.json.id,
            timeout: setTimeout(function() {
              self.removeLeague(data.json.id);
            }, 5000),
          };
          this.$set(this.removingContests, data.json.id, data.json);
          this.removingTimeouts.push(timeoutInfo);
        }
        this.checkForMissingSlates(data.json);
      } else if (data.UPDATE_TYPE === 'lobby_update_sc') {
        this.isLocationValid(data);
      } else {
        this.leagues.push(data.json);
        this.checkForMissingSlates(data.json);
      }
    },

    getContestIndexForId(contestId) {
      for (let i=0; i < this.leagues.length; i++) {
        if (this.leagues[i].id === contestId) {
          return i;
        }
      }
      return null;
    },

    getContestIndexForBatch(batchId, fee) {
      for (let i=0; i < this.leagues.length; i++) {
        const contest = this.leagues[i];
        if (contest.userCreatedBatchId === batchId && contest.fee === fee) {
          return i;
        }
      }
      return null;
    },

    handleSocketUpdate(data) {
      if (!data.json || !data.UPDATE_TYPE) {
        return;
      }
      if (data.UPDATE_TYPE !== 'lobby_update' && data.UPDATE_TYPE !== 'lobby_update_sc') {
        return;
      }

      if (data.json && data.json.rookieLeague && this.rankNum != 1) {
        return;
      }

      // Ignore socket updates for contests that are in the middle of being removed or are invalid
      if (this.removingContests[data.json.id] != null) {
        return;
      }

      this.socketUpdateContests(data);
    },

    checkForMissingSlates(newContest) {
      // If the newly added contest has a game slate
      // Check if it exists in our game slate array and that the slate data has not changed, otherwise reload the data
      const newSlateId = newContest.gameSlateId;
      const newContestStart = newContest.contestStart;
      const gameSlateGames = newContest.gameSlate;
      const contestGamesCount = gameSlateGames != null ? gameSlateGames.length : 0;
      if (!newSlateId) {
        return;
      }
      for (const slate of this.gameSlates) {
        if (slate.id === newSlateId) {
          // Slate already exists
          // Check that the games count and contest start has not changed, if it has reload the data
          if (slate.numberOfGames === contestGamesCount && slate.contestStart === newContestStart) {
            return;
          }
        }
      }
      // Slate does not exist or the slate games count has changed, reload lobby data
      this.loadData();
    },

    removeLeague(id) {
      this.leagues = this.leagues.filter((league) => {
        return league.id != id;
      });

      const timeoutInfo = this.removingTimeouts.find((tInfo) => {
        return tInfo.id == id;
      });

      if (timeoutInfo != null) {
        clearTimeout(timeoutInfo.timeout);

        this.removingTimeouts = this.removingTimeouts.filter((tInfo) => {
          return tInfo.id != id;
        });
      }
    },

    flashPlayerJoined(key, change) {
      const self = this;
      this.$set(this.playerJoined, key, change);
      setTimeout(() => {
        self.$set(self.playerJoined, key, null);
      }, 4000);
    },

    leagueJoined(leagueId) {
      this.$set(this.entered, leagueId, true);
    },

    leagueLeft(leagueId) {
      this.$set(this.entered, leagueId, false);
    },

    leagueCreated(leagueId) {
      this.leagueJoined(leagueId);
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    getFilterList() {
      let filterCookie = localStorage.getItem(this.filtersCookieKey);
      if (filterCookie) {
        filterCookie = JSON.parse(filterCookie);

        for (const key in this.filterList) {
          if (key != 'feeRange' && filterCookie[key]) {
            this.filterList[key] = filterCookie[key];
          }
        }

        if (filterCookie.feeRange) {
          this.feeFilterRange = filterCookie.feeRange;
        }
      }
    },

    saveFiltersLocally() {
      const filtersPlusRanges = this.filterList;
      this.$set(filtersPlusRanges, 'feeRange', this.feeFilterRange);
      localStorage.setItem(this.filtersCookieKey, JSON.stringify(filtersPlusRanges));
    },

    filterSlateGame(id) {
      this.slateId = id;
    },

    getContestStartDate(startDate) {
      const sDate = this.$moment(startDate).format('ddd MMM D,');
      const today = this.$moment().format('ddd MMM D,');
      if (sDate === today) {
        return 'Today ' + this.$moment(startDate).format('h:mma');
      }
      return sDate + ' ' + this.$moment(startDate).format('h:mma');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.ob-page {
  padding-top: 0;
}

.emptyBox {
  height: 15px;
  background-color: var(--obcolor-bg);
}

.sponsorBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--obcolor-bg);
  padding-top: 15px;
}

.sportFilters {
  display: flex;
  gap: 8px;

  .btnContainer {
    color: var(--obcolor-text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      padding-right: 8px;
    }
  }
  .btnSelected {
    border: 1px solid var(--obcolor-ob-blue);
    color: var(--obcolor-text-primary) !important;
  }
  > div {
    display: flex;
  }
  .btnContainer.btnDisabled {
    cursor: not-allowed;
    order: 1;
    opacity: 0.5;
  }
}

.slateCarousel {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid var(--obcolor-background-6);
}

.slateSelectBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--obcolor-background-6);
  border-radius: 8px 8px 0 0;
  min-width: 1255px;
  height: 81px;
  border-bottom: 4px solid var(--obcolor-bg);

  img {
    width: 180px;
    cursor: pointer;
    transition: 0.2s all;
    &:hover {
      filter: brightness(1.25);
    }
  }

  i {
    padding-right: 15px;
    font-size: 12px;
  }

  h2 {
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
  }

  h2,
  p,
  i {
    margin: 0;
    align-self: center;
  }

  > div {
    display: flex;
  }

}

::v-deep {
  .ddBox {
    width: 240px;
    height: 55px;
    background: var(--obcolor-background-4);
    border: 1px solid var(--obcolor-alt-text);
    border-radius: 4px;
    span {
      font-size: 16px;
    }
  }
  .ddBox:hover {
    background: var(--obcolor-background-2);
  }
  .selectContainer {
    top: 70px;
    border: 1px solid var(--obcolor-alt-text);
    border-top: none;
    border-bottom: none;
  }
  .selectEntry {
    display: flex;
    font-size: 16px;
    line-height: 21px;
    padding: 10px 10px 10px 6px;
    border-bottom: 1px solid var(--obcolor-alt-text);
  }
  .selectedEntry {
    background: var(--obcolor-background-1);
  }
  .fa-chevron-down {
    margin-right: 10px;
    font-size: 15px;
  }
  .fa-check {
    display: none;
  }
  .selectEntry span {
    align-self: center;
    font-weight: 400;
  }
  .obLoadingAnimation {
    padding-top: calc(var(--ob-nav-offset) + 50px);
  }
}

.filterBox {
  background-color: var(--obcolor-background-6);
  border-top: var(--obcolor-alt-text) 2px solid;
  box-sizing: border-box;
  padding: 15px 20px 10px 20px;
}

.createBtn {
  width: 100%;
  margin: 0 auto 5px auto;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  background: var(--obcolor-ob-blue);
  transition: 0.2s;
  color: var(--obcolor-alt-text);

  &:hover {
    filter: brightness(1.25) !important;
    background: var(--obcolor-ob-blue) !important;
  }
}

.filters {
  min-width: 255px;
  max-width: 255px;

  .filterBox:nth-child(1) {
    border-top: none;
    border-radius: 8px 8px 0 0;
  }

  h3,
  p {
    margin: 0;
    font-size: 14px;
    line-height: 13px;
  }

  h3 {
    font-weight: 700;
    color: var(--obcolor-text-primary);
    padding-bottom: 10px;
  }

  p {
    font-weight: 500;
    padding-bottom: 10px;
    color: var(--obcolor-text-secondary);
    padding-left: 12px;
    cursor: pointer;
    transition: all 0.2s;

    &:focus,
    &:hover {
      color: var(--obcolor-text-primary);
    }
  }
  .filterSliderContainer {
    color: var(--obcolor-text);
    display: flex;
    width: 87%;
    justify-content: space-between;
    padding: 10px 10px 25px 10px;
    position: relative;
    margin: auto;
  }
  .sliderLabelContainer {
    position: absolute;
    top: 0;
    font-size: 14px;
  }
  .sliderLabel {
    color: var(--obcolor-text-secondary);
    font-size: 12px;
    margin-top: 3px;
  }
  .filterSliderVertical {
    flex: 1;
    margin-top: 32px;
  }
  .whiteText {
    color: var(--obcolor-text-primary);
    font-weight: 600;
  }
}

.sortingHeader {
  padding: 10px 10px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  background: var(--obcolor-background-2);
  font-size: 14px;
  gap: 32px;

  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    i {
      padding-left: 4px;
      font-size: 10px;
      font-weight: 400;
    }

    .selectedSort {
      display: block;
    }
    &:hover {
      color: var(--obcolor-ob-blue);
    }
  }

  div:nth-child(1) {
    max-width: 400px;
    min-width: 400px;
    flex: 1;
  }
  div:nth-child(2) {
    width: 100px;
  }
  div:nth-child(3) {
    width: 89px;
  }
  div:nth-child(4) {
    width: 90px;
  }
  div:nth-child(5) {
    width: 80px;
  }
  div:nth-child(6) {
    width: 124px;
  }
}

.contestBoxes {
  display: flex;
  gap: 10px;

  .contestBox {
    flex: 1;
    min-width: 1160px;
    margin-top: 2px;
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }
}

.headerInfo {
  text-align: center;
}

.noSlates {
  color: var(--obcolor-text-secondary);
}

.slateCarouselContainer {
  ::v-deep {
    .VueCarousel {
      min-width: 1103px;
      max-width: 1103px;
    }

    // Adds slight spacing so the buttons aren't touching the tiles
    .VueCarousel-navigation-prev {
      margin-left: -5px;
      margin-top: 8px;
      max-height: 75px;
    }
    .VueCarousel-navigation-next {
      margin-right: -5px;
      margin-top: 8px;
      max-height: 75px;
    }

    // Remove the outline from selected buttons
    .VueCarousel-navigation-button:focus {
      outline: none;
    }

    // Adjust colour and increase click area to full height
    .VueCarousel-navigation-button {
      color: var(--obcolor-text) !important;
      height: 100%;
    }

    // Adds a pointer and hover highlight for active buttons only
    .VueCarousel-navigation-button:not(.VueCarousel-navigation--disabled) {
      cursor: pointer;
    }
    .VueCarousel-navigation-button:hover:not(.VueCarousel-navigation--disabled) {
      color: var(--obcolor-ob-blue) !important;
    }
  }
}

.slateBox {
  width: 155px;
  height: 55px;
  background: var(--obcolor-background-4);
  border-radius: 4px;
  padding: 10px;
  margin: 16px 8px 0px 0;
  border: 1px solid var(--obcolor-background-4);
  cursor: pointer;

  .allSlates {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &:hover,
  &:focus {
    background: var(--obcolor-background-2);
  }

  &:hover > div,
  &:focus > div {
    color: var(--obcolor-text);
  }

  > div {
    margin: 0;
    font-size: 12px;
    line-height: 19px;
    padding-left: 0;
    color: var(--obcolor-text-secondary);
  }

  > div:nth-child(1) {
    font-size: 14px;
    width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -3px;
  }

  > div:nth-child(2) {
    padding-bottom: 3px;
  }

  > div:nth-child(3) {
    text-decoration: underline;
    width: fit-content;

    &:hover,
    &:focus {
      color: var(--obcolor-ob-blue);
    }
  }
}

.activeSlate {
  border: 1px solid var(--obcolor-ob-blue);
  font-weight: 700;

  div {
    color: var(--obcolor-text-primary);
  }
}

.noContest {
  background-color: var(--obcolor-background-6);
  border-radius: 4px;
  padding: 70px 0 70px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--obcolor-text-secondary);
    margin: 0 0 5px 0;
  }

  .btnContainer {
    width: fit-content;
    margin: 20px auto 0 auto;
    color: var(--obcolor-text-primary);
    padding: 8px;
    background-color: var(--obcolor-background-2);
  }
}
</style>