import Api from '@/api/ObBaseApi';

export default {
  getSponsor(locationKey) {
    return Api()
        .get('/sponsorship/getSponsor?locationKey=' + locationKey)
        .then((response) => {
          return response.data;
        });
  },
};
