<template>
  <div>
    <div class="componentTitle">Details</div>
    <div class="componentContent">
      <div class="contestSection" v-if="league.creatorName">
        <div class="overviewInfoTitle">Created by:</div>
        <div>{{ league.creatorName }}</div>
      </div>
      <div class="contestSection" style="width: 100%;">
        <div class="overviewInfoTitle">Roster:</div>
        <div class="rosterTableContainer">
          <table class="rosterTable" aria-label="League Overview">
            <tr>
              <th>Position</th>
              <th style="text-align: center;">Spots</th>
              <th v-if="league.format != 'DAILY'">
                Limits <Tooltip pos="bottom" :textKey="'LeagueModal_' + (league.ctype === 'Tournament' ? 'Tournament' : 'H2H') + '_GameLimits'" />
              </th>
            </tr>
            <tr v-for="slot in league.draftRoster.roster" :key="slot.short">
              <td>{{ slot.name }}</td>
              <td style="text-align: center;">x{{ slot.num }}</td>
              <td v-if="league.format != 'DAILY'">{{ slot.maxGames }} Games</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="contestSection">
        <div class="overviewInfoTitle">Type:</div>
        <div>{{ league.ctype === 'Tournament' ? (league.format === 'DAILY' ? 'Daily Tournament' : 'Weekly Tournament') : "Head-2-Head" }}</div>
      </div>

      <!-- NFL Contest Duration -->
      <div class="contestSection" v-if="isNflTournament">
        <div class="overviewInfoTitle">Duration:</div>
        <div v-if="league.contestStart" style="display: flex">
          {{ getSalaryContestStartDate(league.contestStart) }} -
          {{ getContestStartDate(league.days[league.days.length - 1]) }}
        </div>
      </div>

      <!-- Contest Start -->
      <div v-else class="contestSection">
        <template v-if="league.ctype === 'Tournament'">
          <div class="overviewInfoTitle">Contest Start:</div>
          <div v-if="league.contestStart" style="display: flex">
            {{ getSalaryContestStartDate(league.contestStart) }}
            <Tooltip pos="bottom" textKey="LeagueModal_Tournament_ContestStart" />
          </div>
        </template>
        <template v-else>
          <div class="overviewInfoTitle">Draft Start:</div>
          <div>{{ getDraftStart() }}</div>
        </template>
      </div>

      <!-- Fee and Prizes -->
      <div class="contestSection">
        <div class="overviewInfoTitle">Entry Fee:</div>
        <div>{{ league.fee.removeCentsIfZero() }}</div>
      </div>
      <div class="contestSection">
        <div class="overviewInfoTitle">Prize Pool:</div>
        <SalaryContestPrizePool :contest="league" />
      </div>

      <div class="contestSection">
        <div class="overviewInfoTitle">Entrants:</div>
        <div>{{ league.playersMax }}</div>
      </div>

      <!-- Salary Cap Contests -->
      <template v-if="league.ctype === 'Tournament'">
        <div class="contestSection">
          <div class="overviewInfoTitle">Salary Cap:</div>
          <div>${{ Intl.NumberFormat('en-US').format(league.salaryCap) }}</div>
        </div>
        <div class="contestSection">
          <div class="overviewInfoTitle">Multi Entry:</div>
          <div>{{ league.myEntries || 0 }} of {{ league.entriesPerUser }}</div>
        </div>
        <div class="contestSection" v-if="league.format == 'WEEKLY'">
          <div class="overviewInfoTitle">Swap Fee:</div>
          <div style="display: flex">
            {{ league.swapFeePct }}%
            <Tooltip pos="bottom" textKey="LeagueModal_Tournament_SwapFee" />
          </div>
        </div>
      </template>

      <!-- H2H Contests -->
      <template v-else>
        <template v-if="league.draftStart != 'Scheduled'">
          <div class="contestSection">
            <div class="overviewInfoTitle">Cutoff Time:</div>
            <ContestCountdown v-if="getContestCutoff(league, true)"
              :end="getContestCutoff(league, true)" :end2="getContestCutoff(league, false)" :min="0"
              :serverOffset="0"
            />
            <Tooltip pos="bottom" textKey="LeagueModal_H2H_Cutoff" />
          </div>
          <div class="contestSection">
            <div class="overviewInfoTitle">Player Swaps:</div>
            <div>2 per week excluding injured players</div>
            <Tooltip pos="bottom" textKey="LeagueModal_H2H_Swaps" />
          </div>
        </template>
        <div class="contestSection" v-else>
          <div class="overviewInfoTitle">Contest Start:</div>
          <div v-if="league.scheduledStartDate">
            {{ getContestStartDate(league.scheduledStartDate) }}
          </div>
        </div>
        <div class="contestSection">
          <div class="overviewInfoTitle">Pick Clock:</div>
          <div>{{ league.pickTime }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import ContestCountdown from '@/components/timers/ContestCountdown.vue';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  props: {
    league: Object,
  },

  components: {
    ContestCountdown,
    Tooltip,
    SalaryContestPrizePool,
  },

  computed: {
    ...mapState(['contestCutoffs']),

    isNflTournament() {
      return this.league.ctype === 'Tournament' && this.league.sport == 'NFL';
    },
  },

  methods: {
    // Same code as lobby, move to a utils class??
    getCutoffDate(league) {
      if (this.contestCutoffs) {
        return this.contestCutoffs[league.sport][league.playersMax].startDate;
      }
      return '-';
    },

    getNextCutoffDate(league) {
      if (this.contestCutoffs) {
        return this.contestCutoffs[league.sport][league.playersMax].nextDate;
      }
      return '-';
    },

    getContestCutoff(league, firstCutoff) {
      if (this.contestCutoffs) {
        const cutoffData = this.contestCutoffs[league.sport][league.playersMax];
        const cutoff = firstCutoff ? cutoffData.startCutoff : cutoffData.nextCutoff;
        if (cutoff) {
          return cutoff;
        }
      }
      return 0;
    },

    getDraftStart() {
      if (this.league.draftStart == 'Scheduled') {
        return this.isToday(this.$moment(this.league.draftTime)) ? 'Today, ' + this.$moment(this.league.draftTime).format('h:mm A') :
          this.$moment(this.league.draftTime).format('ddd MMM Do, h:mm A');
      } else {
        return this.league.draftStart;
      }
    },

    getSalaryContestStartDate(startTS) {
      return this.$moment(startTS).format('ddd MMM Do, h:mm A');
    },

    getContestStartDate(startDate) {
      if (this.isToday(startDate)) {
        return 'Today';
      }
      return this.$moment(startDate, 'YYYY-MM-DD').format('ddd MMM Do');
    },

    isToday(date) {
      const sDate = this.$moment(date, 'YYYY-MM-DD').format('ddd MMM Do');
      const today = this.$moment().format('ddd MMM Do');
      return sDate === today;
    },

    goToPrizes() {
      EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'payouts', 'smooth');
    },
  },
};
</script>

<style lang="scss" scoped>
.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 10px 18px;
  background: var(--obcolor-background-4);
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
}

.componentContent {
  padding: 10px 15px;
  font-size: 14px;
}

.contestSection {
  display: flex;
  padding: 5px 10px;
  align-items: flex-start;
  min-width: calc(50% - 20px);
}

.overviewInfoTitle {
  font-size: 14px;
  // font-weight: bold;
  color: var(--obcolor-font-secondary);
  min-width: 110px;
}

.rosterTableContainer {
  border: 2px solid var(--obcolor-background-2);
  margin-bottom: 3px;
}

.rosterTable {
  // width: 100%;
  font-size: 12px;

  th, td {
    padding: 6px 10px;
    min-width: 70px;
  }

  th {
    font-weight: bold;
    background: var(--obcolor-background-2);
  }

  td {
    background: var(--obcolor-background-4);
  }
}
</style>