<template>
  <form @submit.prevent="validateCredentials" class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
    </div>
    <h1>Create Login Info</h1>
    <div class="inputText">Email</div>
    <input v-model="email" placeholder="Enter your email" name="email"
      type="email" :class="{errorBorder: emailError}"
    >
    <ErrorPanel :message="emailError" />

    <div style="display: flex; margin-top: 40px;">
      <div class="inputText">Password</div>
      <Tooltip pos="top" textKey="Password_Requirements" />
    </div>
    <div class="passwordContainer">
      <input v-model="password" placeholder="Enter your password" :type="showPassword ? 'text' : 'password'"
        @keyup="scorePassword()" autocomplete="new-password"
        :class="{errorBorder: passwordError}"
      >
      <i v-if="showPassword" @click="toggleShowPassword" class="fas fa-eye"></i>
      <i v-else @click="toggleShowPassword" class="fas fa-eye-slash"></i>
    </div>

    <div id="passStrength" class="passRequirements">
      <div class="passStrengthContainer">
        <div class="passStrength" :style="passwordStrength < 1 ? 'background: var(--obcolor-font-secondary)' : 'background: var(--obcolor-red);'"></div>
        <div class="passStrength" :style="passwordStrength < 2 ? 'background: var(--obcolor-font-secondary);' : 'background: orange;'"></div>
        <div class="passStrength" :style="passwordStrength < 3 ? 'background: var(--obcolor-font-secondary);' : 'background: var(--obcolor-green);'"></div>
        <div class="passStrength" :style="passwordStrength < 4 ? 'background: var(--obcolor-font-secondary);' : 'background: var(--obcolor-green);'" style="margin-right: 0;"></div>
      </div>
      <div class="passText">{{ passwordStrengthText }}</div>
    </div>
    <ErrorPanel :message="passwordError" style="margin-top: 5px;" />

    <button v-if="validating" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>
      Validating...
    </button>
    <button v-else class="ob-btn" :disabled="isDisabled"
      @click="validateCredentials()"
    >
      Continue
    </button>
  </form>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import EventBus from '@/event-bus';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    Tooltip,
    ErrorPanel,
  },

  data() {
    return {
      email: '',
      password: '',
      passwordStrength: 0,
      showPassword: false,
      validatedEmail: '',
      validatedPassword: '',
      validating: false,
      errors: null,
    };
  },

  computed: {
    isDisabled() {
      if (!this.email || !this.password) {
        return true;
      }
      // If validation fails, the values must be changed before you can re-submit
      if (this.emailError && this.validatedEmail == this.email) {
        return true;
      }
      if (this.passwordError && this.validatedPassword == this.password) {
        return true;
      }
      return false;
    },

    emailError() {
      return this.errors?.email;
    },

    passwordError() {
      return this.errors?.password;
    },

    passwordStrengthText() {
      if (!this.password) {
        return 'Password Strength';
      } else if (this.passwordStrength == 0) {
        return 'Password Strength';
      } else if (this.passwordStrength == 1) {
        return 'Very weak';
      } else if (this.passwordStrength == 2) {
        return 'Weak';
      } else if (this.passwordStrength == 3) {
        return 'Good';
      } else {
        return 'Strong';
      }
    },
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    scorePassword() {
      if (!this.password) {
        this.passwordStrength = 0;
        return;
      }
      const pass = this.password;
      let score = 0;

      if (RegExp(/[^A-Za-z\d]/g).test(pass)) {
        score += 1; // Special Character
      }
      if (RegExp(/(.*\d.*)/g).test(pass)) {
        score += 1; // Number
      }
      if (RegExp(/(.*[A-Z].*)/g).test(pass)) {
        score += 1; // Uppercase
      }
      if (pass.length > 10) {
        score += 1;
      }
      this.passwordStrength = score;
    },

    validateCredentials() {
      if (this.validating || this.isDisabled) {
        return;
      }
      this.validating = true;
      this.validatedEmail = this.email;
      this.validatedPassword = this.password;
      ObAccountApi.validateCredentials(this.email, this.password)
          .then((_response) => {
            // Success
            this.validating = false;
            EventBus.$emit('UPDATE_SIGNUP_DATA', 'email', this.email);
            EventBus.$emit('UPDATE_SIGNUP_DATA', 'password', this.password);
            EventBus.$emit('ON_BOARDING_NEXT');
          })
          .catch((error) =>{
            // Handle Errors
            this.errors = error?.response?.data?.errors;
            this.validating = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  button {
    margin-top: 50px;
    height: 60px;
  }

  .inputText {
    padding: 0 0 10px 0;
  }
}

.passwordContainer {
  position: relative;
  display: flex;

  input {
    flex: 1;
  }
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.passStrengthContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.passRequirements {
  height: 40px;
  border-radius: 4px;
  margin-top: 4px;
}
.passStrength {
  width: 170px;
  height: 10px;
  margin-right: 2px;
  border-radius: 4px;
}
.passText {
  text-align: right;
  padding-top: 10px;
}
</style>