<template>
  <div class="battlePassSummary">
    <div class="titleSection">
      <div class="battlePassImg">
        <img :src="themeIcon" alt="Ownersbox">
      </div>
      <div class="titleRemaining">
        <div class="titleText">{{ battlePass.name || 'Battle Pass' }}</div>
        <div class="remainingText">
          <CountdownTimer class="countdownText" :end="battlePass.endDate" :min="0"
            :textClock="true" :clockFormat="true"
          />
          <span>remaining</span>
        </div>
      </div>
    </div>
    <div class="levelSection">
      <UserLogo class="userLogo" :id="userId" :size="45" />
      <LevelProgress class="levelProgressSection" :userInfo="battlePass.userInfo" />
    </div>
    <div class="nextRewardContainer">
      <template v-if="nextReward">
        <div class="rewardText">
          <img v-if="getRewardIcon(nextReward)" :src="getRewardIcon(nextReward)" alt="Reward">
          <div class="rewardTextList">
            <div v-for="(reward, index) in nextReward.labels" :key="index"
              :class="{challengeComplete: nextReward.isCompleted}"
            >
              {{ reward }}
              {{ index < nextReward.labels.length - 1 ? ',' : '' }}
            </div>
          </div>
        </div>
        <div class="nextRewardTitle">{{ nextRewardText }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import LevelProgress from '@/components/battlepass/LevelProgress';
import CountdownTimer from '@/components/CountdownTimer';
import UserLogo from '@/components/UserLogo';
import {getRewardIcon, nextRewardText} from '@/utils/shared/BattlePassUtils';
import {mapState} from 'vuex';

export default {
  props: {
    battlePass: {type: Object, default: null},
  },

  components: {
    LevelProgress,
    CountdownTimer,
    UserLogo,
  },

  computed: {
    ...mapState(['userId', 'theme']),

    nextReward() {
      return this.battlePass?.userInfo?.nextReward;
    },

    nextRewardText() {
      if (!this.battlePass?.userInfo) {
        return 'Next Reward';
      }
      return nextRewardText(this.battlePass?.userInfo);
    },

    themeIcon() {
      const themeColour = this.theme == 'dark' ? 'white' : 'black';
      return require('@/assets/obicon_' + themeColour + '.png');
    },
  },

  methods: {
    getRewardIcon,
  },
};
</script>

<style lang="scss" scoped>

.battlePassSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.boxBody {
  padding: 10px;
}

.battlePassImg {
  height: 42px;
  margin-right: 10px;

  img {
    height: 100%;
  }
}
.titleSection {
  flex: 1;
  padding-right: 10px;
}
.titleSection, .levelSection {
  display: flex;
  align-items: center;

  .titleText {
    font-weight: bold;
    font-size: 16px;
  }

  .remainingText {
    display: flex;
    gap: 4px;
    font-size: 14px;
    color: var(--obcolor-font-secondary);
    white-space: nowrap;

    > div {
      white-space: nowrap;
    }
  }
}

.levelProgressSection {
  width: 240px;
  padding-left: 10px;
}

.nextRewardContainer {
  padding-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;

  .nextRewardTitle {
    padding-top: 3px;
    color: var(--obcolor-font-secondary);
  }

  .rewardText {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  img {
    height: 20px;
    margin-top: -2px;
  }

  .rewardTextList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
    min-width: auto;
    font-size: 14px;
  }
}
</style>