<template>
  <div class="dropdownContainer">
    <div class="ddBox" @click.stop="selectOpen = !selectOpen">
      <img v-if="imageUrls && imageUrls[value]" :src="imageUrls[value]" :alt="label"
        class="optionIcon"
      >
      <span v-if="label" style="font-weight: bold;">{{ label }}</span>
      <span class="selectedValue">{{ getSelectedText() }}</span>
      <i class="fas fa-chevron-down" style="margin-left: 2px;"></i>
    </div>
    <div v-show="selectOpen" class="selectContainer ob-scroll" v-click-outside="() => this.selectOpen = false"
      style="max-height: 400px; overflow: auto;"
    >
      <div v-for="(option, index) in options" :key="'dd'+option" class="selectEntry"
        :class="option === value ? 'selectedEntry' : ''"
        @click.stop="selectOption(option)"
      >
        <img v-if="imageUrls && imageUrls[option]" :src="imageUrls[option]" :alt="option"
          class="optionIcon"
        >
        <span v-if="text && text[index]">{{ text[index] }}</span>
        <span v-else>{{ option }}</span>
        <i v-if="option === value" class="fas fa-check" style="margin-left: 6px;"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },

  props: {
    label: String,
    value: String, // 'value' used to work with v-model
    options: Array,
    text: Array, // Optional text labels for the options
    imageUrls: Object, // Optional images for each option, where key is the value of the option
  },

  data() {
    return {
      selectOpen: false,
    };
  },

  methods: {
    getSelectedText() {
      if (!this.text) {
        return this.value;
      }
      let index = 0;
      for (const opt of this.options) {
        if (opt === this.value && this.text[index]) {
          return this.text[index];
        }
        index++;
      }
      return this.value;
    },

    selectOption(opt) {
      // Emit to update v-model value
      this.$emit('input', opt);
      this.selectOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdownContainer {
  display: inline-block;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  // Text not selectable
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ddBox {
  border: 1px solid var(--obcolor-background-1);
  padding: 6px;
  height: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  span {
    margin-right: 3px;
  }
}

.ddBox:hover {
  background: var(--obcolor-background-2);
}

.selectedValue {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectContainer {
  z-index: 70;
  position: absolute;
  min-width: calc(100% - 2px);
  left: 0;
  top: 28px;
  background: var(--obcolor-background-4);
  border: 1px solid var(--obcolor-background-1);
  white-space: nowrap;
  cursor: pointer;
}

.selectEntry:nth-child(odd) {
  background: var(--obcolor-background-3);
}

.selectEntry:hover {
  background: var(--obcolor-background-2);
}

.selectEntry {
  padding: 6px;
}

.optionIcon {
  width: 60px;
  height: auto;
  padding-right: 20px;
}
</style>
