<template>
  <div class="container" :style="getSize() + ' ' + getBorder()">
    <div class="ob-image-circle">
      <img :src="getTeamLogo()" alt="User Logo"
        @error="$event.target.src = require('@/assets/defaultTeamLogo.png')"
      >
    </div>
    <img v-if="showRank" class="rankImg" :style="'width:'+ size * 0.55 +'px'"
      :src="getRankImg()" :alt="`Rank ${rankNum}`"
    >

    <div v-if="status != null" :class="'statusCircle ' + getStatusClass()"></div>
    <div v-if="rank != null" class="posCircle" :style="getRankColors()">
      <i v-if="rank === 'DRAFT_TURN'" class="fas fa-stopwatch"></i>
      <span v-else>{{ rank }}</span>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {

  props: {
    id: {type: String, default: null},
    sport: {type: String, default: null},
    rank: {type: String, default: null},
    rankNum: {type: Number, default: 1},
    showRank: {type: Boolean, default: false},
    rankColor: {type: String, default: 'blue'},
    dark: {type: Boolean, default: null},
    size: {type: Number, default: null},
    borderWidth: {type: Number, default: null},
    status: {type: String, default: null},
  },

  computed: {
    ...mapState(['theme', 'userLogoVersion']),
  },

  methods: {

    getRankImg() {
      return require('@/assets/ranks/'+ this.theme + '_' + this.rankNum + '_' + this.rankColor + '.png');
    },

    getRankColors() {
      let style = '';
      if (this.dark) {
        style += 'border: 2px solid black;';
      }
      if (this.rankColor) {
        style += 'background:' + this.rankColor;
      }
      return style;
    },

    getSize() {
      if (this.size == null) {
        return 'width: 50px; height: 50px; min-width: 50px; min-height: 50px;';
      }
      return 'width: ' + this.size + 'px; height: ' + this.size + 'px;' + 'min-width: ' + this.size + 'px; min-height: ' + this.size + 'px;';
    },

    getBorder() {
      if (this.borderWidth == null) {
        return '';
      }
      return 'border: ' + this.borderWidth + ' solid #000';
    },

    getTeamLogo() {
      let bucketName = 'fsp-user-images';
      switch (location.hostname) {
        case 'localhost':
        case 'ownersbox04.appspot.com':
          // bucketName stays as default
          break;
        case 'www.ownersbox.com':
        case 'ownersbox.com':
        case 'app.ownersbox.com':
        case 'beta.ownersbox.com':
        case 'ownersbox-production-01.appspot.com':
          bucketName = 'fsp-user-images-prod';
          break;
        case 'ownersbox07.appspot.com':
          bucketName = 'fsp-user-images-dev';
          break;
      }

      let tagString = '';
      if (this.userLogoVersion != null) {
        tagString = '?t=' + this.userLogoVersion;
      }
      if (this.sport == null) {
        return 'https://storage.googleapis.com/' + bucketName + '/teamlogos/' + this.id + '.png' + tagString;
      }
      return 'https://storage.googleapis.com/' + bucketName + '/teamlogos/' + this.id + '_' + this.sport + '.png' + tagString;
    },

    getStatusClass() {
      switch (this.status) {
        case '0': return 'offline';
        case '1': return 'online';
        case '2': return 'away';
        default: return undefined;
      }
    },

  },
};
</script>

<style lang="scss" scoped>

$img-dimensions: 50px;

.container {
  position: relative;
  width: $img-dimensions;
  height: $img-dimensions;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
}

.ob-image-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.posCircle {
  background: rgb(122, 17, 17);
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 11px;
  bottom: -2px;
  left: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusCircle {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 11px;
  bottom: -2px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rankImg {
  width: 25px;
  position: absolute;
  bottom: -3px;
  right: -30%;
}

.online {
  background: var(--obcolor-green);
}

.offline {
  background: var(--obcolor-red);
}

.away {
  background: var(--obcolor-yellow);
}

</style>