<template>
  <div>
    <div class="depositTitle">OwnersBox Gift Card</div>
    <div>
      <div class="depositMethod">
        <div class="inputText">Gift Card Number</div>
        <div class="infoContainer">
          <input v-model="giftCardNumber" placeholder="Enter 19 Digit Card #" type="tel"
            v-cleave="cleaveOptionsCard" @blur="validateGiftCardNumber" :class="errorMessageCard ? 'errorBorder' : ''"
          >
        </div>
        <ErrorPanel :message="errorMessageCard" />

        <div class="inputText">Enter PIN</div>
        <div class="infoContainer">
          <input v-model="giftCardPin" v-cleave="cleaveOptionsPin" placeholder="Enter 6 Digit PIN"
            @blur="validateGiftCardPin" :class="errorMessagePin ? 'errorBorder' : ''"
          >
        </div>
        <ErrorPanel :message="errorMessagePin" />
      </div>
    </div>
    <ErrorPanel v-if="submitError" :message="submitError" />

    <button class="ob-btn submitBtn" :disabled="isDisabled" @click="!isDisabled && submitDeposit()">
      <i v-if="waitingForResponse" class="fad fa-spinner-third fa-spin"></i>{{ buttonText }}
    </button>

    <ValidateGiftCardModal />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import ObPaymentApi from '@/api/ObPaymentApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import ValidateGiftCardModal from '@/components/modals/ValidateGiftCardModal.vue';

export default {
  components: {
    ErrorPanel,
    ValidateGiftCardModal,
  },

  props: {
    amount: {type: Number, default: null},
    locationInfo: {type: Object, default: null},
    userInfo: {type: Object, default: null},
    showConnectKroger: {type: Boolean, default: false},
  },

  data() {
    return {
      giftCardNumber: '',
      giftCardPin: '',
      cleaveOptionsCard: {
        blocks: [5, 5, 5, 4],
        numericOnly: true,
      },
      cleaveOptionsPin: {
        blocks: [6],
        numericOnly: true,
      },
      waitingForResponse: false,
      submitError: '',
      timeoutMs: 1800000,
      errorMessage: '',
      errorMessageCard: '',
      errorMessagePin: '',
    };
  },

  created() {
    this.setPageTimeout();
    EventBus.$on('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  destroyed() {
    clearTimeout(this.redirectTimeout);
    EventBus.$off('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  computed: {
    ...mapState(['theme']),

    buttonText() {
      if (this.waitingForResponse) {
        return 'Submitting...';
      } else {
        return 'Deposit';
      }
    },

    bonusValue() {
      return this.depositValue > 500 ? 500 : this.depositValue;
    },

    isDisabled() {
      return this.waitingForResponse || !this.canSubmit;
    },

    isValidInfoGiftCard() {
      return (
        this.giftCardNumber.replace(/\s/g, '').length === 19 &&
        this.giftCardPin.length === 6
      );
    },

    canSubmit() {
      return this.isValidInfoGiftCard;
    },

  },

  watch: {
    giftCardNumber(newVal) {
      const digitsOnly = newVal.replace(/\D/g, '');
      if (digitsOnly.length === 19 || digitsOnly.length === 0) {
        this.errorMessageCard = '';
      }
    },

    giftCardPin(newVal) {
      if (newVal.length === 6 || newVal.length === 0) {
        this.errorMessagePin = '';
      }
    },
  },

  methods: {
    validateGiftCardNumber() {
      const digitsOnly = this.giftCardNumber.replace(/\D/g, '');
      if (digitsOnly.length < 19) {
        this.errorMessageCard = 'Gift card number must be 19 digits';
      } else {
        this.errorMessageCard = '';
      }
    },

    validateGiftCardPin() {
      if (this.giftCardPin.length < 6) {
        this.errorMessagePin = 'PIN must be 6 digits';
      } else {
        this.errorMessagePin = '';
      }
    },

    submitDeposit() {
      this.waitingForResponse = true;
      this.validateGiftCard();
    },

    validateGiftCard() {
      this.waitingForResponse = true;
      this.submitError = '';
      ObPaymentApi.validateGiftCard(this.giftCardNumber.replace(/\s/g, ''), this.giftCardPin)
          .then((response) => {
            EventBus.$emit('OPEN_VALIDATE_GIFT_CARD_MODAL', response, this.showConnectKroger);
            this.waitingForResponse = false;
          }).catch((error) => {
            this.submitError = error.response.data;
            this.waitingForResponse = false;
          });
    },

    setPageTimeout() {
      const self = this;
      clearTimeout(this.redirectTimeout);
      // Redirect in 30 minutes
      this.redirectTimeout = setTimeout(function() {
        self.redirectToPlayNow();
      }, this.timeoutMs);
    },

    redirectToPlayNow() {
      this.$router.push('/start-playing');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>