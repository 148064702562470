<template>
  <div>
    <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 500px; max-height: 695px; padding: 0; overflow: hidden;"
      :hideCloseButton="true" :disableClickaway="true"
    >
      <!-- Contest Entry Info -->
      <div slot="header" class="modalHeader">
        <div class="headerContainer" v-if="lineupInfo != null">
          <img class="llIcon" :src="require('@/assets/contestbadge/lightning_lineups.png')" alt="Lightning Lineups">
          <div class="headerTextContainer">
            <div class="headerText">Entry Placed!</div>
            <div class="headerSubText" :class="isRealMoney ? 'realMoneyContest' : 'ownersbucksContest'">
              Entry placed at
              <span v-if="isRealMoney" style="font-weight: bold;">$</span>
              <img v-else :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                alt="Ownersbucks"
              >
              <span style="font-weight: bold;">{{ entryValueDollars }}</span>
              to win
              <span v-if="isRealMoney" class="moneyText">$</span>
              <img v-else :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                alt="Ownersbucks"
              >
              <span class="moneyText">{{ toWinValue }}</span>
            </div>
          </div>
          <div class="countdownContainer" v-if="firstGameTime">
            <CountdownTimer v-if="firstGameDiffDays < 1" class="countdownText" :end="firstGameTime"
              :min="0" :clockFormat="true" :textClock="false"
            />
            <div v-else class="countdownText">{{ firstGameDiffDays }} days</div>
            <div class="countdownSubText">Starts In</div>
          </div>
        </div>
      </div>

      <!-- Lineups -->
      <div slot="body">
        <div v-if="lineups != null" class="lineupsContainer">
          <div class="lineupSection" v-for="(lineup, index1) in lineups" :key="index1"
            :hidden="index1 >= numLineups"
            :class="index1 === 0 ? 'yourLineup' : 'oppLineup'"
          >
            <div class="titleSection">
              <div class="titleText" v-if="index1 === 0">Your Lineup</div>
              <div class="titleText" v-else>Opponent Lineup</div>
              <div v-if="lineup" class="yourProj">{{ lineup.projection.toFixed(2) }}</div>
            </div>
            <div class="lineupContent">
              <div class="lineupEntry">
                <template v-for="(player, index2) in lineup.players">
                  <LightningPlayerTile :key="index2 + 'tile'" class="playerTile"
                    :player="player" :sport="sport" :locked="lockedPlayerId == player.id"
                    headshotSize="60"
                  />
                  <div v-if="index2 < lineup.players.length - 1" class="playerSeparator" :key="index2 + 'line'"></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Buttons -->
      <div slot="footer" class="modalFooter">
        <div class="navBtnContainer">
          <button class="ob-btn-grey shareContest" @click.stop="openInviteModal()">
            <i class="fas fa-share-square"></i>
            <div>Share</div>
          </button>
          <button class="navBtn ob-btn" @click="newEntry()">New Entry</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import LightningPlayerTile from './LightningPlayerTile.vue';
import CountdownTimer from '@/components/CountdownTimer.vue';
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    LightningPlayerTile,
    CountdownTimer,
    Modal,
  },

  data() {
    return {
      isOpen: false,
      lineupInfo: null,
      selectedLineupIndex: null,
      numLineups: null,
      isRealMoney: true,
      entryValueDollars: 0,
      toWinValue: 0,
      firstGameTime: null,
      lockedPlayerId: null,
    };
  },

  created() {
    EventBus.$on('LL_OPEN_SUBMIT_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('LL_OPEN_SUBMIT_MODAL', this.openModal);
  },

  computed: {
    lineups() {
      if (!this.lineupInfo) {
        return null;
      }

      const lineupList = [];
      const selectedLineup = this.lineupInfo.lineups[this.selectedLineupIndex];
      // Always have the user's lineup at the top
      lineupList.push(selectedLineup);

      for (const lIndex in this.lineupInfo.lineups) {
        if (lIndex != this.selectedLineupIndex) {
          lineupList.push(this.lineupInfo.lineups[lIndex]);
        }
      }

      return lineupList;
    },

    sport() {
      if (!this.lineupInfo) {
        return null;
      }
      return this.lineupInfo.sport;
    },

    firstGameDiffDays() {
      return this.$moment(this.firstGameTime).diff(this.$moment(), 'days');
    },
  },

  methods: {
    openModal(params) {
      this.lineupInfo = params.lineupInfo;
      this.selectedLineupIndex = params.selectedLineupIndex;
      this.numLineups = params.numLineups;
      this.isRealMoney = params.isRealMoney;
      this.entryValueDollars = params.entryValueDollars;
      this.toWinValue = params.toWinValue;
      this.firstGameTime = params.firstGameTime;
      this.lockedPlayerId = params.lockedPlayerId;
      this.isOpen = true;
    },

    newEntry() {
      EventBus.$emit('LL_CLEAR_ENTRY_FEE');
      EventBus.$emit('LL_GENERATE_NEW_LINEUP');
      this.closeModal();
    },

    closeModal() {
      this.isOpen = false;
    },

    getSquadRideUrl() {
      const baseURL = window.location.origin;
      const subfolder = this.$router.options.base;
      const params = `?lineup=${this.lineupInfo.id}&selected=${this.selectedLineupIndex+1}&entry=${this.entryValueDollars}&numLineups=${this.numLineups}`;
      const squadRideUrl = baseURL + subfolder + 'lightning-lineups/builder/' + this.lineupInfo.sport + params + '&btag=a_99999b_0';

      if (this.lineupInfo.id) {
        return squadRideUrl;
      }
      return '';
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_LL', this.lineupInfo, this.getSquadRideUrl());
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
}

.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

// Header section
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  flex: 1;
}

.llIcon {
  margin-left: -5px;
  margin-right: -4px;
  height: 70px;
  opacity: 0.4;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .headerText {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .headerSubText {
    font-size: 16px;
    text-align: center;
    max-width: 270px;

    .ownersbucksIcon {
      height: 10px;
      margin-right: 4px;
    }
  }

  .moneyText {
    font-weight: bold;
  }

  .realMoneyContest .moneyText {
    color: var(--obcolor-green) !important;
  }

  .ownersbucksContest .moneyText {
    color: var(--obcolor-ob-blue) !important;
  }
}

.countdownContainer {
  text-align: right;
  .countdownText {
    font-weight: bold;
    margin-top: 2px;
    padding-bottom: 3px;
  }
  .countdownSubText {
    font-size: 16px;
    color: var(--obcolor-font-secondary);
  }
}

// Body section
.lineupsContainer {
  background: var(--obcolor-background-page);
  padding: 10px 10px 5px 10px;
}

.lineupSection {
  background: var(--obcolor-background-4);
  margin-bottom: 5px;

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
  }

  .titleText {
    font-weight: bold;
  }

  &.oppLineup .titleText {
    color: red;
  }

  .yourProj {
    font-weight: bold;
    color: var(--obcolor-ob-blue);
  }

  .lineupContent {
    background: var(--obcolor-background-6);
    padding: 5px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lineupEntry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;
  }

  .playerSeparator {
    align-self: stretch;
    width: 1px;
    background: var(--obcolor-background-4);
  }
}

::v-deep {
  .lineupSection .playerTile {
    padding: 5px;
    flex: 1;
    min-width: 0;
    box-sizing: border-box;

    .nextGame {
      font-size: 11px;
    }
  }
}

.shareContest {
  margin-right: 10px;
  height: 36px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .fa-share-square {
    margin-right: 6px;
    padding-bottom: 1px;
  }

  > div {
    padding-top: 2px;
  }
}

.navBtnContainer {
  display: flex;
  width: 100%;

  .navBtn, .shareContest {
    flex: 1;
    height: 36px;
    font-size: 16px;
  }
}

</style>