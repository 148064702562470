<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <div class="filtersContainer">
      <div class="ob-btn-grey" @click="openDraftBoard()" style="margin-right: 8px; font-size: 12px;">
        Draft Board
      </div>
      <!-- Search Box -->
      <div class="searchInput">
        <div class="inputIcon"><i class="fa fa-search"></i></div>
        <input v-model="nameFilter" type="text" placeholder="Player Search">
        <div v-show="nameFilter != ''" class="inputIcon cancelSearch" @click="nameFilter = ''">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <!-- Spacer -->
      <div style="flex: 1;"></div>
      <!-- Position Filters -->
      <div class="filterBtns">
        <div class="filterBtn" :class="[{'filterSelected': posFilters[pos.short] === true}, {'filterSlotsFull': fullPositions[pos.short] === true}]" v-for="(pos, index) in roster"
          :key="index"
        >
          <div class="filterTop" @click="selectFilter(pos.short)">{{ pos.short }}</div>
          <div class="filterBottom" @click="selectFilter(pos.short)">{{ remainingInRoster(pos.short) }}</div>
        </div>
      </div>
    </div>
    <!-- Queue Table -->
    <div class="tableContainer ob-scroll" ref="tableContainer">
      <table v-if="true" aria-label="Draft Player Table">
        <tr class="weekHeader">
          <th colspan="3" scope="col"></th>
          <template v-if="sport != 'NFL'">
            <th colspan="5">Week 1</th>
            <th v-if="numWeeks > 1" colspan="2">Total</th>
          </template>
          <template v-else>
            <th colspan="3">Current</th>
            <th colspan="2">Prev</th>
          </template>
          <th></th>
        </tr>
        <tr>
          <th style="width: 25px; min-width: 25px;" scope="row"></th>
          <th style="width: 8px; min-width: 8px;" scope="row"></th>
          <th style="text-align: left;" scope="row">Player</th>
          <template v-if="sport != 'NFL'">
            <th class="statHeader" style="width: 35px;" :class="{sortCol: isSortedBy('wStats.ag')}"
              @click="changeSort('wStats', 'ag')"
              v-tooltip.bottom="{content: 'Available Games', class:'ObTooltipHeat'}"
            >
              AG
            </th>
            <th class="statHeader" style="width: 35px;" :class="{sortCol: isSortedBy('wStats.pg')}"
              @click="changeSort('wStats', 'pg')"
              v-tooltip.bottom="{content: 'Projected Games', class:'ObTooltipHeat'}"
            >
              PG
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('wStats.avg')}" @click="changeSort('wStats', 'avg')"
              v-tooltip.bottom="{content: 'Projected Average', class:'ObTooltipHeat'}"
            >
              pAVG
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('.sAvg')}" @click="changeSortNoCat('sAvg')"
              v-tooltip.bottom="{content: 'Season Average', class:'ObTooltipHeat'}"
            >
              sAVG
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('wStats.proj')}" @click="changeSort('wStats', 'proj')"
              v-tooltip.bottom="{content: 'Projected Points', class:'ObTooltipHeat'}"
            >
              PROJ
            </th>
            <template v-if="numWeeks > 1">
              <th class="statHeader" :class="{sortCol: isSortedBy('wTotals.ag')}" @click="changeSort('wTotals', 'ag')"
                v-tooltip.bottom="{content: 'Available Games', class:'ObTooltipHeat'}"
              >
                AG
              </th>
              <th class="statHeader" :class="{sortCol: isSortedBy('wTotals.proj')}" @click="changeSort('wTotals', 'proj')"
                v-tooltip.bottom="{content: 'Projected Points', class:'ObTooltipHeat'}"
              >
                PROJ
              </th>
            </template>
          </template>
          <template v-else>
            <th class="statHeader noHover"
              v-tooltip.bottom="{content: 'Opponent', class:'ObTooltipHeat'}"
            >
              VS
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('wStats.proj')}" @click="changeSort('wStats', 'proj')"
              v-tooltip.bottom="{content: 'Projected Points', class:'ObTooltipHeat'}"
            >
              PROJ
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('.sAvg')}" @click="changeSortNoCat('sAvg')"
              v-tooltip.bottom="{content: 'Season Average', class:'ObTooltipHeat'}"
            >
              sAVG
            </th>
            <th class="statHeader noHover"
              v-tooltip.bottom="{content: 'Opponent', class:'ObTooltipHeat'}"
            >
              VS
            </th>
            <th class="statHeader" :class="{sortCol: isSortedBy('prevGame.fpts')}" @click="changeSort('prevGame', 'fpts')"
              v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat'}"
            >
              FPTS
            </th>
          </template>
          <th style="width: 33px; box-sizing: border-box;"></th>
        </tr>
        <!-- Empty List -->
        <tr v-show="computedPlayers.length === 0" class="emptyListRow">
          <td colspan="11">No players to show. Adjust filters.</td>
        </tr>
        <!-- Player Rows -->
        <tr v-for="player in computedPlayers" :key="player.id" class="playerRow"
          :class="[{'selectedPlayer' : selectedPlayer && player.id === selectedPlayer.id}, {'pickedPlayer': pickedPlayerIds[player.id] !== undefined}]"
          @click="selectPlayer(player)"
        >
          <td style="font-weight: bold;">{{ player.draftPos }}</td>
          <td style="padding: 0;">
            <PlayerStatus :status="player.status" style="margin-top: -11px;" />
          </td>
          <td style="text-align: left;">
            <div class="playerName">
              <span @click.stop="openPlayerCard(player)" class="ob-link" style="margin-right: 6px;">
                {{ player.name.longName() }}
              </span>
              <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
                :style="pickedPlayerIds[player.id] !== undefined ? 'color: var(--obcolor-text-faded)' : ''"
                v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
              ></i>
              <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
                :style="pickedPlayerIds[player.id] !== undefined ? 'color: var(--obcolor-text-faded)' : ''"
                v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
              ></i>
            </div>
            <div class="playerInfo" :class="{'pickedPlayer': pickedPlayerIds[player.id] !== undefined}">
              <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(sport, player.team) }}</span>
              <span>#{{ player.number }}</span>
              <span>{{ player.position }}</span>
              <span>{{ player.lineInfo }}</span>
            </div>
          </td>
          <template v-if="sport != 'NFL'">
            <td class="statCol" style="width: 35px;" :class="{sortCol: isSortedBy('wStats.ag')}">{{ player.wStats[0].ag }}</td>
            <td class="statCol" style="width: 35px;" :class="{sortCol: isSortedBy('wStats.pg')}">{{ player.wStats[0].pg }}</td>
            <td class="statCol" :class="{sortCol: isSortedBy('wStats.avg')}">{{ roundStat(player.wStats[0].avg) }}</td>
            <td class="statCol" :class="{sortCol: isSortedBy('.sAvg')}">{{ roundTo1Dec(player.sAvg) }}</td>
            <td class="statCol" :class="{sortCol: isSortedBy('wStats.proj')}">{{ roundStat(player.wStats[0].proj) }}</td>
            <template v-if="numWeeks > 1">
              <td class="statCol" style="border-left: 1px solid var(--obcolor-background-2);" :class="{sortCol: isSortedBy('wTotals.ag')}">{{ player.wTotals[numWeeks].ag }}</td>
              <td class="statCol" :class="{sortCol: isSortedBy('wTotals.proj')}">{{ roundStat(player.wTotals[numWeeks].proj) }}</td>
            </template>
          </template>
          <template v-else>
            <td class="statCol">
              <!-- If the current week isn't a bye and the data exists, show the opposing team, otherwise show "BYE" -->
              <div v-if="player.wInfo != null && player.wInfo[0] && !player.wInfo[0].bye" class="centerCol">
                <div v-if="player.wInfo[0].home == false" class="atSign">@</div>
                <img style="width: 24px; height: 24px;" :class="{invalidGameLogo: player.wInfo[0].excluded == true}"
                  :src="getTeamLogo(player.wInfo[0].opp)" :alt="player.wInfo[0].opp"
                >
              </div>
              <div v-else-if="player.wInfo != null && player.wInfo[0] && player.wInfo[0].bye == true" class="centerCol">BYE</div>
            </td>
            <td class="statCol" :class="{sortCol: isSortedBy('wStats.proj')}">{{ roundStat(player.wStats[0].proj) }}</td>
            <td class="statCol" :class="{sortCol: isSortedBy('.sAvg')}">{{ roundTo1Dec(player.sAvg) }}</td>
            <!-- TODO: Add these fields once the data exists -->
            <td class="statCol">
              <div v-if="player.prevGame != null" class="centerCol">
                <img style="width: 24px; height: 24px;" :src="getTeamLogo(player.prevGame.opp)" :alt="player.prevGame.opp">
              </div>
            </td>
            <td class="statCol" :class="{sortCol: isSortedBy('prevGame.fpts')}">
              <template v-if="player.prevGame">{{ player.prevGame.fpts }}</template>
            </td>
          </template>
          <!-- Queue Button -->
          <td v-if="isDraftable(player)" :class="isInQueue(player) ? 'queueBoxRed' : 'queueBox'" @click="selectPlayerForQueue(player)">
            <i :class="isInQueue(player) ? 'fal fa-minus-circle' : 'fal fa-plus-circle'"></i>
          </td>
          <td v-else class="queueBox">
            <i style="color: var(--obcolor-text-faded);" class="fal fa-times-circle"></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import PlayerStatus from '@/components/PlayerStatus.vue';

export default {
  name: 'DraftPlayersTable',

  components: {
    PlayerStatus,
  },

  props: {
    players: Array,
    sport: String,
    selectedPlayer: Object,
    pickedPlayerIds: Object,
    pickQueueIds: Object,
    teamRosters: Object,
    curUserId: String,
    roster: Array,
    numWeeks: Number,
    draftState: Object,
  },

  data() {
    return {
      nameFilter: '',
      posFilters: {},
      showDrafted: false,
      playerListLimit: 200,
      sortStat: 'avg',
      sortCategory: 'wStats',
    };
  },

  created() {
    const tempFilters = {};
    this.roster.forEach((pos) => {
      if (!tempFilters[pos.short]) {
        tempFilters[pos.short] = false;
      }
    });
    this.posFilters = tempFilters;

    if (this.sport == 'NFL') {
      this.sortStat = 'proj';
    }
  },

  computed: {
    fullPositions() {
      const tempFullPositions = {};
      this.teamRosters[this.curUserId].forEach((pos) => {
        tempFullPositions[pos.short] = pos.num <= pos.players.length;
      });
      const fPositions = tempFullPositions;
      return fPositions;
    },

    computedPlayers() {
      const self = this;
      let noPosFilters = true;
      for (const posIndex in this.posFilters) {
        if (this.posFilters[posIndex] === true) {
          noPosFilters = false;
        }
      }
      const filteredPlayers = this.players.filter(function(player) {
        let nameMatch = false;
        let posMatch = false;
        player.shown = false;
        if (!self.showDrafted && self.nameFilter === '' && self.pickedPlayerIds[player.id] !== undefined) {
          return false;
        }
        if (!player.name) {
          return false;
        }
        if (self.nameFilter !== '') {
          const pname = player.name.toLowerCase();
          const nfilter = self.nameFilter.toLowerCase();
          const nFilters = nfilter.split(' ');

          nameMatch = true;
          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) == 0 || pname.indexOf(', '+n) != -1) {
              // All filters must match
            } else {
              nameMatch = false;
              break;
            }
          }

          return nameMatch;
        }
        if (self.posFilters[player.draftPos] || (noPosFilters && self.fullPositions[player.draftPos] === false)) {
          posMatch = true;
        }
        return posMatch;
      });

      // Sorting
      let secondarySort = 'ag';
      switch (self.sortStat) {
        case 'ag': secondarySort = 'avg'; break;
        case 'avg': secondarySort = 'proj'; break;
        case 'proj': secondarySort = 'avg'; break;
      }
      let statKey = 0;
      if (this.sortCategory === 'wTotals') {
        statKey = this.numWeeks;
      }

      filteredPlayers.sort(function(playerA, playerB) {
        // Base level sort, IE sAvg
        if (!self.sortCategory) {
          const aValue = playerA[self.sortStat];
          const bValue = playerB[self.sortStat];
          if (aValue > bValue) {
            return -1;
          } else if (bValue > aValue) {
            return 1;
          }
          return 0;
        }

        if (playerA[self.sortCategory] != null && playerB[self.sortCategory] == null) {
          return -1;
        } else if (playerB[self.sortCategory] != null && playerA[self.sortCategory] == null) {
          return 1;
        } else if (playerA[self.sortCategory] == null && playerB[self.sortCategory] == null) {
          return 0;
        }

        let aValue; let bValue; let aSecondary; let bSecondary;
        if (self.sortCategory == 'prevGame') {
          aValue = playerA[self.sortCategory][self.sortStat];
          bValue = playerB[self.sortCategory][self.sortStat];
          aSecondary = playerA[self.sortCategory][secondarySort];
          bSecondary = playerB[self.sortCategory][secondarySort];
        } else {
          aValue = playerA[self.sortCategory][statKey][self.sortStat];
          bValue = playerB[self.sortCategory][statKey][self.sortStat];
          aSecondary = playerA[self.sortCategory][statKey][secondarySort];
          bSecondary = playerB[self.sortCategory][statKey][secondarySort];
        }

        // Primary Sort
        if (aValue > bValue) {
          return -1;
        } else if (bValue > aValue) {
          return 1;
        }

        // Secondary Sort
        if (aSecondary > bSecondary) {
          return -1;
        } else if (bSecondary > aSecondary) {
          return 1;
        }
        return 0;
      });

      return filteredPlayers.slice(0, this.playerListLimit);
    },
  },

  watch: {
    nameFilter(to, from) {
      this.$nextTick(() => {
        this.$refs.tableContainer.scrollTop = 0;
      });
    },

    teamRosters(to, from) {
      for (const tRosterIndex in to[this.curUserId]) {
        const tempToPos = to[this.curUserId][tRosterIndex];
        const tempFromPos = from[this.curUserId][tRosterIndex];
        if (tempToPos.num <= tempToPos.players.length && tempFromPos.players.length < tempToPos.players.length) {
          this.posFilters[tempToPos.short] = false;
        }
      }
    },
  },

  methods: {
    openDraftBoard() {
      EventBus.$emit('OPEN_DRAFT_BOARD_MODAL');
    },

    openPlayerCard(player) {
      EventBus.$emit('DRAFT_OPEN_PLAYER_CARD', player, this.sport, this.draftState);
    },

    isDraftable(player) {
      return !(this.pickedPlayerIds[player.id] !== undefined || this.fullPositions[player.draftPos] === true);
    },

    selectFilter(posName) {
      if (!this.posFilters[posName]) {
        this.posFilters[posName] = true;
      } else {
        this.posFilters[posName] = false;
      }
      this.$nextTick(() => {
        this.$refs.tableContainer.scrollTop = 0;
      });
    },

    remainingInRoster(posName) {
      let posRoster = {};
      let total = 0;
      let numPlayers = 0;
      this.teamRosters[this.curUserId].forEach((roster) => {
        if (roster.short === posName) {
          posRoster = roster;
        }
      });
      total = posRoster.num;
      numPlayers = posRoster.players.length;

      return total - numPlayers;
    },

    getTeamLogo(team) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },

    roundStat(stat) {
      if (stat == null) {
        return '0';
      }
      return stat.toFixed(0);
    },

    roundTo1Dec(stat) {
      if (stat == null) {
        return '0';
      }
      return stat.toFixed(1);
    },

    isSortedBy(sort) {
      return sort === this.sortCategory + '.' + this.sortStat;
    },

    changeSortNoCat(stat) {
      this.sortCategory = '';
      this.sortStat = stat;
      this.$nextTick(() => {
        this.$refs.tableContainer.scrollTop = 0;
      });
    },

    changeSort(category, stat) {
      this.sortCategory = category;
      this.sortStat = stat;
      this.$nextTick(() => {
        this.$refs.tableContainer.scrollTop = 0;
      });
    },

    isInQueue(player) {
      return this.pickQueueIds[player.id] !== undefined;
    },

    selectPlayer(player) {
      EventBus.$emit('PLAYER_SELECTED', player);
    },

    selectPlayerForQueue(player) {
      EventBus.$emit('QUEUE_PLAYER_SELECTED', player);
    },

  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  display: flex;
  width: 170px;
  height: 31px;
  background-color: var(--obcolor-input-box);
  align-items: center;
  border-radius: 6px;
  padding: 0 10px;

  input {
    box-sizing: border-box;
    flex: 1;
    min-width: 0;
    height: 100%;
    padding: 0 5px;
    margin-left: 4px;
    border-radius: 100px;
  }

  .inputIcon {
    margin-top: 2px;
    color: var(--obcolor-font-secondary);
  }

  .cancelSearch {
    color: red;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
    margin-right: -4px;
  }

  .cancelSearch:hover {
    color: #a80f0f;
  }
}

.filtersContainer {
  display: flex;
  align-items: center;
}

.filterBtns {
  display: flex;
}

.filterBtn {
  color: white;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
}

.filterBtn.filterSelected {
  .filterTop {
    background: var(--obcolor-ob-blue);
    border-radius: 6px 6px 0 0;
    padding: 4px 8px 2px 8px;
  }

  .filterBottom {
    background: var(--obcolor-ob-blue-tinted);
    border-radius: 0 0 6px 6px;
    padding: 2px 8px 4px 8px;
  }

  .filterTop:hover {
    background: var(--obcolor-accent-hl);
  }

  .filterBottom:hover {
    background: var(--obcolor-accent-light-hl);
  }
}
.filterBtn:not(.filterSelected):not(.filterSlotsFull) {
  .filterTop {
    background: var(--btn-grey);
    border-radius: 6px 6px 0 0;
    padding: 4px 8px 2px 8px;
  }

  .filterBottom {
    background: var(--btn-grey-hl);
    border-radius: 0 0 6px 6px;
    padding: 2px 8px 4px 8px;
  }
}

.filterBtn:not(.filterSelected).filterSlotsFull {
  .filterTop {
    background: var(--btn-grey-dark);
    border-radius: 6px 6px 0 0;
    padding: 4px 8px 2px 8px;
  }

  .filterBottom {
    background: var(--btn-grey-dark-hl);
    border-radius: 0 0 6px 6px;
    padding: 2px 8px 4px 8px;
  }
}

.filterBtn:hover {
  .filterTop {
    background: var(--obcolor-accent-hl);
  }
  .filterBottom {
    background: var(--obcolor-accent-light-hl);
  }
}

.filterBtn:not(.filterSelected):not(.filterSlotsFull):hover {
  .filterTop {
    background: var(--btn-grey-light);
  }
  .filterBottom {
    background: var(--btn-grey-light-hl);
  }
}

.filterBtn:not(.filterSelected).filterSlotsFull:hover {
  .filterTop {
    background: var(--btn-grey);
  }
  .filterBottom {
    background: var(--btn-grey-hl)
  }
}

.tableContainer {
  flex: 1;
  overflow-y: scroll;
  overflow-x: auto;
  margin-top: 5px;
}

// --- Players Table ---
table {
  width: 100%;
  // height: 100%;
  font-size: 14px;
  text-align: center;
}

th {
  color: #878998;
  background: var(--obcolor-background-6);
  padding: 4px 4px 4px 4px;
  font-size: 12px;
  position: sticky;
  top: 26px;
}

td {
  padding: 8px 4px 8px 4px;
  cursor: pointer;
}

.weekHeader {
  th {
    padding: 10px 4px 3px 4px;
    top: 0;
  }
}

tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

tr:hover {
  background: var(--obcolor-bg-hover);
}

.statCol {
  width: 45px;
  max-width: 45px;
}

.centerCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.atSign {
  margin-right: 2px;
  height: max-content;
  margin-left: -13px;
}

.invalidGameLogo {
  filter: grayscale(100%);
  opacity: 0.5;
}

.playerName {
  font-weight: bold;
  white-space: nowrap;
}

.pickedPlayer {
  color: var(--obcolor-text-faded) !important;
}

.playerInfo {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -3px;
  color: var(--obcolor-font-secondary);
  span {
    margin-right: 5px;
  }
}

.emptyListRow {
  color: var(--obcolor-text-faded);
  font-size: 12px;
  td {
    background: var(--obcolor-background-6);
    cursor: default;
    padding: 25px 12px 12px 12px;
  }
}

.statHeader {
  width: 53px;
  box-sizing: border-box;
}

.statHeader:not(.noHover) {
  cursor: pointer;
}

.statHeader:not(.noHover):hover {
  background: var(--obcolor-background-2);
}

.sortCol {
  font-weight: bold;
  background: var(--obcolor-background-2);
}

.selectedPlayer {
  background: var(--obcolor-background-2) !important;
}

// --- Queue +/- buttons ---
.queueBox {
  font-size: 18px;
  width: 25px;
}

.queueBox:hover {
  color: green;
}

.queueBoxRed {
  font-size: 18px;
  color: red;
  width: 25px;
}
</style>