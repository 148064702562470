<template>
  <div class="playerSummary">
    <div class="draftPos">
      <div>{{ entry.draftPos }}</div>

      <!-- Can't Swap -->
      <div v-if="showSwapBtn() && entry.dropped == null && swapStatus === 'invalid'" disabled class="ob-btn-grey swapBtn">
        <i class="fas fa-times"></i>
      </div>

      <!-- Waivers -->
      <div v-else-if="showSwapBtn() && entry.dropped == null && swapStatus === 'waivers'" class="ob-btn-grey swapBtn" @click.stop="openSwapComponent()"
        v-tooltip.bottom="{content: 'Create Waiver', class:'ObTooltipHeat'}"
      >
        <i class="fas fa-stopwatch"></i>
      </div>

      <!-- Swap -->
      <div v-else-if="showSwapBtn() && entry.dropped == null && swapStatus === 'swappable'" class="ob-btn-grey swapBtn" @click.stop="openSwapComponent()"
        v-tooltip.bottom="{content: 'Swap Player', class:'ObTooltipHeat'}"
      >
        <i v-if="swapStatus === 'swappable'" class="fas fa-random" style="margin-left: 1px;"></i>
      </div>
    </div>

    <div style="width: 55px; margin-top: 2px;">
      <PlayerHeadshot :id="entry.id" :size="'52'" :sport="league.sport"
        :team="entry.player.team" :showLogo="true"
      />
    </div>

    <div class="playerInfoContainer" v-if="entry.dropped == null">
      <div class="playerNameStatus">
        <!-- Use Status from Player JSON -->
        <PlayerStatus v-if="!useStatusMap" :status="entry.player.status" class="playerStatus" />

        <!-- Status Map for Player Swap -->
        <div class="statusLoading" v-else-if="!statusMap">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <PlayerStatus v-else-if="!statusMap[entry.player.id]" class="playerStatus" :status="'OUT'" />
        <PlayerStatus v-else class="playerStatus" :status="statusMap[entry.player.id].status" />
        <div class="playerName ob-link" @click.stop="openPlayerModal()">
          {{ entry.player.name.shortName() }}
        </div>

        <!-- Need HeatInfo in JSON -->
        <i v-if="entry.player.heat === 'Cold'" class="fas fa-icicles heatIcon cold"
          v-tooltip.right="getHeatInfo(entry.player) != null ? {content: getHeatInfo(entry.player), class:'ObTooltipHeat'} : 'Cold'"
        ></i>
        <i v-else-if="entry.player.heat === 'Hot'" class="fab fa-hotjar heatIcon hot"
          v-tooltip.right="getHeatInfo(entry.player) != null ? {content: getHeatInfo(entry.player), class:'ObTooltipHeat'} : 'Hot'"
        ></i>
      </div>

      <!-- Live Game Info -->
      <div v-if="!isToday" class="playerInfo">
        <span>No Game Today</span>
      </div>

      <div v-else class="playerInfo" :style="gameIsLive() ? 'color: var(--obcolor-accent);' : 'color: var(--obcolor-font-primary);'">
        <span>{{ $SportInfo.getGameSummary(league.sport, gameData, entry.player.teamId, $moment) }}</span>
      </div>
    </div>

    <div class="droppedPlayerInfoContainer" style="color: var(--obcolor-font-secondary);" v-else>
      <div class="ob-link" style="font-weight: bold; color: var(--obcolor-font-primary);" @click.stop="openPlayerModal()">{{ entry.player.name.shortName() }}</div>
      <div v-if="!entry.swapFee || entry.swapFee === 0">No Swap Fee</div>
      <div v-else>Swap Fee {{ entry.swapFee.formatMoney() }}</div>
      <div>{{ parseInt(entry.dropped) | moment("MMM D h:mma") }}</div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    entry: Object,
    league: Object,
    isMyTeam: Boolean,
    rosterGR: Object,
    showSwap: Boolean,
    swapStatus: String,
    statusMap: Object,
    useStatusMap: Boolean,
    teamId: String,
  },

  computed: {
    ...mapState(['todaysGames']),
  },

  watch: {
    todaysGames(to, from) {
      this.setupGameData();
    },

    entry(to, from) {
      this.clearData();
      this.setupGameData();
    },
  },

  data() {
    return {
      nextGame: null,
      gameData: null,
      gameInfo: '',
      isToday: false,
    };
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  created() {
    this.clearData();
    this.setupGameData();
    EventBus.$on('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  destroyed() {
    EventBus.$off('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  methods: {

    openPlayerModal() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.entry.id, this.league.sport, this.league.id, true);
    },

    onGameUpdate(gameId) {
      if (this.nextGame && gameId == this.nextGame.id) {
        this.setupGameData();
      }
    },

    clearData() {
      this.nextGame = null;
      this.gameData = null;
      this.gameInfo = '';
      this.isToday = false;
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.league.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    gameHasStarted() {
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      return liveJSON && (liveJSON.status === 'mid-event' || liveJSON.status === 'post-event');
    },

    gameIsLive() {
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      return liveJSON && (liveJSON.status === 'mid-event');
    },

    setupGameData() {
      const playerJSON = this.entry.player;

      if (playerJSON) {
        const nextGames = playerJSON.next_games;
        // If game is in slot 0 it is being played today
        if (nextGames && nextGames[0] && nextGames[0].id) {
          this.nextGame = nextGames[0];
          this.isToday = true;
          this.gameData = this.todaysGames[this.nextGame.id];
        } else if (nextGames && nextGames[1] && nextGames[1].id) {
          this.nextGame = nextGames[1];
          this.isToday = false;
        }
      }
      this.gameInfo = this.getGameInfo();
    },

    getGameInfo() {
      if (!this.nextGame) {
        return '';
      }

      const gameJSON = this.gameData;
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      const opp = this.nextGame.opponent;

      if (liveJSON && liveJSON.status === 'mid-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        if (this.league.sport === 'MLB') {
          if (liveJSON.inning_half && liveJSON.inning) {
            gameInfo += liveJSON.inning_half === 'top' ? 'Top ' : 'Bot ';
            gameInfo += this.addOrdinalSuffix(liveJSON.inning);
          }
          return gameInfo;
        }
        const gameTime = liveJSON.gametime === '00:00' ? 'End' : liveJSON.gametime;
        gameInfo += gameTime + ' ' + this.addOrdinalSuffix(liveJSON.period);
        return gameInfo;
      } else if (liveJSON && liveJSON.status === 'post-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        gameInfo += ' Final';
        return gameInfo;
      }

      // Game is not live, show time or date
      const gameDate = this.$moment(this.nextGame.date).format('ddd MMM D');
      const today = this.$moment().format('ddd MMM D');

      if (gameDate === today) {
        return this.$moment(this.nextGame.date).format('h:mma');
      }
      if (this.league.format == 'DAILY') {
        // If game is within this week range, show time instead of date
        for (const g in this.entry.fspGames) {
          if (this.entry.fspGames[g].id === this.nextGame.id) {
            return this.$moment(this.nextGame.date).format('ddd h:mma');
          }
        }
      }
      return gameDate;
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    openSwapComponent() {
      const teamId = this.teamId;
      this.$router.push('/salarycontest/editor/' + this.league.id + '/' + teamId + '/' + this.entry.id);
    },

    showSwapBtn() {
      if (this.league.state !== 'IN_PROGRESS') {
        return false;
      }
      return this.isMyTeam && this.showSwap;
    },

    getHeatInfo(playerJSON) {
      if (playerJSON.bio && playerJSON.bio.extra_info && playerJSON.bio.extra_info.hotcold_info) {
        return playerJSON.bio.extra_info.hotcold_info;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.playerSummary {
  display: flex;
  align-items: center;
  height: 100%;
}
.draftPos {
  width: 50px;
  margin-right: 4px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  color: var(--obcolor-font-primary);
}
.playerInfoContainer {
  min-width: 0;
  margin-left: 10px;
  text-align: left;
  text-overflow: ellipsis;
  flex: 1;
}
.playerNameStatus {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--obcolor-font-primary);
}
.playerName {
  display: inline-block;
  margin-left: 5px;
  margin-top: -2px;
  max-width: 92px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.playerStatus {
  display: inline-block;
  margin-top: -3px;
}
.statusLoading {
  width: 16px;
  margin-top: 1px;
  color: var(--obcolor-font-secondary);
}
.teamLogo {
  width: 20px;
  margin-left: 0px;
  margin-right: 3px;
}
.heatIcon {
  margin-left: 6px;
  margin-top: -3px;
}
.heatIcon.cold {
  color: var(--obcolor-player-cold);
}
.heatIcon.hot {
  color: var(--obcolor-player-hot);
}
.swapBtn {
  padding: 3px;
  margin-top: 5px;
  width: 18px;

  > i {
    margin-top: 2px;
  }
}
.playerInfo {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: -5px;
  color: var(--obcolor-font-secondary);
  text-align: left;
  white-space: nowrap;
  margin-left: 15px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
  width: 115px;
  span {
    margin-right: 5px;
    text-overflow: ellipsis;
  }
}

.droppedPlayerInfoContainer {
  margin-left: 25px;
  text-align: left;
  white-space: nowrap;
  max-width: 115px;
  width: 115px;
  div:not(first-child) {
    margin-top: 3px;
  }
}
</style>