<template>
  <div class="ob-page page-not-found">
    <h3>404</h3>
    <h4>Page Not Found</h4>
    <p>The page you are looking for does not exist</p>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  padding-top: 200px;

  h3, h4 {
    margin: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 80px;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
  }

  p {
    color: var(--obcolor-font-secondary);
    margin: 15px 0;
  }
}
</style>