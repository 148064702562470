import { render, staticRenderFns } from "./MobileAppRedirectModal.vue?vue&type=template&id=5c7aa106&scoped=true"
import script from "./MobileAppRedirectModal.vue?vue&type=script&lang=js"
export * from "./MobileAppRedirectModal.vue?vue&type=script&lang=js"
import style0 from "./MobileAppRedirectModal.vue?vue&type=style&index=0&id=5c7aa106&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c7aa106",
  null
  
)

export default component.exports