<template>
  <div class="playerTableContainer">
    <table aria-labelledby="existingContestLineup">
      <tr>
        <th id="existingContestLineup">PLAYER</th>
        <th>PROJ</th>
      </tr>
      <template v-for="(playerList, pickPos) in lineupInfo.picks">
        <tr v-for="(player, index2) in playerList" :key="pickPos + '_' + index2">
          <td>
            <div class="playerSection">
              <div class="playerPos">{{ pickPos }}</div>
              <PlayerHeadshot class="playerImg" :id="player.id"
                size="45"
                :sport="contest.sport" :team="player.team"
                :showLogo="true"
              />
              <div class="playerNameInfo">
                <div class="statusContainer">
                  <div v-if="!lineupInfo.statusMap"><i class="fas fa-spinner fa-spin"></i></div>
                  <PlayerStatus v-else-if="!lineupInfo.statusMap[player.id]" :status="'OUT'" />
                  <PlayerStatus v-else :status="lineupInfo.statusMap[player.id].status" />
                </div>
                <div>
                  <div class="playerName">{{ player.name.shortName() }}</div>
                  <div class="gameInfo">
                    <template v-if="nextGame">
                      <span>{{ nextGameInfo(player) }}</span>
                    </template>
                    <template v-else>
                      <span style="color: var(--obcolor-red);">No Game</span>
                    </template>
                  </div>
                  <div style="margin-top: 5px">
                    <MlbBattingOrder v-if="isDailyMLB() && !isPitcher(player)" :myBatterIndex="getBatterIndex(player)" />
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="playerProj">
            <span v-if="$SportInfo.isDraftPositionMultiplier(pickPos)" class="projStrikeThrough">{{ getStatProj(player) || '-' }}</span>
            {{ getProjection(player, pickPos) }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder';

export default {
  props: {
    lineupInfo: {type: Object, default: null},
    contest: {type: Object, default: null},
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
    MlbBattingOrder,
  },

  methods: {
    getDisplayAlias(alias) {
      alias = alias.replace(/@/, '');
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, alias);
    },

    nextGameInfo(player) {
      if (player.games && this.nextGame(player)) {
        const isHome = this.nextGame(player).game.indexOf('@') == -1;
        const gameTime = this.$moment(this.nextGame(player).ts).format('ddd h:mma');
        return (isHome ? 'vs ' : '@ ') + this.getDisplayAlias(this.nextGame(player).game) + ' ' + gameTime;
      }
      return '';
    },

    nextGame(player) {
      if (!player.games) {
        return null;
      }
      return player.games.find((g) => g.ts != null);
    },

    getBatterIndex(player) {
      if (!this.nextGame(player) || !this.nextGame(player).info) {
        return null;
      }

      return this.nextGame(player).info.lineup;
    },

    isPitcher(player) {
      return player && player.draftPos == 'P';
    },

    isDailyMLB() {
      return this.contest && this.contest.format == 'DAILY' && this.contest.sport == 'MLB';
    },

    getStatProj(player) {
      return player.wStats[0].proj.toFixed(0);
    },

    getProjection(player, pickPos) {
      let proj = this.getStatProj(player);
      if (this.$SportInfo.isDraftPositionMultiplier(pickPos)) {
        proj = (proj * 1.5).toFixed(0);
      }
      return proj;
    },

  },
};
</script>

<style lang="scss" scoped>
.playerTableContainer {
  margin-right: 10px;
  background: var(--obcolor-background-4);
  padding: 10px;
  border-radius: 5px;

  table {
    width: 100%;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  th {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }

  th:first-child {
    padding-left: 50px;
  }

  th:nth-child(2) {
    text-align: center;
  }
  .playerSection {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  .playerPos {
    font-size: 14px;
    width: 40px;
    text-align: center;
    padding-left: 5px;
  }

  .playerNameInfo {
    display: flex; align-items: center;
  }

  .playerName {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 4px;
    width: 120px;
  }

  .statusContainer {
    margin-left: 6px;
    width: 15px;
    padding-bottom: 20px;
  }

  .gameInfo {
    color: var(--obcolor-font-secondary);
  }

  .playerProj {
    font-size: 14px;
    width: 50px;
    text-align: center;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
  }

  .projStrikeThrough {
    color: var(--obcolor-font-secondary);
    text-decoration: line-through;
  }
}
</style>