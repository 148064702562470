// Live Summary Stats
// Controls the top overall summary for live page

// Quarterback Stats
export const INGAME_NFL_PASSING = [
  {
    label: 'PASSING',
    stats: [
      {label: 'passing.PASS_YDS', tooltip: 'Passing Yards', description: 'Pass Yds'},
      {label: 'passing.PASS_TD', tooltip: 'Passing Touchdowns', description: 'Pass TDs'},
      {label: 'passing.PASS_INT', tooltip: 'Interceptions Thrown', description: 'INTs'},
      // {label: 'sacks.SCK_A', tooltip: 'Sacks', description: 'Sacks Taken'},
      {label: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', description: 'Rush Yds'},
      {label: 'rushing.RUS_TD', tooltip: 'Touchdowns', description: 'Rush TDs'},
      // {label: 'fumbles.FBL_L', tooltip: 'Fumbles Lost', description: 'Fumbles Lost'},
      {label: 'passing.REC', tooltip: 'Receptions', description: 'Rec'},
      {label: 'passing.REC_YDS', tooltip: 'Receiving Yards', description: 'Rec Yds'},
      {label: 'passing.REC_TD', tooltip: 'Touchdowns', description: 'Rec TDs'},
      {label: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', description: '2Pt Conversions'},
    ],
  },
];

// Running Back Stats
export const INGAME_NFL_RUSHING = [
  {
    label: 'RUSHING',
    stats: [
      {label: 'rushing.RUS_ATT', tooltip: 'Carries', description: 'ATTs'},
      {label: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', description: 'Rush Yds'},
      {label: 'rushing.RUS_TD', tooltip: 'Touchdowns', description: 'Rush TDs'},
      {label: 'passing.REC', tooltip: 'Receptions', description: 'Rec'},
      {label: 'passing.REC_YDS', tooltip: 'Receiving Yards', description: 'Rec Yds'},
      {label: 'passing.REC_TD', tooltip: 'Touchdowns', description: 'Rec TDs'},
      {label: 'special.RET_YDS', tooltip: 'Return Yards', description: 'Ret Yds'},
      {label: 'special.RET_TD', tooltip: 'Touchdowns', description: 'Ret TDs'},
      {label: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', description: '2Pt Conversions'},
    ],
  },
];


// Wide Receiver/Tight End Stats
export const INGAME_NFL_RECEIVING = [
  {
    label: 'RECEIVING',
    stats: [
      {label: 'passing.REC_TAR', tooltip: 'Targets', description: 'Targets'},
      {label: 'passing.REC', tooltip: 'Receptions', description: 'Rec'},
      {label: 'passing.REC_YDS', tooltip: 'Receiving Yards', description: 'Rec Yds'},
      {label: 'passing.REC_TD', tooltip: 'Touchdowns', description: 'Rec TDs'},
      {label: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', description: 'Rush Yds'},
      {label: 'rushing.RUS_TD', tooltip: 'Touchdowns', description: 'Rush TDs'},
      {label: 'special.RET_YDS', tooltip: 'Return Yards', description: 'Ret Yds'},
      {label: 'special.RET_TD', tooltip: 'Touchdowns', description: 'Ret TDs'},
      {label: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', description: '2Pt Conversions'},
    ],
  },
];