<template>
  <div>
    <!-- H2H Contest -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Join a Weekly Tournament Contest</div>
        <div style="text-align: center;">
          Build a 10-player roster while staying within the salary cap and game limits by position.
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen1 = 'week'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Weekly</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Week-Long Contest</div>
              <div class="infoHoverText">Contest will take place over the span of 1 week, with the winners being determined by total fantasy points earned.</div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen1 = 'bracket'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Tourn. Format</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Tournament Format</div>
              <div class="infoHoverText">
                Compete in a tournament style contest, multiple winners with the top percentage winning a greater share of the pot.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen1 = 'payouts'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Payout</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Payout</div>
              <div class="infoHoverText">Each contest has its payout structure. Check the ‘Prizes’ section in the Contest Information card.</div>
            </div>
          </div>
        </div>
        <div class="explanationImage"><img :src="require('@/assets/howtoplay/tournament/left_joincontest_' + theme + '.png')" alt="Tournament Join Contest"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/tournament/howto_lobby.png')" alt="Tournament Lobby">
        <img :style="screen1 === 'payouts' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_payout.png')" alt="Tournament Payouts">
        <img :style="screen1 === 'bracket' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_format.png')" alt="Tournament Format">
        <img :style="screen1 === 'week' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_weekly.png')" alt="Tournament Weekly">
      </div>
    </div>

    <!-- Live Draft -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Create and submit your best lineup</div>
        <div style="text-align: center;">
          Create your team’s lineup from the list of available players. Make the optimal 10-player team while staying under the Salary Cap.
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen2 = 'gameopps'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Game Opps</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Game Opportunities</div>
              <div class="infoHoverText">
                Each position has a certain number of games that will count towards your final score.
                Pay attention when drafting to maximize your potential or make a strategic choice to utilize our Best Ball system.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen2 = 'queue'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Best Ball</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Best Ball </div>
              <div class="infoHoverText">
                Going over your game opportunities activates Best Ball.
                When active, only the best scoring games within your game opportunities will be scored, while the lowest will be discarded.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen2 = 'playercard'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Salary Cap</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Salary Cap</div>
              <div class="infoHoverText">Your team must fit within the salary cap limit. You may have some extra salary at the end but must not go over.</div>
            </div>
          </div>
        </div>
        <div class="explanationImage"><img :src="require('@/assets/howtoplay/tournament/left_createlineup_' + theme + '.png')" alt="Tournament Lineup"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/tournament/createlineup.png')" alt="Tournament Lineup">
        <img :style="screen2 === 'playercard' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_gameopps.png')" alt="Tournament Opposition">
        <img :style="screen2 === 'gameopps' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_bestball.png')" alt="Tournament Bestball">
        <img :style="screen2 === 'queue' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_salarycap.png')" alt="Tournament Salarycap">
      </div>
    </div>

    <!-- Manage  Lineup -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Manage your Lineup</div>
        <div style="text-align: center;">
          Actively manage your team throughout the duration of the contest. Make strategic swaps to increase your chances to win the week.
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen3 = 'swap'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Player Swap</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Player Swap</div>
              <div class="infoHoverText">
                Swap your players in the Lineup Editor when they are injured, underperforming, or you are over the cap limit.
                You will be charged a 20% fee of the remaining salary for the player you are dropping.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen3 = 'transactions'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Cap Management</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Cap Management</div>
              <div class="infoHoverText">
                As your team plays games throughout the week your total salary cap will decrease depending on the players per game cost.
                Make sure to stay within the limit and make swaps if necessary.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen3 = 'bench'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Added Games</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Added Games</div>
              <div class="infoHoverText">
                If one of your players gets a game added to their schedule, you will have the ability to choose which game to bench or to swap them out for another player.
              </div>
            </div>
          </div>
        </div>
        <div class="explanationImage" style="margin-top: 25px;"><img :src="require('@/assets/howtoplay/tournament/left_managelineup.png')" alt="Manage Lineup"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/tournament/manage.png')" alt="Tournament Manage">
        <img :style="screen3 === 'swap' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_swap.png')" alt="Tournament Swap">
        <img :style="screen3 === 'transactions' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_capmanagement.png')" alt="Tournament Capmanagement">
        <img :style="screen3 === 'bench' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/tournament/howto_addedgames.png')" alt="Tournament Add Games">
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      screen1: 'default',
      screen2: 'default',
      screen3: 'default',
    };
  },

  computed: {
    ...mapState(['theme']),
  },
};
</script>