<template>
  <div class="ob-page">
    <div class="ob-box" style="font-size: 12px;">
      <div class="ob-subheading" style="margin-top: 6px;">Transaction History</div>
      <div style="margin-top: 12px; margin-left: 2px; display: flex; align-items: center;">
        <select v-model="showBonusTransactions" style="width: 160px;" @change="changeType()">
          <option :value="false">Account Balance</option>
          <option :value="true">OwnersBucks</option>
        </select>
        <select v-model="selectedMonth" @change="loadTransactions()" style="width: 110px; margin-left: 10px;">
          <option v-for="(month, index) in getMonthFilters()" :key="index" :value="month">{{ $moment().month(month).format('MMMM') }}</option>
        </select>
        <select v-model="selectedYear" @change="loadTransactions()" style="width: 70px; margin-left: 10px;">
          <option v-for="(year, index) in getYearFilters()" :key="index" :value="year">{{ year }}</option>
        </select>
        <button class="ob-btn downloadBtn" @click="downloadCSV()"
          :class="{'ob-btn-grey': csvLoading}" :disabled="csvLoading"
        >
          Download CSV
        </button>
      </div>


      <!-- Filters -->
      <div style="margin-top: 15px; margin-left: 5px; color: var(--obcolor-font-secondary);">Filter by Type</div>
      <div style="display: flex; margin-bottom: 10px; margin-top: 5px;">
        <FilterButton style="width: 110px;" :label="'Rewards'" :selected="filterList.DEPOSIT"
          @click="toggleFilter('DEPOSIT')" v-if="showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Deposit'" :selected="filterList.DEPOSIT"
          @click="toggleFilter('DEPOSIT')" v-if="!showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Referrals'" :selected="filterList.FRIEND_REFERRAL"
          @click="toggleFilter('FRIEND_REFERRAL')" v-if="showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Withdrawal'" :selected="filterList.WITHDRAWAL"
          @click="toggleFilter('WITHDRAWAL')" v-if="!showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Entry Fee'" :selected="filterList.ENTRY_FEE"
          @click="toggleFilter('ENTRY_FEE')"
        />
        <FilterButton style="width: 110px;" :label="'Refunds'" :selected="filterList.ENTRY_REFUND"
          @click="toggleFilter('ENTRY_REFUND')"
        />
        <FilterButton style="width: 110px;" :label="'Payouts'" :selected="filterList.PRIZE_PAYOUT"
          @click="toggleFilter('PRIZE_PAYOUT')"
        />
        <FilterButton style="width: 110px;" :label="'Conversion'" :selected="filterList.BONUS_CONVERSION"
          @click="toggleFilter('BONUS_CONVERSION')" v-if="!showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Conversion'" :selected="filterList.CONVERT_TO_CASH"
          @click="toggleFilter('CONVERT_TO_CASH')" v-if="showBonusTransactions"
        />
        <FilterButton style="width: 110px;" :label="'Ticket Purchase'" :selected="filterList.CONVERT_TO_TICKET"
          @click="toggleFilter('CONVERT_TO_TICKET')" v-if="showBonusTransactions"
        />
      </div>

      <table aria-label="Transaction">
        <tr>
          <th style="width: 100px; text-align: center;">Transaction Id</th>
          <th style="width: 185px;">Transaction Date</th>
          <th style="width: 120px;">Type</th>
          <th style="width: 120px; text-align: center;">Amount</th>
          <th style="width: 120px; text-align: center;">Balance</th>
          <th>Description</th>
          <th style="width: 90px;"></th>
        </tr>

        <tr v-if="transactions && transactions.length == 0">
          <td colspan="99">You have no transactions for the selected month.</td>
        </tr>

        <!-- Table Rows -->
        <template v-for="(transaction, index) in transactions">
          <tr v-show="filterList[transaction.type] === true || noFiltersSelected()" :key="index">
            <td style="text-align: center;">{{ transaction.id }}</td>
            <td>{{ $moment(transaction.date).format("MMMM Do YYYY, h:mma") }}</td>
            <td>{{ transaction.displayType }}</td>
            <td style="text-align: center;" :style="getPlusMinus(transaction.type) === '-' ? 'color: red;' : 'color: green;'">
              <img v-if="showBonusTransactions" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                style="height: 12px; margin-bottom: -2px;"
              >
              {{ getPlusMinus(transaction.type) + transaction.amount }}
            </td>
            <td style="text-align: center;">
              <img v-if="showBonusTransactions && transaction.userBalance" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                style="height: 12px; margin-bottom: -2px;"
              >
              {{ transaction.userBalance }}
            </td>
            <td>
              {{ transaction.notes }}
              <span v-if="transaction.leagueInfo != null">
                {{ transaction.notes ? " - " : "" }}
                {{ transaction.leagueInfo.sport ? transaction.leagueInfo.sport + " " : transaction.leagueInfo.allSports.join(', ') + " " }}
                {{ transaction.leagueInfo.players + " Team " }}
                {{ transaction.leagueInfo.fee }}
              </span>
            </td>
            <td>
              <router-link v-if="transaction.displayLeague && transaction.league && transaction.type != 'ENTRY_REFUND'"
                :to="'/contest/matchup/summary/' + transaction.league" class="leagueLink"
              >
                View Contest
              </router-link>
            </td>
          </tr>
        </template>
      </table>

      <!-- Loading -->
      <div v-if="account === null || transactions === null" style="text-align: center;">
        <ObLoading />
      </div>
    </div>
  </div>
</template>

<script>
import ObFinanceApi from '@/api/ObFinanceApi';
import ObLoading from '@/components/ObLoading.vue';
import FilterButton from '@/components/buttons/FilterButton';

export default {

  components: {
    ObLoading,
    FilterButton,
  },

  data() {
    return {
      selectedMonth: null,
      selectedYear: null,
      showBonusTransactions: false,
      transactions: null,
      account: null,
      ownersbucks: null,
      filterList: {
        DEPOSIT: false,
        FRIEND_REFERRAL: false,
        WITHDRAWAL: false,
        ENTRY_FEE: false,
        ENTRY_REFUND: false,
        PRIZE_PAYOUT: false,
        BONUS_CONVERSION: false,
        CONVERT_TO_CASH: false,
        CONVERT_TO_TICKET: false,
      },
      csvLoading: false,
    };
  },

  created() {
    const routeType = this.$route.params.type;
    this.showBonusTransactions = routeType === 'ownersbucks';
    this.setInitialDate();
    this.loadTransactions();
  },

  watch: {
    '$route.params.type'() {
      const routeType = this.$route.params.type;
      this.showBonusTransactions = routeType === 'ownersbucks';
      this.resetFilters();
      this.loadTransactions();
    },
  },

  methods: {
    getPlusMinus(tType) {
      switch (tType) {
        case 'DEPOSIT': return '+';
        case 'WITHDRAWAL': return '-';
        case 'ENTRY_FEE': return '-';
        case 'ENTRY_REFUND': return '+';
        case 'PRIZE_PAYOUT': return '+';
        case 'BONUS_CONVERSION': return '+';
        case 'CONVERT_TO_CASH': return '-';
        case 'FRIEND_REFERRAL': return '+';
        case 'CONVERT_TO_TICKET': return '-';
        case 'REMOVAL': return '-';
      }
      return '';
    },

    changeType() {
      if (this.showBonusTransactions) {
        this.$router.push('/transactions/ownersbucks');
      } else {
        this.$router.push('/transactions');
      }
    },

    resetFilters() {
      for (const filterIndex in this.filterList) {
        this.filterList[filterIndex] = false;
      }
    },

    setInitialDate() {
      const now = this.$moment();
      this.selectedMonth = now.month();
      this.selectedYear = now.year();
    },

    getMonthFilters() {
      const january = this.$moment().month(0);
      const monthList = [];
      for (let i = 0; i < 12; i++) {
        const month = january.clone().add(i, 'months');
        monthList.push(month.month());
      }
      return monthList;
    },

    getYearFilters() {
      const curYear = this.$moment();
      const yearList = [curYear.year()];
      for (let i = curYear.year() - 1; i >= 2019; i--) {
        yearList.push(i);
      }
      return yearList;
    },

    loadTransactions() {
      this.transactions = null;
      if (this.showBonusTransactions) {
        this.loadOwnersBucksData();
      } else {
        this.loadRealMoneyData();
      }
    },

    loadRealMoneyData() {
      const self = this;
      ObFinanceApi.getTransactions(this.selectedYear, this.selectedMonth + 1).then((data) => {
        self.transactions = data.transactions;
        self.account = data.account;
        self.ownersbucks = data.ownersbucks;
      });
    },

    loadOwnersBucksData() {
      const self = this;
      ObFinanceApi.getMyBonusTransactions(this.selectedYear, this.selectedMonth + 1).then((data) => {
        self.transactions = data.transactions;
        self.account = data.account;
        self.ownersbucks = data.ownersbucks;
      });
    },

    toggleFilter(filterName) {
      this.filterList[filterName] = !this.filterList[filterName];
    },

    noFiltersSelected() {
      for (const filterIndex in this.filterList) {
        if (this.filterList[filterIndex] === true) {
          return false;
        }
      }
      return true;
    },

    downloadCSV() {
      this.csvLoading = true;
      ObFinanceApi.getMyTransactionsCSV(this.selectedMonth + 1, this.selectedYear, !this.showBonusTransactions)
          .then(() => {
          // CSV file has been downloaded
          })
          .catch((error) => {
            if (error.response?.data) {
              alert(error.response.data);
            } else {
              alert(error.message);
            }
          })
          .finally(() =>{
            this.csvLoading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.tableFilter {
  background-color: var(--obcolor-background-2);
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  border: 1px solid var(--obcolor-background-2);
  border-radius: 8px;
  padding: 6px;
  margin: 5px;
  white-space: nowrap;
  cursor: pointer;
  &.btnSelected {
    border: 1px solid rgb(7, 155, 224);
    color: var(--obcolor-font-primary);
    background-color: var(--obcolor-background-6);
    &:hover {
      background-color: var(--obcolor-bg-hover);
    }
    &:active {
      background-color: var(--obcolor-background-5);
    }
  }
}
.tableFilter:hover {
  background-color: var(--obcolor-bg-hover);
}
.tableFilter:active {
  background-color: var(--obcolor-background-5);
}
table {
  text-align: left;
  width: 100%;
  font-size: 14px;
}
td, th {
  padding: 12px;
}
th {
  background: var(--obcolor-background-2);
}
td, .rowHeader {
  white-space: nowrap;
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}
.ob-subheading {
  margin-bottom: 10px;
}
.leagueLink:hover {
  text-decoration: underline;
}
.downloadBtn {
  padding: 8px 10px;
  margin-left: 10px;
}
</style>
