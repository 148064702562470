<template>
  <div class="ob-page" style="display: flex;">
    <!-- Left Column - Scores -->
    <div class="scores">
      <div class="scoresHeader">
        <div class="sportFilters">
          <!-- Sport Selector -->
          <button class="btnContainer ob-btn ob-btn-transparent" :class="{'btnSelected': !selectedSport}" style="width: 120px;"
            @click="setSelectedSport(null)"
          >
            All Sports
          </button>
          <button v-for="sport in sports" class="btnContainer ob-btn ob-btn-transparent" :class="{'btnSelected': selectedSport === sport.value}"
            @click="setSelectedSport(sport.value)" :key="sport.value"
          >
            <i :class="sport.icon"></i>
            {{ sport.value }}
          </button>
        </div>
        <div class="dateSelector">
          <!-- Date Selector -->
          <!-- Left Arrow -->
          <div class="ob-btn-black" @click="datePrev()">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="ob-btn-black dateDisplay" @click="toggleCalendar()">
            <span>{{ selectedDateFormatted }}</span>
          </div>
          <!-- Right Arrow -->
          <div class="ob-btn-black" @click="dateNext()">
            <i class="fas fa-chevron-right"></i>
          </div>
          <ObCalendar v-if="calendarOpen" v-on-clickaway="hideCalendar"
            :selectedDate="selectedDate" @date-change="changeDate" @close-calendar="hideCalendar"
          />
        </div>
      </div>
      <template v-if="loading">
        <ObLoading />
      </template>
      <template v-else>
        <template v-if="selectedSport">
          <h3>{{ selectedSport }}</h3>
          <div class="gameBox">
            <template v-for="(game, index) in allGames[selectedSport]">
              <ScoreboardGameEntry :key="'game_' + index" :sport="selectedSport" :game="liveGameData(game)" />
            </template>
            <template v-if="allGames[selectedSport].length === 0">
              No Games
            </template>
          </div>
        </template>
        <template v-else>
          <template v-for="sport in sports">
            <template v-if="allGames[sport.value] && allGames[sport.value].length !== 0">
              <h3 :key="'header_' + sport.value">{{ sport.value }}</h3>
              <div class="gameBox" :key="sport.value">
                <template v-for="(game, index) in allGames[sport.value]">
                  <ScoreboardGameEntry :key="'game_' + index" :sport="sport.value" :game="liveGameData(game)" />
                </template>
              </div>
            </template>
          </template>
          <div v-if="Object.values(allGames).every(sportGames => sportGames.length === 0)" style="margin-top: 20px;">
            There are no games scheduled for the selected day
          </div>
        </template>
      </template>
    </div>
    <!-- Right Column - News Feed -->
    <div class="feedSection">
      <SponsorImage class="feedSponsor" sponsorKey="dashboard-feed"
        width="350px" height="185px"
      />
      <div class="feedsContainer">
        <FeedsView />
      </div>
    </div>
  </div>
</template>

<script>
import ObLoading from '@/components/ObLoading';
import ObCalendar from '@/components/ObCalendar';
import ObSportDataApi from '@/api/ObSportDataApi';
import {mixin as clickaway} from 'vue-clickaway';
import moment from 'moment';
import {mapState} from 'vuex';
import ScoreboardGameEntry from '@/components/Scoreboard/ScoreboardGameEntry';
import FeedsView from '@/views/Feeds/FeedsView';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  components: {
    ObLoading,
    ObCalendar,
    ScoreboardGameEntry,
    FeedsView,
    SponsorImage,
  },

  data() {
    return {
      selectedSport: null,
      selectedDate: null,
      calendarOpen: false,
      allGames: {},
      sports: [
        {label: 'NFL', value: 'NFL', icon: 'fas fa-football-ball'},
        {label: 'NBA', value: 'NBA', icon: 'fas fa-basketball-ball'},
        {label: 'MLB', value: 'MLB', icon: 'fas fa-baseball-ball'},
        {label: 'NHL', value: 'NHL', icon: 'fas fa-hockey-puck'},
      ],

      loading: true,
    };
  },

  mixins: [clickaway],

  created() {
    this.selectedSport = null;
    this.selectedDate = new Date();
    this.calendarOpen = false;
  },

  computed: {
    ...mapState(['todaysGames', 'isScoreboardVisible']),

    selectedDateFormatted() {
      return moment(this.selectedDate).format('ddd MMM D, YYYY');
    },
  },

  watch: {
    selectedDate() {
      this.getDayAllGames();
    },
  },

  methods: {
    getDayAllGames() {
      this.loading = true;
      Promise.all(
          this.sports.map((sport) =>
            this.getDayGamesBySport(moment(this.selectedDate).format('YYYY-MM-DD'), sport.value)),
      ).then(() => {
        this.loading = false;
      });
    },

    getDayGamesBySport(day, sport) {
      return new Promise((resolve) => {
        ObSportDataApi.getScoreboardDayGames(sport, day)
            .then((response) => {
              this.allGames[sport] = response.games;
              resolve(response);
            })
            .catch((_error) => {
              // Do nothing
            });
      });
    },

    setSelectedSport(sport) {
      if (this.selectedSport === sport) {
        this.selectedSport = null;
      } else {
        this.selectedSport = sport;
      }
    },

    datePrev() {
      const newSelectedDate = new Date(this.selectedDate);
      newSelectedDate.setDate(newSelectedDate.getDate() - 1);
      this.selectedDate = newSelectedDate;
    },

    dateNext() {
      const newSelectedDate = new Date(this.selectedDate);
      newSelectedDate.setDate(newSelectedDate.getDate() + 1);
      this.selectedDate = newSelectedDate;
    },

    toggleCalendar() {
      this.calendarOpen = !this.calendarOpen;
    },

    changeDate(newDate) {
      this.selectedDate = newDate;
    },

    hideCalendar() {
      this.calendarOpen = false;
    },

    liveGameData(game) {
      if (this.todaysGames[game.id]) {
        return this.todaysGames[game.id];
      }
      return game;
    },
  },
};
</script>

<style lang="scss" scoped>
.gameBox {
  display: flex;
  flex-wrap: wrap;
}

.feedSection {
  display: flex;
  flex-direction: column;
  flex: 0;
  position: sticky;
  top: calc(var(--ob-nav-offset) + 15px);
  height: calc(100vh - (75px + var(--ob-nav-offset)));
  margin-left: 15px;
}

.feedSponsor {
  margin-bottom: 5px;
}

.feedsContainer {
  overflow: hidden;
  width: 350px;
  min-width: 350px;
  border-radius: 5px;
  flex: 1;
  background: var(--obcolor-background-4);
  transition: top 0.3s ease-out, height 0.3s ease-out !important;
}

.ob-btn-transparent {
  padding: 8px 12px;
  border-radius: 5px;
  background: var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
  border: 1px solid transparent;

  &:hover {
    color: var(--obcolor-text-primary) !important;
  }
}

.sportFilters {
  display: flex;
  gap: 8px;
  margin-right: 15px;

  .btnContainer {
    color: var(--obcolor-text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      padding-right: 8px;
    }
  }

  .btnSelected {
    border: 1px solid var(--obcolor-ob-blue);
    color: var(--obcolor-text-primary) !important;
  }

  > div {
    display: flex;
  }
}

.dateSelector {
  > div {
    border: 1px solid transparent;
  }
}

.scores {
  flex: 1;
  min-width: 1000px;
  max-width: 1070px;
}

.scoresHeader {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
}

.dateDisplay {
  margin: 0 5px;
  width: 130px;

  > span {
    font-size: 14px;
  }
}
</style>