<template>
  <div class="liveTeamsList">
    <!-- Row for each team -->
    <div class="teamRow" v-for="(team, tIndex) of currentShownTeams" :key="tIndex"
      :style="maxPage > 0 ? '' : 'flex: 1;'"
    >
      <!-- Team's rank in "Xst, Xnd, Xrd" etc. format -->
      <div class="teamRank" v-if="entry.state === 'CANCELLED' || team.isRemoved || team.rank <= 0">-</div>
      <div class="teamRank trophyRank" v-else-if="team.rank == 1 && !team.isTied"><i class="fas fa-trophy"></i></div>
      <div class="teamRank" v-else>{{ getRank(team) }}</div>

      <!-- Each player on the team -->
      <div v-for="(player, pIndex) of team.players" :key="pIndex" class="playerContainer"
        @click="openPlayerCard(player.id)" v-tooltip.bottom="{content: getPlayerTooltip(player), class:'ObTooltip'}"
      >
        <PlayerHeadshot :id="player.id" :sport="sport" :team="player.teamAlias"
          size="32" :showLogo="true" class="playerHeadshot"
        />
      </div>

      <!-- Team's points -->
      <div class="pointsSection">
        <template v-if="entry.state !== 'CANCELLED'">
          <PieChart v-if="!isCompleted" class="pieChart" size="18"
            :slices="getTeamPieSlices(team)"
          />
          <div v-if="!team.isRemoved" class="teamPoints" :class="{redText: team.fpts < 0}">{{ team.fpts.toFixed(2) }}</div>
        </template>
        <div v-if="team.isRemoved" class="lineupCancelledText">
          Lineup<br>Cancelled
        </div>
      </div>
    </div>

    <!-- Spacer to ensure height is consistent -->
    <div v-if="maxPage > 0" style="flex: 1;"></div>

    <!-- Left/Right Buttons to move paging -->
    <div v-if="maxPage > 0" class="pageControls">
      <div class="pageButton" @click="prevPage()" :class="{ disabledBtn: pageIndex == 0 }">
        <i class="fa fa-chevron-left"></i>
      </div>
      <div class="pageNumbers">
        Page {{ pageIndex + 1 }} of {{ maxPage + 1 }}
      </div>
      <div class="pageButton" @click="nextPage()" :class="{ disabledBtn: pageIndex == maxPage }">
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PieChart from '@/components/charts/PieChart';
import EventBus from '@/event-bus';

export default {
  props: {
    entry: {type: Object, default: null},
    isCompleted: {type: Boolean, default: false},
  },

  components: {
    PlayerHeadshot,
    PieChart,
  },

  data() {
    return {
      pageIndex: 0,
      teamsPerPage: 2,
    };
  },

  computed: {
    sport() {
      if (!this.entry || !this.entry.sport) {
        return null;
      }
      return this.entry.sport;
    },

    maxPage() {
      if (!this.entry || !this.entry.obTeams) {
        return 0;
      }
      return Math.ceil(this.entry.obTeams.length / this.teamsPerPage) - 1;
    },

    currentShownTeams() {
      if (!this.entry || !this.entry.obTeams) {
        return [];
      }

      // Sort by rank
      const sortedTeams = this.entry.obTeams.slice().sort(function(a, b) {
        return a.rank - b.rank;
      });

      // Add removed lineup
      if (this.entry.hasRemovedObTeams) {
        for (const index in this.entry.removedObTeams) {
          sortedTeams.push(this.entry.removedObTeams[index]);
        }
      }

      // Get players from the start of the current page to the end
      // For example if the page was 0 and teamsPerPage is 2, we'd get 2 players: players[0] and players[1]
      return sortedTeams.slice(this.pageIndex * this.teamsPerPage, this.pageIndex * this.teamsPerPage + this.teamsPerPage);
    },
  },

  methods: {
    playersByGameStatus(team) {
      const playerGameStatus = {
        upcoming: [],
        completed: [],
        // Catch all for players who don't have games or games don't have status
        unplayed: [],
      };
      if (!team && !team.players) {
        return playerGameStatus;
      }

      // Sort players into categories based on their game's status
      for (const player of team.players) {
        if (player.game && player.game.gameCompleted === true) {
          playerGameStatus.completed.push(player);
        } else if (player.game && player.game.gameCompleted === false) {
          playerGameStatus.upcoming.push(player);
        } else {
          playerGameStatus.unplayed.push(player);
        }
      }
      return playerGameStatus;
    },

    getTeamPieSlices(team) {
      const slices = [];
      const teamInfo = this.playersByGameStatus(team);
      const teamSize = team.players ? team.players.length : 0;
      const pct = Math.round( teamInfo.upcoming.length / teamSize * 100 );

      const baseColor = 'var(--obcolor-ob-blue)';
      const emptyColor = 'var(--obcolor-background-2)';

      slices.push({color: emptyColor, percent: 100 - pct});
      slices.push({color: baseColor, percent: pct});
      return slices;
    },

    getRank(team) {
      const tiedPrefix = team.isTied ? 'T-' : '';
      return tiedPrefix + String(team.rank).addSuffix();
    },

    prevPage() {
      if (this.pageIndex > 0) {
        this.pageIndex--;
      }
    },

    nextPage() {
      if (this.pageIndex < this.maxPage) {
        this.pageIndex++;
      }
    },

    getPlayerTooltip(player) {
      if (!player || !player.name) {
        return '';
      }
      return player.name.shortName() + ' - ' + this.getTeamAlias(player).toUpperCase();
    },

    openPlayerCard(id) {
      EventBus.$emit('OPEN_PLAYER_CARD', id, this.sport);
    },

    getTeamAlias(player) {
      return this.$TeamInfo.getDisplayAlias(this.sport, player.teamAlias);
    },
  },
};
</script>

<style lang="scss" scoped>
.liveTeamsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.playerContainer {
  cursor: pointer;
}

.teamRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid var(--obcolor-background-page);
  width: 100%;
  box-sizing: border-box;
  height: 48px;
}

.teamRow:last-child {
  border-bottom: 0;
}

.teamRank {
  width: 30px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

.trophyRank {
  color: var(--obcolor-trophy-1st);
  font-size: 16px;
}

.pointsSection {
  display: flex;
  align-items: center;
  width: 60px;
  padding-left: 3px;
  color: var(--obcolor-red) !important;
}

.lineupCancelledText {
  font-size: 12px;
  font-weight: 700;
  text-align: right;
}

.pieChart {
  padding-top: 3px;
  padding-right: 5px;
}

.teamPoints {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;
  flex: 1;
  text-align: center;
}

.pageControls {
  height: 24px;
  display: flex;
  align-items: center;

  .pageButton {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(.disabledBtn):hover {
      background: var(--obcolor-background-4);
    }

    &.disabledBtn {
      cursor: not-allowed;
      color: var(--obcolor-font-secondary);
    }
  }

  .pageNumbers {
    color: var(--obcolor-font-secondary);
    flex: 1;
    text-align: center;
    font-size: 14px;
  }
}

.redText {
  color: var(--obcolor-red) !important;
}
</style>