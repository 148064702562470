// Live Summary Stats
// Controls the top overall summary for live page

// Quarterback Stats
export const INGAME_NBA_STATS = [
  {
    label: 'Stats',
    stats: [
      {label: 'player.PTS', path: 'player.PTS', description: 'Pts'},
      {label: 'player.REB', path: 'player.REB', description: 'Reb'},
      {label: 'player.AST', path: 'player.AST', description: 'Ast'},
      {label: 'player.BLK', path: 'player.BLK', description: 'Blk'},
      {label: 'player.STL', path: 'player.STL', description: 'Stl'},
      {label: 'player.TO', path: 'player.TO', description: 'TO'},
    ],
  },
];