<template>
  <div style="display: flex; width: 100%; padding-bottom: 15px;">
    <div class="bracket" v-for="week in league.numWeeks" :key="week">
      <div class="weekHeaderContainer">
        <div class="weekHeader">Week {{ week }}</div>
      </div>
      <div class="matchupContainer" v-for="index2 in matchupsInWeek(week)" :key="index2"
        style="display: flex; flex-direction: column; flex: 1;"
      >
        <div style="display: flex; flex: 1;">
          <div class="matchup">
            <div :style="getMatchupMargin(week)" class="matchupTeam">
              <div class="payoutContainer" v-if="league.payouts[week - 2] && league.payouts[week - 2].value > 0 && league.payouts[week - 2].prize">
                <div class="payout">
                  <div class="payoutText" :class="league.realMoney ? 'moneyText' : 'obText'">
                    {{ payFormat(league.payouts[week - 2].prize) }}
                  </div>
                </div>
              </div>
            </div>
            <div :style="getMatchupMargin(week)" class="matchupTeam">
              <div class="payoutContainer" v-if="league.payouts[week - 2] && league.payouts[week - 2].value > 0 && league.payouts[week - 2].prize">
                <div class="payout">
                  <div class="payoutText" :class="league.realMoney ? 'moneyText' : 'obText'">
                    {{ payFormat(league.payouts[week - 2].prize) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="matchupConnectorContainer" :style="getBracketHeightMargin(week)">
            <div class="matchupConnector">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="winnerBracket">
      <div class="weekHeaderContainer">
        <div class="weekHeader"></div>
      </div>
      <div class="winnerAlignContainer">
        <div class="winnerCenterContainer">
          <i :class="league.realMoney == true ? 'moneyText' : 'obText'" class="fas fa-trophy finalWinningTrophy"></i>
          <div class="finalWinningPayout">
            <div class="payout">
              <div class="payoutText" :class="league.realMoney == true ? 'moneyText' : 'obText'">
                {{ payFormat(finalWinningPrize.removeCentsIfZero()) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="flex: 1;">
      <div class="weekHeaderContainer">
        <div class="weekHeader"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    league: Object,
  },

  computed: {
    finalWinningPrize() {
      const finalPayout = this.league.payouts[this.league.payouts.length - 1];
      return finalPayout.prize;
    },
  },

  methods: {
    getMatchupOffset(weekNum) {
      return ((15 * (Math.pow(2, weekNum)) - 30) / 2);
    },
    // Similar to getMatchupMargin but accounting for bracket height and not subtracting matchup container height
    getBracketHeightMargin(weekNum) {
      const offset = this.getMatchupOffset(weekNum) + 13;
      return 'margin-top: ' + offset + 'px; margin-bottom: ' + offset + 'px;';
    },
    // Puts each matchup team in the middle of the bracket before it
    getMatchupMargin(weekNum) {
      const offset = this.getMatchupOffset(weekNum);
      return 'margin-top: ' + offset + 'px; margin-bottom: ' + offset + 'px;';
    },

    matchupsInWeek(weekNum) {
      let players = this.league.playersMax;

      for (let i = 0; i < weekNum; ++i) {
        players = players/2;
      }

      return players;
    },

    payFormat(prize) {
      if (this.league.realMoney) {
        return prize.removeCentsIfZero();
      }
      return prize.removeCentsIfZero().replace('$', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.weekHeaderContainer {
  background: var(--obcolor-background-3);
  margin-bottom: 20px;
}

.weekHeaderContainer:not(:last-child) {
  padding-right: 30px;
}

.weekHeader {
  text-align: center;
  padding: 6px 0;
  height: 24px;
  font-size: 12px;
  box-sizing: border-box;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
}

.bracket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  // cursor: pointer;
}

.payoutContainer {
  position: absolute;
  top: -17px;
  display: flex;
  align-items: flex-end;
  width: calc(100% - 10px);
  box-sizing: border-box;
}
.payoutTrophy {
  color: var(--obcolor-background-1);
  padding-right: 5px;
  font-size: 20px;
}

.payout {
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 16px;
  width: 100%;
}

.payoutText {
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.matchupContainer {
  min-height: 55px;
}

.matchup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
}

.matchupTeam {
  width: 60px;
  height: 20px;
  display: flex;
  position: relative;
  align-items: center;
  z-index: 1;
  background: var(--obcolor-background-2);
  margin: 5px;
  padding-left: 5px;
  padding-right: 9px;
  font-size: 12px;
}

.matchupConnectorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  box-sizing: border-box;
}

.matchupScore {
  font-size: 11px;
  padding: 5px;
  margin-left: 5px;
}

.matchupConnector {
  border-top: 3px solid var(--obcolor-background-1);
  border-bottom: 3px solid var(--obcolor-background-1);
  border-right: 3px solid var(--obcolor-background-1);
  height: 100%;
  width: 20px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}


.winnerBracket {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.winnerAlignContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-top: -15px;
  margin-left: 10px;
}

.winnerCenterContainer {
  position: relative;
  align-items: center;
  text-align: center;
}

.finalWinningTrophy {
  width: 100%;
  // position: absolute;
  left: 0;
  top: 20px;
  font-size: 40px;
  color: var(--obcolor-background-1);
}
.finalWinningPayout {
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
}
</style>