<template>
  <div class="context-menu" ref="root" v-on-clickaway="closeMenu">
    <button class="btn-open" @click="openMenu">
      <slot></slot>
    </button>
    <div class="menu" :class="{open}" @click="closeMenu">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
// @ts-check
import Vue from 'vue';
import {mixin as clickaway} from 'vue-clickaway2';

export default Vue.extend({
  mixins: [clickaway],

  data() {
    return {
      open: false,
      windowClickHandler: null,
    };
  },

  methods: {
    openMenu() {
      this.open = true;
    },
    closeMenu() {
      this.open = false;
    },
  },
});
</script>

<style scoped>
.context-menu {
  position: relative;
}

.btn-open {
  all: unset;
  cursor: pointer;
}

.menu {
  display: none;
}

.menu.open {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  background-color: var(--obcolor-background-3);
  right: 0;
}

.menu > button {
  all: unset;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}
</style>