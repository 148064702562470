<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="border: 2px solid var(--obcolor-background-6); overflow: auto; width: 900px; max-height: 600px;"
    bodyStyle="margin: 0;"
  >
    <div slot="header" class="transactionHeader">Transactions</div>
    <div slot="body" style="flex: 1;">
      <div class="ob-scroll" style="overflow-y: auto" v-if="isLoading == false && transactions && transactions.length > 0">
        <div v-for="(transaction, index) in transactions" :key="index"
          class="transaction"
        >
          <div style="flex: 1; padding-left: 10px;">
            <div>{{ $moment(transaction.timestamp).format("MMM D, YYYY h:mma") }}</div>
            <div v-if="transaction.type === 'waivers'" style="margin-top: 3px;">Waiver Claim</div>
          </div>
          <UserLogo :id="transaction.userId" :size="50" :sport="league.sport" />
          <div style="margin-left: 10px; text-align: left; flex: 1;">
            <div style="font-weight: bold; font-size: 14px;">{{ transaction.userTeam }}</div>
            <div style="font-weight: bold; color: var(--obcolor-font-secondary); margin-top: 2px; margin-bottom: 2px;">{{ transaction.userName }}</div>
            <div style="font-weight: bold;">
              <span style="margin-right: 5px;">Week {{ transaction.weekNum }}</span>
              <span v-if="transaction.dropNum">Drop {{ transaction.dropNum }}/{{ transaction.dropMax }}</span>
              <span v-else>Inactive Drop</span>
            </div>
          </div>

          <!-- Dropped Player -->
          <div class="playerBox">
            <i class="fa fa-minus" style="color: var(--obcolor-red); font-size: 20px;"></i>
            <PlayerHeadshot style="margin-right: 10px; margin-left: 10px;" :id="transaction.playerDropped.id" size="50"
              :sport="league.sport" :team="transaction.playerDropped.team"
            />
            <div>
              <div style="font-weight: bold;">{{ transaction.playerDropped.name.longName() }}</div>
              <div style="color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: 3px;">
                #{{ transaction.playerDropped.number }}
                {{ transaction.playerDropped.position }}
              </div>
              <div style="color: var(--obcolor-font-secondary); text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(league.sport, transaction.playerDropped.team) }}</div>
            </div>
          </div>

          <!-- Added Player -->
          <div class="playerBox">
            <i class="fa fa-plus" style="color: var(--obcolor-green); font-size: 20px;"></i>
            <PlayerHeadshot style="margin-right: 10px; margin-left: 10px;" :id="transaction.playerAdded.id" size="50"
              :sport="league.sport" :team="transaction.playerAdded.team"
            />
            <div>
              <div style="font-weight: bold;">{{ transaction.playerAdded.name.longName() }}</div>
              <div style="color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: 3px;">
                #{{ transaction.playerAdded.number }}
                {{ transaction.playerAdded.position }}
              </div>
              <div style="color: var(--obcolor-font-secondary); text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(league.sport, transaction.playerAdded.team) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading == false" style="overflow: hidden; font-size: 14px; margin-left: 5px;">There are no transactions for this contest.</div>
      <ObLoading v-else />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ObLoading from '@/components/ObLoading';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';
import ObLeagueApi from '@/api/ObLeagueApi';

export default {
  components: {
    Modal,
    ObLoading,
    PlayerHeadshot,
    UserLogo,
  },

  data: function() {
    return {
      isOpen: false,
      league: null,
      transactions: [],
      isLoading: false,
    };
  },

  created() {
    EventBus.$on('OPEN_TRANSACTIONS_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_TRANSACTIONS_MODAL', this.openModal);
  },

  methods: {
    openModal(league) {
      this.isOpen = true;
      this.league = league;
      this.loadLeagueTransactions();
    },

    loadLeagueTransactions() {
      this.isLoading = true;
      ObLeagueApi.getLeagueTransactions(this.league.id)
          .then((data) => {
            this.isLoading = false;
            this.transactions = data;
          })
          .catch((error) =>{
            this.isLoading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.transactionHeader {
  height: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 5px;
  margin-bottom: 5px;
}

.transaction {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.playerBox {
  text-align: left;
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 200px;
}

.transaction:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.selectedText {
  color: var(--obcolor-ob-blue) !important;
}
</style>
