<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 555px; height: 507px; padding: 0; overflow: hidden;"
    bodyStyle="padding: 15px; background: var(--obcolor-background-3);"
    :defaultHeaderStyle="true"
  >
    <div slot="header">Report Bug/Feedback</div>
    <div slot="body" style="display: flex; flex-direction: column; height: 100%; font-size: 14px;">
      <div>
        Thank you for helping Ownersbox improve its platform!
        Please complete the form below to report the bug you encountered or provided a detailed description of how we can improve the page you have selected.
        You can also provide feedback or report a bug directly by emailing <a class="hyperlink" href="mailto:feedback@ownersbox.com">feedback@ownersbox.com</a>
      </div>
      <div style="margin-top: 10px; display: flex;">
        <div style="flex: 1; padding-right: 15px;">
          Which page are you reporting?
          <input style="margin-top: 5px; width: 100%; box-sizing: border-box; height: 30px;" v-model="pageReporting" placeholder="Ex. Dashboard">
        </div>
        <div style="flex: 1;">
          Bug or Feedback?
          <select v-model="bugFeature" style="margin-top: 5px; padding: 0 5px; width: 100%; height: 30px;">
            <option value="Bug Report">Report a Bug</option>
            <option value="Feedback">Submit Feedback</option>
          </select>
        </div>
      </div>
      <textarea style="margin-top: 10px; width: 100%; flex: 1; box-sizing: border-box; resize: none;" v-model="bugDescription"
        :placeholder="'If you are reporting a bug, please give a detailed description of how you encountered the bug.'
          + ' If you cannot remember, please do your best to provide a detailed description of the issue.'"
      ></textarea>
      <div style="margin-top: 5px; font-size: 14px; color: red;" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
    <div slot="footer" class="bugReportFooter">
      <button class="ob-btn-grey ob-btn-med" style="margin-right: 10px; width: 120px;" @click="closeModal()">Cancel</button>
      <button v-if="requestPending" class="ob-btn-grey ob-btn-med" disabled
        style="width: 120px; padding: 0; display: flex; align-items: center; justify-content: center;" @click="sendReport()"
      >
        <ObLoading :size="40" style="margin-left: -10px; margin-top: -10px; margin-bottom: -10px;" />
        <span>Sending...</span>
      </button>
      <button v-else class="ob-btn ob-btn-med" style="width: 120px;"
        @click="sendReport()"
        v-tooltip.top.notrigger="{ content: (bugFeature == 'Bug Report' ? 'Bug report' : 'Feedback') + ' sent!', class: 'ObTooltip ob-tooltip-fadeout', visible: isSent }"
      >
        Report
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      pageReporting: '',
      bugFeature: 'Bug Report',
      bugDescription: '',
      requestPending: false,
      errorMessage: '',

      isSent: false,
      isSentTimeout: null,
    };
  },

  created() {
    EventBus.$on('OPEN_BUG_REPORT_MODAL', this.openModal);
  },

  destroyed() {
    clearTimeout(this.isSentTimeout);
    EventBus.$off('OPEN_BUG_REPORT_MODAL', this.openModal);
  },

  watch: {
    isSent(newVal, oldVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.isSentTimeout);
        this.isSentTimeout = setTimeout(function() {
          self.resetIsSent();
        }, 3000);
      }
    },
  },

  methods: {
    openModal() {
      this.isOpen = true;
      this.resetData();
    },

    resetData() {
      this.pageReporting = '';
      this.bugFeature = 'Bug Report';
      this.bugDescription = '';
      this.requestPending = false;
    },

    closeModal() {
      this.isOpen = false;
    },

    resetIsSent() {
      this.isSent = false;
    },

    sendReport() {
      const curRoute = this.$router.currentRoute.path;

      const params = {
        page: this.pageReporting,
        reportType: this.bugFeature,
        url: curRoute,
        description: this.bugDescription,
      };

      this.requestPending = true;

      ObAccountApi.sendBugOrFeedback(params)
          .then((response) => {
            this.isSent = true;
            this.resetData();
          })
          .catch((error) => {
            this.requestPending = false;
            if (error.response && error.response.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Error sending report';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
// re-add hyperlink colours
.hyperlink {
  text-decoration: underline;
  color: var(--obcolor-ob-blue);
  cursor: pointer;
}

.hyperlink:visited {
  color: var(--obcolor-ob-blue-tinted);
}

.hyperlink:active {
  color: var(--obcolor-ob-blue-tinted);
}

.bugReportFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}
</style>