<template>
  <tr class="teamHeader" v-if="myTeam">
    <th class="userLogoField myField" scope="col">
      <UserLogo class="logo" :id="myTeam.userId" :sport="leagueData.sport"
        :size="45" :showRank="true"
        :rankColor="myTeam.rankColor" :rankNum="myTeam.rankNum"
      />
    </th>
    <th class="myField" scope="col">
      <div class="teamNameContainer">
        <div class="teamName">
          <span>{{ myTeam.team }}</span>
          <span v-if="myTeam.entries > 1" style="margin-left: 3px;">({{ myTeam.teamNum }})</span>
        </div>
        <div class="teamSubName">Rank {{ String(myTeam.rank).addSuffix() }}</div>
        <div class="teamPayout" :class="{moneyText: leagueData.realMoney, blueText: !leagueData.realMoney}">
          <img v-if="!leagueData.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
            alt="OwnersBucks Icon"
          >
          <img v-else class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
            alt="Money Bag"
          >
          <span>{{ myTeam.prize.removeCentsIfZero() }}</span>
          <template v-if="myTeam.splitTicketPrize || myTeam.ticketPrize">
            <span style="padding: 0 3px;">+</span>
            <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
              v-tooltip.bottom="getTicketTooltip(myTeam)"
            >
          </template>
        </div>
      </div>
    </th>
    <!-- Live stats -->
    <th v-if="leagueData.sport === 'MLB' && leagueData.format == 'DAILY'" scope="row"></th>
    <th class="contestHeaderField myField" scope="row">
      <div class="teamScoreContainer">
        <div :class="{ blueText: teamWinning(false), negativeText: myTeam.score < 0 }" class="teamScore">
          {{ myTeam.score }}
        </div>
        <div class="subText">Proj {{ myTeam.proj }}</div>
      </div>
    </th>

    <!-- Middle -->
    <th class="middleField" scope="col">vs</th>

    <!-- Opp team -->
    <th scope="col" v-if="!oppTeam" colspan="4"
      style="min-width: 340px; padding: 0;"
    >
      <ObLoading :size="60" />
    </th>
    <template v-else>
      <th class="contestHeaderField oppField">
        <div class="teamScoreContainer">
          <div :class="{ blueText: teamWinning(true), negativeText: oppTeam.score < 0}" class="teamScore">
            {{ oppTeam.score }}
          </div>
          <div class="subText">Proj {{ oppTeam.proj }}</div>
        </div>
      </th>
      <!-- Live stats -->
      <th v-if="leagueData.sport === 'MLB' && leagueData.format == 'DAILY'"></th>
      <th class="oppField">
        <div class="teamNameContainer">
          <div class="teamName">
            <span>{{ oppTeam.team }}</span>
            <span v-if="oppTeam.entries > 1" style="margin-left: 3px;">({{ oppTeam.teamNum }})</span>
          </div>
          <div class="teamSubName">Rank {{ String(oppTeam.rank).addSuffix() }}</div>
          <div class="teamPayout" :class="{moneyText: leagueData.realMoney, blueText: !leagueData.realMoney}">
            <img v-if="!leagueData.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            <img v-else class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
              alt="Money Bag"
            >
            <span>{{ oppTeam.prize.removeCentsIfZero() }}</span>
            <template v-if="oppTeam.splitTicketPrize || oppTeam.ticketPrize">
              <span style="padding: 0 3px;">+</span>
              <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
                v-tooltip.bottom="getTicketTooltip(oppTeam)"
              >
            </template>
          </div>
        </div>
      </th>
      <th class="userLogoField oppField">
        <UserLogo class="logo" :id="oppTeam.id" :sport="leagueData.sport"
          :size="45" :showRank="true"
          :rankColor="oppTeam.rankColor" :rankNum="oppTeam.rankNum"
        />
      </th>
    </template>
  </tr>
  <tr v-else>
    <th scope="col" colspan="99">
      <ObLoading :size="60" />
    </th>
  </tr>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    UserLogo,
    ObLoading,
  },

  props: {
    myTeam: Object,
    oppTeam: Object,
    leagueData: Object,
  },

  methods: {
    contestIsOver() {
      if (!this.leagueData) {
        return false;
      }
      return this.leagueData.state === 'COMPLETED' || this.leagueData.state === 'FINALIZED';
    },

    teamWinning(isOppTeam) {
      const curTeam = isOppTeam ? this.oppTeam : this.myTeam;
      const compareTeam = isOppTeam ? this.myTeam : this.oppTeam;
      if (!curTeam && compareTeam) {
        return false;
      }

      if (!compareTeam && curTeam) {
        return true;
      }

      if (curTeam.score > compareTeam.score) {
        return true;
      }

      if (compareTeam.score > curTeam.score) {
        return false;
      }

      if (curTeam.score == compareTeam.score) {
        return true;
      }
    },

    getTicketTooltip(team) {
      let text = '';
      if (team.ticketPrize) {
        text = team.ticketPrize;
      } else if (team.splitTicketPrize) {
        text = team.splitTicketPrize;
      }
      return {content: text, class: 'ObTooltip'};
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  height: 18px;
  padding: 14px 0;
  background: var(--obcolor-background-3);
  border-bottom: 1px solid var(--obcolor-background-page);
  text-align: center;
}

tr:not(:last-child) {
  td {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

tr.playerRow {
  cursor: pointer;
}
tr.playerRow:hover {
  background: var(--obcolor-background-3);
}

.teamHeader th {
  height: 65px;
}

.userLogoField {
  width: 47px;

  &.myField {
    padding-left: 15px;
  }

  &.oppField {
    padding-right: 20px;
  }
}

.myField {
  .teamNameContainer {
    align-items: flex-start;
  }

  .teamName, .teamSubName, .teamPayout {
    text-align: left;
    padding-left: 25px;
  }
}

.oppField {
  .teamNameContainer {
    align-items: flex-end;
  }

  .teamName, .teamSubName, .teamPayout {
    padding-right: 25px;
    text-align: right;
  }

}

.teamNameContainer {
  display: flex;
  flex-direction: column;
}

.teamName {
  font-size: 14px;
  font-weight: bold;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teamSubName {
  margin-top: 3px;
  font-size: 14px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.teamPayout {
  margin-top: 3px;
  font-size: 14px;
  font-weight: bold;
}

.ownersBucksIcon {
  height: 10px;
  margin-right: 1px;
}
.moneyIcon {
  height: 16px;
  margin-bottom: -3px;
}

.contestHeaderField {
  font-size: 14px;
  text-align: center;
  width: 84px;
}

.middleField {
  font-size: 18px;
  font-weight: normal;
  min-width: 40px;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}

.teamScoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.myField .teamScoreContainer {
  align-items: flex-end;
  padding-right: 13px;
}

.oppField .teamScoreContainer {
  align-items: flex-start;
  padding-left: 13px;
}

.teamScore {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--obcolor-font-secondary);
}

.subText {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: -5px;
  white-space: nowrap;
}

.ticketIcon {
  height: 10px;
}
</style>