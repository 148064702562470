String.prototype.longName = function() {
  const nameArray = this.split(', ');
  if (nameArray.length < 2) {
    return this;
  }
  return nameArray[1] + ' ' + nameArray[0];
};

String.prototype.shortName = function() {
  const nameArray = this.split(', ');
  if (nameArray.length < 2) {
    return this;
  }
  return nameArray[1][0] + '. ' + nameArray[0];
};

String.prototype.lastName = function() {
  const nameArray = this.split(', ');
  if (nameArray.length < 2) {
    return this;
  }
  return nameArray[0];
};

String.prototype.removeCentsIfZero = function() {
  const cents = this.substring(this.length-3);
  if (cents === '.00') {
    return this.substring(0, this.length-3);
  }
  return this;
};

Number.prototype.formatMoney = function() {
  return '$' + Intl.NumberFormat('en-US').format(this);
};

Number.prototype.formatDecimal = function() {
  return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this);
};

Number.prototype.formatMoneyDecimals = function() {
  return '$' + Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this);
};

Number.prototype.addSuffix = function() {
  const i = this;
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

Number.prototype.centsToDollars = function() {
  const newAmt = (this / 100).toFixed(2);
  return newAmt;
};

String.prototype.addSuffix = function() {
  const i = parseInt(this);
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

String.prototype.removePluralWords = function() {
  const endings = {
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    ss: 'ss',
    s: '',
  };
  const pluralText = this;
  let singularText = '';
  const labelParts = pluralText.split(' ');
  for (const partIndex in labelParts) {
    let part = labelParts[partIndex];
    const trailingSpace = partIndex == labelParts.length - 1 ? '' : ' ';
    part = part.replace(
        new RegExp(`(${Object.keys(endings).join('|')})$`),
        (r) => endings[r],
    );
    singularText = singularText + part + trailingSpace;
  }

  return singularText;
};

// Changes a string such as '100000' to '100,000'
String.prototype.commaSeparatedFormat = function() {
  return Intl.NumberFormat('en-US').format(this);
};

Number.prototype.dollarWithCentsFormat = function() {
  if (!this) {
    return '0';
  }

  return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this / 100).removeCentsIfZero();
};

// Changes a number such as 100000 to 100K
// No formatting for any numbers under 100K
Number.prototype.abbreviateLongNumber = function() {
  if (this < 100000) return this.toString();
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    roundingMode: 'floor',
    maximumFractionDigits: '1',
  }).format(this);
};