<template>
  <div>
    <div class="waiverHeader">
      <div>Waiver Claim Priority</div>
      <div v-if="waiverClaims" style="margin-left: 25px;">
        Used: <span style="font-weight: normal;">{{ waiverClaims.length }}/10</span>
      </div>
      <div v-if="priority" style="margin-left: 25px;">
        Your Priority: <span style="font-weight: normal;">{{ priority }}</span>
      </div>
      <div style="flex: 1;"></div>
      <div v-if="savingOrder">Saving Changes...</div>
    </div>

    <div v-if="waiverClaims == null">
      <ObLoading />
    </div>

    <div v-else-if="waiverClaims.length == 0" style="text-align: center; padding: 10px;">
      You have not submitted any waiver claims for this week.
    </div>

    <draggable :disabled="claimsProcessed || locked" v-else v-model="waiverClaims"
      @end="saveOrder"
    >
      <div v-for="(claim, index) in waiverClaims" :key="index" class="waiverClaim"
        :style="claimsProcessed || locked ? 'cursor: default;' : ''"
      >
        <div v-if="!claimsProcessed && !locked" style="min-width: 25px; text-align: center; font-size: 20px; margin-right: 5px;">
          <i class="fad fa-grip-vertical"></i>
        </div>

        <div style="min-width: 25px; text-align: center;">
          {{ index + 1 }}
        </div>

        <div style="min-width: 50px; margin-right: 15px; text-align: center; font-weight: bold; font-size: 16px;">
          {{ claim.dropPosition }}
        </div>

        <i class="fa fa-minus" style="color: var(--obcolor-red); font-size: 20px; margin-right: 15px;"></i>
        <PlayerHeadshot :size="'45'" :id="claim.playerToDrop.id" :sport="leagueData.sport"
          :team="claim.playerToDrop.team" :showLogo="true"
        />
        <div style="margin-left: 20px; min-width: 220px;">
          <div class="playerName">{{ claim.playerToDrop.name.longName() }}</div>
          <div class="playerInfo">#{{ claim.playerToDrop.bio.number }} {{ claim.playerToDrop.bio.position }}</div>
        </div>

        <i class="fa fa-plus" style="color: var(--obcolor-green); font-size: 20px; margin-right: 15px;"></i>
        <PlayerHeadshot :size="'45'" :id="claim.playerToAdd.id" :sport="leagueData.sport"
          :team="claim.playerToAdd.team" :showLogo="true"
        />
        <div style="margin-left: 20px; min-width: 220px;">
          <div class="playerName">{{ claim.playerToAdd.name.longName() }}</div>
          <div class="playerInfo">#{{ claim.playerToAdd.bio.number }} {{ claim.playerToAdd.bio.position }}</div>
        </div>

        <template v-if="claim.status === 'pending'">
          <div style="font-size: 14px; margin-left: 20px;">
            <div class="playerName">Submitted</div>
            <div class="playerInfo">
              {{ parseInt(claim.timestamp) | moment("MMM Do YYYY, h:mma") }}
            </div>
          </div>

          <template v-if="!locked">
            <div style="flex: 1;"></div>
            <div v-if="processing[claim.id]" class="ob-btn-grey removeBtn" disabled>Removing...</div>
            <div v-else class="ob-btn-red removeBtn" @click="removeClaim(claim.id)">Remove</div>
          </template>
          <template v-else>
            <div style="flex: 1;"></div>
            <div class="ob-btn-grey removeBtn" disabled>Locked</div>
          </template>
        </template>

        <!-- Results of waiver processing -->
        <template v-else>
          <div style="flex: 1;"></div>
          <div style="margin-left: 20px; margin-right: 10px;">
            <div class="playerName" style="font-size: 12px;">{{ claim.notes }}</div>
          </div>
          <i v-if="claim.status == 'success'" class="fas fa-check-circle" style="color: green;"></i>
          <i v-else class="fas fa-times-circle" style="color: red;"></i>
        </template>
      </div>
    </draggable>
  </div>
</template>

<script>
import ObWaiversApi from '@/api/ObWaiversApi';
import draggable from 'vuedraggable';
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import ObLoading from '@/components/ObLoading';
import Vue from 'vue';

export default {

  components: {
    draggable,
    PlayerHeadshot,
    ObLoading,
  },

  props: {
    leagueData: Object,
  },

  data: function() {
    return {
      priority: null,
      claimsProcessed: false,
      locked: false,
      waiverClaims: null,
      processing: {},
      savingOrder: false,
    };
  },

  created() {
    this.loadWaivers();
  },

  methods: {
    loadWaivers() {
      ObWaiversApi.getMyWaiverClaims(this.leagueData.id, this.leagueData.matchup.week)
          .then((data) => {
            this.locked = data.locked;
            this.waiverClaims = data.claims;
            this.claimsProcessed = data.processed;
            if (data.priority) {
              this.priority = this.addOrdinalSuffix(data.priority);
            }
          })
          .catch((_error) =>{
            // Do nothing
          });
    },

    removeClaim(claimId) {
      Vue.set(this.processing, claimId, true);
      ObWaiversApi.cancelClaim(this.leagueData.id, this.leagueData.matchup.week, claimId)
          .then((data) => {
          // TODO - handle response
            this.loadWaivers();
          })
          .catch((error) =>{
            Vue.set(this.processing, claimId, false);
          });
    },

    saveOrder(event) {
      this.savingOrder = true;
      const orderedIds = [];
      for (const claim of this.waiverClaims) {
        orderedIds.push(claim.id);
      }
      ObWaiversApi.updateOrder(this.leagueData.id, this.leagueData.matchup.week, orderedIds)
          .then((data) => {
          // TODO - handle response
            this.savingOrder = false;
          })
          .catch((_error) =>{
            this.savingOrder = false;
          });
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
  },
};
</script>

<style lang="scss" scoped>
.waiverHeader {
  padding: 12px 14px;
  background: var(--obcolor-background-4);
  border-radius: 8px 8px 0 0;
  margin: -8px;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
}

.playerName {
  color: var(--obcolor-font-primary);
  font-size: 14px;
  white-space: nowrap;
}
.playerInfo {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  margin-top: 3px;
  white-space: nowrap;
}

.waiverClaim {
  background: var(--obcolor-background-4);
  border-radius: 8px;
  padding: 8px 10px;
  padding-bottom: 10px;
  cursor: grab;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.removeBtn {
  padding: 6px 16px;
  font-size: 14px;
}
</style>
