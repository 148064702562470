<template>
  <Modal v-if="isOpen" class="gamesModal" v-model="isOpen"
    containerStyle="max-height: calc(100% - 60px); width: 640px; max-width: calc(100% - 60px); padding: 10px 10px 0 10px; overflow: hidden"
    bodyStyle="margin: 0 -10px; padding: 10px; background: var(--obcolor-background-page);"
  >
    <div slot="header">
      <h1>{{ gameSlatesInfo.name }}</h1>
      <p>
        {{ gameSlatesInfo.numberOfGames }}
        <span v-if="gameSlatesInfo.numberOfGames == 1">Game</span>
        <span v-else>Games</span>
      </p>
    </div>
    <div slot="body" class="bodyGames">
      <div v-if="!gameSlates" class="loading">
        <ObLoading />
      </div>
      <div v-else v-for="(game, index) in gameSlates.gameSlateData" :key="index"
        class="contestWrapper"
      >
        <p>{{ getContestStartDate(game.date_utc) }}</p>
        <div class="teamInfo visitorTeam">
          <img :src="getTeamLogo(game.sport, game.visitor_team.team_alias)" :alt="game.visitor_team.team_name">
          <div>
            <p class="city">{{ game.visitor_team.team_city }}</p>
            <p class="teamName">{{ game.visitor_team.team_name }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <img :src="getTeamLogo(game.sport, game.home_team.team_alias)" :alt="game.home_team.team_name">
          <div>
            <p class="city">{{ game.home_team.team_city }}</p>
            <p class="teamName">{{ game.home_team.team_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ObLoading,
  },

  created() {
    EventBus.$on('OPEN_GAMESLATE_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_GAMESLATE_MODAL', this.openModal);
  },

  data() {
    return {
      gameSlates: null,
      gameSlatesInfo: null,
      isOpen: false,
    };
  },

  methods: {
    openModal(slateData) {
      this.resetData();
      this.gameSlatesInfo = slateData;
      this.loadGames(slateData.id);
      this.isOpen = true;
    },

    loadGames(slateIdGame) {
      ObLeagueApi.getGameSlate(slateIdGame)
          .then((data) => {
            this.resetData();
            this.gameSlates = data;
          })
          .catch((error) => {
            throw (error);
          });
    },

    resetData() {
      this.gameSlates = null;
      this.gameSlatesData = null;
    },

    getContestStartDate(startDate) {
      return this.$moment(startDate).format('MMM D, h:mma');
    },

    getTeamLogo(sport, team) {
      const sportName = sport.toLowerCase();
      return require('@/assets/logos/'+ sportName +'_'+ team.toLowerCase() +'.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.gamesModal {
  h1 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    margin: 0 0 3px 0;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    margin: 0;
  }

  .modal-header p {
    padding-bottom: 10px;
  }

  .modal-body {
    display: flex;
  }
}

.contestWrapper {
  background-color: var(--obcolor-background-6);
  border-radius: 4px 4px 0 0;
  width: calc((100% / 3) - 10px);

  > p {
    font-weight: 400;
    font-size: 12px;
    color: var(--obcolor-text-secondary);
    line-height: 15px;
    text-align: left;
    background: var(--obcolor-background-4);
    border-radius: 4px 4px 0 0;
    padding: 5px;

  }
}

.modal-container {
  border: none;
}

.bodyGames {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 11px;

  .loading {
    margin: 0 auto;
  }
}

.teamInfo {
  display: flex;
  padding: 5px 0;

  img {
    width: 32px;
    height: 32px;
    align-self: center;
    padding-left: 5px;
    padding-right: 7px;
  }
}

.visitorTeam {
  padding-bottom: 0;
}

.teamName {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.city {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
</style>