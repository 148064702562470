import { render, staticRenderFns } from "./PlayerStartingStatus.vue?vue&type=template&id=f115494a&scoped=true"
import script from "./PlayerStartingStatus.vue?vue&type=script&lang=js"
export * from "./PlayerStartingStatus.vue?vue&type=script&lang=js"
import style0 from "./PlayerStartingStatus.vue?vue&type=style&index=0&id=f115494a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f115494a",
  null
  
)

export default component.exports