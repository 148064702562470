<template>
  <div class="ob-page">
    <div class="ob-box">
      <h1>Login History</h1>
      <button @click="lockoutActiveSessions()" class="ob-btn lockOut">Lockout All</button>
      <p>*Lock out all sessions except your current session</p>
      <table aria-label="Login History">
        <tr>
          <th style="width: 80px;">Type</th>
          <th style="width: 110px;">Login Date</th>
          <th style="width: 110px;">Last Accessed</th>
          <th style="width: 80px;"></th>
        </tr>
        <tr v-for="session in sessions" :key="session.id">
          <td class="deviceType">
            <img v-if="!session.isMobile" :src="webSVG" alt="Web Icon">
            <img v-if="session.isMobile" :src="mobileSVG" alt="Mobile Icon">
            <p>{{ session.isMobile ? 'Mobile' : 'Web' }}</p>
          </td>
          <td>{{ $moment(session.createdDate).format("MMMM Do YYYY, h:mma") }}</td>
          <td>{{ $moment(session.lastAccessed).format("MMMM Do YYYY, h:mma") }}</td>
          <td>
            <button v-if="!session.isCurrentSession" @click="lockoutSessionId(session.id)" class="ob-btn">Lock Out</button>
          </td>
        </tr>
      </table>
      <p>Displays maximum of 10 most recent active sessions.</p>
      <div v-if="loadingSession">
        <ObLoading />
      </div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading.vue';
import webSVG from '@/assets/icons/Web.svg';
import mobileSVG from '@/assets/icons/Mobile.svg';

export default {
  name: 'LoginHistory',

  components: {
    ObLoading,
  },

  data() {
    return {
      sessions: null,
      loadingSession: false,
      webSVG: webSVG,
      mobileSVG: mobileSVG,
    };
  },

  created() {
    this.loadSessions();
  },

  methods: {
    loadSessions() {
      this.resetData();
      this.loadingSession = true;
      ObAccountApi.getActiveUserSessions()
          .then((data) => {
            this.loadingSession = false;
            this.sessions = data.sessions;
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    lockoutSessionId(sessionId) {
      this.resetData();
      this.loadingSession = true;
      ObAccountApi.lockoutSessionId(sessionId)
          .then((_response) => {
            this.loadingSession = false;
            this.loadSessions();
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    lockoutActiveSessions() {
      this.resetData();
      this.loadingSession = true;
      ObAccountApi.lockoutActiveSessions()
          .then((_response) => {
            this.loadingSession = false;
            this.loadSessions();
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    resetData() {
      this.sessions = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-box {
  font-size: 14px;
  padding: 20px;
}

h1 {
  margin-top: 6px;
  align-self: center;
}

button {
  cursor: pointer;
  display: flex;
}

.deviceType {
  display: flex;

  p {
    padding-left: 10px;
  }
}

table {
  text-align: left;
  width: 100%;
  font-size: 14px;
}

td, th {
  padding: 12px;
}

th {
  background: var(--obcolor-background-2);
}

td, .rowHeader {
  white-space: nowrap;
}

tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}
</style>
