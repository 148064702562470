<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 600px; min-width: 565px; height: 495px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-6); overflow: unset !important;"
  >
    <div slot="header" class="modalHeader">
      <div>Refer a Friend</div>
    </div>

    <div slot="body" class="modalBody">
      <div v-if="referralInfo != null" class="referralContainer">
        <div class="bgLogoContainer">
          <img class="bgLogoLeft" :src="require('@/assets/referrals/bg-logo-' + theme + '.png')" alt="OwnesrBox Logo">
          <div class="bgLogoMiddle"></div>
          <img class="bgLogoRight" :src="require('@/assets/referrals/bg-logo-' + theme + '.png')" alt="OwnesrBox Logo">
        </div>
        <img :src="require('@/assets/referrals/ob-rewards-' + theme + '.png')" style="height: 160px;" alt="OwnesrBox Rewards">
        <div class="headerText">
          <div>Refer your friends and get 25 OwnersBucks</div>
          <div>
            Get OwnersBucks when your friends refer their friends!
          </div>
        </div>
        <div style="margin-bottom: 15px;">
          <div class="headerOption">
            <div>Referral Link</div>
            <input readonly type="text" :value="referralInfo.referralUrl"
              placeholder="Referral Link"
            >
            <button class="ob-btn" @click="copyToClipboard(referralInfo.referralUrl, 'link')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyLinkMessage }"></div>
          </div>
          <div class="headerOption">
            <div>Referral Code</div>
            <input readonly type="text" :value="referralInfo.referralCode"
              placeholder="Referral Code"
            >
            <button class="ob-btn" @click="copyToClipboard(referralInfo.referralCode, 'code')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyCodeMessage }"></div>
          </div>
          <div class="headerOption">
            <div>Enter any amount of email referrals separated with commas</div>
            <input v-model="referralEmails" @input="resetReferralMessage()" type="text"
              placeholder="Email"
            >
            <button v-if="sendingReferralEmails == true" disabled class="ob-btn-grey">
              <i class="fad fa-spinner-third fa-spin"></i>
              Sending
            </button>
            <button v-else-if="referralEmails != ''" class="ob-btn" @click="referFriends()"><i class="fas fa-paper-plane" style="margin-right: 7px;"></i>Send</button>
            <button v-else disabled class="ob-btn-grey"><i class="fas fa-paper-plane" style="margin-right: 7px;"></i>Send</button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: referralMessage || '', class: 'ObTooltip ob-tooltip-fadeout', visible: referralMessage != null }"></div>
          </div>
        </div>
      </div>
      <div v-else class="loadingBox">
        <i class="fad fa-spinner-third fa-spin"></i>
        <div class="loadingReferrals">Loading Referrals...</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import ObAccountApi from '@/api/ObAccountApi';

export default {
  components: {
    Modal,
  },

  computed: {
    ...mapState(['theme']),
  },

  created() {
    EventBus.$on('OPEN_REFER_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_REFER_MODAL', this.openModal);
  },

  data() {
    return {
      isOpen: false,
      referralInfo: null,
      referralEmails: '',
      referralMessage: null,
      showCopyLinkMessage: false,
      showCopyCodeMessage: false,
      sendingReferralEmails: false,
      copyLinkTimeout: null,
      copyCodeTimeout: null,
      referralMessageTimeout: null,
    };
  },

  watch: {
    referralMessage(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.referralMessageTimeout);
        this.referralMessageTimeout = setTimeout(function() {
          self.resetReferralMessage();
        }, 3000);
      }
    },

    showCopyCodeMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyCodeTimeout);
        this.copyCodeTimeout = setTimeout(function() {
          self.resetCopyCodeMessage();
        }, 3000);
      }
    },

    showCopyLinkMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyLinkTimeout);
        this.copyLinkTimeout = setTimeout(function() {
          self.resetCopyLinkMessage();
        }, 3000);
      }
    },

    referralEmails(newVal) {
      const emailList = newVal.split(',');
      if (emailList.length == 20 && newVal.charAt(newVal.length - 1) == ',') {
        this.referralEmails = newVal.substr(0, newVal.length - 1);
        this.referralMessage = 'You can send a maximum of 20 emails.';
      }
    },
  },

  methods: {
    openModal() {
      this.resetData();
      this.isOpen = true;
      this.loadMyReferrals();
    },

    closeModal() {
      this.isOpen = false;
    },

    resetData() {
      this.referralInfo = null;
      this.referralEmails = '';
      this.referralMessage = null;
      this.showCopyLinkMessage = false;
      this.showCopyCodeMessage = false;
      this.sendingReferralEmails = false;
      this.copyLinkTimeout = null;
      this.copyCodeTimeout = null;
      this.referralMessageTimeout = null;
    },

    loadMyReferrals() {
      ObAccountApi.getReferralProfile()
          .then((data) => {
            this.referralInfo = data;
          });
    },

    referFriends() {
      this.sendingReferralEmails = true;
      ObAccountApi.referFriends(this.referralEmails)
          .then((response) => {
            this.sendingReferralEmails = false;
            this.referralEmails = '';
            this.referralMessage = response;
          }).catch((e) => {
            this.sendingReferralEmails = false;
            this.referralEmails = '';
            if (e.response) {
              this.referralMessage = e.response.data;
            }
          });
    },

    copyToClipboard(copyText, copyType) {
      navigator.clipboard.writeText(copyText).then(() => {
        /* clipboard successfully set */
        if (copyType == 'link') {
          this.showCopyLinkMessage = true;
        } else if (copyType == 'code') {
          this.showCopyCodeMessage = true;
        }
      }).catch((e) => {
        /* clipboard write failed */
      });
    },

    resetReferralMessage() {
      this.referralMessage = null;
    },

    resetCopyLinkMessage() {
      this.showCopyLinkMessage = false;
    },

    resetCopyCodeMessage() {
      this.showCopyCodeMessage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  font-size: 16px;
  height: 35px;
  padding: 5px 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.bgLogoContainer {
  position: absolute;
  display: flex;
  left: -120px;
  margin-top: 10px;
  height: 230px;
  width: calc(100% + 240px);
  justify-content: space-between;
  pointer-events: none;
}

.bgLogoMiddle {
  flex: 1;
  min-width: 100px;
}

.bgLogoLeft, .bgLogoRight {
  height: 100%;
}

.referralContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerText {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 450px;
  margin-top: 10px;
  margin-right: 10px;
  padding-bottom: 15px;

  > div {
    margin-top: 5px;
  }
}

.headerOption {
  margin-bottom: 15px;
  div {
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    margin-bottom: 2px;
  }

  input {
    padding-left: 10px;
    height: 25px;
    width: 380px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  button {
    width: 110px;
  }
}

.referralTooltip {
  margin-top: -15px;
}

.loadingBox {
  text-align: center;
  padding: 20px;
}

.loadingReferrals {
  margin-top: 10px; font-size: 14px;
}
</style>