<template>
  <div>
    <div class="filterList">
      <div class="posFilters" v-if="roster && picks">
        <div class="filterBtn" v-for="(posInfo, index) in roster" :key="index"
          @click="selectFilter(posInfo.short)"
          :class="[{'filterSelected': posFilters[posInfo.short] === true}]"
        >
          <div class="filterTop">{{ posInfo.short }}</div>
          <div class="filterBottom">{{ getPosFilterNum(posInfo.short) }}</div>
        </div>
      </div>

      <div v-if="contest.format == 'WEEKLY'" class="salaryFilter" style="min-width: 230px; margin-left: 10px; flex: 1;">
        <div class="salaryHeading" v-if="gameRangeFilter[0] != gameRangeFilter[1]">Games Remaining: {{ gameRangeFilter[0] }} - {{ gameRangeFilter[1] }}</div>
        <div class="salaryHeading" v-else>Games Remaining: {{ gameRangeFilter[0] }}</div>
        <div class="rangeSliderContainer">
          <div class="rangeSliderLabel">0</div>
          <RangeSlider min="0" :max="maxGR" v-model="gameRangeFilter"
            :step="gameRangeStep"
          />
          <div class="rangeSliderLabel">{{ maxGR }}</div>
        </div>
        <div class="salaryLabel"></div>
      </div>

      <div class="salaryFilter" style="width: 230px; flex: 1;">
        <div class="salaryHeading" v-if="costRangeFilter[0] != costRangeFilter[1]">Salary: {{ dollarFormat(costRangeFilter[0]) }} - {{ dollarFormat(costRangeFilter[1]) }}</div>
        <div class="salaryHeading" v-else>Salary: {{ dollarFormat(costRangeFilter[0]) }}</div>
        <div class="rangeSliderContainer">
          <div class="rangeSliderLabel">$0</div>
          <RangeSlider min="0" :max="maxCost" v-model="costRangeFilter"
            :step="salaryRangeStep"
          />
          <div class="rangeSliderLabel">{{ dollarFormat(maxCost) }}</div>
        </div>
        <div class="salaryLabel"></div>
      </div>

      <div v-if="contest.format == 'WEEKLY'" class="selectViewContainer">
        <div :style="selectedView === 'stats' ? 'color: var(--obcolor-ob-blue);' : ''" @click="setSelectedView('stats')">Stats</div>
        <div :style="selectedView === 'schedule' ? 'color: var(--obcolor-ob-blue);' : ''" @click="setSelectedView('schedule')" style="padding-left: 8px;">Schedule</div>
      </div>
      <div v-else style="flex: 1;"></div>

      <div class="searchInput">
        <div class="inputIcon"><i class="fa fa-search"></i></div>
        <input v-model="nameFilter" type="text" placeholder="Player Search">
        <div v-show="nameFilter != ''" class="inputIcon cancelSearch" @click="nameFilter = ''">
          <i class="fa fa-times"></i>
        </div>
      </div>

      <div>
        <Dropdown v-model="selectedTeam" :options="teamDropdownValues" :text="teamDropdownLabels"
          style="width: 120px; margin-right: 0px; margin-left: 5px;"
        />
      </div>
    </div>

    <div ref="playerListContainer" class="tableContainer ob-scroll" @scroll="onScroll">
      <table aria-label="Player List">
        <tr :class="selectedView === 'stats' ? 'columnWidthStats' : 'columnWidthSched'">
          <th></th>
          <th style="text-align: left; padding-left: 23px;">Player</th>

          <template v-if="selectedView === 'stats'">
            <template v-if="contest.format == 'WEEKLY'">
              <th class="statHeader" @click="changeSort(null, 'gp')" :class="{ selectedCol: isSelected(null, 'gp') }">
                <GeneralTooltip textKey="SalaryCap_GP" pos="bottom"><div class="headerTrigger" slot="trigger">GP</div></GeneralTooltip>
              </th>
              <th class="statHeader" @click="changeSort(null, 'fpts')" :class="{ selectedCol: isSelected(null, 'fpts') }">
                <GeneralTooltip textKey="SalaryCap_FPTS" pos="bottom"><div class="headerTrigger" slot="trigger">FPTS</div></GeneralTooltip>
              </th>
              <th class="statHeader" @click="changeSort(null, 'gr')" :class="{ selectedCol: isSelected(null, 'gr') }">
                <GeneralTooltip textKey="SalaryCap_GR" pos="bottom"><div class="headerTrigger" slot="trigger">GR</div></GeneralTooltip>
              </th>
            </template>
            <template v-else>
              <th style="width: 77px;">
                <GeneralTooltip textKey="SalaryCap_VS" pos="bottom"><div class="headerTrigger" slot="trigger">VS</div></GeneralTooltip>
              </th>
              <th class="statHeader" style="width: 96px;" @click="changeSort(null, 'gameTime')"
                :class="{ selectedCol: isSelected(null, 'gameTime') }"
              >
                <GeneralTooltip textKey="SalaryCap_Time" pos="bottom"><div class="headerTrigger" slot="trigger">Time</div></GeneralTooltip>
              </th>
              <th v-if="contest.sport == 'NFL'" class="statHeader" @click="changeSort(null, 'oprk')"
                :class="{ selectedCol: isSelected(null, 'oprk') }"
              >
                <GeneralTooltip textKey="SalaryCap_OPRK" pos="bottom"><div class="headerTrigger" slot="trigger">OPRK</div></GeneralTooltip>
              </th>
              <th class="statHeader" @click="changeSort(null, 'projR')" :class="{ selectedCol: isSelected(null, 'projR') }">
                <GeneralTooltip textKey="SalaryCap_Proj" pos="bottom"><div class="headerTrigger" slot="trigger">PROJ</div></GeneralTooltip>
              </th>
            </template>
            <th class="statHeader" @click="changeSort(null, 'sAvg')" :class="{ selectedCol: isSelected(null, 'sAvg') }">
              <GeneralTooltip textKey="SalaryCap_sAvg" pos="bottom"><div class="headerTrigger" slot="trigger">sAvg</div></GeneralTooltip>
            </th>
            <th v-if="contest.format == 'WEEKLY'" class="statHeader" @click="changeSort(null, 'projR')"
              :class="{ selectedCol: isSelected(null, 'projR') }"
            >
              <GeneralTooltip textKey="SalaryCap_Proj" pos="bottom"><div class="headerTrigger" slot="trigger">PROJ</div></GeneralTooltip>
            </th>
          </template>

          <template v-else>
            <th class="statHeader" v-for="d in contest.days" :key="d"
              style="width: 69px; padding: 0;" @click="changeSort('date', d)"
              :style="isToday(d) ? 'color: var(--obcolor-ob-blue);' : ''"
              :class="{ selectedCol: isSelected('date', d) }"
            >
              <div>{{ [d, 'YYYY-MM-DD'] | moment("ddd") }}</div>
              <div>{{ [d, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
            <th></th>
          </template>
          <template v-if="contest.format == 'WEEKLY'">
            <th class="statHeader endSection" @click="changeSort(null, 'salaryPG')" :class="{ selectedCol: isSelected(null, 'salaryPG') }">Per Game</th>
            <th style="padding: 0;"></th>
          </template>
          <th class="statHeader" @click="changeSort(null, 'salaryR')" :class="{ selectedCol: isSelected(null, 'salaryR') }">Salary</th>
          <th style="width: 24px;"></th>
        </tr>
        <LineupEditorPlayerRow class="playerRow" v-for="(player, index) in sortedPlayers" :key="index"
          :player="player"
          :dropped="droppedPlayerIds[player.id]"
          :statusMap="statusMap"
          :status="statusMap && statusMap[player.id] ? statusMap[player.id].status : null"
          :totSpent="totSpent" :contest="contest"
          :selectedView="selectedView" :selectedFor="null"
          :disabled="false"
          :isBeingAdded="pendingAdds[player.id] != null"
        />
      </table>
    </div>
  </div>
</template>

<script>
import ObSportDataApi from '@/api/ObSportDataApi';
import EventBus from '@/event-bus';
import RangeSlider from '@/components/RangeSlider';
import Dropdown from '@/components/Dropdown';
import LineupEditorPlayerRow from '@/views/SalaryContest/LineupEditor/LineupEditorPlayerRow';
import GeneralTooltip from '@/components/tooltip/GeneralTooltip';

export default {
  components: {
    RangeSlider,
    Dropdown,
    LineupEditorPlayerRow,
    GeneralTooltip,
  },

  props: {
    playerList: Array,
    toAdd: Object,
    toDrop: Object,
    picks: Object,
    pickedPlayerIds: Object,
    droppedPlayerIds: Object,
    contest: Object,
    statusMap: Object,
    totSpent: Number,
    salaryRem: Number,
    pendingSwaps: Array,
    pendingAdds: Object,
    filterPos: String,
  },

  data() {
    return {
      sortedBy: {loc: null, stat: 'salaryR'},
      nameFilter: '',
      playerListBaseLimit: 50,
      playerListLimit: 50,
      selectedView: 'stats',

      maxCost: 0,
      maxGR: 0,
      posFilters: {},
      costRangeFilter: [0, 0],
      gameRangeFilter: [0, 4],
      gameRangeStep: 1,
      salaryRangeStep: 500,

      teams: [],
      selectedTeam: '',
    };
  },

  created() {
    this.loadTeams();
    this.initPosFilters();
    this.calcMaxValues();

    if (this.playerList && this.maxCost != null) {
      this.costRangeFilter = [0, this.maxCost];
    }
    if (this.playerList && this.maxGR != null) {
      this.gameRangeFilter = [0, this.maxGR];
    }
  },

  watch: {
    picks(newVal) {
      if (newVal != null) {
        this.initPosFilters();
      }
    },

    // Reset range sliders when player data changes
    // The max value may have changed
    playerList(newVal) {
      this.calcMaxValues();
    },

    // Scroll table to the top, and reset list limit when changing filters
    nameFilter(to, from) {
      this.resetTableScroll();
    },

    selectedTeam(to, from) {
      this.resetTableScroll();
    },

    costRangeFilter(to, from) {
      this.resetTableScroll();
    },

    gameRangeFilter(to, from) {
      this.resetTableScroll();
    },

    filterPos(newVal) {
      this.selectFilterOnly(newVal);
    },
  },

  computed: {
    isWeeklyContest() {
      return this.contest && this.contest.format === 'WEEKLY';
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    teamDropdownValues() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.id;
      });
      values.unshift(''); // All Teams
      return values;
    },

    teamDropdownLabels() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.team_city + ' ' + t.team_name;
      });
      values.unshift('All Teams');
      return values;
    },

    sortedPlayers() {
      const self = this;
      let noPosFilters = true;

      for (const posIndex in this.posFilters) {
        if (this.posFilters[posIndex] === true) {
          noPosFilters = false;
        }
      }

      // TODO: FILTER CURRENT PLAYER'S POSITION
      if (!this.playerList) {
        return [];
      }
      // Using es6 way to clone array ([...array])
      return [...this.playerList].filter((player) => {
        let nameMatch = false;
        let posMatch = false;
        let costMatch = false;
        let pgMatch = false;
        let teamMatch = false;

        // Remove Players already on your team
        if (this.pickedPlayerIds[player.id]) {
          return false;
        }

        if (!player.name) {
          return false;
        }
        if (self.nameFilter !== '') {
          const pname = player.name.toLowerCase();
          const nfilter = self.nameFilter.toLowerCase();
          const nFilters = nfilter.split(' ');

          nameMatch = true;
          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) == 0 || pname.indexOf(', '+n) != -1) {
              // All filters must match
            } else {
              nameMatch = false;
              break;
            }
          }

          return nameMatch;
        }

        if (self.isFilterActive(player.draftPos) || noPosFilters) {
          posMatch = true;
        }

        // Team Filter
        if (self.selectedTeam == '' || self.selectedTeam == player.teamId) {
          teamMatch = true;
        }

        if (self.costRangeFilter[0] <= self.getSalaryRemaining(player) && self.costRangeFilter[1] >= self.getSalaryRemaining(player)) {
          costMatch = true;
        }

        const pg = self.getGamesRemaining(player);
        if (self.gameRangeFilter[0] <= pg && self.gameRangeFilter[1] >= pg) {
          pgMatch = true;
        }

        return posMatch && costMatch && pgMatch && teamMatch;
      }).sort((p1, p2) => {
        if (this.sortedBy.stat == 'gameTime' || this.sortedBy.stat == 'oprk') {
          // If a player has no games, check the other player and don't change list order otherwise
          if (!p1.games) {
            return p2.games != null ? 1 : 0;
          } else if (!p2.games) {
            return p1.games != null ? -1 : 0;
          }

          let compareStat = '';
          if (this.sortedBy.stat == 'gameTime') {
            compareStat = 'ts';
          } else if (this.sortedBy.stat == 'oprk') {
            compareStat = 'oprk';
          }

          // Find each player's next game if it exists. This assumes that the lowest game index is the earliest one (eg. index 0 is earlier than index 1)
          const p1UpcomingGame = p1.games.find((g) => g[compareStat] != null);
          const p2UpcomingGame = p2.games.find((g) => g[compareStat] != null);

          // Return appropriate sorting if we don't have timestamps to compare (prioritize the player with a non-null game/timestamp)
          if (p1UpcomingGame == null || p1UpcomingGame[compareStat] == null) {
            return p2UpcomingGame != null && p2UpcomingGame[compareStat] != null ? 1 : 0;
          } else if (p2UpcomingGame == null || p2UpcomingGame[compareStat] == null) {
            return p1UpcomingGame != null && p1UpcomingGame[compareStat] != null ? -1 : 0;
          }

          return p2UpcomingGame[compareStat] - p1UpcomingGame[compareStat];
        }

        if (this.sortedBy.stat == 'gp') {
          return this.getGamesPlayed(p2) - this.getGamesPlayed(p1);
        }

        if (this.sortedBy.stat == 'gr') {
          return this.getGamesRemaining(p2) - this.getGamesRemaining(p1);
        }

        if (this.sortedBy.stat == 'projR') {
          return this.getProjRemaining(p2) - this.getProjRemaining(p1);
        }

        if (this.sortedBy.stat == 'salaryR') {
          return this.getSalaryRemaining(p2) - this.getSalaryRemaining(p1);
        }

        if (this.sortedBy.stat == 'fpts') {
          return this.sumFpts(p2) - this.sumFpts(p1);
        }

        if (this.sortedBy.loc == 'date') {
          const date = this.sortedBy.stat;
          if (date) {
            const p1Entry = p1.games.find((g) => g.date == date);
            const p2Entry = p2.games.find((g) => g.date == date);

            const projVal = p1Entry.proj ? p1Entry.proj : 0;
            const fptsVal = p1Entry.fpts !== undefined ? p1Entry.fpts * 100 : null;
            let p1Value = 0;
            if (!p1Entry && !p1Entry.game) {
              p1Value = -999;
            } else if (p1Entry.fpts !== null) {
              p1Value = fptsVal;
            } else {
              p1Value = projVal;
            }

            const projVal2 = p2Entry.proj ? p2Entry.proj : 0;
            const fptsVal2 = p2Entry.fpts !== undefined ? p2Entry.fpts * 100 : null;
            let p2Value = 0;
            if (!p2Entry && !p2Entry.game) {
              p2Value = -999;
            } else if (p2Entry.fpts !== null) {
              p2Value = fptsVal2;
            } else {
              p2Value = projVal2;
            }

            if (p1Value > p2Value) {
              return -1;
            }
            if (p1Value < p2Value) {
              return 1;
            }
          }
        }

        if (!this.sortedBy.loc) {
          return p2[this.sortedBy.stat] - p1[this.sortedBy.stat];
        }

        if (this.sortedBy.loc == 'wStats') {
          return this.getStat(p2, this.sortedBy.stat) - this.getStat(p1, this.sortedBy.stat);
        }

        return 0;
      }).slice(0, this.playerListLimit);
    },
  },

  methods: {

    // Show the number of players at this position pending swap
    getPosFilterNum(pos) {
      let count = 0;
      for (const entry of this.pendingSwaps) {
        if (entry.pos === pos) {
          count++;
        }
      }
      return count;
    },

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      // Show more entries once we scroll to the bottom of the table
      // Extra pixel buffer to reduce stuttering
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        this.playerListLimit += this.playerListBaseLimit;
      }
    },

    // When changing filters or sort, scroll back to top and reset the limit
    resetTableScroll() {
      this.$nextTick(() => {
        this.$refs.playerListContainer.scrollTop = 0;
      });
      this.playerListLimit = this.playerListBaseLimit;
    },

    loadTeams() {
      this.teams = [];
      ObSportDataApi.getTeams(this.contest.sport)
          .then((response) => {
            this.teams = response.sort((a, b) => {
              return a.team_city.localeCompare(b.team_city);
            });
          });
    },

    initPosFilters() {
      if (!this.picks || !Object.keys(this.picks)) {
        return;
      }

      const tempFilters = {};
      this.roster.forEach((posInfo) => {
        if (!tempFilters[posInfo.short]) {
          tempFilters[posInfo.short] = false;
        }
      });
      this.posFilters = tempFilters;
    },

    selectFilter(posName) {
      this.resetTableScroll();
      if (!this.posFilters[posName]) {
        this.$set(this.posFilters, posName, true);
      } else {
        this.$set(this.posFilters, posName, false);
      }
    },

    selectFilterOnly(posName) {
      this.resetTableScroll();
      for (const posInfo of this.roster) {
        if (this.posFilters[posInfo.short]) {
          this.$set(this.posFilters, posInfo.short, false);
        }
      }
      if (posName != null) {
        this.selectFilter(posName);
      }
    },

    canPickPosition(draftPos, fullPositions = this.fullPositions) {
      if (this.contest.isFlexContest && this.canPickFlexPosition(draftPos, fullPositions)) {
        return true;
      }

      return fullPositions[draftPos] === false;
    },

    canPickFlexPosition(draftPos, fullPositions = this.fullPositions) {
      const canPickGuardPosition = this.$SportInfo.draftPosIsGuard(draftPos, this.contest.sport) && fullPositions[this.$SportInfo.guard()] === false;
      const canPickForwardPosition = this.$SportInfo.draftPosIsForward(draftPos, this.contest.sport) && fullPositions[this.$SportInfo.forward()] === false;
      const canPickUtilityPosition = this.$SportInfo.draftPosIsUtility(draftPos, this.contest.sport) && (fullPositions[this.$SportInfo.utility()] === false || fullPositions[this.$SportInfo.utilityMultiplier()] === false);
      const canPickFlexPosition = this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport) && fullPositions[this.$SportInfo.flex()] === false;
      const canPickSuperFlexPosition = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport) && (fullPositions[this.$SportInfo.superFlex()] === false || this.$SportInfo.superFlexMultiplier() === false);

      return canPickGuardPosition || canPickForwardPosition || canPickUtilityPosition || canPickFlexPosition || canPickSuperFlexPosition;
    },

    isFilterActive(draftPos) {
      if (this.contest.isFlexContest && this.isFlexFilterActive(draftPos)) {
        return true;
      }

      return this.posFilters[draftPos];
    },

    isFlexFilterActive(draftPos) {
      const isGuard = this.$SportInfo.draftPosIsGuard(draftPos, this.contest.sport);
      const isForward = this.$SportInfo.draftPosIsForward(draftPos, this.contest.sport);
      const isUtility = this.$SportInfo.draftPosIsUtility(draftPos, this.contest.sport);

      const isFlex = this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport);
      const isSuperFlex = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport);

      const isGuardActive = isGuard && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.guard()]);
      const isForwardActive = isForward && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.forward()]);
      const isUtilityActive = isUtility && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.utility()] || this.posFilters[this.$SportInfo.utilityMultiplier()]);
      const isFlexActive = isFlex && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.flex()]);
      const isSuperFlexActive = isSuperFlex && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.superFlex()] || this.posFilters[this.$SportInfo.superFlexMultiplier()])

      return isGuardActive || isForwardActive || isUtilityActive || isFlexActive || isSuperFlexActive;
    },

    calcMaxValues() {
      let highestCost = 0;
      let highestGR = 0;

      for (const player of this.playerList) {
        if (this.getSalaryRemaining(player) && this.getSalaryRemaining(player) > highestCost) {
          highestCost = this.getSalaryRemaining(player);
        }
        const gr = this.getGamesRemaining(player);
        if (gr > highestGR) {
          highestGR = gr;
        }
      }

      // Round up to nearest 1000;
      this.maxCost = highestCost != 0 ? Math.ceil(highestCost/1000) * 1000 : 1000;
      this.maxGR = highestGR != 0 ? highestGR : 1;

      // If max Cost or GR changes, and selected value is higher, adjust down
      if (this.costRangeFilter[1] > this.maxCost) {
        this.$set(this.costRangeFilter, 1, this.maxCost);
      }
      if (this.gameRangeFilter[1] > this.maxGR) {
        this.$set(this.gameRangeFilter, 1, this.maxGR);
      }
    },

    // Could we move some of this to the backend, or pre-calculate to speed up rendering?
    sumFpts(player) {
      let fpts = 0;
      for (const game of player.games) {
        fpts += game.fpts ? game.fpts : 0;
        fpts += game.fptsDH ? game.fptsDH : 0;
      }
      return fpts;
    },

    getGamesPlayed(player) {
      let gp = 0;
      for (const game of player.games) {
        gp += game.played ? 1 : 0;
        gp += game.playedDH ? 1 : 0;
      }
      return gp;
    },

    getGamesRemaining(player) {
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && player.canSwap;
      const now = new Date().getTime();
      let gr = 0;
      for (const game of player.games) {
        if ((isValidDailySwap || game.ts > now) && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && (isValidDailySwap || game.tsDH > now) && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    getProjRemaining(player) {
      if (!player.games) {
        return 0;
      }
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && player.canSwap;
      const now = new Date().getTime();
      let proj = 0;
      for (const game of player.games) {
        if (game.ts && (isValidDailySwap || game.ts > now)) {
          proj += game.proj ? Math.round(game.proj) : 0;
          proj += game.projDH ? Math.round(game.projDH) : 0;
        }
      }
      return proj;
    },

    getSalaryRemaining(player, minGames = 0) {
      return (this.getGamesRemaining(player) || minGames) * player.salaryPG;
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    changeSort(loc, stat) {
      this.resetTableScroll();
      this.sortedBy.loc = loc;
      this.sortedBy.stat = stat;
    },

    setSelectedView(selected) {
      this.selectedView = selected;
    },

    isSelected(loc, stat) {
      return this.sortedBy.loc == loc && this.sortedBy.stat == stat;
    },

    openPlayerCard(player) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', player.id, this.contest.sport, this.contest.id, true);
    },

    isToday(date) {
      if (date == null) {
        return false;
      }
      const dateString = this.$moment(date).format('YYYY-MM-DD');
      return dateString == this.$moment().format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>

  .endSection {
    border-left: 2px solid var(--obcolor-background-2)
  }

  .salaryFilter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .salaryHeading {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .salaryLabel {
    font-size: 12px;
    margin-top: 3px;
  }

  .rangeSliderContainer {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .rangeSliderLabel {
    font-size: 12px;
    width: 50px;
  }

  .rangeSliderLabel:first-child {
    margin-right: 10px;
    text-align: right;
  }

  .rangeSliderLabel:last-child {
    margin-left: 10px;
  }

  .searchInput {
    display: flex;
    width: 125px;
    height: 27px;
    background: var(--obcolor-background-6);
    align-items: center;
    padding: 0 6px;
    border: 1px solid var(--obcolor-background-1);
    margin-left: 25px;

    input {
      box-sizing: border-box;
      flex: 1;
      min-width: 0;
      height: 100%;
      padding: 0 5px;
      margin-left: 1px;
      background: var(--obcolor-background-6);
    }

    .inputIcon {
      margin-top: 2px;
      font-size: 13px;
      color: var(--obcolor-font-secondary);
    }

    .cancelSearch {
      color: red;
      font-size: 12px;
      cursor: pointer;
      padding: 5px;
      margin-right: -4px;
    }

    .cancelSearch:hover {
      color: #a80f0f;
    }
  }

  .filterList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2px;
  }

  .posFilters {
    display: flex;
    align-items: center;
  }

  .filterBtn {
    color: white;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-left: 5px;
  }

  .filterBtn.filterSelected {
    .filterTop {
      background: var(--obcolor-ob-blue);
      border-radius: 6px 6px 0 0;
      padding: 4px 8px 2px 8px;
    }

    .filterBottom {
      background: var(--obcolor-ob-blue-tinted);
      border-radius: 0 0 6px 6px;
      padding: 2px 8px 4px 8px;
    }

    .filterTop:hover {
      background: var(--obcolor-accent-hl);
    }

    .filterBottom:hover {
      background: var(--obcolor-accent-light-hl);
    }
  }
  .filterBtn:not(.filterSelected) {
    .filterTop {
      background: var(--btn-grey);
      border-radius: 6px 6px 0 0;
      padding: 4px 8px 2px 8px;
    }

    .filterBottom {
      background: var(--btn-grey-hl);
      border-radius: 0 0 6px 6px;
      padding: 2px 8px 4px 8px;
    }
  }

  .filterBtn:hover {
    .filterTop {
      background: var(--obcolor-accent-hl);
    }
    .filterBottom {
      background: var(--obcolor-accent-light-hl);
    }
  }

  .filterBtn:not(.filterSelected):hover {
    .filterTop {
      background: var(--btn-grey-light);
    }
    .filterBottom {
      background: var(--btn-grey-light-hl);
    }
  }

  .tableContainer {
    flex: 1;
    min-height: 0;
    overflow: auto;
    overflow-y: scroll;
    margin-top: 5px;
    transition: height ease-out 0.3s;
  }

  table {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  th {
    color: #878998;
    background: var(--obcolor-background-3);
    padding: 12px 6px;
    font-size: 12px;
    position: sticky;
    top: 0;
    white-space: nowrap;
    z-index: 60;
  }

  tr:nth-child(odd) {
    background: var(--obcolor-background-5);
  }

  tr:hover {
    background: var(--obcolor-bg-hover);
  }

  .statHeader {
    width: 53px;
    height: 37px;
    padding: 0;
    box-sizing: border-box;
  }

  .statHeader:not(.noHover) {
    cursor: pointer;
  }

  .statHeader:not(.noHover):hover {
    background: var(--obcolor-background-2);
  }

  .statHeader > div {
    width: 100%;
  }

  .statHeader .headerTrigger {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .selectedCol {
    font-weight: bold;
    background: var(--obcolor-background-2);
  }

  .playerRow:hover {
    background: var(--obcolor-background-4);
  }
  .playerStatus {
    margin-top: -2px;
    margin-right: 8px;
  }

  .pickedPlayer {
    color: var(--obcolor-text-faded) !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 20px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .teamSelector {
    width: 120px;
    height: 31px;
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
  }

  .selectViewContainer {
    display: flex;
    margin-left: 30px;
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    text-transform: uppercase;
    cursor: pointer;
    div:first-child {
      padding-right: 8px;
      border-right: 1px solid var(--obcolor-font-secondary);
    }
    div:hover {
      color: var(--obcolor-ob-blue);
    }
  }

  .tab {
    width: 110px;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
  .tab:hover {
    color: var(--obcolor-ob-blue);
  }

  // Column widths to keep size consistent, even when 0 entries appear in the table
  .columnWidthStats {
    th:nth-child(1) {
      width: 46px;
    }
    th:nth-child(n+3) {
      width: 89px;
    }
    th:nth-child(8) {
      width: 90px;
    }
    th:nth-child(9) {
      width: 26px;
    }
    th:nth-child(10) {
      width: 90px;
    }
    th:nth-child(11) {
      width: 24px;
    }
  }

  .columnWidthSched {
    th:nth-child(1) {
      width: 46px;
    }
    th:nth-child(n+3) {
      width: 1px;
    }
    th:nth-child(11) {
      width: 90px;
    }
    th:nth-child(12) {
      width: 26px;
    }
    th:nth-child(13) {
      width: 90px;
    }
    th:nth-child(14) {
      width: 24px;
    }
  }
</style>