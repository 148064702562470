<template>
  <div class="ob-page" style="display: flex;">
    <!-- If a call has been made to get any of the leagues, empty ones will be [], not null -->
    <div style="flex: 1 1 0%; min-width: 1000px; max-width: 1070px;">
      <div v-if="liveLLContests == null" style="flex: 1;">
        <ObLoading />
      </div>
      <template v-else>
        <!-- Active Lightning Lineups -->
        <div class="liveContestHeaderContainer">
          <LiveContestHeader class="liveContestHeader" label="Lightning Lineups"
            :contests="llContestFiltered" :showGlobalSwap="false" :showQuickSwap="false"
            :contestState="contestState"
          />
        </div>
        <template v-if="llContestFiltered != null && llContestFiltered.length > 0">
          <LiveLLContest v-for="(llEntry, index) in llContestFiltered" :key="index" :entry="llEntry"
            :style="getContestHighlight(llEntry.id)"
            :scoreUpdated="scoreUpdated" class="liveContestContainer"
          />
        </template>
        <div v-else>
          <div class="liveContestContainer noActiveContests">
            You have no {{ contestState }} Lightning Lineups
          </div>
        </div>
      </template>
    </div>
    <FeedSection />

    <ContestWonModal :completedContests="completedLeagues" />
  </div>
</template>

<script>
import ObLeagueApi from '@/api/ObLeagueApi';
import ObLightningLineupsApi from '@/api/ObLightningLineupsApi';
import ContestWonModal from '@/components/modals/ContestWonModal/ContestWonModal';
import EventBus from '@/event-bus';
import LiveContestHeader from './LiveContests/LiveContestHeader';
import LiveLLContest from './LiveContests/LiveLLContest';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';
import FeedSection from '@/components/FeedSection';

export default {
  components: {
    LiveContestHeader,
    LiveLLContest,
    ContestWonModal,
    ObLoading,
    FeedSection,
  },

  data: function() {
    return {
      userId: '',
      days: [],
      selectedDayStart: 0,
      firstLoad: true,
      highlightedLeagueId: null,
      scoreUpdated: {},
      teamData: {},
      completedFilters: {leagueCount: '5', month: this.$moment().month(), year: this.$moment().year(), leagueType: 'all'},
      completedLeagues: [],
      lastCheckTime: null,
      lastCheckedInterval: null,
      socketRooms: {},

      liveLLContests: null,
      lightningLineupsLoaded: false,
      lightningRoomSubscribed: false,

      loadedCompleted: false,
    };
  },

  props: {
    contestState: {type: String, default: null},
  },

  computed: {
    ...mapState(['theme', 'todaysGames']),

    llContestFiltered() {
      if (this.liveLLContests == null) {
        return [];
      }
      return this.liveLLContests.filter((contest) => {
        if (this.contestState === 'live') {
          return this.lastCheckTime.diff(contest.contestStart) > 0;
        }
        return this.lastCheckTime.diff(contest.contestStart) < 0;
      });
    },
  },

  watch: {
    upcomingLeagues(newVal, oldVal) {
      EventBus.$emit('UPDATE_UPCOMING_LEAGUES', newVal);
    },
  },

  created() {
    this.lastCheckTime = this.$moment();
    EventBus.$on('HIGHLIGHT_LEAGUE', this.highlightLeague);
    this.getCompletedLeagues();
    this.loadLLActiveContests();
    // Gets the time to filter active/upcoming every 10s
    this.lastCheckedInterval = setInterval(this.setLastChecked, 10000);
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('LL_SCORE_UPDATE_' + this.userId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('HIGHLIGHT_LEAGUE', this.highlightLeague);
    window.removeEventListener('scroll', this.loadLeagueGamesByScroll);
    clearInterval(this.lastCheckedInterval);
  },

  methods: {
    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'LL_SCORE_UPDATE') {
        // Lightning Lineups live scoring
        const id = data.json.id;
        const json = data.json;
        const found = this.insertLightningLineup(id, json, this.liveLLContests);
        if (!found) {
          this.loadLLActiveContests();
        }
      }
    },

    insertLightningLineup(id, json, list) {
      for (let i=0; i<list.length; i++) {
        if (id === list[i].id) {
          // Hightlight score changes
          const curScore = list[i].userTeam.fpts;
          const newScore = json.userTeam.fpts;
          if (newScore != curScore) {
            this.flashScoreUpdate(id, newScore > curScore ? '+' : '-');
          }
          // Update data
          this.$set(list, i, json);
          return true;
        }
      }
      return false;
    },

    flashScoreUpdate(key, change) {
      const self = this;
      this.$set(this.scoreUpdated, key, change);
      setTimeout(() => {
        self.$set(self.scoreUpdated, key, null);
      }, 800);
    },

    loadLLActiveContests() {
      ObLightningLineupsApi.getActiveContests()
          .then((data) => {
            this.liveLLContests = data.sort(function(a, b) {
              return a.contestStart - b.contestStart;
            });
            this.lightningLineupsLoaded = true;
            this.subscribeToLightningLineupRoom();
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    getCompletedLeagues() {
      this.loadedCompleted = false;
      if (this.completedFilters.leagueCount == 'More') {
        ObLeagueApi.getCompletedLeagues(this.completedFilters.year, this.completedFilters.month + 1)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      } else {
        ObLeagueApi.getLatestCompletedLeague(this.completedFilters.leagueCount)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
              this.subscribeToLightningLineupRoom();
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      }
    },

    subscribeToLightningLineupRoom() {
      // We need userId to subscribe to the correct room
      // Only subscribe if we haven't already, and lightning lineups have been loaded
      if (this.lightningLineupsLoaded && !this.lightningRoomSubscribed && this.userId) {
        EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
        this.$SocketController.subscribeToRoom('LL_SCORE_UPDATE_' + this.userId);
        this.lightningRoomSubscribed = true;
      }
    },

    highlightLeague(id) {
      this.highlightedLeagueId = id;
    },

    getContestHighlight(id) {
      if (this.highlightedLeagueId === id) {
        return 'background: var(--obcolor-background-3);';
      }
      return '';
    },

    setLastChecked() {
      this.lastCheckTime = this.$moment();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.liveContestHeaderContainer, .feedsContainer {
  transition: top 0.3s ease-out;
}

.liveContestHeaderContainer {
  position: sticky;
  z-index: 80;
  padding-bottom: 3px;
  background: var(--obcolor-background-page);
}

.liveContestHeader {
  margin-bottom: 0px;
  background: var(--obcolor-background-3);
}

.liveContestContainer {
  height: 120px;
  display: flex;
  background: var(--obcolor-background-6);
  margin-bottom: 4px;
  overflow: hidden;
}
.liveContestContainer:last-child {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.noActiveContests {
  height: max-content;
  padding: 28px 15px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}
</style>