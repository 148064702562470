<template>
  <div class="ob-page">
    <template v-if="contest">
      <div v-if="contest.state === 'INITIALIZING'" class="intializingContest">
        <SalaryCapContestHeader class="headerBoxHeight" :contest="contest" :myTeams="myTeams"
          :editingTeam="editingTeam" :promoInfo="promoInfo"
        />
        <ObLoading />
        <div class="initializingMsg">The contest is being initialized</div>
      </div>
      <div v-else-if="contest.state === 'SCHEDULED'" class="noContestToDisplay">
        <SalaryCapContestHeader :contest="contest" :myTeams="myTeams"
          :editingTeam="editingTeam" :promoInfo="promoInfo"
        />
        <div>Contest will open on {{ getContestStartDate() }}</div>
        <button class="ob-btn-grey" @click="goToLobby()">Back to Lobby</button>
      </div>
      <div class="boxContainer" v-else-if="playerList">
        <div class="col1">
          <SalaryCapContestHeader class="headerBoxHeight" :contest="contest" :myTeams="myTeams"
            :editingTeam="editingTeam" :promoInfo="promoInfo"
          />
          <SalaryCapGameSlate v-if="contestSlate"
            :contest="contest" :contestSlate="contestSlate" v-model="selectedTeams"
          />
          <!-- Selected player display for weekly contests (Legacy contest support) -->
          <SalaryCapSelectedPlayer v-if="contest.format == 'WEEKLY'" :player="selectedPlayer" :contest="contest"
            :pickedPlayerIds="pickedPlayerIds"
            :picks="picks" :totSpent="totSpent"
            :statusMap="statusMap"
          />
          <!-- Roster list for weekly contests (Legacy contest support) -->
          <SalaryCapPlayerList v-if="contest.format == 'WEEKLY'" :playerList="playerList" :contest="contest"
            :picks="picks" :pickedPlayerIds="pickedPlayerIds"
            :totSpent="totSpent" :statusMap="statusMap"
          />
          <!-- Roster list for daily contests -->
          <SalaryCapPlayerList2 v-if="contest.format == 'DAILY'" :playerList="playerList" :contest="contest"
            :picks="picks" :pickedPlayerIds="pickedPlayerIds"
            :totSpent="totSpent" :statusMap="statusMap"
            :selectedTeams="selectedTeams"
          />
        </div>
        <div class="rosterContainer">
          <!-- Battle Pass Challenges -->
          <BattlePassChallengeList class="headerBoxHeight" :battlePass="battlePass" :dfs="true" />
          <!-- Roster list for daily contests -->
          <SalaryCapRoster2 v-if="contest.format == 'DAILY'" :picks="picks" :totSpent="totSpent"
            :contest="contest" :selectedPlayer="selectedPlayer"
            :statusMap="statusMap" :lineupName="lineupName"
            :submittedLineups="submittedLineups" :editingTeam="editingTeam"
            :promoInfo="promoInfo" :playerList="playerList"
            :numLineups="numLineupsToDisplay" @setNumLineups="setNumLineups"
          />
          <!-- Roster list for weekly contests (Legacy contest support) -->
          <SalaryCapRoster v-else :picks="picks" :totSpent="totSpent"
            :contest="contest" :selectedPlayer="selectedPlayer"
            :statusMap="statusMap" :lineupName="lineupName"
            :submittedLineups="submittedLineups" :editingTeam="editingTeam"
            :promoInfo="promoInfo" :playerList="playerList"
            style="margin-top: -5px;"
          />
          <SponsorImage class="sponsorBannerBox" :sponsor="contestSponsorRoster" width="490px"
            height="67px"
          />
        </div>
      </div>
    </template>
    <div v-else-if="contestIdInvalid" class="noContestToDisplay">
      <div>The contest Id is invalid</div>
      <button class="ob-btn-grey" @click="goToLobby()">Back to Lobby</button>
    </div>
    <ObLoading v-else />
    <SalaryCapLineupModal />
    <SalaryCapConfirmModal />
    <SalaryQuickEntryModal />
    <QuickEntrySuccessModal />
    <SalaryLineupSuccessModal />
    <SalaryLineupEditedModal />
    <SalaryEnterCodeModal />
    <VerifyAccountModal />
    <ImportLineupModal />
    <MultipleLineupConfirmModal />
    <AutoApplyPromoModal />
    <MultiCreateEnteredModal />
  </div>
</template>

<script>
import SalaryCapContestHeader from '@/views/SalaryCapGame/SalaryCapContestHeader';
import SalaryCapGameSlate from '@/views/SalaryCapGame/SalaryCapGameSlate';
import SalaryCapSelectedPlayer from '@/views/SalaryCapGame/SalaryCapSelectedPlayer';
import SalaryCapPlayerList from '@/views/SalaryCapGame/SalaryCapPlayerList';
import SalaryCapPlayerList2 from '@/views/SalaryCapGame/SalaryCapPlayerList2';
import SalaryCapRoster from '@/views/SalaryCapGame/SalaryCapRoster';
import SalaryCapRoster2 from '@/views/SalaryCapGame/SalaryCapRoster2';
import SalaryCapLineupModal from '@/views/SalaryCapGame/LineupModal/SalaryCapLineupModal';
import SalaryCapConfirmModal from '@/views/SalaryCapGame/ConfirmModal/SalaryCapConfirmModal';
import SalaryQuickEntryModal from '@/views/SalaryCapGame/QuickEntryModal/SalaryQuickEntryModal';
import QuickEntrySuccessModal from '@/views/SalaryCapGame/QuickEntryModal/QuickEntrySuccessModal';
import SalaryLineupSuccessModal from '@/views/SalaryCapGame/ConfirmModal/SalaryLineupSuccessModal';
import SalaryLineupEditedModal from './ConfirmModal/SalaryLineupEditedModal';
import SalaryEnterCodeModal from './PromoCode/SalaryEnterCodeModal';
import ObLoading from '@/components/ObLoading';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObPlayersApi from '@/api/ObPlayersApi';
import ObPromoCodeApi from '@/api/ObPromoCodeApi';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import VerifyAccountModal from '@/views/VerifyAccount/VerifyAccountModal';
import ImportLineupModal from '@/views/SalaryCapGame/ImportLineupModal/ImportLineupModal';
import MultipleLineupConfirmModal from '@/views/SalaryCapGame/ConfirmModal/MultipleLineupConfirmModal';
import AutoApplyPromoModal from '@/components/modals/AutoApplyPromoModal';
import MultiCreateEnteredModal from './MultiCreateModal/MultiCreateEnteredModal';
import SalaryCapGameUtils from '@/utils/views/SalaryCapGameUtils';
import LineupsUtils from '@/utils/shared/LineupsUtils';
import BattlePassChallengeList from '@/views/BattlePass/BattlePassChallengeList';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  components: {
    ObLoading,
    SalaryCapContestHeader,
    SalaryCapGameSlate,
    SalaryCapSelectedPlayer,
    SalaryCapPlayerList,
    SalaryCapPlayerList2,
    SalaryCapRoster,
    SalaryCapRoster2,
    SalaryCapLineupModal,
    SalaryLineupSuccessModal,
    SalaryCapConfirmModal,
    SalaryQuickEntryModal,
    QuickEntrySuccessModal,
    SalaryLineupEditedModal,
    SalaryEnterCodeModal,
    VerifyAccountModal,
    ImportLineupModal,
    MultipleLineupConfirmModal,
    AutoApplyPromoModal,
    MultiCreateEnteredModal,
    BattlePassChallengeList,
    SponsorImage,
  },

  data() {
    return {
      contestId: null,
      playerList: null,
      picks: null,
      pickedPlayerIds: {},
      selectedPlayer: null,
      salaryCap: 10000,
      contest: null,
      contestSlate: null,
      statusMap: null,
      rosterCookieKey: 'salary-cap-picked-players',

      // Set as an object so components can use .name for v-model on input
      lineupName: {name: ''},
      tempLineupName: '',
      promoInfo: {code: '', state: 'NO_CODE'},
      numLineups: 1,
      submittedLineups: {},
      editingTeam: null,

      // Daily only
      selectedTeams: {},

      // Handle invalid ids, or reload if contest is still initializing
      reloadInterval: null,
      contestIdInvalid: false,
    };
  },

  created() {
    this.contestId = this.$route.params.contestId;
    this.getContest();

    EventBus.$on('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$on('CLOSE_VERIFY_COMPONENT', this.verificationModalClosed);
    EventBus.$on('SALARY_CAP_SELECT_PLAYER', this.setSelectedPlayer);
    EventBus.$on('SALARY_CAP_PICK_PLAYER', this.pickPlayer);
    EventBus.$on('SALARY_CAP_REMOVE_PLAYER', this.removePlayer);
    EventBus.$on('SALARY_CAP_CONFIRM', this.submitLineup);
    EventBus.$on('SALARY_CAP_CONFIRM_EDIT', this.editLineup);
    EventBus.$on('SALARY_CAP_CLEAR_ROSTER', this.resetRoster);
    EventBus.$on('SALARY_CAP_CLEAR_ROSTER_COOKIE', this.resetCookieVals);
    EventBus.$on('SALARY_CAP_SELECT_LINEUP', this.selectLineup);
    EventBus.$on('SALARY_CAP_SET_PROMO_CODE', this.setCode);
    EventBus.$on('SALARY_CAP_VALIDATE_PROMO_CODE', this.validateCode);
    EventBus.$on('SALARY_CAP_RELOAD_CONTEST_DATA', this.reloadContestData);
    EventBus.$emit('LOAD_BATTLE_PASS');
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('FSP_LOBBY');
    EventBus.$off('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$off('CLOSE_VERIFY_COMPONENT', this.verificationModalClosed);
    EventBus.$off('SALARY_CAP_SELECT_PLAYER', this.setSelectedPlayer);
    EventBus.$off('SALARY_CAP_PICK_PLAYER', this.pickPlayer);
    EventBus.$off('SALARY_CAP_REMOVE_PLAYER', this.removePlayer);
    EventBus.$off('SALARY_CAP_CONFIRM', this.submitLineup);
    EventBus.$off('SALARY_CAP_CONFIRM_EDIT', this.editLineup);
    EventBus.$off('SALARY_CAP_CLEAR_ROSTER', this.resetRoster);
    EventBus.$off('SALARY_CAP_CLEAR_ROSTER_COOKIE', this.resetCookieVals);
    EventBus.$off('SALARY_CAP_SELECT_LINEUP', this.selectLineup);
    EventBus.$off('SALARY_CAP_SET_PROMO_CODE', this.setCode);
    EventBus.$off('SALARY_CAP_VALIDATE_PROMO_CODE', this.validateCode);
    EventBus.$off('SALARY_CAP_RELOAD_CONTEST_DATA', this.reloadContestData);
    this.stopRefresh();
  },

  computed: {
    ...mapState(['userId', 'isDepositVerified', 'battlePass']),

    isMultiCreate() {
      return SalaryCapGameUtils.isMultiCreate(this.contest);
    },

    multiCreateData() {
      return SalaryCapGameUtils.multiCreateData(this.contest);
    },

    isEditMode() {
      return this.$route.params.teamId || this.editingTeam;
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    myTeams() {
      if (!this.contest || !this.contest.leaderboard) {
        return [];
      }
      return this.contest.leaderboard.filter((entry) => entry.userId === this.userId);
    },

    totSpent() {
      return LineupsUtils.totSpent(this.roster, this.picks);
    },

    numLineupsToDisplay() {
      if (!this.isMultiCreate || !this.multiCreateData) {
        return this.numLineups;
      }

      return this.multiCreateData.contestCount;
    },

    contestSponsorRoster() {
      if (!this.contest?.sponsorAds) {
        return null;
      }
      return this.contest?.sponsorAds['salarycap-roster'];
    },
  },

  watch: {
    pickedPlayerIds(newVal) {
      // Don't save players if editing an lineup
      if (this.editingTeam) {
        return;
      }
      if (!this.contest || !this.contest.days || !this.contest.days[0]) {
        return;
      }

      const day = this.$moment(this.contest.days[0]).format('YYYY-MM-DD');
      const cookieKey = this.contest.sport + '_' + day;

      let cookieJSON = localStorage.getItem(this.rosterCookieKey);
      if (cookieJSON != null) {
        cookieJSON = JSON.parse(cookieJSON);
        this.$set(cookieJSON, cookieKey, newVal);
      } else {
        cookieJSON = {};
        this.$set(cookieJSON, cookieKey, newVal);
      }

      localStorage.setItem(this.rosterCookieKey, JSON.stringify(cookieJSON));
    },
  },

  methods: {
    checkForCodeParam() {
      if (this.hasUrlPromo()) {
        this.validateCode(this.$route.query.promo);
      }
    },

    hasUrlPromo() {
      return this.$route.query && this.$route.query.promo != null;
    },

    setCode(codeInfo) {
      this.promoInfo = {code: codeInfo.code, state: codeInfo.state};
    },

    validateCode(code) {
      ObPromoCodeApi.validateCode(this.contest.id, code).then((response) => {
        this.setCode({code: code, state: 'VALID'});
        EventBus.$emit('SALARY_CAP_CODE_IS_VALID', code);
      }).catch((e) => {
        let message = null;
        if (e && e.response && e.response.data) {
          message = e.response.data;
        }
        EventBus.$emit('SALARY_CAP_CODE_IS_INVALID', message);
      });
    },

    getAutoAppliedPromoCode() {
      if (this.editingTeam || this.isMultiCreate) {
        return;
      }
      ObPromoCodeApi.getAutoAppliedSCPromoCode(this.contestId)
          .then((result) => {
            if (result.isValid && this.promoInfo.code == '') {
              this.setCode({code: result.promo.id, state: 'VALID'});
              EventBus.$emit('OPEN_PROMO_AUTO_APPLY_MODAL', result.promo);
            }
          })
          .catch((_error) => {
            // Unable to get Tickets to auto apply
          });
    },

    initializePicks() {
      const picks = {};
      if (!this.roster) {
        this.picks = {};
      }
      for (const posInfo of this.roster) {
        this.$set(picks, posInfo.short, []);
      }
      this.picks = null;
      this.picks = picks;
    },

    resetRoster() {
      this.initializePicks();
      this.pickedPlayerIds = {};
      this.resetCookieVals();
    },

    verificationModalClosed() {
      // When the verification modal is closed
      // Check if the user is verified or not, adjust submit modal
      if (this.isDepositVerified) {
        EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', '');
      } else {
        const errorMsg = 'Your account was not verified, you will be unable to submit a lineup until you complete the verification process';
        EventBus.$emit('SALARY_CAP_PREVENT_SUBMIT');
        EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', errorMsg);
      }
    },

    checkContestEligibility() {
      ObSalaryCapApi.checkContestEligibility()
          .then((_data) => {
            // User is eligible to enter contests, do nothing
          })
          .catch((error) => {
            // User is not eligible, display warning popup
            EventBus.$emit('SHOW_LOCATION_ERROR_MODAL', error?.response?.data?.locationError);
          });
    },

    loadContestInterval() {
      if (this.contest.state === 'INITIALIZING') {
        this.stopRefresh();
        this.reloadInterval = setInterval(this.getContest, 10000);
      }
    },

    stopRefresh() {
      clearInterval(this.reloadInterval);
    },

    getContest() {
      // Don't load grouped contest data if we are editing a lineup
      const groupContest = !this.isEditMode;

      ObSalaryCapApi.getContest(this.contestId, true, groupContest).then((response) => {
        if (response.contest.state === 'INITIALIZING') {
          this.contest = response.contest;
          this.loadContestInterval();
        } else if (response.contest.state === 'SCHEDULED') {
          this.contest = response.contest;
          this.$SocketController.subscribeToRoom('FSP_LOBBY');
        } else {
          // Contest is in the correct state now, no need to continue to refresh
          this.stopRefresh();
          // We store game slate separate so the filters don't need to re-render on socket updates for the contest JSON
          this.contest = response.contest;
          this.contestSlate = response.contest.gameSlateData;
          this.playerList = response.players;
          this.selectedPlayer = this.playerList[0];
          this.initializePicks();
          this.loadStatuses();
          this.getCookieVals();
          this.loadMyLineups();
          this.checkContestEligibility();

          this.$SocketController.subscribeToRoom('FSP_LOBBY');
          this.checkForCodeParam();

          // If not editing a team, check for ticket
          if (!this.$route.params.teamId && !this.hasUrlPromo()) {
            this.getAutoAppliedPromoCode();
          }

          if (response.contest.format == 'DAILY') {
            EventBus.$emit('SET_SCOREBOARD_VISIBLE', false);
          }
        }
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          this.contestIdInvalid = error.response.data === 'Invalid contest';
        }
      });
    },

    reloadContestData(loadPlayers = true) {
      // Don't load grouped contest data if we are editing a lineup
      const groupContest = !this.isEditMode;

      ObSalaryCapApi.getContest(this.contestId, loadPlayers, groupContest).then((response) => {
        this.contest = response.contest;
        this.contestSlate = response.contest.gameSlateData;
        if (loadPlayers) {
          this.playerList = response.players;
          this.resetPicksData();
        }
      });
    },

    getContestStartDate() {
      if (!this.contest) {
        return '';
      }
      return this.$moment(this.contest.contestOpenTime).format('ddd MMM D, h:mma');
    },

    resetPicksData() {
      // Reset the picked players with the updated player list data.
      // Maintains the current roster slot taken.
      // Can this be sped up with an index map by player id?
      for (const pos in this.picks) {
        const players = this.picks[pos];
        let pindex = 0;

        for (const player of players) {
          const playerId = player.id;
          const newJSON = this.playerList.find((p) => p.id == playerId);
          if (newJSON) {
            newJSON.rosterSlotTaken = player.rosterSlotTaken;
            players[pindex] = newJSON;
          }
          pindex++;
        }
      }

      // Update picks in modal
      EventBus.$emit('SALARY_CAP_MODAL_UPDATE', {picks: this.picks, totSpent: this.totSpent});
    },

    loadMyLineups() {
      ObSalaryCapApi.getPreContestLineups(this.contestId).then((response) => {
        this.submittedLineups = response;
        this.checkForSelectedTeam();
      });
    },

    goToLobby() {
      this.$router.push('/lobby');
    },

    checkForSelectedTeam() {
      const editTeamId = this.$route.params.teamId;
      if (editTeamId && this.submittedLineups[editTeamId]) {
        const teamNum = editTeamId.substring(editTeamId.indexOf('_') + 1);
        // Tell the roster to update the dropdown for the set team
        // Doing so will then trigger selectLineup
        // There might be a better way to do this, but this works for now
        EventBus.$emit('SALARY_CAP_SET_SELECTED_LINEUP', String(teamNum));
      }
    },

    selectLineup(teamNum) {
      // Switching from new lineup to view a team
      // Save the current name input to restore when going back to New Lineup
      if (this.editingTeam == null) {
        this.tempLineupName = this.lineupName.name;
      }

      if (teamNum === 'New') {
        this.resetRoster();
        this.editingTeam = null;
        this.lineupName.name = this.tempLineupName;
        this.getCookieVals();
        return;
      }

      // Load a previously submitted lineup (to edit)
      const teamId = String(teamNum).includes('_') ? teamNum : this.userId + '_' + teamNum;
      const teamData = this.submittedLineups[teamId];
      if (!teamData) {
        return;
      }

      this.editingTeam = teamId;
      this.lineupName.name = teamData.customName || '';
      this.resetRoster();
      this.applyPicks(teamData.players);
    },

    resetCookieVals() {
      let cookieJSON = localStorage.getItem(this.rosterCookieKey);
      const day = this.$moment(this.contest.days[0]).format('YYYY-MM-DD');
      const cookieKey = this.contest.sport + '_' + day;

      if (cookieJSON != null) {
        cookieJSON = JSON.parse(cookieJSON);

        if (cookieJSON[cookieKey] != null) {
          this.$set(cookieJSON, cookieKey, {});
          localStorage.setItem(this.rosterCookieKey, JSON.stringify(cookieJSON));
        }
      }
    },

    getCookieVals() {
      let cookieJSON = localStorage.getItem(this.rosterCookieKey);
      const day = this.$moment(this.contest.days[0]).format('YYYY-MM-DD');
      const cookieKey = this.contest.sport + '_' + day;

      // If a team was sent through the route params, set these values and ignore cookie values
      if (this.$route.params.pickedPlayersByPos != null) {
        this.pickPlayersByPos(this.$route.params.pickedPlayersByPos);
        return;
      }

      if (cookieJSON != null) {
        cookieJSON = JSON.parse(cookieJSON);
      } else {
        return;
      }

      for (const sportDay of Object.keys(cookieJSON)) {
        const date = sportDay.replace(/NHL_|NFL_|MLB_|NBA_/, '');

        const today = this.$moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        const contestDay = this.$moment(date, 'YYYY-MM-DD').hours(0).minutes(0).seconds(0).milliseconds(0);

        // Difference in milliseconds
        // Clear if more than 24 hours past the start date
        if (today.diff(contestDay) > 86400000) {
          this.$delete(cookieJSON, sportDay);
        }
      }

      localStorage.setItem(this.rosterCookieKey, JSON.stringify(cookieJSON));

      // Get picked players from cookie
      if (cookieJSON[cookieKey] != null) {
        this.pickedPlayerIds = cookieJSON[cookieKey];
      }

      // Find players from cookie and insert them into "picks" object
      this.applyPicks(Object.keys(this.pickedPlayerIds));
    },

    applyPicks(playerIdArray) {
      for (const playerId of playerIdArray) {
        const player = this.playerList.find((p) => p.id == playerId);
        if (player) {
          this.pickPlayer(player);
        }
      }
    },

    pickPlayerInSlot(playerId, slotName) {
      const isOpen = this.draftSpotIsOpen(slotName);
      if (!isOpen) {
        return;
      }
      const player = this.playerList.find((p) => p.id == playerId);
      this.$set(this.pickedPlayerIds, player.id, true);
      player.rosterSlotTaken = slotName;
      this.picks[slotName].push(player);
    },

    pickPlayersByPos(playerIdsByPos) {
      for (const posInfo of this.roster) {
        if (!playerIdsByPos[posInfo.short]) {
          continue;
        }

        for (const playerId of playerIdsByPos[posInfo.short]) {
          this.pickPlayerInSlot(playerId, posInfo.short);
        }
      }
    },

    setSelectedPlayer(player) {
      this.selectedPlayer = player;
    },

    setNumLineups(numLineups) {
      this.numLineups = numLineups;
    },

    loadStatuses() {
      ObPlayersApi.getGlobalStatusMap(this.contest.sport)
          .then((data) => {
            this.statusMap = data;
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    submitLineup() {
      // Validate lineup and create lineup JSON to sent to server
      const id = this.contest.id;
      const code = this.promoInfo && this.promoInfo.code != '' ? this.promoInfo.code : null;
      let players = [];
      for (const posInfo of this.roster) {
        if (this.picks && this.picks[posInfo.short]) {
          players = players.concat(this.picks[posInfo.short].map((p) => {
            return p.id;
          }));
        }
      }

      let name = this.lineupName.name.trim();
      if (name === '') {
        name = null;
      }

      // If this submission is successful, adjust the modal if we are at the entry limit
      this.submittingLineup = true;
      const params = {
        players: players,
      };
      if (name) {
        params.name = name;
      }
      if (code) {
        params.promo = code;
      }
      this.sendSubmitCall(params, id);
    },

    sendSubmitCall(params, id) {
      const atMaxEntries = this.contest.myEntries + 1 >= this.contest.entriesPerUser;
      ObSalaryCapApi.submitLineup(params, id, this.numLineups)
          .then((response) => {
            this.$Analytics.trackTournamentEntry(this.contest, true, '', this.numLineups);
            this.handleSubmitSuccess(response, atMaxEntries, params.code);
          })
          .catch((error) => {
            const errorMsg = error?.response?.data?.errorMessage ?? 'Submission Error';
            this.$Analytics.trackTournamentEntry(this.contest, false, '', this.numLineups);
            this.submittingLineup = false;

            // Show modal if this is a location error
            const locationError = error?.response?.data?.locationError;
            if (locationError) {
              // Trigger submit failed with no error message to reset the submit modal
              EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', '');
              EventBus.$emit('SHOW_LOCATION_ERROR_MODAL', locationError);
            } else {
              EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', errorMsg);
              // If user is not verified, open verification modal
              if (errorMsg && errorMsg.includes('account must be verified')) {
                EventBus.$emit('OPEN_VERIFY_ACCOUNT_MODAL', false);
              }
            }
            this.reloadContestData();
          });
    },

    handleSubmitSuccess(response, atMaxEntries, code) {
      this.submittingLineup = false;
      this.lineupName.name = '';
      this.loadMyLineups();
      this.reloadContestData(false);


      // Closes confirm modal, and opens successful lineup submission modal
      const lineupInfo = {
        id: null,
        picks: this.picks,
        statusMap: this.statusMap,
        submitTeamResults: null,
        multiCreateData: null,
      };

      if (!this.isMultiCreate) {
        lineupInfo.id = response.teamId;
      } else {
        lineupInfo.id = response.teamId;
        lineupInfo.submitTeamResults = response.submitTeamResults;
        lineupInfo.multiCreateData = response.userCreatedContestsData;
      }

      this.numLineups = 1;
      if (this.contest.format == 'DAILY') {
        if (lineupInfo.submitTeamResults) {
          this.handleMultiCreateEntries(lineupInfo.submitTeamResults);
        }

        EventBus.$emit('SALARY_CAP_SUBMIT_SUCCESS', this.contest, this.promoInfo, atMaxEntries, lineupInfo, this.numLineupsToDisplay);
      } else {
        this.$Analytics.trackTournamentEntry(this.contest, true, code, this.numLineupsToDisplay);
        EventBus.$emit('SALARY_OPEN_SUCCESS_MODAL', this.contest, this.promoInfo, atMaxEntries);
      }

      EventBus.$emit('UPDATE_ACCOUNT_INFO');
    },

    handleMultiCreateEntries(contests) {
      const successContests = contests.slice().filter((c) => c.success);
      const numSuccess = successContests.length;
      const numFail = contests.length - numSuccess;

      this.$Analytics.trackTournamentEntry(this.contest, true, '', numSuccess);
      this.$Analytics.trackTournamentEntry(this.contest, false, '', numFail);
    },

    editLineup() {
      const id = this.contest.id;
      const teamId = this.editingTeam;
      let players = [];
      for (const posInfo of this.roster) {
        if (this.picks && this.picks[posInfo.short]) {
          players = players.concat(this.picks[posInfo.short].map((p) => {
            return p.id;
          }));
        }
      }

      let name = this.lineupName.name.trim();
      if (name === '') {
        name = null;
      }

      this.submittingLineup = true;
      ObSalaryCapApi.editLineup(id, teamId, players, name)
          .then((response) => {
            this.submittingLineup = false;
            this.loadMyLineups();
            // Closes confirm modal, and opens successful edit modal
            EventBus.$emit('SALARY_CAP_EDIT_SUCCESS', this.contest);
          })
          .catch((error) => {
            this.submittingLineup = false;
            if (error && error.response && error.response.data) {
              EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', error.response.data);
            } else {
              EventBus.$emit('SALARY_CAP_SUBMIT_FAILED', 'Submission failed');
            }
          });
    },

    rosterAtPos(position) {
      return LineupsUtils.rosterAtPos(this.roster, position);
    },

    hasValidRosterSlot(player) {
      return LineupsUtils.hasValidRosterSlot(player, this.contest, this.roster, this.picks, this.pickedPlayerIds);
    },

    draftSpotIsOpen(draftPos) {
      return LineupsUtils.draftSpotIsOpen(this.roster, this.picks, draftPos);
    },

    pickPlayer(player) {
      const rosterSlot = this.hasValidRosterSlot(player);
      if (rosterSlot == null) {
        return;
      }
      this.$set(this.pickedPlayerIds, player.id, true);
      player.rosterSlotTaken = rosterSlot;
      this.picks[rosterSlot].push(player);
    },

    removePlayer(player) {
      const rosterSlot = player.rosterSlotTaken;
      if (!this.picks || !this.pickedPlayerIds || !player.id || !this.picks[rosterSlot]) {
        return;
      }

      this.$delete(this.pickedPlayerIds, player.id);
      this.picks[rosterSlot] = this.picks[rosterSlot].filter((p) => {
        return p.id != player.id;
      });
    },

    handleSocketUpdate(data) {
      if (!data.json || !data.UPDATE_TYPE) {
        return;
      }
      if (data.UPDATE_TYPE !== 'lobby_update_sc') {
        return;
      }

      // Ignore updates if contest hasn't loaded yet
      if (!this.contest) {
        return;
      }

      // Don't process lobby updates while in edit lineup mode
      // Lobby updates contain group contest data, we only care about the individual contest data while editing
      if (this.isEditMode) {
        return;
      }

      // For grouped user created contests, match the contest using batch id and entry fee
      // The contest id can change as the grouped contests start to fill
      const batchId = data?.json?.userCreatedBatchId;
      let isMatchingGroupContest = false;
      if (batchId && batchId === this.contest.userCreatedBatchId) {
        isMatchingGroupContest = this.contest.fee == data?.json?.fee;
      }

      // Update if the group batch matches or if the id matches
      if (isMatchingGroupContest || this.contest.id === data.json.id) {
        // When updating contest from a socket update
        // Keep data that is not included in the socket updates
        if (this.contest.myEntries != null) {
          data.json.myEntries = this.contest.myEntries;
        }
        if (this.contest.myEntriesRemaining != null) {
          data.json.myEntriesRemaining = this.contest.myEntriesRemaining;
        }
        if (this.contest.gameSlateData) {
          data.json.gameSlateData = this.contest.gameSlateData;
        }
        this.contest = data.json;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-page {
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  transition: height ease-out 0.3s;
  padding-bottom: 0;
}

.headerBoxHeight {
  box-sizing: border-box;
  height: 110px;
  min-height: 110px;
  max-height: 110px;
}

.boxContainer {
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: flex-start;
}

::v-deep {
  .infoContainer,
  .moneyText {
    font-size: 14px;
  }

  .contestCountDown,
  .contestName {
    font-size: 16px;
  }

  .ob-box {
    flex: 1;
  }

  .boxBody {
    font-size: 12px;
    background: var(--obcolor-background-6);
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
  }

  .label,
  .progressChallenge,
  .rewardContainer {
    font-size: 12px;
  }

  .rewardContainer{
    width: 40px;
  }

  .challengeContentContainer {
    min-width: 120px;
  }

  .title {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .buttonList {
    padding: 5px 10px 5px 10px;
    margin: -5px -5px 0 -5px;
    background: var(--obcolor-background-2);
    border-radius: 5px 5px 0 0;
  }

  .typeBtn {
    min-width: 20px;
    padding: 0;

    &.selected, &:hover {
      background: var(--obcolor-background-1) !important;
    }

    span {
      padding-left: 5px;
    }
  }

  .timeRemainingBox {
    color: var(--obcolor-font-primary) !important;
  }
}

.noContestToDisplay {
  color: var(--obcolor-font-secondary);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 40px auto 0 auto;

  button {
    width: 150px;
    margin: 25px auto 0 auto;
  }
}

.intializingContest {
  color: var(--obcolor-font-secondary);
  display: flex;
  justify-content: center;
  flex-direction: column;

  .initializingMsg {
    align-self: center;
  }
}

.col1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 940px;
  max-height: 100%;
}

.rosterContainer {
  width: 490px;
  min-width: 490px;
  margin-bottom: 45px;
  transition: height ease-out 0.3s;
  margin-left: 10px;
  position: sticky;
  top: calc(15px + var(--ob-nav-offset));
  display: flex;
  flex-direction: column;
  padding: 0;
  // Max height minus room for messenger
  max-height: calc(100% - 45px);
}

.sponsorBannerBox {
  margin-top: 8px;
}
</style>