<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 15px 20px;"
  >
    <div slot="body" class="body">
      <div v-if="numSuccessContests > 0" class="title">Lineups Submitted!</div>
      <div v-else class="title">Lineups Failed to Submit</div>
      <div class="paragraph">
        <div v-if="numSuccessContests > 0">
          You have joined {{ numSuccessContests }} contest{{ numSuccessContests > 1 ? 's' : '' }} with
          <!-- Entry fees in dollars -->
          <span>{{ moneyFormat(feesValue) }}</span>
          <span> in entry fees</span>

          and

          <!-- Prizes in dollars -->
          <span class="moneyText">{{ moneyFormat(prizesValue) }}</span>
          <!-- Add period if there's no "and X in ownersbucks prizes" -->
          <span> in prize pools.</span>
          Good luck!
        </div>
        <div v-else>
          Failed to join contests. Please view errors below:
        </div>
      </div>

      <!-- Table of lineups with errors -->
      <table v-if="failContestResponses.length > 0" class="errorTable" aria-labelledby="errorTitle">
        <tr @click.stop="showErrors = !showErrors" class="errorLineupsHeader">
          <th class="redText" id="errorTitle">
            <i class="fas fa-exclamation-circle fa-lg error"></i>
            Error submitting {{ failContestResponses.length }} lineup(s)
          </th>
          <th>
            <div>
              <i v-if="!showErrors" class="fas fa-chevron-down"></i>
              <i v-else class="fas fa-chevron-up"></i>
            </div>
          </th>
        </tr>
        <template v-if="showErrors">
          <tr v-for="(errorLineup, index) in failContestResponses" :key="index">
            <td class="redText">
              {{ errorLineup.contestId }}
              {{ errorLineup.errorMessage ? ' - ' + errorLineup.errorMessage : '' }}
            </td>
          </tr>
        </template>
      </table>
    </div>

    <div slot="footer" class="footer">
      <button class="ob-btn" @click="closeModal()">Continue</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      // Info regarding total prizes, entries, etc
      multiCreateData: null,
      // Contest list with info about whether it successfully submitted
      contestResults: [],
      showErrors: false,
    };
  },

  created() {
    EventBus.$on('OPEN_MULTI_CREATE_SUBMITTED_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_MULTI_CREATE_SUBMITTED_MODAL', this.openModal);
  },

  computed: {
    feesValue() {
      if (!this.multiCreateData.totalFees) {
        return 0;
      }
      return this.multiCreateData.totalFees / 100;
    },

    prizesValue() {
      if (!this.multiCreateData.totalPrizePool) {
        return 0;
      }
      return this.multiCreateData.totalPrizePool / 100;
    },

    // List of contest info for contests that succeeded in submission
    successContests() {
      return this.contestResults.slice().filter((contest) => {
        return contest.success;
      });
    },

    // Information about the response from the server about contests that failed to submit
    failContestResponses() {
      return this.contestResults.slice().filter((contest) => {
        return !contest.success;
      });
    },

    numSuccessContests() {
      if (!this.successContests) {
        return 0;
      }
      return this.successContests.length;
    },
  },

  methods: {
    openModal(contestResults, multiCreateData) {
      this.contestResults = contestResults;
      this.multiCreateData = multiCreateData;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.contestResults = [];
      this.multiCreateData = null;
      this.showErrors = false;
    },

    moneyFormat(amount) {
      return amount.formatMoneyDecimals().removeCentsIfZero();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .paragraph {
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: var(--obcolor-font-secondary);
  }

  .paragraph:not(:first-child) {
    margin-top: 5px;
  }
}

.footer {
  background: var(--obcolor-background-5);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 14px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.redText {
  color: var(--obcolor-red);
}

.moneyText {
  color: var(--obcolor-green);
  font-weight: bold;
}

.blueText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
}

.ownersbucksIcon {
  height: 10px;
}

.errorTable {
  width: 100%;
  margin-top: 5px;

  th, td {
    padding-top: 5px;
    padding-left: 10px;
  }

  th {
    cursor: pointer;
    border-top: 1px solid var(--obcolor-background-2);
  }

  .errorLineupsHeader {
    text-align: left;
    width: 100%;
  }
}
</style>