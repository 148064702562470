<template>
  <div>
    <Lottie @animCreated="setAnimationControls"
      :options="{
        animationData: require('@/assets/lightninglineups/animations/Reveal.json'),
        autoplay: false,
        loop: false,
      }"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie';

export default {
  components: {
    Lottie,
  },

  data() {
    return {
      animationControls: null,
    };
  },

  computed: {
    // Returns true if we're at the frame where the previous lineup is covered up
    // So that we can change to the new lineup and reveal it
    revealFinished() {
      if (this.animationControls == null) {
        return true;
      }

      return this.animationControls.currentFrame > 10;
    },

    // Returns true if we are at the last frame of the animation
    animationFinished() {
      if (this.animationControls == null) {
        return true;
      }

      // current frame is zero-indexed, so it's finished if the current frame is 1 less than the total frames
      return this.animationControls.currentFrame == this.animationControls.totalFrames - 1;
    },
  },

  watch: {
    animationFinished(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$emit('animationFinished', true);
        this.stopAnimation();
      }
    },

    revealFinished(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$emit('revealFinished', true);
      }
    },
  },

  methods: {
    setAnimationControls(anim) {
      this.animationControls = anim;
    },

    playAnimation() {
      this.animationControls.play();
    },

    stopAnimation() {
      this.animationControls.stop();
    },
  },
};
</script>
