<template>
  <div v-if="scoringStats && playerDetails.statistics">
    <table style="border-radius: 5px; overflow: hidden; width: 100%;" aria-label="Season Stats">
      <tr style="background: var(--obcolor-background-2);" v-if="scoringStats.length > 1">
        <th scope="col"></th>
        <template v-for="(category, index) in scoringStats">
          <th class="endSection" style="padding-top: 10px; padding-bottom: 0; font-size: 12px;" :colspan="category.stats.length"
            :key="index"
          >
            {{ category.label }}
          </th>
        </template>
      </tr>
      <tr style="background: var(--obcolor-background-2);">
        <th class="leftInfo tableTitle" scope="col">SEASON</th>
        <template v-for="(category, index1) in scoringStats">
          <template v-for="(stat, index2) in category.stats">
            <th :key="index1 + '_' + index2"
              v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
              :class="{endSection: index2 == category.stats.length - 1}"
            >
              {{ stat.label }}
            </th>
          </template>
        </template>
      </tr>
      <tr>
        <td class="leftInfo">{{ playerDetails.statistics.season }}</td>
        <template v-for="(category, index1) in scoringStats">
          <template v-for="(stat, index2) in category.stats">
            <td :key="index1 + '_' + index2" :class="{endSection: index2 == category.stats.length - 1}">
              {{ getStat(playerDetails.statistics, stat.path) }}
            </td>
          </template>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  props: {
    playerDetails: Object,
    sport: String,
  },

  data() {
    return {
      scoringStats: {},
      isExpanded: false,
    };
  },

  created() {
    this.scoringStats = this.$ScoringStats.getSeasonStats(this.sport, this.playerDetails.position);
  },

  computed: {

    showMoreCareerStats() {
      return this.playerDetails.statistics;
    },

  },

  methods: {

    playerHasStat(statPath) {
      if (!statPath || !this.playerDetails.statistics) {
        return false;
      }

      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      if (playerRole === '' || !playerRole) {
        return false;
      }
      return this.playerDetails.statistics[playerRole];
    },

    getStat(season, statPath) {
      if (!season || !statPath) {
        return '';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);
      if (!season[playerRole]) {
        return '0';
      }
      const stat = season[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    setIsExpanded(isExpanded) {
      this.isExpanded = isExpanded;
      if (isExpanded) {
        EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'season', 'smooth');
      } else {
        EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'season');
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.leftInfo.tableTitle {
  font-size: 14px;
  width: 70px;
  color: var(--obcolor-font-secondary);
}

.leftInfo {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding: 10px 10px 10px 15px;
  white-space: nowrap;
}

tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}
tr:nth-child(even):not(:first-child) {
  background: var(--obcolor-background-6);
}

th {
  color: var(--obcolor-font-secondary);
  padding: 10px;
}

td {
  padding: 10px 0;
}

th:not(.leftInfo), td:not(.leftInfo) {
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  width: auto;
  min-width: 55px;
  max-width: 200px;
}

.showMore {
  padding: 5px 10px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  cursor: pointer;
}

.endSection:not(:last-child) {
  border-right: 2px solid var(--obcolor-background-6);
}
</style>