import { render, staticRenderFns } from "./VerifySuccess.vue?vue&type=template&id=5b6f9a1e&scoped=true"
import script from "./VerifySuccess.vue?vue&type=script&lang=js"
export * from "./VerifySuccess.vue?vue&type=script&lang=js"
import style0 from "./VerifySuccess.vue?vue&type=style&index=0&id=5b6f9a1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6f9a1e",
  null
  
)

export default component.exports