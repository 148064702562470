<template>
  <div class="ob-page changePassContainer">
    <div class="ob-box" style="display: flex;">
      <div style="height: 100%; flex: 1; display: flex; align-items: center; justify-content: center;">
        <form @submit.prevent="resetPassword()" style="flex: 1;">
          <div class="changePassFormBox" style="padding: 0 20px;">
            <div class="ob-subheading changePassHeader" style="display: flex; align-items: center;">
              <img style="height: 30px; margin-right: 5px;" :src="require('@/assets/obicon_' + ((theme == 'dark') ? 'white' : 'black') + '.png')" alt="OwnersBox Icon">
              <div class="ob-subheading" style="margin-top: 8px;">Change Password</div>
            </div>
            <div class="inputBox">
              <div style="margin-left: 5px; font-weight: normal; color: var(--obcolor-font-primary);">New Password</div>
              <input type="password" v-model="newPassword">
              <div class="resetInfo">
                <span :class="{resetError: passwordError.errors.passLength == true}">Min. 8 characters,</span>
                <span :class="{resetError: passwordError.errors.number == true}"> 1 Number & 1 Letter</span>
              </div>
            </div>
            <div class="inputBox" style="margin-top: 25px;">
              <div style="margin-left: 5px; font-weight: normal; color: var(--obcolor-font-primary);">Confirm New Password</div>
              <input type="password" v-model="newConfirmPassword">
              <div class="resetInfo">
                <div v-show="confirmPasswordError === true" class="resetError">Your passwords do not match</div>
              </div>
            </div>
            <div style="position: relative; width: 100%; margin-top: 30px;">
              <!-- Change password Button -->
              <button v-if="processingRequest == true" class="ob-btn-grey changePass" disabled
                style="overflow: hidden;"
              >
                <ObLoading :size="60" style="margin-left: -30px; margin-right: -5px;" />
                <span style="color: white;">Changing Password...</span>
              </button>
              <button v-else-if="passwordError.isError == false && confirmPasswordError === false && succeededChange == false"
                class="ob-btn changePass"
              >
                Change Password
              </button>
              <button class="ob-btn-grey changePass" disabled
                v-else-if="(passwordError.isError == true || confirmPasswordError === true) && succeededChange == false"
              >
                Change Password
              </button>
              <div v-else style="width: 100%; text-align: center;">
                <div>Password change succeeded. Click <router-link to="/login" class="linkText">here</router-link> to login</div>
              </div>
              <div class="resetError" style="width: 100%; text-align: center; position: absolute; font-size: 14px; margin-top: 10px;" v-if="resetPasswordError != null">
                {{ resetPasswordError }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import {mapState} from 'vuex';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ObLoading,
  },

  data() {
    return {
      validationKey: null,
      newPassword: '',
      newConfirmPassword: '',

      succeededChange: false,
      resetPasswordError: null,
      processingRequest: false,
      randomSport: this.getRandomSport(),
    };
  },
  created() {
    this.validationKey = this.$route.query.validationKey;
  },
  computed: {
    ...mapState(['theme']),

    passwordError() {
      const errorList = {};
      errorList['passLength'] = this.newPassword.length < 8;
      errorList['number'] = !(RegExp(/(?=.*[0-9])(?=.*[a-zA-Z])/).test(this.newPassword));

      if (errorList['length'] || errorList['number']) {
        return {isError: true, errors: errorList};
      }
      return {isError: false, errors: errorList};
    },

    confirmPasswordError() {
      return this.newConfirmPassword !== this.newPassword;
    },
  },
  methods: {
    resetPassword() {
      if (this.passwordError.isError || this.confirmPasswordError) {
        return;
      }

      const params = {
        validationKey: this.validationKey,
        newPassword: this.newPassword,
      };

      this.processingRequest = true;
      this.resetPasswordError = null;

      ObAccountApi.resetPassword(params).then((response) => {
        this.processingRequest = false;
        if (response == 'success') {
          this.succeededChange = true;
        }
      }).catch((error) => {
        this.processingRequest = false;
        if (error.response && error.response.data) {
          this.resetPasswordError = error.response.data;
        }
      });
    },

    getRandomSport() {
      const rNum = Math.floor(Math.random() * 4);
      switch (rNum) {
        case 0: return 'Hockey';
        case 1: return 'Football';
        case 2: return 'Baseball';
        case 3: return 'Basketball';
        default: return 'Football';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/fonts.scss";

  .changePassContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
  }

  .changePassHeader {
    text-align: center;
    color: var(--obcolor-font-primary);
    font-size: 24px;
    padding-bottom: 10px;
    white-space: nowrap;
  }

  .ob-box {
    padding: 10px;
    height: 380px;
    min-height: 380px;
    max-width: 500px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }

  .playerImgContainer {
    width: 360px;
  }

  .playerImg {
    margin: -20px 0 -20px -20px;
  }

  :not([data-theme="dark"]) .playerImg {
    background: radial-gradient(80.13% 50% at 50% 40%, rgba(0,0,0,0.3) 0%, rgba(255, 255, 255, 0) 100%);
  }

  [data-theme="dark"] .playerImg {
    background: radial-gradient(80.13% 50% at 50% 40%, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .changePassBox {
    overflow: hidden;
    height: 554px;
    width: 100%;
    margin: 0;
  }

  .changePassFormBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputBox {
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    color: $ob-color-header;
    width: 100%;
    input {
      box-sizing: border-box;
      width: 100%;
      height: 35px;
    }
  }

  .changePass {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    font-size: 20px;
  }

  .buttonList {
    margin-top: 15px;
  }

  .resetInfo {
    color: var(--obcolor-font-secondary);
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: -10px;
    font-size: 12px;
    text-align: left;
    width: 100%;
    .resetError {
      padding: 0;
    }
  }

  .resetError {
    color: var(--obcolor-red);
    margin-bottom: -23px;
  }

  .linkText {
    text-decoration: underline;
    color: var(--obcolor-ob-blue);
    cursor: pointer
  }

  .linkText:hover {
    color: var(--obcolor-accent-dark);
  }
</style>