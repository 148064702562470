<template>
  <div class="payoutReduced">
    <i class="far fa-info-circle"></i>
    <span>
      Your payouts will be different from the standard payouts because of
      this combination of players.
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
});
</script>

<style scoped>
.payoutReduced{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--obcolor-ob-blue);
  padding: 10px;
  gap: 8px;
}
</style>