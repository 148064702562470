<template>
  <div class="playerStatusContainer" :style="'height: ' + height + 'px'">
    <template v-if="status && status !== 'UNKNOWN' && status !== 'NONE'">
      <div class="statusCircle" :style="'background: ' + getStatusColor() +
        '; height: ' + (height / 1.2).toFixed(0) + 'px; width: ' + (height / 1.2).toFixed(0) + 'px;'"
      ></div>
      <div v-if="showText" class="statusText" :style="'color: ' + getStatusColor() +
        '; height: ' + height + 'px; font-size: ' + height + 'px;'"
      >
        {{ getStatusText() }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    showText: Boolean,
    height: String,
  },

  methods: {
    getStatusColor() {
      switch (this.status) {
        case 'ACT': return '#4EB959';
        case 'AVA':
        case 'TBA':
        case 'BKUP': return '#F2994A';
        case 'OUT':
        case 'DNP':
        case 'SUSP':
        case 'INJ':
        case 'MIN': return '#E5252C';
      }
      return '#E5252C';
    },
    getStatusText() {
      switch (this.status) {
        case 'ACT': return 'Active';
        case 'AVA': return 'Available';
        case 'TBA': return 'Pending';
        case 'BKUP': return 'Backup';
        case 'OUT': return 'Inactive';
        case 'DNP': return 'Did Not Play';
        case 'SUSP': return 'Suspended';
        case 'INJ': return 'Injured';
        case 'MIN': return 'Minors';
      }
      return this.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.playerStatusContainer {
  display: flex;
  align-items: center;
  height: max-content;
}
.statusCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}
.statusText {
  height: 11px;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 4px;
  font-weight: bold;
}
</style>
