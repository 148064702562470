<template>
  <div v-if="loading" class="emptyTransactions">
    <ObLoading :size="50" style="margin: -10px 0;" /><div style="margin-left: 5px;">Loading Transactions...</div>
  </div>
  <div v-else-if="transactions == null || transactions.length == 0" class="emptyTransactions">
    You have not made any transactions for this team
  </div>
  <div v-else style="margin-top: 2px;">
    <template v-for="(transaction, index) in transactions">
      <div class="transactionHeader" :key="'header_' + index">
        <div class="transactionTitle">Transaction {{ index + 1 }} {{ posLongName(getPlayer(transaction.playerDroppedId).draftPos) }}</div>
        <div style="flex: 1;"></div>
        <div class="infoBox">
          <div class="infoText">{{ $moment(transaction.timestamp).format('h:mm A') }}</div>
          <div class="infoTitle">Swap Time</div>
        </div>
        <div class="infoBox">
          <div class="infoText" :class="{ redText: transaction.swapFee > 0, greenText: transaction.swapFee < 0 }">
            {{ dollarFormat(transaction.swapFee) }}
          </div>
          <div class="infoTitle">Swap Fee</div>
        </div>
        <div class="infoBox">
          <div v-if="transaction.swapCost" class="infoText" :class="{ redText: transaction.swapCost < 0, greenText: transaction.swapCost > 0 }">
            {{ dollarFormat(transaction.swapCost) }}
          </div>
          <div v-else class="infoText">-</div>
          <div class="infoTitle">Salary Change</div>
        </div>
      </div>
      <table :key="'table_' + index" aria-label="Salary Team">
        <SalaryTeamRow :entry="getPlayer(transaction.playerDroppedId)"
          :todaysIndex="todaysIndex" :leagueId="leagueData.id" :isMyTeam="true"
          :playedGames="playedGames" :overSalaryGames="overSalaryGames" :bestBallGames="rosterCalc.bestBallGames"
          :matchup="leagueData" :sport="leagueData.sport"
          :selectedTab="selectedTab" :teamId="teamId"
          :selectedFilterIndex="selectedFilterIndex"
          transactionType="drop" :transactionTs="transaction.timestamp"
        />
        <SalaryTeamRow :entry="getPlayer(transaction.playerAddedId)"
          :todaysIndex="todaysIndex" :leagueId="leagueData.id" :isMyTeam="true"
          :playedGames="playedGames" :overSalaryGames="overSalaryGames" :bestBallGames="rosterCalc.bestBallGames"
          :matchup="leagueData" :sport="leagueData.sport"
          :selectedTab="selectedTab" :teamId="teamId"
          :selectedFilterIndex="selectedFilterIndex"
          transactionType="add" :transactionTs="transaction.timestamp"
        />
      </table>
    </template>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import SalaryTeamRow from './SalaryTeamRow';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    SalaryTeamRow,
    ObLoading,
  },

  props: {
    leagueData: Object,
    leagueRoster: Object,
    rosterCalc: Object,
    todaysIndex: Number,
    isMyTeam: Boolean,
    playedGames: Object,
    overSalaryGames: Object,
    bestBallGames: Object,
    selectedTab: String,
    selectedFilterIndex: Number,
    teamId: String,
  },

  data() {
    return {
      loading: true,
      transactions: [],
    };
  },

  created() {
    this.getTransactions();
  },

  computed: {
    playerMap() {
      if (!this.leagueRoster || !this.leagueRoster.players || !this.leagueRoster.dropped) {
        return [];
      }

      const totPlayerList = this.leagueRoster.dropped.slice().concat(this.leagueRoster.players.slice());
      const playerMap = {};
      for (const player of totPlayerList) {
        if (player.id) {
          this.$set(playerMap, player.id, player);
        }
      }

      return playerMap;
    },
  },

  methods: {
    posLongName(shortName) {
      return this.$SportInfo.getPositionName(shortName, this.leagueData.sport);
    },

    getTransactions() {
      this.loading = true;
      if (!this.leagueRoster.id || !this.leagueRoster.teamNum || !this.leagueData.id) {
        return;
      }

      const team = this.leagueRoster.id + '_' + this.leagueRoster.teamNum;
      ObSalaryCapApi.getTransactions(this.leagueData.id, team).then((response) => {
        this.transactions = response;
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
      });
    },

    calcProjSpend(player) {
      if (!player) {
        return 0;
      }
      const now = new Date().getTime();
      const games = player.fspGames;
      const deactGames = player.deactGames;
      let gp = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const ts = parseInt(gJSON.timestamp);
        const proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : 0;
        const isActive = !deactGames[gJSON.id];
        if (isActive && gJSON.inRange && ts > now && proj > 0) {
          gp++;
        }
      }
      return player.salaryPG * gp;
    },

    getPlayer(id) {
      return this.playerMap[id];
    },

    dollarFormat(money) {
      const moneyFormatted = money.formatMoney();
      if (money >= 0) {
        return moneyFormatted;
      }
      return '- ' + moneyFormatted.replace('-', '');
    },
  },
};
</script>

<style lang="scss" scoped>
  .transactionHeader {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    background: var(--obcolor-background-4);
  }

  .transactionTitle {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
  }

  .infoBox {
    padding: 10px 20px;
    text-align: right;
    font-size: 12px;
  }

  .infoText {
    height: 14px;
    font-weight: bold;
  }

  .redText {
    color: var(--obcolor-red);
  }

  .greenText {
    color: var(--obcolor-green);
  }

  table {
    text-align: center;
    width: 100%;
    // Fake height needed for inner div to have 100% height in Chrome
    // This is required for game boxes to be top-aligned (for doubleheaders)
    height: 1px;

    tr:first-child {
      background: var(--obcolor-background-6);
    }

    tr:last-child {
      background: var(--obcolor-background-5);
    }
  }

  .emptyTransactions {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    color: var(--obcolor-font-secondary);
    margin-top: 2px;
  }
</style>