<template>
  <div :style="style">
    {{ time }}
  </div>
</template>

<script>
// This component is similar to the Countdown Timer
// The format is different, it does not include seconds, #h #m
// Also, it allows for a second end value
// If the first end is past, it counts down to the second end value
export default {
  name: 'ContestCountdown',

  props: ['end', 'end2', 'min', 'minText', 'serverOffset', 'dateFormat'],

  data() {
    return {
      time: 0,
      timeout: null,
      style: '',
    };
  },

  created() {
    this.getTime();
    this.loopGetTime();
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  methods: {
    getOffset() {
      return this.serverOffset ? this.serverOffset : 0;
    },

    getTime() {
      if (this.end) {
        let endTS = this.end;
        let seconds = this.$moment(this.end + this.getOffset()).diff(this.$moment(), 'seconds');

        // If first end point is past, use 2nd end value
        if (seconds < this.min && this.end2) {
          seconds = this.$moment(this.end2 + this.getOffset()).diff(this.$moment(), 'seconds');
          endTS = this.end2;
        }

        // If less than 15 minutes (900s) remaining, highlight red
        this.style = seconds < 900 ? 'color: red;' : '';

        // Changed Oct 2020 - If more then 24 hours away, show date and time
        if (seconds > 86400) {
          const dFormat = this.dateFormat ? this.dateFormat : 'ddd MMM Do h:mma';
          this.time = this.$moment(endTS).format(dFormat);
        } else if (seconds < this.min && this.minText) {
          this.time = this.minText;
        } else {
          seconds = seconds < this.min ? this.min : seconds;
          this.time = this.formatTimeToClock(seconds);
        }
        this.$emit('tick', seconds);
      }
    },

    formatTimeToClock(seconds) {
      let minutes = Math.floor(seconds/60);
      const hours = Math.floor(minutes/60);
      minutes = minutes % 60;

      if (hours == 0 && minutes == 0) {
        return '<1m';
      }
      if (hours > 48) {
        const days = Math.floor(hours/24);
        return days + ' days';
      }
      if (hours == 0) {
        return minutes + 'm';
      }
      return hours + 'h ' + minutes + 'm';
    },

    // This timer only shows minutes, so updating every second is not required
    // Update every 10s to ensure minutes are accurate +/- 10s
    loopGetTime() {
      const self = this;
      this.timeout = setTimeout(function() {
        self.getTime();
        self.loopGetTime(10000);
      }, 10000);
    },
  },
};
</script>