// Live Summary Stats
// Controls the top overall summary for live page

// Quarterback Stats
export const LIVE_NFL_QB = [
  {
    label: 'PASSING',
    stats: [
      {label: 'YDS', path: 'passing.PASS_YDS', tooltip: 'Passing Yards', thresholds: [
        {value: 330, heat: 3}, {value: 300, heat: 2}, {value: 270, heat: 1}, {value: 240, heat: 0}, {value: 210, heat: -1}, {value: 180, heat: -2}, {value: 150, heat: -3},
      ]},
      {label: 'TD', path: 'passing.PASS_TD', tooltip: 'Passing Touchdowns', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
      ]},
      {label: 'INT', path: 'passing.PASS_INT', tooltip: 'Interceptions Thrown', thresholds: [
        {value: 0, heat: 1}, {value: 1, heat: 0}, {value: 2, heat: -1}, {value: 3, heat: -2}, {value: 4, heat: -3},
      ]},
      {label: 'SCK', path: 'sacks.SCK_A', tooltip: 'Sacks', thresholds: [
        {value: 0, heat: 2}, {value: 1, heat: 1}, {value: 2, heat: 0}, {value: 3, heat: -1}, {value: 4, heat: -2}, {value: 5, heat: -3},
      ]},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', thresholds: [
        {value: 60, heat: 3}, {value: 50, heat: 2}, {value: 40, heat: 1}, {value: 10, heat: 0}, {value: -10, heat: -1},
      ]},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered', thresholds: [
      //   {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}
      // ] },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles', thresholds: [
      //   {value: 0, heat: 0}
      // ] },
    ],
  },
];

// Running Back Stats
export const LIVE_NFL_RB = [
  {
    label: 'RUSHING',
    stats: [
      {label: 'CAR', path: 'rushing.RUS_ATT', tooltip: 'Carries', thresholds: [
        {value: 22, heat: 3}, {value: 18, heat: 2}, {value: 14, heat: 1}, {value: 10, heat: 0}, {value: 6, heat: -1}, {value: 2, heat: -2}, {value: 0, heat: -3},
      ]},
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', thresholds: [
        {value: 110, heat: 3}, {value: 95, heat: 2}, {value: 80, heat: 1}, {value: 65, heat: 0}, {value: 50, heat: -1}, {value: 35, heat: -2}, {value: 20, heat: -3},
      ]},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: 0},
      ]},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions', thresholds: [
        {value: 8, heat: 3}, {value: 6, heat: 2}, {value: 4, heat: 1}, {value: 2, heat: 0}, {value: 0, heat: -1},
      ]},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards', thresholds: [
        {value: 60, heat: 3}, {value: 50, heat: 2}, {value: 40, heat: 1}, {value: 30, heat: 0},
      ]},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards', thresholds: [
        {value: 20, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered', thresholds: [
      //   {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}
      // ] },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles', thresholds: [
      //   {value: 0, heat: 0}
      // ] },
    ],
  },
];


// Wide Receiver/Tight End Stats
export const LIVE_NFL_WR_TE = [
  {
    label: 'RECEIVING',
    stats: [
      {label: 'TAR', path: 'passing.REC_TAR', tooltip: 'Targets', thresholds: [
        {value: 12, heat: 3}, {value: 10, heat: 2}, {value: 8, heat: 1}, {value: 6, heat: 0}, {value: 4, heat: -1}, {value: 2, heat: -2}, {value: 0, heat: -3},
      ]},
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions', thresholds: [
        {value: 7, heat: 3}, {value: 6, heat: 2}, {value: 5, heat: 1}, {value: 4, heat: 0}, {value: 3, heat: -1}, {value: 2, heat: -2}, {value: 1, heat: -3},
      ]},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards', thresholds: [
        {value: 100, heat: 3}, {value: 80, heat: 2}, {value: 70, heat: 1}, {value: 60, heat: 0}, {value: 40, heat: -1}, {value: 20, heat: -2}, {value: 0, heat: -3},
      ]},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards', thresholds: [
        {value: 30, heat: 3}, {value: 20, heat: 2}, {value: 10, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards', thresholds: [
        {value: 20, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered', thresholds: [
      //   {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}
      // ] },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles', thresholds: [
      //   {value: 0, heat: 0}
      // ] },
    ],
  },
];

// Defense
export const LIVE_NFL_DEF = [
  {
    label: '',
    stats: [
      {label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles', thresholds: [
        {value: 10, heat: 3}, {value: 8, heat: 2}, {value: 6, heat: 1}, {value: 4, heat: 0}, {value: 2, heat: -1}, {value: 4, heat: -2},
      ]},
      {label: 'SCK', path: 'defense.SCK', tooltip: 'Sacks', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'INT', path: 'defense.INT', tooltip: 'Interceptions', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'PD', path: 'defense.PD', tooltip: 'Pass Deflections', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0},
      ]},
      {label: 'SFTY', path: 'defense.STY', tooltip: 'Safeties', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'FF', path: 'fumbles.FBL_F', tooltip: 'Forced Fumbles', thresholds: [
        {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1},
      ]},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards', thresholds: [
        {value: 20, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Touchdowns', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
];

// Kicking
export const LIVE_NFL_K = [
  {
    label: 'KICKING',
    stats: [
      {label: 'YDS', path: 'scoring.FG_YDS', tooltip: 'Field Goal Yards', thresholds: [
        {value: 100, heat: 3}, {value: 80, heat: 2}, {value: 60, heat: 1}, {value: 40, heat: 0}, {value: 20, heat: -1}, {value: 0, heat: -2},
      ]},
      {label: 'FGM', path: 'scoring.FGM', tooltip: 'Field Goals Made', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
      ]},
      {label: 'FGA', path: 'scoring.FGA', tooltip: 'Field Goal Attempts'},
      {label: 'XPM', path: 'scoring.EPM', tooltip: 'Extra Points Made', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 2}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
      ]},
      {label: 'XPA', path: 'scoring.EPA', tooltip: 'Extra Point Attempts'},
    ],
  },
];