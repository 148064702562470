<template>
  <div class="ob-page profilePage" v-if="profile">
    <div class="ob-box userSummaryBox">
      <!-- User Logo & Info -->
      <div class="userNameLogo">
        <UserLogo :id="userId" :size="80" />
        <div class="userNameInfo">
          <div style="display: flex; font-size: 18px; font-weight: bold; margin-bottom: 2px;">
            <div style="margin-right: 5px;">{{ profile.username }}</div>
            <i v-if="isMyProfile" class="fas fa-edit editNameBtn" @click="editProfile()"></i>
          </div>
          <div v-if="profile.name" style="color: var(--obcolor-font-secondary);">
            {{ profile.name }}
          </div>
          <div v-if="profile.email" style="color: var(--obcolor-font-secondary);">{{ profile.email }}</div>
        </div>
      </div>
      <div class="summaryBodyContainer">
        <div class="infoSection">
          <!-- Overall Rank & Progress Bar -->
          <RankLogo :size="100" :rankNum="profile.GLOBAL.rankNum" :rankColor="profile.GLOBAL.rankColor" />
          <div class="rankLabel">
            {{ profile.GLOBAL.rankName.toUpperCase() }}
            <i class="fas fa-info-circle infoBtn" @click="openRankModal()"></i>
          </div>
          <div class="rankProgressBar">
            <div class="rankProgress" :style="'width: ' + profile.GLOBAL.nextRankPct + '%'"></div>
          </div>
          <div v-if="profile.GLOBAL.nextRank" class="rankProgressLabelContainer">
            <div style="color: var(--obcolor-ob-blue);">{{ profile.GLOBAL.rankName.toUpperCase() }}</div>
            <div>{{ profile.GLOBAL.nextRank.toUpperCase() }}</div>
          </div>

          <!-- Overall Record Stats -->
          <div class="fieldListHorizontal" style="text-align: center; margin-top: 14px; padding-bottom: 5px;">
            <div>
              <div style="font-weight: bold;">
                {{ profile.GLOBAL.h2hWin || '0' }}-{{ profile.GLOBAL.h2hLoss || '0' }}
              </div>
              <div class="fieldHeading">W/L Record</div>
            </div>
            <div>
              <div style="font-weight: bold;">{{ (profile.GLOBAL.h2hWinRatio || 0).toFixed(0) + '%' }}</div>
              <div class="fieldHeading">W/L Ratio</div>
            </div>
            <div>
              <div style="font-weight: bold;">
                {{ profile.GLOBAL.L10W || '0' }}-{{ profile.GLOBAL.L10L || '0' }}
              </div>
              <div class="fieldHeading">L10 Record</div>
            </div>
          </div>
        </div>

        <template v-if="isMyProfile">
          <!-- Account Balance -->
          <div class="infoSection">
            <div class="fieldListHorizontal" style="text-align: center; justify-content: center;">
              <div>
                <div style="font-weight: bold;">{{ profile.balance || '-' }}</div>
                <div class="fieldHeading">Balance</div>
              </div>
            </div>
            <div class="fieldListHorizontal">
              <!-- Deposit and Withdraw -->
              <router-link to="/deposit" class="routerLinkButton ob-btn" style="margin-right: 10px;">
                <img style="height: 20px; margin-bottom: -4px; margin-top: -4px; margin-right: 5px;" src="@/assets/icons/dollar-deposit.png" alt="Deposit">Deposit
              </router-link>
              <router-link to="/withdraw" class="routerLinkButton ob-btn" style="margin-left: 10px;">
                <img style="height: 20px; margin-bottom: -4px; margin-top: -4px; margin-right: 5px;" src="@/assets/icons/dollar-withdraw.png" alt="Withdraw">Withdraw
              </router-link>
            </div>
          </div>

          <router-link to="/transactions" class="infoSection ob-link" style="align-items: flex-start; font-weight: bold;">
            Transaction History
          </router-link>
          <router-link to="/loginhistory" class="infoSection ob-link" style="align-items: flex-start; font-weight: bold;">
            Login History
          </router-link>
          <a href="https://support.ownersbox.com/hc/en-us" class="infoSection ob-link" style="align-items: flex-start; font-weight: bold;"
            target="_blank"
          >Help & Support</a>

          <router-link to="/responsibleplay" class="infoSection ob-link" style="align-items: flex-start; font-weight: bold;">
            Responsible Play
          </router-link>
          <div></div>
          <div class="infoSection ob-link" style="align-items: flex-start; font-weight: bold;" @click="openNotificationModal()">Notification Settings</div>
          <div style="display: flex; align-items: center; font-weight: bold; margin-right:auto;">
            <div style="margin-right: 10px;" class="logoutSection">Email Alerts</div>
            <Checkbox v-model="profile.emailAlerts.draftUpdates" type="switch" />
          </div>
          <div class="ob-link logout" style=" align-items: flex-start; font-weight: bold;" @click="logout()">Log out</div>
        </template>
      </div>
    </div>
    <div class="teamBoxColumn">
      <ProfileTeamBox class="ob-box teamBox" :sport="'NHL'" :isMyProfile="isMyProfile"
        :userId="userId" :teamInfo="profile.NHL" style="margin-top: 0px;"
      />
      <ProfileTeamBox class="ob-box teamBox" :sport="'NFL'" :isMyProfile="isMyProfile"
        :userId="userId" :teamInfo="profile.NFL"
      />
    </div>
    <div class="teamBoxColumn">
      <ProfileTeamBox class="ob-box teamBox" :sport="'MLB'" :isMyProfile="isMyProfile"
        :userId="userId" :teamInfo="profile.MLB" style="margin-top: 0px;"
      />
      <ProfileTeamBox class="ob-box teamBox" :sport="'NBA'" :isMyProfile="isMyProfile"
        :userId="userId" :teamInfo="profile.NBA"
      />
    </div>
    <ConvertCurrencyModal />
    <NotificationSettingsModal />
    <RankModal />
    <ConversionInfoModal />
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import RankLogo from '@/components/RankLogo';
import ProfileTeamBox from '@/views/Profile/ProfileTeamBox';
import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';
import ConvertCurrencyModal from '@/views/Profile/ConvertCurrencyModal';
import NotificationSettingsModal from '@/views/Profile/NotificationSettingsModal';
import RankModal from '@/views/Profile/RankModal';
import Checkbox from '@/components/Checkbox';
import ConversionInfoModal from './ConversionInfoModal';

export default {

  components: {
    UserLogo,
    ProfileTeamBox,
    RankLogo,
    ConvertCurrencyModal,
    NotificationSettingsModal,
    RankModal,
    ConversionInfoModal,
    Checkbox,
  },

  data() {
    return {
      userId: '',
      profile: null,
      isMyProfile: false,
      isEditingTeam: true,
    };
  },

  created() {
    const userId = this.$route.params.userId;
    if (userId != null) {
      this.userId = userId;
    } else {
      this.userId = this.$store.getters.userId;
    }
    this.loadProfile();

    EventBus.$on('RELOAD_PROFILE', this.loadProfile);
    EventBus.$on('RELOAD_PROFILE_PAGE', this.reloadPage);
    EventBus.$on('UPDATE_PUSH_SETTINGS', this.updatePushSettings);
  },

  destroyed() {
    EventBus.$off('RELOAD_PROFILE', this.loadProfile);
    EventBus.$off('RELOAD_PROFILE_PAGE', this.reloadPage);
    EventBus.$off('UPDATE_PUSH_SETTINGS', this.updatePushSettings);
  },

  watch: {

    '$route.params.userId'() {
      this.resetData();

      const userId = this.$route.params.userId;
      if (userId != null) {
        this.userId = userId;
      } else {
        this.userId = this.$store.getters.userId;
      }

      this.loadProfile();
    },

    'profile.emailAlerts.draftUpdates'(newVal, oldVal) {
      if (oldVal != null) {
        this.updateEmailAlerts();
      }
    },

  },

  methods: {

    resetData() {
      this.userId = null;
      this.profile = null;
    },

    reloadPage() {
      location.reload();
    },

    loadProfile() {
      ObAccountApi.getProfile(this.userId).then((response) => {
        this.isMyProfile = this.userId === this.$store.getters.userId;
        this.profile = response;
      });
    },

    logout() {
      ObAccountApi.logoutUser()
          .then((data) => {
            this.$cookie.delete('obauth');
            this.$store.commit('setLoggedOut');
            // Reset chat data when logged out
            this.$cookie.delete('expanded-open-convo-map');
            if (this.$router.currentRoute.path != '/login') {
              this.$router.push('/login');
              this.$SocketController.disconnect();
            }
          });
    },

    updateEmailAlerts() {
      let newAlerts = false;
      if (this.profile.emailAlerts) {
        newAlerts = this.profile.emailAlerts.draftUpdates || false;
      }

      ObAccountApi.updateEmailAlerts(newAlerts);
    },

    editProfile() {
      EventBus.$emit('PROFILE_EDIT_GLOBAL', this.profile.username);
    },

    openChat() {
      EventBus.$emit('CONVERSATION_OPENED', this.profile.conversationId);
    },

    openConvertCurrencyModal() {
      EventBus.$emit('CONVERT_CURRENCY_OPEN', this.profile);
    },

    openNotificationModal() {
      EventBus.$emit('NOTIFICATION_SETTINGS_OPEN', this.profile.pushSettings);
    },

    updatePushSettings(pushSettings) {
      this.profile.pushSettings = pushSettings;
    },

    openRankModal() {
      EventBus.$emit('OPEN_PROFILE_RANK_MODAL');
    },

    openConversionInfoModal() {
      EventBus.$emit('OPEN_CONVERSION_INFO_MODAL', this.profile.conversionRate);
    },
  },
};
</script>

<style lang="scss" scoped>
.profilePage {
  display: flex;
  justify-content: center;
  min-width: 1230px;
  box-sizing: border-box;
}

.routerLinkButton {
  flex: 1;
  & > button {
    width: 100%;
    box-sizing: border-box;
  }
}

.routerLinkButton:disabled img {
  filter: brightness(0.55);
}

.userSummaryBox {
  width: 360px;
  margin: 0px 10px 10px 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-5);
}

.userNameLogo {
  padding: 15px;
  display: flex;
  background: var(--obcolor-box-header);
}

.userNameInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
}

.editNameBtn {
  color: var(--obcolor-font-secondary);
  margin-left: 5px;
  cursor: pointer;
}

.editNameBtn:hover {
  color: var(--obcolor-font-primary);
}

.summaryBodyContainer {
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.infoSection {
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--obcolor-background-2);
}

.infoSection, .logoutSection {
  display: flex;
  font-size: 14px;
  text-align: left;
}

.logoutSection{
  padding: 10px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logout{
  flex-direction: row;
  padding: 10px 20px;
  display: flex;
  font-size: 14px;
  text-align: left;
  margin-top:auto;
}

.paddedSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  text-align: left;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.btnSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.rankLabel {
  color: var(--obcolor-ob-blue);
  font-size: 20px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.infoBtn {
  font-size: 16px;
  color: var(--obcolor-font-secondary);
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: var(--obcolor-font-primary);
  }
}

.rankProgressBar {
  margin-top: 10px;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--obcolor-timeline);
}

.rankProgress {
  background: var(--obcolor-ob-blue);
  border-radius: 10px;
  height: 100%;
  width: 0;
}

.rankProgressLabelContainer {
  font-size: 12px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  width: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.fieldListHorizontal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.fieldListHorizontal:not(:last-child) {
  padding-bottom: 8px;
}

.fieldHeading {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.teamBoxColumn {
  display: flex;
  flex-direction: column;
  width: 420px;
  box-sizing: border-box;
}

.teamBox {
  background: var(--obcolor-background-5);
  height: 380px;
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>