<template>
  <div class="leagueBox">
    <div style="flex: 1;">
      <div class="headerText">Completed Contests</div>
      <div style="display: flex; margin-top: 7px;">
        <select v-model="completedFilters.leagueCount" class="completedContestDropdown" style="width: 80px; margin-left: 0;">
          <option value="5">Last 5</option>
          <option value="10">Last 10</option>
          <option value="30">Last 30</option>
          <option>More</option>
        </select>
        <select v-if="completedFilters.leagueCount == 'More'" v-model="completedFilters.month" class="completedContestDropdown"
          style="width: 105px;"
        >
          <option v-for="(month, index) in getCompletedMonthFilters()" :key="index" :value="month">{{ $moment().month(month).format('MMMM') }}</option>
        </select>
        <select v-if="completedFilters.leagueCount == 'More'" v-model="completedFilters.year" class="completedContestDropdown"
          style="width: 65px;"
        >
          <option v-for="(year, index) in getCompletedYearFilters()" :key="index" :value="year">{{ year }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    completedFilters: Object,
  },

  methods: {
    getCompletedMonthFilters() {
      const january = this.$moment().month(0);
      const monthList = [];
      for (let i = 0; i < 12; i++) {
        const month = january.clone().add(i, 'months');
        monthList.push(month.month());
      }
      return monthList;
    },

    getCompletedYearFilters() {
      const curYear = this.$moment();
      const yearList = [curYear.year()];
      for (let i = curYear.year() - 1; i >= 2019; i--) {
        yearList.push(i);
      }
      return yearList;
    },
  },
};
</script>

<style lang="scss" scoped>
.leagueBox {
  padding: 11px 15px;
  background: var(--obcolor-background-3);
  height: 70px;
  box-sizing: border-box;
  font-size: 12px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.headerText {
  font-weight: bold;
  font-size: 14px;
}

.completedContestDropdown {
  margin-left: 5px;
  height: 23px;
  padding: 0 5px;
  font-size: 12px;
}

th {
  background: var(--obcolor-background-3);
  padding: 4px 5px;
  color: var(--obcolor-font-secondary);
  position: sticky;
  box-sizing: border-box;

  &:not(:first-child) {
    text-align: center;
  }

  &:first-child {
    padding-left: 15px;
  }
}
</style>