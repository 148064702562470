export const INGAME_NHL_SKATERS = [
  {
    label: 'SKATERS',
    stats: [
      {label: 'skater.G', tooltip: 'Goals', description: 'G'},
      {label: 'skater.A', tooltip: 'Assists', description: 'A'},
      {label: 'skater.SOG', tooltip: 'Shots on Goal', description: 'Shots'},
      {label: 'skater.plus_minus', tooltip: 'Plus/Minus', description: '+/-'},
    ],
  },
];

export const INGAME_NHL_GOALIES = [
  {
    label: 'GOALIES',
    stats: [
      {label: 'goalie.SV', tooltip: 'Saves', description: 'SV'},
      {label: 'goalie.SVPct', tooltip: 'Save Percentage', description: 'SV%'},
    ],
  },
];