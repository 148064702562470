export const PREGAME_NBA_PLAYER = [
  {
    label: 'STATS',
    stats: [
      {label: 'player.PTS_PG', tooltip: 'Points Per Game', description: 'Pts'},
      {label: 'player.REB_PG', tooltip: 'Rebounds Per Game', description: 'Reb'},
      {label: 'player.AST_PG', tooltip: 'Assists Per Game', description: 'Ast'},
      {label: 'player.BLK_PG', tooltip: 'Blocks Per Game', description: 'Blk'},
      {label: 'player.STL_PG', tooltip: 'Steals Per Game', description: 'Stl'},
      {label: 'player.TO_PG', tooltip: 'Turnovers Per Game', description: 'TO'},
    ],
  },
];
