<template>
  <div style="height: max-content; min-height: 100vh;">
    <div class="mobileHeader">
      <img :src="require('@/assets/obicon_white.png')" alt="OwnersBox Logo">
    </div>
    <div style="overflow-y: auto; display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
      <div class="mobileBody">
        <img class="bigLogo" :src="require('@/assets/logo_' + (theme == 'light' ? 'black' : 'white') + '.png')" alt="OwnersBox Logo">
        <h1>OwnersBox Mobile App</h1>
        <div class="bodyHeader">
          We have detected that you are on a mobile device.
          Please download the app from your app store below:
        </div>
        <div style="display: flex;">
          <a href="https://play.google.com/store/apps/details?id=com.ownersbox.android" target="_blank" style="height: 40px; margin-right: 10px;">
            <img class="downloadLink" :src="require('@/assets/disclaimer/googleLogo.png')" style="height: 100%;"
              alt="Google Store Download Link"
            >
          </a>
          <a href="https://apps.apple.com/us/app/id1513599602" target="_blank" style="height: 40px;">
            <img class="downloadLink" :src="require('@/assets/disclaimer/appleLogo.png')" style="height: 100%;"
              alt="Apple Store Download Link"
            >
          </a>
        </div>

        <div style="display: flex; align-items: center;">
          <img :src="require('@/assets/disclaimer/canadaLeaf.png')" style="margin-right: 5px;" alt="Maple Leaf">
          <div style="font-weight: bold;">Canadian Android Users</div>
        </div>
        <router-link to="/androidapk">
          <img style="height: 40px;" :src="require('@/assets/mobileandroidapk/androidButton.png')" alt="Android Link">
        </router-link>

        <div class="bodyHeader" style="margin-top: 15px; color: var(--obcolor-NBA);">
          OR visit ownersbox.com on a desktop computer for the extended user experience
        </div>

        <img style="width: 100%;" :src="require('@/assets/disclaimer/floatingPhones.png')" alt="Floating Phones">
      </div>
    </div>
    <MobileAppRedirectModal />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import MobileAppRedirectModal from '@/views/MobileAndroidApk/MobileAppRedirectModal';

export default {
  metaInfo: {
    title: 'OwnersBox Mobile App',
    meta: [
      {name: 'description', content: 'Download the official OwnersBox App and play fantasy sports on the go!'},
      {name: 'og:description', content: 'Download the official OwnersBox App and play fantasy sports on the go!'},
    ],
  },
  components: {
    MobileAppRedirectModal,
  },

  computed: {
    ...mapState(['theme']),
  },

  mounted() {
    EventBus.$emit('OPEN_MOBILE_APP_REDIRECT_MODAL');
  },
};
</script>

<style lang="scss" scoped>
.mobileHeader {
  width: 100%;
  box-sizing: border-box;
  height: 27px;
  border-radius: 0 0 30px 30px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background: var(--obcolor-background-5);

  > img {
    height: 30px;
    padding: 8px 5px;
    border-radius: 0 0 10px 10px;
    background: black;
  }
}

.mobileHeader, .mobileHeader > img {
  border-bottom: 3px solid var(--obcolor-background-2);
  border-left: 3px solid var(--obcolor-background-2);
  border-right: 3px solid var(--obcolor-background-2);
}

.mobileBody {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;

  > div, > img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > img {
    max-width: 260px;
  }
}

.bigLogo {
  width: 100%;
}

.bodyHeader {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
}

.bodyText {
  font-size: 14px;
  text-align: center;
}

.bodyText.greyText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
}

.pageSeparator {
  width: 100%;
  box-sizing: border-box;
  background: var(--obcolor-background-4);
  border-top: 3px solid var(--obcolor-background-2);
  border-bottom: 3px solid var(--obcolor-background-2);
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.downloadLink {
  border: 1px solid var(--obcolor-background-1);
  border-radius: 8px;
}
</style>