<template>
  <!-- Success Verification -->
  <div class="verifyBody" style="flex-direction: row;" v-if="loading || stateLoading">
    <ObLoading :size="60" />
    Loading Account Info
  </div>
  <div class="verifyBody" v-else-if="isDepositVerified === true">
    <i class="fas fa-check-circle" style="font-size: 62px; color: limegreen;"></i>
    <div class="statusLabel">VERIFIED</div>
    <div class="statusText">Your account has been Verified, you can now deposit funds and enter real-money contests!</div>
    <button v-if="isFullPage" @click="closeComponent()" class="ob-btn largeButton">
      Continue
    </button>
  </div>

  <!-- Failed Verification -->
  <div class="verifyBody" v-else-if="isDepositVerified == false && !evsCheckSSN">
    <i class="fas fa-times-circle" style="font-size: 62px; color: red;"></i>
    <div class="statusLabel" style="font-weight: bold;">VERIFICATION FAILED</div>
    <div>
      Please contact <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com/hc/en-us/requests/new">customer service</a>
      to verify your identity in order to proceed.
    </div>
    <div style="margin-top: 5px;">
      You will not be able to deposit or play real money contests until you verify your identity.
    </div>
    <div style="margin-top: 15px;">
      If you need additional help, please visit
      <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com">support.ownersbox.com</a>
    </div>

    <router-link v-if="verifyStatus === 'IDPV_VERIFY'" class="ob-btn largeButton" to="/verifyAccountById">Continue to Scan ID</router-link>
    <router-link v-else-if="isFullPage" to="/lobby" class="ob-btn largeButton">
      Back to Lobby
    </router-link>
  </div>

  <!-- Verify Form -->
  <div class="verifyBody" v-else-if="isDepositVerified == null || evsCheckSSN == true">
    <div v-if="!evsCheckSSN" style="font-size: 14px;">
      Before you can participate in real-money contests, we must verify your identity.
      Ensure your information is correct. If you are having trouble verifying your account, please contact
      <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com/hc/en-us/requests/new">customer service</a>
    </div>
    <div v-else>
      <div style="display: flex; align-items: center; justify-content: center;">
        <i class="fas fa-times-circle" style="font-size: 30px; color: red; margin-right: 10px;"></i>
        <div class="statusLabel" style="font-weight: bold;">Verification Error</div>
      </div>
      <div style="font-size: 14px;">
        Some of your information did not match, please enter the last 4-digits of your SSN for further verification.
        If you need additional assistance, contact <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com/hc/en-us/requests/new">customer service</a>
      </div>
    </div>
    <div class="formRow">
      <div class="formField inputContainer">
        <div>Legal First Name</div>
        <input v-model="firstName" name="given-name">
      </div>
      <div class="formField inputContainer">
        <div>Legal Last Name</div>
        <input v-model="lastName" name="family-name">
      </div>
    </div>
    <div class="formRow">
      <div class="formField inputContainer">
        <div>Date of Birth</div>
        <div class="dobInput" style="display: flex; margin-left: -2px; margin-right: -2px;">
          <select v-model="dobMonth" style="flex: 1;">
            <option value="" selected>Month</option>
            <option v-for="mOffset in 12" :key="mOffset" :value="mOffset">{{ $moment().month(0).add(mOffset - 1, 'months').format('MMMM') }}</option>
          </select>
          <select v-model="dobDay" style="flex: 1; margin-right: 5px; margin-left: 5px;">
            <option value="" selected>Day</option>
            <option v-for="day in 31" :key="day">{{ day }}</option>
          </select>
          <select v-model="dobYear" style="flex: 1;">
            <option value="" selected>Year</option>
            <option v-for="yOffset in 100" :key="yOffset">{{ $moment().subtract(yOffset, 'years').format('YYYY') }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="formRow">
      <div class="formField inputContainer">
        <div>Billing Address</div>
        <input v-model="address" name="street-address">
      </div>
      <div class="formField inputContainer" style="min-width: 185px; max-width: 185px;">
        <div>Country</div>
        <select v-model="country">
          <option value="-1" disabled selected></option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
        </select>
      </div>
    </div>
    <div class="formRow">
      <div class="formField inputContainer">
        <div>City</div>
        <input v-model="city">
      </div>
      <div class="formField inputContainer">
        <div>{{ country == 'CA' ? 'Province' : 'State' }}</div>
        <select v-model="region" :disabled="country === '-1'">
          <option value="-1" disabled selected></option>
          <option v-for="(regionVal, index) in regionList" :value="regionVal.name" :key="index">
            {{ regionVal.name }}
          </option>
        </select>
      </div>
      <div class="formField inputContainer" style="min-width: 185px; max-width: 185px;">
        <div>Postal / Zip Code</div>
        <input v-model="postalCode" name="postal-code">
      </div>
    </div>
    <div v-if="evsCheckSSN" class="formRow" :class="{ formRowDisabled: country == 'CA' }">
      <div class="formField inputContainer">
        <div>SSN (Last 4 Digits)</div>
        <input v-model="ssn"
          v-cleave="{ numeral: true, numeralThousandsGroupStyle: 'none',
                      numeralPositiveOnly: true, numeralDecimalScale: 0,
          }" name="ssn" placeholder="0000"
          :disabled="country == 'CA'"
        >
      </div>
    </div>
  </div>
</template>

<script>
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import {mapState} from 'vuex';

export default {
  components: {
    ObLoading,
  },

  props: {
    isVerifyPage: Boolean,
  },

  data() {
    return {
      loading: false,
      stateLoading: false,
      isDepositVerified: false,
      evsCheckSSN: false,
      firstName: '',
      lastName: '',
      dobYear: this.$moment().subtract(1, 'years').format('YYYY'),
      dobMonth: 1,
      dobDay: 1,
      address: '',
      city: '',
      region: '-1',
      country: '-1',
      postalCode: '',
      ssn: null,
      verifyStatus: null,
    };
  },

  created() {
    this.openComponent();
    EventBus.$on('VERIFY_ACCOUNT', this.verifyAccount);
  },

  destroyed() {
    EventBus.$off('VERIFY_ACCOUNT', this.verifyAccount);
  },

  computed: {
    ...mapState(['userId']),

    regionList() {
      return this.$GeographicInfo.getGeographicInfo(this.country);
    },

    dateOfBirth() {
      return this.dobYear + '-' + this.dobMonth + '-' + this.dobDay;
    },

    canVerify() {
      return this.firstName != '' && this.lastName != '' && this.address != '' &&
        this.country != '-1' && this.region != '-1' && this.city != '' &&
        this.postalCode != '' && this.dobMonth != '' && this.dobDay != '' &&
        this.dobYear != '';
    },

    isFullPage() {
      return this.$route.name != 'welcome';
    },
  },

  watch: {
    canVerify(newVal, oldVal) {
      if (newVal !== null) {
        EventBus.$emit('SET_VERIFY_CAN_VERIFY', newVal);
      }
    },

    country(newVal, oldVal) {
      if (oldVal != '-1') {
        this.region = '-1';
      }
    },

    ssn(newVal, oldVal) {
      if (String(newVal).length > 4) {
        this.ssn = oldVal;
      }
    },
  },

  methods: {
    openComponent() {
      this.loading = true;
      EventBus.$emit('SET_VERIFY_IS_LOADING', true);
      this.getVerifyState();

      ObPaymentApi.getVerifyUserInfo()
          .then((response) => {
            if (response.isDepositVerified !== true) {
              this.firstName = response.firstname;
              this.lastName = response.lastname;
              this.country = response.country;
              this.region = response.region;

              if (response.dob) {
                this.dobYear = this.$moment(response.dob, 'YYYY-MM-DD').year();
                this.dobMonth = this.$moment(response.dob, 'YYYY-MM-DD').month() + 1;
                this.dobDay = this.$moment(response.dob, 'YYYY-MM-DD').date();
              }
            }

            this.isDepositVerified = response.isDepositVerified;
            this.setSSNVerification(response.evsCheckSSN);
            this.setIsVerified(response.isDepositVerified);
            EventBus.$emit('SET_VERIFY_CAN_VERIFY', this.canVerify);

            this.loading = false;
            EventBus.$emit('SET_VERIFY_IS_LOADING', false);
          }).catch((_error) => {
            this.loading = false;
            EventBus.$emit('SET_VERIFY_IS_LOADING', false);
          });
    },

    getVerifyState() {
      this.stateLoading = true;
      ObPaymentApi.getVerifyState()
          .then((response) => {
            this.verifyStatus = response.state;
          }).catch((_error) => {
            // Do nothing
          }).finally(() => {
            this.stateLoading = false;
          });
    },

    setIsVerified(isVerified) {
      EventBus.$emit('SET_VERIFY_IS_VERIFIED', isVerified);
      this.$store.commit('setIsDepositVerified', isVerified);
    },

    setSSNVerification(isSSN) {
      this.evsCheckSSN = isSSN;
      EventBus.$emit('SET_VERIFY_IS_CHECK_SSN', isSSN);
    },

    verifyAccount() {
      EventBus.$emit('SET_VERIFY_IS_SENDING', true);
      EventBus.$emit('SET_VERIFY_ERROR_MESSAGE', '');

      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        street: this.address,
        city: this.city,
        country: this.country,
        dateOfBirth: this.dateOfBirth,
      };

      if (this.country == 'US') {
        params.zipCode = this.postalCode;
        params.state = this.region;
      } else {
        params.postalCode = this.postalCode;
        params.province = this.region;
      }

      if (this.ssn && this.country == 'US') {
        params.ssn = parseInt(this.ssn);
      }

      ObPaymentApi.verifyAccount(params)
          .then((response) => {
            EventBus.$emit('SET_VERIFY_IS_SENDING', false);

            if (response === 'Verification Failed') {
              this.isDepositVerified = false;
              this.setSSNVerification(false);
              this.setIsVerified(false);
              this.$Analytics.trackVerify(this.userId, false);
              return;
            }

            if (response == 'CheckSSN') {
              this.isDepositVerified = false;
              this.setSSNVerification(true);
              this.setIsVerified(false);
              this.$Analytics.trackVerifyCheckSSN(this.userId);
            } else if (response != 'Pass') {
              EventBus.$emit(
                  'SET_VERIFY_ERROR_MESSAGE',
                  'Verification encountered an error. Please contact customer service.',
              );
              this.setSSNVerification(false);
              this.$Analytics.trackVerify(this.userId, false);
              return;
            } else {
              this.isDepositVerified = true;
              this.setIsVerified(true);
              this.$Analytics.trackVerify(this.userId, true);
            }

            EventBus.$emit('UPDATE_ACCOUNT_INFO');
          })
          .catch((error) => {
            EventBus.$emit('SET_VERIFY_ERROR_MESSAGE', error.response.data);
            EventBus.$emit('SET_VERIFY_IS_SENDING', false);
          });
    },

    closeComponent() {
      if (this.isDepositVerified === true) {
        // User is verified, recheck location
        EventBus.$emit('DEPOSIT_CHECK_LOCATION');
      }
      EventBus.$emit('CLOSE_VERIFY_COMPONENT');
    },

  },
};
</script>

<style lang="scss" scoped>
.verifyBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.statusLabel {
  font-size: 18px;
  margin: 15px 0;
}

.statusText {
  font-size: 14px;
  width: 470px;
  text-align: center;

  > * {
    display: inline;
  }
}

.formRow {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
}

.formRowDisabled {
  cursor: not-allowed;
}

.formRowDisabled * {
  color: var(--obcolor-font-secondary);
  cursor: not-allowed;
}

.formField {
  width: 100%;
  box-sizing: border-box;

  > * {
    width: 100%;
    box-sizing: border-box;
  }
}

.inputContainer {
  background: var(--obcolor-input-box);
  height: 60px;
  border-radius: 6px;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  margin-top: 3px;

  input {
    margin-left: -5px;
    margin-top: 2px;
    padding-bottom: 0;
    width: 100%;
    height: 29px;
  }
  select {
    margin-top: 4px;
    width: 100%;
    height: 29px;
  }
}

.formField:not(:last-child) {
  margin-right: 10px;
}

.dobInput {
  select {
    margin-top: 5px;
    background: var(--obcolor-background-4);
  }
}

.largeButton {
  margin-top: 15px;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>