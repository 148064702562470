<template>
  <div style="height: 58px;">
    <div class="autopickBox">
      <div style="margin-right: 20px;">Auto Pick</div>
      <div class="ob-btnList" style="font-size: 10px;">
        <div class="ob-btn autoPickOff" :class="{selectedBtn: !autoPickOn}"
          @click="changeAutopick(false)"
        >
          OFF
        </div>
        <div class="ob-btn autoPickOn" :class="{selectedBtn: autoPickOn}"
          @click="changeAutopick(true)"
        >
          ON
        </div>
      </div>
      <div v-if="turnsUntilMine != null" class="turnsUntilMine">
        <div v-if="turnsUntilMine == 0" style="color: var(--obcolor-ob-blue);">
          {{ turnText }}
        </div>
        <div v-else :style="turnsUntilMine < 2 ? 'color: red;' : ''">
          {{ turnText }}
        </div>
      </div>
    </div>
    <div class="nextPick">
      Your next autopick:
      <span>{{ getNextAutopick() }}</span>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  name: 'AutoPick',
  props: {
    draftJSON: Object,
    autoPickOn: Boolean,
    teamRosters: Object,
    pickQueue: Object,
    players: Array,
    curUser: String,
  },

  data: function() {
    return {
      turnsUntilMine: null,
      turnText: '',
    };
  },

  computed: {
    ...mapState(['draftPriority']),
  },

  watch: {
    draftJSON(to, from) {
      this.calcTurnsUntilMine();
    },
  },

  created() {
    this.calcTurnsUntilMine();
  },

  methods: {
    changeAutopick(autoPick) {
      EventBus.$emit('QUEUE_AUTOPICK_CHANGED', autoPick);
    },

    calcTurnsUntilMine() {
      if (this.draftJSON.state === 'COMPLETE') {
        this.turnsUntilMine = null;
        this.turnText = '';
        return;
      }
      let turns = 0;
      const picksArray = this.draftJSON.picks;
      let curTurn = this.draftJSON.overall;
      let started = true;
      // Before Start: You Pick 2nd
      // After Start Your Pick in 1

      // Draft hasnt started yet, therefore it is 1 turn until the first pick
      if (curTurn == null) {
        curTurn = 1;
        turns = 1;
        started = false;
      }

      for (let i = curTurn-1; i < picksArray.length; i++) {
        if (picksArray[i].userId === this.curUser) {
          this.turnsUntilMine = turns;
          if (started) {
            if (turns === 0) {
              this.turnText = 'Your Pick';
            } else if (turns === 1) {
              this.turnText = 'Your Pick Next';
            } else {
              this.turnText = 'Your Pick in ' + turns;
            }
          } else {
            this.turnText = 'You Pick ' + this.addOrdinalSuffix(turns);
          }
          return;
        } else {
          turns++;
        }
      }
      this.turnsUntilMine = null;
      this.turnText = '';
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    getOpenPositons() {
      const validPositions = [];
      if (this.teamRosters != null) {
        const myRoster = this.teamRosters[this.curUser];
        for (const rosterIndex in myRoster) {
          const posGroup = myRoster[rosterIndex];
          if (posGroup.num > posGroup.players.length) {
            validPositions.push(posGroup.short);
          }
        }
      }
      return validPositions;
    },

    getNextAutopick() {
      // Determine open positions
      const openPositions = this.getOpenPositons();
      const picked = this.draftJSON.pickedPlayers;

      // Check for valid pick in Queue
      if (this.pickQueue != null && this.pickQueue.players != null) {
        for (const qIndex in this.pickQueue.players) {
          const p = this.pickQueue.players[qIndex];
          if (openPositions.includes(p.draftPos)) {
            if (picked[p.id] == null) {
              return p.name.shortName();
            }
          }
        }
      }

      // Determine if draft priority needs to apply, only if its the users first pick (and no queue is set)
      let firstPickPosition = null;
      if (!this.hasMadeFirstPick()) {
        const draftPriorityPos = this.draftPriority ? this.draftPriority[this.draftJSON.sport] : '';
        if (draftPriorityPos && draftPriorityPos !== '' && openPositions.includes(draftPriorityPos)) {
          firstPickPosition = draftPriorityPos;
        }
      }

      // Check for next valid pick in player list
      if (this.players != null) {
        for (const pIndex in this.players) {
          const p = this.players[pIndex];

          // First pick uses the draft priority position, if set
          if (firstPickPosition !== null && firstPickPosition !== p.draftPos) {
            continue;
          }

          if (openPositions.includes(p.draftPos) && picked[p.id] == null) {
            return p.name.shortName();
          }
        }
      }
      return '-';
    },

    hasMadeFirstPick() {
      const picksArray = this.draftJSON.picks;
      for (const pick of picksArray) {
        if (pick.userId === this.curUser && pick.round === 1 && pick.player) {
          return true;
        }
        if (pick.round > 1) {
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.autopickBox {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px;
}

.nextPick {
  text-align: center;
  font-size: 14px;
  padding: 0px;
  color: var(--obcolor-font-secondary);
  margin-top: 1px;
  span {
    color: var(--obcolor-font-primary);
  }
}

.autoPickOn {
  background: var(--obcolor-background-2) !important;
  color: var(--obcolor-font-primary) !important;
}

.autoPickOn.selectedBtn, .autoPickOn:hover {
  background: var(--obcolor-ob-blue) !important;
  color: white !important;
}

.autoPickOff {
  color: var(--obcolor-font-primary) !important;
  background: var(--obcolor-background-2) !important;
}

.autoPickOff.selectedBtn, .autoPickOff:hover {
  background: var(--obcolor-background-7) !important;
}

.turnsUntilMine {
  text-align: right;
  flex: 1;
}
</style>