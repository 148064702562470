<template>
  <div class="ob-page">
    <Modal v-if="modalOpen" v-model="modalOpen"
      containerStyle="height: 400px; max-height: calc(100% - 60px); width: 600px; max-width: calc(100% - 60px); padding: 10px; overflow: hidden"
    >
      <div slot="body">
        <div class="logoContainer">
          <div class="presetLogo" v-for="n in 18" :key="n">
            <img :src="require('@/assets/userlogos/PresetLogo_'+ n +'.png')" alt="User Logo" class="teamImage"
              :style="n === selectedPreset ? 'border: 4px solid var(--obcolor-ob-blue);' : 'border: 4px solid var(--obcolor-background-6)'"
              @click="selectedPreset = n"
            >
          </div>
        </div>
      </div>
      <div slot="footer" style="display: flex;">
        <div style="flex: 1;"><div @click.stop="modalOpen = false" class="ob-btn-grey">Cancel</div></div>
        <div><div @click="savePresetLogo()" class="ob-btn">Save</div></div>
      </div>
    </Modal>

    <div class="ob-subheading">Account Information</div>
    <!-- User -->
    <div class="ob-box" style="font-size: 12px; text-align: center;">
      <div class="ob-subheading">Display Name</div>
      <div class="logoBox">
        <UserLogo v-if="prevImage['user'] == null" :id="userId" :size="70"
          :borderWidth="0"
        />
        <img v-else :src="prevImage['user']" alt="User Logo"
          class="teamImage"
        >
      </div>
      <button @click.stop="openLogoModal('')" class="ob-btn" style="width: 120px; margin-bottom: 8px;">Choose Preset</button>
      <div class="imageUploadBox">
        <input type="file" accept="image/*" @change="setupImage($event, 'user')">
        <button :class="prevImage['user'] == null ? 'ob-btn-grey' : 'ob-btn'" @click="uploadImage('user')">Upload</button>
      </div>
      <form @submit.prevent="updateUsername()">
        <input v-model="userName" type="text">
        <button class="ob-btn">Rename</button>
      </form>
    </div>

    <!-- Teams -->
    <div class="teamsFlex">
      <div v-for="sport in sports" :key="sport" class="ob-box sportBox">
        <div class="ob-subheading">{{ sport }} Team</div>
        <div class="logoBox">
          <UserLogo v-if="prevImage[sport] == null" :id="userId" :sport="sport"
            :size="70" :borderWidth="0"
          />
          <img v-else :src="prevImage[sport]" alt="User Logo"
            class="teamImage"
          >
        </div>
        <button @click.stop="openLogoModal(sport)" class="ob-btn" style="width: 120px; margin-bottom: 8px;">Choose Preset</button>
        <div class="imageUploadBox">
          <input type="file" accept="image/*" @change="setupImage($event, sport)">
          <button :class="prevImage[sport] == null ? 'ob-btn-grey' : 'ob-btn'" @click="uploadImage(sport)">Upload</button>
        </div>
        <form @submit.prevent="updateTeam(sport)">
          <input v-model="teams['teamName' + sport]" type="text">
          <button class="ob-btn">Rename</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import ObAccountApi from '@/api/ObAccountApi';
import Modal from '@/components/Modal';

export default {
  name: 'Account',

  data: function() {
    return {
      sports: ['NHL', 'MLB', 'NFL', 'NBA'],
      userId: '',
      userName: '',
      teams: {},
      imageInput: {user: null, NHL: null, NBA: null, NFL: null, MLB: null},
      prevImage: {user: null, NHL: null, NBA: null, NFL: null, MLB: null},

      modalOpen: false,
      selectPresetSport: '',
      selectedPreset: null,
    };
  },

  components: {
    UserLogo,
    Modal,
  },

  created() {
    this.loadData();
  },

  watch: {
    userName(newVal) {
      if (newVal.length > 20) {
        this.userName = newVal.slice(0, 20);
      }
    },
    'teams.teamNameNHL'(newVal) {
      if (newVal.length > 20) {
        this.teams.teamNameNHL = newVal.slice(0, 20);
      }
    },
    'teams.teamNameMLB'(newVal) {
      if (newVal.length > 20) {
        this.teams.teamNameMLB = newVal.slice(0, 20);
      }
    },
    'teams.teamNameNFL'(newVal) {
      if (newVal.length > 20) {
        this.teams.teamNameNFL = newVal.slice(0, 20);
      }
    },
    'teams.teamNameNBA'(newVal) {
      if (newVal.length > 20) {
        this.teams.teamNameNBA = newVal.slice(0, 20);
      }
    },
  },

  methods: {
    openLogoModal(sport) {
      this.selectPresetSport = sport;
      this.modalOpen = true;
    },

    savePresetLogo() {
      const logo = 'PresetLogo_' + this.selectedPreset +'.png';
      ObAccountApi.selectPresetLogo(this.selectPresetSport, logo)
          .then((data) => {
            this.modalOpen = false;
            const sp = this.selectPresetSport ? this.selectPresetSport : 'user';
            this.prevImage[sp] = require('@/assets/userlogos/' + logo);
          })
          .catch((_error) => {
            alert('Error setting logo');
          });
    },

    setupImage(e, sport) {
      const img = e.target.files[0];
      this.imageInput[sport] = img;
      if (img != null) {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = (e) =>{
          this.prevImage[sport] = e.target.result;
        };
      } else {
        this.prevImage[sport] = null;
      }
    },

    uploadImage(sport) {
      const img = this.imageInput[sport];
      if (img == null) {
        return;
      }
      if (sport === 'user') {
        sport = null;
      }
      ObAccountApi.uploadUserLogo(sport, img)
          .then((data) => {
            alert(data);
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    updateTeam(sport) {
      const name = this.teams['teamName' + sport];
      if (name == null) {
        return;
      }
      ObAccountApi.setTeamName(sport, name)
          .catch((_error) => {
            // Do nothing
          });
    },

    updateUsername() {
      const name = this.userName;
      if (name == null || name.trim().length == 0) {
        return;
      }
      ObAccountApi.setUserName(name)
          .catch((_error) => {
            // Do nothing
          });
    },

    loadData() {
      ObAccountApi.loadUserData()
          .then((data) => {
            this.teams = data.teams;
            this.userId = data.id;
            this.userName = data.username;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-subheading {
  margin-bottom: 8px;
}
.teamsFlex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: -8px;
  margin-right: -8px;
}
.sportBox {
  flex-basis: 45%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 15px;
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
}
.teamImage {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.logoBox {
  padding: 10px;
}
.ob-btn {
  width: 75px;
}
.ob-btn-grey {
  width: 75px;
}
input {
  margin-right: 10px;
  width: 210px;
  font-size: 12px;
  padding: 5px;
}
.imageUploadBox {
  margin-bottom: 5px;
}
.logoContainer {
  display: flex;
  flex-wrap: wrap;
  .presetLogo {
    margin: 8px;
    flex: 1;
    img {
      cursor: pointer;
    }
  }
}
</style>