<template>
  <div class="ob-page" style="display: flex;">
    <div style="flex: 1 1 0%; min-width: 1000px; max-width: 1070px;">
      <div class="completedHeaderContainer">
        <DashboardCompletedHeader :completedFilters="completedFilters" />
      </div>
      <DashboardCompletedView style="position: relative; z-index: 81;"
        :completedFilters="completedFilters" :completedLeagues="completedLeagues" :loaded="loadedCompleted"
        :teamData="teamData" :contestType="contestType"
      />
    </div>
    <FeedSection />
    <ContestWonModal :completedContests="completedLeagues" />
  </div>
</template>

<script>
import ObLeagueApi from '@/api/ObLeagueApi';
import DashboardCompletedHeader from './CompletedContests/DashboardCompletedHeader';
import DashboardCompletedView from './CompletedContests/DashboardCompletedView';
import ContestWonModal from '@/components/modals/ContestWonModal/ContestWonModal';
import {mapState} from 'vuex';
import FeedSection from '@/components/FeedSection.vue';

export default {
  components: {
    DashboardCompletedHeader,
    DashboardCompletedView,
    ContestWonModal,
    FeedSection,
  },

  props: {
    contestType: {type: String, default: null},
  },

  data() {
    return {
      leagues: null,
      featured: null,
      userId: '',
      completedFilters: {leagueCount: '5', month: this.$moment().month(), year: this.$moment().year(), leagueType: null},
      completedLeagues: [],
      teamData: {},
      loadedCompleted: false,
    };
  },

  created() {
    this.loadMyLeagues();
    this.completedFilters.leagueType = this.contestType;
    this.getCompletedLeagues();
  },

  watch: {
    'completedFilters.leagueCount'(newVal) {
      this.getCompletedLeagues();
    },
    'completedFilters.month'(newVal) {
      this.getCompletedLeagues();
    },
    'completedFilters.year'(newVal) {
      this.getCompletedLeagues();
    },
  },

  computed: {
    ...mapState(['theme']),
  },

  methods: {
    getCompletedLeagues() {
      this.loadedCompleted = false;
      if (this.completedFilters.leagueCount == 'More') {
        ObLeagueApi.getCompletedLeagues(this.completedFilters.year, this.completedFilters.month + 1)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      } else {
        ObLeagueApi.getLatestCompletedLeague(this.completedFilters.leagueCount)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      }
    },

    loadMyLeagues() {
      ObLeagueApi.loadMyLeagues4()
          .then((data) => {
            this.leagues = data.leagues || [];
            this.featured = data.featured || [];
            this.teamData = data.teamData;
          })
          .catch((_error) => {
            // Do nothing
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.completedHeaderContainer {
  transition: top 0.3s ease-out;
  position: sticky;
  z-index: 82;
  padding-bottom: 3px;
  background: var(--obcolor-background-page);
}

.noContests {
  display: flex;
  background: var(--obcolor-background-6);
  margin-bottom: 4px;
  overflow: hidden;
  height: max-content;
  padding: 28px 15px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}
.noContests:last-child  {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
</style>