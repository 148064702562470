// Matchup Summary Stats
// Controls the top overall summary for matchup page
// NBA has 1 category for summary

// Matchup summary
export const MATCHUP_NBA = [
  {
    label: 'Stats',
    stats: [
      {label: 'PTS', path: 'player.PTS', tooltip: 'Points'},
      {label: 'AST', path: 'player.AST', tooltip: 'Assists'},
      {label: 'REB', path: 'player.REB', tooltip: 'Rebounds'},
      {label: 'BLK', path: 'player.BLK', tooltip: 'Blocks'},
      {label: 'STL', path: 'player.STL', tooltip: 'Steals'},
      {label: 'TO', path: 'player.TO', tooltip: 'Turn Overs'},
    ],
  },
];