<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="height: 800px; max-height: calc(100% - 60px); width: 1070px; max-width: calc(100% - 60px); padding: 10px; overflow: hidden"
    bodyStyle="margin: 0 -10px -10px -10px; padding: 10px 10px; background: var(--obcolor-background-page);"
    zIndex="9999"
    @scrollBody="checkScrollPosition"
  >
    <!-- playerSummary != null && playerDetails != null -->
    <template v-if="playerSummary != null || playerDetails != null">
      <!-- Player Summary -->
      <div class="ob-box summaryContainer" slot="header">
        <PlayerCardSummary v-if="playerSummary != null || playerDetails != null" :playerSummary="playerSummary" :playerDetails="playerDetails"
          :isDraft="isDraft" :draftState="draftState" :sport="sport"
        />
        <div class="navigationMenu">
          <div v-if="hasNextGame()" class="navBtn" :class="{'navSelected': refScrolledTo == 'nextgame'}"
            @click="scrollToComponent('nextgame', 'smooth')"
          >
            NEXT GAME
          </div>
          <div v-if="isRefVisible('schedule')" class="navBtn" :class="{'navSelected': refScrolledTo == 'schedule'}"
            @click="scrollToComponent('schedule', 'smooth')"
          >
            SCHEDULE
          </div>
          <div v-if="playerSummary != null" class="navBtn" :class="{'navSelected': refScrolledTo == 'projection'}"
            @click="scrollToComponent('projection', 'smooth')"
          >
            PROJECTION
          </div>
          <div v-if="playerDetails && playerDetails.news" class="navBtn" :class="{'navSelected': refScrolledTo == 'news'}"
            @click="scrollToComponent('news', 'smooth')"
          >
            NEWS
          </div>
          <div v-if="playerDetails && playerDetails.gamelog" class="navBtn" :class="{'navSelected': refScrolledTo == 'previousGames'}"
            @click="scrollToComponent('previousGames', 'smooth')"
          >
            GAMELOG
          </div>
          <div v-if="playerDetails && playerDetails.statistics" class="navBtn" :class="{'navSelected': refScrolledTo == 'season'}"
            @click="scrollToComponent('season', 'smooth')"
          >
            SEASON
          </div>
          <div v-if="playerDetails && playerDetails.scoutReport" class="navBtn" :class="{'navSelected': refScrolledTo == 'scouting'}"
            @click="scrollToComponent('scouting', 'smooth')"
          >
            SCOUTING
          </div>
          <div v-else-if="playerDetails && playerDetails.bio" class="navBtn" :class="{'navSelected': refScrolledTo == 'scouting'}"
            @click="scrollToComponent('scouting', 'smooth')"
          >
            INFO
          </div>
        </div>
      </div>
      <div slot="body" style="margin-top: -10px; width: 100%;" v-if="playerDetails">
        <!-- Player Next Game -->
        <PlayerCardNextGame ref="nextgame" v-if="isRefVisible('nextgame') && hasNextGame()" class="ob-box ob-scroll infoContainer"
          :playerDetails="playerDetails" :sport="sport"
        />
        <!-- Schedule, Only when not in draft -->
        <PlayerCardSchedule ref="schedule" v-if="isRefVisible('schedule')" class="ob-box ob-scroll infoContainer"
          :playerId="playerId" :leagueId="leagueId" :sport="sport"
          :isScContest="isScContest"
        />
        <!-- Player Games & Projections -->
        <PlayerCardGames ref="projection" v-if="isRefVisible('projection') && playerSummary.gamesByWeek" class="ob-box ob-scroll infoContainer"
          :playerSummary="playerSummary" :playerDetails="playerDetails" :sport="sport"
        />
        <!-- Player Latest News -->
        <PlayerCardNews ref="news" v-if="isRefVisible('news')" class="ob-box ob-scroll infoContainer"
          :playerDetails="playerDetails" :sport="sport"
        />
        <!-- Player Gamelog -->
        <PlayerCardPreviousGames ref="previousGames" v-if="isRefVisible('previousGames')" class="ob-box ob-scroll infoContainer"
          :playerDetails="playerDetails" :sport="sport"
        />
        <!-- Player Season Stats -->
        <PlayerCardSeasonStats ref="season" v-if="isRefVisible('season')" class="ob-box ob-scroll infoContainer"
          :playerDetails="playerDetails" :sport="sport"
        />
        <!-- Player Scouting Report & Player Information -->
        <PlayerCardScoutingReport ref="scouting" v-if="isRefVisible('scouting')" class="ob-box ob-scroll infoContainer"
          :playerDetails="playerDetails" :sport="sport"
        />
      </div>
      <div slot="body" v-else style="display: flex; align-items: center; justify-content: center;">
        <ObLoading :size="60" />
        <div>Loading details...</div>
      </div>
      <!-- Clear the close button at the bottom of the modal -->
      <div slot="footer"></div>
    </template>
    <template v-else>
      <div slot="header" class="ob-box summaryContainer" style="display: flex; justify-content: center; align-items: center; height: 122px;">
        <i class="fad fa-spinner-third fa-spin"></i>
        <div style="font-size: 14px; margin-left: 10px;">Loading...</div>
      </div>
    </template>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import ObDraftApi from '@/api/ObDraftApi';
import PlayerCardSummary from '@/views/PlayerCard/PlayerCardSummary';
import PlayerCardNextGame from '@/views/PlayerCard/PlayerCardNextGame';
import PlayerCardGames from '@/views/PlayerCard/PlayerCardGames';
import PlayerCardSchedule from '@/views/PlayerCard/PlayerCardSchedule';
import PlayerCardPreviousGames from '@/views/PlayerCard/PlayerCardPreviousGames';
import PlayerCardSeasonStats from '@/views/PlayerCard/PlayerCardSeasonStats';
import PlayerCardNews from '@/views/PlayerCard/PlayerCardNews';
import PlayerCardScoutingReport from '@/views/PlayerCard/PlayerCardScoutingReport';
import Modal from '@/components/Modal';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    PlayerCardSummary,
    PlayerCardNextGame,
    PlayerCardGames,
    PlayerCardSchedule,
    PlayerCardPreviousGames,
    PlayerCardSeasonStats,
    PlayerCardNews,
    PlayerCardScoutingReport,
    Modal,
    ObLoading,
  },

  data() {
    return {
      playerId: null,
      leagueId: null, // Added Aug 12, used for schedule view. Not included when drafting.
      isScContest: false,
      playerSummary: null,
      playerDetails: null,
      sport: null,
      isDraft: false,
      draftState: {},
      isOpen: false,
      refNames: [
        {ref: 'nextgame', visible: false},
        {ref: 'schedule', visible: false},
        {ref: 'projection', visible: false},
        {ref: 'news', visible: false},
        {ref: 'previousGames', visible: false},
        {ref: 'season', visible: false},
        {ref: 'scouting', visible: false},
      ],
      refScrolledTo: '',
      sectionTopMargin: 0,
    };
  },

  created() {
    EventBus.$on('DRAFT_OPEN_PLAYER_CARD', this.loadDraftPlayerDetails);
    EventBus.$on('OPEN_PLAYER_CARD', this.loadPlayerDetailsNoDraft);
    EventBus.$on('OPEN_PLAYER_CARD_CONTEST', this.loadPlayerForContest);
    EventBus.$on('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
    EventBus.$on('DRAFT_STATE_CHANGED', this.updateDraftState);
  },

  destroyed() {
    EventBus.$off('DRAFT_OPEN_PLAYER_CARD', this.loadDraftPlayerDetails);
    EventBus.$off('OPEN_PLAYER_CARD', this.loadPlayerDetails);
    EventBus.$off('OPEN_PLAYER_CARD_CONTEST', this.loadPlayerForContest);
    EventBus.$off('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
    EventBus.$off('DRAFT_STATE_CHANGED', this.updateDraftState);
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (newVal === false) {
        this.resetData();
      }
    },
  },

  methods: {
    resetData() {
      this.playerId = null;
      this.playerSummary = null;
      this.playerDetails = null;
      this.sport = null;
      this.isDraft = false;
      this.draftState = {};
      this.refNames = [
        {ref: 'nextgame', visible: false},
        {ref: 'schedule', visible: false},
        {ref: 'projection', visible: false},
        {ref: 'news', visible: false},
        {ref: 'previousGames', visible: false},
        {ref: 'season', visible: false},
        {ref: 'scouting', visible: false},
      ];
      this.refScrolledTo = '';
      this.sectionTopMargin = 0;
    },

    updateDraftState(draftState, player) {
      this.draftState = draftState;
    },

    loadDraftPlayerDetails(player, sport, draftState) {
      this.resetData();
      this.playerSummary = player;
      if (this.playerSummary) {
        for (const refIndex in this.refNames) {
          if (this.refNames[refIndex].ref == 'projection') {
            this.refNames[refIndex].visible = true;
          }
        }
      }
      this.updateDraftState(draftState, player);
      this.isDraft = true;
      this.isOpen = true;
      this.leagueId = null; // No league, don't show schedule
      this.loadPlayerDetails(player.id, sport);
    },

    loadPlayerForContest(playerId, sport, leagueId, isScContest=false) {
      this.resetData();
      this.leagueId = leagueId;
      this.isScContest = isScContest;
      this.loadPlayerDetails(playerId, sport);
    },

    loadPlayerDetailsNoDraft(playerId, sport) {
      this.resetData();
      this.leagueId = null;
      this.loadPlayerDetails(playerId, sport);
    },

    loadPlayerDetails(playerId, sport) {
      this.playerId = playerId;
      this.sport = sport;
      this.isOpen = true;

      ObDraftApi.loadPlayerDetails(playerId, sport)
          .then((data) => {
            this.playerDetails = data;
            if (this.playerDetails) {
              for (const refIndex in this.refNames) {
                if (this.refNames[refIndex].ref == 'previousGames' ||
                this.refNames[refIndex].ref == 'season' ||
                this.refNames[refIndex].ref == 'news' ||
                this.refNames[refIndex].ref == 'scouting'
                ) {
                  this.refNames[refIndex].visible = true;
                } else if (this.refNames[refIndex].ref == 'schedule' && !this.isDraft) {
                  this.refNames[refIndex].visible = true;
                } else if (this.refNames[refIndex].ref == 'nextgame' && this.hasNextGame()) {
                  this.refNames[refIndex].visible = true;
                }
              }
            }
            for (const refIndex in this.refNames) {
              if (this.refNames[refIndex].visible === true && this.refScrolledTo == '') {
                this.refScrolledTo = this.refNames[refIndex].ref;
              }
            }
          });
    },

    isRefVisible(rName) {
      for (const refIndex in this.refNames) {
        if (this.refNames[refIndex].ref === rName) {
          return this.refNames[refIndex].visible;
        }
      }
      return false;
    },

    hasNextGame() {
      return this.playerDetails && this.playerDetails.next_games &&
        ((this.playerDetails.next_games[0] && this.playerDetails.next_games[0].id) || (this.playerDetails.next_games[1] && this.playerDetails.next_games[1].id));
    },

    scrollToComponent(cName, behavior) {
      if (this.$refs[cName]) {
        EventBus.$emit('MODAL_SCROLL_BODY', this.$refs[cName].$el.offsetTop - this.sectionTopMargin, behavior);
      }
    },

    checkScrollPosition(e) {
      let currentScrolledIndex = this.refNames[0].ref;

      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
        this.refScrolledTo = this.refNames[this.refNames.length - 1].ref;
        return;
      }

      for (const rIndex in this.refNames) {
        if (this.$refs[this.refNames[rIndex].ref]) {
          const refEl = this.$refs[this.refNames[rIndex].ref].$el;
          if (e.target.scrollTop >= refEl.offsetTop - e.target.offsetTop - this.sectionTopMargin - 10 && this.refNames[rIndex].visible) {
            currentScrolledIndex = this.refNames[rIndex].ref;
          }
        }
      }

      this.refScrolledTo = currentScrolledIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.summaryContainer {
  margin: -10px -10px 0 -10px;
  border-radius: 5px 5px 0 0;
  background: var(--obcolor-background-6);
}

.navigationMenu {
  display: flex;
  background: var(--obcolor-background-page);
  margin: 0 -8px -8px -8px;
  padding-left: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.navBtn {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.navBtn.navSelected {
  background: var(--obcolor-background-6);
  color: var(--obcolor-ob-blue)
}

.infoContainer {
  margin-bottom: 20px;
  padding: 0;
  border-radius: 10px;
  overflow: auto;
  max-width: 100%;
}

.infoContainer:last-child {
  margin-bottom: 5px;
}

.loadingBox {
  text-align: center;
  padding: 20px;
}
</style>