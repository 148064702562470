// Matchup Summary Stats
// Controls the top overall summary for matchup page
// NFL Split into 2 categories Offense, Defense + Kicking

// Offense Stats
export const MATCHUP_NFL_OFF = [
  {
    label: 'PASSING',
    stats: [
      {label: 'YDS', path: 'passing.PASS_YDS', tooltip: 'Passing Yards'},
      {label: 'TD', path: 'passing.PASS_TD', tooltip: 'Passing Touchdowns'},
      {label: 'INT', path: 'passing.PASS_INT', tooltip: 'Interceptions Thrown'},
      {label: 'SCK', path: 'sacks.SCK_A', tooltip: 'Sacks Surrendered'},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions'},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards'},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns'},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards'},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns'},
    ],
  },
  {
    label: '',
    stats: [
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost'},
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2 Point Conversions'},
    ],
  },
];

// Defense & Kicking
export const MATCHUP_NFL_DEF = [
  {
    label: 'DEFENSE',
    stats: [
      {label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles'},
      {label: 'SCK', path: 'defense.SCK', tooltip: 'Sacks'},
      {label: 'INT', path: 'defense.INT', tooltip: 'Interceptions'},
      {label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumble Recoveries'},
      {label: 'FF', path: 'fumbles.FBL_F', tooltip: 'Forced Fumbles'},
      {label: 'PD', path: 'defense.PD', tooltip: 'Pass Deflections'},
      {label: 'SFTY', path: 'defense.STY', tooltip: 'Safeties'},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards'},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Return Touchdowns'},
    ],
  },
  {
    label: 'KICKING',
    stats: [
      {label: 'YDS', path: 'scoring.FG_YDS', tooltip: 'Field Goal Yards'},
      {label: 'FG', path: 'scoring.FGM', denom: 'scoring.FGA', tooltip: 'Field Goals Made'},
      {label: 'XP', path: 'scoring.EPM', denom: 'scoring.EPA', tooltip: 'Extra Points'},
    ],
  },
];
