<template>
  <div>
    <!-- Batting Order Section -->
    <h1 class="sectionHeader">
      Batting Order
    </h1>
    <div class="startingBattersSection">
      <!-- Away Team Batting Order -->
      <div v-for="index in 9" :key="'batting_order_'+index" class="playerRow">
        <div class="teamPlayer">
          <div v-for="(batter, index1) of battingOrder.awayteam[index-1]" :key="'awayteam_batter_'+index+'_'+index1" class="awayBatter">
            <template v-if="batter.player">
              <div v-if="index1 !== 0" class="arrowLeft"></div>
              <div class="positionField">{{ getGamePosition(batter) }}</div>
              <div class="ob-link" @click.stop="openPlayerCard(batter.player.id)">{{ batter.player.name.shortName() }}</div>
            </template>
            <template v-else>
              <div class="positionField">-</div>
              <div class="tbdField">{{ batter.label }}</div>
            </template>
            <template v-if="liveMatchupData && liveMatchupData.batter && batter.player && liveMatchupData.batter.id === batter.player.id">
              <img :src="require('@/assets/icons/baseball-bat-orange.png')" class="battingIconLeft"
                alt="Orange Bat"
              >
            </template>
          </div>
        </div>
        <div>
          {{ index }}
        </div>
        <div class="teamPlayer">
          <div v-for="(batter, index2) of battingOrder.hometeam[index-1]" :key="'hometeam_batter_'+index+'_'+index2" class="homeBatter">
            <template v-if="liveMatchupData && liveMatchupData.batter && batter.player && liveMatchupData.batter.id === batter.player.id">
              <img :src="require('@/assets/icons/baseball-bat-orange.png')" class="battingIconRight"
                alt="Orange Bat"
              >
            </template>
            <template v-if="batter.player">
              <div class="ob-link" @click.stop="openPlayerCard(batter.player.id)">{{ batter.player.name.shortName() }}</div>
              <div class="positionField">{{ getGamePosition(batter) }}</div>
              <div v-if="index2 !== 0" class="arrowRight"></div>
            </template>
            <template v-else>
              <div class="tbdField">{{ batter.label }}</div>
              <div class="positionField">-</div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    liveMatchupData() {
      return this.gameData?.matchup?.live;
    },

    battingOrder() {
      let awayteam = [];
      let hometeam = [];
      if (!this.gameData.rosters || this.sport !== 'MLB') {
        return {
          hometeam: [],
          awayteam: [],
        };
      }

      const gameId = this.gameData.matchup.id;
      if (this.gameData?.boxscore?.awayteam && this.gameData?.boxscore?.hometeam) {
        awayteam = this.getIngameBattingOrder(this.gameData.boxscore.awayteam);
        hometeam = this.getIngameBattingOrder(this.gameData.boxscore.hometeam);
      } else {
        awayteam = this.getPregameBattingOrder(this.gameData.rosters.awayteam, gameId);
        hometeam = this.getPregameBattingOrder(this.gameData.rosters.hometeam, gameId);
      }
      return {
        awayteam: awayteam,
        hometeam: hometeam,
      };
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    getIngameBattingOrder(boxscore) {
      const sortedBatters = boxscore.filter((player) => {
        return player.lineup !== '0';
      }).sort((prevBatter, currBatter) => {
        if (parseInt(prevBatter.lineup) === parseInt(currBatter.lineup)) {
          return parseInt(prevBatter.lineup_sequence) - parseInt(currBatter.lineup_sequence);
        }
        return parseInt(prevBatter.lineup) - parseInt(currBatter.lineup);
      });

      const battingOrderPlayers = {};
      for (const batter of sortedBatters) {
        if (battingOrderPlayers[batter.lineup]) {
          battingOrderPlayers[batter.lineup].push({player: batter});
        } else {
          battingOrderPlayers[batter.lineup] = [{player: batter}];
        }
      }
      const battingOrder = [];
      for (let i = 0; i < 9; i++) {
        const player = battingOrderPlayers[i+1];
        if (player) {
          battingOrder.push(player);
        } else {
          battingOrder.push([{label: 'TBD'}]);
        }
      }

      return battingOrder;
    },

    getPregameBattingOrder(roster, gameId) {
      const battingOrderPlayers = {};

      for (const player of roster) {
        const gameInfo = this.getGameInfo(player, gameId);
        const battingOrder = gameInfo?.info?.lineup;
        if (battingOrder) {
          battingOrderPlayers[battingOrder] = [{player: player, gameInfo: gameInfo}];
        }
      }

      const battingOrder = [];
      // Need to modify this for different sports
      for (let i = 0; i < 9; i++) {
        const player = battingOrderPlayers[i+1];
        if (player) {
          battingOrder.push(player);
        } else {
          battingOrder.push([{label: 'TBD'}]);
        }
      }

      return battingOrder;
    },

    getGameInfo(player, gameId) {
      if (player?.next_games) {
        for (const game of player.next_games) {
          if (game.id === gameId) {
            return game;
          }
        }
      }
      return null;
    },

    getGamePosition(entry) {
      const gamePosition = entry?.gameInfo?.info?.gamepos;
      if (gamePosition) {
        return gamePosition;
      }
      return entry?.player?.position;
    },
  },
};
</script>

<style lang="scss" scoped>
.startingBattersSection {
  font-size: 14px;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.playerRow {
  display: flex;
  padding: 5px;
  font-weight: bold;

  .teamPlayer {
    flex: 1;
  }
}
.playerRow:not(:last-child) {
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.awayBatter {
  display: flex;
}
.awayBatter:not(:first-child) {
  padding-top: 5px;
}
.arrowLeft {
  position: relative;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid var(--obcolor-background-7);
  border-left: 2px solid var(--obcolor-background-7);
  margin-right: 10px;
  margin-left: 5px;
  margin-top: -4px;
}
.arrowLeft::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: -7px;
  border: 6px solid transparent;
  border-left-color: var(--obcolor-background-7);
}
.arrowRight {
  position: relative;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid var(--obcolor-background-7);
  border-right: 2px solid var(--obcolor-background-7);
  margin-left: 10px;
  margin-right: 5px;
  margin-top: -4px;
}
.arrowRight::after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: -7px;
  border: 6px solid transparent;
  border-right-color: var(--obcolor-background-7);
}
.homeBatter {
  display: flex;
  text-align: right;
  justify-content: right;
}
.homeBatter:not(:first-child) {
  padding-top: 5px;
}
.positionField {
  width: 25px;
  color: var(--obcolor-font-secondary);
}
.tbdField {
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.battingIconLeft {
  height: 16px;
  margin-left: 10px;
}
.battingIconRight {
  height: 16px;
  margin-right: 10px;
}
.fa {
  color: var(--obcolor-font-secondary);
}
</style>