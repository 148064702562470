<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 370px; padding: 0;  background: var(--obcolor-background-6);"
    bodyStyle="margin: 0;"
  >
    <div slot="header" class="confirmHeader">Salary Cap Redeem Rates</div>
    <div slot="body" class="confirmBody">
      <table aria-label="Conversion Info Modal">
        <tr class="header">
          <th style="border-radius: 4px 0px 0px 4px; white-space: nowrap;">Paid Entries (Past 30 Days)</th>
          <th style="border-radius: 0px 4px 4px 0px; white-space: nowrap;">Redeemable Rate</th>
        </tr>
        <tr v-for="rate, idx in conversionInfo" :key="idx">
          <td style="width: 200px;">{{ rate.handle }}</td>
          <td style="width: 140px;">{{ rate.conversionRate }}</td>
        </tr>
      </table>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      conversionRate: 0,
      conversionInfo: [
        {handle: '$0 - $1,000', conversionRate: '4.00%'},
        {handle: '$1,001 - $2,500', conversionRate: '4.25%'},
        {handle: '$2,501 - $5,000', conversionRate: '4.50%'},
        {handle: '$5,001 - $10,000', conversionRate: '4.75%'},
        {handle: '$10,001+', conversionRate: '5.00%'},
      ],
    };
  },
  created() {
    EventBus.$on('OPEN_CONVERSION_INFO_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_CONVERSION_INFO_MODAL', this.openModal);
  },
  computed: {
    conversionRateFormatted() {
      return (Number(this.conversionRate) * 100).toFixed(2) + '%';
    },
  },
  methods: {
    openModal(rate) {
      this.isOpen = true;
      this.conversionRate = rate;
    },
  },
};
</script>

<style lang="scss" scoped>

.header{
  background: var(--obcolor-background-3);
}

.confirmHeader {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}
.confirmBody {
  flex: 1;
  padding: 15px 0px;
  box-sizing: border-box;
}

table {
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;

  th, td {
    padding: 10px;
  }

  th {
    font-size: 14px;
  }

  td {
    font-size: 14px;
  }
}

.bottomSection {
  border-top: 2px solid var(--obcolor-background-2);
  padding: 10px;
}

.subTitle {
  margin: 10px 0;
  font-weight: bold;
}

::v-deep .closeBtn:hover {
  color: var(--obcolor-background-1) !important; /* Optional: Use !important to override */
}
</style>