<template>
  <div class="boxCheckbox" :class="{checked: checked}"
    :style="'height: ' + sizeText + '; width: ' + sizeText + ';'"
    @click.stop="fireClick"
  >
    <i class="fas fa-check selectedCheck"
      :style="'font-size: ' + fontSize + ';'"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    checked: {type: Boolean, default: false},
    size: {type: String, default: '20'},
  },

  computed: {
    fontSize() {
      // Default checkbox size is 20px with 14px font size, so 70%
      return this.size * 0.7 + 'px';
    },

    sizeText() {
      return this.size + 'px';
    },
  },

  methods: {
    fireClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.boxCheckbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 2px solid var(--obcolor-font-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--obcolor-background-1);
  }
  .selectedCheck {
    color: var(--obcolor-font-primary);
    font-size: 14px;
  }
  &:not(.checked) .selectedCheck {
    display: none;
  }
}
</style>