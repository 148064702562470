<template>
  <label v-if="type === 'switch'" class="switch" :class="{noClick: noClick == true}">
    <input type="checkbox" v-model="inputVal">
    <div class="slider round">
      <div class="slider-circle"></div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['type', 'value', 'noClick'],
  computed: {
    // Updates internally when this.value is changed
    // Emits any changes that are made on the input
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.noClick) {
          return;
        }
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: calc(2em + 4px);
  height: calc(1em + 4px);
  &.noClick {
    opacity: 0.6
  }
  &.noClick, &.noClick .slider {
    cursor: not-allowed;
  }
}

/* Hide default HTML checkbox */
input {
  opacity: 0;
  width: 0;
  height: 0;
}
input:checked + .slider {
  background-color: #00AEEF;
}
input:focus + .slider {
  box-shadow: 0 0 1px #00AEEF;
}
input:checked + .slider:before {
  -webkit-transform: translateX(1em);
  -ms-transform: translateX(1em);
  transform: translateX(1em);
}

/* The slider */
/* Set font size to set height of the circle */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--obcolor-background-1);
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  bottom: 2px;
  left: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 1em;
}

.slider.round:before {
  border-radius: 50%;
}
</style>