<template>
  <div class="playerList ob-scroll" v-if="picks">
    <!-- Use preset groupings for positions -->
    <div class="playerRow" v-for="(posList, index) in displayGroups" :key="index">
      <!-- Go through each position in grouping array -->
      <template v-for="(pos, index1) in posList">
        <!-- Make a box for each player in that position -->
        <LineupRosterPlayer v-for="(player, index2) in picks[pos]"
          :key="index + '_' + index1 + '_' + index2"
          :player="player" :contest="contest" :pos="pos"
          :statusMap="statusMap"
          :selectedFor="getPlayerSelectedType(player)"
          :toAdd="getAddedPlayer(player)" :rosterMode="rosterMode"
          :isBeingDropped="pendingDrops[player.id] != null"
        />

        <LineupRosterPlayer v-for="index3 in rosterPosLength(pos)"
          :statusMap="statusMap" :contest="contest"
          :key="index + '_' + index1 + '_' + index3 + '_empty'" :pos="pos"
          :toAdd="toAdd"
          :rosterMode="rosterMode"
        />
      </template>
    </div>
  </div>
</template>

<script>
import LineupRosterPlayer from '@/views/SalaryContest/LineupEditor/LineupRosterPlayer';

export default {
  components: {
    LineupRosterPlayer,
  },

  props: {
    contest: Object,
    statusMap: Object,
    picks: Object,
    toAdd: Object,
    toDrop: Object,
    rosterMode: String,
    pendingDrops: Object,
    pendingSwaps: Array,
  },

  computed: {
    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      return this.contest.draftRoster.roster;
    },

    displayGroups() {
      if (!this.contest) {
        return [];
      }
      return this.$SportInfo.getScDisplayGroups(this.contest.sport, this.contest.format == 'DAILY');
    },
  },

  methods: {
    rosterAtPos(pos) {
      return this.roster.find((r) => {
        return r.short == pos;
      });
    },

    rosterPosLength(pos) {
      if (!this.rosterAtPos(pos)) {
        return 0;
      }
      return this.rosterAtPos(pos).num - this.picks[pos].length;
    },

    getPlayerSelectedType(player) {
      if (this.toAdd && player.id === this.toAdd.id) {
        return 'add';
      } else if (this.toDrop && player.id === this.toDrop.id) {
        return 'drop';
      }

      return null;
    },

    getAddedPlayer(player) {
      const swapIndex = this.pendingDrops[player.id];
      if (swapIndex != null) {
        const swap = this.pendingSwaps[swapIndex];
        return swap.add;
      }
      return null;
    },
  },
};
</script>


<style lang="scss" scoped>
  .playerList {
    width: 100%;
    margin: 0 -3px;
    min-height: 0;
    overflow: auto;
  }

  .playerRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
  }
</style>