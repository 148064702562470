<template>
  <Modal v-if="isOpen" v-model="isOpen" :hideCloseButton="false"
    containerStyle="width: 510px; overflow: hidden; padding: 0;"
  >
    <div class="modalHeaderContainer" slot="header">
      <div>Delete Bank Account?</div>
    </div>
    <div slot="body">
      <div class="modalBody">Are you sure you want to delete this?</div>
      <div class="modalBody modalBlueTxt">This action cannot be undone</div>
      <ErrorPanel v-if="errorMessage" :message="errorMessage" />

      <div class="buttonContainer">
        <button class="ob-btn-grey leftBtn" @click="closeDelete()">Cancel</button>
        <button v-if="waitingToDelete" class="ob-btn rightBtn" disabled>
          <i class="fad fa-spinner-third fa-spin"></i>Submitting...
        </button>
        <button v-else class="ob-btn rightBtn" @click="deleteAccount()">Delete</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
    ErrorPanel,
  },

  data() {
    return {
      errorMessage: null,
      isOpen: false,
      waitingToDelete: false,
      fdAccountId: null,
      accountId: null,
    };
  },

  created() {
    EventBus.$on('OPEN_DELETE_ACCOUNT_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_DELETE_ACCOUNT_MODAL', this.openModal);
  },


  methods: {
    openModal(account) {
      this.isOpen = true;
      this.fdAccountId = account.fdAccountId;
      this.errorMessage = null;
      this.accountId = account.id;
    },

    closeDelete() {
      this.isOpen = false;
      this.$emit('modalClosed');
    },

    deleteAccount() {
      this.waitingToDelete = true;
      ObPaymentApi.deleteAchAccount(this.accountId)
          .then((_response) => {
            this.deleteFiservAccount();
          }).catch((error) => {
            if (error?.response?.data) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'Failed to delete account';
            }
            this.waitingToDelete = false;
          });
    },

    deleteFiservAccount() {
      this.waitingToDelete = true;

      ObPaymentApi.createAchToken(this.fdAccountId)
          .then((response) => {
            this.waitingToDelete = false;
            this.closeDelete();
            this.$emit('handleCloseAccount', response);
          }).catch((error) => {
            this.closeDelete();
            if (error?.response?.data) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'Failed to delete account';
            }
            this.waitingToDelete = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>