// @ts-check

import Vue from 'vue';

/**
 * @template T
 * @typedef {import('vue').ComputedOptions<T>} ComputedOptions
 */

/**
 * @template T
 * @typedef {{
 *  fromQuery: (v: string | null) => T
 *  toQuery: (v: T) => string | null
 * }} SearchParameterOptions
 */

/**
 * fromSearchParam is used to add state that is stored in the url search
 * parameters.
 *
 * @example
 * import {fromSearchParam, numericProperty} from '@/utils/query';
 * export default {
 *   computed: {
 *     // A string value can be used without any options
 *     simple: fromSearchParam('simple'),
 *
 *     // Numeric values can be used with the `numericProperty` options
 *     numeric: fromSearchParam('numeric', numericProperty),
 *
 *     // custom types can be used by sepcifying `toQuery` and `fromQuery` functions
 *     custom: fromSearchParam('custom', {
 *       toQuery(v: Date): string|null {
 *         return v.toISOString();
 *       },
 *       fromQuery(v: string|null): Date {
 *         return new Date(v);
 *       },
 *     }),
 *   }
 * };
 *
 * @template [T=string|null]
 * @param {string} query The name of the query parameter the value is stored in.
 * @param {SearchParameterOptions<T>} [options] Options for moving data to and
 * from the url parameters.
 * @return {ComputedOptions<T>}
 */
export function fromSearchParam(query, options) {
  /** @type {SearchParameterOptions<T>} */
  // @ts-ignore
  const {fromQuery, toQuery} = options || stringProperty;

  const state = Vue.observable({
    value: fromQuery(new URL(location.href).searchParams.get(query)),
  });

  return {
    get() {
      return state.value;
    },
    set(v) {
      state.value = v;
      const queryValue = toQuery(v);
      const u = new URL(location.href);
      if (queryValue !== null) {
        u.searchParams.set(query, queryValue);
      } else {
        u.searchParams.delete(query);
      }
      history.replaceState({}, undefined, u);
    },
  };
}

/**
 * @param {any} v
 * @return {string|null}
 */
function toQuery(v) {
  if (v === null || v === undefined) {
    return null;
  }
  return String(v);
}

/** @type {SearchParameterOptions<string|null>} */
export const stringProperty = {
  fromQuery: (v) => v,
  toQuery: toQuery,
};

/** @type {SearchParameterOptions<number|null>} */
export const numericProperty = {
  fromQuery: (v) => v === null ? null : Number(v),
  toQuery: toQuery,
};


/** @type {SearchParameterOptions<number[]>} */
export const numericArrayProperty ={
  fromQuery: (v) => {
    if (!v) {
      return [];
    }
    return v.split('-').map(Number);
  },
  toQuery: (v) => {
    if (!v || v.length == 0 ) {
      return null;
    }
    return v.join('-');
  },
};
