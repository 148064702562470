<template>
  <div class="uploadDownloadBody">
    <div class="bodyCol">
      <!-- How To -->
      <div class="instructions">
        <h1>How to Perform a Global Swap</h1>
        <ol class="textContent">
          <li>Download your lineups for the specified slate</li>
          <li>Follow the instructions in the Player List to edit your lineup</li>
          <li>Save the file and upload in the box below</li>
        </ol>
      </div>

      <!-- Download Button and Options -->
      <div class="paramsSelector">
        <div class="paramContainer">
          <p>Select Sport</p>
          <i class="fas fa-chevron-down"></i>
          <select v-model="selectedOptions.sport">
            <option :value="null" disabled>Select Sport</option>
            <option v-for="(sport, index) in sportOptions" :key="index">{{ sport }}</option>
          </select>
        </div>
        <div class="paramContainer">
          <p>Select Roster Type</p>
          <i class="fas fa-chevron-down"></i>
          <select v-model="selectedOptions.rosterFormat">
            <option :value="null" disabled>Select Roster Type</option>
            <template v-if="selectedSportValid">
              <option v-for="(option, index) in rosterFormatOptions" :key="index" :value="option">
                {{ option.label }}
              </option>
            </template>
          </select>
        </div>
        <div class="paramContainer">
          <p>Select Slate</p>
          <i class="fas fa-chevron-down"></i>
          <select v-model="selectedOptions.gameSlate">
            <option :value="null" disabled>Select Game Slate</option>
            <template v-if="selectedSportValid">
              <option v-for="(option, index) in gameSlateOptions" :key="index" :value="option">
                {{ option.name }}
              </option>
            </template>
          </select>
        </div>
        <button class="ob-btn downloadBtn" v-if="selectedOptions.gameSlate && !loadingDownload" @click="downloadLineups()">
          <i class="fas fa-download"></i> Download
        </button>
        <button class="ob-btn downloadBtn ob-btn-grey" v-else disabled>
          <i class="fas fa-download"></i> Download
          <ObLoading v-if="loadingDownload" :size="35" class="loadingIcon" />
        </button>
        <div v-if="downloadErrorMessage" class="errorText downloadError">{{ downloadErrorMessage }}</div>
      </div>
    </div>

    <!-- Upload CSV -->
    <div class="bodyCol">
      <img class="importImage" :src="require('@/assets/importlineups/importCSVLogo.png')" alt="Import CSV File Image">
      <div @dragover="dragover" @drop="drop">
        <label class="customFileUpload">
          <template>
            <input type="file" accept="csv" @change="uploadFile($event)">
            <!-- Upload text/button -->
            <template v-if="csvFileName == null">
              <i class="fas fa-file-upload fileIcon"></i>
              <p>Drag and Drop to Upload File</p>
              <p>or</p>
              <div class="ob-btn fileUploadText">
                <i class="fas fa-arrow-alt-from-bottom uploadIcon"></i>
                <span>Upload CSV</span>
              </div>
            </template>
            <!-- Content if file has been dropped in -->
            <template v-else>
              <i class="fas fa-file fileIcon"></i>
              <div class="fileNameContainer">
                <div>{{ csvFileName }}</div>
                <!-- Delete button -->
                <i class="fas fa-times deleteFileBtn" @click.stop="removeFile"></i>
              </div>
            </template>
            <div v-if="csvErrorMessage" class="errorText">{{ csvErrorMessage }}</div>
          </template>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ObLoading,
  },

  data() {
    return {
      csvErrorMessage: null,
      downloadErrorMessage: null,

      loadingDownload: false,

      sportList: ['NHL', 'NFL', 'NBA', 'MLB'],
      selectedOptions: {
        sport: null,
        rosterFormat: null,
        gameSlate: null,
      },
      globalSwapOptions: null,

      csvFileName: null,
    };
  },

  created() {
    this.getSwapOptions();
  },

  computed: {
    selectedSportValid() {
      return this.selectedOptions.sport && this.globalSwapOptions && this.globalSwapOptions[this.selectedOptions.sport];
    },

    sportOptions() {
      if (!this.globalSwapOptions) {
        return [];
      }

      return this.sportList.filter((s) => {
        return this.globalSwapOptions[s];
      });
    },

    rosterFormatOptions() {
      if (!this.selectedSportValid || !this.globalSwapOptions[this.selectedOptions.sport].rosterFormat) {
        return [];
      }
      return this.globalSwapOptions[this.selectedOptions.sport].rosterFormat;
    },

    gameSlateOptions() {
      if (!this.selectedSportValid || !this.globalSwapOptions[this.selectedOptions.sport].gameSlates) {
        return [];
      }
      const totGameSlateOptions = this.globalSwapOptions[this.selectedOptions.sport].gameSlates;

      return totGameSlateOptions.filter((slate) => {
        if (this.selectedOptions.rosterFormat == null) {
          return true;
        }
        return slate.rosterFormat === this.selectedOptions.rosterFormat.label;
      });
    },
  },

  watch: {
    rosterFormatOptions(newVal) {
      if (this.selectedOptions.rosterFormat == null) {
        return;
      }

      // Reset the roster format dropdown if the newest computed list for it doesn't include the selected option
      const foundSelectedRoster = newVal.find((v) => {
        return v.id === this.selectedOptions.rosterFormat.value;
      });

      if (foundSelectedRoster == null) {
        this.selectedOptions.rosterFormat = null;
      }
    },

    gameSlateOptions(newVal) {
      if (this.selectedOptions.gameSlate == null) {
        return;
      }

      // Reset the game slate dropdown if the newest computed list for it doesn't include the selected option
      const foundSelectedSlate = newVal.find((v) => {
        return v.id === this.selectedOptions.gameSlate.id;
      });

      if (foundSelectedSlate == null) {
        this.selectedOptions.gameSlate = null;
      }
    },

    'selectedOptions.sport'() {
      this.downloadErrorMessage = null;
    },

    'selectedOptions.rosterFormat'() {
      this.downloadErrorMessage = null;
    },

    'selectedOptions.gameSlate'() {
      this.downloadErrorMessage = null;
    },
  },

  methods: {

    getSwapOptions() {
      ObSalaryCapApi.getGlobalSwapOptions()
          .then((response) => {
            this.globalSwapOptions = response;
          }).catch((_error) => {
            // Suppress error message
          });
    },

    // Import lineup drag/drop/upload/read logic
    dragover(e) {
      // Prevent the browser from downloading the file
      // so client can import and process file
      e.preventDefault();
    },

    drop(e) {
      // Prevent the browser from downloading the file
      e.preventDefault();
      this.loading = true;
      this.processFile(e.dataTransfer.files[0]);
    },

    uploadFile(e) {
      this.loading = true;
      this.processFile(e.target.files[0]);
    },

    processFile(file) {
      this.csvErrorMessage = null;
      if (!file) {
        this.loading = false;
        return;
      }

      // Check file extension
      if (!this.isCSVFile(file.name)) {
        this.loading = false;
        this.csvErrorMessage = 'Only CSV files are permitted';
        return;
      }

      // Read CSV file
      EventBus.$emit('GLOBAL_SWAP_CSV_INPUTTED', file);
      this.csvFileName = file.name;
    },

    isCSVFile(filename) {
      if (!filename) {
        return false;
      }

      const fileExtension = filename.split('.').pop();
      return fileExtension == 'csv';
    },

    removeFile() {
      EventBus.$emit('GLOBAL_SWAP_CSV_INPUTTED', null);
      this.csvFileName = null;
    },

    downloadLineups() {
      if (this.selectedOptions.gameSlate == null) {
        return;
      }

      this.loadingDownload = true;

      ObSalaryCapApi.getUserGameSlateLineups(this.selectedOptions.gameSlate.id, this.selectedOptions.gameSlate.name)
          .then(() => {
            this.downloadErrorMessage = null;
            this.loadingDownload = false;
          })
          .catch(() => {
            this.downloadErrorMessage = 'Error downloading your game slate lineups';
            this.loadingDownload = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadDownloadBody {
  padding: 20px 40px;
}

.bodyCol {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  p {
    margin-top: 0;
  }

  &:first-child {
    padding-right: 30px
  }

  &:last-child {
    padding-left: 30px;
  }
}

h1 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.textContent {
  font-size: 14px;
  padding-inline-start: 30px;

  > li {
    margin-bottom: 10px;
  }
}

.paramsSelector {
  width: 100%;

  .paramContainer {
    width: 100%;
    margin-bottom: 15px;
    position: relative;

    p {
      font-size: 14px;
      color: var(--obcolor-font-secondary);
      margin: 0 0 5px 0;
    }

    select {
      width: 100%;
      background: var(--obcolor-background-6);
      padding: 9px;
      padding-left: 12px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    i {
      position: absolute;
      right: 10px;
      top: 30px;
      font-size: 16px;
      pointer-events: none;
    }
  }

  .ob-btn-grey {
    background: var(--obcolor-background-6);
  }

  .downloadBtn {
    margin-top: 10px;
    font-size: 14px;
    padding: 8px 20px;
    white-space: nowrap;

    .fas {
      margin-right: 5px;
    }
  }
}

.importImage {
  width: 200px;
}

input[type="file"] {
  display: none;
}

.customFileUpload {
  border: 2px dashed #404040;
  padding: 20px 0px;
  height: 250px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.fileIcon {
  margin-bottom: 10px;
  font-size: 35px;
}

.uploadIcon {
  margin-right: 10px;
}

.fileUploadText {
  margin-bottom: 10px;
  font-size: 14px;
}

.fileNameContainer {
  display: flex;
  align-items: center;
  font-size: 14px;

  > div {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .deleteFileBtn {
    color: var(--obcolor-red);

    &:hover {
      color: var(--obcolor-font-secondary);
    }
  }
}

.downloadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 35px;
  width: 140px;
  box-sizing: border-box;

  .loadingIcon {
    margin-right: -10px;
  }
}

.errorText {
  height: 0;
  font-size: 12px;
  color: var(--obcolor-red);

  &.downloadError {
    margin-top: 5px;
    margin-bottom: -5px;
  }
}
</style>