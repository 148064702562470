import Vue from 'vue';

// @ts-check
export default Vue.extend({
  data() {
    return {
      now: Date.now(),
      TimeMixin_interval: null,
    };
  },
  created() {
    this.TimeMixin_interval = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.TimeMixin_interval);
  },
});