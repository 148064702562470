<template>
  <div class="matchHeader">
    <!-- Left Side: Team1 -->
    <div class="teamContainer">
      <div class="matchTeam">
        <UserLogo :id="league.matchup.team1Id" :sport="league.sport"
          :size="60" :showRank="true" :rankColor="league.roster1.rankColor"
          :rankNum="league.roster1.rankNum"
        />
        <div class="teamDetails">
          <router-link class="matchHeaderTeam ob-link" :to="'/profile/' + team1Roster.id">
            {{ league.matchup.team1Team }}
            <i v-if="league.matchup.coinTossWinner === 'team1'" class="fas fa-coin" style="color: var(--obcolor-coin); margin-left: 5px;"></i>
          </router-link>
          <router-link class="matchHeaderUser ob-link" :to="'/profile/' + team1Roster.id">
            <span style="margin-right: 5px;">{{ league.matchup.team1Name }}</span>
            <span>({{ league.roster1.h2hRecord }})</span>
          </router-link>
          <div class="gpContainer">
            <div class="matchGP">
              {{ GPUntilDay(1, null) }}/{{ maxGames }}
            </div>
            <div v-if="maxGames > 0" class="gamesPlayedBar"
              v-tooltip.bottom="{content: GPUntilDay(1, null) + '/' + maxGames + ' GP, ' + team1PossibleGP + ' Possible', class:'ObTooltip'}"
            >
              <div class="gamesPlayedFill" :style="'width: ' + ((GPUntilDay(1, null) / maxGames) * 100).toFixed(0) + '%'"></div>
              <div v-if="team1PossibleGP !== maxGames" class="maxGameLineLeft" :style="'width: ' + ((((team1PossibleGP - GPUntilDay(1, null)) / maxGames)) * 100).toFixed(0) + '%'"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Center: Score, Proj, H2H Record -->
    <div style="text-align: center; display: flex;">
      <div>
        <div class="matchHeaderRecord">Proj {{ team1Proj }}</div>
        <div class="matchHeaderScore" :style="isTeamWinning(1) ? 'color: var(--obcolor-ob-blue)' : ''">{{ getTotalPoints(1) || 0 }}</div>
        <div class="matchHeaderProj">
          <div :class="league.roster1.movesRem > 0 ? 'swapUnused' : 'swapUsed'"></div>
          <div :class="league.roster1.movesRem > 1 ? 'swapUnused' : 'swapUsed'"></div>
        </div>
      </div>
      <div>
        <div class="matchHeaderRecord">({{ league.matchup.team1H2hWins }}-{{ league.matchup.team2H2hWins }})</div>
        <div class="matchHeaderVS">vs</div>
        <div class="matchHeaderProj">Swaps</div>
      </div>
      <div>
        <div class="matchHeaderRecord">Proj {{ team2Proj }}</div>
        <div class="matchHeaderScore" :style="isTeamWinning(2) ? 'color: var(--obcolor-ob-blue)' : ''">{{ getTotalPoints(2) || 0 }}</div>
        <div class="matchHeaderProj">
          <div :class="league.roster2.movesRem > 0 ? 'swapUnused' : 'swapUsed'"></div>
          <div :class="league.roster2.movesRem > 1 ? 'swapUnused' : 'swapUsed'"></div>
        </div>
      </div>
    </div>
    <!-- Right Side: Team2 -->
    <div class="teamContainer">
      <div class="matchTeam">
        <div class="teamDetails">
          <router-link class="matchHeaderTeam ob-link" :to="'/profile/' + team2Roster.id">
            <i v-if="league.matchup.coinTossWinner === 'team2'" class="fas fa-coin" style="color: var(--obcolor-coin); margin-right: 5px;"></i>
            {{ league.matchup.team2Team }}
          </router-link>
          <router-link class="matchHeaderUser ob-link" :to="'/profile/' + team2Roster.id">
            <span>({{ league.roster2.h2hRecord }})</span>
            <span style="margin-left: 5px;">{{ league.matchup.team2Name }}</span>
          </router-link>
          <div class="gpContainer" style="display: flex; align-items: center;">
            <div v-if="maxGames > 0" class="gamesPlayedBar" v-tooltip.bottom="{content: GPUntilDay(2, null) + '/' + maxGames + ' GP, ' + team2PossibleGP + ' Possible', class:'ObTooltip'}">
              <div v-if="team2PossibleGP !== maxGames" class="maxGameLineRight"
                :style="'width: ' + ((((team2PossibleGP - GPUntilDay(2, null)) / maxGames)) * 100).toFixed(0) + '%'"
              ></div>
              <div class="gamesPlayedFill"
                :style="'width: ' + ((GPUntilDay(2, null) / maxGames) * 100).toFixed(0) + '%'"
              ></div>
            </div>
            <div class="matchGP">
              {{ GPUntilDay(2, null) }}/{{ maxGames }}
            </div>
          </div>
        </div>
        <UserLogo :id="league.matchup.team2Id" :sport="league.sport"
          :size="60" :showRank="true" :rankColor="league.roster2.rankColor"
          :rankNum="league.roster2.rankNum" style="margin-right: 5px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import UserLogo from '@/components/UserLogo.vue';

export default {
  props: {
    league: Object,
    maxGames: Number,
    week: Number,
    team1Roster: Object,
    team2Roster: Object,
    team1GP: Number,
    team2GP: Number,
    team1PossibleGP: Number,
    team2PossibleGP: Number,
    team1Proj: String,
    team2Proj: String,
  },

  components: {
    UserLogo,
  },

  methods: {
    GPUntilDay(teamNum, day) {
      return teamNum === 1 ? this.team1GP : this.team2GP;
    },

    gameDeactivated(player, game) {
      return player.deactGames[game.id] != null;
    },

    addGameIfValid(gJSON, validGames, player) {
      const pos = player.draftPos;
      if (gJSON.inRange &&
          gJSON.played &&
          !this.gameDeactivated(player, gJSON)) {
        const entry = {};
        entry.id = player.id + '_' + gJSON.id;
        entry.ts = parseInt(gJSON.timestamp);
        if (gJSON.ProjPoints) {
          entry.proj = gJSON.ProjPoints.total;
        }
        if (gJSON.ObPoints) {
          entry.fpts = gJSON.ObPoints.total;
        }

        entry.ObPoints = gJSON.ObPoints;
        entry.stats = gJSON.stats;

        if (validGames[pos] == null) {
          validGames[pos] = [];
        }
        validGames[pos].push(entry);
      }
    },

    addPlayerValidGames(player, validGames) {
      const games = player.fspGames;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        this.addGameIfValid(gJSON, validGames, player);
      }
    },

    getTotalPoints(teamNum) {
      const gameLimit = this.league.gameLimits;
      const roster = teamNum == 1 ? this.team1Roster : this.team2Roster;

      // Create list of valid games (Played, not deactivated, not dropped)
      // Order by time, then projected points
      // {id:PLAYERID_GAMEID, ts: timestamp, pos: F, proj: 12.40 }
      const validGames = {};

      for (const pIndex in roster.players) {
        const player = roster.players[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames);
      }

      for (const pIndex in roster.dropped) {
        const player = roster.dropped[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames);
      }

      // Sort and Limit validGames
      for (const pos in validGames) {
        let gamesByPos = validGames[pos];
        const limit = gameLimit[pos];

        gamesByPos.sort(function(a, b) {
          if (a.ts > b.ts) {
            return 1;
          } else if (b.ts > a.ts) {
            return -1;
          }
          // Secondary sort by proj (highest takes proirity)
          if (a.proj > b.proj) {
            return -1;
          } else if (b.proj > a.proj) {
            return 1;
          }
          return 0;
        });

        gamesByPos = gamesByPos.slice(0, limit);
        validGames[pos] = gamesByPos;
      }

      // Calc total points
      let totalPoints = new Decimal(0);
      for (const pos in validGames) {
        const gamesByPos = validGames[pos];
        for (const games of gamesByPos) {
          totalPoints = totalPoints.plus(new Decimal(games.fpts));
        }
      }

      return Number(totalPoints);
    },

    isTeamWinning(teamNum) {
      const matchup = this.league.matchup;

      const team1Won = matchup.team1Score > matchup.team2Score ||
        (matchup.team1Score == matchup.team2Score && matchup.coinTossWinner == 'team1');

      if (teamNum == 1) {
        return team1Won;
      }

      return !team1Won;
    },
  },
};
</script>

<style lang="scss" scoped>
.matchHeader {
  display: flex;
  align-items: center;
}
.teamContainer {
  flex: 1;
  display: flex;
}
.teamContainer:first-child .teamDetails {
  // More margin than right side because of the rank logo
  margin-left: 18px;
  flex: 1;
}
.teamContainer:last-child {
  justify-content: flex-end;
}
.teamContainer:last-child .matchTeam {
  justify-content: flex-end;
}
.teamContainer:last-child .teamDetails {
  margin-right: 10px;
  text-align: right;
  flex: 1;
}
.matchTeam {
  display: flex;
  align-items: center;
  min-width: 0;
  width: 300px;
  padding: 10px 2px;
  border-radius: 8px;
}
.matchHeaderTeam {
  font-weight: bold;
  display: block;
}
.matchHeaderUser {
  font-size: 14px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  margin-top: 2px;
  margin-bottom: -1px;
}
.matchHeaderScore {
  background: var(--obcolor-background-2);
  padding: 4px 10px;
  height: 18px;
  min-width: 35px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}
.matchHeaderVS {
  padding: 2px 10px;
  height: 22px;
  font-size: 20px;
  font-weight: bold;
}
.swapUnused {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--obcolor-ob-blue);
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
.swapUsed {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--obcolor-background-1);
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
.matchHeaderProj {
  padding: 4px 7px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  margin-top: 2px;
}
.matchHeaderRecord {
  padding: 4px 7px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  height: 15px;
  margin-bottom: -3px;
  margin-top: -6px;
}
.gpContainer {
  display: flex;
  align-items: center;
}
.matchGP {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: -2px;
  color: var(--obcolor-font-secondary);
}
.teamContainer:first-child .matchGP {
  margin-right: 5px;
}
.teamContainer:last-child .matchGP {
  margin-left: 5px;
}
.gamesPlayedBar {
  width: 100%;
  margin-top: 5px;
  height: 12px;
  border-radius: 15px;
  background: var(--obcolor-background-page);
  overflow: hidden;
  display: flex;
}
.teamContainer:last-child .gamesPlayedBar {
  justify-content: flex-end;
}
.gamesPlayedFill {
  background: var(--obcolor-ob-blue); height: 100%;
}
.maxGameLineLeft {
  border-right: 1px solid var(--obcolor-ob-blue);
  height: 100%;
}
.maxGameLineRight {
  border-left: 1px solid var(--obcolor-ob-blue);
  height: 100%;
}
</style>
