<template>
  <div class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
    </div>
    <h1>Terms & Conditions</h1>
    <p>
      Welcome to OwnersBox! OwnersBox is a fantasy sports platform that allows you to join, draft, and compete in weekly fantasy sports contests!
      Before using OwnersBox, please take some time to carefully read our Terms of Service below (“Terms,” or “Agreement”). These Terms constitute
      a binding contract between you and OwnersBox.<br><br> By creating an account, using the website located at OwnersBox.com (including all the areas available through such website, collectively,
      the “Site”), downloading the OwnersBox mobile application (“App”), and/or using the various fantasy sports or information services
      (collectively, su...
    </p>
    <div class="openTerms" @click="openTerms()">Read full Terms & Conditions</div>

    <div class="checkboxContainer" @click="toggleAgeCheck">
      <SquareCheckbox :checked="ageCheck" @click="toggleAgeCheck" size="16" />
      <div>I acknowledge that I meet the age eligibility requirements of my state.</div>
    </div>
    <div class="checkboxContainer" @click="toggleConditionCheck">
      <SquareCheckbox :checked="conditionCheck" @click="toggleConditionCheck" size="16" />
      <div>I have read and accept the Terms & Conditions.</div>
    </div>
    <ErrorPanel :message="errorMessage" />
    <button v-if="processing" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>
      Creating Account...
    </button>
    <button v-else class="ob-btn" :disabled="!conditionsChecked"
      @click="verifyConditions()"
    >
      Create Account
    </button>

    <!-- Terms of Service Modal -->
    <Modal v-if="policyModalOpen" v-model="policyModalOpen"
      containerStyle="height: 600px; max-height: calc(100% - 60px); width: 950px; max-width: calc(100% - 60px); padding: 10px; overflow: hidden"
    >
      <div slot="header" class="termsOfServiceHeader">
        {{ policyModalPage == 0 ? 'Terms & Conditions' : 'Privacy Policy' }}
      </div>
      <div ref="termsOfService" v-if="policyModalPage == 0" slot="body"
        key="terms" class="ob-scroll termsContainer"
      >
        <span v-if="termsAndConditions != null">
          <span v-html="termsAndConditions"></span>
        </span>
        <span v-else>Loading...</span>
      </div>
      <div ref="privacyPolicy" v-else-if="policyModalPage == 1" slot="body"
        key="policy" class="ob-scroll termsContainer"
      >
        <PrivacyPolicy />
      </div>
      <div slot="footer" v-if="policyModalPage == 0" class="acceptButton">
        <div class="ob-btn" @click="policyModalPage = 1">View Privacy Policy</div>
      </div>
      <div slot="footer" v-else-if="policyModalPage == 1" class="acceptButton">
        <div class="ob-btn" @click="policyModalPage = 0">View Terms of Service</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';
import Modal from '@/components/Modal.vue';
import SquareCheckbox from '@/components/buttons/SquareCheckbox2.vue';
import PrivacyPolicy from '@/views/KnowledgeBase/PrivacyPolicy.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {

  components: {
    Modal,
    ErrorPanel,
    PrivacyPolicy,
    SquareCheckbox,
  },

  data() {
    return {
      processing: false,
      errorMessage: null,
      termsAndConditions: null,
      policyModalOpen: false,
      policyModalPage: null,
      ageCheck: false,
      conditionCheck: false,
    };
  },

  created() {
    this.loadTerms();
  },

  computed: {
    conditionsChecked() {
      // Next arrow appears if conditions checked
      return this.conditionCheck && this.ageCheck;
    },
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },

    openTerms() {
      this.policyModalPage = 0;
      this.policyModalOpen = true;
    },

    loadTerms() {
      ObAccountApi.getTermsAndConditions()
          .then((data) => {
            this.termsAndConditions = data.document;
            this.termsVersion = data.version;
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    toggleAgeCheck() {
      this.ageCheck = !this.ageCheck;
    },

    toggleConditionCheck() {
      this.conditionCheck = !this.conditionCheck;
    },

    verifyConditions() {
      if (this.conditionCheck && this.ageCheck) {
        this.processing = true;
        EventBus.$emit('ON_BOARDING_CREATE_ACCOUNT');
      } else {
        this.errorMessage = 'Terms and conditions and age must be verified';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container button {
  margin-top: 30px;
  height: 60px;
}

.openTerms {
  margin-top: 20px;
  margin-bottom: 50px;
  color: var(--obcolor-ob-blue);
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.checkboxContainer {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.termsOfServiceHeader {
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  font-weight: bold;
}

.termsContainer {
  padding: 20px;
  padding-top: 10px;
  overflow: auto;
  min-height: 0;
  font-size: 14px;
  background: var(--obcolor-background-page);
}

.acceptButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  padding-top: 12px;
  font-size: 14px;
}
</style>