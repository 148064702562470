<template>
  <div v-if="matchupLiveData && matchupLiveData.status === 'mid-event'">
    <div v-if="!anyPlayersExist" class="liveMatchupHeaders">
    </div>
    <div v-else-if="isAwayBatter" class="liveMatchupHeaders">
      <div>Up To Bat</div>
      <div>Pitching</div>
    </div>
    <div v-else class="liveMatchupHeaders">
      <div>Pitching</div>
      <div>Up To Bat</div>
    </div>
    <div class="liveMatchups">
      <div class="liveMatchup">
        <PlayerHeadshot size="45" :id="livePlayerOne ? livePlayerOne.id : null" :sport="sport"
          :team="livePlayerOne ? livePlayerOne.team : null" :showLogo="true"
        />
        <div v-if="livePlayerOne" class="liveMatchupStats">
          <div class="liveMatchupPlayerName">
            <!-- not sure if we need status for live game? -->
            <span class="playerStatus">
              <PlayerStatus height="12" status="ACT" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(livePlayerOne.id)">{{ livePlayerOne.name.shortName() }}</div>
          </div>
          <p class="liveMatchupsubtext">{{ livePlayerOneBoxscore }}</p>
        </div>
        <div v-else class="liveMatchupStats">
          <div class="liveMatchupPlayerName">
            <div>TBA</div>
          </div>
        </div>
      </div>
      <div class="onBaseState">
        <MlbOnBase :fieldState="fieldState" />
        {{ matchupLiveData.balls === '-' ? '0' : matchupLiveData.balls }}-{{ matchupLiveData.strikes === '-' ? '0' : matchupLiveData.strikes }},
        {{ matchupLiveData.outs !== '-' ? matchupLiveData.outs + ' Outs' : '' }}
      </div>
      <div style="justify-content: right;" class="liveMatchup">
        <div v-if="livePlayerTwo" style="text-align: right;" class="liveMatchupStats">
          <div style="justify-content: right;" class="liveMatchupPlayerName">
            <span class="playerStatus">
              <PlayerStatus height="12" status="ACT" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(livePlayerTwo.id)">{{ livePlayerTwo.name.shortName() }}</div>
          </div>
          <p class="liveMatchupsubtext">{{ livePlayerTwoBoxscore }}</p>
        </div>
        <div v-else style="text-align: right;" class="liveMatchupStats">
          <div style="justify-content: right;" class="liveMatchupPlayerName">
            <div>TBA</div>
          </div>
        </div>
        <PlayerHeadshot size="45" :id="livePlayerTwo ? livePlayerTwo.id : null" :sport="sport"
          :team="livePlayerTwo ? livePlayerTwo.team : null" :showLogo="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import MlbOnBase from '@/components/charts/MlbOnBase.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
    MlbOnBase,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    fieldState() {
      return {
        onBase: [
          this.gameData?.matchup?.live?.runner_1st === 'true',
          this.gameData?.matchup?.live?.runner_2nd === 'true',
          this.gameData?.matchup?.live?.runner_3rd === 'true',
        ],
      };
    },

    anyPlayersExist() {
      return this.matchupLiveData?.batter || this.matchupLiveData?.pitcher;
    },

    isAwayBatter() {
      if (this.matchupLiveData?.batter?.teamId === this.gameData.matchup?.visitor_team?.team_id) {
        return true;
      } else if (this.matchupLiveData?.pitcher?.teamId === this.gameData?.matchup?.visitor_team?.team_id) {
        return false;
      } else if (this.matchupLiveData?.batter?.teamId === this.gameData?.matchup?.home_team?.team_id) {
        return false;
      } else if (this.matchupLiveData?.pitcher?.teamId === this.gameData?.matchup?.home_team?.team_id) {
        return true;
      }
    },

    livePlayerOne() {
      if (this.matchupLiveData?.batter?.teamId === this.gameData?.matchup?.visitor_team?.team_id) {
        return this.matchupLiveData?.batter;
      } else if (this.matchupLiveData?.pitcher?.teamId === this.gameData?.matchup?.visitor_team?.team_id) {
        return this.matchupLiveData?.pitcher;
      } else {
        return null;
      }
    },

    livePlayerOneBoxscore() {
      return this.getStats(this.findPlayerBoxscore(this.livePlayerOne.id));
    },

    livePlayerTwoBoxscore() {
      return this.getStats(this.findPlayerBoxscore(this.livePlayerTwo.id));
    },

    livePlayerTwo() {
      if (this.matchupLiveData?.batter?.teamId === this.gameData?.matchup?.home_team?.team_id) {
        return this.matchupLiveData?.batter;
      } else if (this.matchupLiveData?.pitcher?.teamId === this.gameData?.matchup?.home_team?.team_id) {
        return this.matchupLiveData?.pitcher;
      } else {
        return null;
      }
    },

    matchupLiveData() {
      return this.gameData?.matchup?.live;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    getStats(player) {
      if (!player) {
        return;
      }
      const playerStats = player?.stats;
      const statResults = [];
      const scoringStats = this.$ScoringStats.getIngameScoringStats(this.sport, player.position);
      for (const stat of scoringStats[0].stats) {
        if (stat.label === 'H/AB' && playerStats?.batter?.H && playerStats?.batter?.AB) {
          statResults.push(playerStats.batter.H + '/' + playerStats.batter.AB);
        } else if (stat.label === 'H/AB') {
          statResults.push('0/0');
        } else if (this.$Helpers.parsePath(stat.label, playerStats) === '1') {
          statResults.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description.removePluralWords());
        } else if (this.$Helpers.parsePath(stat.label, playerStats) && parseInt(this.$Helpers.parsePath(stat.label, playerStats)) !== 0) {
          statResults.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description);
        }
      }
      return (statResults.length >= 1 ? statResults.join(', ') : '-');
    },

    findPlayerBoxscore(playerId) {
      const boxScore = this.gameData.boxscore;
      const res = boxScore.awayteam.find((playerIds) => (playerIds.id === playerId));
      if (res) {
        return res;
      } else {
        return boxScore.hometeam.find((playerIds) => (playerIds.id === playerId));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.liveMatchupHeaders {
  display: flex;
  justify-content: space-between;
  margin: 4px 20px;
  font-weight: bold;
  font-size: 18px;
  height: 20px;
}
.liveMatchups {
  display: flex;
  font-weight: bold;
  margin: 0 20px;
  padding-bottom: 25px;
}
.liveMatchup {
  height: 50px;
  width: 50%;
  display: flex;
  align-items: center;
}
.liveMatchupStats {
  margin: 0 20px;
}

.liveMatchupPlayerName {
  display: flex;
  align-items: center;
}
.liveMatchupsubtext {
  font-weight: initial;
  margin: 0;
  margin-top: 5px;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  max-width: 250px;
}
.onBaseState {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  width: 100px;
  color: var(--obcolor-font-secondary);
}
</style>