<template>
  <!-- Team Stats -->
  <!-- data from awayStatRanks and homeStatRanks -->
  <div v-if="allTeamStats.hasStats" class="teamStatsSection">
    <div class="teamStatsHeaderSection">
      <img class="teamImg" :src="getTeamLogo('visitor_team')" alt="Team Logo">
      <h1 class="sectionHeader">
        Team Stats <span class="teamStatsSubheader">({{ (gameIsLiveOrComplete && this.sport !== 'MLB') ? 'Game' : 'Season' }})</span>
      </h1>
      <img class="teamImg" :src="getTeamLogo('home_team')" alt="Team Logo">
    </div>
    <div v-for="(stats, index2) in allTeamStats.stats" :key="'team_stats_' + index2">
      <div class="teamStats">
        <h4>
          {{ stats.awayteamDisplay ? stats.awayteamDisplay : stats.awayteam }}
        </h4>
        <h4>{{ stats.label }}</h4>
        <h4>
          {{ stats.hometeamDisplay ? stats.hometeamDisplay : stats.hometeam }}
        </h4>
      </div>
      <div class="progress" :style="{'background-color': getTeamColor(gameData.matchup.home_team.team_alias),}">
        <div class="progressBar" role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{width: calculateWidth(stats.awayteam, stats.hometeam),
                   'background-color': getTeamColor(gameData.matchup.visitor_team.team_alias)}"
        >
        </div>
        <div class="progressBarMidpoint">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  data() {
    return {
      teamStatLabels: {
        season: {
          MLB: [
            {stat: 'AVG', label: 'Batting Average'},
            {stat: 'HR', label: 'Home Runs'},
            {stat: 'R', label: 'Runs'},
            {stat: 'ERA', label: 'Earned Run Average'},
          ],
          NFL: [
            {stat: 'PASS_TD', label: 'Passing Touchdowns'},
            {stat: 'RUS_TD', label: 'Rushing Touchdowns'},
            {stat: 'RUS_YDS', label: 'Rushing Yards'},
            // {stat: 'TD', label: 'Touchdowns'},
          ],
          NBA: [
            {stat: 'PTS_PG', label: 'Points Per Game'},
            {stat: 'AST_PG', label: 'Assists Per Game'},
            {stat: 'REB_PG', label: 'Rebounds Per Game'},
            {stat: 'BLK_PG', label: 'Blocks Per Game'},
          ],
          NHL: [
            {stat: 'goals_pergame', label: 'Goals Per Game'},
            {stat: 'pp_percent', label: 'Power Play %'},
            {stat: 'goalsallowed_pergame', label: 'Goals Against Per Game'},
            {stat: 'pk_percent', label: 'Penalty Kill %'},
          ],
        },
        game: {
          NFL: [
            {stat: 'PASS_YDS', label: 'Passing Yards'},
            {stat: 'RUS_YDS', label: 'Rushing Yards'},
            {stat: 'SACK', label: 'Sacks'},
            {stat: 'NFL_TO', label: 'Turnovers'},
          ],
          NBA: [
            {stat: 'FGM', label: 'Field Goals'},
            {stat: 'TPM', label: '3 Pointers'},
            {stat: 'FTM', label: 'Free Throws'},
            {stat: 'REB', label: 'Rebounds'},
            {stat: 'REB_O', label: 'Offensive Rebounds'},
            {stat: 'AST', label: 'Assists'},
            {stat: 'STL', label: 'Steals'},
            {stat: 'BLK', label: 'Blocks'},
            {stat: 'TO', label: 'Turnovers'},
          ],
          NHL: [
            {stat: 'FW', label: 'Faceoffs Won (%)'},
            {stat: 'NHL_PP', label: 'Power Plays'},
            {stat: 'Hits', label: 'Hits'},
            {stat: 'NHL_shots', label: 'Shots'},
            {stat: 'BS', label: 'Blocked Shots'},
            {stat: 'PIM', label: 'Penalty Minutes'},
          ],
        },
      },
    };
  },

  computed: {
    gameIsLiveOrComplete() {
      return this.gameData?.matchup?.live?.status === 'mid-event' || this.gameData?.matchup?.live?.status === 'post-event';
    },

    seasonTeamStats() {
      const seasonTeamStats = {stats: {}};
      if (this.gameIsLiveOrComplete && this.sport !== 'MLB') {
        return seasonTeamStats;
      }

      for (const stats of this.teamStatLabels.season[this.sport]) {
        seasonTeamStats.stats[stats.stat] = {label: stats.label};
        this.processStat(this.gameData.awayStatRanks[stats.stat], stats.stat, 'awayteam', seasonTeamStats);
        this.processStat(this.gameData.homeStatRanks[stats.stat], stats.stat, 'hometeam', seasonTeamStats);
      }
      return seasonTeamStats;
    },

    gameTeamStats() {
      const gameTeamStats = {stats: {}};
      if (!this.gameIsLiveOrComplete || this.sport === 'MLB') {
        return gameTeamStats;
      }

      for (const stats of this.teamStatLabels.game[this.sport]) {
        gameTeamStats.stats[stats.stat] = {label: stats.label};
        if (stats.stat === 'NFL_TO') {
          this.processTurnover('hometeam', gameTeamStats);
          this.processTurnover('awayteam', gameTeamStats);
        } else if (stats.stat === 'NHL_PP') {
          this.processStat(this.gameData.matchup.liveStats.powerplay_stats?.home_ppgoals, stats.stat, 'hometeam', gameTeamStats);
          this.processStat(this.gameData.matchup.liveStats.powerplay_stats?.away_ppgoals, stats.stat, 'awayteam', gameTeamStats);
          this.addPPGoalStat(gameTeamStats);
        } else if (stats.stat === 'NHL_shots') {
          this.processStat(this.gameData.matchup.live.hometeam?.attempts, stats.stat, 'hometeam', gameTeamStats);
          this.processStat(this.gameData.matchup.live.awayteam?.attempts, stats.stat, 'awayteam', gameTeamStats);
        } else {
          this.processStat(this.gameData.matchup.liveStats.awayteam[stats.stat], stats.stat, 'awayteam', gameTeamStats);
          this.processStat(this.gameData.matchup.liveStats.hometeam[stats.stat], stats.stat, 'hometeam', gameTeamStats);
        }

        if (stats.stat === 'FW') {
          this.addStatPercentage(this.gameData.matchup.liveStats.awayteam[stats.stat], this.gameData.matchup.liveStats.hometeam[stats.stat], stats.stat, gameTeamStats);
        }
      }

      return gameTeamStats;
    },

    allTeamStats() {
      if (!this.gameIsLiveOrComplete || this.sport === 'MLB') {
        // MLB does not currently have the appropriate in-game statistics, default to seasonal statistics
        // Seasonal team statistics
        return this.seasonTeamStats;
      } else {
        // Game team statistics
        return this.gameTeamStats;
      }
    },
  },

  methods: {
    calculateWidth(stat1, stat2) {
      if (parseFloat(stat1) === 0 && parseFloat(stat2) === 0) {
        return '50%';
      }
      const width = 100 * (stat1 / (parseFloat(stat1) + parseFloat(stat2)));
      return width + '%';
    },
    getTeamColor(team) {
      if (team != null) {
        return this.$TeamInfo.getPrimaryTeamColor(team, this.sport);
      }
      return 'var(--obcolor-background-2)';
    },
    getTeamLogo(awayHome) {
      const teamName = this.gameData.matchup[awayHome].team_alias.toLowerCase();
      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ teamName +'.png');
    },
    processStat(statSource, stat, awayHome, teamStats) {
      const value = statSource?.hasOwnProperty('value') ? statSource.value : statSource;
      if (value !== '') {
        teamStats.stats[stat][awayHome] = value;
        teamStats.hasStats = true;
      }
    },
    processTurnover(awayHome, teamStats) {
      if (this.gameData.matchup.liveStats[awayHome]['FBL_L'] !== '' && this.gameData.matchup.liveStats[awayHome]['INT'] !== '') {
        teamStats.stats['NFL_TO'][awayHome] = parseInt(this.gameData.matchup.liveStats[awayHome]['FBL_L']) + parseInt(this.gameData.matchup.liveStats[awayHome]['INT']);
        teamStats.hasStats = true;
      }
    },
    percentage(stat1, stat2) {
      const statPrimary = parseInt(stat1);
      const statSecondary = parseInt(stat2);
      if (statPrimary === 0 && statSecondary === 0) {
        return '-';
      }
      return (statPrimary / (statPrimary + statSecondary) * 100).toFixed(1);
    },
    addStatPercentage(awayStat, homeStat, stat, teamStats) {
      teamStats.stats[stat].awayteamDisplay = teamStats.stats[stat].awayteam + ' (' + this.percentage(awayStat, homeStat) + '%)';
      teamStats.stats[stat].hometeamDisplay = '(' + this.percentage(homeStat, awayStat) + '%) ' + teamStats.stats[stat].hometeam;
    },
    addPPGoalStat(teamStats) {
      teamStats.stats['NHL_PP'].awayteamDisplay = teamStats.stats['NHL_PP'].awayteam + '/' + this.gameData.matchup.liveStats.powerplay_stats?.away_powerplays;
      teamStats.stats['NHL_PP'].hometeamDisplay = teamStats.stats['NHL_PP'].hometeam + '/' + this.gameData.matchup.liveStats.powerplay_stats?.home_powerplays;
    },
  },
};
</script>

<style lang="scss" scoped>
.teamStatsHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.teamImg {
  height: 30px;
  margin-right: 5px;
  opacity: 0.8;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}
.teamStatsSubheader{
  color: var(--obcolor-font-secondary);
  font-size: 18px;
  font-weight: initial;
}
.teamStatsSection {
  text-align: center;
  margin: 0 20px;
  margin-bottom: 20px;
}
.teamStats h4 {
  font-weight: initial;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.teamStats {
  margin: 0px 5px;
  display:flex;
  justify-content: space-between;
}
.progress {
  height: 5px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.progressBarMidpoint {
  height: 5px;
  overflow: hidden;
  background-color: var(--obcolor-background-page);
  border-radius: 6px;
  position: relative;
  left: -3px;
  width: 6px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.progressBar {
  float: left;
  height: 100%;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
}
</style>