<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 20px;"
    :hideCloseButton="true"
  >
    <div slot="body" class="body">
      <div class="title">Lineup Updated</div>
      <div class="paragraph">
        <div style="margin-top: 5px;">Your changes have been saved.</div>
        <div style="margin-top: 4px;">You can continue to modify your lineups up until the contest start time.</div>
      </div>
    </div>
    <div slot="footer" class="footer">
      <button class="ob-btn-grey" @click="closeModal()">Continue</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      contest: null,
    };
  },

  created() {
    EventBus.$on('SALARY_CAP_EDIT_SUCCESS', this.openModal);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_EDIT_SUCCESS', this.openModal);
  },

  methods: {
    openModal(contest) {
      this.contest = contest;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.contest = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .paragraph {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }

  .paragraph:not(:first-child) {
    margin-top: 10px;
  }
}

.footer {
  background: var(--obcolor-background-5);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 14px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.contestText {
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}
</style>