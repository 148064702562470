// @ts-check

/** @typedef {import('vuex').Module} Module */

/** @typedef {'realMoney' | 'ownersBucks' | 'tickets'} Currency */

/** @type {Module} */
export const PlayerPicksModule = {
  namespaced: true,
  state() {
    return {
      currency: 'realMoney',
      contestType: 'STANDARD',

      settings: {
        payoutMultipliers: {},
        protectedPayoutMultipliers: {},
        maxPicks: 6,
        protectedMaxPicks: 6,
        minPicks: 2,
        protectedMinPicks: 2,
        minEntryFee: 100, // default to $1
        maxEntryFee: 10000, // default to $100
        isMLBActive: false,
        isNBAActive: false,
        isNHLActive: false,
        isNFLActive: false,
      },
      settingsError: null,

      selectedMarkets: [],
    };
  },
  mutations: {
    /**
     * @param {*} state
     * @param {Currency} currency
     */
    setCurrency(state, currency) {
      state.currency = currency;
    },

    setContestType(state, contestType) {
      state.contestType = contestType;
    },

    setSettings(state, settings) {
      state.settings = settings;
    },

    setSettingsError(state, error) {
      state.settingsError = error;
    },

    setSelectedMarkets(state, selectedMarkets) {
      state.selectedMarkets = selectedMarkets;
    },
  },
  getters: {
    currency(state) {
      return state.currency;
    },

    contestType(state) {
      return state.contestType;
    },

    propsSettings(state) {
      return state.settings;
    },

    propsSettingsError(state) {
      return state.settingsError;
    },

    selectedMarkets(state) {
      return state.selectedMarkets;
    },

    discountedMarkets(state) {
      return state.selectedMarkets.filter((m) => m.isDiscounted);
    },
    hasDiscounts(state, getters) {
      return getters.discountedMarkets.length > 0;
    },

    overallMaxPicks(state, getters) {
      if (getters.hasDiscounts) {
        return Math.max(state.settings.discountMaxPicks, state.settings.protectedDiscountMaxPicks);
      }
      return Math.max(state.settings.maxPicks, state.settings.protectedMaxPicks);
    },

    contestMinPicks(state, getters) {
      if (getters.hasDiscounts) {
        return state.contestType == 'STANDARD' ? state.settings.discountMinPicks : state.settings.protectedDiscountMinPicks;
      }
      return state.contestType == 'STANDARD' ? state.settings.minPicks : state.settings.protectedMinPicks;
    },
    contestMaxPicks(state, getters) {
      if (getters.hasDiscounts) {
        return state.contestType == 'STANDARD' ? state.settings.discountMaxPicks : state.settings.protectedDiscountMaxPicks;
      }
      return state.contestType == 'STANDARD' ? state.settings.maxPicks : state.settings.protectedMaxPicks;
    },
  },
};