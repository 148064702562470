<template>
  <div class="progressStepContainer">
    <template v-for="step in totalSteps">
      <div :key="step" class="progressStep" :class="{ progressFilled: progressSteps[step] == true }">
        <div class="progressBar"></div>
        <div class="progressText">{{ getStepInfo(step).label }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    customCurrentStep: {type: Number, default: 1},
    totalSteps: {type: Number, default: 3},
  },


  computed: {
    progressSteps() {
      if (this.customCurrentStep != null) {
        const stepsObject = {
          1: false,
          2: false,
          3: false,
        };

        for (let i = 1; i <= 3; ++i) {
          if (i <= this.customCurrentStep) {
            stepsObject[i] = true;
          }
        }
        return stepsObject;
      }

      return {
        1: this.isDepositVerified === true,
        2: this.hasDeposited === true,
        3: this.hasEnteredContests,
      };
    },

    totFinishedSteps() {
      let tot = 0;
      for (let i = 1; i <= 3; ++i) {
        if (this.progressSteps[i]) {
          ++tot;
        }
      }

      return tot;
    },
  },

  methods: {
    getStepInfo(n) {
      if (this.totalSteps === 3) {
        switch (n) {
          case 1: return {num: 'One', label: 'T & C'};
          case 2: return {num: 'Two', label: 'Found us?'};
          case 3: return {num: 'Three', label: 'Enable notifications'};
          default: return {};
        }
      } else if (this.totalSteps === 2) {
        switch (n) {
          case 1: return {num: 'One', label: 'Verify Account'};
          case 2: return {num: 'Two', label: 'Deposit'};
          default: return {};
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progressStepContainer {
  display: flex;
  gap: 10px;
  line-height: 12px;
}

.progressStep {
  text-align: left;
  color: var(--obcolor-font-secondary);
  font-size: 16px;
}

.progressStep.progressFilled {
  color: var(--obcolor-font-primary);
}

.progressStep .progressBar {
  height: 5px;
  width: 223px;
  background: var(--obcolor-background-1);
  border-radius: 10px;
}

.progressStep.progressFilled .progressBar {
  background: var(--obcolor-ob-blue);
}

.progressText {
  margin-top: 12px;
}
</style>