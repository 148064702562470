<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 670px; height: 397px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0 13px; background: var(--obcolor-background-3); overflow: unset !important;"
    :defaultHeaderStyle="true"
  >
    <div slot="header" class="modalHeader">
      <div>Notification Settings</div>
    </div>

    <div slot="body" class="modalBody">
      <div class="sliderListContainer">
        <div class="notificationHeader">Player Alerts</div>
        <div v-for="(alert, index) in alerts.player" :key="index" style="display: flex; align-items: center;">
          <div style="flex: 1; font-size: 14px;">{{ alert.name }}</div>
          <Checkbox v-model="pushSettings[alert.id]" type="switch" />
        </div>
      </div>
      <div class="sliderListContainer">
        <div class="notificationHeader">Contest Alerts</div>
        <div style="font-size: 14px; color: var(--obcolor-font-secondary);">Coming soon</div>
      </div>
      <div class="sliderListContainer">
        <div class="notificationHeader">Other Alerts</div>
        <div style="font-size: 14px; color: var(--obcolor-font-secondary);">Coming soon</div>
      </div>
    </div>

    <div slot="footer" class="modalFooter">
      <button class="ob-btn-grey" style="margin-right: 10px; width: 93px;" @click="closeModal()">Cancel</button>
      <button v-if="!processing" class="ob-btn" style="width: 93px;"
        @click="save()"
        v-tooltip.top.notrigger="{ content: result, class: 'ObTooltip ob-tooltip-fadeout', visible: result != '' }"
      >
        Save
      </button>
      <button v-else class="ob-btn-grey" style="width: 93px;">
        <i class="fad fa-spinner-third fa-spin" style="margin-right: 1px;"></i>
        Saving...
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import Checkbox from '@/components/Checkbox';
import ObAccountApi from '@/api/ObAccountApi';

export default {
  components: {
    Modal,
    Checkbox,
  },

  data() {
    return {
      isOpen: false,
      processing: false,
      result: '',
      resultTimeout: null,

      pushSettings: null,
      alerts: {
        player: [
          {name: 'Player News', id: 'NEWS', active: false},
          {name: 'Status Changes', id: 'STATUS_CHANGE', active: false},
          {name: 'Fantasy Updates', id: 'GAME_UPDATE', active: false},
          {name: 'Transactions', id: 'TRANSACTION', active: false},
        ],

        contest: [
        ],

        other: [
        ],
      },
    };
  },

  created() {
    EventBus.$on('NOTIFICATION_SETTINGS_OPEN', this.openModal);
  },

  destroyed() {
    EventBus.$off('NOTIFICATION_SETTINGS_OPEN', this.openModal);
  },

  watch: {
    result(newVal) {
      const self = this;
      if (newVal != '') {
        clearTimeout(this.resultTimeout);
        this.resultTimeout = setTimeout(function() {
          self.resetResult();
        }, 3000);
      }
    },
  },

  methods: {
    openModal(pushSettings) {
      this.pushSettings = pushSettings;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    save() {
      this.processing = true;
      ObAccountApi.updatePushSettings(this.pushSettings)
          .then((response) => {
            EventBus.$emit('UPDATE_PUSH_SETTINGS', this.pushSettings);
            this.processing = false;
            this.result = 'Settings saved';
          }).catch((error) => {
            if (error.response && error.response.data) {
              this.result = error.response.data;
            } else {
              this.result = 'Error updating settings';
            }
            this.processing = false;
          });
    },

    resetResult() {
      this.result = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}
.modalBody {
  flex: 1;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;

  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.sliderListContainer {
  padding: 5px 10px;
  border-radius: 5px;
  background: var(--obcolor-background-4);
  flex: 1;
}

.sliderListContainer:not(:first-child) {
  margin-left: 5px;
}

.sliderListContainer:not(:last-child) {
  margin-right: 5px;
}

.notificationHeader {
  font-size: 16px;
  font-weight: bold;
}

.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.resultMessage {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: red;
}
</style>