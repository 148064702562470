<template>
  <div class="container">
    <Modal v-if="isOpen"
      v-model="isOpen"
      :hideCloseButton="true"
      containerStyle="width: 540px; overflow: hidden; padding: 0;"
    >
      <div class="headerContainer" slot="header">
        <img v-if="theme === 'dark'" class="modalBg" :src="require('@/assets/onboarding/bg1.png')"
          alt="modal background"
        >
        <img class="checkmark" :src="require('@/assets/onboarding/greenCheckmark.svg')" alt="checkmark">

        <div class="modalTitle">Amount deposited</div>
        <div class="depositAmount">${{ depositAmount }}</div>
        <div class="transactionDate">{{ transactionDate }}</div>
        <div v-if="transactionId" class="transactionInfo">ID: {{ transactionId }}</div>
      </div>
      <div class="modalBody" slot="body">
        <div v-if="isFirstTimeDeposit" class="bonusText">You have received a deposit bonus of <span class="bonusAmount">{{ bonusAmount }} OwnersBucks</span>.Your OwnersBucks become redeemable for tickets as you play real-money contests!</div>
        <img v-if="isFirstTimeDeposit" :src="require('@/assets/onboarding/giftMoney.png')" alt="money box">
        <router-link v-if="$route.name === 'deposit'" :to="lastVisitedRedirect">
          <div class="ob-btn modalBtn">Done</div>
        </router-link>

        <button v-else-if="showConnectKroger" class="ob-btn modalBtn" @click="openKrogerModal">Continue</button>
        <router-link v-else to="/start-playing">
          <div class="ob-btn modalBtn">Continue</div>
        </router-link>
      </div>
    </Modal>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal.vue';
import {mapState} from 'vuex';

export default {
  name: 'DepositModal',

  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      isFirstTimeDeposit: false,
      depositAmount: null,
      transactionId: null,
      transactionDate: null,
      showConnectKroger: false,
    };
  },

  computed: {
    ...mapState(['theme', 'lastVisitedPage']),

    bonusAmount() {
      return this.depositAmount > 500 ? 500 : this.depositAmount;
    },

    lastVisitedRedirect() {
      const lastVisitedPage = this.lastVisitedPage;
      const shouldRedirect = lastVisitedPage.includes('/player-picks/') || lastVisitedPage.includes('/lobby') || lastVisitedPage.includes('/lightning-lineups/') ||
        lastVisitedPage.includes('/salarycap/') || lastVisitedPage.includes('/lineup-builder/');
      if (lastVisitedPage && shouldRedirect) {
        return lastVisitedPage;
      } else {
        return '/transactions';
      }
    },
  },

  created() {
    EventBus.$on('OPEN_DEPOSIT_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_DEPOSIT_MODAL', this.openModal);
  },

  methods: {
    openModal(depositValue, firstTimeDeposit, fiservTransactionId, showConnectKroger = false) {
      this.depositAmount = depositValue;
      this.isFirstTimeDeposit = firstTimeDeposit;
      this.transactionId = fiservTransactionId;
      this.transactionDate = this.$moment().format('MMMM Do, YYYY - h:mm A');
      this.showConnectKroger = showConnectKroger;
      this.isOpen = true;
    },

    goNext() {
      EventBus.$emit('ON_BOARDING_NEXT');
      this.isOpen = false;
    },

    openKrogerModal() {
      EventBus.$emit('ON_BOARDING_OPEN_KROGER_MODAL');
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.headerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 20px 0;
  font-weight: 700;
  position: relative;
  width: 100%;

  .modalTitle {
    padding-top: 10px;
    font-size: 18px;
    z-index: 10;
  }
  .depositAmount {
    font-size: 36px;
    font-weight: 700;
    line-height: 60px;
    z-index: 10;
  }
  .modalBg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
  }
  .checkmark {
    width: 70px;
    z-index: 10;
  }
}
.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bonusText {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0;
    line-height: 21px;
    text-align: center;
    .bonusAmount {
      font-weight: 900;
      padding-bottom: 20px;
    }
  }
  img {
    width: 100px;
    margin: 0 auto 20px auto;
  }
  .modalBtn {
    box-sizing: border-box;
    min-height: 56px;
    flex: 1;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.transactionDate {
  font-size: 14px;
  z-index: 10;
  line-height: 30px;
  font-weight: 400;
  padding-top: 10px;
}

.transactionInfo {
  color: var(--obcolor-font-primary);
  z-index: 10;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 0;
  line-height: 20px;
}
</style>
