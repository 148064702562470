<template>
  <div>
    <table v-if="userTeam" aria-label="Lightning Live Table">
      <tr>
        <th class="rankSection" :colspan="oppTeam == null ? '1' : '2'">
          <div class="nameRank">
            <div v-if="userTeam.rank > 1 || userTeam.isTied" class="teamRank">{{ getRank(userTeam) }}</div>
            <i v-else class="fas fa-trophy teamRank"></i>
            <div class="teamName">
              <div class="nameText">{{ teamName }}</div>
              <div v-if="userTeam.isUserTeam" class="starText">
                <i class="fas fa-star"></i>
                Your Lineup
              </div>
            </div>
          </div>
        </th>
        <th v-if="oppTeam == null">
          <div class="pieChartSection">
            <PieChart size="24" :slices="teamPieSlices" />
            <div class="upcomingGames">{{ numUpcomingGames }} Game{{ numUpcomingGames != 1 ? 's' : '' }} Left</div>
          </div>
        </th>
        <th class="pointsHeader">
          <div class="teamPoints" :class="{ redText: userTeam.fpts < 0 }">{{ userTeam.fpts.toFixed(2) }}</div>
          <div class="teamProj">{{ userTeam.projection.toFixed(2) }}</div>
        </th>

        <!-- Dropdown -->
        <th class="dropdownHeader">{{ oppTeam != null ? 'vs' : '' }}</th>
        <template v-if="oppTeam != null">
          <th class="pointsHeader oppSection">
            <div class="teamPoints">{{ oppTeam.fpts.toFixed(2) }}</div>
            <div class="teamProj">{{ oppTeam.projection.toFixed(2) }}</div>
          </th>
          <th class="rankSection oppSection" colspan="2">
            <div v-if="!oppTeam.isRemoved" class="nameRank">
              <div class="teamName">
                <div class="nameText">Opponent Lineup</div>
              </div>
              <div v-if="oppTeam.rank > 1 || oppTeam.isTied" class="teamRank">{{ getRank(oppTeam) }}</div>
              <i v-else class="fas fa-trophy teamRank"></i>
            </div>
            <div v-else class="headerCancelled">Lineup Cancelled</div>
          </th>
        </template>
      </tr>
      <template v-for="(player, pIndex) in userTeam.players">
        <LightningLivePlayerRow :key="'player_' + pIndex" :player="player" :contest="contest"
          :oppPlayer="oppPlayer(pIndex)" :dropdownOpen="dropdownOpenMap[player.id]"
        />
        <LightningLiveDropdown v-if="dropdownOpenMap[player.id] == true" :key="'dropdown_' + pIndex" :player="player"
          :contest="contest" :oppPlayer="oppPlayer(pIndex)"
        />
      </template>
    </table>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart';
import LightningLivePlayerRow from './LightningLivePlayerRow';
import LightningLiveDropdown from './LightningLiveDropdown';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    contest: {type: Object, default: null},
    userTeam: {type: Object, default: null},
    oppTeam: {type: Object, default: null},
  },

  components: {
    PieChart,
    LightningLivePlayerRow,
    LightningLiveDropdown,
  },

  data() {
    return {
      dropdownOpenMap: {},
    };
  },

  created() {
    for (const player of this.userTeam.players) {
      this.$set(this.dropdownOpenMap, player.id, false);
    }

    EventBus.$on('LL_LIVE_TOGGLE_DROPDOWN', this.toggleDropdown);
  },

  destroyed() {
    EventBus.$off('LL_LIVE_TOGGLE_DROPDOWN', this.toggleDropdown);
  },

  computed: {
    ...mapState(['teamNames']),

    teamName() {
      if (!this.teamNames['teamName' + this.sport]) {
        return 'Your Team';
      }
      return this.teamNames['teamName' + this.sport] + ' Team';
    },

    sport() {
      return this.contest.sport;
    },

    numUpcomingGames() {
      return this.playersByGameStatus.upcoming.length;
    },

    playersByGameStatus() {
      const playerGameStatus = {
        upcoming: [],
        completed: [],
        // Catch all for players who don't have games or games don't have status
        unplayed: [],
      };
      if (!this.userTeam && !this.userTeam.players) {
        return playerGameStatus;
      }

      // Sort players into categories based on their game's status
      for (const player of this.userTeam.players) {
        if (player.game && player.game.gameCompleted === true) {
          playerGameStatus.completed.push(player);
        } else if (player.game && player.game.gameCompleted === false) {
          playerGameStatus.upcoming.push(player);
        } else {
          playerGameStatus.unplayed.push(player);
        }
      }
      return playerGameStatus;
    },

    teamPieSlices() {
      const slices = [];
      const teamInfo = this.playersByGameStatus;
      const teamSize = this.userTeam.players ? this.userTeam.players.length : 0;
      const pct = Math.round( teamInfo.upcoming.length / teamSize * 100 );

      const baseColor = 'var(--obcolor-ob-blue)';
      const emptyColor = 'var(--obcolor-background-2)';

      slices.push({color: emptyColor, percent: 100 - pct});
      slices.push({color: baseColor, percent: pct});
      return slices;
    },
  },

  methods: {
    oppPlayer(index) {
      if (this.oppTeam != null) {
        return this.oppTeam.players[index];
      }

      return null;
    },

    getRank(team) {
      const tiedPrefix = team.isTied ? 'T-' : '';
      return tiedPrefix + String(team.rank).addSuffix();
    },

    toggleDropdown(pId) {
      this.dropdownOpenMap[pId] = !this.dropdownOpenMap[pId];
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

th {
  padding: 10px 15px;
  box-sizing: border-box;
  height: 64px;
  background: var(--obcolor-background-4);
  font-weight: normal;
}

.headerCancelled {
  font-weight: 700;
  color: var(--obcolor-red);
  font-size: 16px;
  line-height: 19px;
  text-align: right;
}

.dropdownHeader {
  padding: 0;
  text-align: center;
  font-size: 18px;
  color: var(--obcolor-font-secondary);
}

.rankSection {
  width: 300px;
}

.rankSection:not(.oppSection) {
  .teamRank {
    padding-right: 20px;
  }
}

.rankSection.oppSection {
  .nameRank {
    justify-content: flex-end;
  }
  .teamRank {
    padding-left: 20px;
  }
}

// Table header section
.nameRank {
  display: flex;
  align-items: center;

  .teamRank {
    font-size: 18px;

    &.fa-trophy {
      font-size: 20px;
    }
  }

  .teamName {
    font-size: 18px;
    font-weight: bold;

    .nameText {
      max-width: 210px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.starText {
  font-size: 16px;
  font-weight: normal;

  .fa-star {
    font-size: 14px;
  }
  color: var(--obcolor-flash-orange);
}

.fa-trophy {
  color: var(--obcolor-trophy-1st);
}

.pieChartSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .upcomingGames {
    font-size: 14px;
  }
}

.pointsHeader:not(.oppSection) {
  text-align: center;
}

.pointsHeader.oppSection {
  text-align: center;
}

.teamPoints {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 18px;
}

.teamProj {
  color: var(--obcolor-font-secondary);
  font-size: 16px;
}

.redText {
  color: var(--obcolor-red) !important;
}

</style>