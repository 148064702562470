<template>
  <div :style="'color: ' + getStatusColor()">
    {{ getDetailsText() }}
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    injDetails: Object,
  },

  methods: {
    getDetailsText() {
      // Don't show details if status is active
      if (this.status === 'ACT') {
        return '';
      }
      if (this.injDetails && this.injDetails.ext_desc) {
        return this.injDetails.ext_desc;
      }
      return '';
    },

    getStatusColor() {
      switch (this.status) {
        case 'ACT': return '#4EB959';
        case 'AVA':
        case 'TBA':
        case 'BKUP': return '#F2994A';
        case 'OUT':
        case 'DNP':
        case 'SUSP':
        case 'INJ':
        case 'MIN': return '#E5252C';
      }
      return '#E5252C';
    },
  },
};
</script>
