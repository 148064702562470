<template>
  <div class="ob-btn draftButton" style="display: flex;"
    v-if="draftState.isMyTurn && player !== null && !draftState.pickedPlayerIds[player.id] && !teamRosterIsFull(player) && draftState.pickInProgress"
  >
    <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
    DRAFTING
  </div>
  <div class="ob-btn draftButton"
    v-else-if="draftState.isMyTurn && player !== null && !draftState.pickedPlayerIds[player.id] && !teamRosterIsFull(player)"
    @click="draftPlayer()"
  >
    DRAFT
  </div>
  <div class="ob-btn-grey draftButton" v-else-if="player === null" disabled>INVALID</div>
  <div class="ob-btn-grey draftButton" v-else-if="draftState.pickedPlayerIds[player.id]" disabled>DRAFTED</div>
  <div class="ob-btn-grey draftButton" v-else-if="teamRosterIsFull(player)" disabled>SLOT FULL</div>
  <div class="ob-btn-grey draftButton" v-else disabled>DRAFT</div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    draftState: Object,
    player: Object,
  },
  methods: {
    draftPlayer() {
      EventBus.$emit('PLAYER_DRAFTED', this.player);
    },
    teamRosterIsFull(player) {
      if (player === null) {
        return false;
      }
      for (const rosterIndex in this.draftState.roster) {
        if (this.draftState.roster[rosterIndex].short === player.draftPos && this.draftState.roster[rosterIndex].num === this.draftState.roster[rosterIndex].players.length) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.draftButton {
  height: 35px;
  box-sizing: border-box;
}
</style>