<template>
  <div class="playerBoxesContainer">
    <div class="ob-box userListBox">
      <div class="boxTitle">
        <div>Pending</div>
      </div>
      <div class="userListScroll ob-scroll">
        <div v-for="(userId, index) in league.invitedUsers" :key="index + 'pending'" class="userRow">
          <UserLogo class="userLogo" :size="30" :id="userId"
            :sport="league.sport"
          />
          <div class="teamNameContainer">
            <div class="userTeam">{{ league.userInfo[userId].team }}</div>
            <div class="userName">{{ league.userInfo[userId].name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ob-box ob-scroll userListBox userListScroll">
      <div class="boxTitle">
        <div style="display: flex;">
          <i :class="'caretIcon fa fa-caret-down'"></i>
          <div>Accepted</div>
        </div>
        <div>{{ league.users.length }}</div>
      </div>
      <div v-for="(userId, index) in league.users" :key="index + 'accepted'" class="userRow">
        <UserLogo class="userLogo" :size="30" :id="userId"
          :sport="league.sport"
        />
        <div class="teamNameContainer">
          <div class="userTeam">{{ league.userInfo[userId].team }}</div>
          <div class="userName">{{ league.userInfo[userId].name }}</div>
        </div>
      </div>
      <div class="boxTitle">
        <div style="display: flex;">
          <i :class="'caretIcon fa fa-caret-down'"></i>
          <div>Declined</div>
        </div>
      </div>
      <div v-for="(userId, index) in league.declinedInvites" :key="index + 'pending'" class="userRow">
        <UserLogo class="userLogo" :size="30" :id="userId"
          :sport="league.sport"
        />
        <div class="teamNameContainer">
          <div class="userTeam">{{ league.userInfo[userId].team }}</div>
          <div class="userName">{{ league.userInfo[userId].name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';

export default {
  components: {
    UserLogo,
  },
  props: {
    league: Object,
  },
};
</script>

<style lang="scss" scoped>
.referBtn {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 0;
}
.caretIcon {
  margin-right: 5px;
}
.playerBoxesContainer {
  display: flex;
  height: 100%;
}
.userListBox {
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-3);
}
.userListBox:first-child {
  margin-right: 10px;
}
.userListScroll {
  flex: 1;
  overflow: auto;
}

.boxTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.boxTitle:not(:first-child) {
  margin-top: 5px;
}

.entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}
.entryHeading {
  color: var(--obcolor-font-secondary);
}

.userRow {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  background: var(--obcolor-background-2);
}

.userLogo {
  margin-right: 10px;
}

.teamNameContainer {
  flex: 1;
  overflow: hidden;
}
.userTeam {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}
.userName {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}
</style>