<template>
  <div class="container">
    <div v-if="selectedMethod" class="arrowContainer">
      <a class="backBtn" @click="unsetSource"><i class="far fa-chevron-left"></i></a>
    </div>
    <template v-if="!selectedMethod">
      <p class="depositText">Choose your withdrawal method</p>
      <div v-for="(source, index) in sources" :key="index" class="checkboxContainer"
        :class="{noSelect: !canSelect}"
        @click="toggleSource(source)"
      >
        <div class="iconContainer">
          <i v-if="source.iconSrc" :class="source.iconSrc"></i>
          <img v-else-if="source.imgUrl && theme === 'dark'" :src="source.imgUrl" class="methodIcon"
            :alt="source.text"
          >
          <img v-else-if="source.imgUrl && theme === 'light'" :src="source.imgUrlLight" class="methodIcon"
            :alt="source.text"
          >
          <div>
            <div>{{ source.text }}</div>
            <div v-if="source.subtitle" class="methodSubtitle">{{ source.subtitle }}</div>
          </div>
        </div>
        <i class="far fa-chevron-right"></i>
      </div>
    </template>
    <WithdrawCardMethod v-else-if="selectedMethod.type == 'card'"
      :withdrawalValue="withdrawalValue"
    />
    <WithdrawCheckMethod v-else-if="selectedMethod.type == 'check'"
      :withdrawalValue="withdrawalValue"
    />
  </div>
</template>

<script>
import WithdrawCardMethod from './WithdrawCardMethod.vue';
import WithdrawCheckMethod from './WithdrawCheckMethod.vue';
export default {
  props: {
    withdrawalValue: {type: Number, default: null},
    canSelect: {type: Boolean, default: null},
    locationInfo: {type: Object, default: null},
  },

  components: {
    WithdrawCardMethod,
    WithdrawCheckMethod,
  },

  data() {
    return {
      selectedMethod: null,
      sources: [],
    };
  },

  created() {
    this.setSources();
  },

  computed: {
    isDebitValid() {
      return this.locationInfo && this.locationInfo?.isFiservSupported != null ? this.locationInfo.isFiservSupported : true;
    },
  },

  methods: {
    setSources() {
      const sourceList = [
        {
          type: 'check',
          iconSrc: 'fal fa-money-check-edit',
          text: 'Check',
          selected: false,
        },
      ];
      if (this.isDebitValid) {
        sourceList.unshift({
          type: 'card',
          iconSrc: 'fal fa-credit-card',
          text: 'Visa / Mastercard Debit Card',
          subtitle: 'USA Only',
          selected: false,
        });
      }
      this.sources = sourceList;
    },

    toggleSource(source) {
      if (!this.canSelect) {
        return;
      }
      this.sources.forEach((s) => (s.selected = s.text === source.text));
      this.selectedMethod = source;
      this.$emit('isMethodSelected', true);
    },
    unsetSource() {
      this.sources.forEach((s) => (s.selected = false));
      this.selectedMethod = null;
      this.$emit('isMethodSelected', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
.container {
  line-height: 30px;
}
</style>