import Api from '@/api/ObBaseApi';

export default {

  getMyWaiverClaims(leagueId, weekNum) {
    return Api()
        .get('/fsp/waivers/getMyWaiverClaims?league=' + leagueId + '&week=' + weekNum)
        .then((response) => {
          return response.data;
        });
  },

  submitClaim(leagueId, weekNum, toDrop, toAdd) {
    const params = {
      league: leagueId,
      week: weekNum,
      toDrop: toDrop,
      toAdd: toAdd,
    };
    return Api()
        .post('/fsp/waivers/submitClaim', params)
        .then((response) => {
          return response.data;
        });
  },

  cancelClaim(leagueId, weekNum, claimId) {
    const params = {
      league: leagueId,
      week: weekNum,
      claim: claimId,
    };
    return Api()
        .post('/fsp/waivers/cancelClaim', params)
        .then((response) => {
          return response.data;
        });
  },

  updateOrder(leagueId, weekNum, order) {
    const params = {
      league: leagueId,
      week: weekNum,
      order: order,
    };
    return Api()
        .post('/fsp/waivers/updateWaiversOrder', params)
        .then((response) => {
          return response.data;
        });
  },

};