import { render, staticRenderFns } from "./MatchupPlayerInfo.vue?vue&type=template&id=14fbeac7&scoped=true"
import script from "./MatchupPlayerInfo.vue?vue&type=script&lang=js"
export * from "./MatchupPlayerInfo.vue?vue&type=script&lang=js"
import style0 from "./MatchupPlayerInfo.vue?vue&type=style&index=0&id=14fbeac7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14fbeac7",
  null
  
)

export default component.exports