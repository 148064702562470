<template>
  <div class="ob-page container">
    <h1>Sign into your account!</h1>
    <div class="title">Email</div>
    <input placeholder="Enter your Email" type="email" v-model="loginEmail"
      :class="loginError ? 'errorBorder' : ''" @keyup.enter="captchaCheck()"
    >

    <div style="display: flex;">
      <div class="title inputText">Password</div>
      <Tooltip pos="top" textKey="Password_Requirements" />
    </div>
    <div class="passwordContainer">
      <input placeholder="Enter your password" :type="showPassword ? 'text' : 'password'" v-model="loginPassword"
        :class="loginError ? 'errorBorder' : ''" @keyup.enter="captchaCheck()"
      >
      <i v-if="showPassword" @click="toggleShowPassword" class="fas fa-eye"></i>
      <i v-else @click="toggleShowPassword" class="fas fa-eye-slash"></i>
    </div>

    <div class="loginDetail">
      <div class="ob-checkbox-container rememberMe" @click="() => rememberMe = !rememberMe">
        <input type="checkbox" :checked="rememberMe">
        <div class="ob-checkbox"></div>
        <div class="rememberTxt">Remember me</div>
      </div>
      <router-link to="/forgotPassword" class="forgotPass">Forgot Password?</router-link>
    </div>

    <ErrorPanel :message="loginError" />

    <button v-if="waitingForResponse" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>
      Signing in...
    </button>
    <button v-else class="ob-btn" :disabled="disableSignIn"
      @click="captchaCheck()"
    >
      <div>Sign In</div>
    </button>

    <!-- <div class="separator">or</div>
    <button class="ob-btn-grey ssoBtn" @click="googleSignIn()">
      <i class="fab fa-google"></i>
      <p>Sign in with Google</p>
    </button>
    <button class="ob-btn-grey ssoBtn" @click="appleSignIn()">
      <i class="fab fa-apple"></i>
      <p>Sign in with Apple</p>
    </button>
    <button class="ob-btn-grey ssoBtn" @click="krogerSignIn()">
      <img :src="require('@/assets/onboarding/krogerLogo.png')" alt="kroger logo">
      <p>Sign in with Kroger</p>
    </button> -->
    <div class="noAccount">New to OwnersBox? <router-link to="/signup" class="linkText">Sign up</router-link></div>
    <div class="helpTxt">If you or someone you know has a gambling problem, help is available. Call: 1-800-GAMBLER</div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';
import {mapState} from 'vuex';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    Tooltip,
    ErrorPanel,
  },

  data() {
    return {
      loginEmail: '',
      loginPassword: '',
      loginError: null,
      showPassword: false,
      waitingForResponse: false,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      rememberMe: false,

      forgotPassEmail: '',
      forgotPassError: null,
      isForgotPassOpen: false,
      waitingForSendEmail: false,
      emailSent: false,
    };
  },

  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
        'src',
        'https://www.google.com/recaptcha/enterprise.js?render='+this.recaptchaKey,
    );
    document.head.appendChild(recaptchaScript);
  },

  destroyed() {
    const captchaLogo = document.getElementsByClassName('grecaptcha-badge')[0].parentElement;
    document.body.removeChild(captchaLogo);
  },

  computed: {
    ...mapState(['redirectedLink', 'defaultRoute']),
    disableSignIn() {
      return !this.loginEmail || !this.loginPassword;
    },
  },

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    captchaCheck() {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(this.recaptchaKey, {action: 'login'}).then((token) => {
          this.loginUser(token);
        });
      });
    },

    loginUser(token) {
      if (this.loginEmail.trim().length == 0 || this.loginPassword.trim().length == 0) {
        this.loginError = 'Sorry, you entered invalid credentials';
        return;
      }

      this.waitingForResponse = true;
      this.loginError = '';
      const afUserId = this.$cookie.get('afUserId');

      ObAccountApi.loginUser(this.loginEmail.trim(), this.loginPassword, this.rememberMe, token, afUserId)
          .then((data) => {
            this.waitingForResponse = false;

            if (data.userState && data.userState === 'WAITLIST') {
              this.$store.commit('setUserState', 'WAITLIST');
              this.$router.push('/waitlist');
            } else if (data.userId) {
              this.$store.commit('setUserState', data.userState);
              this.$store.commit('setNodeToken', data.st);
              this.$store.commit('setLoggedIn', data.userId);

              if (this.redirectedLink != null) {
                this.$router.push(this.redirectedLink);
              } else {
                this.$router.push(this.defaultRoute);
              }

              EventBus.$emit('LOGIN');
            }
          })
          .catch((error) => {
            this.handleLoginErrors(error);
          });
    },

    handleLoginErrors(error) {
      this.waitingForResponse = false;
      this.loginPassword = '';
      if (error.response && error.response.data === 'failed') {
        this.loginError = 'Sorry, you entered invalid credentials';
      } else if (error.response && error.response.data && error.response.data !== '') {
        // In case the account is disabled or rate-limit has been reached
        this.loginError = error.response.data;
      } else {
        this.loginError = 'Login failed, please contact customer support';
      }
    },

    sendForgotPass() {
      this.waitingForSendEmail = true;

      if (this.forgotPassEmail.trim().length == 0) {
        this.forgotPassError = 'Please enter a valid email';
        this.waitingForSendEmail = false;
        return;
      } else {
        this.forgotPassError = null;
      }

      const params = {email: this.forgotPassEmail.trim()};

      ObAccountApi.forgotPassword(params)
          .then((success) => {
            this.emailSent = true;
            this.waitingForSendEmail = false;
          })
          .catch((error) =>{
            this.waitingForSendEmail = false;
            if (error.response != null && error.response.data != null) {
              this.forgotPassError = error.response.data;
            } else {
              this.forgotPassError = 'Invalid email';
            }
          });
    },

    closeForgotPassword() {
      this.isForgotPassOpen = false;
      this.emailSent = false;
      this.forgotPassError = null;
      this.forgotPassEmail = '';
    },

    appleSignIn() {
      // Sign in with apple
    },

    googleSignIn() {
      // Sing in with Google
    },

    krogerSignIn() {
      // Sing in with Kroger
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

h1 {
  padding: 50px 0 40px 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 705px;
  margin: 0 auto;

  > input,
  .passwordContainer input {
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
    padding: 14px;
    font-size: 18px;
    margin-bottom: 15px;
    height: 22px;
    flex: 1;
  }

  .ob-btn, .ob-btn-grey {
    margin-top: 15px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 18px;
      margin-right: 6px;
    }
  }

  .ssoBtn {
    margin-top: 15px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 18px;
      padding-right: 10px;
    }
    i {
      font-size: 18px;
      margin-right: 10px;
    }
    p {
      padding-top: 3px;
    }
  }

  .title {
    padding: 0 0 10px 0;
  }
}

.errorBorder {
  border: 2px solid var(--obcolor-red) !important;
}

.ob-btn[disabled] {
  background: var(--obcolor-font-secondary);
  color: var(--obcolor-background-2) !important;
  cursor: not-allowed;
}
.ob-btn:focus,
.ob-btn-grey:focus{
  border: 2px solid #08299F;
}

.noAccount {
  padding-top: 35px;
  text-align: center;

  .linkText {
    cursor: pointer;
    font-weight: 900;
    color: var(--obcolor-ob-blue);
    &:hover {
      text-decoration: underline;
    }
  }
}

.passwordContainer {
  position: relative;
  display: flex;

  input {
    flex: 1;
  }
}

.loginDetail {
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.ob-checkbox-container {
  &:hover input:not(:disabled) ~ .ob-checkbox {
    background-color: var(--obcolor-bg-input) !important;
  }
  input:checked:not(:disabled) ~ .ob-checkbox {
    background-color: var(--obcolor-bg-input) !important;
  }
}

.rememberMe{
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  .ob-checkbox {
    border: 1px solid var(--obcolor-font-primary);
    background: var(--obcolor-bg);
  }
  .rememberTxt {
    margin-left: 30px;
    white-space: nowrap;
  }
}

.forgotPass {
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    color: var(--obcolor-ob-blue);
  }
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.separator {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.separator:before, .separator:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
  color: var(--obcolor-font-secondary);
}
.separator:before {
  margin-right: 10px
}
.separator:after {
  margin-left: 10px
}

/* Style autofilled inputs */
input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 1000px var(--obcolor-input-bg) inset;
  -webkit-text-fill-color: var(--obcolor-font-primary);
}

/* Prevent autofill background color change on focus */
input:-webkit-autofill:focus{
  -webkit-box-shadow: 0 0 0 1000px var(--obcolor-input-bg) inset;
  -webkit-text-fill-color: var(--obcolor-font-primary);
}

.helpTxt {
  text-align: center;
  border-top: 1px var(--obcolor-font-secondary) solid;
  padding-top: 50px;
  margin-top: 50px;
}
</style>