<template>
  <Modal v-if="isOpen" v-model="isOpen">
    <div slot="header" style="padding-top: 6px; text-align: center; font-weight: bold;">Draft Board</div>
    <div slot="body">
      <table v-if="draftJSON" style="margin-top: 10px;" aria-label="Draft Board">
        <tr>
          <th style="max-width: 25px; min-width: 25px;"></th>
          <th v-for="userId in draftJSON.users" :key="userId" style="border-radius: 6px 6px 0 0;"
            :style="userId === curUser ? 'background: var(--obcolor-background-page);' : ''"
          >
            <UserLogo style="margin-top: 4px; margin-bottom: 2px;" :id="userId" :sport="draftJSON.sport"
              :size="30"
              :showRank="true" :rankNum="getRankNum(userId)" :rankColor="getRankColor(userId)"
              v-tooltip.bottom="{content: users[userId].team, class:'ObTooltip'}"
            />
          </th>
        </tr>

        <tr v-for="i in 10" :key="i">
          <td class="roundNum">{{ i }}</td>
          <td v-for="pick in picksByRound[i]" :key="'p'+pick.overall"
            :class="'cellSize' + draftJSON.users.length"
            :style="pick.userId === curUser ? 'background: var(--obcolor-background-page);' : ''"
          >
            <div v-if="pick.overall == curPick" class="playerBox curPick" :style="getHighlightColor(pick)">
              <CountdownTimer @tick="setSeconds" :end="draftJSON.turnLimit" :min="0"
                :minText="'autopick'" :clockFormat="true" :serverOffset="serverOffset"
              />
            </div>

            <div v-else-if="pick.playerData" class="playerBox" :style="bgColor[draftJSON.sport][pick.playerData.draftPos]">
              <div>{{ pick.playerData.draftPos }}</div>
              <div class="pName">{{ pick.playerData.name.lastName() }}</div>
            </div>

            <div v-else class="playerBox upcomingPick">
              {{ pick.round + "." + pick.pick }}
            </div>
          </td>
          <td style="text-align: right; max-width: 18px; min-width: 18px; position: relative;">
            <img v-if="i % 2 == 0" class="snake" :src="require('@/assets/draftSnake-'+ theme +'.png')"
              alt="Arrow"
            >
          </td>
        </tr>
      </table>
    </div>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal';
import UserLogo from '@/components/UserLogo';
import CountdownTimer from '@/components/CountdownTimer.vue';
import {mapState} from 'vuex';

export default {

  props: {
    draftJSON: Object,
    curUser: String,
    serverOffset: Number,
    users: Object,
  },

  components: {
    Modal,
    UserLogo,
    CountdownTimer,
  },

  data() {
    return {
      isOpen: false,
      picksByRound: {},
      seconds: null,

      bgColor: {
        NFL: {
          QB: 'background: var(--obcolor-draftboard-1);', RB: 'background: var(--obcolor-draftboard-2);',
          WR: 'background: var(--obcolor-draftboard-3);', TE: 'background: var(--obcolor-draftboard-4);',
          K: 'background: var(--obcolor-draftboard-5);', D: 'background: var(--obcolor-draftboard-6);',
        },
        MLB: {
          'P': 'background: var(--obcolor-draftboard-1);', 'OF': 'background: var(--obcolor-draftboard-4);',
          'IF': 'background: var(--obcolor-draftboard-2)',
          'C': 'background: var(--obcolor-draftboard-3);',
          '1B': 'background: var(--obcolor-draftboard-2)',
          '2B': 'background: var(--obcolor-draftboard-2)',
          '3B': 'background: var(--obcolor-draftboard-2)',
          'SS': 'background: var(--obcolor-draftboard-2)',
        },
        NHL: {
          G: 'background: var(--obcolor-draftboard-1);',
          D: 'background: var(--obcolor-draftboard-6);',
          F: 'background: var(--obcolor-draftboard-2)',
        },
        NBA: {
          PG: 'background: var(--obcolor-draftboard-2);',
          SG: 'background: var(--obcolor-draftboard-3);',
          PF: 'background: var(--obcolor-draftboard-4);',
          SF: 'background: var(--obcolor-draftboard-5);',
          C: 'background: var(--obcolor-draftboard-6);',
        },
      },
    };
  },

  watch: {
    draftJSON() {
      this.setupRounds();
    },
  },

  created() {
    this.setupRounds();
    EventBus.$on('OPEN_DRAFT_BOARD_MODAL', this.openModal);
    EventBus.$on('CLOSE_DRAFT_BOARD_MODAL', this.closeModal);
  },

  destroyed() {
    EventBus.$off('OPEN_DRAFT_BOARD_MODAL', this.openModal);
    EventBus.$off('CLOSE_DRAFT_BOARD_MODAL', this.closeModal);
  },

  computed: {
    ...mapState(['theme']),
    curPick() {
      if (this.draftJSON != null && this.draftJSON.overall != null) {
        return this.draftJSON.overall;
      }
      return 0;
    },
  },

  methods: {

    setSeconds(seconds) {
      this.seconds = seconds;
    },

    openModal() {
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    setupRounds() {
      if (!this.draftJSON || !this.draftJSON.picks) {
        return;
      }
      const picksByRound = {};
      for (const pick of this.draftJSON.picks) {
        const r = pick.round;
        const picks = picksByRound[r] ? picksByRound[r] : [];
        if (r % 2 == 0) {
          picks.unshift(pick);
        } else {
          picks.push(pick);
        }
        picksByRound[r] = picks;
      }
      this.picksByRound = picksByRound;
    },

    getRankColor(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankColor || 'blue';
      }
      return 'blue';
    },
    getRankNum(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankNum || 1;
      }
      return 1;
    },

    getHighlightColor(pick) {
      if (pick.overall === this.curPick) {
        if (this.seconds !== null && pick.userId === this.curUser && this.seconds <= 10) {
          return 'background: var(--obcolor-red);';
        }
        return pick.userId === this.curUser ? 'background: var(--obcolor-ob-blue);' : 'background: #999';
      }
      return '';
    },

  },

};
</script>

<style lang="scss" scoped>

td, th {
  max-width: 90px;
  min-width: 90px;
  // border: 1px solid black;
  padding: 2px;
  text-align: center;
  // overflow: hidden;
}

.cellSize2 {
  max-width: 170px;
  min-width: 170px;
}
.cellSize4 {
  max-width: 140px;
  min-width: 140px;
}
.cellSize8 {
  max-width: 110px;
  min-width: 110px;
}

.playerBox {
  background: var(--obcolor-draftboard-nopick);
  padding: 6px 2px;
  height: 28px;
  color: white;
  position: relative;
}

.curPick {
  // background: var(--obcolor-ob-blue);
  background: grey;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcomingPick {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  // color: var(--obcolor-font-secondary);
}

.pName {
  font-weight: bold;
  white-space: nowrap;
  margin-top: 2px;
  padding: 0 5px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roundNum {
  max-width: 25px;
  min-width: 25px;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
}

.snake {
  position: absolute;
  left: 4px;
  top: -23px;
  height: 50px;
  opacity: 0.9;
}

</style>
