<template>
  <div v-if="sport === 'MLB'">
    <div class="infoText">{{ isHomeGame ? 'vs' : '@' }}</div>
    <div class="infoText">{{ secondTeamAlias }}</div>
    <div class="infoText">{{ score }}</div>
  </div>
  <div v-else>
    {{ $SportInfo.getGameSummary(sport, liveGame, player.team, $moment) }}
  </div>
</template>

<script>
export default {
  props: {
    contest: {type: Object, default: null},
    liveGame: {type: Object, default: null},
    fieldState: {type: Object, default: null},
    player: {type: Object, default: null},
  },

  computed: {
    sport() {
      return this.contest.sport;
    },

    game() {
      return this.player.game;
    },

    fspGame() {
      return this.player.fspGame;
    },

    gameIsLive() {
      return this.liveGame && this.liveGame.live &&
        this.liveGame.live.status === 'mid-event' && this.fieldState != null;
    },

    secondTeamAlias() {
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, this.game.oppAlias);
    },

    score() {
      const playerScore = this.teamScore(this.player.team);
      const opponentScore = this.teamScore(this.game.opponent);
      let score;
      if (playerScore && opponentScore) {
        score = playerScore + '-' + opponentScore;
        if (this.liveGame.live.status == 'post-event') {
          score += '  Final';
        }
        return score;
      }

      const fspGame = this.fspGame;
      // Use the score from fspGame when the liveGame is not available, this is the case for contests in the past
      if (fspGame && fspGame.score && fspGame.score.trim() != '') {
        score = fspGame.score;
        if (fspGame.result) {
          score += '  Final';
        }
        return score;
      }

      return this.gameTime;
    },

    isHomeGame() {
      if (this.game.home != null) {
        return this.game.home === true;
      }
      return this.player.player.teamAlias === this.game.home_team.team_alias;
    },

    gameTime() {
      if (!this.game) {
        return '';
      }
      const game = this.game;

      let gameTimestamp = 0;
      if (game.date) {
        gameTimestamp = game.date;
      } else if (game.date_utc) {
        gameTimestamp = game.date_utc;
      } else {
        gameTimestamp = game.timestamp;
      }

      return this.$moment(gameTimestamp).format('h:mma');
    },

    statLine() {
      if (this.fieldState == null) {
        return '';
      }

      return this.fieldState.statLine;
    },
  },

  watch: {},

  methods: {
    teamScore(teamId) {
      if (this.liveGame && this.liveGame.live && this.liveGame.home_team && this.liveGame.live.hometeam && this.liveGame.live.awayteam) {
        if (this.liveGame.home_team.team_id == teamId) {
          return this.liveGame.live.hometeam.score;
        }

        return this.liveGame.live.awayteam.score;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.infoText:not(:last-child) {
  margin-right: 3px;
}
</style>