<template>
  <form @submit.prevent="checkUsername" class="container">
    <div class="arrowContainer">
      <!-- No Arrows - Keep container for consistent spacing -->
    </div>

    <form @submit.prevent="checkUsername" class="container">
      <h1>Create Username</h1>
      <div style="padding-bottom: 10px;">Username</div>
      <input v-model="username" placeholder="Enter your username" type="text"
        :class="errorMessage ? 'errorBorder' : ''"
      >
      <ErrorPanel :message="errorMessage" />

      <button v-if="loading" class="ob-btn-grey" disabled>
        <i class="fad fa-spinner-third fa-spin"></i>
        Validating...
      </button>
      <button v-else class="ob-btn" :disabled="!username"
        @click="checkUsername()"
      >
        Continue
      </button>
    </form>
  </form>
</template>

<script>
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';

export default {
  components: {
    ErrorPanel,
  },

  data() {
    return {
      username: '',
      loading: false,
      errorMessage: null,
    };
  },

  watch: {
    username(newVal) {
      // Language characters, numbers, punctuation, whitespace
      if (typeof newVal === 'string') {
        const regex = /[^\p{L}\p{N}\p{P}\p{Z}$+=<>|^`~]/gu;
        this.username = newVal.replace(regex, '').replace(/\s+/g, '');
        if (newVal.length > 20) {
          this.username = newVal.slice(0, 20);
        }
      }
    },
  },

  methods: {
    checkUsername() {
      if (this.loading || !this.username) {
        return;
      }
      this.loading = true;
      this.errorMessage = null;
      const userName = this.username.trim();

      ObAccountApi.validateUsername(userName)
          .then((_response) => {
            this.loading = false;
            EventBus.$emit('UPDATE_SIGNUP_DATA', 'userName', this.username);
            EventBus.$emit('ON_BOARDING_NEXT');
          }).catch((error) => {
            this.loading = false;
            if (error?.response?.data) {
              this.errorMessage = error?.response?.data;
            } else {
              this.errorMessage = 'Failed to validate username, please try again';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  button {
    margin-top: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}
</style>