<template>
  <div>
    <!-- Logo -->
    <div class="teamImgArea">
      <div style="flex: 1;"><img :src="require('@/assets/icons/sporticon_' + sport + '.png')" :alt="sport" style="height: 30px;"></div>
      <div class="logoContainer"><UserLogo :size="100" :sport="sport" :id="userId" /></div>
      <div style="flex: 1;"></div>
    </div>

    <!-- Team name and rank info -->
    <div class="teamInfoArea">
      <div class="teamSummaryContainer">
        <div class="established">
          {{ teamInfo.establishedDate != null ? 'Established: ' + $moment(teamInfo.establishedDate).format('MMM Do, YYYY') : '' }}
        </div>
        <div class="nameRankContainer">
          <div class="name">{{ teamInfo.teamName }}</div>
          <i v-if="isMyProfile" class="fas fa-edit editTeamBtn" @click="editTeam()"></i>
        </div>
        <div class="matchSummaryContainer">
          <div>Last 10: {{ teamInfo.recentH2hWin || 0 }}-{{ teamInfo.recentH2hLoss || 0 }}</div>
          <div>Head-to-Head: {{ teamInfo.contests || 0 }}</div>
          <div>Tournaments: {{ teamInfo.contestsSc || 0 }}</div>
        </div>
      </div>

      <!-- League stats and versus when viewing other users -->
      <div style="justify-content: center; flex: 1; display: flex; flex-direction: column;">
        <div>
          <div class="fieldListHorizontal pointsList" style="padding: 0;">
            <div class="pointsBox">
              <div>{{ teamInfo.h2hWin || 0 }}</div>
              <div class="fieldHeading">Wins</div>
            </div>
            <div class="pointsBox">
              <div>{{ teamInfo.h2hLoss || 0 }}</div>
              <div class="fieldHeading">Losses</div>
            </div>
            <div class="pointsBox">
              <div>{{ (teamInfo.h2hWinRatio || 0).toFixed(0) + '%' }}</div>
              <div class="fieldHeading">Win/Loss</div>
            </div>
            <div class="pointsBox">
              <div>{{ (teamInfo.recentH2hWinRatio || 0).toFixed(0) + '%' }}</div>
              <div class="fieldHeading">L10 W/L</div>
            </div>
          </div>
        </div>
        <div v-if="!isMyProfile" class="fieldListHorizontal" style="display: flex; justify-content: center; font-size: 12px; margin-top: 5px;">
          <div>Record against: {{ teamInfo.loggedInUserH2hWins || 0 }}-{{ teamInfo.oppositionH2hWins || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';

export default {

  components: {
    UserLogo,
  },

  props: {
    sport: String,
    userId: String,
    teamInfo: Object,
    isMyProfile: Boolean,
  },

  methods: {
    editTeam() {
      EventBus.$emit('PROFILE_EDIT_TEAM', this.teamInfo, this.sport);
    },
  },
};
</script>

<style lang="scss" scoped>
.teamImgArea {
  display: flex;
  justify-content: space-between;
}
.logoContainer {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teamInfoArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 -8px -8px -8px;
  background: var(--obcolor-box-header);
}
.teamSummaryContainer {
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.established {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  padding-top: 8px;
  height: 1em;
}
.nameRankContainer {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 0;
  margin-top: 10px;
}
.name {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.editTeamBtn {
  color: var(--obcolor-font-secondary);
  margin-left: 5px;
  cursor: pointer;
}
.editTeamBtn:hover {
  color: var(--obcolor-font-primary);
}
.matchSummaryContainer {
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}
.fieldListHorizontal {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
}
.fieldListHorizontal:last-child {
  padding-bottom: 10px;
}
.pointsList {
  display: flex;
  justify-content: center !important;
}
.pointsBox {
  background: var(--obcolor-background-3);
  padding: 5px 0;
  width: 80px;
  text-align: center;
  margin: 0 5px;
  font-weight: bold;
}
.fieldHeading {
  font-size: 12px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}
</style>