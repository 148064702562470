<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 1000px; height: 800px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 5px 0px; background: var(--obcolor-background-page); overflow: auto !important;"
  >
    <div slot="header" class="modalHeader">
      <GameModalSummary v-if="liveGameData != null" :gameData="gameData"
        :gameSummary="liveGameData" :sport="sport" :selectedTeam="selectedTeam"
      />
      <GameModalTab :selectedTab="selectedTab" />
    </div>
    <div slot="body" class="modalBody">
      <GameModalMatchup v-if="gameData != null && selectedTab == 'matchup'" :gameData="gameData" :sport="sport" />
      <div v-else-if="gameData != null && selectedTab == 'stats'" class="ob-scroll">
        <GameModalBoxScores :gameData="gameData" :sport="sport" :selectedTeam="selectedTeam" />
      </div>
      <ObLoading v-else />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ObLoading from '@/components/ObLoading';
import GameModalSummary from '@/components/modals/GameModal/GameModalSummary';
import GameModalBoxScores from '@/components/modals/GameModal/GameModalBoxScores';
import EventBus from '@/event-bus';
import GameModalMatchup from '@/components/modals/GameModal/GameModalMatchup';
import GameModalTab from '@/components/modals/GameModal/GameModalTab';

import ObSportDataApi from '@/api/ObSportDataApi';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    ObLoading,
    GameModalSummary,
    GameModalBoxScores,
    GameModalMatchup,
    GameModalTab,
  },

  data() {
    return {
      gameId: null,
      sport: null,
      isOpen: false,
      loading: false,
      gameLoadTimeout: null,
      gameData: null,
      gameSummary: null,
      selectedTeam: 'away',
      selectedTab: 'matchup',
    };
  },

  created() {
    EventBus.$on('GAME_MODAL_OPEN', this.openModal);
    EventBus.$on('GAME_MODAL_SELECT_TEAM', this.selectTeam);
    EventBus.$on('GAME_MODAL_SELECT_TAB', this.selectTab);
    EventBus.$on('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  destroyed() {
    EventBus.$off('GAME_MODAL_OPEN', this.openModal);
    EventBus.$off('GAME_MODAL_SELECT_TEAM', this.selectTeam);
    EventBus.$off('GAME_MODAL_SELECT_TAB', this.selectTab);
    EventBus.$off('LIVE_GAME_UPDATE', this.onGameUpdate);
    clearTimeout(this.gameLoadTimeout);
  },

  computed: {
    ...mapState(['todaysGames']),

    // If the game has live-updating data available, use that,
    // otherwise just use the static data from the game
    liveGameData() {
      if (!this.gameId) {
        return null;
      }
      return this.todaysGames[this.gameId] || this.gameSummary;
    },
  },

  methods: {
    resetData() {
      this.gameId = null;
      this.sport = null;
      this.gameData = null;
      this.gameSummary = null;
      this.selectedTeam = 'away';
      this.selectedTab = 'matchup';

      clearTimeout(this.gameLoadTimeout);
    },

    // Loop every 2 minutes, if the game is mid-event, reload box scores.
    setGameTimeout() {
      this.gameLoadTimeout = setTimeout(() => {
        if (this.isOpen && this.liveGameData && this.liveGameData.live &&
          this.liveGameData.live.status == 'mid-event'
        ) {
          this.getGameDetails();
        }

        this.setGameTimeout();
      }, 120000);
    },

    openModal(params) {
      this.resetData();
      if (!params || !params.game || !params.sport) {
        return;
      }

      this.isOpen = true;
      this.loading = true;

      this.sport = params.sport;
      this.gameId = params.game.id;
      this.gameSummary = params.game;

      this.selectedTab = 'matchup';
      if (params.setTeam) {
        this.selectTeam(params.setTeam);
      }

      this.getGameDetails();
      this.setGameTimeout();
    },

    getGameDetails() {
      this.loading = true;
      ObSportDataApi.getFullGameDetails(this.sport, this.gameId)
          .then((response) => {
            this.gameData = response;
            this.loading = false;
          }).catch((_error) => {
            // Do nothing
            this.loading = false;
            clearTimeout(this.gameLoadTimeout);
          });
    },

    closeModal() {
      this.isOpen = false;
      this.gameId = null;
      this.sport = null;
      this.gameData = null;
      this.gameSummary = null;
      this.selectedTeam = 'away';

      clearTimeout(this.gameLoadTimeout);
    },

    onGameUpdate(gameId) {
      if (gameId == this.gameId) {
        this.getGameDetails();
      }
    },

    selectTeam(homeAway) {
      this.selectedTeam = homeAway;
    },

    selectTab(tabName) {
      this.selectedTab = tabName;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalBody {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
  padding: 0;
  box-sizing: border-box;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>