<template>
  <div style="min-width: 1200px;">
    <!-- View Contests Modal -->
    <Modal v-if="showGameDetails" v-model="showGameDetails" @close="() => {selectedPlayer = null}">
      <TeamGameDetails slot="body" :player="selectedPlayer" :sport="league.sport" />
    </Modal>

    <div v-if="league == null" class="loadingBox">
      <i class="fad fa-spinner-third fa-spin"></i>
      <div style="margin-top: 10px; font-size: 14px;">Loading Schedule...</div>
    </div>

    <!-- Table Headers -->
    <div v-if="league != null">
      <table style="height: 60px;" aria-label="Team Information">
        <tr>
          <th style="box-sizing: border-box; text-align: left; max-width: 0; min-width: 240px; padding-left: 6px;">
            <div class="userInfo">
              <UserLogo :id="league.roster.id" :sport="league.sport" :size="30" />
              <div style="margin-left: 10px;">
                <div class="matchHeaderTeam">{{ league.roster.team }}</div>
                <div class="matchHeaderUser">{{ league.roster.name }}</div>
              </div>
            </div>
          </th>
          <th v-for="n in 7" style="box-sizing: border-box; min-width: 120px;" :key="n"
            :class="{todayHighlight: todaysIndex == n-1}"
          >
            <div class="lineupDay" v-if="league.roster.days != null">{{ [league.roster.days[n-1], 'YYYY-MM-DD'] | moment("ddd MMM D") }}</div>
          </th>
          <th class="lineupStatHeader">
            <div class="lineupStat">PROJ</div>
          </th>
          <th class="lineupStatHeader">
            <div class="lineupStat">FPTS</div>
          </th>
          <th style="width: 30px; box-sizing: border-box;"></th>
        </tr>
      </table>

      <!-- Players by Position -->
      <template v-for="(group, index) in teamRosters">
        <div style="display: flex;"
          :style="index >= teamRosters.length-1 ? '' : 'border-bottom: 3px solid var(--obcolor-background-6);'"
          :key="index"
        >
          <div style="flex: 1;">
            <table aria-label="Players">
              <TeamTableRow v-for="entry in group.players" :entry="entry" :key="entry.id"
                :swapStatus="getPlayerSwapStatus(entry)"
                :todaysIndex="todaysIndex" :leagueId="leagueId"
                :isMyTeam="isUsersTeam" :sport="league.sport"
                :playedGames="playedGames"
                :matchup="leagueData"
                @showPlayerModal="showPlayerModal(entry)"
                :style="entry.hlTableRow ? 'background: var(--obcolor-background-5);' : ''"
              />
            </table>
          </div>
          <div class="posGroup">
            <div>
              <div :class="{'redHighlight' : rosterGR[group.pos] != rosterPossibleGR[group.pos]}" class="posGroupText">
                {{ rosterGR[group.pos] }}
              </div>
              <div>{{ rosterPossibleGR[group.pos] }}</div>
            </div>
          </div>
        </div>
      </template>

      <!-- Dropped Players -->
      <table class="rowHighlight" aria-label="Players">
        <tr v-if="league.roster.dropped.length > 0">
          <th colspan="12" class="rowHeader" style="text-align: left; padding: 0 6px; height: 60px;">Dropped Players</th>
        </tr>
        <TeamTableRow v-for="entry in league.roster.dropped" :entry="entry" :key="entry.id"
          :todaysIndex="todaysIndex" :leagueId="leagueId" :isMyTeam="isUsersTeam"
          :sport="league.sport"
          :playedGames="playedGames"
          :matchup="leagueData"
          @showPlayerModal="showPlayerModal(entry)"
        />
      </table>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import TeamTableRow from './TeamTableRow.vue';
import TeamGameDetails from './TeamGameDetails.vue';
import UserLogo from '@/components/UserLogo.vue';

export default {
  name: 'ContestTeamSchedule',

  components: {
    Modal,
    TeamTableRow,
    TeamGameDetails,
    UserLogo,
  },

  props: {
    leagueData: Object,
    leagueRoster: Object,
    isUsersTeam: Boolean,
    playedGames: Object,
    agByPosition: Object,
    rosterGR: Object,
    rosterPossibleGR: Object,
  },

  data: function() {
    return {
      league: null,
      leagueId: null,
      userId: null,
      week: 1,
      playerList: {},
      showAddDropModal: false,
      showGameDetails: false,
      toDrop: null,
      toAdd: null,
      todaysIndex: null,
      gameLimits: {},
      selectedPlayer: null,
    };
  },

  computed: {
    teamRosters() {
      const teamRosters = {};
      const rostersArray = [];
      for (const pIndex in this.league.roster.players) {
        const player = this.league.roster.players[pIndex];
        player.hlTableRow = pIndex % 2 != 0;
        if (teamRosters[player.draftPos] != null) {
          teamRosters[player.draftPos].players.push(player);
        } else {
          const list = {pos: player.draftPos, players: [player]};
          teamRosters[player.draftPos] = list;
          rostersArray.push(list);
        }
      }
      return rostersArray;
    },
  },

  created() {
    const routerLeagueId = this.$route.params.leagueId;
    const userId = this.$route.params.userId;
    const week = this.$route.params.week;
    if (routerLeagueId != null) {
      this.leagueId = routerLeagueId;
    }
    if (userId != null) {
      this.userId = userId;
    }
    // TODO: Check if the week is between 1 and maxWeek
    if (week != null) {
      this.week = parseInt(week);
    }

    this.setupData();
  },

  watch: {
    leagueRoster() {
      this.setupData();
    },
  },

  methods: {
    setupData() {
      this.league = null;
      this.league = this.leagueData;
      this.league.roster = this.leagueRoster;
      this.gameLimits = this.leagueData.gameLimits;
      this.setupTodaysIndex();
    },

    showPlayerModal(player) {
      this.selectedPlayer = player;
      this.showGameDetails = true;
    },

    setupTodaysIndex() {
      const days = this.league.roster.days;
      const now = this.$moment().format('YYYY-MM-DD');
      for (let i=0; i<days.length; i++) {
        if (days[i] === now) {
          this.todaysIndex = i;
          break;
        }
      }
    },

    getPlayerSwapStatus(player) {
      const avaGamesForPos = this.rosterPossibleGR[player.draftPos];
      const hasWaivers = this.leagueData.matchup.hasWaivers;
      let isWaiver = false;

      if (avaGamesForPos === 0) {
        isWaiver = true;
      } else if (this.leagueData.movesRem === 0 && this.countsTowardsMoves(player.player)) {
        // Has ava games, but has no moves, and the player to drop is not a free move (inactive)
        isWaiver = true;
      }

      // If its a waiver claim but this is the last week (no waivers) the player cannot be swapped
      if (isWaiver && !hasWaivers) {
        return 'invalid';
      }
      if (isWaiver && hasWaivers) {
        return 'waivers';
      }
      return 'swappable';
    },

    countsTowardsMoves(player) {
      if (!player) {
        return true;
      }
      switch (player.status) {
        case 'INJ':
        case 'SUSP':
        case 'OUT':
        case 'DNP':
        case 'MIN':
          return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.userInfo {
  flex: 1;
  display: flex;
  align-items: center;
  width: 234px;
}
.lineupDay {
  box-sizing: border-box;
}
.lineupStatHeader {
  width: 60px;
  max-width: 60px;
  box-sizing: border-box;
}
.lineupStat {
  min-width: 48px;
}
.loadingBox {
  text-align: center;
  padding: 20px;
}
.topBarButton {
  background: var(--obcolor-background-6);
  border-radius: 10px;
  font-size: 12px;
  height: 30px;
  margin-right: 6px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}
.topBarButton:hover {
  background: var(--obcolor-background-2);
}
.weekSelector {
  background: var(--obcolor-background-6);
  border-radius: 10px;
  font-size: 12px;
  height: 30px;
  display: flex;
  align-items: center;
  div {
    min-width: 20px;
    white-space: nowrap;
  }
  i {
    padding: 8px;
    color: var(--obcolor-text-faded);
    cursor: pointer;
  }
  i:hover {
    color: var(--obcolor-font-primary);
  }
}
.matchHeaderTeam {
  font-weight: bold;
}
.matchHeaderUser {
  font-size: 14px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}
table {
  text-align: center;
  width: 100%;
}

td, th {

  white-space: nowrap;
}

td {
  padding: 8px 6px 8px 6px;
}

th {
  background: var(--obcolor-background-6);
  font-size: 13px;
  border-bottom: 1px solid var(--obcolor-text-faded);
  padding: 0;
}

.rowHighlight tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.todayHighlight {
  background: var(--obcolor-background-2);
  border-radius: 10px 10px 0 0;
}
.posGroup {
  min-width: 30px;
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.posGroupText {
  border-bottom: 2px solid #aaa;
  margin-bottom: 2px
}
.redHighlight {
  color: red !important;
}
</style>