<template>
  <div class="selectedPlayerContainer">
    <div class="headshotContainer">
      <PlayerHeadshot class="headshot" :id="draftState.selectedPlayer.id" :sport="sport"
        :pos="draftState.selectedPlayer.draftPos" :team="draftState.selectedPlayer.team"
      />
      <div class="teamName">{{ $TeamInfo.getDisplayAlias(sport, draftState.selectedPlayer.team) }}</div>
    </div>
    <div class="summaryContainer">
      <div class="playerSummary">
        <div class="playerTextInfo">
          <div class="playerName">
            <span @click="openPlayerCard()" class="ob-link" style="margin-right: 6px;">{{ draftState.selectedPlayer.name.longName() }}</span>
            <i v-if="draftState.selectedPlayer.heat === 'Cold'"
              class="fas fa-icicles"
              style="color: var(--obcolor-player-cold);"
              v-tooltip.right="draftState.selectedPlayer.heatInfo != null ? {content: draftState.selectedPlayer.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
            ></i>
            <i v-else-if="draftState.selectedPlayer.heat === 'Hot'"
              style="color: var(--obcolor-player-hot);"
              class="fab fa-hotjar"
              v-tooltip.right="draftState.selectedPlayer.heatInfo != null ? {content: draftState.selectedPlayer.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
            ></i>
          </div>
          <div class="playerInfo">
            <span>#{{ draftState.selectedPlayer.number }}</span>
            <span>{{ draftState.selectedPlayer.position }}</span>
            <span>{{ draftState.selectedPlayer.lineInfo }}</span>
          </div>
          <div style="margin-top: 10px;"><PlayerStatus :status="draftState.selectedPlayer.status" :showText="true" /></div>
        </div>
        <div class="playerStats">
          <div v-if="sport == 'NFL'" class="statEntry">
            <div v-tooltip.top="{content: 'Opponent', class:'ObTooltipHeat'}">VS</div>
            <div>
              <!-- If the current week isn't a bye and the data exists, show the opposing team, otherwise show "BYE" -->
              <div v-if="draftState.selectedPlayer.wInfo != null && draftState.selectedPlayer.wInfo[0]
                && !draftState.selectedPlayer.wInfo[0].bye"
              >
                <div v-if="draftState.selectedPlayer.wInfo[0].home == false" class="atSign">@</div>
                <img style="width: 24px; height: 24px;" :class="{invalidGameLogo: draftState.selectedPlayer.wInfo[0].excluded == true}"
                  :src="getTeamLogo(draftState.selectedPlayer.wInfo[0].opp)" :alt="draftState.selectedPlayer.wInfo[0].opp"
                >
              </div>
              <div v-else-if="draftState.selectedPlayer.wInfo != null && draftState.selectedPlayer.wInfo[0]
                && draftState.selectedPlayer.wInfo[0].bye == true" class="centerCol"
              >
                BYE
              </div>
            </div>
          </div>
          <div v-if="sport != 'NFL'" class="statEntry">
            <div v-tooltip.top="{content: 'Projected Games', class:'ObTooltipHeat'}">PG</div>
            <div>{{ draftState.selectedPlayer.wStats[0].pg }}</div>
          </div>
          <div v-if="sport != 'NFL'" class="statEntry">
            <div v-tooltip.top="{content: 'Projected Average', class:'ObTooltipHeat'}">pAVG</div>
            <div>{{ roundStat(draftState.selectedPlayer.wStats[0].avg) }}</div>
          </div>
          <div class="statEntry">
            <div v-tooltip.top="{content: 'Season Average', class:'ObTooltipHeat'}">sAVG</div>
            <div>{{ draftState.selectedPlayer.sAvg.toFixed(1) }}</div>
          </div>
          <div class="statEntry">
            <div v-tooltip.top="{content: 'Projected Points', class:'ObTooltipHeat'}">PROJ</div>
            <div>{{ roundStat(draftState.selectedPlayer.wStats[0].proj) }}</div>
          </div>
        </div>
      </div>
      <div class="gamesWeekContainer">
        <table class="weekBox" aria-label="Games Week Container">
          <tr>
            <th v-for="g in draftState.selectedPlayer.games" :key="g.date">
              <div>{{ g.day }}</div>
              <div v-if="g.date">{{ [g.date, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
          </tr>
          <tr>
            <td v-for="g in draftState.selectedPlayer.games" :key="'dh'+g.date"
              class="draftGame" style="color: var(--obcolor-text-primary);"
              :style="g['gameDH'] ? 'width: 85px;' : ''"
            >
              <div style="display: flex; text-align: center;">
                <div style="flex: 1;" :style="g.excluded ? 'color: red;' : ''">
                  <div>{{ convertAlias(g.game) }}</div>
                  <div>{{ roundStat(g.proj) }}</div>
                </div>
                <div v-if="g.gameDH" :style="g.excludedDH ? 'color: red;' : ''" style="width: 2px; height: 24px; background: var(--obcolor-background-4);"></div>
                <div v-if="g.gameDH" style="flex: 1;">
                  <div>{{ g.gameDH }}</div>
                  <div>{{ roundStat(g.projDH) }}</div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="draftPlayerButtonContainer" v-if="draftState">
          <DraftPlayerButton :draftState="draftState" :player="draftState.selectedPlayer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';
import DraftPlayerButton from './DraftButtons/DraftPlayerButton';

export default {

  components: {
    PlayerHeadshot,
    PlayerStatus,
    DraftPlayerButton,
  },

  props: {
    draftState: Object,
    sport: String,
    teamRosters: Object,
    curUserId: String,
  },

  methods: {
    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    convertAlias(game) {
      if (!game) {
        return '';
      }
      if (game.indexOf('@') != -1) {
        return '@' + this.$TeamInfo.getDisplayAlias(this.sport, game.replace('@', ''));
      }
      return this.$TeamInfo.getDisplayAlias(this.sport, game);
    },

    getTeamLogo(team) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },

    openPlayerCard() {
      EventBus.$emit('DRAFT_OPEN_PLAYER_CARD', this.draftState.selectedPlayer, this.sport, this.draftState);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedPlayerContainer {
  display: flex;
}

.headshotContainer {
  text-align: center;
  padding: 5px;
  margin-right: 15px;
}

.headshot {
  width: 75px;
  height: 75px;
}

.teamName {
  margin-top: 5px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  text-transform: uppercase;
}

.summaryContainer {
  flex: 1;
}

.playerSummary {
  display: flex;
  height: 68px;
}

.playerTextInfo {
  flex: 1;
}

.playerName {
  font-weight: bold;
  font-size: 18px;
}

.playerInfo {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: -3px;
  color: var(--obcolor-font-secondary);
  span {
    margin-right: 5px;
  }
}

// Stats
.playerStats {
  display: flex;
  text-align: center;
}

.statEntry {
  min-width: 50px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    color: var(--obcolor-font-secondary);
  }
  div:last-child {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  };
}

.atSign {
  margin-right: 2px;
  height: max-content;
  // margin-left: -13px;
  color: var(--obcolor-font-primary) !important;
}

.invalidGameLogo {
  filter: grayscale(100%);
  opacity: 0.5;
}

.draftPlayerButtonContainer {
  margin-left: 10px;
  flex: 1;
  text-align: right;
  height: 63px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.gamesWeekContainer {
  display: flex;
  align-items: flex-start;
  table {
    width: unset;
  }
}

.weekBox {
  height: 63px;
}

.draftGame {
  padding-bottom: 0px;
  height: 32px;
}

// Weeks
table {
  width: 100%;
  text-align: center;
  font-size: 11px;
  margin-left: -4px;
}

td, th {
  width: 45px;
  min-width: 45px;
}

th {
  color: var(--obcolor-font-secondary);
  text-transform: uppercase;
  padding-bottom: 3px;
}

td {
  padding-bottom: 3px;
  padding-top: 3px;
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-background-2);
  border-left: 4px solid var(--obcolor-background-6);
}

</style>