<template>
  <span v-if="contest">
    <!-- Prize Pool -->
    <span v-if="contest.prizeValue > 0 || !contest.numTicketPrizes">
      {{ contest.prizePool.removeCentsIfZero() }}
    </span>

    <!-- Ticket Icon -->
    <template v-if="showTicketIcon && contest.numTicketPrizes > 0">
      <span class="iconText" v-if="contest.prizeValue > 0"> + </span>
      <img :src="require('@/assets/icons/ticket.png')" alt="Contest Ticket" class="ticketIcon"
        v-tooltip.bottom="getTicketsTooltip()"
        :style="'height: ' + iconHeight + 'px;'"
      >
      <span class="iconText">x{{ contest.numTicketPrizes }}</span>
    </template>

    <!-- Text Only -->
    <template v-else-if="contest.numTicketPrizes > 0">
      <span v-if="contest.prizeValue > 0"> + </span>
      <span>{{ contest.numTicketPrizes }} Ticket{{ contest.numTicketPrizes > 1 ? 's' : '' }}</span>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    contest: {type: Object, default: null},
    showTicketIcon: {type: Boolean, default: false},
    iconHeight: {type: Number, default: 13},
  },

  methods: {
    getTicketsTooltip() {
      if (!this.contest) {
        return {};
      }
      const totalValue = this.contest.totalTicketPrize.removeCentsIfZero();
      const text = this.contest.numTicketPrizes + ' Tickets worth a total of ' + totalValue;
      return {content: text, class: 'ObTooltip'};
    },
  },
};
</script>

<style lang="scss" scoped>
.iconText {
  color: var(--obcolor-font-primary);
}

.ticketIcon {
  margin-bottom: -2px;
  margin-right: 2px;
}
</style>