<template>
  <div slot="body" style="padding-left: 10px; padding-right: 10px; height: 460px;">
    <template>
      <div v-if="loading" style="height: 100%; display: flex; align-items: center; justify-content: center">
        <ObLoading />
      </div>
      <div v-if="contestList.length > 0" class="existingContestContainer">
        <ImportExistingContest v-for="(existingContest, index) in contestList" :key="index" :expanded="openDropdownsList[existingContest.id]"
          :lineupList="contestTeams[existingContest.id]" :contest="existingContest" :playerMap="playerMap"
          :loading="contestTeamsLoading[existingContest.id]" :currentContest="contest"
        />
      </div>
      <div v-if="contestList.length === 0" class="noLineup">
        No lineups available to import
      </div>
    </template>
  </div>
</template>

<script>
import ImportExistingContest from './ImportExistingContest';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ImportExistingContest,
    ObLoading,
  },

  props: {
    contest: {type: Object, default: null},
    playerMap: {type: Object, default: null},
  },

  data() {
    return {
      openDropdownsList: {},
      contestList: [],
      contestTeams: {},
      contestTeamsLoading: {},
      loading: false,
    };
  },

  created() {
    EventBus.$on('IMPORT_LINEUP_TOGGLE_DROPDOWN', this.toggleDropdown);
    this.open();
  },

  destroyed() {
    EventBus.$off('IMPORT_LINEUP_TOGGLE_DROPDOWN', this.toggleDropdown);
    this.close();
  },

  methods: {
    open() {
      this.loading = true;
      this.resetData();
      this.getContests();
      this.$emit('disableImportLineups');
    },

    close() {
      this.$emit('disableImportLineups');
    },

    resetData() {
      this.contestList = [];
      this.contestTeams = {};
      this.contestTeamsLoading = {};
      this.loading = false;
      this.openDropdownsList = {};
    },

    toggleDropdown(contestId) {
      this.$set(this.openDropdownsList, contestId, !this.openDropdownsList[contestId]);
      if (!this.contestTeams[contestId]) {
        this.getContestTeams(contestId);
      }
    },

    getContests() {
      this.loading = true;
      ObSalaryCapApi.getContestsToImport(this.contest.id)
          .then((response) => {
            this.contestList = response;
            if (this.contestList[0]) {
              this.getContestTeams(this.contestList[0].id);
              this.$set(this.openDropdownsList, this.contestList[0].id, true);
            }
            this.loading = false;
          }).catch((e) => {
            if (e.response && e.response.data) {
              console.log(e.response.data);
            } else {
              console.log('Could not retrieve contests');
            }
            this.loading = false;
          });
    },

    getContestTeams(contestId) {
      this.$set(this.contestTeamsLoading, contestId, true);
      ObSalaryCapApi.getTeamsForContest(contestId)
          .then((response) => {
            this.$set(this.contestTeams, contestId, response);
            this.$set(this.contestTeamsLoading, contestId, false);
          }).catch((e) => {
            if (e.response && e.response.data) {
              console.log(e.response.data);
            } else {
              console.log('Could not retrieve lineups');
            }
            this.loading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>

  .existingContestContainer {
    font-size: 14px;
    text-align: center;
    padding-top: 10px;
  }

  .noLineup {
    padding-top: 10px;
    color: var(--obcolor-text-secondary);
  }
</style>