<template>
  <div>
    <!-- H2H Contest -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Join a H2H Weekly Contest</div>
        <div style="text-align: center;">
          From the lobby join a head-to-head, single elimination, bracket style contest!
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen1 = 'week'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Weekly</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Weekly Contests</div>
              <div class="infoHoverText">Contests will take place over a span of 1-4 weeks, depending on the number of entrants.</div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen1 = 'bracket'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Single Elim</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Single Elimination</div>
              <div class="infoHoverText">Winner of the week moves on, loser will be eliminated from the contest.</div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen1 = 'payouts'" @mouseout="screen1 = 'default'">
            <div class="ob-btn">Payouts</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Weekly Payouts</div>
              <div class="infoHoverText">Prizes are paid out weekly, with the contest winner taking the majority of the pot.</div>
            </div>
          </div>
        </div>
        <div class="explanationImage" style="margin-top: 20px;"><img :src="require('@/assets/howtoplay/h2h/h2hweekly_' + theme + '.png')" alt="H2H weekly Contest"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/h2h/howto_lobby.png')" alt="H2H Lobby">
        <img :style="screen1 === 'payouts' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_payouts.png')" alt="H2H Payouts">
        <img :style="screen1 === 'bracket' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_bracket.png')" alt="H2H Bracket">
        <img :style="screen1 === 'week' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_week.png')" alt="H2H Summary">
      </div>
    </div>

    <!-- Live Draft -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Participate in a LIVE Snake Draft</div>
        <div style="text-align: center;">
          Live draft ensures no duplicate lineups! As you draft keep an eye on your Game Opportunities for each position.
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen2 = 'gameopps'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Game Opps</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Game Opportunities</div>
              <div class="infoHoverText">
                Every team has the same number of weekly game opportunities (per position) to ensure an even playing field.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen2 = 'queue'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Player Queue</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Player Queue</div>
              <div class="infoHoverText">Add or remove players from your queue to auto draft or keep track of their status.</div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen2 = 'playercard'" @mouseout="screen2 = 'default'">
            <div class="ob-btn">Player Card</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Player Card</div>
              <div class="infoHoverText">Click on a player's name to bring up the Player Card to see their recent, and seasonal performances.</div>
            </div>
          </div>
        </div>
        <div class="explanationImage" style="margin-top: 30px;"><img :src="require('@/assets/howtoplay/h2h/livedraft_' + theme + '.png')" alt="H2H Live Draft"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/h2h/draft.jpg')" alt="H2H Draft">
        <img :style="screen2 === 'playercard' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_playercard.png')" alt="H2H Player's Card">
        <img :style="screen2 === 'gameopps' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_gameopps.png')" alt="H2H Game Oppositions">
        <img :style="screen2 === 'queue' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_queue.png')" alt="H2H Queue">
      </div>
    </div>

    <!-- Manage  Lineup -->
    <div class="ob-box h2pBoxContainer">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">Manage your Lineup</div>
        <div style="text-align: center;">
          Actively manage your team throughout the contest. Bench, activate, or swap players to win the week!
        </div>
        <div class="infoButtonList">
          <div class="infoButtonContainer" @mouseover="screen3 = 'swap'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Player Swap</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Player Swap</div>
              <div class="infoHoverText">
                Swap your players when they are underperforming or injured. You are restricted to 2 Swaps per week.
                Inactive players do not count towards your swap limit.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen3 = 'transactions'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Waiver Wire</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Waiver Wire</div>
              <div class="infoHoverText">
                Once you use up your swaps or game opportunities, you have to submit a waiver claim in an attempt to pick up a player for the next week.
              </div>
            </div>
          </div>
          <div class="infoButtonContainer" @mouseover="screen3 = 'bench'" @mouseout="screen3 = 'default'">
            <div class="ob-btn">Benching</div>
            <div class="ob-box infoHoverShow">
              <div class="infoHoverTitle">Benching</div>
              <div class="infoHoverText">
                Strategically bench or activate players to make the most of your game opportunities.
              </div>
            </div>
          </div>
        </div>
        <div class="explanationImage" style="margin-top: 25px;"><img :src="require('@/assets/howtoplay/h2h/managelineup.png')" alt="H2H Lineup"></div>
      </div>
      <div class="h2pBox h2pExample">
        <img :src="require('@/assets/howtoplay/h2h/matchup.jpg')" alt="H2H Matchup">
        <img :style="screen3 === 'swap' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_swap.png')" alt="H2H Swap Players">
        <img :style="screen3 === 'transactions' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_transactions.png')" alt="H2H Transactions">
        <img :style="screen3 === 'bench' ? 'opacity: 1;' : 'opacity: 0;'" :src="require('@/assets/howtoplay/h2h/howto_bench.png')" alt="H2H Bench">
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      screen1: 'default',
      screen2: 'default',
      screen3: 'default',
    };
  },

  computed: {
    ...mapState(['theme']),
  },
};
</script>