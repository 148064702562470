
/**
 * @typedef {import("@/api/ObPropsApi").Market} Market
 */

/**
 * @param {Market} market
 * @param {number} [now]
 * @return {boolean}
 */
export function isMarketActive(market, now = Date.now()) {
  if (market.discount?.id) {
    return market.isDiscounted && market.discount.expiry > now;
  }
  return market.state === 'ACTIVE';
}