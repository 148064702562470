<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 15px 20px;"
  >
    <div slot="body" class="body">
      <div v-if="numSuccessContests > 0" class="title">Lineups Submitted!</div>
      <div v-else class="title">Lineups Failed to Submit</div>
      <div class="paragraph">
        <div v-if="numSuccessContests > 0">
          You have joined {{ numSuccessContests }} contest{{ numSuccessContests > 1 ? 's' : '' }} with
          <!-- Entry fees in dollars -->
          <template v-if="hasRealMoneyContests">
            <span>${{ moneyFormat(feesValue) }}</span>
            <span> in entry fees</span>
          </template>
          <!-- Entry fees in ownersbucks -->
          <template v-else>
            <span style="white-space: nowrap;">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
              {{ moneyFormat(feesOBucksValue) }}
            </span>
            <span> in OwnersBucks fees</span>
          </template>

          and

          <!-- Prizes in dollars -->
          <template v-if="hasRealMoneyContests">
            <span class="moneyText">${{ moneyFormat(prizesValue) }}</span>
            <!-- Add period if there's no "and X in ownersbucks prizes" -->
            <span> in prize pools.</span>
          </template>
          <!-- Prizes in ownersbucks -->
          <template v-else>
            <span class="blueText" style="white-space: nowrap;">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
              {{ moneyFormat(prizesOBucksValue) }}
            </span>
            <span> in OwnersBucks prize pools.</span>
          </template>
          Good luck!
        </div>
        <div v-else>
          Failed to join contests. Please view errors below:
        </div>
      </div>

      <!-- Table of lineups with errors -->
      <table v-if="failContestResponses.length > 0" class="errorTable" aria-labelledby="errorTitle">
        <tr @click.stop="showErrors = !showErrors" class="errorLineupsHeader">
          <th class="redText" id="errorTitle">
            <i class="fas fa-exclamation-circle fa-lg error"></i>
            Error submitting {{ failContestResponses.length }} lineup(s)
          </th>
          <th>
            <div>
              <i v-if="!showErrors" class="fas fa-chevron-down"></i>
              <i v-else class="fas fa-chevron-up"></i>
            </div>
          </th>
        </tr>
        <template v-if="showErrors">
          <tr v-for="(errorLineup, index) in failContestResponses" :key="index">
            <td class="redText">
              {{ selectedContests[errorLineup.contestId].contestName }}
              {{ errorLineup.errorMessage ? ' - ' + errorLineup.errorMessage : '' }}
            </td>
          </tr>
        </template>
      </table>
    </div>

    <div slot="footer" class="footer">
      <button v-if="footerButton == 'close'" class="ob-btn" @click="closeModal()">Close</button>
      <button v-else-if="footerButton == 'lineups'" class="ob-btn" @click="goToLineupsPage()">View Lineups</button>
      <button v-else class="ob-btn" @click="goToLobby()">Go to Lobby</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  props: {
    footerButton: {type: String, default: 'lobby'},
  },

  data() {
    return {
      isOpen: false,
      // Contest list with info about the contest
      contestList: [],
      // Contest list with info about whether it successfully submitted
      contestResults: [],
      entriesByContest: {},
      showErrors: false,
    };
  },

  created() {
    EventBus.$on('SALARY_OPEN_QUICK_SUCCESS_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('SALARY_OPEN_QUICK_SUCCESS_MODAL', this.openModal);
  },

  computed: {
    selectedContests() {
      const contestMap = {};
      for (const contest of this.contestList) {
        this.$set(contestMap, contest.id, contest);
      }

      return contestMap;
    },

    // List of contest info for contests that succeeded in submission
    successContests() {
      const self = this;
      return this.contestResults.slice().filter((contest) => {
        return contest.success;
      }).map((contest) => {
        return self.selectedContests[contest.contestId];
      });
    },

    // Information about the response from the server about contests that failed to submit
    failContestResponses() {
      return this.contestResults.slice().filter((contest) => {
        return !contest.success;
      });
    },

    numSuccessContests() {
      let tot = 0;
      for (const contest of this.successContests) {
        const numEntries = this.entriesByContest[contest.id];
        tot += numEntries;
      }
      return tot;
    },

    numFailContests() {
      let tot = 0;
      for (const contest of this.failContestResponses) {
        const numEntries = this.entriesByContest[contest.id];
        tot += numEntries;
      }
      return tot;
    },

    hasRealMoneyContests() {
      for (const contest of this.successContests) {
        if (contest.realMoney) {
          return true;
        }
      }

      return false;
    },

    feesValue() {
      let tot = 0;
      for (const contest of this.successContests) {
        const numEntries = this.entriesByContest[contest.id];

        if (contest.realMoney) {
          tot += contest.feeValue * numEntries;
        }
      }

      return tot / 100;
    },

    feesOBucksValue() {
      let tot = 0;
      for (const contest of this.successContests) {
        const numEntries = this.entriesByContest[contest.id];

        if (!contest.realMoney) {
          tot += contest.feeValue * numEntries;
        }
      }

      return tot / 100;
    },

    prizesValue() {
      let tot = 0;
      for (const contest of this.successContests) {
        if (contest.realMoney) {
          tot += contest.prizeValue;
        }
      }

      return tot / 100;
    },

    prizesOBucksValue() {
      let tot = 0;
      for (const contest of this.successContests) {
        if (!contest.realMoney) {
          tot += contest.prizeValue;
        }
      }

      return tot / 100;
    },
  },

  methods: {
    openModal(contestList, contestResults, entriesByContest) {
      this.contestList = contestList;
      this.contestResults = contestResults;
      this.entriesByContest = entriesByContest;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.contestList = [];
      this.contestResults = [];
      this.entriesByContest = {};
      this.showErrors = false;
    },

    moneyFormat(amount) {
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(amount).removeCentsIfZero();
    },

    goToLobby() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER_COOKIE');
      this.$router.push('/lobby');
    },

    goToLineupsPage() {
      this.$router.push('/salarycap/lineups');
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .paragraph {
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: var(--obcolor-font-secondary);
  }

  .paragraph:not(:first-child) {
    margin-top: 5px;
  }
}

.footer {
  background: var(--obcolor-background-5);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 14px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.redText {
  color: var(--obcolor-red);
}

.moneyText {
  color: var(--obcolor-green);
  font-weight: bold;
}

.blueText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
}

.ownersbucksIcon {
  height: 10px;
}

.errorTable {
  width: 100%;
  margin-top: 5px;

  th, td {
    padding-top: 5px;
    padding-left: 10px;
  }

  th {
    cursor: pointer;
    border-top: 1px solid var(--obcolor-background-2);
  }

  .errorLineupsHeader {
    text-align: left;
    width: 100%;
  }
}
</style>