<template>
  <div>
    <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; padding: 0; overflow: hidden;"
      bodyStyle="margin: 0; background: var(--obcolor-background-3); overflow: unset !important;"
      :hideCloseButton="true" :disableClickaway="true"
    >
      <div slot="header" class="confirmHeader">{{ modalTitle }}</div>
      <div slot="body" class="confirmBody">
        <div class="modalIconContainer" v-if="submitting">
          <ObLoading :size="60" />
        </div>
        <div class="modalIconContainer successFail" v-else-if="!errorMessage">
          <i class="fas fa-check-circle"></i>
          <div class="successText">Contest created successfully</div>
        </div>
        <div class="modalIconContainer successFail" v-else>
          <i class="fas fa-times-circle"></i>
          <div class="errorText">{{ errorMessage }}</div>
        </div>
      </div>
      <div slot="footer" class="confirmFooter">
        <button v-if="isSuccess" class="ob-btn" @click="openInviteModal()">Invite Friends</button>
        <button v-if="isSuccess" class="ob-btn" @click="confirmModal()">Build Lineup</button>
        <button v-else-if="errorMessage !== null" class="ob-btn" @click="closeModal()">Close</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      submitting: true,
      errorMessage: null,
      createdId: null,
      contestInfo: null,
    };
  },

  created() {
    EventBus.$on('CREATE_MODAL_SUBMIT', this.openModalSubmit);
  },

  destroyed() {
    EventBus.$off('CREATE_MODAL_SUBMIT', this.openModalSubmit);
  },

  computed: {
    modalTitle() {
      if (this.errorMessage !== null) {
        return 'Contest Error';
      }
      return this.submitting ? 'Creating Contest' : 'Contest Created';
    },

    isSuccess() {
      return this.createdId !== null && this.errorMessage === null;
    },
  },

  methods: {
    openModalSubmit(contestInfo) {
      this.createContests(contestInfo);
      this.isOpen = true;
    },

    createContests(contestInfo) {
      this.contestInfo = contestInfo;
      this.submitting = true;
      this.errorMessage = null;
      ObSalaryCapApi.createUserContest(contestInfo)
          .then((response) => {
            this.createdId = response;
            this.submitting = false;
          }).catch((e) => {
            this.submitting = false;
            if (e?.response?.data) {
              this.errorMessage = e.response.data;
              return;
            }
            this.errorMessage = 'Failed to create contest(s)';
          });
    },

    closeModal() {
      this.isOpen = false;
      this.contestInfo = null;
      this.submitting = false;
      this.errorMessage = null;
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_ID', this.createdId, false);
    },

    confirmModal() {
      if (!this.isSuccess) {
        this.closeModal();
      }

      this.$router.push('/lineup-builder/' + this.createdId);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.confirmBody {
  flex: 1;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalIconContainer {
    text-align: center;

    &.successFail {
      font-size: 48px;
      margin-top: 15px;
    }

    .successText, .errorText {
      margin-top: 10px;
      font-size: 14px;
    }

    .successText {
      color: var(--obcolor-font-primary);
    }

    .errorText {
      color: var(--obcolor-red);
    }

    .fa-check-circle {
      color: var(--obcolor-green);
    }

    .fa-times-circle {
      color: var(--obcolor-red);
    }
  }
}

.confirmFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;

  .ob-btn {
    width: 140px;
  }
}
</style>