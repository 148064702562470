<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3); overflow: unset !important;"
  >
    <div slot="header" class="redirectHeader">Mobile App</div>
    <div slot="body" class="redirectBody">To view this link open or download the OwnersBox app.</div>
    <div slot="footer" class="redirectFooter">
      <button class="ob-btn largeBtn" @click="goToApp()">Go to App</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import openApp from '@/utils/MobileRedirectDeeplink';

export default {
  components: {
    Modal,
  },

  props: {
    title: String,
    text: String,
    eventBusName: String,
  },

  computed: {
    ...mapState(['redirectedRoute']),
  },

  data() {
    return {
      isOpen: false,
    };
  },

  created() {
    EventBus.$on('OPEN_MOBILE_APP_REDIRECT_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_MOBILE_APP_REDIRECT_MODAL', this.openModal);
  },

  methods: {
    checkAppOpen(route) {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        try {
          openApp(route);
        } catch (e) {
          // Do nothing
        }
        return;
      }

      if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
        try {
          openApp(route);
        } catch (e) {
          window.location = 'https://apps.apple.com/us/app/id1513599602';
        }
      }
    },

    openModal() {
      if (this.redirectedRoute != null) {
        this.isOpen = true;
      }
    },

    goToApp() {
      this.checkAppOpen(this.redirectedRoute);
      this.closeModal();
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.redirectHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding-left: 10px;
}
.redirectBody {
  flex: 1;
  padding: 20px 10px;
  box-sizing: border-box;
  text-align: center;
}
.redirectFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: center;
}
.largeBtn {
  height: 35px;
  width: 150px;
  font-size: 16px;
}
</style>