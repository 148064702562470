<template>
  <div class="rangeSlider">
    <input type="range" :class="{hlThumb: localMinVal != calcMin}" :min="calcMin"
      :max="calcMax" :step="calcStep" v-model="localMinVal"
    >
    <input hidden type="number" :min="calcMin"
      :max="calcMax" :step="calcStep" v-model="localMinVal"
    >
    <input type="range" :class="{hlThumb: localMaxVal != calcMax}" :min="calcMin"
      :max="calcMax" :step="calcStep" v-model="localMaxVal"
    >
    <input hidden type="number" :min="calcMin"
      :max="calcMax" :step="calcStep" v-model="localMaxVal"
    >
    <div class="rangeHlContainer">
      <div class="rangeHl" :style="'left: ' + barLeftPercent + '; width: ' + barWidthPercent"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    min: [String, Number],
    max: [String, Number],
    step: [String, Number],

    // Optional parameter, allows for snapping, can be used instead of min/max/step
    selectableValues: Array,
  },

  computed: {
    calcMin() {
      if (this.min != null) {
        return this.min;
      }

      return 0;
    },

    calcMax() {
      if (this.max != null) {
        return this.max;
      }

      if (this.selectableValues) {
        return this.selectableValues.length - 1;
      }

      return 1;
    },

    calcStep() {
      if (this.step != null) {
        return this.step;
      }

      return 1;
    },

    localMinVal: {
      get: function() {
        const val0 = parseInt(this.value[0]);
        const val1 = parseInt(this.value[1]);
        const val = val1 < val0 ? val : val0;
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val > this.value[1]) {
          this.$set(this.value, 1, val);
        }
        this.$set(this.value, 0, val);
      },
    },
    localMaxVal: {
      get: function() {
        const val0 = parseInt(this.value[0]);
        const val1 = parseInt(this.value[1]);
        const val = val1 > val0 ? val1 : val0;
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val < this.value[0]) {
          this.$set(this.value, 0, val);
        }
        this.$set(this.value, 1, val);
      },
    },

    barLeftPercent() {
      const range = this.calcMax - this.calcMin;
      return 'calc(' + (this.localMinVal / range) * 100 + '%)';
    },

    barWidthPercent() {
      const range = this.calcMax - this.calcMin;

      return 'calc(' + ((this.localMaxVal - this.localMinVal) / range) * 100 + '% - 18px)';
    },
  },
};
</script>

<style lang="scss" scoped>
  .rangeSlider {
    width: 100%;
    height: 5px;
    text-align: center;
    position: relative;
  }

  .rangeHlContainer {
    position: absolute;
    top: 0;
    height: 5px;
    width: calc(100% - 18px);
    pointer-events: none;
    margin: 0 18px;
    box-sizing: border-box;
  }

  .rangeHl {
    position: absolute;
    height: 100%;
    box-sizing: border-box;
    background: var(--obcolor-ob-blue);
  }

  .rangeSlider input[type=range] {
    position: absolute;
    left: 0;
    top: -8px;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    pointer-events: none;
  }

  input[type=range]:focus {
    outline: none;
  }

  @mixin track() {
    background: var(--obcolor-timeline);
    height: 5px;
    pointer-events: none;
  }

  input[type=range]::-moz-range-track {
    @include track;
  }

  input[type=range]::-ms-track {
    @include track;
  }

  input[type=range]:focus::-moz-range-track {
    @include track;
  }

  input[type=range]:focus::-ms-fill-lower {
    @include track;
  }

  input[type=range]:focus::-ms-fill-upper {
    @include track;
  }

  input[type=range]::-webkit-slider-runnable-track {
    @include track;
    margin-top: 1px;
    width: 100%;
    position: absolute;
    top: 7px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: none;
    border: 0;
  }

  @mixin thumb() {
    z-index: 80;
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--obcolor-timeline);
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    pointer-events: auto;
  }

  input[type=range]::-webkit-slider-thumb {
    @include thumb;
    margin-top: -6px;
  }

  input[type=range]::-moz-range-thumb {
    @include thumb;
  }

  input[type=range]::-ms-thumb {
    @include thumb;
    margin-top: -6px;
  }

  input[type=range].hlThumb {
    &::-webkit-slider-thumb {
      background: var(--obcolor-ob-blue-tinted) !important;
    }
    &::-moz-range-thumb {
      background: var(--obcolor-ob-blue-tinted) !important;
    }
    &::-ms-thumb {
      background: var(--obcolor-ob-blue-tinted) !important;
    }
  }
</style>