<template>
  <div :style="'height: ' + size + 'px; width: ' + hexWidth + 'px;' + 'font-size: ' + levelFontSize + 'px'" class="levelContainer">
    <div class="hexContainer">
      <div class="hex"></div>
    </div>
    <div class="level">{{ level }}</div>
  </div>
</template>

<script>
export default {
  props: {
    size: {type: Number, default: null},
    level: {type: Number, default: null},
  },

  computed: {
    levelFontSize() {
      return this.size/2.5;
    },

    // Hex is not 1:1 with height and width, it's a bit thinner
    hexWidth() {
      return this.size/1.12;
    },
  },

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.levelContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.75;
}

.hexContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hex {
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--obcolor-background-2);
}

.hex:before {
  position: absolute;
  content: '';
  $borderSize: 2px;
  top: 2px;
  left: 2px;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  background: var(--obcolor-background-3);
}

.hex, .hex:before {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.level {
  z-index: 2;
}
</style>