<template>
  <div v-if="showStartingQuarterbacks">
    <h1 class="sectionHeader">Starting Quarterbacks</h1>
    <div class="startingQuarterbacks">
      <!-- away starting quarterback -->
      <div class="startingQuarterback">
        <PlayerHeadshot size="45" :id="awayStarter ? awayStarter.id : null" :sport="sport"
          :team="awayStarter ? awayStarter.team : null" :showLogo="true" :pos="awayStarter ? awayStarter.position : null"
        />
        <div v-if="awayStarter" class="startingQuarterbackStats">
          <div class="startingQuarterbackCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="awayStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(awayStarter.id)">{{ awayStarter.name.shortName() }}</div>
          </div>
          <p class="startingQuarterbackSubtext">{{ startingQuarterbackStats.away_starting_Quarterback }}</p>
        </div>
        <div v-else class="startingQuarterbackStats">
          <div style="margin-top: 14px;" class="startingQuarterbackCentered">
            TBD
          </div>
        </div>
      </div>
      <!-- home starting quarterback -->
      <div class="startingQuarterback">
        <div v-if="homeStarter" style="text-align: right;" class="startingQuarterbackStats">
          <div style="justify-content: right;" class="startingQuarterbackCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="homeStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(homeStarter.id)">{{ homeStarter.name.shortName() }}</div>
          </div>
          <p class="startingQuarterbackSubtext">{{ startingQuarterbackStats.home_starting_Quarterback }}</p>
        </div>
        <div v-else class="startingQuarterbackStats">
          <div style="margin-top: 14px;" class="startingQuarterbackCentered">
            TBD
          </div>
        </div>
        <PlayerHeadshot size="45" :id="homeStarter ? homeStarter.id : null" :sport="sport"
          :team="homeStarter ? homeStarter.team : null" :showLogo="true" :pos="homeStarter ? homeStarter.position : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  data() {
    return {
      battingOrder: {
        awayteam: [],
        hometeam: [],
      },
    };
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    startingQuarterbackStats() {
      const statResults = {away_starting_Quarterback: '', home_starting_Quarterback: ''};
      statResults.away_starting_Quarterback += this.awayQuarterbackStats?.passing ? this.awayStartingQuarterbackStats() : 'No Stats Available';
      statResults.home_starting_Quarterback += this.homeQuarterbackStats?.passing ? this.homeStartingQuarterbackStats() : 'No Stats Available';
      return statResults;
    },

    showStartingQuarterbacks() {
      const liveMatchupData = this.gameData.matchup.live;
      return (!liveMatchupData || (liveMatchupData && liveMatchupData.status === 'pre-event'));
    },

    awayStarter() {
      return this.gameData?.matchup?.info?.away_starter;
    },

    homeStarter() {
      return this.gameData?.matchup?.info?.home_starter;
    },

    awayQuarterbackStats() {
      return this.awayStarter?.stats;
    },

    homeQuarterbackStats() {
      return this.homeStarter?.stats;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    awayStartingQuarterbackStats() {
      return this.getQuarterbackStats(this.awayQuarterbackStats);
    },

    homeStartingQuarterbackStats() {
      return this.getQuarterbackStats(this.homeQuarterbackStats);
    },

    getQuarterbackStats(playerStats) {
      if (!playerStats) {
        return '';
      }

      const statsResult = [];
      const scoringStats = this.$ScoringStats.getPregameScoringStats(this.sport, 'QB');
      for (const stat of scoringStats[0].stats) {
        if (this.$Helpers.parsePath(stat.label, playerStats) && this.$Helpers.parsePath(stat.label, playerStats) === '1') {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description.removePluralWords());
        } else if (parseFloat(this.$Helpers.parsePath(stat.label, playerStats)) !== 0) {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description);
        }
      }
      return statsResult.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.startingQuarterbacks {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.startingQuarterback {
  display: flex;
}
.startingQuarterbackCentered {
  display: flex;
  align-items: center;
}
.startingQuarterbackStats {
  margin: 0 20px;
  max-width: 250px;
}
.startingQuarterbackSubtext {
  font-weight: initial;
  margin: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  max-width: 300px;
}
</style>