<template>
  <div>
    <div class="gameDetails" v-for="(game, index) in player.fspGames" :key="index">
      <div class="gameDate">
        {{ new Date(game.date).toLocaleDateString('en-US', {dateStyle: "medium"}) }} {{ parseInt(game.timestamp) | moment("h:mma") }} {{ game.home === 'true' ? 'vs' : '@' }} {{ game.opp_alias }}
      </div>
      <table class="detailsTable" style="width: auto;" aria-label="Team Detail">
        <template v-if="game.ObPoints">
          <tr v-for="(points, index1) in game.ObPoints" :key="index1 + 'head'" style="border-bottom: 1px solid var(--obcolor-background-2);">
            <!-- Ignores the "total" value, only goes for points objects -->
            <template v-if="pointObjectHasPoints(points)">
              <td class="gameType">Type</td>
              <template v-for="(pointVal, pointType) in points">
                <template v-if="pointVal !== 0">
                  <th class="gamePointField" :key="pointType">{{ pointType }}</th>
                </template>
              </template>
            </template>
          </tr>
          <tr v-for="(points, index1) in game.ObPoints" :key="index1 + 'points'">
            <!-- Ignores the "total" value, only goes for points objects -->
            <template v-if="pointObjectHasPoints(points)">
              <td class="gameType" style="padding-top: 5px;">Pts</td>
              <template v-for="(pointVal, pointType) in points">
                <template v-if="pointVal !== 0">
                  <td class="gamePointField" :key="pointType">{{ pointVal }}</td>
                </template>
              </template>
            </template>
          </tr>
          <tr v-for="(points, index1) in game.ObPoints" :key="index1 + 'projected'">
            <!-- Ignores the "total" value, only goes for points objects -->
            <template v-if="pointObjectHasPoints(points)">
              <th class="gameType">Proj</th>
              <template v-for="(pointVal, pointType) in points">
                <template v-if="pointVal !== 0">
                  <td class="gamePointField" :key="pointType">{{ game.ProjPoints[index1][pointType] }}</td>
                </template>
              </template>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(points, index1) in game.ProjPoints" :key="index1 + 'head'">
            <!-- Ignores the "total" value, only goes for points objects -->
            <template v-if="pointObjectHasPoints(points)">
              <th class="gameType">Type</th>
              <template v-for="(pointVal, pointType) in points">
                <template v-if="pointVal !== 0">
                  <th class="gamePointField" :key="pointType">{{ pointType }}</th>
                </template>
              </template>
            </template>
          </tr>
          <tr v-for="(points, index1) in game.ProjPoints" :key="index1 + 'points'">
            <!-- Ignores the "total" value, only goes for points objects -->
            <template v-if="pointObjectHasPoints(points)">
              <th class="gameType" style="padding-top: 5px;">Proj</th>
              <template v-for="(pointVal, pointType) in points">
                <template v-if="pointVal !== 0">
                  <td class="gamePointField" :key="pointType">{{ pointVal }}</td>
                </template>
              </template>
            </template>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>


export default {
  name: 'GameDetails',
  props: {
    player: Object,
  },
  methods: {
    pointObjectHasPoints(pointObject) {
      if (typeof pointObject !== 'object') {
        return false;
      }
      for (const pIndex in pointObject) {
        if (pointObject[pIndex] !== 0) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .detailsTable {
    margin-bottom: 10px;
  }
  .gameDate {
    font-size: 14px;
    font-weight: bold;
  }
  .gameType {
    width: 30px;
    padding-right: 5px;
    border-right: 1px solid var(--obcolor-background-2);
    text-align: center;
  }
  .gamePointField {
    width: 30px;
    padding-right: 5px;
    text-align: center;
  }
</style>
