<template>
  <div v-if="playerDetails.gamelog">
    <table style="border-radius: 5px; width: calc(100% - 1px);" aria-label="Player Card Previous Game">
      <tr style="background: var(--obcolor-background-2);" v-if="scoringStats.length > 1">
        <th class="endSection" colspan="4" scope="col"></th>
        <th class="endSection" style="padding-top: 10px; padding-bottom: 0px; font-size: 12px;" v-for="(category, index) in scoringStats"
          :key="index"
          :colspan="category.stats.length" scope="col"
        >
          {{ category.label }}
        </th>
      </tr>
      <tr style="background: var(--obcolor-background-2);">
        <th colspan="2" class="leftInfo tableTitle" scope="col">GAMELOG</th>
        <th style="color: var(--obcolor-font-primary);" scope="col"
          v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat' }"
        >
          FPTS
        </th>
        <th v-tooltip.bottom="{content: 'Projected Fantasy Points', class:'ObTooltipHeat' }"
          class="endSection" scope="col"
        >
          PROJ
        </th>
        <template v-for="(category, index1) in scoringStats">
          <th v-for="(stat, index2) in category.stats" :key="index1 + '_' + index2"
            v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat' }"
            :class="{endSection: index2 == category.stats.length - 1}"
          >
            {{ stat.label }}
          </th>
        </template>
      </tr>
      <tr v-for="(game, index) in showMoreGamelog" :key="index">
        <!-- Game Result -->
        <td class="leftInfo" style="border-right: none;">
          <div class="gameSummary">
            <div style="min-width: 47px;">{{ $moment(game.date).format('MMM D') }}</div>
            <div style="min-width: 20px; text-align: center;">{{ game.home == 'true' ? 'vs' : "@" }}</div>
            <img class="teamLogo" :src="getTeamLogo(game.opponent)" :alt="game.opponent">
          </div>
        </td>
        <td style="width: 130px; border: none;">
          <div style="display: flex; align-items: center; white-space: nowrap; margin-left: 8px; margin-right: 8px;">
            <PlayerStatus style="margin-top: -1px;" height="16" :status="game.status" />
            <div style="margin-left: 8px;">
              {{ game.result == 'win' ? 'Win' : game.result == 'loss' ? 'Loss' : '' }} {{ game.score }}
            </div>
          </div>
        </td>
        <!-- Proj & FPTS -->
        <td style="font-weight: bold;">{{ game.ObTotals ? game.ObTotals.total : "-" }}</td>
        <td style="color: var(--obcolor-font-secondary)">{{ game.ProjPoints ? game.ProjPoints.total.toFixed(0) : "-" }}</td>
        <!-- Stats -->
        <template v-for="(category, index1) in scoringStats">
          <template v-for="(stat, index2) in category.stats">
            <td :key="index1 + '_' + index2" v-if="getStat(game, stat.path) != '-' && playedGame(game)"
              :style="'background: ' + $ScoringStats.getStatBackground(getStat(game, stat.path), stat) "
            >
              <span>{{ getStat(game, stat.path) }}</span>
            </td>
            <td v-else :key="index1 + '_' + index2">-</td>
          </template>
        </template>
      </tr>
    </table>
    <template v-if="showExpand()">
      <div class="showMore" v-if="!isExpanded" @click="setIsExpanded(true)">Show more...</div>
      <div class="showMore" v-else @click="setIsExpanded(false)">Show less</div>
    </template>
  </div>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  props: {
    playerDetails: Object,
    sport: String,
  },

  components: {
    PlayerStatus,
  },

  data() {
    return {
      scoringStats: {},
      isExpanded: false,
      minimizedLimit: 5,
    };
  },

  created() {
    this.scoringStats = this.$ScoringStats.getLiveStats(this.sport, this.playerDetails.position);
  },

  computed: {
    showMoreGamelog() {
      if (this.isExpanded === false) {
        return this.playerDetails.gamelog.slice(0, this.minimizedLimit);
      }
      return this.playerDetails.gamelog;
    },
  },

  methods: {
    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    showExpand() {
      return this.playerDetails.gamelog && this.playerDetails.gamelog.length > this.minimizedLimit;
    },

    playerHasStat(statPath) {
      if (!statPath) {
        return false;
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      if (playerRole === '' || !playerRole) {
        return false;
      }
      return this.playerDetails.gamelog && this.playerDetails.gamelog[0] && this.playerDetails.gamelog[0].stats && this.playerDetails.gamelog[0].stats[playerRole];
    },

    getStat(game, statPath) {
      if (!game || !game.stats || !statPath) {
        return '-';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game.stats[playerRole]) {
        return '0';
      }
      const stat = game.stats[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    // TODO - move logic to backend? Provide simple played flag?
    playedGame(game) {
      let timeVariable = '';
      switch (this.sport) {
        case 'NHL':
          timeVariable = 'TOI';
          break;
        case 'NBA':
          timeVariable = 'MIN';
          break;
        case 'NFL': case 'MLB':
          return game.stats != null;
        default:
          break;
      }
      for (const pIndex in game.stats) {
        const pointsObject = game.stats[pIndex];
        if (typeof pointsObject === 'object') {
          const gameTime = pointsObject[timeVariable];
          if (gameTime != null && gameTime != '-' && gameTime != '' && gameTime != '00:00' && gameTime != '0:00') {
            return true;
          }
        }
      }
      return false;
    },

    setIsExpanded(isExpanded) {
      this.isExpanded = isExpanded;
      EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'previousGames');
    },
  },
};
</script>

<style lang="scss" scoped>
.leftInfo.tableTitle {
  font-size: 14px;
}

.leftInfo {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding: 12px 15px;
  padding-right: 0px;
  width: 100px;
  white-space: nowrap;
}

.tableTitle {
  color: var(--obcolor-font-secondary);
}

.gameSummary {
  display: flex;
  min-width: 95px;
  align-items: center;
}

.teamLogo {
  width: 25px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: 4px;
}

tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

tr:nth-child(even):not(:first-child) {
  background: var(--obcolor-background-6);
}

th {
  color: var(--obcolor-font-secondary);
  padding: 10px;
  position: sticky;
  top: 0px;
}

td {
  border: 2px solid var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
}

th:not(.leftInfo), td:not(.leftInfo) {
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  min-width: 50px;
}

.showMore {
  padding: 5px 10px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  cursor: pointer;
}

.endSection:not(:last-child) {
  border-right: 2px solid var(--obcolor-background-6);
}
</style>