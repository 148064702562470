<template>
  <div>
    <Lottie v-show="getTheme() === 'dark'"
      class="obLoadingAnimation" :style="'width: ' + defaultSize + 'px; height: ' + defaultSize + 'px;'"
      :options="{animationData: require('@/assets/loading_dark.json'), autoplay: true, loop: true}"
    />
    <Lottie v-show="getTheme() === 'light'"
      class="obLoadingAnimation" :style="'width: ' + defaultSize + 'px; height: ' + defaultSize + 'px;'"
      :options="{animationData: require('@/assets/loading_light.json'), autoplay: true, loop: true}"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Lottie from 'vue-lottie';

export default {
  components: {
    Lottie,
  },
  props: {
    size: Number,
    customTheme: String,
  },
  computed: {
    defaultSize() {
      if (this.size == null) {
        return 90;
      }

      return this.size;
    },

    ...mapState(['theme']),
  },

  methods: {
    getTheme() {
      if (this.customTheme != null) {
        return this.customTheme;
      }

      return this.theme;
    },
  },
};
</script>

<style lang="scss" scoped>
.obLoadingAnimation {
  opacity: 0.5;
}
</style>
