<template>
  <div class="statsContainer ob-scroll">
    <table aria-label="Matchup Statistics">
      <!-- If there are multiple stat categories, render an upper row showing the categories -->
      <tr v-if="rosterScoringStats.length > 1">
        <!-- Filter selector and stats/fpts selector -->
        <th class="rowHeader" colspan="2" rowspan="2"
          style="text-align: left; max-width: 130px;" scope="col"
        >
          <!-- Stat category dropdown, only for NFL and MLB -->
          <select v-if="rosterFilters && rosterFilters.length > 1" v-model="selectedFilterIndex" class="statDropDown">
            <option v-for="(roster, index) in rosterFilters" :key="index" :value="index">{{ roster.label }}</option>
          </select>
          <!-- Filter selector and stats/fpts selector -->
          <div style="display: flex; align-items: center; margin-bottom: -3px;">
            <div class="statSwitch switchOn" :class="{active: showFpts}" @click="showFpts = false">Stats</div>
            <div class="statSwitch switchOff" :class="{active: !showFpts}" @click="showFpts = true">FPTS</div>
          </div>
        </th>
        <!-- Row header descriptors such as "Defense","Rushing", etc. -->
        <th class="rowSubHeader" style="padding-top: 15px;" v-for="(category, index) in rosterScoringStats"
          :key="index" :colspan="category.stats.length" scope="col"
          :class="{ endSection: index != rosterScoringStats.length - 1 }"
        >
          {{ category.label }}
        </th>
      </tr>
      <!-- "Main" row if there was only one row, or "subheading" row if there were multiple categories -->
      <tr>
        <!-- Only show filters here if there was one category, otherwise it's rendered above with 2 rowspan -->
        <th v-if="rosterScoringStats.length == 1" scope="row"
          class="rowHeader" colspan="2" style="text-align: left; max-width: 130px;"
        >
          <!-- Stat category dropdown, only for NFL and MLB -->
          <select v-if="rosterFilters && rosterFilters.length > 1" v-model="selectedFilterIndex" class="statDropDown">
            <option v-for="(roster, index) in rosterFilters" :key="index" :value="index">{{ roster.label }}</option>
          </select>
          <!-- Filter selector and stats/fpts selector -->
          <div style="display: flex; align-items: center; margin-bottom: -3px;">
            <div class="statSwitch switchOn" :class="{active: showFpts}" @click="showFpts = false">Stats</div>
            <div class="statSwitch switchOff" :class="{active: !showFpts}" @click="showFpts = true">FPTS</div>
          </div>
        </th>
        <!-- Show one heading with the stats if there is only one category -->
        <template v-if="rosterScoringStats.length == 1">
          <th class="rowHeader" v-for="stat in rosterScoringStats[0].stats" :key="stat.path"
            v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}" scope="row"
          >
            {{ stat.label }}
          </th>
        </template>
        <!-- If there are multiple categories, loop through them to show, and put a border to the right at the end of each category -->
        <template v-else>
          <template v-for="(category, index) in rosterScoringStats">
            <th class="rowSubHeader borderBottom" v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
              :class="{endSection: index1 == category.stats.length - 1 && index != rosterScoringStats.length - 1}"
              v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
            >
              {{ stat.label }}
            </th>
          </template>
        </template>
      </tr>
      <MatchupStatsTableRow :league="league"
        :roster="team1Roster"
        :fpts="team1Fpts"
        :totals="team1Totals"
        :selectedDay="selectedDay"
        :selectedFilterIndex="selectedFilterIndex"
        :showFpts="showFpts"
        :userId="league.matchup.team1Id"
      />
      <MatchupStatsTableRow :league="league"
        :roster="team2Roster"
        :fpts="team2Fpts"
        :totals="team2Totals"
        :selectedDay="selectedDay"
        :selectedFilterIndex="selectedFilterIndex"
        :showFpts="showFpts"
        :userId="league.matchup.team2Id"
      />
    </table>
  </div>
</template>

<script>
import MatchupStatsTableRow from '@/views/Contest/Header/MatchupStatsTableRow';
import EventBus from '@/event-bus';

export default {
  components: {
    MatchupStatsTableRow,
  },

  props: {
    league: Object,
    selectedDay: Number,
    team1Roster: Object,
    team2Roster: Object,
    team1Totals: Object,
    team2Totals: Object,
    team1Fpts: Object,
    team2Fpts: Object,
  },

  data() {
    return {
      showFpts: false,
      selectedFilterIndex: 0,
    };
  },

  computed: {
    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.league.sport);
    },

    rosterScoringStats() {
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return [];
    },
  },

  methods: {
    selectFilter(rosterIndex) {
      this.selectedFilterIndex = rosterIndex;
      EventBus.$emit('CLOSE_DROPDOWNS');
    },
  },
};
</script>

<style lang="scss" scoped>
.statsContainer {
  flex: 1;
  overflow-x: auto;
  border-right: 1px solid var(--obcolor-background-2);
}
table {
  text-align: center;
  width: 100%;
  height: 180px;
}
td, .rowHeader {
  padding: 8px 6px 8px 6px;
  white-space: nowrap;
}
.rowHeader {
  background: var(--obcolor-background-6);
  font-size: 13px;
  border-bottom: 1px solid var(--obcolor-background-2);
  height: 60px;
  box-sizing: border-box;
}
.rowSubHeader {
  background: var(--obcolor-background-6);
  font-size: 13px;
  height: 30px;
  // Setting width to very wide which will make each column equal width instead
  width: 100px;
  box-sizing: border-box;
}

.borderBottom {
  border-bottom: 1px solid var(--obcolor-background-2);
}

.endSection {
  border-right: 1px solid var(--obcolor-background-2);
}

.logoCol {
  max-width: 30px; width: 30px;
  box-sizing: border-box;
}

.scoreCol {
  text-align: left;
  font-size: 14px;
  max-width: 40px;
  width: 40px;
  box-sizing: border-box;
}

.matchHeaderScore {
  background: var(--obcolor-background-2);
  padding: 5px 10px;
  border-radius: 10px;
}

.statDropDown {
  width: 101px;
  height: 20px;
  margin-bottom: 6px;
  padding: 0 2px;
  font-size: 12px;
  font-weight: bold;
}

.statSwitch {
  font-size: 12px;
  border-radius: 5px;
  padding: 4px 9px;
  text-transform: uppercase;
  cursor: pointer;
}
.statSwitch.switchOn {
  border-radius: 5px 0 0 5px;
}
.statSwitch.active {
  background: var(--obcolor-background-page);
  color: var(--obcolor-text-faded);
}
.statSwitch:not(.active) {
  background: var(--obcolor-background-2);
  color: var(--obcolor-font-primary);
}
.statSwitch.switchOff {
  border-radius: 0 5px 5px 0;
}

.clickableIcon {
  padding: 5px 10px !important;
  width: max-content !important;
  cursor: pointer;
}

.optionButton {
  padding: 5px 10px !important;
}

.filterBtn {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}

.filterText {
  font-size: 10px;
  width: max-content;
}

.filterBtnContainer {
  cursor: pointer;
  width: 40px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
  margin-bottom: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>