<template>
  <div class="ob-page">
    <h2>Connections</h2>
    <IntegrationsList v-if="accountInfoLoaded" class="integrationsList" />
  </div>
</template>

<script>
import IntegrationsList from './IntegrationsList.vue';
import {mapState} from 'vuex';
export default {
  props: {},

  components: {
    IntegrationsList,
  },

  computed: {
    ...mapState(['accountInfoLoaded']),

    verifyAccountMessage() {
      return 'You must verify your account to connect with Kroger';
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  margin-top: 20px;
}
.integrationsList {
  width: 100%;
}
</style>