<template>
  <TooltipContainer :pos="pos" style="display: inline-block;">
    <slot name="trigger" slot="trigger"></slot>
    <div slot="content">
      <TooltipText :textList="$Tooltips.getTooltips(textKey)" />
    </div>
  </TooltipContainer>
</template>

<script>
import TooltipText from '@/components/tooltip/TooltipText';
import TooltipContainer from '@/components/tooltip/TooltipContainer';

export default {
  components: {
    TooltipText,
    TooltipContainer,
  },

  props: {
    pos: String,
    textKey: String,
  },
};
</script>