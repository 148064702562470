<template>
  <tr @click="viewLineup(team)"
    :class="{ selectedTeam : isSelected(), myEntry : isMyTeam() || contestIsOver() || leagueData.format == 'DAILY' }"
    :style="getFlashStyle() + transition"
    :id="'leaderboardRow_' + team.teamId"
  >
    <!-- Rank -->
    <td class="teamRank" style="position: relative; overflow: hidden;">
      <div v-if="isMyTeam()" class="myTeamIndicator"></div>
      <div>
        <div v-if="team.rank <= 3" :style="getIconColor(team.rank)"><i class="fas fa-trophy"></i></div>
        <div v-else-if="team.rank < 100">{{ String(team.rank).addSuffix() }}</div>
        <div v-else>{{ team.rank }}</div>
      </div>
    </td>

    <!-- Team logo -->
    <td class="userLogoContainer">
      <UserLogo class="logo" :id="team.userId" :sport="sport"
        :size="40" :showRank="true" :rankColor="team.rankColor"
        :rankNum="team.rankNum"
      />
    </td>

    <!-- Rename Team -->
    <template v-if="renaming">
      <td class="nameCol" colspan="6" @click.stop="">
        <div style="display: flex; align-items: center; padding-right: 12px;">
          <div>
            <input v-model="renameInput" class="nameInput" maxlength="35"
              placeholder="Enter lineup name..." type="text"
            >
            <div class="renameInfo">Custom lineup names are only visible to you</div>
          </div>
          <div style="flex: 1;"></div>
          <div v-if="savingRename">Saving...</div>
          <div v-if="!savingRename" class="ob-btn-grey renameBtn" @click.stop="renaming = false">Cancel</div>
          <div v-if="!savingRename" class="ob-btn renameBtn" @click.stop="saveRename()">Save</div>
        </div>
      </td>
    </template>

    <template v-else>
      <td class="nameCol">
        <div style="display: flex; align-items: center;">
          <!-- Team Name -->
          <div>
            <div class="teamName">
              <span>{{ getTeamName() }}</span>
              <span v-if="team.entries > 1" style="margin-left: 3px;">({{ team.teamNum }})</span>
              <i v-if="isMyTeam()" @click.stop="renameTeam()" class="fas fa-edit renameIcon"></i>
            </div>
            <div :style="isMyTeam() ? 'color: var(--obcolor-ob-blue);' : ''" class="userName">{{ team.name }}</div>
          </div>
          <div style="flex: 1;"></div>

          <!-- Team Warning Icons -->
          <template v-if="isMyTeam()">
            <div v-if="teamSalaryWarning === 'over'">
              <img class="warnIcon" :src="require('@/assets/salarycap/warn_overcap.png')" alt="Warning"
                v-tooltip.right="{content: warnOverSalary, class:'ObTooltipHeat'}"
              >
            </div>
            <div v-else-if="teamSalaryWarning === 'under'">
              <img class="warnIcon" :src="require('@/assets/salarycap/warn_extrasalary.png')" alt="Extra Salary"
                v-tooltip.right="{content: warnExtraSalary, class:'ObTooltipHeat'}"
              >
            </div>
            <div v-if="teamUnderLimits !== ''">
              <img class="warnIcon" :src="require('@/assets/salarycap/warn_overcap.png')" alt="Warning"
                v-tooltip.right="{content: warnUnderOpps, class:'ObTooltipHeat'}"
              >
            </div>
          </template>
        </div>
      </td>

      <!-- Winnings -->
      <td class="winningsContainer">
        <div>
          <img v-if="!leagueData.realMoney" style="height: 10px;" :src="require('@/assets/icons/ownersbucks.png')"
            alt="OwnersBucks Icon"
          >
          <div :class="{moneyText: leagueData.realMoney == true && team.prizeValue !== 0, blueText: leagueData.realMoney == false && team.prizeValue !== 0}">
            {{ team.prize ? team.prize.removeCentsIfZero() : dollarFormat(0) }}
          </div>
          <template v-if="team.splitTicketPrize || team.ticketPrize">
            <div style="font-size: 16px;">+</div>
            <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
              v-tooltip.bottom="getTicketTooltip(team)"
            >
          </template>
        </div>
      </td>

      <!-- Game Opps -->
      <td v-if="leagueData.format == 'WEEKLY'" style="min-width: 90px;">
        <PieChart size="24" :slices="getPieSlices(team)" />
        <div class="gameOpps">{{ team.gp }} / {{ maxGames }}</div>
      </td>

      <!-- Projected Points -->
      <td v-if="!isMyTeam() && isPreContest()" class="teamProj" style="min-width: 70px;">-</td>
      <td v-else class="teamProj" style="min-width: 70px;">{{ team.proj }}</td>

      <!-- Score becomes edit lineup button while in Pre-Contest -->
      <td v-if="isMyTeam() && isPreContest()" style="padding: 0px; min-width: 90px;">
        <div class="ob-btn editBtn" @click.stop="editLineup()">
          <div>Edit</div>
          <div>Lineup</div>
        </div>
      </td>
      <td v-else-if="isPreContest()" style="padding: 0px; min-width: 90px;" class="teamProj">-</td>
      <td v-else style="padding: 0px; min-width: 90px;"><div class="scoreBox">{{ team.score }}</div></td>
    </template>
  </tr>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import PieChart from '@/components/charts/PieChart';
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';

export default {

  components: {
    UserLogo,
    PieChart,
  },

  props: {
    leagueData: {type: Object, default: null},
    team: {type: Object, default: null},
    customNames: {type: Object, default: null},
    salaryWarnings: {type: Object, default: null},
    overLimits: {type: Object, default: null},
    underLimits: {type: Object, default: null},
    maxGames: {type: Number, default: null},
    sport: {type: String, default: null},
    myUserId: {type: String, default: null},
    leagueId: {type: String, default: null},
    teamId: {type: String, default: null},
    oppTeamId: {type: String, default: null},
  },

  data: function() {
    return {
      renameInput: '',
      renaming: false,
      savingRename: false,
      flashUpdate: null,
      transition: '',

      warnExtraSalary: 'This team is under the salary cap by a large margin, consider making a swap',
      warnOverSalary: 'This team is over the salary cap, some games will not count',
      warnUnderOpps: 'This team has unused game opportunities',
    };
  },

  computed: {
    teamSalaryWarning() {
      return this.salaryWarnings[this.team.teamId] || '';
    },

    teamOverLimits() {
      return this.overLimits[this.team.teamId] || '';
    },

    teamUnderLimits() {
      return this.underLimits[this.team.teamId] || '';
    },
  },

  watch: {
    team(to, from) {
      this.flashChanges(to, from);
    },
  },

  methods: {

    editLineup() {
      this.$router.push('/lineup-builder/' + this.leagueId + '/' + this.team.teamId);
    },

    isPreContest() {
      if (!this.leagueData) {
        return false;
      }
      if (this.leagueData.state !== 'FILLING' && this.leagueData.state !== 'FILLED') {
        return false;
      }
      return !this.leagueData.contestStart < new Date().getTime();
    },

    getFlashStyle() {
      if (!this.flashUpdate) {
        return '';
      }
      if (this.flashUpdate === 'up') {
        return 'background: green !important;';
      }
      if (this.flashUpdate === 'down') {
        return 'background: red !important;';
      }
      return '';
    },

    flashChanges(to, from) {
      if (from.score > to.score) {
        // Score went down
        this.flashEntry('down');
      } else if (from.score < to.score) {
        // Score went up
        this.flashEntry('up');
      }
    },

    flashEntry(type) {
      const self = this;
      this.transition = 'transition-duration: 600ms;';
      this.flashUpdate = type;
      setTimeout(() => {
        self.flashUpdate = null;
      }, 800);
      setTimeout(() => {
        self.transition = '';
      }, 1600);
    },

    getIconColor(rank) {
      switch (rank) {
        case 1: return 'color: orange;';
        case 2: return 'color: #C0C0C0;';
        case 3: return 'color: #B1560F;';
        case 4: return 'color: #C0C0C0;';
      }
      return '';
    },

    contestIsOver() {
      if (!this.leagueData) {
        return false;
      }
      const state = this.leagueData.state;
      return state === 'FINALIZED' || state === 'COMPLETED';
    },

    viewLineup(entry) {
      if (this.leagueData.format == 'WEEKLY' && (this.contestIsOver() || entry.userId === this.myUserId)) {
        this.$router.push('/salarycontest/leaderboard/' + this.leagueId + '/' + entry.teamId);
      } else if (this.leagueData.format == 'DAILY') {
        EventBus.$emit('SELECT_DAILY_LEADERBOARD_TEAM', entry);
      }
    },

    getTeamName() {
      const cName = this.customNames[this.team.teamId];
      if (this.isMyTeam() && cName && cName != '') {
        return cName;
      }
      return this.team.team;
    },

    isSelected() {
      return this.team.teamId === this.teamId || this.team.teamId == this.oppTeamId;
    },

    isMyTeam() {
      return this.team.userId === this.myUserId;
    },

    renameTeam() {
      const cName = this.customNames[this.team.teamId];
      if (this.isMyTeam() && cName && cName != '') {
        this.renameInput = cName;
      }
      this.renaming = true;
    },

    saveRename() {
      this.savingRename = true;
      const tid = this.team.teamId;
      const name = this.renameInput.trim();

      ObSalaryCapApi.renameTeam(this.leagueId, tid, name)
          .then((response) => {
            this.$set(this.customNames, tid, name);
            this.savingRename = false;
            this.renaming = false;
          }).catch((error) => {
          // TODO - user feedback for failed rename
            this.savingRename = false;
          });
    },

    getPieSlices(team) {
      const slices = [];
      const pct = Math.round( team.gp / this.maxGames * 100 );

      let baseColor = 'var(--obcolor-ob-blue)';
      if (this.isMyTeam()) {
        if (this.teamUnderLimits !== '') {
          baseColor = 'red';
        } else if (this.teamOverLimits !== '') {
          baseColor = 'orange';
        }
      }

      slices.push({color: baseColor, percent: pct});
      slices.push({color: 'background', percent: 100 - pct});
      return slices;
    },

    dollarFormat(dollarAmount) {
      const dollarSign = this.leagueData.realMoney ? '$' : '';
      if (!dollarAmount) {
        return dollarSign + '0';
      }
      const d = Math.round(dollarAmount);
      return dollarSign + Intl.NumberFormat('en-US').format(d);
    },

    getTicketTooltip(team) {
      let text = '';
      if (team.ticketPrize) {
        text = team.ticketPrize;
      } else if (team.splitTicketPrize) {
        text = team.splitTicketPrize;
      }
      return {content: text, class: 'ObTooltip'};
    },
  },

};
</script>

<style lang="scss" scoped>

th, td {
  text-align: center;
}
td {
  height: 60px;
  font-size: 14px;
  border-bottom: 1px solid var(--obcolor-background-page);
}
tr {
  transition-property: background-color;
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.nameCol {
  text-align: left;
  width: 100%;
  min-width: 200px;
  white-space: nowrap;
  padding-right: 10px;
}

.selectedTeam {
  background: var(--obcolor-background-2) !important;
}

.myEntry {
  cursor: pointer;
}
.myEntry:hover {
  background: var(--obcolor-background-4);
}

.teamRank {
  min-width: 70px;
  width: 70px;
  font-weight: bold;
  font-size: 14px;
}

.fa-trophy {
  font-size: 16px;
}

.warnIcon {
  height: 16px;
  margin-left: 2px;
  margin-right: 2px;
}

.userLogoContainer {
  min-width: 53px;
  text-align: left;
  padding-right: 5px;
}
.logo {
  margin-top: 3px;
}

.teamName {
  font-size: 12px;
  margin-top: -2px;
}
.userName {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: -2px;
  color: var(--obcolor-font-secondary);
}

.renameIcon {
  color: var(--obcolor-font-secondary);
  margin-left: 6px;
  cursor: pointer;
}
.renameIcon:hover {
  color: var(--obcolor-font-primary);
}
.nameInput {
  width: 225px;
  background: var(--obcolor-background-1);
  border-radius: 0;
  font-size: 12px;
}
.renameInfo {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  margin-top: 4px;
  margin-bottom: -4px;
}
.renameBtn {
  margin-right: 6px;
  padding: 6px 10px;
  border-radius: 0;
  font-size: 12px;
  width: 45px;
}

.gameOpps {
  font-size: 11px;
  margin-left: 2px;
  margin-top: 1px;
  margin-bottom: -1px;
}

.teamProj {
  color: var(--obcolor-font-secondary);
}

.scoreBox {
  // background: var(--obcolor-background-2);
  display: inline-block;
  padding: 5px;
  min-width: 45px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  color: var(--obcolor-ob-blue);
}

.myTeamIndicator {
  $size: 18px;
  height: $size;
  width: $size;
  background: var(--obcolor-sportscolour-nba);
  transform: rotate(45deg);
  position: absolute;
  top: -($size/2);
  left: -($size/2);
}

.editBtn {
  font-size: 11px;
  font-weight: normal;
  padding: 3px 8px;
  border-radius: 0;
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}
.moneyText {
  color: var(--obcolor-green) !important;
}

.winningsContainer {
  font-weight: bold;
  min-width: 100px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}

.ticketIcon {
  height: 12px;
  margin-top: -1px;
}
</style>
