<template>
  <form @submit.prevent="verifyCode" class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
      <a class="onboardingForwardBtn" v-if="showNextArrow" @click="goNext"><i class="far fa-chevron-right"></i></a>
    </div>

    <h1>Confirm It’s You</h1>
    <p>Enter the 4 digit code sent to {{ signupData ? signupData.phoneNumber : '' }}</p>

    <div class="title">Verification Code</div>

    <div class="inputContainer">
      <input v-for="(digit, index) in codeDigit" :key="index" v-model="codeDigit[index]"
        :ref="'inputBox' + index"
        type="text" maxlength="1" @input="inputFocus($event.target, index)"
        :style="errorMessage ? 'border-bottom: 2px solid var(--obcolor-red);' : ''"
      >
    </div>


    <ErrorPanel :message="errorMessage" />

    <div class="resendCode">
      Didn't receive anything?
      <span v-if="resending" class="resending">Sending...</span>
      <span v-else-if="!resendTimeoutSeconds" class="resendLink" @click="resendCode">Resend code</span>
      <span v-if="resendTimeoutSeconds" class="resent">Code Sent!</span>
      <span v-if="resendTimeoutSeconds" class="resendTimeout">(Resend in {{ resendTimeoutSeconds }}s)</span>
    </div>

    <button v-if="verifying" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>Verifying...
    </button>

    <button v-else-if="verified" class="ob-btn successBtn">
      <i class="fa fa-check"></i>
      Phone number has been verified
    </button>

    <button v-else class="ob-btn" @click="verifyCode()"
      :disabled="!canSubmit"
    >
      Confirm Phone Number
    </button>
  </form>
</template>

<script>
import ObPhoneVerificationApi from '@/api/ObPhoneVerificationApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    ErrorPanel,
  },

  data() {
    return {
      codeDigit: ['', '', '', ''],
      verifying: false,
      verified: false,
      errorMessage: null,
      resending: false,
      resendTimeoutSeconds: 0,
    };
  },

  props: {
    signupData: {type: Object, default: null},
  },

  computed: {
    canSubmit() {
      return this.codeDigit.join('').length === 4;
    },

    showNextArrow() {
      // Next arrow appears if phone number has already been verified
      return this.signupData?.phoneVerified;
    },
  },

  mounted() {
    this.focusFirstInput();
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },

    goNext() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    resetInputBoxes() {
      this.codeDigit = ['', '', '', ''];
      this.focusFirstInput();
    },

    focusFirstInput() {
      this.$nextTick(() => {
        const inputBox = this.$refs.inputBox0;
        if (inputBox?.[0]) {
          inputBox[0].focus();
        }
      });
    },

    updateResendTimeout() {
      setTimeout(() => {
        if (this.resendTimeoutSeconds > 0) {
          this.resendTimeoutSeconds -= 1;
          this.updateResendTimeout();
        }
      }, 1000);
    },

    verifyCode() {
      if (!this.canSubmit || this.verifying || this.verified || this.resending) {
        return;
      }
      const verifyCode = this.codeDigit.join('');
      const id = this.signupData?.phoneVerificationId;
      this.errorMessage = null;
      this.verifying = true;

      ObPhoneVerificationApi.verifyPhoneNumber(id, verifyCode)
          .then((_response) => {
            // Show verified message, continue to next step in x seconds
            this.verified = true;
            setTimeout(() => {
              EventBus.$emit('UPDATE_SIGNUP_DATA', 'phoneVerified', true);
              this.goNext();
            }, 1500);
          })
          .catch((error) => {
            this.resetInputBoxes();
            if (error?.response?.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Failed to verify phone number, please contact customer support';
            }
          })
          .finally(() => {
            this.verifying = false;
          });
    },

    resendCode() {
      // Call to send text message
      const id = this.signupData?.phoneVerificationId;
      const phoneNumber = this.signupData?.phoneNumber;
      this.resending = true;

      ObPhoneVerificationApi.resendVerificationCode(id, phoneNumber)
          .then((response) => {
            // Verification id may change if the original is not valid
            if (response?.id) {
              EventBus.$emit('UPDATE_SIGNUP_DATA', 'phoneVerificationId', response.id);
              this.resendTimeoutSeconds = 30;
              this.updateResendTimeout();
            } else {
              this.errorMessage = 'Failed to send verification code, please contact customer support';
            }
          })
          .catch((error) => {
            if (error?.response?.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Failed to send verification code, please contact customer support';
            }
          })
          .finally(() => {
            this.resending = false;
          });
    },

    inputFocus(input, index) {
      // Move to the next input field
      if (input.value && index < this.codeDigit.length - 1) {
        input.nextElementSibling.focus();
      }

      // If the input is empty and not the first input, move to the previous input
      if (!input.value && index > 0) {
        input.previousElementSibling.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .inputContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  input {
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid var(--obcolor-font-secondary) !important;
    border-radius: 0;
    text-align: center;
    font-size: 22px !important;
    max-width: 150px;
    padding: 0 !important;
    padding-bottom: 10px !important;
  }

  button {
    margin-top: 50px;
    height: 60px;
  }

  .title {
    padding: 50px 0 40px 0;
    color: var(--obcolor-ob-blue);
  }
}

.ob-btn, .ob-btn-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.successBtn {
  background: var(--obcolor-green) !important;
  cursor: default;
}

.resendCode {
  margin-top: 20px;
}
.resendLink {
  color: var(--obcolor-ob-blue);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.resending {
  color: var(--obcolor-ob-blue);
}
.resent {
  color: var(--obcolor-green);
}
.resendTimeout {
  color: var(--obcolor-font-secondary);
  margin-left: 6px
}
</style>