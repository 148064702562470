// @ts-check

import EventBus from '@/event-bus';
import store from '@/store';
import * as braze from '@braze/web-sdk';
import router from '@/router';

/**
 * @param {boolean} isLoggedIn
 * @param {string} userId
 * @return {void}
 */
export function initBraze(isLoggedIn, userId) {
  if (store.getters.isBrazeInitialized) {
    console.log('braze already initialized');

    return;
  }
  const baseURL = window.location.origin;

  // @ts-ignore
  const subfolder = router.options.base;

  const brazeServiceWorker = baseURL + subfolder + 'Braze-service-worker.js';

  // initialize the Braze SDK
  braze.initialize(process.env.VUE_APP_BRAZE_API_KEY, {
    baseUrl: process.env.VUE_APP_BRAZE_ENDPOINT,
    serviceWorkerLocation: brazeServiceWorker,
    allowUserSuppliedJavascript: true,
    minimumIntervalBetweenTriggerActionsInSeconds: 0,
  });
  store.commit('setBrazeInitialized', true);

  subscribeToInAppMessage();

  // if you use Content Cards
  braze.subscribeToContentCardsUpdates((cards) => {
    store.commit('setContentCards', cards.cards.filter((card) => !card.extras.platform || card.extras.platform === 'web'));
  });

  EventBus.$on('ENTER_PLAYER_PICKS_CONTEST', (data) => {
    /** @type {import('@braze/web-sdk').Card[]} */
    const cards = store.getters.contentCards;
    for (const card of cards) {
      if (
        card.extras.clearOnEntry === 'player-picks' && (
          card.extras.isRealMoney === undefined ||
            (card.extras.isRealMoney === String(data.realMoney) && !data.promoCode)
        )
      ) {
        braze.logCardDismissal(card);
      }
    }
  });

  braze.requestContentCardsRefresh();

  // Polling ro new content cards
  // to be replaced with push eventually
  setInterval(() => {
    braze.requestContentCardsRefresh();
  }, 30000);

  // optionally set the current user's external ID
  if (isLoggedIn) {
    braze.changeUser(userId);
  }

  // Be sure to call `openSession` after `automaticallyShowInAppMessages`
  braze.openSession();
}

/**
 * @return {void}
 */
function subscribeToInAppMessage() {
// subscribe to in-app messages
  braze.subscribeToInAppMessage(function(inAppMessage) {
  // check if message is not a control variant
    if (inAppMessage instanceof braze.InAppMessage) {
    // access the key-value pairs, defined as `extras`
      const keyValuePairs = inAppMessage.extras || {};
      // check the value of our key `msg-id` defined in the Braze dashboard
      if (keyValuePairs['msg-id'] === 'push-primer') {
      // We don't want to display the soft push prompt to users on browsers
      // that don't support push, or if the user has already granted/blocked permission
        if (
          braze.isPushSupported() === false ||
          braze.isPushPermissionGranted() ||
          braze.isPushBlocked()
        ) {
        // do not call `showInAppMessage`
          return;
        }

        // user is eligible to receive the native prompt
        // register a click handler on one of the two buttons
        // @ts-ignore
        if (inAppMessage.buttons[1]) {
        // Prompt the user when the first button is clicked
          // @ts-ignore
          inAppMessage.buttons[1].subscribeToClickedEvent(function() {
            braze.requestPushPermission(
                function() {
                // success!
                },
                function() {
                // user declined
                },
            );
          });
        }
      }
    }

    // show the in-app message now
    braze.showInAppMessage(inAppMessage);
  });
}