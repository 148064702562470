<template>
  <div style="border-radius: 0 0 5px 5px; overflow: hidden;">
    <table class="completedContestTable" style="min-width: 0;" aria-label="Complete Contest"
      role="Dashboard View"
    >
      <template v-if="sortedLeagues && sortedLeagues.length > 0 && loaded">
        <template v-for="(league, index) in sortedLeagues">
          <tr v-if="league.ctype === 'LIGHTNING_LINEUPS'" :key="index">
            <td colspan="99" style="padding: 0;">
              <LiveLLContest v-if="league.ctype === 'LIGHTNING_LINEUPS'" :key="index"
                :entry="league" :isCompleted="true" class="liveContestContainer"
              />
            </td>
          </tr>
          <router-link :key="index" v-else :to="getLeaderboardIfTournamentLink(league)"
            :class="{ hoverableRow: league.ctype === 'Tournament' }" class="rowTable"
          >
            <td class="hoverableField titleField" @click="event => openBracketModal(event, league)">
              <div style="display: flex; align-items: center; padding-right: 4px;">
                <img :src="getContestBadge(league.sport)" :alt="league.sport" class="contestBadge">
                <div>
                  <div v-if="league.contestName" class="contestName">{{ league.contestName }}</div>
                  <div v-else style="font-size: 14px;">{{ league.sport }} {{ league.playersMax }} Teams</div>
                  <div v-if="league.state === 'CANCELLED'" style="margin-top: 3px; color: red;">CANCELLED</div>
                  <div class="summaryText" :class="league.realMoney ? 'moneyText' : 'blueText'">
                    Prizes: {{ league.prizePool.removeCentsIfZero() }}
                  </div>
                  <div class="summaryText" v-if="league.fee && league.realMoney == true">
                    Entry: {{ league.fee.removeCentsIfZero() }}
                  </div>
                  <div v-else-if="league.fee" class="summaryText" style="display: flex; align-items: center; width: 100%;">
                    <div>Entry: </div>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 10px; margin-right: 2px; margin-left: 5px; margin-bottom: 2px;">
                    <div>{{ league.fee.removeCentsIfZero() }}</div>
                  </div>
                  <div class="summaryText">
                    {{ getContestType(league) }}
                  </div>
                  <div class="summaryText">
                    {{ getContestDate(league) }}
                  </div>
                </div>
              </div>
            </td>

            <!-- Salary Cap Contest Results -->
            <td v-if="league.ctype === 'Tournament'" class="placeField">
              <div :style="getSalaryContestHeight(league)" class="salaryResults">
                <div class="salaryResultsFlexbox">
                  <div>
                    <div>{{ league.myEntries }}</div>
                    <div class="salaryResultLabel">Entries</div>
                  </div>
                  <div>
                    <div>{{ getNumPlacing(league) }}</div>
                    <div class="salaryResultLabel">Placed</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div :class="league.realMoney ? 'moneyText' : 'blueText'">
                      <img v-if="!league.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                        style="height: 11px; margin-right: 3px;"
                      >
                      {{ getMyPayouts(league).removeCentsIfZero() }}
                    </div>
                    <div class="salaryResultLabel">Winnings</div>
                  </div>
                </div>
              </div>
            </td>
            <!-- H2H Contest Results -->
            <td v-else class="placeField">
              <div v-if="league.results && league.results[userId]" @click="event => openBracketModal(event, league)" class="resultBox hoverableField">
                <div class="resultText">{{ addOrdinalSuffix(league.results[userId]) }}</div>
                <div class="resultLabel">PLACE</div>
              </div>
              <div v-else-if="league.ctype === 'Tournament'">
                <div class="resultText">{{ getNumPlacing(league) }} of {{ league.myEntries }}</div>
                <div class="resultLabel">PLACED</div>
              </div>
            </td>

            <!-- Salary Cap Payout Bar -->
            <template v-if="league.ctype === 'Tournament'">
              <td colspan="5" style="padding: 0 35px;">
                <SalaryContestEntriesBar :contest="league" :teamDate="teamData" />
              </td>
            </template>

            <!-- Entry List for Salary Cap Contests -->
            <td v-if="league.ctype === 'Tournament'" class="salaryEntriesCell">
              <!-- Empty click handler to allow the LiveSalaryEntries pagination to function -->
              <div class="salaryEntriesContainer" @click.prevent="">
                <LiveSalaryEntries :league="league" :myEntries="getMyEntriesList(league)" :canEnterLineup="false"
                  :isPreContest="false" :teamData="teamData" style="position: static; width: 100%;"
                />
              </div>
            </td>
            <!-- Prizes, Winnings, Results for H2H Contests -->
            <template v-else>
              <td style="width: 93px;">
                <div v-if="league.realMoney == true" class="resultText">{{ league.prizePool.removeCentsIfZero() }}</div>
                <div v-else class="resultText" style="display: flex; align-items: center; width: 100%; justify-content: center;">
                  <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 11px; margin-right: 5px; margin-bottom: 2px;">
                  <div>{{ league.prizePool.removeCentsIfZero() }}</div>
                </div>
                <div class="resultLabel">PRIZES</div>
              </td>
              <td style="width: 93px;">
                <div v-if="league.realMoney == true" class="resultText">
                  <div>{{ getMyPayouts(league).removeCentsIfZero() }}</div>
                  <div class="ticketWinnings">{{ getMyTicketPrizes(league) }}</div>
                </div>
                <div v-else class="resultText" style="display: flex; align-items: center; width: 100%; justify-content: center;">
                  <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 11px; margin-right: 5px; margin-bottom: 2px;">
                  <div>{{ getMyPayouts(league).removeCentsIfZero() }}</div>
                </div>
                <div class="resultLabel">WINNINGS</div>
              </td>
              <td style="max-width: 110px; width: 110px;">
                <div v-if="getWinnerId(league) != null" style="display: flex; flex-direction: column; align-items: center;">
                  <UserLogo :id="getWinnerId(league)" :sport="league.sport" :size="30"
                    style="margin-bottom: 5px;"
                  />
                  <div v-if="league.realMoney == true">{{ getWinnerPayout(league) }}</div>
                  <div v-else style="display: flex; align-items: center;">
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 11px; margin-right: 5px;">
                    <div>{{ getWinnerPayout(league) }}</div>
                  </div>
                </div>
                <div v-else>N/A</div>
              </td>
            </template>
          </router-link>
        </template>
      </template>
      <tr v-else-if="loaded == false">
        <td colspan="9" style="height: 30px; padding-left: 15px; color: var(--obcolor-font-secondary);">
          <ObLoading />
        </td>
      </tr>
      <tr v-else>
        <td colspan="9" class="noContestsCell">You have no completed {{ contestTypeText }} in this range</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import LiveLLContest from '@/views/ContestsList/LiveContests/LiveLLContest';
import SalaryContestEntriesBar from '@/views/SalaryContest/SalaryContestEntriesBar.vue';
import LiveSalaryEntries from '@/views/ContestsList/LiveContests/LiveSalaryEntries';
import UserLogo from '@/components/UserLogo';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';

export default {

  components: {
    LiveLLContest,
    SalaryContestEntriesBar,
    LiveSalaryEntries,
    UserLogo,
    ObLoading,
  },

  props: {
    completedFilters: {type: Object, default: null},
    completedLeagues: {type: Array, default: null},
    loaded: {type: Boolean, default: null},
    teamData: {type: Object, default: null},
    contestType: {type: String, default: null},
  },

  computed: {
    ...mapState(['userId']),

    contestSponsorHeader() {
      if (!this.league?.sponsorAds) {
        return null;
      }
      return this.league?.sponsorAds['salarycap-header'];
    },

    sortedLeagues() {
      const self = this;
      const tempList = this.completedLeagues.slice(0).filter((league) => {
        if (self.completedFilters.leagueType === 'all') {
          return true;
        }
        if (league.ctype === 'Tournament') {
          return self.completedFilters.leagueType == 'tournament';
        } else if (league.ctype == 'LIGHTNING_LINEUPS') {
          return self.completedFilters.leagueType == 'lightning';
        } else {
          return self.completedFilters.leagueType == 'h2h';
        }
      }).sort(function(a, b) {
        return b.prizeValue - a.prizeValue;
      }).sort(function(a, b) {
        const eDate1 = self.getStartEnd(a).end;
        const eDate2 = self.getStartEnd(b).end;

        return eDate2.diff(eDate1);
      });

      if (this.completedFilters.leagueCount != 'More') {
        return tempList.slice(0, this.completedFilters.leagueCount);
      }

      return tempList;
    },

    contestTypeText() {
      if (this.contestType === 'tournament') {
        return 'salary cap contests';
      } else {
        return 'Lightning Lineups';
      }
    },
  },

  methods: {
    getPlayerPayouts(league, playerId) {
      let totPayouts = 0;
      if (league.ctype === 'Tournament') {
        const myEntries = this.getMyEntriesList(league);

        for (const entry of myEntries) {
          totPayouts += entry.prizeValue;
        }
      } else {
        for (const weekIndex in league.bracket) {
          for (const matchup of league.bracket[weekIndex].matchups) {
            if (matchup.winner == 'team1' && matchup.team1Id == playerId ||
              matchup.winner == 'team2' && matchup.team2Id == playerId
            ) {
              totPayouts += league.payouts[weekIndex].value;
            }
          }
        }
      }

      return (league.realMoney ? '$' : '') + this.formatMoney(totPayouts / 100);
    },

    getMyPayouts(league) {
      return this.getPlayerPayouts(league, this.userId);
    },

    getMyTicketPrizes(league) {
      if (league.ctype !== 'Tournament') {
        return '';
      }
      let numTickets = 0;
      for (const entry of this.getMyEntriesList(league)) {
        if (entry.ticketPrize) {
          numTickets += 1;
        }
      }
      if (numTickets == 0) {
        return '';
      }
      return numTickets + ' Ticket' + (numTickets > 1 ? 's' : '');
    },

    getStartEnd(league) {
      if (league.ctype === 'Tournament') {
        const start = this.$moment(league.days[0], 'YYYY-MM-DD');
        const end = this.$moment(league.days[league.days.length - 1], 'YYYY-MM-DD');
        return {start: start.startOf('day'), end: end.startOf('day')};
      } else if (league.ctype == 'LIGHTNING_LINEUPS') {
        // Lightning Lineups starts and ends on the same day
        const start = this.$moment(league.contestStart);
        return {start: start, end: start};
      }

      return {start: this.$moment(league.leagueStart).startOf('day'), end: this.$moment(league.leagueEnd).startOf('day')};
    },

    getContestDate(contest) {
      const contestDate = this.getStartEnd(contest);
      const startDate = contestDate.start.format('MMM D');
      const endDate = contestDate.end.format('MMM D');
      if (startDate !== endDate) {
        return startDate + ' - ' + endDate;
      }
      return contestDate.start.format('MMM D, YYYY');
    },

    formatMoney(value) {
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value.toFixed(2)).removeCentsIfZero();
    },

    getLeaderboardIfTournamentLink(league) {
      if (league.ctype === 'HEAD_TO_HEAD') {
        return '/contest/matchup/summary/' + league.id;
      }
      if (league.ctype !== 'Tournament') {
        // Returning empty string to avoid console error
        return '';
      }
      if (!league.leaderboard) {
        return '';
      }
      const myEntries = league.leaderboard.filter((entry) => entry.userId === this.userId);
      return '/salarycontest/leaderboard/' + league.id + '/' + myEntries[0].teamId;
    },

    getMyEntriesList(league) {
      if (!league.leaderboard) {
        return [];
      }
      return league.leaderboard.filter((entry) => entry.userId === this.userId);
    },

    getNumPlacing(league) {
      let numPlacing = 0;

      if (!league.myEntries) {
        return 0;
      }

      for (const entry of this.getMyEntriesList(league)) {
        if (entry.prizeValue && entry.prizeValue > 0 || entry.ticketPrize || entry.splitTicketPrize || this.isPlacing(league, entry.rank)) {
          numPlacing++;
        }
      }

      return numPlacing;
    },

    isPlacing(league, rank) {
      return rank <= league?.numPayouts;
    },

    changeFilterType(type) {
      this.filterType = type;
    },

    getMatchupAtWeek(league, weekNum) {
      if (league.bracket && league.bracket[weekNum - 1]) {
        const weekJSON = league.bracket[weekNum - 1];
        const matchups = weekJSON.matchups;
        let foundMatch = true;

        for (const match of matchups) {
          foundMatch = true;
          if (match.team1Id === this.userId || match.team2Id === this.userId) {
            return {state: 'found', matchup: match};
          }
        }

        if (foundMatch) {
          return {state: 'loss'};
        }
      }

      return {state: 'notfound'};
    },

    getWinnerPayout(league) {
      let tPayout = 0;

      if (league.ctype === 'Tournament' && league.leaderboard && league.leaderboard[0] && league.leaderboard[0].prize) {
        return league.leaderboard[0].prize.removeCentsIfZero();
      } else if (league.ctype === 'Tournament') {
        return '$0';
      }

      for (const pIndex in league.payouts) {
        tPayout += league.payouts[pIndex].value;
      }

      if (league.realMoney) {
        return '$' + this.formatMoney(tPayout / 100);
      }

      return this.formatMoney(tPayout / 100);
    },

    getWinnerId(league) {
      let matchup = null;
      if (!league) {
        return;
      }

      if (league.ctype === 'Tournament' && league.leaderboard && league.leaderboard[0]) {
        return league.leaderboard[0].userId;
      } else if (league.ctype === 'Tournament') {
        return;
      }

      if (!league || !league.bracket) {
        return;
      }

      if (league.bracket[league.bracket.length - 1] && league.bracket[league.bracket.length - 1].matchups &&
        league.bracket[league.bracket.length - 1].matchups[0] && league.bracket[league.bracket.length - 1].matchups[0].winner != ''
      ) {
        matchup = league.bracket[league.bracket.length - 1].matchups[0];
      }

      if (!matchup) {
        return null;
      }

      if (matchup.winner == 'team1') {
        return matchup.team1Id;
      } else if (matchup.winner == 'team2') {
        return matchup.team2Id;
      }
    },

    openBracketModal(event, league) {
      if (league.ctype !== 'Tournament') {
        event.preventDefault();
        EventBus.$emit('SHOW_BRACKET_MODAL', league);
      }
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    getSalaryContestHeight(contest) {
      const numEntries = this.getMyEntriesList(contest).length;
      let minHeight = 104;
      if (numEntries > 5) {
        minHeight = 154;
      } else if (numEntries > 4) {
        minHeight = 26 * numEntries;
      }
      return 'min-height: ' + minHeight + 'px;';
    },

    getContestType(contest) {
      switch (contest.ctype) {
        case 'Tournament': return 'Tournament';
        case 'HEAD_TO_HEAD': return 'H2H';
        case 'LIGHTNING_LINEUPS': return 'Lightning Lineups';
        default: return '';
      }
    },

    getContestBadge(sport) {
      return require('@/assets/contestbadge/' + sport + '.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.completedContestTable {
  width: 100%;
  tr {
    height: 92px;
    min-height: 92px;
  }
}

.completedContestTable tr:not(:last-child) {
  border-bottom: 4px solid var(--obcolor-background-page);
}

.completedContestTable td:not(:last-child) {
  border-right: 1px solid var(--obcolor-background-page);
}

.completedContestTable td:not(:first-child) {
  text-align: center;
}

.completedContestTable td {
  height: 60px;
  background: var(--obcolor-background-6);
}

.completedContestTable td, .completedContestTable th {
  box-sizing: border-box;
}

.liveContestContainer {
  height: 120px;
  display: flex;
  background: var(--obcolor-background-6);
  overflow: hidden;
  font-size: 14px;
}

.hoverableRow {
  cursor: pointer;
}
.hoverableRow:hover td {
  background: var(--obcolor-background-4);
}

.hoverableField {
  cursor: pointer;
}
.hoverableField:hover {
  background: var(--obcolor-background-4);
}

.titleField {
  min-width: 225px;
  max-width: 225px;
  width: 225px;
}

.placeField {
  min-width: 145px;
  max-width: 145px;
  width: 145px;
  font-size: 16px;
}

.summaryText {
  margin-top: 3px;
  font-size: 11px;
  color: var(--obcolor-font-secondary);
}

.resultBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resultText {
  font-size: 14px;
  font-weight: bold;
}

.resultLabel {
  margin-top: 2px;
  margin-bottom: -2px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  font-weight: bold;
}

.salaryResultLabel {
  margin-top: 2px;
  margin-bottom: -2px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}

.prizeBox {
  background: var(--obcolor-ob-blue);
  padding: 2px 5px;
  color: white;
  border-radius: 5px;
  width: max-content;
}

.ticketWinnings {
  padding: 1px;
  margin-top: 3px;
  font-size: 12px;
}

.contestName {
  font-size: 14px;
  overflow-wrap: break-word;
  max-width: 167px;
}

.rowTable {
  display: table-row;
  height: 92px;
  min-height: 92px;
  vertical-align: middle;
}

.completedContestTable .rowTable:not(:last-child) {
  border-bottom: 4px solid var(--obcolor-background-page);
}

.contestBadge {
  width: 55px;
  margin-right: 12px;
  margin-left: 5px;
}

.salaryResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  gap: 15px;
}

.salaryResultsFlexbox {
  display: flex;
  justify-content: space-around;
}

.salaryEntriesCell {
  width: 250px;
  height: 100%;
  padding: 0;
  vertical-align: top;
}

.salaryEntriesContainer {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: default;
}

.blueText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green);
  font-weight: bold;
}

.noContestsCell {
  height: 30px;
  padding-left: 15px;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}

</style>