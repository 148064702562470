<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 520px; padding: 0; border-radius: 0 !important; margin-top: 90px;"
    bodyStyle="margin: 0; background: var(--obcolor-box-header);"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="header" class="modalHeader">
      <img class="confetti" :src="require('@/assets/contestWonModal/confetti.png')" alt="Confetti"
        :style="showConfetti ? 'width: 450px;' : 'width: 50px;'"
      >
      <img class="wonBanner" :src="require('@/assets/contestWonModal/banner.png')" alt="Winner Banner">
      <div class="payout realMoney" v-if="currentTab == 'realMoney'">${{ formatMoney(totalMoneyWinnings) }}</div>
      <div class="payout ownersBucks" v-else-if="currentTab == 'ownersBucks'">
        <img :src="require('@/assets/icons/ownersbucks-inv.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
        {{ formatMoney(totalOwnersBucksWinnings) }}
      </div>
    </div>
    <div slot="body" class="modalBody">
      <ContestWonTable v-if="todaysWonContests && currentTab == 'realMoney'" :contests="todaysMoneyContests" @close="closeModal()" />
      <ContestWonTable v-else-if="todaysWonContests && currentTab == 'ownersBucks'" :contests="todaysOwnersBucksContests" @close="closeModal()" />
    </div>
    <div slot="footer" class="modalFooter">
      <button class="ob-btn-grey" @click="closeModal()">Close</button>
      <button v-if="showNextButton" class="ob-btn" @click="nextPage()">Next</button>
      <button v-if="showBackButton" class="ob-btn" @click="prevPage()">Back</button>
    </div>
  </Modal>
</template>

<script>
import {mapState} from 'vuex';
import Modal from '@/components/Modal';
import ContestWonTable from '@/components/modals/ContestWonModal/ContestWonTable';
import ObAccountApi from '@/api/ObAccountApi';

export default {
  components: {
    Modal,
    ContestWonTable,
  },
  props: {
    completedContests: Array,
  },

  data() {
    return {
      isOpen: false,
      showConfetti: false,
      lastCheckedDate: null,
      currentTab: 'ownersBucks',
    };
  },


  computed: {
    ...mapState(['userId', 'contestCheckIsLoaded', 'contestCheckTs']),

    showNextButton() {
      return this.currentTab == 'realMoney' && this.todaysOwnersBucksContests && this.todaysOwnersBucksContests.length > 0;
    },

    showBackButton() {
      return this.currentTab == 'ownersBucks' && this.todaysMoneyContests && this.todaysMoneyContests.length > 0;
    },

    todaysWonContests() {
      return this.completedContests.filter((contest) => {
        let finalized = null;
        if (contest.ctype == 'LIGHTNING_LINEUPS') {
          finalized = contest.contestFinalizedDate;
        } else {
          finalized = contest.finalized;
        }
        if (!finalized) {
          return false;
        }
        // Only considered a winning contest if you have a payout, or a ticket prize
        if (this.getMyPayouts(contest) == 0 && !this.hasTicketPayouts(contest)) {
          return false;
        }
        if (this.lastCheckedDate == null) {
          return true;
        }
        return this.$moment(finalized).diff(this.lastCheckedDate) > 0;
      });
    },

    todaysMoneyContests() {
      return this.todaysWonContests.filter((contest) => contest.realMoney);
    },

    todaysOwnersBucksContests() {
      return this.todaysWonContests.filter((contest) => !contest.realMoney);
    },

    todaysShownContests() {
      if (this.currentTab == 'realMoney') {
        return this.todaysMoneyContests;
      } else if (this.currentTab == 'ownersBucks') {
        return this.todaysOwnersBucksContests;
      }
      return null;
    },

    totalMoneyWinnings() {
      if (this.todaysMoneyContests == null || this.todaysMoneyContests == []) {
        return 0;
      }

      // Adds all payouts together
      return this.todaysMoneyContests.reduce((tot, contest) => {
        return tot + this.getMyPayouts(contest);
      }, 0);
    },

    totalOwnersBucksWinnings() {
      if (this.todaysOwnersBucksContests == null || this.todaysOwnersBucksContests == []) {
        return 0;
      }

      // Adds all payouts together
      return this.todaysOwnersBucksContests.reduce((tot, contest) => {
        return tot + this.getMyPayouts(contest);
      }, 0);
    },
  },

  // Watcher is required because the created event can fire before the contests are loaded
  watch: {
    completedContests(to, from) {
      if (to && to.length > 0) {
        this.checkForWinnings();
      }
    },

    contestCheckIsLoaded(to, from) {
      this.checkForWinnings();
    },
  },

  created() {
    this.checkForWinnings();
  },

  methods: {
    openModal() {
      this.initVariables();
      this.isOpen = true;
      const self = this;
      setTimeout(function() {
        self.showConfetti = true;
      }, 700);
    },

    closeModal() {
      this.isOpen = false;
      this.showConfetti = false;
    },

    setLastCheckedDate() {
      const newTs = this.$moment().valueOf();
      this.$store.commit('setContestCheckState', {loaded: true, ts: newTs});
      ObAccountApi.updateContestCheckTimestamp(newTs);
    },

    checkForWinnings() {
      if (!this.contestCheckIsLoaded) {
        return;
      }

      // If lastCheckedDate is not set, its means the user has never opened the dashboard
      // In this case, all of the completed contests can be treated as winnings in the modal
      if (this.contestCheckTs) {
        this.lastCheckedDate = this.$moment(parseInt(this.contestCheckTs));
      }

      if (this.todaysWonContests && this.todaysWonContests.length > 0) {
        // If any contests have been finalized since the last check, reset the date to now and open the modal.
        this.setLastCheckedDate();
        this.openModal();
      }
    },

    // Sets the tab depending on which contests won
    initVariables() {
      if (this.todaysMoneyContests && this.todaysMoneyContests.length > 0) {
        this.currentTab = 'realMoney';
      } else if (this.todaysOwnersBucksContests && this.todaysOwnersBucksContests.length) {
        this.currentTab = 'ownersBucks';
      }
    },

    getMyPayouts(contest) {
      return this.getPlayerPayouts(contest, this.userId);
    },

    hasTicketPayouts(contest) {
      // Only salary cap contests have ticket prizes
      if (contest.ctype !== 'Tournament') {
        return false;
      }
      for (const entry of this.getMyEntriesList(contest)) {
        if (entry.ticketPrize) {
          return true;
        }
      }
      return false;
    },

    getMyEntriesList(contest) {
      if (!contest.leaderboard) {
        return [];
      }
      return contest.leaderboard.filter((entry) => entry.userId === this.userId);
    },

    getTournamentPayouts(contest) {
      let totPayouts = 0;
      const myEntries = this.getMyEntriesList(contest);
      for (const entry of myEntries) {
        totPayouts += entry.prizeValue;
      }
      return totPayouts;
    },

    getH2hPayouts(contest, playerId) {
      let totPayouts = 0;
      // Traverse the bracket to find which payouts are the player's
      for (const weekIndex in contest.bracket) {
        for (const matchup of contest.bracket[weekIndex].matchups) {
          if (matchup.winner == 'team1' && matchup.team1Id == playerId ||
            matchup.winner == 'team2' && matchup.team2Id == playerId
          ) {
            totPayouts += contest.payouts[weekIndex].value;
          }
        }
      }

      return totPayouts;
    },

    // Gets total payout for a given player
    getPlayerPayouts(contest, playerId) {
      let totPayouts = 0;
      if (contest.ctype === 'LIGHTNING_LINEUPS') {
        totPayouts = contest.userWinnings;
      } else if (contest.ctype === 'Tournament') {
        totPayouts = this.getTournamentPayouts(contest);
      } else {
        totPayouts = this.getH2hPayouts(contest, playerId);
      }

      return totPayouts / 100;
    },

    formatMoney(value) {
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value.toFixed(2)).removeCentsIfZero();
    },

    nextPage() {
      this.currentTab = 'ownersBucks';
    },

    prevPage() {
      this.currentTab = 'realMoney';
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 18px;
}

.wonBanner {
  position: absolute;
  width: 430px;
  // negative margin affects chrome (and half-affects firefox/edge), positive margin half-affects firefox and edge
  top: -80px;
}

.confetti {
  position: absolute;
  width: 50px;
  bottom: 80px;
  transition-duration: 300ms;
}

.payout {
  padding: 7px 20px 7px 20px;
  font-weight: bold;
  font-size: 24px;
  margin-top: 30px;
  min-width: 100px;
  width: max-content;
  max-width: 190px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payout.realMoney {
  background: var(--obcolor-green);
  color: white;
}

.payout.ownersBucks {
  background: var(--obcolor-ob-blue-tinted);
  color: white;
  .ownersbucksIcon {
    height: 18px;
    margin-left: -5px;
    margin-right: 5px;
    margin-top: -2px;
  }

}

.modalBody {
  flex: 1;
  padding: 0;
  box-sizing: border-box;
}
.modalFooter {
  font-size: 13px;
  background: var(--obcolor-box-header);
  padding: 10px;
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px;
  }
}

.ob-btn, .ob-btn-grey {
  border-radius: 0 !important;
  width: 90px;
}
</style>