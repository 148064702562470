<template>
  <div class="propsEntryWrapper" data-testid="player-slip-tile">
    <div class="propsEntry" :class="{disabled: changeMessage && !hideWarnings}">
      <div class="flexColumn centered paddedBox clickable" @click.stop="openPlayerCard(market.playerId.toString())">
        <PlayerHeadshot :id="market.playerId.toString()" size="50" :sport="market.sport"
          :team="market.player.teamAlias"
          :showLogo="true"
        />
        <div class="playerPosition">{{ market.player.position }}</div>
      </div>
      <div class="flexColumn slipContent">
        <div class="flexRow spacedContent paddedBox">
          <div class="flexColumn grow">
            <div class="nameContainer" @click.stop="openPlayerCard(market.playerId.toString())">
              <PlayerStatus height="12" :status="getPlayerStatus()" />
              <div class="playerName clickable">
                {{ market.player.firstName + ' ' + market.player.lastName }}
              </div>
              <PlayerStartingStatus class="startingStatus" :sport="market.sport" :player="market.player" />
            </div>
            <div class="subHeader">
              {{ playerGameText }}
              <span v-if="isGameStarted">Started</span>
              <LobbyContestCountdown v-else :end="market.game.date" :tournament="!isOneHourAway" />
            </div>
          </div>
          <DiscountTag v-if="market.isDiscounted" class="discountTag" :market="market"
            rotate
          />
          <div v-if="!submitted" @click.stop="removeEntry(market)" class="removeButton fal fa-times"></div>
        </div>
        <div class="seperator"></div>
        <div class="flexRow spacedContent paddedBox">
          <div class="boldText balancedLine">
            <span>
              <MarketLine :market="market" />
              {{ market.marketType.name }}
            </span>
          </div>
          <div class="moreLessContainer" :class="{clickable: !submitted}">
            <button v-if="lessAvailable" class="moreLess" @click="!submitted && selectMarket(market, 'UNDER')"
              :class="{ selected: market.action == 'UNDER', disabled: submitted == true }"
            >
              Less
            </button>
            <button v-if="moreAvailable" class="moreLess" @click="!submitted && selectMarket(market, 'OVER')"
              :class="{ selected: market.action == 'OVER', disabled: submitted == true }"
            >
              More
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="changeMessage && !hideWarnings" class="change">
      <div class="changeWarning">
        <i class="far fa-exclamation-circle"></i>
        {{ changeMessage }}
      </div>
      <div class="changeButtons">
        <button class="changeButton" @click="removeEntry(market)">
          Remove Player
        </button>
        <button v-if="updatedMarket && isActive" class="changeButton" @click="acceptChanges()">
          Accept
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerStartingStatus from '@/components/PlayerPicks/PlayerStartingStatus.vue';
import EventBus from '@/event-bus';
import LobbyContestCountdown from '@/views/Lobby/LobbyContestCountdown.vue';
import DiscountTag from './DiscountTag.vue';
import MarketLine from '@/components/PlayerPicks/MarketLine.vue';
import {marketChangeEvent} from '@/mixins/MarketsMixin';
import TimeMixin from '@/mixins/TimeMixin';
import {isMarketActive} from '@/utils/PlayerPicksUtils';

/**
 * @typedef {import('@/api/ObPropsApi').Market} Market
 */

export default {
  mixins: [TimeMixin],

  props: {
    market: {type: Object, default: null},
    submitted: {type: Boolean, default: false},
    hideWarnings: {type: Boolean, default: false},
  },

  data() {
    return {
      /** @type {Market|null} */
      updatedMarket: null,
    };
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
    PlayerStartingStatus,
    LobbyContestCountdown,
    DiscountTag,
    MarketLine,
  },

  created() {
    EventBus.$on(marketChangeEvent(this.market), this.onMarketChange);
  },

  destroyed() {
    EventBus.$off(marketChangeEvent(this.market), this.onMarketChange);
  },

  computed: {
    playerGameText() {
      return this.$TeamInfo.getDisplayAlias(this.market.sport, this.market.player.teamAlias) + ' ' + this.$SportInfo.playerGameText(this.market, this.gameFinished, this.market.sport, false);
    },

    playerInfoText() {
      return this.market.player.position + ' - ' + this.$TeamInfo.getDisplayAlias(this.market.sport, this.market.player.teamAlias);
    },

    discountExpired() {
      const discountExpiry = this.updatedMarket?.discount?.expiry ?? this.market.discount?.expiry ?? this.now+1;
      return discountExpiry < this.now;
    },

    discountDeleted() {
      return this.market.isDiscounted && !this.updatedMarket?.isDiscounted;
    },

    marketSuspended() {
      return !this.market.isDiscounted && this.market.state !== this.updatedMarket?.state;
    },

    updatedLine() {
      return this.updatedMarket?.discount?.discountLine ?? this.updatedMarket?.line.balancedLine;
    },
    marketLineChanged() {
      const line = this.market.discount?.discountLine ?? this.market.line.balancedLine;
      return line !== this.updatedLine;
    },

    pickOptionsChanged() {
      return this.market.pickOptions !== this.updatedMarket.pickOptions;
    },

    changeMessage() {
      if (this.discountExpired) {
        return 'Discount has expired';
      }

      if (this.updatedMarket === null) {
        return null;
      }

      if (this.discountDeleted) {
        return 'Discount is no longer available';
      }

      if (this.marketSuspended) {
        return 'Market is no longer available';
      }

      if (this.marketLineChanged) {
        if (this.market.isDiscounted) {
          return `Discount updated to ${this.updatedLine}`;
        } else {
          return `Line updated to ${this.updatedLine}`;
        }
      }

      if (this.pickOptionsChanged) {
        switch (this.updatedMarket.pickOptions) {
          case 'MORE_ONLY':
            return 'Less selection no longer available';
          case 'MORE_OR_LESS':
            return 'Less selection is now available';
          default:
            return 'Available pick selections have updated';
        }
      }

      return null;
    },

    moreAvailable() {
      return this.market.pickOptions != 'LESS_ONLY';
    },
    lessAvailable() {
      return this.market.pickOptions != 'MORE_ONLY';
    },

    isActive() {
      return isMarketActive(this.updatedMarket ?? this.market, this.now);
    },

    isOneHourAway() {
      // check if game is within 1 hour in seconds
      return this.$moment(this.market.game.date).diff(this.$moment(this.now), 'seconds') <= 3600;
    },

    isGameStarted() {
      return this.$moment(this.market.game.date).diff(this.$moment(this.now), 'seconds') <= 0;
    },
  },

  methods: {
    removeEntry(market) {
      EventBus.$emit('PROPS_REMOVE_ENTRY', market);
    },

    selectMarket(market, action) {
      EventBus.$emit('PROPS_SELECT_MARKET', market, action);
    },

    getPlayerStatus() {
      return this.market.player.status;
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.market.sport);
    },

    /**
     * @param {Market} to
     * @param {Market} from
     */
    onMarketChange(to, from) {
      this.updatedMarket = to;
      if (to.isDiscounted) {
        EventBus.$emit('PROPS_UPDATE_SELECTED_MARKET', {
          ...this.market,
          discount: {
            ...this.market.discount,
            discountPercentage: to.discount.discountPercentage,
          },
          line: to.line,
        });
      }
    },

    acceptChanges() {
      if (this.updatedMarket.pickOptions === 'MORE_ONLY' && this.market.action !== 'OVER') {
        EventBus.$emit('PROPS_SELECT_MARKET', this.updatedMarket, 'OVER');
      } else if (this.updatedMarket.pickOptions === 'LESS_ONLY' && this.market.action !== 'UNDER') {
        EventBus.$emit('PROPS_SELECT_MARKET', this.updatedMarket, 'UNDER');
      }
      EventBus.$emit('PROPS_UPDATE_SELECTED_MARKET', this.updatedMarket);
    },
  },
};
</script>

<style lang="scss" scoped>
.propsEntryWrapper{
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-6);
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  width: 100%;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
}

.playerName {
  font-weight: 700;
  margin-left: 4px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.playerName:hover {
  text-decoration: underline;
}

.startingStatus {
  margin-left: 4px;
}

.subHeader {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--obcolor-font-secondary);
}

.moreLessContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}

.moreLess {
  all: unset;
  border: solid 1px var(--obcolor-background-2);
  background-color: var(--obcolor-background-2);
  color: var(--obcolor-font-secondary);
  display: flex;
  flex: 1;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  width: 36px;
  padding: 5px 10px;
  font-size: 14px;


  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-width: 0px !important;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0px !important;
  }

  &.selected {
    background-color: var(--obcolor-ob-blue);
    border: solid 1px var(--obcolor-accent-light);
    color: var(--obcolor-background-2)
  }

  &:not(.disabled):hover {
    background-color: var(--obcolor-ob-blue);
    color: var(--obcolor-background-6);
    border: solid 1px var(--obcolor-accent-light);
  }

  &.disabled {
    pointer-events: none;
  }
}

.propsEntry {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-self: center;
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.removeButton {
  color: var(--obcolor-font-secondary);
  margin: 0 8px 0 16px;
  font-size: 20px;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.boldText {
  font-size: 14px;
  font-weight: 700;
}

.seperator {
  border-bottom: 1px solid var(--obcolor-timeline);
  margin: 0 8px;
}

.balancedLine {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.discountTag {
  right: 40px;
}

.clickable {
  cursor: pointer;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spacedContent {
  display: flex;
  justify-content: space-between;
}

.slipContent {
  position: relative;
  width: 100%;
}

.paddedBox {
  padding: 8px;
}

.grow {
  flex-grow: 1;
}

.playerPosition{
  color: var(--obcolor-font-secondary);
  margin-top: 4px;
}

.change{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.changeWarning {
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  background-color: var(--obcolor-warning-background);
  color: var(--obcolor-warning);
  border-radius: 4px;
  font-weight: 400;
}

.changeButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1px;

  .changeButton {
    all: unset;
    text-align: center;
    padding: 12px 8px;
    background-color: var(--obcolor-background-1);
    font-weight: 500;
    flex: 1;
  }
}
</style>