<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 690px; max-height: 690px; padding: 0; overflow: hidden;"
    bodyStyle="background: var(--obcolor-background-3); padding: 0;"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="header" class="swapHeader">
      <div style="padding: 5px 0;">Swap Confirmation</div>
      <div style="display: flex; justify-content: space-between; margin: 10px 0;">
        <!-- Salary -->
        <div style="margin-right: 15px;">
          <div class="salaryLabel">Remaining Salary</div>
          <div :class="salaryCalc.remaining >= 0 ? 'moneyText' : 'redText'" class="salaryValue">{{ salaryCalc.remaining.formatMoney() }}</div>
          <div style="font-size: 12px; margin-top: 7px; display: flex; align-items: center;">
            <div :class="salaryCalc.cost >= 0 ? 'moneyText' : 'redText'">{{ formattedCost() }}</div>
            <Tooltip pos="bottom" textKey="SalaryCap_Editor_SalaryRemaining" style="margin-top: -2px;" />
          </div>
        </div>

        <!-- Projection -->
        <div style="margin-right: 15px;">
          <div class="salaryLabel">Projected</div>
          <div class="salaryValue">{{ salaryCalc.proj }}</div>
          <div style="font-size: 12px; margin-top: 7px;">{{ formatChange(salaryCalc.projChange) }}</div>
        </div>

        <!-- Game Limits -->
        <div v-for="(entry, index) in gameLimits" :key="index"
          style="flex: 1; text-align: center; margin-left: 2px; margin-right: 2px; max-width: 115px; height: 64px;"
          :style="'border: 1px solid ' + entry.color"
        >
          <div :style="'background: ' + entry.color" style="padding: 2px; font-weight: bold; font-size: 14px;">{{ entry.pos }}</div>
          <div style="padding: 4px;">
            <div style="margin-bottom: 3px; font-size: 16px; font-weight: normal;">
              <span :style="'color: ' + entry.text">{{ entry.proj }}</span> /
              <span>{{ entry.max }}</span>
            </div>
            <div style="font-weight: normal; font-size: 12px;">{{ entry.change }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- List of Swaps -->
    <div slot="body" style="box-sizing: border-box;">
      <div class="swapsList" style="border-top: 1px solid var(--obcolor-background-page);">
        <div v-for="(swap, index) in pendingSwaps" :key="index" style="font-size: 14px;">
          <div class="swapRow">
            <div>
              <span style="font-weight: bold;">Swap #{{ index + 1 }}</span>
              <span v-if="!isDailyContest()"> - Dropped players can't be reacquired</span>
            </div>
            <div style="display: flex; align-items: center;">
              <div v-if="contest.format == 'WEEKLY'" style="text-align: right; margin-right: 10px;">
                <div style="font-weight: bold" class="redText">{{ dollarFormat(getSwapFee(swap)) }}</div>
                <div style="font-size: 12px;">Swap Fee</div>
              </div>
              <div style="text-align: right; min-width: 110px;">
                <div style="font-weight: bold" :class="swap.calcCost < 0 ? 'redText' : 'moneyText'">
                  {{ dollarFormat(swap.calcCost) }}
                </div>
                <div style="font-size: 12px;">Salary Change</div>
              </div>
            </div>
          </div>
          <PreviewModalSwapPlayer type="drop" :swap="swap" :player="swap.drop"
            :contest="contest" :statusMap="statusMap" :status="getStatus(swap.drop)"
          />
          <PreviewModalSwapPlayer type="add" :swap="swap" :player="swap.add"
            :contest="contest" :statusMap="statusMap" :status="getStatus(swap.add)"
          />
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div slot="footer" style="padding: 15px 10px; height: 22px; font-size: 12px; display: flex; align-items: center;">
      <template v-if="submitProcessing">
        <ObLoading :size="40" style="margin-left: -5px; margin-right: 2px;" />
        <div style="font-size: 14px;">Processing Swaps...</div>
      </template>
      <template v-else-if="submitSuccess">
        <div class="successCircle">
          <i class="fas fa-check" style="margin-top: 2px;"></i>
        </div>
        <div style="flex: 1; font-size: 14px;">Success! Your swaps have been processed.</div>
        <div v-if="contest.format == 'WEEKLY'" @click="backToSchedule()" class="ob-btn-grey"
          style="width: 100px; margin-right: 10px;"
        >
          Back to Schedule
        </div>
        <div v-else @click="backToLeaderboard()" class="ob-btn-grey"
          style="width: 120px; margin-right: 10px;"
        >
          Back to Leaderboard
        </div>
        <div @click="closeModal()" class="ob-btn" style="width: 80px;">Continue</div>
      </template>
      <template v-else>
        <div v-if="submitError" class="errorCircle">
          <i v-if="submitError" class="fas fa-exclamation"></i>
        </div>
        <div v-if="submitError" style="flex: 1; font-size: 14px; margin-right: 10px;">{{ submitError }}</div>
        <div v-else style="flex: 1;"></div>
        <div @click="closeModal()" class="ob-btn-grey">Cancel</div>
        <!-- On failed swap, user must cancel and adjust before re-submitting -->
        <div v-if="!submitError" @click="submitSwaps()" class="ob-btn"
          style="margin-left: 10px;"
        >
          Submit Swaps
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';
import PreviewModalSwapPlayer from './PreviewModalSwapPlayer';

export default {
  components: {
    Modal,
    Tooltip,
    PreviewModalSwapPlayer,
    ObLoading,
  },

  data() {
    return {
      contest: null,
      roster: null,
      pendingSwaps: null,
      pendingFees: null,
      pendingCost: null,
      projChange: null,
      isOpen: false,
      statusMap: {},
      salaryCalc: {},
      gameLimits: [],

      submitError: '',
      submitSuccess: false,
      submitProcessing: false,
    };
  },

  created() {
    EventBus.$on('LINEUP_EDITOR_OPEN_PREVIEW', this.openModal);
  },

  destroyed() {
    EventBus.$off('LINEUP_EDITOR_OPEN_PREVIEW', this.openModal);
  },

  methods: {
    resetData() {
      this.contest = null;
      this.roster = null;
      this.pendingSwaps = null;
      this.statusMap = {};

      this.pendingFees = null;
      this.pendingCost = null;
      this.projChange = null;
      this.salaryCalc = {};
      this.gameLimits = [];

      this.submitProcessing = false;
      this.submitError = null;
      this.submitSuccess = false;
    },

    openModal(contest, roster, rosterCalc, pendingSwaps, salaryCalc, gameLimits, statusMap) {
      this.contest = contest;
      this.roster = roster;
      this.rosterCalc = rosterCalc;
      this.statusMap = statusMap;
      this.pendingSwaps = pendingSwaps;

      this.salaryCalc = salaryCalc;
      this.gameLimits = gameLimits;
      this.isOpen = true;
    },

    backToSchedule() {
      const contestId = this.contest.id;
      const teamId = this.roster.id + '_' + this.roster.teamNum;
      this.$router.push('/salarycontest/lineup/' + contestId + '/' + teamId);
    },

    backToLeaderboard() {
      const contestId = this.contest.id;
      const teamId = this.roster.id + '_' + this.roster.teamNum;
      this.$router.push('/salarycontest/leaderboard/' + contestId + '/' + teamId);
    },

    closeModal() {
      this.isOpen = false;
      this.resetData();
    },

    getStatus(player) {
      if (player && this.statusMap && this.statusMap[player.id]) {
        return this.statusMap[player.id].status;
      }
      return null;
    },

    formattedCost() {
      const cost = this.salaryCalc.cost;
      const costFormatted = cost.formatMoney();
      if (cost >= 0) {
        return '+ ' + costFormatted;
      }
      return '- ' + costFormatted.replace('-', '');
    },

    getSwapFee(swap) {
      if (!swap.drop) {
        return 0;
      }
      return swap.drop.calcFee;
    },

    formatChange(n) {
      if (n == null) {
        return '+ 0';
      }
      if (n >= 0) {
        return '+ ' + n;
      }
      return String(n).replace('-', '- ');
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const negativeSign = dollarAmount < 0 ? '-' : '';
      return negativeSign + '$' + Intl.NumberFormat('en-US').format(Math.abs(dollarAmount));
    },

    isDailyContest() {
      if (this.contest) {
        return this.contest.format === 'DAILY';
      }
      return false;
    },

    submitSwaps() {
      const swaps = [];
      for (const entry of this.pendingSwaps) {
        if (!entry.add || !entry.drop) {
          // Cannot submit if any swaps do not have a player assigned
          return;
        }
        swaps.push({add: entry.add.id, drop: entry.drop.id, pos: entry.pos});
      }

      const teamId = this.roster.id + '_' + this.roster.teamNum;
      this.submitError = '';
      this.submitSuccess = false;
      this.submitProcessing = true;

      // Save a deep copy of the pending transactions
      // If swap is successful show this data, since the reference to pending swaps will clear
      const swapsCopy = JSON.parse(JSON.stringify(this.pendingSwaps));

      ObSalaryCapApi.swapPlayers(this.contest.id, teamId, swaps)
          .then((data) => {
            this.pendingSwaps = swapsCopy;
            this.submitSuccess = true;
            this.submitProcessing = false;
            EventBus.$emit('LINEUP_EDITOR_CLEAR_SWAPS');
            EventBus.$emit('SALARY_CAP_RELOAD');
          })
          .catch((error) =>{
            this.submitError = error.response.data;
            this.submitProcessing = false;
            EventBus.$emit('LINEUP_EDITOR_FAILED_SWAP');
            EventBus.$emit('SALARY_CAP_RELOAD');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .swapHeader {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
  }

  .redText {
    color: var(--obcolor-red);
  }

  .moneyText {
    color: var(--obcolor-green);
  }

  .ob-btn {
    border-radius: 0;
    min-width: 60px;
  }
  .ob-btn-grey {
    border-radius: 0;
    min-width: 60px;
  }

  .salaryLabel {
    margin-top: 4px;
    margin-bottom: 5px;
    color: var(--obcolor-font-secondary);
    text-transform: uppercase;
    font-size: 12px;
  }

  .swapRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 15px;
    background: var(--obcolor-background-2);
    border-bottom: 1px solid var(--obcolor-background-page);
  }

  .errorCircle {
    color: white;
    background: red;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .successCircle {
    color: white;
    background: var(--obcolor-green);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
</style>