<template>
  <div v-if="entry" class="entryContainer flexColumn" :class="{ownersBucks: !entry.isRealMoney, promotion: hasPromotions}">
    <div class="heading">
      <div class="contestInfoWrapper">
        <img class="obLogo" :src="require('@/assets/obicon_' + (theme == 'light' ? 'black' : 'white') + '.png')" alt="OwnersBox Logo">
        <div class="contestInfo">
          <div :class="{ changeFlash: payoutChange, }">
            {{ numPicks }} Picks to Pay
            <template v-if="entry.contestType === 'PROTECTED'">
              up to
            </template>
            <span v-if="payoutAdjusted" class="moneyText">
              <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.reducedPayout" />
            </span>
            <span class="moneyText" :class="{adjustedPayout: payoutAdjusted}">
              <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.originalPayout" />
            </span>
          </div>
          <div class="subHeading">
            <div>
              <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.entryFee" /> {{ contestType }}
            </div>
            <div>{{ getContestStart() }}</div>
          </div>
        </div>
      </div>
      <ContentCards locationId="player-picks-live-contest" :filter="{ contestId: entry.id }" v-model="hasPromotions" />
      <div v-if="entry.state !== 'CANCELLED'" class="flexColumn headerSection">
        <ContextMenu>
          <template #default>
            <i class="far fa-ellipsis-h"></i>
          </template>
          <template #menu>
            <button @click="openPayoutStructure">Payout Structure</button>
          </template>
        </ContextMenu>
        <div class="infoSection"
          :class="{
            win: isWinning,
            changeFlash: statusChange,
          }"
        >
          <template v-if="entry.status === 'UPCOMING'">
            Upcoming
          </template>
          <template v-if="entry.status === 'PENDING'">
            In Progress
          </template>
          <template v-else-if="entry.status === 'WINNING'">
            <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.payout" />
            Winning
          </template>
          <template v-else-if="entry.status === 'WON'">
            <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.payout" />
            Won
          </template>
          <template v-else-if="entry.status === 'LOST'">
            <OBCurrency :realMoney="entry.isRealMoney" :cents="entry.entryFee * -1" />
            Lost
          </template>
        </div>
      </div>
      <div v-else class="subHeading errorText">
        <div class="errorTextTitle">
          <i class="fas fa-exclamation-circle error fa-lg"></i> Entry Cancelled.
        </div>
        <div class="errorTextContent">
          {{ entry.cancellationNote }}
        </div>
      </div>
    </div>
    <div class="propsContest">
      <LivePropsPick v-for="(pick, pickIndex) in entryPicks" :key="pickIndex" :pick="pick"
        :entry="entry"
      />
    </div>
  </div>
</template>

<script>
import LivePropsPick from './LivePropsPick.vue';
import {mapState} from 'vuex';
import moment from 'moment';
import OBCurrency from '@/components/OBCurrency.vue';
import ContextMenu from '@/components/ContextMenu/ContextMenu.vue';
import EventBus from '@/event-bus';
import ContentCards from '@/components/ContentCards.vue';

/**
 * @typedef {import('@/api/ObPropsApi').PropContest} PropContest
 */

export default {
  props: {
    entry: {type: Object, default: null},
    isCompleted: {type: Boolean, default: false},
    contestState: {type: String, default: null},
  },

  components: {
    LivePropsPick,
    OBCurrency,
    ContextMenu,
    ContentCards,
  },

  data() {
    return {
      statusChange: false,
      payoutChange: false,
      hasPromotions: false,
    };
  },

  created() {
    EventBus.$on('PROPS_CONTEST_CHANGE_'+this.entry.id, this.onPropsContestChange);
  },

  destroyed() {
    EventBus.$off('PROPS_CONTEST_CHANGE_'+this.entry.id, this.onPropsContestChange);
  },

  computed: {
    ...mapState(['theme']),

    numPicks() {
      return this.entry?.picks?.length;
    },

    numCorrect() {
      const picks = this.entry.picks;
      let count = 0;
      for (const pick of picks) {
        if (this.getResult(pick)) {
          count++;
        }
      }
      return count;
    },

    numIncorrect() {
      const picks = this.entry.picks;
      let count = 0;
      for (const pick of picks) {
        if (this.getResult(pick) === false) {
          count++;
        }
      }
      return count;
    },

    entryPicks() {
      return [...this.entry.picks, ...this.entry.removedPicks];
    },

    validPicks() {
      return this.entry.picks.filter((pick) => pick.isRemoved === false);
    },

    payoutAdjusted() {
      return this.entry.removedPicks.length > 0 && this.entry.state !== 'CANCELLED';
    },

    /**
     * @return {string}
     */
    contestType() {
      return this.entry.contestType == 'STANDARD' ? 'Perfect Picks' : 'Protected Picks';
    },

    isWinning() {
      return this.entry.status === 'WINNING' || this.entry.status === 'WON';
    },
  },

  methods: {
    getContestStart() {
      if (!this.entry) {
        return '';
      }
      const dateCheckFormat = 'MMM D YYYY';
      const contestStart = this.entry.contestStart;
      const contestDate = moment(contestStart).format(dateCheckFormat);
      const todaysDate = moment().format(dateCheckFormat);

      if (contestDate == todaysDate) {
        return 'Today, ' + moment(contestStart).format('h:mma');
      }
      return moment(contestStart).format('MMM D, h:mma');
    },

    getResult(pick) {
      const result = pick?.result;
      const overUnder = pick?.selection;
      const balancedLine = pick?.balancedLine;

      if (!result && result !== 0) {
        return null;
      }
      switch (overUnder) {
        case 'OVER':
          if (result < balancedLine && !this.gameFinished(pick)) {
            return null;
          }
          return result > balancedLine;
        // only let under bets return true if the game has finished
        case 'UNDER':
          if (this.gameFinished(pick)) {
            return result < balancedLine;
          } else if (!this.gameFinished(pick) && result < balancedLine) {
            return null;
          } else {
            return false;
          }
        default:
          return null;
      }
    },

    gameFinished(pick) {
      if (!pick?.game?.state) {
        return null;
      }
      return pick.game?.state === 'COMPLETED';
    },

    openPayoutStructure() {
      EventBus.$emit('OPEN_PROPS_PAYOUT_STRUCTURE_MODAL', this.entry);
    },

    /**
     * @param {PropContest} to
     * @param {PropContest} from
     */
    onPropsContestChange(to, from) {
      if (to.status !== from.status || to.payout !== from.payout) {
        this.statusChange = true;
      }
      if (to.originalPayout !== from.originalPayout || to.reducedPayout !== from.reducedPayout) {
        this.payoutChange = true;
      }
      setTimeout(() => {
        this.statusChange = false;
        this.payoutChange = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion {
  border: solid 0.5px var(--obcolor-orange500);
  border-radius: 8px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.centered {
  display: flex;
  text-align: center;
  align-items: center;
}

.subHeading {
  font-weight: 400;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}

.infoSection {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5ch;
  align-items: center;
  background-color: var(--obcolor-background-4);
  border-radius: 8px;
  padding: 4px 12px;
  min-width: 120px;
  justify-content: center;

  &.win {
    background-color: #38C56751;
    color: var(--obcolor-green);
  }
}

.ownersBucks .infoSection.win {
  background-color: #2FC3F851;
  color: var(--obcolor-ob-blue);
}


.heading {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--obcolor-game-possibility);
}

.contestInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.headerSection {
  gap: 8px;
  margin-right: 8px;
  align-items: flex-end;
}

.liveScoringValue {
  font-size: 14px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.liveScoringLabel {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  text-align: right;
}

.entryContainer {
  display: flex;
  position: relative;
  align-self: center;
  justify-content: center;

  transition: color 300ms;
}

.moneyText {
  color: inherit;

  &.win {
    color: var(--obcolor-green);
  }
}
.ownersBucks .moneyText.win {
  color: var(--obcolor-ob-blue);
}

.propsContest {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
  padding: 16px;
}

.obLogo {
  width: 44px;
  height: 36px;
}

.contestInfo {
  margin-left: 16px;
  line-height: 1.25em;
}

.error {
  color: var(--obcolor-red);
  margin-right: 4px;
}

.errorText {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 14px;
  font-weight: 700;

  .errorTextTitle {
     font-weight: 700;
  }
  .errorTextContent {
     font-weight: 400;
  }
}

.adjustedPayout {
  text-decoration: line-through;
  color: var(--obcolor-font-secondary);
}

.ownersBucksIcon {
  height: 10px;
}

.changeFlash{
  color: var(--obcolor-flash-orange) !important;
}
</style>