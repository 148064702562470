<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3); overflow: unset !important;"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="body" class="modalBody">Player swap was successful</div>
    <div slot="footer" class="modalFooter">
      <button class="ob-btn" @click="closeModal()">Continue</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      contestId: null,
      teamId: null,
    };
  },
  created() {
    EventBus.$on('LINEUP_EDITOR_OPEN_SUCCESS_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('LINEUP_EDITOR_OPEN_SUCCESS_MODAL', this.openModal);
  },
  methods: {
    openModal(contestId, teamId) {
      this.isOpen = true;
      this.contestId = contestId;
      this.teamId = teamId;
    },
    goToLineup() {
      this.isOpen = false;
      EventBus.$emit('SALARY_CAP_RELOAD');
      if (this.contest.format == 'WEEKLY') {
        this.$router.push('/salarycontest/lineup/' + this.contestId + '/' + this.teamId);
      } else {
        this.$router.push('/salarycontest/leaderboard/' + this.contestId + '/' + this.teamId);
      }
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 10px;
}
.modalBody {
  flex: 1;
  padding: 25px 10px;
  padding-bottom: 0;
  box-sizing: border-box;
  text-align: center;
}
.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 20px;
  display: flex;
  justify-content: center;
}
</style>