<template>
  <div class="ob-page" v-if="info != null">
    <div class="pageTitle">Knowledge Base</div>
    <div class="searchInputContainer">
      <i class="fa fa-search"></i>
      <input class="searchInput" placeholder="Search the Knowledge Base...">
    </div>
    <div class="infoSelectorContainer">
      <div v-for="(heading, index) in info" :key="index" class="infoSelector"
        :class="{selectedInfo: selectedIndex == index}" @click="selectInfo(index)"
      >
        <div class="infoSelectorIconContainer">
          <img v-if="heading.icon != null && heading.icon != ''" :src="require('@/assets/knowledgebase/' + heading.icon + '.png')" :alt="heading.icon"
            class="infoSelectorIcon"
          >
        </div>
        <div class="infoSelectorTitle">{{ heading.title }}</div>
        <div @click.stop="selectInfo(index, subIndex)" v-for="(subTopic, subIndex) in heading.topics"
          :key="subIndex" class="infoSelectorSubheading"
        >
          {{ subTopic.title }}
        </div>
      </div>
    </div>
    <div class="infoBody" v-if="selectedIndex != null">
      <div class="infoBodyTitle">{{ info[selectedIndex].title }}</div>
      <div v-for="(topic, index) in info[selectedIndex].topics" :key="index" class="infoBodyTopic">
        <div class="infoBodyTopicHeader" @click="expandTopic(index)">
          <div class="infoBodyTopicTitle">{{ topic.title }}</div>
          <i class="fas " :class="'fa-chevron-' + (expandedTopics[index] != null ? 'down' : 'left')"></i>
        </div>
        <div v-if="expandedTopics[index] != null" v-html="topic.content" class="infoBodyTopicContent"></div>
      </div>
    </div>
  </div>
  <div v-else class="loadingBox">
    <i class="fad fa-spinner-third fa-spin"></i>
    <div class="loadingDraft">Loading Knowledge Base...</div>
  </div>
</template>

<script>
import ObKnowledgeBaseApi from '@/api/ObKnowledgeBaseApi';
export default {
  data() {
    return {
      info: null,
      selectedIndex: 0,
      expandedTopics: {},
    };
  },
  created() {
    this.getKnowledgeBase();
  },
  methods: {
    getKnowledgeBase() {
      ObKnowledgeBaseApi.getAllTopics().then((response) => {
        this.info = response;
      });
    },
    selectInfo(index, subIndex) {
      if (index != this.selectedIndex) {
        this.expandedTopics = {};
      }
      this.selectedIndex = index;
      if (subIndex != null) {
        this.expandTopic(subIndex);
      }
    },
    expandTopic(index) {
      if (this.expandedTopics[index] != null) {
        this.$delete(this.expandedTopics, index);
      } else {
        this.$set(this.expandedTopics, index, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingBox {
  text-align: center;
  padding: 20px;
}
.ob-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pageTitle {
  width: 100%;
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
}
.searchInputContainer {
  width: 50%;
  min-width: 400px;
  background: var(--obcolor-box-background);
  border-radius: 30px;
  padding: 5px 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.searchInput {
  background: var(--obcolor-box-background);
  flex: 1;
}

.infoSelectorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.infoSelector {
  background: var(--obcolor-box-background);
  border-radius: 10px;
  padding: 15px;
  margin: 20px;
  box-sizing: border-box;
  width: 200px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.infoSelector.selectedInfo {
  background: var(--obcolor-ob-blue);
  color: white;
}
.infoSelectorIconContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: var(--obcolor-ob-blue-tinted);
  margin-top: -47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoSelectorIcon {
  width: 40px;
  height: 40px;
}
.infoSelectorTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}
.infoSelectorSubheading {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.infoSelectorSubheading:hover {
  text-decoration: underline;
}

.infoBody {
  width: 100%;
  background: var(--obcolor-background-5);
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}
.infoBodyTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 10px;
}

.infoBodyTopic {
  width: calc(100% - 20px);
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  box-sizing: border-box;
  background: var(--obcolor-background-2);
}
.infoBodyTopicHeader {
  width: 100%;
  margin: -15px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
</style>