import Api from '@/api/ObBaseApi';

export default {
  getServerTime() {
    return Api()
        .get('/fsp/draft/getServerTime')
        .then((response) => {
          return response.data;
        });
  },

  loadDraft(id, includePlayers) {
    let params = '?id=' + id;
    params += includePlayers ? '&players=true' : '';
    return Api()
        .get('/fsp/draft/getDraft3' + params)
        .then((response) => {
          return response.data;
        });
  },
  loadPickQueue(id) {
    const params = '?draft=' + id;
    return Api()
        .get('/fsp/draft/getPlayerQueue' + params)
        .then((response) => {
          return response.data;
        });
  },
  updatePickQueue(id, playerList) {
    const params = {
      draftId: id,
      playerList: playerList,
    };
    return Api().post('/fsp/draft/updatePlayerQueue', params);
  },
  draftPlayer(id, playerId) {
    const params = {
      draft: id,
      player: playerId,
    };
    return Api().post('/fsp/draft/makePick', params);
  },
  updateDraftSettings(draftId, autopick) {
    let params = '?id=' + draftId;
    params += '&autopick=' + autopick;
    return Api().post('/fsp/draft/updateDraftSettings' + params);
  },
  loadPlayerDetails(id, sport) {
    return Api()
        .get('/pps-web/' + sport + '/playerprofile/' + id)
        .then((response) => {
          return response.data;
        });
  },

};
