<template>
  <div class="contestProgressContainer">
    <div class="progressStep" :class="getStepClass(index)" v-for="(stepVal, index) in steps"
      :key="index"
    >
      <div class="stepLabel">
        <div class="stepText">{{ getStepLabel(stepVal, index) }}</div>
      </div>
      <div class="stepChevron">
        <div class="stepChevronBorderLeft" :class="getStepClass(index)"></div>
        <div class="stepChevronBorderRight" :class="getStepClass(index + 1)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {type: Number, default: null},
    steps: {type: Array, default: null},
    settings: {type: Object, default: null},
  },

  methods: {
    getStepClass(index) {
      if (this.step > index) {
        return 'finishedStep';
      } else if (this.step === index) {
        return 'currentStep';
      }

      return 'upcomingStep';
    },

    getStepLabel(stepVal, stepIndex) {
      const settingsKey = stepVal.settingsKey;
      const setting = this.settings[settingsKey];

      if (this.step > stepIndex && setting != null) {
        if (setting.label) {
          return setting.label;
        }
        if (setting.name) {
          return setting.name;
        }

        return setting;
      }

      return stepVal.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.contestProgressContainer {
  display: flex;
  overflow: hidden;
}

.progressStep {
  font-size: 14px;
  font-weight: bold;

  height: 30px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--obcolor-background-4);
  border-top: 1px solid var(--obcolor-background-4);
  border-bottom: 1px solid var(--obcolor-background-4);
  box-sizing: border-box;

  .stepLabel {
    text-align: center;
    overflow: visible;
    flex: 1;
    margin-right: -45px;

    .stepText {
      padding: 0 30px;
      max-width: 120px;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--obcolor-background-4);

    .stepLabel {
      margin-right: -24px;
    }
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid var(--obcolor-background-4);
    .stepLabel {
      margin-right: -14px;
    }
  }

  &:not(:last-child) .stepChevron {
    $size: 17px;
    $thickness: 8px;
    border-style: solid;
    border-width: $thickness $thickness 0 0;
    border-color: var(--obcolor-background-page);
    background: var(--obcolor-background-4);
    content: '';
    display: inline-block;
    height: $size + $thickness/2;
    position: relative;
    transform: rotate(45deg);
    vertical-align: top;
    width: $size + $thickness/2;
    right: -$size;

    .stepChevronBorderLeft {
      position: absolute;
      border-style: solid;
      border-width: 1px 1px 0 0;
      width: $size + $thickness/2;
      height: $size + $thickness/2;
    }
    .stepChevronBorderRight {
      position: absolute;
      border-style: solid;
      border-width: 1px 1px 0 0;
      width: $size + $thickness/2;
      height: $size + $thickness/2;
      top: -$thickness;
      right: -$thickness;
    }
  }
}

.upcomingStep {
  &.progressStep, &.stepChevronBorderLeft, &.stepChevronBorderRight {
    border-color: var(--obcolor-background-4);
  }
}

.currentStep {
  &.progressStep, &.stepChevronBorderLeft, &.stepChevronBorderRight {
    border-color: var(--obcolor-ob-blue);
  }
}
.finishedStep {
  &.progressStep, .stepChevron {
    background: var(--obcolor-ob-blue) !important;
  }
  &.progressStep, &.stepChevronBorderLeft, &.stepChevronBorderRight {
    border-color: var(--obcolor-ob-blue);
  }
}
</style>