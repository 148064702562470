<template>
  <div class="selectedPlayerBox">
    <template v-if="player">
      <i v-if="type == 'add'" class="fa fa-plus"></i>
      <i v-else-if="type == 'drop'" class="fa fa-minus"></i>
      <div style="width: 25px; margin-right: 10px; text-align: center; font-weight: bold;">{{ player.draftPos }}</div>
      <div class="headshotContainer">
        <PlayerHeadshot size="60" :id="player.id" :sport="contest.sport"
          :team="player.team" :showLogo="true"
        />
      </div>

      <div style="min-width: 130px; margin-left: 5px; white-space: nowrap;">
        <div @click.stop="openPlayerCard()" class="ob-link" style="font-weight: bold;">
          {{ player.name.shortName() }}
          {{ player.nameTag }}
        </div>
        <div class="playerInfo">
          <span>#{{ player.number }}</span>
          <span>{{ player.position }}</span>
          <span>{{ player.lineInfo }}</span>
        </div>
        <div v-if="!statusMap" class="statusLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <PlayerStatus v-else-if="!status" height="12px;" :showText="true"
          status="'OUT'"
        />
        <PlayerStatus v-else height="12px;" :showText="true"
          :status="status.status"
        />
        <div style="font-size: 13px; margin-top: 4px;">
          <PlayerStatusDetails v-if="status" :status="status.status" :injDetails="status.injuryDetails" />
        </div>
      </div>

      <div style="flex: 1;"></div>
      <template v-if="contest.format == 'WEEKLY'">
        <table class="weekBox" aria-label="Lineup Week Box">
          <tr>
            <th v-for="date in contest.days" :key="date">
              <div :class="{ todayHeader: isToday(date) }">{{ $moment(date).format("MMM D") }}</div>
            </th>
          </tr>
          <tr>
            <td v-for="g in player.games" :key="g.date">
              <div style="display: flex;">
                <LineupEditorGameEntry :contest="contest" :entry="g" :isDH="false"
                  style="min-width: 55px;"
                />
                <LineupEditorGameEntry v-if="g.gameDH" :contest="contest" :entry="g"
                  :isDH="true" style="min-width: 55px; margin-left: 2px;"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td v-for="g in player.games" :key="g.date" class="moneyText projPayout"
              style="height: 15px;"
            >
              <div style="display: flex; text-align: center; margin-top: 3px;">
                <div :style="getGameCostHighlight(g.id)" style="flex: 1;">{{ getGameSalary(g, player, false) }}</div>
                <div v-if="g.gameDH" :style="getGameCostHighlight(g.idDH)" style="flex: 1;">{{ getGameSalary(g, player, true) }}</div>
              </div>
            </td>
          </tr>
        </table>

        <div style="flex: 1;"></div>
        <div style="min-width: 170px; margin-left: 10px;">
          <div class="statRow">
            <template v-if="type == 'drop'">
              <div class="statField">
                <div class="statVal redText">-{{ playerSwapFeeText }}</div>
                <div class="statTitle" style="display: flex; align-items: center; min-height: 15px;">
                  <span style="white-space: nowrap;">Swap Fee</span>
                  <Tooltip v-if="showTodaysGamesWarning()" style="color: #F2994A;" pos="bottom"
                    textKey="SalaryCap_Editor_SwapFee_TodayExcluded"
                  />
                  <Tooltip v-else pos="bottom" textKey="SalaryCap_Editor_SwapFee" />
                </div>
              </div>
              <div class="statField" style="width: 75px;">
                <div class="statVal moneyText">{{ playerCostText }}</div>
                <div class="statTitle" style="display: flex; align-items: center; min-height: 15px;">
                  <span>Refund</span>
                  <Tooltip v-if="showRefundWarning()" pos="bottom" textKey="SalaryCap_Editor_Refund_Excluded" />
                </div>
              </div>
            </template>
            <template v-else-if="type == 'add'">
              <div class="statField">
                <div class="statVal moneyText">{{ playerCostText }}</div>
                <div class="statTitle">Salary</div>
              </div>
            </template>
          </div>
          <div class="statRow">
            <div class="statField" style="width: 40px;">
              <div class="statVal">{{ gamesRemaining }}</div>
              <div class="statTitle">GR</div>
            </div>
            <div class="statField">
              <div class="statVal">{{ player.sAvg.toFixed(1) }}</div>
              <div class="statTitle">sAVG</div>
            </div>
            <div class="statField">
              <div class="statVal">{{ projRemaining }}</div>
              <div class="statTitle">Proj</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="statFieldNfl">
          <div v-if="nextGame" class="logoBox statVal">
            <div v-if="nextGame.game.includes('@')" style="margin-bottom: 3px;">@</div>
            <img :src="getTeamLogo(nextGame.game)" style="height: 24px;" :alt="`${nextGame.game} Team`">
          </div>
          <div v-else class="logoBox statVal">-</div>
          <div class="statTitle">VS</div>
        </div>
        <div class="statFieldNfl" style="width: 110px;">
          <div v-if="nextGame && nextGame.ts != null" class="statVal">{{ $moment(nextGame.ts).format('ddd h:mma') }}</div>
          <div v-else class="statVal">-</div>
          <div class="statTitle">TIME</div>
        </div>
        <div v-if="contest.sport == 'NFL'" class="statFieldNfl">
          <div v-if="nextGame && nextGame.oprk != null" class="statVal oprk" :style="'color: ' + getOprkColour(nextGame.oprk)">{{ nextGame.oprk }}</div>
          <div class="statVal" v-else>-</div>
          <div class="statTitle">OPRK</div>
        </div>
        <div class="statFieldNfl">
          <div class="statVal">{{ projRemaining }}</div>
          <div class="statTitle">PROJ</div>
        </div>
        <div class="statFieldNfl">
          <div class="statVal">{{ player.sAvg.toFixed(1) }}</div>
          <div class="statTitle">sAVG</div>
        </div>
        <div class="statFieldNfl">
          <div class="statVal moneyText">{{ dollarFormat(player.salary) }}</div>
          <div class="statTitle">SALARY</div>
        </div>
      </template>

      <div @click="removePlayer()" class="removeBtn">
        <div><i class="fal fa-times"></i></div>
      </div>
    </template>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerStatusDetails from '@/components/PlayerStatusDetails.vue';
import LineupEditorGameEntry from './LineupEditorGameEntry';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
    PlayerStatusDetails,
    LineupEditorGameEntry,
    Tooltip,
  },

  props: {
    player: {type: Object, default: null},
    statusMap: {type: Object, default: null},
    status: {type: Object, default: null},
    contest: {type: Object, default: null},
    type: {type: String, default: null},
  },

  computed: {
    isWeeklyContest() {
      return this.contest && this.contest.format === 'WEEKLY';
    },

    playerCost() {
      const gr = this.gamesRemaining;
      return this.player.salaryPG * gr;
    },

    playerCostText() {
      if (this.type === 'drop') {
        return this.dollarFormat(this.player.swapInfo.refund);
      }
      return this.dollarFormat(this.playerCost);
    },

    playerSwapFeeText() {
      if (this.type === 'drop') {
        return this.dollarFormat(this.player.swapInfo.fee);
      }
      return 0;
    },

    projRemaining() {
      if (!this.player) {
        return 0;
      }
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && this.player.canSwap;
      const now = new Date().getTime();
      let proj = 0;
      for (const game of this.player.games) {
        proj += this.getGameEntryProjection(game, isValidDailySwap, now);
      }
      return proj;
    },

    gamesRemaining() {
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && this.player.canSwap;
      const now = new Date().getTime();
      let gr = 0;
      for (const game of this.player.games) {
        if ((isValidDailySwap || game.ts > now) && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && (isValidDailySwap || game.tsDH > now) && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    gamesRemainingExcludingToday() {
      const now = new Date().getTime();
      const today = this.$moment(now).format('YYYY-MM-DD');
      let gr = 0;
      for (const game of this.player.games) {
        if (game.ts > now && game.proj > 0) {
          const gDate = this.$moment(game.ts).format('YYYY-MM-DD');
          if (today !== gDate) {
            gr++;
          }
        }
        if (game.tsDH && game.tsDH > now && game.projDH > 0) {
          const gDate = this.$moment(game.tsDH).format('YYYY-MM-DD');
          if (today !== gDate) {
            gr++;
          }
        }
      }
      return gr;
    },

    nextGame() {
      if (!this.player.games) {
        return null;
      }

      return this.player.games.find((g) => g.ts != null);
    },
  },

  methods: {
    removePlayer() {
      if (this.type === 'add') {
        EventBus.$emit('LINEUP_EDITOR_REMOVE_CUR_ADD');
      } else {
        EventBus.$emit('LINEUP_EDITOR_REMOVE_CUR_DROP');
      }
    },

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    getStat(path) {
      if (this.player && this.player.wStats && this.player.wStats[0]) {
        return this.player.wStats[0][path];
      }
      return null;
    },

    getOprkColour(oprk) {
      if (oprk <= 10) {
        return 'var(--obcolor-red)';
      } else if (oprk <= 20) {
        return 'var(--obcolor-yellow)';
      }

      return 'var(--obcolor-green)';
    },

    formatPoints(fpts) {
      return fpts < 0 ? fpts : '+' + fpts;
    },

    showTodaysGamesWarning() {
      if (!this.player.swapInfo) {
        return false;
      }
      return this.player.swapInfo.showFeeWarning;
    },

    showRefundWarning() {
      if (!this.player.swapInfo) {
        return false;
      }
      return this.player.swapInfo.showRefundWarning;
    },

    getGameCostHighlight(gameId) {
      if (this.gameExcludedFromFee(gameId)) {
        return 'color: #F2994A;';
      }
      if (this.gameExcludedFromRefund(gameId)) {
        return 'color: var(--obcolor-red);';
      }
      return '';
    },

    // Game excluded from swap fee due to status
    gameExcludedFromFee(gameId) {
      if (this.type !== 'drop') {
        return false;
      }
      if (!this.player.swapInfo.noFeeGames) {
        return false;
      }
      return this.player.swapInfo.noFeeGames.includes(gameId);
    },

    // Game excluded from refund, due to deactivation
    gameExcludedFromRefund(gameId) {
      if (this.type !== 'drop') {
        return false;
      }
      if (!this.player.swapInfo.noRefundGames) {
        return false;
      }
      return this.player.swapInfo.noRefundGames.includes(gameId);
    },

    getGameSalary(entry, player, isDH) {
      const dh = isDH ? 'DH' : '';
      // No game, or excluded from this contest
      if (!entry['game'+dh] || entry['excluded'+dh]) {
        return '';
      }
      // Not projected to play
      if (entry['proj'+dh] <= 0) {
        return '';
      }
      // Game has already started
      if (entry['ts'+dh] < new Date().getTime()) {
        return '';
      }
      return this.dollarFormat(player.salaryPG);
    },

    dollarFormat(dollarAmount) {
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    convertAlias(game) {
      if (!game) {
        return '';
      }
      if (game.indexOf('@') != -1) {
        return '@' + this.$TeamInfo.getDisplayAlias(this.contest.sport, game.replace('@', ''));
      }
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, game);
    },

    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },

    isToday(date) {
      if (date == null) {
        return false;
      }
      const dateString = this.$moment(date).format('YYYY-MM-DD');
      return dateString == this.$moment().format('YYYY-MM-DD');
    },

    getGameEntryProjection(game, isValidDailySwap, now) {
      if (!game) {
        return 0;
      }
      let proj = 0;
      if (game.ts && (isValidDailySwap || game.ts > now)) {
        proj += game.proj ? Math.round(game.proj) : 0;
      }
      if (game.tsDH && (isValidDailySwap || game.tsDH > now)) {
        proj += game.projDH ? Math.round(game.projDH) : 0;
      }
      return proj;
    },
  },
};
</script>

<style lang="scss" scoped>
  .selectedPlayerBox {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 100%;
  }

  .fa-minus, .fa-plus {
    padding-right: 8px;
    font-size: 20px;
    margin-left: -5px;
  }

  .fa-minus {
    color: var(--obcolor-red);
  }

  .fa-plus {
    color: var(--obcolor-green);
  }

  .headshotContainer {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 3px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .statusLoading {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .statRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .statRow:not(:last-child) {
    margin-bottom: 10px;
  }

  .statField, .statFieldNfl {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .statField {
    width: 60px;
  }

  .statField:not(:last-child), .statFieldNfl:not(:last-child) {
    margin-right: 10px;
  }

  .statVal {
    font-size: 14px;
    font-weight: bold;
  }

  .statTitle {
    font-size: 12px;
  }

  .statFieldNfl {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logoBox {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--obcolor-font-secondary);
    }

    .statVal {
      font-size: 16px;
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4px;
    }

    .statTitle {
      font-size: 14px;
      text-align: center;
      color: var(--obcolor-font-secondary);
      padding: 2px 8px;
      border-radius: 5px;
      background: var(--obcolor-background-3);
    }
  }

  .blueText {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }

  .redText {
    color: var(--obcolor-red);
  }

  .projPayout {
    padding-bottom: 0px !important;
  }

  table.weekBox {
    height: 100%;
    text-align: center;
    font-size: 11px;
    margin: 0 10px;

    .draftGame {
      padding-bottom: 0px;
      height: 40px;
    }

    td, th {
      width: 50px;
      min-width: 50px;
    }

    th {
      color: var(--obcolor-font-secondary);
      text-transform: uppercase;
      div {
        height: 100%;
        width: calc(100% - 4px);
        margin: 0 3px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .todayHeader {
      color: var(--obcolor-ob-blue);
    }

    td {
      padding: 0 3px;
      color: var(--obcolor-font-secondary);
    }

    td > .gameContainer {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--obcolor-background-1);
      margin-right: 2px;
    }
  }

  .gameProj {
    font-size: 16px;
    font-weight: bold;
    margin-top: 1px;
    margin-bottom: -1px;
  }

  .addButtonContainer {
    display: flex;
    justify-content: flex-end;

    button {
      width: 160px;
    }
  }

  .removeBtn {
    width: 26px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-text-faded);
    font-size: 20px;
    cursor: pointer;

    background: var(--obcolor-background-4);
    margin-right: -10px;
    margin-left: 14px;
  }
  .removeBtn:hover {
    color: var(--obcolor-font-primary);
    background: var(--obcolor-background-2);
  }
</style>