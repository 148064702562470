<template>
  <div class="challengeContainer" :style="fontSize">
    <img class="challengeIcon" :src="getChallengeBadge(challenge.icon, theme)" :alt="challenge.icon">
    <div class="challengeContentContainer" :style="progressBarWidth">
      <div class="challengeLabelContainer">
        <div class="label" :class="{challengeComplete: challenge.isCompleted}">{{ challenge.label }}</div>
        <div v-if="challenge.isCompleted" class="progressChallenge challengeComplete">Completed</div>
        <div v-else class="progressChallenge">({{ challenge.progressCurrent }}/{{ challenge.progressGoal }})</div>
      </div>
      <div class="progressBar" :style="progressBarHeight">
        <div class="progress" :class="{challengeComplete: challenge.isCompleted}" :style="'width: ' + challenge.progressPercent + '%;'"></div>
      </div>
    </div>
    <div class="challengeInfo">
      <div class="rewardContainer" :class="{challengeComplete: challenge.isCompleted}">
        <span v-if="challenge.expPoints">
          {{ challenge.expPoints }} XP<span v-if="!lightningLineup || !dfs">{{ rewards ? ',' : '' }}</span>
        </span>
        <template v-if="rewards && (lightningLineup || dfs)">
          <div class="prizes" @click.stop="togglePrizes" v-click-outside="closePrizes">
            <div>+ Prizes</div>
            <BattlePassPrizesTooltip v-if="showPrizes" class="prizesTooltipContainer" :rewards="rewards"
              :challengeComplete="challenge.isCompleted"
            />
          </div>
        </template>
        <template v-else-if="rewards">
          <span v-for="(reward, index) in rewards.rewardDetails" :key="index"
            :class="{challengeComplete: challenge.isCompleted}"
          >
            {{ reward.name }}
            <img v-if="getRewardIcon(reward)" :src="getRewardIcon(reward)" alt="Rewards"
              class="rewardIcon"
            >
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {getChallengeBadge, getRewardIcon} from '@/utils/shared/BattlePassUtils';
import BattlePassPrizesTooltip from './BattlePassPrizesTooltip';
import {mapState} from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'BattlePassProgressBar',

  directives: {
    ClickOutside,
  },

  props: {
    challenge: {type: Object, default: null},
    progressBarWidth: {type: String, default: null},
    progressBarHeight: {type: String, default: null},
    fontSize: {type: String, default: null},
    lightningLineup: {type: Boolean, default: false},
    dfs: {type: Boolean, default: false},
  },

  components: {
    BattlePassPrizesTooltip,
  },

  data() {
    return {
      showPrizes: false,
    };
  },

  computed: {
    ...mapState(['theme']),

    rewards() {
      return this.challenge?.rewards;
    },
  },

  methods: {
    getChallengeBadge,
    getRewardIcon,

    togglePrizes() {
      this.showPrizes = !this.showPrizes;
    },

    closePrizes() {
      this.showPrizes = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.prizes {
  cursor: pointer;
}

.prizeArrow {
  position: absolute;
  top: 0;
  width: 1px;
  border-bottom: 8px solid var(--obcolor-background-6);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.prizesContainer {
  position: relative;
  height: 0;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.prizesBox {
  position: absolute;
  box-sizing: border-box;
  top: 5px;
  right: 10px;
  z-index: 9999;
  padding: 4px;
  background: var(--obcolor-background-6);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
  }

  img {
    padding-right: 3px;
  }
}

.challengeContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 10px;
  font-size: 12px;
  .challengeIcon {
    width: 25px;
    padding-right: 8px;
  }

  .rewardIcon {
    width: 16px;
    padding-left: 3px;
  }
}

.challengeContentContainer {
  .challengeLabelContainer {
    display: flex;
    justify-content: space-between;
  }

  .label {
    padding-bottom: 5px;
  }
}

.challengeInfo {
  flex: 1;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: -6px;
}

.rewardContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  text-align: right;
  white-space: pre;
  width: 200px;
  line-height: 1.3;
}

.challengeComplete,
.progressChallenge {
  color: var(--obcolor-font-secondary);
}

.progressBar {
  width: 100%;
  background: var(--obcolor-timeline);
  height: 6px;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  background: var(--obcolor-ob-blue);
  height: 100%;
  transition: 400ms ease-out;
}

.progress.challengeComplete {
  opacity: 0.4;
}
</style>