// Matchup Summary Stats
// Controls the top overall summary for matchup page
// NHL has 1 category for summary, split into Skater and Goaltender

// Matchup Summary
export const MATCHUP_NHL = [
  {
    label: 'SKATER',
    stats: [
      {label: 'G', path: 'skater.G', tooltip: 'Goals'},
      {label: 'A', path: 'skater.A', tooltip: 'Assists'},
      {label: '+/-', path: 'skater.plus_minus', tooltip: '+/-'},
      {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal'},
      {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots'},
    ],
  },
  {
    label: 'GOALTENDER',
    stats: [
      {label: 'SV', path: 'goalie.SV', tooltip: 'Saves'},
      {label: 'GA', path: 'goalie.GA', tooltip: 'Goals Against'},
      {label: 'W', path: 'goalie.G_W', tooltip: 'Wins'},
      {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts'},
    ],
  },
];
