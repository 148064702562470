<template>
  <div class="ob-page" v-if="loaded">
    <div class="ob-box pageBox">
      <div class="ob-subheading" style="margin-left: 0; color: var(--obcolor-font-primary);">User Limits</div>
      <div style="margin-bottom: 10px;">
        Setting a personal user limit is a tool that can assist in managing your play in a healthy and responsible way.
        You can change your limits at any time but there'll be a 90 day restriction before you can increase your limit afterwards.
        Below you have the option to limit yourself in a variety of ways to make sure that you are spending your time on OwnersBox appropriately.
      </div>
      <div class="ob-box categoryBox" v-if="showUniqueStateLimitation == true">
        <div class="boxHeader">Unique State Limitations</div>
        <div class="boxBody" style="flex-direction: column;">
          <div>
            Certain States within the USA has specific limits that restricts users from depositing over the set amount.
            As a resident of <b>{{ region }}</b> you are limited to a deposit limit of
            <b>${{ regionDepositLimit }} per {{ isRollingDeposit ? '31 days' : 'month' }}</b>.
            <br>
            To request a higher rate, please contact <a href="https://support.ownersbox.com/hc/en-us" class="hyperlink">Customer Service.</a>
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Deposit Limits and Alerts</div>
        <div class="boxBody">
          <div class="bodyLeftSide">
            Setting a deposit <b>alert</b> will result in the user receiving an email once they have passed the set threshold.
            Users will <b>not be restricted</b> from depositing once they have passed the limit,
            they will only be notified that they have surpassed it.
            <br>
            <br>
            <b>Example:</b> If you set your weekly alert to $200, once you have deposited over $200 you will receive an email alerting you.
            <br>
            <br>
            Setting a deposit <b>limit</b> will determine how much you can deposit into your OwnersBox account in a given period of time.
            Once you have set a limit you will be restricted from increasing your limit for 90 calendar days.
            <br>
            <br>
            <b>Example:</b> If you set your weekly limit to $200, you can deposit up to $200 every 7 days.
            <br>
            <br>
            Limitations a user applies are backdated for the entire 24 hour, 7 day, or 31 day windows prior to any deposits being made.
            When you go to make a deposit the system will look back, from that moment,
            24 hours, 7 days, or 31 days (depending on type of limit) to see how much you can deposit.
            <br>
            <br>
            <b>Example:</b> If you had a weekly limit set of $200 and went to deposit $175
            the system would look back and check the previous 7 days for any deposits.
            if you had deposited $50 3 days ago you will be restricted from completing
            the transaction as you would only have $150 left in your limit.
          </div>
          <div class="bodyRightSide">
            <div class="ob-box fieldBox">
              <div class="field">
                <div>Set Daily Alert</div>
                <input placeholder="No Limit" v-model="dailyDepositAlert">
              </div>
              <div class="field">
                <div>Set Daily Limit</div>
                <input placeholder="No Limit" v-model="dailyDepositLimit">
                <div v-if="dailyDepositDaysLeft && dailyDepositDaysLeft > 0" class="fieldWarning">
                  {{ dailyDepositDaysLeft }} Day{{ dailyDepositDaysLeft > 1 ? 's' : '' }} Remaining
                </div>
              </div>
            </div>
            <div class="ob-box fieldBox">
              <div class="field">
                <div>Set Weekly Alert</div>
                <input placeholder="No Limit" v-model="weeklyDepositAlert">
              </div>
              <div class="field">
                <div>Set Weekly Limit</div>
                <input placeholder="No Limit" v-model="weeklyDepositLimit">
                <div v-if="weeklyDepositDaysLeft && weeklyDepositDaysLeft > 0" class="fieldWarning">
                  {{ weeklyDepositDaysLeft }} Day{{ weeklyDepositDaysLeft > 1 ? 's' : '' }} Remaining
                </div>
              </div>
            </div>
            <div class="ob-box fieldBox">
              <div class="field">
                <div>Set Monthly Alert</div>
                <input placeholder="No Limit" v-model="monthlyDepositAlert">
              </div>
              <div class="field">
                <div>Set Monthly Limit</div>
                <input placeholder="No Limit" v-model="monthlyDepositLimit">
                <div v-if="monthlyDepositDaysLeft && monthlyDepositDaysLeft > 0" class="fieldWarning">
                  {{ monthlyDepositDaysLeft }} Day{{ monthlyDepositDaysLeft > 1 ? 's' : '' }} Remaining
                </div>
              </div>
            </div>
            <div style="flex: 1; text-align: right;">
              <div class="btnError" v-if="depositMessage" v-tooltip.top.notrigger="{ content: depositMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: true }"></div>
              <button class="ob-btn-med ob-btn-grey applyBtn" v-if="processingDeposit == true" disabled>
                <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
                <div>Saving...</div>
              </button>
              <button v-else-if="depositLimitsValid() == true" class="ob-btn applyBtn" @click="submitDepositLimits()">Apply</button>
              <button v-else disabled class="ob-btn-grey applyBtn">Apply</button>
            </div>
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Entry Fee Limit</div>
        <div class="boxBody">
          <div class="bodyLeftSide">
            Setting an entry fee limit prevents you from joining a contest that has an entry fee exceeding the limit you establish.
            Once you have set an limit you will be restricted from increasing your limit for 90 calendar days.
            <br>
            <br>
            <b>Example:</b> If you set your entry fee limit to $500 you will only be able to enter contests with entry fees of $500 or less.
          </div>
          <div class="bodyRightSide">
            <div class="ob-box fieldBox">
              <div class="field">
                <div>Set Entry Fee Limit</div>
                <input placeholder="No Limit" v-model="entryFeeLimit">
                <div v-if="entryFeeDaysLeft && entryFeeDaysLeft > 0" class="fieldWarning">
                  {{ entryFeeDaysLeft }} Day{{ entryFeeDaysLeft > 1 ? 's' : '' }} Remaining
                </div>
              </div>
            </div>
            <div style="flex: 1; text-align: right;">
              <div class="btnError" v-if="entryFeeMessage" v-tooltip.top.notrigger="{ content: entryFeeMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: true }"></div>
              <button class="ob-btn-med ob-btn-grey applyBtn" v-if="processingEntryFee == true" disabled>
                <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
                <div>Saving...</div>
              </button>
              <button v-else-if="entryFeeLimitValid()" class="ob-btn applyBtn" @click="submitEntryFeeLimit()">Apply</button>
              <button v-else disabled class="ob-btn-grey applyBtn">Apply</button>
            </div>
          </div>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Contest Entry Limit</div>
        <div class="boxBody">
          <div class="bodyLeftSide">
            Setting a contest entry <b>alert</b> will result in you getting an email notification when you surpass the set alert limit of contests entries.
            You will <b>NOT BE</b> restricted from entering if you go over the set alert limit.
            <br>
            <br>
            <b>Example:</b> If you set your entry limit alert to 50 you will receive an email notification when you join your 51st contest.
            <br>
            <br>
            Setting a contest entry <b>limit</b> will prevent you from entering more concurrent contests then your established limit.
            Once you have set a limit you will be restricted from increasing your limit for 90 calendar days.

            <b>Example:</b> If you set your entry limit to 10 you can only be in 10 active contest at a time.
          </div>
          <div class="bodyRightSide">
            <div class="ob-box fieldBox">
              <div class="field">
                <div>Set Contest Entry Alert</div>
                <input placeholder="No Limit" v-model="contestEntryAlert">
              </div>
              <div class="field">
                <div>Set Contest Entry Limit</div>
                <input placeholder="No Limit" v-model="contestEntryLimit">
                <div v-if="contestEntryDaysLeft && contestEntryDaysLeft > 0" class="fieldWarning">
                  {{ contestEntryDaysLeft }} Day{{ contestEntryDaysLeft > 1 ? 's' : '' }} Remaining
                </div>
              </div>
            </div>
            <div style="flex: 1; text-align: right;">
              <div class="btnError" v-if="contestEntryMessage" v-tooltip.top.notrigger="{ content: contestEntryMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: true }"></div>
              <button class="ob-btn-med ob-btn-grey applyBtn" v-if="processingContestEntry == true" disabled>
                <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
                <div>Saving...</div>
              </button>
              <button v-else-if="contestEntryLimitsValid()" class="ob-btn applyBtn" @click="submitContestEntryLimits()">Apply</button>
              <button v-else disabled class="ob-btn-grey applyBtn">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loadingBox">
    <i class="fad fa-spinner-third fa-spin"></i>
    <div style="margin-top: 10px; font-size: 14px;">Loading Limits...</div>
  </div>
</template>

<script>
import ObResponsiblePlayApi from '@/api/ObResponsiblePlayApi';

export default {
  data() {
    return {
      loaded: false,
      userLimitsOriginal: null,
      showUniqueStateLimitation: null,

      region: '',
      isRollingDeposit: false,
      regionDepositLimit: null,
      regionLimitType: null,

      dailyDepositAlert: null,
      dailyDepositLimit: null,
      weeklyDepositAlert: null,
      weeklyDepositLimit: null,
      monthlyDepositAlert: null,
      monthlyDepositLimit: null,

      entryFeeLimit: null,

      contestEntryAlert: null,
      contestEntryLimit: null,

      dailyDepositExpiry: null,
      weeklyDepositExpiry: null,
      monthlyDepositExpiry: null,
      entryFeeLimitExpiry: null,
      contestEntryExpiry: null,

      processingDeposit: false,
      processingEntryFee: false,
      processingContestEntry: false,

      depositMessage: null,
      entryFeeMessage: null,
      contestEntryMessage: null,

      depositMessageTimeout: null,
      entryFeeMessageTimeout: null,
      contestEntryMessageTimeout: null,
    };
  },

  created() {
    this.loadUserLimits();
  },

  computed: {
    dailyDepositDaysLeft() {
      if (this.dailyDepositExpiry != null) {
        return this.$moment(this.dailyDepositExpiry).diff(this.$moment(), 'days') + 1;
      }

      return null;
    },
    weeklyDepositDaysLeft() {
      if (this.weeklyDepositExpiry != null) {
        return this.$moment(this.weeklyDepositExpiry).diff(this.$moment(), 'days') + 1;
      }

      return null;
    },
    monthlyDepositDaysLeft() {
      if (this.monthlyDepositExpiry != null) {
        return this.$moment(this.monthlyDepositExpiry).diff(this.$moment(), 'days') + 1;
      }

      return null;
    },
    entryFeeDaysLeft() {
      if (this.entryFeeLimitExpiry != null) {
        return this.$moment(this.entryFeeLimitExpiry).diff(this.$moment(), 'days') + 1;
      }

      return null;
    },
    contestEntryDaysLeft() {
      if (this.contestEntryExpiry != null) {
        return this.$moment(this.contestEntryExpiry).diff(this.$moment(), 'days') + 1;
      }

      return null;
    },
  },

  watch: {
    // Watch ALL inputs to force them to be dollar amounts
    dailyDepositAlert(newVal) {
      this.dailyDepositAlert = this.getNumbersOnly(newVal);
    },

    dailyDepositLimit(newVal) {
      this.dailyDepositLimit = this.getNumbersOnly(newVal);
    },

    weeklyDepositAlert(newVal) {
      this.weeklyDepositAlert = this.getNumbersOnly(newVal);
    },

    weeklyDepositLimit(newVal) {
      this.weeklyDepositLimit = this.getNumbersOnly(newVal);
    },

    monthlyDepositAlert(newVal) {
      this.monthlyDepositAlert = this.getNumbersOnly(newVal);
    },

    monthlyDepositLimit(newVal) {
      this.monthlyDepositLimit = this.getNumbersOnly(newVal);
    },

    entryFeeLimit(newVal) {
      this.entryFeeLimit = this.getNumbersOnly(newVal);
    },

    depositMessage(newVal) {
      const self = this;

      if (newVal != null) {
        clearTimeout(this.depositMessageTimeout);
        this.depositMessageTimeout = setTimeout(function() {
          self.resetDepositError();
        }, 3000);
      }
    },

    entryFeeMessage(newVal) {
      const self = this;

      if (newVal != null) {
        clearTimeout(this.entryFeeMessageTimeout);
        this.entryFeeMessageTimeout = setTimeout(function() {
          self.resetEntryFeeError();
        }, 3000);
      }
    },

    contestEntryMessage(newVal) {
      const self = this;

      if (newVal != null) {
        clearTimeout(this.contestEntryMessageTimeout);
        this.contestEntryMessageTimeout = setTimeout(function() {
          self.resetContestEntryError();
        }, 3000);
      }
    },
  },

  methods: {
    resetDepositError() {
      this.depositMessage = null;
    },
    resetEntryFeeError() {
      this.entryFeeMessage = null;
    },
    resetContestEntryError() {
      this.contestEntryMessage = null;
    },
    loadUserLimits() {
      ObResponsiblePlayApi.getUserLimits().then((response) => {
        this.loaded = true;
        // Keep a copy to check if a user changed a limit that they weren't allowed to.
        this.userLimitsOriginal = response;

        this.showUniqueStateLimitation = response.showUniqueStateLimitation;
        if (response.region) {
          this.region = response.region;
        }
        if (response.regionDepositLimit) {
          this.regionDepositLimit = (response.regionDepositLimit / 100).toFixed(0);
          this.userLimitsOriginal.regionDepositLimit = (response.regionDepositLimit / 100).toFixed(0);
        }
        if (response.isRollingDeposit) {
          this.isRollingDeposit = response.isRollingDeposit;
        }


        if (response.dailyDepositLimitAlert != null) {
          this.dailyDepositAlert = (response.dailyDepositLimitAlert / 100).toFixed(0);
          this.userLimitsOriginal.dailyDepositLimitAlert = (response.dailyDepositLimitAlert / 100).toFixed(0);
        }
        if (response.dailyDepositLimit != null) {
          this.dailyDepositLimit = (response.dailyDepositLimit / 100).toFixed(0);
          this.userLimitsOriginal.dailyDepositLimit = (response.dailyDepositLimit / 100).toFixed(0);
        }
        if (response.weeklyDepositLimitAlert != null) {
          this.weeklyDepositAlert = (response.weeklyDepositLimitAlert / 100).toFixed(0);
          this.userLimitsOriginal.weeklyDepositLimitAlert = (response.weeklyDepositLimitAlert / 100).toFixed(0);
        }
        if (response.weeklyDepositLimit != null) {
          this.weeklyDepositLimit = (response.weeklyDepositLimit / 100).toFixed(0);
          this.userLimitsOriginal.weeklyDepositLimit = (response.weeklyDepositLimit / 100).toFixed(0);
        }
        if (response.monthlyDepositLimitAlert != null) {
          this.monthlyDepositAlert = (response.monthlyDepositLimitAlert / 100).toFixed(0);
          this.userLimitsOriginal.monthlyDepositLimitAlert = (response.monthlyDepositLimitAlert / 100).toFixed(0);
        }
        if (response.monthlyDepositLimit != null) {
          this.monthlyDepositLimit = (response.monthlyDepositLimit / 100).toFixed(0);
          this.userLimitsOriginal.monthlyDepositLimit = (response.monthlyDepositLimit / 100).toFixed(0);
        }

        if (response.entryFeeLimit != null) {
          this.entryFeeLimit = (response.entryFeeLimit / 100).toFixed(0);
          this.userLimitsOriginal.entryFeeLimit = (response.entryFeeLimit / 100).toFixed(0);
        }

        if (response.contestEntryAlert != null) {
          this.contestEntryAlert = response.contestEntryAlert;
        }
        if (response.contestEntryLimit != null) {
          this.contestEntryLimit = response.contestEntryLimit;
        }

        if (response.dailyDepositLimitExpiry != null) {
          this.dailyDepositExpiry = response.dailyDepositLimitExpiry;
        }
        if (response.weeklyDepositLimitExpiry != null) {
          this.weeklyDepositExpiry = response.weeklyDepositLimitExpiry;
        }
        if (response.monthlyDepositLimitExpiry != null) {
          this.monthlyDepositExpiry = response.monthlyDepositLimitExpiry;
        }
        if (response.entryFeeLimitExpiry != null) {
          this.entryFeeLimitExpiry = response.entryFeeLimitExpiry;
        }
        if (response.contestEntryLimitExpiry != null) {
          this.contestEntryExpiry = response.contestEntryLimitExpiry;
        }
      });
    },

    getNumbersOnly(inputVal) {
      if (inputVal != null && inputVal != '' && !isNaN(inputVal)) {
        return Math.round(inputVal);
      } else if (isNaN(inputVal) && inputVal != null && inputVal != '') {
        return inputVal.replace(/\D/g, '');
      }

      return null;
    },

    depositLimitsValid() {
      if (this.dailyDepositLimit == this.userLimitsOriginal.dailyDepositLimit &&
        this.weeklyDepositLimit == this.userLimitsOriginal.weeklyDepositLimit &&
        this.monthlyDepositLimit == this.userLimitsOriginal.monthlyDepositLimit
      ) {
        return true;
      }
      if (this.dailyDepositDaysLeft != null && this.dailyDepositDaysLeft > 0 &&
        (this.dailyDepositLimit > this.userLimitsOriginal.dailyDepositLimit ||
          this.dailyDepositLimit == null && this.userLimitsOriginal.dailyDepositLimit != null)
      ) {
        return false;
      }

      if (this.weeklyDepositDaysLeft != null && this.weeklyDepositDaysLeft > 0 &&
        (this.weeklyDepositLimit > this.userLimitsOriginal.weeklyDepositLimit ||
          this.weeklyDepositLimit == null && this.userLimitsOriginal.weeklyDepositLimit != null)
      ) {
        return false;
      }

      return !(this.monthlyDepositDaysLeft != null && this.monthlyDepositDaysLeft > 0 &&
        (this.monthlyDepositLimit > this.userLimitsOriginal.monthlyDepositLimit ||
          this.monthlyDepositLimit == null && this.userLimitsOriginal.monthlyDepositLimit != null));
    },

    submitDepositLimits() {
      const keyVals = {
        dailyDepositLimitAlert: this.dailyDepositAlert,
        dailyDepositLimit: this.dailyDepositLimit,
        weeklyDepositLimitAlert: this.weeklyDepositAlert,
        weeklyDepositLimit: this.weeklyDepositLimit,
        monthlyDepositLimitAlert: this.monthlyDepositAlert,
        monthlyDepositLimit: this.monthlyDepositLimit,
      };
      const params = {};

      for (const index in keyVals) {
        if (keyVals[index] != null) {
          params[index] = keyVals[index] * 100;
        } else {
          params[index] = null;
        }
      }

      this.processingDeposit = true;

      ObResponsiblePlayApi.updateUserDeposit(params)
          .then((response) => {
            this.depositMessage = 'Saved';
            this.processingDeposit = false;
          })
          .catch((error) => {
            this.processingDeposit = false;
            if (error.response && error.response.data) {
              this.depositMessage = error.response.data;
            }
          }); ;
    },

    entryFeeLimitValid() {
      if (this.entryFeeLimit == this.userLimitsOriginal.entryFeeLimit) {
        return true;
      }

      return !(this.entryFeeDaysLeft != null && this.entryFeeDaysLeft > 0 &&
        ((this.entryFeeLimit == null && this.userLimitsOriginal.entryFeeLimit != null) ||
          (this.entryFeeLimit != null && this.entryFeeLimit > this.userLimitsOriginal.entryFeeLimit)));
    },

    submitEntryFeeLimit() {
      const params = {};

      if (this.entryFeeLimit != null) {
        params.entryFeeLimit = this.entryFeeLimit * 100;
      } else {
        params.entryFeeLimit = null;
      }

      this.processingEntryFee = true;

      ObResponsiblePlayApi.updateEntryFeeLimit(params)
          .then((response) => {
            this.processingEntryFee = false;
            this.entryFeeMessage = 'Saved';
          })
          .catch((error) => {
            this.processingEntryFee = false;
            if (error.response && error.response.data) {
              this.entryFeeMessage = error.response.data;
            }
          });
    },

    contestEntryLimitsValid() {
      if (this.contestEntryLimit == this.userLimitsOriginal.contestEntryLimit) {
        return true;
      }

      return !(this.contestEntryDaysLeft != null && this.contestEntryDaysLeft > 0 &&
        ((this.contestEntryLimit == null && this.userLimitsOriginal.contestEntryLimit != null) ||
          (this.contestEntryLimit != null && this.contestEntryLimit > this.userLimitsOriginal.contestEntryLimit)));
    },

    submitContestEntryLimits() {
      const keyVals = {
        contestEntryAlert: this.contestEntryAlert,
        contestEntryLimit: this.contestEntryLimit,
      };
      const params = {};

      for (const index in keyVals) {
        if (keyVals[index] != null) {
          params[index] = keyVals[index];
        } else {
          params[index] = null;
        }
      }

      this.processingContestEntry = true;

      ObResponsiblePlayApi.updateContestEntry(params)
          .then((response) => {
            this.processingContestEntry = false;
            this.contestEntryMessage = 'Saved';
          })
          .catch((error) => {
            this.processingContestEntry = false;
            if (error.response && error.response.data) {
              this.contestEntryMessage = error.response.data;
            }
          });
      // TODO: handle error
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 10px;
}

// re-add hyperlink colours
.hyperlink {
  text-decoration: underline;
  color: var(--obcolor-ob-blue);
  cursor: pointer;
}

.hyperlink:visited {
  color: purple;
}

.hyperlink:active {
  color: red
}

.applyBtn {
  width: 177px;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.btnError {
  width: 177px;
  margin-left: auto;
}

.pageBox {
  padding: 15px 20px;
  font-size: 14px;
}

.categoryBox {
  background: var(--obcolor-background-4);
  overflow: hidden;
  padding: 15px 20px;
}

.categoryBox:not(:last-child) {
  margin-bottom: 15px;
}

.boxHeader {
  background: var(--obcolor-background-3);
  margin: -15px -20px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
}

.boxBody {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.bodyLeftSide {
  flex: 1;
  max-width: 720px;
}

.bodyRightSide {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
}

.fieldBox {
  background: var(--obcolor-background-3);
  display: flex;
}

.fieldBox:not(:first-child) {
  margin-top: 15px;
}

.fieldBox:not(:last-child) {
  margin-bottom: 15px;
}

.field {
  padding: 0 5px;
  font-size: 14px;
}

.fieldWarning {
  font-size: 10px;
  color: red;
  padding-top: 5px;
}

.loadingBox {
  text-align: center;
  padding: 20px;
}
</style>