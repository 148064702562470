<template>
  <div v-if="message" class="errorPanel" :class="isWarning ? 'warningPanel' : ''">
    <i class="far fa-info-circle"></i>
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {type: String, default: null},
    isWarning: {type: Boolean, default: null},
  },
};
</script>

<style lang="scss" scoped>
.errorPanel {
  background: rgba(230, 46, 46, 0.10);
  color: var(--obcolor-red);
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  >i {
    width: 40px;
    text-align: center;
    margin-top: 1px;
  }
}
.warningPanel {
  background: var(--ob-color-yellow-bg);
  color: var(--ob-color-yellow-2);
}
</style>