import Api from '@/api/ObBaseApi';

export default {

  getSocialContent() {
    return Api()
        .get('/fsp/social/getSocialContent')
        .then((response) => {
          return response.data;
        });
  },

  searchUser(username) {
    const params = {
      username: username,
    };

    return Api()
        .post('/fsp/social/searchUser', params)
        .then((response) => {
          return response.data;
        });
  },

  addFriend(userId) {
    const params = {
      userId: userId,
    };
    return Api()
        .post('/fsp/social/sendFriendRequest', params)
        .then((response) => {
          return response.data;
        });
  },

  sendFriendRequest(username) {
    const params = {
      username: username,
    };
    return Api()
        .post('/fsp/social/sendFriendRequest', params)
        .then((response) => {
          return response.data;
        });
  },

  acceptFriendRequest(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/acceptFriendRequest', params)
        .then((response) => {
          return response.data;
        });
  },

  rejectFriendRequest(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/rejectFriendRequest', params)
        .then((response) => {
          return response.data;
        });
  },

  cancelFriendRequest(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/removeFriend', params)
        .then((response) => {
          return response.data;
        });
  },

  clearNotifications(conversationIds) {
    const params = {
      conversationIdList: conversationIds,
    };
    return Api()
        .post('/fsp/social/notification/clearUnreadCount', params)
        .then((response) => {
          return response.data;
        });
  },

  removeFriend(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/removeFriend', params)
        .then((response) => {
          return response.data;
        });
  },

  blockUser(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/blockUser', params)
        .then((response) => {
          return response.data;
        });
  },

  unblockUser(id) {
    const params = {
      userId: id,
    };
    return Api()
        .post('/fsp/social/unBlockUser', params)
        .then((response) => {
          return response.data;
        });
  },

  getMessages(conversationId, from, to) {
    return Api()
        .get('/fsp/social/getConversation?conversationId=' + conversationId + '&from=' + from + '&to=' + to)
        .then((response) => {
          return response.data;
        });
  },

  sendMessage(conversationId, message) {
    const params = {
      conversationId: conversationId,
      message: message,
    };
    return Api()
        .post('/fsp/social/saveConversation', params)
        .then((response) => {
          return response.data;
        });
  },

  getGroupMessages(conversationId, from, to) {
    return Api()
        .get('/fsp/social/group/getGroupConversation?conversationId=' + conversationId + '&from=' + from + '&to=' + to)
        .then((response) => {
          return response.data;
        });
  },

  sendGroupMessage(conversationId, message) {
    const params = {
      conversationId: conversationId,
      message: message,
    };
    return Api()
        .post('/fsp/social/group/sendGroupMessage', params)
        .then((response) => {
          return response.data;
        });
  },

  createGroup(name, friendIds) {
    const params = {
      groupName: name,
      membersList: friendIds,
    };
    return Api()
        .put('/fsp/social/group/createUserGroup', params)
        .then((response) => {
          return response.data;
        });
  },

  saveGroupSettings(params) {
    const data = new FormData();
    let paramString = '';
    let nParamCounter = 0;

    for (const pIndex in params) {
      if (pIndex != 'file') {
        if (nParamCounter == 0) {
          paramString += '?';
        } else {
          paramString += '&';
        }
        nParamCounter++;

        paramString += pIndex + '=' + params[pIndex];
      }
    }

    if (params.file) {
      data.append('file', params.file);
    }

    return Api()
        .post('/fsp/groupimages/upload' + paramString, data)
        .then((response) => {
          return response.data;
        });
  },

  addGroupMembers(conversationId, userIds) {
    const params = {
      conversationId: conversationId,
      userIds: userIds,
    };
    return Api()
        .post('/fsp/social/group/addMembers', params)
        .then((response) => {
          return response.data;
        });
  },

  leaveGroup(conversationId) {
    const params = {
      conversationId: conversationId,
    };
    return Api()
        .post('/fsp/social/group/leaveGroup', params)
        .then((response) => {
          return response.data;
        });
  },

  updateOwner(conversationId, userId) {
    const params = {
      conversationId: conversationId,
      userId: userId,
    };
    return Api()
        .post('/fsp/social/group/updateOwner', params)
        .then((response) => {
          return response.data;
        });
  },

  updateName(conversationId, groupName) {
    const params = {
      conversationId: conversationId,
      groupName: groupName,
    };
    return Api()
        .post('/fsp/social/group/updateName', params)
        .then((response) => {
          return response.data;
        });
  },

  removeMember(conversationId, userId) {
    const params = {
      conversationId: conversationId,
      userId: userId,
    };
    return Api()
        .post('/fsp/social/group/removeMember', params)
        .then((response) => {
          return response.data;
        });
  },

  getMembersName(conversationId) {
    return Api()
        .get('/fsp/social/group/getMembersName?conversationId=' + conversationId)
        .then((response) => {
          return response.data;
        });
  },

  getMembersInfo(conversationId, sport) {
    return Api()
        .get('/fsp/social/group/getMembersInfo?conversationId=' + conversationId + '&sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  removeAdmin(conversationId, userId) {
    const params = {
      conversationId: conversationId,
      userId: userId,
    };
    return Api()
        .post('/fsp/social/group/removeAdmin', params)
        .then((response) => {
          return response.data;
        });
  },

  addAdmin(conversationId, userId) {
    const params = {
      conversationId: conversationId,
      userId: userId,
    };
    return Api()
        .post('/fsp/social/group/addAdmin', params)
        .then((response) => {
          return response.data;
        });
  },

  getGroupInfo(groupId, filters) {
    let filterString = '';

    if (filters && filters.sport) {
      filterString += '&sport=' + filters.sport;
    }
    if (filters && filters.season) {
      filterString += '&sportSeason=' + filters.season;
    }
    return Api()
        .get('/fsp/social/group/getGroupInfo?id=' + groupId + filterString)
        .then((response) => {
          return response.data;
        });
  },

  muteGroup(convoId) {
    const params = {
      conversationId: convoId,
    };
    return Api()
        .post('/fsp/social/notification/muteGroup', params)
        .then((response) => {
          return response.data;
        });
  },

  unmuteGroup(convoId) {
    const params = {
      conversationId: convoId,
    };
    return Api()
        .delete('/fsp/social/notification/unMuteGroup', params)
        .then((response) => {
          return response.data;
        });
  },

  muteUser(convoId) {
    const params = {
      conversationId: convoId,
    };
    return Api()
        .post('/fsp/social/notification/muteUser', params)
        .then((response) => {
          return response.data;
        });
  },

  unmuteUser(convoId) {
    const params = {
      conversationId: convoId,
    };
    return Api()
        .delete('/fsp/social/notification/unMuteUser', params)
        .then((response) => {
          return response.data;
        });
  },

  getUserGroupsStats() {
    return Api()
        .get('/fsp/social/group/getUserGroupsStats')
        .then((response) => {
          return response.data;
        });
  },

  // Global calls
  sendGlobalMessage(message, roomId = 'CHAT_GLOBAL') {
    const params = {
      roomId: roomId,
      message: message,
    };

    return Api()
        .post('/fsp/social/chat/sendMessage', params)
        .then((response) => {
          return response.data;
        });
  },

  getGlobalConversation(roomId = 'CHAT_GLOBAL') {
    return Api()
        .get('/fsp/social/chat/getConversation?roomId=' + roomId + '&from=0&to=49')
        .then((response) => {
          return response.data;
        });
  },
};
