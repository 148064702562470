<template>
  <div v-if="player != null" class="playerRow" :class="{ addPlayer: isAdd, dropPlayer: isDrop }">
    <div class="field plusMinus">
      <i :class="'fas fa-' + (isAdd ? 'plus' : 'minus')"></i>
    </div>

    <div class="field position">{{ swap.pos }}</div>

    <div class="field">
      <PlayerHeadshot size="45" :id="player.id" :sport="contest.sport"
        :team="player.team" :showLogo="true" style="margin-top: -5px;"
      />
    </div>

    <div style="height: 100%; margin-left: 2px; margin-right: -4px;">
      <div v-if="!statusMap" style="margin-top: 17px; width: 11px; font-size: 12px;">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <PlayerStatus v-else-if="!status" class="playerStatus" height="16px;"
        status="'OUT'" style="margin-top: 14px;"
      />
      <PlayerStatus v-else class="playerStatus" height="16px;"
        :status="status" style="margin-top: 14px;"
      />
    </div>

    <div class="field mainPlayerField">
      <div>{{ player.name.shortName() }}</div>
      <div class="playerInfo">
        <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }}</span>
        <span>#{{ player.number }}</span>
        <span>{{ player.position }}</span>
        <span>{{ player.lineInfo }}</span>
      </div>
    </div>

    <div class="field statField">
      <div>{{ player.calcGR }}</div>
      <div v-if="getStatDiff('gr') != null && isAdd" class="statDiff">{{ formatChange(getStatDiff('gr')) }}</div>
      <div v-else class="statDiff">GR</div>
    </div>

    <div class="field statField" style="min-width: 70px;">
      <div class="moneyText">{{ dollarFormat( player.calcSalary ) }}</div>
      <div class="statDiff">{{ isDrop ? 'Refund' : 'Salary' }}</div>
    </div>

    <div class="field statField">
      <div>{{ player.sAvg.toFixed(1) || 0 }}</div>
      <div v-if="getStatDiff('sAvg', false) != null && isAdd" class="statDiff">{{ formatChange(getStatDiff('sAvg', false)) }}</div>
      <div v-else class="statDiff">sAvg</div>
    </div>

    <div class="field statField">
      <div>{{ player.calcProj }}</div>
      <div v-if="getStatDiff('proj') != null && isAdd" class="statDiff">{{ formatChange(getStatDiff('proj')) }}</div>
      <div v-else class="statDiff">Proj</div>
    </div>
  </div>

  <div v-else class="playerRow" :class="{ addPlayer: isAdd, dropPlayer: isDrop }">
    <div class="field plusMinus">
      <i :class="'fas fa-' + (isAdd ? 'plus' : 'minus')"></i>
    </div>
    <div class="field" style="color: var(--obcolor-font-secondary);">None Selected</div>
  </div>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerHeadshot from '@/components/PlayerHeadshot';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    swap: Object,
    player: Object,
    contest: Object,
    type: String,
    statusMap: Object,
    status: String,
  },

  computed: {
    isAdd() {
      return this.type == 'add';
    },

    isDrop() {
      return this.type == 'drop';
    },
  },

  methods: {

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    getStat(player, path) {
      if (path == 'proj') {
        return player.calcProj;
      }
      if (path == 'gr') {
        return player.calcGR;
      }
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }

      return null;
    },

    getStatDiff(path, isStat = true) {
      if (!this.swap || this.swap.add == null || this.swap.drop == null) {
        return null;
      }

      let addStat = null;
      let dropStat = null;

      if (isStat) {
        addStat = this.getStat(this.swap.add, path);
        dropStat = this.getStat(this.swap.drop, path);
      } else {
        addStat = this.swap.add[path];
        dropStat = this.swap.drop[path];
      }

      if (addStat == null && dropStat == null) {
        return null;
      }

      // removeCentsIfZero works well here because it's just meant to remove trailing 0's and we want up to 2 decimal points
      return (addStat - dropStat).toFixed(2).removeCentsIfZero();
    },

    formatChange(n) {
      if (n == null) {
        return '+ 0';
      }
      if (n >= 0) {
        return '+ ' + n;
      }
      return String(n).replace('-', '- ');
    },

  },
};
</script>

<style lang="scss" scoped>
  .field {
    padding: 10px;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .field.statField {
    align-items: flex-end;
  }

  .addPlayer {
    // border: 2px solid var(--obcolor-green);
    .plusMinus {
      color: var(--obcolor-green);
    }
  }

  .dropPlayer {
    // border: 2px solid var(--obcolor-red);
    .plusMinus {
      color: var(--obcolor-red);
    }
  }

  .playerRow {
    height: 60px;
    display: flex;
    align-items: center;
    background: var(--obcolor-background-3);
    border-bottom: 1px solid var(--obcolor-background-page);
  }

  .plusMinus {
    // padding: 10px;
    font-size: 20px;
    min-width: 35px;
  }

  .field.position {
    font-size: 16px;
    // font-weight: bold;
    align-items: flex-start;
    min-width: 15px;
  }

  .mainPlayerField {
    flex: 1;
    align-items: flex-start;
  }

  .playerInfo {
    // margin-left: 15px;
    margin-top: 2px;
    text-align: left;
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    span {
      margin-right: 5px;
    }
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }

  .statDiff {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }
</style>