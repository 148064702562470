<template>
  <div>
    <div v-if="isOpen" @click="isOpen = !isOpen" class="blackFade"
      :style="isOpen ? 'opacity: 0.5;' : 'opacity: 0;'"
    ></div>
    <div class="mobileMenuContainer" :style="isOpen ? 'left: 50px' : 'left: 100%;'">
      <div class="logoBox">
        <UserLogo v-if="userId" :id="userId" />
      </div>
      <div class="userName">
        <div>username</div>
        <div>$0.00</div>
      </div>
      <div class="menu">
        <div @click="goToRoute('/lobby')" class="menuBtn">Lobby</div>
        <div @click="goToRoute('/community')" class="menuBtn">Community</div>
        <div @click="goToRoute('/social')" class="menuBtn">Social</div>
        <div @click="goToRoute('/login')" class="menuBtn">Login</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import UserLogo from '@/components/UserLogo';
import {mapState} from 'vuex';

export default {
  components: {
    UserLogo,
  },

  computed: {
    ...mapState(['isLoggedIn', 'userId']),
  },


  data: function() {
    return {
      isOpen: false,
    };
  },

  created() {
    const self = this;
    EventBus.$on('MOBILE_MENU_TOGGLE', function() {
      self.isOpen = !self.isOpen;
    });
  },

  methods: {
    goToRoute(path) {
      this.$router.push(path);
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.blackFade {
  position: fixed;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  z-index: 99;
  transition-duration: 300ms;
}
.mobileMenuContainer {
  position: fixed;
  background: black;
  border-left: 1px solid black;
  top: 0;
  bottom: 0;
  width: calc(100% - 50px);
  z-index: 99;
  transition-duration: 300ms;
  color: white;
}

.logoBox {
  width: 50px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 30px;
}
.userName {
  text-align: center;
  width: 100%;
  padding-bottom: 40px;
  border-bottom: 1px solid #222;
  color: #ccc;
  font-size: 12px;
}

.menu {
  width: 100%;
}
.menuBtn {
  padding: 15px 10px;
  text-align: center;
  border-bottom: 1px solid #222;
  cursor: pointer;
}
.menuBtn:hover {
  background: #222;
}
.menu a {
  text-decoration: none;
  color: white;
  &.router-link-active {
    color: white;
    font-weight: bold;
  }
}
</style>