<template>
  <!-- Some data in here requires the contest and lineups -->
  <div class="howToPlayContainer">
    <div class="howToPlay">
      <p>How to Play</p>
      <div class="h2pBody">
        <div v-for="(section, index) in h2pSections" :key="index" class="h2pEntry">
          <div class="sectionImgContainer">
            <img class="sectionImg" :src="section.img" :alt="section.title">
          </div>
          <div class="sectionTextContainer">
            <div class="sectionTitle">{{ section.title }}</div>
            <div class="sectionText">{{ section.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="contest && contest.scoring" class="contestInfo">
      <div class="contestInfoTile">
        <p :class="{selectedOption: selectedTab === 'scoring'}" @click="selectTab('scoring')">Scoring</p>
        <p :class="{selectedOption: selectedTab === 'payout'}" @click="selectTab('payout')">Payout Schedule</p>
      </div>
      <div class="infoSection" v-if="selectedTab === 'scoring'">
        <div v-for="section in contest.scoring" :key="section.category">
          <div class="sectionTitle">{{ section.category }}</div>
          <div class="sectionContent">
            <div class="sectionText" v-for="entry in section.stats" :key="entry.stat">
              <div style="flex: 1;">{{ entry.name }}</div>
              <div>
                <span>{{ entry.weight }}</span>
                <span v-if="contest.sport === 'NFL' && entry.weight.indexOf('per') != -1"> yds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'payout'">
        <table aria-label="Payout Schedule" class="payoutSchedule">
          <tr class="sectionTitle">
            <th scope="col">Number of Lineups</th>
            <th scope="col">Payout</th>
          </tr>
          <tr v-for="(payout, idx, index) in contest.payouts" :key="index">
            <td>
              {{ idx }} Lineups<span v-if="index == 0">*</span>
            </td>
            <td>{{ payout }}x Entry</td>
          </tr>
        </table>
        <p class="description">*only possible if a lineup gets cancelled</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contest: {type: Object, default: null},
  },

  data() {
    return {
      selectedTab: 'scoring',
      h2pSections: [
        {
          title: 'Spin',
          img: require('@/assets/contestbadge/lightning_lineups.png'),
          text: 'Spin the button for new lineups',
        },
        {
          title: 'Pick',
          img: require('@/assets/lightninglineups/howtoplay/dollarSign.png'),
          text: 'Pick a lineup and place an entry',
        },
        {
          title: 'Win',
          img: require('@/assets/lightninglineups/howtoplay/trophy.png'),
          text: 'If you get the most points, you win!',
        },
      ],
    };
  },

  methods: {
    selectTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.howToPlayContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
  gap: 10px;
}

.howToPlay, .contestInfo {
  background: var(--obcolor-background-6);
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 288px;
}

.howToPlay {
  min-width: 0;
  align-items: flex-start;

  .sectionTitle {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  .sectionText {
    font-size: 11px;
    line-height: 15px;
    color: var(--obcolor-font-secondary);
  }
}

.contestInfo {
  flex: 1;
  min-width: 288px;

  .sectionTitle {
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    padding: 3px 0;
    margin: 10px 0;
    border-bottom: 2px solid var(--obcolor-background-4);
  }

  .sectionText {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    display: flex;
    padding-bottom: 8px;
  }
}

.contestInfoTile {
  display: flex;
  gap: 50px;

  p {
    color: var(--obcolor-font-secondary);
    transform: all 0.2s;
    cursor: pointer;

    &:focus,
    &:hover {
      color: var(--obcolor-text-primary);
    }
  }

  .selectedOption {
    color: var(--obcolor-text-primary);
  }
}

p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.payoutSchedule {
  width: 270px;
  margin: 10px auto;
}

td {
  max-width: 110px;
}

tr,
td,
th {
  color: var(--obcolor-font-secondary);
  padding: 5px;
}

.description {
  color: var(--obcolor-font-secondary);
  font-size: 11px;
  font-weight: 400;
}

.h2pBody {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.h2pEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 1;
  min-width: 120px;
}

.infoSection {
  width: 100%;
}

.sectionImgContainer {
  height: 40px;
  width: 40px;
  background: var(--obcolor-background-4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sectionImg {
  height: 28px;
}

.sectionTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.sectionContent {
  background: var(--obcolor-background-6);
  font-size: 13px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
</style>