<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 600px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0 13px; background: var(--obcolor-background-3);"
  >
    <div slot="header" v-if="sport" class="modalHeader">{{ sport }} Team Settings</div>
    <div slot="header" v-else class="modalHeader">Profile Image</div>
    <div slot="body" class="modalBody">
      <div class="nameEditTitle" v-if="sport != null">Team Name</div>
      <form v-if="sport" @submit.prevent="updateName()">
        <div style="display: flex;">
          <input class="nameEditInput" placeholder="Enter team name..." v-model="teamName">
          <button class="ob-btn-med ob-btn-grey" style="display: flex;" v-if="processingSave == true"
            disabled
          >
            <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
            <div>Saving...</div>
          </button>
          <button class="ob-btn-med"
            v-else-if="teamName != null && teamName != '' && teamName != teamInfo.teamName"
            v-tooltip.top.notrigger="{ content: nameChangeMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: nameChangeMessage != null, }"
          >
            Save
          </button>
          <button v-else disabled class="ob-btn-med ob-btn-grey"
            v-tooltip.top.notrigger="{ content: nameChangeMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: nameChangeMessage != null, }"
          >
            Save
          </button>
        </div>
      </form>
      <div class="teamLogoListContainer">
        <div class="uploadLogoBtn" @click="$refs.imgPicker.click()">
          <div v-if="sport != null">Upload Team Logo</div>
          <div v-else>Upload Logo</div>
          <input ref="imgPicker" type="file"
            accept="image/*" @change="setupImage($event, 'user')"
            style="display: none;"
          >
        </div>
        <div v-if="prevImage != null" class="presetLogo"
          @click="selectedImg = 'custom'"
        >
          <img :src="prevImage" class="teamImage" alt="User Logo"
            :class="{ selectedImg: selectedImg == 'custom' }"
          >
        </div>
        <div v-else class="presetLogo" @click="() => selectedImg = null">
          <UserLogo class="teamImage" :id="$store.getters.userId" :sport="sport"
            :size="78"
            :class="{ selectedImg: selectedImg == null }"
          />
        </div>
        <div class="presetLogo" v-for="n in 18" :key="n"
          @click="selectedImg = n"
        >
          <img :src="require('@/assets/userlogos/PresetLogo_'+ n +'.png')" class="teamImage" alt="User Logo"
            :class="{ selectedImg: n === selectedImg }"
          >
        </div>
      </div>
    </div>
    <div slot="footer" class="modalFooter">
      <div v-if="imageMessage != null" class="imageMessageTooltip" v-tooltip.top.notrigger="{ content: imageMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: true, }"></div>
      <div style="display: flex; justify-content: flex-end;">
        <button class="ob-btn-grey" style="margin-right: 10px;" @click="closeModal()">Cancel</button>
        <button class="ob-btn-grey" style="display: flex;" v-if="processingConfirm == true"
          disabled
        >
          <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
          <div>Confirming...</div>
        </button>
        <button class="ob-btn" v-else-if="selectedImg != null" @click="confirmModal()">Confirm</button>
        <button class="ob-btn-grey" v-else disabled>Confirm</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';

export default {

  components: {
    Modal,
    UserLogo,
  },

  data() {
    return {
      isOpen: false,
      teamInfo: null,
      teamName: null,
      sport: null,

      imageInput: null,
      prevImage: null,
      selectedImg: null,
      imageMessage: null,
      imageMessageTimeout: null,

      nameChangeMessage: null,
      nameChangeTimeout: null,

      processingSave: false,
      processingConfirm: false,
    };
  },

  created() {
    EventBus.$on('PROFILE_EDIT_TEAM', this.openModal);
    EventBus.$on('PROFILE_EDIT_GLOBAL', this.openModalGlobal);
  },

  destroyed() {
    EventBus.$off('PROFILE_EDIT_TEAM', this.openModal);
    EventBus.$off('PROFILE_EDIT_GLOBAL', this.openModalGlobal);
  },

  watch: {
    imageMessage(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.imageMessageTimeout);
        this.imageMessageTimeout = setTimeout(function() {
          self.resetImageError();
        }, 3000);
      }
    },

    nameChangeMessage(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.nameChangeTimeout);
        this.nameChangeTimeout = setTimeout(function() {
          self.resetNameChangeMessage();
        }, 3000);
      }
    },

    teamName(newVal) {
      if (typeof newVal === 'string') {
        const regex = /[^\p{L}\p{N}\p{P}\p{Z}$+=<>|^`~]/gu;
        this.teamName = newVal.replace(regex, '');
      }
    },
  },

  methods: {

    resetData() {
      this.teamInfo = null;
      this.teamName = null;
      this.sport = null;
      this.imageInput = null;
      this.imageMessage = null;
      this.nameChangeMessage = null;
      this.prevImage = null;
      this.selectedImg = null;
      this.processingSave = false;
      this.processingConfirm = false;
    },

    setupImage(e, sport) {
      const img = e.target.files[0];
      this.imageInput = img;
      if (img != null) {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = (e) =>{
          this.prevImage = e.target.result;
        };
      } else {
        this.prevImage = null;
      }
      this.selectedImg = 'custom';
    },

    closeModal() {
      this.isOpen = false;
      this.resetData();
    },

    resetImageError() {
      this.imageMessage = null;
    },

    resetNameChangeMessage() {
      this.nameChangeMessage = null;
    },

    confirmModal() {
      if (this.selectedImg == null) {
        this.closeModal();
        return;
      }
      if (this.selectedImg != 'custom') {
        const logo = 'PresetLogo_' + this.selectedImg +'.png';
        this.processingConfirm = true;
        ObAccountApi.selectPresetLogo(this.sport, logo)
            .then((data) => {
              EventBus.$emit('CHANGE_PROFILE_PICTURE');
              this.processingConfirm = false;
              this.imageMessage = 'Image changes saved';
              this.imageInput = null;
              this.prevImage = null;
              this.selectedImg = null;
            })
            .catch((error) => {
              this.processingConfirm = false;
              if (error.response && error.response.data) {
                this.imageMessage = error.response.data;
              } else {
                this.imageMessage = 'Failed to upload image';
              }
            });
      } else if (this.imageInput != null) {
        this.processingConfirm = true;
        ObAccountApi.uploadUserLogo(this.sport, this.imageInput)
            .then((data) => {
              EventBus.$emit('CHANGE_PROFILE_PICTURE');
              this.processingConfirm = false;
              this.imageMessage = 'Image changes saved';
              this.imageInput = null;
              this.prevImage = null;
              this.selectedImg = null;
            })
            .catch((error) => {
              this.processingConfirm = false;
              if (error.response && error.response.data) {
                this.imageMessage = error.response.data;
              } else {
                this.imageMessage = 'Failed to upload image';
              }
            });
      }
    },

    updateName() {
      if (this.teamName == null || this.teamName == '') {
        return;
      }
      if (this.sport == null) {
        this.processingSave = true;
        ObAccountApi.setUserName(this.teamName)
            .then((response) => {
              EventBus.$emit('RELOAD_PROFILE');
              this.teamInfo.teamName = this.teamName;
              this.processingSave = false;
              this.nameChangeMessage = 'New name saved';
            })
            .catch((_error) => {
              this.processingSave = false;
              this.nameChangeMessage = 'New name saved';
            });
      } else {
        this.processingSave = true;
        ObAccountApi.setTeamName(this.sport, this.teamName)
            .then((response) => {
              EventBus.$emit('RELOAD_PROFILE');
              this.teamInfo.teamName = this.teamName;
              this.processingSave = false;
              this.nameChangeMessage = 'New name saved';
            })
            .catch((error) => {
              this.processingSave = false;
              if (error.response && error.response.data) {
                this.nameChangeMessage = error.response.data;
              } else {
                this.nameChangeMessage = 'Failed to change name';
              }
            });
      }
    },

    openModal(teamInfo, sport) {
      this.teamInfo = teamInfo;
      this.sport = sport;
      this.isOpen = true;
      this.teamName = teamInfo.teamName;
    },

    openModalGlobal(name) {
      this.teamName = name;
      this.teamInfo = {
        teamName: name,
      };
      this.sport = null;
      this.isOpen = true;
    },

  },
};
</script>

<style lang="scss" scoped>

.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}

.modalBody {
  flex: 1;
  padding: 15px 10px;
  box-sizing: border-box;
}

.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
}

.nameEditTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.nameEditInput {
  min-height: 30px;
  margin-right: 5px;
  box-sizing: border-box;
  width: 270px;
}

.teamLogoListContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 29px;
  border-radius: 10px;
  margin-top: 10px;
  background: var(--obcolor-background-4);
}

.teamImage {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 4px solid var(--obcolor-background-6)
}

.teamImage.selectedImg {
  border: 4px solid var(--obcolor-ob-blue);
}

.teamImage:not(.selectedImg):hover {
  border: 4px solid var(--obcolor-background-1);
}

.presetLogo {
  margin: 2px;
  border-radius: 50%;
  cursor: pointer;
}

.uploadLogoBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--obcolor-background-2);
  border: 4px solid var(--obcolor-background-5);
  color: var(--obcolor-font-secondary);
  font-weight: bold;
  font-size: 12px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 2px;
  cursor: pointer;
}

.uploadLogoBtn:hover {
  background: var(--obcolor-ob-blue);
  color: white;
}

.uploadLogoBtn:active {
  background: var(--obcolor-ob-blue-tinted);
}

.imageMessageTooltip {
  width: 72px;
  float: right;
}

</style>