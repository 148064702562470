<template>
  <div class="detailsContainer">
    <div>
      <p>Sport:</p>
      <p>{{ selectedContestSport }}</p>
    </div>
    <div>
      <p>Game Type:</p>
      <p>{{ selectedContestGameType }}</p>
    </div>
    <div>
      <p>Slate:</p>
      <p>{{ selectedContestSlate }}</p>
    </div>
    <div>
      <p>Contest Privacy:</p>
      <p>{{ selectedContestPrivacy }}</p>
    </div>
    <div>
      <p>Contest Type:</p>
      <p>{{ selectedContestType }}</p>
    </div>
    <div v-if="selectedContestType !== '1v1'">
      <p>Contest Size:</p>
      <p>{{ contestSize }} Entrants</p>
    </div>
    <div v-if="selectedContestType === '1v1'">
      <p>Number of Contests:</p>
      <p>{{ numberOfContests }} Contests</p>
    </div>
    <div v-if="selectedContestType === '1v1'">
      <p>Total Entry Fees:</p>
      <p>{{ totFees }}</p>
    </div>
    <div>
      <p>Total Prizes:</p>
      <p>{{ totalPrizes }}</p>
    </div>
    <div v-if="selectedContestType !== '1v1'">
      <p>Entry Fees:</p>
      <p>{{ entryFee }}</p>
    </div>
    <div>
      <p>Entry Type:</p>
      <p>{{ entryType }}</p>
    </div>
    <div v-if="selectedContestType !== '1v1' && selectedContestType !== 'Multiplier'">
      <p>Prize Structure:</p>
      <p>{{ selectedPrizeStructure }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settings: {type: Object, default: null},
    totalPrizes: {type: String, default: null},
  },

  computed: {
    selectedContestSport() {
      if (!this.settings || !this.settings.sport) {
        return 'N/A';
      }

      return this.settings.sport;
    },

    selectedContestGameType() {
      if (!this.settings || !this.settings.rosterFormat) {
        return 'N/A';
      }

      return this.settings.rosterFormat.label;
    },

    selectedContestSlate() {
      if (!this.settings || !this.settings.gameSlate) {
        return 'N/A';
      }

      return this.settings.gameSlate.name;
    },

    selectedContestPrivacy() {
      if (!this.settings || !this.settings.privacy) {
        return 'N/A';
      }

      return this.settings.privacy.label;
    },

    selectedContestType() {
      if (!this.settings || !this.settings.contestType) {
        return 'N/A';
      }

      return this.settings.contestType.label;
    },

    contestSize() {
      if (!this.settings || !this.settings.details.contestSize) {
        return 'N/A';
      }

      return this.settings.details.contestSize;
    },

    numberOfContests() {
      let totContests = 0;
      for (const numContest of this.settings.details.fees) {
        totContests = totContests + numContest.contestCount;
      }

      return totContests;
    },

    selectedPrizeStructure() {
      if (!this.settings || !this.settings.details.prizeStructure) {
        return 'N/A';
      }

      return this.settings.details.prizeStructure.label;
    },

    entryFee() {
      if (!this.settings || !this.settings.details.entryFee) {
        return 'N/A';
      }

      return (this.settings.details.entryFee / 100).formatMoneyDecimals().removeCentsIfZero();
    },

    entryType() {
      if (this.settings.details.isRookie) {
        return 'Rookie';
      } else {
        return 'All';
      }
    },

    totFees() {
      if (!this.settings || !this.settings.details || !this.settings.details.fees) {
        return 'N/A';
      }

      let totFees = 0;
      for (const feeField of this.settings.details.fees) {
        const fee = feeField.entryFee * feeField.contestCount;
        totFees += fee;
      }

      return this.dollarAmount(totFees);
    },
  },

  methods: {
    dollarAmount(fee) {
      if (!fee) {
        return '$0';
      }
      const dollarAmount = fee / 100;
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    width: 400px;
    align-items: center;
    margin: 20px 0 0 150px;
  }

  p {
    margin: 0;
    flex: 1;
  }
}
</style>