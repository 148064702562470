/**
 * Tells whether a contest is multi-create and currently has no entries
 * @param {Object} contest The contest to be entered
 * @return {Boolean} whether or not the contest is multi-create.
 */
function isMultiCreate(contest) {
  if (!contest) {
    return false;
  }

  return multiCreateData(contest) && multiCreateData(contest).contestCount > 1;
}

/**
 * The multi-create data inside of a contest
 * @param {Object} contest The contest to be entered
 * @return {Object} the contest's multi-create data
 */
function multiCreateData(contest) {
  if (!contest || !contest.userCreatedContestsData) {
    return {};
  }

  return contest.userCreatedContestsData;
}

/**
 * Total fees for multi create contest with null checking
 * @param {Object} contest The contest to be entered
 * @return {Number} the contest's fees in cents
 */
function multiCreateFees(contest) {
  if (!contest || !isMultiCreate(contest)) {
    return null;
  }

  return multiCreateData(contest).totalFees / 100;
}

/**
 * Total prizes for multi create contest with null checking
 * @param {Object} contest The contest to be entered
 * @return {Number} the contest's prizes in cents
 */
function multiCreatePrizes(contest) {
  if (!contest || !isMultiCreate(contest)) {
    return null;
  }

  return multiCreateData(contest).totalPrizePool / 100;
}

export default {isMultiCreate, multiCreateData, multiCreateFees, multiCreatePrizes};