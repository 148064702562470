<template>
  <div :class="{fadedGame: !game.inRange, benchedGame: showBenched()}">
    <div>
      <span>{{ game.home === 'true' ? 'vs' : '@' }}</span>
      <img class="teamLogo" :src="getTeamLogo(game.opp_alias)" :alt="game.opp_alias"
        style="margin-right: 3px;"
      >
      <span v-if="game.result == null">{{ parseInt(game.timestamp) | moment("h:mma") }}</span>
      <span v-else>{{ game.score }}</span>
    </div>

    <!-- Game Projection -->
    <div v-if="game.ProjPoints != null" style="margin-top: 5px; display: flex; justify-content: center;">
      <PlayerStatus v-if="game.status" :status="game.status" style="display: inline-block; margin-right: 5px; margin-top: 1px;" />
      <div>Proj {{ game.ProjPoints.total }}</div>
    </div>

    <div v-if="gameHourAfterStart() && !game.played" style="margin-top: 5px;">DNP</div>

    <!-- Game Result Points -->
    <div v-else-if="game.ObPoints != null" style="margin-top: 5px;">
      <template v-if="gameWasPlayed()">
        <span v-if="game.ObPoints.total > -1" style="color: var(--obcolor-ob-blue);">+{{ game.ObPoints.total }} PTS</span>
        <span v-else style="color: red;">{{ game.ObPoints.total }} PTS</span>
      </template>
      <template v-else>
        <span v-if="game.ObPoints.total > -1">+{{ game.ObPoints.total }} PTS</span>
        <span v-else>{{ game.ObPoints.total }} PTS</span>
      </template>
    </div>

    <div>
      <div v-if="showBenched()" style="margin-top: 2px;">
        <span style="color: red; font-weight: bold;">Benched</span>
      </div>

      <!-- Activate / Deact Switch -->
      <div v-if="!player.dropped && !gameHasStarted() && isMyTeam"
        class="ob-btn-grey deactivateSliderContainer"
        :class="gameDeactivated() ? 'deactivatedGame' : ''"
        @click.stop="deactivateGame()"
      >
        <div><i v-if="processing" class="loadingSlider fad fa-spinner-third fa-spin"></i></div>
        <div class="deactivateSlider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ObLeagueApi from '@/api/ObLeagueApi';
import EventBus from '@/event-bus';
import PlayerStatus from '@/components/PlayerStatus.vue';

export default {

  props: {
    leagueId: String,
    sport: String,
    player: Object,
    game: Object,
    playedGames: Object,
    isMyTeam: Boolean,
  },

  components: {
    PlayerStatus,
  },

  data: function() {
    return {
      processing: false,
    };
  },

  methods: {
    showBenched() {
      return (!this.isMyTeam || this.gameHasStarted()) && this.gameDeactivated();
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    deactivateGame() {
      this.processing = true;
      const game = this.game;
      const player = this.player;

      const isActive = !this.gameDeactivated();
      ObLeagueApi.deactivatePlayer(this.leagueId, !isActive, player.draftPos, player.id, game.id)
          .then((data) => {
            if (data != null && data === 'success') {
              this.$set(player.deactGames, game.id, isActive ? 0 : null);
            }
            this.processing = false;
            EventBus.$emit('PLAYER_DEACTIVATED');
          })
          .catch((_error) =>{
            this.processing = false;
          });
    },

    gameDeactivated() {
      return this.player.deactGames[this.game.id] != null;
    },

    gameWasPlayed() {
      const gameKey = this.player.id + '_' + this.game.id;
      return this.playedGames[gameKey];
    },

    gameHasStarted() {
      const now = new Date().getTime();
      const gTime = parseInt(this.game.timestamp);
      return now > gTime;
    },

    gameHourAfterStart() {
      const now = new Date().getTime();
      const gTime = parseInt(this.game.timestamp);
      return now > (gTime + (3600000 * 6));
    },

    gameIsActive() {
      if (!this.game.inRange) {
        return false;
      }
      if (this.game.result == null) {
        // Game hasnt finished yet
        return true;
      }
      return this.game.played;
    },

  },

};
</script>

<style lang="scss" scoped>
.fadedGame {
  // color: var(--obcolor-font-secondary) !important;
  opacity: 0.3;
}

.benchedGame {
  color: red;
}

.teamLogo {
  height: 22px;
  margin-bottom: -6px;
  margin-left: 2px;
  margin-right: 2px;
}

.deactivatedGame {
  background: red;
}
.deactivatedGame:hover {
  background: #db0000 !important;
}

.deactivateSliderContainer {
  padding: 3px;
  width:40px;
  height: 12px;
  margin-top: 3px;
  position: relative;
}
.deactivateSlider {
  position: relative;
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  border-radius: 3px;
  left: 50%;
  background: var(--obcolor-background-page);
  transition: left 0.3s;
}
.deactivatedGame .deactivateSlider {
  left: 0;
}

.loadingSlider {
  box-sizing: border-box;
  position: absolute;
  margin-left: -38px;
  color: var(--obcolor-font-primary);
}

</style>
