<template>
  <div>
    <div class="apkHeader">
      <img :src="require('@/assets/obicon_white.png')" alt="OwnersBox Logo">
    </div>
    <div style="overflow-y: auto; display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
      <div class="apkBody">
        <img class="bigLogo" :src="require('@/assets/logo_' + (theme == 'light' ? 'black' : 'white') + '.png')" alt="OwnersBox Logo">
        <div class="bodyHeader">Why am I downloading the app here instead of the Play Store?</div>
        <div class="bodyText">
          Paid fantasy sports are currently unavailable on the Google Play store in Canada.
          If this changes, we will be sure to notify all of our valued Canadian customers.
        </div>
        <div class="bodyHeader">Can I trust this APK?</div>
        <div class="bodyText">
          Absolutely! This is the same application available to users on the iOS and US Play Store.
        </div>
        <a :href="apkLink" style="margin-bottom: 10px; margin-top: 5px; text-align: center;" @click="trackAPKDownload()">
          <img class="downloadLink" style="height: 40px;" :src="require('@/assets/mobileandroidapk/androidButton.png')"
            alt="Android Link"
          >
          <div class="ob-hyperlink" style="text-decoration: none; font-size: 16px; font-weight: bold; margin-top: 5px; margin-bottom: 10px;">
            Download Here
          </div>
        </a>
      </div>
      <div class="pageSeparator">How to install the APK?</div>
      <div class="apkBody" style="margin-top: 0;">
        <div class="flickityBox">
          <div class="flickityContainer">
            <div class="nextPrevContainer" @click="flickityPrev()">
              <i :class="{ disabledNextPrev: flickityScrollValues.scrolledLeft }" class="fas fa-chevron-left flickityNextPrev"></i>
            </div>
            <flickity class="apkFlickity" ref="flickity" :options="flickityOptions">
              <div class="flickityStep">
                <div class="stepPhone">
                  <img :src="require('@/assets/mobileandroidapk/phoneStep1.png')" alt="Click on Download" style="position: absolute; top: -25px;">
                </div>
                <div class="stepBody">
                  <div class="stepTitle" style="position: absolute;">STEP ONE</div>
                  <div class="stepText">
                    <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px;">DOWNLOAD APK</div>
                    <div>
                      Look for the ‘Get it now Android’
                      button to start the APK download.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flickityStep">
                <div class="stepPhone">
                  <img :src="require('@/assets/mobileandroidapk/phoneStep2.png')" alt="Click on OwnersBox" style="position: absolute;">
                </div>
                <div class="stepBody">
                  <div class="stepTitle" style="position: absolute;">STEP TWO</div>
                  <div class="stepText">
                    <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px;">OPEN APK</div>
                    <div>
                      Tap the notification to open the APK for installation.
                      You might need to navigate to your settings and turn on ‘Trust Unknown Sources’.
                      This is done for any APK not downloaded directly from the Play Store.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flickityStep">
                <div class="stepPhone">
                  <img :src="require('@/assets/mobileandroidapk/phoneStep3.png')" alt="Install OwnersBox" style="position: absolute;">
                </div>
                <div class="stepBody">
                  <div class="stepTitle" style="position: absolute;">STEP THREE</div>
                  <div class="stepText">
                    <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px;">INSTALL APK</div>
                    <div>
                      Tap on Install to complete the installation of OwnersBox.
                      Now you can begin your Weekly Fantasy Career on your mobile device!
                    </div>
                  </div>
                </div>
              </div>
            </flickity>
            <div class="nextPrevContainer" @click="flickityNext()">
              <i :class="{ disabledNextPrev: flickityScrollValues.scrolledRight }" class="fas fa-chevron-right flickityNextPrev"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="apkBody">
        <div class="bodyHeader" style="margin: 0; margin-bottom: 12px;">Get the OwnersBox App below</div>
        <a class="downloadLink" :href="apkLink" style="text-align: center;"
          @click="trackAPKDownload()"
        >
          <img style="height: 40px;" :src="require('@/assets/mobileandroidapk/androidButton.png')" alt="Android Link">
          <div class="ob-hyperlink" style="text-decoration: none; font-size: 16px; font-weight: bold; margin-top: 5px; margin-bottom: 10px;">
            Download Here
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Flickity from 'vue-flickity';

export default {
  components: {
    Flickity,
  },

  computed: {
    ...mapState(['theme']),
  },

  mounted() {
    this.rerenderFlickity();
  },

  data() {
    return {
      flickityOptions: {
        pageDots: false,
        cellAlign: 'left',
        selectedAttraction: 0.02,
        friction: 0.3,
        contain: true,
        prevNextButtons: false,
        draggable: false,
      },

      flickityScrollValues: {
        scrolledLeft: true,
        scrolledRight: false,
      },

      apkLink: 'https://storage.googleapis.com/ownersbox-files/mobileapp/OwnersBox_latest.apk',
    };
  },

  methods: {
    rerenderFlickity() {
      this.$nextTick(() => {
        this.$refs.flickity.rerender();
      });
    },

    flickityNext() {
      this.$refs.flickity.next();

      this.$nextTick(() => {
        this.setScrollVals();
      });
    },

    flickityPrev() {
      this.$refs.flickity.previous();

      this.$nextTick(() => {
        this.setScrollVals();
      });
    },

    setScrollVals() {
      if (!this.$refs.flickity) {
        return;
      }

      const flickityData = this.$refs.flickity.data('flickity');
      const index = flickityData.selectedIndex;
      this.flickityScrollValues.scrolledLeft = index == 0;

      // If the selected cell's position is close to the width of the container, we have scrolled all the way to the right
      this.flickityScrollValues.scrolledRight = (flickityData.selectedIndex == flickityData.cells.length - 1);
    },

    trackAPKDownload() {
      this.$Analytics.trackAPKDownload();
    },
  },
};
</script>

<style lang="scss" scoped>
.apkHeader {
  width: 100%;
  box-sizing: border-box;
  height: 27px;
  border-radius: 0 0 30px 30px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background: var(--obcolor-background-5);

  > img {
    height: 30px;
    padding: 8px 5px;
    border-radius: 0 0 10px 10px;
    background: black;
  }
}

.apkHeader, .apkHeader > img {
  border-bottom: 3px solid var(--obcolor-background-2);
  border-left: 3px solid var(--obcolor-background-2);
  border-right: 3px solid var(--obcolor-background-2);
}

.apkBody {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;

  > div, > img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > img {
    max-width: 300px;
  }
}

.bigLogo {
  width: 100%;
}

.bodyHeader {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
}

.bodyText {
  font-size: 14px;
  text-align: center;
}

.bodyText.greyText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
}

.pageSeparator {
  width: 100%;
  box-sizing: border-box;
  background: var(--obcolor-background-4);
  border-top: 3px solid var(--obcolor-background-2);
  border-bottom: 3px solid var(--obcolor-background-2);
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.flickityBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
  box-sizing: border-box;
}

.flickityContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
}

.nextPrevContainer {
  height: 150px;
  display: flex;
  position: absolute;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--obcolor-font-secondary);
  }
}

.nextPrevContainer:first-child {
  left: 0px;
  padding-left: 10px;
}

.nextPrevContainer:last-child {
  right: 0px;
  padding-right: 10px;
}

.nextPrevContainer:first-child .flickityNextPrev {
  margin-right: 20px;
}

.nextPrevContainer:last-child .flickityNextPrev {
  margin-left: 20px;
}

.flickityNextPrev {
  font-size: 30px;
  z-index: 2;
  cursor: pointer;

  &.disabledNextPrev {
    color: var(--obcolor-text-faded);
    cursor: not-allowed;
  }
}

.apkFlickity {
  height: 300px;
  width: 100%;
}

.flickityStep {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepPhone {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 130px;
}

.stepBody {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.stepTitle {
  padding: 5px 10px;
  background: var(--obcolor-background-4);
  border: 3px solid var(--obcolor-background-2);
  border-radius: 10px;
  font-weight: bold;
}

.stepText {
  margin-top: 17px;
  border-radius: 10px;
  width: 100%;
  height: 130px;
  background: var(--obcolor-background-3);
  border: 3px solid var(--obcolor-background-2);
  padding: 20px 10px 0 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
