// Live Summary Stats
// Controls the top overall summary for live page

// Live summary
export const LIVE_NBA_PLAYER = [
  {
    label: 'Stats',
    stats: [
      {label: 'MIN', path: 'player.MIN', tooltip: 'Minutes'},
      {label: 'PTS', path: 'player.PTS', tooltip: 'Points', thresholds: [
        {value: 45, heat: 3}, {value: 35, heat: 2}, {value: 25, heat: 1}, {value: 15, heat: 0}, {value: 10, heat: -1}, {value: 5, heat: -2}, {value: 0, heat: -3},
      ]},
      {label: 'AST', path: 'player.AST', tooltip: 'Assists', thresholds: [
        {value: 8, heat: 3}, {value: 7, heat: 2}, {value: 6, heat: 1}, {value: 5, heat: 0}, {value: 4, heat: -1}, {value: 3, heat: -2}, {value: 2, heat: -3},
      ]},
      {label: 'REB', path: 'player.REB', tooltip: 'Rebounds', thresholds: [
        {value: 8, heat: 3}, {value: 7, heat: 2}, {value: 6, heat: 1}, {value: 5, heat: 0}, {value: 4, heat: -1}, {value: 3, heat: -2}, {value: 2, heat: -3},
      ]},
      {label: 'BLK', path: 'player.BLK', tooltip: 'Blocks', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'STL', path: 'player.STL', tooltip: 'Steals', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'TO', path: 'player.TO', tooltip: 'Turnovers', thresholds: [
        {value: 0, heat: 1}, {value: 1, heat: 0}, {value: 2, heat: -1}, {value: 3, heat: -2}, {value: 4, heat: -3},
      ]},
    ],
  },
];