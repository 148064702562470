<template>
  <div class="ob-page">
    <div v-if="!tokenIsGenerated">
      <h1>Get your ID and phone ready!</h1>
      <div class="title">We need to take extra steps to verify your identity and ensure account security by having you scan your identification. Here’s what you’ll need to do next:</div>

      <div class="steps">
        <div>•<span>Get out your government issued identification</span></div>
        <div>•<span>You will be required to take an image of the front and back of this ID</span></div>
        <div>•<span>Take a selfie</span></div>
      </div>

      <div class="titleBold">Valid ID types:</div>
      <div v-for="(type, index) in idTypes" :key="index" class="typesOfId">
        <i :class="type.icon"></i>
        <div>{{ type.text }}</div>
      </div>
      <button v-if="waitingForResponse" class="ob-btn" :disabled="waitingForResponse">Validating...</button>
      <button v-else class="ob-btn" @click="createToken()">Scan ID</button>
    </div>

    <div v-else class="qrCodeScreen">
      <img src="@/assets/verification/qrCode.png" class="qrImage" alt="qr code">
      <div class="qrTitle">Scan QR Code</div>
      <div>Scan this QR Code with your mobile phone's camera to complete the ID Scan process</div>
      <qrcode-vue :value="qrCodeLink" :size="200" level="H"
        class="qrCode"
      />
    </div>
  </div>
</template>

<script>
import ObPaymentApi from '@/api/ObPaymentApi';
import QrcodeVue from 'qrcode.vue';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';

export default {
  components: {
    QrcodeVue,
  },

  data() {
    return {
      waitingForResponse: false,
      qrCodeLink: null,
      tokenIsGenerated: false,
      tokenId: null,
      tokenStatus: null,
      statusCheckInterval: null,
      statusCheckIntervalTime: 10000,
      idTypes: [
        {
          icon: 'fal fa-id-card',
          text: ' Driver’s license',
        },
        {
          icon: 'fal fa-passport',
          text: 'Passport',
        },
        {
          icon: 'fal fa-id-badge',
          text: 'Government issued ID',
        },
      ],
    };
  },

  computed: {
    ...mapState(['userId']),
  },

  created() {
    this.getVerifyState();
  },

  destroyed() {
    this.clearStatusCheckInterval();
  },

  methods: {
    getVerifyState() {
      ObPaymentApi.getVerifyState()
          .then((response) => {
            this.verifyStatus = response.state;
            if (this.verifyStatus === 'IDPV_VERIFY') {
              return;
            } else {
              this.$router.push('/verifyaccount');
            }
          }).catch((_error) => {
            // Do nothing
          });
    },

    createToken() {
      this.waitingForResponse = true;
      this.tokenIsGenerated = false;
      ObPaymentApi.createToken()
          .then((response) => {
            this.qrCodeLink = response.tokenData.hostedFormLink;
            this.tokenId = response.tokenData.token;
            this.waitingForResponse = false;
            this.tokenIsGenerated = true;

            // Check token status every 20s
            this.statusCheckInterval = setInterval(() => {
              this.createTokenStatus();
            }, this.statusCheckIntervalTime);
          }).catch((error) => {
            this.waitingForResponse = false;
            if (error?.response?.data?.errorMessage) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'An unexpected error has occurred. Please try again or contact support for assistance';
            }
          });
    },

    createTokenStatus() {
      ObPaymentApi.createTokenStatus(this.tokenId)
          .then((response) => {
            this.tokenStatus = response.tokenStatus;

            if (this.tokenStatus === 'complete' || this.tokenStatus === 'failed') {
              this.clearStatusCheckInterval();

              // Set verifyAccount to true if tokenStatus complete
              const isVerified = this.tokenStatus === 'complete';
              this.$Analytics.trackVerify(this.userId, isVerified);
              EventBus.$emit('UPDATE_ACCOUNT_INFO');
              this.$router.push('/verifyaccount');
            }
          }).catch((error) => {
            this.clearStatusCheckInterval();
            this.waitingForResponse = false;
            if (error?.response?.data?.errorMessage) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'An unexpected error has occurred. Please try again or contact support for assistance';
            }
          });
    },

    clearStatusCheckInterval() {
      if (this.statusCheckInterval) {
        clearInterval(this.statusCheckInterval);
        this.statusCheckInterval = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-page {
  font-family: 'Archivo', sans-serif;
  box-sizing: border-box;
  line-height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 705px;
  margin: 0 auto;
}

.title {
  padding: 16px 0 24px 0;
}

.titleBold {
  padding: 24px 0 8px 0;
  font-weight: 800;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;

  span {
    padding-left: 10px;
  }
}

.typesOfId {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;

  i {
    font-size: 18px;
    width: 20px;
    color: var(--obcolor-ob-blue);
    text-align: center;
  }

  .fa-id-badge {
    font-size: 20px;
  }
}

button {
  margin-top: 32px;
  height: 60px;
  width: 100%;
}

.ob-btn[disabled] {
  background: var(--obcolor-font-secondary);
  color: var(--obcolor-background-2) !important;
  cursor: not-allowed;
}

.qrCodeScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.qrTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding-top: 25px;
}

.qrImage {
  width: 80px;
}

.qrCode {
  margin-top: 50px;
  display: flex;
  border: 3px solid var(--obcolor-font-always-white);
}
</style>