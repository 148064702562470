<template>
  <div class="twoColumns">
    <div class="leftCol">
      <div class="ob-box pendingSwapsTabs">
        <div style="padding: 4px 15px; font-weight: bold;">Lineup Editor</div>
        <div v-for="(swap, index) in pendingSwaps" :key="index" class="swapTab"
          :class="{selectedSwapTab: index == selectedSwap}" @click="selectedSwap = index"
        >
          <div>
            <div>
              <i class="fa fa-minus" style="margin-right: 6px;"></i>
              <span :style="!swap.drop ? 'color: var(--obcolor-font-secondary);' : ''">{{ swap.drop ? swap.drop.name.lastName() : 'TBD' }}</span>
            </div>
            <div>
              <i class="fa fa-plus" style="margin-right: 6px; margin-top: 2px;"></i>
              <span :style="!swap.add ? 'color: var(--obcolor-font-secondary);' : ''">{{ swap.add ? swap.add.name.lastName() : 'TBD' }}</span>
            </div>
          </div>
        </div>
        <div class="swapTab" @click="selectedSwap = -1" :class="{selectedSwapTab: !selectedSwapEntry}">
          <i class="fa fa-plus" style="margin-right: 6px; margin-top: 2px; color: var(--obcolor-ob-blue);"></i>
          <div>
            <div>New</div>
            <div>Transaction</div>
          </div>
        </div>
      </div>

      <div class="ob-box" style="height: 80px; margin-top: 10px;">
        <LineupEditorToAddDrop v-if="selectedToDrop" :player="selectedToDrop" :contest="contest"
          :statusMap="statusMap" :status="getPlayerStatus(selectedToDrop)" type="drop"
        />
        <div v-else class="emptyText disabledText">Choose a player from your team on the right to drop</div>
      </div>

      <div class="ob-box" style="height: 80px; margin-top: 10px;">
        <LineupEditorToAddDrop v-if="selectedToAdd" :player="selectedToAdd" :contest="contest"
          :statusMap="statusMap" :status="getPlayerStatus(selectedToAdd)" type="add"
        />
        <div v-else class="emptyText disabledText">Choose a player from the list below to add</div>
      </div>

      <LineupEditorPlayerList class="ob-box playerListContainer" v-if="loaded"
        style="flex: 1; margin-top: 10px;"
        :playerList="playerList" :contest="contest"
        :toAdd="toAdd" :toDrop="toDrop" :picks="picks"
        :salaryRem="rosterCalc.salaryRemaining"
        :totSpent="rosterCalc.salarySpent" :statusMap="statusMap"
        :pickedPlayerIds="pickedPlayerIds"
        :droppedPlayerIds="droppedPlayerIds"
        :pendingSwaps="pendingSwaps" :pendingAdds="pendingAdds"
        :filterPos="selectedPosition"
      />
      <div class="ob-box playerListContainer" v-else><ObLoading /></div>
    </div>

    <div class="rightCol">
      <div class="ob-box rosterContainer">
        <LineupEditorSalary :contest="contest" :roster="leagueRoster" :pendingSwaps="pendingSwaps"
          :rosterCalc="rosterCalc" :pendingDrops="pendingDrops" :picks="picks"
          :customNames="customNames" :statusMap="statusMap"
        />

        <div class="rosterBtnList">
          <div class="rosterBtn" :class="{ selectedBtn: rosterMode == 'proj' }" @click="selectRosterMode('proj')">
            Projected Team
          </div>
          <div class="rosterBtn" :class="{ selectedBtn: rosterMode == 'current' }" @click="selectRosterMode('current')">
            Current Team
          </div>
        </div>

        <LineupEditorRoster v-if="leagueRoster && picks"
          :contest="contest" :picks="picks"
          :statusMap="statusMap" :toAdd="toAdd" :toDrop="toDrop"
          :rosterMode="rosterMode"
          :pendingDrops="pendingDrops"
          :pendingSwaps="pendingSwaps"
        />
        <div v-else style="flex: 1;">
          <ObLoading />
        </div>

        <div class="cancelSection">
          <div @click="backToLineup()" class="ob-btn-grey" style="border-radius: 0; width: 80px;">Cancel</div>
        </div>
      </div>
    </div>

    <LineupEditorSuccessModal />
    <LineupEditorFailModal />
    <LineupEditorCalendarModal />
    <LineupEditorPreviewModal />
  </div>
</template>

<script>
import LineupEditorPlayerList from '@/views/SalaryContest/LineupEditor/LineupEditorPlayerList';
import LineupEditorRoster from '@/views/SalaryContest/LineupEditor/LineupEditorRoster';
import LineupEditorToAddDrop from '@/views/SalaryContest/LineupEditor/LineupEditorToAddDrop';
import LineupEditorFailModal from '@/views/SalaryContest/LineupEditor/LineupEditorFailModal';
import LineupEditorSuccessModal from '@/views/SalaryContest/LineupEditor/LineupEditorSuccessModal';
import LineupEditorSalary from './LineupEditorSalary';
import LineupEditorCalendarModal from './CalendarModal/LineupEditorCalendarModal';
import LineupEditorPreviewModal from './PreviewModal/LineupEditorPreviewModal';
import ObLoading from '@/components/ObLoading';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObPlayersApi from '@/api/ObPlayersApi';
import EventBus from '@/event-bus';

export default {
  components: {
    LineupEditorPlayerList,
    LineupEditorRoster,
    LineupEditorToAddDrop,
    LineupEditorSuccessModal,
    LineupEditorFailModal,
    LineupEditorPreviewModal,
    LineupEditorCalendarModal,
    LineupEditorSalary,
    ObLoading,
  },

  props: {
    contest: Object,
    rosterCalc: Object,
    leagueRoster: Object,
    customNames: Object,
  },

  data() {
    return {
      playerList: null,
      toAdd: null,
      toDrop: null,
      picks: null,
      processing: false,
      rosterMode: 'proj',

      statusMap: null,
      loaded: false,

      // Multi swap
      subbedRoom: null,
      pendingSwaps: [],
      selectedSwap: 1,
    };
  },

  computed: {
    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    pickedPlayerIds() {
      if (!this.leagueRoster) {
        return {};
      }
      // Dropped players are not included here, we show them in the player list
      // But don't allow the user to add them
      const pickedPlayerIds = {};
      if (this.leagueRoster.players) {
        for (const player of this.leagueRoster.players) {
          pickedPlayerIds[player.id] = true;
        }
      }
      return pickedPlayerIds;
    },

    droppedPlayerIds() {
      if (!this.leagueRoster) {
        return {};
      }
      const playerIds = {};
      if (this.leagueRoster.dropped) {
        for (const player of this.leagueRoster.dropped) {
          playerIds[player.id] = true;
        }
      }
      return playerIds;
    },

    // Multi-Swap
    pendingDrops() {
      const drops = {};
      let index = 0;
      for (const swap of this.pendingSwaps) {
        if (swap.drop) {
          drops[swap.drop.id] = index;
        }
        index++;
      }
      return drops;
    },

    pendingAdds() {
      const adds = {};
      let index = 0;
      for (const swap of this.pendingSwaps) {
        if (swap.add) {
          adds[swap.add.id] = index;
        }
        index++;
      }
      return adds;
    },

    selectedSwapEntry() {
      return this.pendingSwaps[this.selectedSwap];
    },

    selectedPosition() {
      const selectedSwap = this.selectedSwapEntry;
      if (selectedSwap && selectedSwap.drop) {
        return selectedSwap.pos;
      }
      return null;
    },

    selectedToDrop() {
      const selectedSwap = this.selectedSwapEntry;
      if (selectedSwap) {
        return selectedSwap.drop;
      }
      return null;
    },

    selectedToAdd() {
      const selectedSwap = this.selectedSwapEntry;
      if (selectedSwap) {
        return selectedSwap.add;
      }
      return null;
    },
  },

  created() {
    this.loadContestData();
    EventBus.$on('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$on('LINEUP_EDITOR_SELECT_TO_DROP', this.selectToDrop);
    EventBus.$on('LINEUP_EDITOR_REMOVE_TO_DROP', this.removeToDrop);
    EventBus.$on('LINEUP_EDITOR_SELECT_TO_ADD', this.selectToAdd);
    EventBus.$on('LINEUP_EDITOR_REMOVE_CUR_ADD', this.removeCurAdd);
    EventBus.$on('LINEUP_EDITOR_REMOVE_CUR_DROP', this.removeCurDrop);
    EventBus.$on('LINEUP_EDITOR_CLEAR_SWAPS', this.clearSwaps);

    // On succesful swap, reload data
    EventBus.$on('SALARY_CAP_RELOAD', this.reloadContestData);
    EventBus.$on('LINEUP_EDITOR_FAILED_SWAP', this.refreshAddedPlayers);
  },

  destroyed() {
    if (this.subbedRoom) {
      this.$SocketController.unsubscribeFromRoom(this.subbedRoom);
    }
    EventBus.$off('SOCKET_BROADCAST', this.handleSocketUpdate);
    EventBus.$off('LINEUP_EDITOR_SELECT_TO_DROP', this.selectToDrop);
    EventBus.$off('LINEUP_EDITOR_REMOVE_TO_DROP', this.removeToDrop);
    EventBus.$off('LINEUP_EDITOR_SELECT_TO_ADD', this.selectToAdd);
    EventBus.$off('LINEUP_EDITOR_REMOVE_CUR_ADD', this.removeCurAdd);
    EventBus.$off('LINEUP_EDITOR_REMOVE_CUR_DROP', this.removeCurDrop);
    EventBus.$off('LINEUP_EDITOR_CLEAR_SWAPS', this.clearSwaps);
    EventBus.$off('SALARY_CAP_RELOAD', this.reloadContestData);
    EventBus.$off('LINEUP_EDITOR_FAILED_SWAP', this.refreshAddedPlayers);
  },

  methods: {

    handleSocketUpdate(data) {
      if (!data.json || !data.type) {
        return;
      }
      if (data.type !== 'FSP_SC_PLAYERLIST') {
        return;
      }
      if (data.id !== this.contest.id) {
        return;
      }

      // Update player list, refresh data in pending swaps
      this.playerList = data.json;
      this.resetPendingSwaps();

      // Recalculate salaries, in case projections have changed
      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    resetPendingSwaps() {
      // Used when a player list update occurs, update player data in pending swaps
      for (const entry of this.pendingSwaps) {
        if (entry.add) {
          const playerId = entry.add.id;
          const player = this.playerList.find((player) => player.id == playerId);
          if (player) {
            entry.add = player;
          }
        }
      }
    },

    reloadContestData() {
      // This occurs after a successful swap
      this.loadContestData();
    },

    loadContestData() {
      if (!this.contest || !this.contest.id) {
        return;
      }

      const contestId = this.contest.id;
      const teamId = this.leagueRoster.id + '_' + this.leagueRoster.teamNum;

      ObSalaryCapApi.getSwapData(contestId, teamId).then((response) => {
        this.playerList = response.players;
        this.picks = response.myPlayers;
        this.loaded = true;

        this.subbedRoom = 'SC_PLAYERS_' + contestId;
        this.$SocketController.subscribeToRoom(this.subbedRoom);

        this.loadStatuses();

        // Setup initial to-drop player, if set in route params
        if (this.$route.params && this.$route.params.swapPlayerId) {
          const swapPlayerId = this.$route.params.swapPlayerId;
          const player = this.getPlayerFromMyPlayers(swapPlayerId);
          if (player) {
            // Remove id from route to prevent issues when reloading
            this.$router.push({params: {swapPlayerId: null}});
            // use nextTick to ensure position filter gets set
            this.$nextTick(() => {
              const newSwap = {drop: player, add: null, pos: player.draftPos};
              this.pendingSwaps.push(newSwap);
              this.selectedSwap = this.pendingSwaps.length - 1;
              EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
            });
          }
        }
      });
    },

    loadStatuses() {
      ObPlayersApi.getGlobalStatusMap(this.contest.sport)
          .then((data) => {
            this.statusMap = data;
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    clearSwaps() {
      // Use splice instead of replacing the array, so vue reacts to the change
      this.pendingSwaps.splice(0, this.pendingSwaps.length);
      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    selectToAdd(playerId) {
      const player = this.playerList.find((player) => player.id == playerId);

      // Multi-swap
      // When adding a player, always modify the currently selected transaction
      // Unless, player is the added player of another transaction?
      const curSwap = this.selectedSwapEntry;
      if (this.pendingAdds[playerId] != null) {
        const swapIndex = this.pendingAdds[playerId];
        const swap = this.pendingSwaps[swapIndex];
        if (swap.drop) {
          swap.add = null;
          this.selectedSwap = swapIndex;
        } else {
          this.pendingSwaps.splice(swapIndex, 1);
        }
      } else if (curSwap && (this.positionMatches(curSwap.pos, player.draftPos) || !curSwap.drop)) {
        // Replace player in cur selected swap, if position matches, or a player to drop has not been selected
        curSwap.add = player;
        this.refreshPlayerData(player);
      } else {
        const newSwap = {drop: null, add: player, pos: player.draftPos};
        this.pendingSwaps.push(newSwap);
        this.selectedSwap = this.pendingSwaps.length - 1;
        this.refreshPlayerData(player);
      }

      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    removeCurAdd() {
      const curSwap = this.selectedSwapEntry;
      if (curSwap && !curSwap.drop) {
        this.pendingSwaps.splice(this.selectedSwap, 1);
      } else if (curSwap) {
        curSwap.add = null;
      }
      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    removeCurDrop() {
      const curSwap = this.selectedSwapEntry;
      if (curSwap && !curSwap.add) {
        this.pendingSwaps.splice(this.selectedSwap, 1);
      } else if (curSwap) {
        curSwap.drop = null;
      }
      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    getPlayerFromMyPlayers(playerId) {
      for (const pos in this.picks) {
        for (const p of this.picks[pos]) {
          if (p.id === playerId) {
            // Override draftPos with roster slot
            // IE. This changes QB to SF if hes in the SuperFlex slot
            p.draftPos = pos;
            return p;
          }
        }
      }
      return null;
    },

    selectToDrop(playerId) {
      // To Drop, we get from my players data, not the player list
      const player = this.getPlayerFromMyPlayers(playerId);
      if (player == null) {
        return;
      }

      // Multi-swap
      const curSwap = this.selectedSwapEntry;
      if (this.pendingDrops[playerId] != null) {
        // Player already set to be dropped, remove him from the transaction
        // If the transaction has no player to add, remove the transaction
        const swapIndex = this.pendingDrops[playerId];
        const swap = this.pendingSwaps[swapIndex];

        if (swap.add) {
          swap.drop = null;
          this.selectedSwap = swapIndex;
        } else {
          this.pendingSwaps.splice(swapIndex, 1);
        }
      } else if (curSwap && !curSwap.drop && this.positionMatches(curSwap.pos, player.draftPos)) {
        // If player fits in the currently selected transaction, insert him as the drop player
        curSwap.drop = player;
        // Reset swap position, in case this is a Flex or SuperFlex player
        curSwap.pos = player.draftPos;
      } else {
        // If player fits in any of the existing transactions
        let swapIndex = 0;
        for (const swap of this.pendingSwaps) {
          if (this.positionMatches(swap.pos, player.draftPos) && !swap.drop) {
            // Reset swap position, in case this is a Flex or SuperFlex player
            swap.pos = player.draftPos;
            swap.drop = player;
            this.selectedSwap = swapIndex;
            EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
            return;
          }
          swapIndex++;
        }

        // No valid slots for this player, setup as a new swap
        const newSwap = {drop: player, add: null, pos: player.draftPos};
        this.pendingSwaps.push(newSwap);
        this.selectedSwap = this.pendingSwaps.length - 1;
      }

      EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
    },

    positionMatches(dropPos, addPos) {
      if (this.contest.isFlexContest && (this.positionMatchesFlex(dropPos, addPos) || this.positionMatchesUtility(dropPos, addPos))) {
        return true;
      }

      return dropPos == addPos;
    },

    positionMatchesFlex(dropPos, addPos) {
      // Check if position matches Flex position
      if (addPos == this.$SportInfo.flex() && this.$SportInfo.draftPosIsFlex(dropPos, this.contest.sport)) {
        return true;
      }
      if (dropPos == this.$SportInfo.flex() && this.$SportInfo.draftPosIsFlex(addPos, this.contest.sport)) {
        return true;
      }

      // Check if position matches SuperFlex or SuperFlex multiplier position
      if ((addPos == this.$SportInfo.superFlex() || addPos == this.$SportInfo.superFlexMultiplier()) && this.$SportInfo.draftPosIsSuperFlex(dropPos, this.contest.sport)) {
        return true;
      }
      return (dropPos == this.$SportInfo.superFlex() || dropPos == this.$SportInfo.superFlexMultiplier()) && this.$SportInfo.draftPosIsSuperFlex(addPos, this.contest.sport);
    },

    positionMatchesUtility(dropPos, addPos) {
      // Check if position matches Guard
      if (addPos == this.$SportInfo.guard() && this.$SportInfo.draftPosIsGuard(dropPos, this.contest.sport)) {
        return true;
      }
      if (dropPos == this.$SportInfo.guard() && this.$SportInfo.draftPosIsGuard(addPos, this.contest.sport)) {
        return true;
      }

      // Check if position matches Forward
      if (addPos == this.$SportInfo.forward() && this.$SportInfo.draftPosIsForward(dropPos, this.contest.sport)) {
        return true;
      }
      if (dropPos == this.$SportInfo.forward() && this.$SportInfo.draftPosIsForward(addPos, this.contest.sport)) {
        return true;
      }

      // Check if position matches Utility or Utility multiplier
      if ((addPos == this.$SportInfo.utility() || addPos == this.$SportInfo.utilityMultiplier()) && this.$SportInfo.draftPosIsUtility(dropPos, this.contest.sport)) {
        return true;
      }

      return (dropPos == this.$SportInfo.utility() || dropPos == this.$SportInfo.utilityMultiplier()) && this.$SportInfo.draftPosIsUtility(addPos, this.contest.sport)
    },

    selectRosterMode(mode) {
      this.rosterMode = mode;
    },

    // When a swap fails refresh all pending adds
    // There is a probably a better way to do this
    refreshAddedPlayers() {
      for (const entry of this.pendingSwaps) {
        if (entry.add) {
          this.refreshPlayerData(entry.add);
        }
      }
    },

    // When a player is selected to add, call the server to refresh the data for that player
    // The player list data may be out of date
    refreshPlayerData(player) {
      const playerId = player.id;
      ObSalaryCapApi.getPlayerForContest(this.contest.id, playerId)
          .then((data) => {
            // Update player list and data in pending swaps
            this.updatePlayerList(data);
          });
    },

    updatePlayerList(player) {
      const id = player.id;
      let pIndex = -1;

      for (let i=0; i<this.playerList.length; i++) {
        const p = this.playerList[i];
        if (p.id === id) {
          pIndex = i;
          break;
        }
      }

      if (pIndex !== -1) {
        // Update Player List Data
        this.$set(this.playerList, pIndex, player);

        // Update data in pending swaps
        for (const entry of this.pendingSwaps) {
          if (entry.add && entry.add.id === id) {
            entry.add = player;
          }
        }

        // Update Status
        if (this.statusMap[id]) {
          this.statusMap[id].status = player.status;
          this.statusMap[id].injuryDetails = player.injuryDetails;
        }

        // Recalculate salaries incase projections have changed
        EventBus.$emit('LINEUP_EDITOR_CALCULATE_SALARIES');
      }
    },

    getPlayerStatus(player) {
      if (player && this.statusMap) {
        return this.statusMap[player.id];
      }
      return null;
    },

    openSuccessModal() {
      const contestId = this.contest.id;
      const teamId = this.leagueRoster.id + '_' + this.leagueRoster.teamNum;
      EventBus.$emit('LINEUP_EDITOR_OPEN_SUCCESS_MODAL', contestId, teamId);
    },

    openFailModal(error) {
      EventBus.$emit('LINEUP_EDITOR_OPEN_FAIL_MODAL', error);
    },

    backToLineup() {
      const contestId = this.contest.id;
      const teamId = this.leagueRoster.id + '_' + this.leagueRoster.teamNum;
      if (this.contest.format == 'WEEKLY') {
        this.$router.push('/salarycontest/lineup/' + contestId + '/' + teamId);
      } else {
        this.$router.push('/salarycontest/leaderboard/' + contestId + '/' + teamId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.twoColumns {
  display: flex;
  height: 100%;
}

.leftCol {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rightCol {
  width: 407px;
  display: flex;
  flex-direction: column;
  margin-bottom: 47px;

  > .ob-box {
    margin-left: 10px;
  }

  > .ob-box:not(:last-child) {
    margin-bottom: 5px;
  }
}

.rosterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  overflow: hidden;
  transition: height 0.5s;
  padding: 0;
}

.rosterBtnList {
  display: flex;
  margin: -8px -8px 5px -8px;
  width: calc(100% + 16px);
}

.rosterBtn {
  padding: 8px 0;
  width: 50%;
  text-align: center;
  background: var(--obcolor-background-4);
  color: var(--obcolor-font-secondary);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.rosterBtn:not(.selectedBtn):hover {
  background: var(--obcolor-background-3);
  color: var(--obcolor-font-primary);
}

.rosterBtn.selectedBtn {
  background: var(--obcolor-background-2);
  color: var(--obcolor-font-primary);
}

.playerListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin-top: 10px;
}

.emptyText {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;

  &.disabledText {
    color: var(--obcolor-font-secondary);
  }
}

.cancelSection {
  min-height: 45px;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  background: var(--obcolor-background-3);
}

.fa-minus {
  color: var(--obcolor-red);
}
.fa-plus {
  color: var(--obcolor-green);
}

.pendingSwapsTabs {
  font-size: 12px;
  display: flex;
  padding: 0;
  align-items: center;
  min-height: 36px;
  flex-wrap: wrap;
  max-width: 1037px;
}
.swapTab {
  cursor: pointer;
  min-width: 80px;
  padding: 4px 10px;
  background: var(--obcolor-background-4);
  border-left: 2px solid var(--obcolor-background-page);
  display: flex;
  align-items: center;
  min-height: 30px;
  border-bottom: 2px solid #00000000;
}
.swapTab:last-child {
  border-right: 2px solid var(--obcolor-background-page);
}
.swapTab:hover {
  background: var(--obcolor-background-2);
}
.selectedSwapTab {
  background: var(--obcolor-background-2);
  border-bottom: 2px solid orange;
}
</style>
