<template>
  <div>
    <div class="tag" :class="{rotate}">
      <svg width="31" height="47" viewBox="0 0 31 47"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path id="Vector" d="M12.6076 0.97091L1.27002 10.0669C0.458557 10.7185 0 11.616 0 12.5535V43.5427C0 45.4518 1.86364 47 4.16296 47H26.837C29.1364 47 31 45.4518 31 43.5427V12.5535C31 11.616 30.5414 10.7185 29.73 10.0669L18.3924 0.97091C16.7793 -0.323637 14.2207 -0.323637 12.6076 0.97091Z" fill="#B6161B" />
        <circle cx="15.5" cy="7" r="3"
          fill="#E8EEFF" stroke="#1F1F1F" stroke-miterlimit="10"
        />
      </svg>
      <div class="content">
        <div class="percentage">
          {{ discountPercentage }}%
        </div>
        <div class="off">
          OFF
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    market: {
      type: Object,
      default: null,
    },
    pick: {
      type: Object,
      default: null,
    },
    rotate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    discountPercentage() {
      return this.market?.discount?.discountPercentage ?? this.pick?.discount?.discountPercentage;
    },
  },
};

</script>

<style scoped>
.tag {
  position: relative;
  width: min-content;
  text-align: left;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 700;
  text-align: center;

  top: 12px;
  bottom: 0;
  left: 0;
  right: 0;
}
.percentage{
  color: #fff;
}
.off {
  color: var(--obcolor-yellow300);
}

.rotate {
  &.tag {
    height: 31px;
    width: 47px;
  }
  svg {
    transform: rotate(-90deg) translate(-100%, 0);
    transform-origin: top left;
  }
  .content {
    top: 0;
    bottom: 0;
    right: 0;
    left: 12px;
  }
  .off {
    font-size: 8px;
  }
}
</style>