<template>
  <div>
    <div class="matchupContainer" v-for="(matchup, index) in draftJSON.matchups" :key="index">
      <div class="ob-box matchHeaderTeamBox">
        <UserLogo :id="matchup.team1Id" :sport="draftJSON.sport" :size="40"
          :rankColor="users[matchup.team1Id].rankColor"
          :rankNum="users[matchup.team1Id].rankNum" :showRank="true"
        />
        <div style="margin-left: 15px; font-size: 12px;">
          <div class="matchHeaderName" style="font-weight: bold;">{{ users[matchup.team1Id].team }}</div>
          <div class="matchHeaderName" style="color: var(--obcolor-font-secondary);">{{ users[matchup.team1Id].name }} ({{ users[matchup.team1Id].h2hRecord }})</div>
        </div>
      </div>
      <div class="matchupVs">
        <div>
          <div class="matchHeaderCoin">
            <i v-if="matchup.coinTossWinner == 'team1'" class="fas fa-coin" style="color: var(--obcolor-coin);"></i>
          </div>
          <div class="matchHeaderProj" :style="projectedWin(1, matchup) ? 'color: var(--obcolor-ob-blue)' : ''">{{ getPotentialProj(matchup.team1Id) }}</div>
        </div>
        <div class="matchHeaderRecord">
          <div style="font-size: 12px; margin-bottom: 6px; margin-top: -4px;">({{ matchup.team1H2H != null ? matchup.team1H2H : '#' }}-{{ matchup.team2H2H != null ? matchup.team2H2H : '#' }})</div>
          <div style="font-weight: bold;">vs</div>
        </div>
        <div>
          <div class="matchHeaderCoin">
            <i v-if="matchup.coinTossWinner == 'team2'" class="fas fa-coin" style="color: var(--obcolor-coin);"></i>
          </div>
          <div class="matchHeaderProj" :style="projectedWin(2, matchup) ? 'color: var(--obcolor-ob-blue)' : ''">{{ getPotentialProj(matchup.team2Id) }}</div>
        </div>
      </div>
      <div class="ob-box matchHeaderTeamBox">
        <div style="margin-right: 10px; font-size: 12px;">
          <div class="matchHeaderName" style="font-weight: bold;">{{ users[matchup.team2Id].team }}</div>
          <div class="matchHeaderName" style="color: var(--obcolor-font-secondary);">{{ users[matchup.team2Id].name }} ({{ users[matchup.team2Id].h2hRecord }})</div>
        </div>
        <UserLogo :id="matchup.team2Id" :sport="draftJSON.sport" :size="40"
          :rankColor="users[matchup.team2Id].rankColor"
          :rankNum="users[matchup.team2Id].rankNum" :showRank="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';

export default {
  components: {
    UserLogo,
  },
  props: {
    draftJSON: Object,
    users: Object,
    teamRosters: Object,
  },

  methods: {
    getPotentialProj(userId) {
      let projT = 0;
      this.teamRosters[userId].forEach((group) => {
        const playerGames = [];
        let sortedGames = [];
        group.players.forEach((player) => {
          if (player.gamesByWeek) {
            player.gamesByWeek[0].forEach(function(game) {
              playerGames.push(game.proj);
            });
          }
        });
        sortedGames = playerGames.sort((proj1, proj2) => {
          return proj1 < proj2 ? 1 : -1;
        });
        for (let i = 0; i < group.maxGames; ++i) {
          if (sortedGames[i]) {
            projT += Math.round(sortedGames[i]);
          }
        }
      });

      return projT.toFixed(0);
    },

    projectedWin(teamNumber, matchup) {
      if (this.getPotentialProj(matchup.team1Id) == this.getPotentialProj(matchup.team2Id)) {
        if (teamNumber == 1 && matchup.coinTossWinner == 'team1') {
          return true;
        } else if (teamNumber == 2 && matchup.coinTossWinner == 'team2') {
          return true;
        }
        return false;
      }

      if (teamNumber == 1 && this.getPotentialProj(matchup.team1Id) > this.getPotentialProj(matchup.team2Id)) {
        return true;
      } else if (teamNumber == 2 && this.getPotentialProj(matchup.team2Id) > this.getPotentialProj(matchup.team1Id)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.matchupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
.matchupContainer:nth-child(odd) {
  background: var(--obcolor-background-5);
}
.matchupContainer:nth-child(even) {
  background: var(--obcolor-background-6);
}

.matchupVs {
  // flex: 1;
  display: flex;
  // justify-content: center;
  margin-top: -10px;
  padding: 0 10px;
  color: var(--obcolor-font-secondary);
  text-align: center;
}
.matchHeaderTeamBox {
  background: var(--obcolor-background-2);
  // border: 1px solid var(--obcolor-text-faded);
  display: flex;
  align-items: center;
  width: 200px;
  padding-right: 15px;
}
.matchHeaderTeamBox:last-child {
  text-align: right;
  justify-content: flex-end;
}
.matchHeaderName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.matchHeaderCoin {
  padding: 0 7px 4px 7px;
  font-size: 16px;
  color: var(--obcolor-font-secondary);
  height: 18px;
  margin-bottom: -3px;
  margin-top: -6px;
  opacity: 1;
  transition: opacity 1s;
}
.matchHeaderCoin.hiddenCoin {
  opacity: 0;
}
.matchHeaderProj {
  background: var(--obcolor-background-2);
  padding: 3px 5px;
  height: 16px;
  min-width: 35px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.matchHeaderRecord {
  height: 18px;
  font-size: 14px;
  padding: 0 10px;
}
</style>