<template>
  <!-- Table Headers -->
  <div v-if="league != null">
    <table aria-label="Matchup Statistics" style="height: 60px;">
      <!-- If there are multiple stat categories, render an upper row showing the categories -->
      <tr v-if="rosterScoringStats.length > 1">
        <th rowspan="2" class="playerInfoCol rowHeader" scope="col">
          <div>
            <select v-if="rosterFilters && rosterFilters.length > 1" v-model="selectedFilterIndex" class="statDropDown">
              <option v-for="(roster, index) in rosterFilters" :key="index" :value="index">{{ roster.label }}</option>
            </select>
          </div>
        </th>
        <!-- Row header descriptors such as "Defense","Rushing", etc. -->
        <th class="rowSubHeader endSection" style="padding-top:6px; padding-bottom 0" v-for="(category, index) in rosterScoringStats"
          :key="index" :colspan="category.stats.length" scope="col"
        >
          {{ category.label }}
        </th>
        <th colspan="2" scope="col"></th>
      </tr>
      <!-- "Main" row if there was only one row, or "subheading" row if there were multiple categories -->
      <tr>
        <th v-if="rosterScoringStats.length == 1" class="playerInfoCol rowHeader" scope="row">
          <div>
            <select v-if="rosterFilters && rosterFilters.length > 1" v-model="selectedFilterIndex" class="statDropDown">
              <option v-for="(roster, index) in rosterFilters" :key="index" :value="index">{{ roster.label }}</option>
            </select>
          </div>
        </th>
        <!-- Show one heading with the stats if there is only one category -->
        <template v-if="rosterScoringStats.length == 1">
          <th class="rowHeader" v-for="(stat, index1) in rosterScoringStats[0].stats" :key="stat.path"
            :class="{endSection: index1 == rosterScoringStats[0].stats.length - 1}"
            v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
          >
            {{ stat.label }}
          </th>
        </template>
        <!-- If there are multiple categories, loop through them to show, and put a border to the right at the end of each category -->
        <template v-else>
          <template v-for="(category, index) in rosterScoringStats">
            <th class="rowSubHeader borderBottom" v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
              :class="{endSection: index1 == category.stats.length - 1}"
              v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat'}"
            >
              {{ stat.label }}
            </th>
          </template>
        </template>
        <th class="statsPtsHeader rowHeader">
          <div v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat'}">FPTS</div>
          <div>{{ totalPts }}</div>
        </th>
        <th class="posGroupEmpty rowHeader"><div></div></th>
      </tr>
    </table>
    <!-- Current Roster grouped by position -->
    <template v-for="(group, index) in teamRosters">
      <div style="display: flex;"
        :style="index >= teamRosters.length-1 ? '' : 'border-bottom: 3px solid var(--obcolor-background-6);'"
        :key="index"
      >
        <div style="flex: 1;">
          <table aria-label="Matchup Statistics">
            <MatchupStatsTableRow v-for="entry in group.players" :key="entry.id"
              :entry="entry" :league="league" :selectedDay="selectedDay"
              :isMyTeam="isMyTeam" :dropped="false"
              :playedGames="playedGames"
              :style="entry.hlTableRow ? 'background: var(--obcolor-background-5);' : ''"
              :selectedFilterIndex="selectedFilterIndex"
            />
          </table>
        </div>
        <div class="posGroup">
          <div>
            <div :class="{'redHighlight' : rosterGR[group.pos] != rosterPossibleGR[group.pos]}" class="posGroupText">
              {{ rosterGR[group.pos] }}
            </div>
            <div>{{ rosterPossibleGR[group.pos] }}</div>
          </div>
        </div>
      </div>
    </template>
    <!-- Dropped Players -->
    <table class="rowHighlight" aria-label="Dropped Player">
      <tr v-if="roster.dropped.length > 0">
        <th colspan="14" class="rowHeader" style="text-align: left; padding: 0 6px; height: 60px;">Dropped Players</th>
      </tr>
      <MatchupStatsTableRow v-for="entry in roster.dropped" :key="entry.id"
        :entry="entry" :league="league" :selectedDay="selectedDay"
        :isMyTeam="isMyTeam" :dropped="true"
        :playedGames="playedGames"
        :style="entry.hlTableRow ? 'background: var(--obcolor-background-5);' : ''"
        :selectedFilterIndex="selectedFilterIndex"
      />
    </table>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import MatchupStatsTableRow from '@/views/Contest/MatchupStats/MatchupStatsTableRow';
import EventBus from '@/event-bus';

export default {
  props: {
    league: Object,
    roster: Object,
    isMyTeam: Boolean,
    score: Number,
    selectedDay: Number,
    playedGames: Object,
    agByPosition: Object,
    rosterGR: Object,
    rosterPossibleGR: Object,
  },

  components: {
    MatchupStatsTableRow,
  },

  data() {
    return {
      selectedFilterIndex: 0,
    };
  },

  computed: {
    totalPts() {
      let total = new Decimal(0);
      // Current Team
      for (const pIndex in this.roster.players) {
        const entry = this.roster.players[pIndex];
        total = total.plus(this.sumPlayerPoints(entry));
      }
      // Dropped Players
      for (const pIndex in this.roster.dropped) {
        const entry = this.roster.dropped[pIndex];
        total = total.plus(this.sumPlayerPoints(entry));
      }
      return Number(total);
    },

    teamRosters() {
      const teamRosters = {};
      const rostersArray = [];
      for (const pIndex in this.roster.players) {
        const player = this.roster.players[pIndex];
        player.hlTableRow = pIndex % 2 != 0;
        if (teamRosters[player.draftPos] != null) {
          teamRosters[player.draftPos].players.push(player);
        } else {
          const list = {pos: player.draftPos, players: [player]};
          teamRosters[player.draftPos] = list;
          rostersArray.push(list);
        }
      }
      return rostersArray;
    },

    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.league.sport);
    },

    rosterScoringStats() {
      const statList = [];
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return statList;
    },
  },

  methods: {
    sumPlayerPoints(player) {
      const games = player.fspGames;
      let totalPoints = new Decimal(0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        if (this.playedGames[gameKey]) {
          totalPoints = totalPoints.plus(new Decimal(gJSON.ObPoints.total));
        }
      }
      return totalPoints;
    },

    selectFilter(rosterIndex) {
      this.selectedFilterIndex = rosterIndex;
      EventBus.$emit('CLOSE_DROPDOWNS');
    },
  },
};
</script>

<style lang="scss" scoped>
.playerInfoCol {
  text-align: left;
  max-width: 250px;
  min-width: 250px;
  width: 250px;
  padding-left: 6px;
  padding-bottom: 0px;
  box-sizing: border-box;
}

.playerInfoCol > div {
  display: flex;
  align-items: center;
}

.matchHeaderTeam {
  font-weight: bold;
}

.matchHeaderUser {
  font-size: 14px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.statsPtsHeader {
  min-width: 73px;
  max-width: 0;
  width: 73px;
  box-sizing: border-box;
}

.rowHighlight tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

table {
  text-align: center;
  width: 100%;
}

td, th {
  padding: 0;
  white-space: nowrap;
}

th {
  background: var(--obcolor-background-6);
  font-size: 13px;
}

th:not(.playerInfoCol):not(.statsPtsHeader):not(.posGroupEmpty) {
  min-width: 55px;
}

.rowHeader {
  border-bottom: 1px solid var(--obcolor-text-faded);
}

.borderBottom {
  border-bottom: 1px solid var(--obcolor-text-faded);
}

.endSection {
  border-right: 1px solid var(--obcolor-background-2);
}

tr:not(.heading):hover {
  background: var(--obcolor-background-2) !important;
}

.posGroupEmpty {
  width: 30px;
  min-width: 30px;
  box-sizing: border-box;
}

.posGroupEmpty > div {
  width: 18px;
}

.posGroup {
  width: 30px;
  min-width: 30px;
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.posGroupText {
  border-bottom: 2px solid #aaa;
  margin-bottom: 2px
}

.redHighlight {
  color: red !important;
}

.optionButton {
  cursor: pointer;
  padding: 5px 10px !important;
}

.filterBtn {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}

.filterText {
  font-size: 10px;
  width: max-content;
}

.filterBtnContainer {
  cursor: pointer;
  width: 40px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
  margin-bottom: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statDropDown {
  width: 120px;
  font-size: 12px;
  font-weight: bold;
}
</style>
