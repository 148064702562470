<template>
  <div class="contestContainer">
    <div class="contestEntry" style="display: flex; padding-left: 10px; padding-right: 10px;" @click.stop="toggleDropdown()">
      <div class="contestInfoContainer" style="flex: 1;">
        <div class="contestName">{{ contestNameString }}</div>
        <div class="contestDate">{{ startEnd.toUpperCase() }}</div>
        <div class="numLineups">{{ lineupString }}</div>
        <div class="prizeMoney">
          <span style="margin-right: 10px;">PRIZES</span>
          <span :class="contest.realMoney ? 'moneyText' : 'blueText'">{{ contest.prizePool.removeCentsIfZero() }}</span>
        </div>
      </div>
      <div class="dropdownArrow" @click.stop="toggleDropdown()">
        <i v-if="!expanded" class="fas fa-chevron-down"></i>
        <i v-else class="fas fa-chevron-up"></i>
      </div>
    </div>
    <div v-if="loading && expanded">
      <ObLoading />
    </div>
    <div v-else-if="expanded" class="lineupsContainer">
      <table style="margin-top: 10px;" aria-labelledby="existingContestLineup">
        <tr>
          <th id="existingContestLineup">Lineups</th>
          <th style="text-align: center;">Rem Salary</th>
          <th style="text-align: center;">Proj</th>
          <th></th>
        </tr>
        <ImportExistingEntry v-for="(lineup, index) in sortedLineups" :key="index" :lineup="lineup"
          :playerMap="playerMap" :contest="contest" :currentContest="currentContest"
        />
      </table>
    </div>
  </div>
</template>

<script>
import ImportExistingEntry from './ImportExistingEntry';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    ImportExistingEntry,
    ObLoading,
  },

  props: {
    contest: {type: Object, default: null},
    expanded: {type: Boolean, default: false},
    lineupList: {type: Array, default: null},
    playerMap: {type: Object, default: null},
    loading: {type: Boolean, default: false},
    currentContest: {type: Object, default: null},
  },

  computed: {
    startEnd() {
      if (!this.contest) {
        return;
      }

      const start = this.$moment(this.contest.days[0], 'YYYY-MM-DD').format('MMM D');
      const end = this.$moment(this.contest.days[this.contest.days.length - 1], 'YYYY-MM-DD').format('MMM D');
      if (start == end) {
        return start;
      }
      return start + ' - ' + end;
    },

    contestNameString() {
      return this.contest.contestName ? this.contest.contestName.toUpperCase() : '';
    },

    lineupString() {
      let numLineups = this.contest.myEntries + ' LINEUP';

      if (this.contest.myEntries > 1) {
        numLineups += 'S';
      }

      return numLineups;
    },

    sortedLineups() {
      if (!this.lineupList) {
        return [];
      }
      return this.lineupList.slice().sort((l1, l2) => {
        return parseInt(l1.teamNumber) - parseInt(l2.teamNumber);
      });
    },
  },

  methods: {
    toggleDropdown() {
      EventBus.$emit('IMPORT_LINEUP_TOGGLE_DROPDOWN', this.contest.id);
    },
  },
};
</script>

<style lang="scss" scoped>

  table {
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;
    background: var(--obcolor-background-6);
  }

  th {
    font-weight: normal;
    font-size: 14px;
    line-height: 250%;

    color: var(--obcolor-text-primary);
    opacity: 0.5;

    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    min-width: 40px;
    text-align: left;
  }

  td {
    padding: 8px 4px 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  tr:not(:nth-child(1)) {
    border-top: 1px solid var(--obcolor-background-2);
  }

  .contestContainer {
    padding: 8px 4px 8px 4px;
    border-bottom: 1px solid var(--obcolor-background-1);
  }

  .contestContainer:last-of-type {
    border-bottom: none;
  }

  .contestInfoContainer {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    color: var(--obcolor-text-primary);
    align-items: center;
    text-align: right;
    height: 50px;

    div:not(:last-child) {
      margin-right: 20px;
    }
  }

  .contestName {
    text-align: left;
    overflow: hidden;
    width: 250px;
    text-overflow: ellipsis;
    flex: 1;
  }

  .contestDate {
    min-width: 90px;
  }

  .numLineups {
    min-width: 120px;
  }

  .prizeMoney {
    min-width: 135px;
    margin-right: 31px;
  }

  .lineupsContainer {
    padding-left: 15px;
    padding-right: 15px;
    background: var(--obcolor-background-6);
  }

  .dropdownArrow {
    color: var(--obcolor-text-secondary);
    font-size: 16px;

    height: 50px;
    display: flex;
    align-items: center;
  }

  .contestEntry:hover .dropdownArrow {
    color: var(--obcolor-text-primary);
  }

  .moneyText {
    color: var(--obcolor-green);
  }

  .blueText {
    color: var(--obcolor-ob-blue);
  }

  .contestEntry:hover {
    background: var(--obcolor-background-6);
    cursor: pointer;
  }
</style>