<template>
  <div class="gameEntry" @click="openGameModal()">
    <!-- Game Header -->
    <div class="gameDateHeader" :class="getHeaderColor()">
      <div style="flex: 1;">{{ getHeaderText() }}</div>
      <div>{{ liveGameDesc() }}</div>
    </div>

    <div class="scoreboardBody">
      <!-- Away Team -->
      <div class="scoreContainer">
        <div class="teamContainer">
          <img class="logo" :src="getTeamLogo(game, game.visitor_team.team_alias)" :alt="game.visitor_team.team_alias">
          <div class="teamAlias">{{ getTeamAlias(false) }}</div>
          <i v-if="hasBall('away')" class="fas fa-football-ball ballIcon"></i>
        </div>
        <div class="scoreBox" :class="{ wonGame: hasWonGame('awayteam') }">{{ hasScore('awayteam') ? game.live.awayteam.score : '-' }}</div>
      </div>

      <!-- Home Team -->
      <div class="scoreContainer">
        <div class="teamContainer">
          <img class="logo" :src="getTeamLogo(game, game.home_team.team_alias)" :alt="game.home_team.team_alias">
          <div class="teamAlias">{{ getTeamAlias(true) }}</div>
          <i v-if="hasBall('home')" class="fas fa-football-ball ballIcon"></i>
        </div>
        <div class="scoreBox" :class="{ wonGame: hasWonGame('hometeam') }">{{ hasScore('hometeam') ? game.live.hometeam.score : '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  props: {
    sport: String,
    game: Object,
  },

  methods: {
    hasScore(teamIndex) {
      return this.game.live && this.game.live[teamIndex] && this.game.live[teamIndex].score != null;
    },

    hasBall(team) {
      if (this.sport !== 'NFL') {
        return false;
      }
      if (this.game.live && this.game.live.status === 'mid-event' && this.game.live.team_possession) {
        return this.game.live.team_possession === team;
      }
      return false;
    },

    liveGameDesc() {
      switch (this.sport) {
        case 'NFL': return this.liveGameDescNFL();
        case 'MLB': return this.liveGameDescMLB();
      }
      return '';
    },

    liveGameDescNFL() {
      const liveJSON = this.game.live;
      if (liveJSON && liveJSON.status === 'mid-event') {
        const down = liveJSON.down;
        const dist = liveJSON.dist_first;
        if (down && dist && down !== '0') {
          return this.addOrdinalSuffix(down) + ' & ' + dist;
        }
      }
      return '';
    },

    liveGameDescMLB() {
      const liveJSON = this.game.live;
      if (liveJSON && liveJSON.status === 'mid-event') {
        const outs = liveJSON.outs;
        if (outs && outs != '') {
          return outs + (outs === '1' ? ' Out' : ' Outs');
        }
      }
      return '';
    },

    hasWonGame(teamType) {
      if (this.game.live && this.game.live.status == 'post-event') {
        const oppTeamType = teamType == 'hometeam' ? 'awayteam' : 'hometeam';
        return parseInt(this.game.live[teamType].score) > parseInt(this.game.live[oppTeamType].score);
      }
      return false;
    },

    getHeaderColor() {
      const gameStatus = this.game.live ? this.game.live.status : '';
      switch (gameStatus) {
        case 'mid-event': return 'liveHeader';
        case 'Postponed': return 'invalidHeader';
        case 'Canceled': return 'invalidHeader';
      }
      return '';
    },

    getHeaderText() {
      const gameStatus = this.game.live ? this.game.live.status : '';
      switch (gameStatus) {
        case 'mid-event': return this.getLiveGameState();
        case 'Postponed': return 'POSTPONED';
        case 'Canceled': return 'CANCELED';
        case 'post-event':
          if (this.sport !== 'MLB') {
            const period = this.$SportInfo.getGamePeriod(this.sport, this.game);
            if (period.includes('OT')) {
              return 'FINAL ' + period;
            }
          } else if (this.sport === 'MLB') {
            const finalInnings = this.game?.live?.awayteam?.scores?.split(',').length;
            if (finalInnings >= 10) {
              return 'FINAL/' + finalInnings;
            }
          }
          return 'FINAL';
      }
      return this.$moment(this.game.date_utc).format('h:mma ddd MMM D');
    },

    getLiveGameState() {
      const liveJSON = this.game.live;

      if (this.sport === 'MLB') {
        const inning = this.$SportInfo.getGamePeriod(this.sport, this.game);
        if (inning !== '') {
          return inning;
        }
        return this.$moment(this.game.date_utc).format('h:mma ddd MMM D');
      }

      const gameTime = liveJSON.gametime === '00:00' ? 'End' : liveJSON.gametime;
      const period = this.$SportInfo.getGamePeriod(this.sport, this.game);
      return (gameTime + ' ' + period).toUpperCase();
    },

    getTeamLogo(game, team) {
      let sportName = this.sport.toLowerCase();
      if (game.sport) {
        sportName = game.sport.toLowerCase();
      }
      return require('@/assets/logos/'+ sportName +'_'+ team.toLowerCase() +'.png');
    },

    getTeamAlias(home) {
      let sportName = this.sport.toUpperCase();
      if (this.game.sport) {
        sportName = this.game.sport.toUpperCase();
      }
      const alias = home ? this.game.home_team.team_alias : this.game.visitor_team.team_alias;
      return this.$TeamInfo.getDisplayAlias(sportName, alias);
    },

    openGameModal() {
      if (this.game) {
        EventBus.$emit('GAME_MODAL_OPEN', {
          sport: this.sport,
          game: this.game,
        });
      }
    },

    addOrdinalSuffix(i) {
      if (i == null) {
        return '';
      }
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
  },
};
</script>

<style lang="scss" scoped>
$gameHeight: 80px;

.gameEntry {
  border-radius: 5px;
  margin: 0 8px 8px 0;
  min-width: 165px;
  width: 250px;
  box-sizing: border-box;
  overflow: hidden;
  background: var(--obcolor-background-3);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.gameEntry:hover {
  background: var(--obcolor-background-2);

  .gameDateHeader:not(.liveHeader):not(.invalidHeader) {
    background: var(--obcolor-background-1);
  }

  .liveHeader {
    background: var(--obcolor-ob-blue-tinted)
  }

  .invalidHeader {
    background: var(--obcolor-red-tinted);
  }

  .scoreBox {
    background: var(--obcolor-background-1);
  }
}

.gameDateHeader {
  background: var(--obcolor-background-2);
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

.liveHeader {
  background: var(--obcolor-ob-blue);
  color: white;
}

.invalidHeader {
  background: var(--obcolor-red);
  color: white;
}

.logo {
  width: 22px;
}

.scoreboardBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}

.scoreContainer {
  display: flex;
  align-items: center;
  padding: 2px 2px;
  height: 25px;
}

.teamContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.scoreBox {
  background: var(--obcolor-background-2);
  min-width: 40px;
  min-height: 13px;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: bold;
}

.wonGame {
  color: var(--obcolor-ob-blue);
}

.teamAlias {
  margin-left: 7px;
  text-transform: uppercase;
  min-width: 25px;
}

.ballIcon {
  margin-left: 4px;
  color: var(--obcolor-ob-blue);
}
</style>
