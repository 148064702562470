<template>
  <div class="ob-page">
    <div class="ob-box verifyBox">
      <!-- Invalid -->
      <div v-if="invalidCode">{{ invalidMessage }}</div>

      <!-- Processing -->
      <div v-else-if="processing">
        <ObLoading />
        <div style="margin-bottom: 15px;">Verifying email...</div>
      </div>

      <!-- Success -->
      <div v-else style="display: flex; flex-direction: column; align-items: center;">
        <div style="margin-bottom: 10px;">Your email has been verified.</div>
        <router-link v-if="isLoggedIn || userState === 'WAITLIST' || userState === 'NOT_VERIFIED'" to="/lobby" style="width: max-content;"
          class="ob-btn"
        >
          Lobby
        </router-link>
        <router-link v-else to="/login" style="width: max-content;"
          class="ob-btn"
        >
          Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';

export default {

  components: {
    ObLoading,
  },

  data() {
    return {
      invalidCode: false,
      invalidMessage: '',
      processing: false,

      userId: null,
      code: null,
    };
  },

  computed: {
    ...mapState(['isLoggedIn', 'userState']),
  },

  created() {
    this.openPage();
  },

  watch: {
    '$route.params.key'() {
      this.openPage();
    },
  },

  methods: {
    openPage() {
      this.processing = true;
      this.invalidMessage = '';
      this.invalidCode = false;
      this.userId = null;
      this.code = null;

      const verificationCode = this.$route.params.key;
      const splitCode = verificationCode.split('-');

      if (splitCode[0]) {
        this.userId = splitCode[0];
      }
      if (splitCode[1]) {
        this.code = splitCode[1];
      }

      this.verify();
    },

    verify() {
      if (!this.userId) {
        this.invalidCode = true;
        this.invalidMessage = 'Error - User ID not found';
        return;
      }
      if (!this.code) {
        this.invalidCode = true;
        this.invalidMessage = 'Error - Verification code not found';
        return;
      }

      ObAccountApi.verifyEmail(this.userId, this.code)
          .then((data) => {
            this.processing = false;

            if (data.status && data.status === 'success') {
            // Success but not logged in, do nothing
            } else if (data.verified != null && !data.verified) {
              this.$store.commit('setUserState', 'NOT_VERIFIED');
            } else if (data.userState && data.userState === 'WAITLIST') {
              this.$store.commit('setUserState', 'WAITLIST');
            } else if (data.userId) {
            // Clear user state, would previously have been NOT_VERIFIED if they were logged in
              this.$store.commit('setUserState', data.userState);
              this.$store.commit('setLoggedIn', data.userId);
              this.$store.commit('setNodeToken', data.st);
              EventBus.$emit('LOGIN');
            }
          })
          .catch((error) =>{
            this.invalidCode = true;
            if (error && error.response && error.response.data) {
              this.invalidMessage = error.response.data;
            }
            this.processing = false;
          });
    },
  },

};
</script>

<style lang="scss" scoped>
.verifyBox {
  max-width: 600px;
  height: 108px;
  padding: 15px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
