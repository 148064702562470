// Season Summary Stats
// Controls the top overall summary for season page

// Quarterback Stats
export const SEASON_NFL_QB = [
  {
    label: '',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'QBR', path: 'passing.QBR', tooltip: 'Quarterback Rating'},
      {label: 'TTD', path: 'scoring.TTD', tooltip: 'Total Touchdowns'},
      {label: 'TYD', path: 'scoring.TYD', tooltip: 'Total Yards'},
    ],
  },
  {
    label: 'PASSING',
    stats: [
      {label: 'ATT', path: 'passing.PASS_ATT', tooltip: 'Attempts'},
      {label: 'CMP', path: 'passing.PASS_M', tooltip: 'Completions'},
      {label: 'YDS', path: 'passing.PASS_YDS', tooltip: 'Passing Yards'},
      {label: 'AVG', path: 'passing.PASS_AVG', tooltip: 'Average Passing Yards'},
      {label: 'TD', path: 'passing.PASS_TD', tooltip: 'Passing Touchdowns'},
      {label: 'INT', path: 'passing.PASS_INT', tooltip: 'Interceptions Thrown'},
      {label: 'SCK', path: 'sacks.SCK_A', tooltip: 'Sacks'},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'CAR', path: 'rushing.RUS_ATT', tooltip: 'Carries'},
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards'},
      {label: 'AVG', path: 'rushing.RUS_AVG', tooltip: 'Average Rushing Yards'},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns'},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost'},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'TAR', path: 'passing.REC_TAR', tooltip: 'Targets'},
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions'},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards'},
      {label: 'AVG', path: 'passing.REC_AVG', tooltip: 'Average Receiving Yards'},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns'},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered' },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions'},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles' },
    ],
  },
];

// Running Back Stats
export const SEASON_NFL_RB = [
  {
    label: '',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'TTD', path: 'scoring.TTD', tooltip: 'Total Touchdowns'},
      {label: 'TYD', path: 'scoring.TYD', tooltip: 'Total Yards'},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'CAR', path: 'rushing.RUS_ATT', tooltip: 'Carries'},
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards'},
      {label: 'AVG', path: 'rushing.RUS_AVG', tooltip: 'Average Rushing Yards'},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns'},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost'},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'TAR', path: 'passing.REC_TAR', tooltip: 'Targets'},
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions'},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards'},
      {label: 'AVG', path: 'passing.REC_AVG', tooltip: 'Average Receiving Yards'},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns'},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards'},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Return Touchdowns'},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered' },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions'},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles' },
    ],
  },
];

// Wide Receiver/Tight End Stats
export const SEASON_NFL_WR_TE = [
  {
    label: '',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'TTD', path: 'scoring.TTD', tooltip: 'Total Touchdowns'},
      {label: 'TYD', path: 'scoring.TYD', tooltip: 'Total Yards'},
    ],
  },
  {
    label: 'RECEIVING',
    stats: [
      {label: 'TAR', path: 'passing.REC_TAR', tooltip: 'Targets'},
      {label: 'REC', path: 'passing.REC', tooltip: 'Receptions'},
      {label: 'YDS', path: 'passing.REC_YDS', tooltip: 'Receiving Yards'},
      {label: 'AVG', path: 'passing.REC_AVG', tooltip: 'Average Receiving Yards'},
      {label: 'TD', path: 'passing.REC_TD', tooltip: 'Touchdowns'},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost'},
    ],
  },
  {
    label: 'RUSHING',
    stats: [
      {label: 'CAR', path: 'rushing.RUS_ATT', tooltip: 'Carries'},
      {label: 'YDS', path: 'rushing.RUS_YDS', tooltip: 'Rushing Yards'},
      {label: 'AVG', path: 'rushing.RUS_AVG', tooltip: 'Average Rushing Yards'},
      {label: 'TD', path: 'rushing.RUS_TD', tooltip: 'Touchdowns'},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards'},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Return Touchdowns'},
    ],
  },
  {
    label: '',
    stats: [
      // { label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumbles Recovered' },
      {label: '2PT', path: 'scoring.TOTAL_TPC', tooltip: '2-Point Conversions'},
      // { label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles' },
    ],
  },
];

// Defense
export const SEASON_NFL_DEF = [
  {
    label: '',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'SOLO', path: 'defense.TCK_SOLO', tooltip: 'Solo Tackles'},
      {label: 'SCK', path: 'defense.SCK', tooltip: 'Sacks'},
      {label: 'INT', path: 'defense.INT', tooltip: 'Interceptions'},
      {label: 'PD', path: 'defense.PD', tooltip: 'Pass Deflections'},
      {label: 'SFTY', path: 'defense.STY', tooltip: 'Safeties'},
      {label: 'FF', path: 'fumbles.FBL_F', tooltip: 'Forced Fumbles'},
      {label: 'FR', path: 'fumbles.FBL_REC', tooltip: 'Fumble Recoveries'},
      {label: 'FL', path: 'fumbles.FBL_L', tooltip: 'Fumbles Lost'},
      {label: 'BLK', path: 'defense.BLK', tooltip: 'Blocks'},
    ],
  },
  {
    label: 'RETURN',
    stats: [
      {label: 'YDS', path: 'special.RET_YDS', tooltip: 'Return Yards'},
      {label: 'TD', path: 'special.RET_TD', tooltip: 'Return Touchdowns'},
    ],
  },
];

// Kicking
export const SEASON_NFL_K = [
  {
    label: 'KICKING',
    stats: [
      {label: 'YDS', path: 'scoring.FG_YDS', tooltip: 'Field Goal Yards'},
      {label: 'FGM', path: 'scoring.FGM', tooltip: 'Field Goals Made'},
      {label: 'FGA', path: 'scoring.FGA', tooltip: 'Field Goal Attempts'},
      {label: 'FG%', path: 'scoring.FGPct', tooltip: 'Field Goal Percentage'},
      {label: 'XPM', path: 'scoring.EPM', tooltip: 'Extra Points Made'},
      {label: 'XPA', path: 'scoring.EPA', tooltip: 'Extra Point Attempts'},
      {label: 'XP%', path: 'scoring.EPPct', tooltip: 'Extra Points Percentage'},
      {label: 'LNG', path: 'scoring.FG_LNG', tooltip: 'Longest Field Goal'},
    ],
  },
];