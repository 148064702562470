<template>
  <div class="ob-page">
    <div class="ob-box" style="padding: 15px; font-family: Arial, sans-serif; font-size: 14px;">
      <div v-if="termsAndConditions" v-html="termsAndConditions"></div>
      <div v-else><ObLoading /></div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading';

export default {
  metaInfo: {
    title: 'Terms Of Service | OwnersBox Fantasy Sports',
    meta: [
      {name: 'description', content: 'The official OwnersBox terms of service.'},
      {name: 'og:description', content: 'The official OwnersBox terms of service.'},
    ],
  },

  components: {
    ObLoading,
  },

  data() {
    return {
      termsAndConditions: null,
      termsVersion: null,
    };
  },

  created() {
    this.loadTerms();
  },

  methods: {
    loadTerms() {
      ObAccountApi.getTermsAndConditions()
          .then((data) => {
            this.termsAndConditions = data.document;
            this.termsVersion = data.version;
          })
          .catch((error) => {
            console.log('Terms and Conditions Error');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: red !important;
}
</style>