<template>
  <div>
    <table class="teamTable" aria-label="Lightning Live Leaderboard">
      <tr>
        <th>Rank</th>
        <!-- Players in team -->
        <th></th>
        <!-- Payout -->
        <th style="width: 60px;"></th>
        <th style="width: 60px;">Proj</th>
        <th style="width: 60px;">Score</th>
      </tr>
      <tr class="teamRow" v-for="(team, tIndex) in allTeams" :key="tIndex"
        @click="selectTeam(tIndex)" :class="{selectedTeam: tIndex == selectedTeamIndex}"
      >
        <!-- Rank -->
        <td class="rankSection">
          <i v-if="team.isUserTeam" class="fas fa-star"></i>
          <div class="teamRank" v-if="contest.state === 'CANCELLED' || team.isRemoved || team.rank <= 0">-</div>
          <div class="teamRank trophyRank" v-else-if="team.rank == 1 && !team.isTied"><i class="fas fa-trophy"></i></div>
          <div class="teamRank" v-else>{{ getRank(team) }}</div>
        </td>
        <!-- Players in team -->
        <td class="teamSection">
          <div class="teamList">
            <PlayerHeadshot v-for="(player, pIndex) in team.players" :key="pIndex"
              class="playerHeadshot"
              :id="player.id" :sport="sport" :team="player.teamAlias"
              :size="'50'" :showLogo="true" v-tooltip.bottom="{content: getPlayerTooltip(player), class:'ObTooltip'}"
            />
          </div>
        </td>
        <!-- Payout -->
        <td v-if="!team.isRemoved" class="payoutSection">
          <div v-if="team.isUserTeam" :class="contest.realMoney ? 'greenText' : 'blueText'">
            <img v-if="contest.realMoney" class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
              alt="Money Bag"
            >
            <img v-else class="ownersBucksIcon" style="margin-right: 4px;"
              :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
            >
            <span>{{ userPayout }}</span>
          </div>
        </td>
        <!-- Projection -->
        <td v-if="!team.isRemoved" class="projSection">{{ team.projection.toFixed(2) }}</td>
        <!-- Score -->
        <td v-if="!team.isRemoved" class="pointsSection blueText" :class="{ redText: team.fpts < 0 }">{{ team.fpts.toFixed(2) }}</td>
        <td colspan="3" v-else class="cancelledContest">
          <p class="redTextCancelled"> Lineup Cancelled</p>
          <p v-if="team.isRemoved" class="cancelNote">{{ team.scratchedPlayersNotes }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import EventBus from '@/event-bus';

export default {
  props: {
    contest: {type: Object, default: null},
    allTeams: {type: Array, default: null},
    selectedTeamIndex: {type: Number, default: null},
  },

  components: {
    PlayerHeadshot,
  },

  computed: {
    sport() {
      if (!this.contest) {
        return null;
      }

      return this.contest.sport;
    },

    userPayout() {
      if (!this.contest || !this.contest.userWinningsFormatted) {
        return '0';
      }
      return this.contest.userWinningsFormatted.removeCentsIfZero();
    },
  },

  methods: {
    selectTeam(teamIndex) {
      EventBus.$emit('LL_LIVE_SELECT_TEAM', teamIndex);
    },

    getRank(team) {
      const tiedPrefix = team.isTied ? 'T-' : '';
      return tiedPrefix + String(team.rank).addSuffix();
    },

    getPlayerTooltip(player) {
      if (!player || !player.name) {
        return '';
      }
      return player.name.shortName() + ' - ' + this.getTeamAlias(player).toUpperCase();
    },

    getTeamAlias(player) {
      return this.$TeamInfo.getDisplayAlias(this.sport, player.teamAlias);
    },

    hasLineupRemoved(team) {
      return team.isRemoved;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

th {
  padding: 15px 20px;
  box-sizing: border-box;
  height: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  background: var(--obcolor-background-4);
}

td {
  padding: 10px 20px;
}

.fa-star {
  margin-bottom: 5px;
  color: var(--obcolor-flash-orange);
}

.fa-trophy {
  font-size: 16px;
  color: var(--obcolor-trophy-1st)
}

.teamList {
  display: flex;
}

.teamRow {
  cursor: pointer;
  height: 87px;
  box-sizing: border-box;
  background: var(--obcolor-background-6);
  border-top: 1px solid var(--obcolor-background-page);

  &:hover {
    background: var(--obcolor-background-4);
  }

  &.selectedTeam {
    background: var(--obcolor-background-4);
  }
}

.playerHeadshot {
  margin: 0 10px;
}

.rankSection {
  text-align: center;

  .teamRank {
    font-size: 14px;
    font-weight: bold;
    align-self: center;
  }
}

.payoutSection {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.projSection {
  text-align: center;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}

.pointsSection {
  text-align: center;
  color: var(--obcolor-ob-blue);
  font-size: 14px;
  font-weight: bold;
}

.moneyIcon {
  height: 18px;
  margin-bottom: -3px;
  margin-right: 2px;
}

.ownersBucksIcon {
  height: 10px;
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.greenText {
  color: var(--obcolor-green);
}

.redText {
  color: var(--obcolor-red) !important;
}

.cancelledContest {
  text-align: right;
  font-size: 14px;

  p {
    margin: 0;
  }

  .redTextCancelled {
    color: var(--obcolor-red);
    font-weight: 700;
  }

  .cancelNote {
    color: var(--obcolor-font-secondary);
  }
}
</style>