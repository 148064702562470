<template>
  <div>
    <div class="componentTitle">Scoring</div>
    <div class="componentContent" style="padding: 8px 14px;">
      <div class="infoBox" v-for="section in league.scoring" :key="section.category">
        <div class="infoBoxTitle">{{ section.category }}</div>
        <div class="infoBoxContent">
          <div class="infoBoxField" v-for="entry in section.stats" :key="entry.stat">
            <div style="flex: 1;">{{ entry.name }}</div>
            <div>
              <span>{{ entry.weight }}</span>
              <span v-if="league.sport === 'NFL' && entry.weight.indexOf('per') != -1"> yds</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    league: Object,
  },
};
</script>

<style lang="scss" scoped>

.componentContent {
  display: flex;
  justify-content: center;
}

.infoBox {
  overflow: hidden;
  flex: 1;
  background: var(--obcolor-background-4);
  border: 2px solid var(--obcolor-background-2);
  max-width: calc(50% - 8px);
}

.infoBox:not(:last-child) {
  margin-right: 8px;
}

.infoBoxTitle {
  font-size: 12px;
  padding: 6px 8px;
  background: var(--obcolor-background-2);
  font-weight: bold;
  text-transform: capitalize;
}

.infoBoxContent {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  overflow: hidden;
}

.infoBoxField {
  padding: 5px 8px;
  display: flex;
}

</style>