<template>
  <div @click="$emit('click')" class="notificationEntry">
    <!-- Player headshot -->
    <div v-if="entry.player" class="leftColumn">
      <PlayerHeadshot :id="entry.player.id" :size="'52'" :sport="entry.sport"
        :team="entry.player.team" :showLogo="true"
      />
      <div class="entryFee">{{ entry.entryFee.removeCentsIfZero() }}</div>
    </div>

    <!-- Sport Icon and Entry fee -->
    <div v-else class="leftColumn">
      <img v-if="entry.sport" :src="require('@/assets/icons/sporticon_' + entry.sport + '.png')" :alt="`${entry.sport} Icon`"
        style="height: 40px;"
      >
      <div v-if="entry.entryFee" class="entryFee">{{ entry.entryFee.removeCentsIfZero() }}</div>
      <img v-if="entry.type === 'SALARYCAP_EXTRA_SALARY'" class="extraSalaryIcon" :src="require('@/assets/salarycap/warn_extrasalary.png')"
        alt="Extra Salary"
      >
      <img v-if="entry.type === 'SALARYCAP_EXTRA_GAMEOPPS'" class="extraSalaryIcon" :src="require('@/assets/salarycap/warn_overcap.png')"
        alt="Overcap"
      >
      <img v-if="entry.type === 'SALARYCAP_NEW_PLAYERS'" class="extraSalaryIcon" :src="require('@/assets/salarycap/warn_newplayers.png')"
        alt="New Players"
      >
    </div>

    <!-- Headline and content -->
    <div>
      <div style="display: flex; align-items: center; margin-top: -3px;">
        <div style="font-weight: bold;">
          <span>{{ getEmoji() + " " }}</span>
          <span>{{ entry.headline }}</span>
          <span>{{ " " + getEmoji() }}</span>
        </div>
        <!-- Live Button if drafting -->
        <div v-if="showLive(entry)" class="liveBtn">
          <div style="width: 10px; height: 10px; background: white; display: inline-block; border-radius: 50%; margin-left: -2px;"></div>
          <span style="margin-left: 3px; font-weight: bold; margin-top: 1px;">LIVE</span>
        </div>
        <div style="flex: 1;"></div>
        <div style="color: var(--obcolor-font-secondary); font-weight: bold; font-size: 12px; margin-right: 3px;">{{ getTime(entry.date) }}</div>
      </div>
      <div v-if="entry.contestName" style="margin-top: -2px;">{{ entry.contestName }}</div>
      <div>{{ entry.message }}</div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';

export default {

  props: {
    entry: Object,
  },

  components: {
    PlayerHeadshot,
  },

  methods: {
    getEmoji() {
      switch (this.entry.type) {
        case 'CONTEST_CHAMPION': return '🚨';
        case 'SALARYCAP_WINNER': return '🚨';
        case 'SALARYCAP_EXTRA_SALARY': return '💰';
        case 'SALARYCAP_EXTRA_GAMEOPPS': return '❗';
        case 'SALARYCAP_GAME_ADDED': return '❗';
        case 'CONTEST_INVITE': return '📨';
        case 'DRAFT_STARTING': return '⏱';
        case 'SALARYCAP_NEW_PLAYERS': return '⚠️';
      }
      return '';
    },

    getTime(date) {
      // Show time only if posted today, otherwise show date only
      const now = this.$moment().format('MMM D YYYY');
      const newsDate = this.$moment(date);
      if (now === newsDate.format('MMM D YYYY')) {
        return newsDate.format('h:mma');
      }
      return newsDate.format('MMM D');
    },

    showLive(entry) {
      if (entry.type === 'DRAFT_STARTING' && entry.draftEnd) {
        const now = new Date().getTime();
        if (now < entry.draftEnd) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.leftColumn {
  width: 75px;
  min-width: 75px;
  margin-right: 5px;
  text-align: center;
  position: relative;
}

.entryFee {
  background: var(--obcolor-ob-blue);
  color: white;
  margin: 0 auto;
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  padding: 2px;
  padding-top: 3px;
  min-width: 45px;
  border-radius: 5px;
  display: inline-block;
}

.liveBtn {
  display: flex;
  align-items: center;
  color: white;
  background: red;
  padding: 2px 6px;
  border-radius: 5px;
  height: 15px;
  font-size: 12px;
  margin-left: 10px;
}

.extraSalaryIcon {
  position: absolute;
  right: 10px;
  bottom: 24px;
  height: 20px;
}
</style>