<template>
  <div class="ob-page">
    <div class="ob-box" style="display: flex;">
      <!-- Left Column Player Image -->
      <div class="playerImgContainer">
        <img class="playerImg" :src="require('@/assets/graphics/' + randomSport + '2.png')" alt="Sports Player">
      </div>

      <!-- Right Column -->
      <div class="verifyBox">
        <div class="verifyTitle">
          <img style="height: 30px; margin-right: 10px;" :src="require('@/assets/obicon_' + ((theme == 'dark') ? 'white' : 'black') + '.png')" alt="OwnersBox Logo">
          <div>Verify your Email</div>
        </div>
        <div style="margin-bottom: 25px;">
          Thanks for signing up! Please verify your email address to finish setting up your account.
          If you did not receive the email check your junk folder. If you still don’t have the email resend it below.
        </div>
        <div v-if="state === 'sending'" style="padding: 10px 40px; margin-bottom: 10px;">Sending Email...</div>
        <div v-if="state === 'sent'" style="padding: 10px 40px; margin-bottom: 10px;">Email Sent</div>
        <div v-if="state === 'failed'" style="padding: 10px 40px; margin-bottom: 10px;">Failed</div>
        <div v-if="state === 'pending'" class="ob-btn logoutBtn" style="padding: 10px 0; margin-bottom: 10px; width: 180px; text-align: center;"
          @click="resendEmail()"
        >
          Resend Email
        </div>
        <div class="ob-btn" style="padding: 10px 0; width: 180px; text-align: center; margin-bottom: 10px;" @click="logout()">Log Out</div>
      </div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import {mapState} from 'vuex';

export default {

  data: function() {
    return {
      state: 'pending',
      randomSport: this.getRandomSport(),
    };
  },

  computed: {
    ...mapState(['theme']),
  },

  methods: {
    resendEmail() {
      this.state = 'sending';
      ObAccountApi.resendVerification()
          .then((data) => {
            this.state = 'sent';
          })
          .catch((error) =>{
            this.state = 'failed';
          });
    },

    logout() {
      ObAccountApi.logoutUser()
          .then((data) => {
            this.$cookie.delete('obauth');
            this.$store.commit('setLoggedOut');
            // Reset chat data when logged out
            this.$cookie.delete('expanded-open-convo-map');
            if (this.$router.currentRoute.path != '/login') {
              this.$router.push('/login');
              this.$SocketController.disconnect();
            }
          });
    },

    getRandomSport() {
      const rNum = Math.floor(Math.random() * 4);
      switch (rNum) {
        case 0: return 'Hockey';
        case 1: return 'Football';
        case 2: return 'Baseball';
        case 3: return 'Basketball';
        default: return 'Football';
      }
    },
  },

};
</script>

<style lang="scss" scoped>

.ob-box {
  padding: 20px;
  height: 554px;
  min-height: 460px;
  width: 760px;
  overflow: hidden;
  box-sizing: border-box;
  margin: auto;
}

.verifyBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.verifyTitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.playerImgContainer {
  width: 340px;
  min-width: 340px;
}
.playerImg {
  margin: -20px 0 -20px -20px;
}
:not([data-theme="dark"]) .playerImg {
  background: radial-gradient(80.13% 50% at 50% 40%, rgba(0,0,0,0.3) 0%, rgba(255, 255, 255, 0) 100%);
}
[data-theme="dark"] .playerImg {
  background: radial-gradient(80.13% 50% at 50% 40%, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

@media only screen and (max-width: 790px) {
  .playerImgContainer {
    display: none;
  }
  .ob-box {
    width: 100%;
    min-width: 100px;
  }
}
</style>
