<template>
  <div class="slateList">
    <div v-if="!slateList.length" class="slateRow noClick">
      <div style="padding-left: 20px">No valid slates found.</div>
    </div>
    <div v-for="(slate, index) in slateList" :key="index" class="slateRow"
      @click="selectSlate(slate)"
    >
      <img :src="require('@/assets/contestbadge/' + slate.sport + '.png')" :alt="`${slate.sport} Icon`"
        class="sportBadge"
      >
      <div class="slateNameContainer">
        <div class="slateName">{{ slate.name }}</div>
        <div v-if="slateIsLive(slate)" class="rowSubText greenText">Live</div>
        <div v-else class="rowSubText">{{ $moment(slate.contestStart).format('ddd h:mma') }}</div>
      </div>
      <div class="slateField">
        <div>{{ slate.userInfo.numLineups }}</div>
        <div class="rowSubText"># of Lineups</div>
      </div>
      <div class="slateField">
        <div>
          <span v-if="slate.userInfo.entryFees">{{ slate.userInfo.entryFeesFormatted }}</span>
          <span v-if="slate.userInfo.entryFees & slate.userInfo.ownersBucksFees"> + </span>
          <span v-if="slate.userInfo.ownersBucksFees">
            <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
            {{ slate.userInfo.ownersBucksFeesFormatted }}
          </span>
          <span v-if="!slate.userInfo.entryFees && !slate.userInfo.ownersBucksFees">$0</span>
        </div>
        <div class="rowSubText">Entry Fees</div>
      </div>
      <div class="chevronContainer">
        <i class="rightChevron fas fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedSlate: {type: Object, default: null},
    slateList: {type: Array, default: null},
  },

  methods: {
    slateIsLive(slate) {
      const todayTs = this.$moment().valueOf();

      return slate.contestStart < todayTs && todayTs < slate.contestEnd;
    },

    selectSlate(slate) {
      this.$emit('selectSlate', slate);
    },
  },
};
</script>

<style lang="scss" scoped>
.slateList {
  width: 100%;
  margin-top: 30px;
}

.slateRow {
  height: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  border-top: 2px solid var(--obcolor-background-6);
  width: 100%;

  &:not(.noClick) {
    cursor: pointer;

    &:hover {
      background: var(--obcolor-background-4);
    }
  }

  &:last-child {
    border-bottom: 2px solid var(--obcolor-background-6);
  }

  .sportBadge {
    padding-left: 30px;
    padding-right: 10px;
    height: 50px;
  }

  .slateName {
    font-weight: bold;
  }

  .slateNameContainer {
    flex: 1;
  }

  .slateField {
    margin: 0 15px;
    min-width: 140px;
  }

  .slateField > div:first-child {
    font-weight: bold;
  }

  .rowSubText {
    color: var(--obcolor-font-secondary);
    margin-top: 3px;

    &.greenText {
      display: flex;
      align-items: center;
      color: var(--obcolor-green);

      &:before {
        content: '';
        position: relative;
        display: block;
        margin-right: 5px;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: var(--obcolor-green);
      }
    }
  }

  .chevronContainer {
    padding-right: 15px;
  }

  .rightChevron {
    font-size: 20px;
    margin: 20px;
  }
}

.ownersbucksIcon {
  height: 10px;
  margin-right: 1px;
}
</style>