// @ts-check

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

/**
 * Returns a formatted duration for the given number of milliseconds. If
 * {@link textClock} is true it will be in long form such as `"4 days"` or `"3h
 * 2m"`. If its false it will be formatted like a clock, `"hh:mm:ss"`
 *
 * @example <caption>Durations of at least 2 days only show days</caption>
 * // returns 2 days
 * formatDuration(2*DAY + 10*MINUTE, true)
 *
 * @example <caption>Durations over 1 hour don't show seconds</caption>
 * // returns 1h 20m
 * formatDuration(1*HOUR + 20*MINUTE + 35*SECOND, true)
 *
 * @example <caption>Durations under 1 hour show minutes and seconds</caption>
 * // returns 20m 35s
 * formatDuration(20*MINUTE + 35*SECOND, true)
 *
 * @example <caption>textClock = false</caption>
 *
 * @example <caption>With textClock false, minutes are always show</caption>
 * // returns 00:35
 * formatDuration(35*SECOND, false)
 *
 * @param {number} totalMilliseconds
 * @param {boolean} textClock
 * @return {string}
 */
export function formatDuration(totalMilliseconds, textClock) {
  const totalSeconds = Math.floor(totalMilliseconds / SECOND);
  const totalMinutes = Math.floor(totalMilliseconds / MINUTE);
  const totalHours = Math.floor(totalMilliseconds / HOUR);
  const totalDays = Math.floor(totalMilliseconds / DAY);

  const seconds = totalSeconds % 60;
  const minutes = totalMinutes % 60;

  if (!textClock) {
    const minutesFormatted = minutes.toString().padStart(2, '0');
    const secondsFormatted = seconds.toString().padStart(2, '0');
    if (totalHours == 0) {
      return minutesFormatted + ':' + secondsFormatted;
    }
    return totalHours + ':' + minutesFormatted + ':' + secondsFormatted;
  }

  if (totalDays >= 2) {
    return totalDays + ' days';
  }
  if (totalMinutes == 0) {
    return seconds + 's';
  }
  if (totalHours == 0) {
    return minutes + 'm ' + seconds + 's';
  }
  return totalHours + 'h ' + minutes + 'm';
}