<template>
  <div class="mainLineup" v-if="showStartingLineups">
    <h1 class="sectionHeader">Starting Lineup</h1>
    <div class="startingLineupWrapper" v-for="(category, role) in Object.keys(getPosition)" :key="'starter_'+role">
      <h2 class="startingLineupSubheader startingLineupBorder">
        {{ category }}
      </h2>
      <div class="startingLineupRow" v-for="index in getPosition[category]" :key="'lineup_'+index">
        <!-- away starting lineup -->
        <div class="startingLineup">
          <PlayerHeadshot size="45" :id="starters.awayteam[category][index-1] ? starters.awayteam[category][index-1].id : null" :sport="sport"
            :team="starters.awayteam[category][index-1] ? starters.awayteam[category][index-1].team : null" :showLogo="true" :pos="starters.awayteam[category][index-1]? starters.awayteam[category][index-1].position : null"
          />
          <div v-if="starters.awayteam[category][index-1]" class="startingLineupStats">
            <div class="startingLineupCentered">
              <span class="playerStatus">
                <PlayerStatus height="12" :status="starters.awayteam[category][index-1].status" />
              </span>
              <div class="ob-link" @click.stop="openPlayerCard(starters.awayteam[category][index-1].id)">{{ starters.awayteam[category][index-1].name.shortName() }}</div>
            </div>
            <p class="startingLineupSubtext">{{ startingLineupStats(starters.awayteam[category][index-1]) }}</p>
          </div>
          <div v-else class="startingLineupStats">
            <div style="margin-top: 14px;" class="startingLineupCentered">
              TBD
            </div>
          </div>
        </div>
        <!-- home starting lineup -->
        <div class="startingLineup">
          <div v-if="starters.hometeam[category][index-1]" style="text-align: right;" class="startingLineupStats">
            <div style="justify-content: right;" class="startingLineupCentered">
              <span class="playerStatus">
                <PlayerStatus height="12" :status="starters.hometeam[category][index-1].status" />
              </span>
              <div class="ob-link" @click.stop="openPlayerCard(starters.hometeam[category][index-1].id)">{{ starters.hometeam[category][index-1].name.shortName() }}</div>
            </div>
            <p class="startingLineupSubtext">{{ startingLineupStats(starters.hometeam[category][index-1]) }}</p>
          </div>
          <div v-else class="startingLineupStats">
            <div style="margin-top: 14px;" class="startingLineupCentered">
              TBD
            </div>
          </div>
          <PlayerHeadshot size="45" :id="starters.hometeam[category][index-1] ? starters.hometeam[category][index-1].id : null" :sport="sport"
            :team="starters.hometeam[category][index-1] ? starters.hometeam[category][index-1].team : null" :showLogo="true" :pos="starters.hometeam[category][index-1] ? starters.hometeam[category][index-1].position : null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    showStartingLineups() {
      const liveMatchupData = this.gameData.matchup.live;
      return ((!liveMatchupData || liveMatchupData.status === 'pre-event') && this.starters['awayteam']['Guards'].length != 0);
    },

    awayStarters() {
      const startingPlayers = [];
      for (const player of this.gameData.rosters.awayteam) {
        for (const game of player.next_games) {
          if (game.id === this.gameData.matchup.id && game.info?.gamestatus === 'starter') {
            player.gameinfo = game.info;
            startingPlayers.push(player);
          }
        }
      };
      return startingPlayers;
    },

    homeStarters() {
      const startingPlayers = [];
      for (const player of this.gameData.rosters.hometeam) {
        for (const game of player.next_games) {
          if (game.id === this.gameData.matchup.id && game.info?.gamestatus === 'starter') {
            player.gameinfo = game.info;
            startingPlayers.push(player);
            break;
          }
        }
      }
      return startingPlayers;
    },

    getPosition() {
      return this.$ScoringStats.getPositions(this.sport);
    },

    starters() {
      const startingLineup = {awayteam: this.awayStarters, hometeam: this.homeStarters};
      const finalLineup = {awayteam: {}, hometeam: {}};
      for (const category of this.$ScoringStats.getAllLiveStats(this.sport)) {
        for (const awayHome of ['awayteam', 'hometeam']) {
          if (category.label === 'Guards') {
            finalLineup[awayHome]['Guards'] = this.getGuard(startingLineup, awayHome);
          } else if (category.label === 'Forwards') {
            finalLineup[awayHome]['Forwards'] = this.getForward(startingLineup, awayHome);
          } else if (category.label === 'Centers') {
            finalLineup[awayHome]['Center'] = this.getCenter(startingLineup, awayHome);
          }
        }
      }
      return finalLineup;
    },

  },

  methods: {
    startingLineupStats(startingPlayer) {
      const statResults = startingPlayer?.statistics?.player ? this.getLineupStats(startingPlayer.statistics) : 'No Stats Available';
      return statResults;
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    getLineupStats(playerStats) {
      if (!playerStats) {
        return '';
      }

      const statsResult = [];
      const scoringStats = this.$ScoringStats.getPregameScoringStats(this.sport, 'G');
      for (const stat of scoringStats[0].stats) {
        if (this.$Helpers.parsePath(stat.label, playerStats) && this.$Helpers.parsePath(stat.label, playerStats) === '1') {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description.removePluralWords());
        } else if (parseFloat(this.$Helpers.parsePath(stat.label, playerStats)) !== 0) {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description);
        }
      }
      return statsResult.join(', ');
    },

    getGuard(inputPlayers, awayhome) {
      const guards = [];
      for (const player of inputPlayers[awayhome]) {
        if (player.gameinfo?.gamepos === 'G') {
          guards.push(player);
        }
      }
      const length = guards.length;
      for (let i = 0; i < (2 - length); i++) {
        guards.push(null);
      }
      return this.sortPlayers(guards);
    },

    getForward(inputPlayers, awayhome) {
      const forwards = [];
      for (const player of inputPlayers[awayhome]) {
        if (player.gameinfo?.gamepos === 'F') {
          forwards.push(player);
        }
      }
      const length = forwards.length;
      for (let i = 0; i < (2 - length); i++) {
        forwards.push(null);
      }
      return this.sortPlayers(forwards);
    },

    getCenter(inputPlayers, awayhome) {
      const centers = [];
      for (const player of inputPlayers[awayhome]) {
        if (player.gameinfo?.gamepos === 'C') {
          centers.push(player);
        }
      }
      if (centers.length < 1) {
        centers.push(null);
      }
      return centers;
    },

    sortPlayers(players) {
      const sortedPlayers = players.toSorted((a, b) => {
        if ((a?.position === 'PF' && b?.position === 'SF') || (a?.position === 'SG' && b?.position === 'PG')) {
          return 1;
        } else if ((a?.position === 'SF' && b?.position === 'PF') || (a?.position === 'PG' && b?.position === 'SG')) {
          return -1;
        } else {
          return 0;
        }
      });
      return sortedPlayers;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainLineup {
  margin: 0 20px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.startingLineupRow {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
}
.startingLineup {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.startingLineupCentered {
  display: flex;
  align-items: center;
}
.startingLineupStats {
  margin: 0 20px;
  max-width: 250px;
}
.startingLineupSubtext {
  font-weight: initial;
  margin: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  max-width: 300px;
}
.startingLineupSubheader {
  color: var(--obcolor-font-secondary);
  font-size: 18px;
  font-weight: initial;
  padding-bottom: 2px;
  text-align: center;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}

</style>