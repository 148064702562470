import { render, staticRenderFns } from "./LightningHowToPlaySection.vue?vue&type=template&id=2a691725&scoped=true"
import script from "./LightningHowToPlaySection.vue?vue&type=script&lang=js"
export * from "./LightningHowToPlaySection.vue?vue&type=script&lang=js"
import style0 from "./LightningHowToPlaySection.vue?vue&type=style&index=0&id=2a691725&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a691725",
  null
  
)

export default component.exports