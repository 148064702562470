<template>
  <div>
    <div class="container">
      <div class="depositTitle">We Need Your Info</div>
      <div class="yellowBox">
        <i class="far fa-info-circle"></i>
        <div>
          Check withdrawal requests may take
          <span>7-10 business</span>
          days to process.
        </div>
      </div>
      <label>
        <div class="inputText">Full Name</div>
        <input v-model="chequeInfo.fullName" placeholder="Enter Full Name" type="text">
      </label>

      <div class="inputText">Address 1</div>
      <GoogleAutocompleteStreetAddress id="map"
        v-model="chequeInfo.address1"
        maxlength="500" placeholder="Street number and name"
        type="text" @placechanged="updateAddressData" :country="['us', 'ca']"
        :enable-geolocation="true"
      />
      <label>
        <div class="inputText">Address 2</div>
        <input v-model="chequeInfo.address2" placeholder="Apartment, suite, unit, etc" type="text">
      </label>
      <div class="addressContainer">
        <div style="position: relative;">
          <div class="inputText">Country</div>
          <select v-model="chequeInfo.country" :style="chequeInfo.country === '-1' ? 'color: var(--obcolor-font-secondary);' : ''">
            <option value="-1" disabled selected>Select Country</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
          <i class="far fa-chevron-down selectArrow"></i>
        </div>

        <div style="position: relative;">
          <div class="inputText">{{ stateProvinceText }}</div>
          <select v-model="chequeInfo.region">
            <option value="-1" disabled>Select {{ stateProvinceText }}</option>
            <option v-for="(region, index) in regionList" :value="region.name" :key="index">{{ region.name }}</option>
          </select>
          <i class="far fa-chevron-down selectArrow"></i>
        </div>
      </div>
      <label>
        <div class="inputText">City</div>
        <input v-model="chequeInfo.city" placeholder="Enter City" type="text">
      </label>
      <label>
        <div class="inputText">{{ zipPostalText }} Code</div>
        <input v-model="chequeInfo.postalCode" maxlength="10" :placeholder="'Enter ' + zipPostalText + ' Code'"
          type="text"
        >
      </label>
      <div class="container">
        <ErrorPanel v-if="submitError" :message="submitError" class="withdrawError" />
        <button v-if="withdrawalSent" class="ob-btn withdrawBtn" disabled>
          Withdrawal request sent
        </button>
        <button v-else-if="waitingForResponse" disabled class="ob-btn withdrawBtn">
          <ObLoading :size="60" style="margin-left: -30px; margin-right: -5px;" />
          <span>Submitting...</span>
        </button>

        <button v-else-if="withdrawalValue < 10" disabled class="ob-btn-grey withdrawBtn">
          Minimum Withdrawal is $10
        </button>
        <!-- Button to submit the withdrawal -->
        <button v-else-if="canWithdraw" class="ob-btn withdrawBtn" @click="confirmSubmit">
          Continue
        </button>

        <!-- Disabled due to missing fields -->
        <button v-else disabled class="withdrawBtn">
          Continue
        </button>
      </div>
    </div>
    <WithdrawConfirmationModal
      @confirm="submitChequeWithdrawal"
    >
      <p slot="text" class="checkInfoText">
        Are you sure that you want to withdraw
        <br>
        <span class="moneyText">{{ withdrawalValueFormatted }} USD</span>
        by check to:
        <br>
        -----
        <br>
        <span class="blueText" style="text-transform: uppercase;">{{ chequeInfo.fullName }}</span>
        <br>
        {{ chequeInfo.address1 }},
        <br>
        {{ chequeInfo.city }}, {{ chequeInfo.region }},
        <br>
        {{ chequeInfo.postalCode }}
      </p>
    </WithdrawConfirmationModal>
    <WithdrawSuccessModal />
  </div>
</template>

<script>
import ObLoading from '@/components/ObLoading';
import ObPaymentApi from '@/api/ObPaymentApi';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import GoogleAutocompleteStreetAddress from '@/components/GoogleAutocompleteStreetAddress';
import WithdrawConfirmationModal from '@/components/modals/Financial/WithdrawConfirmationModal.vue';
import WithdrawSuccessModal from '@/components/modals/Financial/WithdrawSuccessModal.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import {validateZipPostal, formatZipPostal} from '@/utils/shared/FinancialUtils';

export default {
  components: {
    ErrorPanel,
    ObLoading,
    GoogleAutocompleteStreetAddress,
    WithdrawSuccessModal,
    WithdrawConfirmationModal,
  },

  props: {
    withdrawalValue: {type: Number, default: null},
  },

  data() {
    return {
      chequeInfo: {
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        country: '-1',
        region: '-1',
        postalCode: '',
      },

      waitingForResponse: false,
      withdrawalSent: false,
      submitError: '',
    };
  },

  computed: {
    ...mapState(['balanceValue']),

    withdrawalValueFormatted() {
      if (!this.withdrawalValue) {
        return '';
      }

      const dollarAmount = this.withdrawalValue.toFixed(2);
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount).removeCentsIfZero();
    },

    regionList() {
      return this.$GeographicInfo.getGeographicInfo(this.chequeInfo.country);
    },

    stateProvinceText() {
      if (this.chequeInfo.country === '-1') {
        return 'State/Province';
      } else if (this.chequeInfo.country === 'CA') {
        return 'Province';
      }
      return 'State';
    },

    zipPostalText() {
      if (this.chequeInfo.country === '-1') {
        return 'Zip/Postal';
      } else if (this.chequeInfo.country === 'CA') {
        return 'Postal';
      }
      return 'Zip';
    },

    canWithdraw() {
      return this.chequeInfo.fullName != '' && this.chequeInfo.address1 != '' && this.chequeInfo.city != '' &&
        this.chequeInfo.country != '-1' && this.chequeInfo.region != '-1' && this.chequeInfo.postalCode != '';
    },
  },

  watch: {
    'chequeInfo.fullName'() {
      this.submitError = '';
    },
    'chequeInfo.address1'() {
      this.submitError = '';
    },
    'chequeInfo.address2'() {
      this.submitError = '';
    },
    'chequeInfo.city'() {
      this.submitError = '';
    },
    'chequeInfo.country'() {
      this.submitError = '';
    },
    'chequeInfo.region'() {
      this.submitError = '';
    },
    'chequeInfo.postalCode'() {
      this.submitError = '';
      this.formatZipPostal();
    },
  },

  methods: {
    formatZipPostal() {
      if (!this.chequeInfo?.postalCode || !this.chequeInfo?.country) {
        return;
      }

      this.chequeInfo.postalCode = formatZipPostal(this.chequeInfo.country, this.chequeInfo.postalCode);
    },

    validateZipPostal() {
      if (!this.chequeInfo?.postalCode) {
        return;
      }
      return validateZipPostal(this.chequeInfo?.country, this.chequeInfo?.postalCode);
    },

    confirmSubmit() {
      if (this.waitingForResponse) {
        return;
      }

      const zipPostalErrors = this.validateZipPostal();
      if (zipPostalErrors) {
        this.submitError = zipPostalErrors;
        return;
      }

      EventBus.$emit('OPEN_WITHDRAWAL_CONFIRM_MODAL');
    },

    submitChequeWithdrawal() {
      if (this.waitingForResponse) {
        return;
      }
      const params = this.chequeInfo;
      // Add cents to withdrawal value
      params.amount = this.withdrawalValue * 100;
      this.waitingForResponse = true;
      ObPaymentApi.withdraw(params)
          .then((response) =>{
            this.withdrawalSent = true;
            this.waitingForResponse = false;
            // If there was an error in the previous submission this field may not be empty
            this.submitError = '';

            const balance = this.balanceValue - this.withdrawalValue * 100;
            this.$store.commit('setBalanceValue', balance);
            this.$store.commit('setAccountBalance', Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(balance / 100));
            EventBus.$emit('WITHDRAWAL_SUCCESS_OPEN_MODAL', params, response);
          })
          .catch((error) => {
            this.waitingForResponse = false;
            if (error.response && error.response.data) {
              this.submitError = error.response.data;
            } else {
              this.submitError = 'Withdrawal Error';
            }
          });
    },

    updateAddressData(addressData, _placeResultData, _id) {
      if (addressData.country == 'United States') {
        this.chequeInfo.country = 'US';
      } else {
        this.chequeInfo.country = 'CA';
      }
      // Wait for country to change
      this.$nextTick().then(() => {
        this.chequeInfo.region = this.$GeographicInfo.abbreviationToName(this.chequeInfo.country, addressData.administrative_area_level_1);
        this.chequeInfo.city = addressData.city;
        let zipCode = addressData.postal_code || '';
        zipCode = zipCode.replace(' ', '');
        this.chequeInfo.postalCode = zipCode;
        this.chequeInfo.address1 = addressData.street_address;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
.moneyText, .blueText {
  font-weight: bold;
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.checkInfoText {
  line-height: 30px;
}

.withdrawError {
  margin-top: 10px;
  margin-bottom: -20px;
}
</style>