<template>
  <div class="progressContainer">
    <LevelIcon :level="currLevel" :size="30" />
    <div class="levelProgressContainer">
      <div class="levelProgress" :style="'width: ' + progressPercent + '%;'"></div>
    </div>
    <LevelIcon :level="nextLevel" :size="30" />
  </div>
</template>

<script>
import LevelIcon from '@/components/battlepass/LevelIcon';
import {levelProgressPercent} from '@/utils/shared/BattlePassUtils';

export default {
  props: {
    userInfo: {type: Object, default: null},
  },

  components: {
    LevelIcon,
  },

  computed: {
    currLevel() {
      return this.userInfo?.currentLevel || 1;
    },

    nextLevel() {
      return !this.isMaxLevel ? this.currLevel + 1 : null;
    },

    progressPercent() {
      return levelProgressPercent(this.userInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.progressContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.levelProgressContainer {
  flex: 1;
  height: 5px;
  background: var(--obcolor-background-2);

  .levelProgress {
    height: 100%;
    background: var(--obcolor-ob-blue);
  }
}
</style>