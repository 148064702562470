/**
* Returns an error depending on if the user has an incorrect postal/zip code
* @param {String} country - User's country code (CA or US).
* @param {String} code - Zip/postal code.
* @return {String} Error text or null.
*/
export function validateZipPostal(country, code) {
  if (country == 'CA') {
    return validatePostalCode(code);
  }
  return validateZipCode(code);
}

/**
* Formats postal/zip code by reducing its length to 6 and removing spaces
* @param {String} country - User's country code (CA or US).
* @param {String} code - Zip/postal code.
* @return {String} New code.
*/
export function formatZipPostal(country, code) {
  if (country == 'CA') {
    return formatPostalCode(code);
  }
  return formatZipCode(code);
}

/**
* Formats postal code by reducing its length to 6 and removing spaces
* @param {String} code - Postal code.
* @return {String} New code.
*/
function formatPostalCode(code) {
  const postalCode = code.replace(' ', '');
  if (postalCode.length > 6) {
    return postalCode.slice(0, 6);
  }
  return postalCode;
}

/**
* Formats zip code by reducing its length to 6 and removing spaces
* @param {String} code - Zip code.
* @return {String} New code.
*/
function formatZipCode(code) {
  const zipCode = code.replace(/\D/g, '');
  if (zipCode.length > 5) {
    return `${zipCode.slice(0, 5)}-${zipCode.slice(5, 9)}`;
  }
  return zipCode;
}

/**
* Returns an error depending on if the user has an incorrect postal code
* @param {String} code - postal code.
* @return {String} Error text or null.
*/
function validatePostalCode(code) {
  const postalCode = code.replace(/\s+/g, '');
  const isValidLength = postalCode.length === 6;
  const isValidFormat = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(postalCode);
  if (isValidLength && isValidFormat) {
    return null;
  }

  return 'Please enter postal code in the format A1A1A1.';
}

/**
* Returns an error depending on if the user has an incorrect zip code
* @param {String} code - Zip code.
* @return {String} Error text or null.
*/
function validateZipCode(code) {
  if (code.length !== 5 && code.length !== 10) {
    return 'Please enter a 5-digit or 5+4 digit zip code';
  }
  return null;
}

export default {
  validateZipPostal,
};