<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3); overflow: unset !important;"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="header" class="confirmHeader">{{ title }}</div>
    <div slot="body" class="confirmBody">{{ text }}</div>
    <div slot="footer" class="confirmFooter">
      <button class="ob-btn-grey" style="margin-right: 10px;" @click="closeModal()">Cancel</button>
      <button class="ob-btn" @click="confirmModal()">{{ confirmButtonLabel }}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  props: {
    title: String,
    text: String,
    eventBusName: String,
    confirmButtonLabel: {
      type: String,
      default: 'Confirm',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    EventBus.$on(this.eventBusName, this.openModal);
  },
  destroyed() {
    EventBus.$off(this.eventBusName, this.openModal);
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    confirmModal() {
      this.isOpen = false;
      this.$emit('confirm');
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
}
.confirmBody {
  flex: 1;
  padding: 15px 10px;
  box-sizing: border-box;
}
.confirmFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>