<template>
  <div ref="settingsButton" style="position: relative;" class="clickableIcon"
    @click.stop="openDropdown()"
  >
    <slot name="button"></slot>
    <div ref="dropdown" v-if="isOpen"
      class="settingsDropdown"
      :style="dropdownPosition"
      v-click-outside="() => this.isOpen = false"
    >
      <slot name="optionList"></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import EventBus from '@/event-bus';

export default {
  props: {
    id: {type: String, default: null},
    allowClick: {type: Boolean, default: true},
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isOpen: false,
      dropdownPosition: null,
    };
  },

  created() {
    EventBus.$on('OPEN_DROPDOWN', this.checkIfDropdownOpened);
    EventBus.$on('CLOSE_DROPDOWNS', this.closeDropdown);
  },

  destroyed() {
    EventBus.$off('OPEN_DROPDOWN', this.checkIfDropdownOpened);
    EventBus.$off('CLOSE_DROPDOWNS', this.closeDropdown);
  },

  methods: {
    openDropdown() {
      if (!this.allowClick) {
        return;
      }
      EventBus.$emit('OPEN_DROPDOWN', this.id);
      this.isOpen = true;
      this.$nextTick(() => {
        this.getDropdownPosition();
      });
    },

    // If another dropdown was opened, close this one
    checkIfDropdownOpened(id) {
      if (id != this.id) {
        this.isOpen = false;
      }
    },

    closeDropdown() {
      this.isOpen = false;
    },

    getDropdownPosition() {
      // Make the function async so that it has all of the proper refs.
      const dropdown = this.$refs.dropdown;
      const container = this.$refs.settingsButton;
      const body = document.body;

      this.$nextTick(() => {
        if (dropdown && container) {
          const dimensions = dropdown.getBoundingClientRect();
          const bDimensions = body.getBoundingClientRect();
          const cDimensions = container.getBoundingClientRect();
          const newDimensions = dimensions;

          if (bDimensions.height <= cDimensions.y + dimensions.height) {
            newDimensions.y = bDimensions.height - dimensions.height;
          } else {
            newDimensions.y = cDimensions.y;
          }
          if (bDimensions.width - 1 <= cDimensions.x + dimensions.width) {
            newDimensions.x = bDimensions.width - dimensions.width - 1;
          } else {
            newDimensions.x = cDimensions.x;
          }
          this.dropdownPosition = 'position: fixed; top: ' + newDimensions.y + 'px; left: ' + newDimensions.x + 'px;';
        } else {
          this.dropdownPosition = 'position: absolute; top: 0; left: 0;';
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settingsDropdown {
  background: var(--obcolor-background-6);
  border: 2px solid var(--obcolor-background-2);
  position: absolute;
  // 1 higher than the x button in modals
  z-index: 201;
  min-width: 170px;
  color: var(--obcolor-font-primary);
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}
.settingsDropdown > div {
  padding: 15px 10px;
  box-sizing: border-box;
}
.settingsDropdown > div:nth-child(odd) {
  background: var(--obcolor-background-5);
}
.settingsDropdown > div:hover {
  background: var(--obcolor-background-2);
}
.settingsDropdown > div i {
  margin-right: 10px;
  font-size: 18px;
}

.clickableIcon {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>