<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 860px; padding: 0; overflow: auto; background: var(--obcolor-background-6);"
    bodyStyle="margin: 10px; padding: 0; overflow: unset !important;"
  >
    <div slot="header" class="modalHeader">
      <h1>{{ contestTypeText }} is available in <br><span>{{ eligibleStateCount }} States + DC + All of Canada</span></h1>
      <p class="text">If your state is highlighted in {{ stateColor }} below, you are good to go!</p>
    </div>
    <div slot="body" class="modalBody">
      <img class="map" :src="'https://storage.googleapis.com/ob-images-marketing/maps/' + contestMap + '.png'" alt="eligibility map">

      <div class="stateContainer">
        <template v-for="(state, index) in states">
          <div v-if="state.contestEligibility[contestType]" :key="index" v-tooltip.bottom="{content: state.state, class:'ObTooltip'}">
            <i class="fas fa-circle" :style="{color: circleColorStyle}"></i>
            {{ state.stateShort }}
          </div>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';

export default {
  name: 'EligibilityMapModal',

  components: {
    Modal,
  },

  data: function() {
    return {
      isOpen: false,
      states: null,
      contestType: null,
    };
  },

  created() {
    EventBus.$on('OPEN_MAP_ELIGIBILITY_MODAL', this.openModal);
    EventBus.$on('CLOSE_MAP_ELIGIBILITY_MODAL', this.closeModal);
    this.getLocationEligibility();
  },

  destroyed() {
    EventBus.$off('OPEN_MAP_ELIGIBILITY_MODAL', this.openModal);
    EventBus.$off('CLOSE_MAP_ELIGIBILITY_MODAL', this.closeModal);
  },

  computed: {
    circleColorStyle() {
      switch (this.contestType) {
        case 'LIGHTNING_LINEUPS':
          return 'var(--obcolor-blue-ll)';
        case 'SalaryCap':
          return 'var(--obcolor-green-salary-cap)';
        default:
          return 'var(--obcolor-yellow-props)';
      }
    },

    contestMap() {
      switch (this.contestType) {
        case 'LIGHTNING_LINEUPS':
          return 'map_lightninglineups';
        case 'SalaryCap':
          return 'map_salarycap';
        default:
          return 'map_playerpicks';
      }
    },

    stateColor() {
      switch (this.contestType) {
        case 'LIGHTNING_LINEUPS':
          return 'blue';
        case 'SalaryCap':
          return 'green';
        default:
          return 'orange';
      }
    },

    contestTypeText() {
      switch (this.contestType) {
        case 'LIGHTNING_LINEUPS':
          return 'Lightning Lineups';
        case 'SalaryCap':
          return 'Salary Cap';
        default:
          return 'Player Picks';
      }
    },

    eligibleStateCount() {
      // Iterate over each state and check if contest type is eligible
      // Subtract 1 for DC
      let count = -1;
      for (const state of this.states) {
        if (state.contestEligibility[this.contestType]) {
          count++;
        }
      }
      return count;
    },
  },

  methods: {
    openModal(contestType) {
      this.contestType = contestType;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    getLocationEligibility() {
      ObAccountApi.getLocationEligibility()
          .then((response) => {
            // Sort states alphabetically & create an array of sorted state objects
            const sortedStates = Object.keys(response).sort();
            this.states = sortedStates.map((state) => response[state]);
          })
          .catch((_error) => {
            // Do Nothing
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 650px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
::v-deep {
  .modal-header {
    background: var(--obcolor-background-6) !important;
  }
  .modal-body {
    margin: 0 !important;
  }
}
.modalHeader {
  text-align: center;
  background: var(--obcolor-background-6) !important;
}

h1 {
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
  margin-top: 48px;
  span {
    font-size: 26px;
    font-weight: 900;
  }
}
.text {
  margin-top: 8px;
  margin-bottom: 30px;
}
.stateContainer {
  font-size: 14px;
  max-width: 710px;
  height: 150px;
  margin: 36px auto 30px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  gap: 8px;
  cursor: default;
  .fa-circle {
    padding-right: 10px;
    font-size: 10px;
  }
}
</style>