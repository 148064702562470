<template>
  <div class="ob-page">
    <div class="headerSection">
      <div class="sectionBox llHeader">
        <div class="llTitleSection">
          <img class="llIcon" :src="require('@/assets/contestbadge/lightning_lineups.png')" alt="Lightning Lineups">
          <div class="llTitle">
            <div class="llTitleText">Lightning Lineups</div>
            <div class="howToPlayText" @click="toggleHowToPlay">How to Play</div>
            <div v-if="showHowToPlay" class="modal-mask" @click="closeHowToPlay()"></div>
            <LightningHowToPlayPopup v-if="showHowToPlay" @close="closeHowToPlay()" :contest="contest"
              :isFromBuilder="false"
            />
          </div>
        </div>
        <template v-if="contest">
          <div class="infoSection">
            <div class="infoText">{{ contest.sport }}</div>
            <div class="infoTitle">Sport</div>
          </div>
          <div class="infoSection">
            <div class="infoText">{{ contestStartFormatted }}</div>
            <div class="infoTitle">Start</div>
          </div>
          <div class="infoSection">
            <div class="infoText" :class="contest.realMoney ? 'greenText' : 'blueText'">
              <img v-if="!contest.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBuck Icon"
                style="height: 10px;"
              >
              {{ contest.fee.removeCentsIfZero() }}
            </div>
            <div class="infoTitle">Entry</div>
          </div>
          <div class="infoSection">
            <div class="infoText payoutLineupRemoved">
              <span v-if="hasNewMultiplier()" class="payoutCrossout">{{ contest.originalPayoutMultiplier }}x</span>
              <span>{{ contest.payoutMultiplier }}x</span>
            </div>
            <div class="infoTitle">Payout</div>
          </div>
          <div v-if="contest.state === 'CANCELLED'" class="infoSection">
            <div class="infoText redTextCancel">Cancelled</div>
            <div class="infoTitle">Status</div>
          </div>
          <div v-else class="infoSection">
            <div class="infoText" :class="contest.realMoney ? 'greenText' : 'blueText'">
              <img v-if="!contest.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                style="height: 10px;"
              >
              {{ contest.payout.removeCentsIfZero() }}
            </div>
            <div class="infoTitle">Prize</div>
          </div>
        </template>
      </div>
      <div class="sectionBox lineupBuilderLinkSection">
        <img class="llIcon" style="height: 70px;" :src="require('@/assets/contestbadge/lightning_lineups.png')"
          alt="Lightning Lineups"
        >
        <template v-if="hasContestStarted">
          <div class="llActionText">More action is a click away</div>
          <router-link :to="sport != null ? '/lightning-lineups/builder/' + sport : '/lobby'" class="ob-btn llSpinBtn">Spin Now</router-link>
        </template>
        <template v-else>
          <div class="llShareText">Share contest with friends!</div>
          <button @click="openInviteModal()" class="ob-btn shareContestLink">
            <i class="fas fa-share-square"></i>
            <div>Share Contest</div>
          </button>
        </template>
      </div>
    </div>
    <div class="bodySection">
      <LightningLiveLeaderboard class="sectionBox leaderboardContainer ob-scroll" :contest="contest" :allTeams="allTeams"
        :selectedTeamIndex="selectedTeamIndex"
      />
      <LightningLiveTable v-if="userTeam != null" class="sectionBox liveContainer ob-scroll" :contest="contest"
        :oppTeam="selectedTeam"
        :userTeam="userTeam"
      />
    </div>
  </div>
</template>

<script>
import ObLightningLineupsApi from '@/api/ObLightningLineupsApi';
import LightningLiveLeaderboard from './LightningLiveLeaderboard.vue';
import LightningLiveTable from './LightningLiveTable.vue';
import LightningHowToPlayPopup from '@/views/LightningLineups/LineupBuilder/LightningHowToPlayPopup';
import EventBus from '@/event-bus';

export default {
  components: {
    LightningLiveLeaderboard,
    LightningLiveTable,
    LightningHowToPlayPopup,
  },

  data() {
    return {
      contestId: null,
      userId: null,
      contest: null,
      selectedTeamIndex: null,
      showHowToPlay: false,
    };
  },

  created() {
    this.contestId = this.$route.params.contestId;
    this.getLLEntry(true);
    EventBus.$on('LL_LIVE_SELECT_TEAM', this.setSelectedTeam);
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('LL_SCORE_UPDATE_' + this.userId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('LL_LIVE_SELECT_TEAM', this.setSelectedTeam);
  },

  computed: {
    contestStartFormatted() {
      if (!this.contest || !this.contest.contestStart) {
        return;
      }

      // Show full date if there's more than 1 day difference in the past
      if (this.$moment().diff(this.$moment(this.contest.contestStart), 'hours') >= 24) {
        return this.$moment(this.contest.contestStart).format('MMM D, YYYY');
      }
      // Also show full date if there's a 7 day difference in the future
      if (this.$moment().diff(this.$moment(this.contest.contestStart), 'days') <= -7) {
        return this.$moment(this.contest.contestStart).format('MMM D, YYYY');
      }

      return this.$moment(this.contest.contestStart).format('ddd h:mma');
    },

    hasContestStarted() {
      if (!this.contest || !this.contest.contestStart) {
        return true;
      }

      return this.$moment().diff(this.$moment(this.contest.contestStart), 'seconds') >= 0;
    },

    sport() {
      if (!this.contest) {
        return null;
      }
      return this.contest.sport;
    },

    selectedTeam() {
      if (!this.allTeams || this.selectedTeamIndex == null) {
        return null;
      }
      return this.allTeams[this.selectedTeamIndex];
    },

    userTeam() {
      if (!this.contest || !this.contest.userTeam) {
        return null;
      }
      return this.contest.userTeam;
    },

    allTeams() {
      if (!this.contest || !this.contest.obTeams || !this.contest.userTeam) {
        return [];
      }

      const teamsList = this.contest.obTeams.slice();
      // Flag so that we can put the star in the user's team
      const userTeam = this.contest.userTeam;
      userTeam.isUserTeam = true;
      teamsList.push(userTeam);
      teamsList.sort((t1, t2) => {
        return t1.rank - t2.rank;
      });

      if (this.contest && this.contest.hasRemovedObTeams) {
        return teamsList.concat(this.contest.removedObTeams);
      }

      return teamsList;
    },
  },

  methods: {
    getLLEntry(firstLoad) {
      return ObLightningLineupsApi.getContest(this.contestId)
          .then((response) => {
            this.contest = response;
            this.userId = response.userId;
            if (firstLoad) {
              this.selectedTeamIndex = null;
              EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
              this.$SocketController.subscribeToRoom('LL_SCORE_UPDATE_' + this.userId);
            }
          }).catch((_error) => {
            // Do nothing
          });
    },

    setSelectedTeam(teamIndex) {
      if ((this.allTeams && this.allTeams[teamIndex].isUserTeam) || teamIndex == this.selectedTeamIndex) {
        this.selectedTeamIndex = null;
        return;
      }
      this.selectedTeamIndex = teamIndex;
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_LL', this.contest);
    },

    toggleHowToPlay() {
      this.showHowToPlay = !this.showHowToPlay;
    },

    closeHowToPlay() {
      this.showHowToPlay = false;
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'LL_SCORE_UPDATE' && data.json.id == this.contestId) {
        this.getLLEntry(false);
      }
    },

    hasNewMultiplier() {
      return this.contest.originalPayoutMultiplier !== this.contest.payoutMultiplier;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.ob-page {
  min-width: 1270px;
  min-height: 400px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.headerSection {
  display: flex;
  width: 100%;
}

.sectionBox {
  display: flex;
  background: var(--obcolor-background-6);
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;

  &:not(:first-child) {
    margin-left: 10px;
  }
}

.llHeader {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.llTitleSection {
  display: flex;
  align-items: center;
}

.llIcon {
  height: 80px;
  margin-right: 10px;
}

.llTitleText {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

.howToPlayText {
  cursor: pointer;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  text-decoration: underline;
}

.infoTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}

.infoText {
  font-size: 16px;
  padding-bottom: 3px;
}

p {
  margin: 0;
  font-size: 16px;
  padding-bottom: 3px;
}

.payoutLineupRemoved {
  font-size: 16px;
  display: flex;
  gap: 2px;
  color: var(--obcolor-text-primary);
}

.payoutCrossout {
  text-decoration: line-through;
  color: var(--obcolor-font-secondary);
  margin-right: 2px;
  font-size: 14px;
  align-self: flex-end;
}

.lineupBuilderLinkSection {
  align-items: center;
  justify-content: space-between;
  width: 400px;
}

.llActionText {
  width: 160px;
  font-size: 16px;
}

.llSpinBtn {
  padding: 10px 20px;
}

.llShareText {
  width: 125px;
  font-size: 16px;
}

.bodySection {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  min-height: 0;
}

.leaderboardContainer {
  max-height: 100%;
  min-width: 574px;
  padding: 0;
  overflow: auto;
}

.liveContainer {
  max-height: 100%;
  min-width: 695px;
  padding: 0;
  flex: 1;
  overflow: auto;
}

.shareContestLink {
  flex: 1;
  margin-top: 5px;
  width: 100%;
  max-height: 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .fa-share-square {
    margin-right: 6px;
    padding-bottom: 1px;
  }

  > div {
    padding-top: 2px;
  }
}

.greenText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.redTextCancel {
  color: var(--obcolor-red);
  font-weight: 700;
}
</style>