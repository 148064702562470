<template>
  <span :class="{discounted: isDiscounted}">
    <span class="original" :class="{flash: flashOriginal}">{{ originalLine }}</span>{{ " " }}
    <span v-if="isDiscounted" class="discount" :class="{highlight, flash: flashDiscount}">{{ discountLine }}</span>
  </span>
</template>

<script>
export default {
  props: {
    market: {
      type: Object,
      default: null,
    },
    pick: {
      type: Object,
      default: null,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    flashDiscount: {
      type: Boolean,
      default: false,
    },
    flashOriginal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDiscounted() {
      return this.market?.isDiscounted ?? this.pick?.isDiscounted;
    },
    originalLine() {
      if (this.market) {
        return this.market?.line.balancedLine;
      }
      if (this.pick) {
        if (this.pick.isDiscounted) {
          return this.pick.discount.originalLine;
        } else {
          return this.pick.balancedLine;
        }
      }
      return undefined;
    },
    discountLine() {
      if (this.market) {
        return this.market?.discount.discountLine;
      }
      if (this.pick?.isDiscounted) {
        return this.pick.balancedLine;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss">
.discounted {
  .original {
    display: inline-block;
    text-decoration: line-through;
    color: var(--obcolor-font-secondary);
    font-size: 10px;
    line-height: 1;
    transform: translate(0, -4px);
  }
  .discount {
    line-height: 1;
    &.highlight {
      color: var(--obcolor-green);
    }
  }
}

.flash {
  color: var(--obcolor-flash-orange) !important;
}
</style>