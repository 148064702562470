import Vue from 'vue';
import Vuex from 'vuex';
import {BrazeModule} from './braze';
import {PlayerPicksModule} from './player-picks';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfoLoaded: false,
    isLoggedIn: false,
    userId: null,
    userName: null,
    userEmail: null,
    userState: null,
    accountBalance: '',
    balanceValue: 0,
    ownersbucks: '',
    ownersbucksValue: 0,
    tickets: [],
    numTickets: 0,
    ticketFeeLookup: {},
    socialContent: null,
    contestCutoffs: null,
    rankNum: null,
    accountCountry: null,
    newsFeed: null,
    teamNames: {},
    firstName: '',
    lastName: '',
    email: '',
    // Amount of contests user can still enter (in an object), and money user can still spend on contests.
    contestLimits: null,
    noContestRestrictions: false,
    theme: 'light',
    userLogoVersion: '0',
    showBetaDisclaimer: true,
    notificationCount: 0,
    nodeServer: null,
    nodeToken: null,
    isScoreboardVisible: true,

    isDepositVerified: null,
    hasDeposited: null,
    hasEnteredContests: null,
    isFantasyProsUser: false,
    leaguesCountALL: 0,
    leaguesCountScALL: 0,
    leaguesCountBySport: {},
    sportNumContests: {},
    draftPriority: {},
    krogerId: null,
    isKrogerAffiliate: null,
    showConnections: false,

    contestCheckIsLoaded: false,
    contestCheckTs: null,

    // Live Game Data
    todaysGames: {},

    // Login with Google / Facebook
    // Used when redirecting to sign up page after oauth login
    // Pre-populates the email field
    oAuthProvider: null,
    oAuthEmail: null,

    redirectedLink: null,
    redirectedRoute: null,
    recipientId: null,
    geoLoadedTs: null,

    battlePass: null,
    loadingBattlePass: false,
    firstBattlePassLoad: true,

    // Get only variable that shows the default route when logged in
    defaultRoute: '/player-picks/lobby',
    lastVisitedPage: null,
    CP: null,

    // GeoComply
    geoComplyLicense: null,
    geoPackets: {},
    geoPacketExpiry: {},
  },
  mutations: {
    setAccountInfoLoaded(state, loaded) {
      state.accountInfoLoaded = loaded;
    },
    setLoggedIn(state, userId) {
      if (userId != null) {
        state.isLoggedIn = true;
        state.userId = userId;
        state.userState = null;
      }
    },
    setUserState(state, userState) {
      state.userState = userState;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setUserEmail(state, userEmail) {
      state.userEmail = userEmail;
    },
    setAccountBalance(state, balance) {
      state.accountBalance = balance;
    },
    setBalanceValue(state, balanceValue) {
      state.balanceValue = balanceValue;
    },
    setOwnersBucks(state, balance) {
      state.ownersbucks = balance;
    },
    setOwnersBucksValue(state, ownersbucksValue) {
      state.ownersbucksValue = ownersbucksValue;
    },
    setIsKrogerAffiliate(state, isKrogerAffiliate) {
      state.isKrogerAffiliate = isKrogerAffiliate;
    },
    setShowConnections(state, showConnections) {
      state.showConnections = showConnections;
    },
    setTickets(state, tickets) {
      const autoTickets = tickets.slice().filter((t) => t.autoApply === true);
      state.tickets = autoTickets;
      state.numTickets = autoTickets.length;

      // Setup all and sport based objects for quick lookup
      const ticketObj = {};
      ticketObj.all = {
        LIGHTNING_LINEUPS: {realMoney: {}, ownersBucks: {}},
        SalaryCap: {realMoney: {}, ownersBucks: {}},
        all: {realMoney: {}, ownersBucks: {}},
      };

      const sports = ['NHL', 'MLB', 'NFL', 'NBA'];
      for (const sport of sports) {
        ticketObj[sport] = {
          LIGHTNING_LINEUPS: {realMoney: {}, ownersBucks: {}},
          SalaryCap: {realMoney: {}, ownersBucks: {}},
          all: {realMoney: {}, ownersBucks: {}},
        };
      }

      // // Set the cents amount flags for if the ticket exists
      for (const ticket of autoTickets) {
        const feeType = ticket.realMoney ? 'realMoney' : 'ownersBucks';
        const contestType = ticket.contestType || 'all';
        if (ticket.sport !== '') {
          ticketObj[ticket.sport][contestType][feeType][ticket.feeValue] = ticket;
        } else {
          ticketObj.all[contestType][feeType][ticket.feeValue] = ticket;
        }
      }

      state.ticketFeeLookup = ticketObj;
    },
    setSocialContent(state, socialContent) {
      state.socialContent = socialContent;
    },
    setContestCutoffs(state, contestCutoffs) {
      state.contestCutoffs = contestCutoffs;
    },
    setRankNum(state, rankNum) {
      state.rankNum = rankNum;
    },
    setContestLimits(state, contestLimits) {
      state.contestLimits = contestLimits;
    },
    setNewsFeed(state, newsFeed) {
      state.newsFeed = newsFeed;
    },
    setTeamNames(state, teamNames) {
      state.teamNames = teamNames;
    },
    setLoggedOut(state) {
      state.isLoggedIn = false;
      state.userId = null;
      state.userState = null;
      state.accountBalance = '';
      state.socialContent = null;
      state.contestLimits = null;
      state.newsFeed = null;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setUserLogoVersion(state, userLogoVersion) {
      state.userLogoVersion = userLogoVersion;
    },
    setShowBetaDisclaimer(state, showBetaDisclaimer) {
      state.showBetaDisclaimer = showBetaDisclaimer;
    },
    setNotificationCount(state, count) {
      state.notificationCount = count;
    },
    setNodeServer(state, nodeServer) {
      state.nodeServer = nodeServer;
    },
    setNodeToken(state, nodeToken) {
      state.nodeToken = nodeToken;
    },
    setTodaysGames(state, games) {
      state.todaysGames = games;
    },
    setNoContestRestrictions(state, value) {
      state.noContestRestrictions = value;
    },
    setIsScoreboardVisible(state, value) {
      state.isScoreboardVisible = value;
    },
    setIsDepositVerified(state, value) {
      state.isDepositVerified = value;
    },
    setHasDeposited(state, value) {
      state.hasDeposited = value;
    },
    setHasEnteredContests(state, value) {
      state.hasEnteredContests = value;
    },
    setIsFantasyProsUser(state, value) {
      state.isFantasyProsUser = value;
    },
    setKrogerId(state, value) {
      state.krogerId = value;
    },
    setLeaguesCountALL(state, value) {
      state.leaguesCountALL = value;
    },
    setLeaguesCountScALL(state, value) {
      state.leaguesCountScALL = value;
    },
    setLeaguesCountBySport(state, value) {
      state.leaguesCountBySport = value;
    },
    setSportNumContests(state, value) {
      state.sportNumContests = value;
    },
    setDraftPriority(state, value) {
      state.draftPriority = value;
    },
    setOAuthEmail(state, value) {
      state.oAuthEmail = value;
    },
    setOAuthProvider(state, value) {
      state.oAuthProvider = value;
    },
    setRedirectedRoute(state, value) {
      if (value && value.fullPath) {
        state.redirectedLink = value.fullPath;
      } else {
        state.redirectedLink = null;
      }
      state.redirectedRoute = value;
    },
    setContestCheckState(state, value) {
      if (value) {
        state.contestCheckIsLoaded = value.loaded;
        state.contestCheckTs = value.ts;
      }
    },
    setAccountCountry(state, value) {
      state.accountCountry = value;
    },
    setRecipientId(state, value) {
      state.recipientId = value;
    },
    setGeoLoadedTs(state, value) {
      state.geoLoadedTs = value;
    },
    setBattlePass(state, value) {
      state.battlePass = value;
    },
    setLoadingBattlePass(state, value) {
      state.loadingBattlePass = value;
    },
    setFirstBattlePassLoad(state, value) {
      state.firstBattlePassLoad = value;
    },
    setFirstName(state, value) {
      state.firstName = value;
    },
    setLastName(state, value) {
      state.lastName = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setLastVisitedPage(state, page) {
      state.lastVisitedPage = page;
    },
    setCP(state, cpInstance) {
      state.CP = cpInstance;
    },
    setGeoComplyLicense(state, value) {
      state.geoComplyLicense = value;
    },
    setGeoPackets(state, value) {
      state.geoPackets = value;
    },
    setGeoPacketExpiry(state, value) {
      state.geoPacketExpiry = value;
    },
  },
  getters: {
    accountInfoLoaded: (state) => state.accountInfoLoaded,
    isLoggedIn: (state) => state.isLoggedIn,
    userId: (state) => state.userId,
    userName: (state) => state.userName,
    userEmail: (state) => state.userEmail,
    userState: (state) => state.userState,
    accountBalance: (state) => state.accountBalance,
    balanceValue: (state) => state.balanceValue,
    ownersbucks: (state) => state.ownersbucks,
    ownersbucksValue: (state) => state.ownersbucksValue,
    tickets: (state) => state.tickets,
    numTickets: (state) => state.numTickets,
    ticketFeeLookup: (state) => state.ticketFeeLookup,
    accountCountry: (state) => state.accountCountry,
    socialContent: (state) => state.socialContent,
    contestCutoffs: (state) => state.contestCutoffs,
    rankNum: (state) => state.rankNum,
    contestLimits: (state) => state.contestLimits,
    newsFeed: (state) => state.newsFeed,
    teamNames: (state) => state.teamNames,
    theme: (state) => state.theme,
    userLogoVersion: (state) => state.userLogoVersion,
    showBetaDisclaimer: (state) => state.showBetaDisclaimer,
    notificationCount: (state) => state.notificationCount,
    nodeServer: (state) => state.nodeServer,
    nodeToken: (state) => state.nodeToken,
    noContestRestrictions: (state) => state.noContestRestrictions,
    isScoreboardVisible: (state) => state.isScoreboardVisible,
    isDepositVerified: (state) => state.isDepositVerified,
    hasDeposited: (state) => state.hasDeposited,
    hasEnteredContests: (state) => state.hasEnteredContests,
    isFantasyProsUser: (state) => state.isFantasyProsUser,
    leaguesCountALL: (state) => state.leaguesCountALL,
    leaguesCountScALL: (state) => state.leaguesCountScALL,
    leaguesCountBySport: (state) => state.leaguesCountBySport,
    sportNumContests: (state) => state.sportNumContests,
    draftPriority: (state) => state.draftPriority,
    contestCheckIsLoaded: (state) => state.contestCheckIsLoaded,
    contestCheckTs: (state) => state.contestCheckTs,
    todaysGames: (state) => state.todaysGames,
    oAuthEmail: (state) => state.oAuthEmail,
    oAuthProvider: (state) => state.oAuthProvider,
    redirectedLink: (state) => state.redirectedLink,
    redirectedRoute: (state) => state.redirectedRoute,
    recipientId: (state) => state.recipientId,
    defaultRoute: (state) => state.recipientId,
    geoLoadedTs: (state) => state.geoLoadedTs,
    battlePass: (state) => state.battlePass,
    loadingBattlePass: (state) => state.loadingBattlePass,
    firstBattlePassLoad: (state) => state.firstBattlePassLoad,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    email: (state) => state.email,
    krogerId: (state) => state.krogerId,
    isKrogerAffiliate: (state) => state.isKrogerAffiliate,
    showConnections: (state) => state.showConnections,
    CP: (state) => state.CP,
    geoComplyLicense: (state) => state.geoComplyLicense,
    geoPackets: (state) => state.geoPackets,
    geoPacketExpiry: (state) => state.geoPacketExpiry,
  },
  actions: {},
  modules: {
    BrazeModule,
    playerPicks: PlayerPicksModule,
  },
});
