<template>
  <div class="ob-main-nav">
    <div class="ob-nav">
      <div class="logoContainer">
        <img class="ob-logo" :src="OBLogo" alt="OwnersBox logo">
        <div class="sponsorText" v-if="sponsor.imageUrl">
          <div class="sponsoredBy">Sponsored by</div>
          <SponsorImage :sponsor="sponsor" height="28px" maxWidth="50px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObSponsorApi from '@/api/ObSponsorApi';
import {mapState} from 'vuex';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  components: {
    SponsorImage,
  },

  computed: {
    ...mapState([
      'theme',
    ]),
    OBLogo() {
      if (!this.isDarkMode) {
        return require('@/assets/logo_nofantasy_black.png');
      }
      return require('@/assets/logo_nofantasy_white.png');
    },
  },

  data: function() {
    return {
      isDarkMode: false,
      sponsor: {},
    };
  },

  mounted() {
    this.isDarkMode = this.theme === 'dark';
    this.loadSponsor();
  },

  methods: {
    loadSponsor() {
      ObSponsorApi.getSponsor('navbar-header')
          .then((response) => {
            this.sponsor = response;
          }).catch((_e) => {
            // Suppress console error
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.ob-main-nav {
  height: $ob-nav-height;
  background: var(--obcolor-page-header);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 89;
}

.ob-nav {
  display: flex;
  align-items: center;
  max-width: $ob-maxwidth;
  height: 100%;
  margin: auto;
  padding: 0 15px;
}

.logoContainer {
  padding: 5px 0;
  margin-top: 4px;
  margin-right: 15px;
}

.ob-logo {
  width: 216px;
  border-radius: 6px;
}

.sponsorText {
  margin-top: -5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding-left: 43px;

  .sponsoredBy {
    font-size: 14px;
    margin: 5px 0 0 5px;
  }
}
</style>