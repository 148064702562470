<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 413px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
    bodyStyle="padding-bottom: 0;"
  >
    <div slot="body" class="confirmBody">
      <h2>Withdrawal</h2>
      <div class="textContent">
        <slot name="text"></slot>
      </div>
      <div class="modalButtonContainer">
        <button class="ob-btn-grey" @click="closeModal()">Cancel</button>
        <button class="ob-btn" @click="confirmModal()">Confirm</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    EventBus.$on('OPEN_WITHDRAWAL_CONFIRM_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_WITHDRAWAL_CONFIRM_MODAL', this.openModal);
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    confirmModal() {
      this.isOpen = false;
      this.$emit('confirm');
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/style/Modals/modals.scss';

.confirmBody {
  text-align: left;
  margin-top: 30px;
}

h2, .textContent {
  padding: 0 30px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.textContent {
  line-height: 24px;
  font-size: 16px;
}

.modalButtonContainer {
  margin-top: 20px;
}
</style>