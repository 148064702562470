<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 600px; min-height: 448px; padding: 0; overflow: hidden; border: none;"
    bodyStyle="background: var(--obcolor-background-3); padding: 10px 20px;"
    :defaultHeaderStyle="true"
    :hideCloseButton="isDepositVerified === false"
    :disableClickaway="true"
  >
    <div slot="header">Account Verification</div>
    <VerifyAccountBody slot="body" />
    <VerifyAccountFooter slot="footer" style="padding: 10px 20px;" />
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import VerifyAccountBody from '@/views/VerifyAccount/VerifyAccountBody';
import VerifyAccountFooter from '@/views/VerifyAccount/VerifyAccountFooter';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
    VerifyAccountBody,
    VerifyAccountFooter,
  },

  data() {
    return {
      isOpen: false,
      isDepositVerified: null,
    };
  },

  created() {
    EventBus.$on('OPEN_VERIFY_ACCOUNT_MODAL', this.openModal);
    EventBus.$on('CLOSE_VERIFY_COMPONENT', this.closeModal);
  },

  destroyed() {
    EventBus.$off('OPEN_VERIFY_ACCOUNT_MODAL', this.openModal);
    EventBus.$off('CLOSE_VERIFY_COMPONENT', this.closeModal);
  },

  methods: {
    openModal(isDepositVerified) {
      this.isOpen = true;
      this.isDepositVerified = isDepositVerified;
    },

    closeModal() {
      if (this.isDepositVerified === true) {
        // User is verified, recheck location
        EventBus.$emit('DEPOSIT_CHECK_LOCATION');
      }
      this.isOpen = false;
    },

  },
};
</script>

<style lang="scss" scoped>
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>