<template>
  <div class="itemList">
    <div class="selectContainer" v-for="(type, index) in contestTypes"
      :class="{selected: selected !== null && selected.id === type.id}"
      @click="selectType(type)" :key="index"
    >
      <div class="title">
        {{ type.label }}
      </div>
      <div class="description">{{ type.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contestTypes: {type: Array, default: null},
    selected: {type: Object, default: null},
  },

  methods: {
    selectType(format) {
      this.$emit('select', format);
      this.$emit('nextStep');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.selectContainer {
  width: 320px;
  height: 100px;
}

.selectContainer:nth-child(3),
.selectContainer:nth-child(4) {
  margin-top: 0;
}
</style>