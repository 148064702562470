<template>
  <div class="graphContainer" :class="direction == 'row' ? 'rowContainer' : 'colContainer'">
    <div class="graphText" v-if="textPosition == null || textPosition == 'top'">{{ playersCurrent.abbreviateLongNumber() }} of {{ playersMax.abbreviateLongNumber() }}</div>
    <div class="progressBarContainer" v-if="format === 'Tournament'">
      <div class="fullText">{{ fullPercent }}%</div>
      <div class="progressBar">
        <div class="progress" :style="'width: ' + fullPercent + '%;' + (playerJoined ? 'background: var(--obcolor-flash-orange);' : '')"></div>
      </div>
    </div>
    <div class="bracketProgressContainer" v-else>
      <div v-for="(pairSet, index) in pairs" :key="index" class="pairContainer">
        <div class="bracketConnector"></div>
        <div class="pairFillContainer">
          <div v-for="(pair, index1) in pairSet" :key="index1" class="pair">
            <div v-for="n in pair.size" :key="n"
              class="pairFillBox" :class="{ pairFilled: pair.nFilled >= n }"
              :style="pair.nFilled >= n && playerJoined ? 'background: var(--obcolor-flash-orange);' : ''"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="graphText" style="margin-top: 4px;" v-if="textPosition == 'bottom'">{{ playersCurrent }} of {{ playersMax }}</div>
  </div>
</template>

<script>
export default {
  props: {
    playersCurrent: Number,
    playersMax: Number,
    format: String,
    playerJoined: Boolean,
    textPosition: String,
    direction: String,
  },

  computed: {
    fullPercent() {
      if (!this.playersMax || !this.playersCurrent) {
        return 0;
      }

      return Math.floor((this.playersCurrent / this.playersMax) * 100);
    },

    pairs() {
      if (this.playersMax == 2) {
        return [[
          {size: 1, nFilled: this.playersCurrent > 0 ? 1 : 0},
          {size: 1, nFilled: this.playersCurrent > 1 ? 1 : 0},
        ]];
      }

      if (!this.playersMax || this.playersMax % 4 != 0) {
        return [];
      }

      const pairArray = [];
      // We know that it's divisible by 2 from the mod check at the start
      for (let i = 0; i < this.playersMax / 4; ++i) {
        const numChecking = (i+1)*4;
        if (numChecking <= this.playersCurrent) {
          pairArray.push([
            {size: 2, nFilled: 2},
            {size: 2, nFilled: 2},
          ]);
        } else if (numChecking - this.playersCurrent < 4) {
          const diff = this.playersCurrent % 4;
          // Each section of the pair is filled to a minimum of 0, and a maximum of 2
          const fill1 = diff > 2 ? 2 : diff;
          const fill2 = diff > 2 ? diff - 2 : 0;

          pairArray.push([
            {size: 2, nFilled: fill1},
            {size: 2, nFilled: fill2},
          ]);
        } else {
          pairArray.push([
            {size: 2, nFilled: 0},
            {size: 2, nFilled: 0},
          ]);
        }
      }
      return pairArray;
    },
  },
};
</script>

<style lang="scss" scoped>
  .graphContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .graphContainer.rowContainer {
    flex-direction: row;
  }

  .progressBarContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .rowContainer .graphText {
    margin: 5px 5px 0 5px;
  }

  .graphText {
    font-weight: bold;
  }

  .fullText {
    color: var(--obcolor-font-secondary);
    font-size: 11px;
    margin-top: 2px;
    margin-left: -6px;
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
  }

  .progressBar {
    background: var(--obcolor-timeline);
    height: 8px;
    flex: 1;
    margin-left: 5px;
    border-radius: 10px;
    overflow: hidden;
  }
  .progress {
    background: var(--obcolor-ob-blue);
    height: 100%;
    transition: 400ms ease-out;
  }

  .bracketProgressContainer {
    display: flex;
  }

  .pairContainer {
    width: 100%;
    margin: 0 2px;
  }

  .bracketConnector {
    margin: 0 8px 1px 8px;
    width: calc(100% - 15px);
    box-sizing: border-box;
    height: 3px;
    border-top: 1px solid var(--obcolor-timeline);
    border-left: 1px solid var(--obcolor-timeline);
    border-right: 1px solid var(--obcolor-timeline);
  }

  .pairFillContainer {
    display: flex;
  }

  .pair {
    display: flex;
    width: 16px;
    justify-content: center;
  }

  .pair:not(:last-child) {
    margin-right: 4px;
  }

  .pairFillBox {
    background: var(--obcolor-timeline);
    height: 12px;
    width: 7px;
    max-width: 7px;
    min-width: 7px;
    border-radius: 2px;
    transition: 400ms ease-out;
  }

  .pairFillBox:not(:last-child) {
    margin-right: 1px;
  }

  .pairFillBox.pairFilled {
    background: var(--obcolor-ob-blue);
  }
</style>