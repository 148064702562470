<template>
  <div class="validationContainer">
    <ObLoading :size="70" v-if="validationState.isSending" />
    <div v-else class="pendingTransactionsBox">
      <template v-if="!submitState.result">
        <div class="boxTitle">Pending Transactions</div>
        <div class="boxSubTitle">Note: Transactions only occur for the {{ selectedSlate.name }} slate.</div>
      </template>
      <template v-else>
        <div class="resultsText greenText" v-if="numSuccess">
          <i class="infoIcon fas fa-check-circle"></i>
          {{ numSuccess }} Swap{{ numSuccess > 1 ? 's' : '' }} Succeeded
        </div>
        <div class="resultsText redText" v-if="numFailed">
          <i class="infoIcon fas fa-exclamation-circle"></i>
          {{ numFailed }} Swap{{ numFailed > 1 ? 's' : '' }} Failed
        </div>
      </template>
      <QuickSwapPendingTransactions :swapPlayers="swapPlayers" :selectedSlate="selectedSlate" />
    </div>
    <QuickSwapValidationList :validationState="validationState" :submitState="submitState" :selectedSlate="selectedSlate" />
  </div>
</template>

<script>
import QuickSwapPendingTransactions from './QuickSwapPendingTransactions';
import QuickSwapValidationList from './QuickSwapValidationList';
import ObLoading from '@/components/ObLoading';

export default {
  props: {
    selectedSlate: {type: Object, default: null},
    swapPlayers: {type: Object, default: null},
    validationState: {type: Object, default: null},
    submitState: {type: Object, default: null},
  },

  components: {
    ObLoading,
    QuickSwapPendingTransactions,
    QuickSwapValidationList,
  },

  computed: {
    numSuccess() {
      if (!this.submitState.result) {
        return 0;
      }

      return this.submitState.result.numTeams - this.numFailed;
    },

    numFailed() {
      if (!this.submitState.result) {
        return 0;
      }

      return this.submitState.result.numFailed;
    },
  },
};
</script>

<style lang="scss" scoped>
.validationContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.resultsText {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.resultsText:not(:first-child) {
  margin-top: 10px;
}

.infoIcon {
  margin-right: 8px;
  font-size: 18px;
}

.pendingTransactionsBox {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  .boxTitle {
    font-size: 16px;
    font-weight: bold;
  }

  .boxSubTitle {
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }
}

.greenText {
  color: var(--obcolor-green);
}

.redText {
  color: var(--obcolor-red);
}
</style>