import Api from '@/api/ObBaseApi';

export default {
  validateCode(contestId, code) {
    const params = {
      contestId: contestId,
      code: code,
    };
    return Api()
        .post('/fsp/promocode/validate', params)
        .then((response) => {
          return response.data;
        });
  },

  validateLightningLineup(sport, code) {
    const params = {
      sport: sport,
      code: code,
    };
    return Api()
        .post('/fsp/promocode/validateLightningLineup', params)
        .then((response) => {
          return response.data;
        });
  },

  validatePlayerPicks(code) {
    const params = {
      code: code,
    };
    return Api()
        .post('/fsp/promocode/validatePlayerPicks', params)
        .then((response) => {
          return response.data;
        });
  },

  getAutoAppliedLLPromoCode(sport) {
    return Api()
        .get('/fsp/promocode/getAutoAppliedLLPromoCode?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  getAutoAppliedSCPromoCode(contestId) {
    if (!contestId || contestId == '') {
      return;
    }
    return Api()
        .get('/fsp/promocode/getAutoAppliedSCPromoCode?contestId=' + contestId)
        .then((response) => {
          return response.data;
        });
  },
};
