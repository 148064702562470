<template>
  <tr>
    <td>
      <img class="sportImage" :src="require('@/assets/icons/sporticon_' + sport + '.png')" alt="Sport Icon">
    </td>
    <td>
      <div class="lineupContainer">
        <div v-for="(id, index) in players" :key="index" class="player">
          <div v-if="validPlayer(id)" class="playerActive" @click.stop="openPlayerCard(id, sport, contestId)">
            <span>{{ getPlayerName(id) }}</span>
            <span v-if="index !== lineup.players.length - 1">,</span>
          </div>
          <div v-else>
            <span class="playerInactive">{{ id }}</span>
            <span v-if="index !== lineup.players.length - 1">,</span>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="centered salary" :class="{salaryNegative: remainingSalary < 0}">{{ remainingSalary.formatMoney() }}</div>
    </td>
    <td class="projection">{{ totalProjection }}</td>
    <td class="checkboxContainer">
      <div v-if="lineup.success" class="ob-checkbox-container" @click="toggleSubmitEditLineup()">
        <input type="checkbox" :checked="submit">
        <div class="ob-checkbox"></div>
      </div>
      <div v-else v-tooltip.bottom="{content: errorMessage, class:'ObTooltip'}">
        <i class="fas fa-exclamation-circle fa-lg"></i>
      </div>
    </td>
  </tr>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  props: {
    lineup: {type: Object, default: null},
    contest: {type: Object, default: null},
    playerMap: {type: Object, default: null},
    submit: {type: Boolean, default: false},
  },

  computed: {
    salaryCap() {
      if (!this.contest || !this.contest.salaryCap) {
        return 0;
      }
      return this.contest.salaryCap;
    },

    contestId() {
      if (!this.contest || !this.contest.id) {
        return 0;
      }
      return this.contest.id;
    },

    sport() {
      if (!this.contest || !this.contest.sport) {
        return '';
      }
      return this.contest.sport;
    },

    players() {
      if (!this.lineup || !this.lineup.playerIds) {
        return [];
      }
      return this.lineup.playerIds;
    },

    remainingSalary() {
      let remainingSalary = this.salaryCap;

      for (const id of this.players) {
        if (this.playerMap[id]) {
          remainingSalary -= this.playerMap[id].salary;
        }
      }

      return remainingSalary;
    },

    totalProjection() {
      let totalProj = 0;

      for (const id of this.players) {
        if (this.playerMap[id]) {
          totalProj += this.playerMap[id].tProj;
        }
      }

      return totalProj.toFixed(0);
    },

    errorMessage() {
      if (!this.lineup || !this.lineup.row) {
        return 'Invalid Lineup';
      }
      return 'Row ' + this.lineup.row + ': ' + this.lineup.error;
    },
  },

  methods: {

    openPlayerCard(playerId, sport, contestId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId.toString(), sport, contestId, true);
    },

    toggleSubmitEditLineup() {
      if (this.lineup.submit) {
        EventBus.$emit('TOGGLE_EDIT_ALL_LINEUPS');
      }
      EventBus.$emit('TOGGLE_EDIT_LINEUP_SUBMIT', this.lineup.teamId);
    },

    getPlayerName(id) {
      const contestPlayer = this.playerMap[id];
      if (!contestPlayer) {
        return id;
      }

      return contestPlayer.name.shortName();
    },

    validPlayer(id) {
      return this.playerMap[id] != null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

  td {
    height: 55px;
    white-space: nowrap;
  }

  th, td {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    min-width: 40px;
    text-align: left;
  }

  tr:not(:nth-child(1)) {
    border-top: 1px solid var(--obcolor-background-2);
  }

  em {
    color: var(--obcolor-red);
  }

  .player {
    font-weight: bold;
    font-size: 14px;
    margin-right: 7px;
  }

  .playerActive {
    color: var(--obcolor-text-primary);
    cursor: pointer;
  }

  .playerActive:hover {
    text-decoration: underline;
    text-decoration-color: var(--obcolor-text-primary);
  }

  .playerInactive {
    color: var(--obcolor-red);
  }

  .projection {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: var(--obcolor-text-primary);
  }

  .salary {
    font-weight: bold;
    font-size: 14px;
    color: var(--obcolor-realmoney);
  }

  .salaryNegative {
    font-weight: bold;
    font-size: 14px;
    color: var(--obcolor-red);
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lineupContainer {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .sportImage {
    margin-top: 5px;
    height: 22px;
  }

  .checkboxContainer {
    padding-left: 15px;
  }
</style>
