// Season Summary Stats
// Controls the top overall summary for season page

export const SEASON_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: 'GP', path: 'batter.GP', tooltip: 'Games Played'},
      {label: 'AB', path: 'batter.AB', tooltip: 'At Bats'},
      {label: 'H', path: 'batter.H', tooltip: 'Hits'},
      {label: '1B', path: 'batter.1B', tooltip: 'Single'},
      {label: '2B', path: 'batter.2B', tooltip: 'Double'},
      {label: '3B', path: 'batter.3B', tooltip: 'Triple'},
      {label: 'HR', path: 'batter.HR', tooltip: 'Home Runs'},
      {label: 'R', path: 'batter.R', tooltip: 'Runs'},
      {label: 'RBI', path: 'batter.RBI', tooltip: 'Runs Batted In'},
      {label: 'K', path: 'batter.K', tooltip: 'Strikeout'},
      {label: 'BB', path: 'batter.BB', tooltip: 'Walks'},
      {label: 'HBP', path: 'batter.HBP', tooltip: 'Hit By Pitch'},
      {label: 'SB', path: 'batter.SB', tooltip: 'Stolen Bases'},
      {label: 'CS', path: 'batter.CS', tooltip: 'Caught Stealing'},
      {label: 'PK', path: 'batter.PK', tooltip: 'Picked Off'},
      {label: 'AVG', path: 'batter.AVG', tooltip: 'Batting Average'},
      // { label: 'OBP', path: '', tooltip: '' },
      // { label: 'WAR', path: '', tooltip: '' },
      // { label: 'SLG', path: '', tooltip: '' },
    ],
  },
];

export const SEASON_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'GP', path: 'pitcher.GP', tooltip: 'Games Played'},
      {label: 'GS', path: 'pitcher.GS', tooltip: 'Games Started'},
      {label: 'IP', path: 'pitcher.IP', tooltip: 'Innings Pitched'},
      {label: 'W', path: 'pitcher.W', tooltip: 'Wins'},
      {label: 'L', path: 'pitcher.L', tooltip: 'Losses'},
      {label: 'K', path: 'pitcher.K', tooltip: 'Strikeouts'},
      {label: 'SO', path: 'pitcher.SO', tooltip: 'Shut Outs'},
      {label: 'BB', path: 'pitcher.BB', tooltip: 'Walks'},
      {label: 'HBP', path: 'pitcher.HBP', tooltip: 'Batters Hit By Pitch'},
      {label: 'H', path: 'pitcher.H', tooltip: 'Hits Allowed'},
      {label: 'HR', path: 'pitcher.HR', tooltip: 'Home Runs'},
      {label: 'ER', path: 'pitcher.ER', tooltip: 'Earned Runs'},
      {label: 'ERA', path: 'pitcher.ERA', tooltip: 'Earned Runs Average'},
      {label: 'WHIP', path: 'pitcher.WHIP', tooltip: 'Walks + Hits per Inning Pitched'},
      {label: 'SV', path: 'pitcher.SV', tooltip: 'Saves'},
      {label: 'HLD', path: 'pitcher.HLD', tooltip: 'Holds'},
      {label: 'QS', path: 'pitcher.QS', tooltip: 'Quality Starts'},
    ],
  },
];
