// @ts-check

import SocketController from '@/utils/SocketController';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      SocketControllerMixin_socketController: undefined,
    };
  },
  computed: {
    $SocketController() {
      if (this.SocketControllerMixin_socketController === undefined) {
        // lazy load the socket controller so it isn't initialized for every component
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.SocketControllerMixin_socketController = new SocketController();
      }
      return this.SocketControllerMixin_socketController;
    },
  },
  destroyed() {
    if (this.SocketControllerMixin_socketController !== undefined) {
      this.SocketControllerMixin_socketController.close();
    }
  },
});
