<template>
  <div class="ob-page">
    <div class="ob-box pageBox">
      <div class="ob-subheading" style="margin-left: 0; color: var(--obcolor-font-primary);">User Lockout</div>
      <div style="margin-bottom: 10px;">
        Knowing your limits and playing within it helps encourage a healthy playstyle for our OwnersBox customers. 
        Making sure you know when you should be taking some time away from the platform can both help improve your mental health and your chances at winning your next contest.
        <br>
        <br>
        If you wish, you can disable your account for any reason for a period of 30 days to a maximum of 5 years. 
        Once you've chosen to lock your account, any entries into pending contests you have entered will be withdrawn and corresponding entry fees refunded. For the duration of your Lockout period, you will be prevented from building an account, participating in any fantasy contests, collecting any prizes or awards, or recovering any losses.
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Set Lockout Length</div>
        <div class="boxBody" style="flex-direction: column;">
          <div>
            Choose between <span :class="{daysReminder: lockoutDays != null && lockoutDays != '' && (lockoutDays < 30 || lockoutDays > 1827)}">30 days to 1827 days</span> to Lock your account. 
            Once you have confirmed the Lockout request, your account will immediately be locked automatically. 
            All restrictions will automatically end once the selected Lockout time period expires, unless you choose to extend, renew, or set up a new account lock.
          </div>
          <input placeholder="Enter number of days..." style="margin: 10px 0; width: 260px;" v-model="lockoutDays">
          <div>
            Once you confirm the Lockout of your account, you will be logged out and will not have access to your account for the duration of the Lockout time. 
            Once you have been Locked out of your account you will be removed from email notification lists for the duration of your lockout.
            <br>
            <br>
            By Submitting this request you acknowledge that you release all the the following from any harm, monetary or otherwise, that may arise as a consequence of the self-restriction:
            <br>
            <br>
            • The state in which you reside
            <br>
            • The Gaming Agency in your state, including its employees and agents (if applicable)
            <br>
            • OwnersBox 3.0 Corporation, as applicable, and its officers, directors, employees, and agents.
            <br>
            <br>
          </div>
          <div class="submitError" v-if="validationMessage" v-tooltip.top.notrigger="{ content: validationMessage, class: 'ObTooltip ob-tooltip-fadeout', visible: true }"></div>
          <button class="ob-btn-med ob-btn-grey submitBtn" v-if="processingSend == true" disabled>
            <i class="fad fa-spinner-third fa-spin" style="margin-right: 5px;"></i>
            <div>Submitting...</div>
          </button>
          <button v-else-if="lockoutDays == null || lockoutDays == '' || (lockoutDays < 30 || lockoutDays > 1827)" class="ob-btn-grey submitBtn" disabled>Submit Request</button>
          <button v-else class="ob-btn submitBtn" @click="submitUserLockout()">Submit Request</button>
        </div>
      </div>
      <div class="ob-box categoryBox">
        <div class="boxHeader">Affiliated Parties Exclusion</div>
        <div class="boxBody" style="flex-direction: column;">
          OwnersBox allows qualified affiliates, who have concerns about a player’s ability to manage their play, to request a limitation on that player’s use of OwnersBox. 
          Limitations may include restriction or exclusion from play.
          <br>
          <br>
          Affiliated Party Exclusion Requests for an OwnersBox user can be made by requestors who can provide the following as proof:
          <br>
          <br>
          • Requestor is solely or jointly obligated on the credit or debit card associated with the OwnersBox account source of funds deposited
          <br>
          • The OwnersBox user is legally dependent on the requestor under state or federal law
          <br>
          • Requestor is wholly or partially obligated for debts of the OwnersBox user
          <br>
          • OwnersBox user is subject to court order requiring him or her to pay unmet child support
          <br>
          • OwnersBox makes available and prominently publishes and facilitates parental control procedures. 
          It is the general principle of OwnersBox to exclude all participation of minors on the platform, and has implemented technologies related to this initiative.
          <br>
          <br>
          At the request of a qualified third party, the OwnersBox account may be excluded from play for a period of 30 to 1827 days or have a deposit limit applied to their daily, weekly, or monthly deposits. 
          While under exclusion the player will not receive any notifications or marketing correspondence from OwnersBox.
          <br>
          <br>
          In the event of a qualified third party applying an exclusion, the associated OwnersBox account email will receive an email notification of the limits being applied. 
          The users will also have the ability to dispute the limitation placement for up to 5 days, if they fail to respond then the limitation will be applied to their account.
          <br>
          <br>
          If you would like to request an exclusion for an OwnersBox account please contact <a href="https://support.ownersbox.com/hc/en-us" class="hyperlink" target="_blank">our Player Support</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObResponsiblePlayApi from '@/api/ObResponsiblePlayApi';

export default {
  data() {
    return {
      lockoutDays: null,

      validationMessage: null,
      validationMessageTimeout: null,

      processingSend: false,
    };
  },

  watch: {
    validationMessage(newVal) {
      const self = this;

      if (newVal != null) {
        clearTimeout(this.validationMessageTimeout);
        this.validationMessageTimeout = setTimeout(function() {
          self.resetError();
        }, 3000);
      }
    },
  },

  methods: {
    resetError() {
      this.validationMessage = null;
    },

    getNumbersOnly(inputVal) {
      if (inputVal != null && inputVal != '' && !isNaN(inputVal)) {
        return Math.round(inputVal);
      } else if (isNaN(inputVal) && inputVal != null && inputVal != '') {
        return inputVal.replace(/\D/g, '');
      }

      return null;
    },

    submitUserLockout() {
      this.processingSend = true;

      ObResponsiblePlayApi.setLockout(this.lockoutDays)
          .then((response) => {
            this.processingSend = false;
            this.validationMessage = 'Success';
          })
          .catch((error) => {
            this.processingSend = false;
            if (error.response && error.response.data) {
              this.validationMessage = error.response.data;
            } else {
              this.validationMessage = 'Lockout failed';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 10px;
}

.daysReminder {
  color: red;
}

.submitError {
  width: 165px;
}

.submitBtn {
  width: 165px;
  box-sizing: border-box;
  padding: 10px 30px;
  display: flex;
}

// re-add hyperlink colours
.hyperlink {
  text-decoration: underline;
  display: contents;
  color: var(--obcolor-ob-blue);
  cursor: pointer;
}

.hyperlink:visited {
  color: purple;
}

.hyperlink:active {
  color: red
}

.pageBox {
  padding: 15px 20px;
  font-size: 14px;
}

.categoryBox {
  background: var(--obcolor-background-4);
  overflow: hidden;
  padding: 15px 20px;
  margin-top: 15px;
}

.categoryBox:not(:last-child) {
  margin-bottom: 15px;
}

.boxHeader {
  background: var(--obcolor-background-3);
  margin: -15px -20px;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 16px;
}

.boxBody {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.bodyLeftSide {
  flex: 1;
  max-width: 720px;
}
</style>