// Season Summary Stats
// Controls the top overall summary for season page

// Season summary
export const SEASON_NBA_PLAYER = [
  {
    label: 'Stats',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'MIN', path: 'player.MIN_PG', tooltip: 'Minutes Per Game'},
      {label: 'PTS', path: 'player.PTS_PG', tooltip: 'Points Per Game'},
      {label: 'AST', path: 'player.AST_PG', tooltip: 'Assists Per Game'},
      {label: 'REB', path: 'player.REB_PG', tooltip: 'Rebounds Per Game'},
      {label: 'BLK', path: 'player.BLK_PG', tooltip: 'Blocks Per Game'},
      {label: 'STL', path: 'player.STL_PG', tooltip: 'Steals Per Game'},
      {label: 'TO', path: 'player.TO_PG', tooltip: 'Turnovers Per Game'},
      // { label: 'FGA', path: 'player.FGA', tooltip: 'Field Goal Attempts' },
      {label: 'FG%', path: 'player.FGPct', tooltip: 'Field Goal Percentage'},
      {label: 'FT%', path: 'player.FTPct', tooltip: 'Free Throw Percentage'},
      {label: '3P%', path: 'player.TPPct', tooltip: '3-Points Percentage'},
    ],
  },
];