// @ts-check

import io from 'socket.io-client';
import EventBus from '@/event-bus';

/**
 * @typedef {unknown} SocketUpdate
 * @typedef {(update: SocketUpdate) => void} SocketUpdateHandler
 */

/**
 */
export default class SocketController {
  /**
   * @private
   * @type {SocketIOClient.Socket|null}
   */
  static _socketConnection = null
  /**
   * @private
   * @type {Map<string, Set<SocketController>>}
   */
  static _connectedSocketControllers = new Map()
  /**
   * @private
   * @type {Set<string>}
   */
  _subbedRooms = new Set()

  /**
   * @private
   * @param {string} room
   * @return {Set<SocketController>}
   */
  static _getConnectedSocketControllers(room) {
    if (this._connectedSocketControllers.has(room)) {
      return this._connectedSocketControllers.get(room);
    }
    const s = new Set();
    this._connectedSocketControllers.set(room, s);
    return s;
  }

  /**
   * @private
   * @return {void}
   */
  static _handleConnect() {
    // Resub to any rooms, in case of temporary disconnect
    for (const key of Array.from(this._connectedSocketControllers.keys())) {
      this._socketConnection.emit('subscribe', {room: key});
    }
  }

  /**
   * @private
   * @param {any} data
   * @return {void}
   */
  static _handleBroadcast(data) {
    try {
      const json = typeof(data) === 'object' ? data : JSON.parse(data);
      if (json != null) {
        EventBus.$emit('SOCKET_BROADCAST', json);
      }
    } catch (_error) {
      // Do nothing
    }
  }

  /**
   * @private
   * @return {void}
   */
  static _handleDisconnect() {
    // noop
  }

  /**
   *
   * @param {string} serverURL
   * @param {string} userId
   * @param {string} securityToken
   * @return {void}
   */
  connect(serverURL, userId, securityToken) {
    if (SocketController._socketConnection !== null) {
      return;
    }
    if (!serverURL.startsWith('http://') && !serverURL.startsWith('https://')) {
      serverURL = "https://" + serverURL;
    }
    const st = encodeURIComponent(securityToken);
    const socket = io(serverURL, {query: 'obtoken=' + userId + '&st=' + st});
    SocketController._socketConnection = socket;
    socket.on('connect', SocketController._handleConnect.bind(SocketController));
    socket.on('broadcast', SocketController._handleBroadcast.bind(SocketController));
    socket.on('disconnect', SocketController._handleDisconnect.bind(SocketController));
  }

  /**
   * @return {void}
   */
  disconnect() {
    if (SocketController._socketConnection !== null) {
      SocketController._socketConnection.disconnect();
      SocketController._connectedSocketControllers.clear();
      SocketController._socketConnection = null;
    }
  }

  /**
   * @param {*} params
   * @return {void}
   */
  sendDraftMessage(params) {
    if (SocketController._socketConnection != null && params != null) {
      SocketController._socketConnection.emit('draftSendMessage', params);
    }
  }

  /**
   * @param {string} room
   */
  subscribeToRoom(room) {
    const connected = SocketController._getConnectedSocketControllers(room);

    if (SocketController._socketConnection != null && connected.size === 0) {
      SocketController._socketConnection.emit('subscribe', {room: room});
    }

    // Add to subbed rooms list anyway, on connect they will get subscribed
    connected.add(this);
    this._subbedRooms.add(room);
  }

  /**
   * @param {string} room
   * @return {void}
   */
  unsubscribeFromRoom(room) {
    const connected = SocketController._getConnectedSocketControllers(room);

    connected.delete(this);

    if (SocketController._socketConnection != null && connected.size === 0) {
      SocketController._socketConnection.emit('unsubscribe', {room: room});
    }
    this._subbedRooms.delete(room);
  }

  /**
   *
   * @param {string} roomId
   * @param {SocketUpdateHandler} broadcastHandler
   * @return {void}
   */
  startRoom(roomId, broadcastHandler) {
    this.subscribeToRoom(roomId);
    EventBus.$on('SOCKET_BROADCAST', broadcastHandler);
  }

  /**
   *
   * @param {string} roomId
   * @param {SocketUpdateHandler} broadcastHandler
   * @return {void}
   */
  stopRoom(roomId, broadcastHandler) {
    this.unsubscribeFromRoom(roomId);
    EventBus.$off('SOCKET_BROADCAST', broadcastHandler);
  }

  /**
   * @return {void}
   */
  close() {
    for (const roomId of Array.from(this._subbedRooms)) {
      this.unsubscribeFromRoom(roomId);
    }
  }
}