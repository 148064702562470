<template>
  <div v-if="isOpen">
    <Modal v-model="isOpen"
      containerStyle="width: 800px; height: 650px; padding: 0; overflow: hidden;"
      bodyStyle="margin: 0; padding: 10px 13px; background: var(--obcolor-background-page); overflow: auto !important;"
      :disableClickaway="true"
    >
      <!-- Player Summary -->
      <div slot="header" class="modalHeader">
        <div class="headerText">
          Create a Contest
        </div>
        <div class="subText">
          Follow the steps to create a contest.
        </div>
      </div>
      <div slot="body" class="modalBody">
        <div v-if="!loaded" class="loadingContainer">
          <ObLoading :size="60" />
        </div>
        <template v-else>
          <CreateContestProgress :settings="settings" :step="step" :steps="steps" />
          <div class="stepTitle">{{ currentStep ? currentStep.title : '' }}</div>
          <CreateContestSportSelect v-if="step === 0" :sports="stepOptions.sports" :selected="settings.sport"
            @select="selectSport" @nextStep="nextStep"
          />
          <CreateContestFormatSelect v-else-if="step === 1" :rosterFormats="stepOptions.rosterFormats"
            :selected="settings.rosterFormat" :gameSlates="gameSlatesForSport" @select="selectFormat"
            @nextStep="nextStep"
          />
          <CreateContestSlateSelect v-else-if="step === 2" :gameSlates="stepOptions.gameSlates"
            :selected="settings.gameSlate" :sport="settings.sport" :gameSlateData="gameSlateData"
            @select="selectGameSlate" @nextStep="nextStep"
          />
          <CreateContestTypeSelect v-else-if="step === 3" :contestTypes="stepOptions.contestTypes"
            :selected="settings.contestType" @select="selectType" @nextStep="nextStep"
          />
          <CreateContestPrivacySelect v-else-if="step === 4" :privacyOptions="stepOptions.privacy"
            :selected="settings.privacy" @select="selectPrivacy" @nextStep="nextStep"
          />
          <CreateContestDetailsSelect v-else-if="step === 5 && settings.contestType.id === 'ONE_VS_ONE'" :fees="stepOptions.fees"
            :rookieOptions="stepOptions.isRookie" :selectedFees="settings.details.fees" :selectedIsRookie="settings.details.isRookie"
            @selectIsRookie="selectIsRookie" @selectFees="selectFees"
          />
          <CreateContestDetailsDropdown v-else-if="step === 5 && settings.contestType.id !== 'ONE_VS_ONE'" :rookieOptions="stepOptions.isRookie"
            :contestTypeOptions="settings.contestType" :selectedDetails="settings.details" @selectDetails="selectDetails"
            @getCreateContestPayouts="getCreateContestPayouts" :payoutInfo="payoutInfo" :payoutApiError="payoutApiError"
            :payoutApiErrorMessage="payoutApiErrorMessage"
          />
          <CreateContestConfirmPage v-else-if="step === 6" :settings="settings" :totalPrizes="totalPrizes" />
        </template>
      </div>
      <div slot="footer" class="modalFooter">
        <div class="infoList">
          <div>
            <div>{{ accountBalance }}</div>
            <div class="infoLabel">Balance</div>
          </div>
          <div v-if="step >= 5">
            <div>{{ totContests }}</div>
            <div class="infoLabel">Contests</div>
          </div>
          <div v-if="step >= 5">
            <div>{{ entryFees }}</div>
            <div class="infoLabel">Entry Fees</div>
          </div>
          <div v-if="step >= 5">
            <div>{{ totalPrizes }}</div>
            <div class="infoLabel">Total Prizes</div>
          </div>
        </div>
        <div class="buttonsList">
          <div v-if="canClickPrev" class="ob-btn-grey footerBtn" @click="prevStep">Prev</div>
          <div v-if="step === 6" class="ob-btn footerBtn" @click="nextStep">Create Contest</div>
          <div v-else-if="!canClickNext" class="footerBtn ob-btn-grey" disabled>Next</div>
          <div v-else class="footerBtn ob-btn-grey" @click="nextStep">Next</div>
        </div>
      </div>
    </Modal>
    <SubmitProgressModal />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLeagueApi from '@/api/ObLeagueApi';
import Modal from '@/components/Modal';
import ObLoading from '@/components/ObLoading';
import CreateContestProgress from './CreateContestProgress';
import CreateContestSportSelect from './pages/CreateContestSportSelect';
import CreateContestFormatSelect from './pages/CreateContestFormatSelect';
import CreateContestSlateSelect from './pages/CreateContestSlateSelect';
import CreateContestTypeSelect from './pages/CreateContestTypeSelect';
import CreateContestPrivacySelect from './pages/CreateContestPrivacySelect';
import CreateContestDetailsSelect from './pages/CreateContestDetailsSelect';
import CreateContestDetailsDropdown from './pages/CreateContestDetailsDropdown';
import CreateContestConfirmPage from './pages/CreateContestConfirmPage';
import SubmitProgressModal from './SubmitProgressModal';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    ObLoading,
    CreateContestProgress,
    CreateContestSportSelect,
    CreateContestFormatSelect,
    CreateContestSlateSelect,
    CreateContestTypeSelect,
    CreateContestPrivacySelect,
    CreateContestDetailsDropdown,
    CreateContestDetailsSelect,
    CreateContestConfirmPage,
    SubmitProgressModal,
  },

  data() {
    return {
      isOpen: false,
      loaded: false,
      payoutApiError: false,
      payoutApiErrorMessage: '',
      settings: {},

      defaultSettings: {
        sport: null,
        rosterFormat: null,
        gameSlate: null,
        contestType: null,
        privacy: null,
        details: {
          label: 'Details',
          isRookie: false,
          fees: [],
          multiplierSize: null,
          contestSize: null,
          entryFee: null,
          entryFeePayouts: [],
          prizeStructure: {
            id: null,
            label: null,
          },
          maxEntry: null,
        },
      },

      gameSlateData: {},
      settingsInfo: null,
      payoutInfo: null,
      step: 0,

      steps: [
        {settingsKey: 'sport', label: 'Sport', title: 'Select a Sport'},
        {settingsKey: 'rosterFormat', label: 'Roster', title: 'Select Roster Type'},
        {settingsKey: 'gameSlate', label: 'Slate', title: 'Select Slate'},
        {settingsKey: 'contestType', label: 'Type', title: 'Select Game Type'},
        {settingsKey: 'privacy', label: 'Privacy', title: 'Select Contest Privacy'},
        {settingsKey: 'details', label: 'Details', title: 'Choose Contest Details'},
        {settingsKey: null, label: 'Confirm', title: 'Confirm Contest Details'},
      ],
    };
  },

  created() {
    EventBus.$on('OPEN_CREATE_MODAL', this.openModal);
    EventBus.$on('CLOSE_CONTEST_CREATE_MODAL', this.closeModal);
  },

  destroyed() {
    EventBus.$off('OPEN_CREATE_MODAL', this.openModal);
    EventBus.$off('CLOSE_CONTEST_CREATE_MODAL', this.closeModal);
  },

  computed: {
    ...mapState(['accountBalance', 'balanceValue']),

    currentStep() {
      return this.steps[this.step];
    },

    canClickPrev() {
      return this.step > 0;
    },

    canClickNext() {
      if (this.step === 6) {
        return true;
      }
      if (this.step === 5) {
        const details = this.settings.details;
        switch (this.settings.contestType.id) {
          case 'BATTLE_ROYALE': return details.contestSize && details.entryFee && details.maxEntry && details.prizeStructure.id && details.isRookie !== null;
          case 'TEN_MAN': return details.contestSize && details.entryFee && details.prizeStructure.id && details.isRookie !== null;
          case 'MULTIPLIER': return details.multiplierSize && details.contestSize && details.entryFee && details.maxEntry && details.isRookie !== null;
          case 'ONE_VS_ONE': return details.fees.length && details.isRookie !== null;
        }
      }
      const key = this.steps[this.step] ? this.steps[this.step].settingsKey : null;
      if (key == null) {
        return false;
      }

      return this.settings[key] !== null;
    },

    gameSlatesForSport() {
      if (!this.settings.sport) {
        return;
      }

      const sport = this.settings.sport;
      const settingsForSport = this.settingsInfo[sport];
      if (!settingsForSport || !settingsForSport.gameSlates) {
        return;
      }

      return settingsForSport.gameSlates;
    },

    // For 1v1 Contests
    totContests() {
      const fees = this.settings.details.fees;
      if (this.settings.contestType.id === 'ONE_VS_ONE' && fees.length > 0) {
        let totContests = 0;
        for (const fee of fees) {
          totContests += fee.contestCount;
        }
        return totContests.toString();
      } else if (this.settings.contestType.id !== 'ONE_VS_ONE') {
        return '1';
      }
      return '-';
    },

    // For 1v1 Contests
    entryFees() {
      const fees = this.settings.details.fees;
      const entyFee = this.settings.details.entryFee;

      if (this.settings.contestType.id === 'ONE_VS_ONE' && fees && fees.length > 0) {
        let totFees = 0;
        for (const fee of fees) {
          totFees += fee.contestCount * fee.entryFee;
        }
        return (totFees / 100).formatMoneyDecimals().removeCentsIfZero();
      } else if (this.settings.contestType.id !== 'ONE_VS_ONE' && entyFee) {
        return (entyFee / 100).formatMoneyDecimals().removeCentsIfZero();
      }
      return '-';
    },

    totalPrizes() {
      const fees = this.settings.details.fees;

      if (this.settings.contestType.id === 'ONE_VS_ONE' && fees && fees.length > 0) {
        let totPrizes = 0;
        for (const fee of fees) {
          totPrizes += fee.contestCount * fee.payout;
        }
        return (totPrizes / 100).formatMoneyDecimals().removeCentsIfZero();
      } else if (this.payoutInfo) {
        let totPrize = 0;
        for (const prize of this.payoutInfo) {
          totPrize += prize.prizeValue * prize.payouts;
        }
        return (totPrize / 100).formatMoneyDecimals().removeCentsIfZero();
      }
      return '-';
    },

    stepOptions() {
      const options = {
        sports: [],
        rosterFormats: [],
        gameSlates: [],
        contestTypes: [],
        privacy: [],
        details: {},
      };

      const sports = ['MLB', 'NBA', 'NFL', 'NHL'];

      if (!this.settingsInfo) {
        return options;
      }

      const availSports = [];
      for (const sport of sports) {
        if (this.settingsInfo[sport]) {
          availSports.push(sport);
        }
      }

      // Set sports, contest types
      options.sports = availSports;
      options.contestTypes = this.settingsInfo.contestTypes;

      // Other options rely on a sport being set, so if we don't have one set we will just return that sport info
      if (this.step <= 0 || this.settings.sport === null) {
        return options;
      }

      const sport = this.settings.sport;
      const settingsForSport = this.settingsInfo[sport];

      // Set roster formats
      options.rosterFormats = settingsForSport.rosterFormats;

      // Everything after this requires a roster type for filtering
      if (!this.settings.rosterFormat) {
        return options;
      }

      // Set slates
      options.gameSlates = settingsForSport.gameSlates.filter((s) => s.rosterFormat == this.settings.rosterFormat.label);

      // Set privacy
      if (this.settings.contestType && this.settings.contestType.id === 'BATTLE_ROYALE') {
        options.privacy = [
          {label: 'Private', value: true, description: 'Private contests will not appear in the lobby.'},
        ];
      } else {
        options.privacy = [
          {label: 'Public', value: false, description: 'Public contests will appear in the lobby and can be entered by any user.'},
          {label: 'Private', value: true, description: 'Private contests will not appear in the lobby.'},
        ];
      }

      if (!this.settings.contestType) {
        return options;
      }

      options.isRookie = [{label: 'All', value: false}, {label: 'Rookie Only', value: true}];
      options.fees = this.settings.contestType.entryFees;

      return options;
    },
  },

  watch: {
    isOpen(newVal) {
      if (newVal === false) {
        this.resetData();
      }
    },

    'settings.gameSlate'(newVal) {
      if (newVal == null) {
        return;
      }
      this.getGameSlateData(newVal);
    },

    'settings.details.id'(newVal) {
      if (newVal) {
        this.payoutInfo = null;
      }
    },

    'settings.details.multiplierSize'(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.payoutInfo = null;
    },
  },

  methods: {
    resetData() {
      this.settings = structuredClone(this.defaultSettings);
      this.step = 0;
      this.gameSlateData = {};
    },

    getCreateOptions() {
      this.loaded = false;
      ObSalaryCapApi.getCreateContestOptions()
          .then((response) => {
            this.loaded = true;
            this.settingsInfo = response;
          });
    },

    getGameSlateData(slate) {
      // If slate is null or the data for this slate is already loaded, do nothing
      if (!slate || this.gameSlateData[slate.id]) {
        return;
      }
      const slateId = slate.id;
      ObLeagueApi.getGameSlate(slateId)
          .then((response) => {
            this.$set(this.gameSlateData, slateId, response.gameSlateData);
          }).catch((_error) => {
            // Do nothing
          });
    },

    nextStep() {
      if (this.step === 6) {
        this.submitContest();
        return;
      }

      if (this.canClickNext) {
        this.step++;
      }
    },

    prevStep() {
      if (this.step <= 0) {
        return;
      }
      const key = this.steps[this.step] ? this.steps[this.step].settingsKey : null;

      if (key) {
        this.settings[key] = structuredClone(this.defaultSettings[key]);
      }
      this.payoutInfo = null;

      this.step--;
    },

    openModal() {
      this.resetData();
      this.getCreateOptions();
      this.isOpen = true;
    },

    selectSport(sport) {
      this.settings.sport = sport;
    },

    selectFormat(format) {
      this.settings.rosterFormat = format;
    },

    selectGameSlate(gameSlate) {
      this.settings.gameSlate = gameSlate;
    },

    selectType(type) {
      this.settings.contestType = type;
    },

    selectPrivacy(privacy) {
      this.settings.privacy = privacy;
    },

    selectFees(fees) {
      const newFees = [];
      const payouts = this.settingsInfo.contestTypes[0].entryFeePayouts;
      for (const fee of fees) {
        if (fee.contestCount && payouts[fee.entryFee]) {
          fee['payout'] = payouts[fee.entryFee];
          newFees.push(fee);
        }
      }

      this.settings.details.fees = newFees;
    },

    selectIsRookie(isRookie) {
      this.settings.details.isRookie = isRookie;
    },

    selectContestSize(contestSize) {
      this.settings.contestSize = contestSize;
    },

    selectDetails(details) {
      this.settings.details = structuredClone(details);
    },

    entryFeeToFee() {
      this.settings.details.fees = [];
      const entryFeeObj = {
        'entryFee': this.settings.details.entryFee,
        'contestCount': 1,
      };
      this.settings.details.fees.push(entryFeeObj);
    },

    submitContest() {
      if (!this.settings || !this.settings.details || !this.settings.gameSlate || !this.settings.contestType) {
        return;
      }

      if (this.settings.contestType.id !== 'ONE_VS_ONE') {
        this.entryFeeToFee();
      }

      const settingsToSend = {
        sport: this.settings.sport,
        contests: this.settings.details.fees,
        isRookie: this.settings.details.isRookie,
        isPrivate: this.settings.privacy.value,
        gameSlateId: this.settings.gameSlate.id,
        contestTypeId: this.settings.contestType.id,
        prizeStructureId: this.settings.details.prizeStructure.id,
        multiplierSizeId: this.settings.details.multiplierSize,
        entries: this.settings.details.contestSize,
        entriesPerUser: this.settings.details.maxEntry,
      };
      EventBus.$emit('CREATE_MODAL_SUBMIT', settingsToSend);
    },

    closeModal() {
      this.resetData();
      this.isOpen = false;
      this.payoutInfo = null;
    },

    getCreateContestPayouts() {
      ObSalaryCapApi.getCreateContestPayouts(
          this.settings.details.entryFee,
          this.settings.details.contestSize,
          this.settings.details.multiplierSize,
          this.settings.details.prizeStructure.id,
          this.settings.contestType.id,
      ).then((response) => {
        this.payoutInfo = response;
      }).catch((_error) => {
        this.payoutApiError = true;
        this.payoutApiErrorMessage = 'Payouts failed to load, please try again';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  padding: 10px 20px;
}

.headerText {
  font-size: 16px;
  font-weight: bold;
}

.subText {
  color: var(--obcolor-font-secondary);
}

.stepTitle {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 0 0;
}

.loadingContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooter {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;

  .infoList, .buttonsList {
    display: flex;
    align-items: center;
  }

  .infoList {
    > div {
      margin-right: 20px;
    }

    .infoLabel {
      color: var(--obcolor-font-secondary);
      font-size: 14px;
    }
  }

  .buttonsList > div {
    margin-left: 10px;
  }

  .footerBtn {
    width: 110px;
    font-size: 14px;
  }
}
</style>