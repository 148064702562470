<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 600px; overflow: hidden; padding: 0; border-width: 0;"
    bodystyle="padding-bottom: 0;"
    :hideCloseButton="true"
  >
    <div class="bodyContainer" slot="body">
      <img class="modalBg" :src="require('@/assets/onboarding/modal/kroger/kroger_modal_bg_black.png')" alt="Background">
      <img class="modalPointsIcon" :src="require('@/assets/onboarding/modal/kroger/kroger_points.png')" alt="Kroger Points">
      <div class="imgContainer">
        <img :src="require('@/assets/obicon_white.png')" alt="OwnersBox">
        <img :src="require('@/assets/onboarding/x.svg')" alt="Times">
        <img :src="require('@/assets/onboarding/modal/kroger/kroger_logo_white.png')" alt="Kroger">
      </div>
      <div class="modalTitle">Kroger Connect</div>
      <div class="modalText">
        You have been referred to us by Kroger. Connect your Kroger account with OwnersBox to receive Kroger fuel points as you play Daily Fantasy Sports.
      </div>
      <div class="modalButtonContainer">
        <router-link :to="redirectedLinkComputed" class="ob-btn-grey">Later</router-link>
        <button @click="connectKroger" class="ob-btn">Connect</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {mapState} from 'vuex';
import Modal from '@/components/Modal.vue';
import EventBus from '@/event-bus';
import ObIntegrationsApi from '@/api/ObIntegrationsApi';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,

      krogerIdInterval: null,
      krogerOAuthUrl: null,
      loadingOAuthUrl: false,

      redirect: null,
    };
  },

  created() {
    this.getKrogerOAuthUrl();
    this.checkIfKrogerConnected();
    EventBus.$on('ON_BOARDING_OPEN_KROGER_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('ON_BOARDING_OPEN_KROGER_MODAL', this.openModal);
  },

  computed: {
    ...mapState(['krogerId', 'isDepositVerified', 'theme']),

    redirectedLinkComputed() {
      if (!this.redirectedLink) {
        return '/start-playing';
      } else {
        return this.redirectedLink;
      }
    },
  },

  watch: {
    krogerId() {
      this.checkIfKrogerConnected();
    },
  },

  methods: {
    openModal(redirectedLink = null) {
      this.isOpen = true;
      this.redirectedLink = redirectedLink;
    },

    closeModal() {
      clearInterval(this.krogerIdInterval);
      this.isOpen = false;
    },

    checkIfKrogerConnected() {
      if (this.krogerId != null && this.isOpen) {
        this.$router.replace(this.redirectedLinkComputed);
      }
    },

    getKrogerOAuthUrl() {
      this.loadingOAuthUrl = true;
      ObIntegrationsApi.getKrogerOAuthUrl()
          .then((response) => {
            this.krogerOAuthUrl = response;
            this.loadingOAuthUrl = false;
          }).catch((e) => {
            this.loadingOAuthUrlError = true;
            this.loadingOAuthUrl = false;
          });
    },

    connectKroger() {
      window.open(this.krogerOAuthUrl, 'Kroger Integration', 'width=600, height=820');
      this.loopGetKrogerId();
    },

    loopGetKrogerId() {
      clearInterval(this.krogerIdInterval);
      // 1 second timer to attempt to fetch kroger id
      this.krogerIdInterval = setInterval(this.getKrogerIdForRefresh, 2000);
    },

    // Intended to get the user's kroger id once they've connected, but since it's in a different window
    // we don't actually know when that will be.
    getKrogerIdForRefresh() {
      ObIntegrationsApi.loadUserKrogerId()
          .then((response) => {
            const krogerId = response != '' ? response : null;
            this.$store.commit('setKrogerId', krogerId);

            // Clear the interval for grabbing the kroger id if the id is found
            // If this never happens, it's cleared when the user leaves the page
            if (krogerId != null) {
              clearInterval(this.krogerIdInterval);
            }
          }).catch((_e) => {
            // Hide error
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.modalBg {
  position: absolute;
  top: 0;
  height: 500px;
  z-index: 0;
}

.bodyContainer {
  :not(.modalBg) {
    z-index: 1;
  }
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 0 0;
  font-size: 15px;
  font-weight: 700;
  .modalTitle {
    padding-top: 20px;
    font-size: 24px;
  }
  .modalText {
    text-align: center;
    padding-top: 10px;
    max-width: 90%;
    font-weight: 400;
    line-height: 19px;
  }
  .modalPointsIcon {
    height: 166px;
  }
  .imgContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 10;
    padding-top: 25px;
    > img:nth-child(1) {
      height: 32px;
    }
    > img:nth-child(2) {
      height: 14px;
    }
    > img:nth-child(3) {
      height: 48px;
    }
  }
}

.modalButtonContainer {
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 30px;

  > a, > button {
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:first-child {
      border-radius: 0 0 0 5px;
    }
    &:last-child {
      border-radius: 0 0 5px 0;
    }
  }
}
</style>