const GeographicInfo = {};
import store from '@/store';

GeographicInfo.install = function(Vue, options) {
  // Cache up to 5 mins
  const GEOLOCATION_MAX_AGE_MS = 5 * 60 * 1000;

  const US_STATES = [
    {name: 'Alabama', abbreviation: 'AL'},
    {name: 'Alaska', abbreviation: 'AK'},
    {name: 'Arizona', abbreviation: 'AZ'},
    {name: 'Arkansas', abbreviation: 'AR'},
    {name: 'California', abbreviation: 'CA'},
    {name: 'Colorado', abbreviation: 'CO'},
    {name: 'Connecticut', abbreviation: 'CT'},
    {name: 'Delaware', abbreviation: 'DE'},
    {name: 'District of Columbia', abbreviation: 'DC'},
    {name: 'Florida', abbreviation: 'FL'},
    {name: 'Georgia', abbreviation: 'GA'},
    {name: 'Hawaii', abbreviation: 'HI'},
    {name: 'Idaho', abbreviation: 'ID'},
    {name: 'Illinois', abbreviation: 'IL'},
    {name: 'Indiana', abbreviation: 'IN'},
    {name: 'Iowa', abbreviation: 'IA'},
    {name: 'Kansas', abbreviation: 'KS'},
    {name: 'Kentucky', abbreviation: 'KY'},
    {name: 'Louisiana', abbreviation: 'LA'},
    {name: 'Maine', abbreviation: 'ME'},
    {name: 'Maryland', abbreviation: 'MD'},
    {name: 'Massachusetts', abbreviation: 'MA'},
    {name: 'Michigan', abbreviation: 'MI'},
    {name: 'Minnesota', abbreviation: 'MN'},
    {name: 'Mississippi', abbreviation: 'MS'},
    {name: 'Missouri', abbreviation: 'MO'},
    {name: 'Montana', abbreviation: 'MT'},
    {name: 'Nebraska', abbreviation: 'NE'},
    {name: 'Nevada', abbreviation: 'NV'},
    {name: 'New Hampshire', abbreviation: 'NH'},
    {name: 'New Jersey', abbreviation: 'NJ'},
    {name: 'New Mexico', abbreviation: 'NM'},
    {name: 'New York', abbreviation: 'NY'},
    {name: 'North Carolina', abbreviation: 'NC'},
    {name: 'North Dakota', abbreviation: 'ND'},
    {name: 'Ohio', abbreviation: 'OH'},
    {name: 'Oklahoma', abbreviation: 'OK'},
    {name: 'Oregon', abbreviation: 'OR'},
    {name: 'Pennsylvania', abbreviation: 'PA'},
    {name: 'Rhode Island', abbreviation: 'RI'},
    {name: 'South Carolina', abbreviation: 'SC'},
    {name: 'South Dakota', abbreviation: 'SD'},
    {name: 'Tennessee', abbreviation: 'TN'},
    {name: 'Texas', abbreviation: 'TX'},
    {name: 'Utah', abbreviation: 'UT'},
    {name: 'Vermont', abbreviation: 'VT'},
    {name: 'Virginia', abbreviation: 'VA'},
    {name: 'Washington', abbreviation: 'WA'},
    {name: 'West Virginia', abbreviation: 'WV'},
    {name: 'Wisconsin', abbreviation: 'WI'},
    {name: 'Wyoming', abbreviation: 'WY'},
  ];

  const EXCLUDED_REGIONS = [
    'Arizona',
    'Connecticut',
    'Delaware',
    'Hawaii',
    'Idaho',
    'Indiana',
    'Iowa',
    'Louisiana',
    'Michigan',
    'Mississippi',
    'Montana',
    'Nevada',
    'New York',
    'Washington',
  ];

  const CA_PROVINCES = [
    {name: 'Alberta', abbreviation: 'AB'},
    {name: 'British Columbia', abbreviation: 'BC'},
    {name: 'Manitoba', abbreviation: 'MB'},
    {name: 'New Brunswick', abbreviation: 'NB'},
    {name: 'Newfoundland and Labrador', abbreviation: 'NL'},
    {name: 'Northwest Territories', abbreviation: 'NT'},
    {name: 'Nova Scotia', abbreviation: 'NS'},
    {name: 'Nunavut', abbreviation: 'NU'},
    {name: 'Ontario', abbreviation: 'ON'},
    {name: 'Prince Edward Island', abbreviation: 'PE'},
    {name: 'Quebec', abbreviation: 'QC'},
    {name: 'Saskatchewan', abbreviation: 'SK'},
    {name: 'Yukon Territory', abbreviation: 'YT'},
  ];

  const GeographicInfo = {
    getGeographicInfo(country) {
      switch (country) {
        case 'US':
          return US_STATES;
        case 'CA':
          return CA_PROVINCES;
      }
      return US_STATES;
    },

    getExcludedLocations() {
      return EXCLUDED_REGIONS;
    },

    checkGeoLocation(params = {}) {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation && store.getters.geoLoadedTs !== null) {
          navigator.geolocation.getCurrentPosition((position) => {
            if (position && position.coords && position.coords.longitude && position.coords.latitude) {
              params.long = position.coords.longitude.toString();
              params.lat = position.coords.latitude.toString();
            }
            resolve(params);
          },
          (error) => {
            resolve(params);
          }, {
            // Cache up to 5 mins
            maximumAge: GEOLOCATION_MAX_AGE_MS,
          });
        } else {
          resolve(params);
        }
      });
    },

    setupGeolocationCheck() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(() => {
            this.saveLocationCheckTs();
            resolve();
          },
          (error) => {
            resolve();
          }, {
            // Cache up to 5 mins
            maximumAge: GEOLOCATION_MAX_AGE_MS,
          });
        } else {
          resolve();
        }
      });
    },

    abbreviationToName(countryShort, regionAbbrev) {
      const regions = this.getGeographicInfo(countryShort);
      for (const reg of regions) {
        if (reg.abbreviation == regionAbbrev) {
          return reg.name;
        }
      }
      return null;
    },

    saveLocationCheckTs() {
      const ts = Date.now();
      store.commit('setGeoLoadedTs', ts);
    },
  };

  Vue.prototype.$GeographicInfo = GeographicInfo;
};

export default GeographicInfo;
