<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="padding: 0; overflow: hidden; width: 820px; height: 700px;"
    bodyStyle="margin: 0; padding: 0;"
  >
    <div slot="header" class="header">
      <div class="title">{{ titleText }}</div>
      <div class="paragraph greyText">
        Enter your lineup into more contests for a chance at more prizes.
      </div>
    </div>

    <div slot="body" class="body">
      <div v-if="this.lineupInfo.picks" class="quickEntryInfo">
        <!-- Player list table -->
        <QuickEntryPlayerList class="playerTableContainer ob-scroll" :lineupInfo="lineupInfo" :contest="contest" />

        <!-- Contest list -->
        <div v-if="loadingContests" class="contestTableContainer loadingInfo">
          <ObLoading class="loadingIndicator" :size="80" />
        </div>
        <QuickEntryContestList v-else-if="hasContests" class="contestTableContainer ob-scroll" :contestList="contestList"
          :entriesByContest="entriesByContest"
        />
        <div v-else class="greyText noContestText">
          No additional contests found for this lineup
        </div>
      </div>
    </div>

    <div slot="footer" class="footer">
      <div class="footerItem">
        <div class="itemText">
          <div v-if="contest.realMoney" :class="{redText: !canAffordRealMoney}">
            {{ accountBalance }}
          </div>
          <div v-else>
            <span :class="{redText: !canAffordOwnersBucks}">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
              {{ ownersbucks }}
            </span>
          </div>
        </div>
        <div class="itemTitle">Balance</div>
      </div>
      <div class="footerItem">
        <div class="itemText">{{ numSelectedContests }}</div>
        <div class="itemTitle">Entries</div>
      </div>
      <div class="footerItem">
        <div class="itemText">
          <div v-if="contest.realMoney">
            <span :class="{redText: !canAffordRealMoney}">${{ totRealMoneyFees.dollarWithCentsFormat() }}</span>
          </div>
          <div v-else>
            <span :class="{redText: !canAffordOwnersBucks}">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
              {{ totOwnersBucksFees.dollarWithCentsFormat() }}
            </span>
          </div>
        </div>
        <div class="itemTitle">Entry Fees</div>
      </div>
      <div class="footerItem">
        <div class="itemText">
          <div v-if="contest.realMoney">
            <span :class="canAffordRealMoney ? 'moneyText' : 'redText'">${{ totRealMoneyPrizes }}</span>
          </div>
          <div v-else>
            <span :class="canAffordOwnersBucks ? 'blueText' : 'redText'">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
              {{ totOwnersBucksPrizes }}
            </span>
          </div>
        </div>
        <div class="itemTitle">Total Prizes</div>
      </div>
      <div style="flex: 1;"></div>
      <button class="ob-btn-grey modalBtn" style="width: 120px;" @click="goToLobby">Lobby</button>
      <button v-if="joiningContests" class="ob-btn-grey modalBtn" disabled
        style="width: 120px;"
      >
        <ObLoading class="loadingIndicator" :size="40" />
        <span>Joining...</span>
      </button>
      <button v-else :class="canSubmit ? 'ob-btn' : 'ob-btn-grey modalBtn'" :disabled="!canSubmit"
        style="width: 120px;"
        @click="joinContests"
      >
        Join Contests
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import QuickEntryPlayerList from './QuickEntryPlayerList';
import QuickEntryContestList from './QuickEntryContestList';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    QuickEntryPlayerList,
    QuickEntryContestList,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      contest: null,
      atMaxEntries: false,
      promoInfo: {},
      lineupInfo: {},
      numLineups: 1,
      contestList: [],
      loadingContests: false,
      joiningContests: false,
      contestError: null,
      entriesByContest: {},
    };
  },

  created() {
    EventBus.$on('SALARY_CAP_SUBMIT_SUCCESS', this.openModal);
    EventBus.$on('QUICK_ENTRY_CHANGE_ENTRY_VAL', this.updateEntryVal);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_SUBMIT_SUCCESS', this.openModal);
    EventBus.$off('QUICK_ENTRY_CHANGE_ENTRY_VAL', this.updateEntryVal);
  },

  computed: {
    ...mapState(['accountBalance', 'ownersbucks', 'ownersbucksValue', 'balanceValue']),

    titleText() {
      const amountText = this.numLineups > 1 ? this.numLineups + ' ' : '';
      return amountText + 'Lineup' + (this.numLineups > 1 ? 's' : '') + ' Submitted - ' + this.lineupTime;
    },

    lineupTime() {
      if (!this.contest || !this.contest.contestStart) {
        return '';
      }
      return this.$moment(this.contest.contestStart).format('ddd h:mma');
    },

    hasContests() {
      return this.contestList && this.contestList.length > 0;
    },

    canSubmit() {
      return this.numSelectedContests > 0 && this.canAffordRealMoney && this.canAffordOwnersBucks;
    },

    numSelectedContests() {
      let tot = 0;
      for (const contest of this.contestList) {
        tot += this.entriesByContest[contest.id];
      }

      return tot;
    },

    canAffordRealMoney() {
      return this.balanceValue >= this.totRealMoneyFees;
    },

    canAffordOwnersBucks() {
      return this.ownersbucksValue >= this.totOwnersBucksFees;
    },

    totRealMoneyFees() {
      let tot = 0;
      for (const contest of this.contestList) {
        if (contest.realMoney && this.entriesByContest[contest.id]) {
          tot += contest.feeValue * this.entriesByContest[contest.id];
        }
      }
      return tot;
    },

    totOwnersBucksFees() {
      let tot = 0;
      for (const contest of this.contestList) {
        if (!contest.realMoney && this.entriesByContest[contest.id]) {
          tot += contest.feeValue * this.entriesByContest[contest.id];
        }
      }
      return tot;
    },

    totRealMoneyPrizes() {
      let tot = 0;
      for (const contest of this.contestList) {
        if (contest.realMoney && this.entriesByContest[contest.id]) {
          tot += contest.prizeValue;
        }
      }
      return tot.dollarWithCentsFormat();
    },

    totOwnersBucksPrizes() {
      let tot = 0;
      for (const contest of this.contestList) {
        if (!contest.realMoney) {
          tot += contest.prizeValue;
        }
      }
      return tot.dollarWithCentsFormat();
    },

    entriesToSubmit() {
      const entriesToSubmit = [];
      for (const contest of this.contestList) {
        entriesToSubmit.push({
          id: contest.id,
          count: this.entriesByContest[contest.id],
        });
      }
      return entriesToSubmit;
    },

    contestMap() {
      const contestMap = {};
      for (const contest of this.contestList) {
        this.$set(contestMap, contest.id, contest);
      }

      return contestMap;
    },

    numMultiCreateSuccessContests() {
      if (this.lineupInfo && this.lineupInfo.submitTeamResults) {
        const successContests = this.lineupInfo.submitTeamResults.slice().filter((c) => c.success);

        return successContests.length;
      }

      return 0;
    },
  },

  methods: {
    openModal(contest, promoInfo, atMaxEntries, lineupInfo, numLineups = 1) {
      this.contest = contest;
      this.promoInfo = promoInfo;
      this.atMaxEntries = atMaxEntries;
      this.lineupInfo = lineupInfo;
      this.numLineups = numLineups;
      this.getContestsForLineup();
      this.isOpen = true;

      if (lineupInfo.submitTeamResults) {
        this.openMultiCreateModal(lineupInfo);
        this.numLineups = this.numMultiCreateSuccessContests;
      }
    },

    closeModal() {
      this.isOpen = false;
      this.contest = null;
      this.atMaxEntries = false;
      this.lineupInfo = {};
      this.numLineups = 1;
      this.contestList = [];
      this.entriesByContest = {};
    },

    getContestsForLineup() {
      if (!this.lineupInfo || !this.lineupInfo.id) {
        return;
      }

      this.loadingContests = true;

      const lineupId = this.lineupInfo.id;
      ObSalaryCapApi.getContestsForLineup(lineupId)
          .then((response) => {
            this.contestList = response;
            this.loadingContests = false;
            this.initializeEntryValues();
          }).catch((_error) => {
            this.loadingContests = false;
          });
    },

    updateEntryVal(contestId, value) {
      this.entriesByContest[contestId] = value;
    },

    initializeEntryValues() {
      for (const contest of this.contestList) {
        this.$set(this.entriesByContest, contest.id, 0);
      }
    },

    joinContests() {
      if (!this.lineupInfo || !this.lineupInfo.id || !this.canSubmit) {
        return;
      }

      const self = this;
      const lineupId = this.lineupInfo.id;
      const selectedEntries = this.entriesToSubmit.slice().filter((selectedEntry) => {
        return self.entriesByContest[selectedEntry.id];
      });

      this.joiningContests = true;
      const params = {
        lineupId: lineupId,
        entries: selectedEntries,
      };
      this.submitLineupToContests(params);
    },

    submitLineupToContests(params) {
      const self = this;
      // Copy contest list and change to contest IDs that have been selected
      const selectedContestList = this.contestList.slice().filter((contest) => {
        return self.entriesByContest[contest.id];
      });

      ObSalaryCapApi.submitLineupToContests(params).then((response) => {
        const resultsList = response;
        EventBus.$emit('SALARY_OPEN_QUICK_SUCCESS_MODAL',
            selectedContestList,
            resultsList,
            this.entriesByContest,
        );

        // Track each successful/unsuccessful entry
        for (const contestResult of resultsList) {
          const contest = this.contestMap[contestResult.contestId];
          this.$Analytics.trackTournamentEntry(contest, contestResult.success, null, this.entriesByContest[contestResult.contestId], true);
        }

        this.joiningContests = false;
        EventBus.$emit('UPDATE_ACCOUNT_INFO');
        this.closeModal();
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          this.contestError = error.response.data;
        }
        this.joiningContests = false;
      });
    },

    openMultiCreateModal(lineupInfo) {
      EventBus.$emit('OPEN_MULTI_CREATE_SUBMITTED_MODAL', lineupInfo.submitTeamResults, lineupInfo.multiCreateData);
    },

    goToLobby() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER_COOKIE');
      this.$router.push('/lobby');
    },
  },
};
</script>

<style lang="scss" scoped>
.header, .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .paragraph {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}

.body {
  background: var(--obcolor-background-6);
  flex: 1;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.footer {
  background: var(--obcolor-background-5);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .footerItem {
    margin-right: 25px;
  }
  .itemText {
    font-weight: bold;
  }
  .itemTitle {
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }

  button {
    font-size: 14px;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.quickEntryInfo {
  display: flex;
  align-items: flex-start;
  flex: 1;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.playerTableContainer, .contestTableContainer {
  width: 50%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  &.loadingInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loadingIndicator {
      margin-top: -80px;
    }
  }
}

.contestTableContainer {
  padding-right: 10px;
}

.noContestText {
  width: 50%;
  font-size: 14px;
  text-align: center;
  padding-top: 7px;
}

.contestText {
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.redText {
  color: var(--obcolor-red);
}

.greyText {
  color: var(--obcolor-font-secondary);
}

.ownersbucksIcon {
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.loadingIndicator {
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: -10px;
}
</style>