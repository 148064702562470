<template>
  <div class="container">
    <h1>Congratulations!</h1>
    <p class="textInfo">Your account has been verified</p>
    <div v-for="(step, index) in steps" :key="index" class="steps">
      <i :class="step.iconSrc"></i>
      <div>{{ step.text }}</div>
    </div>

    <!-- Prompt to return to related page if was redirected from there -->
    <router-link class="ob-btn depositBtn" :to="redirectRoute" v-if="hasRedirect">
      Return
    </router-link>
    <div v-else-if="onBoardingStep" class="buttonContainer">
      <button class="ob-btn-grey" @click="skipVerifyAccount()">Later</button>
      <button class="ob-btn" @click="goToDeposit()">Deposit Now</button>
    </div>
    <button v-else class="ob-btn depositBtn" @click="goToDeposit()">
      Deposit Now
    </button>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    onBoardingStep: {type: Boolean, default: false},
  },

  data() {
    return {
      steps: [
        {
          iconSrc: 'fal fa-check-circle',
          text: 'You can apply your sign-up bonus (check your email for details)',
        },
        {
          iconSrc: 'fal fa-check-circle',
          text: 'You can now play paid contests',
        },
        {
          iconSrc: 'fal fa-history',
          text: 'Deposit funds to earn an additional bonus',
        },
      ],
    };
  },

  computed: {
    hasRedirect() {
      return this.$route.query.redirectBackTo != null;
    },

    redirectRoute() {
      if (!this.hasRedirect) {
        return null;
      }

      return '/' + this.$route.query.redirectBackTo;
    },
  },

  methods: {
    skipVerifyAccount() {
      EventBus.$emit('ON_BOARDING_EXIT');
    },

    goToDeposit() {
      if (this.onBoardingStep) {
        EventBus.$emit('ON_BOARDING_NEXT');
      } else {
        this.$router.replace('/deposit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .ob-btn, .ob-btn-grey {
    box-sizing: border-box;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .depositBtn {
    margin-top: 50px;
  }

  .textInfo {
    padding: 0 0 25px 0;
    text-align: center;
    margin: 0;
  }

  h1 {
    margin-top: 50px;
    text-align: center;
    font-size: 24px;
    line-height: 10px;
    font-weight: bold;
    padding: 50px 0 30px 0;
  }

  .fa-check-circle {
    color: var(--obcolor-green);
  }

  .fa-history {
    color: var(--obcolor-ob-blue);
  }

  .steps {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }
}
</style>