<template>
  <!-- Modified version of vue-google-autocomplete repository https://www.npmjs.com/package/vue-google-autocomplete
    Modified to simplify for our use case and not automatically place result into input
  -->
  <input ref="autocomplete"
    type="text"
    :class="classname"
    :id="id"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="autocompleteText"
    @focus="onFocus"
    @blur="onBlur"
    @change="onChange"
    @keypress="onKeyPress"
    @keyup="onKeyUp"
    @input="onInput"
  >
</template>

<script>
// Script creates global callback for when google loads
import {gmapApi} from 'vue2-google-maps';

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
};

const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country',
  'administrative_area_level_1', 'administrative_area_level_2'];

/*
    By default, we're only including basic place data because requesting these
    fields place data is not additionally charged by Google. Please refer to:

    https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
  */
const BASIC_DATA_FIELDS = ['address_components', 'adr_address', 'alt_id',
  'formatted_address', 'geometry', 'icon', 'id', 'name',
  'business_status', 'photo', 'place_id', 'scope', 'type', 'url',
  'utc_offset_minutes', 'vicinity'];

export default {
  name: 'VueGoogleAutocomplete',

  props: {
    id: {
      type: String,
      required: true,
    },

    classname: String,

    placeholder: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    types: {
      type: String,
      default: 'address',
    },

    fields: {
      type: Array,
      default: function() {
        return BASIC_DATA_FIELDS;
      },
    },

    country: {
      type: [String, Array],
      default: null,
    },

    enableGeolocation: {
      type: Boolean,
      default: false,
    },

    geolocationOptions: {
      type: Object,
      default: null,
    },

    changeInputOnClick: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,

      /**
     * Autocomplete input text
     * @type {String}
     */
      autocompleteText: '',

      geolocation: {
        /**
         * Google Geocoder Objet
         * @type {Geocoder}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,

        /**
         * Filled after geolocate result
         * @type {Coordinates}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,

        /**
         * Filled after geolocate result
         * @type {Position}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null,
      },
    };
  },

  watch: {
    autocompleteText(newVal, oldVal) {
      this.$emit('inputChange', {newVal, oldVal}, this.id);
    },
    country() {
      if (!this.autocomplete) {
        return;
      }
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country,
      });
    },
  },

  mounted() {
    // Global function from gmapApi, has a callback when google maps is loaded
    this.$gmapApiPromiseLazy().then(() => {
      this.scriptLoaded();
    });
  },

  methods: {
    scriptLoaded() {
      // Failsafe if script didn't load properly - Treat component as text input
      if (typeof google?.maps?.places?.Autocomplete != 'function') {
        return;
      }

      const options = {};

      if (this.types) {
        options.types = [this.types];
      }

      if (this.country) {
        options.componentRestrictions = {
          country: this.country,
        };
      }
      this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById(this.id),
          options,
      );

      this.autocomplete.setFields(this.fields);

      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },

    /**
     * When a place is changed
     */
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place, this.id);
        return;
      }

      if (place.address_components === undefined) {
        return;
      }

      // Code to change full address to street address
      let address1 = '';
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }
        }
      }

      // Override google place default setting to set the value to the address instead
      this.autocompleteText = address1;
      document.getElementById(this.id).value = address1;

      // return returnData object and PlaceResult object
      this.$emit('placechanged', this.formatResult(place), place, this.id);
      this.onChange();
    },

    /**
     * When the input gets focus - Default function for inputs
     */
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit('focus');
    },

    /**
     * When the input loses focus - Default function for inputs
     */
    onBlur() {
      this.$emit('blur');
    },

    /**
     * When the input got changed - Default function for inputs
     */
    onChange() {
      this.$emit('change', this.autocompleteText);
    },

    /**
     * When a key gets pressed - Default function for inputs
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit('keypress', event);
    },

    /**
     * When a keyup occurs - Default function for inputs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit('keyup', event);
    },

    /**
     * When the input changes, propogate that to the parent
     * @param  {Event} event A keypress event
     */
    onInput(event) {
      this.$emit('input', event.target.value);
    },

    /**
     * Clear the input - Default function for inputs
     */
    clear() {
      this.autocompleteText = '';
    },

    /**
     * Focus the input - Default function for inputs
     */
    focus() {
      if (!this.autocomplete) {
        this.$emit('focus');
        return;
      }
      this.$refs.autocomplete.focus();
    },

    /**
     * Blur the input - Default function for inputs
     */
    blur() {
      if (!this.autocomplete) {
        this.$emit('blur');
        return;
      }
      this.$refs.autocomplete.blur();
    },

    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value;
      this.$emit('input', value);
    },

    /**
     * Update the coordinates of the input
     * @param  {Coordinates} value
     */
    updateCoordinates(value) {
      if (value && !(value.lat || value.lng)) return;
      if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder();
      this.geolocation.geocoder.geocode({'location': value}, (results, status) => {
        if (status === 'OK') {
          results = this.filterGeocodeResultTypes(results);
          if (results[0]) {
            this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id);
            if (this.changeInputOnClick) {
              this.update(results[0].formatted_address);
            }
          } else {
            this.$emit('error', 'no result for provided coordinates');
          }
        } else {
          this.$emit('error', 'error getting address from coords');
        }
      });
    },

    /**
     * Update location based on navigator geolocation
     */
    geolocate() {
      this.updateGeolocation((geolocation, position) => {
        this.updateCoordinates(geolocation);
      });
    },

    /**
     * Update internal location from navigator geolocation
     * @param {Function} callback (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        const options = {};
        if (this.geolocationOptions) Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition((position) => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.geolocation.loc = geolocation;
          this.geolocation.position = position;

          if (callback) callback(geolocation, position);
        }, (err) => {
          this.$emit('error', 'Cannot get Coordinates from navigator', err);
        }, options);
      }
    },


    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },

    /**
     * Format result from Geo google APIs
     * @param {any} place
     * @return {formattedOutput} Formatted result for lat and long
     */
    formatResult(place) {
      const returnData = {};
      // Code to change full address to street address
      let address1 = '';
      let sublocality = '';
      let city = '';
      for (const addressComponent of place.address_components) {
        const addressType = addressComponent.types[0];

        switch (addressType) {
          case 'street_number': {
            address1 = `${addressComponent.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += addressComponent.short_name;
            break;
          }

          case 'sublocality_level_1': {
            sublocality = addressComponent.long_name;
            break;
          }

          case 'locality': {
            city = addressComponent.long_name;
            break;
          }
        }

        if (ADDRESS_COMPONENTS[addressType]) {
          const val = addressComponent[ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      if (sublocality != '' && city == '') {
        city = sublocality;
      }

      returnData['city'] = city;
      returnData['street_address'] = address1;

      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData;
    },

    /**
     * Extract configured types out of raw result as
     * Geocode API does not allow to do it
     * @param {any} results
     * @return {GeocoderResult}
     * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
     */
    filterGeocodeResultTypes(results) {
      if (!results || !this.types) return results;
      const output = [];
      let types = [this.types];
      if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
      if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

      for (const r of results) {
        for (const t of r.types) {
          if (types.includes(t)) {
            output.push(r);
            break;
          }
        }
      }
      return output;
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  background-color: var(--obcolor-background-5) !important;
  color: var(--obcolor-font-primary) !important;
  border-top: 1px solid var(--obcolor-background-1) !important;
}
.pac-item{
  background-color: var(--obcolor-background-6) !important;
  color: var(--obcolor-font-primary) !important;
  border-top: 1px solid var(--obcolor-background-1) !important;
}
.pac-item-query {
  color: var(--obcolor-font-primary) !important;
}
</style>
