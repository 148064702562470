import axios from 'axios';

export default () => {
  let url = 'https://test.paysecure.acculynk.net';
  switch (location.hostname) {
    case 'ownersbox.com':
    case 'app.ownersbox.com':
    case 'beta.ownersbox.com':
    case 'ownersbox-production-01.appspot.com':
      url = 'https://api.paysecure.acculynk.net';
      break;
  }

  return axios.create({
    baseURL: url,
    withCredentials: false,
  });
};
