<template>
  <div>
    <VerifyAccountEVS v-if="provider === 'EVS'" :onBoardingStep="onBoardingStep" />
    <VerifyAccountGeoComply v-if="provider === 'GEO_COMPLY'" :onBoardingStep="onBoardingStep" />
    <ErrorPanel :message="errorMessage" style="margin-top: 40px;" />
  </div>
</template>

<script>
import VerifyAccountEVS from '@/views/VerifyAccount/VerifyAccountEVSView.vue';
import VerifyAccountGeoComply from '@/views/VerifyAccount/VerifyAccountGeoComplyView.vue';
import ObPaymentApi from '@/api/ObPaymentApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    VerifyAccountEVS,
    VerifyAccountGeoComply,
    ErrorPanel,
  },

  mounted() {
    this.getProvider();
  },

  data() {
    return {
      provider: '',
      errorMessage: null,
    };
  },

  props: {
    onBoardingStep: {type: Boolean, default: false},
  },

  methods: {
    getProvider() {
      ObPaymentApi.getProvider()
          .then((response) => {
            this.provider = response.provider;
          }).catch((error) => {
            if (error?.response?.data?.errorMessage) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'An unexpected error has occurred. Please try again or contact support for assistance';
            }
          });
    },
  },
};
</script>