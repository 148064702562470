<template>
  <div class="summaryContainer">
    <GameSummaryTeamHeading :gameSummary="gameSummary"
      :isHome="false" :sport="sport" :gameData="gameData"
    />

    <div class="h2hInfo">
      <div class="scoreContainer" v-if="game && game.date_utc">
        {{ (getLiveStatus() === '' || getLiveStatus() === 'SCHEDULED') ? $moment(game.date_utc).format('h:mma') :
          (getLiveScore('away') != null ? getLiveScore('away') + '-' + getLiveScore('home') : 'TBA') }}
      </div>
      <div class="liveStatus" v-if="game && game.date_utc">
        {{ (getLiveStatus() === '' || getLiveStatus() === 'SCHEDULED') ? $moment(game.date_utc).format('ddd, MMM D') : getLiveStatus() }}
      </div>
    </div>

    <GameSummaryTeamHeading :gameSummary="gameSummary"
      :isHome="true" :sport="sport" :gameData="gameData"
    />
  </div>
</template>

<script>
import GameSummaryTeamHeading from '@/components/modals/GameModal/GameSummaryTeamHeading';

export default {
  props: {
    gameSummary: Object,
    sport: String,
    gameData: Object,
  },

  components: {
    GameSummaryTeamHeading,
  },

  computed: {
    game() {
      // If live data is not available, use data from the matchup json
      if (this.gameSummary && this.gameSummary.live) {
        return this.gameSummary;
      }
      if (this.gameData && this.gameData.matchup) {
        return this.gameData.matchup;
      }
      return null;
    },
  },

  methods: {
    // Parse score, check if game is finished. If it is, return whether the team won.
    hasWon(homeAway) {
      let score = this.getLiveScore(homeAway);
      let oppScore = homeAway == 'home' ? this.getLiveScore('away') : this.getLiveScore('home');

      if (score == null || !this.game.live || this.game.live.status != 'post-event') {
        return false;
      } else if (oppScore == null) {
        return true;
      }

      score = parseInt(score);
      oppScore = parseInt(oppScore);
      return score > oppScore;
    },

    getLiveScore(homeAway) {
      const indexName = homeAway == 'home' ? 'hometeam' : 'awayteam';
      if (this.game && this.game.live && this.game.live[indexName]) {
        return this.game.live[indexName].score;
      }
      return null;
    },

    // Returns a printable string representing how far in the game is
    getLiveGameState() {
      if (!this.game) {
        return '';
      }

      const liveJSON = this.game.live;
      let gameInfo = '';

      if (this.sport === 'MLB') {
        if (liveJSON.inning_half && liveJSON.inning) {
          gameInfo += liveJSON.inning_half === 'top' ? 'Top ' : 'Bot ';
          gameInfo += this.addOrdinalSuffix(liveJSON.inning);
        }
        return gameInfo;
      }

      const gameTime = liveJSON.gametime === '00:00' ? 'End' : liveJSON.gametime;
      gameInfo += gameTime + ' ' + this.addOrdinalSuffix(liveJSON.period);
      return gameInfo.toUpperCase();
    },

    // Returns a printable string representing the status of the game (Postponed, final, etc.)
    getLiveStatus() {
      if (!this.game) {
        return '';
      }

      const gameStatus = this.game.live ? this.game.live.status : '';
      switch (gameStatus) {
        case 'mid-event': return this.getLiveGameState();
        case 'Postponed': return 'POSTPONED';
        case 'Canceled': return 'CANCELED';
        case 'post-event':
          if (this.sport !== 'MLB') {
            const period = this.$SportInfo.getGamePeriod(this.sport, this.game);
            if (period.includes('OT')) {
              return 'Final ' + period;
            }
          } else if (this.sport === 'MLB') {
            const finalInnings = this.game?.live?.awayteam?.scores?.split(',').length;
            if (finalInnings >= 10) {
              return 'Final/' + finalInnings;
            }
          }
          return 'Final';
      }
      return 'SCHEDULED';
    },

    addOrdinalSuffix(i) {
      if (i == null) {
        return '';
      }

      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
  },
};
</script>

<style lang="scss" scoped>
  .summaryContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 35px;
    box-sizing: border-box;
    background: var(--obcolor-background-5);
    border-bottom: 3px solid var(--obcolor-background-3);
    min-height: 105px;
  }

  .h2hInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 65px;
    padding-top: 10px;
  }

  .scoreContainer {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .liveStatus {
    color: var(--obcolor-font-secondary);
    text-align: center;
  }
</style>