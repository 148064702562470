<template>
  <div v-if="topPerformers.hasTopPerformers" class="allTopPerformers">
    <!-- Headers for stat sections (eg. 'Rushing', 'Passing'..., or 'Pitching', 'Batting', or Forward) -->
    <h1 class="sectionHeader">
      Top Fantasy Performers <span class="topPerformersSubheader">
        {{ gameIsLiveOrComplete ? '(Game)' : '(Season)' }}
      </span>
    </h1>
    <div class="topPerformers" v-for="(category, index1) in Object.keys(topPerformers.awayteam)" :key="'top_performer_' + index1">
      <div v-if="checkIfTopPerformer(category)">
        <h2 class="topPerformersSubheader topPerformersBorder">
          {{ category }}
        </h2>
        <div>
          <div class="awayTopPerformer">
            <div class="topPerformerCentered">
              <PlayerHeadshot size="45" :id="topPerformers.awayteam[category].id" :sport="sport"
                :team="topPerformers.awayteam[category].team" :showLogo="true" :pos="getTopPerformerPosition('awayteam', category)"
              />
              <div class="topPerformerStats">
                <div class="topPerformerCentered">
                  <span class="playerStatus">
                    <PlayerStatus height="12" :status="topPerformers.awayteam[category].status ? topPerformers.awayteam[category].status : 'ACT'" />
                  </span>
                  <div class="ob-link" @click.stop="openPlayerCard(topPerformers.awayteam[category].id)">
                    {{ topPerformers.awayteam[category].name.shortName() }}
                  </div>
                </div>
                <p class="topPerformerSubtext">
                  {{ getStats(topPerformers.awayteam[category]) }}
                </p>
              </div>
            </div>
            <div class="fantasyStats">
              <div v-if="!gameIsLiveOrComplete" class="topPerformerSAVG">
                {{ topPerformers.awayteam[category].sAvg }}
                <p class="topPerformerSubtext">sAVG</p>
              </div>
              <div class="topPerformerProj fptsField" :class="{ negativePts: getProjOrFpts(topPerformers.awayteam[category]) < 0 }">
                {{ getProjOrFpts(topPerformers.awayteam[category]) }}
                <p class="topPerformerSubtext">{{ gameIsLiveOrComplete ? 'Pts' : 'Proj' }}</p>
              </div>
            </div>
          </div>
          <div class="homeTopPerformer">
            <div class="topPerformerCentered">
              <PlayerHeadshot size="45" :id="topPerformers.hometeam[category].id" :sport="sport"
                :team="topPerformers.hometeam[category].team" :showLogo="true" :pos="getTopPerformerPosition('hometeam', category)"
              />
              <div class="topPerformerStats">
                <div class="topPerformerCentered">
                  <span class="playerStatus">
                    <PlayerStatus height="12" :status="topPerformers.hometeam[category].status ? topPerformers.hometeam[category].status : 'ACT'" />
                  </span>
                  <div class="ob-link" @click.stop="openPlayerCard(topPerformers.hometeam[category].id)">
                    {{ topPerformers.hometeam[category].name.shortName() }}
                  </div>
                </div>
                <p class="topPerformerSubtext">
                  {{ getStats(topPerformers.hometeam[category]) }}
                </p>
              </div>
            </div>
            <div class="fantasyStats">
              <div v-if="!gameIsLiveOrComplete" class="topPerformerSAVG">
                {{ topPerformers.hometeam[category].sAvg }}
                <p class="topPerformerSubtext">sAVG</p>
              </div>
              <div class="topPerformerProj fptsField" :class="{ negativePts: getProjOrFpts(topPerformers.hometeam[category]) < 0 }">
                {{ getProjOrFpts(topPerformers.hometeam[category]) }}
                <p class="topPerformerSubtext">{{ gameIsLiveOrComplete ? 'Pts' : 'Proj' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    gameIsLiveOrComplete() {
      return this.gameData?.matchup?.live?.status === 'mid-event' || this.gameData?.matchup?.live?.status === 'post-event';
    },

    topPerformers() {
      let topPerformers = {awayteam: {}, hometeam: {}, hasTopPerformers: false};
      const comparisonFactor = this.gameIsLiveOrComplete ? 'stats.fpts.total' : 'sAvg';
      const sortingFrom = this.gameIsLiveOrComplete ? 'boxscore' : 'rosters';
      const statsFrom = this.gameIsLiveOrComplete ? 'stats' : 'statistics';
      if (this.gameData[sortingFrom].awayteam.length === 0 || this.gameData[sortingFrom].hometeam.length === 0) {
        return topPerformers;
      }
      topPerformers = this.getTopPerformers(topPerformers, sortingFrom, statsFrom, comparisonFactor);
      topPerformers.hasTopPerformers = this.checkIfTopPerformers(topPerformers);
      return topPerformers;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    getTopPerformers(topPerformers, sortingFrom, statsFrom, comparisonFactor) {
      for (const category of this.$ScoringStats.getAllLiveStats(this.sport)) {
        for (const awayHome of ['awayteam', 'hometeam']) {
          if (category.label === 'Pitchers') {
            topPerformers[awayHome]['Pitching'] = this.getPitchingTopPerformer(awayHome, sortingFrom, statsFrom, comparisonFactor);
          } else if (category.label === 'Batters') {
            topPerformers[awayHome]['Batting'] = this.getBattingTopPerformer(awayHome, sortingFrom, statsFrom, comparisonFactor);
          } else if (category.label === 'Quarterbacks') {
            topPerformers[awayHome]['Passing'] = this.getPassingTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Running Backs') {
            topPerformers[awayHome]['Rushing'] = this.getRushingTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Receivers') {
            topPerformers[awayHome]['Receiving'] = this.getReceivingTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Guards') {
            topPerformers[awayHome]['Guard'] = this.getGuardTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Forwards') {
            topPerformers[awayHome]['Forward'] = this.getForwardTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Centers') {
            topPerformers[awayHome]['Center'] = this.getCenterTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Skaters') {
            topPerformers[awayHome]['Forward'] = this.getForwardSkaterTopPerformer(awayHome, sortingFrom, comparisonFactor);
            topPerformers[awayHome]['Defense'] = this.getDefenseTopPerformer(awayHome, sortingFrom, comparisonFactor);
          } else if (category.label === 'Goalies') {
            topPerformers[awayHome]['Goalies'] = this.getGoaliesTopPerformer(awayHome, sortingFrom, comparisonFactor);
          }
        }
      }
      return topPerformers;
    },

    checkIfTopPerformers(topPerformers) {
      if (!topPerformers?.awayteam || !topPerformers?.hometeam) {
        return false;
      }
      for (const topPerformer in topPerformers.awayteam) {
        if (topPerformers.awayteam[topPerformer]?.stats?.fpts?.total || topPerformers?.awayteam[topPerformer]?.sAvg) return true;
      }
      for (const topPerformer in topPerformers.hometeam) {
        if (topPerformers.hometeam[topPerformer]?.stats?.fpts?.total || topPerformers?.hometeam[topPerformer]?.sAvg) return true;
      }
      return false;
    },

    checkIfTopPerformer(category) {
      if (this.topPerformers.awayteam[category]?.stats?.fpts?.total && this.topPerformers.hometeam[category]?.stats?.fpts?.total) {
        return true;
      }
      return this.topPerformers.awayteam[category]?.sAvg && this.topPerformers.hometeam[category]?.sAvg;
    },

    getPitchingTopPerformer(awayHome, sortingFrom, statsFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return (player.position === 'P' || player.position === 'SP') && player[statsFrom].pitcher;
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getBattingTopPerformer(awayHome, sortingFrom, statsFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return !(player.position === 'P' || player.position === 'SP') && player[statsFrom].batter;
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getPassingTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return player.position === 'QB';
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getRushingTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return (player.position === 'RB' || player.position === 'FB');
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getReceivingTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return (player.position === 'TE' || player.position === 'WR');
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getForwardTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return (player.position === 'PF' || player.position === 'SF');
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getGuardTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return (player.position === 'SG' || player.position === 'PG');
      }).reduce((prev, curr) => {
        return (parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr))) ? prev : curr;
      }, {});
    },

    getCenterTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome].filter((player) => {
        return player.position === 'C';
      }).reduce((prev, curr) => {
        return parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr)) ? prev : curr;
      }, {});
    },

    getForwardSkaterTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome]
          .filter((player) => {
            return player.position === 'C' || player.position === 'LW' || player.position === 'RW';
          })
          .reduce((prev, curr) => {
            return parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr)) ? prev : curr;
          }, {});
    },

    getDefenseTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome]
          .filter((player) => {
            return player.position === 'D';
          })
          .reduce((prev, curr) => {
            return parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr)) ? prev : curr;
          }, {});
    },

    getGoaliesTopPerformer(awayHome, sortingFrom, comparisonFactor) {
      return this.gameData[sortingFrom][awayHome]
          .filter((player) => {
            if (this.gameIsLiveOrComplete) {
              return player.position === 'G' && player.stats?.skater?.TOI != '-';
            } else {
              return player.position === 'G';
            }
          })
          .reduce((prev, curr) => {
            return parseFloat(this.$Helpers.parsePath(comparisonFactor, prev)) > parseFloat(this.$Helpers.parsePath(comparisonFactor, curr)) ? prev : curr;
          }, {});
    },

    getStats(topPerformerAllStats) {
      const topPerformerStats = topPerformerAllStats.stats ? topPerformerAllStats.stats : topPerformerAllStats.statistics;
      if (Object.keys(topPerformerStats).length === 0) {
        return '-';
      }
      const scoringStats = this.gameIsLiveOrComplete ? this.$ScoringStats.getIngameScoringStats(this.sport, topPerformerAllStats.position) :
      this.$ScoringStats.getPregameScoringStats(this.sport, topPerformerAllStats.position);
      const statResults = this.makeStats(scoringStats, topPerformerStats);
      return (statResults.length >= 1 ? statResults.join(', ') : '-');
    },

    parseStatValue(statLabel, topPerformerStats) {
      return this.$Helpers.parsePath(statLabel, topPerformerStats);
    },

    makeParsedStat(stat, topPerformerStats, statResults) {
      if (this.parseStatValue(stat.label, topPerformerStats) === '1') {
        statResults.push(this.parseStatValue(stat.label, topPerformerStats) + ' ' + stat.description.removePluralWords());
      } else if (this.parseStatValue(stat.label, topPerformerStats) && parseFloat(this.parseStatValue(stat.label, topPerformerStats)) !== 0) {
        statResults.push(this.parseStatValue(stat.label, topPerformerStats) + ' ' + stat.description);
      }
    },

    makeMLBWLStat(topPerformerStats, statResults) {
      if (topPerformerStats?.pitcher?.W && topPerformerStats?.pitcher?.L) {
        statResults.push(topPerformerStats.pitcher.W + '-' + topPerformerStats.pitcher.L);
      } else {
        statResults.push('0-0');
      }
    },

    makeMLBHABStat(topPerformerStats, statResults) {
      if (topPerformerStats?.batter?.H && topPerformerStats?.batter?.AB) {
        statResults.push(topPerformerStats.batter.H + '/' + topPerformerStats.batter.AB);
      } else {
        statResults.push('0/0');
      }
    },

    makerSkaterStat(stat, topPerformerStats, statResults) {
      if (stat.label === 'skater.plus_minus') {
        statResults.push((parseInt(this.parseStatValue(stat.label, topPerformerStats)) >= 0 ? '+' : '') + this.parseStatValue(stat.label, topPerformerStats));
      } else if (parseFloat(this.parseStatValue(stat.label, topPerformerStats)) !== 0) {
        statResults.push(this.parseStatValue(stat.label, topPerformerStats) + stat.description);
      }
    },

    makeGoalieStat(topPerformerStats, statResults) {
      if (topPerformerStats?.goalie?.W && topPerformerStats?.goalie?.L && topPerformerStats?.goalie?.OTL) {
        statResults.push(topPerformerStats.goalie.W + '-' + topPerformerStats.goalie.L + '-' + topPerformerStats.goalie.OTL);
      }
    },

    makeStats(scoringStats, topPerformerStats) {
      const statResults = [];

      for (const stat of scoringStats[0].stats) {
        switch (stat.label) {
          case 'W/L':
            this.makeMLBWLStat(topPerformerStats, statResults);
            break;
          case 'H/AB':
            this.makeMLBHABStat(topPerformerStats, statResults);
            break;
          case 'skater.G': case 'skater.A': case 'skater.plus_minus':
            this.makerSkaterStat(stat, topPerformerStats, statResults);
            break;
          case 'goalie.W_L_OTL':
            this.makeGoalieStat(topPerformerStats, statResults);
            break;
          case 'WHIP': case 'ERA':
            if (this.parseStatValue(stat.label, topPerformerStats)) {
              statResults.push(this.parseStatValue(stat.label, topPerformerStats) + ' ' + stat.description);
            }
            break;
          default:
            this.makeParsedStat(stat, topPerformerStats, statResults);
        }
      }
      return statResults;
    },

    getProjOrFpts(player) {
      if (this.gameIsLiveOrComplete) {
        return player.stats.fpts.total;
      }
      const gameId = this.gameData.matchup.id;
      const gameInfo = this.getGameInfo(player, gameId);
      return gameInfo?.ProjPoints?.total || '0';
    },

    getGameInfo(player, gameId) {
      if (player?.next_games) {
        for (const game of player.next_games) {
          if (game.id === gameId) {
            return game;
          }
        }
      }
      return null;
    },

    getTopPerformerPosition(team, category) {
      if (this.sport === 'NHL' && (this.topPerformers[team][category].position === 'RW' || this.topPerformers[team][category].position === 'LW' || this.topPerformers[team][category].position === 'C')) {
        return 'F';
      }
      return this.topPerformers[team][category].position;
    },
  },
};
</script>

<style lang="scss" scoped>
.allTopPerformers {
  margin: 20px 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.topPerformersSubheader {
  color: var(--obcolor-font-secondary);
  font-size: 18px;
  font-weight: initial;
  padding-bottom: 2px;
}
.topPerformerCentered {
  display: flex;
  align-items: center;
}
.topPerformersBorder {
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.topPerformers {
  font-weight: bold;
  text-align: center;
  display: grid;
  margin-bottom: 10px;
}

.awayTopPerformer {
  width: 45%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.topPerformerStats {
  margin-left: 20px;
  text-align: left;
  max-width: 250px;
}

.fantasyStats {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.topPerformerSAVG {
  text-align: right;
  margin-left: 20px;
}

.topPerformerProj {
  text-align: right;
  margin-left: 20px;
}

.fptsField {
  color: var(--obcolor-ob-blue);
}

.fptsField.negativePts {
  color: var(--obcolor-red);
}

.homeTopPerformer {
  width: 45%;
  float: right;
  display: flex;
  justify-content: space-between;
}

.topPerformerSubtext {
  font-weight: initial;
  margin: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
}
</style>