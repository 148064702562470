import Api from '@/api/ObBaseApi';

export default {

  getLicense(forceUpdate = false) {
    const params = forceUpdate ? '?forceUpdate=true' : '';
    return Api()
        .get('/fsp/geolocation/getLicense' + params)
        .then((response) => {
          return response.data;
        });
  },

  getGeopacketExpiry(geopacket) {
    const config = {};
    config.headers = {'Ownersbox-Location': geopacket};
    return Api()
        .get('/fsp/geolocation/getGeopacketExpirySeconds', config)
        .then((response) => {
          return response.data;
        });
  },

};