// Header Summary Stats
// Controls the top overall summary for header page
// MLB has 2 categories for for pitching and batting

export const HEADER_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: 'GP', path: 'batter.GP', tooltip: 'Games Played'},
      {label: 'H', path: 'batter.H', tooltip: 'Hits'},
      {label: 'HR', path: 'batter.HR', tooltip: 'Home Runs'},
      {label: 'R', path: 'batter.R', tooltip: 'Runs'},
      {label: 'RBI', path: 'batter.RBI', tooltip: 'Runs Batted In'},
      {label: 'SB', path: 'batter.SB', tooltip: 'Stolen Bases'},
      {label: 'AVG', path: 'batter.AVG', tooltip: 'Batting Average'},
    ],
  },
];

export const HEADER_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'IP', path: 'pitcher.IP', tooltip: 'Innings Pitched'},
      {label: 'W', path: 'pitcher.W', tooltip: 'Wins'},
      {label: 'L', path: 'pitcher.L', tooltip: 'Losses'},
      {label: 'K', path: 'pitcher.K', tooltip: 'Strikeouts'},
      {label: 'ERA', path: 'pitcher.ERA', tooltip: 'Earned Runs Average'},
      {label: 'WHIP', path: 'pitcher.WHIP', tooltip: 'Walks + Hits per Inning Pitched'},
      {label: 'SV', path: 'pitcher.SV', tooltip: 'Saves'},
    ],
  },
];
