<template>
  <div v-if="player" class="playerBox" :class="{ noClick: !allowClick }"
    @click="selectPlayer()"
  >
    <!-- Player position, number, image and remove player button -->
    <div style="display: flex;">
      <div class="posContainer">
        <div class="playerPos">{{ pos }}</div>
        <div class="playerNumber">
          #{{ player.number }}
        </div>
      </div>
      <div class="headshotContainer">
        <PlayerHeadshot :id="player.id"
          size="50" :sport="contest.sport"
          :team="player.team"
          :showLogo="true"
        />
      </div>
      <div v-if="allowClick" class="removeBtn" @click.stop="removePlayer()">
        <i class="fal fa-minus-circle"></i>
      </div>
      <div v-else class="removeBtnEmpty"></div>
    </div>

    <!-- Player name info -->
    <div class="nameContainer">
      <div class="statusLoading" v-if="!statusMap">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <PlayerStatus v-else-if="!statusMap[player.id]" :status="'OUT'" />
      <PlayerStatus v-else :status="statusMap[player.id].status" />
      <div @click.stop="openPlayerCard()" class="playerName ob-link">
        {{ player.name.shortName() }}
      </div>
    </div>

    <!-- Player stats -->
    <table class="playerStatsTable" aria-label="Player Statistics">
      <tr>
        <td v-if="contest.format == 'WEEKLY'">{{ getStat('pg') }}</td>
        <td v-else>
          <div class="logoContainer">
            <img v-if="nextGame" :src="getTeamLogo(nextGame.game)" :alt="nextGame.game"
              style="height: 20px; margin-top: -3px; margin-bottom: -3px;"
            >
            <div v-else>-</div>
          </div>
        </td>
        <td class="blueText">{{ getProj().toFixed(0) || '-' }}</td>
        <td class="moneyText">{{ dollarFormat(player.salary) }}</td>
      </tr>
      <tr class="playerStatHeadings">
        <template v-if="contest.format == 'WEEKLY'">
          <td>PG</td>
          <td>{{ getStat('avg').toFixed(0) || '-' }}</td>
          <td>{{ dollarFormat(player.salaryPG) }}</td>
        </template>
        <template v-else>
          <td style="width: 28px;">Opp</td>
          <td>Proj</td>
          <td>Salary</td>
        </template>
      </tr>
    </table>
  </div>

  <!-- Empty Box - No Player Selected -->
  <div v-else class="playerBox" @click="selectRosterFilter(pos)">
    <div v-if="isNflSuperFlex" class="playerEmpty">
      <div>
        <div>SFLEX</div>
        <div class="subPosition">QB/RB/WR/TE</div>
      </div>
    </div>
    <div v-else-if="isNflFlex" class="playerEmpty">
      <div>
        <div>FLEX</div>
        <div class="subPosition">RB/WR/TE</div>
      </div>
    </div>
    <div v-else class="playerEmpty">{{ pos }}</div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    player: Object,
    contest: Object,
    pos: String,
    statusMap: Object,
    allowClick: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    nextGame() {
      if (!this.player.games) {
        return null;
      }
      return this.player.games.find((g) => g.ts != null);
    },

    isNflFlex() {
      return this.contest.sport === 'NFL' && this.pos === 'F';
    },

    isNflSuperFlex() {
      return this.contest.sport === 'NFL' && this.pos === 'SF';
    },
  },

  methods: {
    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const d = Math.round(dollarAmount);
      return '$' + Intl.NumberFormat('en-US').format(d);
    },

    selectPlayer() {
      if (!this.allowClick) {
        return;
      }
      // Daily doesn't have "selected" player, so treat this like the plus/minus button instead
      if (this.contest.format == 'DAILY') {
        this.removePlayer();
        return;
      }

      EventBus.$emit('SALARY_CAP_SELECT_PLAYER', this.player);
    },

    removePlayer() {
      if (!this.allowClick) {
        return;
      }
      EventBus.$emit('SALARY_CAP_REMOVE_PLAYER', this.player);
    },

    selectRosterFilter(posName) {
      if (!this.allowClick) {
        return;
      }
      EventBus.$emit('SALARY_CAP_SELECT_POS_FILTER', posName);
    },

    getStat(path) {
      const player = this.player;
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }

      return null;
    },

    getProj() {
      const projection = this.getStat('proj');
      if (projection && this.$SportInfo.isDraftPositionMultiplier(this.pos)) {
        return projection * 1.5;
      }
      return projection;
    },

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },
  },
};
</script>

<style lang="scss" scoped>

  .playerBox {
    margin: 2px;
    display: flex;
    flex-direction: column;
    background: var(--obcolor-background-4);
    height: 120px;
    width: 121px;
  }

  .playerBox:not(.noClick) {
    cursor: pointer;
  }

  .playerBox:not(.noClick):hover {
    background: var(--obcolor-background-3);
  }

  .playerBox.selectedPlayer {
    background: var(--obcolor-background-3);
  }

  .statusLoading {
    font-size: 12px;
    margin-right: -1px;
    color: var(--obcolor-font-secondary);
  }

  .posContainer {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .playerPos {
    padding: 3px 5px;
    font-size: 14px;
    background: var(--obcolor-background-2);
    text-align: center;
  }

  .playerNumber {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    margin-top: 2px;
  }

  .headshotContainer {
    flex: 1;
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }

  .removeBtn {
    color: var(--obcolor-red);
    font-size: 20px;
    padding: 3px;
    cursor: pointer;
    height: 22px;
  }
  .removeBtnEmpty {
    width: 26px;
  }
  .removeBtn:hover {
    color: white;
  }

  .nameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  .playerName {
    margin-left: 5px;
    margin-top: 2px;
    max-width: 96px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .playerEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    width: 100%;
    height: 100%;
  }

  .playerStatsTable {
    width: calc(100% - 6px);
    margin: 0 3px;
    box-sizing: border-box;
    margin-top: 3px;

    td {
      text-align: center;
      font-size: 14px;
    }
  }

  .playerStatHeadings td {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--obcolor-font-secondary);
    font-size: 12px;
  }

  .subPosition {
    font-size: 14px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
  }
</style>