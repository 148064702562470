<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 400px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
    bodyStyle="padding-bottom: 0;" :disableClickaway="true"
  >
    <div slot="body" class="confirmBody">
      <div class="ticketInfoContainer">
        <i class="fas fa-check-circle checkMark"><div class="checkBg"></div></i>
        <h2>Ticket{{ pluralTicketLetter }} Redeemed!</h2>
        <div class="conversionVisualContainer">
          <img src="@/assets/icons/ownersbucks-stack.png" alt="ownersbucks">
          <div class="arrow">
            <div class="line"></div>
            <div class="point"></div>
          </div>
          <img class="ticketIcon" src="@/assets/icons/ticket.png" alt="tickets">
        </div>
        <div class="ticketAmount">
          <span>{{ ticketQuantity }}x</span>
          <span class="ticketText">
            <img src="@/assets/icons/ticket.png" alt="tickets">
            ${{ roundedTicketValue }} Ticket{{ pluralTicketLetter }}
          </span>
        </div>
        <div>{{ $moment(timeOfOpen).format('Do MMMM YYYY - h:mm A') }}</div>
      </div>
      <div class="modalButtonContainer">
        <button @click="closeModal" class="ob-btn">Done</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      ticketQuantity: null,
      ticketValue: null,
      timeOfOpen: '',
    };
  },
  created() {
    this.timeOfOpen = this.$moment().valueOf();
    EventBus.$on('OPEN_REDEEM_SUCCESS_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_REDEEM_SUCCESS_MODAL', this.openModal);
  },

  computed: {
    pluralTicketLetter() {
      if (this.ticketQuantity > 1) {
        return 's';
      }

      return '';
    },

    roundedTicketValue() {
      return Math.round(this.ticketValue * 100) / 100;
    },
  },

  methods: {
    resetData() {
      this.timeOfOpen = this.$moment().valueOf();
      this.ticketQuantity = null;
      this.ticketValue = null;
    },

    openModal(ticketQuantity, ticketValue) {
      this.timeOfOpen = this.$moment().valueOf();
      this.ticketQuantity = ticketQuantity;
      this.ticketValue = ticketValue;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.resetData();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Modals/modals.scss';

.confirmBody {
  text-align: center;
}

.checkMark {
  font-size: 45px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: limegreen;
  padding-top: 1px;
  border-radius: 100%;
  border: 14px solid rgba(limegreen, 0.2);
}

h2 {
  font-size: 16px;
  margin: 0;
  font-weight: normal;
}

.conversionVisualContainer {
  display: flex;
  align-items: center;
  width: 205px;
  padding: 11px;
  border: 1px solid var(--obcolor-background-1);
  img {
    height: 30px;

    &.ticketIcon {
      height: 21px;
    }
  }
  .arrow {
    flex: 1;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .line {
    flex: 1;
    background: var(--obcolor-background-1);
    height: 2px;
    float: left;
  }

  .point {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid var(--obcolor-background-1);
    float: right;
  }
}

.ticketInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 40px 15px;
  font-size: 16px;

  .ticketAmount {
    font-weight: bold;
    display: flex;
    gap: 10px;

    .ticketText {
      color: var(--obcolor-ticket);
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        height: 15px;
      }
    }
  }
}

.submittedInfoFooter {
  margin-top: 10px;
  font-size: 15px;
  padding: 10px 20px;
  background: var(--obcolor-background-2);
  line-height: 24px;
}

.modalButtonContainer {
  margin: 0;
}
</style>