<template>
  <div class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
    </div>
    <h1>Referral/Promo Code</h1>
    <div style="padding-bottom: 10px;">(Optional)  If applicable, enter your code below.</div>
    <form @submit.prevent="applyReferral" class="container">
      <input placeholder="Enter your referral/promo code" type="text"
        v-model="referralCode" :class="{errorBorder: errorMessage}"
      >
    </form>
    <ErrorPanel :message="errorMessage" />
    <div @submit.prevent="applyReferral" class="buttonContainer">
      <button class="ob-btn-grey" :disabled="loading" @click="skipVerifyAccount()">I Don’t Have a Code</button>
      <button v-if="loading" class="ob-btn-grey" disabled>
        <i class="fad fa-spinner-third fa-spin"></i>
        Applying Code...
      </button>
      <button v-else class="ob-btn" :disabled="!referralCode"
        @click="applyReferral()"
      >
        Apply Code
      </button>
    </div>

    <Modal v-if="krogerModalOpen" v-model="krogerModalOpen"
      containerStyle="height: 460px; width: 605px; overflow: hidden; padding: 0;"
    >
      <div class="headerContainer" slot="header">
        <img class="modalBg" :src="require('@/assets/onboarding/krogerModal.png')" alt="background">
        <div class="imgContainer">
          <img :src="require('@/assets/obicon_white.png')" alt="ownersbox logo">
          <img :src="require('@/assets/onboarding/x.svg')" alt="times">
          <img :src="require('@/assets/onboarding/kroger.png')" alt="kroger logo">
        </div>
        <div class="modalTitle">Kroger Connect</div>
        <div class="modalText">
          You have been referred to us by Kroger. Connect your Kroger account with OwnersBox to receive Kroger fuel points as you play Daily Fantasy Sports.
        </div>
      </div>
      <div slot="body">
        <div class="buttonContainerModal">
          <div @click="connectLater" class="ob-btn-grey">Later</div>
          <div @click="connectKroger" class="ob-btn">Continue</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import ObAccountApi from '@/api/ObAccountApi';

export default {
  components: {
    Modal,
    ErrorPanel,
  },

  props: {
    signupData: {type: Object, default: null},
  },

  data() {
    return {
      referralCode: null,
      errorMessage: null,
      loading: false,
      krogerModalOpen: false,
    };
  },

  created() {
    if (this.signupData?.referralCode) {
      this.referralCode = this.signupData?.referralCode;
    }
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },

    continue() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    skipVerifyAccount() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    connectKroger() {
      // Go to kroger sign in page
    },

    connectLater() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    applyReferral() {
      if (this.loading || !this.referralCode) {
        return;
      }
      this.loading = true;
      const code = this.referralCode;
      ObAccountApi.validateReferralCode(code)
          .then((_response) => {
            this.loading = false;
            EventBus.$emit('UPDATE_SIGNUP_DATA', 'referralCode', code);
            EventBus.$emit('ON_BOARDING_NEXT');
          }).catch((error) => {
            this.loading = false;
            if (error?.response?.data?.errorMessage) {
              this.errorMessage = error?.response?.data?.errorMessage;
            } else {
              this.errorMessage = 'Failed to validate referral/promo code, please try again';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  button {
    height: 60px;
  }

  .headerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 35px 0;
    font-size: 15px;
    font-weight: 700;
    .modalTitle {
      padding-top: 30px;
      font-size: 24px;
    }
    .modalText {
      text-align: center;
      padding-top: 20px;
      max-width: 90%;
      font-weight: 400;
      line-height: 19px;
    }
    .modalBg {
      height: 166px;
      width: 320px;
    }
    .imgContainer {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-content: center;
      align-items: center;
      z-index: 10;
      padding-top: 25px;
      > img:nth-child(1) {
        height: 28px;
      }
      > img:nth-child(2) {
        width: 16px;
        height: 20px;
      }
      > img:nth-child(3) {
        width: 60px;
        height: 30px;
      }
    }
  }
}

.buttonContainerModal {
  display: flex;
  margin-top: -5px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0;
    height: 40px;
  }

  > div:nth-child(1) {
    width: 25%;
  }
  > div:nth-child(2) {
    width: 75%;
  }
}
</style>