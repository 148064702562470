<template>
  <div>
    <ObLoading v-if="loading" />
    <template v-else>
      <div class="inlineCenter">
        <div class="importInstructionContainer">
          <div class="importInstructionHeader">How to Edit Lineups via CSV</div>
          <div class="importInstruction">1. <span class="exportPlayerSlate" @click="exportPlayerSlate()">Download my lineups</span> for the current slate</div>
          <div class="importInstruction">2. Follow the instructions in the Player List to edit your lineup</div>
          <div class="importInstruction">3. Save the file and upload in the box below</div>
          <div v-if="csvError" class="csvErrorText">{{ csvErrorMessage }}</div>
        </div>
        <div class="importImageContainer">
          <img class="importImage" :src="require('@/assets/importlineups/importCSVLogo.png')" alt="Import CSV File Image">
        </div>
      </div>
      <div @dragover="dragover" @drop="drop">
        <label class="customFileUpload">
          <input type="file" accept=".csv" @change="uploadFile($event)">
          <i class="fas fa-file-upload fileIcon"></i>
          <div class="fileUploadText">Drag and Drop to Upload File</div>
          <div class="fileUploadText">or</div>
          <div class="ob-btn fileUploadText">
            <i class="fas fa-arrow-alt-from-bottom uploadIcon"></i>
            <span>Upload CSV</span>
          </div>
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import {parse} from 'csv-parse/sync';

export default {
  components: {
    ObLoading,
  },

  props: {
    contest: {type: Object, default: null},
    playerMap: {type: Object, default: null},
    promoInfo: {type: Object, default: null},
    showCSVInstructions: Boolean,
    showCSVLineups: Boolean,
  },

  data() {
    return {
      allLineups: [],
      invalidExpanded: false,
      loading: false,
      allLineupsAdded: false,
      numEntries: 0,
      csvErrorMessage: '',
      maxCSVEntries: 150,
      teamIdColumn: 0,
      contestIdColumn: 1,
      firstPositionColumn: 4,
    };
  },

  computed: {
    csvError() {
      return this.csvErrorMessage != '';
    },
  },

  methods: {
    exportPlayerSlate() {
      ObSalaryCapApi.getPlayerSlateWithLineups(this.contest.id, this.contest.contestName)
          .then(() => {
            this.csvErrorMessage = '';
          })
          .catch(() => {
            this.csvErrorMessage = 'Error downloading the contest player slate';
          });
    },

    dragover(e) {
      // Prevent the browser from downloading the file
      // so client can import and process file
      e.preventDefault();
    },

    drop(e) {
      // Prevent the browser from downloading the file
      e.preventDefault();
      this.loading = true;
      this.readFile(e.dataTransfer.files[0]);
    },

    uploadFile(e) {
      this.loading = true;
      this.readFile(e.target.files[0]);
    },

    readFile(file) {
      if (!file) {
        this.loading = false;
        return;
      }

      // Check file extension
      if (!this.isCSVFile(file.name)) {
        this.loading = false;
        this.csvErrorMessage = 'Only CSV files are permitted';
        return;
      }

      // Read CSV file
      const reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        self.parseFile(e.target.result);
      };
      reader.readAsText(file);
    },

    parseFile(csvFile) {
      const file = parse(csvFile, {relax_column_count: true});
      this.parseLineups(file);
    },

    isCSVFile(filename) {
      if (!filename) {
        return false;
      }

      const fileExtension = filename.split('.').pop();
      return fileExtension == 'csv';
    },

    // Get lineups from csv file imported
    parseLineups(file) {
      const self = this;
      const importedTeams = [];

      // Iterate through each row in the csv file imported to get edited lineup on each row
      file.forEach((row, rowIndex) => {
        // Do not iterate through the first row or rows after the maximum amount of entries allowed
        if (rowIndex == 0 || rowIndex > self.maxCSVEntries) {
          return;
        }

        let teamId = '';
        let contestId = '';
        const players = [];

        // Iterate through each column in the csv file row to get
        // team ID, contest ID, and player IDs in the lineup
        row.forEach((col, colIndex) => {
          if (colIndex == this.teamIdColumn) {
            // Get team ID from first column
            teamId = col;
          } else if (colIndex == this.contestIdColumn) {
            // Get the contest ID from the second column
            contestId = col;
          } else if (colIndex >= self.firstPositionColumn) {
            // Get Player ID from file using regex that retrieves only
            // numbers in the cell and then join them together
            const idStr = col.match(/\d+/g);
            const id = idStr != null ? idStr.join('') : null;

            // Create player object including ID and position based on column row
            if (id && colIndex < self.contest.exportPositions.length + self.firstPositionColumn) {
              const player = {
                id: id,
                position: self.contest.exportPositions[colIndex - self.firstPositionColumn],
              };

              // Add player to the list of players in the lineup
              players.push(player);
            }
          }
        });

        // If players are found in the row, create a lineup object to be validated
        if (players.length > 0) {
          const lineup = {
            teamId: teamId,
            contestId: contestId,
            row: rowIndex + 1,
            players: players,
          };

          importedTeams.push(lineup);
        }
      });

      // Validate the lineups found in the csv file imported
      ObSalaryCapApi.editLineups(this.contest.id, importedTeams, true)
          .then((response) => {
            this.allLineups = response;
            this.loading = false;
            let containsError = false;
            this.csvErrorMessage = '';

            // Add submit flag to each lineup validated and
            // check if there is an error in any of the lineups
            for (const lineup of this.allLineups) {
              lineup.submit = false;

              if (!lineup.success) {
                containsError = true;
              }
            }

            EventBus.$emit('CSV_EDIT_LINEUPS_IMPORTED', this.allLineups, containsError);
          }).catch((e) => {
            this.loading = false;
            if (e.response && e.response.data) {
              this.csvErrorMessage = e.response.data;
            } else {
              this.csvErrorMessage = 'Could not validate lineups';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .exportPlayerSlate {
    color: var(--obcolor-ob-blue);
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  input[type="file"] {
    display: none;
  }

  .customFileUpload {
    border: 2px dashed #404040;
    margin-left: 70px;
    padding: 20px 0px;
    width: 640px;
    height: 190px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
  }

  .fileIcon {
    margin-bottom: 25px;
    font-size: 35px;
    color: var(--obcolor-font-secondary);
  }

  .uploadIcon {
    margin-right: 10px;
  }

  .inlineCenter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .importInstructionContainer {
    margin-top: 65px;
    margin-left: 80px;
    font-size: 12px;
  }

  .importInstructionHeader {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 15px;
  }

  .importInstruction {
    margin-top: 10px;
    margin-left: 10px;
  }

  .fileUploadText {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .ob-btn {
    margin-top: 5px;
    padding: 8px 20px 8px 20px;
  }

  .csvErrorText {
    margin-top: 10px;
    margin-left: 10px;
    color: var(--obcolor-red);
  }

  .loadingIcon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .importImageContainer {
    margin-top: 50px;
    margin-right: 80px;
  }

  .importImage {
    width: 200px;
  }
</style>