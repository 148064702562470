<template>
  <div class="ob-box playerListBox">
    <div class="filterList">
      <div class="posFilters" v-if="filterPositions">
        <div class="filterBtn" v-for="(position, index) in filterPositions" :key="index"
          @click="selectFilter(position)"
          :class="[{'filterSelected': posFilters[position] === true}, {'filterSlotsFull': isFilterPositionFull(position)}]"
        >
          {{ position }}
        </div>
      </div>

      <StatusMultiDropdown v-model="statusFilter" style="margin-left: 10px; margin-top: -14px;" />

      <div class="salaryFilter" style="min-width: 230px; flex: 1;">
        <div class="salaryHeading">Salary: {{ dollarFormat(costRangeFilter[0]) }} - {{ dollarFormat(costRangeFilter[1]) }}</div>
        <div class="rangeSliderContainer">
          <div class="rangeSliderLabel">$0</div>
          <RangeSlider min="0" :max="maxCost" v-model="costRangeFilter"
            step="500"
          />
          <div class="rangeSliderLabel">{{ dollarFormat(maxCost) }}</div>
        </div>
        <div class="salaryLabel"></div>
      </div>
      <div style="flex: 1;"></div>
      <div class="searchInput">
        <div class="inputIcon"><i class="fa fa-search"></i></div>
        <input v-model="nameFilter" type="text" placeholder="Search for a Player">
        <div v-show="nameFilter != ''" class="inputIcon cancelSearch" @click="nameFilter = ''">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div v-if="!isGameSlateLineup" class="exportSlate" @click="exportPlayerSlate()"
        v-tooltip.bottom="{content: 'Download Player Slate', class:'ObTooltip'}"
      >
        <i class="fas fa-arrow-alt-to-bottom"></i>
      </div>
    </div>

    <!-- Players Table -->
    <div class="tableRadiusContainer">
      <div ref="playerListContainer" class="tableContainer ob-scroll" @scroll="onScroll">
        <table aria-label="Player List">
          <tr>
            <th style="width: 30px; padding: 0;"></th>
            <th style="text-align: left;">PLAYER</th>

            <template v-if="selectedView === 'stats'">
              <th class="statHeader" style="width: 110px;" @click="changeSort(null, 'gameTime')"
                :class="{ selectedCol: isSelected(null, 'gameTime') }"
              >
                <div style="display: flex;">
                  GAME <i class="fa fa-chevron-down sortIcon"></i>
                </div>
              </th>
              <th v-if="contest.sport == 'NFL'" class="statHeader" @click="changeSort(null, 'oprk')"
                :class="{ selectedCol: isSelected(null, 'oprk') }"
              >
                <GeneralTooltip textKey="SalaryCap_OPRK" pos="bottom">
                  <div class="headerTrigger" slot="trigger">
                    OPRK <i class="fa fa-chevron-down sortIcon"></i>
                  </div>
                </GeneralTooltip>
              </th>
              <th class="statHeader" @click="changeSort(null, 'sAvg')" :class="{ selectedCol: isSelected(null, 'sAvg') }">
                <GeneralTooltip textKey="SalaryCap_sAvg" pos="bottom">
                  <div class="headerTrigger" slot="trigger">
                    AVG <i class="fa fa-chevron-down sortIcon"></i>
                  </div>
                </GeneralTooltip>
              </th>
              <th class="statHeader" @click="changeSort('wStats', 'avg')" :class="{ selectedCol: isSelected('wStats', 'avg') }">
                <GeneralTooltip textKey="SalaryCap_pAvg" pos="bottom">
                  <div class="headerTrigger" slot="trigger">
                    PROJ <i class="fa fa-chevron-down sortIcon"></i>
                  </div>
                </GeneralTooltip>
              </th>
            </template>

            <template v-else>
              <th v-for="d in contest.days" :key="d" style="padding: 0;">
                <div>{{ [d, 'YYYY-MM-DD'] | moment("ddd") }}</div>
                <div>{{ [d, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
              </th>
              <th></th>
            </template>
            <th class="statHeader" style="width: 100px;" @click="changeSort(null, 'salary')"
              :class="{ selectedCol: isSelected(null, 'salary') }"
            >
              <div class="headerTrigger">
                SALARY <i class="fa fa-chevron-down sortIcon"></i>
              </div>
            </th>
            <th style="width: 24px;"></th>
          </tr>
          <template v-if="sortedPlayers.length">
            <SalaryCapPlayerRow2 class="playerRow" v-for="(player, index) in sortedPlayers" :key="index"
              :player="player" :picked="pickedPlayerIds ? pickedPlayerIds[player.id] : null"
              :statusMap="statusMap"
              :status="statusMap && statusMap[player.id] ? statusMap[player.id].status : null"
              :totSpent="totSpent" :picks="picks" :contest="contest"
              :selectedView="selectedView"
              :editingGameSlateLineup="editingGameSlateLineup"
              :isGameSlateLineup="isGameSlateLineup"
            />
          </template>
          <tr class="emptyRow" v-else>
            <td colspan="999">No players found for the selected filter</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ObSportDataApi from '@/api/ObSportDataApi';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import EventBus from '@/event-bus';
import RangeSlider from '@/components/RangeSlider';
import SalaryCapPlayerRow2 from '@/views/SalaryCapGame/SalaryCapPlayerRow2';
import GeneralTooltip from '@/components/tooltip/GeneralTooltip';
import StatusMultiDropdown from '@/views/SalaryCapGame/StatusMultiDropdown';

export default {

  components: {
    RangeSlider,
    SalaryCapPlayerRow2,
    GeneralTooltip,
    StatusMultiDropdown,
  },

  props: {
    playerList: {type: Array, default: null},
    picks: {type: Object, default: null},
    pickedPlayerIds: {type: Object, default: null},
    contest: {type: Object, default: null},
    statusMap: {type: Object, default: null},
    totSpent: {type: Number, default: null},
    selectedTeams: {type: Object, default: null},
    editingGameSlateLineup: {type: Boolean, default: false},
    isGameSlateLineup: {type: Boolean, default: false},
  },

  data() {
    return {
      sortedBy: {loc: null, stat: 'salary'},
      posFilters: {},
      nameFilter: '',
      playerListBaseLimit: 40,
      playerListLimit: 40,
      selectedView: 'stats',

      maxCost: 0,
      maxPG: 0,
      costRangeFilter: [0, 0],
      gameRangeFilter: [0, 4],
      statusFilter: 'inactive',

      teams: [],
      selectedTeam: '',
    };
  },

  created() {
    this.loadTeams();
    this.initPosFilters();
    this.calcMaxValues();

    if (this.playerList && this.maxCost) {
      this.costRangeFilter = [0, this.maxCost];
    }
    if (this.playerList && this.maxPG) {
      this.gameRangeFilter = [0, this.maxPG];
    }
    this.statusFilter = 'inactive';

    EventBus.$on('SALARY_CAP_SELECT_POS_FILTER', this.selectFilterOnly);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_SELECT_POS_FILTER', this.selectFilterOnly);
  },

  watch: {
    picks(newVal) {
      if (newVal != null) {
        this.initPosFilters();
      }
    },

    playerList(newVal) {
      this.calcMaxValues();
    },

    // Scroll table to the top, and reset list limit when changing filters
    nameFilter(to, from) {
      this.resetTableScroll();
    },

    selectedTeam(to, from) {
      this.resetTableScroll();
    },

    costRangeFilter(to, from) {
      this.resetTableScroll();
    },

    statusFilter(to, from) {
      this.resetTableScroll();
    },

    gameRangeFilter(to, from) {
      this.resetTableScroll();
    },

    fullFilterPositions(newVal, oldVal) {
      this.filterPositions.forEach((pos) => {
        if (!this.canPickPosition(pos, newVal) && this.canPickPosition(pos, oldVal)) {
          this.posFilters[pos] = false;
        }
      });
    },
  },

  computed: {
    sport() {
      if (!this.contest && !this.contest.sport) {
        return '';
      }

      return this.contest.sport;
    },

    showRosterPositionFilters() {
      return this.sport == 'NFL' && this.isPrimeTimeFlex;
    },

    showRosterPositionFiltersWithUtility() {
      return this.sport == 'NBA';
    },

    // General map in the roster to see if the given position is full are not
    fullPositions() {
      const tempFullPositions = {};
      this.roster.forEach((pos) => {
        const pickedAtPos = this.picks[pos.short] ? this.picks[pos.short].length : 0;
        tempFullPositions[pos.short] = pos.num <= pickedAtPos;
      });
      const fPositions = tempFullPositions;
      return fPositions;
    },

    // Map of which groups each player position is in (Flex, superflex, FC, G, etc)
    rosterPositionMap() {
      const rosterPositionMap = {};
      for (const posInfo of this.roster) {
        for (const pos of posInfo.pos) {
          if (!rosterPositionMap[pos]) {
            rosterPositionMap[pos] = [];
          }
          rosterPositionMap[pos].push(posInfo.short);
        }
      }

      return rosterPositionMap;
    },

    fullFilterPositions() {
      const fullFilterPositions = {};

      // For each position, check if its filter groups (flex, superflex, etc) is full, and if its own filter is full
      this.filterPositions.forEach((pos) => {
        let positionIsFull = true;
        // Check the groups that the position is in, and if they're full
        if (this.rosterPositionMap[pos]) {
          for (const rosterPosName of this.rosterPositionMap[pos]) {
            if (this.fullPositions[rosterPosName] === false) {
              positionIsFull = false;
              break;
            }
          }
        }

        // Check if the filter itself is full
        if (this.fullPositions[pos] === false) {
          positionIsFull = false;
        }
        fullFilterPositions[pos] = positionIsFull;
      });

      return fullFilterPositions;
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      return this.contest.draftRoster.roster;
    },

    filterPositions() {
      if (this.sport) {
        return this.contest.positionFilters;
      };

      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      const filterPos = [];
      for (const position of this.contest.draftRoster.roster) {
        filterPos.push(position.short);
      }

      return filterPos;
    },

    isPrimeTimeFlex() {
      return this.contest.rosterFormat === 'Prime Time Flex';
    },

    teamDropdownValues() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.id;
      });
      values.unshift(''); // All Teams
      return values;
    },

    teamDropdownLabels() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.team_city + ' ' + t.team_name;
      });
      values.unshift('All Teams');
      return values;
    },

    sortedPlayers() {
      const self = this;

      let noPosFilters = true;
      for (const posIndex in this.posFilters) {
        if (this.posFilters[posIndex] === true) {
          noPosFilters = false;
        }
      }

      if (!this.playerList) {
        return [];
      }

      let noSelectedSlates = true;
      for (const teamIndex in this.selectedTeams) {
        if (this.selectedTeams[teamIndex]) {
          noSelectedSlates = false;
        }
      }
      // Using es6 way to clone array ([...array])
      return [...this.playerList].filter((player) => {
        let nameMatch = false;
        let posMatch = false;
        let costMatch = false;
        let pgMatch = false;
        let statusMatch = false;
        let teamMatch = false;
        let slateMatch = false;

        if (!player.name) {
          return false;
        }
        if (self.nameFilter !== '') {
          const pname = player.name.toLowerCase();
          const nfilter = self.nameFilter.toLowerCase();
          const nFilters = nfilter.split(' ');

          nameMatch = true;
          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) == 0 || pname.indexOf(', ' + n) != -1) {
              // All filters must match
            } else {
              nameMatch = false;
              break;
            }
          }

          return nameMatch;
        }

        if (noSelectedSlates || (self.selectedTeams !== null && player.teamId && self.selectedTeams[player.teamId] === true)) {
          slateMatch = true;
        }

        // Team Filter
        if (self.selectedTeam == '' || self.selectedTeam == player.teamId) {
          teamMatch = true;
        }

        let allPositionsFull = true;
        for (const pos of self.roster) {
          if (!self.fullPositions[pos.short]) {
            allPositionsFull = false;
          }
        }

        if (self.isFilterActive(player.draftPos, player.position) || (noPosFilters && (self.canPickPosition(player.draftPos) === true || allPositionsFull))) {
          posMatch = true;
        }

        // If we are editing lineups, player salary becomes 0 when they can't be added or dropped
        let playerSalary = player.salary;
        if (this.isGameSlateLineup && this.editingGameSlateLineup && !player.canSwap) {
          playerSalary = 0;
        }
        // Filter salary range
        if (self.costRangeFilter[0] <= playerSalary && self.costRangeFilter[1] >= playerSalary) {
          costMatch = true;
        }

        const liveStatus = this.statusMap && this.statusMap[player.id] ? this.statusMap[player.id].status : player.status;

        if (self.statusFilter === 'active') {
          if (liveStatus === 'ACT') {
            statusMatch = true;
          }
        } else if (self.statusFilter === 'questionable') {
          if (liveStatus === 'ACT' ||
            liveStatus === 'AVA' ||
            liveStatus === 'TBA' ||
            liveStatus === 'BKUP') {
            statusMatch = true;
          }
        } else if (self.statusFilter === 'inactive') {
          statusMatch = true;
        }

        const pg = self.getStat(player, 'pg');
        if (self.gameRangeFilter[0] <= pg && self.gameRangeFilter[1] >= pg) {
          pgMatch = true;
        }

        return posMatch && costMatch && pgMatch && slateMatch && teamMatch && statusMatch;
      }).sort((p1, p2) => {
        // If we are editing lineups, players that can't be added or dropped get sorted to the bottom
        if (this.isGameSlateLineup && this.editingGameSlateLineup) {
          if (!p1.canSwap && p2.canSwap) {
            return 1;
          } else if (p1.canSwap && !p2.canSwap) {
            return -1;
          }
        }

        if (this.sortedBy.stat == 'gameTime' || this.sortedBy.stat == 'oprk') {
          // If a player has no games, check the other player and don't change list order otherwise
          if (!p1.games) {
            return p2.games != null ? 1 : 0;
          } else if (!p2.games) {
            return p1.games != null ? -1 : 0;
          }

          let compareStat = '';
          if (this.sortedBy.stat == 'gameTime') {
            compareStat = 'ts';
          } else if (this.sortedBy.stat == 'oprk') {
            compareStat = 'oprk';
          }

          // Find each player's next game if it exists. This assumes that the lowest game index is the earliest one (eg. index 0 is earlier than index 1)
          const p1UpcomingGame = p1.games.find((g) => g[compareStat] != null);
          const p2UpcomingGame = p2.games.find((g) => g[compareStat] != null);

          // Return appropriate sorting if we don't have timestamps to compare (prioritize the player with a non-null game/timestamp)
          if (p1UpcomingGame == null || p1UpcomingGame[compareStat] == null) {
            return p2UpcomingGame != null && p2UpcomingGame[compareStat] != null ? 1 : 0;
          } else if (p2UpcomingGame == null || p2UpcomingGame[compareStat] == null) {
            return p1UpcomingGame != null && p1UpcomingGame[compareStat] != null ? -1 : 0;
          }

          return p2UpcomingGame[compareStat] - p1UpcomingGame[compareStat];
        } else if (!this.sortedBy.loc) {
          return p2[this.sortedBy.stat] - p1[this.sortedBy.stat];
        } else if (this.sortedBy.loc == 'wStats') {
          return this.getStat(p2, this.sortedBy.stat) - this.getStat(p1, this.sortedBy.stat);
        }

        return 0;
      }).slice(0, this.playerListLimit);
    },
  },

  methods: {

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      // Show more entries once we scroll to the bottom of the table
      // Extra pixel buffer to reduce stuttering
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        this.playerListLimit += this.playerListBaseLimit;
      }
    },

    // When changing filters or sort, scroll back to top and reset the limit
    resetTableScroll() {
      this.$nextTick(() => {
        this.$refs.playerListContainer.scrollTop = 0;
      });
      this.playerListLimit = this.playerListBaseLimit;
    },

    loadTeams() {
      this.teams = [];
      ObSportDataApi.getTeams(this.contest.sport)
          .then((response) => {
            this.teams = response.sort((a, b) => {
              return a.team_city.localeCompare(b.team_city);
            });
          });
    },

    calcMaxValues() {
      let highestCost = 0;
      let highestPG = 0;

      for (const player of this.playerList) {
        if (player.salary && player.salary > highestCost) {
          highestCost = player.salary;
        }
        const pg = this.getStat(player, 'pg');
        if (pg > highestPG) {
          highestPG = pg;
        }
      }

      // Round up to nearest 1000;
      this.maxCost = Math.ceil(highestCost/1000) * 1000;
      this.maxPG = highestPG;

      // If max Cost or GR changes, and selected value is higher, adjust down
      if (this.costRangeFilter[1] > this.maxCost) {
        this.$set(this.costRangeFilter, 1, this.maxCost);
      }
      if (this.gameRangeFilter[1] > this.maxGR) {
        this.$set(this.gameRangeFilter, 1, this.maxGR);
      }
    },

    initPosFilters() {
      if (!this.picks || !Object.keys(this.picks)) {
        return;
      }

      const tempFilters = {};
      this.filterPositions.forEach((pos) => {
        if (!tempFilters[pos]) {
          tempFilters[pos] = false;
        }
      });
      this.posFilters = tempFilters;
    },

    selectFilter(posName) {
      this.resetTableScroll();
      if (!this.posFilters[posName]) {
        this.$set(this.posFilters, posName, true);
      } else {
        this.$set(this.posFilters, posName, false);
      }
    },

    selectFilterOnly(posName) {
      for (const pos of this.filterPositions) {
        if (this.posFilters[pos]) {
          this.$set(this.posFilters, pos, false);
        }
      }

      if (this.showRosterPositionFilters || this.showRosterPositionFiltersWithUtility) {
        const rosterPositions = this.getRosterPositions(posName);
        for (const rosterPos of rosterPositions) {
          this.selectFilter(rosterPos);
        }
        return;
      }

      this.selectFilter(posName);
    },

    canPickPosition(draftPos, fullPositions = this.fullPositions) {
      if (this.contest.isFlexContest && this.canPickFlexPosition(draftPos, fullPositions)) {
        return true;
      }

      return fullPositions[draftPos] === false;
    },

    canPickFlexPosition(draftPos, fullPositions) {
      const isGuard = this.$SportInfo.draftPosIsGuard(draftPos, this.contest.sport);
      const isForward = this.$SportInfo.draftPosIsForward(draftPos, this.contest.sport);
      const isUtility = this.$SportInfo.draftPosIsUtility(draftPos, this.contest.sport);
      const isSuperFlex = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport);
      const isFlex = this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport);

      const guardOpen = isGuard && fullPositions[this.$SportInfo.guard()] === false;
      const forwardOpen = isForward && fullPositions[this.$SportInfo.forward()] === false;
      const utilityOpen = isUtility && (fullPositions[this.$SportInfo.utility()] === false || fullPositions[this.$SportInfo.utilityMultiplier()] === false);
      const superFlexOpen = isSuperFlex && (fullPositions[this.$SportInfo.superFlex()] === false || fullPositions[this.$SportInfo.superFlexMultiplier()] === false);
      const flexOpen = isFlex && fullPositions[this.$SportInfo.flex()] === false;

      return guardOpen || forwardOpen || utilityOpen || superFlexOpen || flexOpen;
    },

    isFilterActive(draftPos, rosterPos) {
      if (this.showRosterPositionFilters) {
        return this.posFilters[rosterPos];
      }

      if (this.contest.isFlexContest && this.isFlexFilterActive(draftPos)) {
        return true;
      }

      return this.posFilters[draftPos];
    },

    isFlexFilterActive(draftPos) {
      const isGuard = this.$SportInfo.draftPosIsGuard(draftPos, this.sport);
      const isForward = this.$SportInfo.draftPosIsForward(draftPos, this.sport);
      const isUtility = this.$SportInfo.draftPosIsUtility(draftPos, this.sport);

      const isFlex = this.$SportInfo.draftPosIsFlex(draftPos, this.sport);
      const isSuperFlex = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.sport);

      const isGuardActive = isGuard && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.guard()]);
      const isForwardActive = isForward && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.forward()]);
      const isUtilityActive = isUtility && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.utility()] || this.posFilters[this.$SportInfo.utilityMultiplier()]);
      const isFlexActive = isFlex && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.flex()]);
      const isSuperFlexActive = isSuperFlex && (this.posFilters[draftPos] || this.posFilters[this.$SportInfo.superFlex()] || this.posFilters[this.$SportInfo.superFlexMultiplier()])

      return isGuardActive || isForwardActive || isUtilityActive || isFlexActive || isSuperFlexActive;
    },

    isFilterPositionFull(position) {
      if (this.showRosterPositionFilters) {
        return this.fullPositions[this.$SportInfo.superFlex()] && this.fullPositions[this.$SportInfo.superFlexMultiplier()];
      } else if (this.showRosterPositionFiltersWithUtility && this.isPrimeTimeFlex) {
        return this.fullPositions[this.$SportInfo.utility()] && this.fullPositions[this.$SportInfo.utilityMultiplier()];
      }

      for (const posInfo of this.roster) {
        if (posInfo.pos.includes(position) && !this.fullPositions[posInfo.short]) {
          return false;
        }
      }

      return this.fullPositions[position];
    },

    getRosterPositions(draftPos) {
      // Exclude utility position from roster positions
      if (draftPos === this.$SportInfo.utility() || draftPos === this.$SportInfo.utilityMultiplier()) {
        return [this.$SportInfo.utility()];
      }

      for (const posInfo of this.roster) {
        if (posInfo.short == draftPos) {
          return posInfo.pos;
        }
      }
      return [];
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    changeSort(loc, stat) {
      this.resetTableScroll();
      this.sortedBy.loc = loc;
      this.sortedBy.stat = stat;
    },

    isSelected(loc, stat) {
      return this.sortedBy.loc == loc && this.sortedBy.stat == stat;
    },

    openPlayerCard(player) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', player.id, this.contest.sport, this.contest.id, true);
    },

    exportPlayerSlate() {
      ObSalaryCapApi.getPlayerSlate(this.contest.id, this.contest.contestName)
          .catch(() => {
            console.log('Error downloading the contest player slate');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .playerListBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    padding: 0;
  }

  .endSection {
    border-left: 2px solid var(--obcolor-background-1)
  }

  .salaryFilter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .salaryHeading {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .salaryLabel {
    font-size: 12px;
    margin-top: 3px;
  }

  .rangeSliderContainer {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .rangeSliderLabel {
    font-size: 12px;
    width: 50px;
  }

  .rangeSliderLabel:first-child {
    margin-right: 10px;
    text-align: right;
  }

  .rangeSliderLabel:last-child {
    margin-left: 10px;
  }

  .searchInput {
    display: flex;
    width: 145px;
    height: 27px;
    background: var(--obcolor-background-2);
    align-items: center;
    padding: 3px 10px;
    border-radius: 4px;

    input {
      box-sizing: border-box;
      flex: 1;
      min-width: 0;
      height: 100%;
      padding: 0 5px;
      margin-left: 3px;
      background: var(--obcolor-background-2);
    }

    .inputIcon {
      margin-top: 2px;
      font-size: 13px;
      color: var(--obcolor-font-secondary);
    }

    .cancelSearch {
      color: red;
      font-size: 12px;
      cursor: pointer;
      padding: 5px;
      margin-right: -4px;
    }

    .cancelSearch:hover {
      color: #a80f0f;
    }
  }

  .exportSlate {
    display: flex;
    width: 20px;
    height: 27px;
    background: var(--obcolor-background-2);
    align-items: center;
    padding: 3px 7px 3px 15px;
    margin-left: 8px;
    cursor: pointer;
    color: var(--obcolor-font-secondary);
    border-radius: 4px;
  }

  .exportSlate:hover {
    background: var(--obcolor-background-1);
  }

  .filterList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 3px 10px;

    ::v-deep {
      .ddBox {
        border-radius: 4px;
      }
    }
  }

  .posFilters {
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  .filterBtn {
    color: var(--obcolor-font-primary);
    background-color: var(--obcolor-background-1);
    border-right: 1px solid var(--obcolor-background-4);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 29px;
    height: 39px;
    text-align: center;
    padding: 0px 5px 0px 5px;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: none;
    }
  }

  .filterBtn.filterSelected {
    color: white;
    background: var(--obcolor-ob-blue);
  }
  .filterBtn:not(.filterSelected):not(.filterSlotsFull) {
      background-color: var(--obcolor-background-1);
  }

  .filterBtn:not(.filterSelected).filterSlotsFull {
    color: var(--obcolor-font-secondary);
  }

  .filterBtn:hover {
    background: var(--obcolor-ob-blue-tinted) !important;
    color: white !important;
  }

  .tableRadiusContainer {
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    flex: 1;
    min-height: 0;
    display: flex;
  }

  .tableContainer {
    overflow: auto;
    margin-top: 5px;
    transition: height ease-out 0.3s;
    border-radius: 0 0 5px 5px;
    width: 100%;
  }

  table {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  th {
    color: var(--obcolor-font-primary);
    background: var(--obcolor-background-2);
    font-size: 14px;
    height: 40px;
    position: sticky;
    top: 0;
    white-space: nowrap;
    z-index: 60;
  }

  .statHeader {
    width: 88px;
    height: 37px;
    padding: 0;
    box-sizing: border-box;
  }

  .centered {
    text-align: center;
    background: red;
  }

  .statHeader:not(.noHover) {
    cursor: pointer;
  }

  .statHeader:not(.noHover):hover {
    background: var(--obcolor-background-1);
  }

  .statHeader > div {
    width: 100%;
  }

  .statHeader .headerTrigger {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sortIcon {
    display: none;
    margin-left: 5px;
    margin-right: -8px;
  }
  .selectedCol .sortIcon {
    display: block;
  }

  .playerRow:hover {
    background: var(--obcolor-background-4);
  }
  .playerStatus {
    margin-top: -2px;
    margin-right: 8px;
  }

  .pickedPlayer {
    color: var(--obcolor-text-faded) !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 20px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .teamSelector {
    width: 120px;
    height: 31px;
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
  }

  .selectViewContainer {
    display: flex;
    margin-left: 30px;
    margin-right: 25px;
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    text-transform: uppercase;
    cursor: pointer;
    div:first-child {
      padding-right: 8px;
      border-right: 1px solid var(--obcolor-font-secondary);
    }
    div:hover {
      color: var(--obcolor-ob-blue);
    }
  }

  .emptyRow td {
    padding: 10px;
    color: var(--obcolor-font-secondary);
  }

</style>