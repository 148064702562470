<template>
  <div>
    <div v-if="!notificationIsEnabled" class="container">
      <div class="arrowContainer">
        <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
      </div>
      <h1>Enable Notifications</h1>
      <p>Turn on notifications to receive the latest news on promotions, player updates and contest changes.</p>

      <ErrorPanel :message="errorMessage" />
      <button class="ob-btn" v-if="!errorMessage" @click="setNotification()">Enable Notifications</button>
      <button class="ob-btn" v-else @click="goNext()">Continue</button>
      <img :src="require('@/assets/onboarding/notification.png')" alt="notification bell">
    </div>

    <div v-else class="container">
      <div class="arrowContainer">
        <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
      </div>
      <h1>Enable Notifications</h1>
      <p>Turn on notifications to receive the latest news on promotions, player updates and contest changes.</p>
      <i class="fas fa-check-circle"></i>
      <p class="enabledText">Notifications are enabled!</p>
      <button class="ob-btn" @click="goNext()">Continue</button>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  data() {
    return {
      checkedNews: false,
      processing: false,
      errorMessage: null,
      notificationIsEnabled: false,
    };
  },

  created() {
    this.isNotificationEnabled();
  },

  components: {
    ErrorPanel,
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },
    goNext() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },
    isNotificationEnabled() {
      if (Notification.permission === 'granted') {
        this.notificationIsEnabled = true;
      } else {
        this.notificationIsEnabled = false;
      }
    },
    setNotification() {
      this.processing = true;
      // Request permission to send notifications
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          EventBus.$emit('ON_BOARDING_NEXT');
        } else if (permission === 'denied') {
          this.errorMessage = 'Notifications are blocked. You may enable notifications via your browser settings for an enhanced experience.';
        }
      }).catch((_error) => {
        EventBus.$emit('ON_BOARDING_NEXT');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  button {
    margin-top: 50px;
    height: 60px;
  }
  img {
    padding-top: 70px;
    width: 345px;
    margin: 0 auto;
  }
  ::v-deep {
    .errorPanel {
      color: var(--obcolor-ll-countdown-yellow);
      background: rgba(248, 206, 10, 0.1);
      margin: 15px 0 -10px 0;
    }
  }
  .fa-check-circle {
    color: var(--obcolor-green);
    font-size: 60px;
    margin: 60px auto;
  }
  .enabledText {
    margin: 0 auto;
    font-weight: 700;
  }
}
</style>
