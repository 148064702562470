<template>
  <div class="ob-main-nav">
    <div class="ob-nav">
      <div class="logoContainer">
        <template v-if="!sponsor.imageUrl">
          <img v-if="!isDarkMode" class="ob-logo" src="../assets/logo_nofantasy_black.png"
            alt="OwnersBox Logo"
          >
          <img v-else class="ob-logo" src="../assets/logo_nofantasy_white.png"
            alt="OwnersBox Logo"
          >
        </template>
        <template v-else>
          <img v-if="!isDarkMode" class="ob-logo-promo" src="../assets/logo_alt_black.png"
            alt="OwnersBox Logo"
          >
          <img v-else class="ob-logo-promo" src="../assets/logo_alt_white.png"
            alt="OwnersBox Logo"
          >
          <div class="sponsorText" v-if="sponsor.imageUrl">
            <div class="sponsoredBy">Sponsored by</div>
            <SponsorImage :sponsor="sponsor" height="28px" maxWidth="50px" />
          </div>
        </template>
      </div>

      <div class="navMenu">
        <router-link v-if="isLoggedIn" to="/player-picks/lobby">
          <div class="ob-nav-btn" :class="subMenuPage == 'playerpicks' ? 'active' : ''">Player Picks</div>
        </router-link>
        <router-link v-show="isLoggedIn" to="/lobby">
          <div class="ob-nav-btn" :class="subMenuPage == 'salarycap' ? 'active' : ''">Salary Cap</div>
        </router-link>
        <router-link v-show="isLoggedIn" to="/lightning-lineups/builder">
          <div class="ob-nav-btn" :class="subMenuPage == 'lightninglineups' ? 'active' : ''">Lightning Lineups</div>
        </router-link>
        <router-link v-show="isLoggedIn" to="/scores">
          <div class="ob-nav-btn">Scores</div>
        </router-link>
        <router-link v-show="isLoggedIn" to="/players">
          <div class="ob-nav-btn">Players</div>
        </router-link>
        <router-link v-if="battlePass && !firstBattlePassLoad" v-show="isLoggedIn" to="/battlepass">
          <div class="ob-nav-btn">Battle Pass</div>
        </router-link>
      </div>

      <div class="userInfo">
        <!-- Currency and OwnersBucks -->
        <div v-if="isLoggedIn" class="financeSection">
          <TicketTooltip>
            <div slot="trigger" class="financeField">
              <div class="financeVal">
                <img :src="require('@/assets/icons/ticket.png')" class="ticketsIcon" alt="Tickets Icon">
                <div class="ticketText" data-testid="ticket-balance">{{ numTickets || "0" }}</div>
              </div>
              <div class="financeLabel">Tickets</div>
            </div>
          </TicketTooltip>

          <router-link to="/ownersbucks" class="financeField financeLink">
            <div class="financeVal">
              <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
              <div class="financeText blueText" data-testid="ownersbucks-balance">{{ ownersbucks || "-" }}</div>
            </div>
            <div class="financeLabel">OwnersBucks</div>
          </router-link>
          <router-link to="/transactions" class="financeField financeLink">
            <div class="financeVal">
              <div class="financeText greenText" data-testid="cash-balance">{{ accountBalance || "-" }}</div>
            </div>
            <div class="financeLabel">Balance</div>
          </router-link>
        </div>
      </div>

      <div class="depositReferContainer" v-if="isLoggedIn && userId != null">
        <div class="depositReferBtn ob-btn-green">
          <router-link to="/deposit">
            DEPOSIT
          </router-link>
        </div>
        <div class="depositReferBtn ob-btn" v-if="!isDepositVerified">
          <router-link to="/verifyaccount">
            VERIFY ACCOUNT
          </router-link>
        </div>
      </div>

      <div class="iconContainer">
        <!-- Alerts -->
        <div v-if="isLoggedIn" class="darkModeBtn" style="margin-left: 15px; position: relative;"
          @click="openNotificationsModal()"
          v-tooltip.bottom="{content: 'Notifications', class:'ObTooltip'}" key="notifications"
        >
          <i class="fas fa-bell"></i>
          <div v-show="notificationCount > 0" class="notificationMarker">{{ notificationCount }}</div>
        </div>
      </div>

      <!-- Account -->
      <div v-if="isLoggedIn && userId != null" class="profileContainer">
        <i class="far fa-bars"></i>
        <div v-if="refreshDropdown == false" @click="closeDropdown" class="settingsDropdown">
          <router-link :to="'/profile/' + userId">My Profile</router-link>
          <router-link v-if="!isDepositVerified" to="/verifyaccount">Verify Account</router-link>
          <div v-else style="color: #2EB85C; cursor: default;">Account Verified</div>
          <router-link to="/deposit">Deposit Funds</router-link>
          <router-link to="/withdraw">Withdraw Funds</router-link>
          <router-link to="/ownersbucks">OwnersBucks</router-link>
          <router-link v-if="showConnections" to="/integrations">Connections</router-link>
          <router-link to="/referfriend">Refer a Friend</router-link>
          <router-link to="/transactions">Transaction History</router-link>
          <router-link to="/bonuses">Bonuses</router-link>
          <router-link to="/loginhistory">Login History</router-link>
          <router-link to="/responsibleplay">Responsible Play</router-link>
          <a href="https://support.ownersbox.com/hc/en-us" target="_blank" rel="noopener">Help & Support</a>
          <div @click="openBugReportModal()">Report Bug/Feedback</div>
          <div @click.stop="toggleLightDark()">Toggle Theme</div>
          <div @click="logout()">Logout</div>
        </div>
      </div>
    </div>
    <div class="subMenuContainer" v-show="isLoggedIn && subMenu">
      <div class="navMenusContainer">
        <div class="subMenu">
          <div v-for="(item, index) in subMenu" :key="index">
            <router-link :to="item.link">
              <div class="ob-nav-btn">{{ item.name }}</div>
            </router-link>
          </div>
        </div>
        <div v-if="optionalMenu" class="optionalMenu">
          <div v-for="(item, index) in optionalMenu" :key="index">
            <div v-if="item.action" @click="menuHandler(item.action)" class="ob-nav-btn">{{ item.name }} </div>

            <router-link v-if="item.link" :to="item.link">
              <div class="ob-nav-btn">{{ item.name }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObBattlePassApi from '@/api/ObBattlePassApi';
import EventBus from '@/event-bus';
import TicketTooltip from '@/components/tooltip/tickets/TicketTooltip';
import ObSponsorApi from '@/api/ObSponsorApi';
import {mapState} from 'vuex';
import SponsorImage from '@/components/sponsors/SponsorImage';
import subMenuModel from '@/utils/subMenuModel.json';
import optionalMenuModel from '@/utils/optionalMenuModel.json';

export default {
  name: 'MainNav',

  components: {
    TicketTooltip,
    SponsorImage,
  },

  data: function() {
    return {
      userId: null,
      userName: '',
      userBalance: '',
      userBalanceValue: null,
      ownersbucks: '',
      rankNum: 1,
      rankColor: 'blue',
      isDarkMode: false,
      refreshDropdown: false,
      isDepositVerified: null,
      appLoadEventRequired: true,
      isFirstLoad: true,
      battlePassInterval: null,
      battlePassCookieKey: 'battlepass_1',
      subMenuModel: subMenuModel,
      optionalMenuModel: optionalMenuModel,
      validRouteNames: {
        'dashboard': true,
        'profile': true,
        'lobby': true,
        'contest-matchup': true,
        'draft': true,
        'grouplist': true,
      },

      sponsor: {},
      showConnections: null,
    };
  },

  computed: {
    ...mapState([
      'isLoggedIn',
      'userState',
      'theme',
      'notificationCount',
      'accountBalance',
      'numTickets',
      'battlePass',
      'loadingBattlePass',
      'firstBattlePassLoad',
    ]),

    subMenu() {
      // We only have a subMenu if the route.meta.subMenu has a value set
      // and it matches one of the subMenu's defined in our model
      return this.subMenuModel[this.$route.meta.subMenu];
    },

    optionalMenu() {
      return this.optionalMenuModel[this.$route.meta.subMenu];
    },

    subMenuPage() {
      return this.$route.meta.subMenu;
    },
  },

  mounted() {
    this.isDarkMode = this.theme === 'dark';
    if (this.isLoggedIn) {
      this.loadAccountInfo();
    }
    this.getSportNumContests();
    EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
    this.loadSponsor();

    EventBus.$on('LOGIN', this.loadAccountInfoWithDisclaimer);
    EventBus.$on('UPDATE_ACCOUNT_INFO', this.loadAccountInfo);
    EventBus.$on('LOAD_BATTLE_PASS', this.loadBattlePass);
    EventBus.$on('FETCH_SPORT_NUM_CONTESTS', this.getSportNumContests);
  },

  destroyed() {
    clearInterval(this.battlePassInterval);
    EventBus.$off('LOGIN', this.loadAccountInfoWithDisclaimer);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('UPDATE_ACCOUNT_INFO', this.loadAccountInfo);
    EventBus.$off('LOAD_BATTLE_PASS', this.loadBattlePass);
    EventBus.$off('FETCH_SPORT_NUM_CONTESTS', this.getSportNumContests);
  },

  methods: {
    toggleLightDark() {
      const body = document.getElementsByTagName('body')[0];
      const theme = body.getAttribute('data-theme');
      if (theme == null) {
        body.setAttribute('data-theme', 'dark');
        this.$cookie.set('ob-dark-mode', 'true', 3650);
      } else {
        body.removeAttribute('data-theme');
        this.$cookie.set('ob-dark-mode', 'false', 3650);
      }
      this.isDarkMode = this.$cookie.get('ob-dark-mode') !== 'false';
      this.$store.commit('setTheme', this.isDarkMode ? 'dark' : 'light');
    },

    closeDropdown() {
      // Remove the dropdown for 1 tick so that it is no longer hovered
      this.refreshDropdown = true;
      setTimeout(() => {
        this.refreshDropdown = false;
      }, 1);
    },

    loadAccountInfo(isLogin) {
      EventBus.$emit('LOAD_USER_TICKETS');
      this.$store.commit('setContestCheckState', {loaded: false, ts: null});
      ObAccountApi.loadUserData()
          .then((response) => {
            this.$store.commit('setContestCheckState', {loaded: true, ts: response.contestCheckTime});
            this.userId = response.id;
            this.userName = response.username;
            this.userBalance = response.balance;
            this.userBalanceValue = response.balanceValue;
            this.ownersbucks = response.ownersbucks;
            this.rankColor = response.rankColor;
            this.rankNum = response.rankNum;
            this.isDepositVerified = response.isDepositVerified;
            this.showConnections = response.showConnections;
            this.$store.commit('setShowConnections', response.showConnections);
            this.$store.commit('setKrogerId', response.krogerId);
            this.$store.commit('setIsKrogerAffiliate', response.isKrogerAffiliate);
            this.$store.commit('setAccountBalance', this.userBalance);
            this.$store.commit('setBalanceValue', response.balanceValue);
            this.$store.commit('setOwnersBucks', this.ownersbucks);
            this.$store.commit('setOwnersBucksValue', response.ownersbucksValue);
            this.$store.commit('setLoggedIn', response.id);
            this.$store.commit('setUserName', response.username);
            this.$store.commit('setUserEmail', response.email);
            this.$store.commit('setRankNum', response.rankNum);
            this.$store.commit('setShowBetaDisclaimer', response.showBetaDisclaimer);
            this.$store.commit('setNoContestRestrictions', response.noContestRestrictions);
            this.$store.commit('setIsDepositVerified', response.isDepositVerified);
            this.$store.commit('setHasDeposited', response.hasDeposited);
            this.$store.commit('setHasEnteredContests', response.hasEnteredContests);
            this.$store.commit('setIsFantasyProsUser', response.isFantasyProsUser);
            this.$store.commit('setLeaguesCountALL', response.leaguesCountALL);
            this.$store.commit('setLeaguesCountScALL', response.leaguesCountScALL);
            this.setLeaguesCountBySport(response);
            this.$store.commit('setDraftPriority', response.draftPriority);
            this.$store.commit('setAccountInfoLoaded', true);
            this.$store.commit('setAccountCountry', response.country);
            this.$store.commit('setRecipientId', response.recipientId);
            this.$store.commit('setTeamNames', response.teams);
            this.$store.commit('setFirstName', response.firstName);
            this.$store.commit('setLastName', response.lastName);
            this.$store.commit('setEmail', response.email);

            if (this.appLoadEventRequired) {
              this.appLoadEventRequired = false;
              this.$Analytics.trackAppLoad(response);
            }

            if (response.contestAlertAndLimit) {
              this.$store.commit('setContestLimits', response.contestAlertAndLimit);
            }

            if (isLogin && !this.$DeviceInfo.isMobile()) {
              EventBus.$emit('OPEN_BETA_DISCLAIMER_MODAL', isLogin || false);
            }

            if (this.isFirstLoad) {
              EventBus.$emit('ACCOUNT_INFO_LOADED');
              this.$SocketController.subscribeToRoom('BATTLE_PASS_UPDATE_' + response.id);
              this.$SocketController.subscribeToRoom('OWNERS_BUCKS_UPDATE_' + response.id);
              this.loadBattlePass();
            }

            this.isFirstLoad = false;
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    loadBattlePass() {
    // Setting loading in the store does not happen instantly so this prevents simultaneous loads
      if (this.loadingBattlePass && !this.firstBattlePassLoad) {
        return;
      }

      this.$store.commit('setLoadingBattlePass', true);
      ObBattlePassApi.getBattlePass()
          .then((response) => {
            this.$store.commit('setFirstBattlePassLoad', false);
            this.$store.commit('setLoadingBattlePass', false);
            this.updateBattlePass(response);
          }).catch((_e) => {
            this.$store.commit('setLoadingBattlePass', false);
          });
    },

    updateBattlePass(battlePass) {
      const oldBattlePass = this.battlePass;
      this.checkForNotifications(oldBattlePass, battlePass);
      this.$store.commit('setBattlePass', battlePass);
      this.setBattlePassCookie(this.battlePass);
    },

    setBattlePassCookie(battlePass) {
      localStorage.setItem(this.battlePassCookieKey, JSON.stringify(battlePass));
    },

    getBattlePassFromCookie() {
      const battlePassCookie = localStorage.getItem(this.battlePassCookieKey);
      return battlePassCookie ? JSON.parse(battlePassCookie) : null;
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'BATTLE_PASS_UPDATE') {
        this.updateBattlePass(data.json);
      }

      if (data.type === 'OWNERS_BUCKS_UPDATE') {
        const updateJSON = data.json;
        if (updateJSON) {
          this.ownersbucks = updateJSON.ownersbucks;
          this.$store.commit('setOwnersBucks', updateJSON.ownersbucks);
          this.$store.commit('setOwnersBucksValue', updateJSON.ownersbucksValue);
        }
      }
    },

    // Not used in code, shows an example of the reward animation for each reward in the battle pass
    // Put this in the getBattlePass function to simulate finishing a challenge
    testAllNotifications(newBattlePass) {
      for (const category of newBattlePass.challengeCategories) {
        if (!category.challenges) {
          continue;
        }
        for (const newChallenge of category.challenges) {
          const newerChallenge = {...newChallenge};
          newerChallenge.progressPercent = 100;
          this.emitBattlePassNotificationIfExists(newChallenge, newerChallenge);
        }
      }
    },

    checkForNotifications(oldBattlePass, newBattlePass) {
      if (!oldBattlePass && newBattlePass) {
        const cookiePass = this.getBattlePassFromCookie();
        if (cookiePass && cookiePass.id == newBattlePass.id) {
          oldBattlePass = cookiePass;
        }
      }

      if (!newBattlePass?.challengeCategories || !oldBattlePass?.challengeCategories) {
        return;
      }

      const newChallengeObject = this.createChallengeObject(newBattlePass);

      // Compare old challenges with new ones to see if they have a different progress %
      for (const category of oldBattlePass.challengeCategories) {
        if (!category.challenges) {
          continue;
        }
        for (const oldChallenge of category.challenges) {
          if (!newChallengeObject[oldChallenge.id]) {
            continue;
          }
          this.emitBattlePassNotificationIfExists(oldChallenge, newChallengeObject[oldChallenge.id]);
        }
      }
    },

    createChallengeObject(battlePass) {
      const challengeObject = {};
      for (const category of battlePass.challengeCategories) {
        if (!category.challenges) {
          continue;
        }
        for (const challenge of category.challenges) {
          this.$set(challengeObject, challenge.id, challenge);
        }
      }
      return challengeObject;
    },

    emitBattlePassNotificationIfExists(oldChallenge, newChallenge) {
      if (!oldChallenge || !newChallenge) {
        return;
      }
      if (oldChallenge.progressPercent == newChallenge.progressPercent) {
        return;
      }

      const notificationObject = {
        old: oldChallenge,
        new: newChallenge,
      };

      EventBus.$emit('BATTLE_PASS_CHALLENGE_NOTIFICATION', notificationObject);
    },

    loadSponsor() {
      ObSponsorApi.getSponsor('navbar-header')
          .then((response) => {
            this.sponsor = response;
          }).catch((_e) => {
            // Suppress console error
          });
    },

    setLeaguesCountBySport(response) {
      const leaguesCountBySport = {
        NFL: response.leaguesCountAllNFL,
        MLB: response.leaguesCountAllMLB,
        NBA: response.leaguesCountAllNBA,
        NHL: response.leaguesCountAllNHL,
      };

      this.$store.commit('setLeaguesCountBySport', leaguesCountBySport);
    },

    getSportNumContests() {
      this.$store.commit('setSportNumContests', {});
      ObLeagueApi.getSportNumContests()
          .then((response) => {
            this.$store.commit('setSportNumContests', response);
          })
          .catch(() => {
            // No action
          });
    },

    loadAccountInfoWithDisclaimer() {
      this.loadAccountInfo(true);
    },

    logout() {
      ObAccountApi.logoutUser()
          .then((data) => {
            this.updateBattlePass(null);
            this.$cookie.delete('obauth');
            this.$store.commit('setLoggedOut');

            // Reset chat data when logged out
            this.$cookie.delete('expanded-open-convo-map');
            this.$cookie.delete('showEliminatedLeagues');

            this.$SocketController.unsubscribeFromRoom('BATTLE_PASS_UPDATE_' + this.userId);
            localStorage.removeItem(this.battlePassCookieKey);

            // Logged out users don't need socket updates
            this.$SocketController.disconnect();
            EventBus.$emit('LOGOUT');

            if (this.$router.currentRoute.path != '/login') {
              this.$router.push('/login');
            }

            // reset the appLoadEventRequired value, so appLoad event is fired on next login
            this.appLoadEventRequired = true;
            this.isFirstLoad = true;
          });
    },

    toggleMenu() {
      EventBus.$emit('MOBILE_MENU_TOGGLE');
    },

    openBugReportModal() {
      EventBus.$emit('OPEN_BUG_REPORT_MODAL');
    },

    openNotificationsModal() {
      EventBus.$emit('SYSTEM_NOTIFICATIONS_OPEN');
    },

    menuHandler(action) {
      EventBus.$emit(action);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.fa-bars {
  font-size: 26px;
  margin-left: 5px;
  cursor: pointer;
  color: #ccc;
  &:hover {
    color: #aaa;
  }
}

.iconContainer {
  display: flex;
}

.darkModeBtn {
  font-size: 26px;
  color: #ccc;
  cursor: pointer;
}

.darkModeBtn:hover {
  color: #aaa;
}

.ob-main-nav {
  height: $ob-nav-height;
  background: var(--obcolor-page-header);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 89;
}

.ob-nav {
  display: flex;
  align-items: center;
  max-width: $ob-maxwidth;
  height: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.logoContainer {
  padding: 5px 0;
  margin-top: 4px;
  margin-right: 15px;
}

.sponsorText {
  margin-top: -5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding-left: 43px;

  .sponsoredBy {
    font-size: 16px;
    margin-top: -4px;
  }
}

.ob-logo {
  width: 216px;
  border-radius: 6px;
}

.ob-logo-promo {
  height: 28px;
}

.ob-nav-btn {
  border-radius: 7px;
  padding: 6px 12px;
  display: inline-block;
  cursor: pointer;
}

.howToPlay {
  color: var(--obcolor-ob-blue);
}

.howToPlay:hover {
  color: var(--obcolor-ob-blue-tinted) !important;
}

.navMenu {
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  flex: 1;
  min-width: 235px;
}

.navMenu a {
  text-decoration: none;
  color: var(--obcolor-font-secondary);
  &.router-link-active {
    color: var(--obcolor-font-primary);
    font-weight: bold;
    box-sizing: border-box;
  }
}

.subMenuContainer a {
  text-decoration: none;
  color: var(--obcolor-font-secondary);
  &.router-link-active {
    color: var(--obcolor-font-primary);
    font-weight: bold;
    border-bottom: var(--obcolor-ob-blue) 2px solid;
    margin-bottom: -2px;
  }
}

.active {
  color: var(--obcolor-font-primary);
  font-weight: bold;
}

.ob-nav-btn:hover {
  color: var(--obcolor-text);
}

.userInfo {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: right;
  margin-top: 4px;
}

.financeSection {
  display: flex;
  margin-right: 10px;
}

.financeField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  padding: 0px 9px;
  border-right: 1px solid var(--obcolor-background-3);
}

.financeVal {
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.financeLabel {
  display: flex;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.financeLink {
  cursor: pointer;
}

.financeLink:hover {
  .financeText {
    text-decoration: underline;
  }

  img {
    filter: brightness(1.2);
  }
}

.currencyBox {
  text-align: center;
  background: var(--obcolor-background-2);
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: bold;
  min-width: 70px;
}

.currencyHeader {
  margin-bottom: 2px;
  color: var(--obcolor-font-secondary);
}

.ownersbucksIcon {
  height: 12px;
  margin-right: 6px;
  margin-top: -2px;
}

.depositReferContainer {
  display: flex;
  gap: 10px;

  .depositReferBtn {
    font-size: 12px;
    padding: 0 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.profileContainer, .profileContainerNoHover {
  padding: 20px 20px 20px 5px;
  margin: 0 -20px 0 10px;
  cursor: pointer;
  position: relative;
}

.profileContainer:hover .settingsDropdown {
  display: flex;
}

.settingsDropdown {
  background: var(--obcolor-background-6);
  border: 1px solid var(--obcolor-background-2);
  border-top: none;
  position: absolute;
  top: 60px;
  right: 15px;
  // 1 higher than the x button in modals
  z-index: 201;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  width: 185px;
  color: var(--obcolor-font-primary);
  font-size: 14px;
  white-space: nowrap;
  display: none;
  flex-direction: column;
}

.settingsDropdown > div {
  padding: 10px 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.settingsDropdown > div:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.settingsDropdown > div:hover {
  background: var(--obcolor-background-2);
}

.settingsDropdown > div i {
  margin-right: 10px;
  font-size: 18px;
}

.settingsDropdown > a {
  padding: 10px 10px;
  box-sizing: border-box;
}

.settingsDropdown > a:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.settingsDropdown > a:hover {
  background: var(--obcolor-background-2);
}

.settingsDropdown > a i {
  margin-right: 10px;
  font-size: 18px;
}

.mobileMenu {
  display: none;
  font-size: 24px;
  text-align: center;
  padding: 8px;
}

.notificationMarker {
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 0px;
  right: -7px;
  font-size: 12px;
  color: white;
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.greenText {
  color: var(--obcolor-green);
}

@media only screen and (max-width: 1000px) {
  // Hide Navbar on mobile
  .ob-main-nav {
    height: 0px;
    display: none;
  }

  .ob-logo {
    height: 30px;
    margin-top: 3px;
  }

  .navMenu {
    display: none;
  }

  .userInfo {
    display: none;
  }

  .logoContainer {
    flex: 1;
    height: 30px;
    margin-top: 3px;
  }

  .mobileMenu {
    display: block;
  }
}

.subMenuContainer {
  display: flex;
  width: 100%;
  height: 50px;
  background: var(--obcolor-background-2);
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.navMenusContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 1440px;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
}

.subMenu {
  display: flex;
  justify-self: flex-start;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 1440px;

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    padding: 12px, 8px, 12px, 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.optionalMenu {
  display: flex;
  color: var(--obcolor-font-secondary);
  height: 100%;
  align-items: center;
}
</style>