<template>
  <div class="confetti-container">
    <div v-for="(confetti, index) in confettiList" :key="index" class="confetti"
      :style="getConfettiStyle(confetti)"
    >
      <i style="--bg: yellow"
        class="pentagram"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numParticles: 8,
      randDurationRange: [2, 3],
      randHeightRange: [-20, -30],
      randHorizontalRange: [-100, 100],
      randSizeRange: [7, 8],
      randRotationRange: [-360, 360],
    };
  },

  computed: {
    confettiList() {
      const list = [];
      for (let i = 0; i < this.numParticles; ++i) {
        const confettiObj = this.makeConfettiObj();
        list.push(confettiObj);
      }

      return list;
    },
  },

  methods: {
    makeConfettiObj() {
      const confettiObj = {
        duration: this.randInt(this.randDurationRange[0], this.randDurationRange[1]),
        maxHeight: this.randInt(this.randHeightRange[0], this.randHeightRange[1]),
        horizRange: this.randInt(this.randHorizontalRange[0], this.randHorizontalRange[1]),
        size: this.randInt(this.randSizeRange[0], this.randSizeRange[1]),
        endDeg: this.randInt(this.randRotationRange[0], this.randRotationRange[1]),
      };

      return confettiObj;
    },

    getConfettiStyle(obj) {
      return '--duration: ' + obj.duration + 's; --maxHeight: ' + obj.maxHeight +
        'px; --endX: ' + obj.horizRange + 'px; --size: ' + obj.size + 'px; --endDeg: ' +
        obj.endDeg + 'deg;';
    },

    randInt(min, max) {
      return Math.random() * (max - min) + min;
    },
  },
};
</script>

<style lang="scss" scoped>
.confetti-container {
  width: 100%;
  position: relative;
  user-select: none;
  pointer-events: none;
  z-index: 4;
}
.confetti {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  font-size: var(--size);

  animation: confetti-fly var(--duration) linear forwards;

  .pentagram {
    width: 0em;
    height: 0em;
    display: block;
    margin: 0.5em 0;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid var(--bg);
    border-left: 1em solid transparent;
    transform: rotate(35deg);
    position: relative;
  }
  .pentagram:before {
    border-bottom: 0.8em solid var(--bg);
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -0.45em;
    left: -0.65em;
    display: block;
    content: "";
    transform: rotate(-35deg);
  }
  .pentagram:after {
    position: absolute;
    display: block;
    color: var(--bg);
    top: 0.03em;
    left: -1.05em;
    width: 0em;
    height: 0em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid var(--bg);
    border-left: 1em solid transparent;
    transform: rotate(-70deg);
    content: "";
  }

  @keyframes confetti-fly {
    0% {
      top: 0;
      right: 0;
      animation-timing-function: ease-out;
      transform: rotate(0);
    }
    20% {
      opacity: 0.8;
      top: var(--maxHeight);
      animation-timing-function: cubic-bezier(.36,-0.04,.69,.38);
      transform: rotate(--endDeg);
    }
    60% {
      opacity: 0;
      transform: rotate(calc(var(--endDeg) * 2));
    }
    80%, 100% {
      opacity: 0;
      top: 200px;
      right: var(--endX);
    }
  }
}

.confetti i {
  width: 3em;
  height: 3em;
  margin: 0 0.2em;
}

.confetti i:nth-child(even) {
  transform: rotate(90deg);
}
</style>