<template>
  <div style="display: flex;">
    <div class="bracket" :class="{selectedWeek: week === selectedWeek}" v-for="(bracket, week) in league.bracket"
      :key="week"
    >
      <div class="weekHeaderContainer">
        <div class="weekHeader" :class="{hoverable: allowSelectingWeeks}"
          @click="() => {selectWeek(week)}"
        >
          Week {{ week + 1 }}
        </div>
      </div>
      <div class="matchupContainer" v-for="(matchup, index2) in bracket.matchups" :key="index2"
        style="display: flex; flex-direction: column; flex: 1;"
      >
        <div style="display: flex; flex: 1;">
          <div class="matchup"
            @click="selectMatchup(matchup)"
            :class="{'hoverableMatchup' : matchup.team1Id && matchup.team2Id, 'offsetMatchup' : week == 0}"
          >
            <div :style="getMatchupMargin(matchup, week)" class="matchupTeam" :class="{curUserTeam: matchup.team1Id == userId}">
              <div class="payoutContainer" v-if="league.bracket[week - 1] && league.bracket[week - 1].winnerPrize">
                <i class="fas fa-trophy payoutTrophy"></i>
                <div class="payout">
                  <div class="payoutText">{{ league.bracket[week - 1].winnerPrize }}</div>
                </div>
              </div>
              <UserLogo v-if="matchup.team1Id" :id="matchup.team1Id" :sport="league.sport"
                :size="30" :borderWidth="0"
              />
              <div class="playerName">
                <div class="teamName">{{ matchup.team1Team || 'Pending' }}</div>
                <div class="realName">{{ matchup.team1Name || '' }}</div>
              </div>
              <div v-if="matchup.team1Id != null" class="teamScore" :class="{winningColour: getWinningTeam(matchup) == 1}">
                {{ getScoreOrZero(matchup.team1Score) }}
              </div>
            </div>
            <div :style="getMatchupMargin(matchup, week)" class="matchupTeam" :class="{curUserTeam: matchup.team2Id == userId}">
              <div class="payoutContainer" v-if="league.bracket[week - 1] && league.bracket[week - 1].winnerPrize">
                <i class="fas fa-trophy payoutTrophy"></i>
                <div class="payout">
                  <div class="payoutText">{{ league.bracket[week - 1].winnerPrize }}</div>
                </div>
              </div>
              <UserLogo v-if="matchup.team2Id" :id="matchup.team2Id" :sport="league.sport"
                :size="30" :borderWidth="0"
              />
              <div class="playerName">
                <div class="teamName">{{ matchup.team2Team || 'Pending' }}</div>
                <div class="realName">{{ matchup.team2Name || '' }}</div>
              </div>
              <div v-if="matchup.team2Id != null" class="teamScore" :class="{winningColour: getWinningTeam(matchup) == 2}">
                {{ getScoreOrZero(matchup.team2Score) }}
              </div>
            </div>
          </div>
          <div class="matchupConnectorContainer" :style="getBracketHeightMargin(matchup, week)">
            <div class="matchupConnector" :class="{'isPending' : matchup.team1Team === undefined || matchup.winner == '', 'topWinning': getWinningTeam(matchup) == 1 }">
              <div v-if="matchup.team1Team && matchup.winner != ''" class="winningBar" :style="'height: calc(50% + 3px)'"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="winnerBracket">
      <div class="weekHeaderContainer">
        <div class="weekHeader"></div>
      </div>
      <div class="winnerAlignContainer">
        <div class="winnerCenterContainer">
          <div v-if="finalWinningMatchup !== null" class="winnerTeamName">{{ finalWinningMatchup[finalWinningMatchup.winner + 'Team'] }}</div>
          <div v-else class="winnerTeamName"></div>
          <div v-if="finalWinningMatchup !== null" class="winnerUserName">{{ finalWinningMatchup[finalWinningMatchup.winner + 'Name'] }}</div>
          <div v-else class="winnerUserName"></div>
          <i class="fas fa-trophy finalWinningTrophy"></i>
          <div class="finalWinningUser">
            <UserLogo v-if="finalWinningMatchup !== null && finalWinningMatchup[finalWinningMatchup.winner + 'Id']"
              :id="finalWinningMatchup[finalWinningMatchup.winner + 'Id']" :sport="league.sport" :size="24"
              :borderWidth="0"
            />
          </div>
          <div class="finalWinningPayout">
            <div class="payout">
              <div class="payoutText">{{ finalWinningPrize }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="flex: 1;">
      <div class="weekHeaderContainer">
        <div class="weekHeader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    league: Object,
    selectedWeek: Number,
    allowSelectingWeeks: Boolean,
  },
  components: {
    UserLogo,
  },
  computed: {
    ...mapState(['userId']),
    finalWinningMatchup() {
      const finalLeague = this.league.bracket[this.league.bracket.length - 1];
      if (finalLeague && finalLeague.matchups[0] && finalLeague.matchups[0].winner != '') {
        return finalLeague.matchups[0];
      }
      return null;
    },
    finalWinningPrize() {
      const finalLeague = this.league.bracket[this.league.bracket.length - 1];
      return finalLeague.winnerPrize;
    },
  },
  methods: {
    getMatchupOffset(matchup, weekNum) {
      return ((55 * (Math.pow(2, weekNum)) - 40) / 2);
    },
    // Similar to getMatchupMargin but accounting for bracket height and not subtracting matchup container height
    getBracketHeightMargin(matchup, weekNum) {
      if (weekNum === 0) {
        return 'margin-top: 28px; margin-bottom: 28px;';
      }
      const offset = this.getMatchupOffset(matchup, weekNum) + 20;
      return 'margin-top: ' + offset + 'px; margin-bottom: ' + offset + 'px;';
    },
    // Puts each matchup team in the middle of the bracket before it
    getMatchupMargin(matchup, weekNum) {
      if (weekNum === 0) {
        return 'margin-top: 5px; margin-bottom: 5px;';
      }
      const offset = this.getMatchupOffset(matchup, weekNum);
      return 'margin-top: ' + offset + 'px; margin-bottom: ' + offset + 'px;';
    },
    getScoreOrZero(score) {
      return score != null ? score : 0;
    },
    getWinningTeam(matchup) {
      if (matchup.winner && matchup.winner == 'team1') {
        return 1;
      }
      if (matchup.winner && matchup.winner == 'team2') {
        return 2;
      }
      if (matchup.team1Score > matchup.team2Score) {
        return 1;
      }
      if (matchup.team1Score < matchup.team2Score) {
        return 2;
      }
      if (matchup.team1Score == matchup.team2Score && matchup.coinTossWinner == 'team1') {
        return 1;
      }
      if (matchup.team1Score == matchup.team2Score && matchup.coinTossWinner == 'team2') {
        return 2;
      }

      // Team1 didn't win coin toss and it's a tie
      return 0;
    },
    selectWeek(weekNum) {
      if (this.allowSelectingWeeks) {
        EventBus.$emit('CONTEST_SELECT_WEEK', weekNum);
      }
    },

    selectMatchup(matchup) {
      if (matchup.team1Id && matchup.team2Id) {
        this.$router.push('/contest/matchup/summary/' + this.league.id + '/' + matchup.id);
        this.$emit('selectMatchup');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.weekHeaderContainer {
  background: var(--obcolor-background-3);
}
.weekHeaderContainer:not(:last-child) {
  padding-right: 30px;
}

.weekHeader {
  text-align: center;
  padding: 5px 0;
  height: 28px;
  box-sizing: border-box;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
}

.weekHeader.hoverable {
  cursor: pointer;
}

.weekHeader.hoverable:hover {
  background: var(--obcolor-background-2);
}

.bracket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  // cursor: pointer;
}

// .bracket:hover {
//   background: var(--obcolor-background-5);
// }

.selectedWeek .matchup {
  background: var(--obcolor-background-4);
}

.payoutContainer {
  position: absolute;
  top: -23px;
  display: flex;
  align-items: flex-end;
}

.payoutTrophy {
  color: var(--obcolor-background-1);
  padding-right: 5px;
  font-size: 20px;
}

.payout {
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 20px;
}

.payoutText {
  height: 20px;
  font-size: 12px;
  border-radius: 5px;
  background: var(--obcolor-ob-blue);
  width: max-content;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  color: white;
}

.bracket:first-child .matchupContainer:nth-child(2) .matchup {
  padding-top: 20px;
}

.matchupContainer:nth-child(2) .matchup {
  padding-top: 15px;
}

.matchupContainer:nth-child(2) .matchupConnectorContainer {
  padding-top: 15px;
}

.matchup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // cursor: pointer;
}

.matchup.hoverableMatchup {
  cursor: pointer;
}

.offsetMatchup {
  padding: 5px 0;
}

.matchupWinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.matchup.hoverableMatchup:hover .matchupTeam {
  background: var(--obcolor-ob-blue);
  .playerName > div {
    color: white;
  }
}

.matchupTeam {
  width: 180px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  z-index: 1;
  background: var(--obcolor-background-2);
  border-radius: 10px;
  margin: 5px;
  padding-left: 5px;
  padding-right: 9px;
  font-size: 12px;
}

.matchup:not(:hover) .matchupTeam.curUserTeam {
  background: var(--obcolor-ob-blue-tinted);
  .playerName > div {
    color: white;
  }
}

.playerName {
  flex: 1;
  margin-right: 6px;
  margin-left: 6px;
  width: 95px;
  max-width: 95px;

  & > div {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.teamName {
  font-weight: bold;
}

.realName {
  color: var(--obcolor-font-secondary);
}

.teamScore {
  box-sizing: border-box;
  padding: 4px 0;
  width: 40px;
  height: 22px;
  font-size: 14px;
  border-radius: 5px;
  background: var(--obcolor-background-6);
  display: flex;
  color: var(--obcolor-font-secondary);
  font-weight: bold;
  // align-items: center;
  justify-content: center;
}

.winningColour {
  font-weight: bold;
  color: var(--obcolor-ob-blue);
}

.matchupConnectorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  box-sizing: border-box;
}

.matchupScore {
  font-size: 11px;
  padding: 5px;
  margin-left: 5px;
}

.matchupConnector {
  border-top: 3px solid var(--obcolor-background-1);
  border-bottom: 3px solid var(--obcolor-background-1);
  border-right: 3px solid var(--obcolor-background-1);
  height: 100%;
  width: 20px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.matchupConnector:not(.isPending).topWinning {
  border-top: 3px solid var(--obcolor-ob-blue);

  .winningBar {
    background: var(--obcolor-ob-blue);
  }
}

.matchupConnector:not(.isPending):not(.topWinning) {
  border-bottom: 3px solid var(--obcolor-ob-blue);
  border-right: 3px solid var(--obcolor-ob-blue);

  .winningBar {
    background: var(--obcolor-background-1);
  }
}

.winnerBracket {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.winnerAlignContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-left: 5px;
}

.winnerCenterContainer {
  position: relative;
  align-items: center;
  height: 120px;
  text-align: center;
}

.finalWinningTrophy {
  width: 100%;
  // position: absolute;
  left: 0;
  top: 20px;
  font-size: 50px;
  color: var(--obcolor-background-1);
}

.finalWinningUser {
  width: 100%;
  position: absolute;
  top: 33px;
  height: 30px;
}

.finalWinningPayout {
  position: absolute;
  top: 85px;
  width: 100%;
  text-align: center;
}

.winningBar {
  width: 3px;
  height: calc(100% + 3px);
  z-index: 1;
  margin-right: -3px;
  margin-top: -3px;
}

.winnerTeamName {
  font-size: 12px;
  font-weight: bold;
  height: 13px;
  width: 100%;
}

.winnerUserName {
  font-size: 12px;
  height: 13px;
  color: var(--obcolor-font-secondary);
  padding-bottom: 2px;
  width: 100%;
}
</style>