<template>
  <form @submit.prevent="verifyNumber" class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
      <a class="onboardingForwardBtn" v-if="showNextArrow" @click="goNext"><i class="far fa-chevron-right"></i></a>
    </div>

    <h1>Enter Your Phone Number</h1>
    <p>We’ll text you to confirm your number. Your phone number can only be used to register one OwnersBox account.</p>
    <div class="title">Phone Number</div>
    <div class="phoneNumberContainer">
      <div v-if="phoneNumber" class="countryCode">+1</div>
      <InputMask v-model="phoneNumber" mask="999-999-9999" placeholder="Enter your phone number"
        maskChar="" :class="errorMessage ? 'errorBorder' : ''"
        :style="phoneNumber ? 'padding-left: 45px;' : ''"
      />
    </div>

    <ErrorPanel :message="errorMessage" />

    <div class="consentText">By providing your phone number, you agree to receive text messages from OwnersBox. Message and data rates may apply.</div>

    <button v-if="sendingCode" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>Sending Verification Code...
    </button>
    <button v-else class="ob-btn" :disabled="!canSubmit"
      @click="verifyNumber()"
    >
      Continue
    </button>
    <div class="helpTxt">If you or someone you know has a gambling problem, help is available. Call: 1-800-GAMBLER</div>
  </form>
</template>

<script>
import ObPhoneVerificationApi from '@/api/ObPhoneVerificationApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import EventBus from '@/event-bus';
import InputMask from 'vue-input-mask/src/InputMask.vue';

export default {
  components: {
    InputMask,
    ErrorPanel,
  },

  props: {
    signupData: {type: Object, default: null},
  },

  data() {
    return {
      phoneNumber: null,
      sendingCode: false,
      errorMessage: null,
    };
  },

  created() {
    // When using the back button, pre-populate the selected number
    // Remove country code (+1) from start of the phone number
    if (this.signupData?.phoneNumber) {
      this.phoneNumber = this.signupData?.phoneNumber.substring(2);
    }
    if (this.signupData?.signupErrors?.phoneNumber) {
      this.errorMessage = this.signupData?.signupErrors?.phoneNumber;
    }
  },

  computed: {
    showNextArrow() {
      // Next arrow appears if verification code has already been sent
      return this.signupData?.phoneVerificationId != null;
    },

    canSubmit() {
      if (!this.phoneNumber) {
        return false;
      }
      const phoneNum = this.phoneNumber.replace(/\D/g, '');
      return phoneNum.length == 10;
    },
  },

  methods: {
    goBack() {
      this.$router.push('/home');
    },

    goNext() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    verifyNumber() {
      if (!this.canSubmit || this.sendingCode) {
        return;
      }
      if (!this.phoneNumber) {
        this.errorMessage = 'Please enter a phone number';
        return;
      }
      // Remove any spaces from the phone number
      const phoneDigits = this.phoneNumber.replace(/\D/g, '');
      if (phoneDigits.length != 10) {
        this.errorMessage = 'Please enter a valid 10-digit phone number';
        return;
      }
      // Add country code
      const phoneNumberE164 = '+1' + phoneDigits;
      this.sendVerificationCode(phoneNumberE164);
    },

    sendVerificationCode(phoneNumberE164) {
      this.errorMessage = null;
      this.sendingCode = true;
      ObPhoneVerificationApi.sendVerificationCode(phoneNumberE164)
          .then((response) => {
            // Response must contain a verification id to continue
            if (response?.id) {
              EventBus.$emit('UPDATE_SIGNUP_DATA', 'phoneNumber', phoneNumberE164);
              EventBus.$emit('UPDATE_SIGNUP_DATA', 'phoneVerificationId', response.id);
              EventBus.$emit('UPDATE_SIGNUP_DATA', 'phoneVerified', false);
              this.goNext();
            } else {
              this.errorMessage = 'Failed to send verification code, please contact customer support';
            }
          })
          .catch((error) => {
            if (error?.response?.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Failed to send verification code, please contact customer support';
            }
          })
          .finally(() => {
            this.sendingCode = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  input {
    padding: 14px 14px 14px 20px;
    flex: 1;
  }
  button {
    margin-top: 40px;
    height: 60px;
  }
  .title {
    padding: 40px 0 10px 0;
  }
}

.phoneNumberContainer {
  position: relative;
  display: flex;
}
.countryCode {
  position: absolute;
  left: 5px;
  top: 0px;
  padding: 14px;
  height: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
  user-select: none;
}

.consentText {
  margin-top: 15px;
}

.ob-btn, .ob-btn-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.helpTxt {
  text-align: center;
  border-top: 1px var(--obcolor-font-secondary) solid;
  padding-top: 50px;
  margin-top: 50px;
}
</style>