<template>
  <Modal containerStyle="width: 693px; overflow: hidden; padding: 0;"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="body" class="modalBody">
      <div :class="isIDPV ? 'verifyInfoIDPV' : 'verifyInfo'" :style="modalBgImage">
        <img :src="require('@/assets/verification/verify_failed.png')" class="failedIcon" alt="Verify Failed">
        <ErrorPanel message="Oh no! Verification failed" class="failedError" />
        <div v-if="checkSSN">
          <div style="padding: 8px; margin-top: 15px;">
            Some of your information did not match, please enter the last 4-digits of your SSN
          </div>
          <div style="padding: 8px;">
            for further verification. If you need additional assistance,
            <a href="https://support.ownersbox.com/hc/en-us/requests/new" target="_blank" rel="noopener">contact customer service.</a>
          </div>
        </div>
        <div v-else-if="isIDPV" style="padding: 10px 6px 6px 6px;">
          We were not able to verify your identity; please scan your ID to proceed.
        </div>
        <template v-else>
          <div style="padding: 6px;">
            Please contact
            <a href="https://support.ownersbox.com/hc/en-us/requests/new" target="_blank" rel="noopener">customer service</a>
            to verify your identity in order to proceed.
          </div>
          <div style="padding: 6px;">You will not be able to deposit or play real money contests until you verify your identity.</div>
        </template>
      </div>
      <div v-if="checkSSN" class="ssnPanel">
        <div class="infoBox">To further verify, enter the last 4 digits of your SSN</div>
        <div class="inputText">SSN (Last 4 digits)</div>
        <input v-model="ssn" class="ssnInput"
          maxlength="4" placeholder="Enter last 4 digits"
        >
      </div>
    </div>
    <!-- Footer Buttons -->
    <div v-if="loading" slot="footer" class="modalFooter">
      <button class="ob-btn-grey fullBtn" disabled>
        <i class="fad fa-spinner-third fa-spin"></i>
        Verifying...
      </button>
    </div>
    <div v-else-if="checkSSN" slot="footer" class="modalFooter">
      <button class="ob-btn-grey leftBtn" @click="skipVerification()">Skip Verification</button>
      <button class="ob-btn rightBtn" :disabled="!canVerifySSN" @click="verifyWithSSN()">Verify Account</button>
    </div>
    <div v-else slot="footer" class="modalFooter">
      <router-link v-if="isIDPV" class="ob-btn fullBtn" to="/verifyAccountById">
        Continue to Scan ID
      </router-link>
      <button v-else class="ob-btn fullBtn" @click="skipVerification()">{{ skipButtonText }}</button>
    </div>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    Modal,
    ErrorPanel,
  },

  props: {
    checkSSN: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    onBoardingStep: {type: Boolean, default: false},
    verifyStatus: {type: String, default: ''},
  },

  data() {
    return {
      ssn: null,
    };
  },

  computed: {
    modalBgImage() {
      return 'background-image: url(' + require('@/assets/verification/verify_bg.png') + ')';
    },

    canVerifySSN() {
      return this.ssn != null && this.ssn.length == 4;
    },

    skipButtonText() {
      return this.onBoardingStep ? 'Skip Verification' : 'Go to Lobby';
    },

    isIDPV() {
      return this.verifyStatus === 'IDPV_VERIFY';
    },
  },

  methods: {
    skipVerification() {
      if (this.onBoardingStep) {
        EventBus.$emit('ON_BOARDING_EXIT');
      } else {
        this.$router.push('/');
      }
    },

    verifyWithSSN() {
      this.$emit('verifyWithSSN', this.ssn);
    },
  },
};
</script>

<style lang="scss" scoped>
.modalBody {
  background: var(--obcolor-modal-bg);
  color: var(--obcolor-font-always-white);
  position: relative;
  a {
    color: var(--obcolor-ob-blue);
    text-decoration: underline;
  }
}

.verifyInfo {
  position: relative;
  text-align: center;
  height: 369px;
}

.verifyInfoIDPV {
  position: relative;
  text-align: center;
  height: 300px;
}

.failedIcon {
  height: 90px;
  margin-top: 70px;
  margin-bottom: 25px;
}
.failedError {
  display: inline-flex;
  width: 538px;
  padding: 15px;
  font-size: 18px;
  justify-content: center;
  margin-bottom: 10px;
}

.ssnPanel {
  background: var(--obcolor-modal-bg);
  height: 220px;
  padding: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.inputText {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 10px;
}
.ssnInput {
  margin: 0 !important;
}
.infoBox {
  background: var(--obcolor-info-panel);
  color: var(--obcolor-info-text);
  font-size: 16px;
  height: 52px;
  box-sizing: border-box;
  padding: 11px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.leftBtn {
  border-radius: 0 0 0 5px;
}
.rightBtn {
  border-radius: 0 0 5px 0;
}
.fullBtn {
  border-radius: 0 0 5px 5px;
}

.modalFooter {
  display: flex;
  padding: 0;
  height: 60px;
  .ob-btn {
    flex: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ob-btn[disabled] {
  color: var(--obcolor-background-2) !important;
  background: var(--obcolor-disable);
  cursor: not-allowed;
}
</style>