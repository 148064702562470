import Api from '@/api/ObBaseApi';

export default {
  saveUserCode(_integrationName, code) {
    // In the future, this can be a switch statement that accepts an integration name and runs a different call
    return this.saveKrogerCode(code);
  },

  saveKrogerCode(code) {
    return Api()
        .post('/fsp/integrations/saveKrogerIntegrationId?krogerAuthCode=' + encodeURIComponent(code))
        .then((response) => {
          return response.data;
        });
  },

  unlinkKrogerIntegration() {
    return Api()
        .post('/fsp/integrations/unlinkKrogerIntegration')
        .then((response) => {
          return response.data;
        });
  },

  getKrogerOAuthUrl() {
    return Api()
        .get('/fsp/integrations/getKrogerOAuthUrl')
        .then((response) => {
          return response.data;
        });
  },

  loadUserKrogerId() {
    return Api()
        .get('/fsp/integrations/getUserKrogerId')
        .then((response) => {
          return response.data;
        });
  },
};
