<template>
  <div class="playerContainer ob-scroll">
    <!-- Header -->
    <div class="playerEntry playerHeader">
      <div></div>
      <div>PLAYER</div>
      <div style="flex: 1;"></div>
      <div>PROJ</div>
    </div>

    <!-- Players -->
    <div v-for="p in players" :key="p.id" class="playerEntry">
      <div>{{ p.draftPos }}</div>
      <PlayerHeadshot :id="p.id" size="45" class="headshot"
        :sport="sport" :team="p.player.team" :showLogo="true"
      />
      <div style="flex: 1;">
        <div class="playerName">
          <PlayerStatus :status="p.fspGames[0] ? p.fspGames[0].rollingStatus : null" />
          {{ p.player.name.shortName() }}
        </div>
        <div class="gameInfo">{{ nextGameInfo(p) }}</div>
      </div>
      <div class="projection">{{ p.projTotal }}</div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';

export default {

  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    players: {type: Array, default: null},
    sport: {type: String, default: null},
  },

  methods: {
    nextGameInfo(player) {
      const nextGame = player?.fspGames?.[0];
      if (!nextGame) {
        return 'No Game';
      }
      let info = nextGame.home == 'true' ? 'vs ' : '@ ';
      info += this.$TeamInfo.getDisplayAlias(this.sport, nextGame.opp_alias);
      info += this.$moment(Number(nextGame.timestamp)).format(' ddd h:mma');
      return info;
    },
  },

};
</script>

<style lang="scss" scoped>
.playerContainer {
  height: fit-content;
  max-height: 100%;
  border: 1px solid var(--obcolor-background-2);
  overflow-y: auto;
}

.playerHeader {
  color: var(--obcolor-font-primary);
  font-weight: bold;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 5;
}

.playerEntry {
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--obcolor-background-5);
  padding: 6px;
  border-bottom: 1px solid var(--obcolor-background-2);
  font-weight: bold;
  font-size: 14px;

  >div:first-child {
    width: 50px;
    text-align: center;
  }
  >div:last-child {
    width: 60px;
    text-align: center;
  }
  .projection {
    color: var(--obcolor-font-secondary);
  }
}
.playerEntry:last-child {
  border-bottom: none;
}

.playerName {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.gameInfo {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  font-weight: normal;
  margin-left: 18px;
  margin-top: 3px;
  margin-bottom: -3px;
}

.headshot {
  margin-right: 12px;
  margin-bottom: 4px;
}
</style>