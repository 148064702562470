<template>
  <table aria-label="Contest Won">
    <tr :key="index" v-for="(contest, index) in contests" @click="goToContest(contest)">
      <td style="width: 420px; box-sizing: border-box;">
        <div style="display: flex; align-items: center;">
          <div style="width: 80px; min-width: 80px; text-align: center; padding-right: 5px;">
            <img :src="require('@/assets/contestbadge/' + contestBadgeName(contest) + '.png')" alt="Contest Badge" style="max-height: 60px; max-width: 70px;">
          </div>
          <div>
            <div v-if="isLLContest(contest)" class="contestName" :title="contest.sport + ' Lightning Lineups'">{{ contest.sport }} Lightning Lineups</div>
            <div v-else-if="contest.contestName" class="contestName" :title="contest.contestName">{{ contest.contestName }}</div>
            <div v-else class="contestName" :title="contest.sport + ' ' + contest.playersMax + ' Teams'">{{ contest.sport }} {{ contest.playersMax }} Teams</div>
            <div v-if="isLLContest(contest)" class="summaryText">{{ llNumTeams(contest) }} Lineups ({{ llPayoutMulti(contest) }}x Payout)</div>
            <div v-else class="summaryText">{{ contest.id }}</div>
            <div v-if="isLLContest(contest)" class="summaryText">
              {{ $moment(contest.contestStart).format('MMM D, h:mma') }}
            </div>
            <div v-else-if="contest.format == 'DAILY'" class="summaryText">
              {{ getStartEnd(contest).start.format('MMM D, YYYY') }}
            </div>
            <div v-else class="summaryText">
              {{ getStartEnd(contest).start.format('MMM D') }} - {{ getStartEnd(contest).end.format('MMM D') }}
            </div>
            <div class="summaryText" v-if="contest.fee && contest.realMoney == true">
              Entry: {{ contest.fee.removeCentsIfZero() }}
            </div>
            <div v-else-if="contest.fee" class="summaryText" style="display: flex; align-items: center; width: 100%;">
              <div>Entry: </div>
              <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 10px; margin-right: 2px; margin-left: 5px; margin-bottom: 2px;">
              <div>{{ contest.fee.removeCentsIfZero() }}</div>
            </div>
          </div>
          <div v-if="isLLContest(contest)" class="lightningPlayerList">
            <PlayerHeadshot v-for="(player, index) in contest.userTeam.players" :key="index"
              size="50"
              class="playerHeadshot" :id="player.id"
              :sport="contest.sport" :team="player.teamAlias"
              :showLogo="true"
            />
          </div>
        </div>
      </td>
      <td style="padding: 0; width: 0;"></td>
      <td style="width: 95px;">
        <div v-if="contest.realMoney == true" class="resultText moneyText">{{ getMyPayouts(contest).removeCentsIfZero() }}</div>
        <div v-else class="resultText blueText">
          <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" style="height: 12px; margin-right: 5px; margin-bottom: 2px;">
          <div>{{ getMyPayouts(contest).removeCentsIfZero() }}</div>
        </div>
        <div style="margin-top: 3px;">{{ getMyTicketPayouts(contest) }}</div>
        <div class="resultLabel">WINNINGS</div>
      </td>
    </tr>
  </table>
</template>

<script>
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import PlayerHeadshot from '@/components/PlayerHeadshot';

export default {
  props: {
    contests: Array,
  },

  components: {
    PlayerHeadshot,
  },

  computed: {
    ...mapState(['userId']),
  },

  methods: {
    isLLContest(contest) {
      return contest.ctype == 'LIGHTNING_LINEUPS';
    },

    // Returns the file name of the contest badge based on contest type
    contestBadgeName(contest) {
      if (this.isLLContest(contest)) {
        return 'lightning_lineups';
      } else if (contest.ctype === 'Tournament' && contest.format === 'DAILY') {
        return 'salarycap';
      } else {
        return 'weekly_contest';
      }
    },

    getMyEntriesList(contest) {
      if (!contest.leaderboard) {
        return [];
      }
      return contest.leaderboard.filter((entry) => entry.userId === this.userId);
    },

    getTournamentPayouts(contest) {
      let totPayouts = 0;
      const myEntries = this.getMyEntriesList(contest);

      for (const entry of myEntries) {
        totPayouts += entry.prizeValue;
      }

      return totPayouts;
    },

    getH2hPayouts(contest, playerId) {
      let totPayouts = 0;
      // Traverse the bracket to find which payouts are the player's
      for (const weekIndex in contest.bracket) {
        for (const matchup of contest.bracket[weekIndex].matchups) {
          if (matchup.winner == 'team1' && matchup.team1Id == playerId ||
            matchup.winner == 'team2' && matchup.team2Id == playerId
          ) {
            totPayouts += contest.payouts[weekIndex].value;
          }
        }
      }

      return totPayouts;
    },

    // Gets total payout for a given player
    getPlayerPayouts(contest, playerId) {
      let totPayouts = 0;
      if (contest.ctype === 'Tournament') {
        totPayouts = this.getTournamentPayouts(contest);
      } else {
        totPayouts = this.getH2hPayouts(contest, playerId);
      }

      return (contest.realMoney ? '$' : '') + this.formatMoney(totPayouts / 100);
    },

    getMyPayouts(contest) {
      if (this.isLLContest(contest)) {
        return contest.userWinningsFormatted;
      }
      return this.getPlayerPayouts(contest, this.userId);
    },

    getMyTicketPayouts(contest) {
      // No ticket prizes for head 2 head or lightning lineups
      if (contest.ctype !== 'Tournament') {
        return '';
      }
      let numTickets = 0;
      for (const entry of this.getMyEntriesList(contest)) {
        if (entry.ticketPrize) {
          numTickets += 1;
        }
      }
      if (numTickets == 0) {
        return '';
      }
      return numTickets + ' Ticket' + (numTickets > 1 ? 's' : '');
    },

    getStartEnd(league) {
      if (league.ctype === 'Tournament') {
        const start = this.$moment(league.days[0], 'YYYY-MM-DD');
        const end = this.$moment(league.days[league.days.length - 1], 'YYYY-MM-DD');
        return {start: start, end: end};
      }

      return {start: this.$moment(league.leagueStart), end: this.$moment(league.leagueEnd)};
    },

    goToContest(contest) {
      if (this.isLLContest(contest)) {
        this.$router.push('/lightning-lineups/live/' + contest.id);
      }

      if (contest.ctype !== 'Tournament') {
        EventBus.$emit('SHOW_BRACKET_MODAL', contest);
        this.$emit('close');
        return;
      }

      if (!contest.leaderboard) {
        return;
      }
      const myEntries = contest.leaderboard.filter((entry) => entry.userId === this.userId);

      this.$router.push('/salarycontest/leaderboard/' + contest.id + '/' + myEntries[0].teamId);
    },

    formatMoney(value) {
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value.toFixed(2)).removeCentsIfZero();
    },

    llNumTeams(contest) {
      if (!this.isLLContest(contest)) {
        return null;
      }

      return contest.obTeams.length + 1;
    },

    llPayoutMulti(contest) {
      if (!this.isLLContest(contest)) {
        return null;
      }

      return contest.payoutMultiplier;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  height: max-content;
  tr {
    height: 90px;
    min-height: 90px;
    background: var(--obcolor-background-5);
    border-bottom: 2px solid var(--obcolor-background-page);
    cursor: pointer;

    &:first-child {
      border-top: 2px solid var(--obcolor-background-page);
    }

    &:hover {
      background: var(--obcolor-background-3);
    }
  }
}

table td:not(:first-child) {
  text-align: center;
}

table td, table th {
  box-sizing: border-box;
}

.lightningPlayerList {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .playerHeadshot {
    margin: 0 5px;
  }
}
.contestName {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summaryText {
  margin-top: 3px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}

.resultText {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.resultLabel {
  margin-top: 2px;
  margin-bottom: -2px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}
</style>