// @ts-check

/** @typedef {import('vuex').Module} Module */

/** @type {Module} */
export const BrazeModule = {
  state() {
    return {
      isBrazeInitialized: false,
      contentCards: null,
    };
  },
  mutations: {
    setBrazeInitialized(state, brazeInitialized) {
      state.isBrazeInitialized = brazeInitialized;
    },

    setContentCards(state, contentCards) {
      state.contentCards = contentCards;
    },
  },
  getters: {
    isBrazeInitialized(state) {
      return state.isBrazeInitialized;
    },

    contentCards(state) {
      return state.contentCards;
    },
    contentCardsLoading(state) {
      return state.contentCards === null;
    },
  },
};