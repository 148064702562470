<template>
  <div v-if="playerDetails.gamelog">
    <table style="border-radius: 5px; width: calc(100% - 1px);" aria-label="Player Card Next Game">
      <tr style="background: var(--obcolor-background-2);" v-if="scoringStats.length > 1">
        <th class="endSection" colspan="2" scope="col"></th>
        <th class="statHeader endSection" v-for="(category, index) in scoringStats" :key="index"
          :colspan="category.stats.length" scope="col"
        >
          <span style="font-size: 12px;">{{ category.label }}</span>
        </th>
      </tr>
      <tr style="background: var(--obcolor-background-2);">
        <th class="leftInfo tableTitle" scope="col">
          <div style="display: flex;">
            <div>NEXT GAME</div>
            <div v-if="gameIsLive()" class="liveText">LIVE</div>
          </div>
        </th>
        <th class="endSection" v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat' }" scope="col">FPTS</th>
        <template v-for="(category, index1) in scoringStats">
          <th v-for="(stat, index2) in category.stats" :key="index1 + '_' + index2"
            v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltipHeat' }"
            :class="{endSection: index2 == category.stats.length - 1}"
          >
            {{ stat.label }}
          </th>
        </template>
      </tr>

      <tr v-for="(gInfo, index) in nextGamesList" :key="index">
        <td class="leftInfo" style="padding: 4px 15px;">
          <div style="display: flex; align-items: center; min-width: 150px;">
            <span>{{ gInfo.game.home === 'true' ? 'vs' : '@' }}</span>
            <img class="teamLogo" :src="getTeamLogo(gInfo.game.opponent)" :alt="gInfo.game.opponent">
            <span>{{ $SportInfo.getGameSummary(sport, gInfo.data ? gInfo.data : gInfo.game, playerDetails.teamId, $moment, false) }}</span>
          </div>
        </td>
        <td v-if="gInfo.boxscore && gInfo.boxscore.fpts" style="font-weight: bold;">
          <span v-if="gInfo.boxscore.fpts.total >= 0" style="color: var(--obcolor-accent);">+{{ gInfo.boxscore.fpts.total }}</span>
          <span v-else style="color: red;">{{ gInfo.boxscore.fpts.total }}</span>
        </td>
        <td v-else>-</td>

        <!-- Stats -->
        <template v-for="(category, index1) in scoringStats">
          <template v-for="(stat, index2) in category.stats">
            <td :key="playerDetails.id + '_' + index1 + '_' + index2" v-if="gInfo.boxscore && getStat(gInfo.boxscore, stat.path) != '-'"
              :style="'background: ' + $ScoringStats.getStatBackground(getStat(gInfo.boxscore, stat.path), stat) "
            >
              <span>{{ getStat(gInfo.boxscore, stat.path) }}</span>
            </td>
            <td v-else :key="playerDetails.id + '_' + index1 + '_' + index2">-</td>
          </template>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import ObSportDataApi from '@/api/ObSportDataApi';

export default {
  props: {
    playerDetails: Object,
    sport: String,
  },

  computed: {
    ...mapState(['todaysGames']),
  },

  watch: {
    todaysGames(to, from) {
      this.setupData();
    },
  },

  data() {
    return {
      scoringStats: {},
      nextGamesList: [],
    };
  },

  created() {
    this.scoringStats = this.$ScoringStats.getLiveStats(this.sport, this.playerDetails.position);
    this.setupData();
    EventBus.$on('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  destroyed() {
    EventBus.$off('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  methods: {

    onGameUpdate(gameId) {
      if (this.nextGame && gameId == this.nextGame.id) {
        this.setupData();
      }
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    gameIsLive() {
      for (const gameObject of this.nextGamesList) {
        if (gameObject.data) {
          const data = gameObject.data;

          const liveJSON = data && data.live ? data.live : null;

          if (liveJSON && liveJSON.status === 'mid-event') {
            return true;
          };
        }
      }

      return false;
    },

    getBoxscore(game, gameIndex) {
      if (this.nextGamesList.length == 0) {
        return;
      }

      ObSportDataApi.getPlayerBoxscore(this.sport, this.playerDetails.id, game.id)
          .then((data) => {
            if (this.nextGamesList.length == 1) {
              this.$set(this.nextGamesList[0], 'boxscore', data);
            } else if (this.nextGamesList.length > 1) {
              this.$set(this.nextGamesList[gameIndex], 'boxscore', data);
            }
          });
    },

    getGameInfo(gameObject) {
      if (!gameObject || !gameObject.game || !gameObject.data) {
        return '';
      }

      const game = gameObject.game;
      const data = gameObject.data;

      const gameJSON = data;
      const liveJSON = data && data.live ? data.live : null;
      const opp = game.opponent;

      if (liveJSON && liveJSON.status === 'mid-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        if (this.sport === 'MLB') {
          if (liveJSON.inning_half && liveJSON.inning) {
            gameInfo += liveJSON.inning_half === 'top' ? 'Top ' : 'Bot ';
            gameInfo += this.addOrdinalSuffix(liveJSON.inning);
          }
          return gameInfo;
        }
        const gameTime = liveJSON.gametime === '00:00' ? 'End' : liveJSON.gametime;
        gameInfo += gameTime + ' ' + this.addOrdinalSuffix(liveJSON.period);
        return gameInfo;
      } else if (liveJSON && liveJSON.status === 'post-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        gameInfo += ' Final';
        return gameInfo;
      }

      // Game is not live, show time or date
      const gameDate = this.$moment(game.date).format('ddd MMM D');
      const today = this.$moment().format('ddd MMM D');

      if (gameDate === today) {
        return this.$moment(game.date).format('h:mma');
      }
      return gameDate;
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    setupData() {
      if (this.playerDetails) {
        let nextGames = this.playerDetails.next_games;
        const nextGamesList = [];

        nextGames = nextGames.filter((game) => {
          return game.id != null;
        });
        // If game is in slot 0 it is being played today
        if (nextGames && nextGames[0] && nextGames[0].id) {
          const game = nextGames[0];
          const todaysGameData = this.todaysGames[game.id];
          let gameInfo = null;

          const gameObject = {game: game, data: todaysGameData, isToday: true};
          gameInfo = this.getGameInfo(gameObject);
          this.$set(gameObject, 'info', gameInfo);

          this.isToday = true;

          nextGamesList.push(gameObject);
        }

        let gamesSameDate = false;

        if (nextGames[0] && nextGames[1]) {
          const game1Date = this.$moment(parseInt(nextGames[0].timestamp)).format('YYYY-MM-DD');
          const game2Date = this.$moment(parseInt(nextGames[1].timestamp)).format('YYYY-MM-DD');

          if (game1Date == game2Date) {
            gamesSameDate = true;
          }
        }


        if (gamesSameDate || (nextGames && nextGames[0] == null && nextGames[1] && nextGames[1].id)) {
          const game = nextGames[1];
          const today = this.$moment().format('YYYY-MM-DD');
          const gameDate = this.$moment(parseInt(game.timestamp)).format('YYYY-MM-DD');
          let todaysGameData = null;
          let gameInfo = null;
          let isToday = false;

          if (gameDate == today) {
            isToday = true;
            todaysGameData = this.todaysGames[game.id];
          }

          const gameObject = {game: game, data: todaysGameData, isToday: isToday};
          gameInfo = this.getGameInfo(gameObject);
          this.$set(gameObject, 'info', gameInfo);

          nextGamesList.push(gameObject);
        }
        this.nextGamesList = nextGamesList;

        for (const gameIndex in this.nextGamesList) {
          this.getBoxscore(this.nextGamesList[gameIndex].game, gameIndex);
        }
      }
      this.gameInfo = this.getGameInfo();
    },

    getStat(game, statPath) {
      if (!game || !game.stats || !statPath) {
        return '-';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game.stats[playerRole]) {
        return '-';
      }
      const stat = game.stats[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

  },
};
</script>

<style lang="scss" scoped>
.leftInfo.tableTitle {
  font-size: 14px;
}

.leftInfo {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding: 12px 15px;
  padding-right: 0px;
  width: 110px;
  white-space: nowrap;
}

.liveText {
  color: var(--obcolor-accent);
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  margin-top: -3px;
}

.teamLogo {
  width: 25px;
  margin-bottom: 2px;
  margin-left: 4px;
  margin-right: 6px;
}

.statHeader {
  padding-top: 10px;
  padding-bottom: 0px;
  font-size: 12px;
}

.tableTitle {
  color: var(--obcolor-font-secondary);
}

.gameSummary {
  display: flex;
  min-width: 100px;
}

th {
  color: var(--obcolor-font-secondary);
  padding: 10px;
  position: sticky;
  top: 0px;
}

td {
  border: 2px solid var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
}

th:not(.leftInfo), td:not(.leftInfo) {
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  min-width: 50px;
}

.endSection:not(:last-child) {
  border-right: 2px solid var(--obcolor-background-6);
}
</style>