<template>
  <div class="itemList">
    <div class="selectContainer" v-for="(format, index) in rosterFormats"
      :class="{selected: selected !== null && selected.value === format.value, disabled: !canSelectFormat(format)}"
      @click="selectFormat(format)" :key="index"
    >
      <div class="title">
        <img v-if="format.value === 'PRIME_TIME_FLEX'" :src="require('@/assets/contesticons/Primetime.svg')" alt="Primetime Contest">
        {{ format.label }}
      </div>
      <div class="description">{{ format.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rosterFormats: {type: Array, default: null},
    gameSlates: {type: Array, default: null},
    selected: {type: Object, default: null},
  },

  methods: {
    canSelectFormat(format) {
      if (!this.gameSlates || !this.rosterFormats) {
        return false;
      }

      for (const slate of this.gameSlates) {
        if (slate.rosterFormat == format.label) {
          return true;
        }
      }

      return false;
    },

    selectFormat(format) {
      if (this.canSelectFormat(format)) {
        this.$emit('select', format);
        this.$emit('nextStep');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.selectContainer {
  width: 320px;
}
.selectContainer img {
  height: 18px;
  margin-right: 5px;
  margin-bottom: 3px;
}
</style>