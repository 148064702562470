<template>
  <div style="height: 145px;" :style="canScroll">
    <flickity class="myLeagues" ref="flickity" :options="flickityOptions">
      <div class="pickContainer" v-for="(pick, index) in draftJSON.picks" :key="pick.overall"
        :style="getHighlightColor(pick)"
      >
        <div v-if="pick.overall == curPick" class="draftingText">
          Now Drafting
        </div>

        <div v-else class="roundInfo">
          <div v-if="pick.overall != curPick && roundChanged(index)">
            Round {{ pick.round }}
          </div>
        </div>

        <div class="pickBox">
          <div class="teamName">{{ pick.userTeam }}</div>
          <template v-if="pick.playerData != null">
            <PlayerHeadshot class="playerHeadshot" :id="pick.playerData.id" :sport="draftJSON.sport"
              :team="pick.playerData.team" :pos="pick.playerData.draftPos"
            />
            <div class="playerName">{{ pick.playerData.name.lastName() }}</div>
          </template>
          <template v-else>
            <UserLogo class="userLogo" :size="60" :id="pick.userId"
              :sport="draftJSON.sport"
              :showRank="true" :rankNum="getRankNum(pick.userId)" :rankColor="getRankColor(pick.userId)"
            />
            <div v-if="pick.overall != curPick" class="playerName">{{ pick.userName }}</div>
          </template>
          <!-- Countdown for Current Pick -->
          <div v-if="pick.overall == curPick" class="clock" :style="getHighlightColor(pick)">
            <CountdownTimer @tick="setSeconds" :end="draftJSON.turnLimit" :min="0"
              :minText="'autopick'" :clockFormat="true" :serverOffset="serverOffset"
            />
          </div>
        </div>
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import UserLogo from '@/components/UserLogo.vue';
import CountdownTimer from '@/components/CountdownTimer.vue';

const ppsAlert = new Audio(require('@/assets/sounds/ppsalert.mp3'));

export default {

  computed: {
    isYourTurn: function() {
      return this.curUser === this.draftJSON.turn;
    },
    canScroll: function() {
      if (this.curPick != 0) {
        return 'pointer-events: none;';
      }
      if (this.draftJSON == null || this.draftJSON.state === 'NOT_STARTED') {
        return 'pointer-events: none;';
      }
      return '';
    },
    curPick() {
      if (this.draftJSON != null && this.draftJSON.overall != null) {
        return this.draftJSON.overall;
      }
      return 0;
    },
  },

  components: {
    Flickity,
    PlayerHeadshot,
    UserLogo,
    CountdownTimer,
  },

  props: {
    draftJSON: Object,
    users: Object,
    curUser: String,
    serverOffset: Number,
  },

  watch: {
    curPick(to, from) {
      this.scrollToCurPick();
      if (to !== from && this.isYourTurn) {
        ppsAlert.play().catch((_error) => {
          // Could not play
        });
      };
    },
  },

  data() {
    return {
      flickityOptions: {
        pageDots: false,
        cellAlign: 'center',
        selectedAttraction: 0.02,
        friction: 0.3,
        contain: true,
        prevNextButtons: false,
        draggable: true,
        percentPosition: false,
      },
      curSelection: null,
      seconds: null,
    };
  },

  created() {
    setTimeout(this.scrollToCurPick, 100);
  },

  methods: {
    setSeconds(seconds) {
      this.seconds = seconds;
    },
    roundChanged(pickIndex) {
      const round = this.draftJSON.picks[pickIndex].round;
      const lastPick = this.draftJSON.picks[pickIndex-1];
      if (lastPick != null) {
        return round != lastPick.round;
      }
      return true;
    },
    scrollToCurPick() {
      if (this.curPick !== 0 && this.curSelection !== this.curPick) {
        this.curSelection = this.curPick;
        this.$refs.flickity.select(this.curPick-1);
      }
    },
    getRankColor(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankColor || 'blue';
      }
      return 'blue';
    },
    getRankNum(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankNum || 1;
      }
      return 1;
    },
    getHighlightColor(pick) {
      if (pick.overall === this.curPick) {
        if (this.seconds !== null && pick.userId === this.curUser && this.seconds <= 10) {
          return 'background: var(--obcolor-red);';
        }
        return pick.userId === this.curUser ? 'background: var(--obcolor-ob-blue);' : 'background: #999';
      }
      return '';
    },
  },

};
</script>

<style lang="scss" scoped>
.pickContainer {
  width: 150px;
  height: 145px;
  padding: 10px;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0 ;
}
.roundInfo {
  font-size: 11px;
  height: 8px;
  padding-top: 2px;
  padding-left: 3px;
  color: var(--obcolor-font-secondary);
}
.draftingText {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  height: 10px;
}
.pickBox {
  width: 100%;
  margin-top: 10px;
  height: calc(100% - 20px);
  background: var(--obcolor-background-2);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}
.teamName {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.playerHeadshot {
  width: 60px;
  height: 60px;
  margin-top: 6px;
}
.userLogo {
  width: 55px;
  height: 55px;
  margin-top: 6px;
}
.playerName {
  font-size: 12px;
  margin-top: 3px;
  color: var(--obcolor-font-secondary);
}
.clock {
  background: var(--obcolor-ob-blue);
  border-radius: 6px 6px 0 0;
  width: 70px;
  margin: auto;
  margin-top: 6px;
  padding-top: 3px;
  color: white;
  font-size: 14px;
}
</style>
