<template>
  <div>
    <!-- Contest Details -->
    <div class="selectContest">
      <router-link :to="getContestLink()" class="detailsBox">
        <div class="sportBox">
          <div>
            <img :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="league.sport"
              style="height: 30px;"
            >
            <div v-if="league.isPrivate" class="privateIcon"><i class="fas fa-lock"></i></div>
          </div>
        </div>
        <div class="detailsContainer">
          <div v-if="league.contestName" class="detailsHeader">{{ league.contestName }}</div>
          <div v-else class="detailsHeader">{{ league.sport }} {{ league.playersMax }} Teams</div>
          <div class="infoList" :class="league.realMoney ? 'moneyText' : 'blueText'" style="font-weight: bold;">
            <div style="margin-right: 4px;">Prizes:</div>
            <div>
              <img v-if="!league.realMoney" style="height: 9px; margin-right: 3px;" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              <SalaryContestPrizePool :contest="league" />
            </div>
          </div>
          <div class="infoList">Tournament</div>
          <div class="infoList" style="min-width: 0; flex: 1; display: flex; margin-top: 5px;">{{ league.id }}</div>
          <div class="infoList">
            <div style="margin-right: 4px;">Entry Fee:</div>
            <div :class="league.realMoney ? 'moneyText' : 'blueText'">
              <img v-if="!league.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                style="height: 9px;"
              > {{ league.fee.removeCentsIfZero() }}
            </div>
          </div>
          <div class="infoList">
            <div style="margin-right: 4px;">Entries:</div>
            <div>{{ league.playersCurrent }} / {{ league.playersMax }}</div>
          </div>
          <div v-if="league.state === 'FILLING'" class="infoList shareContest" @click.prevent="openInviteModal()">Share Contest</div>
        </div>
      </router-link>

      <!-- My Score -->
      <div class="matchupBox">
        <div>
          <div style="display: flex;">
            <div style="flex: 1; text-align: center;">
              <UserLogo :id="userId" :sport="league.sport" :size="40" />
            </div>
            <div class="infoBox">
              <div style="margin-top: 3px;">Entries</div>
              <div class="infoValue">{{ myEntries.length }}</div>
            </div>
          </div>
          <div class="infoList">
            <div class="infoBox">
              <div>Placing</div>
              <div v-if="isPreContest()" class="infoValue">-</div>
              <div v-else class="infoValue">{{ numPlacing || '0' }}</div>
            </div>
            <div class="infoBox">
              <div>Winning</div>
              <div class="winningsValue" :class="league.realMoney ? 'moneyText' : 'blueText'">
                <span>{{ totWinningsFormatted }}</span>
                <template v-if="numTicketsWon > 0">
                  <span style="margin-left: 2px; margin-right: 2px;">+</span>
                  <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
                    v-tooltip.bottom="{content: numTicketsWon + ' Ticket' + (numTicketsWon > 1 ? 's' : ''), class: 'ObTooltip'}"
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="leaderboardLink">Days Left: {{ daysLeft }}</div>
          <div v-if="isDailyContest && myEntries.length !== 0" class="lineupEditorLink" @click.stop="editLineups">CSV Lineup Editor</div>
        </div>
      </div>
    </div>

    <!-- Graph -->
    <div class="graphContainer" :style="getSalaryCapHeightStyle()">
      <LiveSalaryFilling v-if="isPreContest()" :league="league" />

      <div v-else class="payzoneContainer">
        <SalaryContestEntriesBar :contest="league" :teamDate="teamData" />
      </div>

      <LiveSalaryEntries :league="league" :myEntries="myEntries" :canEnterLineup="canEnterLineup()"
        :selectedEntry="selectedEntry" @selectEntry="selectEntry" :isPreContest="isPreContest()"
        :teamData="teamData"
      />
    </div>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import UserLogo from '@/components/UserLogo';
import LiveSalaryFilling from './LiveSalaryFilling';
import LiveSalaryEntries from './LiveSalaryEntries';
import EventBus from '@/event-bus';
import SalaryContestEntriesBar from '@/views/SalaryContest/SalaryContestEntriesBar.vue';

export default {

  components: {
    UserLogo,
    SalaryContestEntriesBar,
    LiveSalaryFilling,
    LiveSalaryEntries,
    SalaryContestPrizePool,
  },

  props: {
    league: Object,
    userId: String,
    teamData: Object,
  },

  data() {
    return {
      selectedEntry: null,
    };
  },

  computed: {
    myEntries() {
      if (!this.league.leaderboard) {
        return [];
      }
      return this.league.leaderboard.filter((entry) => entry.userId === this.userId);
    },

    daysLeft() {
      if (this.league == null || this.league.days == null) {
        return 'N/A';
      }
      const finalDay = this.$moment(this.league.days[this.league.days.length - 1]).hours(0).minutes(0).seconds(0).milliseconds(0);
      const today = this.$moment().hours(0).minutes(0).seconds(0).milliseconds(0);
      const diff = Math.floor(finalDay.diff(today, 'days')) + 1;
      return diff > 0 ? diff : 0;
    },

    numPlacing() {
      let numPlacing = 0;
      for (const entry of this.myEntries) {
        if (entry.prizeValue && entry.prizeValue > 0 || entry.ticketPrize || entry.splitTicketPrize || this.isPlacing(entry.rank)) {
          numPlacing++;
        }
      }
      return numPlacing;
    },

    totWinnings() {
      let totWinnings = 0;
      for (const entry of this.myEntries) {
        if (entry.prizeValue && entry.prizeValue > 0) {
          totWinnings += entry.prizeValue;
        }
      }
      return totWinnings;
    },

    totWinningsFormatted() {
      return this.dollarFormat(this.totWinnings / 100).removeCentsIfZero();
    },

    numTicketsWon() {
      let numTickets = 0;
      for (const entry of this.myEntries) {
        if (entry.ticketPrize || entry.splitTicketPrize) {
          numTickets += 1;
        }
      }
      return numTickets;
    },

    isDailyContest() {
      return this.league.format == 'DAILY';
    },
  },

  created() {
    this.selectedEntry = this.myEntries[0];
  },

  methods: {

    isPreContest() {
      return this.league.state === 'FILLING' || this.league.state === 'FILLED';
    },

    selectEntry(entry) {
      this.selectedEntry = entry;
    },

    getContestLink() {
      const entries = this.myEntries;
      const numEntries = this.league.myEntries;
      if (!numEntries || numEntries === 0) {
        return '/lineup-builder/' + this.league.id;
      } else if (entries && entries.length > 0) {
        return '/salarycontest/leaderboard/' + this.league.id + '/' + entries[0].teamId;
      } else if (numEntries && numEntries > 0) {
        // When the lineup has been entered but the entries information is not available yet
        return '/salarycontest/leaderboard/' + this.league.id + '/' + this.userId;
      }
    },

    getSalaryCapHeightStyle() {
      return 'height: ' + this.getSalaryCapHeight() + 'px;';
    },

    getSalaryCapHeight() {
      if (this.myEntries.length > 5 && this.canEnterLineup()) {
        return 182;
      }

      return 156;
    },

    canEnterLineup() {
      if (this.league.state !== 'FILLING') {
        return false;
      }
      if (this.league.playersCurrent >= this.league.playersMax) {
        return false;
      }
      return !this.league.myEntries >= this.league.entriesPerUser;
    },

    dollarFormat(dollarAmount) {
      const dollarSign = this.league.realMoney ? '$' : '';
      if (!dollarAmount) {
        return dollarSign + '0';
      }
      const d = dollarAmount.toFixed(2);

      return dollarSign + d;
    },

    editLineups() {
      EventBus.$emit('OPEN_SALARY_CAP_EDIT_LINEUP_MODAL', this.league);
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_CONTEST', this.league);
    },

    isPlacing(rank) {
      return rank <= this.league?.numPayouts;
    },
  },
};
</script>

<style lang="scss" scoped>
.sportBox {
  width: 55px;
  min-width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privateIcon {
  color: var(--obcolor-font-secondary);
  text-align: center;
  margin-top: 3px;
}

.detailsContainer {
  min-width: 0;
}

.detailsBox {
  width: 225px;
  min-width: 225px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  border-right: 1px solid var(--obcolor-background-page);
  color: var(--obcolor-font-secondary);
  padding: 8px 0;
}

.shareContest {
  color: var(--obcolor-font-primary);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.detailsBox:hover {
  cursor: pointer;
  background: var(--obcolor-background-4);
}

.detailsHeader {
  font-size: 16px;
  color: var(--obcolor-font-primary);
  max-width: 165px;
  white-space: normal;
}

.matchupBox {
  width: 120px;
  min-width: 120px;
  padding: 0 12px;
  border-right: 1px solid var(--obcolor-background-page);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectContest {
  display: flex;
}

.contestName {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.ownersbucksIcon {
  height: 14px;
  margin-left: 2px;
  margin-right: 5px;
  margin-bottom: -3px;
}

.infoList {
  display: flex;
  margin-top: 5px;
}

.infoBox {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  flex: 1;
  text-align: center;;
}

.infoValue {
  color: var(--obcolor-font-primary);
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
}

.winningsValue {
  color: var(--obcolor-green);
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
}

.leaderboardLink {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

.graphContainer {
  flex: 1;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid var(--obcolor-background-page);
  margin-bottom: -1px;
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green) !important;
  font-weight: bold;
}

.lineupEditorLink {
  color: var(--obcolor-font-primary);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
}

.lineupEditorLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ticketIcon {
  height: 10px;
}

.payzoneContainer {
  position: absolute;
  left: 0;
  right: 253px;
  top: 0;
  background: var(--obcolor-background-5);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
}

</style>
