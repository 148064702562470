<template>
  <tr>
    <td class="playerInfoCol">
      <SalaryPlayerInfo :league="matchup" :entry="entry" :statusMap="statusMap"
        :isMyTeam="isMyTeam" :swapStatus="swapStatus" :showSwap="true"
        :teamId="teamId"
      />
    </td>
    <!-- Salary -->
    <td class="salaryCol">
      <div class="moneyProj">${{ formatMoney(calcProjSpend()) }}</div>
      <div>${{ formatMoney(calcSpent()) }} Spent</div>
    </td>
    <td class="totalVal endSection" style="padding-right: 17px; text-align: right; color: var(--obcolor-ob-blue); font-weight: bold;">
      <div v-if="sumPlayerPoints(entry) >= 0">+{{ sumPlayerPoints(entry) }}</div>
      <div v-else style="color: red;">{{ sumPlayerPoints(entry) }}</div>
      <div style="font-size: 11px; margin-top: 2px; font-weight: normal; color: var(--obcolor-font-secondary);">
        Proj {{ sumPlayerProj(entry) }}
      </div>
    </td>
    <!-- 7 Days -->
    <template v-if="selectedTab == 'schedule' || selectedTab == 'transactions'">
      <td v-for="n in 7" :key="n" class="dayCol"
        :class="{todayHighlight: todaysIndex == n-1}"
      >
        <div class="dayContent" v-if="entry.fspGames" :class="{ dropDay: isDropDay(n), addDay: isAddDay(n) }">
          <div v-if="entry.fspGames[n-1]">
            <SalaryGameEntry :leagueId="leagueId" :player="entry" :teamId="teamId"
              :game="entry.fspGames[n-1]" :sport="sport" :isMyTeam="isMyTeam"
              :overSalaryGames="overSalaryGames" :bestBallGames="bestBallGames"
              :playedGames="playedGames" :allowDeactivate="allowDeactivate()"
            />
          </div>

          <!-- DoubleHeader, typically MLB only -->
          <div v-if="entry.fspGames[(n-1)+'DH']" style="margin-top: 10px;">
            <SalaryGameEntry :leagueId="leagueId" :player="entry" :teamId="teamId"
              :game="entry.fspGames[(n-1)+'DH']" :sport="sport" :isMyTeam="isMyTeam"
              :overSalaryGames="overSalaryGames" :bestBallGames="bestBallGames"
              :playedGames="playedGames" :allowDeactivate="allowDeactivate()"
            />
          </div>
        </div>
      </td>
    </template>
    <!-- Stats based on sport -->
    <template v-else-if="rosterScoringStats">
      <template v-for="(category, index) in rosterScoringStats">
        <td class="statsCol" v-for="(stat, index1) in category.stats"
          :key="index + '_' + index1" :class="{endSection: index1 == category.stats.length - 1}"
        >
          <div style="font-size: 14px;">
            <!-- Total stat for the week -->
            <span>{{ getStat(stat.path) || '-' }}</span>
          </div>
        </td>
      </template>
    </template>

    <!-- End spacing for game opps -->
    <td v-if="entry.dropped && selectedTab != 'transactions'" style="width: 30px;"></td>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import SalaryPlayerInfo from './SalaryPlayerInfo';
import EventBus from '@/event-bus';
import SalaryGameEntry from './SalaryGameEntry';

export default {
  props: {
    entry: Object,
    todaysIndex: Number,
    leagueId: String,
    isMyTeam: Boolean,
    sport: String,
    playedGames: Object,
    overSalaryGames: Object,
    bestBallGames: Object,
    matchup: Object,
    swapStatus: String,
    selectedTab: String,
    selectedFilterIndex: Number,
    statusMap: Object,
    transactionType: String,
    transactionTs: Number,
    teamId: String,
  },

  components: {
    SalaryPlayerInfo,
    SalaryGameEntry,
  },

  computed: {
    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.sport);
    },

    rosterScoringStats() {
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return [];
    },
  },

  methods: {

    formatMoney(value) {
      return Intl.NumberFormat('en-US').format(value);
    },

    allowDeactivate() {
      if (!this.entry) {
        return false;
      }
      // This shouldn't happen, just a safeguard
      if (this.entry.projGP == undefined) {
        return false;
      }
      return this.getGpAndProj() > this.entry.projGP;
    },

    // Used to determine if you can deactivate
    // Only triggers if your projected + played games is more than originally projected
    // * Does not factor out deactivated games *
    // * This is because the deactivate buttons need to still appear after you bench a player
    // * In case you decide to un-bench him
    getGpAndProj() {
      if (!this.entry) {
        return 0;
      }
      const now = new Date().getTime();
      const games = this.entry.fspGames;
      const deactGames = this.entry.deactGames;
      let gp = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const ts = parseInt(gJSON.timestamp);
        const proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : 0;
        const isActive = !deactGames[gJSON.id];
        // Played
        if (isActive && gJSON.inRange && gJSON.played) {
          gp++;
        } else if (gJSON.inRange && ts > now && proj > 0) {
          // Projected to play
          gp++;
        }
      }
      return gp;
    },

    calcSpent() {
      if (!this.entry) {
        return 0;
      }
      const games = this.entry.fspGames;
      const deactGames = this.entry.deactGames;
      let gp = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const isActive = !deactGames[gJSON.id];
        if (isActive && gJSON.inRange && gJSON.played && !gJSON.partial) {
          gp++;
        }
      }
      return this.entry.salaryPG * gp;
    },

    calcProjSpend() {
      if (!this.entry) {
        return 0;
      }
      const now = new Date().getTime();
      const games = this.entry.fspGames;
      const deactGames = this.entry.deactGames;
      let gp = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const ts = parseInt(gJSON.timestamp);
        const proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : 0;
        const isActive = !deactGames[gJSON.id];
        if (isActive && gJSON.inRange && ts > now && proj > 0) {
          gp++;
        }
      }
      return this.entry.salaryPG * gp;
    },

    getStat(statPath) {
      if (!this.entry || !statPath) {
        return '-';
      }

      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);
      const games = this.entry.fspGames;
      let total = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = this.entry.id + '_' + gJSON.id;

        if (gJSON.stats && gJSON.stats[playerRole] && gJSON.stats[playerRole][statType]) {
          const stat = gJSON.stats[playerRole][statType];
          if (this.playedGames[gameKey] && this.entry.ObTotals[playerRole] &&
            stat !== null && stat !== undefined
          ) {
            total += parseInt(stat);
          }
        }
      }
      return total;
    },

    getStatPoints(statPath) {
      if (!this.entry || !this.entry.ObTotals || !statPath) {
        return '-';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!this.entry.ObTotals[playerRole]) {
        return '0';
      }
      const stat = this.entry.ObTotals[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    openPlayerModal() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.entry.id, this.sport, this.leagueId, true);
    },

    sumPlayerPoints(player) {
      if (!player) {
        return 0;
      }
      const games = player.fspGames;
      let totalPoints = new Decimal(0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        if (this.playedGames[gameKey]) {
          totalPoints = totalPoints.plus(new Decimal(gJSON.ObPoints.total));
        }
      }
      return Number(totalPoints);
    },

    sumPlayerProj(player) {
      if (!player) {
        return 0;
      }
      const games = player.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        if (gJSON.inRange && gJSON.ProjPoints != null) {
          totalPoints += gJSON.ProjPoints.total;
        }
      }
      return totalPoints.toFixed(0);
    },

    // Transactions view only
    // Determines whether or not the field at a specified day index should have a red outline
    isDropDay(index) {
      if (this.transactionType == null || this.transactionTs == null || this.matchup.days[index - 1] == null) {
        return;
      }
      const dayName = this.matchup.days[index - 1];
      const transactionDayName = this.$moment(this.transactionTs).format('YYYY-MM-DD');
      return dayName == transactionDayName && this.transactionType == 'drop';
    },

    // Transactions view only
    // Determines whether or not the field at a specified day index should have a green outline
    isAddDay(index) {
      if (this.transactionType == null || this.transactionTs == null || this.matchup.days[index - 1] == null) {
        return;
      }
      const dayName = this.matchup.days[index - 1];
      const transactionDayName = this.$moment(this.transactionTs).format('YYYY-MM-DD');
      return dayName == transactionDayName && this.transactionType == 'add';
    },
  },
};
</script>

<style lang="scss" scoped>
.playerInfoCol {
  width: 240px;
}

.salaryCol {
  width: 121px;
}

.salaryCol div:not(:last-child) {
  margin-bottom: 4px;
}

.salaryCol :not(.moneyProj) {
  font-size: 11px;
}

.moneyProj {
  font-size: 14px;
  font-weight: bold;
  color: var(--obcolor-green);
}

td.dayCol {
  box-sizing: border-box;
  min-width: 120px;
  height: 100%;
  padding: 4px;
}

.dayContent {
  height: 100%;
  width: 100%;
}

.statsCol {
  height: 76px;
  min-width: 80px;
}

tr {
  height: 76px;
}

td, th {
  padding: 8px 0;
  white-space: nowrap;
  box-sizing: border-box;
}
.todayHighlight {
  background: var(--obcolor-background-3);
}

.totalVal {
  width: 60px;
  font-size: 14px;
}
.totalVal > div {
  min-width: 48px;
}
.posGroupEmpty {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

.endSection {
  border-right: 2px solid var(--obcolor-background-3);
}

.dropDay {
  border: 2px solid red;
}

.addDay {
  border: 2px solid var(--obcolor-green);
}
</style>