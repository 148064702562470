<template>
  <div class="container" v-if="league">
    <!-- Contest Overview -->
    <div class="ob-box">
      <div style="display: flex; align-items: center;">
        <img class="sportIcon" :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="`${league.sport} Icon`">
        <div class="contestName">{{ league.contestName }}</div>
        <div v-if="league.state === 'CANCELLED'" class="contestName" style="margin-left: 5px; color: red;">(Canceled)</div>
        <div class="ob-hyperlink" style="margin-left: 10px; color: var(--obcolor-font-secondary);" @click="openLeagueModal()">Contest Info</div>
      </div>

      <div style="display: flex; margin-top: 4px; margin-left: 37px; color: var(--obcolor-font-secondary);">
        <div style="margin-right: 25px;">
          <div class="infoValue" :class="league.realMoney ? 'moneyText' : 'blueText'">
            <img v-if="!league.realMoney" style="height: 10px;" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            {{ league.fee.removeCentsIfZero() }}
          </div>
          <div>ENTRY</div>
        </div>
        <div style="margin-right: 25px;">
          <div class="infoValue" :class="league.realMoney ? 'moneyText' : 'blueText'">
            <img v-if="!league.realMoney" style="height: 10px;" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            {{ league.prizePool.removeCentsIfZero() }}
          </div>
          <div>PRIZES</div>
        </div>
        <div style="margin-right: 25px;">
          <div class="infoValue" v-if="isPreContest()">{{ league.playersCurrent }} / {{ league.playersMax }}</div>
          <div class="infoValue" v-else>{{ league.playersCurrent }}</div>
          <div>ENTRANTS</div>
        </div>
        <div>
          <div class="infoValue">{{ getContestDuration() }}</div>
          <div>CONTEST DURATION</div>
        </div>
      </div>

      <!-- Payout Bar -->
      <div style="margin-top: 6px; height: 20px; margin-left: 36px;">
        <div class="payoutBar" style="position: relative;">
          <div class="payoutZone" :style="getPayoutZoneWidth()"></div>
        </div>
        <div class="myEntries" v-if="!isDaily">
          <div v-for="entry in myTeams" :key="entry.teamId" class="entryCircle"
            @click="changeTeam(entry.teamId)"
            :class="isSelected(entry) ? 'entryCircleHl' : ''" :style="getRankPct(entry)"
          >
            <div class="entryTriangle"></div>
            <div>{{ entry.teamNum }}</div>
          </div>
        </div>
        <div class="myEntries" v-else>
          <div v-for="entry in myTeams" :key="entry.teamId" class="entryImgCircle"
            @click="changeTeam(entry.teamId)"
            :class="isSelected(entry) ? 'entryCircleHl' : ''" :style="getRankPct(entry)"
          >
            <div class="entryTriangle"></div>
            <div class="entryImg">
              <UserLogo :id="entry.userId" :sport="league.sport" :size="28"
                :showRank="false" :rankColor="entry.rankColor" :rankNum="entry.rankNum"
              />
            </div>
          </div>
          <div v-if="oppTeam != null" class="entryImgCircle entryCircleHl noSelect" :style="getRankPct(oppTeam)">
            <div class="entryTriangle"></div>
            <div class="entryImg">
              <UserLogo :id="oppTeam.userId" :sport="league.sport" :size="28"
                :showRank="false" :rankColor="oppTeam.rankColor" :rankNum="oppTeam.rankNum"
              />
            </div>
          </div>
        </div>
      </div>

      <template v-if="roster">
        <div style="margin-top: 27px; height: 20px; margin-left: 38px; margin-right: 18px;">
          <div class="entryInfo">
            <div>My Entries <span>{{ myTeams.length }}</span></div>
            <div>Placing <span>{{ getNumPlacing() }}</span></div>
            <div>
              <span>Winning</span>
              <span :class="league.realMoney ? 'moneyText' : 'blueText'">
                <img v-if="!league.realMoney" style="height: 9px; margin-left: 2px;" :src="require('@/assets/icons/ownersbucks.png')"
                  alt="OwnersBucks Icon"
                >
                {{ getMyWinnings().removeCentsIfZero() }}
              </span>
            </div>
          </div>
        </div>

        <div class="navbar">
          <div :class="getTabClass('leaderboard')" @click="selectTab('leaderboard')" style="flex: 1;">Live</div>
          <div v-if="!isDaily" :class="getTabClass('lineup')" @click="selectTab('lineup')"
            style="flex: 1;"
          >
            Schedule
          </div>
          <div v-if="isPreContest()" class="ob-btn-grey" @click="openLineupBuilder()"
            style="flex: 1;"
          >
            Lineup Editor
          </div>
          <div v-else :class="getTabClass('editor')" @click="selectTab('editor')"
            style="flex: 1;"
          >
            Lineup Editor
          </div>
        </div>
      </template>
    </div>

    <!-- Team Selection -->
    <div class="ob-box" style="display: flex;">
      <div :class="hasPrevPage() ? 'ob-btn' : 'ob-btn-grey btnDisabled'" class="myTeamsBtn" @click="prevPage()">
        <i class="fas fa-angle-left"></i>
      </div>
      <table class="myTeamsTable" style="flex: 1; text-align: center; margin-top: -6px;" aria-label="Team Selection">
        <tr>
          <th :class="isSortedColumn('entry')" style="width: 45px;" @click="sortMyTeams('entry')">Entry</th>
          <th :class="isSortedColumn('rank')" style="width: 75px;" @click="sortMyTeams('rank')">Rank</th>
          <th :class="isSortedColumn('winning')" @click="sortMyTeams('winning')">Winning</th>
          <th :class="isSortedColumn('proj')" style="width: 65px;" @click="sortMyTeams('proj')">Proj</th>
          <th :class="isSortedColumn('score')" style="width: 65px;" @click="sortMyTeams('score')">Score</th>
          <th :class="isSortedColumn('games')" style="width: 80px;" @click="sortMyTeams('games')">Games</th>
        </tr>
        <tr v-for="entry in getMyTeams()" :key="entry.teamId" :class="isSelected(entry) ? 'selectedRow' : ''"
          @click="changeTeam(entry.teamId)"
        >
          <td :class="isSortedColumn('entry')">{{ entry.teamNum }}</td>
          <td :class="isSortedColumn('rank')">{{ String(entry.rank).addSuffix() }}</td>
          <td :class="isSortedColumn('winning')">
            <span :class="{ moneyText: leagueData.realMoney == true && entry.prizeValue !== 0, blueText: leagueData.realMoney == false && entry.prizeValue !== 0 }">
              <img v-if="!leagueData.realMoney" style="height: 8px;" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              {{ entry.prize ? entry.prize.removeCentsIfZero() : '$0' }}
            </span>
          </td>
          <td :class="isSortedColumn('proj')" style="color: var(--obcolor-font-secondary;">{{ entry.proj }}</td>
          <td :class="isSortedColumn('score')" style="color: var(--obcolor-ob-blue);">{{ entry.score }}</td>
          <td :class="isSortedColumn('games')">{{ entry.gp }} / {{ maxGames }}</td>
        </tr>
      </table>
      <div :class="hasNextPage() ? 'ob-btn' : 'ob-btn-grey btnDisabled'" class="myTeamsBtn" @click="nextPage()">
        <i class="fas fa-angle-right"></i>
      </div>
    </div>

    <!-- Salary Info -->
    <div class="ob-box" style="max-width: 379px;">
      <template v-if="roster">
        <div style="display: flex; margin-left: 3px; margin-right: 3px; justify-content: space-between; font-size: 14px;">
          <div>
            <div class="salaryLabel">Committed Salary</div>
            <div class="salaryValue">{{ (rosterCalc.salaryCommitted + rosterCalc.salarySpent + roster.swapFees).formatMoney() }}</div>
            <div style="font-size: 12px; margin-top: 7px;">
              <span>{{ (rosterCalc.salarySpent + roster.swapFees).formatMoney() }}</span>
              <span style="color: var(--obcolor-font-secondary); margin-left: 4px;">Spent</span>
            </div>
          </div>
          <div style="color: red;">
            <div class="salaryLabel">Remaining Salary</div>
            <div :class="{money: rosterCalc.salaryRemaining >= 0}" class="salaryValue">
              {{ rosterCalc.salaryRemaining.formatMoney() }}
            </div>
          </div>
          <div>
            <div class="salaryLabel">Score</div>
            <div class="salaryValue" style="color: var(--obcolor-ob-blue);">{{ roster.score || 0 }}</div>
          </div>
        </div>

        <!-- Positions -->
        <div style="display: flex; justify-content: space-around; margin-top: 10px; margin-bottom: 12px;">
          <div v-for="(entry, index) in rosterLimits" :key="index"
            style="flex: 1; text-align: center; margin-left: 2px; margin-right: 2px; max-width: 115px; height: 64px;"
            :style="'border: 1px solid '+ getGameLimitColor(entry.short)"
          >
            <div :style="getGameLimitHeaderStyle(entry.short)" style="padding: 2px; font-weight: bold; font-size: 14px;">
              {{ entry.short }} {{ formatChange(gpPastBestBall(entry.short)) }}
            </div>
            <div style="padding: 4px;">
              <div style="margin-bottom: 3px; font-size: 16px;">
                <span :style="'color: ' + getGameLimitColor(entry.short, true)">{{ getGamesRemaining(entry.short) }}</span>
                <span> / </span>
                <span>{{ getMaxGamesRemaining(entry.short, entry.maxGames) }}</span>
              </div>
              <div style="color: var(--obcolor-font-secondary); font-weight: normal; font-size: 12px;">
                {{ rosterCalc.salaryByPos[entry.short] ? rosterCalc.salaryByPos[entry.short].formatMoney() : '$0' }}
              </div>
            </div>
          </div>
        </div>

        <!-- Game Limit Warnings -->
        <div style="display: flex;">
          <div v-if="showUnderGames" style="flex: 1; color: red; margin-left: 3px; font-size: 14px; display: flex;">
            <span style="white-space: nowrap;">Unused Game Opportunities</span>
            <Tooltip pos="bottom" textKey="SalaryCap_UnusedOpps" />
          </div>

          <div v-if="showBestBall" style="color: orange; margin-left: 3px; font-size: 14px; display: flex;">
            <span style="white-space: nowrap;">Best Ball Activated</span>
            <Tooltip pos="bottom" textKey="SalaryCap_BestBall" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';
import UserLogo from '@/components/UserLogo';

export default {

  components: {
    Tooltip,
    UserLogo,
  },

  props: {
    leagueData: Object,
    rosterData: Object,
    rosterCalc: Object,
    myTeams: Array,
    teamId: String,
    oppTeamId: String,
    tab: String,
  },

  data: function() {
    return {
      league: null,
      roster: null,
      myTeamsPage: 0,
      myTeamsSort: 'entry',
      teamsPerPage: 5,
      showBestBall: false,
      showUnderGames: false,
    };
  },

  watch: {
    leagueData(to, from) {
      if (to) {
        this.league = to;
      }
    },
    rosterData(to, from) {
      if (to) {
        this.roster = to;
        this.setupGameLimits();
      }
    },
    rosterCalc(to, from) {
      this.setupGameLimits();
    },
  },

  created() {
    if (this.leagueData) {
      this.league = this.leagueData;
    }
    if (this.rosterData) {
      this.roster = this.rosterData;
    }

    this.setupGameLimits();
    EventBus.$on('SALARY_HEADER_SELECT_TAB', this.selectTab);
  },

  destroyed() {
    EventBus.$off('SALARY_HEADER_SELECT_TAB', this.selectTab);
  },

  computed: {
    myTeamsPages() {
      return Math.ceil(this.myTeams.length / this.teamsPerPage);
    },

    maxGames() {
      if (!this.league || !this.league.gameLimits) {
        return 0;
      }
      return this.league.gameLimits.total;
    },

    rosterLimits() {
      if (!this.league || !this.league.draftRoster || !this.league.draftRoster.roster) {
        return null;
      }
      return this.league.draftRoster.roster;
    },

    isDaily() {
      return this.league.format == 'DAILY';
    },

    oppTeam() {
      if (this.leagueData) {
        return this.leagueData.leaderboard.find((team) => {
          return team.teamId == this.oppTeamId;
        });
      }

      return null;
    },
  },

  methods: {
    gpPastBestBall(pos) {
      if (!this.rosterCalc || !this.rosterCalc.gpWithBB || !this.rosterCalc.gpWithBB[pos] ||
        !this.rosterCalc.gpByPos || !this.rosterCalc.gpByPos[pos]
      ) {
        return null;
      }

      const totGames = this.rosterCalc.gpWithBB[pos] - this.rosterCalc.gpByPos[pos];

      if (totGames <= 0) {
        return null;
      }

      return totGames;
    },

    getGamesRemaining(pos) {
      const gamesProj = this.rosterCalc.projGames[pos];
      const gamesPlayed = this.rosterCalc.gpByPos[pos] || 0;
      const bestBallGp = this.gpPastBestBall(pos) || 0;
      return gamesProj - gamesPlayed - bestBallGp;
    },

    getMaxGamesRemaining(pos, maxGames) {
      const gamesPlayed = this.rosterCalc.gpByPos[pos] || 0;
      const maxGamesRem = maxGames - gamesPlayed;
      return maxGamesRem < 0 ? 0 : maxGamesRem;
    },

    isPreContest() {
      if (!this.leagueData) {
        return false;
      }
      if (this.leagueData.contestStart < new Date().getTime()) {
        return false;
      }
      return this.leagueData.state === 'FILLING' || this.leagueData.state === 'FILLED';
    },

    openLineupBuilder() {
      this.$router.push('/lineup-builder/' + this.league.id + '/' + this.teamId);
    },

    openLeagueModal() {
      EventBus.$emit('OPEN_LEAGUE_MODAL', this.league);
    },

    sortMyTeams(sort) {
      if (this.myTeamsSort === sort) {
        this.myTeamsSort = '-' + sort;
      } else {
        this.myTeamsSort = sort;
      }
    },

    isSortedColumn(sort) {
      if (sort === this.myTeamsSort.replace('-', '')) {
        return 'sortedColumn';
      }
      return '';
    },

    getMyTeams() {
      if (!this.myTeams) {
        return [];
      }

      let sort = this.myTeamsSort;
      let reverse = false;
      if (sort.includes('-')) {
        sort = sort.replace('-', '');
        reverse = true;
      }

      const sorted = [...this.myTeams].sort((a, b) => {
        switch (sort) {
          case 'entry': return reverse ? b.teamNum - a.teamNum : a.teamNum - b.teamNum;
          case 'rank': return reverse ? b.rank - a.rank : a.rank - b.rank;
          case 'winning': return reverse ? a.prizeValue - b.prizeValue : b.prizeValue - a.prizeValue;
          case 'proj': return reverse ? a.proj - b.proj : b.proj - a.proj;
          case 'score': return reverse ? a.score - b.score : b.score - a.score;
          case 'games': return reverse ? b.gp - a.gp : a.gp - b.gp;
        }
        return a.teamNum - b.teamNum;
      });

      const p = this.myTeamsPage;
      const n = this.teamsPerPage;
      return sorted.slice((p * n), (p + 1) * n);
    },

    hasPrevPage() {
      return this.myTeamsPage > 0;
    },
    prevPage() {
      if (this.myTeamsPage > 0) {
        this.myTeamsPage -= 1;
      }
    },

    hasNextPage() {
      return this.myTeamsPage < this.myTeamsPages - 1;
    },
    nextPage() {
      if (this.myTeamsPage < this.myTeamsPages - 1) {
        this.myTeamsPage += 1;
      }
    },

    getTabClass(name) {
      if (this.tab === name) {
        return 'ob-btn';
      }
      return 'ob-btn-grey';
    },

    selectTab(name) {
      const teamId = this.teamId;
      this.$router.push('/salarycontest/' + name + '/' + this.league.id + '/' + teamId);
    },

    getPayoutZoneWidth(entry) {
      let pct = this.getNumWinners() / this.league.playersCurrent * 100;
      if (pct >= 100) {
        pct = 100;
      }
      return 'width: ' + pct + '%;' + (pct >= 100 ? ' border-radius: 6px;' : '');
    },

    getNumWinners() {
      let total = 0;
      for (const entry of this.league.payouts) {
        total += entry.payouts;
      }
      return total;
    },

    getRankPct(entry) {
      const pct = (entry.rank - 1) / this.league.playersCurrent * 100;
      return 'right: calc(' + pct + '% - 8px);';
    },

    isSelected(entry) {
      return entry.teamId === this.teamId;
    },

    changeTeam(teamId) {
      this.$router.push('/salarycontest/' + this.tab + '/' + this.league.id + '/' + teamId);
    },

    getContestDuration() {
      if (!this.league || !this.league.days) {
        return '-';
      }
      const lastDay = this.league.days.length - 1;
      const start = this.$moment(this.league.days[0], 'YYYY-MM-DD').format('MMM D');
      const end = this.$moment(this.league.days[lastDay], 'YYYY-MM-DD').format('MMM D');
      return start + ' - ' + end;
    },

    // May change this to a server side calculation, only recalculated on score updates
    getMinScoreForPayout() {
      if (!this.league || !this.league.leaderboard) {
        return '';
      }
      let score = null;
      for (const entry of this.league.leaderboard) {
        if (entry.prizeValue && entry.prizeValue > 0) {
          score = entry.score;
        } else {
          return score;
        }
      }
      return score || '';
    },

    getMyWinnings() {
      const dollarSign = this.league.realMoney ? '$' : '';
      if (!this.myTeams) {
        return dollarSign + '0';
      }
      let total = 0;
      for (const team of this.myTeams) {
        total += team.prizeValue || 0;
      }
      return dollarSign + (total / 100).toFixed(2);
    },

    getNumPlacing() {
      if (!this.myTeams) {
        return '0';
      }
      let total = 0;
      for (const team of this.myTeams) {
        if (team.prizeValue && team.prizeValue > 0) {
          total += 1;
        }
      }
      return total;
    },

    setupGameLimits() {
      if (!this.rosterLimits) {
        return;
      }

      this.showBestBall = false;
      this.showUnderGames = false;

      for (const entry of this.rosterLimits) {
        const gp = this.rosterCalc.projGames[entry.short];
        const maxGames = this.rosterCalc.gameLimits[entry.short];
        if (gp > maxGames) {
          this.showBestBall = true;
        }
        if (gp < maxGames) {
          this.showUnderGames = true;
        }
      }
    },

    getGameLimitHeaderStyle(pos) {
      const numGames = this.rosterCalc.projGames[pos];
      const maxGames = this.rosterCalc.gameLimits[pos];
      if (numGames > maxGames) {
        return 'background: orange; color: black;';
      }
      if (numGames < maxGames) {
        return 'background: var(--obcolor-red); color: white;';
      }
      return 'background: var(--obcolor-background-2); color: var(--obcolor-font-secondary);';
    },

    getGameLimitColor(pos, text = false) {
      const numGames = this.rosterCalc.projGames[pos];
      const maxGames = this.rosterCalc.gameLimits[pos];
      if (numGames > maxGames) {
        return 'orange';
      }
      if (numGames < maxGames) {
        return 'var(--obcolor-red);';
      }
      return text ? 'var(--obcolor-font-primary)' : 'var(--obcolor-background-2)';
    },

    formatChange(n) {
      if (n == null) {
        return '';
      }
      if (n >= 0) {
        return '+ ' + n;
      }
      return String(n).replace('-', '- ');
    },
  },
};
</script>

<style lang="scss" scoped>

.container {
  min-height: 52px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 8px;

  .ob-box {
    flex: 1;
    min-height: 163px;
  }
  .ob-box:not(:first-child) {
    margin-left: 8px;
  }
}

.navbar {
  display: flex;
  div {
    border-radius: 0;
  }
  div:not(:first-child) {
    margin-left: 5px;
  }
}

.sportIcon {
  height: 26px;
  margin-right: 8px;
}

.contestName {
  font-size: 16px;
  font-weight: bold;
  padding-left: 2px;
}

.minScore {
  flex: 1;
  text-align: right;
  margin-right: 4px;
  font-weight: bold;
}

.infoText {
  margin-right: 35px;
}
.infoValue {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  color: var(--obcolor-font-primary);
}
.money {
  color: var(--obcolor-green) !important;
}

.payoutBar {
  margin-top: 3px;
  height: 10px;
  width: calc(100% - 20px);
  border-radius: 6px;
  background: var(--obcolor-background-2);
  // overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.payoutZone {
  background: var(--obcolor-green);
  height: 100%;
  width: 25%;
  border-radius: 0 6px 6px 0;
  position: absolute;
  right: 0;
}

.myEntries {
  width: calc(100% - 20px);
  height: 17px;
  position: relative;
}
.entryCircle.entryCircleHl {
  background: var(--obcolor-ob-blue) !important;
  z-index: 2;
  .entryTriangle {
    border-bottom: 6px solid var(--obcolor-ob-blue) !important;
  }
}
.entryCircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  position: absolute;
  background: #474F6D;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
  transition-property: right;
  transition-duration: 600ms;
}

.entryImgCircle.entryCircleHl {
  background: var(--obcolor-ob-blue) !important;
  z-index: 2;
  .entryTriangle {
    border-bottom: 6px solid var(--obcolor-ob-blue) !important;
  }
}
.entryImgCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  position: absolute;
  background: #474F6D;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  color: white;
  transition-property: right;
  transition-duration: 600ms;

  .entryImg {
    margin-top: 8px;
  }
}

.entryImgCircle:not(.noSelect) {
  cursor: pointer;
  &:not(.entryCircleHl) {
    filter: brightness(50%);
  }
  &:hover {
    filter: brightness(100%);
  }
}

.entryTriangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #474F6D;
  position: absolute;
  top: -4px;
}

.entryCircle:hover {
  background: var(--obcolor-background-3);
  z-index: 60;
  .entryTriangle {
    border-bottom: 6px solid var(--obcolor-background-3);
  }
}

.entryInfo {
  display: flex;
  justify-content: space-between;
  color: var(--obcolor-font-secondary);
  span {
    color: var(--obcolor-font-primary);
    font-weight: bold;
    margin-left: 2px;
  }
}

.myTeamsBtn {
  padding: 0;
  width: 20px;
  margin-top: 20px;
  height: 143px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.btnDisabled {
  color: var(--obcolor-font-secondary);
  cursor: default;
  background: var(--obcolor-background-2) !important;
}

.myTeamsTable {
  margin-left: 3px;
  margin-right: 3px;
  width: calc(100% - 40px);
  height: max-content;

  .sortedColumn:not(th) {
    background: var(--obcolor-background-4);
  }
  .sortedColumn:not(td) {
    color: var(--obcolor-font-primary);
  }

  .selectedRow {
    td:first-child {
      background: var(--obcolor-ob-blue);
      color: white;
    }
    td {
      background: var(--obcolor-background-2);
    }
  }
  th {
    height: 24px;
    color: var(--obcolor-font-secondary);
    cursor: pointer;
  }
  th:hover {
    color: var(--obcolor-font-primary);
  }
  td {
    cursor: pointer;
    height: 25px;
    background: var(--obcolor-background-3);
  }
  tr:not(:last-child) {
    td {
      border-bottom: 2px solid var(--obcolor-background-5);
    }
  }
  tr:hover {
    td {
      background: var(--obcolor-background-2);
    }
  }
}

.salaryLabel {
  text-transform: uppercase;
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: bold;
}
.salaryValue {
  font-size: 16px;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}

.moneyText {
  color: var(--obcolor-green) !important;
}
</style>