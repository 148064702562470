<template>
  <div>
    <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; max-height: 100vh; padding: 0; overflow: hidden;"
      :hideCloseButton="true" :disableClickaway="true" data-testid="entry-placed-modal"
    >
      <!-- Contest Entry Info -->
      <div slot="header" class="modalHeader">
        <div class="headerContainer" v-if="selectedMarkets != null">
          <div class="headerTextContainer">
            <div class="headerText">Entry Placed!</div>
            <div class="headerSubText" :class="isRealMoney ? 'realMoneyContest' : 'ownersbucksContest'">
              Entry placed at
              <span v-if="isRealMoney" class="bold">$</span>
              <img v-else :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                alt="Ownersbucks"
              >
              <span class="bold">{{ entryValueDollars }}</span>
              to win
              <span v-if="isRealMoney" class="moneyText">$</span>
              <img v-else :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
                alt="Ownersbucks"
              >
              <span class="moneyText">{{ toWinValue }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Lineups -->
      <div slot="body">
        <div class="lineupsContainer">
          <ContentCards locationId="player-picks-submit" />
          <div class="lineupSection">
            <div class="lineupContent">
              <div class="lineupEntry">
                <PropsSlipTile :market="market" :key="marketKey(market)"
                  :submitted="submitted" hideWarnings
                  v-for="market in selectedMarkets"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Buttons -->
      <div slot="footer" class="modalFooter">
        <div class="navBtnContainer">
          <button class="navBtn ob-btn-grey marginRight" @click="goToDashboard()">View Upcoming</button>
          <button class="navBtn ob-btn" @click="newEntry()">New Entry</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import PropsSlipTile from './PropsSlipTile.vue';
import ContentCards from '@/components/ContentCards.vue';

export default {
  components: {
    Modal,
    PropsSlipTile,
    ContentCards,
  },

  data() {
    return {
      selectedMarkets: null,
      submitData: null,
      numSelectdPlayers: null,
      isRealMoney: null,
      entryValueDollars: null,
      toWinValue: null,
      sport: null,
      isOpen: false,
      submitted: true,
    };
  },

  created() {
    EventBus.$on('PROPS_OPEN_SUBMIT_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('PROPS_OPEN_SUBMIT_MODAL', this.openModal);
  },

  methods: {
    openModal(params) {
      this.selectedMarkets = params.selectedMarkets;
      this.submitData = params.submitData;
      this.numSelectdPlayers = params.numSelectdPlayers;
      this.isRealMoney = params.isRealMoney;
      this.entryValueDollars = params.entryValueDollars;
      this.toWinValue = params.toWinValue;
      this.sport = params.sport;

      this.isOpen = true;
    },

    newEntry() {
      EventBus.$emit('PROPS_RESET_SLIP');
      EventBus.$emit('PROPS_RESET_ENTRY_DATA');
      this.closeModal();
    },

    goToDashboard() {
      this.$router.push('/player-picks/upcoming');
      this.closeModal();
    },

    closeModal() {
      this.isOpen = false;
    },

    marketKey(market) {
      return `${market.id}|${market.discount?.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
}

.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

// Header section
.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  flex: 1;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .headerText {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .headerSubText {
    font-size: 16px;
    text-align: center;
    max-width: 350px;

    .ownersbucksIcon {
      height: 10px;
      margin-right: 4px;
    }
  }

  .moneyText {
    font-weight: bold;
  }

  .realMoneyContest .moneyText {
    color: var(--obcolor-green) !important;
  }

  .ownersbucksContest .moneyText {
    color: var(--obcolor-ob-blue) !important;
  }
}

// Body section
.lineupsContainer {
  background: var(--obcolor-background-page);
  padding: 10px 10px 5px 10px;
}

.lineupSection {
  background: var(--obcolor-background-4);
  margin-bottom: 5px;

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
  }

  .titleText {
    font-weight: bold;
  }

  .lineupContent {
    background: var(--obcolor-bg);
    padding: 5px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lineupEntry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    gap: 8px;
  }

  .playerSeparator {
    align-self: stretch;
    width: 1px;
    background: var(--obcolor-background-4);
  }
}

.navBtnContainer {
  display: flex;
  width: 100%;

  .navBtn {
    flex: 1;
    height: 36px;
    font-size: 16px;
  }
}

.marginRight {
  margin-right: 10px;
}

.bold {
  font-weight: 700;
}

</style>