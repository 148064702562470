<template>
  <div class="container">
    <div class="arrowContainer">
      <!-- No Arrows - Keep container for consistent spacing -->
    </div>
    <h1>Great Job!</h1>
    <p v-if="signupData.referralCode">Great job! Your account is set up. You entered a valid code which has been applied to your account. Welcome to OwnersBox!</p>
    <p v-else>Your account is set up. Welcome to OwnersBox.</p>
    <img :src="require('@/assets/onboarding/claps.gif')" alt="Animated GIF">
    <button class="ob-btn" @click="startVerification()">Verify Your Account</button>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    signupData: {type: Object, default: null},
  },

  methods: {
    startVerification() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  h1,
  p {
    text-align: center;
  }

  button {
    margin-top: 50px;
    height: 50px;
  }

  img {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    padding: 50px 0;
  }
}
</style>