<template>
  <div class="ob-page" style="display: flex;">
    <div style="flex: 1 1 0%; min-width: 1000px; max-width: 1070px;">
      <div v-if="leagues == null || featured == null" style="flex: 1;">
        <ObLoading />
      </div>
      <template v-else>
        <div class="liveContestHeaderContainer">
          <LiveContestHeader class="liveContestHeader" label="Salary Cap Contests" :contests="liveLeagues"
            :contestState="contestState"
          />
        </div>
        <div v-if="liveLeagues.length > 0">
          <template v-for="league in liveLeagues">
            <LiveSalaryContest v-if="league.ctype === 'Tournament'" :key="league.id"
              class="liveSalaryContestContainer"
              :style="getContestHighlight(league.id)"
              :league="league" :userId="userId"
              :teamData="teamData"
            />

            <LiveContest v-else :ref="'liveLeague_' + league.id" class="liveContestContainer"
              :style="getContestHighlight(league.id)"
              :selectedDay="selectedDay" :startingDay="startingDay" :league="league"
              :key="league.id" :scoreUpdated="scoreUpdated"
              :gamesData="liveLeagueWeekData && liveLeagueWeekData[league.id] ? liveLeagueWeekData[league.id].gamesData : null"
              :gameFinishedStatus="gameFinishedStatus"
            />
          </template>
        </div>

        <div v-else>
          <div class="liveContestContainer noContests">
            You have no {{ contestState }} salary cap contests
          </div>
        </div>
      </template>
    </div>

    <FeedSection />
    <EditLineupCSVModal />
    <MultipleLineupEditedModal />
    <ContestWonModal :completedContests="completedLeagues" />
  </div>
</template>

<script>
import ObLeagueApi from '@/api/ObLeagueApi';
import LiveContestHeader from './LiveContests/LiveContestHeader';
import LiveSalaryContest from './LiveContests/LiveSalaryContest';
import LiveContest from './LiveContests/LiveContest';
import EditLineupCSVModal from '@/views/SalaryCapGame/EditLineupCSVModal/EditLineupCSVModal';
import ContestWonModal from '@/components/modals/ContestWonModal/ContestWonModal';
import MultipleLineupEditedModal from '@/views/SalaryCapGame/ConfirmModal/MultipleLineupEditedModal';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';
import FeedSection from '@/components/FeedSection';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';

export default {
  components: {
    LiveSalaryContest,
    LiveContest,
    ContestWonModal,
    LiveContestHeader,
    MultipleLineupEditedModal,
    ObLoading,
    EditLineupCSVModal,
    FeedSection,
  },

  props: {
    contestState: {type: String, default: null},
  },

  data() {
    return {
      leagues: null,
      featured: null,
      userId: '',
      days: [],
      selectedDayStart: 0,
      firstLoad: true,
      highlightedLeagueId: null,
      scoreUpdated: {},
      teamData: {},
      completedFilters: {leagueCount: '5', month: this.$moment().month(), year: this.$moment().year(), leagueType: 'all'},
      completedLeagues: [],
      salaryCapUpdateInterval: 10000,
      scheduledSalaryCapUpdates: {},
      socketRooms: {},
      lastCheckTime: null,
      lastCheckedInterval: null,

      liveLeagueWeekData: {},
      // possible values: { isOnScreen, hasTimeout, timeoutFn, gamesData }
      gameFinishedStatus: {},

      // Number of minutes before liveLeagueWeekData refreshes (for each league)
      liveWeekTimeoutMins: 30,
      loadedCompleted: false,
    };
  },

  created() {
    this.lastCheckTime = this.$moment();
    EventBus.$on('LEAGUE_LEFT', this.handleLeagueLeft);
    EventBus.$on('LEAGUE_DISBANDED', this.handleLeagueLeft);
    EventBus.$on('HIGHLIGHT_LEAGUE', this.highlightLeague);
    EventBus.$on('LIVE_GAME_UPDATE', this.onGameUpdate);
    this.getCompletedLeagues();

    this.loadMyLeagues();
    this.setGameFinishedStatus();
    // Gets the time to filter active/upcoming every 10s
    this.lastCheckedInterval = setInterval(this.setLastChecked, 10000);
  },

  destroyed() {
    this.unsubFromSalaryContestRooms();
    this.$SocketController.unsubscribeFromRoom('DASHBOARD_' + this.userId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('LEAGUE_LEFT', this.handleLeagueLeft);
    EventBus.$off('LEAGUE_DISBANDED', this.handleLeagueLeft);
    EventBus.$off('HIGHLIGHT_LEAGUE', this.highlightLeague);
    EventBus.$off('LIVE_GAME_UPDATE', this.onGameUpdate);
    clearInterval(this.lastCheckedInterval);
  },

  watch: {
    todaysGames() {
      this.setGameFinishedStatus();
    },
  },

  computed: {
    ...mapState(['theme', 'todaysGames']),

    sortedLeagues() {
      if (!this.leagues) {
        return null;
      }
      return this.leagues.slice().sort(function(a, b) {
        let aVal = a.draftTime || -1;
        let bVal = b.draftTime || -1;
        if (aVal > bVal) {
          return -1;
        } else if (aVal < bVal) {
          return 1;
        }

        aVal = a.dateFilled || -1;
        bVal = b.dateFilled || -1;
        if (aVal > bVal) {
          return -1;
        } else if (aVal < bVal) {
          return 1;
        }
        return 0;
      });
    },
    liveLeagues() {
      if (this.sortedLeagues == null) {
        return [];
      }

      return this.sortedLeagues.filter((league) => {
        if (league.ctype === 'Tournament' && this.contestState === 'live') {
          return this.$moment().diff(league.contestStart) > 0;
        }
        return this.$moment().diff(league.contestStart) < 0;
      }).sort((l1, l2) => {
        // Sort by start date, entry fee, and prize pool
        if (this.compareStartDate(l1, l2) !== null) {
          return this.compareStartDate(l1, l2);
        }
        if (l1.feeValue > l2.feeValue) {
          return -1;
        } else if (l1.feeValue < l2.feeValue) {
          return 1;
        }
        if (l1.prizeValue > l2.prizeValue) {
          return -1;
        } else if (l1.prizeValue < l2.prizeValue) {
          return 1;
        }
        if (l1.id < l2.id) {
          return -1;
        }
        return 1;
      });
    },
    selectedDay() {
      return this.$moment().clone().startOf('day').subtract(this.selectedDayStart, 'days');
    },
    startingDay() {
      return this.selectedDay.clone().startOf('day').subtract(7, 'days');
    },
  },

  methods: {
    compareStartDate(l1, l2) {
      const l1Timestamp = l1.ctype === 'Tournament' ? l1.contestStart : l1.startDate;
      const l2Timestamp = l2.ctype === 'Tournament' ? l2.contestStart : l2.startDate;
      let l1Start = null;
      let l2Start = null;

      if (l1Timestamp) {
        l1Start = this.$moment(l1Timestamp);
      }
      if (l2Timestamp) {
        l2Start = this.$moment(l2Timestamp);
      }

      if (l1Start != null && l2Start != null) {
        if (l1Start.diff(l2Start) < 0) {
          return -1;
        } else if (l1Start.diff(l2Start) > 0) {
          return 1;
        }
      } else if (l1Start) {
        return -1;
      } else if (l2Start) {
        return 1;
      }

      return null;
    },

    handleLeagueLeft(leagueId) {
      this.leagues = this.leagues.filter((league) => league.id != leagueId);
    },

    onGameUpdate() {
      this.setGameFinishedStatus();
    },

    getGameProgressFraction(maxPeriods, periodLengthMins, liveJSON) {
      const totMins = maxPeriods * periodLengthMins;
      let periodMins = 0;
      let playedMins = 0;

      if (liveJSON.gametime) {
        try {
          const ms = liveJSON.gametime.split(':');
          periodMins = parseInt(ms[0]) + (parseInt(ms[1]) / 60);
          playedMins = (liveJSON.period * periodLengthMins) - periodMins;
          return playedMins / totMins;
        } catch (e) {}
      }

      return 1;
    },

    getGameProgressBySport(game) {
      let gameVal = 0;
      if (game.sport === 'MLB' && game.live.inning) {
        // Divided by 10 since AFTER the 9th inning would be end of game
        gameVal = game.live.inning / 10;
      } else if (game.sport === 'NHL' && game.live.period != null) {
        gameVal = this.getGameProgressFraction(3, 20, game.live);
      } else if (game.sport === 'NFL' && game.live.period != null) {
        gameVal = this.getGameProgressFraction(4, 15, game.live);
      } else if (game.sport === 'NBA' && game.live.period != null) {
        gameVal = this.getGameProgressFraction(4, 12, game.live);
      }

      if (gameVal > 1) {
        gameVal = 1;
      }

      if (gameVal < 0) {
        gameVal = 0;
      }

      return gameVal;
    },

    setGameFinishedStatus() {
      const newGamesObject = {};

      for (const id of Object.keys(this.todaysGames)) {
        const game = this.todaysGames[id];

        if (game.live && (game.live.status === 'post-event' || game.live.status === 'Canceled')) {
          this.$set(newGamesObject, id, 1);
        } else if (game.live && game.live.status === 'mid-event') {
          const gameVal = this.getGameProgressBySport(game);
          this.$set(newGamesObject, id, gameVal);
        } else {
          this.$set(newGamesObject, id, 0);
        }
      }

      this.gameFinishedStatus = newGamesObject;
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'FSP_DASHBOARD' || data.type === 'FSP_SC_UPDATE') {
        const id = data.json.id;
        const json = data.json;
        let found = false;
        if (json.ctype && json.ctype === 'Tournament') {
          // Insert SalaryCap Contest
          found = this.insertSalaryCap(id, json, this.leagues);

          // Update my entries for this contest
          if (found) {
            this.updateSalaryCapEntries(id);
          }
        }

        if (!found) {
          // Update for a invited league won't be found in the leagues list
          this.loadMyLeagues();
        }
      }
    },

    updateSalaryCapEntries(contestId) {
      const nextUpdate = this.scheduledSalaryCapUpdates[contestId];
      const now = new Date().getTime();
      if (nextUpdate !== null && nextUpdate > now) {
        // Not updating contest, next update will trigger in a few seconds
        // This prevents excessive rest calls during high volume of contest entries
        return;
      }

      // Schedule update in x seconds, this prevents excessive loading when many updates occur
      // This can happen pre-contest if a lot of users join all at once
      // Subtract 1 second buffer in case an update occurs right as the call is triggered
      this.scheduledSalaryCapUpdates[contestId] = now + this.salaryCapUpdateInterval - 1000;
      setTimeout(() => {
        ObSalaryCapApi.getLeaderboardForUser(contestId)
            .then((response) => {
              for (const contest of this.leagues) {
                if (contest.id === contestId) {
                  contest.leaderboard = response;
                  break;
                }
              }
            })
            .catch((_error) => {
              // Do nothing
            });
      }, this.salaryCapUpdateInterval);
    },

    insertSalaryCap(id, json, list) {
      for (let i=0; i<list.length; i++) {
        if (id === list[i].id) {
          // Merge in teamRanks, if needed
          if (!json.teamRanks && list[i].teamRanks) {
            json.teamRanks = list[i].teamRanks;
          }

          // Merge in my entries, if needed
          if (!json.myEntries && list[i].myEntries) {
            json.myEntries = list[i].myEntries;
          }
          if (!json.myEntriesRemaining && list[i].myEntriesRemaining) {
            json.myEntriesRemaining = list[i].myEntriesRemaining;
          }

          // Merge existing leaderboard data, it is no longer included in socket updates
          // This data gets updated with a REST call
          json.leaderboard = list[i].leaderboard;

          this.$set(list, i, json);
          return true;
        }
      }
      return false;
    },

    flashScoreUpdate(key, change) {
      const self = this;
      this.$set(this.scoreUpdated, key, change);
      setTimeout(() => {
        self.$set(self.scoreUpdated, key, null);
      }, 800);
    },

    loadMyLeagues() {
      ObLeagueApi.loadMyLeagues4()
          .then((data) => {
            this.userId = data.userId;
            this.leagues = data.leagues || [];
            this.featured = data.featured || [];
            this.teamData = data.teamData;
            this.subscribeToSalaryContestRooms();

            if (this.firstLoad) {
              this.firstLoad = false;
              EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
              this.$SocketController.subscribeToRoom('DASHBOARD_' + this.userId);
            }
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    getCompletedLeagues() {
      this.loadedCompleted = false;
      if (this.completedFilters.leagueCount == 'More') {
        ObLeagueApi.getCompletedLeagues(this.completedFilters.year, this.completedFilters.month + 1)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      } else {
        ObLeagueApi.getLatestCompletedLeague(this.completedFilters.leagueCount)
            .then((response) => {
              this.completedLeagues = response.completedLeagues || [];
              this.userId = response.userId;
              this.loadedCompleted = true;
            }).catch((e) => {
              this.completedLeagues = [];
              this.loadedCompleted = true;
            });
      }
    },

    subscribeToSalaryContestRooms() {
      for (const contest of this.leagues) {
        const id = contest.id;
        const roomKey = 'FSP_SC_UPDATE_' + id;
        const isSubbed = this.socketRooms[roomKey];

        if (contest.ctype === 'Tournament' && !isSubbed) {
          this.socketRooms[roomKey] = true;
          this.$SocketController.subscribeToRoom(roomKey);
        }
      }
    },

    unsubFromSalaryContestRooms() {
      for (const roomKey in this.socketRooms) {
        this.$SocketController.unsubscribeFromRoom(roomKey);
      }
    },

    setLastChecked() {
      this.lastCheckTime = this.$moment();
    },

    highlightLeague(id) {
      this.highlightedLeagueId = id;
    },

    getContestHighlight(id) {
      if (this.highlightedLeagueId === id) {
        return 'background: var(--obcolor-background-3);';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.liveContestHeaderContainer {
  transition: top 0.3s ease-out;
  position: sticky;
  z-index: 80;
  padding-bottom: 3px;
  background: var(--obcolor-background-page);
}

.liveContestHeader {
  margin-bottom: 0px;
  background: var(--obcolor-background-3);
}

.liveContestContainer {
  height: 120px;
  display: flex;
  background: var(--obcolor-background-6);
  margin-bottom: 4px;
  overflow: hidden;
}
.liveContestContainer:last-child {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.liveSalaryContestContainer {
  min-height: 129px;
  display: flex;
  background: var(--obcolor-background-6);
  margin-bottom: 4px;
  overflow: hidden;
}
.liveSalaryContestContainer:last-child {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.noContests {
  height: max-content;
  padding: 28px 15px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}
</style>