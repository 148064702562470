<template>
  <div>
    {{ time }}
  </div>
</template>

<script>
import {formatDuration, SECOND} from '@/utils/Time';
export default {
  name: 'CountdownTimer',

  props: {
    end: {type: Number, default: null},
    min: {type: Number, default: null},
    minText: {type: String, default: null},
    clockFormat: {type: Boolean, default: null},
    textClock: {type: Boolean, default: null},
    serverOffset: {type: Number, default: null},
  },

  data() {
    return {
      time: 0,
      timeout: null,
    };
  },

  created() {
    this.getTime();
    this.loopGetTime();
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  watch: {
    end() {
      this.getTime();
    },

    min() {
      this.getTime();
    },

    minText() {
      this.getTime();
    },

    serverOffset() {
      this.getTime();
    },
  },

  methods: {
    getOffset() {
      return this.serverOffset ? this.serverOffset : 0;
    },

    getTime() {
      if (this.end) {
        let seconds = this.$moment(this.end + this.getOffset()).diff(this.$moment(), 'seconds');
        if (seconds < this.min && this.minText) {
          this.time = this.minText;
        } else {
          seconds = seconds < this.min ? this.min : seconds;
          this.time = this.clockFormat ? formatDuration(seconds * SECOND, this.textClock) : seconds;
        }
        this.$emit('tick', seconds);
      }
    },

    loopGetTime() {
      const self = this;
      this.timeout = setTimeout(function() {
        self.getTime();
        self.loopGetTime(1000);
      }, 1000);
    },
  },
};
</script>