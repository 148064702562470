<template>
  <table style="width: 100%; font-weight: bold;" aria-label="Draft Summary Result">
    <tr style="color: var(--obcolor-font-secondary); background: var(--obcolor-background-5)">
      <th class="tableHeader" style="width: 50px; text-align: center;">#</th>
      <th class="tableHeader" style="width: 50px; text-align: center;">Round</th>
      <th class="tableHeader" style="width: 50px; text-align: center;">Pick</th>
      <th class="tableHeader" style="width: 70px; text-align: center;">Position</th>
      <th class="tableHeader">Player Drafted</th>
      <th class="tableHeader" style="width: 90px; text-align: center;">pAVG</th>
      <th class="tableHeader" style="width: 200px;">Drafted By</th>
    </tr>
    <tr v-for="(pick, index) in filteredPicks" :key="index" :class="pick.pick == draftJSON.numUsers ? 'roundEnd' : ''">
      <td style="text-align: center;">{{ pick.overall }}</td>
      <td style="text-align: center;">{{ pick.round }}</td>
      <td style="text-align: center;">{{ pick.pick }}</td>
      <td style="text-align: center;">{{ pick.playerData.draftPos }}</td>
      <td class="ob-link" @click="openPlayerCard(pick.player)">{{ pick.playerData.name.longName() }}</td>
      <td style="text-align: center;">{{ pick.playerData.w1Avg.toFixed(0) }}</td>
      <td>
        <div style="display: flex; align-items: center; font-size: 14px; font-weight: bold; padding: 5px 0;">
          <UserLogo :id="pick.userId" :sport="draftJSON.sport" :size="25" />
          <div class="userName">{{ pick.userTeam }}</div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';

export default {
  components: {
    UserLogo,
  },
  props: {
    draftJSON: Object,
    playerSearchText: String,
  },
  computed: {
    filteredPicks() {
      const sName = this.playerSearchText;
      return this.draftJSON.picks.filter((pick) => {
        const player = pick.playerData;
        if (sName !== '') {
          const pname = player.name.toLowerCase();
          const nfilter = sName.toLowerCase();
          const nFilters = nfilter.split(' ');

          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) == 0 || pname.indexOf(', '+n) != -1) {
              // All filters must match
            } else {
              return false;
            }
          }
        }
        return true;
      });
    },
  },
  methods: {
    openPlayerCard(playerId) {
      EventBus.$emit('OPEN_PLAYER_CARD', playerId, this.draftJSON.sport);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  padding: 10px 0px;
}

tr:nth-child(even) > td {
  background: var(--obcolor-background-6);
}
tr:nth-child(odd) > td {
  background: var(--obcolor-background-5);
}

.roundEnd {
  border-bottom: 1px solid var(--obcolor-background-2);
}

.userName {
  display: block;
  margin-left: 10px;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>