<template>
  <div>
    <Modal v-if="isOpen"
      v-model="isOpen"
      containerStyle="width: 450px; height: 395px; padding: 0; overflow: hidden; background-color: var(--obcolor-background-6)"
      :hideCloseButton="true"
    >
      <div slot="header" class="modalHeader">
        <div class="section bold">How to Play</div>
        <div class="closeBtn fal fa-times" @click.stop="closeModal()"></div>
      </div>

      <div slot="body">
        <div class="flexRow section">
          <div class="number">1</div>
          <div>
            <div class="flexRow">
              <PlayerHeadshot size="50" class="playerHeadshot" id="424205001"
                sport="NFL" team="Mia"
              />
              <PlayerHeadshot size="50" class="playerHeadshot" id="172785009"
                sport="NFL" team="SF"
              />
            </div>
            <div class="instructionText">Pick minimum 2 players from a single sport</div>
          </div>
        </div>
        <div class="flexRow section">
          <div class="number">2</div>
          <div>
            <PlayerHeadshot size="50" class="playerHeadshot" id="424205001"
              sport="NFL" team="Mia" style="transform: translateX(20px);"
            />
            <div class="moreLessContainer">
              <div class="moreLess">
                Less
              </div>
              <div class="moreLess">
                More
              </div>
            </div>
            <div class="instructionText">Choose whether those players will get MORE or LESS than their projected stat</div>
          </div>
        </div>
        <div class="flexRow section">
          <div class="number">3</div>
          <div>
            <div class="instructionText">Press Place Entry and choose an entry fee</div>
          </div>
        </div>
        <div class="flexRow section">
          <div class="number">4</div>
          <div>
            <div class="instructionText">Follow your picks live during the contest</div>
          </div>
        </div>
        <div class="link"><a href="https://support.ownersbox.com/hc/en-us/sections/25941003876237" rel="noopener" target="_blank">View full details</a></div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
    PlayerHeadshot,
  },
  data() {
    return {
      isOpen: false,
    };
  },

  created() {
    EventBus.$on('PROPS_OPEN_HOW_TO_PLAY', this.openModal);
  },

  destroyed() {
    EventBus.$off('PROPS_OPEN_HOW_TO_PLAY', this.openModal);
  },

  methods: {
    openModal() {
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>

div {
  overflow: hidden;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.number {
  align-self: center;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: var(--obcolor-background-4);
  min-width: 31px;
  min-height: 31px;
  border-radius: 50%;
  margin-right: 16px;
}

.section {
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid var(--obcolor-background-4);
  align-items: center;
}

.instructionText {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
}

.bold {
  font-weight: 700;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 14px;
  a {
    color: var(--obcolor-ob-blue);
  }
}

.playerHeadshot {
  margin-right: 8px;
}

.moreLessContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;
  transform: translateY(-8px);
}
.moreLess {
  border: solid 1px var(--obcolor-timeline);
  background-color: var(--obcolor-background-2);
  color: var(--obcolor-font-secondary);
  display: flex;
  flex: 1;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  width: 45px;
  padding: 2px 8px;
  font-size: 14px;

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-right-width: 0.5px !important;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-left-width: 0.5px !important;
  }
}
</style>
