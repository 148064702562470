// Header Summary Stats
// Controls the top overall summary for header page
// NHL has 1 category for summary, split into Skater and Goaltender

// Header Summary
export const HEADER_NHL_SKATER = [
  {
    label: 'SKATER',
    stats: [
      {label: 'GP', path: 'skater.GP', tooltip: 'Games Played'},
      {label: 'G', path: 'skater.G', tooltip: 'Goals'},
      {label: 'A', path: 'skater.A', tooltip: 'Assists'},
      {label: '+/-', path: 'skater.plus_minus', tooltip: '+/-'},
      {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots'},
      {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal'},
    ],
  },
];

export const HEADER_NHL_GOALIE = [
  {
    label: 'GOALTENDER',
    stats: [
      {label: 'GP', path: 'skater.GP', tooltip: 'Games Played'},
      {label: 'W', path: 'goalie.W', tooltip: 'Wins'},
      {label: 'L', path: 'goalie.L', tooltip: 'Losses'},
      {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts'},
      {label: 'GAA', path: 'goalie.GAA', tooltip: 'Average Goals Against'},
      {label: 'SV%', path: 'goalie.SVPct', tooltip: 'Save Percentage'},
    ],
  },
];
