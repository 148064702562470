<template>
  <div style="display: flex; flex-direction: column; min-height: 0;">
    <div class="userLogoContainer">
      <div v-for="user in draftJSON.users" :key="user" class="userLogo"
        style="margin-bottom: 5px;" @click="selectedUserId = user"
      >
        <UserLogo class="userLogoComponent"
          :id="user" :sport="draftJSON.sport" :size="35"
          style="margin-right: 5px;"
          :class="selectedUserId === user ? 'selectedImg' : ''"
          :rank="userIsAutopicking(user) ? getAutpickStatus(user) : null" :rankColor="'red'"
        />
        <div v-show="draftJSON.turn === user" class="turnIndicator">
          <i class="fas fa-caret-up"></i>
        </div>
      </div>
    </div>
    <div class="selectedTeamName">
      <div class="myTeamName">
        <RankLogo style="margin-right: 5px;" :size="22" :rankNum="getRankNum(selectedUserId)"
          :rankColor="getRankColor(selectedUserId)"
        />
        {{ users[selectedUserId] ? users[selectedUserId].team : '' }}
      </div>
      <div v-show="curUser != selectedUserId" class="myTeamLink"
        @click="selectedUserId = curUser"
      >
        <span style="margin-right: 5px;">My Team</span>
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <div class="resultsTableContainer ob-scroll">
      <div style="width: calc(100% - 31px);">
        <table style="width: 100%;" aria-label="Draft Result">
          <tr>
            <th colspan="3" scope="col"></th>
            <!-- Width of table columns + padding - border -->
            <th style="width: 47px; min-width: 47px;" v-tooltip.bottom="{content: 'Projected Games', class:'ObTooltipHeat'}" scope="col">
              <template v-if="sport != 'NFL'">PG</template>
            </th>
            <th style="width: 47px; min-width: 47px;" v-tooltip.bottom="{content: 'Projected Points', class:'ObTooltipHeat'}" scope="col">PROJ</th>
          </tr>
          <tr>
            <th colspan="3" v-if="draftJSON.numWeeks > 1" scope="row">
              Week
              <div v-for="index in draftJSON.numWeeks" :key="index "
                :style="selectedWeek === index ? 'color: var(--obcolor-ob-blue)' : ''" style="cursor: pointer; display: inline-block; margin-left: 5px;"
                @click="selectedWeek = index"
              >
                {{ index }}
              </div>
            </th>
            <th v-else colspan="3" scope="row"> Week 1</th>
            <th style="width: 47px; min-width: 47px;" scope="row">
              <template v-if="sport != 'NFL'">{{ totalAg }}</template>
              <template v-else>VS</template>
            </th>
            <th style="width: 47px; min-width: 47px;" scope="row">
              <div>{{ potentialProj }}</div>
            </th>
          </tr>
        </table>
      </div>

      <!-- Loop by position group -->
      <template v-for="group in teamRosters[selectedUserId]">
        <div class="posPlayersContainer" :key="group.short">
          <table class="posPlayers" aria-label="Player Position">
            <tr v-for="i in group.num" :key="group.name + '-' +i"
              :class="group.players[i-1] != null ? 'playerRow' : ''"
              @click="selectPlayer(group.players[i-1])"
            >
              <!-- Player Entry -->
              <template v-if="group.players[i-1] != null">
                <td class="position">{{ group.short }}</td>
                <td class="headshotCell">
                  <div class="headshotContainer">
                    <PlayerHeadshot size="35" :id="group.players[i-1].id" :sport="draftJSON.sport"
                      :team="group.players[i-1].team"
                    />
                  </div>
                </td>
                <td class="status">
                  <PlayerStatus :status="group.players[i-1].status" style="margin-top: -11px;" />
                </td>
                <td class="playerInfoCell">
                  <div @click.stop @click="openPlayerCard(group.players[i-1])" class="ob-link playerName">
                    {{ group.players[i-1].name.shortName() }}
                  </div>
                  <div class="playerInfo">
                    <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(sport, group.players[i-1].team) }}</span>
                    <span>#{{ group.players[i-1].number }}</span>
                    <span>{{ group.players[i-1].lineInfo }}</span>
                  </div>
                </td>
                <td class="ag">
                  <!-- Show ag if it isn't NFL -->
                  <template v-if="sport != 'NFL'">{{ group.players[i-1].wStats[selectedWeek-1].pg }}</template>
                  <!-- Otherwise, check if the info exists, and if it's not a bye than display the team logo -->
                  <div v-else-if="group.players[i-1].wInfo != null && group.players[i-1].wInfo[selectedWeek-1]
                    && !group.players[i-1].wInfo[selectedWeek-1].bye" class="centerCol"
                  >
                    <div v-if="group.players[i-1].wInfo[selectedWeek-1].home == false" class="atSign">@</div>
                    <img style="width: 24px; height: 24px;" :class="{invalidGameLogo: group.players[i-1].wInfo[selectedWeek-1].excluded == true}"
                      :src="getTeamLogo(group.players[i-1].wInfo[selectedWeek-1].opp)" :alt="group.players[i-1].wInfo[selectedWeek-1].opp"
                    >
                  </div>
                  <div v-else-if="group.players[i-1].wInfo != null && group.players[i-1].wInfo[selectedWeek-1]
                    && group.players[i-1].wInfo[selectedWeek-1].bye == true" class="centerCol"
                  >
                    BYE
                  </div>
                </td>
                <td class="proj">{{ roundStat(group.players[i-1].wStats[selectedWeek-1].proj) }}</td>
              </template>
              <!-- Open Slot -->
              <template v-else>
                <td class="position">{{ group.short }}</td>
                <td colspan="5" class="openSlot">Open Slot</td>
              </template>
            </tr>
          </table>
          <div class="posGroup">
            <div>
              <div :class="{'redHighlight' : sumAg(group.players) < group.maxGames}" class="posGroupText">{{ sumAg(group.players) }}</div>
              <div>{{ group.maxGames }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo.vue';
import RankLogo from '@/components/RankLogo.vue';
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';

export default {

  components: {
    UserLogo,
    RankLogo,
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    draftJSON: Object,
    teamRosters: Object,
    users: Object,
    curUser: String,
    userInfo: Object,
    sport: String,
    draftState: Object,
  },

  data() {
    return {
      selectedUserId: null,
      selectedWeek: 1,
    };
  },

  created() {
    if (this.curUser) {
      this.selectedUserId = this.curUser;
    }
  },

  computed: {
    totalAg() {
      const self = this;
      let agT = 0;
      this.teamRosters[self.selectedUserId].forEach((group) => {
        group.players.forEach((player) => {
          agT += player.wStats[self.selectedWeek - 1].pg;
        });
      });
      return agT.toFixed(0);
    },

    potentialProj() {
      let projT = 0;
      const self = this;
      this.teamRosters[self.selectedUserId].forEach((group) => {
        const playerGames = [];
        let sortedGames = [];
        group.players.forEach((player) => {
          if (player.gamesByWeek) {
            player.gamesByWeek[self.selectedWeek - 1].forEach(function(game) {
              playerGames.push(game.proj);
            });
          }
        });
        sortedGames = playerGames.sort((proj1, proj2) => {
          return proj1 < proj2 ? 1 : -1;
        });
        for (let i = 0; i < group.maxGames; ++i) {
          if (sortedGames[i]) {
            projT += Math.round(sortedGames[i]);
          }
        }
      });

      return projT.toFixed(0);
    },

  },

  methods: {
    openPlayerCard(player) {
      EventBus.$emit('DRAFT_OPEN_PLAYER_CARD', player, this.sport, this.draftState);
    },

    sumAg(players) {
      const self = this;
      let sum = 0;
      players.forEach((player) => {
        sum += player.wStats[self.selectedWeek - 1].pg;
      });
      return sum;
    },

    userIsAutopicking(userId) {
      if (this.userInfo != null) {
        const info = this.userInfo[userId];
        if (info != null) {
          return info.autopick || !info.active;
        }
      }
      return true;
    },

    getAutpickStatus(userId) {
      if (this.userInfo != null) {
        const info = this.userInfo[userId];
        if (info != null) {
          if (info.autopick && !info.active) {
            return 'X';
          } else if (info.autopick) {
            return 'A';
          } else if (!info.active) {
            return 'X';
          }
        }
      }
      return '';
    },

    roundStat(stat) {
      return stat.toFixed(0);
    },

    selectPlayer(player) {
      if (player != null) {
        EventBus.$emit('PLAYER_SELECTED', player);
      }
    },

    getRankColor(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankColor || 'blue';
      }
      return 'blue';
    },

    getRankNum(userId) {
      if (this.users[userId]) {
        return this.users[userId].rankNum || 1;
      }
      return 1;
    },

    getTeamLogo(team) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },

  },
};
</script>

<style lang="scss" scoped>
.userLogoContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 2px 12px 0px 12px;
  margin-top: 0px;
}

.userLogo {
  margin-bottom: 3px;
  cursor: pointer;
}

.userLogoComponent {
  border: 2px solid var(--obcolor-background-6) !important;
}

.turnIndicator {
  height: 5px;
  margin-top: -7px;
  margin-bottom: 5px;
  text-align: center;
}

.turnIndicator > i {
  margin-left: -4px;
  color: green;
  font-size: 20px;
}

.selectedTeamName {
  display: flex;
  align-items: center;
  color: var(--obcolor-font-secondary);
  padding: 0 10px 0 10px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  margin-top: 7px;
  margin-bottom: 7px;
  background: var(--obcolor-background-2);
}

.myTeamName {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.myTeamLink {
  border-left: 1px solid var(--obcolor-text-faded);
  color: var(--obcolor-text-faded);
  font-size: 12px;
  padding: 3px 0px 3px 8px;
  cursor: pointer;
}

.myTeamLink:hover {
  color: var(--obcolor-font-primary);
}

.selectedImg {
  border: 2px solid #ffc400 !important;
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.5);
}

.resultsTableContainer {
  -ms-overflow-style: none;
  scrollbar-width: thin;
  scrollbar-color: var(--obcolor-background-2) var(--obcolor-background-6);
  padding: 6px;
  padding-top: 0px;
  position: relative;
  height: 100%;
  min-height: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.resultsTableContainer > table {
  width: calc(100% - 30px);
}

table {
  font-size: 14px;
  text-align: center;
}

th {
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-background-6);
}

td {
  border-top: 5px solid var(--obcolor-background-6);
  padding: 4px;
  height: 35px;
}

tr {
  background: var(--obcolor-background-5);
}

.playerRow {
  cursor: pointer;
}

.centerCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.atSign {
  margin-right: 2px; height: max-content;
  margin-left: -13px;
}

.playerRow:hover {
  background: var(--obcolor-bg-hover);
}

.playerName {
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.playerInfo {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -3px;
  color: var(--obcolor-font-secondary);
  white-space: nowrap;
  span {
    margin-right: 5px;
  }
}

.posPlayersContainer {
  display: flex;
  width: 100%;
}

.posPlayers {
  flex: 1;
  margin-bottom: 2px;
  // Subtract width of right bar because apparently Firefox doesn't know how a flexbox works
  width: calc(100% - 31px);
}

.position {
  width: 25px;
  min-width: 25px;
  font-weight: bold;
}

.headshotCell {
  width: 35px;
}

.headshotContainer {
  display: flex;
  align-items: center;
}

.status {
  width: 14px;
  padding: 0;
  padding-left: 2px;
}

.playerInfoCell {
  text-align: left;
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}

.ag {
  width: 40px;
  min-width: 40px;
}

.proj {
  width: 45px;
  min-width: 45px;
  box-sizing: border-box;
}

.openSlot {
  color: var(--obcolor-font-secondary);
}

.posGroup {
  width: 28px;
  min-width: 28px;
  background: var(--obcolor-background-2);
  margin-top: 5px;
  margin-left: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0 10px 10px 0;
}

.posGroupText {
  border-bottom: 2px solid #aaa;
  margin-bottom: 2px
}

.redHighlight {
  color: red;
}

</style>