<template>
  <div class="ob-page">
    <div class="ob-box" style="min-width: min-content;">
      <div style="margin-bottom: 5px; display: flex; align-items: center;">
        <div class="btnGroup">
          <button class="btnFilter" v-for="(sport, index) in sports" :key="index"
            :class="[{'btnSelected ' : sport === selectedSport}, 'btnSport' + index]" @click="selectSport(sport)"
          >
            <div style="display: flex; align-items: center;">
              <img v-if="sport != 'Overall'" :src="require('@/assets/icons/sporticon_' + sport + '.png')" :alt="sport"
                style="height: 25px; margin-right: 5px;"
              >
              {{ sport }}
            </div>
          </button>
        </div>
      </div>

      <table aria-label="Leaderboard">
        <tr>
          <th style="text-align: left;">User</th>
          <th :class="{selectedHeader: sortBy.name == 'recentH2hWinRatio'}">L10%</th>
          <th :class="{selectedHeader: sortBy.name == 'h2hWin'}" style="border-left: 1px solid var(--obcolor-background-2);">Wins</th>
          <th :class="{selectedHeader: sortBy.name == 'h2hLoss'}">Losses</th>
          <th :class="{selectedHeader: sortBy.name == 'h2hDiff'}">Diff</th>
          <th :class="{selectedHeader: sortBy.name == 'h2hWinRatio'}">Win %</th>
          <th :class="{selectedHeader: sortBy.name == 'doublePlayerAvgPlace'}" style="border-left: 1px solid var(--obcolor-background-2);">2M Avg</th>
          <th :class="{selectedHeader: sortBy.name == 'quadPlayerAvgPlace'}">4M Avg</th>
          <th :class="{selectedHeader: sortBy.name == 'octPlayerAvgPlace'}">8M Avg</th>
          <th :class="{selectedHeader: sortBy.name == 'hexPlayerAvgPlace'}">16M Avg</th>
        </tr>
        <tr v-for="entry in sortedLeaders" :key="entry.id">
          <td style="display: flex; align-items: center; white-space: nowrap;">
            <UserLogo :id="entry.user" :sport="selectedSport != 'Overall' ? selectedSport : null"
              :size="50" :showRank="true" :rankColor="entry.rankColor"
              :rankNum="entry.rankNum"
              style="margin-right: 20px;"
            />
            <router-link v-if="entry.teamname" class="ob-link" style="text-align: left;"
              :to="'/profile/' + entry.user"
            >
              <div>{{ entry.teamname }}</div>
              <div style="font-size: 12px; color: var(--obcolor-font-secondary);">{{ entry.username }}</div>
            </router-link>
            <router-link v-else class="ob-link" :to="'/profile/' + entry.user"
              style="text-align: left; white-space: nowrap;"
            >
              {{ entry.username }}
            </router-link>
          </td>
          <td>{{ entry.recentH2hWinRatio.toFixed(1) }}</td>
          <td style="border-left: 1px solid var(--obcolor-background-2);">{{ entry.h2hWin }}</td>
          <td>{{ entry.h2hLoss }}</td>
          <td>{{ (entry.h2hDiff >= 0 ? "+" : "") + entry.h2hDiff }}</td>
          <td>{{ entry.h2hWinRatio.toFixed(1) }}</td>
          <td style="border-left: 1px solid var(--obcolor-background-2);">{{ entry.doublePlayerAvgPlace }}</td>
          <td>{{ entry.quadPlayerAvgPlace }}</td>
          <td>{{ entry.octPlayerAvgPlace }}</td>
          <td>{{ entry.hexPlayerAvgPlace }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ObUserStatsApi from '@/api/ObUserStatsApi';
import UserLogo from '@/components/UserLogo';

export default {

  components: {
    UserLogo,
  },

  data: function() {
    return {
      leaders: [],
      sports: ['Overall', 'NHL', 'NFL', 'MLB', 'NBA'],
      selectedSport: 'Overall',
      sortBy: {name: 'h2hDiff', type: 'high'},
    };
  },

  created() {
    this.loadData();
  },

  computed: {
    sortedLeaders() {
      const self = this;
      const tempLeaders = this.leaders.slice();
      return tempLeaders.sort((l1, l2) => {
        if (l1[self.sortBy.name] == null) {
          return 1;
        } else if (l2[self.sortBy.name] == null) {
          return -1;
        }
        if (self.sortBy.type == 'high') {
          if (l1[self.sortBy.name] == 0) {
            return 1;
          }
          if (l2[self.sortBy.name] == 0) {
            return -1;
          }
          if (l1[self.sortBy.name] < l2[self.sortBy.name]) {
            return 1;
          } else if (l1[self.sortBy.name] > l2[self.sortBy.name]) {
            return -1;
          }
        } else if (self.sortBy.type == 'low') {
          if (l1[self.sortBy.name] == 0) {
            return 1;
          }
          if (l2[self.sortBy.name] == 0) {
            return -1;
          }
          if (l1[self.sortBy.name] < l2[self.sortBy.name]) {
            return -1;
          } else if (l1[self.sortBy.name] > l2[self.sortBy.name]) {
            return 1;
          }
        }
        if (l1.h2hWinRatio < l2.h2hWinRatio) {
          return 1;
        } else if (l1.h2hWinRatio > l2.h2hWinRatio) {
          return -1;
        }
        return 1;
      });
    },
  },

  methods: {
    loadData() {
      const sport = this.selectedSport != 'Overall' ? this.selectedSport : '';
      ObUserStatsApi.getSportLeaders(sport)
          .then((data) => {
            this.leaders = this.calcDifferential(data);
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    calcDifferential(data) {
      for (const user of data) {
        user.h2hDiff = (user.h2hWin || 0) - (user.h2hLoss || 0);
      }
      return data;
    },

    selectSport(sport) {
      this.selectedSport = sport;
      this.loadData();
    },

    changeSortBy(sortString, sortDirection = 'high') {
      this.sortBy = {name: sortString, type: sortDirection};
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
th {
  text-align: center;
  font-size: 14px;
  padding: 17px 8px 12px 8px;
  position: sticky;
  top: 75px;
  background: var(--obcolor-bg-hover);
  z-index: 1;
}
td {
  text-align: center;
  font-size: 14px;
  padding: 10px 8px;
}
td:not(:first-child) {
  width: 110px;
}
tr:nth-child(even) {
  background: var(--obcolor-background-5);
}

.btnGroup {
  display: flex;
}
.btnFilter {
  background-color: var(--obcolor-background-2);
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  border: 1px solid var(--obcolor-background-2);
  border-radius: 10px;
  width: 94px;
  height: 40px;
  box-sizing: border-box;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    margin-left: -5px;
  }
  &.btnSelected {
    color: white;

    background-color: var(--obcolor-ob-blue);
    &:hover {
      background-color: var(--obcolor-ob-blue-tinted);
    }
    &:active {
      background-color: var(--obcolor-accent-hl);
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.btnFilter:hover {
  background-color: var(--obcolor-bg-hover);
}
.btnFilter:active {
  background-color: var(--obcolor-background-5);
}

.sortHeader {
  white-space: nowrap;
  cursor: pointer;
}
.sortHeader:hover {
  background-color: var(--obcolor-background-2);
}
.selectedHeader {
  background-color: var(--obcolor-background-2);
}
</style>