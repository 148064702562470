<template>
  <div class="userInfoBox">
    <div class="boxHeader userInfoHeader">
      <UserLogo :id="userId" />
      <div class="userNameEmail">
        <div class="userName">
          {{ userName }}
          <i class="fas fa-edit editNameBtn" @click="editProfile()"></i>
        </div>
        <div class="userEmail">{{ userEmail }}</div>
      </div>
    </div>
    <div class="boxBody loadingBox" v-if="isLoading">
      <ObLoading :size="80" />
    </div>
    <div class="boxBody" v-else-if="userInfo">
      <div class="levelSection">
        <LevelIcon :level="userLevel" :size="60" />
        <div class="levelTooltipText">
          {{ isMaxLevel ? 'Max ' : '' }}Level
          <Tooltip pos="bottom" textKey="BattlePass_Level" iconStyle="fal" />
        </div>
        <LevelProgress v-if="!isMaxLevel" class="progressSection" :userInfo="userInfo" />
      </div>
      <div v-if="profile" class="conversionSection">
        <div class="infoField">
          <div class="infoValue blueText">
            <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
            {{ ownersbucks }}
          </div>
          <div class="infoTitle">OwnersBucks</div>
        </div>
        <div class="infoField">
          <div class="infoValue">
            {{ (Number(profile.conversionRate) * 100).toFixed(2) }}%
          </div>
          <div class="infoTitle">
            Rate
            <Tooltip pos="bottom" textKey="BattlePass_RedeemRate" iconStyle="fal" />
          </div>
        </div>
        <div class="infoField">
          <div class="infoValue">
            ${{ profile.convertible }}
          </div>
          <div class="infoTitle">Redeemable</div>
        </div>
      </div>
      <div class="ob-btn redeemBtn" @click="openConvertCurrencyModal">Redeem Cash</div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo.vue';
import LevelIcon from '@/components/battlepass/LevelIcon';
import LevelProgress from '@/components/battlepass/LevelProgress';
import Tooltip from '@/components/tooltip/Tooltip';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import {isMaxLevel} from '@/utils/shared/BattlePassUtils';
import {mapState} from 'vuex';

export default {
  props: {
    userInfo: {type: Object, default: null},
    profile: {type: Object, default: null},
    isLoading: {type: Boolean, default: null},
  },

  components: {
    UserLogo,
    LevelIcon,
    LevelProgress,
    Tooltip,
    ObLoading,
  },

  computed: {
    ...mapState(['userId', 'userName', 'userEmail', 'ownersbucks']),

    userLevel() {
      return this.userInfo?.currentLevel;
    },

    isMaxLevel() {
      return isMaxLevel(this.userInfo);
    },
  },

  methods: {
    openConvertCurrencyModal() {
      EventBus.$emit('CONVERT_CURRENCY_OPEN', this.profile);
    },

    editProfile() {
      EventBus.$emit('PROFILE_EDIT_GLOBAL', this.userName);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .levelProgressContainer {
    height: 8px;
  }
}
.userInfoBox {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.userInfoHeader {
  display: flex;
  align-items: center;
  gap: 10px;

  .userName {
    font-weight: bold;
    font-size: 16px;
  }

  .userEmail {
    color: var(--obcolor-font-secondary);
  }

  .editNameBtn {
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
    cursor: pointer;
  }

  .editNameBtn:hover {
    color: var(--obcolor-font-primary);
  }
}

.boxBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex: 1;

  &.loadingBox {
    justify-content: center;
  }
}

.levelSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.levelTooltipText {
  display: flex;
  color: var(--obcolor-font-secondary);
  margin-top: 5px;

  .levelTooltip {
    margin-left: 5px;
    margin-top: -1px;
  }
}

.progressSection {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  padding: 20px;
  border-bottom: var(--obcolor-background-2);
}

.conversionSection {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0 10px 0;
  border-top: 1px solid var(--obcolor-background-3);
}

.infoField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .infoValue {
    font-weight: bold;
    margin-bottom: 3px;
  }

  .infoTitle {
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }
}

.ownersbucksIcon {
  height: 14px;
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.redeemBtn {
  margin-top: 10px;
  width: 200px;
}
</style>