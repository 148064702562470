<template>
  <div v-if="contest.sport === 'MLB' && contest.format == 'DAILY'">
    <span style="font-weight: bold;">{{ firstTeamAlias + ' ' }}</span>
    <span>
      {{ $SportInfo.getGameSummary(contest.sport, game, player.player.teamId, $moment, true, false) }}
    </span>
  </div>
  <div v-else>
    <span>{{ $SportInfo.getGameSummary(contest.sport, game, player.player.teamId, $moment, true, true) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    fieldState: {type: Object, default: null},
    player: {type: Object, default: null},
    contest: {type: Object, default: null},
    game: {type: Object, default: null},
  },

  computed: {
    gameTime() {
      if (!this.game) {
        return '';
      }

      const game = this.game;
      let gameTimestamp;
      if (game.date) {
        gameTimestamp = game.date;
      } else if (game.date_utc) {
        gameTimestamp = game.date_utc;
      } else {
        gameTimestamp = game.timestamp;
      }
      return this.$moment(gameTimestamp).format('h:mma');
    },

    firstTeamAlias() {
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, this.player.player.team);
    },

    secondTeamAlias() {
      let alias = '';
      if (this.game.opp_alias != null) {
        alias = this.game.opp_alias;
      } else if (this.player.player.team === this.game.home_team.team_alias) {
        alias = this.game.visitor_team.team_alias;
      } else {
        alias = this.game.home_team.team_alias;
      }
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, alias);
    },

    isHomeGame() {
      if (this.game.home != null) {
        return this.game.home === 'true';
      }
      return this.player.player.team === this.game.home_team.team_alias;
    },

    teamScores() {
      if (!this.game.live || !this.game.live.hometeam || !this.game.live.awayteam) {
        return '';
      }
      if (this.isHomeGame) {
        return this.game.live.hometeam.score + '-' + this.game.live.awayteam.score;
      }
      return this.game.live.awayteam.score + '-' + this.game.live.hometeam.score;
    },
  },
};
</script>