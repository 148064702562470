import Api from '@/api/ObBaseApi';

export default {
  getAllTopics() {
    return Api()
        .get('/fsp/knowledgebase/getAllTopics')
        .then((response) => {
          return response.data;
        });
  },
};
