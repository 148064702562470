/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/require-default-prop */
<template>
  <tr class="ddContainer">
    <td :colspan="leagueData.sport === 'MLB' && leagueData.format === 'DAILY' ? 4 : 3">
      <div class="tableContainer">
        <table aria-label="Your Statistics">
          <tr class="statRow" v-for="(row, index) in statRows.myRowList" :key="index">
            <td class="statLabel">{{ row.label }}</td>
            <td></td>
            <td class="statText">{{ row.text }}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </td>
    <td class="middleField"></td>
    <td :colspan="leagueData.sport === 'MLB' && leagueData.format === 'DAILY' ? 4 : 3">
      <div class="tableContainer">
        <table aria-label="Opponent Statistics">
          <tr class="statRow" v-for="(row, index) in statRows.oppRowList" :key="index">
            <td class="statTextOpp">{{ row.text }}</td>
            <td></td>
            <td class="statLabelOpp">{{ row.label }}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    myPlayer: Object,
    oppPlayer: Object,
    todaysGames: Object,
    leagueData: Object,
    gameIndex: Number,
  },

  computed: {
    statRows() {
      const scoringStats = this.$ScoringStats.getAllStats(this.leagueData.sport, true);
      const myRowList = [];
      const oppRowList = [];

      if (this.myPlayer && this.myPlayer.salaryPG) {
        myRowList.push({label: 'Salary', text: this.myPlayer ? this.myPlayer.salaryPG.formatMoney() : ''});
      }
      if (this.oppPlayer && this.oppPlayer.salaryPG) {
        oppRowList.push({label: 'Salary', text: this.oppPlayer ? this.oppPlayer.salaryPG.formatMoney() : ''});
      }

      if (this.myPlayer && this.myPlayer.pctTaken != null) {
        myRowList.push({label: '% Owned', text: this.myPlayer.pctTaken + '%'});
      }
      if (this.oppPlayer && this.oppPlayer.pctTaken != null) {
        oppRowList.push({label: '% Owned', text: this.oppPlayer.pctTaken + '%'});
      }

      // Set the rows for each stat that isn't zero
      for (const stat of scoringStats) {
        const myStats = this.getStatString(false, stat);
        const oppStats = this.getStatString(true, stat);
        if (myStats != null) {
          myRowList.push(myStats);
        }
        if (oppStats != null) {
          oppRowList.push(oppStats);
        }
      }

      return {myRowList: myRowList, oppRowList: oppRowList};
    },
  },

  methods: {
    getStatString(isOppTeam, stat, category) {
      if (this.getStat(stat.path, isOppTeam, true) != null && this.getStat(stat.path, isOppTeam, true) != 0) {
        // scoreNum + labelName gives "150 Passing Yards" for example
        const labelScoreNum = this.getStat(stat.path, isOppTeam, false);
        let labelName = stat.label;
        if (labelScoreNum == 1) {
          labelName = labelName.removePluralWords();
        } else {
          labelName = stat.label;
        }

        return {label: labelScoreNum + ' ' + labelName, text: this.getStat(stat.path, isOppTeam, true) + ' PTS'};
      }

      return null;
    },

    // Changes text to lowercase with uppercase first letter
    removeUppercase(inputText) {
      if (inputText == null) {
        return '';
      }
      return inputText.slice(0, 1).toUpperCase() + inputText.slice(1).toLowerCase();
    },

    getFspGame(isOppTeam = false, isDH = false) {
      const entry = isOppTeam ? this.oppPlayer : this.myPlayer;
      if (!entry || !entry.fspGames ) {
        return null;
      }

      if (this.leagueData.format == 'WEEKLY') {
        if (this.gameIndex != null && this.gameIndex != -1) {
          let gameIndexString = this.gameIndex.toString();
          if (isDH) {
            gameIndexString += '_DH';
          }
          return entry.fspGames[gameIndexString];
        } else {
          return {};
        }
      }
      let foundGame = null;
      for (const index in entry.fspGames) {
        if (entry.fspGames[index].timestamp != null && foundGame == null) {
          foundGame = entry.fspGames[index];
        }
      }

      return foundGame;
    },

    todaysGame(isOppTeam = false) {
      const gameJSON = this.getFspGame(isOppTeam);
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    // Safely find a player stat and return in a printable format. Useful for failing gracefully when stats aren't found.
    getStat(statPath, isOppTeam, isFpts = false) {
      const pathRoot = isFpts ? 'ObPoints' : 'stats';
      const game = this.getFspGame(isOppTeam);

      if (!game || !game[pathRoot] || !statPath) {
        return null;
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game[pathRoot][playerRole]) {
        return 0;
      }
      const stat = game[pathRoot][playerRole][statType];
      if (stat === null || stat === undefined) {
        return 0;
      }
      return stat;
    },
  },
};
</script>

<style lang="scss" scoped>
  .ddContainer {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
  td {
    vertical-align: top;
  }

  .tableContainer {
    width: 100%;
    height: 100%;

    table {
      width: 100%;
    }
  }

  .statRow {
    font-size: 14px;
    height: 27px;
    border-bottom: 1px solid var(--obcolor-background-2);
  }
  .statRow:last-child {
    border-bottom: none;
  }

  .statLabel {
    padding: 5px 0 5px 15px;
    color: var(--obcolor-font-secondary);
  }
  .statText {
    padding: 5px 7px 5px 0;
    text-align: right;
  }

  .statLabelOpp {
    padding: 5px 15px 5px 0;
    color: var(--obcolor-font-secondary);
    text-align: right;
  }
  .statTextOpp {
    padding: 5px 0 5px 12px;
  }

  .middleField {
    background: var(--obcolor-background-6);
  }
</style>