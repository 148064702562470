<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 550px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-3); overflow-y: scroll;"
    :hideCloseButton="false" @input="setIsOpen"
  >
    <div slot="header" class="header">
      <div>Tickets ({{ numValidTickets }} Available)</div>
    </div>

    <div slot="body" class="body">
      <div class="ticketList" v-if="validTickets && validTickets.length > 0">
        <TicketField v-for="(ticket, index) in validTickets" :key="index"
          :ticket="ticket" :showButton="true"
          @ticketClicked="applyCodeFromTicket"
        />
      </div>
    </div>
    <div slot="footer">
      <div class="applySection">
        <div class="inputContainer">
          <form @submit.prevent="applyCodeFromContest()" style="flex: 1;">
            <input style="width: 100%;" v-model="promoCode">
          </form>
          <button v-if="state == 'SENDING'" disabled class="ob-btn-grey">
            <ObLoading :size="35" style="margin-left: -13px; margin-top: -10px;" /> Apply Ticket
          </button>
          <button v-else-if="promoCode == null || promoCode == ''" disabled class="ob-btn-grey">Apply Ticket</button>
          <button v-else class="ob-btn" @click="applyCodeFromContest()">Apply Ticket</button>
        </div>
        <div v-if="state == 'VALID'" class="successMessageContainer">
          <div :class="{ greenText: contest.realMoney == true, blueText: contest.realMoney == false, }">
            <img v-if="!contest.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
              class="ownersbucksIcon"
            >
            {{ contest.fee.removeCentsIfZero() }} Ticket Applied
          </div>
          <div class="removeText" @click="clearCode()">Remove</div>
        </div>
        <div v-if="state == 'INVALID'" class="errorMessage"><i class="fas fa-exclamation-circle"><div class="iconBg"></div></i>{{ message }}</div>
        <div class="noteText">Tickets are only valid for contests where the entry fee matches the promotional value of the code.</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import TicketField from '@/components/tooltip/tickets/TicketField';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    ObLoading,
    TicketField,
  },

  data() {
    return {
      isOpen: false,
      promoCode: '',
      state: 'NO_CODE',
      message: '',
      contest: null,
      lineupModalInfo: {},
    };
  },

  created() {
    EventBus.$on('SALARY_CAP_OPEN_PROMO_CODE_MODAL', this.openModal);
    EventBus.$on('SALARY_CAP_CODE_IS_VALID', this.setCodeIsValid);
    EventBus.$on('SALARY_CAP_CODE_IS_INVALID', this.setCodeIsInvalid);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_OPEN_PROMO_CODE_MODAL', this.openModal);
    EventBus.$off('SALARY_CAP_CODE_IS_VALID', this.setCodeIsValid);
    EventBus.$off('SALARY_CAP_CODE_IS_INVALID', this.setCodeIsInvalid);
  },

  computed: {
    ...mapState(['tickets', 'numTickets']),

    numValidTickets() {
      return this.validTickets.length;
    },

    validTickets() {
      if (!this.contest) {
        return [];
      }
      return this.tickets.slice().filter((t) => {
        const isContestType = t.contestType == null || t.contestType === 'SalaryCap';
        return isContestType && t.feeValue === this.contest.feeValue && t.realMoney === this.contest.realMoney &&
          (t.sport === '' || t.sport === this.contest.sport);
      });
    },
  },

  methods: {
    openModal(contest, promoInfo, lineupModalInfo = {}) {
      EventBus.$emit('LOAD_USER_TICKETS');
      this.isOpen = true;
      this.contest = contest;
      this.lineupModalInfo = lineupModalInfo;

      if (promoInfo == null) {
        this.promoCode = '';
        this.state = 'NO_CODE';
        this.message = '';
      } else {
        this.promoCode = promoInfo.code;
        this.state = promoInfo.state;
        this.message = promoInfo.message;
      }
    },

    clearCode() {
      this.promoCode = '';
      this.state = 'NO_CODE';
      this.message = '';
      EventBus.$emit('SALARY_CAP_SET_PROMO_CODE', {code: '', state: 'NO_CODE'});
    },

    applyCodeFromTicket(ticket) {
      this.applyCodeId(ticket.id);
    },

    applyCodeFromContest() {
      this.state = 'SENDING';

      if (!this.contest || !this.contest.id) {
        this.state = 'INVALID';
        this.message = 'Error retrieving contest information';
        return;
      }

      this.applyCodeId(this.promoCode);
    },

    applyCodeId(id) {
      EventBus.$emit('SALARY_CAP_VALIDATE_PROMO_CODE', id);
    },

    setCodeIsValid(code) {
      this.state = 'VALID';
      this.closeModal();
    },

    setCodeIsInvalid(errorMessage) {
      this.state = 'INVALID';
      this.message = errorMessage;
    },

    setIsOpen(isOpen) {
      if (!isOpen) {
        this.closeModal();
      }
    },

    closeModal() {
      if (this.lineupModalInfo && this.lineupModalInfo.reopenOnCode) {
        this.$set(this.lineupModalInfo.entryInfo, 'promoInfo', {code: this.promoCode, state: this.state});
        EventBus.$emit('OPEN_SALARY_CAP_CONFIRM_MODAL', this.lineupModalInfo.entryInfo);
      }
      this.promoCode = '';
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--obcolor-background-5);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}

.body {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  background: var(--obcolor-background-4);
  max-height: 500px;

  .ticketList {
    border-bottom: 1px solid var(--obcolor-background-2);
  }
}

.applySection {
  // margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 19px 10px 10px;

  .infoText {
    white-space: break-spaces;
    margin-bottom: 10px; text-align: left;
  }
}

.inputContainer {
  height: 30px;
  width: 100%;
  display: flex;

  input {
    font-size: 14px;
    border-radius: 0;
    height: 100%;
    box-sizing: border-box;
  }
  button {
    margin-left: 10px;
    font-size: 14px;
    width: 110px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    white-space: nowrap;
  }
}

.greenText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.errorMessage, .successMessageContainer {
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  padding-left: 2px;

  i {
    margin-right: 5px;
  }
}

.successMessageContainer {
  display: flex;
  justify-content: space-between;
}

.removeText {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.errorMessage {
  color: red;
}

.iconBg {
  margin-top: -12px;
  margin-left: 1px;
  background: white;
  border-radius: 10px;
  height: 9px;
  width: 9px;
}

.noteText {
  text-align: center;
  margin-top: 10px;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  width: 100%;
  padding-left: 2px;
}

.ownersbucksIcon {
  height: 10px;
}
</style>