<template>
  <div class="blueCheckbox" :class="{checked: checked, canHover: canHover}"
    :style="'height: ' + sizeText + '; width: ' + sizeText + ';'"
    @click.stop="fireClick"
  >
    <i class="fas fa-check selectedCheck"
      :style="'font-size: ' + fontSize + ';'"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    checked: {type: Boolean, default: false},
    size: {type: String, default: '20'},
    canHover: {type: Boolean, default: true},
  },

  computed: {
    fontSize() {
      // Default checkbox size is 20px with 14px font size, so 70%
      return this.size * 0.7 + 'px';
    },

    sizeText() {
      return this.size + 'px';
    },
  },

  methods: {
    fireClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.blueCheckbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background: var(--obcolor-background-2);
  display: flex;
  justify-content: center;
  align-items: center;
  &.canHover {
    cursor: pointer;
  }

  &.canHover:hover {
    filter: brightness(1.3);
  }

  .selectedCheck {
    color: white;
    font-size: 14px;
  }

  &.checked {
    background: var(--obcolor-ob-blue) !important;
  }

  &:not(.checked) .selectedCheck {
    display: none;
  }
}
</style>