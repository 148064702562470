<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 450px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3); overflow: unset !important;"
    :hideCloseButton="false" :disableClickaway="true"
  >
    <div slot="header" class="confirmHeader">Swap Failed</div>
    <div slot="body" class="confirmBody">{{ message != null ? message : 'Unknown Error' }}</div>
    <div slot="footer" class="confirmFooter">
      <button class="ob-btn-grey" @click="closeModal()">Close</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      message: null,
    };
  },
  created() {
    EventBus.$on('LINEUP_EDITOR_OPEN_FAIL_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('LINEUP_EDITOR_OPEN_FAIL_MODAL', this.openModal);
  },
  methods: {
    openModal(error) {
      if (error && error.response && error.response.data) {
        this.message = error.response.data;
      }

      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.message = null;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 10px;
}
.confirmBody {
  flex: 1;
  padding: 15px 10px;
  box-sizing: border-box;
}
.confirmFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>