import Api from '@/api/ObBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

export default {
  authorizeUser() {
    return Api()
        .get('/fsp/account/authorize')
        .then((response) => {
          return response.data;
        });
  },

  resendVerification() {
    return Api()
        .post('/fsp/account/resendEmailVerification')
        .then((response) => {
          return response.data;
        });
  },

  verifyEmail(userId, code) {
    return Api()
        .get('/fsp/account/verify/' + userId + '/' + code)
        .then((response) => {
          return response.data;
        });
  },

  loginUser(email, password, stayLoggedIn, captchaToken, afUserId) {
    const params = {
      email: email,
      password: password,
      staylogged: stayLoggedIn,
      token: captchaToken,
      afUserId: afUserId,
    };
    return Api()
        .post('/fsp/account/login2', params)
        .then((response) => {
          return response.data;
        });
  },

  logoutUser() {
    return Api()
        .get('/fsp/account/logout')
        .then((response) => {
          return response.data;
        });
  },

  loginWithGoogle(jwt, stayLoggedIn) {
    const params = {
      jwt: jwt,
      staylogged: stayLoggedIn,
    };
    return Api()
        .post('/fsp/account/loginWithGoogle', params)
        .then((response) => {
          return response.data;
        });
  },

  checkUsername(username) {
    const params = {
      username: username,
    };
    return Api()
        .post('/fsp/account/checkUsername', params)
        .then((response) => {
          return response.data;
        });
  },

  validateUsername(username) {
    const params = {
      username: username,
    };
    return Api()
        .post('/fsp/account/validateUsername', params)
        .then((response) => {
          return response.data;
        });
  },

  validateCredentials(email, password) {
    const params = {
      email: email,
      password: password,
    };
    return Api()
        .post('/fsp/account/validateCredentials', params)
        .then((response) => {
          return response.data;
        });
  },

  createAccount2(params) {
    return Api()
        .post('/fsp/account/createAccount2', params)
        .then((response) => {
          return response.data;
        });
  },

  createAccount(params) {
    return Api()
        .post('/fsp/account/createAccount', params)
        .then((response) => {
          return response.data;
        });
  },

  applyReferralCode(referralCode) {
    const params = {
      referralCode: referralCode,
    };
    return Api()
        .post('/fsp/account/applyReferralCode', params)
        .then((response) => {
          return response.data;
        });
  },

  validateReferralCode(referralCode) {
    const params = {
      referralCode: referralCode,
    };
    return Api()
        .post('/fsp/account/validateReferralCode', params)
        .then((response) => {
          return response.data;
        });
  },

  getTermsAndConditions() {
    return Api()
        .get('/fsp/account/getTermsAndConditions')
        .then((response) => {
          return response.data;
        });
  },

  forgotPassword(params) {
    return Api()
        .post('/fsp/account/forgotPassword', params)
        .then((response) => {
          return response.data;
        });
  },

  resetPassword(params) {
    return Api()
        .post('/fsp/account/resetPassword', params)
        .then((response) => {
          return response.data;
        });
  },

  loadUserData() {
    return Api()
        .get('/fsp/account/getAccountInfo')
        .then((response) => {
          return response.data;
        });
  },

  setTeamName(sport, name) {
    const params = {};
    params.sport = sport;
    params.name = name;
    return Api()
        .post('/fsp/account/setTeamName', params)
        .then((response) => {
          return response.data;
        });
  },

  setUserName(name) {
    const data = {
      name: name,
    };
    return Api()
        .post('/fsp/account/changeUsername', data)
        .then((response) => {
          return response.data;
        });
  },

  uploadUserLogo(sport, image) {
    const data = new FormData();
    data.append('file', image);
    const params = sport == null || sport === '' ? '' : '?sport=' + sport;
    return Api()
        .post('/fsp/images/fspimageupload' + params, data)
        .then((response) => {
          return response.data;
        });
  },

  selectPresetLogo(sport, logo) {
    const params = '?sport=' + sport + '&logo=' + logo;
    return Api()
        .post('/fsp/account/usePresetLogo' + params)
        .then((response) => {
          return response.data;
        });
  },

  getProfile(userId) {
    let params = '';
    if (userId) {
      params = '?userId=' + userId;
    }
    return Api()
        .get('/fsp/account/getProfile' + params)
        .then((response) => {
          return response.data;
        });
  },

  getFriendSuggestions() {
    return Api()
        .get('/fsp/account/addFriendSuggestions')
        .then((response) => {
          return response.data;
        });
  },

  updateEmailAlerts(emailAlerts) {
    const params = {
      draftUpdates: emailAlerts,
    };
    return Api()
        .post('/fsp/account/updateEmailAlerts', params)
        .then((response) => {
          return response.data;
        });
  },

  convertOwnersbucks(amount) {
    const params = {
      amount: amount,
    };
    return Api()
        .post('/fsp/account/convertOwnersbucks', params)
        .then((response) => {
          return response.data;
        });
  },

  getReferralProfile() {
    return Api()
        .get('/fsp/referral/myProfile')
        .then((response) => {
          return response.data;
        });
  },

  referFriends(emailString) {
    const params = {
      emailIds: emailString,
    };
    return Api()
        .post('/fsp/referral/referFriends', params)
        .then((response) => {
          return response.data;
        });
  },

  getActiveUserSessions() {
    return Api()
        .get('/fsp/account/getActiveUserSessions')
        .then((response) => {
          return response.data;
        });
  },

  lockoutSessionId(sessionId) {
    const params = sessionId;
    return Api()
        .post('/fsp/account/lockoutSessionId?sessionId=' + params)
        .then((response) => {
          return response.data;
        });
  },

  lockoutActiveSessions() {
    return Api()
        .post('/fsp/account/lockoutActiveSessions')
        .then((response) => {
          return response.data;
        });
  },

  updatePushSettings(params) {
    return Api()
        .post('/fsp/account/updatePushSettings', params)
        .then((response) => {
          return response.data;
        });
  },

  sendBugOrFeedback(params) {
    return Api()
        .post('/fsp/account/report/bugorfeedback', params)
        .then((response) => {
          return response.data;
        });
  },

  updateBetaDisclaimer(statusNum) {
    const params = {
      showBetaDisclaimer: statusNum,
    };

    return Api()
        .post('/fsp/account/updateBetaDisclaimer', params)
        .then((response) => {
          return response.data;
        });
  },

  testGeolocation(lat, long) {
    const params = '?lat=' + lat + '&long=' + long;
    return Api()
        .post('/fsp/account/testGeoLocation' + params)
        .then((response) => {
          return response.data;
        });
  },

  updateDraftPriority(sport, value) {
    const params = {
      sport: sport,
      value: value,
    };
    return Api()
        .post('/fsp/account/updateDraftPriority', params)
        .then((response) => {
          return response.data;
        });
  },

  updateContestCheckTimestamp(newTs) {
    const params = {
      contestCheckTimestamp: newTs,
    };
    return Api()
        .post('/fsp/account/setContestCheckTimestamp', params)
        .then((response) => {
          return response.data;
        });
  },

  createUserErrorLog(errorMessage) {
    const params = {
      errorMessage: errorMessage,
    };

    return Api()
        .post('/fsp/account/createUserErrorLog', params)
        .then((response) => {
          return response.data;
        });
  },

  getUserTickets() {
    return Api()
        .get('/fsp/account/getUserTickets')
        .then((response) => {
          return response.data;
        });
  },

  getLocationEligibility() {
    return Api()
        .get('/fsp/account/getLocationEligibility')
        .then((response) => {
          return response.data;
        });
  },

  async checkLocation() {
    const reason = 'Periodic';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp-marketing/checkLocation', {}, config)
        .then((response) => {
          return response.data;
        });
  },
};
