/**
 * @param {number} v
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export function clamp(v, min, max) {
  if (v < min) {
    return min;
  }
  if (v > max) {
    return max;
  }
  return v;
}