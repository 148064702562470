// @ts-check

import EventBus from '@/event-bus';
import Vue from 'vue';


/**
 *
 * @typedef {import('@/api/ObPropsApi').PropContest} PropContest
 * @typedef {import('@/api/ObPropsApi').PropContestState} PropContestState
 * @typedef {import('@/api/ObPropsApi').PropContestStatus} PropContestStatus
 * @typedef {import('@/api/ObPropsApi').PropContestType} PropContestType
 * @typedef {import('@/api/ObPropsApi').Pick} Pick
 * @typedef {import('@/api/ObPropsApi').PickStatus} PickStatus
 * @typedef {import('@/utils/SocketController').default} SocketController
 *
 * @typedef {{
 *  id: string,
 *  state: PropContestState,
 *  payout: number, // cents
 *  payoutMultiplier: number,
 *  picks: SocketPick[],
 *  correctPicks: number,
 *  status: PropContestStatus,
 *  userWon: boolean,
 * }} SocketPropContest
 *
 * @typedef {{
 *  outcome?: 'UNDER'|'OVER',
 *  marketId: number,
 *  status: PickStatus,
 *  isCorrect?: boolean,
 * }} SocketPick
 *
 * @typedef {{
 *  id: number,
 *  result: number,
 * }} MarketResultUpdate
 *
 * @typedef {{
 *  type: 'PROP_CONTEST_UPDATE',
 *  contest: SocketPropContest,
 * }|{
 *  type: 'MARKET_RESULT_UPDATE',
 *  market: MarketResultUpdate,
 * }} SocketUpdate
 *
 * @typedef {PropsContestsMixin & {
 *  PropsContestsMixin_propsContestIndexesById: Map<string, number>,
 *  PropsContestsMixin_propsContestsByMarketId: Map<number, PropContest>,
 *  PropsContestsMixin_handleSocketUpdate: (data: PropContest) => void,
 *  PropsContestsMixin_handlePropContestUpdate: (data: SocketPropContest|Partial<PropContest>) => void,
 *  PropsContestsMixin_handleMarketResultUpdate: (data: MarketResultUpdate) => void,
 *  $SocketController: SocketController,
 * }} PropsContestsMixinThis
 *
 * @typedef {{
 *  propsContests: PropContest[],
 *  setPropsContests: (newContests: PropContest[]) => void,
 *  appendPropsContests: (newContests: PropContest[]) => void,
 *  clearPropsContests: () => void,
 * }} PropsContestsMixin
 */

/**
 * PropsContestsMixin manages a list of props contests and keeps it up to date
 * with socket updates. It adds a list of contests types and methods it. The
 * properties and methods added can be found here {@link PropsContestsMixin}.
 *
 * @example
 * <template>
 *   <li>
 *     <li v-for="contests of propsContests" v-for="contest.id">
 *       {{ contest }}
 *     </li>
 *   </li>
 * </template>
 *
 * <script>
 *   export default Vue.extend({
 *     mixins: [PropsContestsMixin],
 *
 *     created() {
 *       loadPropsContests().then((contests) => {
 *         // Sets the contests to watch, this updates this.propsContests
 *         this.setPropsContests(contests)
 *       })
 *     },
 *
 *     methods: {
 *       loadMore() {
 *         loadMorePropsContests().then((contests) => {
 *           // Adds new contests to watch to the end of the list
 *           this.appendPropsContests(contests)
 *         })
 *       },
 *
 *       clear() {
 *         // Removes all contests from the list
 *         this.clearPropsContests();
 *       }
 *     },
 *   })
 * </script>
 */
export default Vue.extend({
  /**
   * @return {Partial<PropsContestsMixinThis>}
   */
  data() {
    return {
      propsContests: [],
      PropsContestsMixin_propsContestIndexesById: new Map(),
      PropsContestsMixin_propsContestsByMarketId: new Map(),
    };
  },

  /**
   * @this {PropsContestsMixinThis}
   */
  created() {
    EventBus.$on('SOCKET_BROADCAST', this.PropsContestsMixin_handleSocketUpdate);
  },

  /**
   * @this {PropsContestsMixinThis}
   */
  destroyed() {
    EventBus.$off('SOCKET_BROADCAST', this.PropsContestsMixin_handleSocketUpdate);
    this.clearPropsContests();
  },

  methods: {
    /**
     * @this {PropsContestsMixinThis}
     * @param {SocketUpdate} data
     * @return {void}
     */
    PropsContestsMixin_handleSocketUpdate(data) {
      switch (data.type) {
        case 'PROP_CONTEST_UPDATE':
          this.PropsContestsMixin_handlePropContestUpdate(data.contest);
          break;
        case 'MARKET_RESULT_UPDATE':
          this.PropsContestsMixin_handleMarketResultUpdate(data.market);
          break;
      }
    },

    /**
     * @this {PropsContestsMixinThis}
     * @param {SocketPropContest|Partial<PropContest>} contest
     * @return {void}
     */
    PropsContestsMixin_handlePropContestUpdate(contest) {
      const i = this.PropsContestsMixin_propsContestIndexesById.get(contest.id);
      if (i === undefined) {
        return;
      }

      const oldContest = this.propsContests[i];
      const newContest = {
        ...this.propsContests[i],
        ...contest,
        picks: this.propsContests[i].picks.map((pick)=>{
          const newPick = contest.picks.find((newPick) => pick.marketId === newPick.marketId);
          return {...pick, ...newPick};
        }),
      };

      this.propsContests = [
        ...this.propsContests.slice(0, i),
        newContest,
        ...this.propsContests.slice(i+1),
      ];

      EventBus.$emit('PROPS_CONTEST_CHANGE_'+newContest.id, newContest, oldContest);
    },

    /**
     * @this {PropsContestsMixinThis}
     * @param {MarketResultUpdate} newPick
     * @return {void}
     */
    PropsContestsMixin_handleMarketResultUpdate(newPick) {
      const contest = this.PropsContestsMixin_propsContestsByMarketId.get(newPick.id);
      if (contest === undefined) {
        return;
      }

      this.PropsContestsMixin_handlePropContestUpdate({
        id: contest.id,
        picks: contest.picks.map((existingPick) => {
          if (existingPick.marketId !== newPick.id) {
            return existingPick;
          }
          return {...existingPick, ...newPick};
        }),
      });
    },

    /**
     * @this {PropsContestsMixinThis}
     * @param {PropContest[]} newContests
     * @return {void}
     */
    setPropsContests(newContests) {
      this.clearPropsContests();
      this.appendPropsContests(newContests);
    },

    /**
     * @this {PropsContestsMixinThis}
     * @param {PropContest[]} newContests
     * @return {void}
     */
    appendPropsContests(newContests) {
      let i = this.propsContests.length;

      const filteredContests = newContests.filter((contest) => !this.PropsContestsMixin_propsContestIndexesById.has(contest?.id));
      this.propsContests = this.propsContests.concat(filteredContests);

      for (const contest of filteredContests) {
        this.$SocketController.subscribeToRoom('PROP_CONTEST_UPDATE_' + contest.id);
        for (const pick of contest.picks) {
          this.$SocketController.subscribeToRoom('MARKET_RESULT_UPDATE_' + pick.marketId);
          this.PropsContestsMixin_propsContestsByMarketId.set(pick.marketId, contest);
        }
        this.PropsContestsMixin_propsContestIndexesById.set(contest.id, i);
        i++;
      }
    },

    /**
     * @this {PropsContestsMixinThis}
     * @return {void}
     */
    clearPropsContests() {
      for (const contest of this.propsContests) {
        this.$SocketController.unsubscribeFromRoom('PROP_CONTEST_UPDATE_' + contest.id);
        for (const pick of contest.picks) {
          this.$SocketController.unsubscribeFromRoom('MARKET_RESULT_UPDATE_' + pick.marketId);
        }
      }
      this.propsContests = [];
      this.PropsContestsMixin_propsContestIndexesById = new Map();
      this.PropsContestsMixin_propsContestsByMarketId = new Map();
    },
  },
});