<template>
  <!-- Right Column - News Feed -->
  <div class="feedSection">
    <SponsorImage class="feedSponsor" sponsorKey="dashboard-feed"
      width="350px" height="185px"
    />
    <div class="feedsContainer">
      <FeedsView />
    </div>
  </div>
</template>

<script>
import FeedsView from '@/views/Feeds/FeedsView';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  components: {
    FeedsView,
    SponsorImage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.feedSection {
  display: flex;
  flex-direction: column;
  flex: 0;
  position: sticky;
  top: calc(var(--ob-nav-offset) + 15px);
  height: calc(100vh - (75px + var(--ob-nav-offset)));
  margin-left: 15px;
}

.feedSponsor {
  margin-bottom: 5px;
}

.feedsContainer {
  overflow: hidden;
  width: 350px;
  min-width: 350px;
  border-radius: 5px;
  flex: 1;
  background: var(--obcolor-background-4);
  transition: top 0.3s ease-out, height 0.3s ease-out !important;
  top: calc(var(--ob-nav-offset) + 104px) !important;
  height: calc(100vh - (167px + var(--ob-nav-offset)));
}
</style>