<template>
  <tr @click.stop="openPlayerModal()">
    <td style="box-sizing: border-box; max-width: 240px; min-width: 240px;">
      <MatchupPlayerInfo :league="matchup" :entry="entry" :isMyTeam="isMyTeam"
        :swapStatus="swapStatus" :showSwap="true"
      />
    </td>
    <!-- 7 Days -->
    <td v-for="n in 7" :key="n" style="box-sizing: border-box; min-width: 120px;"
      :class="{todayHighlight: todaysIndex == n-1}"
    >
      <div v-if="entry.fspGames" style="box-sizing: border-box;">
        <div v-if="entry.fspGames[n-1]">
          <LineupGameEntry :leagueId="leagueId" :player="entry" :game="entry.fspGames[n-1]"
            :sport="sport" :isMyTeam="isMyTeam" :playedGames="playedGames"
          />
        </div>

        <!-- DoubleHeader, typically MLB only -->
        <div v-if="entry.fspGames[(n-1)+'DH']" style="margin-top: 10px;">
          <LineupGameEntry :leagueId="leagueId" :player="entry" :game="entry.fspGames[(n-1)+'DH']"
            :sport="sport" :isMyTeam="isMyTeam" :playedGames="playedGames"
          />
        </div>
      </div>
    </td>
    <!-- Total -->
    <td class="totalVal" style="color: var(--obcolor-font-secondary);">
      <div>{{ sumPlayerProj(entry) }}</div>
    </td>
    <td class="totalVal" style="color: var(--obcolor-ob-blue); font-weight: bold;">
      <div>{{ sumPlayerPoints(entry) }}</div>
    </td>
    <td class="posGroupEmpty" v-if="entry.dropped"></td>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import MatchupPlayerInfo from '@/views/Contest/MatchupPlayerInfo';
import EventBus from '@/event-bus';
import LineupGameEntry from './LineupGameEntry.vue';

export default {
  props: {
    entry: Object,
    todaysIndex: Number,
    leagueId: String,
    isMyTeam: Boolean,
    sport: String,
    playedGames: Object,
    matchup: Object,
    swapStatus: String,
  },

  components: {
    MatchupPlayerInfo,
    LineupGameEntry,
  },

  methods: {

    openPlayerModal() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.entry.id, this.sport, this.leagueId);
    },

    sumPlayerPoints(player) {
      const games = player.fspGames;
      let totalPoints = new Decimal(0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        if (this.playedGames[gameKey]) {
          totalPoints = totalPoints.plus(new Decimal(gJSON.ObPoints.total));
        }
      }
      return Number(totalPoints);
    },

    sumPlayerProj(player) {
      const games = player.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        if (gJSON.inRange && gJSON.ProjPoints != null) {
          totalPoints += gJSON.ProjPoints.total;
        }
      }
      return totalPoints.toFixed(0);
    },

  },
};
</script>

<style lang="scss" scoped>

tr:hover {
  background: var(--obcolor-background-3) !important;
  cursor: pointer;
}
td, th {
  padding: 8px 0;
  white-space: nowrap;
}
.todayHighlight {
  background: var(--obcolor-background-2);
}

.totalVal {
  width: 60px;
  box-sizing: border-box;
}
.totalVal > div {
  min-width: 48px;
}
.posGroupEmpty {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  box-sizing: border-box;
}
</style>