<template>
  <Modal v-if="isOpen && promoCode" v-model="isOpen" containerStyle="width: 460px; padding: 0; border-radius: 5px;"
    bodyStyle="margin: 0; background: var(--obcolor-background-5);" :hideCloseButton="true"
  >
    <div slot="header" class="header">
      Ticket Automatically Applied!
    </div>

    <div slot="body" class="body">
      <div>Ticket <span class="promo-code-name">{{ promoCode.id }}</span> has been automatically applied to your entry.</div>
      <div>You will receive one free <span class="fee">{{ promoCode.fee }}</span> submission.</div>
    </div>

    <div slot="footer" class="footer">
      <button class="ob-btn" @click="closeModal()">Continue</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      promoCode: null,
    };
  },

  created() {
    EventBus.$on('OPEN_PROMO_AUTO_APPLY_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_PROMO_AUTO_APPLY_MODAL', this.openModal);
  },

  methods: {
    openModal(promoCode) {
      this.promoCode = promoCode;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--obcolor-background-5);
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.body {
  text-align: center;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 0px 15px 15px;

  .promo-code-name {
    font-weight: bold;
    color: var(--obcolor-yellow);
  }

  .fee {
    color: var(--obcolor-green);
    font-weight: bold;
  }
}

.footer {
  background: var(--obcolor-background-3);
  padding: 15px;

  button {
    height: 100%;
    width: 100%;
  }
}
</style>