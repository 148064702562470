// @ts-check

import ObPropsApi from '@/api/ObPropsApi';
import EventBus from '@/event-bus';
import { CanceledError } from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('@/api/ObPropsApi').MarketType} MarketType
 * @typedef {import('@/utils/SocketController').default} SocketController
 *
 * @typedef {{
 *  type: 'PULL_MARKET_TYPES',
 * }} SocketUpdate
 *
 * @typedef {MarketTypesMixin & {
 *  MarketTypesMixin_loadMarketTypes(sport: string): Promise<void>
 *  MarketTypesMixin_handleSocketUpdate(data: SocketUpdate): void,
 *  $SocketController: SocketController,
 *  MarketTypesMixin_abortController: AbortController|null
 * }} MarketsMixinThis
 *
 * @typedef {{
 *  marketTypesSport: string|null,
 *  marketTypes: MarketType[]|null,
 *  marketTypesLoading: boolean,
 *  setMarketTypeSport(sport: string): void
 * }} MarketTypesMixin
 */

/**
 * MarketTypesMixin manages a list of markets types and keeps it up to date with
 * socket updates. It adds a list of market types and a method to set the sport
 * to load. The properties and methods added can be found here
 * {@link MarketTypesMixin}.
 *
 * @example
 * <template>
 *   <li>
 *     <li v-for="marketType of marketTypes" v-for="marketType.id">
 *       {{ marketType }}
 *     </li>
 *   </li>
 * </template>
 *
 * <script>
 *   export default Vue.extend({
 *     mixins: [MarketTypesMixin],
 *
 *     created() {
 *       this.setMarketTypeSport('NFL');
 *     },
 *
 *     methods: {
 *       setSport(sport) {
 *         this.setMarketTypeSport(sport)
 *       },
 *     },
 *   })
 * </script>
 */
export default Vue.extend({
  /**
   * @return {Partial<MarketsMixinThis>}
   */
  data() {
    return {
      marketTypesSport: null,
      marketTypes: [],
      marketTypesLoading: false,
      MarketTypesMixin_abortController: null,
    };
  },

  /**
   * @this {MarketsMixinThis}
   */
  created() {
    EventBus.$on('SOCKET_BROADCAST', this.MarketTypesMixin_handleSocketUpdate);
  },

  /**
   * @this {MarketsMixinThis}
   */
  destroyed() {
    EventBus.$off('SOCKET_BROADCAST', this.MarketTypesMixin_handleSocketUpdate);
    if (this.marketTypesSport !== null) {
      this.$SocketController.unsubscribeFromRoom(
          'PULL_MARKET_TYPES_' + this.marketTypesSport,
      );
    }
  },

  watch: {
    /**
     * @this {MarketsMixinThis}
     * @param {string|null} to
     * @param {string|null} from
     */
    marketTypesSport(to, from) {
      if (from == to) {
        return;
      }
      if (from) {
        this.$SocketController.unsubscribeFromRoom('PULL_MARKET_TYPES_' + from);
      }
      this.marketTypes = [];
      if (to) {
        this.$SocketController.subscribeToRoom('PULL_MARKET_TYPES_' + to);

        this.marketTypesLoading = true;
        this.MarketTypesMixin_loadMarketTypes(to).finally(() => {
          this.marketTypesLoading = false;
        });
      }
    },
  },

  methods: {
    /**
     * @param {string} sport
     * @return {void}
     */
    setMarketTypeSport(sport) {
      this.marketTypesSport = sport;
    },

    /**
     * @this {MarketsMixinThis}
     * @param {string} sport
     * @return {Promise<void>}
     */
    MarketTypesMixin_loadMarketTypes(sport) {
      if (this.MarketTypesMixin_abortController) {
        this.MarketTypesMixin_abortController.abort();
      }
      this.MarketTypesMixin_abortController = new AbortController();
      return ObPropsApi.getActiveMarketTypes(sport, {
        signal: this.MarketTypesMixin_abortController.signal,
      })
          .then((marketTypes) => {
            this.marketTypes = marketTypes;
          })
          .catch((error) => {
            if (error instanceof CanceledError) {
              return;
            }
            console.warn(error);
          });
    },

    /**
     * @this {MarketsMixinThis}
     * @param {SocketUpdate} data
     * @return {void}
     */
    MarketTypesMixin_handleSocketUpdate(data) {
      if (data.type !== 'PULL_MARKET_TYPES') {
        return;
      }

      this.MarketTypesMixin_loadMarketTypes(this.marketTypesSport);
    },
  },
});
