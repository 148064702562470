<template>
  <div v-if="league.curMatchup" style="min-height: max-content;">
    <!-- Contest Details -->
    <div class="detailsBox">
      <div class="sportBox">
        <div>
          <img :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="league.sport" style="height: 30px;">
          <div v-if="league.isPrivate" class="privateIcon"><i class="fas fa-lock"></i></div>
        </div>
      </div>
      <div style="min-width: 0; flex: 1; display: flex; flex-direction: column;">
        <div class="detailsHeader">{{ league.playersMax }} Teams</div>
        <div style="margin-top: 5px;">Head-2-Head</div>
        <div style="min-width: 0; flex: 1; display: flex; margin-top: 5px;">
          <span class="contestName" v-if="league.contestName">{{ league.contestName }}</span>
          <span class="contestName" v-else>{{ league.id }}</span>
        </div>
        <div style="margin-top: 5px;">
          <span style="margin-right: 4px;">Entry Fee:</span>
          <img v-if="!league.realMoney" :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
            alt="OwnersBucks Icon"
          >
          <span>{{ league.fee }}</span>
        </div>
        <!-- Temp Links -->
        <div style="margin-top: 7px; margin-bottom: 5px; font-weight: bold;">
          <router-link class="ob-link" :to="'/draft/' + league.draftId">Draft</router-link>
          <router-link class="ob-link" v-if="league.state != 'DRAFTING'"
            style="margin-left: 10px;" :to="'/contest/matchup/schedule1/' + league.id"
          >
            Lineup
          </router-link>
          <div @click.stop="openBracketModal(league)" class="ob-link"
            style="margin-left: 10px; display: inline-block; color: var(--obcolor-font-primary);"
          >
            Bracket
          </div>
        </div>
      </div>
    </div>

    <!-- Current Matchup -->
    <router-link :to="getMatchupLink()" class="matchupBox" :style="league.curMatchup && league.curMatchup.myTeam === 2 ? 'flex-direction: column-reverse;' : ''">
      <div class="matchupTeam">
        <UserLogo v-if="league.curMatchup.myTeam !== 1" :id="league.curMatchup.team1Id" :sport="league.sport"
          :size="40"
          v-tooltip.bottom="{class: 'ObTooltip', content: league.curMatchup.team1Name}"
        />
        <UserLogo v-else :id="league.curMatchup.team1Id" :sport="league.sport"
          :size="40"
        />
        <div style="flex: 1; margin-top: -6px; position: relative;" :style="curUserWinning() ? 'color: var(--obcolor-ob-blue)' : ''">
          <i v-if="league.curMatchup.coinTossWinner == 'team1'" class="fas fa-coin coinFlip"></i>
          <div class="scoreBox" :style="getUpdateStyle(1)">
            {{ league.curMatchup.team1Score || '0' }}
          </div>
          <!-- Swap count under team score -->
          <div class="swapsBox">
            <div class="swapsText">Swaps</div>
            <div :class="teamMovesRem(1) > 0 ? 'swapUnused' : 'swapUsed'"></div>
            <div :class="teamMovesRem(1) > 1 ? 'swapUnused' : 'swapUsed'"></div>
          </div>
        </div>
      </div>
      <div class="matchupTeam">
        <UserLogo v-if="league.curMatchup.myTeam !== 2" :id="league.curMatchup.team2Id" :sport="league.sport"
          :size="40"
          v-tooltip.bottom="{class: 'ObTooltip', content: league.curMatchup.team2Name}"
        />
        <UserLogo v-else :id="league.curMatchup.team2Id" :sport="league.sport"
          :size="40"
        />
        <div style="flex: 1; margin-top: -6px; position: relative;" :style="!curUserWinning() ? 'color: var(--obcolor-ob-blue)' : ''">
          <i v-if="league.curMatchup.coinTossWinner == 'team2'" class="fas fa-coin coinFlip"></i>
          <div class="scoreBox" :style="getUpdateStyle(2)">
            {{ league.curMatchup.team2Score || '0' }}
          </div>
          <!-- Swap count under team score -->
          <div class="swapsBox">
            <div class="swapsText">Swaps</div>
            <div :class="teamMovesRem(2) > 0 ? 'swapUnused' : 'swapUsed'"></div>
            <div :class="teamMovesRem(2) > 1 ? 'swapUnused' : 'swapUsed'"></div>
          </div>
        </div>
      </div>
    </router-link>

    <!-- Graph -->
    <div>
      <LiveContestGraph :selectedDay="selectedDay" :startingDay="startingDay" :league="league"
        :gamesData="gamesData" :gameFinishedStatus="gameFinishedStatus"
      />
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import LiveContestGraph from './LiveContestGraph';
import EventBus from '@/event-bus';

export default {

  components: {
    UserLogo,
    LiveContestGraph,
  },

  props: {
    selectedDay: Object,
    startingDay: Object,
    league: Object,
    scoreUpdated: Object,
    gamesData: Object,
    gameFinishedStatus: Object,
  },

  methods: {
    curUserWinning() {
      const matchup = this.league.curMatchup;
      return matchup.team1Score > matchup.team2Score ||
        (matchup.team1Score == matchup.team2Score && matchup.coinTossWinner == 'team1');
    },

    teamMovesRem(teamNum) {
      const teamId = teamNum === 1 ? this.league.curMatchup.team1Id : this.league.curMatchup.team2Id;
      if (teamId && this.gamesData && this.gamesData[teamId]) {
        return this.gamesData[teamId].movesRem || 0;
      }
      return 0;
    },

    getMatchupLink() {
      return '/contest/matchup/summary/' + this.league.id;
    },

    getUpdateStyle(num) {
      if (this.scoreUpdated) {
        const status = this.scoreUpdated[this.league.id +'_'+ num];
        if (status === '+') {
          return 'background: #3ABC31; color: white;';
        } else if (status === '-') {
          return 'background: #F81818; color: white;';
        }
      }
      return '';
    },

    openBracketModal(league) {
      EventBus.$emit('SHOW_BRACKET_MODAL', league);
    },
  },

};
</script>

<style lang="scss" scoped>
.sportBox {
  width: 55px;
  min-width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privateIcon {
  color: var(--obcolor-font-secondary);
  text-align: center;
  margin-top: 3px;
}

.detailsBox {
  width: 225px;
  min-width: 225px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  border-right: 1px solid var(--obcolor-background-page);
  color: var(--obcolor-font-secondary);
}

.detailsHeader {
  font-size: 16px;
  color: var(--obcolor-font-primary);
}

.matchupBox {
  width: 120px;
  min-width: 120px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--obcolor-background-page);
  flex-direction: column;
  margin-bottom: -10px;
  cursor: pointer;
}

.matchupBox:hover {
  background: var(--obcolor-background-4);
}

.matchupTeam {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}

.scoreBox {
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  min-height: 25px;
  transition-duration: 400ms;
}

.contestName {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownersbucksIcon {
  height: 10px;
  margin-left: 2px;
  margin-right: 5px;
  margin-bottom: -1px;
}

.swapsBox {
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: -12px;
}

.swapsText {
  font-size: 10px;
  margin-right: 5px;
  margin-top: 1px;
  color: var(--obcolor-font-secondary);
  font-weight: bold;
}

.swapUnused {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: var(--obcolor-ob-blue);
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.swapUsed {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: var(--obcolor-background-1);
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.coinFlip {
  color: var(--obcolor-coin);
  font-size: 12px;
  position: absolute;
  left: -7px;
  top: 24px;
}
</style>
