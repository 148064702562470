const Tooltips = {};

Tooltips.install = function(Vue, options) {
  const TOOLTIPS = {
    Password_Requirements: [
      {text: '• At least 8 characters'},
      {text: '• Must contain a number'},
      {text: '• Must contain a letter'},
    ],

    Lobby_EntryType: [
      {title: '$ (USD)', text: 'contests are played with real money.'},
      {title: 'OwnersBucks', text: 'contests are played with virtual money.'},
      {title: 'Rookie', text: 'contests are only for those who have played less than 50 contests.'},
    ],

    Lobby_GameType: [
      {title: 'Tournament', text: 'contests are played against a large pool of users. Submit your lineup within the game opp and salary cap limit.'},
      {title: 'H2H', text: 'contests pit you against a single opponent, winner moves on. Draft your team LIVE and build the best team.'},
    ],

    Lobby_StartType: [
      {title: 'When Filled', text: 'lets users know that a H2H draft will start when the max number of entrants is met.'},
      {title: 'Scheduled', text: 'H2H contest drafts will only begin at the scheduled time AND when it has filled.'},
    ],

    SalaryCap_BestBall: [
      {text: 'Best Ball has been activated for certain positions. This means that only the BEST games from your game opportunities will be scored'},
    ],

    SalaryCap_UnusedOpps: [
      {text: 'Make sure you have at least met your game opportunities to maxmize your ability to earn fantasy points'},
    ],

    SalaryCap_PGAG: [
      {text: 'Projected Games/Available Games'},
    ],

    SalaryCap_VS: [
      {text: 'Opponent'},
    ],

    SalaryCap_Time: [
      {text: 'Game Time'},
    ],

    SalaryCap_OPRK: [
      {text: 'Opponent Rank'},
    ],

    SalaryCap_pAvg: [
      {text: 'Projection'},
    ],

    SalaryCap_sAvg: [
      {text: 'Season Average'},
    ],

    SalaryCap_Proj: [
      {text: 'Projected Points'},
    ],

    SalaryCap_GP: [
      {text: 'Games Played'},
    ],

    SalaryCap_GR: [
      {text: 'Games Remaining'},
    ],

    SalaryCap_FPTS: [
      {text: 'Fantasy Points'},
    ],

    SalaryCap_Editor_SalaryRemaining: [
      {text: 'Total change to your remaining salary from your pending swaps'},
    ],

    SalaryCap_Editor_SwapFee: [
      {text: 'This transaction has a 20% swap fee applied to the player’s salary for their remaining projected games.'},
    ],

    SalaryCap_Editor_SwapFee_TodayExcluded: [
      {text: 'This transaction has a 20% swap fee applied to the player’s remaining salary. Today’s game is excluded from the fee due to the player’s status.'},
    ],

    SalaryCap_Editor_Refund_Excluded: [
      {text: 'Deactivated games are not included in your refund because they have already been removed from your total committed salary.'},
    ],

    LeagueModal_H2H_Draft: [
      {text: 'Drafts start at either their scheduled draft times or 5 minutes after filling for \'when filled\' contests. You will receive a push alert (app or browser) and a email minutes before you draft. Draft order is randomly determined when the contests is generated. If you are unable to attend or go offline during a draft, the autodraft function kicks in selecting the highest projected player for your remaining available positions. You may set a draft queue once you enter the draft screen.'},
    ],

    LeagueModal_H2H_GameLimits: [
      {text: 'Game limits is how OwnersBox ensures fairness over the course of a 7 day fluctuating schedule. Once your players have reached the game limit assigned to their position, future games will not count.'},
    ],

    LeagueModal_H2H_PostDraft: [
      {text: 'At the conclusion of the draft a randomizer will determine who you are matched up againts for the first round. The gold coin represents who wins in the event of a tie.'},
    ],

    LeagueModal_H2H_Swaps: [
      {text: 'Injured or inactive players can be dropped and replaced without consuming a swap. All drafted players that are dropped are not available for the rest of the contest. All dropped players are not available for the rest of the contest.'},
    ],

    LeagueModal_H2H_WhenFilled: [
      {text: 'In contrast to a scheduled contest that drafts at a specific time, the \'when filled\' drafts within 5 minutes of the contest filling. User volume fluctuates so if you cannot get into a \'when filled\' contest in a reasonable time, we recommend joining or creating a scheduled contest from the lobby.'},
    ],

    LeagueModal_H2H_Cutoff: [
      {text: 'If the cutoff time is reached, your contest starts the next gameday.'},
    ],

    LeagueModal_Tournament_Cutoff: [
      {text: 'In tournament contests, the cutoff is the time when your lineup entry must be completed and submitted to be eligible to participate in the tournament.'},
    ],

    LeagueModal_H2H_Cutoff_Scheduled: [
      {text: 'If the contest does not fill by the cutoff time, it will be disbanded and entry fees will be refunded.'},
    ],

    LeagueModal_Tournament_Roster: [
      {text: 'You cannot exceed the salary cap when building your lineup. You will see the players per game cost as well as their total cost (per game $ * projected games). It is also important to pick players with enough games to fill your game opportunities by position. Game opportunities represent the number of games that count towards your total fantasy points.'},
    ],

    LeagueModal_Tournament_BestBall: [
      {text: 'Best ball means we will use the games with the highest fantasy points up until the game limit is reached at that position. Having extra scheduled games means you will bump your lowest scoring game(s).'},
    ],

    LeagueModal_Tournament_ContestStart: [
      {text: 'This is when the contest starts and you can no longer submit more entries.'},
    ],

    LeagueModal_Tournament_GameLimits: [
      {text: 'Game limits is how OwnersBox ensures fairness over the course of a 7 day fluctuating schedule. Once your players have reached the game limit assigned to their position, only their highest scoring games will count towards your fantasy score.'},
    ],

    LeagueModal_Tournament_WeekLong: [
      {text: 'Our contests are a week (7-days) long.'},
    ],

    LeagueModal_Tournament_SwapFee: [
      {text: 'When swapping a player, 20% of the remaining salary from the player you’re dropping will be deducted from the available salary to be used towards a new player.'},
    ],

    BattlePass_Level: [
      {text: 'Your battle pass level increases as you complete challenges and earn experience points (XP). Complete more challenges to increase your level and earn more rewards.'},
    ],

    BattlePass_RedeemRate: [
      {text: 'When you enter a real money contest, a percentage of the entry fee, from your existing OwnersBucks balance, will become redeemable into real money.'},
    ],

    Dashboard_Live_Scoring: [
      {text: 'Live scoring includes real-money contests that have already started. Upcoming contests and OwnersBucks contests are excluded.'},
    ],

    Available_to_Withdraw: [
      {text: 'Equal to balance minus the playthrough requirements of promotions to your account.'},
    ],
  };

  const Tooltips = {
    getTooltips(key) {
      return TOOLTIPS[key];
    },
  };

  Vue.prototype.$Tooltips = Tooltips;
};

export default Tooltips;
