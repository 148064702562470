import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import Draft from '../views/Draft/DraftView.vue';
import SalaryCapLineupBuilder from '../views/SalaryCapGame/SalaryCapLineupBuilder.vue';
import SalaryTeamView from '../views/SalaryContest/SalaryTeamView.vue';
import ContestMatchup from '../views/Contest/MatchupView.vue';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/Login/LoginView.vue';
import ForgotPassword from '../views/Login/ForgotPassword.vue';
import StartPlaying from '../views/OnBoardingSteps/StartPlaying.vue';
import ChangePasswordView from '../views/Account/ChangePasswordView.vue';
import AccountView from '../views/Account/AccountView.vue';
import ProfileView from '../views/Profile/ProfileView.vue';
import BattlePassView from '../views/BattlePass/BattlePassView.vue';
import KnowledgeBaseView from '../views/KnowledgeBase/KnowledgeBaseView.vue';
import ResponsiblePlayView from '../views/ResponsiblePlay/ResponsiblePlayView.vue';
import TransactionsView from '../views/Finance/TransactionsView.vue';
import BonusesView from '../views/Finance/BonusesTransactions.vue';
import LobbyView from '../views/Lobby/LobbyView.vue';
import UpcomingActiveSalaryCap from '../views/ContestsList/UpcomingActiveSalaryCap.vue';
import PlayerPicksDashboard from '../views/ContestsList/PlayerPicksDashboard.vue';
import UpcomingActiveLL from '../views/ContestsList/UpcomingActiveLL.vue';
import CompletedContests from '../views/ContestsList/CompletedContests.vue';
import ContestView from '../views/Contest/ContestView.vue';
import Leaderboard from '../views/Leaderboards/Leaderboard.vue';
import PlayerListView from '../views/PlayerList/PlayerListView.vue';
import TodaysStatsView from '../views/PlayerList/TodaysStatsView.vue';
import DepositView from '../views/Finance/deposit/DepositView.vue';
import CancelDepositView from '../views/Finance/CancelDepositView.vue';
import WithdrawView from '../views/Finance/withdraw/WithdrawView.vue';
import CancelWithdrawalView from '../views/Finance/CancelWithdrawalView.vue';
import ReferFriendView from '../views/Finance/ReferFriendView.vue';
import UserLimitsView from '../views/ResponsiblePlay/UserLimitsView.vue';
import UserLockoutView from '../views/ResponsiblePlay/UserLockoutView.vue';
import TermsAndConditionsView from '../views/KnowledgeBase/TermsAndConditionsView.vue';
import WaitlistView from '../views/Waitlist/WaitlistView.vue';
import VerifyEmailView from '../views/Login/VerifyEmailView.vue';
import NotVerifiedView from '../views/Login/NotVerifiedView.vue';
import LoginHistory from '../views/Account/LoginHistory.vue';
import PrivacyPolicyView from '../views/KnowledgeBase/PrivacyPolicyView.vue';
import HowToPlayView from '../views/HowToPlay/HowToPlayView.vue';
import OnBoardingViewSteps from '../views/OnBoardingSteps/OnBoardingView';
import VerifyAccountView from '../views/VerifyAccount/VerifyAccountView.vue';
import VerifyAccountById from '../views/VerifyAccount/VerifyAccountById.vue';
import MobileAndroidApkView from '../views/MobileAndroidApk/MobileAndroidApkView.vue';
import MobileWarningView from '../views/MobileAndroidApk/MobileWarningView.vue';
import EventBus from '@/event-bus';
import LightningLineupBuilder from '../views/LightningLineups/LineupBuilder/LightningLineupBuilder.vue';
import LightningLiveView from '../views/LightningLineups/Live/LightningLiveView.vue';
import PropsBuilder from '../views/Props/LineupBuilder/PropsBuilder.vue';
import PageNotFound from '../views/PageNotFound/PageNotFound';
import ScoresView from '../views/Scores/ScoresView.vue';
import SalaryCapLineupView from '../views/SalaryCapLineups/SalaryCapLineupView.vue';
import IntegrationsView from '../views/Integrations/IntegrationsView';
import IntegrationStatusView from '../views/Integrations/IntegrationStatusView';
import GameSlateLineupBuilder from '../views/SalaryCapLineups/GameSlateLineupBuilder.vue';
import openApp from '@/utils/MobileRedirectDeeplink';
import OwnersBucksView from '@/views/OwnersBucks/OwnersBucksView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/players/:sport?',
    name: 'playerlist',
    component: PlayerListView,
  },
  {
    path: '/players-today',
    name: 'todays-stats',
    component: TodaysStatsView,
  },
  {
    path: '/draft/:draftId?',
    name: 'draft',
    component: Draft,
  },
  {
    path: '/lineup-builder/:contestId?/:teamId?',
    name: 'lineup-builder',
    component: SalaryCapLineupBuilder,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/lightning-lineups/builder/:sport?/:mode?',
    name: 'lightning-lineups-builder',
    component: LightningLineupBuilder,
    meta: {subMenu: 'lightninglineups'},
  },
  {
    path: '/lightning-lineups/live/:contestId',
    name: 'll-live',
    component: LightningLiveView,
    meta: {subMenu: 'lightninglineups'},
  },
  {
    path: '/salarycontest/:view?/:contestId?/:teamId?/:swapPlayerId?',
    name: 'salarycontestteam',
    component: SalaryTeamView,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/contest/matchup/:selectedTab?/:leagueId?/:matchId?/:playerId?',
    alias: '/contest/matchup/:selectedTab?/:leagueId?/:matchId?/swap/:playerId?',
    name: 'contest-matchup',
    component: ContestMatchup,
  },
  {
    path: '/salarycap/lineups',
    name: 'salarycap-lineups',
    component: SalaryCapLineupView,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/salarycap/create-lineup/:slateId',
    name: 'salarycap-create-lineup',
    component: GameSlateLineupBuilder,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/salarycap/edit-lineup/:lineupId?',
    name: 'salarycap-edit-lineup',
    component: GameSlateLineupBuilder,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/lobby/:sport?/:format?',
    name: 'lobby',
    component: LobbyView,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/salarycap/upcoming',
    name: 'salary-cap-upcoming',
    component: UpcomingActiveSalaryCap,
    meta: {subMenu: 'salarycap'},
    props: {contestState: 'upcoming'},
  },
  {
    path: '/salarycap/live',
    name: 'salary-cap-active',
    component: UpcomingActiveSalaryCap,
    meta: {subMenu: 'salarycap'},
    props: {contestState: 'live'},
  },
  {
    path: '/salarycap/completed',
    name: 'salary-cap-completed',
    component: CompletedContests,
    meta: {subMenu: 'salarycap'},
    props: {contestType: 'tournament'},
  },
  {
    path: '/lightning-lineups/upcoming',
    name: 'lightning-lineups-upcoming',
    component: UpcomingActiveLL,
    meta: {subMenu: 'lightninglineups'},
    props: {contestState: 'upcoming'},
  },
  {
    path: '/lightning-lineups/live',
    name: 'lightning-lineups-live',
    component: UpcomingActiveLL,
    meta: {subMenu: 'lightninglineups'},
    props: {contestState: 'live'},
  },
  {
    path: '/lightning-lineups/completed',
    name: 'lightning-lineups-completed',
    component: CompletedContests,
    meta: {subMenu: 'lightninglineups'},
    props: {contestType: 'lightning'},
  },
  {
    path: '/player-picks/lobby/:sport?',
    name: 'player-picks-lobby',
    component: PropsBuilder,
    meta: {subMenu: 'playerpicks'},
  },
  {
    path: '/player-picks/live',
    name: 'player-picks-live',
    component: PlayerPicksDashboard,
    meta: {subMenu: 'playerpicks'},
    props: {contestState: 'active'},

  },
  {
    path: '/player-picks/upcoming',
    name: 'player-picks-upcoming',
    component: PlayerPicksDashboard,
    meta: {subMenu: 'playerpicks'},
    props: {contestState: 'upcoming'},
  },
  {
    path: '/player-picks/completed',
    name: 'player-picks-completed',
    component: PlayerPicksDashboard,
    meta: {subMenu: 'playerpicks'},
    props: {contestState: 'completed'},
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: Leaderboard,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/contest/:leagueId?',
    name: 'contest',
    component: ContestView,
    meta: {subMenu: 'salarycap'},
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
  },
  {
    path: '/loginhistory',
    name: 'loginhistory',
    component: LoginHistory,
  },
  {
    path: '/profile/:userId?',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/battlepass',
    name: 'battlepass',
    component: BattlePassView,
  },
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
    component: KnowledgeBaseView,
  },
  {
    path: '/responsibleplay',
    name: 'responsibleplay',
    component: ResponsiblePlayView,
  },
  {
    path: '/transactions/:type?',
    name: 'transactions',
    component: TransactionsView,
  },
  {
    path: '/bonuses',
    name: 'bonuses',
    component: BonusesView,
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: DepositView,
  },
  {
    path: '/canceldeposit',
    name: 'canceldeposit',
    component: CancelDepositView,
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithdrawView,
  },
  {
    path: '/cancelwithdraw',
    name: 'cancelwithdraw',
    component: CancelWithdrawalView,
  },
  {
    path: '/referfriend',
    name: 'referfriend',
    component: ReferFriendView,
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: WaitlistView,
  },
  {
    path: '/home',
    name: 'homeView',
    component: HomeView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/notverified',
    name: 'notverified',
    component: NotVerifiedView,
  },
  {
    path: '/verify/:key',
    name: 'verify',
    component: VerifyEmailView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePasswordView,
    props: (route) => ({
      validationKey: route.query.validationKey,
    }),
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: OnBoardingViewSteps,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/welcome/:page?',
    name: 'welcome',
    component: OnBoardingViewSteps,
  },
  {
    path: '/start-playing',
    name: 'startplaying',
    component: StartPlaying,
  },
  {
    path: '/userLimits',
    name: 'userlimits',
    component: UserLimitsView,
  },
  {
    path: '/userLockout',
    name: 'userlockout',
    component: UserLockoutView,
  },
  {
    path: '/verifyaccount',
    name: 'verifyaccount',
    component: VerifyAccountView,
  },
  {
    path: '/verifyAccountById',
    name: 'verifyAccountById',
    component: VerifyAccountById,
  },
  {
    path: '/terms',
    name: 'termsandconditions',
    component: TermsAndConditionsView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacypolicy',
    component: PrivacyPolicyView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/howtoplay',
    name: 'howtoplay',
    component: HowToPlayView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/mobileapp',
    name: 'mobileapp',
    component: MobileWarningView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/androidapk',
    name: 'androidapk',
    component: MobileAndroidApkView,
    meta: {
      publicPage: true,
    },
  },
  {
    path: '/scores',
    name: 'scores',
    component: ScoresView,
  },
  {
    path: '/integrations',
    name: 'integration',
    component: IntegrationsView,
  },
  {
    path: '/integrations/:integrationName?/status',
    name: 'integrationstatus',
    component: IntegrationStatusView,
  },
  {
    path: '/ownersbucks',
    name: 'OwnersBucks',
    component: OwnersBucksView,
  },
  // will match everything and put it under `route.params.pathMatch`
  {
    path: '*',
    name: 'pageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : '',
  base: '/wfs/',
  routes: routes,
});

const isMobile = function() {
  let check = false;
  // regex from http://detectmobilebrowsers.com/
  (function(a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent||window.opera);
  return check;
};

const checkAppOpen = function(route) {
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    try {
      openApp(route);
    } catch (e) {
      // Do nothing on error
    }

    return;
  }
  if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    try {
      openApp(route);
    } catch (e) {
      window.location = 'https://apps.apple.com/us/app/id1513599602';
    }
  }
};

const checkAffiliates = function(routeQuery) {
  if (routeQuery.btag) {
    localStorage.setItem('btag', routeQuery.btag);
  }

  if (routeQuery.partnercode) {
    localStorage.setItem('ob-partner', routeQuery.partnercode);
  }

  if (routeQuery.fpros) {
    localStorage.setItem('fpros', routeQuery.fpros);
  }

  if (routeQuery.mbsy) {
    localStorage.setItem('mbsy', routeQuery.mbsy);
  }
};

const isOldPage = function(path) {
  const oldPages = {'/dashboard': true, '/prelobby': true};
  return oldPages[path];
};

router.beforeEach((to, from, next) => {
  const isPublicPage = to.meta && to.meta.publicPage;
  const userOnWaitlist = store.getters.userState === 'WAITLIST';
  const userNotVerified = store.getters.userState === 'NOT_VERIFIED';
  const defaultRoute = store.state.defaultRoute;

  EventBus.$emit('ROUTE_CHANGED');
  EventBus.$emit('CLOSE_DROPDOWNS');

  checkAffiliates(to.query);

  // Track page views for Braze
  if (store.getters.isBrazeInitialized) {
    Vue.prototype.$Analytics.trackPageView(to.path);
  }

  if (to.path !== '/login' && to.path != '/signup' && to.name != 'signup' && to.path != '/mobileapp') {
    store.commit('setRedirectedRoute', null);
  }

  // Store last visited page for re-direct
  if (from.path) {
    store.commit('setLastVisitedPage', from.fullPath);
  }

  if (isOldPage(to.path)) {
    return next(defaultRoute);
  }

  // If user is logged in, but not verified, redirect
  // Allow access to public pages (like terms), but don't allow login or signup since they're currently logged in
  if (to.path !== '/notverified' && (!isPublicPage || to.path === '/login' || to.path === '/signup') && userNotVerified) {
    return next('/notverified');
  }

  // Path is not verified page, but user is verified, redirect to default
  if (to.path === '/notverified' && !userNotVerified) {
    return next(store.getters.isLoggedIn ? defaultRoute : '/login');
  }

  // If user is logged in, but on waitlist, redirect
  // Allow access to public pages (like terms), but don't allow login or signup since they're currently logged in
  if (to.path !== '/waitlist' && (!isPublicPage || to.path === '/login' || to.path === '/signup') && userOnWaitlist) {
    return next('/waitlist');
  }

  // Path is waitlist, but user is not on the waitlist, redirect to default
  if (to.path === '/waitlist' && !userOnWaitlist) {
    return next(store.getters.isLoggedIn ? defaultRoute : '/login');
  }

  // Root path defaults to lobby when logged in, otherwise home page
  if (to.path === '/') {
    return next(store.getters.isLoggedIn ? defaultRoute : '/home');
  }

  // If user is already logged in, login page redirects to lobby
  if (store.getters.isLoggedIn && to.path === '/login') {
    return next(defaultRoute);
  }

  // Must be logged in unless specified as public page
  if (isPublicPage) {
    next();
  }

  if (to.path !== '/mobileapp' && to.path !== '/androidapk' && isMobile()) {
    store.commit('setRedirectedRoute', to);

    // Logged in users on mobile, sent to page to install app
    // Allow access to change password
    if (!to.path.includes('changepassword')) {
      // Attempt to open mobile app, otherwise redirect to the mobile links page
      checkAppOpen(to);

      return next('/mobileapp');
    }
  }

  if (!isPublicPage && !store.getters.isLoggedIn && !userOnWaitlist && !userNotVerified) {
    store.commit('setRedirectedRoute', to);
    return next('/login');
  }

  if (to.path == '/lobby') {
    EventBus.$emit('GET_CONTEST_CUTOFFS');
    EventBus.$emit('UPDATE_ACCOUNT_INFO');
  }
  if (to.path == defaultRoute || to.path.includes('/profile')) {
    EventBus.$emit('UPDATE_ACCOUNT_INFO');
  }

  next();
});

export default router;
