<template>
  <table aria-label="Contests Table">
    <tr>
      <th>Contest</th>
      <th>Entries</th>
      <th>Entry Fee</th>
      <th>Your Entries</th>
      <th>This Lineup</th>
      <th>Prize Pool</th>
      <th class="btnCol"></th>
    </tr>
    <tr v-for="contest in filteredContests" :key="contest.id">
      <td>
        <router-link :to="'/salarycontest/leaderboard/' + contest.id" class="contestLink">
          {{ getContestName(contest) }}
        </router-link>
      </td>
      <td>{{ contest.playersCurrent }} / {{ contest.playersMax }}</td>
      <td>
        <img v-if="!contest.realMoney" :src="require('@/assets/icons/ownersbucks.png')"
          class="ownersbucksIcon" alt="OwnersBucks Icon"
        >
        {{ contest.fee.removeCentsIfZero() }}
      </td>
      <td>{{ contest.myEntries }} / {{ contest.entriesPerUser }}</td>
      <td>{{ getEntriesForLineup(contest) }}</td>
      <td>
        <div class="moneyIconContainer">
          <img v-if="contest.isGuaranteed" class="guaranteedIcon"
            :src="require('@/assets/contesticons/Guaranteed' + (contest.realMoney ? '' : 'Blue') + '.svg')"
            alt="Guaranteed"
          >
          <img v-if="!contest.realMoney" :src="require('@/assets/icons/ownersbucks.png')"
            class="ownersbucksIcon" alt="OwnersBucks Icon"
          >
          <div class="prizeText" :class="contest.realMoney ? 'greenText' : 'blueText'">{{ contest.prizePool.removeCentsIfZero() }}</div>
        </div>
      </td>
      <td>
        <router-link v-if="canEnterContest(contest)"
          :to="{name: 'lineup-builder', params: { contestId: contest.id, pickedPlayersByPos: getPlayerRosterInfo(lineup) }}"
        >
          <button class="ob-btn-grey enterBtn">
            <img v-if="useableTicket(contest) != null" class="ticketIcon" :src="require('@/assets/icons/ticket.png')"
              alt="Ticket"
            >
            Enter Again
          </button>
        </router-link>
        <button v-else class="ob-btn-grey disabledBtn enterBtn">{{ cantEnterButtonText(contest) }}</button>
      </td>
    </tr>
  </table>
</template>

<script>

export default {
  props: {
    lineup: {type: Object, default: null},
    contests: {type: Array, default: null},
  },
  computed: {
    filteredContests() {
      return this.contests.slice().filter((c) => this.getEntriesForLineup(c) > 0);
    },
  },

  methods: {
    useableTicket(contest) {
      return this.$UserInfo.canUseTicket(contest);
    },

    getEntriesForLineup(contest) {
      let id = contest.id;
      if (contest.userCreatedBatchId) {
        id = contest.userCreatedBatchId + '_' + contest.feeValue;
      }
      const numEntries = this.lineup.entriesPerContest[id];
      if (numEntries == undefined) {
        return 0;
      }
      return numEntries;
    },

    // Copied from lobby, maybe suitable for a utils method
    getContestName(contest) {
      if (!contest) {
        return '';
      }
      if (contest.contestName && contest.groupContestsRemaining) {
        return contest.contestName + ' (x' + contest.groupContestsRemaining + ')';
      } else if (contest.contestName) {
        return contest.contestName;
      }
      return contest.sport + ' ' + contest.playersMax + ' Team';
    },

    canEnterContest(contest) {
      return !this.maxEnteredContest(contest) && !this.contestFull(contest) && !this.contestStarted(contest);
    },

    maxEnteredContest(contest) {
      return contest.myEntriesRemaining <= 0;
    },

    contestFull(contest) {
      return contest.entriesRemaining <= 0;
    },

    contestStarted(contest) {
      return contest.contestStart < this.$moment().valueOf();
    },

    cantEnterButtonText(contest) {
      if (this.maxEnteredContest(contest)) {
        return 'Max Entered';
      }

      if (this.contestFull(contest)) {
        return 'Contest Full';
      }

      if (this.contestStarted(contest)) {
        return 'Contest Started';
      }

      return 'Can\'t Enter';
    },

    getPlayerRosterInfo(lineup) {
      if (!lineup?.roster?.players) {
        return null;
      }
      const rosterMap = {};
      for (const p of lineup.roster.players) {
        if (!rosterMap[p.draftPos]) {
          this.$set(rosterMap, p.draftPos, []);
        }
        rosterMap[p.draftPos].push(p.id);
      }

      return rosterMap;
    },
  },
};
</script>

<style lang="scss" scoped>

table {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  border: 1px solid var(--obcolor-background-2);

  th {
    padding: 8px 12px;
    background: var(--obcolor-background-2);
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  td {
    padding: 6px 12px;
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  th:not(:first-child) {
    min-width: 110px;
    width: 110px;
  }
  th:nth-child(2) {
    min-width: 130px;
    width: 130px;
  }

  th.btnCol {
    width: 130px;
  }
}

.contestLink {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.enterBtn {
  width: 130px;
  font-size: 14px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabledBtn {
    cursor: not-allowed;
    color: var(--obcolor-font-secondary);
  }

  .ticketIcon {
    height: 12px;
    margin-right: 5px;
  }
}

.moneyIconContainer {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;

  .prizeText {
    font-weight: bold;
    margin-bottom: -1px;
  }
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.greenText {
  color: var(--obcolor-green);
}

.guaranteedIcon {
  height: 17px;
}

.ownersbucksIcon {
  height: 10px;
}
</style>