<template>
  <tr :class="{ greenOutline: isAdd, redOutline: isDrop }">
    <td class="position">{{ player.draftPos }}</td>
    <td>
      <PlayerHeadshot size="45" :id="player.id" :sport="contest.sport"
        :team="player.team" :showLogo="true"
      />
    </td>
    <td style="padding-left: 10px;">
      <div class="playerName">
        <div v-if="!statusMap" class="statusLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <PlayerStatus v-else-if="!status" class="playerStatus" height="16px;"
          status="'OUT'"
        />
        <PlayerStatus v-else class="playerStatus" height="16px;"
          :status="status"
        />

        <span @click.stop="openPlayerCard()" class="ob-link playerNameLink" style="margin-right: 6px">
          {{ player.name.shortName() }}
        </span>

        <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
        ></i>
        <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
          v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
        ></i>
      </div>
      <div class="playerInfo">
        <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }}</span>
        <span>#{{ player.number }}</span>
        <span>{{ player.position }}</span>
        <span>{{ player.lineInfo }}</span>
      </div>
    </td>
    <td style="width: 43px;">
      <div>{{ getGamesRemaining(player) }}</div>
      <div v-if="getStatDiff('gr') != null" class="statDiff">{{ formatChange(getStatDiff('gr')) }}</div>
    </td>
    <td style="width: 63px;">
      <div class="moneyText">{{ dollarFormat(getSalaryRemaining(player)) }}</div>
      <div v-if="getStatDiff('salaryPG', false) != null" class="statDiff">{{ changePrefix(getStatDiff('salaryPG', false)) + dollarFormat(getStatDiff('salaryPG', false)) }}</div>
      <div v-else style="font-size: 12px; font-weight: normal; color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: -3px;">
        {{ dollarFormat(player.salaryPG) }} PG
      </div>
    </td>
    <td style="width: 63px;">
      <div>{{ player.sAvg.toFixed(1) || 0 }}</div>
      <div v-if="getStatDiff('sAvg', false) != null" class="statDiff">{{ formatChange(getStatDiff('sAvg', false)) }}</div>
    </td>
    <td style="width: 63px;">
      <div>{{ getProjRemaining(player) }}</div>
      <div v-if="getStatDiff('proj') != null" class="statDiff">{{ formatChange(getStatDiff('proj')) }}</div>
    </td>
    <td class="dayField" v-for="g in player.games" :key="g.date">
      <div class="scheduleBox" :style="!g.game || (g.proj === 0 && !g.played) ? 'opacity: 0.4;': ''">
        <div v-if="g.game" :style="g.played || g.result ? 'color: var(--obcolor-font-secondary)' : ''">
          <div class="logoBox">
            <div v-if="g.game.includes('@')">@</div>
            <img :src="getTeamLogo(g.game)" :alt="`${g.game} Team`" style="height: 18px; margin: 0 2px 2px 2px;">
            <div>{{ parseInt(g.ts) | moment("h:mma") }}</div>
          </div>
          <div :style="g.proj === 0 ? 'color: var(--obcolor-font-secondary);' : ''">Proj {{ roundStat(g.proj) }}</div>
          <div v-if="g.fpts != undefined && g.played" class="blueText" :style="g.fpts < 0 ? 'color: red;' : ''">{{ formatPoints(g.fpts) }}</div>
          <div v-else-if="g.result">DNP</div>
          <div v-else-if="g.proj != 0" class="moneyText">{{ dollarFormat(player.salaryPG) }}</div>
        </div>
      </div>
      <div v-if="g.gameDH" class="scheduleBox" :style="g.projDH === 0 && !g.playedDH ? 'opacity: 0.4;': ''">
        <div :style="g.played || g.result ? 'color: var(--obcolor-font-secondary)' : ''">
          <div class="logoBox">
            <div v-if="g.gameDH.includes('@')">@</div>
            <img :src="getTeamLogo(g.gameDH)" :alt="`${g.gameDH} Team`" style="height: 18px; margin: 0 2px 2px 2px;">
            <div>{{ parseInt(g.tsDH) | moment("h:mma") }}</div>
          </div>
          <div :style="g.projDH === 0 ? 'color: var(--obcolor-font-secondary);' : ''">Proj {{ roundStat(g.projDH) }}</div>
          <div v-if="g.fptsDH != undefined && g.playedDH" class="blueText" :style="g.fptsDH < 0 ? 'color: red;' : ''">{{ formatPoints(g.fptsDH) }}</div>
          <div v-else-if="g.resultDH">DNP</div>
          <div v-else-if="g.projDH != 0" class="moneyText">{{ dollarFormat(player.salaryPG) }}</div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';
import PlayerHeadshot from '@/components/PlayerHeadshot';

export default {
  props: {
    contest: Object,
    player: Object,
    statusMap: Object,
    status: String,
    swap: Object,
  },

  components: {
    PlayerStatus,
    PlayerHeadshot,
  },

  computed: {
    isAdd() {
      if (this.swap == null) {
        return false;
      }

      if (this.swap.add == null) {
        return false;
      }

      return this.swap.add.id == this.player.id;
    },

    isDrop() {
      if (this.swap == null) {
        return false;
      }

      if (this.swap.drop == null) {
        return false;
      }

      return this.swap.drop.id == this.player.id;
    },
  },

  methods: {
    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
    },

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    formatPoints(fpts) {
      return fpts < 0 ? fpts : '+' + fpts;
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },
    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }

      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },
    getGamesRemaining(player) {
      const now = new Date().getTime();
      let gr = 0;
      for (const game of player.games) {
        if (game.ts > now && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && game.tsDH > now && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    getSalaryRemaining(player) {
      const salaryPG = player.salaryPG;

      return this.getGamesRemaining(player) * salaryPG;
    },

    getProjRemaining(player) {
      const now = new Date().getTime();
      let proj = 0;
      for (const game of player.games) {
        if (game.ts && game.ts > now) {
          proj += game.proj ? Math.round(game.proj) : 0;
          proj += game.projDH ? Math.round(game.projDH) : 0;
        }
      }
      return proj;
    },

    getStat(player, path) {
      if (path == 'salaryPG') {
        return player.salaryPG;
      }
      if (path == 'salary') {
        return this.getSalaryRemaining(player);
      }
      if (path == 'proj') {
        return this.getProjRemaining(player);
      }
      if (path == 'gr') {
        return this.getGamesRemaining(player);
      }
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }

      return null;
    },

    getStatDiff(path, isStat = true) {
      if (!this.swap || this.swap.add == null || this.swap.drop == null) {
        return null;
      }

      let addStat = null;
      let dropStat = null;

      if (isStat) {
        addStat = this.getStat(this.swap.add, path);
        dropStat = this.getStat(this.swap.drop, path);
      } else {
        addStat = this.swap.add[path];
        dropStat = this.swap.drop[path];
      }

      if (addStat == null && dropStat == null) {
        return null;
      }

      // removeCentsIfZero works well here because it's just meant to remove trailing 0's and we want up to 2 decimal points
      return (addStat - dropStat).toFixed(2).removeCentsIfZero();
    },

    changePrefix(n) {
      if (n == null) {
        return '+ ';
      }
      if (n >= 0) {
        return '+ ';
      }
      return '- ';
    },

    formatChange(n) {
      if (n == null) {
        return '+ 0';
      }
      if (n >= 0) {
        return '+ ' + n;
      }
      return String(n).replace('-', '- ');
    },
  },
};
</script>

<style lang="scss" scoped>
  .greenOutline {
    border: 2px solid var(--obcolor-green);
  }

  .redOutline {
    border: 2px solid red;
  }

  tr {
    height: 65px;
  }

  tr:not(.greenOutline):not(.redOutline) {
    border: 1px solid var(--obcolor-background-1);
  }

  .draftPos {
    width: 40px;
    font-weight: bold;
  }

  .position {
    font-size: 16px;
    width: 40px;
  }

  .playerName {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .playerNameLink {
    max-width: 118px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .playerInfo {
    margin-left: 16px;
    text-align: left;
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    span {
      margin-right: 5px;
    }
  }

  .statusLoading, .playerStatus {
    margin-right: 5px;
  }

  .scheduleBox {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    background: var(--obcolor-background-2);
    height: 51px;
    font-size: 12px;
    font-weight: bold;
  }

  .logoBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .statDiff {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  td.dayField {
    padding: 2px;
    width: 95px;
    box-sizing: border-box;
    vertical-align: top;
    position: relative;
  }
</style>