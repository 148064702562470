<template>
  <div v-if="computedSponsor.imageUrl">
    <a v-if="computedSponsor.redirectUrl" :href="computedSponsor.redirectUrl" target="_blank">
      <img :src="computedSponsor.imageUrl" alt="Sponsor" :style="computedStyle">
    </a>
    <img v-else :src="computedSponsor.imageUrl" alt="Sponsor"
      :style="computedStyle"
    >
  </div>
</template>

<script>
import ObSponsorApi from '@/api/ObSponsorApi';

export default {
  props: {
    sponsor: {type: Object, default: null},
    sponsorKey: {type: String, default: null},
    height: {type: String, default: null},
    maxHeight: {type: String, default: null},
    width: {type: String, default: null},
    maxWidth: {type: String, default: null},
  },

  data() {
    return {
      loadedSponsor: {},
    };
  },

  created() {
    this.loadSponsor();
  },

  computed: {
    computedSponsor() {
      if (this.sponsor) {
        return this.sponsor;
      }

      return this.loadedSponsor;
    },

    computedStyle() {
      let totalStyle = '';
      if (this.height) {
        totalStyle += 'height: ' + this.height + '; ';
      }
      if (this.width) {
        totalStyle += 'width: ' + this.width + '; ';
      }
      if (this.maxHeight) {
        totalStyle += 'max-height: ' + this.maxHeight + '; ';
      }
      if (this.maxWidth) {
        totalStyle += 'max-width: ' + this.maxWidth + '; ';
      }

      return totalStyle;
    },
  },

  methods: {
    loadSponsor() {
      if (!this.sponsorKey) {
        return;
      }
      ObSponsorApi.getSponsor(this.sponsorKey)
          .then((response) => {
            this.loadedSponsor = response;
          }).catch((_e) => {
            // Show nothing if ad load fails
          });
    },
  },
};
</script>

<style lang="scss" scoped>
// a tags usually have a bit of extra bottom padding due to being inline, remove that
a {
  display: flex;
}
</style>