<template>
  <div class="ob-box playerListBox">
    <div class="filterList">
      <div class="posFilters" v-if="filterPositions && picks">
        <div class="filterBtn" v-for="(position, index) in filterPositions" :key="index"
          @click="selectFilter(position)"
          :class="[{'filterSelected': posFilters[position] === true}, {'filterSlotsFull': isFilterPositionFull(position)}]"
        >
          <div :class="showDraftPositionFilters ? 'filterTop' : 'filterPos'">{{ position }}</div>
          <div v-if="showDraftPositionFilters" class="filterBottom">{{ getRemainingPicks(position) }}</div>
        </div>
      </div>
      <div v-if="contest.format == 'WEEKLY'" class="salaryFilter" style="width: 170px; margin-left: 10px;">
        <div class="salaryHeading">Proj Games: {{ gameRangeFilter[0] }} - {{ gameRangeFilter[1] }}</div>
        <div class="rangeSliderContainer">
          <div class="rangeSliderLabel">0</div>
          <RangeSlider min="0" :max="maxPG" v-model="gameRangeFilter"
            step="1"
          />
          <div class="rangeSliderLabel">{{ maxPG }}</div>
        </div>
        <div class="salaryLabel"></div>
      </div>

      <div class="salaryFilter" style="min-width: 230px; flex: 1;">
        <div class="salaryHeading">Salary: {{ dollarFormat(costRangeFilter[0]) }} - {{ dollarFormat(costRangeFilter[1]) }}</div>
        <div class="rangeSliderContainer">
          <div class="rangeSliderLabel">$0</div>
          <RangeSlider min="0" :max="maxCost" v-model="costRangeFilter"
            step="500"
          />
          <div class="rangeSliderLabel">{{ dollarFormat(maxCost) }}</div>
        </div>
        <div class="salaryLabel"></div>
      </div>

      <div v-if="contest.format == 'WEEKLY'" class="selectViewContainer">
        <div :style="selectedView === 'stats' ? 'color: var(--obcolor-ob-blue);' : ''" @click="selectedView = 'stats'">Stats</div>
        <div :style="selectedView === 'schedule' ? 'color: var(--obcolor-ob-blue);' : ''" @click="selectedView = 'schedule'" style="padding-left: 8px;">Schedule</div>
      </div>
      <div v-else style="flex: 1;"></div>

      <div class="searchInput">
        <div class="inputIcon"><i class="fa fa-search"></i></div>
        <input v-model="nameFilter" type="text" placeholder="Player Search">
        <div v-show="nameFilter != ''" class="inputIcon cancelSearch" @click="nameFilter = ''">
          <i class="fa fa-times"></i>
        </div>
      </div>

      <div>
        <Dropdown v-model="selectedTeam" :options="teamDropdownValues" :text="teamDropdownLabels"
          style="width: 120px; margin-right: 0px; margin-left: 5px;"
        />
      </div>
    </div>
    <div ref="playerListContainer" class="tableContainer ob-scroll" @scroll="onScroll">
      <table aria-label="Player List">
        <tr>
          <th style="width: 46px;"></th>
          <th style="text-align: left; padding-left: 23px;">Player</th>

          <template v-if="selectedView === 'stats'">
            <th v-if="contest.format == 'WEEKLY'" class="statHeader" @click="changeSort('wStats', 'pg')"
              :class="{ selectedCol: isSelected('wStats', 'pg') }"
            >
              <GeneralTooltip textKey="SalaryCap_PGAG" pos="bottom"><div class="headerTrigger" slot="trigger">PG/AG</div></GeneralTooltip>
            </th>
            <template v-if="contest.format == 'DAILY'">
              <th style="width: 77px;">
                <GeneralTooltip textKey="SalaryCap_VS" pos="bottom"><div class="headerTrigger" slot="trigger">VS</div></GeneralTooltip>
              </th>
              <th class="statHeader" style="width: 96px;" @click="changeSort(null, 'gameTime')"
                :class="{ selectedCol: isSelected(null, 'gameTime') }"
              >
                <GeneralTooltip textKey="SalaryCap_Time" pos="bottom"><div class="headerTrigger" slot="trigger">Time</div></GeneralTooltip>
              </th>
              <th v-if="contest.sport == 'NFL'" class="statHeader" @click="changeSort(null, 'oprk')"
                :class="{ selectedCol: isSelected(null, 'oprk') }"
              >
                <GeneralTooltip textKey="SalaryCap_OPRK" pos="bottom"><div class="headerTrigger" slot="trigger">OPRK</div></GeneralTooltip>
              </th>
            </template>
            <th class="statHeader" @click="changeSort('wStats', 'avg')" :class="{ selectedCol: isSelected('wStats', 'avg') }">
              <GeneralTooltip textKey="SalaryCap_pAvg" pos="bottom">
                <div class="headerTrigger" slot="trigger">
                  <span v-if="contest.format == 'WEEKLY'">pAvg</span>
                  <span v-else>PROJ</span>
                </div>
              </GeneralTooltip>
            </th>
            <th class="statHeader" @click="changeSort(null, 'sAvg')" :class="{ selectedCol: isSelected(null, 'sAvg') }">
              <GeneralTooltip textKey="SalaryCap_sAvg" pos="bottom"><div class="headerTrigger" slot="trigger">sAvg</div></GeneralTooltip>
            </th>
            <th v-if="contest.format == 'WEEKLY'" class="statHeader" @click="changeSort('wStats', 'proj')"
              :class="{ selectedCol: isSelected('wStats', 'proj') }"
            >
              <GeneralTooltip textKey="SalaryCap_Proj" pos="bottom"><div class="headerTrigger" slot="trigger">PROJ</div></GeneralTooltip>
            </th>
          </template>

          <template v-else>
            <th v-for="d in contest.days" :key="d" style="padding: 0;">
              <div>{{ [d, 'YYYY-MM-DD'] | moment("ddd") }}</div>
              <div>{{ [d, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
            <th></th>
          </template>
          <template v-if="contest.format == 'WEEKLY'">
            <th class="statHeader endSection" @click="changeSort(null, 'salaryPG')" :class="{ selectedCol: isSelected(null, 'salaryPG') }">Per Game</th>
            <th style="padding: 0;"></th>
          </template>
          <th class="statHeader" @click="changeSort(null, 'salary')" :class="{ selectedCol: isSelected(null, 'salary') }">Salary</th>
          <th style="width: 24px;"></th>
        </tr>
        <template v-if="sortedPlayers.length">
          <SalaryCapPlayerRow class="playerRow" v-for="(player, index) in sortedPlayers" :key="index"
            :player="player" :picked="pickedPlayerIds ? pickedPlayerIds[player.id] : null"
            :statusMap="statusMap"
            :status="statusMap && statusMap[player.id] ? statusMap[player.id].status : null"
            :totSpent="totSpent" :picks="picks" :contest="contest"
            :selectedView="selectedView"
          />
        </template>
        <tr class="emptyRow" v-else>
          <td colspan="999">No players found for the selected filter</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ObSportDataApi from '@/api/ObSportDataApi';
import EventBus from '@/event-bus';
import RangeSlider from '@/components/RangeSlider';
import Dropdown from '@/components/Dropdown';
import SalaryCapPlayerRow from '@/views/SalaryCapGame/SalaryCapPlayerRow';
import GeneralTooltip from '@/components/tooltip/GeneralTooltip';

export default {
  components: {
    RangeSlider,
    Dropdown,
    SalaryCapPlayerRow,
    GeneralTooltip,
  },

  props: {
    playerList: Array,
    picks: Object,
    pickedPlayerIds: Object,
    contest: Object,
    statusMap: Object,
    totSpent: Number,
  },

  data() {
    return {
      sortedBy: {loc: null, stat: 'salary'},
      posFilters: {},
      nameFilter: '',
      playerListBaseLimit: 40,
      playerListLimit: 40,
      selectedView: 'stats',

      maxCost: 0,
      maxPG: 0,
      costRangeFilter: [0, 0],
      gameRangeFilter: [0, 4],

      teams: [],
      selectedTeam: '',
    };
  },

  created() {
    this.loadTeams();
    this.initPosFilters();
    this.calcMaxValues();

    if (this.playerList && this.maxCost) {
      this.costRangeFilter = [0, this.maxCost];
    }
    if (this.playerList && this.maxPG) {
      this.gameRangeFilter = [0, this.maxPG];
    }

    EventBus.$on('SALARY_CAP_SELECT_POS_FILTER', this.selectFilterOnly);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_SELECT_POS_FILTER', this.selectFilterOnly);
  },

  watch: {
    picks(newVal) {
      if (newVal != null) {
        this.initPosFilters();
      }
    },

    playerList(newVal) {
      this.calcMaxValues();
    },

    // Scroll table to the top, and reset list limit when changing filters
    nameFilter(to, from) {
      this.resetTableScroll();
    },

    selectedTeam(to, from) {
      this.resetTableScroll();
    },

    costRangeFilter(to, from) {
      this.resetTableScroll();
    },

    gameRangeFilter(to, from) {
      this.resetTableScroll();
    },

    fullPositions(newVal, oldVal) {
      this.filterPositions.forEach((pos) => {
        const draftPos = this.getDraftPos(pos);
        if (!this.canPickPosition(draftPos, newVal) && this.canPickPosition(draftPos, oldVal)) {
          this.posFilters[pos] = false;
        }
      });
    },
  },

  computed: {
    sport() {
      if (!this.contest && !this.contest.sport) {
        return '';
      }

      return this.contest.sport;
    },

    showDraftPositionFilters() {
      return this.sport != 'NBA';
    },

    fullPositions() {
      const tempFullPositions = {};
      this.roster.forEach((pos) => {
        const pickedAtPos = this.picks[pos.short] ? this.picks[pos.short].length : 0;
        tempFullPositions[pos.short] = pos.num <= pickedAtPos;
      });
      const fPositions = tempFullPositions;
      return fPositions;
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      return this.contest.draftRoster.roster;
    },

    teamDropdownValues() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.id;
      });
      values.unshift(''); // All Teams
      return values;
    },

    filterPositions() {
      if (this.sport == 'NBA') {
        return ['PG', 'SG', 'SF', 'PF', 'C'];
      }

      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      const filterPos = [];
      for (const position of this.contest.draftRoster.roster) {
        filterPos.push(position.short);
      }

      return filterPos;
    },

    teamDropdownLabels() {
      if (!this.teams) {
        return [];
      }
      const values = this.teams.map((t) => {
        return t.team_city + ' ' + t.team_name;
      });
      values.unshift('All Teams');
      return values;
    },

    sortedPlayers() {
      const self = this;

      let noPosFilters = true;
      for (const posIndex in this.posFilters) {
        if (this.posFilters[posIndex] === true) {
          noPosFilters = false;
        }
      }
      if (!this.playerList) {
        return [];
      }
      // Using es6 way to clone array ([...array])
      return [...this.playerList].filter((player) => {
        let nameMatch = false;
        let posMatch = false;
        let costMatch = false;
        let pgMatch = false;
        let teamMatch = false;

        if (!player.name) {
          return false;
        }
        if (self.nameFilter !== '') {
          const pname = player.name.toLowerCase();
          const nfilter = self.nameFilter.toLowerCase();
          const nFilters = nfilter.split(' ');

          nameMatch = true;
          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) == 0 || pname.indexOf(', '+n) != -1) {
              // All filters must match
            } else {
              nameMatch = false;
              break;
            }
          }

          return nameMatch;
        }

        // Team Filter
        if (self.selectedTeam == '' || self.selectedTeam == player.teamId) {
          teamMatch = true;
        }

        let allPositionsFull = true;
        for (const pos of self.roster) {
          if (!self.fullPositions[pos.short]) {
            allPositionsFull = false;
          }
        }

        if (self.isFilterActive(player.draftPos, player.position) || (noPosFilters && (self.canPickPosition(player.draftPos) === true || allPositionsFull))) {
          posMatch = true;
        }

        if (self.costRangeFilter[0] <= player.salary && self.costRangeFilter[1] >= player.salary) {
          costMatch = true;
        }

        const pg = self.getStat(player, 'pg');
        if (self.gameRangeFilter[0] <= pg && self.gameRangeFilter[1] >= pg) {
          pgMatch = true;
        }

        return posMatch && costMatch && pgMatch && teamMatch;
      }).sort((p1, p2) => {
        if (this.sortedBy.stat == 'gameTime' || this.sortedBy.stat == 'oprk') {
          // If a player has no games, check the other player and don't change list order otherwise
          if (!p1.games) {
            return p2.games != null ? 1 : 0;
          } else if (!p2.games) {
            return p1.games != null ? -1 : 0;
          }

          let compareStat = '';
          if (this.sortedBy.stat == 'gameTime') {
            compareStat = 'ts';
          } else if (this.sortedBy.stat == 'oprk') {
            compareStat = 'oprk';
          }

          // Find each player's next game if it exists. This assumes that the lowest game index is the earliest one (eg. index 0 is earlier than index 1)
          const p1UpcomingGame = p1.games.find((g) => g[compareStat] != null);
          const p2UpcomingGame = p2.games.find((g) => g[compareStat] != null);

          // Return appropriate sorting if we don't have timestamps to compare (prioritize the player with a non-null game/timestamp)
          if (p1UpcomingGame == null || p1UpcomingGame[compareStat] == null) {
            return p2UpcomingGame != null && p2UpcomingGame[compareStat] != null ? 1 : 0;
          } else if (p2UpcomingGame == null || p2UpcomingGame[compareStat] == null) {
            return p1UpcomingGame != null && p1UpcomingGame[compareStat] != null ? -1 : 0;
          }

          return p2UpcomingGame[compareStat] - p1UpcomingGame[compareStat];
        } else if (!this.sortedBy.loc) {
          return p2[this.sortedBy.stat] - p1[this.sortedBy.stat];
        } else if (this.sortedBy.loc == 'wStats') {
          return this.getStat(p2, this.sortedBy.stat) - this.getStat(p1, this.sortedBy.stat);
        }

        return 0;
      }).slice(0, this.playerListLimit);
    },
  },

  methods: {

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      // Show more entries once we scroll to the bottom of the table
      // Extra pixel buffer to reduce stuttering
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        this.playerListLimit += this.playerListBaseLimit;
      }
    },

    // When changing filters or sort, scroll back to top and reset the limit
    resetTableScroll() {
      this.$nextTick(() => {
        this.$refs.playerListContainer.scrollTop = 0;
      });
      this.playerListLimit = this.playerListBaseLimit;
    },

    loadTeams() {
      this.teams = [];
      ObSportDataApi.getTeams(this.contest.sport)
          .then((response) => {
            this.teams = response.sort((a, b) => {
              return a.team_city.localeCompare(b.team_city);
            });
          });
    },

    calcMaxValues() {
      let highestCost = 0;
      let highestPG = 0;

      for (const player of this.playerList) {
        if (player.salary && player.salary > highestCost) {
          highestCost = player.salary;
        }
        const pg = this.getStat(player, 'pg');
        if (pg > highestPG) {
          highestPG = pg;
        }
      }

      // Round up to nearest 1000;
      this.maxCost = Math.ceil(highestCost/1000) * 1000;
      this.maxPG = highestPG;

      // If max Cost or GR changes, and selected value is higher, adjust down
      if (this.costRangeFilter[1] > this.maxCost) {
        this.$set(this.costRangeFilter, 1, this.maxCost);
      }
      if (this.gameRangeFilter[1] > this.maxGR) {
        this.$set(this.gameRangeFilter, 1, this.maxGR);
      }
    },

    initPosFilters() {
      if (!this.picks || !Object.keys(this.picks)) {
        return;
      }

      const tempFilters = {};
      this.filterPositions.forEach((pos) => {
        if (!tempFilters[pos]) {
          tempFilters[pos] = false;
        }
      });
      this.posFilters = tempFilters;
    },

    selectFilter(posName) {
      this.resetTableScroll();
      if (!this.posFilters[posName]) {
        this.$set(this.posFilters, posName, true);
      } else {
        this.$set(this.posFilters, posName, false);
      }
    },

    selectFilterOnly(posName) {
      for (const pos of this.filterPositions) {
        if (this.posFilters[pos]) {
          this.$set(this.posFilters, pos, false);
        }
      }

      if (!this.showDraftPositionFilters) {
        const rosterPositions = this.getRosterPositions(posName);
        for (const rosterPos of rosterPositions) {
          this.selectFilter(rosterPos);
        }
        return;
      }

      this.selectFilter(posName);
    },

    canPickPosition(draftPos, fullPositions = this.fullPositions) {
      if (this.contest.isFlexContest) {
        if (this.$SportInfo.draftPosIsSuperFlex(draftPos, this.contest.sport) && fullPositions['SF'] === false) {
          return true;
        }

        if (this.$SportInfo.draftPosIsFlex(draftPos, this.contest.sport) && fullPositions['F'] === false) {
          return true;
        }
      }

      return fullPositions[draftPos] === false;
    },

    isFilterActive(draftPos, rosterPos) {
      if (!this.showDraftPositionFilters) {
        return this.posFilters[rosterPos];
      }

      if (this.contest.isFlexContest) {
        const isFlex = this.$SportInfo.draftPosIsFlex(draftPos, this.sport);
        const isSuperFlex = this.$SportInfo.draftPosIsSuperFlex(draftPos, this.sport);

        if (isFlex && (this.posFilters[draftPos] || this.posFilters['F'])) {
          return true;
        }
        if (isSuperFlex && (this.posFilters[draftPos] || this.posFilters['SF'])) {
          return true;
        }
      }

      return this.posFilters[draftPos];
    },

    isFilterPositionFull(position) {
      if (!this.showDraftPositionFilters) {
        const draftPos = this.getDraftPos(position);
        return this.fullPositions[draftPos];
      }

      return this.fullPositions[position];
    },

    getDraftPos(rosterPos) {
      for (const posInfo of this.roster) {
        if (posInfo.short != 'SF' && posInfo.pos.includes(rosterPos)) {
          return posInfo.short;
        }
      }

      return rosterPos;
    },

    getRosterPositions(draftPos) {
      for (const posInfo of this.roster) {
        if (posInfo.short == draftPos) {
          let rosterPositions = posInfo.pos;

          // Convert all SuperFlex draft positions into all roster positions
          if (draftPos == 'SF') {
            rosterPositions = [];
            for (const superFlexDraftPos of posInfo.pos) {
              const superFlexRosterPos = this.getRosterPositions(superFlexDraftPos);
              rosterPositions = rosterPositions.concat(superFlexRosterPos);
            }
          }

          return rosterPositions;
        }
      }

      return [];
    },

    getRemainingPicks(position) {
      if (!this.showDraftPositionFilters) {
        return '';
      }

      for (const posInfo of this.roster) {
        if (posInfo.short == position) {
          return this.picks[position] ? posInfo.num - this.picks[position].length : posInfo.num;
        }
      }

      return '';
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }
      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    changeSort(loc, stat) {
      this.resetTableScroll();
      this.sortedBy.loc = loc;
      this.sortedBy.stat = stat;
    },

    isSelected(loc, stat) {
      return this.sortedBy.loc == loc && this.sortedBy.stat == stat;
    },

    openPlayerCard(player) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', player.id, this.contest.sport, this.contest.id, true);
    },
  },
};
</script>

<style lang="scss" scoped>

  .playerListBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .endSection {
    border-left: 2px solid var(--obcolor-background-2)
  }

  .salaryFilter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .salaryHeading {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .salaryLabel {
    font-size: 12px;
    margin-top: 3px;
  }

  .rangeSliderContainer {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .rangeSliderLabel {
    font-size: 12px;
    width: 50px;
  }

  .rangeSliderLabel:first-child {
    margin-right: 10px;
    text-align: right;
  }

  .rangeSliderLabel:last-child {
    margin-left: 10px;
  }

  .searchInput {
    display: flex;
    width: 125px;
    height: 27px;
    background: var(--obcolor-background-6);
    align-items: center;
    padding: 0 6px;
    border: 1px solid var(--obcolor-background-1);

    input {
      box-sizing: border-box;
      flex: 1;
      min-width: 0;
      height: 100%;
      padding: 0 5px;
      margin-left: 1px;
      background: var(--obcolor-background-6);
    }

    .inputIcon {
      margin-top: 2px;
      font-size: 13px;
      color: var(--obcolor-font-secondary);
    }

    .cancelSearch {
      color: red;
      font-size: 12px;
      cursor: pointer;
      padding: 5px;
      margin-right: -4px;
    }

    .cancelSearch:hover {
      color: #a80f0f;
    }
  }

  .filterList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2px;
  }

  .posFilters {
    display: flex;
    align-items: center;
  }

  .filterBtn {
    color: white;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-left: 5px;
  }

  .filterBtn.filterSelected {
    .filterTop {
      background: var(--obcolor-ob-blue);
      border-radius: 6px 6px 0 0;
      padding: 4px 8px 2px 8px;
    }

    .filterBottom {
      background: var(--obcolor-ob-blue-tinted);
      border-radius: 0 0 6px 6px;
      padding: 2px 8px 4px 8px;
    }

    .filterPos {
      background: var(--obcolor-ob-blue);
      border-radius: 6px;
      padding: 6px 8px;
    }

    .filterTop:hover {
      background: var(--obcolor-accent-hl);
    }

    .filterBottom:hover {
      background: var(--obcolor-accent-light-hl);
    }

    .filterPos:hover {
      background: var(--obcolor-accent-hl);
    }
  }

  .filterBtn:not(.filterSelected):not(.filterSlotsFull) {
    .filterTop {
      background: var(--btn-grey);
      border-radius: 6px 6px 0 0;
      padding: 4px 8px 2px 8px;
    }

    .filterBottom {
      background: var(--btn-grey-hl);
      border-radius: 0 0 6px 6px;
      padding: 2px 8px 4px 8px;
    }

    .filterPos {
      background: var(--btn-grey);
      border-radius: 6px;
      padding: 6px 8px;
    }
  }

  .filterBtn:not(.filterSelected).filterSlotsFull {
    .filterTop {
      background: var(--btn-grey-dark);
      border-radius: 6px 6px 0 0;
      padding: 4px 8px 2px 8px;
    }

    .filterBottom {
      background: var(--btn-grey-dark-hl);
      border-radius: 0 0 6px 6px;
      padding: 2px 8px 4px 8px;
    }

    .filterPos {
      background: var(--btn-grey-dark);
      border-radius: 6px;
      padding: 6px 8px;
    }
  }

  .filterBtn:hover {
    .filterTop {
      background: var(--obcolor-accent-hl);
    }

    .filterBottom {
      background: var(--obcolor-accent-light-hl);
    }

    .filterPos {
      background: var(--obcolor-accent-hl);
    }
  }

  .filterBtn:not(.filterSelected):not(.filterSlotsFull):hover {
    .filterTop {
      background: var(--btn-grey-light);
    }

    .filterBottom {
      background: var(--btn-grey-light-hl);
    }

    .filterPos {
      background: var(--btn-grey-light);
    }
  }

  .filterBtn:not(.filterSelected).filterSlotsFull:hover {
    .filterTop {
      background: var(--btn-grey);
    }

    .filterBottom {
      background: var(--btn-grey-hl)
    }

    .filterPos {
      background: var(--btn-grey);
    }
  }

  .tableContainer {
    flex: 1;
    min-height: 0;
    overflow: auto;
    margin-top: 5px;
    transition: height ease-out 0.3s;
  }

  table {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  th {
    color: #878998;
    background: var(--obcolor-background-3);
    padding: 12px 6px;
    font-size: 12px;
    position: sticky;
    top: 0;
    white-space: nowrap;
    z-index: 60;
  }

  tr:nth-child(odd) {
    background: var(--obcolor-background-5);
  }

  tr:hover {
    background: var(--obcolor-bg-hover);
  }

  .statHeader {
    width: 88px;
    height: 37px;
    padding: 0;
    box-sizing: border-box;
  }

  .statHeader:not(.noHover) {
    cursor: pointer;
  }

  .statHeader:not(.noHover):hover {
    background:
    var(--obcolor-background-2);
  }

  .statHeader > div {
    width: 100%;
  }

  .statHeader .headerTrigger {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .selectedCol {
    font-weight: bold;
    background: var(--obcolor-background-2);
  }

  .playerRow:hover {
    background: var(--obcolor-background-4);
  }
  .playerStatus {
    margin-top: -2px;
    margin-right: 8px;
  }

  .pickedPlayer {
    color: var(--obcolor-text-faded) !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 20px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .teamSelector {
    width: 120px;
    height: 31px;
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
  }

  .selectViewContainer {
    display: flex;
    margin-left: 30px;
    margin-right: 25px;
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    text-transform: uppercase;
    cursor: pointer;
    div:first-child {
      padding-right: 8px;
      border-right: 1px solid var(--obcolor-font-secondary);
    }
    div:hover {
      color: var(--obcolor-ob-blue);
    }
  }

  .emptyRow td {
    padding: 10px;
    color: var(--obcolor-font-secondary);
  }

</style>