// @ts-check

import ObPropsApi from '@/api/ObPropsApi';
import EventBus from '@/event-bus';
import store from '@/store';
import Vue from 'vue';
import {mapGetters} from 'vuex';

/**
 * @typedef {import('@/api/ObPropsApi').PropSettings} PropSettings
 * @typedef {import('@/utils/SocketController').default} SocketController
 *
 * @typedef {{
 *  type: 'UPDATE_PROP_SETTINGS',
 * }} SocketUpdate
 *
 * @typedef {PropsSettingsMixin & {
 *  PropsSettingsMixin_handleSocketUpdate(data: SocketUpdate): void,
 *  $SocketController: SocketController,
 * }} PropsSettingsMixinThis
 *
 * @typedef {{
 *  propsSettings: PropSettings|null,
 *  propsSettingsError: string|null,
 * }} PropsSettingsMixin
 */

/**
 * @return {Promise<void>}
 */
function updateSettings() {
  return ObPropsApi.getPropsSettings()
      .then((settings) => {
        store.commit('playerPicks/setSettings', settings);
      })
      .catch((error) => {
        let err = 'Failed loading props settings';
        if (error instanceof Error) {
          err += ': ' + error.message;
        }
        store.commit('playerPicks/setSettingsErr', err);
      });
}

EventBus.$on('SOCKET_BROADCAST', (data) => {
  if (data.type !== 'UPDATE_PROP_SETTINGS') {
    return;
  }
  updateSettings();
});

/**
 * PropsSettingsMixin loads the props settings and keeps them up to date with
 * socket updates. It adds a property to access the settings. The properties
 * added can be found here {@link PropsSettingsMixin}.
 *
 * @example
 * <template>
 *   <div>
 *     {{ propsSettings }}
 *   </div>
 * </template>
 *
 * <script>
 *   export default Vue.extend({
 *     mixins: [PropsSettingsMixin],
 *   })
 * </script>
 */
export default Vue.extend({

  /**
   * @this {PropsSettingsMixinThis}
   */
  created() {
    this.$SocketController.subscribeToRoom('UPDATE_PROP_SETTINGS');

    updateSettings();
  },

  /**
   * @this {PropsSettingsMixinThis}
   */
  destroyed() {
    this.$SocketController.unsubscribeFromRoom('UPDATE_PROP_SETTINGS');
  },
  computed: {
    ...mapGetters('playerPicks', ['propsSettings', 'propsSettingsError']),
  },
});
