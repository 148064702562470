<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="padding: 0; width: 820px; height: 743px;"
    bodyStyle="background: var(--obcolor-background-page);"
  >
    <div slot="header" class="header">
      <div class="title">Enter Contests</div>
      <div class="subTitle">Enter your lineup into more contests for a chance at more prizes.</div>
    </div>

    <div v-if="joinContestError" slot="body" class="body errorContainer">
      <div>
        <div class="errorTitle">Failed to Join Contests</div>
        <div class="errorMessage">{{ joinContestError }}</div>
        <button class="ob-btn-grey continueBtn" @click="closeError">Continue</button>
      </div>
    </div>

    <div v-else slot="body" class="body">
      <PlayerList :players="players" :sport="sport" />
      <ObLoading v-if="loadingContests" />
      <QuickEntryContestList v-else class="contestList ob-scroll"
        :contestList="contests" :entriesByContest="entriesByContest"
      />
    </div>

    <div slot="footer" class="footer">
      <QuickEntryInfo :entryInfo="selectedEntryInfo" />
      <div style="flex: 1;"></div>
      <button v-if="showLineupsButton" class="ob-btn-grey" @click="goToLineupsPage()">
        View Lineups
      </button>
      <button v-if="joiningContests" class="ob-btn-grey loadingBtn" disabled>
        <ObLoading :size="40" /> Joining...
      </button>
      <button v-else :class="canSubmit? 'ob-btn' : 'ob-btn-grey'"
        :disabled="!canSubmit" @click="submitEntries"
      >
        Join Contests
      </button>
    </div>
  </Modal>
</template>

<script>
import {mapState} from 'vuex';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import Modal from '@/components/Modal';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import PlayerList from './PlayerList.vue';
import QuickEntryInfo from './QuickEntryInfo.vue';
import QuickEntryContestList from '@/views/SalaryCapGame/QuickEntryModal/QuickEntryContestList';

export default {

  props: {
    showLineupsButton: {type: Boolean, default: true},
  },

  components: {
    Modal,
    PlayerList,
    QuickEntryInfo,
    ObLoading,
    QuickEntryContestList,
  },

  data() {
    return {
      isOpen: false,
      slateId: null,
      sport: null,
      players: [],
      contests: [],
      entriesByContest: {},
      loadingContests: false,
      joiningContests: false,
      joinContestError: null,
    };
  },

  computed: {
    ...mapState(['balanceValue']),

    canSubmit() {
      const entryInfo = this.selectedEntryInfo;
      return entryInfo.entries > 0 && entryInfo.fees <= this.balanceValue;
    },

    selectedEntryInfo() {
      const info = {};
      info.entries = 0;
      info.fees = 0;
      info.prizes = 0;

      for (const contest of this.contests) {
        const numEntries = this.entriesByContest[contest.id];
        if (numEntries > 0) {
          info.entries += numEntries;
          info.prizes += contest.prizeValue;
          info.fees += contest.feeValue * numEntries;
        }
      }
      info.userCanAfford = this.balanceValue >= info.fees;
      return info;
    },

    contestMap() {
      const contestMap = {};
      for (const contest of this.contests) {
        contestMap[contest.id] = contest;
      }
      return contestMap;
    },
  },

  created() {
    EventBus.$on('OPEN_LINEUP_QUICK_ENTRY', this.openModal);
    EventBus.$on('QUICK_ENTRY_CHANGE_ENTRY_VAL', this.updateEntryVal);
  },

  destroyed() {
    EventBus.$off('OPEN_LINEUP_QUICK_ENTRY', this.openModal);
    EventBus.$off('QUICK_ENTRY_CHANGE_ENTRY_VAL', this.updateEntryVal);
  },

  methods: {
    openModal(sport, players, slateId) {
      this.sport = sport;
      this.slateId = slateId;
      this.players = players;
      this.entriesByContest = {};
      this.joiningContests = false;
      this.loadContests();
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.slateId = null;
      this.sport = null;
      this.contests = [];
      this.players = [];
      this.entriesByContest = {};
    },

    closeError() {
      this.joinContestError = null;
      this.loadContests();
    },

    getPlayerIds() {
      const playerIds = [];
      for (const player of this.players) {
        playerIds.push(player.id);
      }
      return playerIds;
    },

    getContestEntries() {
      const entries = [];
      for (const contestId in this.entriesByContest) {
        const numEntries = this.entriesByContest[contestId];
        if (numEntries > 0) {
          entries.push({id: contestId, count: numEntries});
        }
      }
      return entries;
    },

    updateEntryVal(contestId, value) {
      this.entriesByContest[contestId] = value;
    },

    initializeEntryValues() {
      const entries = {};
      for (const contest of this.contests) {
        entries[contest.id] = 0;
      }
      this.entriesByContest = entries;
    },

    loadContests() {
      this.loadingContests = true;
      this.contests = [];
      this.getContests();
    },

    getContests() {
      this.loadingContests = true;
      this.contests = [];
      const players = this.getPlayerIds();
      ObSalaryCapApi.getQuickEntryContests(this.slateId, true, players)
          .then((response) => {
            this.contests = response.contests;
            this.initializeEntryValues();
            this.loadingContests = false;
          }).catch((_error) => {
            // Do nothing, will show 'no available contests'
            this.loadingContests = false;
          });
    },

    submitEntries() {
      this.joiningContests = true;
      this.joinContestError = null;
      const players = this.getPlayerIds();
      const entries = this.getContestEntries();

      // Copy contest list and change to contest IDs that have been selected
      const selectedContestList = this.contests.slice().filter((contest) => {
        return this.entriesByContest[contest.id];
      });

      ObSalaryCapApi.submitQuickEntry(players, entries)
          .then((response) => {
            // Reuse the results modal from lineup builder quick-entry
            const resultsList = response;
            EventBus.$emit('SALARY_OPEN_QUICK_SUCCESS_MODAL',
                selectedContestList,
                resultsList,
                this.entriesByContest,
            );

            // Track each successful/unsuccessful entry
            for (const contestResult of resultsList) {
              const contest = this.contestMap[contestResult.contestId];
              this.$Analytics.trackTournamentEntry(contest, contestResult.success, null, this.entriesByContest[contestResult.contestId], true);
            }

            this.joiningContests = false;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('RELOAD_LINEUPS_PAGE');
            this.closeModal();
          }).catch((error) => {
            if (error?.respons?.data) {
              this.joinContestError = error?.respons?.data;
            } else {
              this.joinContestError = 'Failed to enter contests, please try again';
            }
            this.entriesByContest = {};
            this.joiningContests = false;
          });
    },

    goToLineupsPage() {
      this.$router.push('/salarycap/lineups');
    },
  },

};
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  padding: 10px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .subTitle {
    font-size: 14px;
    margin-top: 4px;
    color: var(--obcolor-font-secondary);
  }
}

.body {
  display: flex;
  gap: 10px;
  padding: 10px;
  min-height: 200px;
  > div {
    width: 50%;
  }
}

.footer {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 65px;
  font-size: 14px;
  gap: 10px;
  > button {
    width: 120px;
  }
}

.contestList {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-right: 5px;
}

.loadingBtn {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 4px
}

.errorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.errorTitle {
  font-weight: bold;
  font-size: 16px;
  color: var(--obcolor-font-primary);
}
.errorMessage {
  font-size: 14px;
  margin-top: 15px;
  color: var(--obcolor-red);
}
.continueBtn {
  font-size: 14px;
  margin-top: 20px;
}

</style>