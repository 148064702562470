const TEAMCOLORS_NHL = {
  Anh: {primary: '#836e4b', secondary: '#d34d2a', bg: 'secondary'},
  Ari: {primary: '#79b1e0', secondary: '#fff', bg: 'primary'},
  Bos: {primary: '#f2b12a', secondary: '#444', bg: 'primary'},
  Buf: {primary: '#21355e', secondary: '#f2b12a', bg: 'secondary'},
  Car: {primary: '#b8262e', secondary: '#9ea4a2', bg: 'primary'},
  Cgy: {primary: '#b9262f', secondary: '#ecba44', bg: 'secondary'},
  Chi: {primary: '#b9262f', secondary: '#c28532', bg: 'primary'},
  Cls: {primary: '#22355d', secondary: '#b9262f', bg: 'secondary'},
  Col: {primary: '#5f2535', secondary: '#265882', bg: 'secondary'},
  Dal: {primary: '#13593c', secondary: '#9ba3a2', bg: 'primary'},
  Det: {primary: '#ba2633', secondary: '#444', bg: 'primary'},
  Edm: {primary: '#272e61', secondary: '#de4a2b', bg: 'secondary'},
  Fla: {primary: '#ba2631', secondary: '#bb9461', bg: 'secondary'},
  LA: {primary: '#afb7ba', secondary: '#444', bg: 'primary'},
  Min: {primary: '#1b4031', secondary: '#e2a52e', bg: 'primary'},
  Mon: {primary: '#95242e', secondary: '#262f6f', bg: 'secondary'},
  NJ: {primary: '#b9262f', secondary: '#444', bg: 'primary'},
  NYI: {primary: '#28346b', secondary: '#de4a2b', bg: 'secondary'},
  NYR: {primary: '#233a82', secondary: '#ba2633', bg: 'secondary'},
  Nsh: {primary: '#f2b12a', secondary: '#23345e', bg: 'primary'},
  Ott: {primary: '#da1a32', secondary: '#b79257', bg: 'secondary'},
  Phi: {primary: '#dd442b', secondary: '#dd442b', bg: 'secondary'},
  Pho: {primary: '#74252f', secondary: '#d9c69a', bg: 'primary'},
  Pit: {primary: '#FFB81C', secondary: '#444', bg: 'primary'},
  SJ: {primary: '#1b5964', secondary: '#1b5964', bg: 'primary'},
  Sea: {primary: '#001425', secondary: '#96D8D8', bg: 'primary'},
  StL: {primary: '#28346a', secondary: '#f2b12a', bg: 'primary'},
  TB: {primary: '#222b66', secondary: '#444', bg: 'primary'},
  Tor: {primary: '#012763', secondary: '#444', bg: 'primary'},
  Uta: {primary: '#79b1e0', secondary: '#fff', bg: 'primary'},
  Van: {primary: '#30386f', secondary: '#00843D', bg: 'secondary'},
  VGK: {primary: '#b59963', secondary: '#333d40', bg: 'primary'},
  Was: {primary: '#041e42', secondary: '#c8102e', bg: 'primary'},
  Wpg: {primary: '#173372', secondary: '#9aa3a2', bg: 'secondary'},
};

const TEAMCOLORS_NBA = {
  Atl: {primary: '#cd2231', secondary: '#444', bg: 'secondary'},
  Bkn: {primary: '#000', secondary: '#fff', bg: 'secondary'},
  Bos: {primary: '#0b7b3e', secondary: '#BE974F', bg: 'primary'},
  Cha: {primary: '#07778d', secondary: '#1d1160', bg: 'primary'},
  Chi: {primary: '#bc202f', secondary: '#444', bg: 'secondary'},
  Cle: {primary: '#860038', secondary: '#041e42', bg: 'primary'},
  Dal: {primary: '#2759a8', secondary: '#a7a8ab', bg: 'secondary'},
  Den: {primary: '#1D428A', secondary: '#fab728', bg: 'secondary'},
  Det: {primary: '#eb204d', secondary: '#006BB6', bg: 'secondary'},
  GS: {primary: '#0c6ab2', secondary: '#f6c22e', bg: 'secondary'},
  Hou: {primary: '#d22148', secondary: '#C6CFD3', bg: 'secondary'},
  Ind: {primary: '#2f4b90', secondary: '#f8b832', bg: 'secondary'},
  LAC: {primary: '#c8102e', secondary: '#1d428a', bg: 'primary'},
  LAL: {primary: '#552583', secondary: '#fdb927', bg: 'primary'},
  Mem: {primary: '#6289b9', secondary: '#fab827', bg: 'primary'},
  Mia: {primary: '#971b32', secondary: '#f5a01f', bg: 'secondary'},
  Mil: {primary: '#0a5039', secondary: '#ded2af', bg: 'secondary'},
  Min: {primary: '#015184', secondary: '#a7a8ab', bg: 'primary'},
  NO: {primary: '#c8102e', secondary: '#0c2340', bg: 'primary'},
  NY: {primary: '#f58426', secondary: '#006bb6', bg: 'secondary'},
  OKC: {primary: '#ef3b24', secondary: '#007ac1', bg: 'secondary'},
  Orl: {primary: '#0077c0', secondary: '#c4ced4', bg: 'primary'},
  Phi: {primary: '#ed174c', secondary: '#006bb6', bg: 'primary'},
  Pho: {primary: '#1d1160', secondary: '#e56020', bg: 'primary'},
  Por: {primary: '#e13b3f', secondary: '#c4ced4', bg: 'secondary'},
  SA: {primary: '#c4ced4', secondary: '#000', bg: 'primary'},
  Sac: {primary: '#694c9f', secondary: '#C6CFD3', bg: 'secondary'},
  Tor: {primary: '#bd1034', secondary: '#C6CFD3', bg: 'secondary'},
  Uta: {primary: '#ffffff', secondary: '#50008d', bg: 'primary'},
  Was: {primary: '#143784', secondary: '#e42039', bg: 'secondary'},
};

const TEAMCOLORS_NFL = {
  Ari: {primary: '#b2203f', secondary: '#fcc21b', bg: 'primary'},
  Atl: {primary: '#c92440', secondary: '#d3d4d5', bg: 'primary'},
  Bal: {primary: '#373396', secondary: '#ac8c30', bg: 'primary'},
  Buf: {primary: '#294a9b', secondary: '#cd2230', bg: 'secondary'},
  Car: {primary: '#1d99d6', secondary: '#C6CFD3', bg: 'primary'},
  Chi: {primary: '#0b162a', secondary: '#e64100', bg: 'secondary'},
  Cin: {primary: '#f05127', secondary: '#444', bg: 'primary'},
  Cle: {primary: '#ea561c', secondary: '#664420', bg: 'secondary'},
  Dal: {primary: '#1b3f86', secondary: '#bfbfbf', bg: 'secondary'},
  Den: {primary: '#f05223', secondary: '#1c4688', bg: 'primary'},
  Det: {primary: '#046eb1', secondary: '#C6CFD3', bg: 'secondary'},
  GB: {primary: '#2c5b4c', secondary: '#ffc317', bg: 'secondary'},
  Hou: {primary: '#192a77', secondary: '#c92540', bg: 'primary'},
  Ind: {primary: '#1f3e72', secondary: '#444', bg: 'primary'},
  Jax: {primary: '#d8a329', secondary: '#056879', bg: 'secondary'},
  KC: {primary: '#b21f36', secondary: '#444', bg: 'primary'},
  LA: {primary: '#1d3667', secondary: '#FFD100', bg: 'secondary'},
  Mia: {primary: '#038d95', secondary: '#f58220', bg: 'primary'},
  Min: {primary: '#592e84', secondary: '#ffc82f', bg: 'primary'},
  NE: {primary: '#1c3f78', secondary: '#cc232f', bg: 'primary'},
  NO: {primary: '#c9af75', secondary: '#c2c7c8', bg: 'primary'},
  NYG: {primary: '#23337d', secondary: '#b02039', bg: 'primary'},
  NYJ: {primary: '#115740', secondary: '#fff', bg: 'secondary'},
  Oak: {primary: '#c2c7c8', secondary: '#444', bg: 'primary'},
  Phi: {primary: '#0a4e55', secondary: '#bac3c8', bg: 'primary'},
  Pit: {primary: '#ffc317', secondary: '#b3babf', bg: 'primary'},
  SD: {primary: '#1480c1', secondary: '#fdc218', bg: 'primary'},
  SF: {primary: '#c42227', secondary: '#ba9b5a', bg: 'secondary'},
  Sea: {primary: '#002244', secondary: '#69be28', bg: 'secondary'},
  StL: {primary: '#1d3667', secondary: '#c8af75', bg: 'primary'},
  TB: {primary: '#d52127', secondary: '#bac3c8', bg: 'secondary'},
  Ten: {primary: '#4695d1', secondary: '#da2429', bg: 'secondary'},
  Was: {primary: '#5a1414', secondary: '#ffb612', bg: 'primary'},
};

const TEAMCOLORS_MLB = {
  Ari: {primary: '#95242f', secondary: '#d2c194', bg: 'secondary'},
  Atl: {primary: '#a72632', secondary: '#003771', bg: 'secondary'},
  Bal: {primary: '#dd4c2b', secondary: '#444', bg: 'primary'},
  Bos: {primary: '#b8262f', secondary: '#002E68', bg: 'secondary'},
  CWS: {primary: '#c2c9ca', secondary: '#c2c9ca', bg: 'primary'},
  ChC: {primary: '#2d3f80', secondary: '#b62732', bg: 'secondary'},
  Cin: {primary: '#d8244b', secondary: '#444', bg: 'primary'},
  Cle: {primary: '#c52633', secondary: '#21418f', bg: 'secondary'},
  Col: {primary: '#37327e', secondary: '#838681', bg: 'primary'},
  Det: {primary: '#0d2d56', secondary: '#FE4006', bg: 'primary'},
  Hou: {primary: '#1d4086', secondary: '#d56c2d', bg: 'secondary'},
  KC: {primary: '#2b3b89', secondary: '#C6CFD3', bg: 'secondary'},
  LAA: {primary: '#c02544', secondary: '#384984', bg: 'primary'},
  LAD: {primary: '#1c5287', secondary: '#c2c9ca', bg: 'primary'},
  Mia: {primary: '#EF3340', secondary: '#2f6ca6', bg: 'primary'},
  Mil: {primary: '#11284B', secondary: '#fdb801', bg: 'secondary'},
  Min: {primary: '#ab2637', secondary: '#094486', bg: 'secondary'},
  NYM: {primary: '#042e75', secondary: '#de4b2b', bg: 'primary'},
  NYY: {primary: '#003680', secondary: '#c4ced4', bg: 'secondary'},
  Oak: {primary: '#143c33', secondary: '#f0b02e', bg: 'secondary'},
  Phi: {primary: '#b92d49', secondary: '#002D72', bg: 'secondary'},
  Pit: {primary: '#f2b236', secondary: '#444', bg: 'secondary'},
  SD: {primary: '#784E24', secondary: '#444', bg: 'primary'},
  SF: {primary: '#dc482e', secondary: '#444', bg: 'secondary'},
  Sea: {primary: '#125e52', secondary: '#125e52', bg: 'primary'},
  StL: {primary: '#be2544', secondary: '#023d8a', bg: 'secondary'},
  TB: {primary: '#6aa9cf', secondary: '#444', bg: 'primary'},
  Tex: {primary: '#aa2631', secondary: '#00327a', bg: 'primary'},
  Tor: {primary: '#224084', secondary: '#87BCE1', bg: 'secondary'},
  Was: {primary: '#aa2833', secondary: '#0a4281', bg: 'secondary'},
};

const TeamInfo = {
  getDisplayAlias(sport, alias) {
    if (sport == null || alias == null) {
      return alias;
    }
    if (sport === 'NFL') {
      switch (alias.toUpperCase()) {
        case 'SD': return 'LAC';
        case 'OAK': return 'LV';
        case 'LA': return 'LAR';
      }
    } else if (sport === 'NHL') {
      switch (alias.toUpperCase()) {
        case 'ARI': return 'UTA';
      }
    }
    return alias.toUpperCase();
  },

  getTeamColor(alias, sport) {
    switch (sport) {
      case 'NHL':
        if (TEAMCOLORS_NHL[alias]) {
          return TEAMCOLORS_NHL[alias][TEAMCOLORS_NHL[alias].bg];
        }
        break;
      case 'NBA':
        if (TEAMCOLORS_NBA[alias]) {
          return TEAMCOLORS_NBA[alias][TEAMCOLORS_NBA[alias].bg];
        }
        break;
      case 'NFL':
        if (TEAMCOLORS_NFL[alias]) {
          return TEAMCOLORS_NFL[alias][TEAMCOLORS_NFL[alias].bg];
        }
        break;
      case 'MLB':
        if (TEAMCOLORS_MLB[alias]) {
          return TEAMCOLORS_MLB[alias][TEAMCOLORS_MLB[alias].bg];
        }
        break;
    }
    return '#000';
  },

  getPrimaryTeamColor(alias, sport) {
    switch (sport) {
      case 'NHL':
        return TEAMCOLORS_NHL[alias].primary;
      case 'NBA':
        return TEAMCOLORS_NBA[alias].primary;
      case 'NFL':
        return TEAMCOLORS_NFL[alias].primary;
      case 'MLB':
        return TEAMCOLORS_MLB[alias].primary;
    }
    return '#000';
  },

  getSecondaryTeamColor(alias, sport) {
    switch (sport) {
      case 'NHL':
        return TEAMCOLORS_NHL[alias].secondary;
      case 'NBA':
        return TEAMCOLORS_NBA[alias].secondary;
      case 'NFL':
        return TEAMCOLORS_NFL[alias].secondary;
      case 'MLB':
        return TEAMCOLORS_MLB[alias].secondary;
    }
    return '#000';
  },
};

TeamInfo.install = function(Vue, options) {
  Vue.prototype.$TeamInfo = TeamInfo;
};

export default TeamInfo;
