<template>
  <ObLoading v-if="!atwInfo" />

  <div v-else class="ob-page">
    <div class="ob-box">
      <div class="ob-subheading" style="margin-top: 6px;">Bonuses</div>
      <div class="btn ob-btn-grey" :class="{selectedBtn: !showActive}" @click="setShowActive(true)">Active Bonuses</div>
      <div class="btn ob-btn-grey" :class="{selectedBtn: showActive}" @click="setShowActive(false)">Completed Bonuses</div>
      <table style="margin-top: 10px;" aria-label="Bonuses">
        <tr>
          <th>Date Earned</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Playthrough Ratio</th>
          <th>Amount Remaining</th>
          <th v-if="showActive" style="width: 200px;">Progress</th>
        </tr>
        <template v-if="atwInfo && awtBonuses && awtBonuses.length > 0">
          <tr v-for="(bonus, index) in sortedBonuses" :key="index">
            <td v-if="bonus.date">{{ $moment(bonus.date).format("MMM Do YYYY, h:mma") }}</td>
            <td v-else>-</td>
            <td v-if="bonus.note">{{ bonus.note }}</td>
            <td v-else>-</td>
            <td v-if="bonus.bonusAmount">${{ centsToDollars(bonus.bonusAmount) }}</td>
            <td v-else>0</td>
            <td v-if="bonus.playThroughRatio">{{ bonus.playThroughRatio }}:1</td>
            <td v-else>-</td>
            <td v-if="bonus.playThroughAmountRemaining" class="amountRemaining">
              ${{ centsToDollars(bonus.playThroughAmountRemaining) }}
            </td>
            <td v-else>0</td>
            <td v-if="bonus.playThroughAmountRemaining && bonus.playThroughAmount && showActive">
              <div class="progressBarContainer">
                <div class="fullText">{{ fullPercent(bonus) }}%</div>
                <div class="progressBar">
                  <div class="progress" :style="'width: ' + fullPercent(bonus) + '%;'"></div>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template v-else>
          <p class="noBonuses">No available bonuses</p>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import ObFinanceApi from '@/api/ObFinanceApi';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';

export default {
  components: {
    ObLoading,
  },

  data() {
    return {
      atwInfo: null,
      showActive: true,
    };
  },

  created() {
    this.getWithdrawInformation();
  },

  computed: {
    ...mapState(['userId']),

    awtBonuses() {
      return this.atwInfo.slice().filter((bonus) => {
        if (this.showActive) {
          return bonus.locked;
        } else {
          return !bonus.locked;
        }
      });
    },

    sortedBonuses() {
      return this.awtBonuses.slice().sort((a, b) => {
        return this.compareBonuses(a, b);
      });
    },
  },

  methods: {
    getWithdrawInformation() {
      ObFinanceApi.getWithdrawInformation()
          .then((data) => {
            this.atwInfo = data.bonuses;
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    centsToDollars(value) {
      return (value / 100).toFixed(2).removeCentsIfZero();
    },

    compareBonuses(a, b) {
      // Sort by date
      if (a.date > b.date) {
        return 1;
      } else if (b.date > a.date) {
        return -1;
      }
      return 0;
    },

    fullPercent(bonus) {
      if (!bonus.playThroughAmount || !bonus.playThroughAmountRemaining) {
        return 0;
      }

      return Math.floor(((bonus.playThroughAmount - bonus.playThroughAmountRemaining) / bonus.playThroughAmount) * 100);
    },

    setShowActive(activeBonus) {
      this.showActive = activeBonus;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-right: 10px;
  font-size: 14px;
}
.selectedBtn {
  background: var(--obcolor-background-3) !important;
  color: var(--obcolor-font-primary) !important;
}
.noBonuses {
  padding-left: 10px;
}
table {
  text-align: left;
  width: 100%;
  font-size: 14px;
}
td, th {
  padding: 12px;
}
th {
  background: var(--obcolor-background-2);
}
td, .rowHeader {
  white-space: nowrap;
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}
.ob-subheading {
  margin-bottom: 10px;
}
.progressBarContainer {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
}
.fullText {
  color: var(--obcolor-font-secondary);
  font-size: 11px;
  margin-top: 2px;
  margin-left: -6px;
  white-space: nowrap;
  text-align: right;
  font-weight: bold;
}
.progressBar {
  background: var(--obcolor-timeline);
  height: 8px;
  flex: 1;
  margin-left: 5px;
  border-radius: 10px;
  overflow: hidden;
}
.progress {
  background: var(--obcolor-ob-blue);
  height: 100%;
  transition: 400ms ease-out;
}
.amountRemaining {
  display: flex;
  justify-content: space-between;
}
</style>
