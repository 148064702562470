export const PREGAME_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'W/L', tooltip: 'Wins & Losses', description: 'W/L'},
      {label: 'pitcher.ERA', tooltip: 'Earned Runs Average', description: 'ERA'},
      {label: 'pitcher.K', tooltip: 'Strikeouts', description: 'Strikeouts'},
      {label: 'pitcher.WHIP', tooltip: 'Walks + Hits per Inning Pitched', description: 'WHIP'},
    ],
  },
];

export const PREGAME_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: 'batter.AVG', tooltip: 'Batting Average', description: 'AVG'},
      {label: 'batter.H', tooltip: 'Hits', description: 'Hits'},
      {label: 'batter.HR', tooltip: 'Home Runs', description: 'HR'},
      {label: 'batter.RBI', tooltip: 'Runs Batted In', description: 'RBI'},
    ],
  },
];
