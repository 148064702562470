<template>
  <div v-if="isValidSport" class="startingStatusContainer" v-tooltip="tooltip">
    <template v-if="isStartingPitcher">
      <i class="far fa-baseball-ball icon baseball" alt="Baseball"></i>
    </template>
    <template v-else-if="isStartingBatter && hasStartingLineupPosition">
      <img v-if="theme !== 'dark'" :src="require('@/assets/icons/baseball-bat-black.svg')" class="icon"
        alt="Baseball Bat"
      >
      <img v-else :src="require('@/assets/icons/baseball-bat.svg')" class="icon"
        alt="Baseball Bat"
      >
      <span class="battingOrder">{{ player.startingLineupPosition }}</span>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    sport: {type: String, default: null},
    player: {type: Object, default: null},
  },

  computed: {
    ...mapState(['theme']),

    isValidSport() {
      return this.sport === 'MLB';
    },

    isStartingPitcher() {
      return (this.player.position == 'SP' || this.player.position == 'P') && this.player.hasStartingGameStatus;
    },

    isStartingBatter() {
      return this.player.isStarter;
    },

    hasStartingLineupPosition() {
      return this.player.startingLineupPosition != null && this.player.startingLineupPosition != '';
    },

    tooltip() {
      if (this.isStartingPitcher) {
        return 'Starting Pitcher';
      }
      if (this.isStartingBatter && this.hasStartingLineupPosition) {
        return 'Batting Order';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.startingStatusContainer {
  display: flex;
  flex-direction: row;
  background-color: var(--obcolor-background-2);
  border-radius: 4px;
  border: 1px solid var(--obcolor-background-3);
  text-align: center;

  .icon {
    width: 20px;
    height: 20px;
  }

  .baseball {
    align-content: center;
    font-size: 10px;
    transform: rotate(45deg);
  }

  .battingOrder {
    font-size: 12px;
    font-family: 'Archivo', sans-serif;
    width: 14px;
    padding: 4px 2px 0 0;
  }
}
</style>