import axios from 'axios';
import store from '@/store';
import router from '@/router';

export default () => {
  const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '' : process.env.VUE_APP_BASE_URL,
    withCredentials: true,
  });

  api.defaults.headers.common['OWNERSBOX_DEVICE'] = 'web';
  api.defaults.headers.common['OWNERSBOX_VERSION'] = '2.0.0';

  api.interceptors.response.use(function(response) {
    return response;
  }, function(error) {
    // Intercept unauthorized requests, force a login
    if (error.response?.status === 401) {
      store.commit('setLoggedOut');
      if (router.currentRoute.path != '/login' && !router.currentRoute.path.includes('/signup') ) {
        router.push('/login');
      }
    }
    return Promise.reject(error);
  });

  return api;
};
