export const INGAME_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'pitcher.IP', tooltip: 'Innings Pitched', description: 'IP'},
      {label: 'pitcher.K', tooltip: 'Strikeouts', description: 'Strikeouts'},
      {label: 'pitcher.BB', tooltip: 'Walks', description: 'Walks'},
      {label: 'pitcher.H', tooltip: 'Hits Allowed', description: 'Hits Allowed'},
      {label: 'pitcher.SO', tooltip: 'Shut Outs', description: 'Shut Outs'},
      {label: 'pitcher.ER', tooltip: 'Earned Runs', description: 'Earned Runs'},
      {label: 'pitcher.SV', tooltip: 'Saves', description: 'Saves'},
    ],
  },
];

export const INGAME_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: 'H/AB', tooltip: 'At Bats', description: 'H/AB'},
      {label: 'batter.1B', tooltip: 'Single', description: 'Singles'},
      {label: 'batter.2B', tooltip: 'Double', description: 'Doubles'},
      {label: 'batter.3B', tooltip: 'Triple', description: 'Triples'},
      {label: 'batter.RBI', tooltip: 'Runs Batted In', description: 'RBI'},
      {label: 'batter.K', tooltip: 'Strikeout', description: 'Strikeouts'},
      {label: 'batter.R', tooltip: 'Runs', description: 'Runs'},
      {label: 'batter.BB', tooltip: 'Walks', description: 'Walks'},
      {label: 'batter.HBP', tooltip: 'Hit By Pitch', description: 'Hit By Pitch'},
      {label: 'batter.SB', tooltip: 'Stolen Bases', description: 'Stolen Bases'},
      {label: 'batter.HR', tooltip: 'Home Runs', description: 'Home Runs'},
    ],
  },
];
