<template>
  <Modal v-if="isOpen" v-model="isOpen" :disableClickaway="true"
    containerStyle="min-width: 460px; padding: 3px;"
  >
    <div slot="header" class="modalHeader">
      <div>Create Lineup</div>
      <div>Select the slate you want to create a lineup for</div>
    </div>

    <div v-if="isLoading" slot="body" class="modalBody">
      <div class="loadingBox">
        <i class="fad fa-spinner-third fa-spin"></i>Loading Game Slates...
      </div>
    </div>

    <div v-else-if="gameSlateError" slot="body" class="modalBody">
      <div class="loadingBox">{{ gameSlateError }}</div>
    </div>

    <div v-else-if="gameSlates.length == 0" slot="body" class="modalBody">
      <div class="loadingBox">No active game slates</div>
    </div>

    <div v-else slot="body" class="modalBody">
      <router-link v-for="gameSlate in gameSlates" :key="gameSlate.id"
        class="slateEntry" :to="'/salarycap/create-lineup/' + gameSlate.id"
      >
        <i :class="sportIcons[gameSlate.sport]" class="sportIcon"></i>
        <div>{{ gameSlate.name }}</div>
        <div style="flex: 1;"></div>
        <div style="color: var(--obcolor-font-secondary);">Starts</div>
        <div>{{ getStartDate(gameSlate) }}</div>
        <i class="fas fa-chevron-right"></i>
      </router-link>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      gameSlateError: null,
      gameSlates: [],
      sportIcons: {
        NFL: 'fas fa-football-ball',
        NBA: 'fas fa-basketball-ball',
        MLB: 'fas fa-baseball-ball',
        NHL: 'fas fa-hockey-puck',
      },
    };
  },

  created() {
    EventBus.$on('OPEN_CREATE_LINEUP_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_CREATE_LINEUP_MODAL', this.openModal);
  },

  methods: {
    openModal() {
      this.loadGameSlates();
      this.isOpen = true;
    },

    loadGameSlates() {
      this.isLoading = true;
      this.gameSlates = [];
      this.gameSlateError = null;

      ObSalaryCapApi.getGameSlates()
          .then((response) => {
            this.gameSlates = response.gameSlates;
            this.isLoading = false;
          }).catch((_error) => {
            this.gameSlateError = 'Failed to load game slates, please try again';
            this.isLoading = false;
          });
    },

    getStartDate(gameSlate) {
      const slateStart = this.$moment(gameSlate.contestStart);
      const weekFromToday = this.$moment().add(7, 'days');
      if (slateStart.isBefore(weekFromToday)) {
        return slateStart.format('ddd, h:mma');
      }
      return slateStart.format('MMM D, h:mma');
    },
  },
};
</script>

<style lang="scss" scoped>

.modalHeader {
  padding: 10px;
  > div:first-child {
    font-size: 16px;
    font-weight: bold;
    color: var(--obcolor-font-primary);
  }
  > div {
    color: var(--obcolor-font-secondary);
    font-size: 14px;
    margin-top: 2px;
  }
}

.modalBody {
  max-height: 455px;
}

.slateEntry {
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 14px;
  height: 56px;
  padding: 0 14px;
  background: var(--obcolor-background-page);
  border-bottom: 1px solid var(--obcolor-background-3);
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: var(--obcolor-background-6);
  }
}

.sportIcon {
  font-size: 22px;
}

.loadingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 12px;
  height: 56px;
  padding: 0 14px;
  background: var(--obcolor-background-page);
}

</style>