<template>
  <div class="infoContainer">
    <div>
      <div class="value">{{ accountBalance }}</div>
      <div class="label">Balance</div>
    </div>
    <div>
      <div class="value">{{ entryInfo.entries }}</div>
      <div class="label">Entries</div>
    </div>
    <div>
      <div class="value" :class="{redText: !entryInfo.userCanAfford}">${{ entryInfo.fees.dollarWithCentsFormat() }}</div>
      <div class="label">Entry Fees</div>
    </div>
    <div>
      <div class="value moneyText">${{ entryInfo.prizes.dollarWithCentsFormat() }}</div>
      <div class="label">Total Prizes</div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {

  props: {
    entryInfo: {type: Object, default: null},
  },

  computed: {
    ...mapState(['accountBalance']),
  },

};
</script>

<style lang="scss" scoped>
.infoContainer {
  display: flex;
  gap: 30px;
}

.value {
  font-weight: bold;
  margin-bottom: 3px;
}

.label {
  color: var(--obcolor-font-secondary);
}
</style>