// @ts-check

import EventBus from '@/event-bus';
import Vue from 'vue';


/**
 * @typedef {import('@/api/ObPropsApi').Market} Market
 * @typedef {import('@/utils/SocketController').default} SocketController
 *
 * @typedef {{
 *  type: 'MARKET_UPDATE',
 *  market: Market,
 * }} SocketUpdate
 *
 * @typedef {{
 *  MarketsMixin_marketIndexesById: Map<string, number>,
 *  MarketsMixin_marketsHandleSocketUpdate(data: SocketUpdate): void,
 *  $SocketController: SocketController,
 * } & MarketsMixin} MarketsMixinThis
 *
 * @typedef {{
 *  markets: Market[],
 *  clearMarkets(): void,
 *  setMarkets(markets: Market[]): void,
 *  appendMarkets(markets: Market[]): void,
 * }} MarketsMixin
 */

/**
 * MarketsMixin manages a list of markets and keeps it up to date with socket
 * updates. It adds a list of markets and several methods to manage it a
 * component. The properties and methods added can be found here
 * {@link MarketsMixin}.
 *
 * @example
 * <template>
 *   <li>
 *     <li v-for="market of markets" v-for="market.id">
 *       {{ market }}
 *     </li>
 *   </li>
 * </template>
 *
 * <script>
 *   export default Vue.extend({
 *     mixins: [MarketsMixin],
 *
 *     created() {
 *       loadMarkets().then((markets) => {
 *         // Sets the markets to watch, this updates this.markets
 *         this.setMarkets(markets)
 *       })
 *     },
 *
 *     methods: {
 *       loadMore() {
 *         loadMoreMarkets().then((markets) => {
 *           // Adds new markets to watch to the end of the list
 *           this.appendMarkets(markets)
 *         })
 *       },
 *
 *       clear() {
 *         // Removes all markets from the list
 *         this.clearMarkets();
 *       }
 *     },
 *   })
 * </script>
 */
export default Vue.extend({
  /**
   * @return {Partial<MarketsMixinThis>}
   */
  data() {
    return {
      markets: [],
      MarketsMixin_marketIndexesById: new Map(),
    };
  },

  /**
   * @this {MarketsMixinThis}
   */
  created() {
    EventBus.$on('SOCKET_BROADCAST', this.MarketsMixin_marketsHandleSocketUpdate);
  },

  /**
     * @this {MarketsMixinThis}
     */
  destroyed() {
    EventBus.$off('SOCKET_BROADCAST', this.MarketsMixin_marketsHandleSocketUpdate);
    this.clearMarkets();
  },

  methods: {
    /**
     * @this {MarketsMixinThis}
     * @param {SocketUpdate} data
     * @return {void}
     */
    MarketsMixin_marketsHandleSocketUpdate(data) {
      if (data.type !== 'MARKET_UPDATE') {
        return;
      }

      const i = this.MarketsMixin_marketIndexesById.get(getMarketIndexKey(data.market));
      if (i === undefined) {
        return;
      }

      const oldMarket = this.markets[i];

      this.markets = [
        ...this.markets.slice(0, i),
        data.market,
        ...this.markets.slice(i+1),
      ];

      EventBus.$emit(marketChangeEvent(data.market), data.market, oldMarket);
    },

    /**
     * @this {MarketsMixinThis}
     * @param {Market[]} newContests
     * @return {void}
     */
    setMarkets(newContests) {
      this.clearMarkets();
      this.appendMarkets(newContests);
    },

    /**
     * @this {MarketsMixinThis}
     * @param {Market[]} markets
     * @return {void}
     */
    appendMarkets(markets) {
      let i = this.markets.length;

      const newMarkets = markets.filter((market) => !this.MarketsMixin_marketIndexesById.has(getMarketIndexKey(market)));
      this.markets = this.markets.concat(newMarkets);

      for (const market of newMarkets) {
        this.$SocketController.subscribeToRoom('MARKET_UPDATE_' + market.id);
        this.MarketsMixin_marketIndexesById.set(getMarketIndexKey(market), i);
        i++;
      }
    },

    /**
       * @this {MarketsMixinThis}
       * @return {void}
       */
    clearMarkets() {
      for (const market of this.markets) {
        this.$SocketController.unsubscribeFromRoom('MARKET_UPDATE_' + market.id);
      }
      this.markets = [];
      this.MarketsMixin_marketIndexesById = new Map();
    },
  },
});

/**
 *
 * @param {Market} market
 * @return {string}
 */
function getMarketIndexKey(market) {
  return `${market.id}_${market.discount?.id ?? 'NO_DISCOUNT'}`;
}

/**
 *
 * @param {Market} market
 * @return {string}
 */
export function marketChangeEvent(market) {
  return 'MARKET_CHANGE_' + getMarketIndexKey(market);
}
