<template>
  <tr>
    <td style="width: 500px;">
      <div style="display: flex; margin-top: 5px;">
        <div class="sportImg">
          <img :src="require('@/assets/icons/sporticon_' + sport + '.png')" style="height: 22px;" alt="Sport Icon">
        </div>
        <div v-if="lineup.customName" class="lineupNum">{{ lineup.customName }}</div>
        <div v-else class="lineupNum">Lineup #{{ lineup.teamNumber }}</div>
      </div>
      <div style="display: flex; max-width: 500px; flex-wrap: wrap; margin-left: 30px; margin-bottom: 5px;">
        <div v-for="(player, index) in sortedPlayers" :key="index" class="player"
          @click.stop="openPlayerCard(player.id, sport, contestId)"
        >
          <div :class="playerPlaying(player.id, index) ? 'playerActive' : 'playerInactive'">
            <span>{{ getPlayerName(player) }}</span>
            <span v-if="index !== lineup.players.length - 1">,</span>
          </div>
        </div>
      </div>
    </td>
    <td style="width: 120px">
      <div v-if="totalSalary >= 0" class="salaryPositive centered">{{ totalSalary.formatMoney() }}</div>
      <div v-else class="salaryNegative centered">{{ totalSalary.formatMoney() }}</div>
    </td>
    <td style="width: 100px">
      <div class="projection centered">{{ totalProjection }}</div>
    </td>
    <td style="width: 90px;">
      <div class="rightAligned">
        <button class="ob-btn" @click.stop="submitLineup(lineup.players)">Import</button>
      </div>
    </td>
  </tr>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  props: {
    lineup: {type: Object, default: null},
    playerMap: {type: Object, default: null},
    contest: {type: Object, default: null},
    currentContest: {type: Object, default: null},
  },

  computed: {
    salaryCap() {
      if (!this.currentContest || !this.currentContest.salaryCap) {
        return 0;
      }
      return this.currentContest.salaryCap;
    },

    contestId() {
      if (!this.contest || !this.contest.id) {
        return 0;
      }
      return this.contest.id;
    },

    sport() {
      if (!this.contest || !this.contest.sport) {
        return '';
      }
      return this.contest.sport;
    },

    sortedPlayers() {
      if (!this.contest || !this.contest.draftRoster) {
        return [];
      }

      const roster = this.contest.draftRoster.roster;
      const rosterSortOrder = {};
      let sortedPlayers = [];

      for (const posIndex in roster) {
        const pos = roster[posIndex];
        this.$set(rosterSortOrder, pos.short, posIndex);
      }

      sortedPlayers = this.lineup.players.slice().sort((p1, p2) => {
        if (rosterSortOrder[p1.position] > rosterSortOrder[p2.position]) {
          return 1;
        }
        if (rosterSortOrder[p1.position] < rosterSortOrder[p2.position]) {
          return -1;
        }

        return 0;
      });

      return sortedPlayers;
    },

    totalSalary() {
      let remainingSalary = this.salaryCap;

      for (const player of this.lineup.players) {
        const id = player.id;
        if (this.playerMap[id] && !this.invalidPlayers[id]) {
          remainingSalary -= this.playerMap[id].salary;
        }
      }

      return remainingSalary;
    },

    totalProjection() {
      if (!this.contest || !this.contest.draftRoster) {
        return '0';
      }

      const roster = this.contest.draftRoster.roster;

      let tProj = 0;

      for (const posInfo of roster) {
        let projList = [];
        let totPlayerGames = 0;
        for (const player of this.teamPicks[posInfo.short]) {
          for (const game of player.games) {
            if (game.proj != null) {
              projList.push(game.proj);
              totPlayerGames++;
            }
            if (game.projDH != null) {
              projList.push(game.projDH);
              totPlayerGames++;
            }
          }
        }

        projList = projList.sort((proj1, proj2) => proj2 - proj1);
        const maxGames = posInfo.maxGames < totPlayerGames ? posInfo.maxGames : totPlayerGames;
        for (let index = 0; index < maxGames; index++) {
          tProj += projList[index];
        }
      }

      return tProj.toFixed(0);
    },

    teamPicks() {
      if (!this.contest || !this.contest.draftRoster || !this.lineup || !this.sortedPlayers) {
        return {};
      }

      const roster = this.contest.draftRoster.roster;
      const rosterMap = {};
      const picksInfo = {};

      for (const pos of roster) {
        this.$set(picksInfo, pos.short, []);
        this.$set(rosterMap, pos.short, pos);
      }

      for (const p of this.sortedPlayers) {
        const contestPlayer = this.playerMap[p.id];

        if (contestPlayer && rosterMap[contestPlayer.draftPos] &&
          rosterMap[contestPlayer.draftPos].num > picksInfo[contestPlayer.draftPos].length &&
          this.playerPlaying(p.id)
        ) {
          picksInfo[contestPlayer.draftPos].push(contestPlayer);
        } else if (contestPlayer && this.contest.isFlexContest && this.playerPlaying(p.id)) {
          if (rosterMap['F'] && this.$SportInfo.draftPosIsFlex(contestPlayer.draftPos, this.contest.sport) &&
              rosterMap['F'].num > picksInfo['F'].length
          ) {
            picksInfo['F'].push(contestPlayer);
          } else if (rosterMap['SF'] && this.$SportInfo.draftPosIsSuperFlex(contestPlayer.draftPos, this.contest.sport) &&
              rosterMap['SF'].num > picksInfo['SF'].length
          ) {
            picksInfo['SF'].push(contestPlayer);
          }
        }
      }

      return picksInfo;
    },

    invalidPlayers() {
      const invalidPlayers = {};
      const players = this.sortedPlayers;
      const positions = this.getPositions();

      for (const player of players) {
        const id = player.id;
        const playerInfo = this.playerMap[id];

        // If a player does not have any information in the current contest's roster, they are invalid
        if (!playerInfo) {
          this.$set(invalidPlayers, id, true);
          continue;
        }

        const salary = playerInfo.salary;
        const position = playerInfo.draftPos;

        // If a player has no salary they are not playing, but this does not mean that they are invalid, so they are just skipped over
        if (salary === 0) {
          continue;
        }

        // If there is an available slot for the player's position, the player is valid and the # of slots is decremented
        if (positions[position] > 0) {
          positions[position] -= 1;
          continue;
        }

        // Extra cases for NFL (Flex and SuperFlex)
        if (this.contest.isFlexContest) {
          // If a player's position is a Flex position and there is a Flex slot available, the player is valid and the # of Flex slots is decremented
          if (this.$SportInfo.draftPosIsGuard(position, this.contest.sport)) {
            if (positions[this.$SportInfo.guard()] !== 0) {
              positions[this.$SportInfo.guard()] -= 1;
              continue;
            }
          }
          
          if (this.$SportInfo.draftPosIsForward(position, this.contest.sport)) {
            if (positions[this.$SportInfo.forward()] !== 0) {
              positions[this.$SportInfo.forward()] -= 1;
              continue;
            }
          }

          if (this.$SportInfo.draftPosIsUtility(position, this.contest.sport)) {
            if (positions[this.$SportInfo.utility()] !== 0) {
              positions[this.$SportInfo.utility()] -= 1;
              continue;
            } else if (positions[this.$SportInfo.utilityMultiplier()] !== 0) {
              positions [this.$SportInfo.utilityMultiplier()] -= 1;
              continue;
            }
          }
          
          if (this.$SportInfo.draftPosIsFlex(position, this.contest.sport)) {
            if (positions['F'] !== 0) {
              positions['F'] -= 1;
              continue;
            }
          }

          // Same logic as Flex but for SuperFlex
          if (this.$SportInfo.draftPosIsSuperFlex(position, this.contest.sport)) {
            if (positions['SF'] !== 0) {
              positions['SF'] -= 1;
              continue;
            } else if (positions['SF (1.5x)'] !== 0) {
              positions ['SF (1.5x)'] -= 1;
              continue;
            }
          }
        }

        // If none of the cases trigger, this means that there aren't any slots availlable for the player, and so they are invalid
        this.$set(invalidPlayers, id, true);
      }

      return invalidPlayers;
    },

  },

  methods: {

    playerPlaying(id) {
      return !this.invalidPlayers[id] && this.playerMap[id].salary !== 0;
    },

    openPlayerCard(playerId, sport, contestId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId.toString(), sport, contestId, true);
    },

    submitLineup(players) {
      this.sortLineup(players);
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER');
      for (const player of players) {
        const id = player.id;
        if (this.playerPlaying(id)) {
          EventBus.$emit('SALARY_CAP_PICK_PLAYER', this.playerMap[id]);
        }
      }
      EventBus.$emit('CLOSE_SALARY_CAP_IMPORT_MODAL');
    },

    getPositions() {
      const tempFullPositions = {};
      this.contest.draftRoster.roster.forEach((pos) => {
        tempFullPositions[pos.short] = pos.num;
      });
      return tempFullPositions;
    },

    getPlayerName(player) {
      const contestPlayer = this.playerMap[player.id];
      if (!contestPlayer) {
        return player.firstName.charAt(0) + '. ' + player.lastName;
      }

      return contestPlayer.name.shortName();
    },

    sortLineup(players) {
      const sortOrder = [];

      this.contest.draftRoster.roster.forEach((pos) => {
        sortOrder.push(pos.short);
      });

      players.sort((a, b) => {
        return sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

td {
  height: 55px;
  white-space: nowrap;
}
th, td {
  padding: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 40px;
  text-align: left;
}
tr:not(:nth-child(1)) {
  border-top: 1px solid var(--obcolor-background-2);
}
.lineupNum {
  font-weight: bold;
  font-size: 14px;

  margin-top: 3px;
  margin-left: 8px;

  color: var(--obcolor-text-primary);

  opacity: 0.8;
}
.player {
  font-weight: bold;
  font-size: 14px;

  margin-right: 7px;
}
.playerActive {
  color: var(--obcolor-text-primary);
}
.playerActive:hover {
  text-decoration: underline;
  text-decoration-color: var(--obcolor-text-primary);
  cursor: pointer;
}
.playerInactive {
  color: #E5252C;
}
.playerInactive:hover {
  text-decoration: underline;
  text-decoration-color: #E5252C;
  cursor: pointer;
}
.projection {
  font-weight: bold;
  font-size: 14px;

  color: var(--obcolor-text-primary);
}
.salaryPositive {
  font-weight: bold;
  font-size: 14px;

  color: #38C567;
}
.salaryNegative {
  font-weight: bold;
  font-size: 14px;

  color: #E5252C;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightAligned {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
