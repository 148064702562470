<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 820px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 15px; background: var(--obcolor-background-3);"
    :hideCloseButton="false"
  >
    <div slot="header" class="header">
      <div>Test Contest Results</div>
    </div>

    <div v-if="lineupInfo" slot="body" class="body">
      <table style="width: 100%;" aria-label="Lightning Results">
        <tr>
          <th>Lineup</th>
          <th>Player 1</th>
          <th>Player 2</th>
          <th>Player 3</th>
          <th>Score</th>
        </tr>
        <template v-for="(lineup, index) in lineupInfo.lineups">
          <tr v-if="index < numLineups" :key="index" :class="index == selectedIndex ? 'selectedTeam' : ''">
            <td>Lineup {{ index + 1 }}</td>
            <td v-for="player in lineup.players" :key="player.id">{{ player.name.shortName() }}</td>
            <td :class="winningIndex == index ? 'winningScore' : ''">{{ lineup.fpts }}</td>
          </tr>
        </template>
      </table>
      <div v-if="winningIndex == selectedIndex" class="winText">You Win!</div>
      <div v-else class="lossText">You Lose</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      lineupInfo: null,
      selectedIndex: null,
      numLineups: null,
    };
  },

  created() {
    EventBus.$on('LL_SHOW_TEST_RESULTS', this.openModal);
  },

  destroyed() {
    EventBus.$off('LL_SHOW_TEST_RESULTS', this.openModal);
  },

  computed: {
    winningIndex() {
      let highScore = null;
      let highIndex = null;
      for (let i=0; i<this.numLineups; i++) {
        const lineup = this.lineupInfo.lineups[i];
        if (highScore == null || lineup.fpts > highScore) {
          highScore = lineup.fpts;
          highIndex = i;
        }
      }
      return highIndex;
    },
  },

  methods: {
    openModal(lineupInfo, selectedIndex, numLineups) {
      this.lineupInfo = lineupInfo;
      this.selectedIndex = selectedIndex;
      this.numLineups = numLineups;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--obcolor-background-5);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}

table {
  font-size: 14px;
  th {
    background: var(--obcolor-background-5);
  }
  td, th {
    text-align: center;
    border: 1px solid var(--obcolor-background-2);
    padding: 6px;
  }
}

.selectedTeam {
  background: var(--obcolor-background-1);
}

.winningScore {
  color: var(--obcolor-NBA);
  font-weight: bold;
}

.winText {
  font-weight: bold;
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
  color: var(--obcolor-green);
}
.lossText {
  font-weight: bold;
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
  color: var(--obcolor-red);
}
</style>