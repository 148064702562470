<template>
  <tr class="playerRow" @click="openPlayerCard()">
    <td class="posField">{{ entry.draftPos }}</td>
    <td class="iconField">
      <PlayerHeadshot :size="'44'" :id="entry.id" :sport="sport"
        :team="entry.player.team" :showLogo="true"
      />
    </td>
    <td class="nameField">
      <div style="display: flex; padding-top: 4px;">
        <PlayerStatus :status="entry.player.status" style="margin-top: 1px;" />
        <div style="margin-left: 5px;">
          <div>{{ entry.player.name.shortName() }} {{ entry.player.nameTag }}</div>
        </div>
        <img v-if="isDailyMLB() && isCurrentBatter()"
          class="battingIcon"
          :src="require('@/assets/icons/baseball-bat-orange.png')" alt="Baseball Bat"
        >
        <img v-if="isDailyMLB() && isCurrentPitcher()"
          class="battingIcon"
          :src="require('@/assets/icons/baseball-ball-orange.png')" alt="Baseball Ball"
        >
      </div>
      <div v-if="entry.dropped" class="playerInfo" style="color: red;">Dropped</div>
      <div v-else-if="getTodayOrFspGame() == null && getTodayOrFspGame(true) == null" class="playerInfo">
        <span>#{{ entry.player.number }}</span>
        <span>{{ entry.player.position }}</span>
        <span>{{ entry.player.lineInfo }}</span>
      </div>
      <LiveGameSummary v-if="getTodayOrFspGame() != null" :fieldState="fieldState" :player="entry"
        :contest="league" :game="getTodayOrFspGame()" class="playerInfo"
      />
      <LiveGameSummary v-if="getTodayOrFspGame(true) != null" :fieldState="fieldState" :player="entry"
        :contest="league" :game="getTodayOrFspGame(true)" class="playerInfo"
      />
      <MlbBattingOrder v-if="isDailyMLB() && !isPitcher()" class="playerInfo"
        :fieldState="fieldState"
      />
    </td>

    <!-- Daily Only -->
    <template v-if="league.format == 'DAILY'">
      <td v-if="sport === 'MLB' && fieldState.inning != null" class="liveField">
        <div class="liveGameContainer">
          <div class="liveGame">
            <MlbOnBase :fieldState="fieldState" />
            <div>{{ fieldState.count }}</div>
            <div>{{ fieldState.inning }}</div>
          </div>
        </div>
      </td>
      <td v-else class="costField">
        <div class="moneyText" style="font-size: 14px;">{{ entry.salaryPG.formatMoney() }}</div>
        <div class="projText">Salary</div>
      </td>

      <td class="pointsField" style="width: 75px;">
        <SalaryPlayerPoints :entry="entry" :gameIndex="gameIndex" :todaysGames="todaysGames"
          :league="league" :rosterCalc="rosterCalc"
        />
      </td>

      <td class="gameTimeField">
        <div class="gameContainer" :class="{ selectableGame: getFspGame(), inactiveGame: getGameState().active != true }"
          @click.stop="openGameModal(getFspGame())"
        >
          <div style="width: 100%;">
            <GameStatusText :game="todaysGame()" :fspGame="getFspGame()"
              :position="entry.draftPos" :player="entry"
              :teamId="entry.player.teamId"
              :sport="sport"
            />
            <div v-if="showCurrentPitcher" class="curPitcher">
              vs: {{ fieldState.pitcher.name.shortName() }}
            </div>
            <GameProgressBar v-if="getFspGame() != null"
              :game="todaysGame()" :fspGame="getFspGame()" :sport="sport"
              style="margin-top: 6px; margin-bottom: 2px;" :dayHasDoubleHeader="false"
              :disabled="getGameState().active != true"
            />
          </div>
        </div>
      </td>

      <!-- Dropdown Button, Swap Button, or empty cell for dropped players -->
      <td v-if="canSwapDaily() && !contestIsOver()" style="width: 40px;">
        <div class="minusCircleContainer" @click.stop="swapPlayer()">
          <i class="fal fa-minus-circle minusCircle" style="font-size: 18px;"></i>
        </div>
      </td>

      <td v-else-if="dailyGameHasStarted() || contestIsOver()" style="width: 40px;">
        <div class="dropdownArrow" @click.stop="toggleDropdown()">
          <i v-if="!isDropdownOpen" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </div>
      </td>

      <td v-else-if="entry.dropped"></td>
    </template>

    <!-- Week Overview -->
    <template v-else-if="gameIndex == -1">
      <td class="pointsField">
        <div class="obText" style="color: var(--obcolor-font-primary);">{{ calcGP() }} / {{ entry.projGP }}</div>
        <div class="projText">GP / PG</div>
      </td>

      <td class="costField">
        <div class="moneyText" style="font-size: 14px;">{{ entry.salaryPG.formatMoney() }}</div>
        <div class="projText" style="color: var(--obcolor-font-primary)">{{ entry.salaryPG.formatMoney() }} PG</div>
      </td>

      <td class="pointsField" style="padding-left: 10px;">
        <div v-if="!getTotalFpts()" class="obText" style="color: var(--obcolor-font-primary);">0</div>
        <div v-else class="obText" :class="{negativeText: getTotalFpts() < 0}">{{ formatPts(getTotalFpts()) }}</div>
        <div class="projText">Proj {{ getTotalProj() }}</div>
      </td>

      <td class="pointsField" style="width: 80px;">
        <div class="obText" style="color: var(--obcolor-font-primary);">{{ calcCostPerPoint().formatMoney() }}</div>
        <div class="projText">$ per FPTS</div>
      </td>

      <td v-if="contestIsOver()" class="pointsField">
        <div class="obText" style="color: var(--obcolor-font-primary);">{{ entry.pctTaken }}</div>
        <div class="projText">% Drafted</div>
      </td>
    </template>

    <!-- Selected Day -->
    <template v-else>
      <td class="costField">
        <div :class="{ inactiveGame: getGameState().active != true, overSalary: gameOverSalaryCap() }">
          <div class="moneyText" style="font-size: 14px;">{{ gameZeroSalary() ? '$0' : entry.salaryPG.formatMoney() }}</div>
          <div class="projText" style="color: var(--obcolor-font-primary)">{{ entry.salaryPG.formatMoney() }} PG</div>
        </div>
        <div v-if="hasDoubleHeader()" :class="{ inactiveGame: getGameState(true).active != true, overSalary: gameOverSalaryCap(true) }"
          style="margin-top: 32px; margin-bottom: -3px;"
        >
          <div class="moneyText" style="font-size: 14px;">{{ gameZeroSalary(true) ? '$0' : entry.salaryPG.formatMoney() }}</div>
          <div class="projText" style="color: var(--obcolor-font-primary)">{{ entry.salaryPG.formatMoney() }} PG</div>
        </div>
      </td>

      <td class="pointsField" style="padding-left: 13px; padding-right: 0;">
        <div class="pointsContainer" :class="{ inactiveGame: getGameState().active != true, overSalary: gameOverSalaryCap() }"
          v-tooltip.bottom="{visible: getGameState().tooltip != null, content: getGameState().tooltip, class:'ObTooltip'}"
        >
          <div v-if="!hasFpts()" :class="getPointsClass()" class="obText">0</div>
          <div v-else class="obText" :class="getPointsClass()">{{ formatPts(getPlayerFpts()) }}</div>
          <div v-if="getGameState().label != null" class="projText">{{ getGameState().label }}</div>
          <div v-else class="projText">Proj {{ getPlayerProj() }}</div>
          <i v-if="gameOverSalaryCap()" class="fas fa-usd-circle moneyCircle"></i>
        </div>
        <!-- Double Header Points -->
        <div class="pointsContainer" v-if="hasDoubleHeader()" :class="{ inactiveGame: getGameState(true).active != true, overSalary: gameOverSalaryCap(true) }"
          style="margin-top: 32px; margin-bottom: -3px;"
          v-tooltip.bottom="{visible: getGameState(true).tooltip != null, content: getGameState(true).tooltip, class:'ObTooltip'}"
        >
          <div v-if="!hasFpts(true)" :class="getPointsClass(true)" class="obText">0</div>
          <div v-else class="obText" :class="getPointsClass(true)">{{ formatPts(getPlayerFpts(true)) }}</div>
          <div v-if="getGameState(true).label != null" class="projText">{{ getGameState(true).label }}</div>
          <div v-else class="projText">Proj {{ getPlayerProj(true) }}</div>
          <i v-if="gameOverSalaryCap()" class="fas fa-usd-circle moneyCircle"></i>
        </div>
      </td>

      <td class="gameTimeField">
        <div class="gameContainer" :class="{ selectableGame: getFspGame(), inactiveGame: getGameState().active != true }"
          @click.stop="openGameModal(getFspGame())"
        >
          <div style="width: 100%;">
            <GameStatusText :game="todaysGame()" :fspGame="getFspGame()"
              :position="entry.draftPos" :player="entry"
              :teamId="entry.player.teamId"
              :sport="sport"
            />
            <GameProgressBar v-if="getFspGame() != null"
              :game="todaysGame()" :fspGame="getFspGame()" :sport="sport"
              style="margin-top: 6px; margin-bottom: 2px;" :dayHasDoubleHeader="hasDoubleHeader()"
              :disabled="getGameState().active != true"
            />
          </div>
        </div>
        <!-- Double Header Game -->
        <div v-if="hasDoubleHeader()" class="gameContainer" style="margin-top: 4px;"
          :class="{ selectableGame: getFspGame(true), inactiveGame: getGameState(true).active != true }"
          @click.stop="openGameModal(getFspGame(true))"
        >
          <div style="width: 100%;">
            <GameStatusText :game="todaysGame(true)" :fspGame="getFspGame(true)"
              :position="entry.draftPos" :player="entry"
              :teamId="entry.player.teamId"
              :sport="sport"
            />
            <GameProgressBar v-if="getFspGame(true) != null"
              :game="todaysGame(true)" :fspGame="getFspGame(true)" :sport="sport"
              style="margin-top: 6px; margin-bottom: 2px;" :dayHasDoubleHeader="hasDoubleHeader()"
              :disabled="getGameState(true).active != true"
            />
          </div>
        </div>
      </td>
      <td v-if="!gameHasStarted(false)" style="width: 40px;">
        <div class="minusCircleContainer" @click.stop="swapPlayer()">
          <i class="fal fa-minus-circle minusCircle" style="font-size: 18px;"></i>
        </div>
      </td>
      <td v-else-if="!entry.dropped" style="width: 40px;">
        <div class="dropdownArrow" @click.stop="toggleDropdown()">
          <i v-if="!isDropdownOpen" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </div>
      </td>
      <td v-else style="width: 40px;"></td>
    </template>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import GameStatusText from '@/components/GameStatusText.vue';
import MlbOnBase from '@/components/charts/MlbOnBase.vue';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder.vue';
import GameProgressBar from '@/views/SalaryContest/Leaderboard/GameProgressBar.vue';
import SalaryPlayerPoints from '@/views/SalaryContest/Leaderboard/SalaryPlayerPoints.vue';
import LiveGameSummary from '@/components/live/LiveGameSummary.vue';
import EventBus from '@/event-bus';

export default {

  components: {
    PlayerHeadshot,
    GameStatusText,
    GameProgressBar,
    PlayerStatus,
    SalaryPlayerPoints,
    LiveGameSummary,
    MlbOnBase,
    MlbBattingOrder,
  },

  props: {
    entry: {type: Object, default: null},
    sport: {type: String, default: null},
    gameIndex: {type: Number, default: null},
    todaysGames: {type: Object, default: null},
    leagueId: {type: String, default: null},
    league: {type: Object, default: null},
    rosterCalc: {type: Object, default: null},
    teamId: {type: String, default: null},
    isDropdownOpen: {type: Boolean, default: false},
  },

  computed: {
    fieldState() {
      const fieldState = this.$SportInfo.getFieldState(this.league.sport, this.entry, this.getFspGame(), this.getTodayOrFspGame());
      return fieldState;
    },

    showCurrentPitcher() {
      return this.league.format == 'DAILY' && this.league.sport == 'MLB' && this.fieldState && this.fieldState.pitcher && this.fieldState.pitcher.name;
    },
  },

  methods: {
    openPlayerCard() {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.entry.id, this.sport, this.leagueId, true);
    },

    openGameModal(game) {
      const setTeam = game.home === 'true' ? 'home' : 'away';
      EventBus.$emit('GAME_MODAL_OPEN', {game: game, sport: this.sport, setTeam: setTeam});
    },

    toggleDropdown() {
      EventBus.$emit('SALARY_LIVE_TOGGLE_DROPDOWN', this.entry.id);
    },

    swapPlayer() {
      EventBus.$emit('SALARY_CONTEST_NAVIGATE_TO_SWAP', this.entry.id);
    },

    formatPts(pts) {
      return pts > 0 ? '+' + pts : pts;
    },

    canSwapDaily() {
      if (this.entry.dropped) {
        return false;
      }
      if (this.isPreContest()) {
        return false;
      }
      return this.entry.canSwap;
    },

    contestIsOver() {
      if (!this.league) {
        return false;
      }
      return this.league.state === 'COMPLETED' || this.league.state === 'FINALIZED';
    },

    isPreContest() {
      if (!this.league) {
        return false;
      }
      if (this.league.contestStart < new Date().getTime()) {
        return false;
      }
      return this.league.state === 'FILLING' || this.league.state === 'FILLED';
    },

    calcCostPerPoint() {
      const spent = this.calcSpent();
      const fpts = this.getTotalFpts();
      if (!fpts || fpts <= 0) {
        return 0;
      }
      return Number((spent / fpts).toFixed(0));
    },

    calcSpent() {
      const gp = this.calcGP();
      return this.entry.salaryPG * gp;
    },

    calcGP() {
      if (!this.entry) {
        return 0;
      }
      const games = this.entry.fspGames;
      const deactGames = this.entry.deactGames;
      let gp = 0;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const isActive = !deactGames[gJSON.id];
        if (isActive && gJSON.inRange && gJSON.played) {
          gp++;
        }
      }
      return gp;
    },

    gameOverSalaryCap(isDH) {
      const game = this.getFspGame(isDH);
      if (game == null || this.entry == null ||
        !this.rosterCalc || !this.rosterCalc.overSalaryGames
      ) {
        return false;
      }

      const gameKey = this.entry.id + '_' + game.id;
      return this.rosterCalc.overSalaryGames[gameKey];
    },

    gameZeroSalary(isDH) {
      const state = this.getGameState(isDH);
      return !state.active && state.state != 'NoSalary';
    },

    showDNP(isDH) {
      const game = this.getFspGame(isDH);
      return this.gameHourAfterStart(isDH) && !game.played;
    },

    gameHourAfterStart(isDH) {
      const game = this.getFspGame(isDH);
      const now = new Date().getTime();
      const gTime = parseInt(game.timestamp);
      return now > (gTime + (3600000 * 6));
    },

    getGameState(isDH) {
      const player = this.entry;
      const game = this.getFspGame(isDH);

      if (!game || !player || !this.league) {
        return {state: 'NoGame', label: null, tooltip: null, active: false};
      }

      if (!this.league.contestStart || this.league.contestStart > game.timestamp) {
        // ContestNotStarted - Contest start ts greater than game ts
        return {state: 'ContestNotStarted', label: null, tooltip: 'Game not included in contest slate', active: false};
      } else if (!game.inRange) {
        // NOR - Got put on your team mid-game
        return {state: 'NOR', label: 'NOR', tooltip: 'Was not on your team at game start', active: false};
      } else if (this.showDNP(isDH)) {
        // DNP - Not a starter in the game (game finished)
        return {state: 'DNP', label: 'DNP', tooltip: 'Did not play', active: false};
      } else if (game.partial) {
        // DNS - Not a starter in the game (game running)
        return {state: 'DNS', label: 'DNS', tooltip: 'Did not start game', active: false};
      } else if (player.deactGames[game.id] != null) {
        // Benched - was benched when game started
        return {state: 'Benched', label: 'Benched', tooltip: null, active: false};
      } else if (this.gameOverSalaryCap(isDH)) {
        // NoSalary - Game was not allocated salary
        return {state: 'NoSalary', label: null, tooltip: 'Not enough salary', active: false};
      } else if (game.ProjPoints && game.ProjPoints.total == 0) {
        // OffDay - Not a starter in the game (and other conditions not met)
        return {state: 'OffDay', label: null, tooltip: null, active: false};
      }

      return {state: 'Active', label: null, tooltip: null, active: true};
    },

    isGameHome(player, game) {
      const teamId = player.player.teamId;
      if (!teamId) {
        return null;
      }
      if (game && game.home_team && game.home_team.team_id == teamId) {
        return true;
      }
      if (game && game.visitor_team && game.visitor_team.team_id == teamId) {
        return false;
      }
      if (game && game.home != null) {
        return game.home == 'true';
      }
      return null;
    },

    gameIsLive(todayInfo) {
      const liveJSON = todayInfo && todayInfo.live ? todayInfo.live : null;
      return liveJSON && (liveJSON.status === 'mid-event');
    },

    dailyGameCounted() {
      const game = this._getFspGameDaily(false);
      const gameKey = this.entry.id + '_' + game.id;
      return this.rosterCalc.playedGames[gameKey];
    },

    getTotalFpts() {
      if (!this.entry || !this.entry.fspGames) {
        return null;
      }
      return this.sumPlayerPoints();
    },

    getTotalProj() {
      if (!this.entry || !this.entry.fspGames) {
        return null;
      }
      return this.sumPlayerProj();
    },

    sumPlayerPoints() {
      const games = this.entry.fspGames;
      let totalPoints = new Decimal(0);
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = this.entry.id + '_' + gJSON.id;
        if (this.rosterCalc.playedGames[gameKey]) {
          totalPoints = totalPoints.plus(new Decimal(gJSON.ObPoints.total));
        }
      }
      return Number(totalPoints);
    },

    sumPlayerProj() {
      const games = this.entry.fspGames;
      let totalPoints = 0;
      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        if (gJSON.inRange && gJSON.ProjPoints != null) {
          totalPoints += gJSON.ProjPoints.total;
        }
      }
      return totalPoints.toFixed(0);
    },

    hasFpts(dh) {
      const game = this.getFspGame(dh);
      return game != null && game.ObPoints != null && game.ObPoints.total != null;
    },

    gameIsPartial(dh) {
      const game = this.getFspGame(dh);
      return game != null ? game.partial : false;
    },

    getPointsClass(dh) {
      if (this.gameIsPartial(dh) && this.league.format == 'WEEKLY') {
        return 'greyPoints';
      }
      if (this.getPlayerFpts(dh) < 0) {
        return 'redPoints';
      }
      if (!this.getGameState(dh).active && this.getGameState(dh).state != 'NoSalary') {
        return 'greyPoints';
      }
      if (!this.hasFpts(dh)) {
        return 'whitePoints';
      }
      return '';
    },

    getPlayerFpts(dh) {
      const game = this.getFspGame(dh);
      if (game != null && game.ObPoints != null && game.ObPoints.total != null) {
        return game.ObPoints.total;
      }
      return 0;
    },

    getPlayerProj(dh) {
      const game = this.getFspGame(dh);
      if (game != null && game.ProjPoints != null && game.ProjPoints.total != null) {
        return game.ProjPoints.total;
      }
      return 0;
    },

    hasDoubleHeader() {
      if (this.entry.fspGames == null) {
        return false;
      }
      return this.entry.fspGames[this.gameIndex + 'DH'];
    },

    getTodayOrFspGame(dh) {
      if (this.todaysGame(dh)) {
        return this.todaysGame(dh);
      }
      return this.getFspGame(dh);
    },

    getFspGame(dh) {
      if (this.league.format == 'DAILY') {
        return this._getFspGameDaily(dh);
      }
      if (this.entry.fspGames == null) {
        return null;
      }
      if (dh === true) {
        return this.entry.fspGames[this.gameIndex + 'DH'];
      }
      return this.entry.fspGames[this.gameIndex];
    },

    todaysGame(dh) {
      if (this.league.format == 'DAILY') {
        return this._todaysGameDaily(dh);
      }
      const gameJSON = this.getFspGame(dh);
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    // Daily Version of above, not to be used directly
    _getFspGameDaily(dh) {
      if (dh === true) {
        return null;
      }
      let firstGame = null;
      for (const gameIndex in this.entry.fspGames) {
        const game = this.entry.fspGames[gameIndex];
        if (game.id && game.inRange) {
          firstGame = game;
          break;
        }
      }
      return firstGame;
    },

    // Daily Version of above, not to be used directly
    _todaysGameDaily(dh) {
      if (dh === true) {
        return null;
      }
      const gameJSON = this._getFspGameDaily();
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    gameHasStarted(dh) {
      const game = this.getFspGame(dh);
      if (game && game.live) {
        return true;
      }
      if (game && game.timestamp) {
        return parseInt(game.timestamp) < new Date().getTime();
      }
      return false;
    },

    dailyGameHasStarted() {
      const game = this._getFspGameDaily(false);
      if (game && game.timestamp) {
        return parseInt(game.timestamp) < new Date().getTime();
      }
      return false;
    },

    findDailySummaryFspGame() {
      const date = this.$moment().format('YYYY-MM-DD');
      for (const gameIndex in this.entry.fspGames) {
        const game = this.entry.fspGames[gameIndex];
        if (this.$moment(parseInt(game.timestamp)).format('YYYY-MM-DD') == date) {
          return game;
        }
      }

      return null;
    },

    findDailySummaryGame() {
      const fspGame = this.findDailySummaryFspGame();
      if (!fspGame) {
        return null;
      }
      return this.todaysGames[fspGame.id];
    },

    isPitcher() {
      return this.entry != null && this.entry.origPos == 'P';
    },

    isCurrentPitcher() {
      return this.fieldState && this.entry && this.fieldState.currentPitcher === this.entry.player.id;
    },

    isCurrentBatter() {
      return this.fieldState && this.entry && this.fieldState.currentBatter === this.entry.player.id;
    },

    isDailyMLB() {
      return this.league && this.league.format == 'DAILY' && this.league.sport == 'MLB';
    },
  },

};
</script>

<style lang="scss" scoped>
.overSalary {
  .moneyText, .obText, .projText {
    color: red !important;
  }

  .moneyCircle {
    color: red;
    margin-left: 4px;
    margin-top: 3px;
  }
}

.moneyText {
  color: var(--obcolor-green);
  font-weight: bold;
  margin: 3px 0;
}


.obText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;

  margin: 3px 0;

  &.whitePoints {
    color: var(--obcolor-font-primary);
  }

  &.greyPoints {
    color: var(--obcolor-font-secondary);
  }

  &.redPoints {
    color: red;
  }
}

.projText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  margin: 3px 0;
}

.negativeText {
  color: red;
}

th, td {
  text-align: center;
}
td {
  height: 60px;
  font-size: 12px;
}
tr {
  td {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
}
tr:last-child:not(:nth-child(10)) {
  td {
    border-bottom: none;
  }
}
tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

tr.playerRow {
  cursor: pointer;
}
tr.playerRow:hover {
  background: var(--obcolor-background-2);
}

.posField {
  width: 50px;
  font-weight: bold;
  font-size: 14px;
}
.iconField {
  width: 45px;
}
.nameField {
  text-align: left;
  padding-left: 10px;

  .battingIcon {
    height: 14px;
    margin-left: 3px;
    margin-top: -2px;
  }
}
.playerInfo {
  font-size: 12px;
  margin-top: 4px;
  width: 180px;

  color: var(--obcolor-font-secondary);
  text-align: left;
  margin-left: 15px;
  min-width: 0;
  span {
    margin-right: 3px;
    text-overflow: ellipsis;
  }
}

.curPitcher {
  margin-top: 1px;
  margin-bottom: -1px;
  color: var(--obcolor-font-secondary);
  font-size: 12px;
}

.liveField {
  width: 80px;
  font-size: 11px;
  color: var(--obcolor-font-secondary);
  white-space: nowrap;
}

.liveGameContainer {
  display: flex;
  justify-content: flex-end;
}

.liveGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  overflow:visible;
}

.costField {
  text-align: right;
  width: 80px;
}
.pointsField {
  text-align: right;
  width: 70px;
  padding-right: 13px;
}
.pointsContainer {
  text-align: right;
}
.gameTimeField {
  width: 205px;
  padding-right: 2px;
  padding-left: 5px;
}

.inactiveGame {
  opacity: 0.5;
}

.gameContainer {
  padding: 0 10px;
  height: 60px;
  display: flex;
  align-items: center;
}

.selectableGame:hover {
  background: var(--obcolor-background-2);
}

.minusCircleContainer {
  color: var(--obcolor-red);
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.minusCircleContainer:hover {
  background: var(--obcolor-background-2);
  color: white;
}

.dropdownArrow {
  color: var(--obcolor-text-secondary);
  font-size: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdownArrow:hover {
  color: var(--obcolor-text-primary);
  background: var(--obcolor-background-2);
}
</style>
