<template>
  <div class="dropdownContainer">
    <div class="ddBox" @click.stop="selectOpen = !selectOpen">
      <span v-if="label" style="font-weight: bold;">{{ label }}</span>
      <span class="selectedValue">{{ getSelectedText() }}</span>
      <i class="fas fa-chevron-down" style="margin-left: 2px;"></i>
    </div>
    <div v-show="selectOpen" class="selectContainer ob-scroll" v-click-outside="() => this.selectOpen = false">
      <div v-for="(option, index) in options" :key="'dd'+option" class="selectEntry"
        @click.stop="selectOption(option)"
      >
        <div class="statusCircle" :style="'background: ' + getStatusColor(option) + ';'"></div>
        <span v-if="text && text[index]" style="flex: 1; padding-left: 5px">{{ text[index] }}</span>
        <span v-else style="flex: 1;">{{ option }}</span>
        <i v-if="checkOption(value, options[index])" class="fas fa-check" style="color: #00AEEF;"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {

  directives: {
    ClickOutside,
  },

  props: {
    label: {type: String, default: 'Status:'},
    value: {type: String, default: 'inactive'},
    isChecked: {type: Boolean, default: true},
  },

  data() {
    return {
      selectOpen: false,
      options: ['active', 'questionable', 'inactive'],
      text: ['Active', 'Active, Available', 'All'], // Optional text labels for the options
    };
  },

  methods: {
    getSelectedText() {
      if (!this.text) {
        return this.value;
      }
      let index = 0;
      for (const opt of this.options) {
        if (opt === this.value && this.text[index]) {
          return this.text[index];
        }
        index++;
      }
      return this.value;
    },

    checkOption(selOption, option) {
      switch (selOption) {
        case 'active':
          return option === 'active';
        case 'questionable':
          return option === 'active' || option === 'questionable';
        case 'inactive':
          return true;
      }
      return false;
    },

    selectOption(opt) {
      // Emit to update v-model value
      this.$emit('input', opt);
      this.selectOpen = false;
    },

    getStatusColor(option) {
      switch (option) {
        case 'active': return '#4EB959';
        case 'questionable': return '#F2994A';
        case 'inactive': return '#E5252C';
      }
      return '#E5252C';
    },
  },

};
</script>

<style lang="scss" scoped>
.dropdownContainer {
  display: inline-block;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  // Text not selectable
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 160px;
  height: 25px;
}
.ddBox {
  border: 1px solid var(--obcolor-background-1);
  background: var(--obcolor-background-1);
  height: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  span {
    margin-right: 3px;
  }
}
.ddBox:hover {
  background: var(--obcolor-ob-blue);
}

.selectedValue {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectContainer {
  z-index: 70;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 38px;
  background: var(--obcolor-background-3);
  border: 1px solid var(--obcolor-background-1);
  white-space: nowrap;
  cursor: pointer;
  max-height: 400px;
  min-width: 158px;
  overflow: auto;
}

.selectEntry:hover {
  background: var(--obcolor-background-2);
}
.selectEntry {
  padding: 6px;
  background: var(--obcolor-background-3);
  flex: 1;
  display: flex;
  align-items: center;
}

.statusCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 5px;
}

</style>
