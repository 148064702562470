<template>
  <div v-if="showStartingPitchers">
    <h1 class="sectionHeader">Starting Pitchers</h1>
    <div class="startingPitchers">
      <!-- away starting pitcher -->
      <div class="startingPitcher">
        <PlayerHeadshot size="45" :id="awayStarter ? awayStarter.id : null" :sport="sport"
          :team="awayStarter ? awayStarter.team : null" :showLogo="true" :pos="awayStarter ? awayStarter.position : null"
        />
        <div v-if="awayStarter" class="startingPitcherStats">
          <div class="startingPitcherCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="awayStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(awayStarter.id)">{{ awayStarter.name.shortName() }}</div>
          </div>
          <p class="startingPitcherSubtext">{{ startingPitcherStats.away_starting_pitcher }}</p>
        </div>
        <div v-else class="startingPitcherStats">
          <div style="margin-top: 14px;" class="startingPitcherCentered">
            TBD
          </div>
        </div>
      </div>
      <!-- home starting pitcher -->
      <div class="startingPitcher">
        <div v-if="homeStarter" style="text-align: right;" class="startingPitcherStats">
          <div style="justify-content: right;" class="startingPitcherCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="homeStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(homeStarter.id)">{{ homeStarter.name.shortName() }}</div>
          </div>
          <p class="startingPitcherSubtext">{{ startingPitcherStats.home_starting_pitcher }}</p>
        </div>
        <div v-else class="startingPitcherStats">
          <div style="margin-top: 14px;" class="startingPitcherCentered">
            TBD
          </div>
        </div>
        <PlayerHeadshot size="45" :id="homeStarter ? homeStarter.id : null" :sport="sport"
          :team="homeStarter ? homeStarter.team : null" :showLogo="true" :pos="homeStarter ? homeStarter.position : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  data() {
    return {
      battingOrder: {
        awayteam: [],
        hometeam: [],
      },
    };
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    startingPitcherStats() {
      const statResults = {away_starting_pitcher: '', home_starting_pitcher: ''};
      statResults.away_starting_pitcher += this.awayStarter ? this.awayStartingPitcherStats() : '';
      statResults.home_starting_pitcher += this.homeStarter ? this.homeStartingPitcherStats() : '';
      return statResults;
    },

    showStartingPitchers() {
      const liveMatchupData = this.gameData.matchup.live;
      return (!liveMatchupData || (liveMatchupData && liveMatchupData.status === 'pre-event'));
    },

    awayStarter() {
      return this.gameData?.matchup?.info?.away_starter;
    },

    homeStarter() {
      return this.gameData?.matchup?.info?.home_starter;
    },

    awayPitcherStats() {
      return this.awayStarter.stats;
    },

    homePitcherStats() {
      return this.homeStarter.stats;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    awayStartingPitcherStats() {
      return this.getPitcherStats(this.awayPitcherStats);
    },

    homeStartingPitcherStats() {
      return this.getPitcherStats(this.homePitcherStats);
    },

    getPitcherStats(playerStats) {
      const statsResult = [];
      const scoringStats = this.$ScoringStats.getPregameScoringStats(this.sport, 'P');
      for (const stat of scoringStats[0].stats) {
        if (stat.label === 'W/L' && playerStats?.pitcher?.W && playerStats?.pitcher?.L) {
          statsResult.push(playerStats.pitcher.W + '-' + playerStats.pitcher.L);
        } else if (stat.label === 'W/L') {
          statsResult.push('0-0');
        } else if (this.$Helpers.parsePath(stat.label, playerStats) && this.$Helpers.parsePath(stat.label, playerStats) === '1') {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description.removePluralWords());
        } else if (this.$Helpers.parsePath(stat.label, playerStats) && (stat.label === 'WHIP' || stat.label === 'ERA' || parseFloat(this.$Helpers.parsePath(stat.label, playerStats)) !== 0)) {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description);
        }
      }
      return statsResult.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.startingPitchers {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.startingPitcher {
  display: flex;
}
.startingPitcherCentered {
  display: flex;
  align-items: center;
}
.startingPitcherStats {
  margin: 0 20px;
  max-width: 250px;
}
.startingPitcherSubtext {
  font-weight: initial;
  margin: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  max-width: 300px;
}
</style>