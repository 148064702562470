<template>
  <div class="entriesContainer">
    <table aria-label="Salary Entries">
      <router-link v-for="entry in filteredEntries" :key="entry.teamId" :class="isSelected(entry)"
        @mouseover="selectEntry(entry)" :style="getFlashStyle(entry)" :to="getTeamLink(entry.teamId)"
        class="tableRow"
      >
        <!-- Entry Number -->
        <td class="teamNum" style="background-clip: padding-box; position: relative;"
          v-tooltip.bottom="{content: 'Lineup Number', class:'ObTooltipHeat'}"
        >
          {{ entry.teamNum }}
          <div v-if="getCustomName(entry)" class="nameLabel">{{ getCustomName(entry) }}</div>
        </td>

        <!-- Show rank only if contest has started -->
        <td v-if="!isPreContest" :style="hasPayout(entry) ? '' : 'color: var(--obcolor-font-secondary);'"
          v-tooltip.bottom="{content: 'Rank', class:'ObTooltipHeat'}"
        >
          {{ String(entry.rank).addSuffix() }}
        </td>

        <!-- Projection if contest has not started -->
        <td v-if="isPreContest" style="color: var(--obcolor-font-secondary); width: 80px;">
          Proj {{ entry.proj }}
        </td>
        <!-- If started show score -->
        <td v-else :style="hasPayout(entry) ? '' : 'color: var(--obcolor-font-secondary);'"
          v-tooltip.bottom="{content: 'Score', class:'ObTooltipHeat'}"
        >
          {{ entry.score }}
        </td>

        <!-- Current Winnings -->
        <td class="winningsTd" :class="getWinningsClass(entry)" v-tooltip.bottom="{content: 'Winnings', class:'ObTooltipHeat'}">
          <span>{{ getPrizeText(entry) }}</span>
          <template v-if="entry.ticketPrize || entry.splitTicketPrize">
            <span style="margin-left: 2px;">+</span>
            <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket">
          </template>
        </td>

        <!-- Team Warnings -->
        <td v-if="!contestIsOver" class="warnTd" v-tooltip.bottom="{content: getWarningTooltip(entry), class:'ObTooltipHeat'}">
          <img v-if="getUnderGameOpps(entry) !== ''"
            class="warnIcon" :src="require('@/assets/salarycap/warn_overcap.png')" alt="Warning"
          >
          <img v-else-if="getSalaryWarning(entry) === 'over'"
            class="warnIcon" :src="require('@/assets/salarycap/warn_overcap.png')" alt="Warning"
          >
          <img v-else-if="getSalaryWarning(entry) === 'under'"
            class="warnIcon" :src="require('@/assets/salarycap/warn_extrasalary.png')" alt="Extra Salary"
          >
        </td>
      </router-link>

      <tr v-if="canEnterLineup">
        <router-link :to="getCreateLineupLink()" class="tableCreateLineup">
          <i class="fas fa-plus" style="margin-right: 5px;"></i>
          Create New Lineup
        </router-link>
      </tr>

      <!-- Insert empty rows as space fillers -->
      <tr v-for="n in emptyRows" :key="n">
        <td class="emptyRow" :class="{ noBorder: n !== emptyRows }" colspan="5"></td>
      </tr>

      <!-- Page navigation for entries -->
      <tr v-if="myEntries.length > 5">
        <td class="pageNavigationContainer" colspan="5">
          <div class="pageNavigation">
            <div class="arrowContainer arrowToLine" :class="{ disabledBtn: curPage == 0 }" @click="prevPage(true)">
              <i class="fa fa-arrow-to-left"></i>
            </div>
            <div class="arrowContainer" :class="{ disabledBtn: curPage == 0 }" @click="prevPage(false)">
              <i class="fa fa-arrow-left"></i>
            </div>
            <span class="pageText">Page {{ curPage + 1 }} of {{ lastPage + 1 }}</span>
            <div class="arrowContainer" :class="{ disabledBtn: curPage == lastPage }" @click="nextPage(false)">
              <i class="fa fa-arrow-right"></i>
            </div>
            <div class="arrowContainer arrowToLine" :class="{ disabledBtn: curPage == lastPage }" @click="nextPage(true)">
              <i class="fa fa-arrow-to-right" @click="nextPage(true)"></i>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    league: Object,
    myEntries: Array,
    canEnterLineup: Boolean,
    selectedEntry: Object,
    isPreContest: Boolean,
    teamData: Object,
  },

  data() {
    return {
      flashUpdate: {},
      curPage: 0,
      maxLineupsPerPage: 5,
    };
  },

  computed: {
    filteredEntries() {
      if (!this.myEntries) {
        return [];
      }

      // Paginate entries by current page
      const firstEntry = this.curPage * this.maxLineupsPerPage;
      const lastEntry = (this.curPage + 1) * this.maxLineupsPerPage;
      return this.myEntries.slice(firstEntry, lastEntry);
    },

    lastPage() {
      return Math.ceil(this.myEntries.length / this.maxLineupsPerPage) - 1;
    },

    emptyRows() {
      if (this.curPage != 0 && this.curPage == this.lastPage) {
        return this.maxLineupsPerPage - this.filteredEntries.length;
      }
      return 0;
    },

    contestIsOver() {
      if (!this.league) {
        return false;
      }
      return this.league.state === 'FINALIZED' || this.league.state === 'CANCELLED';
    },
  },

  watch: {
    myEntries(to, from) {
      this.flashChanges(to, from);
    },
  },

  methods: {
    getSalaryWarning(entry) {
      const key = this.league.id + '_' + entry.teamId;
      return this.teamData.salaryWarnings[key] || '';
    },

    getUnderGameOpps(entry) {
      const key = this.league.id + '_' + entry.teamId;
      return this.teamData.underLimits[key] || '';
    },

    getWarningTooltip(entry) {
      let warningText = '';
      const underGameOpps = this.getUnderGameOpps(entry);
      if (underGameOpps !== '') {
        warningText += 'Under Game Opps';
      }
      if (this.getSalaryWarning(entry) === 'over') {
        warningText += warningText !== '' ? ', ' : '';
        warningText += 'Over Salary Cap';
      }
      if (this.getSalaryWarning(entry) === 'under') {
        warningText += warningText !== '' ? ', ' : '';
        warningText += 'Extra Salary';
      }
      return warningText;
    },

    getCustomName(entry) {
      const key = this.league.id + '_' + entry.teamId;
      return this.teamData?.customNames?.[key];
    },

    getFlashStyle(entry) {
      const flash = this.flashUpdate[entry.teamId];
      if (!flash) {
        return '';
      }
      if (flash === 'up') {
        return 'background: green !important;';
      }
      if (flash === 'down') {
        return 'background: red !important;';
      }
      return '';
    },

    flashChanges(to, from) {
      // Detect score or rank changes
      const oldValues = {};
      for (const entry of from) {
        oldValues[entry.teamId] = {rank: entry.rank, score: entry.score};
      }

      for (const entry of to) {
        const oldValue = oldValues[entry.teamId];
        if (!oldValue) {
          continue;
        }
        if (oldValue.rank < entry.rank || oldValue.score > entry.score) {
          // Rank is worse (increased), or score went down
          this.flashEntry(entry.teamId, 'down');
        } else if (oldValue.rank > entry.rank || oldValue.score < entry.score) {
          // Rank is better (lower), or score went up
          this.flashEntry(entry.teamId, 'up');
        }
      }
    },

    flashEntry(id, type) {
      const self = this;
      this.$set(this.flashUpdate, id, type);
      setTimeout(() => {
        self.$set(self.flashUpdate, id, null);
      }, 800);
    },

    getCreateLineupLink() {
      return '/lineup-builder/' + this.league.id;
    },

    getTeamLink(teamId) {
      return '/salarycontest/leaderboard/' + this.league.id + '/' + teamId;
    },

    isSelected(entry) {
      if (this.selectedEntry && this.selectedEntry.teamId === entry.teamId) {
        return 'selectedEntry';
      }
      return '';
    },

    hasPayout(entry) {
      return entry.prizeValue && entry.prizeValue > 0;
    },

    getWinningsClass(entry) {
      if (!this.hasPayout(entry)) {
        return '';
      }
      return this.league.realMoney ? 'moneyText' : 'blueText';
    },

    getPrizeText(entry) {
      if (entry.prize) {
        return entry.prize.removeCentsIfZero();
      }
      return this.league.realMoney ? '$0' : '0';
    },

    selectEntry(entry) {
      this.$emit('selectEntry', entry);
    },

    prevPage(goToStart) {
      if (goToStart) {
        this.curPage = 0;
        return;
      }

      if (this.curPage > 0) {
        this.curPage--;
      }
    },

    nextPage(goToEnd) {
      if (goToEnd) {
        this.curPage = this.lastPage;
        return;
      }

      if (this.curPage < this.lastPage) {
        this.curPage++;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.entriesContainer {
  border-left: 1px solid var(--obcolor-background-page);
  position: absolute;
  right: 0;
  top: 0;
  background: var(--obcolor-background-5);
  width: 251px;
  height: 100%;
  z-index: 60;
  font-size: 12px;
  font-weight: bold;
}

table {
  width: 100%;
}
tr,
.tableRow {
  height: 26px;
  max-height: 26px;
  cursor: pointer;
  transition-duration: 600ms;
}
tr:hover,
.tableRow:hover {
  background: var(--obcolor-background-3);
  .nameLabel {
    opacity: 1;
  }
}
td,
.tableCreateLineup {
  padding: 0 10px;
  width: 30px;
  min-width: 30px;
  border-bottom: 1px solid var(--obcolor-background-page);
  text-align: center;;
  white-space: nowrap;
}

.teamNum {
  width: 11px;
  min-width: 11px;
  background: var(--obcolor-background-2);
  border-right: 1px solid var(--obcolor-background-page);
  text-align: center;
}

.selectedEntry {
  background: var(--obcolor-background-2) !important;
}

.winningsTd {
  color: var(--obcolor-font-secondary);
}

.warnTd {
  padding: 0;
  min-width: 25px;
}
.warnIcon {
  height: 15px;
}

.moneyText {
  color: var(--obcolor-green);
}
.blueText {
  color: var(--obcolor-ob-blue);
}

.nameLabel {
  position: absolute;
  right: 38px;
  top: 3px;
  background: var(--obcolor-ob-blue);
  color: white;
  padding: 3px;
  opacity: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  transition-duration: 300ms;
}

.pageNavigationContainer {
  padding: 0px 0px !important;
  background: var(--obcolor-background-5) !important;
}

.pageNavigation {
  display: flex;
  flex-direction: row;
  height: 26px;
  cursor: default;
}

.pageText {
  font-size: 12px;
  align-content: center;
  padding-top: 6px;
  flex: 1;
}

.arrowContainer {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5.8px;
  font-size: 14px;
  width: 26px;
  height: 20.2px;

  &:not(.disabledBtn):hover {
    background: var(--obcolor-background-3);
  }

  &.disabledBtn {
    cursor: not-allowed;
    color: var(--obcolor-font-secondary);
  }
}

.arrowToLine {
  padding-top: 3px !important;
  font-size: 18px !important;
  height: 23px !important;
}

.emptyRow {
  background: var(--obcolor-background-5) !important;
  cursor: default;
}

.noBorder {
  border-bottom: none;
}

.ticketIcon {
  height: 10px;
  margin-bottom: -1px;
  margin-left: 3px;
}

.tableRow {
  display: table-row;
  vertical-align: middle;
}

.tableCreateLineup {
  display: table-cell;
  vertical-align: inherit;
}
</style>
