<template>
  <div class="boxBody" v-if="battlePass">
    <template v-if="filteredChallengeCategories">
      <div v-if="title" class="title">
        <router-link to="/battlepass" class="titleText">
          <span>{{ title }}</span>
        </router-link>
      </div>
      <div class="buttonList">
        <div class="typeBtn" v-for="(category, index) in filteredChallengeCategories"
          :class="{selected: selectedCategory && category.name == selectedCategory.name}"
          :key="index" @click="selectCategory(category)"
        >
          {{ category.name }} {{ categoryProgressString(category) }}
        </div>
        <div class="timeRemainingBox" v-if="showTimeRemaining">
          <i class="watchIcon fal fa-stopwatch"></i>
          Resets in
          <CountdownTimer class="countdownText" :end="selectedCategory.resetDate" :min="0"
            :textClock="true" :clockFormat="true"
          />
        </div>
      </div>
      <div v-if="sortedSelectedChallenges && sortedSelectedChallenges.length !== 0" class="challengeList ob-scroll">
        <div v-for="(challenge, index) in sortedSelectedChallenges" :key="index"
          class="challengeEntryContainer"
        >
          <BattlePassProgressBar class="progressBarField"
            :challenge="challenge" progressBarWidth="width: 75%;" progressBarHeight="height: 8px;"
            fontSize="font-size: 14px;" :lightningLineup="lightningLineup" :dfs="dfs"
          />
        </div>
      </div>
      <div v-else class="challengeList noChallenges">
        No challenges available for the selected category.
      </div>
    </template>
  </div>
</template>

<script>
import CountdownTimer from '@/components/CountdownTimer';
import BattlePassProgressBar from '@/components/battlepass/BattlePassProgressBar.vue';
import {categoryProgressString} from '@/utils/shared/BattlePassUtils';

export default {
  props: {
    battlePass: {type: Object, default: null},
    lightningLineup: {type: Boolean, default: false},
    dfs: {type: Boolean, default: false},
    title: {type: String, default: null},
  },

  components: {
    CountdownTimer,
    BattlePassProgressBar,
  },

  data() {
    return {
      selectedCategoryName: null,
    };
  },

  created() {
    this.setInitialSelectedCategory();
  },

  watch: {
    challengeCategories(_newVal, oldVal) {
      if (oldVal.length == 0) {
        this.setInitialSelectedCategory();
      }
    },
  },

  computed: {
    selectedCategory() {
      return this.challengeCategoryMap[this.selectedCategoryName] || null;
    },

    showTimeRemaining() {
      return this.selectedCategory?.resetDate && !this.lightningLineup;
    },

    challengeCategoryMap() {
      const catMap = {};
      if (!this.filteredChallengeCategories) {
        return catMap;
      }
      for (const category of this.filteredChallengeCategories) {
        this.$set(catMap, category.name, category);
      }

      return catMap;
    },

    isFilteringCategories() {
      return this.lightningLineup || this.dfs;
    },

    challengeFilterKeyName() {
      return this.lightningLineup ? 'LIGHTNING_LINEUPS' : 'SalaryCap';
    },

    filteredChallengeCategories() {
      const filteredCategories = [];
      if (!this.isFilteringCategories) {
        return this.challengeCategories;
      }

      for (const category of this.challengeCategories) {
        if (!category.challenges) {
          filteredCategories.push({...category});
          continue;
        }

        let challengeList = category.challenges.slice();
        challengeList = challengeList.filter((c) => {
          return c.contestTypes[this.challengeFilterKeyName];
        });

        const newCategory = {...category};
        this.$set(newCategory, 'challenges', challengeList);
        filteredCategories.push(newCategory);
      }
      return filteredCategories;
    },

    sortedSelectedChallenges() {
      if (!this.selectedCategory?.challenges) {
        return null;
      }

      return this.selectedCategory?.challenges.slice().sort((c1, c2) => {
        if (c1.isCompleted && !c2.isCompleted) {
          return 1;
        } else if (!c1.isCompleted && c2.isCompleted) {
          return -1;
        }

        if (c1.expPoints < c2.expPoints) {
          return -1;
        } else if (c1.expPoints > c2.expPoints) {
          return 1;
        }

        return 0;
      });
    },

    challengeCategories() {
      if (!this.battlePass?.challengeCategories) {
        return [];
      }

      return this.battlePass?.challengeCategories;
    },

    totCompleted() {
      return this.totAndCompletedChallenges.complete;
    },

    totChallenges() {
      return this.totAndCompletedChallenges.tot;
    },

    totAndCompletedChallenges() {
      let numComplete = 0;
      let numChallenges = 0;

      for (const category of this.filteredChallengeCategories) {
        if (!category.challenges) {
          continue;
        }
        for (const challenge of category.challenges) {
          numChallenges++;
          if (challenge.isCompleted) {
            numComplete++;
          }
        }
      }

      return {complete: numComplete, tot: numChallenges};
    },
  },

  methods: {
    setInitialSelectedCategory() {
      const filteredChallengeCatLength = this.filteredChallengeCategories.length;

      for (let i = 0; i < filteredChallengeCatLength; ++i) {
        if (filteredChallengeCatLength > 0 && this.filteredChallengeCategories[i].challenges && this.filteredChallengeCategories[i].challenges.length > 0) {
          this.selectedCategoryName = this.filteredChallengeCategories[i].name;
          return;
        }
      }
      if (!this.selectedCategoryName && this.filteredChallengeCategories.length) {
        this.selectedCategoryName = this.filteredChallengeCategories[0].name;
      }
    },

    selectCategory(category) {
      this.selectedCategoryName = category.name;
    },

    categoryProgressString,
  },
};
</script>

<style lang="scss" scoped>
.challengesBox {
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  padding: 8px;
  font-size: 14px;
  background: var(--obcolor-background-2);
  margin: -5px -5px 0 -5px;
  border-radius: 4px;
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.boxBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  padding: 5px;
}

.buttonList {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  .typeBtn {
    padding: 5px 10px;
    box-sizing: border-box;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: var(--obcolor-font-secondary);
    white-space: nowrap;
    cursor: pointer;

    &:hover, &.selected {
      background: var(--obcolor-background-2);
    }

    &.selected {
      color: var(--obcolor-font-primary);
    }
  }

  .timeRemainingBox {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 3px;
    color: var(--obcolor-font-secondary);
    white-space: nowrap;

    .watchIcon {
      margin-right: 5px;
    }
  }
}

.challengeList {
  padding: 0 0 5px 0;
  min-height: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  &.noChallenges {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--obcolor-font-secondary);
    text-align: center;
  }

  .challengeEntryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      margin-top: 10px;
    }
  }
}
</style>