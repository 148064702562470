<template>
  <div class="lineupContainer"
    :class="{ selectedLineup: isSelectedLineup, flashLineup: inactiveFlashActive, lineupContainerSmall: showSmallLineup }"
    @click="selectLineup"
  >
    <!-- Animation that covers up lineups and shows new lineups when new lineups are generated -->
    <div class="revealAnimationContainer">
      <RevealAnimation ref="revealAnimation" class="revealAnimation"
        @revealFinished="revealTeam"
      />
    </div>

    <!-- Team display -->
    <template v-if="shownLineup != null">
      <div class="lineupSpacingSmall" v-if="showSmallLineup">
        <div class="lineupHeaderSmall">
          <div>Lineup {{ lineupIndex + 1 }}</div>
          <div class="projText">{{ shownLineup.projection.toFixed(2) }}</div>
        </div>
        <template v-for="(player, pIndex) in shownLineup.players">
          <LightningPlayerRow class="playerTile" :key="pIndex + 'tile'"
            :player="player" :sport="sport" :lockedPlayerId="lockedPlayerId"
            :allowLockedPlayers="allowLockedPlayers"
          />
          <!-- Using these instead of borders so that we can ensure they're evenly spaced -->
          <div class="lineupDivider" v-if="pIndex !== (shownLineup.players.length - 1)" :key="pIndex + 'divider'"></div>
        </template>
      </div>
      <div class="lineupSpacing" v-else>
        <div class="lineupHeader">Lineup {{ lineupIndex + 1 }}</div>
        <template v-for="(player, pIndex) in shownLineup.players">
          <LightningPlayerTile class="playerTile" :key="pIndex + 'tile'"
            :player="player" :sport="sport" :lockedPlayerId="lockedPlayerId"
            :allowLockedPlayers="allowLockedPlayers"
          />
          <!-- Using these instead of borders so that we can ensure they're evenly spaced -->
          <div class="lineupDivider" v-if="pIndex !== (shownLineup.players.length - 1)" :key="pIndex + 'divider'"></div>
        </template>
        <div class="lineupProj">
          <div class="projText">{{ shownLineup.projection.toFixed(2) }}</div>
          <div class="projTitle">Projected</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LightningPlayerTile from './LightningPlayerTile.vue';
import LightningPlayerRow from './LightningPlayerRow.vue';
import EventBus from '@/event-bus';
import RevealAnimation from '@/components/animations/LightningLineups/RevealAnimation.vue';

export default {
  props: {
    isSelectedLineup: {type: Boolean, default: false},
    lineup: {type: Object, default: null},
    sport: {type: String, default: null},
    numLineups: {type: Number, default: null},
    lineupIndex: {type: Number, default: null},
    canClickLineupButtons: {type: Boolean, default: null},
    allowLockedPlayers: {type: Boolean, default: false},
    lockedPlayerId: {type: String, default: null},
    showSmallLineup: {type: Boolean, default: false},
  },

  components: {
    LightningPlayerTile,
    LightningPlayerRow,
    RevealAnimation,
  },

  data() {
    return {
      // Number of seconds between the start of each reveal animation in the list
      revealAnimationDelay: 0.1,
      showReveal: null,
      revealFinished: false,
      oldLineup: null,

      // Number of seconds between the start of each inactive flash in the list
      inactiveFlashDelay: 0.3,
      inactiveFlashActive: false,
      flashTimeout: null,
      flashDurationTimeout: null,
    };
  },

  created() {
    EventBus.$on('LL_INACTIVE_PROMPT', this.startInactiveFlash);
    EventBus.$on('LL_CLEAR_INACTIVE_PROMPT', this.stopInactiveFlash);
    EventBus.$on('LL_NEW_LINEUPS_GENERATED', this.newLineupsGenerated);
    EventBus.$on('LL_SPIN_FINISHED', this.revealTeam);
  },

  mounted() {
    this.startReveal();
  },

  destroyed() {
    EventBus.$off('LL_INACTIVE_PROMPT', this.startInactiveFlash);
    EventBus.$off('LL_CLEAR_INACTIVE_PROMPT', this.stopInactiveFlash);
    EventBus.$off('LL_NEW_LINEUPS_GENERATED', this.newLineupsGenerated);
    EventBus.$off('LL_SPIN_FINISHED', this.revealTeam);
  },

  computed: {
    // While the reveal animation is going down, show the old lineup, then show the current lineup
    shownLineup() {
      return this.revealFinished ? this.lineup : this.oldLineup;
    },
  },

  watch: {
    inactiveFlashActive(newVal) {
      if (newVal) {
        clearInterval(this.flashDurationTimeout);
        this.flashDurationTimeout = setInterval(() => {
          this.inactiveFlashActive = false;
        }, 300);
      }
    },

    // On error or switching sports, unset the old lineup as it won't be used in the first reveal animation
    hasLineups(newVal) {
      if (newVal) {
        this.oldLineup = null;
      }
    },
  },

  methods: {
    selectLineup() {
      if (!this.canClickLineupButtons) {
        return;
      }

      EventBus.$emit('LL_SELECT_LINEUP', this.lineupIndex);
    },

    newLineupsGenerated(lineupsDiff) {
      if (!lineupsDiff || this.lineupIndex == null) {
        return;
      }

      if (!lineupsDiff.old || !lineupsDiff.old.lineups) {
        this.oldLineup = null;
      } else {
        // Remember the previous lineup that existed before new lineups were generated
        this.oldLineup = lineupsDiff.old.lineups[this.lineupIndex];
      }

      this.startReveal();
    },

    // Reveal animation section
    startReveal() {
      this.revealFinished = false;

      // Offset each reveal based on their position in the list
      setTimeout(this.playRevealAnimation, this.revealAnimationDelay * this.lineupIndex * 1000);
    },

    playRevealAnimation() {
      if (this.lineupIndex >= this.numLineups) {
        this.revealFinished = true;
        return;
      }

      this.$refs.revealAnimation.playAnimation();
    },

    revealTeam() {
      this.revealFinished = true;
    },

    // Inactive animation section
    startInactiveFlash() {
      // Offset flash based on their position in the list
      clearTimeout(this.flashTimeout);
      this.flashTimeout = setTimeout(this.setInactiveFlash, this.inactiveFlashDelay * this.lineupIndex * 1000);
    },

    setInactiveFlash() {
      this.inactiveFlashActive = true;
    },

    stopInactiveFlash() {
      clearInterval(this.flashTimeout);
      clearInterval(this.flashDurationTimeout);
      this.inactiveFlashActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lineupContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  height: 420px;
  width: 110px;
  margin: 2.5px;
  border-radius: 10px;
  border: 2px solid var(--obcolor-background-4);
  background: var(--obcolor-background-4);
  text-align: center;
  overflow: hidden;

  &.selectedLineup {
    border: 2px solid var(--obcolor-ob-blue);
  }

  &:not(.selectedLineup):hover {
    border: 2px solid var(--obcolor-background-1);
  }

  &.flashLineup {
    border: 2px solid var(--obcolor-ll-flash-yellow) !important;
    box-shadow: 0 0 3px 1px var(--obcolor-ll-flash-yellow);
  }
}

.lineupContainerSmall {
  height: 190px;
  width: 150px;
}

.lineupSpacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  padding: 5px 0;
}

.lineupSpacingSmall {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.lineupHeader {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
}

.lineupHeaderSmall {
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 0px;
}

.playerTile {
  width: 100%;
}

.lineupDivider {
  width: 100%;
  height: 1px;
  background: var(--obcolor-background-6);
}

::v-deep {
  .lineupContainer .playerName {
    font-size: 12px;
  }
}

.lineupProj {
  padding-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.projText {
  color: var(--obcolor-ob-blue);
}

.revealAnimationContainer {
  height: 0;
  position: relative;
  width: calc(100% + 6px);
  top: -20px;
  z-index: 1;
}

.revealAnimation {
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
}
</style>