<template>
  <img class="rankImg" :src="getRankImg()" :alt="this.rankNum"
    :style="'width:'+ size +'px'"
  >
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    size: {type: Number, default: null},
    rankNum: {type: Number, default: null},
    rankColor: {type: String, default: null},
  },

  computed: {
    ...mapState(['theme']),
  },

  methods: {
    getRankImg() {
      let showRankNum = this.rankNum;
      let showRankColor = this.rankColor;
      if (this.rankNum == null) {
        showRankNum = 1;
      }
      if (this.rankColor == null) {
        showRankColor = 'blue';
      }
      return require('@/assets/ranks/'+ this.theme +'_'+ showRankNum +'_'+ showRankColor +'.png');
    },
  },
};
</script>