<template>
  <Modal v-if="(isOpen && currentMatchup != null) || loading == true" v-model="isOpen"
    containerStyle="max-height: calc(100% - 60px); width: 900px; max-width: calc(100% - 60px); padding: 10px; overflow: hidden"
    bodyStyle="margin: 0 -10px; padding: 0; background: var(--obcolor-background-page);"
    footerStyle="background: var(--obcolor-box-header)"
    :hideCloseButton="!rouletteCompleted"
  >
    <template v-if="!loading">
      <div slot="header">
        <div class="draftDuration">Draft Duration: {{ draftJSON.duration }}</div>
        <div class="headerProjections">Projections</div>
        <div class="matchHeader">
          <div class="ob-box matchHeaderTeamBox">
            <UserLogo :id="curUser" :sport="draftJSON.sport" :rankColor="users[curUser].rankColor"
              :rankNum="users[curUser].rankNum" :showRank="true"
            />
            <div style="margin-left: 15px;">
              <div class="matchHeaderName">{{ users[curUser].team }}</div>
              <div class="matchHeaderName matchHeaderUsername">{{ users[curUser].name }} ({{ users[curUser].h2hRecord }})</div>
            </div>
          </div>
          <div class="matchHeaderVs">
            <div>
              <div class="matchHeaderCoin" :class="{hiddenCoin: !rouletteCompleted}">
                <i v-if="wonCoinToss(curUser)" class="fas fa-coin"></i>
              </div>
              <div class="matchHeaderProj" :style="projectedWin(curUser) ? 'color: var(--obcolor-ob-blue)' : ''">{{ getPotentialProj(curUser) }}</div>
            </div>
            <div class="matchHeaderRecord">
              <div style="font-size: 12px; height: 0;" v-if="rouletteCompleted">
                ({{ curUserH2H != null ? curUserH2H : '#' }}-{{ oppUserH2H != null ? oppUserH2H : '#' }})
              </div>
              <div class="matchHeaderVsText">vs</div>
            </div>
            <div>
              <div class="matchHeaderCoin" :class="{hiddenCoin: !rouletteCompleted}">
                <i v-if="wonCoinToss(oppUser)" class="fas fa-coin"></i>
              </div>
              <div class="matchHeaderProj" :style="projectedWin(oppUser) ? 'color: var(--obcolor-ob-blue)' : ''">{{ rouletteCompleted ? getPotentialProj(oppUser) : '???' }}</div>
            </div>
          </div>
          <DraftSummaryRoulette v-if="currentMatchup"
            :draftJSON="draftJSON" :users="users" :curUser="curUser"
            :oppUser="oppUser" :rouletteCompleted="rouletteCompleted"
          />
        </div>
        <div class="navbar" :style="rouletteCompleted ? '' : 'height: 0; padding: 0; overflow: hidden;'">
          <div style="display: flex;">
            <div class="navOption" :class="{selectedOption: selectedTab == 'matchups'}" @click="selectTab('matchups')">Contest Matchups</div>
            <div class="navOption" :class="{selectedOption: selectedTab == 'draftResults'}" @click="selectTab('draftResults')">Draft Results</div>
          </div>
          <div class="searchBar" v-if="selectedTab == 'draftResults'">
            <i class="fa fa-search"></i>
            <input v-model="playerSearchText" placeholder="Player Search">
          </div>
        </div>
      </div>
      <div slot="body" class="summaryBody" :style="rouletteCompleted ? 'height: 500px;' : 'height: 0; overflow: hidden;'">
        <DraftSummaryResults v-if="selectedTab == 'draftResults'"
          :draftJSON="draftJSON" :playerSearchText="playerSearchText"
        />
        <DraftSummaryMatchups v-else :draftJSON="draftJSON" :users="users"
          :teamRosters="teamRosters"
        />
      </div>
      <div slot="footer" class="modalFooter">
        <router-link to="/lobby"
          v-if="$router.currentRoute.name != 'lobby'"
          class="modal-default-button ob-btn-med routerLink"
        >
          Lobby
        </router-link>
        <router-link :to="'/contest/matchup/summary/' + draftJSON.leagueId"
          v-if="$router.currentRoute.name != 'contest-matchup'"
          class="modal-default-button ob-btn-med routerLink"
        >
          Matchup
        </router-link>
      </div>
    </template>
    <div v-else slot="header" class="loadingHeader">
      <ObLoading />
    </div>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal';
import UserLogo from '@/components/UserLogo';
import DraftSummaryRoulette from '@/views/Draft/DraftSummaryModal/DraftSummaryRoulette';
import DraftSummaryResults from '@/views/Draft/DraftSummaryModal/DraftSummaryResults';
import DraftSummaryMatchups from '@/views/Draft/DraftSummaryModal/DraftSummaryMatchups';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    UserLogo,
    ObLoading,
    DraftSummaryRoulette,
    DraftSummaryResults,
    DraftSummaryMatchups,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      isOpen: false,
      draftJSON: {},
      teamRosters: {},
      users: {},
      curUser: '',

      selectedTab: 'matchups',
      playerSearchText: '',
      rouletteCompleted: false,
    };
  },
  created() {
    EventBus.$on('OPEN_DRAFT_SUMMARY_MODAL', this.openModal);
    EventBus.$on('ROULETTE_COMPLETED', this.handleRouletteCompleted);
  },
  destroyed() {
    EventBus.$off('OPEN_DRAFT_SUMMARY_MODAL', this.openModal);
    EventBus.$off('ROULETTE_COMPLETED', this.handleRouletteCompleted);
  },
  computed: {
    currentMatchup() {
      if (!this.draftJSON || !this.draftJSON.matchups) {
        return null;
      }
      for (const matchup of this.draftJSON.matchups) {
        if (matchup.team1Id == this.curUser || matchup.team2Id == this.curUser) {
          return matchup;
        }
      }
      return null;
    },
    oppUser() {
      if (this.currentMatchup.team1Id != this.curUser) {
        return this.currentMatchup.team1Id;
      }
      return this.currentMatchup.team2Id;
    },
    curUserH2H() {
      if (this.currentMatchup.team1Id == this.curUser) {
        return this.currentMatchup.team1H2H;
      }
      return this.currentMatchup.team2H2H;
    },
    oppUserH2H() {
      if (this.currentMatchup.team1Id != this.curUser) {
        return this.currentMatchup.team1H2H;
      }
      return this.currentMatchup.team2H2H;
    },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) {
        this.resetFields();
      }
    },
  },
  methods: {
    wonCoinToss(userId) {
      if (!this.currentMatchup) {
        return false;
      }
      return (this.currentMatchup.coinTossWinner == 'team1' && this.currentMatchup.team1Id == userId) ||
        (this.currentMatchup.coinTossWinner == 'team2' && this.currentMatchup.team2Id == userId);
    },
    handleRouletteCompleted() {
      this.rouletteCompleted = true;
    },
    resetFields() {
      this.draftJSON = {};
      this.teamRosters = {};
      this.users = {};
      this.curUser = '';

      this.selectedTab = 'matchups';
      this.playerSearchText = '';
    },
    openModal(draftJSON, teamRosters, users, curUser, isFirstTime) {
      this.resetFields();
      this.draftJSON = draftJSON;
      this.teamRosters = teamRosters;
      this.users = users;
      this.curUser = curUser;

      this.isOpen = true;
      if (!isFirstTime) {
        this.rouletteCompleted = true;
      }
    },

    selectTab(name) {
      this.playerSearchText = '';
      this.selectedTab = name;
    },

    getPotentialProj(userId) {
      let projT = 0;
      this.teamRosters[userId].forEach((group) => {
        const playerGames = [];
        let sortedGames = [];
        group.players.forEach((player) => {
          if (player.gamesByWeek) {
            player.gamesByWeek[0].forEach(function(game) {
              playerGames.push(game.proj);
            });
          }
        });
        sortedGames = playerGames.sort((proj1, proj2) => {
          return proj1 < proj2 ? 1 : -1;
        });
        for (let i = 0; i < group.maxGames; ++i) {
          if (sortedGames[i]) {
            projT += Math.round(sortedGames[i]);
          }
        }
      });

      return projT.toFixed(0);
    },

    projectedWin(userId) {
      let teamNumber = 1;
      const matchup = this.currentMatchup;

      if (matchup.team2Id == userId) {
        teamNumber = 2;
      }

      if (this.getPotentialProj(matchup.team1Id) == this.getPotentialProj(matchup.team2Id)) {
        if (teamNumber == 1 && matchup.coinTossWinner == 'team1') {
          return true;
        } else if (teamNumber == 2 && matchup.coinTossWinner == 'team2') {
          return true;
        }
        return false;
      }

      if (teamNumber == 1 && this.getPotentialProj(matchup.team1Id) > this.getPotentialProj(matchup.team2Id)) {
        return true;
      } else if (teamNumber == 2 && this.getPotentialProj(matchup.team2Id) > this.getPotentialProj(matchup.team1Id)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalFooter {
  padding: 10px;
  margin: 0 0 -10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.draftDuration {
  font-size: 14px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
}

.matchHeader {
  display: flex;
  padding-bottom: 10px;
}

.loadingHeader {
  height: 702px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.matchHeaderVs {
  flex: 1;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  color: var(--obcolor-font-secondary);
  text-align: center;
}

.matchHeaderVsText {
  padding-top: 16px;
  font-weight: bold;
  font-size: 20px;
  color: var(--obcolor-font-primary);
}

.fa-coin {
  color: var(--obcolor-coin);
}

.headerProjections {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
}

.matchHeaderTeamBox {
  background: var(--obcolor-background-2);
  border: 1px solid var(--obcolor-text-faded);
  display: flex;
  align-items: center;
  width: 230px;
}

.matchHeaderTeamBox:last-child {
  text-align: right;
  justify-content: flex-end
}

.matchHeaderCoin {
  padding: 4px 7px;
  font-size: 16px;
  color: var(--obcolor-font-secondary);
  height: 18px;
  margin-bottom: -3px;
  margin-top: -6px;
  opacity: 1;
  transition: opacity 1s;
}

.matchHeaderCoin.hiddenCoin {
  opacity: 0;
}

.matchHeaderProj {
  background: var(--obcolor-background-2);
  padding: 5px 12px;
  height: 18px;
  min-width: 35px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.matchHeaderRecord {
  height: 18px;
  font-size: 14px;
}

.matchHeaderName {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.matchHeaderUsername {
  font-size: 14px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.navbar {
  width: calc(100% + 20px);
  box-sizing: border-box;
  background: var(--obcolor-navi-bar);
  margin: 0 -10px 0 -10px;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  font-weight: bold;
}

.navOption {
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.navOption:hover {
  background: var(--obcolor-bg-hover);
}

.navOption.selectedOption {
  background: var(--obcolor-background-6);
  color: var(--obcolor-ob-blue);
}

.routerLink:not(:last-child) {
  margin-right: 10px;
}

.searchBar {
  display: flex;
  align-items: center;
  border-radius: 26px;
  width: 200px;
  background: var(--obcolor-background-page);
  height: 26px;
  padding: 5px 10px;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  box-sizing: border-box;
}

.searchBar > input {
  background: none;
  height: 16px;
}

.navBar, .summaryBody {
  transition: height 1s;
}

.summaryBody {
  background: var(--obcolor-box-header);
}
</style>