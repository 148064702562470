<template>
  <div class="lineupSelectorContainer">
    <div class="lineupsListContainer ob-scroll">
      <div class="lineupsList" :style="containerPadding">
        <div v-if="!loaded && !hasLineups" class="emptyLineupMessage">
          <ObLoading :size="120" />
        </div>
        <!-- This shouldn't ever happen - If a sport isn't set we shouldn't be loading lineups -->
        <!-- This is a failsafe in case any logic changes and this state becomes valid -->
        <div v-else-if="!sport" class="emptyLineupMessage">
          <div v-if="!allSportsClosed">Select a sport to start</div>
          <div v-else>No sports are currently available. Please try again later</div>
        </div>
        <div v-else-if="!hasLineups" class="emptyLineupMessage">
          <div>{{ sport }} Lightning Lineups is not available at this time.</div>
          <div style="margin-top: 60px">{{ lineupErrorMessage }}</div>
          <div>In the meantime, visit the lobby to join a live contest.</div>
          <router-link to="/lobby" class="ob-btn-grey" style="margin-top: 20px; padding: 10px 30px;">Go to Lobby</router-link>
        </div>
        <template v-else-if="lineupInfo && lineupInfo.lineups" v-for="(lineup, index) in lineupInfo.lineups">
          <LightningSelectorTeam v-show="index < numLineups" :key="index"
            :isSelectedLineup="isSelectedLineup(index)"
            :lineup="lineup" :sport="sport" :numLineups="numLineups"
            :lineupIndex="index" :canClickLineupButtons="canClickLineupButtons"
            :lockedPlayerId="lockedPlayerId" :allowLockedPlayers="lineupInfo.allowLockedPlayers"
            :showSmallLineup="showSmallLineup"
          />
        </template>
      </div>
    </div>
    <div class="lineupSelectorControls">
      <div class="lineupSelectorBar">
        <div class="newLineupBtn" :class="{canClick: canClickLineupButtons}" @click="generateLineupFromButton()">
          <!-- Button image - on click, the animation is played and player is barred from clicking again. On finishing animation, player can click again -->
          <SpinAnimation ref="spinAnimation" class="lineupBtnImg" @animationFinished="finishSpin()" />
        </div>
        <div class="addRemoveContainer">
          <div class="leftContainer">
            <!-- Timer and countdown bar -->
            <div class="timerContainer">
              <template v-if="timerValue != null">
                <!-- Empty part of timer -->
                <div :style="'height: ' + (100 - timerPercent) + '%'"></div>
                <!-- highlighted part of timer -->
                <div class="lineupTimer" :style="'height: ' + timerPercent + '%'"></div>
              </template>
            </div>
            <div class="infoContainer">
              <template v-if="timerValue != null">
                <div class="infoValue">{{ (timerValue / 10).toFixed(1) }}</div>
                <div class="infoText">Countdown</div>
              </template>
            </div>
            <div class="triangleLeft"></div>
            <div v-if="hasLineups" class="addRemoveBtn" :class="{ canClick: canClickRemove }"
              @click="removeLineup()"
            >
              <i class="fas fa-minus"></i>
              <div class="addRemoveText removeText">
                <div class="addRemoveTitle">Remove Lineup</div>
                <div v-if="!isMinLineups" class="payoutAmountText">Payout {{ prevMultiplier }}x</div>
                <div v-else class="payoutAmountText">Min {{ minLineups }} Lineups</div>
              </div>
            </div>
          </div>
          <div class="rightContainer">
            <div v-if="hasLineups" class="addRemoveBtn addBtn" :class="{ canClick: canClickAdd }"
              @click="addLineup()"
            >
              <div class="addRemoveText addText">
                <div class="addRemoveTitle">Add Lineup</div>
                <div v-if="!isMaxLineups" class="payoutAmountText">Payout {{ nextMultiplier }}x</div>
                <div v-else class="payoutAmountText">Max {{ maxLineups }} Lineups</div>
              </div>
              <i class="fas fa-plus"></i>
            </div>
            <div class="triangleRight"></div>
            <!-- Current multiplier and payout -->
            <div class="infoContainer">
              <template v-if="currentMultiplier != null">
                <div class="infoValue">{{ currentMultiplier }}x</div>
                <div class="infoText">Payout</div>
              </template>
            </div>
            <LightningPayoutBars v-if="currentMultiplier != null" class="payoutBars" :numLineups="numLineups"
              :maxLineups="maxLineups"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightningSelectorTeam from './LightningSelectorTeam';
import LightningPayoutBars from './LightningPayoutBars.vue';
import ObLoading from '@/components/ObLoading.vue';
import SpinAnimation from '@/components/animations/LightningLineups/SpinAnimation';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    sport: {type: String, default: null},
    lineupInfo: {type: Object, default: null},
    loaded: {type: Boolean, default: null},
    hasLineups: {type: Boolean, default: null},
    numLineups: {type: Number, default: null},
    selectedLineupIndex: {type: Number, default: null},
    lineupErrorMessage: {type: String, default: ''},
    lockedPlayerId: {type: String, default: null},
  },

  components: {
    LightningSelectorTeam,
    LightningPayoutBars,
    ObLoading,
    SpinAnimation,
  },

  data() {
    return {
      useTimer: true,
      // Make the trigger startTimer() every X seconds (number of seconds sent from the backend)
      timerTriggered: false,
      timerInterval: null,
      timerValue: null,

      inactivityTimeoutList: [],
      inactivityTimesSeconds: [12, 40],

      canClickLineupButtons: true,
      largeLineupLimit: 6,
      minLineups: 3,
      sportOptions: [
        'NFL',
        'NBA',
        'NHL',
        'MLB',
      ],
    };
  },

  created() {
    EventBus.$on('LL_PAUSE_TIMER', this.pauseTimer);
    EventBus.$on('LL_UNPAUSE_TIMER', this.setTimerInterval);
    EventBus.$on('LL_NEW_LINEUPS_GENERATED', this.newLineupsGenerated);
  },

  destroyed() {
    clearInterval(this.timerInterval);
    EventBus.$off('LL_PAUSE_TIMER', this.pauseTimer);
    EventBus.$off('LL_UNPAUSE_TIMER', this.setTimerInterval);
    EventBus.$off('LL_NEW_LINEUPS_GENERATED', this.newLineupsGenerated);
  },

  computed: {
    ...mapState(['sportNumContests']),

    allSportsClosed() {
      for (const option in this.sportOptions) {
        if (this.isSportOpen(option)) {
          return false;
        }
      }

      return true;
    },

    showLoadingSpinner() {
      return this.loading || !this.lineupInfo || !this.lineupInfo.lineups;
    },

    maxLineups() {
      if (!this.lineupInfo) {
        return 10;
      }
      return this.lineupInfo.lineups.length;
    },

    isMinLineups() {
      return this.minLineups >= this.numLineups;
    },

    isMaxLineups() {
      return this.maxLineups <= this.numLineups;
    },

    showSmallLineup() {
      return this.numLineups && this.numLineups > this.largeLineupLimit;
    },

    canClickAdd() {
      return !this.isMaxLineups && this.canClickLineupButtons;
    },

    canClickRemove() {
      return !this.isMinLineups && this.canClickLineupButtons;
    },

    nextMultiplier() {
      if (!this.lineupInfo || !this.lineupInfo.payouts || !this.lineupInfo.payouts[this.numLineups + 1]) {
        return null;
      }
      return this.lineupInfo.payouts[this.numLineups + 1];
    },

    currentMultiplier() {
      if (!this.lineupInfo?.payouts || !this.lineupInfo?.payouts[this.numLineups]) {
        return null;
      }
      return this.lineupInfo.payouts[this.numLineups];
    },

    prevMultiplier() {
      if (!this.lineupInfo || !this.lineupInfo.payouts || !this.lineupInfo.payouts[this.numLineups - 1]) {
        return null;
      }
      return this.lineupInfo.payouts[this.numLineups - 1];
    },

    timerPercent() {
      if (!this.lineupInfo || !this.lineupInfo.refreshTimeout || !this.useTimer) {
        return 100;
      }
      return ((this.timerValue / 10) / this.lineupInfo.refreshTimeout) * 100;
    },

    containerPadding() {
      if (this.numLineups != null && this.numLineups > 6 && this.numLineups < 9) {
        return 'padding: 10px 80px;';
      }

      return 'padding: 10px 0';
    },
  },

  watch: {
    loaded(newVal) {
      if (!this.useTimer || !this.hasLineups) {
        this.stopTimer();
      } else if (!newVal) {
        this.pauseTimer();
      }
    },

    selectedLineupIndex(newVal) {
      if (newVal != null) {
        this.stopInactivityTimer();
      }
    },
  },

  methods: {
    isSelectedLineup(lineupIndex) {
      return lineupIndex === this.selectedLineupIndex;
    },

    newLineupsGenerated() {
      this.lineupButtonPressedAnimations();
      this.startTimer();
    },

    // Countdown timer section (Yellow timer line)
    setTimerInterval() {
      this.timerInterval = setInterval(this.tickTimerInterval, 100);
    },

    startTimer() {
      clearInterval(this.timerInterval);

      if (!this.lineupInfo || !this.useTimer) {
        return;
      }

      this.timerTriggered = false;
      this.timerValue = this.lineupInfo.refreshTimeout * 10;
      this.setTimerInterval();
    },

    stopTimer() {
      this.stopInactivityTimer();
      clearInterval(this.timerInterval);
      this.timerValue = null;
    },

    pauseTimer() {
      this.stopInactivityTimer();
      clearInterval(this.timerInterval);
      this.timerTriggered = false;
    },

    tickTimerInterval() {
      if (this.timerValue === null) {
        return;
      }

      if (this.timerValue !== 0) {
        this.timerValue--;
      } else if (!this.timerTriggered) {
        this.timerTriggered = true;
        EventBus.$emit('LL_TIMER_FINISHED');
      }
    },

    // Inactivity timer section
    stopInactivityTimer() {
      EventBus.$emit('LL_CLEAR_INACTIVE_PROMPT');
      for (const timeout of this.inactivityTimeoutList) {
        clearTimeout(timeout);
      }
      this.inactivityTimeoutList = [];
    },

    resetInactivityTimer() {
      this.stopInactivityTimer();

      if (this.selectedLineupIndex == null) {
        for (const timeSeconds of this.inactivityTimesSeconds) {
          const timeout = setTimeout(this.triggerInactivityTimer, timeSeconds * 1000);
          this.inactivityTimeoutList.push(timeout);
        }
      }
    },

    triggerInactivityTimer() {
      EventBus.$emit('LL_INACTIVE_PROMPT');
    },

    // Lineup button section
    finishSpin() {
      this.canClickLineupButtons = true;

      if (this.useTimer && this.hasLineups && !this.timerValue) {
        this.startTimer();
      }
      this.resetInactivityTimer();
      EventBus.$emit('LL_SPIN_FINISHED');
    },

    generateLineupFromButton() {
      if (!this.canClickLineupButtons || !this.loaded) {
        return;
      }

      // Play button spinning animation, stop timer, disable button clicking, generate new lineup
      this.lineupButtonPressedAnimations();
      this.generateNewLineup();
    },

    lineupButtonPressedAnimations() {
      this.$refs.spinAnimation.playAnimation();
      this.canClickLineupButtons = false;
    },

    generateNewLineup() {
      this.stopInactivityTimer();
      EventBus.$emit('LL_GENERATE_NEW_LINEUP');
    },

    // Add/remove buttons
    addLineup() {
      if (!this.canClickAdd) {
        return;
      }
      EventBus.$emit('LL_ADD_LINEUP');
    },

    removeLineup() {
      if (!this.canClickRemove) {
        return;
      }
      EventBus.$emit('LL_REMOVE_LINEUP');
    },

    isSportOpen(sport) {
      return this.sportNumContests?.[sport]?.llContestOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.lineupSelectorContainer {
  background: var(--obcolor-background-page);
  display: flex;
  flex-direction: column;
}

.emptyLineupMessage {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.lineupsListContainer {
  overflow: auto;
  display: flex;
  height: 445px;
}

.lineupsList {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
}

.lineupSelectorControls {
  --lineup-btn-width: 86px;
  box-sizing: border-box;
  height: 56px;
}

.lineupSelectorBar {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}

.timerContainer {
  min-height: 56px;
  min-width: 2px;
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-6);
}

.lineupTimer {
  width: 2px;
  background: var(--obcolor-ll-countdown-yellow);
}

.newLineupBtn {
  cursor: pointer;
  position: absolute;
  left: calc(50% - var(--lineup-btn-width)/2);
  top: 0px;

  width: var(--lineup-btn-width);

  .lineupBtnImg {
    width: var(--lineup-btn-width);
  }

  &:not(.canClick) {
    cursor: not-allowed;
  }

  &.canClick .lineupBtnImg {
    filter: drop-shadow(0 0 1px white);
    transform-origin: center;
    transform: scale(1);
  }

  &.canClick:hover .lineupBtnImg {
    filter: drop-shadow(0 0 2px white);
    transform-origin: center;
    transform: scale(0.97);
  }
  &.canClick:active .lineupBtnImg {
    filter: drop-shadow(0 0 4px white);
    transform-origin: center;
    transform: scale(0.94);
  }

  &.canClick .imgMask {
    display: none;
  }

  .imgMask {
    position: absolute;
    width: var(--lineup-btn-width);
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    transition: height 1s linear;

    .lineupBtnImg {
      filter: grayscale(1) brightness(0.5);
    }
  }
}

.addRemoveContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
  background: var(--obcolor-background-page);
}

.leftContainer {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 50%;
  text-align: right;
}

.infoContainer {
  min-width: 72px;
  display: flex;
  flex-direction: column;
  padding: 0 13px;
  background: var(--obcolor-background-6);
}

.infoValue {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}

.infoText {
  font-size: 12px;
  line-height: 16px;
  color: var(--obcolor-font-secondary);
}

.triangleLeft {
  width: 0;
  height: 0;
  border-bottom: 56px solid var(--obcolor-background-6);
  border-right: 100px solid transparent;
}

.triangleRight {
  width: 0;
  height: 0;
  border-bottom: 56px solid var(--obcolor-background-6);
  border-left: 100px solid transparent;
}

.payoutBars {
  max-width: 56px;
}

.addRemoveBtn {
  display: flex;
  align-items: center;
  height: 41px;
  width: 126px;
  font-size: 14px;
  font-weight: bold;
  margin: 7px 0;
  padding: 0 10px;
  border-radius: 4px;

  background: var(--obcolor-background-6);

  // Prevent highlight/copy of text (I kept highlighting it when trying to click the button)
  user-select: none;

  &.canClick {
    cursor: pointer;

    &:hover {
      background: var(--obcolor-background-4);
    }
  }

  &:not(.canClick) {
    cursor: not-allowed;
    color: var(--obcolor-font-secondary);
  }
}

.addBtn {
  justify-content: flex-end;
}

.removeText {
  margin-left: 10px;
}

.addText {
  text-align: right;
  margin-right: 10px;
}

.canClick .addRemoveTitle {
  color: var(--obcolor-font-primary);
}

.payoutAmountText {
  color: var(--obcolor-font-secondary);
  font-weight: normal;
}
</style>