<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 1000px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);"
  >
    <div slot="body">
      <table aria-label="Salary Cap Lineup">
        <tr>
          <th style="width: 0; min-width: 0; padding: 0;"></th>
          <th style="text-align: left;">Lineup</th>
          <th>PG/AG</th>
          <th>Salary</th>
          <th>sAVG</th>
          <th>PROJ</th>
          <template v-if="firstPlayer">
            <th v-for="(g, index) in firstPlayer.games" :key="index">
              <div>{{ g.day }}</div>
              <div v-if="g.date">{{ [g.date, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
          </template>
          <th v-else colspan="7"></th>
          <th></th>
        </tr>
        <template v-for="(posInfo, index) in roster">
          <tr v-for="(player, index1) in picks[posInfo.short]"
            :key="index + '_' + index1 + '_player'"
            :class="{ dividerTop: index1 == 0 && index != 0 }"
          >
            <td class="draftPos">{{ player.draftPos }}</td>
            <td>
              <div class="playerContainer">
                <PlayerHeadshot size="40" :id="player.id" :sport="contest.sport"
                  :team="player.team"
                />
                <div class="infoContainer">
                  <div class="nameContainer">
                    <PlayerStatus :status="player.status" />
                    <div style="margin-left: 5px; margin-top: 2px;"
                      @click.stop="openPlayerCard(player)" class="ob-link"
                    >
                      {{ player.name.shortName() }}
                    </div>
                  </div>
                  <div class="playerInfo">
                    <span>#{{ player.number }}</span>
                    <span>{{ player.position }}</span>
                    <span>{{ player.lineInfo }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ getStat(player, 'pg') }}/{{ getStat(player, 'ag') }}</td>
            <td class="moneyText">
              <div>{{ dollarFormat(player.salary) }}</div>
              <div style="font-size: 12px; font-weight: normal; color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: -3px;">
                {{ dollarFormat(player.salaryPG) }} PG
              </div>
            </td>
            <td>{{ player.sAvg.toFixed(1) || 0 }}</td>
            <td class="blueText">{{ getStat(player, 'proj') }}</td>
            <td v-for="(g, index2) in player.games"
              :key="index + '_' + index1 + '_' + index2"
              class="draftGame" style="color: var(--obcolor-text-primary);"
              :style="g['gameDH'] ? 'width: 85px;' : ''"
            >
              <div class="gameContainer">
                <div style="flex: 1;" :style="g.excluded ? 'color: red;' : ''">
                  <div class="gameOpp">{{ convertAlias(g.game) }}</div>
                  <div class="gameProj">{{ roundStat(g.proj) }}</div>
                  <div class="gameCost moneyText">{{ g.game && !g.excluded && g.proj > 0 ? dollarFormat(player.salaryPG) : '' }}</div>
                </div>
                <div v-if="g.gameDH" :style="g.excludedDH ? 'color: red;' : ''" style="width: 2px; height: 24px; background: var(--obcolor-background-4);"></div>
                <div v-if="g.gameDH" style="flex: 1;">
                  <div class="gameOpp">{{ g.gameDH }}</div>
                  <div class="gameProj">{{ roundStat(g.projDH) }}</div>
                </div>
              </div>
            </td>
            <td class="posColumn" v-if="index1 == 0" :key="index + '_pos'"
              :rowspan="posInfo.num"
            >
              <div class="posFilledMax">
                <div :style="posStatusInfo(posInfo).text ? 'color: ' + posStatusInfo(posInfo).bg : ''"
                  class="posFilledGames"
                >
                  {{ posFilledGames(posInfo.short) }}
                </div>
                <div class="posMaxGames">{{ posInfo.maxGames }}</div>
              </div>
            </td>
          </tr>
          <tr v-for="(num, index1) in rosterRemainingPicks(posInfo)"
            :key="index + '_' + index1 + '_empty'"
            :class="{ dividerTop: index1 == 0 && index != 0 }"
          >
            <td class="draftPos" style="min-width: 18px;">{{ posInfo.short }}</td>
            <td colspan="12" class="emptyText">
              <!-- No Player Selected -->
            </td>

            <!-- Add in position column if it wasn't put in in a filled player td -->
            <td class="posColumn" v-if="picks[posInfo.short].length == 0 && index1 == 0"
              :key="index1 + '_pos'" :rowspan="posInfo.num"
            >
              <div class="posFilledMax">
                <div :style="posStatusInfo(posInfo).text ? 'color: ' + posStatusInfo(posInfo).bg : ''"
                  class="posFilledGames"
                >
                  {{ posFilledGames(posInfo.short) }}
                </div>
                <div class="posMaxGames">{{ posInfo.maxGames }}</div>
              </div>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </Modal>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus.vue';
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
    PlayerHeadshot,
    PlayerStatus,
  },

  data() {
    return {
      isOpen: false,
      picks: null,
      contest: null,
    };
  },

  created() {
    EventBus.$on('OPEN_SALARY_CAP_CALENDAR_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_SALARY_CAP_CALENDAR_MODAL', this.openModal);
  },

  watch: {
    isOpen(newVal) {
      if (!newVal) {
        this.resetData();
      }
    },
  },

  computed: {
    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }

      return this.contest.draftRoster.roster;
    },

    firstPlayer() {
      for (const posInfo of this.roster) {
        if (this.picks[posInfo.short] && this.picks[posInfo.short].length > 0) {
          return this.picks[posInfo.short][0];
        }
      }

      return null;
    },
  },

  methods: {
    openModal(contest, picks) {
      this.contest = contest;
      this.picks = picks;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    resetData() {
      this.contest = null;
      this.picks = null;
    },

    rosterRemainingPicks(posInfo) {
      if (!posInfo || !posInfo.num || !this.picks || !this.picks[posInfo.short]) {
        return 0;
      }

      return posInfo.num - this.picks[posInfo.short].length;
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }

      return null;
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }

      return '$' + Intl.NumberFormat('en-US').format(dollarAmount);
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    convertAlias(game) {
      if (!game) {
        return '';
      }
      if (game.indexOf('@') != -1) {
        return '@' + this.$TeamInfo.getDisplayAlias(this.contest.sport, game.replace('@', ''));
      }
      return 'vs ' + this.$TeamInfo.getDisplayAlias(this.contest.sport, game);
    },

    posFilledGames(pos) {
      let totGames = 0;
      for (const player of this.picks[pos]) {
        totGames += this.getStat(player, 'pg') || 0;
      }

      return totGames;
    },

    posStatusInfo(posInfo) {
      const pos = posInfo.short;
      const posPicks = this.picks[pos];
      const posFilledGames = this.posFilledGames(pos);

      if (posPicks.length == posInfo.num && posInfo.maxGames > posFilledGames) {
        return {bg: 'var(--obcolor-red)', fg: 'white', text: 'Unused Game Opportunities', order: 1};
      } else if (posFilledGames > posInfo.maxGames) {
        return {bg: 'var(--obcolor-NBA)', fg: 'black', text: 'Best Ball Activated', order: 2};
      }

      // bg: background colour (and text colour if used in messaging), fg: foreground colour if background colour is used
      // text: text for error message, order: order in which errors should be shown
      return {bg: 'var(--obcolor-background-2)', fg: 'var(--obcolor-font-secondary)', text: '', order: 3};
    },

    openPlayerCard(player) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', player.id, this.contest.sport, this.contest.id, true);
    },
  },
};
</script>

<style lang="scss" scoped>

  table {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  th {
    color: #878998;
    background: var(--obcolor-background-5);
    padding: 12px 6px 6px 6px;
    height: 20px;
    font-size: 12px;
    position: sticky;
    top: 0;
    white-space: nowrap;
    z-index: 5;
  }

  td:not(.posColumn):not(.draftPos) {
    padding: 8px 4px 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  .draftPos {
    min-width: 40px;
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background: var(--obcolor-background-5);
  }

  .dividerTop {
    border-top: 2px solid var(--obcolor-background-page);
  }

  .playerContainer {
    display: flex;
  }

  .infoContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nameContainer {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    margin-left: 15px;
    color: var(--obcolor-font-secondary);
    text-align: left;
    span {
      margin-right: 5px;
    }
  }

  .draftGame {
    padding: 2px;
  }

  .gameContainer {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--obcolor-background-2);
    margin-right: 2px;
  }

  .gameOpp {
    font-size: 12px;
  }

  .gameProj {
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: -2px;
  }

  .gameCost {
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: -2px;
  }

  .posColumn {
    width: 42px;
    background: var(--obcolor-background-2);
  }

  .posFilledMax {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .posFilledGames {
    padding-bottom: 3px;
    border-bottom: 1px solid var(--obcolor-font-primary);
    width: 20px;
    text-align: center;
  }

  .posMaxGames {
    padding-top: 3px;
  }

  .blueText {
    color: var(--obcolor-ob-blue) !important;
    font-weight: bold;
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }

  .emptyText {
    color: var(--obcolor-font-secondary);
    font-size: 12px;
    text-align: left;
  }
</style>