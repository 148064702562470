<template>
  <div>
    <div class="componentTitle">Description</div>
    <div v-if="!description" class="componentContent">
      <ul>
        <li v-for="(entry, index) in getText()" :key="index">
          <div>
            {{ entry.text }}
            <Tooltip style="margin: 0;" pos="bottom" v-if="entry.tooltipKey"
              :textKey="entry.tooltipKey"
            />
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="componentContent">
      <div v-html="description"></div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  props: {
    league: {type: Object, default: null},
  },

  components: {
    Tooltip,
  },

  computed: {
    description() {
      if (this.league?.description && this.league?.description != '' ) {
        return this.league.description;
      }
      return null;
    },
  },

  methods: {
    getText() {
      const numPlayers = this.league.draftRoster ? this.league.draftRoster.rounds : '10';
      let dailyText = [
        {text: 'Build a ' + numPlayers + '-player roster while staying within the salary cap.'},
        {text: 'Contest results will be determined by the total points accumulated by each player (scoring rules summarized below).'},
        {text: 'You are able to swap players whose games have not started.'},
        {text: 'See the Prizes section for the payout schedule for this contest.'},
      ];

      let tournamentText = [
        {text: 'Build a ' + numPlayers + '-player roster while staying within the salary cap and game limits by position ', tooltipKey: 'LeagueModal_Tournament_Roster'},
        {text: 'Going over the game limit in a position triggers best ball', tooltipKey: 'LeagueModal_Tournament_BestBall'},
        {text: 'As games are played, salary and game opportunities are consumed'},
        {text: 'Swap players as desired, but swap fees apply', tooltipKey: 'LeagueModal_Tournament_SwapFee'},
        {text: 'Accumulate fantasy points over 7 days and secure the highest possible payout'},
        {text: 'See the Prizes section for the payout schedule for this contest'},
      ];

      if (this.league.sport == 'NHL') {
        tournamentText.push({text: 'A goalie set to backup will not consume game opportunities or salary. If they come in during a game their fantasy points will not count.'});
      } else if (this.league.sport == 'MLB') {
        tournamentText.push({text: 'Players not in the starting batting order and non-starting pitchers will not consume game opportunities or salary. If they come in as relief/pinch hitter/pinch runner, their fantasy points will not count.'});
        dailyText = [
          {text: 'Play the best SuperFlex Fantasy Baseball game now!'},
          {text: 'Build a ' + numPlayers + '-player roster while staying within the salary cap.'},
          {text: 'Contest results will be determined by the total points accumulated by each player (scoring rules summarized below).'},
          {text: 'You are able to swap players whose games have not started.'},
          {text: 'See the Prizes section for the payout schedule for this contest.'},
        ];
      } else if (this.league.sport == 'NFL') {
        tournamentText = [
          {text: 'Build a ' + numPlayers + '-player roster while staying within the salary cap.'},
          {text: 'As games are played, salary and game opportunities are consumed.'},
          {text: 'Contest results will be determined by the total points accumulated by each player (scoring rules summarized below).'},
          {text: 'See the Prizes section for the payout schedule for this contest.'},
        ];
        dailyText = tournamentText;
      }

      if (this.league.ctype === 'Tournament' && !this.league.isGuaranteed) {
        tournamentText.push({text: 'Non-Guaranteed contests do not start unless they are filled by the contest start time'});
        tournamentText.push({text: 'If the contest does not fill you will be refunded your entry fee'});
        dailyText.push({text: 'Non-Guaranteed contests do not start unless they are filled by the contest start time'});
        dailyText.push({text: 'If the contest does not fill you will be refunded your entry fee'});
      }

      const h2hText = [
        {text: 'Participate in a live snake draft to populate a ' + numPlayers + ' player lineup', tooltipKey: 'LeagueModal_H2H_Draft'},
        {text: 'Be aware of the game limits by position and draft accordingly', tooltipKey: 'LeagueModal_H2H_GameLimits'},
        {text: 'Post draft, teams are randomly paired for the first week of competition', tooltipKey: 'LeagueModal_H2H_PostDraft'},
        {text: 'Teams only receive 2 swaps per week. Use them wisely', tooltipKey: 'LeagueModal_H2H_Swaps'},
        {text: 'Bench players as needed'},
        {text: 'Accumulate the most fantasy points over 7 days to win'},
        {text: 'See the Prizes section for payout schedule for the contest'},
      ];

      if (!this.league) {
        return [];
      }
      if (this.league.ctype === 'Tournament') {
        return this.league.format === 'DAILY' ? dailyText : tournamentText;
      }

      return h2hText;
    },
  },
};
</script>

<style lang="scss" scoped>
.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 10px 15px;
  background: var(--obcolor-background-4);
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
}

.componentContent {
  padding: 10px 15px !important;
  margin: 0 !important;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.overviewInfoTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  width: 110px;
}

::v-deep ul {
  padding: 0;
  margin-left: 17px;
  margin-top: 0;
  margin-bottom: 0;
  li:not(:last-child) {
    margin-bottom: 4px;
  }
}

</style>