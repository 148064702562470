import ObGeolocationApi from '@/api/ObGeolocationApi';
import moment from 'moment';
import store from '@/store';

const ERROR_LICENSE_EXPIRED = 606;
const ERROR_LICENSE_INVALID = 608;

/**
 * Loads the GeoComply SDK, if not already loaded
 */
export function initGeoComply() {
  // Only load SDK if it hasn't already been loaded
  if (window.GcHtml5) {
    return;
  }
  const script = document.createElement('script');
  script.src = getGeoComplySDK();
  document.body.appendChild(script);
}

/**
 * Get production or staging SDK based on current hostname
 * @return {String}
 */
function getGeoComplySDK() {
  const prodURL = 'https://cdn.geocomply.com/240/gc-html5.js';
  const devURL = 'https://stg-cdn.geocomply.com/240/gc-html5.js';
  switch (location.hostname) {
    case 'localhost':
    case 'ownersbox04.appspot.com':
    case 'ownersbox07.appspot.com':
    case 'obtestenv.com':
    case 'app.obtestenv.com':
    case 'obdevenv.com':
    case 'app.obdevenv.com':
      return devURL;
  }
  return prodURL;
}

/**
 * Gets GeoComply license string from the backend
 * Stores the json object which includes the expiry timestamp
 * @param {Boolean} forceUpdate
 * @return {Promise}
 */
function getLicense(forceUpdate) {
  return new Promise((resolve) => {
    ObGeolocationApi.getLicense(forceUpdate)
        .then((response) => {
          if (response.license) {
            saveLicenseObject(response);
            resolve(response);
          } else {
            saveLicenseObject(null);
            resolve(null);
          }
        })
        .catch((_error) =>{
          resolve(null);
        });
  });
}

/**
 * Saves the license data locally to be reused on next location request (if not expired)
 * @param {JSON} licenseObj
 */
function saveLicenseObject(licenseObj) {
  store.commit('setGeoComplyLicense', licenseObj);
}

/**
 * Checks expiry to see if we need to fetch a new license
 * @param {JSON} license
 * @return {Boolean}
 */
function isExpired(license) {
  if (!license?.expiry || !license?.license) {
    return true;
  }
  return license.expiry < new Date().getTime();
}

/**
 * Helper method for APIs to setup the geolocation header
 * @param {String} reason
 * @param {String} gameType
 * @param {Boolean} preventHints
 * @return {JSON}
 */
export async function getGeopacketConfig(reason, gameType, preventHints) {
  const config = {};
  const geopacket = await getGeopacket(reason, gameType, preventHints);
  if (geopacket) {
    config.headers = {'Ownersbox-Location': geopacket};
  }
  return config;
}

/**
 * Returns a string for lookup based on the reason and game type of a geopacket
 * @param {String} reason
 * @param {String} gameType
 * @return {String}
 */
export function geoPacketKey(reason, gameType) {
  // Null reasons on backend default to Periodic
  if (reason == null) {
    reason = 'Periodic';
  }
  let key = reason;
  if (gameType) {
    key = key + '_' + gameType;
  }
  return key;
}

/**
 * Helper method to reset cached geopacket at a given key
 * @param {String} reason
 * @param {String} gameType
 */
export function clearCachedGeopacket(reason, gameType) {
  const key = geoPacketKey(reason, gameType);
  const cachedGeoPackets = store.getters.geoPackets;
  const cachedPacketExpiries = store.getters.geoPacketExpiry;
  delete cachedGeoPackets[key];
  delete cachedPacketExpiries[key];
  store.commit('setGeoPackets', cachedGeoPackets);
  store.commit('setGeoPacketExpiry', cachedPacketExpiries);
}

/**
 * Helper method to reset all cached geopackets
 */
export function clearCachedGeopackets() {
  store.commit('setGeoPackets', {});
  store.commit('setGeoPacketExpiry', {});
}

/**
 * Helper method to get a geopacket from the cache
 * @param {String} key
 * @return {Object}
 */
export function getCachedGeoPacket(key) {
  const cachedGeoPackets = store.getters.geoPackets;
  return cachedGeoPackets ? cachedGeoPackets[key] : null;
}

/**
 * Helper method to get a geopacket from the cache, but only if a valid one exists
* @param {String} reason
 * @param {String} gameType
 * @return {Object}
 */
export function validCachedGeoPacket(reason, gameType) {
  const key = geoPacketKey(reason, gameType);
  const cachedPacketExpiries = store.getters.geoPacketExpiry;
  const expiryTs = cachedPacketExpiries[key];
  const curTs = moment().valueOf();
  const cachedPacket = getCachedGeoPacket(key);

  if (cachedPacket && expiryTs && expiryTs > curTs) {
    return cachedPacket;
  }
  return null;
}

/**
 * Given a gametype and a geopacket, get its expiry date and save the packet and expiry to local storage
 * @param {String} reason
 * @param {String} gameType
 * @param {String} geopacket
 * @param {Object} fetchedTime
 */
export async function saveGeoPacketToCache(reason, gameType, geopacket, fetchedTime) {
  clearCachedGeopacket(reason, gameType);
  try {
    const response = await ObGeolocationApi.getGeopacketExpiry(geopacket);
    if (!response?.geopacketExpirySeconds) {
      return;
    }

    // Update from store in case another function changed these in the meantime
    const cachedGeoPackets = store.getters.geoPackets;
    const cachedPacketExpiries = store.getters.geoPacketExpiry;

    // Set expiry to time initially fetched + expiry in seconds
    let expiryTs = fetchedTime.clone().add(response.geopacketExpirySeconds, 'seconds');
    expiryTs = expiryTs.valueOf();

    // Update geopackets/expiries, commit to store
    const key = geoPacketKey(reason, gameType);
    cachedPacketExpiries[key] = expiryTs;
    cachedGeoPackets[key] = geopacket;
    store.commit('setGeoPackets', cachedGeoPackets);
    store.commit('setGeoPacketExpiry', cachedPacketExpiries);
    return;
  } catch (e) {
    clearCachedGeopacket(reason, gameType);
    return;
  }
}

/**
 * Gets the GeoComply geopacket, which is decrypted on the backend
 * @param {String} reason
 * @param {String} gameType
 * @param {Boolean} preventHints
 * @return {String}
 */
export async function getGeopacket(reason, gameType, preventHints) {
  // UserId is required
  const userId = store.getters.userId;
  if (!userId) {
    return null;
  }

  // Get saved license, if it is invalid or expired, get a new one from the backend
  let licenseObj = store.getters.geoComplyLicense;
  if (isExpired(licenseObj)) {
    licenseObj = await getLicense(false);
  }
  if (isExpired(licenseObj)) {
    return null;
  }

  // GeoComply sdk must exist to get geopacket
  if (!window.GcHtml5) {
    return null;
  }

  const cachedPacket = validCachedGeoPacket(reason, gameType);
  if (cachedPacket != null) {
    return cachedPacket;
  }

  // Attempt to get geopacket
  let result = await sendGeoComplyRequest(licenseObj.license, userId, reason, gameType, preventHints);
  // Save time that packet was fetched for timeout purposes
  let fetchedTime = moment();

  // If we get a license error, force the backend to request a new license and try again
  if (result.retry) {
    licenseObj = await getLicense(true);
    if (!isExpired(licenseObj)) {
      result = await sendGeoComplyRequest(licenseObj.license, userId, reason, gameType, preventHints);
      // Save time that packet was fetched for timeout purposes
      fetchedTime = moment();
    }
  }

  await saveGeoPacketToCache(reason, gameType, result.geopacket, fetchedTime);

  // If the 2nd attempt fails, continue without geopacket
  return result.geopacket;
}

/**
 * Initialize the request to get geopacket data
 * @param {String} license
 * @param {String} userId
 * @param {String} reason
 * @param {String} gameType
 * @param {Boolean} preventHints
 * @return {JSON}
 */
function sendGeoComplyRequest(license, userId, reason, gameType, preventHints) {
  return new Promise((resolve) => {
    const geoClient = GcHtml5.createClient();

    geoClient.events.on('success', function(geopacket) {
      const responseObj = {geopacket: geopacket};
      resolve(responseObj);
    }).on('failed', function(code, message) {
      // If any errors occur, clear out the license data
      // If the license is expired or invalid, trigger a retry
      saveLicenseObject(null);
      const responseObj = {geopacket: null};
      responseObj.retry = code == ERROR_LICENSE_EXPIRED || code == ERROR_LICENSE_INVALID;
      clearCachedGeopacket(gameType);
      resolve(responseObj);
    });

    // Reason values: Login, Deposit, GamePlay, Periodic
    if (!reason) {
      reason = 'Periodic';
    }
    geoClient.setLicense(license);
    geoClient.setUserId(userId);
    geoClient.setReason(reason);

    // In some cases we don't want the GeoComply hints ui to trigger
    // If preventHints value is null, we default to showing hints
    geoClient.allowHint(preventHints ? false : true);

    // GeoComply applies different location restrictions based on game type
    // This is applied through a custom field, this can be blank for deposits or checkes not tied to a contest entry
    if (gameType) {
      geoClient.customFields.set('identifier_type', gameType);
    }

    geoClient.request();
  });
}