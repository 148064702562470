<template>
  <div class="pendingTransactions">
    <div class="playerRow">
      <div class="addDropButton redText"><i class="fal fa-minus-circle"></i></div>
      <div class="playerInfoContainer">
        <div class="position">{{ toDrop.lineupPosition }}</div>
        <PlayerHeadshot size="35"
          class="playerHeadshot" :id="toDrop.id"
          :sport="sport" :team="toDrop.teamAlias"
          :showLogo="true"
        />
        <div class="nameContainer">
          <div class="playerName">
            <PlayerStatus height="12" :status="toDrop.status" />
            {{ toDrop.name.longName() }}
          </div>
          <div class="gameInfo">
            {{ playerNextGameText(toDrop) }}
          </div>
        </div>
      </div>
      <div class="statSection ptsSection" :class="playerProjClass(toDrop)">
        {{ playerNextGameProj(toDrop) }}
      </div>
      <div class="statSection salarySection">
        {{ toDrop.salary.formatMoney() }}
      </div>
    </div>

    <div class="playerDivider"></div>

    <div class="playerRow addPlayer">
      <div class="addDropButton greenText"><i class="fal fa-plus-circle"></i></div>
      <div class="playerInfoContainer">
        <div class="position">{{ toDrop.lineupPosition }}</div>
        <PlayerHeadshot size="35"
          class="playerHeadshot" :id="toAdd.id"
          :sport="sport" :team="toAdd.teamAlias"
          :showLogo="true"
        />
        <div class="nameContainer">
          <div class="playerName">
            <PlayerStatus height="12" :status="toAdd.status" />
            {{ toAdd.name.longName() }}
          </div>
          <div class="gameInfo">
            {{ playerNextGameText(toAdd) }}
          </div>
        </div>
      </div>
      <div class="playerCol statSection ptsSection" :class="playerProjClass(toDrop)">
        {{ playerNextGameProj(toAdd) }}
      </div>
      <div class="statSection salarySection"
        :class="{cantAfford: !canAffordToAdd(toAdd)}"
      >
        {{ toAdd.salary.formatMoney() }}
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';

export default {
  props: {
    swapPlayers: {type: Object, default: null},
    selectedSlate: {type: Object, default: null},
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  computed: {
    toAdd() {
      return this.swapPlayers.add;
    },

    toDrop() {
      return this.swapPlayers.drop;
    },

    sport() {
      return this.selectedSlate.sport;
    },
  },

  methods: {
    playerNextGame(player) {
      if (!player?.gamesByWeek || !player.gamesByWeek.length) {
        return null;
      }

      let nextGame = null;
      for (const game of player.games) {
        if (game.game && game.ts) {
          nextGame = game;
          break;
        }
      }

      return nextGame;
    },

    playerNextGameText(player) {
      const nextGame = this.playerNextGame(player);
      let gameText = '';
      let gameName = nextGame.game;

      if (!nextGame) {
        return null;
      }

      if (nextGame.game.charAt(0) != '@') {
        gameText = 'vs ';
      } else {
        gameText = '@ ';
        gameName = gameName.slice(1);
      }

      gameName = this.$TeamInfo.getDisplayAlias(this.sport, gameName);

      gameText = gameText + gameName + ' ' + this.$moment(nextGame.ts).format('ddd h:mma');
      return gameText;
    },

    playerNextGameProj(player) {
      const nextGame = this.playerNextGame(player);
      if (!nextGame) {
        return 0;
      }

      return nextGame.proj;
    },

    playerProjClass(player) {
      const proj = this.playerNextGameProj(player);
      if (proj > 0) {
        return 'blueText';
      } else if (proj < 0) {
        return 'redText';
      }
    },

    canAffordToAdd(player) {
      if (!this.toDrop) {
        return true;
      }

      return player.salary <= this.toDrop.salary;
    },

    formatMoney(value) {
      if (!value) {
        return '$0';
      }
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value.toFixed(2)).removeCentsIfZero();
    },
  },
};
</script>

<style lang="scss" scoped>
.pendingTransactions {
  display: flex;
  align-items: center;
  height: 60px;
  margin-top: 10px;
}

.playerRow {
  height: 50px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex: 1;
}

.playerDivider {
  height: 100%;
  width: 2px;
  background: var(--obcolor-background-4);
  margin: 0 20px;
}

.addDropButton {
  font-size: 20px;
}

.nameContainer {
  margin-left: 10px;
  .playerName {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  .gameInfo {
    margin-top: 3px;
    margin-left: 15px;
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }
}

.playerInfoContainer {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 15px;

  .position {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    width: 40px;
    margin-right: 10px;
  }
}

.statSection {
  text-align: center;
  font-size: 16px;
  font-weight: bold;

  &.ptsSection {
    padding-right: 30px;
    color: var(--obcolor-font-secondary);
    font-weight: normal;
  }

  &.salarySection {
    color: var(--obcolor-green);

    &.cantAfford {
      color: var(--obcolor-red);
    }
  }
}

.greenText {
  color: var(--obcolor-green);
}

.redText {
  color: var(--obcolor-red);
}
</style>