// Season Summary Stats
// Controls the top overall summary for season page

// Season Summary
export const SEASON_NHL_SKATER = [
  {
    label: 'SKATER',
    stats: [
      {label: 'GP', path: 'skater.GP', tooltip: 'Games Played'},
      {label: 'TOI', path: 'skater.TOI', tooltip: 'Time on Ice'},
      {label: 'G', path: 'skater.G', tooltip: 'Goals'},
      {label: 'A', path: 'skater.A', tooltip: 'Assists'},
      {label: 'PTS', path: 'skater.PTS', tooltip: 'Points'},
      {label: '+/-', path: 'skater.plus_minus', tooltip: '+/-'},
      {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots'},
      {label: 'PIM', path: 'skater.PIM', tooltip: 'Penalty Minutes'},
      {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal'},
      {label: 'PPG', path: 'skater.PPG', tooltip: 'Power Play Goals'},
      {label: 'PPA', path: 'skater.PPA', tooltip: 'Power Play Assists'},
      {label: 'SHG', path: 'skater.SHG', tooltip: 'Short Handed Goals'},
      {label: 'SHA', path: 'skater.SHA', tooltip: 'Short Handed Assists'},
      // { label: 'GWG', path: '', tooltip: 'Game-Winning Goals' },
    ],
  },
];

export const SEASON_NHL_GOALIE = [
  {
    label: 'GOALTENDER',
    stats: [
      {label: 'GP', path: 'skater.GP', tooltip: 'Games Played'},
      {label: 'TOI', path: 'skater.TOI', tooltip: 'Time on Ice'},
      {label: 'W', path: 'goalie.W', tooltip: 'Wins'},
      {label: 'L', path: 'goalie.L', tooltip: 'Losses'},
      {label: 'OTL', path: 'goalie.OTL', tooltip: 'Overtime Losses'},
      {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts'},
      {label: 'GA', path: 'goalie.GA', tooltip: 'Goals Against'},
      {label: 'GAA', path: 'goalie.GAA', tooltip: 'Average Goals Against'},
      {label: 'SA', path: 'goalie.SA', tooltip: 'Shots Against'},
      {label: 'SV', path: 'goalie.SV', tooltip: 'Saves'},
      {label: 'SV%', path: 'goalie.SVPct', tooltip: 'Save Percentage'},
    ],
  },
];
