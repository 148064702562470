<template>
  <div ref="tooltipPosition" class="positioningAnchor">
    <div class="prizesContainer" :style="tooltipStyle">
      <div class="prizeArrow"></div>
      <div class="prizesBox">
        <span v-for="(reward, index) in rewards.rewardDetails" :key="index"
          :class="{challengeComplete: challengeComplete}"
        >
          <img v-if="getRewardIcon(reward)" :src="getRewardIcon(reward)" alt="Rewards"
            class="rewardIcon"
          >
          {{ reward.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {getRewardIcon} from '@/utils/shared/BattlePassUtils';

export default {
  props: {
    rewards: {type: Object, default: null},
    challengeComplete: {type: Boolean, default: null},
  },

  data() {
    return {
      tooltipStyle: '',
    };
  },

  mounted() {
    this.setTooltipStyle();

    document.addEventListener('scroll', this.setTooltipStyle);
    window.addEventListener('resize', this.setTooltipStyle);
  },

  destroyed() {
    document.removeEventListener('scroll', this.setTooltipStyle);
    window.removeEventListener('resize', this.setTooltipStyle);
  },

  methods: {
    getRewardIcon,

    setTooltipStyle() {
      if (!this.$refs?.tooltipPosition) {
        return;
      }

      const rect = this.$refs.tooltipPosition.getBoundingClientRect();

      this.tooltipStyle = 'left: ' + (rect.left - 5) + 'px; top: ' + rect.top + 'px;';
    },
  },
};
</script>

<style lang="scss" scoped>
.positioningAnchor {
  height: 0;
  width: 0;
}

.prizeArrow {
  position: absolute;
  top: 0;
  width: 1px;
  border-bottom: 8px solid var(--obcolor-background-2);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.prizesContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.prizesBox {
  position: absolute;
  box-sizing: border-box;
  top: 5px;
  right: 10px;
  z-index: 9999;
  padding: 4px;
  background: var(--obcolor-background-2);
  box-shadow: 0 0 2px rgba(0, 0, 0, .33);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  > span {
    display: flex;
    align-items: center;
  }

  img {
    padding-right: 3px;
  }
}

.rewardIcon {
  width: 16px;
  padding-left: 3px;
}
</style>