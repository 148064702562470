import Api from '@/api/ObBaseApi';

export default {
  getSportLeaders(sport) {
    return Api()
        .get('/fsp/stats/getSportLeaders?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },
};
