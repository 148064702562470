<template>
  <tr @click.stop="openPlayerModal(entry)" :style="gameCounts ? '' : 'color: red;'">
    <td style="min-width: 250px; width: 250px; max-width: 250px;">
      <MatchupPlayerInfo :league="league" :entry="entry" :isMyTeam="isMyTeam"
        :showSwap="dropped"
      />
    </td>

    <template v-for="(category, index) in rosterScoringStats">
      <td style="height: 76px;" v-for="(stat, index1) in category.stats"
        :key="index + '_' + index1" :class="{endSection: index1 == category.stats.length - 1}"
      >
        <div>
          <span>{{ playerStats[stat.path] || '-' }}</span>
          <span v-if="stat.denom && playerStats[stat.denom]">/{{ playerStats[stat.denom] || '-' }}</span>
        </div>
      </td>
    </template>

    <td class="totalPoints" :style="gameCounts ? '' : 'color: red;'">{{ totalPoints }}</td>
    <td class="posGroupEmpty" v-if="dropped"></td>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import MatchupPlayerInfo from '@/views/Contest/MatchupPlayerInfo';
import EventBus from '@/event-bus';

export default {
  props: {
    entry: Object,
    league: Object,
    selectedDay: Number,
    isMyTeam: Boolean,
    dropped: Boolean,
    playedGames: Object,
    selectedFilterIndex: Number,
  },

  data() {
    return {
      totalPoints: '0',
      playerStats: {},
      gameCounts: true,
    };
  },

  components: {
    MatchupPlayerInfo,
  },

  computed: {
    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.league.sport);
    },

    rosterScoringStats() {
      const statList = [];
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return statList;
    },
  },

  watch: {
    selectedDay(to, from) {
      this.setupData();
    },
  },

  created() {
    this.setupData();
  },

  methods: {
    openPlayerModal(entry) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', entry.id, this.league.sport, this.league.id);
    },

    setupData() {
      const scoringStats = this.$ScoringStats.getMatchupSummaryStatsList(this.league.sport);
      this.playerStats = {};
      this.totalPoints = '0';
      this.gameCounts = true;

      // Setup Week Totals, including only games that count towards your total
      if (this.selectedDay == null) {
        this.gameCounts = true;
        this.setupWeekTotals(this.entry.fspGames, scoringStats);
        return;
      }

      // Find game data for specified day
      const selectedIndex = this.selectedDay - 1;
      const games = this.entry.fspGames;
      if (games == null) {
        return;
      }
      const game = games[selectedIndex];
      if (game == null) {
        return;
      }

      // Determine if game counts (for color highlight)
      const gameKey = this.entry.id + '_' + game.id;
      if (!this.playedGames[gameKey]) {
        this.gameCounts = false;
      }

      // Total fantasy points for this day
      if (game.ObPoints) {
        this.totalPoints = game.ObPoints.total;
      }

      // For each stat find value
      for (const statPath of scoringStats) {
        this.playerStats[statPath] = this.getValue(game.stats, statPath, '0');
      }
    },

    setupWeekTotals(fspGames, scoringStats) {
      // For each game, check if it counts, apply totals
      if (fspGames == null) {
        return '0';
      }

      const statTotals = {};
      let fptsTotal = new Decimal(0);

      for (const gameKey in fspGames) {
        const game = fspGames[gameKey];
        if (game == null) {
          continue;
        }
        if (!this.playedGames[this.entry.id + '_' + game.id]) {
          continue;
        }
        // Game counts, add stats
        const pointsToAdd = game.ObPoints ? game.ObPoints.total : 0;
        fptsTotal = fptsTotal.plus(new Decimal(pointsToAdd));

        // Get total for each stat
        for (const statPath of scoringStats) {
          let curTotal = statTotals[statPath] || 0;
          curTotal += parseFloat(this.getValue(game.stats, statPath, '0'));
          statTotals[statPath] = curTotal;
        }
      }

      this.playerStats = statTotals;
      this.totalPoints = Number(fptsTotal);
    },

    getValue: function(json, path, defaultValue) {
      if (defaultValue === undefined) {
        defaultValue = '-';
      }
      if (json == null || path == null) {
        return defaultValue;
      }
      try {
        return path.split('.').reduce(function(obj, i) {
          if (obj[i] == null || obj[i] == '') {
            return defaultValue;
          }
          return obj[i];
        }, json);
      } catch (e) {
        // Do nothing
      }
      return defaultValue;
    },
  },
};
</script>

<style lang="scss" scoped>
table tr td {
  padding: 0;
  min-width: 55px;
  white-space: nowrap;
}

tr:not(.heading) {
  cursor: pointer;
}

tr:not(.heading):hover {
  background: var(--obcolor-background-2) !important;
}

.totalPoints {
  font-weight: bold;
  font-size: 12px;
  color: var(--obcolor-ob-blue);
  width: 73px;
  max-width: 73px;
  min-width: 73px;
  box-sizing: border-box;
}

.posGroupEmpty {
  min-width: 30px;
  max-width: 30px;
  width: 30px; box-sizing: border-box;
}

.endSection {
  border-right: 1px solid var(--obcolor-background-2);
}
</style>