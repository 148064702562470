<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 413px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
    bodyStyle="padding-bottom: 0;" :disableClickaway="true"
  >
    <div slot="body" class="confirmBody">
      <h2>Confirm</h2>
      <div class="textContent">
        <div>Are you sure you want to redeem</div>
        <div>
          <div class="blueText">
            <img src="@/assets/icons/ownersbucks.png" alt="ownersbucks">
            {{ roundedOBucks }}
          </div>
          to get {{ ticketQuantity }}x
          <div class="ticketText">
            <img src="@/assets/icons/ticket.png" alt="tickets">
            ${{ roundedTicketValue }} Ticket{{ pluralTicketLetter }}
          </div>
          ?
        </div>
      </div>
    </div>
    <div slot="footer" class="modalButtonContainer">
      <button class="ob-btn-grey" @click="closeModal()">Cancel</button>
      <button class="ob-btn" @click="confirmModal()">Confirm</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      ownersbucks: null,
      ticketQuantity: null,
      ticketValue: null,
    };
  },
  created() {
    EventBus.$on('OPEN_REDEEM_CONFIRM_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('OPEN_REDEEM_CONFIRM_MODAL', this.openModal);
  },
  computed: {
    roundedOBucks() {
      return Math.round(this.ownersbucks * 100) / 100;
    },

    roundedTicketValue() {
      return Math.round(this.ticketValue * 100) / 100;
    },

    pluralTicketLetter() {
      if (this.ticketQuantity > 1) {
        return 's';
      }

      return '';
    },
  },
  methods: {
    resetData() {
      this.ownersbucks = null;
      this.ticketQuantity = null;
      this.ticketValue = null;
    },

    openModal(ownersbucks, ticketQuantity, ticketValue) {
      this.ownersbucks = ownersbucks;
      this.ticketQuantity = ticketQuantity;
      this.ticketValue = ticketValue;
      this.isOpen = true;
    },
    confirmModal() {
      this.isOpen = false;
      this.resetData();
      this.$emit('confirm');
    },
    closeModal() {
      this.isOpen = false;
      this.resetData();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Modals/modals.scss';

.confirmBody {
  flex: 1;
  padding: 30px 30px 0 30px;
  box-sizing: border-box;
  text-align: left;

  h2 {
    line-height: 20px;
    margin-bottom: 10px;
  }

  .textContent {
    line-height: 30px;

    > div {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }
}
.confirmFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.blueText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;

  img {
    height: 11px;
  }
}

.ticketText {
  color: var(--obcolor-ticket);
  font-weight: bold;
  margin-right: -4px;

  img {
    height: 14px;
    margin-bottom: -1px;
  }
}

.modalButtonContainer {
  margin-top: 20px;
}
</style>