<template>
  <div class="ticketField" @click="ticketClicked">
    <div class="imgField">
      <img :src="imgPath" :alt="contestTypeName">
    </div>
    <div class="textField">
      <div class="ticketTitle">
        <img :src="require('@/assets/icons/ticket.png')" class="ticketsIcon" alt="Tickets Icon">
        {{ ticketTitle }}
      </div>
      <div class="ticketBody">
        <div>{{ ticketBodyText }}</div>
      </div>
      <div class="expiryCode">
        <div>Code: {{ ticket.id }}</div>
        <div class="expiry">{{ ticketExpiryText }}</div>
      </div>
    </div>
    <div class="btnField" v-if="showButton">
      <div class="applyBtn ob-btn">
        Apply Ticket
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    ticket: {type: Object, default: null},
    showButton: {type: Boolean, default: false},
  },

  computed: {
    ...mapState(['theme']),

    contestTypeName() {
      let contestTypeName = '';
      switch (this.ticket.contestType) {
        case 'SalaryCap':
          contestTypeName = 'Salary Cap';
          break;
        case 'LIGHTNING_LINEUPS':
          contestTypeName = 'Lightning Lineups';
          break;
        case 'PROPS':
          contestTypeName = 'Player Picks';
          break;
        default:
          break;
      }

      return contestTypeName;
    },

    contestTypeNameSpace() {
      if (this.contestTypeName != '') {
        return this.contestTypeName + ' ';
      }
      return '';
    },

    ticketTitle() {
      let sportText = '';
      if (this.ticket.sport != '') {
        sportText = this.ticket.sport + ' ';
      }
      return this.ticket.fee + ' ' + sportText + this.contestTypeNameSpace + 'Ticket';
    },

    ticketBodyText() {
      let sportText = '';
      if (this.ticket.sport != '') {
        sportText = this.ticket.sport + ' ';
      }
      return 'Valid for a single entry in a ' + this.ticket.fee + ' ' + sportText + this.contestTypeNameSpace + 'Contest.';
    },

    ticketExpiryText() {
      if (!this.ticket.expiryDate) {
        return '';
      }
      return 'Expires ' + this.$moment(this.ticket.expiryDate).format('MMMM D, YYYY');
    },

    imgPath() {
      if (this.ticket.contestType === 'LIGHTNING_LINEUPS') {
        return require('@/assets/contestbadge/lightning_lineups.png');
      }
      if (this.ticket.contestType === 'PROPS') {
        return require('@/assets/contestbadge/player_picks.png');
      }
      if (this.ticket.sport != '') {
        return require('@/assets/contestbadge/' + this.ticket.sport + '.png');
      }
      if (this.ticket.contestType === 'SalaryCap') {
        return require('@/assets/contestbadge/salarycap.png');
      }
      if (this.theme == 'dark') {
        return require('@/assets/obicon_white.png');
      }
      return require('@/assets/obicon_black.png');
    },
  },

  methods: {
    ticketClicked() {
      this.$emit('ticketClicked', this.ticket);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticketField {
  display: flex;
  height: 90px;
  width: 100%;
  cursor: pointer;
  background: var(--obcolor-background-4);

  &:not(:first-child) {
    border-top: 1px solid var(--obcolor-background-2);
  }

  &:hover {
    background: var(--obcolor-background-3);
  }
}

.imgField, .textField {
  padding: 10px;
}

.imgField {
  width: 60px;
  border-right: 1px solid var(--obcolor-background-2);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
  }
}

.textField {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 5px;

  .ticketTitle {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    white-space: break-spaces;
  }

  .ticketBody {
    font-weight: normal;
    white-space: break-spaces;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .expiryCode {
    display: flex;
    justify-content: space-between;
    color: var(--obcolor-font-secondary);
    font-size: 12px;
    font-weight: normal;
    width: 100%;

    .expiry {
      font-style: italic;
    }
  }
}

.btnField {
  border-left: 1px solid var(--obcolor-background-2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;

  .applyBtn {
    width: 110px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    white-space: nowrap;
  }
}

.ticketsIcon {
  height: 12px;
  margin-top: -2px;
  margin-right: 5px;
}
</style>