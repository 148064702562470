<template>
  <div class="queueContainer">
    <table aria-label="Draft Queue">
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th class="agCol" v-if="sport != 'NFL'" v-tooltip.bottom="{content: 'Projected Games', class:'ObTooltipHeat'}">PG</th>
        <th class="agCol" v-else v-tooltip.bottom="{content: 'Opponent', class:'ObTooltipHeat'}">VS</th>
        <th class="avgCol" v-if="sport != 'NFL'" v-tooltip.bottom="{content: 'Projected Average', class:'ObTooltipHeat'}">pAVG</th>
        <th class="avgCol" v-else v-tooltip.bottom="{content: 'Projected Points', class:'ObTooltipHeat'}">PROJ</th>
        <th class="queueBox"></th>
      </tr>

      <!-- Player Row -->
      <template v-if="pickQueue != null">
        <draggable v-model="pickQueue.players" tag="tbody" @end="changeQueueOrder">
          <tr v-for="(player, index) in pickQueue.players" :key="index" @click="selectPlayer(player)"
            v-show="pickedPlayerIds[player.id] === undefined"
          >
            <td class="gripCol">
              <i class="fad fa-grip-vertical"></i>
            </td>
            <td class="posCol">{{ player.draftPos }}</td>
            <td class="statusCol">
              <PlayerStatus :status="player.status" style="margin-top: -11px;" />
            </td>
            <td class="nameCol">
              <div class="playerName">
                <span @click.stop @click="openPlayerCard(player)" class="ob-link"
                  style="margin-right: 6px;"
                >{{ player.name.shortName() }}</span>
                <i v-if="player.heat === 'Cold'" style="color: var(--obcolor-player-cold);" class="fas fa-icicles"
                  v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
                ></i>
                <i v-else-if="player.heat === 'Hot'" style="color: var(--obcolor-player-hot);" class="fab fa-hotjar"
                  v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
                ></i>
              </div>
              <div class="playerInfo">
                <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(sport, player.team) }}</span>
                <span>#{{ player.number }}</span>
                <span>{{ player.lineInfo }}</span>
              </div>
            </td>

            <!-- Projected Games, unless NFL -->
            <td v-if="sport != 'NFL'" class="agCol">{{ player.wStats[0].pg }}</td>
            <td v-else class="agCol">
              <div style="white-space: nowrap; display: flex; align-items: center; justify-content: center; text-align: center;">
                <div v-if="player.wInfo[0].home == false && player.wInfo[0].opp" style="margin-right: 2px; margin-left: -2px;">@</div>
                <img v-if="player.wInfo[0].opp" style="width: 24px; height: 24px;" :class="{invalidGameLogo: player.wInfo[0].excluded == true}"
                  :src="getTeamLogo(player.wInfo[0].opp)" :alt="player.wInfo[0].opp"
                >
                <template v-else>BYE</template>
              </div>
            </td>

            <td class="avgCol">{{ roundStat(player.wStats[0].avg) }}</td>
            <td class="queueBox" @click="removeFromQueue(player)">
              <i class="fal fa-minus-circle"></i>
            </td>
          </tr>
        </draggable>
      </template>
    </table>
    <!-- Empty -->
    <div class="queueInfo" v-show="isListVisible === false">
      <div>Click the <i class="fal fa-plus-circle"></i> button to add a player to your queue.</div>
      <div class="newParagraph">The top valid player in your queue will be selected if the time runs out during your turn.</div>
      <div class="newParagraph">You can drag and drop players to reorder your queue.</div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import EventBus from '@/event-bus';
import PlayerStatus from '@/components/PlayerStatus.vue';

export default {

  components: {
    draggable,
    PlayerStatus,
  },

  props: {
    pickQueue: Object,
    sport: String,
    selectedPlayer: Object,
    roster: Object,
    pickedPlayerIds: Object,
    draftId: String,
    autopickInitial: Boolean,
    draftState: Object,
  },

  computed: {
    isListVisible() {
      let visiblePlayer;
      const self = this;
      if (this.pickQueue === null) {
        return false;
      }
      visiblePlayer = this.pickQueue.players.find((player) => {
        return !self.pickedPlayerIds[player.id];
      });
      return visiblePlayer !== undefined;
    },
  },

  methods: {
    openPlayerCard(player) {
      EventBus.$emit('DRAFT_OPEN_PLAYER_CARD', player, this.sport, this.draftState);
    },

    roundStat(stat) {
      return stat.toFixed(0);
    },

    changeQueueOrder(evt) {
      const tempPickQueue = this.pickQueue;
      for (const playerIndex in tempPickQueue.players) {
        const player = tempPickQueue.players[playerIndex];
        this.$set(tempPickQueue.ids, player.id, playerIndex);
      }
      EventBus.$emit('QUEUE_ORDER_CHANGED', tempPickQueue);
    },

    selectPlayer(player) {
      EventBus.$emit('PLAYER_SELECTED', player);
    },

    removeFromQueue(player) {
      EventBus.$emit('QUEUE_PLAYER_SELECTED', player);
    },

    getTeamLogo(team) {
      if (!this.sport || !team) {
        return '';
      }
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },
  },
};
</script>

<style lang="scss" scoped>

.queueContainer {
  padding: 4px;
}
table {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
th {
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-background-6);
}
td {
  border-top: 5px solid var(--obcolor-background-6);
  padding: 4px;
  cursor: grab;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
}
tr {
  background: var(--obcolor-background-5);
}
tr:hover {
  background: var(--obcolor-bg-hover);
}
.playerName {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}
.playerInfo {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -3px;
  color: var(--obcolor-font-secondary);
  span {
    margin-right: 5px;
  }
}
// -- Columns --
.gripCol {
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  font-size: 18px;
  padding-left: 7px;
}
.posCol {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  font-weight: bold;
}
.nameCol {
  text-align: left;
  white-space: nowrap;
}
.statusCol {
  width: 11px;
  min-width: 11px;
  max-width: 11px;
}
.agCol {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}
.avgCol {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}
.queueBox {
  font-size: 18px;
  cursor: pointer;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}
.queueBox:hover {
  color: red;
}
.queueInfo {
  color: var(--obcolor-font-secondary);
  padding: 6px;
  font-size: 13px;
  margin-top: 7px;
}
.newParagraph {
  margin-top: 10px;
}
</style>