export const PREGAME_NHL_SKATERS = [
  {
    label: 'SKATERS',
    stats: [
      {label: 'skater.G', tooltip: 'Goals', description: 'G'},
      {label: 'skater.A', tooltip: 'Assists', description: 'A'},
      {label: 'skater.plus_minus', tooltip: 'Plus/Minus', description: '+/-'},
    ],
  },
];

export const PREGAME_NHL_GOALIES = [
  {
    label: 'GOALIES',
    stats: [
      {label: 'goalie.W_L_OTL', tooltip: 'Wins-Losses-Ties', description: 'W-L-OTL'},
      {label: 'goalie.GAA', tooltip: 'Average Goals Against', description: 'GAA'},
      {label: 'goalie.SVPct', tooltip: 'Save Percentage', description: 'SV%'},
    ],
  },
];