<template>
  <div @click="$emit('click')" class="notificationEntry">
    <div style="width: 75px; min-width: 75px; margin-right: 5px; height: 100%; display: flex; justify-content: center;">
      <img v-if="iconName" class="bwIcon" :src="require('@/assets/icons/' + iconName + '.png')"
        :alt="iconName" style="width: 48px;"
      >
      <slot name="image" v-else-if="useSlot == true"></slot>
    </div>
    <div style="flex: 1;">
      <div style="display: flex; align-items: center; margin-top: -3px;">
        <div style="font-weight: bold;">{{ entry.headline }}</div>
        <div style="flex: 1;"></div>
        <div style="color: var(--obcolor-font-secondary); font-weight: bold; font-size: 12px; margin-right: 3px;">{{ getTime(entry.date) }}</div>
      </div>
      <div>{{ entry.message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entry: Object,
    iconName: String,
    useSlot: Boolean,
  },

  methods: {
    getTime(date) {
      // Show time only if posted today, otherwise show date only
      const now = this.$moment().format('MMM D YYYY');
      const newsDate = this.$moment(date);
      if (now === newsDate.format('MMM D YYYY')) {
        return newsDate.format('h:mma');
      }
      return newsDate.format('MMM D');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bwIcon {
  filter: brightness(0);
}

[data-theme="dark"] {
  .bwIcon {
    filter: brightness(100);
  }
}
</style>