<template>
  <div class="ob-page" v-if="checkingLocation == true">
    <div class="loadingBox">
      <div><ObLoading :size="90" /></div>
      <div>Verifying your location...</div>
    </div>
  </div>
  <div v-else class="ob-page container">
    <template v-if="!isMethodSelected">
      <h1>Withdraw</h1>
      <div class="withdrawAmountContainer ob-box">
        <div class="atwContainer">
          <div class="atwText">
            <span>Available to Withdraw</span>
            <Tooltip pos="top" textKey="Available_to_Withdraw" />
          </div>
          <div class="moneyText atwValue">{{ atwFormatted || '--' }}</div>
        </div>
        <div class="withdrawAmtContainer">
          <p class="depositText">Amount to be withdrawn (USD)</p>
          <div class="inputContainer">
            <i class="fal fa-dollar-sign"></i>
            <input v-model="customWithdrawalValue" ref="customInput"
              :class="{errorBorder: withdrawError}"
              placeholder="Enter Amount" v-cleave="{
                numeral: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none',
              }"
              maxlength="7"
              @blur="validateWithdrawalValue"
            >
          </div>
          <div :class="withdrawError ? 'redBox' : 'blueBox'">
            <i class="far fa-info-circle"></i>
            <div>
              You must withdraw a minimum of <span>$10</span> and withdrawals must be within your <span>available to withdraw</span> balance.
            </div>
          </div>
        </div>
      </div>
    </template>
    <WithdrawMethods @isMethodSelected="setIsMethodSelected"
      :withdrawalValue="withdrawalValue"
      :selectedAmount="selectedAmount"
      :canSelect="canWithdraw"
      :locationInfo="locationInfo"
    />
    <div class="container">
      <div v-if="!isMethodSelected" class="tosText">
        If you wish to withdraw a lower amount, please contact <a href="https://support.ownersbox.com/hc/en-us/requests/new" target="_blank" rel="noopener"
          class="ob-hyperlink"
        >Customer Service.</a>
      </div>
      <div class="tosText">
        This Withdrawal is subject to the OwnersBox <router-link class="ob-hyperlink" to="/terms" target="_blank">Terms of Service.</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ObPaymentApi from '@/api/ObPaymentApi';
import ObFinanceApi from '@/api/ObFinanceApi';
import WithdrawMethods from './WithdrawMethods.vue';
import ObLoading from '@/components/ObLoading.vue';
import Tooltip from '@/components/tooltip/Tooltip';
import {mapState} from 'vuex';

export default {
  components: {
    WithdrawMethods,
    ObLoading,
    Tooltip,
  },

  data() {
    return {
      isMethodSelected: false,
      selectedMethod: null,
      selectedAmount: 'custom',
      customWithdrawalValue: '',
      withdrawalValue: null,
      locationInfo: null,
      checkingLocation: false,
      atwFormatted: null,
      atw: null,

      withdrawError: false,
    };
  },

  created() {
    this.saveLocation();
    this.getWithdrawInformation();
  },

  computed: {
    ...mapState(['balanceValue']),

    canWithdraw() {
      const withdrawValueCents = this.withdrawalValue * 100;
      return withdrawValueCents >= 1000 && this.balanceValue >= withdrawValueCents;
    },
  },

  watch: {
    customWithdrawalValue(_newVal) {
      this.withdrawalValue = Number(_newVal.replace(/\$ /g, ''));
    },
  },

  methods: {
    setIsMethodSelected(isSelected) {
      this.isMethodSelected = isSelected;
    },

    saveLocation() {
      this.checkingLocation = true;
      ObPaymentApi.saveLocation()
          .then((response) => {
            this.checkingLocation = false;
            this.locationInfo = response;
          })
          .catch((_error) => {
            this.checkingLocation = false;
          });
    },

    getWithdrawInformation() {
      ObFinanceApi.getWithdrawInformation()
          .then((data) => {
            this.atwFormatted = data.availableToWithdrawFormatted;
            this.atw = data.availableToWithdraw;
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    // Set withdraw error colour after user is done entering amount
    validateWithdrawalValue() {
      this.withdrawError = !this.canWithdraw;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';

.withdrawAmountContainer {
  padding: 0;
  border: 0.5px solid var(--obcolor-box-border);
  overflow: hidden;
}

.atwContainer {
  background: var(--obcolor-background-page);
  padding: 20px 25px;
}

.atwText {
  display: flex;
  color: var(--obcolor-font-secondary);
  align-items: center;
  margin-bottom: 5px;
  font-size: 13px;
}

.atwValue {
  font-size: 20px;
}

.withdrawAmtContainer {
  padding: 10px 25px 10px 25px;
}

.tosText {
  text-align: center;
  margin-top: 10px;
  &:first-child {
    margin-top: 30px;
  }
}
</style>