<template>
  <div class="ob-page">
    <div class="ob-box">
      <div style="display: flex; align-items: center; font-size: 12px; white-space: nowrap;">
        <FilterButton v-for="(sp, index) in sports" :key="index" style="margin-top: 5px; font-size: 14px; margin-right: 5px;"
          :sport="sp" :label="sp" :selected="sp == sport"
          @click="changeSport(sp)"
        />
        <div style="margin-right: 50px; margin-left: 50px;">
          <div :class="!filterMyPlayers ? 'ob-btn' : 'ob-btn-grey'" @click="filterMyPlayers = false" style="border-radius: 5px 0 0 5px;">All</div>
          <div :class="filterMyPlayers ? 'ob-btn' : 'ob-btn-grey'" @click="filterMyPlayers = true" style="border-radius: 0 5px 5px 0;">My Players</div>
        </div>
        <div style="flex: 1;"></div>
        <template v-if="allScoringStats.length > 1">
          <div v-for="(section, index) in allScoringStats" :key="'opt' + index"
            style="margin-right: 5px; display: flex; align-items: center;"
            :class="index == selectedSection ? 'ob-btn' : 'ob-btn-grey'"
            @click="selectedSection = index"
          >
            {{ section.label }}
          </div>
        </template>
      </div>


      <table class="posTable" style="margin-top: 10px;" aria-label="Today's Stats">
        <tr class="statHeader" v-if="positionData.liveStats.length > 1 || true">
          <th class="endSection" colspan="4" scope="col"></th>
          <th class="endSection catHeader" v-for="(category, index2) in positionData.liveStats" :key="index2"
            :colspan="category.stats.length" scope="col"
          >
            <div style="height: 13px;">{{ positionData.liveStats.length > 1 ? category.label : '' }}</div>
          </th>
        </tr>
        <tr class="statHeader">
          <th class="posCol" scope="row"></th>
          <th style="text-align: left;" scope="row">{{ positionData.label }}</th>
          <th class="teamsCol" scope="row">Teams</th>
          <th class="endSection fptsCol" v-tooltip.bottom="{content: 'Fantasy Points', class:'ObTooltipHeat'}" scope="row">
            FPTS
          </th>
          <template v-for="(category, index2) in positionData.liveStats">
            <th v-for="(stat, index3) in category.stats" :key="index2 + '_' + index3"
              v-tooltip.bottom="{content: stat.tooltip || '', class:'ObTooltip' }"
              :class="{ endSection: index3 == category.stats.length - 1 }"
            >
              {{ stat.label }}
            </th>
          </template>
        </tr>

        <tr v-for="player in getSortedPlayers(positionData)" :key="player.id">
          <td>
            <div class="playerPos">
              <div v-if="myplayers[player.id]" class="myPlayerIcon"></div>
              <div>{{ player.position }}</div>
            </div>
          </td>
          <td>
            <div class="playerInfoField">
              <PlayerHeadshot size="40" :id="player.id" :sport="sport"
                :team="player.team" :showLogo="true" style="margin-right: 7px;"
              />
              <div class="playerName ob-link" @click.stop="openPlayerCard(player.id)">{{ player.name.shortName() }}</div>
            </div>
          </td>
          <td>
            <span v-if="myplayers[player.id]" style="color: var(--obcolor-ob-blue); font-weight: bold;">
              {{ myplayers[player.id].length }}
            </span>
            <span v-else style="color: var(--obcolor-font-secondary);">0</span>
          </td>
          <td class="endSection fptsField" :class="{ negativePts: player.stats && player.stats.fpts && player.stats.fpts.total < 0 }">
            {{ player.stats.fpts.total }}
          </td>

          <template v-for="(category, index1) in positionData.liveStats">
            <template v-for="(stat, index2) in category.stats">
              <td :key="index1 + '_' + index2" v-if="getStat(player, stat.path) != '-'"
                :class="{endSection: index2 == category.stats.length - 1}"
              >
                <span>{{ getStat(player, stat.path) }}</span>
              </td>
              <td v-else :key="index1 + '_' + index2">-</td>
            </template>
          </template>
        </tr>
      </table>

      <ObLoading v-if="boxscores == null" />

      <div class="noPlayers" v-else-if="getSortedPlayers(positionData).length == 0">No Players</div>
    </div>
  </div>
</template>

<script>
import ObSportDataApi from '@/api/ObSportDataApi';
import ObLeagueApi from '@/api/ObLeagueApi';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import EventBus from '@/event-bus';
import FilterButton from '@/components/buttons/FilterButton';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    PlayerHeadshot,
    FilterButton,
    ObLoading,
  },

  data: function() {
    return {
      sports: ['NHL', 'NFL', 'NBA', 'MLB'],
      sport: 'NFL',
      defaultSport: 'NFL',
      boxscores: null,
      listLimit: 70,
      selectedSection: 0,
      myplayers: {},
      filterMyPlayers: false,
    };
  },

  computed: {
    allScoringStats() {
      if (!this.sport) {
        return null;
      }
      return this.$ScoringStats.getAllLiveStats(this.sport);
    },

    positionData() {
      return this.allScoringStats[this.selectedSection];
    },
  },

  created() {
    this.loadBoxscores();
    this.loadMyPlayers();
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    changeSport(sp) {
      this.boxscores = null;
      this.selectedSection = 0;
      this.sport = sp;
      this.loadBoxscores();
    },

    loadBoxscores() {
      ObSportDataApi.getTodaysBoxscores(this.sport)
          .then((response) => {
            this.boxscores = response;
          });
    },

    loadMyPlayers() {
      ObLeagueApi.getMyPlayers()
          .then((response) => {
            if (response.myplayers) {
              this.myplayers = response.myplayers;
            }
          });
    },

    getSortedPlayers(posData) {
      if (!this.boxscores) {
        return [];
      }
      return this.boxscores.filter((p) => {
        if (this.filterMyPlayers && !this.myplayers[p.id]) {
          return false;
        }
        return posData.isPosition(p.position);
      })
          .sort((a, b) => {
            return b.stats.fpts.total - a.stats.fpts.total;
          })
          .slice(0, this.listLimit);
    },

    getStat(player, statPath) {
      if (!player || !player.stats || !statPath) {
        return '-';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!player.stats[playerRole]) {
        return '0';
      }
      const stat = player.stats[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },
  },

};
</script>

<style lang="scss" scoped>

.noPlayers {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  padding: 3px 5px;
}

.posTable {
  border-radius: 5px;
  min-width: calc(100% - 1px);
  overflow: hidden;
  margin-bottom: 10px;
}

.statHeader {
  background: var(--obcolor-background-2);
}

.catHeader {
  padding-top: 10px;
  padding-bottom: 0px;
  font-size: 12px !important;
}

.leftInfo.tableTitle {
  font-size: 14px;
}

.leftInfo {
  font-size: 14px;
  color: var(--obcolor-font-primary);
  padding: 12px 15px;
  padding-right: 0px;
  width: 100px;
  white-space: nowrap;
}

.tableTitle {
  color: var(--obcolor-font-secondary);
}

.gameSummary {
  display: flex;
  min-width: 95px;
  align-items: center;
}

.teamLogo {
  width: 25px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: 4px;
}

tr:nth-child(odd):not(.statHeader) {
  background: var(--obcolor-background-4);
}

tr:nth-child(even):not(.statHeader) {
  background: var(--obcolor-background-5);
}

th {
  color: var(--obcolor-font-secondary);
  padding: 10px;
}

td {
  border-top: 2px solid var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
}

th:not(.leftInfo), td:not(.leftInfo) {
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  min-width: 50px;
}

.posCol {
  width: 30px;
}

.teamsCol {
  width: 70px;
  min-width: 70px;
}
.fptsCol {
  width: 70px;
  min-width: 70px;
}

.fptsField {
  font-weight: bold;
  color: var(--obcolor-ob-blue);
}

.fptsField.negativePts {
  color: var(--obcolor-red);
}

.endSection:not(:last-child) {
  border-right: 2px solid var(--obcolor-background-6);
}

.playerPos {
  position: relative;
  height: 45px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .myPlayerIcon {
    $iconSize: 18px;
    width: $iconSize;
    height: $iconSize;
    position: absolute;
    top: calc(-#{$iconSize} / 2);
    left: calc(-#{$iconSize} / 2);
    transform: rotate(45deg);
    background: var(--obcolor-sportscolour-nba);
  }
}

.playerInfoField {
  padding: 5px;
  display: flex;
  align-items: center;
}

.playerStatus {
  margin-right: 5px;
}

.playerName {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
</style>
