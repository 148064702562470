<template>
  <div class="tooltipText">
    <template v-if="textList">
      <div v-for="(textObj, index) in textList" :key="index" class="textSection">
        <span v-if="textObj.title" class="title">{{ textObj.title }}</span>
        <span v-if="textObj.text" class="text">{{ textObj.text }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    textList: Array,
  },
};
</script>

<style lang="scss" scoped>
  .tooltipText {
    font-weight: normal;
    // Overrides things like uppercase transform on parent of tooltip
    text-transform: none;
    font-size: 12px;
    color: white;
    text-align: left;
  }

  .textSection:not(:last-child) {
    margin-bottom: 10px;
  }

  .title {
    font-weight: bold;
    margin-right: 1ex;
    white-space: nowrap;
  }
</style>