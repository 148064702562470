<template>
  <div class="ob-page" v-if="referralInfo != null">
    <!-- Promo Section -->
    <div class="ob-box" style="height: 345px; min-width: 565px; position: relative; overflow: hidden;">
      <div class="bgLogoContainer">
        <img class="bgLogoLeft" :src="require('@/assets/referrals/bg-logo-' + theme + '.png')" alt="Backgroud OwnersBox">
        <div class="bgLogoMiddle"></div>
        <img class="bgLogoRight" :src="require('@/assets/referrals/bg-logo-' + theme + '.png')" alt="Backgroud OwnersBox">
      </div>
      <div style="margin: auto; width: 580px;">
        <div style="display: flex;">
          <div class="headerText" style="margin-right: 10px; width: 520px;">
            <div style="margin-top: 25px;">Refer your friends and get 25 OwnersBucks</div>
            <div style="margin-top: 30px; margin-bottom: 30px;">
              Get OwnersBucks when your friends refer their friends!
            </div>
          </div>
          <div style="width: 200px;">
            <img :src="require('@/assets/referrals/ob-rewards-' + theme + '.png')" alt="OwnersBox Rewards" style="height: 140px;">
          </div>
        </div>
        <div>
          <div class="headerOption">
            <div>Referral Link</div>
            <input readonly type="text" :value="referralInfo.referralUrl"
              placeholder="Referral Link"
            >
            <button class="ob-btn" @click="copyToClipboard(referralInfo.referralUrl, 'link')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyLinkMessage }"></div>
          </div>
          <div class="headerOption">
            <div>Referral Code</div>
            <input readonly type="text" :value="referralInfo.referralCode"
              placeholder="Referral Code"
            >
            <button class="ob-btn" @click="copyToClipboard(referralInfo.referralCode, 'code')"><i class="fas fa-link" style="margin-right: 5px;"></i>Copy</button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyCodeMessage }"></div>
          </div>
          <div class="headerOption">
            <div>Enter any amount of email referrals separated with commas</div>
            <input v-model="referralEmails" @input="resetReferralMessage()" type="text"
              placeholder="Email"
            >
            <button v-if="sendingReferralEmails == true" disabled class="ob-btn-grey">
              <i class="fad fa-spinner-third fa-spin"></i>
              Sending
            </button>
            <button v-else-if="referralEmails != ''" class="ob-btn" @click="referFriends()"><i class="fas fa-paper-plane" style="margin-right: 7px;"></i>Send</button>
            <button v-else disabled class="ob-btn-grey"><i class="fas fa-paper-plane" style="margin-right: 7px;"></i>Send</button>
            <div v-if="referralMessage != null" class="referralTooltip" v-tooltip.bottom.notrigger="{ content: referralMessage || '', class: 'ObTooltip ob-tooltip-fadeout', visible: referralMessage != null }"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 Columns -->
    <div style="display: flex;">
      <!-- Info section -->
      <div style="width: 395px; min-width: 395px; margin-top: 10px; margin-right: 10px;">
        <div class="ob-box" style="padding: 15px;">
          <div class="subHeader" style="margin-bottom: 10px;">How Referrals Work</div>
          <!-- Referral tier diagram -->
          <div class="ob-box" style=" overflow: hidden; height: 379px; width: 100%; box-sizing: border-box; background: var(--obcolor-background-4); display: flex; padding: 0;">
            <div style="width: 70px; background: var(--obcolor-background-3);">
              <div class="referralDiagramLabel" style="height: 110px; font-weight: bold;">Player</div>
              <div class="referralDiagramLabel" style="height: 100px">
                <div style="font-weight: bold;">Tier 1</div>
                <div style="display: flex; margin-top: 2px; color: var(--obcolor-font-secondary);">
                  <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon"
                    style="margin-top: 1px;"
                  >
                  <div>25</div>
                </div>
              </div>
              <div class="referralDiagramLabel" style="height: 80px">
                <div style="font-weight: bold;">Tier 2</div>
                <div style="display: flex; margin-top: 2px; color: var(--obcolor-font-secondary);">
                  <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon"
                    style="margin-top: 1px;"
                  >
                  <div>10</div>
                </div>
              </div>
              <div class="referralDiagramLabel" style="height: 65px">
                <div style="font-weight: bold;">Tier 3</div>
                <div style="display: flex; margin-top: 2px; color: var(--obcolor-font-secondary);">
                  <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon"
                    style="margin-top: 1px;"
                  >
                  <div>5</div>
                </div>
              </div>
            </div>
            <div style="flex: 1;">
              <div class="referralDiagramIcons" style="height: 110px;">
                <UserLogo :id="referralInfo.id" class="userLogo" :size="70" />
              </div>
              <div class="referralDiagramIcons" style="height: 100px">
                <div class="referralDiagramLine" style="width: 2px; height: 20px; top: -13px"></div>
                <img :src="require('@/assets/userlogos/PresetLogo_2.png')" alt="User Logo" class="userLogo"
                  style="height: 70px"
                >
              </div>
              <div class="referralDiagramIcons" style="height: 80px">
                <div class="referralDiagramLine" style="width: 2px; height: 15px; top: -10px"></div>
                <div class="referralDiagramLine" style="width: 50%; height: 2px; top: 5px"></div>
                <img :src="require('@/assets/userlogos/PresetLogo_3.png')" alt="User Logo" class="userLogo"
                  style="height: 50px"
                >
                <img :src="require('@/assets/userlogos/PresetLogo_4.png')" alt="User Logo" class="userLogo"
                  style="height: 50px"
                >
              </div>
              <div class="referralDiagramIcons" style="height: 65px">
                <div class="referralDiagramIcons">
                  <div class="referralDiagramLine" style="width: 2px; height: 15px; top: -25px"></div>
                  <div class="referralDiagramLine" style="width: 50%; height: 2px; top: -10px"></div>
                  <img :src="require('@/assets/userlogos/PresetLogo_5.png')" alt="User Logo" class="userLogo"
                    style="height: 35px"
                  >
                  <img :src="require('@/assets/userlogos/PresetLogo_6.png')" alt="User Logo" class="userLogo"
                    style="height: 35px"
                  >
                </div>
                <div class="referralDiagramIcons">
                  <div class="referralDiagramLine" style="width: 2px; height: 15px; top: -25px"></div>
                  <div class="referralDiagramLine" style="width: 50%; height: 2px; top: -10px"></div>
                  <img :src="require('@/assets/userlogos/PresetLogo_7.png')" alt="User Logo" class="userLogo"
                    style="height: 35px"
                  >
                  <img :src="require('@/assets/userlogos/PresetLogo_8.png')" alt="User Logo" class="userLogo"
                    style="height: 35px"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="referralParagraph">
            OwnersBox has taken the traditional referral system and added a unique twist to it.
            OwnersBox rewards users whose referrals continue the referral tree.
            The longer your referral tree is, the more you’ll be earning.
          </div>
          <div class="referralParagraph">
            Tier 1 are people you directly referred to OwnersBox, once they deposit their first $25, you will earn 25 OwnersBucks.
          </div>
          <div class="referralParagraph">
            Tier 2 are referrals from your Tier 1.
            When a Tier 2 deposits $25, you earn an additional 10 OwnersBucks.
          </div>
          <div class="referralParagraph">
            Tier 3 referrals is the end of the tree and will earn you 5 OwnersBucks once they deposit $25.
          </div>
          <div class="referralParagraph">
            That means the more friends your referrals bring in, the more OwnersBucks everyone will earn!
          </div>
        </div>
      </div>
      <!-- Right section -->
      <div style="flex: 1;">
        <div class="ob-box" style="margin-top: 10px; padding: 15px; min-width: 0;">
          <div class="subHeader">Your Referrals</div>

          <!-- Tier 1 referrals table -->
          <div style="display: flex;" v-if="referralInfo.referrals && Object.keys(referralInfo.referrals).length > 0">
            <div class="ob-box referralTable" style="flex: 1; margin-right: 15px; min-width: 0;">
              <table style="width: 100%; box-sizing: border-box; table-layout: fixed;" aria-label="Referral Information">
                <tr>
                  <th style="text-align: left;">Name</th>
                  <th class="ownersbucksHeader">OwnersBucks Earned</th>
                </tr>
                <tr v-for="(referral, userId) in referralInfo.referrals" :key="userId">
                  <td style="text-align: left;">
                    <router-link class="ob-link" :to="'/profile/' + userId">{{ referral.name }}</router-link>
                  </td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referral.t1Earned ? referral.t1Earned.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
                <!-- Total -->
                <tr v-if="referralInfo.referrals && Object.keys(referralInfo.referrals).length > 1">
                  <td style="text-align: left; font-weight: bold;">Total Earned</td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referralInfo.t1Total ? referralInfo.t1Total.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
              </table>
            </div>

            <!-- Tier 2 referrals table -->
            <div class="ob-box referralTable" style="margin-right: 15px; min-width: 0;">
              <table style="width: 100%; box-sizing: border-box;" aria-label="Referral Information">
                <tr>
                  <th class="tierHeader">Tier 2</th>
                  <th class="ownersbucksHeader">OwnersBucks Earned</th>
                </tr>
                <tr v-for="(referral, userId) in referralInfo.referrals" :key="userId">
                  <td>{{ referral.t2Count || '--' }}</td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referral.t2Earned ? referral.t2Earned.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
                <!-- Total -->
                <tr v-if="referralInfo.referrals && Object.keys(referralInfo.referrals).length > 1">
                  <td></td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referralInfo.t2Total ? referralInfo.t2Total.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
              </table>
            </div>

            <!-- Tier 3 referrals table -->
            <div class="ob-box referralTable">
              <table style="width: 100%; box-sizing: border-box;" aria-label="Referral Information">
                <tr>
                  <th class="tierHeader" style="width: 65px;">Tier 3</th>
                  <th class="ownersbucksHeader">OwnersBucks Earned</th>
                </tr>
                <tr v-for="(referral, userId) in referralInfo.referrals" :key="userId">
                  <td>{{ referral.t3Count || '--' }}</td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referral.t3Earned ? referral.t3Earned.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
                <!-- Total -->
                <tr v-if="referralInfo.referrals && Object.keys(referralInfo.referrals).length > 1">
                  <td></td>
                  <td>
                    <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
                    {{ referralInfo.t3Total ? referralInfo.t3Total.removeCentsIfZero() : '0' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div v-else style="font-size: 14px; color: var(--obcolor-font-secondary)">
            You currently have no referrals. Copy the referral link above to refer a friend!
          </div>
        </div>

        <div class="ob-box" style="margin-top: 10px; padding: 15px;">
          <div class="subHeader">Referrals Pending Deposit</div>
          <div v-if="referralInfo.pendingDeposit && Object.keys(referralInfo.pendingDeposit).length > 0" class="ob-box" style="padding: 0; margin: 10px 0 0 5px; overflow: auto; background: var(--obcolor-background-4);">
            <table style="width: 100%; box-sizing: border-box;" aria-label="Referral Pending">
              <tr>
                <th style="text-align: left; width: 200px; max-width:">Name</th>
                <th style="text-align: right;">Date of Account Creation</th>
              </tr>
              <tr v-for="(pending, userId) in referralInfo.pendingDeposit" :key="userId">
                <td class="nameCell" style="text-align: left;">
                  <router-link class="ob-link" :to="'/profile/' + userId">{{ pending.name }}</router-link>
                </td>
                <td style="text-align: right;">{{ $moment(pending.createdDate).format('MMMM D, YYYY') }}</td>
              </tr>
            </table>
          </div>
          <div v-else style="font-size: 14px; color: var(--obcolor-font-secondary)">
            You currently have no referrals pending deposit. Refer more friends for more rewards!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loadingBox">
    <i class="fad fa-spinner-third fa-spin"></i>
    <div class="loadingReferrals">Loading Referrals...</div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ObAccountApi from '@/api/ObAccountApi';
import UserLogo from '@/components/UserLogo';

export default {

  components: {
    UserLogo,
  },

  computed: {
    ...mapState(['theme']),
  },

  data() {
    return {
      referralInfo: null,
      referralEmails: '',
      referralMessage: null,
      showCopyLinkMessage: false,
      showCopyCodeMessage: false,
      sendingReferralEmails: false,
      copyLinkTimeout: null,
      copyCodeTimeout: null,
      referralMessageTimeout: null,
    };
  },

  created() {
    this.loadMyReferrals();
  },

  watch: {
    referralMessage(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.referralMessageTimeout);
        this.referralMessageTimeout = setTimeout(function() {
          self.resetReferralMessage();
        }, 3000);
      }
    },

    showCopyCodeMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyCodeTimeout);
        this.copyCodeTimeout = setTimeout(function() {
          self.resetCopyCodeMessage();
        }, 3000);
      }
    },

    showCopyLinkMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyLinkTimeout);
        this.copyLinkTimeout = setTimeout(function() {
          self.resetCopyLinkMessage();
        }, 3000);
      }
    },

    referralEmails(newVal) {
      const emailList = newVal.split(',');
      if (emailList.length == 20 && newVal.charAt(newVal.length - 1) == ',') {
        this.referralEmails = newVal.substr(0, newVal.length - 1);
        this.referralMessage = 'You can send a maximum of 20 emails.';
      }
    },
  },

  methods: {

    loadMyReferrals() {
      ObAccountApi.getReferralProfile()
          .then((data) => {
            this.referralInfo = data;
          });
    },

    referFriends() {
      this.sendingReferralEmails = true;
      ObAccountApi.referFriends(this.referralEmails)
          .then((response) => {
            this.sendingReferralEmails = false;
            this.referralEmails = '';
            this.referralMessage = response;
          }).catch((e) => {
            this.sendingReferralEmails = false;
            this.referralEmails = '';
            if (e.response) {
              this.referralMessage = e.response.data;
            }
          });
    },

    copyToClipboard(copyText, copyType) {
      navigator.clipboard.writeText(copyText).then(() => {
        /* clipboard successfully set */
        if (copyType == 'link') {
          this.showCopyLinkMessage = true;
        } else if (copyType == 'code') {
          this.showCopyCodeMessage = true;
        }
      }).catch((e) => {
        /* clipboard write failed */
      });
    },

    resetReferralMessage() {
      this.referralMessage = null;
    },

    resetCopyLinkMessage() {
      this.showCopyLinkMessage = false;
    },

    resetCopyCodeMessage() {
      this.showCopyCodeMessage = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.bgLogoContainer {
  position: absolute;
  display: flex;
  left: -290px;
  top: -50px;
  height: 478px;
  width: calc(100% + 575px);
  justify-content: center;
  pointer-events: none;
}

.bgLogoMiddle {
  flex: 1;
  min-width: 595px;
}

.bgLogoLeft, .bgLogoRight {
  height: 428px;
  width: 608px;
}

.headerText {
  font-size: 18px;
  font-weight: bold;
}

.headerOption {
  margin-bottom: 15px;

  div {
    font-size: 12px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    margin-bottom: 2px;
  }

  input {
    padding-left: 10px;
    height: 25px;
    width: 430px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  button {
    width: 110px;
  }
}

.subHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.tierHeader {
  width: 65px;
}

.referralDiagramLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.referralDiagramIcons {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 0;
  position: relative;
}

.referralDiagramLine {
  height: 2px;
  width: 100%;
  background: var(--obcolor-background-1);
  position: absolute;
}

.referralTooltip {
  margin-top: -15px;
}


.userLogo {
  border-radius: 50%;
  object-fit: cover;
}

.ownersbucksHeader {
  text-align: center;
  width: 150px;
}

.ownersbucksIcon {
  height: 12px;
  margin-bottom: -2px;
  margin-right: 4px;
  display: inline-block;
}

.referralParagraph {
  margin-top: 15px;
  font-size: 14px;
}

.referralTable {
  padding: 0;
  margin: 10px 0 0 5px;
  overflow: hidden;
  background: var(--obcolor-background-4);
}

tr {
  max-width: 100%;
  min-width: 0;
}

td, th {
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-background-3);
}

th:not(:last-child), td:not(:last-child) {
  border-right: 2px solid var(--obcolor-background-2);
}

tr:not(:last-child) td {
  border-bottom: 2px solid var(--obcolor-background-2);;
}


.loadingBox {
  text-align: center;
  padding: 20px;
}

.loadingReferrals{
  margin-top: 10px; font-size: 14px;
}
</style>