/**
* Returns the path of the reward icon based on the icon name.
* If there is no icon name, check for an imageUrl in rewardDetails
* @param {String} reward - The reward object.
* @return {String} The path of the reward icon.
*/
export function getRewardIcon(reward) {
  const rewardIcon = reward?.icon;
  if (!rewardIcon || rewardIcon == '') {
    if (reward?.rewardDetails) {
      return reward?.rewardDetails[0]?.imageUrl;
    }
    return null;
  }
  return require('@/assets/icons/' + rewardIcon + '.png');
}

/**
* Returns the path of the challenge badge icon based on the icon name.
* @param {String} icon - The name of the challenge icon.
* @param {String} theme - User's selected theme, either dark or light.
* @return {String} The path of the challenge badge icon.
*/
export function getChallengeBadge(icon, theme) {
  const themeColour = theme == 'dark' ? 'white' : 'black';
  if (!icon || icon == '') {
    return require('@/assets/obicon_' + themeColour + '.png');
  }
  return require('@/assets/contesticons/' + icon + '.svg');
}

/**
 * Function that returns the user's current EXP if it exists and the user isn't max level
 * Returns 0 otherwise
 *
 * @param {Object} userInfo - User information object containing user's experience data.
 * @return {Number} - The amount of experience the user has gotten this level.
 */
export function currLevelExp(userInfo) {
  if (isMaxLevel(userInfo)) {
    return 0;
  }
  if (!userInfo?.levelExp) {
    return 0;
  }

  return userInfo?.levelExp;
}

/**
 * Function that calculates the percentage of experience the user has in the current level
 *
 * @param {Object} userInfo - User information object containing user's experience data.
 * @return {Number} - The percentage of experience the user has gotten this level.
 */
export function levelProgressPercent(userInfo) {
  if (isMaxLevel(userInfo)) {
    return 100;
  }
  if (!userInfo || !currLevelExp(userInfo) || !nextLevelExp(userInfo)) {
    return 0;
  }
  return (currLevelExp(userInfo) / nextLevelExp(userInfo)) * 100;
}

/**
 * Function that returns the user's exp for reaching the next level if it exists
 * Returns 0 otherwise
 *
 * @param {Object} userInfo - User information object containing user's experience data.
 * @return {Number} - The calculated experience required to get to the next level.
 */
export function nextLevelExp(userInfo) {
  if (!userInfo?.nextLevelExp) {
    return 0;
  }

  return userInfo?.nextLevelExp;
}

/**
 * Function that checks if the user has reached the maximum level.
 *
 * @param {Object} userInfo - User information object containing user's experience data.
 * @return {Boolean} - True if the user has reached the maximum level.
 */
export function isMaxLevel(userInfo) {
  return userInfo?.nextLevelExp === null || userInfo?.nextLevelExp === undefined;
}

/**
 * Function that gets a string (in '(1/4)' format) to describe progress in a category
 *
 * @param {Object} challengeCategory - Challenge category information
 * @return {String} - String to display user's progress
 */
export function categoryProgressString(challengeCategory) {
  let totFinished = 0;
  let totChallenges = 0;

  if (!challengeCategory?.challenges || challengeCategory?.challenges?.length === 0) {
    return '(0/0)';
  }

  for (const challenge of challengeCategory.challenges) {
    totChallenges++;
    if (challenge.isCompleted) {
      totFinished++;
    }
  }

  return '(' + totFinished + '/' + totChallenges + ')';
}

/**
* Returns the path of the challenge badge icon based on the icon name.
*
* @param {Object} userInfo - The user's level information.
* @return {String} The text to show for the user's next reward
*/
export function nextRewardText(userInfo) {
  let rewardText = 'Next Reward';
  const currLevel = userInfo?.currentLevel;
  if (!currLevel) {
    return rewardText;
  }
  if (userInfo?.nextReward?.level && userInfo?.nextReward?.level > currLevel) {
    rewardText = rewardText + ' (Level ' + (userInfo?.nextReward?.level + 1) + ')';
  }

  return rewardText;
}

export default {
  getChallengeBadge,
  getRewardIcon,
  currLevelExp,
  nextLevelExp,
  levelProgressPercent,
  isMaxLevel,
  categoryProgressString,
  nextRewardText,
};