// Header Summary Stats
// Controls the top overall summary for header page
// NBA has 1 category for summary

// Header summary
export const HEADER_NBA_PLAYER = [
  {
    label: 'Stats',
    stats: [
      {label: 'GP', path: 'player.GP', tooltip: 'Games Played'},
      {label: 'PTS', path: 'player.PTS_PG', tooltip: 'Points Per Game'},
      {label: 'AST', path: 'player.AST_PG', tooltip: 'Assists Per Game'},
      {label: 'REB', path: 'player.REB_PG', tooltip: 'Rebounds Per Game'},
      {label: 'BLK', path: 'player.BLK_PG', tooltip: 'Blocks Per Game'},
      {label: 'STL', path: 'player.STL_PG', tooltip: 'Steals Per Game'},
      {label: 'TO', path: 'player.TO_PG', tooltip: 'Turnovers Per Game'},
    ],
  },
];