<template>
  <div :style="getStyle()">
    {{ displayText }}
  </div>
</template>

<script>
// This component is similar to the ContestCountdown
// But, it does not log a timer
// It just displays text based on in a timestamp is past
// It runs a timer to trigger an update when the timestamp is past
export default {
  name: 'TimeConditionalDisplay',

  props: ['end', 'text', 'altText', 'textStyle', 'altStyle', 'serverOffset'],

  data() {
    return {
      displayText: '',
      displayStyle: '',
      timeout: null,
    };
  },

  created() {
    this.getTime();
    this.loopGetTime();
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  methods: {
    getOffset() {
      return this.serverOffset ? this.serverOffset : 0;
    },

    getStyle() {
      return this.displayStyle == null ? '' : this.displayStyle;
    },

    getTime() {
      if (this.end) {
        const seconds = this.$moment(this.end + this.getOffset()).diff(this.$moment(), 'seconds');
        this.displayText = seconds < 0 ? this.altText : this.text;
        this.displayStyle = seconds < 0 ? this.altStyle : this.textStyle;
      } else {
        this.displayText = this.text;
        this.displayStyle = this.textStyle;
      }
    },

    // 10 second interval, may need to be adjusted for different use
    // For contest cutoff we only show minutes, so 1s timing isn't needed
    loopGetTime() {
      const self = this;
      this.timeout = setTimeout(function() {
        self.getTime();
        self.loopGetTime(10000);
      }, 10000);
    },
  },
};
</script>