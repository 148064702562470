<template>
  <div>
    <h1>Why Verify Your Account?</h1>
    <p>We need additional information to provide you with access to our exciting games and contests.</p>

    <div class="title">Your account needs to be verified to:</div>
    <div v-for="(step, index) in steps" :key="index" class="steps">
      <img :src="step.imgSrc" :alt="step.text">
      <div>{{ step.text }}</div>
    </div>
  </div>
</template>

<script>
import checkmark from '@/assets/onboarding/checkmark.png';

export default {
  data() {
    return {
      steps: [
        {
          imgSrc: checkmark,
          text: 'Play paid contests',
        },
        {
          imgSrc: checkmark,
          text: 'Deposit funds',
        },
        {
          imgSrc: checkmark,
          text: 'Use your sign-up bonus',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding: 50px 0 30px 0;
}

.steps {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}
</style>