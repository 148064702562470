<template>
  <div class="flexContainer">
    <div class="svgContainer">
      <svg :viewBox="'0 -7 258 152'"
        :style="'height: ' + height + 'px; width: ' + width + 'px;'"
        fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="progress-mask">
            <path class="clipPath" d="M5.79927 131.297C2.87459 131.258 0.524804 128.853 0.681227 125.932C2.25486 96.5496 13.5702 68.4676 32.9198 46.1669C53.4283 22.5304 81.6393 6.91333 112.558 2.08104C143.476 -2.75126 175.107 3.51293 201.85 19.7644C227.081 35.0975 246.425 58.3875 256.89 85.8887C257.93 88.6224 256.426 91.6294 253.653 92.5596C250.88 93.4898 247.888 91.9908 246.839 89.2607C237.176 64.1295 219.443 42.8504 196.349 28.8163C171.743 13.8636 142.64 8.10008 114.193 12.5462C85.746 16.9923 59.7897 31.3611 40.9202 53.1086C23.2099 73.5202 12.8148 99.1956 11.2816 126.077C11.115 128.997 8.72396 131.337 5.79927 131.297Z" />
          </clipPath>
        </defs>
        <g opacity="0.2">
          <path d="M5.79927 131.297C2.87459 131.258 0.524804 128.853 0.681227 125.932C2.25486 96.5496 13.5702 68.4676 32.9198 46.1669C53.4283 22.5304 81.6393 6.91333 112.558 2.08104C143.476 -2.75126 175.107 3.51293 201.85 19.7644C227.081 35.0975 246.425 58.3875 256.89 85.8887C257.93 88.6224 256.426 91.6294 253.653 92.5596C250.88 93.4898 247.888 91.9908 246.839 89.2607C237.176 64.1295 219.443 42.8504 196.349 28.8163C171.743 13.8636 142.64 8.10008 114.193 12.5462C85.746 16.9923 59.7897 31.3611 40.9202 53.1086C23.2099 73.5202 12.8148 99.1956 11.2816 126.077C11.115 128.997 8.72396 131.337 5.79927 131.297Z" fill="var(--obcolor-ob-blue)" />
          <circle class="dial" x="0" y="120"
            r="7"
            cx="252"
            cy="90"
            stroke-width="5"
            stroke="var(--obcolor-ob-blue)" fill="var(--obcolor-background-4)"
          />
        </g>
        <g clip-path="url(#progress-mask)">
          <path class="progress" d="M5.79927 131.297C2.87459 131.258 0.524804 128.853 0.681227 125.932C2.25486 96.5496 13.5702 68.4676 32.9198 46.1669C53.4283 22.5304 81.6393 6.91333 112.558 2.08104C143.476 -2.75126 175.107 3.51293 201.85 19.7644C227.081 35.0975 246.425 58.3875 256.89 85.8887C257.93 88.6224 256.426 91.6294 253.653 92.5596C250.88 93.4898 247.888 91.9908 246.839 89.2607C237.176 64.1295 219.443 42.8504 196.349 28.8163C171.743 13.8636 142.64 8.10008 114.193 12.5462C85.746 16.9923 59.7897 31.3611 40.9202 53.1086C23.2099 73.5202 12.8148 99.1956 11.2816 126.077C11.115 128.997 8.72396 131.337 5.79927 131.297Z" fill="var(--obcolor-ob-blue)"
            :style="'transform: rotate(' + completedPercentAngle +'deg)'"
          />
        </g>
        <circle class="dial" x="0" y="120"
          r="10"
          cx="253"
          cy="90"
          stroke-width="6"
          stroke="var(--obcolor-ob-blue)" fill="var(--obcolor-white)"
          :style="'transform: rotate(' + completedPercentAngle +'deg)'"
        />
      </svg>
      <div class="ownersbucksRedeemable">
        <div class="ownersbucksText">
          <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
            alt="OwnersBucks Icon"
          >
          {{ ownersbucksRedeemableDisplay }}
        </div>
        <div>Redeemable</div>
      </div>
      <div class="ownersbucksTotal">
        <div class="ownersbucksText">
          <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
            alt="OwnersBucks Icon"
          >
          {{ ownersbucksTotDisplay }}
        </div>
        <div>Balance</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ownersbucksTot: {type: String, default: null},
    ownersbucksRedeemable: {type: String, default: null},
    scale: {type: Number, default: 1},
  },

  components: {},

  data() {
    return {
      visibleSize: 63,
      circleRadius: 265,
      redeemableComputed: 0,
      baseHeight: 132,
      baseWidth: 258,
    };
  },

  created() {
    const self = this;
    // For animating the bar going up
    setTimeout(() => {
      self.redeemableComputed = self.ownersbucksRedeemable;
    }, 100);
  },

  destroyed() {},

  computed: {
    height() {
      return this.baseHeight * this.scale;
    },

    width() {
      return this.baseWidth * this.scale;
    },

    ownersbucksTotDisplay() {
      if (this.ownersbucksTot == null) {
        return '--';
      }
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this.ownersbucksTot);
    },

    ownersbucksRedeemableDisplay() {
      if (this.ownersbucksRedeemable == null) {
        return '--';
      }
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this.ownersbucksRedeemable);
    },

    completedPercent() {
      const redeemable = parseFloat(this.redeemableComputed);
      const obucks = parseFloat(this.ownersbucksTot);
      if (!redeemable || redeemable < 0) {
        return 0;
      }
      if (!obucks) {
        return 0;
      }
      if (obucks < redeemable) {
        return 1;
      }
      return redeemable / this.ownersbucksTot;
    },

    completedPercentAngle() {
      const graphDegrees = 160;
      // Rotate backwards at a percentage of completed
      return -graphDegrees * (1 - this.completedPercent);
    },
  },

  watch: {
    ownersbucksRedeemable(newVal) {
      this.redeemableComputed = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Archivo', sans-serif;
}

.svgContainer {
  position: relative;
}

.ownersbucksTotal, .ownersbucksRedeemable {
  position: absolute;
  color: var(--obcolor-font-secondary);
  width: 0;
  height: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ownersbucksText {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }

}

.ownersbucksRedeemable {
  top: 36%;
  left: 51%;
  font-size: 18px;

  .ownersbucksText {
    gap: 5px;
    font-size: 27px;
  }

  img {
    height: 14px;
  }
}

.ownersbucksTotal {
  top: 70%;
  left: 92%;
  font-size: 14px;

  .ownersbucksText {
    font-size: 16px;
  }

  img {
    height: 10px;
  }
}

.progress, .dial, .clipPath {
  transform-origin: 133px 133px;
  transition: transform 1s;
}
.progress {
  fill: var(--obcolor-ob-blue);
}
</style>