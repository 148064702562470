<template>
  <div class="weekSelector">
    <!-- Middle Progress Bar -->
    <div class="weekProgressBar">
      <div class="weekProgressInner" :style="'width:' + getDaysWidth() +'%'"></div>
    </div>
    <canvas ref="graphCanvas" class="graphCanvas"></canvas>
    <table class="dayTable" aria-label="Matchup Day Selector">
      <tr>
        <th class="dayCol" v-for="index in 7" :key="index"
          @click="selectDay(index)" :class="{'selectedDay': selectedDay === index}"
        >
          <div class="day">
            <div class="rowHeader">
              <div>{{ [team1Roster.days[index-1], 'YYYY-MM-DD'] | moment("ddd") }}</div>
              <div>{{ [team1Roster.days[index-1], 'YYYY-MM-DD'] | moment("D") }}</div>
            </div>
            <div class="graphPointContainer">
              <template v-if="$moment().diff($moment(team1Roster.days[index-1])) > 0">
                <div class="graphPoint" :style="'top: calc(' + getScorePct(index) + '% - 5px)'"></div>
              </template>
            </div>
          </div>
        </th>
        <th class="weekCol" :class="{'selectedDay': selectedDay === null}" @click="selectDay(null)">
          <div class="rowHeader">Week {{ this.week }}</div>
          <div class="weekWinningsContainer">
            <i class="fas fa-trophy winnerTrophy"></i>
            <div v-if="winnerPrize" class="winnerPrize">
              {{ winnerPrize.removeCentsIfZero() }}
            </div>
          </div>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  props: {
    selectedDay: Number,
    league: Object,
    team1Roster: Object,
    team2Roster: Object,
    week: Number,
    winnerPrize: String,
  },

  data() {
    return {
      todaysIndex: 0,
      dayWidth: 43,
      graphHeight: 114,
    };
  },

  created() {
    this.setupTodaysIndex();
  },

  mounted() {
    this.buildGraph();
  },

  watch: {
    league(to, from) {
      this.setupTodaysIndex();
      this.buildGraph();
    },

    week(to, from) {
      this.buildGraph();
    },

    team1Roster(to, from) {
      this.buildGraph();
    },

    team2Roster(to, from) {
      this.buildGraph();
    },
  },

  methods: {
    selectDay(day) {
      EventBus.$emit('MATCHUP_SELECT_DAY', day);
    },

    getDaysWidth() {
      if (this.todaysIndex == null) {
        if (this.$moment().diff(this.$moment(this.team1Roster.days[0]), 'days') > 0) {
          return 100;
        } else {
          return 0;
        }
      }
      return ((this.todaysIndex) + 0.5) / 7 * 100;
    },

    setupTodaysIndex() {
      const days = this.league.roster1.days;
      const now = this.$moment().format('YYYY-MM-DD');
      this.todaysIndex = null;
      for (const dIndex in days) {
        if (days[dIndex] === now) {
          this.todaysIndex = dIndex;
          break;
        }
      }
    },

    totalUntilDay(teamNum, day) {
      let teamRoster = {};
      if (teamNum === 1) {
        teamRoster = this.team1Roster;
      } else {
        teamRoster = this.team2Roster;
      }
      const totalPoints = this.getTotalPoints(teamNum, teamRoster, true, day);
      return totalPoints;
    },

    addPlayerValidGames(player, validGames, getSum = false, day = this.selectedDay) {
      const games = player.fspGames;

      if (day != null && getSum === false) {
        if (games[day - 1] != null) {
          const gJSON = games[day - 1];
          this.addGameIfValid(gJSON, validGames, player);
        }
        if (games[(day - 1) + 'DH'] != null) {
          const gJSON = games[(day - 1) + 'DH'];
          this.addGameIfValid(gJSON, validGames, player);
        }
      } else {
        for (const gameIndex in games) {
          if (day == null || gameIndex.charAt(0) < day) {
            const gJSON = games[gameIndex];
            this.addGameIfValid(gJSON, validGames, player);
          }
        }
      }
    },

    addGameIfValid(gJSON, validGames, player) {
      const pos = player.draftPos;
      if (gJSON.inRange &&
          gJSON.played &&
          !this.gameDeactivated(player, gJSON)) {
        const entry = {};
        entry.id = player.id + '_' + gJSON.id;
        entry.ts = parseInt(gJSON.timestamp);
        if (gJSON.ProjPoints) {
          entry.proj = gJSON.ProjPoints.total;
        }
        if (gJSON.ObPoints) {
          entry.fpts = gJSON.ObPoints.total;
        }

        entry.ObPoints = gJSON.ObPoints;
        entry.stats = gJSON.stats;

        if (validGames[pos] == null) {
          validGames[pos] = [];
        }
        validGames[pos].push(entry);
      }
    },

    gameDeactivated(player, game) {
      return player.deactGames[game.id] != null;
    },

    getTotalPoints(teamNum, teamRoster, getSum = false, day = this.selectedDay) {
      const gameLimit = this.league.gameLimits;

      // Create list of valid games (Played, not deactivated, not dropped)
      // Order by time, then projected points
      // {id:PLAYERID_GAMEID, ts: timestamp, pos: F, proj: 12.40 }
      const validGames = {};

      for (const pIndex in teamRoster.players) {
        const player = teamRoster.players[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
      }

      for (const pIndex in teamRoster.dropped) {
        const player = teamRoster.dropped[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
      }

      // Sort and Limit validGames
      for (const pos in validGames) {
        let gamesByPos = validGames[pos];
        const limit = gameLimit[pos];

        gamesByPos.sort(function(a, b) {
          if (a.ts > b.ts) {
            return 1;
          } else if (b.ts > a.ts) {
            return -1;
          }
          // Secondary sort by proj (highest takes proirity)
          if (a.proj > b.proj) {
            return -1;
          } else if (b.proj > a.proj) {
            return 1;
          }
          return 0;
        });

        gamesByPos = gamesByPos.slice(0, limit);
        validGames[pos] = gamesByPos;
      }

      // Calc total points
      let totalPoints = 0;
      for (const pos in validGames) {
        const gamesByPos = validGames[pos];
        for (const game of gamesByPos) {
          totalPoints += game.fpts;
        }
      }

      return totalPoints;
    },

    getScorePct(index) {
      const scorePct = this.getScorePercentage(this.totalUntilDay(2, index), this.totalUntilDay(1, index));
      return scorePct.toFixed(0);
    },

    getScorePercentage(team1Score, team2Score) {
      if ((team1Score === undefined || team1Score === 0) && (team2Score === undefined || team2Score === 0)) {
        return 50;
      }

      return this.$ScoringStats.getLeadPercentage(this.league.sport, team1Score, team2Score);
    },

    buildGraph() {
      const c = this.$refs['graphCanvas'];
      let ctx = null;
      if (c != undefined) {
        ctx = c.getContext('2d');
        this.canvas = ctx;
      } else {
        return;
      }

      ctx.clearRect(0, 0, (this.dayWidth * 7), this.graphHeight + 66);
      // We start 1 day behind so that the graph connects the first day on the screen with the day before it
      const dWidth = this.dayWidth;
      const halfX = dWidth / 2;
      let curX = 0;

      let lastPointX = null;
      let lastPointY = null;

      for (let i = 0; i < 7; i++) {
        // Draw point
        const pct = this.getScorePct(i + 1);

        const newPointX = curX + halfX;
        const newPointY = ((this.graphHeight + 32) * pct / 100) + 2;

        // Draw the line from the previous day's point to the current day's point.
        if (lastPointX && lastPointY && this.$moment().diff(this.team1Roster.days[i]) > 0) {
          ctx.beginPath();
          ctx.moveTo(lastPointX, lastPointY);
          ctx.lineTo(newPointX, newPointY);
          ctx.strokeStyle = '#878998';
          ctx.stroke();
        }

        lastPointX = newPointX;
        lastPointY = newPointY;

        curX += dWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.graphCanvas {
  position: absolute;
  z-index: 60;
  top: 62px;
  height: calc(100% - 63px);
  width: calc(100% - 66px);
  pointer-events: none;
}
.weekSelector {
  width: 380px;
  height: 180px;
  position: relative;
  margin-top: 1px;
}
.dayTable {
  width: 100%;
  height: 100%;
  tr {
    cursor: pointer;
  }
  th {
    padding: 0;
  }
  th:hover {
    background: var(--obcolor-background-5);
  }
}

.selectedDay {
  background: var(--obcolor-background-5);
}

.dayCol {
  width: 45px;
}
.day {
  display: flex;
  flex-direction: column;
  height: 180px;
}
.weekCol {
  max-width: 65px;
  height: 180px;
  display: flex;
  flex-direction: column;
}
.weekCol .rowHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowHeader {
  font-size: 13px;
  border-bottom: 1px solid var(--obcolor-background-2);
  padding: 18px 6px 12px 6px;
  height: 60px;
  box-sizing: border-box;
  text-align: center;
}

.graphPointContainer {
  flex: 1;
  border-right: 1px solid var(--obcolor-background-2);
  margin-top: 6px;
  position: relative;
}
.graphPoint {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-left: calc(50% - 2px);
  margin-right: calc(50% - 4px);
  background: var(--obcolor-font-secondary);
  position: absolute;
  z-index: 87;
}

.weekProgressBar {
  pointer-events: none;
  opacity: 0.9;
  position: absolute;
  left: 10px;
  height: 5px;
  top: 118px;
  right: 75px;
  background: var(--obcolor-background-page);
  border-radius: 5px;
  overflow: hidden;
  z-index: 86;
}
.weekProgressInner {
  height: 100%;
  background: var(--obcolor-ob-blue);
  border-radius: 5px;
}

.weekWinningsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.winnerTrophy {
  font-size: 24px;
  margin-bottom: 5px;
  color: #888;
}
.winnerPrize {
  border-radius: 5px;
  background: var(--obcolor-ob-blue);
  width: max-content;
  padding: 5px;
  color: white;
}
</style>