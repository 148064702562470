<template>
  <div class="ob-page" style="display: flex;" v-if="league != null && league.sport != null">
    <div style="flex: 1;">
      <template v-if="league">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div style="display: flex; align-items: center;">
            <div>
              <img :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="`${league.sport} Icon`" style="height: 30px; margin-top: 5px;">
            </div>
            <div class="ob-btn" style="padding: 4px; font-size: 12px; margin-left: 10px; margin-right: 10px;">{{ league.fee }}</div>
            <div>Contest #{{ league.id }}</div>
          </div>
          <div>
            {{ league.playersMax }} Teams <div class="ob-btn" style="padding: 4px; font-size: 12px; margin-left: 10px; margin-right: 10px;">{{ league.prizePool }}</div>
          </div>
        </div>
        <div class="ob-box" style="padding: 0; overflow: hidden;">
          <ContestBracket :league="league" :selectedWeek="week" :allowSelectingWeeks="true" />
        </div>
      </template>

      <div class="ob-box" style="margin-top: 10px;">
        <ContestPlayers :league="league" :week="week" />
      </div>

      <div class="ob-box" style="margin-top: 10px; overflow: hidden;" v-if="transactions.length > 0">
        <div class="ob-scroll" style="margin: -8px; overflow-y: auto">
          <div v-for="(transaction, index) in transactions" :key="index"
            class="transaction"
          >
            <div style="flex: 1;">
              <div>{{ $moment(transaction.timestamp).format("MMM D, YYYY h:mma") }}</div>
              <div v-if="transaction.type === 'waivers'" style="margin-top: 3px;">Waiver Claim</div>
            </div>
            <UserLogo :id="transaction.userId" :size="50" :sport="league.sport" />
            <div style="margin-left: 10px; text-align: left; flex: 1;">
              <div style="font-weight: bold; font-size: 14px;">{{ transaction.userTeam }}</div>
              <div style="font-weight: bold; color: var(--obcolor-font-secondary); margin-top: 2px; margin-bottom: 2px;">{{ transaction.userName }}</div>
              <div style="font-weight: bold;">
                <span style="margin-right: 5px;">Week {{ transaction.weekNum }}</span>
                <span v-if="transaction.dropNum">Drop {{ transaction.dropNum }}/{{ transaction.dropMax }}</span>
                <span v-else>Inactive Drop</span>
              </div>
            </div>

            <!-- Dropped Player -->
            <div style="text-align: left; flex: 1; display: flex; align-items: center;">
              <i class="fa fa-minus" style="color: var(--obcolor-red); font-size: 20px;"></i>
              <PlayerHeadshot style="margin-right: 10px; margin-left: 10px;" :id="transaction.playerDropped.id" size="50"
                :sport="league.sport" :team="transaction.playerDropped.team"
              />
              <div>
                <div style="font-weight: bold;">{{ transaction.playerDropped.name.longName() }}</div>
                <div style="color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: 3px;">
                  #{{ transaction.playerDropped.number }}
                  {{ transaction.playerDropped.position }}
                </div>
                <div style="color: var(--obcolor-font-secondary); text-transform: uppercase;">{{ transaction.playerDropped.team }}</div>
              </div>
            </div>

            <!-- Added Player -->
            <div style="text-align: left; flex: 1; display: flex; align-items: center;">
              <i class="fa fa-plus" style="color: var(--obcolor-green); font-size: 20px;"></i>
              <PlayerHeadshot style="margin-right: 10px; margin-left: 10px;" :id="transaction.playerAdded.id" size="50"
                :sport="league.sport" :team="transaction.playerAdded.team"
              />
              <div>
                <div style="font-weight: bold;">{{ transaction.playerAdded.name.longName() }}</div>
                <div style="color: var(--obcolor-font-secondary); margin-top: 3px; margin-bottom: 3px;">
                  #{{ transaction.playerAdded.number }}
                  {{ transaction.playerAdded.position }}
                </div>
                <div style="color: var(--obcolor-font-secondary); text-transform: uppercase;">{{ transaction.playerAdded.team }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="feedsContainer">
      <FeedsView />
    </div>
  </div>
</template>

<script>
import ObLeagueApi from '@/api/ObLeagueApi';
import EventBus from '@/event-bus';
import ContestBracket from '@/views/Contest/ContestSummary/ContestBracket';
import ContestPlayers from '@/views/Contest/ContestSummary/ContestPlayers';
import UserLogo from '@/components/UserLogo';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import FeedsView from '@/views/Feeds/FeedsView';

export default {

  components: {
    ContestBracket,
    ContestPlayers,
    UserLogo,
    PlayerHeadshot,
    FeedsView,
  },

  data() {
    return {
      selectedLeague: null,
      league: {},
      transactions: [],
      userId: null,
      leagueId: null,
      week: 0,
      newsType: 'feed',
    };
  },

  created() {
    this.leagueId = this.$route.params.leagueId;
    this.loadPage();
    EventBus.$on('CONTEST_SELECT_WEEK', this.selectWeek);
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('DASHBOARD_' + this.userId);
    EventBus.$off('CONTEST_SELECT_WEEK', this.selectWeek);
  },

  computed: {
    inProgressLeagues() {
      return this.leagues.filter(function(league) {
        return league.state !== 'IN_PROGRESS';
      });
    },
  },

  methods: {
    loadPage() {
      ObLeagueApi.getLeague(this.leagueId)
          .then((data) => {
            this.userId = data.userId;
            this.league = data || {};
            this.determineAllMatchups();
            this.sortLeagues();

            this.$SocketController.subscribeToRoom('DASHBOARD_' + this.userId);
          })
          .catch((_error) => {
            // Do nothing
          });
      ObLeagueApi.getLeagueTransactions(this.leagueId)
          .then((data) => {
            this.transactions = data;
          })
          .catch((_error) =>{
            // Do nothing
          });
    },

    selectWeek(week) {
      this.week = week;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/style/variables.scss";

  .transaction {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 8px;
  }
  .transaction:nth-child(even) {
    background: var(--obcolor-background-5);
  }
  .selectedText {
    color: var(--obcolor-ob-blue) !important;
  }

  .feedsContainer {
    overflow: hidden;
    width: 350px;
    min-width: 350px;
    border-radius: 10px;
    flex: 0;
    position: sticky;
    top: 90px;
    height: calc(100vh - (75px + var(--ob-nav-offset)));
    background: var(--obcolor-background-4);
    margin-left: 15px;
  }
</style>