<template>
  <div v-if="player" class="playerBox" @click="selectPlayer()"
    :class="{ toAddPlayer: showAddedPlayer, toDropPlayer: !showAddedPlayer && isBeingDropped, noClick: !canDrop }"
  >
    <!-- Player position, number, image and remove player button -->
    <div style="display: flex;">
      <div class="posContainer">
        <div class="playerPos">{{ pos }}</div>
        <div class="playerNumber">
          #{{ computedPlayer.number }}
        </div>
      </div>
      <div class="headshotContainer">
        <PlayerHeadshot :id="computedPlayer.id"
          size="50" :sport="contest.sport"
          :team="computedPlayer.team"
          :showLogo="true"
        />
      </div>
      <div>
        <div v-if="!canDrop" class="swapBtn"></div>
        <i v-else-if="isBeingDropped" class="fa fa-times swapBtn"></i>
        <i v-else class="fal fa-minus-circle swapBtn" style="color: var(--obcolor-red);"></i>
      </div>
    </div>

    <!-- Player name info -->
    <div class="nameContainer">
      <div class="statusLoading" v-if="!statusMap">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <PlayerStatus v-else-if="!statusMap[computedPlayer.id]" :status="'OUT'" />
      <PlayerStatus v-else :status="statusMap[computedPlayer.id].status" />
      <div class="playerName">
        {{ computedPlayer.name.shortName() }}
        {{ computedPlayer.nameTag }}
      </div>
    </div>

    <!-- Player stats -->
    <table class="playerStatsTable" style="margin-top: 4px;" aria-label="Player Statistics">
      <tr>
        <td v-if="contest.format == 'WEEKLY'">{{ gamesRemaining }}</td>
        <td v-else>
          <div class="logoContainer">
            <img v-if="firstGame" :src="getTeamLogo(firstGame.game)" :alt="`${firstGame.game} Team`"
              style="height: 20px; margin-top: -3px; margin-bottom: -5px;"
            >
            <div v-else>-</div>
          </div>
        </td>
        <td v-if="contest.format == 'WEEKLY'">{{ projRemaining }}</td>
        <td v-else-if="canDrop">{{ projRemaining }}</td>
        <td v-else :style="getScoreStyle(totalFpts)">{{ totalFpts }}</td>
        <td v-if="contest.format == 'WEEKLY'" class="moneyText">{{ dollarFormat(salaryRemaining) }}</td>
        <td v-else class="moneyText">{{ dollarFormat(computedPlayer.salaryPG) }}</td>
      </tr>
      <tr class="playerStatHeadings">
        <template v-if="contest.format == 'WEEKLY'">
          <td>PG</td>
          <td>Proj</td>
          <td>{{ dollarFormat(computedPlayer.salaryPG) }}</td>
        </template>
        <template v-else>
          <td style="width: 28px;">Opp</td>
          <td v-if="canDrop">Proj</td>
          <td v-else>FPTS</td>
          <td>Salary</td>
        </template>
      </tr>
    </table>
  </div>
  <div v-else class="playerBox" @click="selectRosterFilter(pos)">
    <div class="playerEmpty">{{ pos }}</div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    player: {type: Object, default: null},
    contest: {type: Object, default: null},
    pos: {type: String, default: null},
    statusMap: {type: Object, default: null},
    selectedFor: {type: String, default: null},
    toAdd: {type: Object, default: null},
    rosterMode: {type: String, default: null},
    isBeingDropped: {type: Boolean, default: false},
  },

  computed: {
    isWeeklyContest() {
      return this.contest && this.contest.format === 'WEEKLY';
    },

    canDrop() {
      return this.player && this.player.canSwap;
    },

    showAddedPlayer() {
      return this.toAdd != null && this.rosterMode == 'proj';
    },

    computedPlayer() {
      if (this.toAdd != null && this.rosterMode == 'proj') {
        return this.toAdd;
      }
      return this.player;
    },

    gamesRemaining() {
      const now = new Date().getTime();
      let gr = 0;
      for (const game of this.computedPlayer.games) {
        if (game.ts > now && game.proj > 0) {
          gr++;
        }
      }
      return gr;
    },

    projRemaining() {
      if (!this.computedPlayer) {
        return 0;
      }
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && this.player.canSwap;
      const now = new Date().getTime();
      let proj = 0;
      for (const game of this.computedPlayer.games) {
        if (game.ts && (isValidDailySwap || game.ts > now)) {
          proj += game.proj ? Math.round(game.proj) : 0;
          proj += game.projDH ? Math.round(game.projDH) : 0;
        }
      }
      return proj;
    },

    totalFpts() {
      if (!this.computedPlayer) {
        return 0;
      }

      let fpts = 0;

      for (const game of this.computedPlayer.games) {
        if (game.fpts != null) {
          fpts = fpts + game.fpts;
        }
      }

      return fpts;
    },

    salaryRemaining() {
      return this.gamesRemaining * this.computedPlayer.salaryPG;
    },

    firstGame() {
      if (!this.computedPlayer || !this.computedPlayer.games) {
        return null;
      }

      return this.computedPlayer.games.find((g) => g.ts != null && !g.excluded);
    },

    nextGame() {
      if (!this.computedPlayer || !this.computedPlayer.games) {
        return null;
      }
      return this.computedPlayer.games.find((g) => g.ts != null && g.ts > this.$moment().valueOf() && !g.excluded);
    },
  },

  methods: {

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const d = Math.round(dollarAmount);
      return '$' + Intl.NumberFormat('en-US').format(d);
    },

    getScoreStyle(score) {
      if (score < 0) {
        return 'color: var(--obcolor-red)';
      } else if (score > 0) {
        return 'color: var(--obcolor-ob-blue)';
      }

      return 'color: var(--obcolor-font-primary)';
    },

    selectPlayer() {
      if (!this.canDrop) {
        return;
      }
      if (this.showAddedPlayer) {
        EventBus.$emit('LINEUP_EDITOR_SELECT_TO_ADD', this.computedPlayer.id);
      } else {
        EventBus.$emit('LINEUP_EDITOR_SELECT_TO_DROP', this.computedPlayer.id);
      }
    },

    getTeamLogo(alias) {
      alias = alias.replace(/@/, '');
      return require('@/assets/logos/'+ this.contest.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },
  },
};
</script>

<style lang="scss" scoped>
  .noClick {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .playerBox {
    margin: 2px;
    display: flex;
    flex-direction: column;
    background: var(--obcolor-background-4);
    height: 118px;
    width: 118px;
    border: 2px solid var(--obcolor-background-4);
  }
  .playerBox:not(.noClick) {
    cursor: pointer;
  }

  .playerBox.toAddPlayer {
    border: 2px solid var(--obcolor-green);
  }

  .playerBox.toDropPlayer {
    border: 2px solid var(--obcolor-red);
  }

  .playerBox:not(.noClick):hover {
    background: var(--obcolor-background-3);
    .swapBtn {
      color: var(--obcolor-font-primary) !important;
    }
  }

  .playerBox.selectedPlayer {
    background: var(--obcolor-background-3);
  }

  .statusLoading {
    font-size: 12px;
    margin-right: -1px;
    color: var(--obcolor-font-secondary);
  }

  .posContainer {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .playerPos {
    padding: 3px 5px;
    font-size: 14px;
    background: var(--obcolor-background-2)
  }

  .playerNumber {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    margin-top: 2px;
  }

  .headshotContainer {
    flex: 1;
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }

  .swapBtn {
    font-size: 16px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
    width: 12px;
  }

  .removeBtn {
    color: var(--obcolor-red);
    font-size: 20px;
    padding: 3px;
    cursor: pointer;
    height: 22px;
  }
  .removeBtn:hover {
    color: white;
  }

  .nameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--obcolor-background-2);
  }

  .playerName {
    margin-left: 5px;
    margin-top: 2px;
    max-width: 96px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .playerEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: 100%;
    height: 100%;
  }

  .playerStatsTable {
    width: calc(100% - 6px);
    margin: 0 3px;
    box-sizing: border-box;
    margin-top: 3px;

    td {
      text-align: center;
      font-size: 12px;
    }
  }

  .playerStatHeadings td {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    padding-top: 2px;
  }

  .moneyText {
    color: var(--obcolor-green);
    font-weight: bold;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
    font-weight: bold;
  }
</style>