const Helpers = {};

Helpers.install = function(Vue, options) {
  const Helpers = {
    parsePath(path, object) {
      return path.split('.').reduce((o, i) => {
        return o[i] === undefined ? false : o[i];
      }, object);
    },

    validateEntryFee(value) {
      const regexInteger = /[^\d^.]/g;
      const regexDecimal = /(\d*\.\d{0,2}).*/g;
      let replaceVal = String(value);
      replaceVal = replaceVal.replace(regexInteger, '');
      replaceVal = replaceVal.replace(regexDecimal, '$1');
      return replaceVal;
    },

    getACHScript() {
      const prodURL = 'https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js';
      const devURL = 'https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js';

      switch (location.hostname) {
        case 'ownersbox.com':
        case 'app.ownersbox.com':
        case 'beta.ownersbox.com':
        case 'ownersbox-production-01.appspot.com':
          return prodURL;
        default:
          return devURL;
      }
    },
  };

  Vue.prototype.$Helpers = Helpers;
};

export default Helpers;