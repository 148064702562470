// @ts-check

import EventBus from '@/event-bus';
import Vue from 'vue';


/**
 * @typedef {import('@/api/ObPropsApi').Game2} Game2
 * @typedef {import('@/utils/SocketController').default} SocketController
 *
 * @typedef {{
 *  type: 'MARKET_GAME_UPDATE',
 *  games: Game2[],
 * }} SocketUpdate
 *
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {*} options
 */
export default function GamesMixin(options = {}) {
  const {
    property = 'games',
    sportProperty = 'sport',
  } = options;

  return Vue.extend({
    data() {
      return {
        /** @type {Game2[]} */
        GamesMixin_games: [],
      };
    },

    computed: {
      [property]: {
        get() {
          return this.GamesMixin_games;
        },
        set(newGames) {
          this.GamesMixin_games = newGames;
        },
      },

      GamesMixin_sport() {
        const sport = this[sportProperty];
        if (!sport) {
          return null;
        }
        if (typeof sport === 'string') {
          return sport;
        }
        if (typeof sport === 'object') {
          return sport.value;
        }
        return null;
      },
    },

    watch: {
      GamesMixin_sport(to, from) {
        if (from) {
          this.$SocketController.unsubscribeFromRoom('MARKET_GAME_UPDATE_' + from);
        }
        if (to) {
          this.$SocketController.subscribeToRoom('MARKET_GAME_UPDATE_' + to);
        }
      },
    },

    created() {
      EventBus.$on('SOCKET_BROADCAST', this.GamesMixin_marketsHandleSocketUpdate);
      if (this.GamesMixin_sport) {
        this.$SocketController.subscribeToRoom('MARKET_GAME_UPDATE_' + this.GamesMixin_sport);
      }
    },

    destroyed() {
      EventBus.$off('SOCKET_BROADCAST', this.GamesMixin_marketsHandleSocketUpdate);
      if (this.GamesMixin_sport) {
        this.$SocketController.unsubscribeFromRoom('MARKET_GAME_UPDATE_' + this.GamesMixin_sport);
      }
    },

    methods: {
    /**
     * @param {SocketUpdate} data
     * @return {void}
     */
      GamesMixin_marketsHandleSocketUpdate(data) {
        if (data.type !== 'MARKET_GAME_UPDATE') {
          return;
        }

        const newGames = new Map();
        for (const game of data.games) {
          newGames.set(game.id, game);
        }
        this.GamesMixin_games = this.GamesMixin_games
            .map((g) => newGames.get(g.id) ?? g);
      },
    },
  });
}