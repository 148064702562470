<template>
  <div class="ob-page">
    <div class="ob-box">
      <!-- Sport Filters -->
      <div class="filterGroup">
        <div class="filterTitle">Sport</div>
        <div class="btnGroup">
          <FilterButton v-for="(sport, index) in sports" :key="index"
            :sport="sport" :label="sport" :selected="selectedSport == sport"
            @click="changeSport(sport)"
          />
        </div>
      </div>

      <div style="display: flex; gap: 20px;">
        <!-- Position Filters -->
        <div class="filterGroup">
          <div class="filterTitle">Filter by Position</div>
          <div class="btnGroup">
            <button class="btnFilter" v-for="(pos, index) in positions" :key="index"
              :class="{btnSelected : pos.active}" @click="togglePos(pos.label)"
              style="width: 50px;"
            >
              {{ pos.label }}
            </button>
          </div>
        </div>

        <!-- Team Filters -->
        <div class="filterGroup">
          <div class="filterTitle">Filter by Team</div>
          <div style="display: flex;">
            <button class="btnFilter selectTeamBtn" :class="{btnSelectedTeam : selectedTeam}" @click="openTeamSelector()">
              <img v-if="selectedTeam" :src="getTeamLogoForFilter(selectedTeam.team_alias)" alt="Team Logo">
              <span v-if="selectedTeam">{{ selectedTeam.team_city }}</span>
              <span v-else>Select Team</span>
              <i class="fas fa-chevron-down" style="margin-left: 10px;"></i>
            </button>
            <button v-if="selectedTeam" class="btnFilter clearFilterBtn" @click="clearTeamFilter()">X</button>
          </div>
          <div v-if="selectTeamOpen" @click="closeTeamSelector()" class="teamSelectorMask"></div>
          <div v-if="selectTeamOpen" class="teamSelector">
            <div class="btnGroup">
              <button class="btnFilter teamBtn" v-for="team in teams" :key="team.id"
                :class="{btnSelectedTeam : selectedTeam && selectedTeam.id == team.id}" @click="selectTeam(team)"
              >
                <img :src="getTeamLogoForFilter(team.team_alias)" alt="Team Logo">
                <div>
                  <div class="boldTitle">{{ team.team_city }}</div>
                  <div>{{ team.team_name }}</div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- Search by Name -->
        <div class="filterGroup">
          <div class="filterTitle">Search by Name</div>
          <div style="display: flex;">
            <div class="searchInput" style="margin-right: 10px;">
              <i class="fa fa-search"></i>
              <input placeholder="Search Player List..." v-model="nameSearch">
            </div>
            <button v-if="nameSearch" class="btnFilter clearFilterBtn" @click="clearNameFilter()">X</button>
          </div>
        </div>

        <!-- My Players Filter -->
        <div class="filterGroup">
          <div class="filterTitle">Filter by Type</div>
          <div class="btnGroup">
            <button class="btnFilter myPlayersBtn" :class="{btnSelected : filterMyPlayers}" @click="toggleMyPlayers()">
              My Players
            </button>
            <button class="btnFilter heatFilterBtn" :class="{btnSelected : heatFilters['Hot']}" @click="toggleHeatFilter('Hot')">
              <i class="fab fa-hotjar hotIcon"></i>
            </button>
            <button class="btnFilter heatFilterBtn" :class="{btnSelected : heatFilters['Cold']}" @click="toggleHeatFilter('Cold')">
              <i class="fas fa-icicles coldIcon"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="filterGroup btnGroup">
        <div v-if="rosterFilters && rosterFilters.length > 1 && selectedDataField == 'stats'">
          <DropdownButton style="display: inline-block;">
            <div slot="button" class="filterIconContainer">
              <i class="fa fa-filter filterIcon"></i>
              <div v-if="rosterFilters[selectedFilterIndex] != null" class="filterText">
                {{ rosterFilters[selectedFilterIndex].label }}
              </div>
            </div>
            <template slot="optionList">
              <div class="optionButton" v-for="(roster, index) in rosterFilters" :key="index"
                @click.stop="selectFilter(index)"
              >
                {{ roster.label }}
              </div>
            </template>
          </DropdownButton>
        </div>
      </div>

      <!-- Loading -->
      <div v-if="!allPlayers" style="text-align: center; padding: 10px;">
        <ObLoading />
        <span style="margin-left: 10px;">Loading Players...</span>
      </div>

      <!-- Table -->
      <table v-else aria-label="[Player List">
        <tr class="tableHeader1">
          <th colspan="4" scope="col"></th>
          <template v-if="selectedDataField == 'schedule'">
            <th colspan="99" style="padding-bottom: 4px;">Schedule</th>
          </template>
          <template v-else>
            <th v-for="(category, index) in rosterScoringStats"
              :key="index" :colspan="category.stats.length"
              :class="{ endSection: index != rosterScoringStats.length - 1 }"
            >
              {{ category.label }}
            </th>
          </template>
        </tr>
        <tr class="tableHeader2">
          <th scope="col">POS</th>
          <th scope="col"></th>
          <th class="nameHeader" style="text-align: left;" scope="col">Player Name</th>
          <template v-if="selectedDataField == 'schedule'">
            <th @click="() => scheduleSort = 'sAvg'" class="sortable" :class="{selected: scheduleSort == 'sAvg'}">
              <GeneralTooltip textKey="SalaryCap_sAvg" pos="bottom">
                <div slot="trigger">
                  AVG
                </div>
              </GeneralTooltip>
            </th>
            <th v-for="(game, index) in allPlayers[0].games" :key="index"
              @click="() => scheduleSort = 'day' + (index + 1)" class="sortable"
              :class="{selected: scheduleSort == 'day' + (index + 1)}" style="min-width: 94px;"
            >
              <div>{{ [game.date, 'YYYY-MM-DD'] | moment("ddd") }}</div>
              <div>{{ [game.date, 'YYYY-MM-DD'] | moment("MMM D") }}</div>
            </th>
          </template>

          <!-- Season Stat Headers -->
          <template v-else>
            <template v-for="(category, index) in rosterScoringStats">
              <th v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
                :class="{endSection: index1 == category.stats.length - 1 && index != rosterScoringStats.length - 1,
                         selected: statsSort == stat.path,
                }"
                class="sortable"
                @click="() => statsSort = stat.path"
              >
                {{ stat.label }}
              </th>
            </template>
          </template>
        </tr>

        <tr v-for="player in players" :key="player.id">
          <td style="padding: 0;">
            <div class="playerPos">
              <div v-if="myplayers[player.id]" class="myPlayerIcon"></div>
              <div>{{ player.draftPos }}</div>
            </div>
          </td>
          <td style="width: 55px;">
            <PlayerHeadshot :id="player.id" :sport="selectedSport" :team="player.team"
              :size="'45'" :showLogo="true"
            />
          </td>
          <td class="nameField">
            <div>
              <span class="ob-link" @click="openPlayerModal(player.id)">
                {{ player.name.longName() }}
                {{ player.nameTag }}
              </span>
              <!-- Heat Icons -->
              <i v-if="player.heat === 'Cold'" class="fas fa-icicles coldIcon"
                v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Cold'"
              ></i>
              <i v-else-if="player.heat === 'Hot'" class="fab fa-hotjar hotIcon"
                v-tooltip.right="player.heatInfo != null ? {content: player.heatInfo, class:'ObTooltipHeat'} : 'Hot'"
              ></i>
            </div>
            <div class="playerInfo">
              <span v-if="player.number">#{{ player.number }}</span>
              <span>{{ player.position }}</span>
              <span>{{ player.lineInfo }}</span>
            </div>
          </td>

          <!-- Schedule / Projections -->
          <template v-if="selectedDataField == 'schedule'">
            <td>{{ player.sAvg.toFixed(1) }}</td>
            <td v-for="(entry, index) in player.gamesByWeek[0]" :key="index" style="border-left: 2px solid var(--obcolor-background-6); width: 75px;">
              <div v-if="entry.game" style="display: flex; align-items: center; justify-content: center; font-size: 12px; margin-bottom: 5px;">
                <img style="height: 22px;" :src="getTeamLogo(entry.game)" :alt="entry.game">
                <div v-if="entry.ts != null" style="margin-left: 5px;">{{ $moment(entry.ts).format("h:mma") }}</div>
              </div>
              <div style="height: 16px; font-size: 12px;">
                <template v-if="entry.game">
                  <span v-if="entry.proj">Proj {{ entry.proj.toFixed(0) }}</span>
                  <span v-else style="color: var(--obcolor-text-faded);">Proj 0</span>
                </template>
              </div>
            </td>
          </template>

          <!-- Season Stats -->
          <template v-else>
            <template v-for="(category, index) in rosterScoringStats">
              <td v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
                :class="{endSection: index1 == category.stats.length - 1 && index != rosterScoringStats.length - 1}"
                style="width: 90px;"
              >
                {{ getStat(player.statistics, stat.path) }}
              </td>
            </template>
          </template>
        </tr>
        <tr v-if="players == null || players.length == 0">
          <td colspan="99" class="noResults">
            <div>No players match your filters</div>
            <div class="clearFilters" @click="clearAllFilters()">Clear Filters</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ObSportDataApi from '@/api/ObSportDataApi';
import ObPlayersApi from '@/api/ObPlayersApi';
import ObLeagueApi from '@/api/ObLeagueApi';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import DropdownButton from '@/components/buttons/DropdownButton';
import EventBus from '@/event-bus';
import FilterButton from '@/components/buttons/FilterButton';
import ObLoading from '@/components/ObLoading';
import GeneralTooltip from '@/components/tooltip/GeneralTooltip';

export default {
  name: 'PlayerList',

  components: {
    PlayerHeadshot,
    DropdownButton,
    FilterButton,
    ObLoading,
    GeneralTooltip,
  },

  data: function() {
    return {
      sports: ['NHL', 'NFL', 'NBA', 'MLB'],
      positions: [],
      teams: [],
      selectedTeam: null,
      selectTeamOpen: false,
      myplayers: {},
      filterMyPlayers: false,
      heatFilters: {},
      selectedSport: 'NHL',
      defaultSport: 'NFL',
      allPlayers: null,
      listLimit: 50,

      nameSearch: '',
      selectedDataField: 'schedule',
      scheduleSort: 'sAvg',
      statsSort: null,
      selectedFilterIndex: 0,
    };
  },

  computed: {
    players() {
      const validPos = {};
      for (const entry of this.positions) {
        if (entry.active) {
          validPos[entry.label] = true;
        }
      }

      let filteredPlayers = this.allPlayers.filter((player) => {
        // Filter name search
        if (this.nameSearch !== '' && this.nameSearch != null) {
          const pname = player.name.toLowerCase();
          const nfilter = this.nameSearch.toLowerCase();
          const nFilters = nfilter.split(' ');

          for (const index in nFilters) {
            const n = nFilters[index];
            if (pname.indexOf(n) != 0 && pname.indexOf(', ' + n) == -1) {
              return false;
            }
          }
        }

        // Filter team
        if (this.selectedTeam && player.teamId != this.selectedTeam.id) {
          return false;
        }

        // Filter My Players
        if (this.filterMyPlayers && !this.myplayers[player.id]) {
          return false;
        }

        // Filter hot and cold
        const heatFilterSet = Object.keys(this.heatFilters).length > 0;
        if (heatFilterSet && !this.heatFilters[player.heat]) {
          return false;
        }

        // Filter position
        return !(Object.keys(validPos).length > 0 && !validPos[player.draftPos]);
      });

      filteredPlayers = filteredPlayers.sort((p1, p2) => {
        if (/day/.test(this.scheduleSort)) {
          return this.sortByDay(p1, p2);
        } else {
          return this.sortByStats(p1, p2);
        }
      });
      return !filteredPlayers ? [] : filteredPlayers.slice(0, this.listLimit);
    },

    rosterScoringStats() {
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return [];
    },

    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.selectedSport);
    },
  },

  watch: {
    '$route.params.sport'() {
      const routeSport = this.$route.params.sport;
      this.allPlayers = null;
      this.selectedSport = routeSport || this.defaultSport;
      this.loadPlayers();
      this.loadTeams();
    },

    selectedSport() {
      // set the last-sport-selected cookie to expire after 7 days
      this.$cookie.set('last-sport-selected', this.selectedSport, 7);
      this.selectedFilterIndex = 0;
      this.resetSortFields();
    },
  },

  created() {
    this.selectSport();
    this.loadPlayers();
    this.loadTeams();
    this.loadMyPlayers();
    this.resetSortFields();
  },

  methods: {
    resetSortFields() {
      if (this.rosterFilters && this.rosterFilters[0] && this.rosterFilters[0].categories &&
         this.rosterFilters[0].categories[0] && this.rosterFilters[0].categories[0].stats &&
        this.rosterFilters[0].categories[0].stats[0].label != null
      ) {
        this.statsSort = this.rosterFilters[0].categories[0].stats[0].path;
      }

      this.scheduleSort = 'sAvg';
    },

    getTeamLogo(game) {
      let alias = game;
      if (game.indexOf('@') != -1) {
        alias = game.substring(1);
      }
      return require('@/assets/logos/'+ this.selectedSport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    selectFilter(rosterIndex) {
      this.selectedFilterIndex = rosterIndex;
      EventBus.$emit('CLOSE_DROPDOWNS');
    },

    changeSport(sport) {
      if (sport != this.selectedSport) {
        this.allPlayers = null;
        this.$router.push('/players/' + sport);
      }
    },

    togglePos(pos) {
      for (const entry of this.positions) {
        if (entry.label === pos) {
          entry.active = !entry.active;
          break;
        }
      }
    },

    setupPositionFilters() {
      const filters = [];
      const sportPositions = this.$SportInfo.getPositions(this.selectedSport);
      for (const pos of sportPositions) {
        filters.push({label: pos, active: false});
      }
      this.positions = filters;
    },

    loadPlayers() {
      const sp = this.selectedSport;
      this.setupPositionFilters();
      ObPlayersApi.getPlayerList(sp)
          .then((data) => {
          // Sport may have been changed while data was still loading
            if (sp === this.selectedSport) {
              this.allPlayers = data;
            }
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    loadMyPlayers() {
      ObLeagueApi.getMyPlayers()
          .then((response) => {
            if (response.myplayers) {
              this.myplayers = response.myplayers;
            }
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    loadTeams() {
      if (!this.selectedSport) {
        return;
      }
      this.clearTeamFilter();
      ObSportDataApi.getTeams(this.selectedSport)
          .then((response) => {
            this.teams = response.sort((a, b) => {
              return a.team_city.localeCompare(b.team_city);
            });
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    openTeamSelector() {
      this.selectTeamOpen = true;
    },

    closeTeamSelector() {
      this.selectTeamOpen = false;
    },

    selectTeam(team) {
      if (!team?.id == this.selectedTeam?.id) {
        return;
      }
      this.selectedTeam = team.id == this.selectedTeam?.id ? null : team;
      this.selectTeamOpen = false;
    },

    getTeamLogoForFilter(team) {
      return require('@/assets/logos/'+ this.selectedSport.toLowerCase() +'_'+ team.toLowerCase() +'.png');
    },

    clearTeamFilter() {
      this.selectedTeam = null;
    },

    sortByDay(p1, p2) {
      const dayNum = Number(this.scheduleSort.charAt(this.scheduleSort.length - 1));
      const p1Entry = p1.gamesByWeek[0][dayNum - 1];
      const p2Entry = p2.gamesByWeek[0][dayNum - 1];

      if ((p1Entry == null || p1Entry.proj == null) && (p2Entry != null && p2Entry.proj != null)) {
        return 1;
      }
      if ((p1Entry != null && p1Entry.proj != null) && (p2Entry == null || p2Entry.proj == null)) {
        return -1;
      }
      if ((p1Entry == null || p1Entry.proj == null) && (p2Entry == null || p2Entry.proj == null)) {
        return 0;
      }
      if (p1Entry.proj > p2Entry.proj) {
        return -1;
      }
      if (p1Entry.proj < p2Entry.proj) {
        return 1;
      }
    },

    sortByStats(p1, p2) {
      const p1Stat = p1[this.scheduleSort];
      const p2Stat = p2[this.scheduleSort];
      if (p1Stat > p2Stat) {
        return -1;
      }
      if (p1Stat < p2Stat) {
        return 1;
      }
    },

    clearNameFilter() {
      this.nameSearch = '';
    },

    clearTypeFilters() {
      this.filterMyPlayers = false;
    },

    clearAllFilters() {
      this.clearTeamFilter();
      this.clearNameFilter();
      this.setupPositionFilters();
      this.clearTypeFilters();
      this.heatFilters = {};
    },

    getStat(statistics, statPath) {
      if (!statistics || !statPath) {
        return '0';
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);
      if (!statistics[playerRole]) {
        return '0';
      }
      const stat = statistics[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    toggleMyPlayers() {
      this.filterMyPlayers = !this.filterMyPlayers;
    },

    toggleHeatFilter(heat) {
      if (this.heatFilters[heat]) {
        this.$delete(this.heatFilters, heat);
      } else {
        this.$set(this.heatFilters, heat, true);
      }
    },

    openPlayerModal(id) {
      EventBus.$emit('OPEN_PLAYER_CARD', id, this.selectedSport);
    },

    selectSport() {
      const routeSport = this.$route.params.sport;

      if (routeSport) {
        this.selectedSport = routeSport;
      } else {
        const lastSportSelected = this.$cookie.get('last-sport-selected');
        if (lastSportSelected) {
          this.selectedSport = lastSportSelected;
        } else {
          try {
            this.selectedSport = this.$UserInfo.mostRelevantLLSport();
          } catch (error) {
            // fallback to the default sport if nothing else works
            this.selectedSport = this.defaultSport;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.searchInput {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  background: var(--obcolor-input-box);
  color: var(--obcolor-font-secondary);

  i {
    margin-right: 7px;
  }

  input {
    background: none;
    padding: 0;
  }
}

.toggleSwitch {
  display: inline-block;
  color: var(--obcolor-font-secondary);
  background: var(--obcolor-btn-unselected);
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: var(--obcolor-hover-selection-light);
  }
  &:active {
    background: var(--obcolor-hover-selection-dark);
  }
}

.toggleSwitch.selected {
  color:white;
  background: var(--obcolor-btn-selected);

  &:hover {
    background: var(--obcolor-accent-light-hl);
  }
  &:active {
    background: var(--obcolor-accent-light);
  }
}

.toggleSwitch:first-child {
  border-radius: 5px 0 0 5px;
}

.toggleSwitch:last-child {
  border-radius: 0 5px 5px 0;
}

table {
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.tableHeader1 {
  th {
    font-size: 12px;
    padding: 10px 8px 0px 8px;
    background: var(--obcolor-background-2);
    color: var(--obcolor-font-secondary);
  }
  th:first-child {
    border-radius: 5px 0 0 0;
  }
  th:last-child {
    border-radius: 0 5px 0 0;
  }
}

.tableHeader2 {
  th {
    font-size: 12px;
    padding: 6px;
    position: sticky;
    transition: top 0.3s ease-out;
    top: var(--ob-nav-offset);
    z-index: 80;
    background: var(--obcolor-background-2);
    color: var(--obcolor-font-secondary);
  }

  th:not(.nameHeader) {
    width: 70px;
    box-sizing: border-box;
  }
}

.endSection {
  border-right: 2px solid var(--obcolor-background-2);
}

th.selected {
  background: var(--obcolor-background-4);
  color: var(--obcolor-font-primary);
}

th.sortable {
  cursor: pointer;
}

th.sortable:hover {
  background: var(--obcolor-background-3);
}

td {
  padding: 8px;
  border-bottom: 2px solid var(--obcolor-background-6);
}

tr:nth-child(even) {
  background: var(--obcolor-background-5);
}
tr:nth-child(odd) {
  background: var(--obcolor-background-4);
}

.nameField {
  text-align: left;
  font-weight: bold;
  min-width: 180px;
  white-space: nowrap;
  font-size: 14px;
}

// Copied from lobby, could be component
.filterGroup {
  font-size: 14px;
  padding: 0 10px;
  margin-bottom: 10px;
}

.btnGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.btnFilter {
  background-color: var(--obcolor-background-2);
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  border: 1px solid var(--obcolor-background-2);
  border-radius: 10px;
  width: 94px;
  height: 40px;
  box-sizing: border-box;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    margin-left: -5px;
  }
  &.btnSelectedTeam {
    color: white;
    background-color: var(--obcolor-ob-blue);
    &:hover {
      background-color: var(--obcolor-ob-blue-tinted);
    }
    &:active {
      background-color: var(--obcolor-accent-hl);
    }
  }
  &.btnSelected {
    color: white;
    background-color: var(--obcolor-ob-blue);
    &:hover {
      background-color: var(--obcolor-ob-blue-tinted);
    }
    &:active {
      background-color: var(--obcolor-accent-hl);
    }
    img {
      filter: brightness(0) invert(1);
    }
    i {
      color: white;
    }
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.btnFilter:hover {
  background-color: var(--obcolor-bg-hover);
}

.btnFilter:active {
  background-color: var(--obcolor-background-5);
}

.filterTitle {
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  padding: 10px 0;
}

.filterIcon {
  color: var(--obcolor-font-secondary);
  font-size: 18px;
  padding: 5px 10px;
  cursor: pointer;
}

.filterIconContainer {
  cursor: pointer;
  width: 52px;
  color: var(--obcolor-font-secondary);
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterText {
  font-size: 12px;
  width: max-content;
  margin-top: -5px;
}

.optionButton {
  padding: 5px 10px !important;
  cursor: pointer;
}

.boldTitle {
  font-weight: bold;
  color: var(--obcolor-font-primary);
}

.selectTeamBtn {
  position: relative;
  white-space: nowrap;
  min-width: 170px;
  > img {
    height: 28px;
    margin-right: 6px;
  }
}

.clearFilterBtn {
  width: 35px;
  margin-left: -5px;
}

.teamSelectorMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 95;
}

.teamSelector {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
  background: var(--obcolor-background-4);
  border: 2px solid var(--obcolor-background-1);
  padding: 10px;
  width: 900px;
  z-index: 96;
  border-radius: 10px;
}

.teamBtn {
  width: 170px;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 15px;
  font-weight: normal;
  > img {
    height: 28px;
    margin-left: 6px;
  }
}

.playerInfo {
  font-weight: normal;
  color: var(--obcolor-font-secondary);
  margin-top: 3px;
  margin-bottom: -3px;
  span {
    margin-right: 5px;
  }
}

.playerPos {
  font-weight: bold;
  font-size: 16px;
  position: relative;
  height: 62px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .myPlayerIcon {
    $iconSize: 24px;
    width: $iconSize;
    height: $iconSize;
    position: absolute;
    top: calc(-#{$iconSize} / 2);
    left: calc(-#{$iconSize} / 2);
    transform: rotate(45deg);
    background: var(--obcolor-sportscolour-nba);
  }
}

.noResults {
  white-space: nowrap;
  color: var(--obcolor-font-secondary);
  padding: 12px;
}

.clearFilters {
  padding: 4px 10px;
  margin-top: 6px;
  color: var(--obcolor-font-primary);
  display: inline-block;
  background: var(--obcolor-background-2);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: var(--obcolor-background-1);
  }
}

.coldIcon {
  color: var(--obcolor-player-cold);
  margin-left: 5px;
}

.hotIcon {
  color: var(--obcolor-player-hot);
  margin-left: 5px;
}

.myPlayersBtn {
  width: 100px;
}

.heatFilterBtn {
  width: 50px;
}

</style>
