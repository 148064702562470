<template>
  <div>
    <h1 style="font-size: 16px; font-weight: bold; margin: 0;">OWNERSBOX&trade; PRIVACY POLICY</h1>
    <p>Last modified: February 24, 2020</p>
    <p>OwnersBox 3.0 Corporation (&ldquo;<strong>OwnersBox</strong>&rdquo;) operates the OwnersBox mobile application, OwnersBox Fantasy Sports (&ldquo;<strong>App</strong>&rdquo;) and the OwnersBox.com website (&ldquo;<strong>Site</strong>&rdquo;), and the fantasy sports competition and information services offered through our platform (together with the Apps and the Sites, the &ldquo;<strong>Services</strong>&rdquo;).&nbsp;</p>
    <p>OwnersBox respects and protects the privacy of the users that use our Services. We maintain strict policies to ensure the privacy of those who use our Services (&ldquo;<strong>End Users</strong>&rdquo;) or those who may just be visiting our Site (&ldquo;<strong>Visitors</strong>&rdquo;). This policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;) describes the types of information we may collect from you and our practices for how we collect, use, maintain, protect, and disclose such information. The way that we collect and use your information depends on the way you access the Services (whether by Site or by App). This Privacy Policy also includes a description of certain rights that you may have over information that we may collect from you.&nbsp;</p>
    <p>By using the Services, you agree to this Privacy Policy. If you do not agree with our policies and practices, your choice is to not use our Services.</p>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td colspan="7">
            <p><strong>Summary</strong></p>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <p>This chart is a summary of data collected and data disclosed. The rest of this Privacy Policy provides additional information on our privacy practices.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            <p><strong>Categories of Personal Information</strong></p>
          </td>
          <td colspan="2">
            <p><strong>Collection</strong></p>
          </td>
          <td colspan="2">
            <p><strong>Disclosure</strong></p>
          </td>
          <td colspan="2">
            <p><strong>Sale</strong></p>
          </td>
        </tr>
        <tr>
          <td style="width: 110px;">
            <p><strong>Do we collect?</strong></p>
          </td>
          <td style="width: 110px;">
            <p><strong>Collected in past 12 months</strong></p>
          </td>
          <td style="width: 110px;">
            <p><strong>For a business purpose</strong></p>
          </td>
          <td style="width: 110px;">
            <p><strong>For a business purpose in past 12 months</strong></p>
          </td>
          <td style="width: 110px;">
            <p><strong>Do we sell?</strong></p>
          </td>
          <td style="width: 110px;">
            <p><strong>Sold in past 12 months</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Identifiers:&nbsp;</p>
            <p>IP addresses; cookies, beacons, pixel tags, mobile ad identifiers, customer numbers, unique pseudonyms, user aliases, and telephone numbers.</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Categories of PI described in Section 1798.80(e) of the CCPA (including name, email, phone number, mailing address, birthday, SSN, driver&rsquo;s license number, and bank account number)</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Commercial information</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Geolocation data</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Internet or other electronic network activity information</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Inferences drawn from other personal information to create a profile about a consumer.</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>YES</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Characteristics of protected classifications under California or federal law</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Biometric information</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Audio, visual or similar information</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Professional or employment related information</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Non-public education information (per the Family Educational Rights and Privacy Act)</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
          <td style="text-align: center;">
            <p>NO</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td colspan="4">
            <p><strong>Information that OwnersBox Collects</strong></p>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            <p><strong>Types of Information Collected</strong></p>
          </td>
          <td colspan="2">
            <p>Personal Data</p>
          </td>
          <td>
            <p>&ldquo;Personal Data&rdquo; is information by which you may be personally identified. &nbsp;OwnersBox may collect the following Personal Data from you:</p>
            <br>
            <ul>
              <li>Name;</li>
              <li>Profile Photos;</li>
              <li>Email;</li>
              <li>Phone Number;</li>
              <li>Mailing Address;&nbsp;</li>
              <li>Screen Name;</li>
              <li>Birthdate;</li>
              <li>Payment Information;</li>
              <li>Social Security Number;&nbsp;</li>
              <li>Driver&rsquo;s License Number or Government-Issued ID Card Number;</li>
              <li>Bank account number (through 3rd party services).&nbsp;</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>Non-Personal Data</p>
          </td>
          <td>
            <p>Non-personal data includes any data that cannot be used on its own to identify, trace, or identify a person. We may collect your IP Address, device information, or location information.&nbsp;</p>
            <br>
            <p>When non-Personal Data you give to us is combined with Personal Data we collect about you, it will be treated as Personal Data and we will only use it in accordance with this Privacy Policy.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>How </strong><strong>we collect information.</strong></p>
          </td>
          <td colspan="3">
            <p>We collect information about you in three ways:&nbsp;</p>
            <p>(1) when you provide it do us directly through an interaction with us; for example</p>
            <ul>
              <li>When you register for the service;</li>
              <li>When you enter a contest;</li>
              <li>When you take a survey;&nbsp;</li>
              <li>When you participate in surveys, chats, or other forums;&nbsp;</li>
              <li>When you contact us for service requests; and</li>
              <li>When we have received your information through our refer-a-friend program.</li>
            </ul>
            <p>&nbsp;(2) through automated collection methods like cookies or log files; and&nbsp;</p>
            <p>(3) when we obtain the information through a third party, including third party data verification entities and through our referral programs.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Why we collect and how we use your information. (Legal Basis)</strong></p>
          </td>
          <td colspan="3">
            <p>We collect and use your Personal Data when we have a legitimate purpose to do so, including the following reasons:</p>
            <br>
            <ul>
              <li>to verify your eligibility to use the Services (age verification, location, etc);&nbsp;</li>
              <li>to confirm identity to use the Services;</li>
              <li>when it is necessary for the general functioning of the Services, including to facilitate payments or to contact you;&nbsp;</li>
              <li>when it is necessary in connection with any contract you have entered into with us (including our terms of service or when you make a purchase through the site) or to take steps prior to entering into a contract with us;</li>
              <li>when we have obtained your or a third party&rsquo;s prior consent to the use, including to communicate with you about our features and about upcoming events offered by sports teams and other partners with whom we have relationships;</li>
              <li>when we have a legitimate interest in processing your information for the purpose of providing or improving our Services;&nbsp;</li>
              <li>when have a legitimate interest in using the information for the purpose of contacting you, subject to compliance with applicable law; or</li>
              <li>when we have a legitimate interest in using the information for the purpose of detecting, and protecting against, breaches of our policies and applicable laws.</li>
            </ul>
            <p>We may use aggregated (anonymized) information about our End Users, and information that does not identify any individual, without restriction.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Information Collected from Third Parties</strong></p>
          </td>
          <td colspan="3">
            <p><em>Information from our service providers:</em>&nbsp;We may receive information about you from third-party service providers that we engage for marketing our products and services.</p>
            <br>
            <p><em>Information from social media sites and other publicly available sources :</em>&nbsp;When you interact or engage with us on social media sites through posts, comments, questions and other interactions, we may collect such publicly&nbsp;accessible&nbsp;information, including profile information, to allow us to connect with you, improve our products, or better understand user reactions and issues. We must tell you that once collected, this information may remain with us even if you delete it from the social media sites. We may also add and update information about you, from other publicly available sources.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Accessing and Controlling Your Information</strong></p>
          </td>
          <td colspan="3">
            <p>If you would like to prevent us from collecting your information completely, you should cease use of our Services. You can also control certain data via these other methods:</p>
            <br>
            <p>Opt-out of non-essential electronic communications:&nbsp;You may opt out of receiving newsletters and other non-essential messages by using the &lsquo;unsubscribe' function included in all such messages. However, you will continue to receive notices and essential transactional emails.</p>
            <br>
            <p>Optional information:&nbsp;You can always choose not to fill in non-mandatory fields when you submit any form linked to our services.</p>
            <br>
            <p>If you are a California resident, you also have the rights disclosed below.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Data Rights for California Individuals</strong></p>
          </td>
          <td colspan="3">
            <p>You have the following rights with respect to information that we hold about you. We attempt to provide you the same rights no matter where you choose to live.</p>
            <br>
            <p><em>Right to access:</em>&nbsp;You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose and period of processing, and the persons to whom the information is shared.</p>
            <br>
            <p><em>Right to rectification:</em>&nbsp;You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database.</p>
            <br>
            <p><em>Right to erasure:</em>&nbsp;You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.</p>
            <br>
            <p><em>Right to restriction of processing:</em>&nbsp;You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
            <br>
            <p><em>Right to data portability:</em>&nbsp;You have the right to transfer your information to a third party in a structured, commonly used and machine-readable format, in circumstances where the information is processed with your consent or by automated means.</p>
            <br>
            <p><em>Right to object</em>: You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Exercise Your Data Rights</strong></p>
          </td>
          <td colspan="3">
            <p>We acknowledge your right to request access, amendment, or deletion of your data. We also recognize that you have the right to prohibit sale of your data, but we do not sell data.&nbsp;</p>
            <br>
            <p>You can exercise the rights described above, by sending an email or mail to the addresses listed in the Contact section below. Only you, or an agent authorized to make a request on your behalf, may make a request related to your personal information.&nbsp;</p>
            <br>
            <p>We cannot respond to your request if, (i) we cannot verify your identity; or (ii) your request lacks sufficient details to help us handle the request. We will make our best efforts to respond to your request withing 45 days of its receipt. If we cannot respond in 45 days, we will inform you, in writing, the reason for the delay and will respond to your request within 90 days. Any information we provide will only cover the 12-month period preceding the request's receipt.&nbsp;</p>
            <br>
            <p>We do not charge a fee to process or respond to your request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. We are not obligated to provide responses to your data requests more than twice in a 12-month period.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>How Long do we Store Personal Data?</strong></p>
          </td>
          <td colspan="3">
            <p>We will only retain your Personal Data for as long as is necessary to fulfil the purposes for which it is collected. This length of time may vary according to the nature of your relationship with us. Personal Data will be purged after five years of nonuse of an account.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            <p><strong>Automated Data Collection Methods</strong></p>
          </td>
          <td>
            <p><strong>Cookies</strong></p>
          </td>
          <td colspan="2">
            <p>A cookie is a small file placed on the hard drive of your computer. Cookies are used to help us manage and report on your interaction with the Site. Through cookies, we are able to collect information that we use to improve the Services, keep count of return visits to our website, authenticate your login credentials, manage multiple instances of the Site in a single browser, keep track of your shopping cart, keep track of usernames and passwords, monitor click-stream data, and tailor your experience on the Services. Cookies may also collect other data such as the date and time you visited the Site, and your current IP address. If you turn off cookies, your experience on the Services may be impaired.</p>
            <br>
            <p>Advertisers and third-parties that deliver ads to you on the Site may place or recognize a unique cookie on your hard drive. These types of non-OwnersBox cookies are known as &ldquo;third-party cookies&rdquo;; Third-party cookies may be used by third parties in order to collect information about you. The use of third-party cookies is not covered by this privacy policy. We do not have access to or control over cookies placed by advertisers and other third parties.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Log Files</strong></p>
          </td>
          <td colspan="2">
            <p>We use means through the Services to collect IP addresses, browser types, access times, domain names, and physical location. We use this information to ensure compliance with various state or provincial laws, including by verifying location. Occasionally, we use the information to improve our Services, verify identity, and monitor financial transactions.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Users under the age of 16</strong></p>
          </td>
          <td colspan="3">
            <br>
            <p>Our Services are not intended for anyone under 18, particularly children under 16 years of age and we do not knowingly collect Personal Data from children under 16. If you are under 16, do not use or register on the Services, make any purchases, use any of the interactive or public comment features, or provide any information about yourself to us. If we learn we have collected or received Personal Data from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at the email address listed below.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Do Not Track Settings</strong></p>
          </td>
          <td colspan="3">
            <p>We do not track our Users over time and across third party websites to provide targeted advertising and do not specifically respond to Do Not Track (&ldquo;DNT&rdquo;) signals.&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Referral Programs</strong></p>
          </td>
          <td colspan="3">
            <p>We think OwnersBox is even more fun when you play with people you know, so we provide a way for friends to invite other friends to Join OwnersBox. In those instances, we will collect emails provided by one user and send invitations to those emails for more users to join OwnersBox. When this happens, we rely on the inviting user to provide the required consent that we can send the invitee an email. Once an email is sent, we will not send the invitee additional communications unless the invitee signs up for our services.&nbsp;</p>
            <br>
            <p>If you have received an email and you would like for us to remove your information from our database, you may contact us at <a href="mailto:info@OwnersBox.com">info@OwnersBox.com</a> to request the removal.</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td colspan="2">
            <p><strong>Who We Share Data With</strong></p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <br>
            <p>We may use aggregated (anonymized) information about our End Users and Visitors, and information that does not identify any individual, without restriction.</p>
            <br>
            <p>We do not sell or otherwise disclose Personal Data specific personal or transactional information to anyone except as described below.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Other Users Via Platform Contest and Leaderboards&nbsp;</p>
          </td>
          <td>
            <p>If you enter into contests on the services, we may publish the information below in contest leaderboards or winner lists for the purpose of transparency in our contests. Unless you disclose personal information within these data points, other users are unable to directly identify the name of the account owner.&nbsp;</p>
            <ul>
              <li>Username;</li>
              <li>Player rank/rating;</li>
              <li>Profile photos; and</li>
              <li>First name and last initial.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Affiliates and Subsidiaries</p>
          </td>
          <td>
            <p>We may, for our legitimate interests, share your information with entities under common ownership or control with us who will process your information in a manner consistent with this Privacy Policy and subject to appropriate safeguards. Such parent companies, affiliates, or subsidiaries may be located in the United States or Canada.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Successors in Interest.</p>
          </td>
          <td>
            <p>We may, for our legitimate interests, share your information with a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, in which Personal Data about our End Users is among the assets transferred. You will be notified of any such change by a prominent notice displayed on our Services or by e-mail. Any successor in interest to this Privacy Policy will be bound to the Privacy Policy at the time of transfer.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Law enforcement and other governmental agencies.</p>
          </td>
          <td>
            <p>We may share your information when we believe in good faith that such sharing is reasonably necessary to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Service Providers.</p>
          </td>
          <td>
            <p>We may, for our legitimate interests, share certain information with contractors, service providers, third party authenticators, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Data confidential and use it only for the purposes for which we disclose it to them. Some of the functions that our service providers provide are as follows:&nbsp;</p>
            <br>
            <ul>
              <li>Host server infrastructure and storage;</li>
              <li>Business analytics services;</li>
              <li>In-App user management;</li>
              <li>Identity verification management;&nbsp;</li>
              <li>Payment processing;</li>
              <li>Site log analytics service for activity, performance, and troubleshooting;</li>
              <li>App log analytics service for activity, performance, and troubleshooting;</li>
              <li>Infrastructure monitoring for app performance;</li>
              <li>Advertising;&nbsp;</li>
              <li>Lead generation;&nbsp;</li>
              <li>Marketing, sales, and service management; and&nbsp;</li>
              <li>Email management services.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td>
            <p><strong>Third-Party Services and Websites</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <br>
            <p>OwnersBox is not responsible for the privacy policies or other practices employed by websites linked to, or from, our Site or App nor the information or content contained therein, and we encourage you to read the privacy statements of any linked third party. This includes sharing information via social media websites.&nbsp;</p>
            <br><br>
          </td>
        </tr>
      </tbody>
    </table>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td>
            <p><strong>Data Storage and How OwnersBox</strong><strong>Protects Your Information</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <br>
            <p>OwnersBox stores basic End User data on our servers including name, email, phone number, address, and username. Payment information is processed and stored by our partners or service providers. Personal Data about End Users and Visitors is stored within the United States or Canada. The Services are only intended to be used inside the United States or Canada. If you are using the Services from the EEA or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your Personal Data to the United States or Canada. The United States and Canada may have laws which are different, and potentially not as protective, as the laws of your own country. By providing your Personal Data, you consent to any transfer and processing in accordance with this Privacy Policy. For the avoidance of doubt, our platform is not intended for any users outside of the United States or Canada.&nbsp;</p>
            <br>
            <p>OwnersBox employs physical, electronic, and managerial control procedures to safeguard and help prevent unauthorized access to your information. We choose these safeguards based on the sensitivity of the information that we collect, process and store and the current state of technology. Our outsourced service providers who support our operations are also vetted to ensure that they too have the appropriate organizational and technical measures in place to protect your information</p>
            <br>
            <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Data, we cannot guarantee the security of your information transmitted to the Services. Any transmission of information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Services. In the event that there is breach in the information that we hold; we shall notify of such breach via email or via notice on the Services.</p>
            <br><br>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td>
            <p><strong>Changes to the Privacy Policy</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <br>
            <p>It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes to how we treat our End Users&rsquo; or Visitors&rsquo; Personal Data, we will notify you by email to the primary email address specified in your account or through a prominent notice on the Site or App. Such changes will be effective when posted. The date the Privacy Policy was last revised is identified at the top of the page. Your continued use of our Services following the posting of any modification to this Privacy Policy shall constitute your acceptance of the amendments to this Privacy Policy. You can choose to discontinue use of the Service if you do not accept any modified version of this Privacy Policy.</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table aria-label="Privacy">
      <tbody>
        <tr>
          <td>
            <p><strong>Questions and Comments</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p>If you have any questions or comments about this Privacy Policy, or if you would like to file a request about the data we hold or file a deletion request, please contact us at <a href="mailto:info@OwnersBox.com">info@OwnersBox.com</a> or by phone at 888-912-8780.</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Privacy Policy | OwnersBox Fantasy Sports',
    meta: [
      {name: 'description', content: 'The official OwnersBox privacy policy.'},
      {name: 'og:description', content: 'The official OwnersBox privacy policy.'},
    ],
  },

};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}
tr:first-child {
  td {
    background: var(--obcolor-background-2);
  }
}
th {
  background: var(--obcolor-background-2);
}
td:first-child {
  min-width: 130px;
}
td {
  height: 25px;
}
th, td {
  border: 1px solid var(--obcolor-background-2);
  padding: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 40px;
  text-align: left;
}
</style>