<template>
  <div v-if="isOpen && league != null">
    <Modal v-model="isOpen"
      containerStyle="height: 800px; max-height: calc(100% - 30px); width: 600px; max-width: calc(100% - 60px); padding: 10px 10px 0 10px; overflow: hidden"
      bodyStyle="margin: 0 -10px; padding: 10px; background: var(--obcolor-background-page);"
      @scrollBody="checkScrollPosition"
    >
      <!-- Player Summary -->
      <div class="ob-box summaryContainer" slot="header">
        <div style="padding: 10px 20px; 10px 30px;">
          <LeagueModalSummary v-if="league != null" :league="league" />
        </div>
        <div class="navigationMenu">
          <div class="navBtn" :class="{'navSelected': selectedTab == 'details'}" @click="selectedTab = 'details'">DETAILS</div>
          <div v-if="((league.type == 'friend' || league.type == 'group') && league.state == 'NOT_STARTED' && league.isPrivate) || league.ctype == 'Tournament'"
            class="navBtn" :class="{'navSelected': selectedTab == 'entrants'}" @click="selectedTab = 'entrants'"
          >
            ENTRANTS
          </div>
          <div v-if="allowInviting == true && league.state == 'NOT_STARTED' && league.isPrivate" class="navBtn"
            :class="{'navSelected': selectedTab == 'invite'}" @click="selectTab('invite')"
          >
            INVITE
          </div>
          <div style="flex: 1;"></div>
          <div v-if="league.ctype == 'Tournament' && league.state === 'FILLING'">
            <button @click="openInviteModal()" class="ob-btn shareContestLink">
              <i class="fas fa-share-square"></i>
              <div>Share Contest</div>
            </button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyMessage }"></div>
          </div>
          <div class="ob-btn navBtn" style="color: white;" @click="openReferModal()">
            <i class="fas fa-envelope"></i> Refer
          </div>
        </div>
      </div>
      <div slot="body" style="height: 100%; position: relative;">
        <SponsorImage v-if="contestSponsorBanner && selectedTab == 'details'" :sponsor="contestSponsorBanner" height="77px"
          width="569px" class="sponsorBox"
        />
        <template v-if="selectedTab == 'details'">
          <LeagueModalDescription class="ob-box infoContainer" :league="league" />
          <LeagueModalOverview ref="details" v-if="isRefVisible('details')" class="ob-box infoContainer"
            :league="league"
          />
          <LeagueModalScoring ref="scoring" v-if="isRefVisible('scoring')" class="ob-box infoContainer"
            :league="league"
          />
          <LeagueModalPayout ref="payouts" v-if="isRefVisible('payouts')" class="ob-box infoContainer"
            :league="league"
          />
          <div style="height: 1px;"></div>
        </template>
        <template v-else-if="selectedTab == 'entrants'">
          <LeagueModalTournamentPlayers v-if="league.ctype === 'Tournament'" :league="league" />
          <LeagueModalPlayers v-else :league="league" />
        </template>
        <template v-else-if="selectedTab == 'invite'">
          <LeagueModalInvite :league="league" :friendsList="friendsList"
            :userAddingList="userAddingList" @addInvitedUser="addInvitedUser"
            @removeInvitedUser="removeInvitedUser"
          />
        </template>
      </div>

      <!-- Clear the close button at the bottom of the modal -->
      <div slot="footer" v-if="!isDraft">
        <div v-if="league.ctype === 'Tournament'" style="font-size: 12px; margin-top: 10px;">
          By entering a lineup in this contest you are agreeing to the OwnersBox
          <router-link class="ob-hyperlink" to="/terms" target="_blank">Terms of Service</router-link>
          and contest rules. Entry into contest is not guaranteed until lineup submission.
        </div>

        <div v-else class="autopickFooter">
          <!-- Draft Priority -->
          <div class="draftPriorityContainer">
            <div>In the event that you are not present for the start of the draft, your first selection will be:</div>
            <select v-model="draftPrioritySelection" style="margin-top: 7px; margin-left: -1px; font-size: 12px;">
              <option value="">Highest Average Projection (Default)</option>
              <option v-for="(option, index) in draftPriorityOptions" :key="index" :value="option.value">
                {{ option.label }}
              </option>
            </select>
            <span style="margin-left: 15px;">{{ draftPriorityStatus }}</span>
          </div>

          <div style="font-size: 12px; margin-top: 5px;">
            By joining this contest, you are accepting the OwnersBox
            <router-link class="ob-hyperlink" to="/terms" target="_blank">Terms of Service</router-link>
            and contest rules and acknowledge that in the event that you are not present during your draft selection,
            the system will select the highest projected player according to your Draft Priority settings.
          </div>
        </div>

        <div class="modalFooter">
          <div style="flex: 1; min-width: 0; text-overflow: ellipsis; overflow: hidden;">
            <div class="errorMessage" v-if="geolocationError">
              OwnersBox requires access to your location to join a contest.
            </div>

            <div class="errorMessage" v-else-if="leagueErrorMessage != ''">{{ leagueErrorMessage }}</div>
          </div>

          <button class="modal-default-button ob-btn-grey ob-btn-med" style="margin-right: 10px;" @click="closeModal()">
            Close
          </button>

          <!-- Salary Cap Create Lineup -->
          <template v-if="league.ctype === 'Tournament' && !inLineupCreator()">
            <button v-if="league.contestStart && $moment().diff($moment(league.contestStart)) > 0" disabled class="modal-default-button ob-btn-grey ob-btn-med">
              Contest Started
            </button>
            <router-link v-else :to="getLineupLink()" class="modal-default-button ob-btn-med">
              Build Lineup
            </router-link>
          </template>

          <!-- Join and Leave Buttons -->
          <template v-else-if="league.creatorId != $store.getters.userId || !league.isPrivate">
            <button class="modal-default-button ob-btn-med ob-btn-grey" style="margin-right: 10px;" disabled
              v-if="decliningLeague == true"
            >
              <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
              Declining...
            </button>

            <div v-else-if="isInvitedNotJoined()" class="ob-btn-red ob-btn-med" style="margin-right: 10px;"
              @click="declineLeagueInvite()"
            >
              Decline
            </div>

            <template v-if="!inLeague()">
              <button class="modal-default-button ob-btn-med ob-btn-grey" disabled v-if="joiningLeague == true">
                <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
                Joining...
              </button>

              <button v-else-if="!hasSufficientFunds()" disabled class="modal-default-button ob-btn-grey ob-btn-med">
                Insufficient Funds
              </button>

              <button v-else-if="contestLimits && contestLimits.contestLimit && contestLimits.contestLimit <= contestLimits.activeContest" disabled class="modal-default-button ob-btn-grey ob-btn-med">
                Contest Limit Reached
              </button>

              <button v-else-if="contestLimits && contestLimits.entryFeeLimit && contestLimits.entryFeeLimit < league.feeValue" disabled class="modal-default-button ob-btn-grey ob-btn-med">
                Entry Above Limit
              </button>

              <button v-else-if="league.state == 'NOT_STARTED' && !geolocationError" class="modal-default-button ob-btn-med" @click="joinLeagueConfirm()">
                Join Contest
              </button>

              <button v-else disabled class="modal-default-button ob-btn-grey ob-btn-med">
                {{ geolocationError ? 'Location Required' : 'Join Contest' }}
              </button>
            </template>

            <button class="modal-default-button ob-btn-med ob-btn-grey" disabled v-else-if="leavingLeague == true">
              <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
              Leaving...
            </button>

            <button v-else-if="league.state == 'NOT_STARTED'" class="modal-default-button ob-btn-med ob-btn-red" @click="leaveLeagueConfirm()">
              Leave Contest
            </button>
          </template>
          <button v-else-if="disbandingLeague == true" disabled class="modal-default-button ob-btn-med ob-btn-grey">
            <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
            Disbanding...
          </button>

          <button v-else-if="invitingPlayers == true" disabled class="modal-default-button ob-btn-med ob-btn-grey">
            <i class="fad fa-spinner-third fa-spin" style="margin-right: 1ex;"></i>
            Inviting...
          </button>

          <button v-else-if="selectedTab != 'invite' && league.state == 'NOT_STARTED'"
            class="modal-default-button ob-btn-med ob-btn-red" @click="disbandLeagueConfirm()"
          >
            Disband Contest
          </button>

          <button v-else-if="selectedTab == 'invite' && league.state == 'NOT_STARTED'" class="modal-default-button ob-btn-med" @click="invitePlayers()">
            Invite
          </button>
        </div>
      </div>
    </Modal>

    <ConfirmationModal :title="'League Alert'" :text="leagueAlertText"
      :eventBusName="'OPEN_LEAGUE_ALERT_CONFIRMATION_MODAL'" @confirm="joinLeague()"
    />
    <ConfirmationModal :title="'Leaving League'" :text="'Are you sure that you want to leave '
      + (league.contestName != null ? league.contestName : 'this contest') + '?'"
      :eventBusName="'OPEN_LEAGUE_LEAVE_CONFIRMATION_MODAL'" @confirm="leaveLeague()"
    />
    <ConfirmationModal :title="'Disbanding League'" :text="'Are you sure that you want to disband '
      + (league.contestName != null ? league.contestName : 'this contest') + '?'"
      :eventBusName="'OPEN_LEAGUE_DISBAND_CONFIRMATION_MODAL'" @confirm="disbandLeague()"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObSocialApi from '@/api/ObSocialApi';
import ObAccountApi from '@/api/ObAccountApi';
import LeagueModalSummary from '@/views/Lobby/LeagueModal2/LeagueModalSummary';
import LeagueModalOverview from '@/views/Lobby/LeagueModal2/LeagueModalOverview';
import LeagueModalPayout from '@/views/Lobby/LeagueModal2/LeagueModalPayout';
import LeagueModalScoring from '@/views/Lobby/LeagueModal2/LeagueModalScoring';
import LeagueModalTournamentPlayers from '@/views/Lobby/LeagueModal2/LeagueModalTournamentPlayers';
import LeagueModalPlayers from '@/views/Lobby/LeagueModal2/LeagueModalPlayers';
import LeagueModalInvite from '@/views/Lobby/LeagueModal2/LeagueModalInvite';
import LeagueModalDescription from '@/views/Lobby/LeagueModal2/LeagueModalDescription';
import ConfirmationModal from '@/components/ConfirmationModal';
import SponsorImage from '@/components/sponsors/SponsorImage';
import Modal from '@/components/Modal';
import {mapState} from 'vuex';

export default {

  components: {
    LeagueModalSummary,
    LeagueModalOverview,
    LeagueModalPayout,
    LeagueModalScoring,
    LeagueModalTournamentPlayers,
    LeagueModalPlayers,
    LeagueModalInvite,
    LeagueModalDescription,
    Modal,
    ConfirmationModal,
    SponsorImage,
  },

  data() {
    return {
      // Used to avoid saving draft priority (via watcher) when setting the default value
      modalInitialized: false,

      league: null,
      isDraft: false,
      isOpen: false,
      autopickType: 'proj',
      isDefaultChecked: false,
      refNames: [
        {ref: 'details', visible: false},
        {ref: 'scoring', visible: false},
        {ref: 'payouts', visible: false},
      ],
      refScrolledTo: '',
      selectedTab: 'details',
      sectionTopMargin: 10,

      leagueErrorMessage: '',
      friendsList: [],
      userAddingList: [],

      leagueAlertText: '',

      isEntered: false,
      isInvited: false,

      disbandingLeague: false,
      joiningLeague: false,
      leavingLeague: false,
      decliningLeague: false,
      invitingPlayers: false,

      geolocationError: false,
      geolocationWarning: false,

      copyLinkTimeout: null,
      showCopyMessage: false,

      draftPrioritySelection: '',
      draftPriorityStatus: '',
      draftPriorityOpt: {
        NHL: [
          {label: 'Highest Projected Forward', value: 'F'},
          {label: 'Highest Projected Defense', value: 'D'},
          {label: 'Highest Projected Goalie', value: 'G'},
        ],
        NBA: [
          {label: 'Highest Projected Point Guard', value: 'PG'},
          {label: 'Highest Projected Shooting Guard', value: 'SG'},
          {label: 'Highest Projected Small Forward', value: 'SF'},
          {label: 'Highest Projected Power Forward', value: 'PF'},
          {label: 'Highest Projected Center', value: 'C'},
        ],
        MLB: [
          {label: 'Highest Projected Catcher', value: 'C'},
          {label: 'Highest Projected 1st Base', value: '1B'},
          {label: 'Highest Projected 2nd Base', value: '2B'},
          {label: 'Highest Projected Shortstop', value: 'SS'},
          {label: 'Highest Projected 3rd Base', value: '3B'},
          {label: 'Highest Projected Outfield', value: 'OF'},
          {label: 'Highest Projected Pitcher', value: 'P'},
        ],
        NFL: [
          {label: 'Highest Projected Quarterback', value: 'QB'},
          {label: 'Highest Projected Runningback', value: 'RB'},
          {label: 'Highest Projected Wide Receiver', value: 'WR'},
          {label: 'Highest Projected Tight End', value: 'TE'},
          {label: 'Highest Projected Kicker', value: 'K'},
          {label: 'Highest Projected Defense', value: 'D'},
        ],
      },
    };
  },

  created() {
    EventBus.$on('UPDATE_UPCOMING_LEAGUES', this.setLeagueData);
    EventBus.$on('OPEN_LEAGUE_MODAL_BY_ID', this.openModalById);
    EventBus.$on('OPEN_LEAGUE_MODAL', this.openModal);
    EventBus.$on('OPEN_DRAFT_LEAGUE_MODAL', this.openDraftModal);
    EventBus.$on('LEAGUE_JOIN_ERROR', this.leagueError);
    EventBus.$on('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
  },

  destroyed() {
    EventBus.$off('UPDATE_UPCOMING_LEAGUES', this.setLeagueData);
    EventBus.$off('OPEN_LEAGUE_MODAL_BY_ID', this.openModalById);
    EventBus.$off('OPEN_LEAGUE_MODAL', this.openModal);
    EventBus.$off('OPEN_DRAFT_LEAGUE_MODAL', this.openDraftModal);
    EventBus.$off('LEAGUE_JOIN_ERROR', this.leagueError);
    EventBus.$off('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (newVal === false) {
        this.resetData();
      }
    },

    draftPrioritySelection(to, from) {
      if (this.modalInitialized) {
        this.saveDraftPriority();
      }
    },

    showCopyMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyLinkTimeout);
        this.copyLinkTimeout = setTimeout(function() {
          self.resetCopyLinkVar();
        }, 3000);
      }
    },

    '$route.name'(newVal) {
      if (newVal !== 'lobby') {
        this.closeModal();
      }
    },
  },

  computed: {

    allowInviting() {
      return (this.league.type == 'friend' || this.league.type == 'group') &&
        this.league.creatorId == this.$store.getters.userId;
    },

    draftPriorityOptions() {
      if (!this.league || !this.league.sport) {
        return [];
      }
      return this.draftPriorityOpt[this.league.sport];
    },

    contestSponsorBanner() {
      if (!this.league?.sponsorAds) {
        return null;
      }
      return this.league?.sponsorAds['salarycap-contest-card'];
    },

    ...mapState(['socialContent', 'ownersbucksValue', 'balanceValue', 'contestLimits', 'draftPriority']),

  },

  methods: {

    openReferModal() {
      EventBus.$emit('OPEN_REFER_MODAL');
    },

    selectTab(tabName) {
      this.selectedTab = tabName;
    },

    setLeagueData(leagues) {
      if (leagues == null || this.league == null) {
        return;
      }
      const currLeague = leagues.find((league) => league.id == this.league.id);
      if (currLeague != null) {
        if (this.league.state == 'NOT_STARTED' && currLeague.state != 'NOT_STARTED') {
          this.resetData();
        }
        this.league = currLeague;
      }
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_ID', this.league.id, true);
    },

    resetCopyLinkVar() {
      this.showCopyMessage = false;
    },

    saveDraftPriority() {
      this.draftPriorityStatus = 'Saving Changes...';
      ObAccountApi.updateDraftPriority(this.league.sport, this.draftPrioritySelection)
          .then((data) => {
            this.$store.commit('setDraftPriority', data);
            this.draftPriorityStatus = '';
          })
          .catch((error) => {
            this.draftPriorityStatus = 'Error saving draft priority';
          });
    },

    hasSufficientFunds() {
      if (this.league.realMoney) {
        return this.balanceValue >= this.league.feeValue;
      }
      return this.ownersbucksValue >= this.league.feeValue;
    },

    isInvitedNotJoined() {
      return this.isInvited;
    },

    // Fix this now that the users list is null
    inLeague() {
      const userId = this.$store.getters.userId;
      if (this.league.creatorId == userId) {
        return true;
      }
      if (this.isEntered) {
        return true;
      }
      // If they see the league from the "Invited Contests" list, they haven't joined the league yet.
      if (this.isInvited) {
        return false;
      }
      return this.league.declinedInvites && this.league.declinedInvites.find((id) => id == userId) != null;
    },

    inLineupCreator() {
      return this.$route.name == 'lineup-builder';
    },

    getLineupLink() {
      return '/lineup-builder/' + this.league.id;
    },

    joinLeagueConfirm() {
      if (!this.contestLimits || !this.contestLimits.activeContest || !this.contestLimits.contestWarning) {
        this.joinLeague();
        return;
      }

      if (this.contestLimits.activeContest >= this.contestLimits.contestWarning) {
        this.leagueAlertText = 'You are already in ' + this.contestLimits.activeContest + ' contests. Are you sure that you want to join?';
        EventBus.$emit('OPEN_LEAGUE_ALERT_CONFIRMATION_MODAL');
      } else {
        this.joinLeague();
      }
    },

    joinLeague() {
      this.joiningLeague = true;
      ObLeagueApi.joinLeague(this.league.id, this.league.realMoney)
          .then((data) => {
            if (data === 'success' || data === 'success-league-full') {
              this.$Analytics.trackHead2HeadEntry(this.league, true);
              EventBus.$emit('UPDATE_ACCOUNT_INFO');
              EventBus.$emit('LEAGUE_JOINED', this.league.id);
              this.closeModal();
            }
            this.joiningLeague = false;
          })
          .catch((error) => {
            this.$Analytics.trackHead2HeadEntry(this.league, false);
            if (error && error.response && error.response.data) {
              this.leagueErrorMessage = error.response.data;
            }
            this.joiningLeague = false;
          });
    },

    leaveLeagueConfirm() {
      EventBus.$emit('OPEN_LEAGUE_LEAVE_CONFIRMATION_MODAL');
    },

    leaveLeague() {
      this.leavingLeague = true;
      ObLeagueApi.leaveLeague(this.league.id, this.league.realMoney)
          .then((data) => {
            if (data === 'success') {
              EventBus.$emit('UPDATE_ACCOUNT_INFO');
              EventBus.$emit('LEAGUE_LEFT', this.league.id);
              this.closeModal();
            }
            this.leavingLeague = false;
          })
          .catch((_error) => {
            this.leavingLeague = false;
          });
    },

    disbandLeagueConfirm() {
      EventBus.$emit('OPEN_LEAGUE_DISBAND_CONFIRMATION_MODAL');
    },

    disbandLeague() {
      this.disbandingLeague = true;
      ObLeagueApi.disbandLeague(this.league.id)
          .then((response) => {
            EventBus.$emit('LEAGUE_DISBANDED', this.league.id);
            this.closeModal();
            this.disbandingLeague = false;
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              this.leagueErrorMessage = error.response.data;
            } else {
              this.leagueErrorMessage = 'Error disbanding league';
            }

            this.disbandingLeague = false;
          });
    },

    addInvitedUser(friend) {
      if (this.userAddingList.find((user) => user.id == friend.id) == null) {
        this.userAddingList.push(friend);
      }
    },

    removeInvitedUser(friend) {
      this.userAddingList = this.userAddingList.filter((user) => user.id != friend.id);
    },

    invitePlayers() {
      this.invitingPlayers = true;
      ObLeagueApi.inviteUsersToLeague(this.league.id, this.userAddingList.map((user) => user.id))
          .then((response) => {
            this.closeModal();
            this.invitingPlayers = false;
          }).catch((e) => {
            this.invitingPlayers = false;
          });
    },

    declineLeagueInvite() {
      this.decliningLeague = true;
      ObLeagueApi.declineLeagueInvite(this.league.id)
          .then((data) => {
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('LEAGUE_DECLINED', this.league.id);
            this.closeModal();
            this.decliningLeague = false;
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              this.leagueErrorMessage = error.response.data;
            } else {
              this.leagueErrorMessage = 'Error declining league';
            }

            this.decliningLeague = false;
          });
    },

    leagueError(errorMessage) {
      this.leagueErrorMessage = errorMessage;
      this.joiningLeague = false;
    },

    resetData() {
      this.modalInitialized = false;
      this.league = null;
      this.isDraft = false;
      this.refNames = [
        {ref: 'details', visible: false},
        {ref: 'scoring', visible: false},
        {ref: 'payouts', visible: false},
      ];
      this.refScrolledTo = '';
      this.selectedTab = 'details';
      this.sectionTopMargin = 10;
      this.joiningLeague = false;
      this.leagueErrorMessage = '';
      this.geolocationError = false;
    },

    loadFriendsList() {
      if (this.league.type == 'friend') {
        if (this.socialContent) {
          this.friendsList = this.socialContent.friendsList.map((friend) => {
            return {
              id: friend.id,
              name: friend.name,
              team: friend['teamName' + this.league.sport],
            };
          });
        }
      } else if (this.league.type == 'group') {
        ObSocialApi.getMembersInfo(this.league.groupId, this.league.sport).then((response) => {
          const friendsList = [];
          for (const userId in response) {
            friendsList.push({
              id: userId,
              name: response[userId].name,
              team: response[userId].team,
            });
          }
          this.friendsList = friendsList;
        });
      } else if (this.league.ctype == 'Tournament') {
        // TODO: Get user info for tournament
      }
    },

    openModalById(leagueId) {
      ObLeagueApi.getLeague(leagueId).then((response) => {
        const league = response;
        const isInvited = league.invitedUsers != null ? league.invitedUsers.find((user) => user.id == this.$store.getters.userId) : false;
        const isInLeague = league.users != null ? league.users.find((user) => user.id == this.$store.getters.userId) : false;
        this.openModal(league, isInLeague, isInvited);
      });
    },

    openModal(league, isEntered, isInvited) {
      this.resetData();
      this.isOpen = true;
      this.league = league;
      this.isEntered = isEntered;
      this.isInvited = isInvited === true;
      this.loadFriendsList();
      this.setScrollVals();

      // Initialize draft priority selected value
      if (this.draftPriority && this.draftPriority[this.league.sport]) {
        this.draftPrioritySelection = this.draftPriority[this.league.sport];
      } else {
        this.draftPrioritySelection = '';
      }

      // Set as nextTick to prevent watcher from triggering
      this.$nextTick(() => {
        this.modalInitialized = true;
      });
    },

    openDraftModal(leagueId) {
      this.resetData();
      this.isOpen = true;
      this.isDraft = true;
      ObLeagueApi.getLeague(leagueId).then((league) => {
        this.league = league;
        const isInvited = league.invitedUsers != null ? league.invitedUsers.find((user) => user.id == this.$store.getters.userId) : false;
        this.openModal(league, true, isInvited);
      });
    },

    closeModal() {
      this.resetData();
      this.isOpen = false;
    },

    isRefVisible(rName) {
      for (const refIndex in this.refNames) {
        if (this.refNames[refIndex].ref === rName) {
          return this.refNames[refIndex].visible;
        }
      }
      return false;
    },

    setScrollVals() {
      if (this.league) {
        for (const refIndex in this.refNames) {
          const rName = this.refNames[refIndex].ref;
          if (rName == 'scoring' && this.league.scoring == null) {
            this.refNames[refIndex].visible = false;
          } else if (rName == 'payouts' && (this.league.payouts == null ||
            this.league.payouts.length == 0)) {
            this.refNames[refIndex].visible = false;
          } else {
            this.refNames[refIndex].visible = true;
          }
        }
      }
      for (const refIndex in this.refNames) {
        if (this.refNames[refIndex].visible === true && this.refScrolledTo == '') {
          this.refScrolledTo = this.refNames[refIndex].ref;
        }
      }
    },

    scrollToComponent(cName, behavior) {
      if (this.selectedTab != 'details') {
        this.selectedTab = 'details';
      }
      const self = this;
      this.$nextTick(() => {
        if (self.$refs[cName]) {
          EventBus.$emit('MODAL_SCROLL_BODY', self.$refs[cName].$el.offsetTop - self.sectionTopMargin, behavior);
        }
      });
    },

    checkScrollPosition(e) {
      let currentScrolledIndex = this.refNames[0].ref;

      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
        this.refScrolledTo = this.refNames[this.refNames.length - 1].ref;
        return;
      }

      for (const rIndex in this.refNames) {
        if (this.$refs[this.refNames[rIndex].ref]) {
          const refEl = this.$refs[this.refNames[rIndex].ref].$el;
          if (e.target.scrollTop >= refEl.offsetTop - e.target.offsetTop - this.sectionTopMargin - 10 && this.refNames[rIndex].visible) {
            currentScrolledIndex = this.refNames[rIndex].ref;
          }
        }
      }

      this.refScrolledTo = currentScrolledIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .componentTitle {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    padding: 8px 15px;
    background: var(--obcolor-background-4);
    width: 100%;
    font-weight: bold;
    box-sizing: border-box;
  }

  .componentContent {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 5px 10px;
    box-sizing: border-box;
    min-width: 380px;
  }

  .infoBtn {
    font-size: 12px;
    color: var(--obcolor-font-primary);
    margin-left: 5px;
  }

  .obText {
    color: var(--obcolor-ob-blue);
  }

  .moneyText {
    color: var(--obcolor-green);
  }
}

.summaryContainer {
  margin: -20px -20px 0 -20px;
  border-radius: 5px 5px 0 0;
  background: var(--obcolor-box-header);
}

.navigationMenu {
  display: flex;
  background: var(--obcolor-navi-bar);
  margin-bottom: -8px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

.navBtn {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.navBtn.navSelected {
  background: var(--obcolor-background-2);
  color: var(--obcolor-ob-blue)
}

.sponsorBox {
  margin-bottom: 6px;
}

.infoContainer {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  min-width: min-content;
}

.autopickFooter {
  padding-top: 10px;
  font-size: 12px;
}

.autopickSelect {
  font-size: 12px;
  height: 23px;
  padding: 0 5px;
}

.draftPriorityContainer {
  font-size: 12px;
  border-bottom: 2px solid var(--obcolor-background-2);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.selectContainer {
  margin: 5px 0 15px 0;
  display: flex;
  align-items: center;
}

.shareContestLink {
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;

  .fa-share-square {
    margin-right: 6px;
  }
}

.modalFooter {
  padding: 5px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.errorMessage {
  color: red;
  font-size: 12px;
}
</style>