<template>
  <Modal v-if="isOpen" v-model="isOpen"
    containerStyle="width: 540px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
    bodyStyle="padding-bottom: 0;"
    :hideCloseButton="true"
  >
    <div slot="body" class="confirmBody">
      <div class="withdrawInfoContainer">
        <i class="fas fa-check-circle checkMark"><div class="checkBg"></div></i>
        <div>Withdrawal Submitted</div>
        <div class="withdrawAmount">{{ amountFormatted }}</div>
        <div>{{ $moment(timeOfOpen).format('Do MMMM YYYY - h:mm A') }}</div>
      </div>
      <div class="submittedInfoFooter">
        Your withdrawal request has been submitted.
        We typically process withdrawals within 24 hours.
      </div>
      <div class="modalButtonContainer">
        <button @click="closeModal" class="ob-btn">Done</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isOpen: false,
      params: {},
      timeOfOpen: '',
    };
  },
  created() {
    this.timeOfOpen = this.$moment().valueOf();
    EventBus.$on('WITHDRAWAL_SUCCESS_OPEN_MODAL', this.openModal);
  },
  destroyed() {
    EventBus.$off('WITHDRAWAL_SUCCESS_OPEN_MODAL', this.openModal);
  },

  computed: {
    amountFormatted() {
      if (!this.params.amount) {
        return '';
      }

      let dollarAmount = this.params.amount / 100;
      dollarAmount = dollarAmount.toFixed(2);
      return '$' + Intl.NumberFormat('en-US').format(dollarAmount).removeCentsIfZero();
    },
  },

  methods: {
    resetData() {
      this.params = {};
      this.timeOfOpen = this.$moment().valueOf();
    },

    openModal(params) {
      this.params = params;
      this.timeOfOpen = this.$moment().valueOf();
      this.isOpen = true;
    },

    closeModal() {
      this.resetData();
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Modals/modals.scss';

.confirmBody {
  text-align: center;
}

.checkMark {
  font-size: 45px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: limegreen;
  margin-bottom: 5px;
  padding-top: 1px;
  border-radius: 100%;
  border: 14px solid rgba(limegreen, 0.2);
}

.withdrawInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 15px;
  margin-top: 50px;
  font-size: 14px;

  .withdrawAmount {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.submittedInfoFooter {
  margin-top: 10px;
  font-size: 15px;
  padding: 10px 20px;
  background: var(--obcolor-background-2);
  line-height: 24px;
}

.modalButtonContainer {
  margin: 0;
}
</style>