// @ts-check
import Api from '@/api/ObBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

/**
 * @typedef {import('./types').Sport} Sport
 *
 * @typedef {'STANDARD'|'PROTECTED'} PropContestType
 * @typedef {'UPCOMING'|'ACTIVE'|'COMPLETED'|'FINALIZED'|'CANCELLED'} PropContestState
 * @typedef {'UPCOMING'|'PENDING'|'WINNING'|'WON'|'LOST'|'CANCELLED'} PropContestStatus
 *
 * @typedef {'UNDER'|'OVER'} PickOutcome
 * @typedef {'Over'|'Under'|'Push'} PickType
 * @typedef {'PENDING'|'INCORRECT'|'CORRECT'} PickStatus
 *
 * @typedef {'SUSPENDED'|'ACTIVE'|'FINALIZED'} MarketState
 *
 * @typedef {'UPCOMING'|'ACTIVE'|'COMPLETED'} MarketGameState
 *
 * @typedef {{
 *  id: string,
 *  userId: string,
 *  contestType: PropContestType,
 *  contestStart: number,
 *  state: PropContestState,
 *  entryFee: number,
 *  payout: number,
 *  originalPayout: number,
 *  reducedPayout: number,
 *  payoutMultiplier: number,
 *  originalPayoutMultiplier: number,
 *  reducedPayoutMultiplier: number,
 *  isRealMoney: boolean,
 *  isPayoutComplete: boolean,
 *  isEntryBonusComplete: boolean,
 *  isRefundComplete: boolean,
 *  dateCreated: number,
 *  lastUpdated: number,
 *  contestCompleted: number,
 *  marketIds: number[],
 *  picks: Pick[],
 *  removedPicks: Pick[],
 *  correctPicks: number,
 *  status: PropContestStatus,
 *  userWon: boolean,
 *  contestRevenue: number,
 *  cancellationNote: string,
 *  minPicks: number,
 *  payoutMultipliers: Record<string, number>,
 *  protectedPayoutMultipliers: Record<string, Record<string, number>>,
 * }} PropContest
 *
 * @typedef {{
 *  marketId: number,
 *  playerId: number,
 *  gameId: number,
 *  sport: string,
 *  marketType: unknown,
 *  balancedLine: number,
 *  selection: PickType,
 *  removedNote: string,
 *  gameStart: number,
 *  dateCreated: number,
 *  isRemoved: boolean,
 *  isFinalized: boolean,
 *  status: PickStatus,
 *  outcome?: PickOutcome,
 *  result?: number,
 *  isCorrect?: boolean,
 *  isDiscounted: boolean,
 *  discount?: PickDiscount,
 * }} Pick
 *
 * @typedef {{
 *  id: number,
 *  originalLine: number,
 *  discountPercentage: number,
 * }} PickDiscount
 *
 * @typedef {{
 *  state: PropContestState,
 *  offset: number|string,
 *  limit: number|string,
 *  year?: number|string,
 *  month?: number|string,
 * }} GetPropContestsOptions
 *
 * @typedef {"MORE_OR_LESS"|"MORE_ONLY"} PickOptions
 *
 * @typedef {{
 *  id: number,
 *  marketTypeId: number,
 *  playerId: number,
 *  gameId: number,
 *  sport: Sport,
 *  state: MarketState,
 *  marketType: MarketType,
 *  player: MarketPlayer,
 *  game: MarketGame,
 *  line: MarketLine,
 *  pickOptions: PickOptions,
 *  isDiscounted: boolean,
 *  discount?: MarketDiscount,
 * }} Market
 *
 * @typedef {{
 *  id: number,
 *  discountLine: number,
 *  discountPercentage: number,
 *  expiry: number,
 * }} MarketDiscount
 *
 * @typedef {{
 *  id: number,
 *  sport: Sport,
 *  name: string,
 *  abbreviation: string,
 *  statPath: string,
 *  marketProviderId: string,
 *  lobbyRank: number,
 *  isActive: boolean,
 * }} MarketType
 *
 * @typedef {{
 *  id: number,
 *  firstName: string,
 *  lastName: string,
 *  name: string,
 *  position: string,
 *  status: string,
 *  teamId: number,
 *  teamCity: string,
 *  teamName: string,
 *  teamAlias: string,
 *  isHome: boolean,
 * }} MarketPlayer
 *
 * @typedef {{
 *  gameId: number,
 *  date: number,
 *  period: number,
 *  gameTime: string,
 *  inning: string,
 *  halfInning: string,
 *  state: MarketGameState,
 *  homeTeam: MarketTeam,
 *  awayTeam: MarketTeam,
 * }} MarketGame
 *
 * @typedef {{
 *  id: number,
 *  alias: string,
 *  score: number,
 * }} MarketTeam
 *
 * @typedef {{
 *  balancedLine: number,
 * }} MarketLine
 *
 * @typedef {{
 *  minEntryFee: number,
 *  maxEntryFee: number,
 *  minPicks: number,
 *  maxPicks: number,
 *  isNFLActive: boolean,
 *  isNBAActive: boolean,
 *  isMLBActive: boolean,
 *  isNHLActive: boolean,
 *  payoutMultipliers: Record<string,number>,
 *  protectedMinPicks: number,
 *  protectedMaxPicks: number,
 *  protectedPayoutMultipliers: Record<string,Record<string,number>>,
 * }} PropSettings
 *
 * @typedef {{
 *  id: number,
 *  homeTeamId: number,
 *  awayTeamId: number,
 *  homeTeamAlias: string,
 *  awayTeamAlias: string,
 *  date: number,
 * }} Game2
 *
 * @typedef {{
 *  markets: Market[],
 *  games: Game2[],
 * }} GetMarketsResponse
 *
 * @typedef {{
 *  marketId: number,
 *  balancedLine: number,
 *  selection: PickType,
 * }} PickTO
 *
 * @typedef {{
 *  picks: PickTO[],
 * }} PropContestPayoutsTO
 *
 * @typedef {{
 *  signal?: import('axios').GenericAbortSignal
 * }} RequestOptions
 */

export default {
  /**
   * @param {string} sport
   * @param {number} marketTypeId
   * @param {RequestOptions} [options]
   * @return {Promise<GetMarketsResponse>}
   */
  getMarkets(sport, marketTypeId, options = {}) {
    return Api()
        .get('/fsp/v3/market', {
          params: {
            sport: sport,
            marketTypeId: marketTypeId,
          },
          signal: options.signal,
        })
        .then(async (response ) => {
          return response.data;
        });
  },

  async createPropContest(entry) {
    const reason = 'GamePlay';
    const gameType = 'PlayerPicks';
    const config = await getGeopacketConfig(reason, gameType);
    return Api()
        .post('/fsp/v2/propContest?errorType=json', entry, config)
        .then((response) => {
          return response.data;
        });
  },

  /**
   * @param {string} sport
   * @param {RequestOptions} [options]
   * @return {Promise<MarketType[]>}
   */
  getActiveMarketTypes(sport, options = {}) {
    return Api()
        .get('/fsp/marketType/active', {
          params: {
            sport: sport,
          },
          signal: options.signal,
        })
        .then((response) => {
          return response.data;
        });
  },

  /**
   * @return {Promise<PropSettings>}
   */
  getPropsSettings() {
    return Api()
        .get('/fsp/propSettings')
        .then((response) => {
          return response.data;
        });
  },

  /**
   *
   * @param {GetPropContestsOptions} options
   * @return {Promise<PropContest[]>}
   */
  getPropContests({state, offset, limit, year = null, month = null}) {
    let params = '?state=' + state + '&offset=' + offset + '&limit=' + limit;
    if (year !== '' && month !== '' && year && month) {
      params += '&year=' + year + '&month=' + month;
    }
    return Api()
        .get('/fsp/propContest' + params)
        .then((response) => {
          return response.data;
        });
  },

  /**
   * @param {PropContestPayoutsTO} request
   * @param {RequestOptions} [options]
   * @return {Promise<unknown>}
   */
  payouts(request, options = {}) {
    return Api()
        .post('/fsp/v2/propContest/payouts', request, {
          signal: options.signal,
        })
        .then((response) => {
          return response.data;
        });
  },
};
