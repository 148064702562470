<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 750px; min-height: 180px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-6); overflow: unset !important;"
    :hideCloseButton="!allowClose" :disableClickaway="!allowClose"
  >
    <div slot="header" class="modalHeader">
      <div>Invite Friends</div>
    </div>
    <div slot="body">
      <div class="loading" v-if="loading">
        <ObLoading :size="80" />
      </div>
      <div v-else-if="showError" class="errorMessage">
        <div>The contest you are trying to share is invalid</div>
      </div>
      <div v-else>
        <div class="stepContainer">
          <div v-for="(item, idx) in getStepsToShare" :key="idx" class="threeStepToShare">
            <span>{{ idx + 1 }}</span>
            <p v-html="item.description"></p>
          </div>
        </div>
        <div class="shareOptions">
          <p>Share Link</p>
          <div class="shareLink">
            <p>{{ contestUrl }}</p>
            <button class="ob-btn-grey" @click="copyLink"
              v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyLinkMessage }"
            >
              Copy Link
            </button>
          </div>
          <p>or Share via</p>
          <div class="shareSocials">
            <ShareNetwork v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{backgroundColor: network.color}"
              :url="contestUrl"
              :title="sharing.title"
              :description="sharing.description"
              :hashtags="sharing.hashtags"
            >
              <i :class="network.icon"></i>
              <span>{{ network.name }}</span>
            </ShareNetwork>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!allowClose" slot="footer" class="confirmFooter">
      <button class="ob-btn" @click="goToLineupBuilder()">Build Lineup</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObLoading from '@/components/ObLoading';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';

export default {
  name: 'ShareContestSocials',
  components: {
    Modal,
    ObLoading,
  },


  data() {
    return {
      isOpen: false,
      allowClose: true,
      contestUrl: null,
      showCopyLinkMessage: false,
      loading: true,
      contestId: null,
      showError: false,
      isSquadRide: false,
      stepsToShare: [
        {description: 'Copy the link to the  <br> lineup builder below'},
        {description: 'Share the link to <br> your contest with friends'},
        {description: 'Build your lineup and <br> compete against your friends'},
      ],
      stepsToShareLL: [
        {description: 'Copy the link to your <br> Lightning Lineups Contest.'},
        {description: 'Share the link to <br> your contest with friends so <br> they can enter the same <br> lineup as you!'},
        {description: 'Have your friends tail <br> your lineup or fade your lineup!'},
      ],
      sharing: {
        title: null,
        description: null,
        hashtags: null,
      },
      networks: [
        {network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333'},
        {network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2'},
        {network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2'},
        {network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500'},
      ],
    };
  },

  created() {
    EventBus.$on('SHARE_SOCIALS_FOR_ID', this.openSocialsModalById);
    EventBus.$on('SHARE_SOCIALS_FOR_CONTEST', this.openSocialsModal);
    EventBus.$on('SHARE_SOCIALS_FOR_LL', this.openSocialsModalLL);
  },

  destroyed() {
    EventBus.$off('SHARE_SOCIALS_FOR_ID', this.openSocialsModalById);
    EventBus.$off('SHARE_SOCIALS_FOR_CONTEST', this.openSocialsModal);
    EventBus.$off('SHARE_SOCIALS_FOR_LL', this.openSocialsModalLL);
  },

  computed: {
    getStepsToShare() {
      return this.isSquadRide ? this.stepsToShareLL : this.stepsToShare;
    },
  },

  methods: {
    openSocialsModalById(contestId, closeButton) {
      this.resetData();
      this.allowClose = closeButton;
      this.contestId = contestId;
      this.contestUrl = `${window.location.origin}${this.$router.options.base}lineup-builder/${contestId}`;
      this.isOpen = true;
      this.getContest();
    },

    openSocialsModal(contest) {
      if (!contest) {
        return;
      }
      this.resetData();
      this.contestId = contest.id;
      this.contestUrl = `${window.location.origin}${this.$router.options.base}lineup-builder/${contest.id}`;
      this.setContestDescription(contest.contestName);
      this.loading = false;
      this.isOpen = true;
    },

    openSocialsModalLL(squadRideContest, squadRideUrl) {
      if (!squadRideContest) {
        return;
      }
      this.resetData();
      this.isSquadRide = true;
      if (squadRideUrl) {
        this.contestUrl = squadRideUrl;
      } else {
        this.contestUrl = this.squadRideUrl(squadRideContest);
      }
      this.setContestDescriptionSquadRide(squadRideContest);
      this.loading = false;
      this.isOpen = true;
    },

    squadRideUrl(squadRideContest) {
      const baseURL = window.location.origin;
      const subfolder = this.$router.options.base;

      let params = '';
      if (squadRideContest?.lineupId != null) {
        let selected = 1;
        if (squadRideContest.selectedLineup != null) {
          selected = squadRideContest.selectedLineup + 1;
        }

        let entry = 20;
        if (squadRideContest.feeValue != null) {
          entry = (squadRideContest.feeValue / 100).formatDecimal().removeCentsIfZero();
        }

        let numLineups = 3;
        if (squadRideContest.numLineups != null) {
          numLineups = squadRideContest.numLineups;
        }

        params = '?lineup=' + squadRideContest.lineupId + '&selected=' + selected + '&entry=' + entry + '&numLineups=' + numLineups;
      }

      return baseURL + subfolder + 'lightning-lineups/builder/' + squadRideContest.sport + params + '&btag=a_99999b_0';
    },

    resetData() {
      this.showError = false;
      this.isSquadRide = false;
      this.allowClose = true;
    },

    getContest() {
      this.loading = true;
      ObSalaryCapApi.getContest(this.contestId, true).then((response) => {
        this.setContestDescription(response.contest.contestName);
        this.loading = false;
      }).catch((_error) => {
        this.showError = true;
        this.loading = false;
      });
    },

    setContestDescriptionSquadRide(squadRideContest) {
      this.sharing.title = 'Join me in my Lightning Lineups Contest on OwnersBox!';
      this.sharing.description = 'Come tail my ' + squadRideContest.sport + ' Lightning Lineups lineup on OwnersBox! OwnersBox has the best Free and Paid Daily Fantasy Sports for NFL, NBA, MLB, and NHL!';
      this.sharing.hashtags = 'DFS,OwnersBox,LightningLineups';
    },

    setContestDescription(contestName) {
      this.sharing.title = 'Join me in the ' + contestName + ' Contest on OwnersBox!';
      this.sharing.description = 'Come build a lineup against me in the ' + contestName + ' Contest on OwnersBox! OwnersBox has the best Free and Paid Daily Fantasy Sports contests for NFL, NBA, MLB, and NHL!';
      this.sharing.hashtags = 'DFS,OwnersBox';
    },

    copyLink() {
      navigator.clipboard
          .writeText(this.contestUrl)
          .then(() => {
            this.showCopyLinkMessage = true;

            setTimeout(() => {
              this.showCopyLinkMessage = false;
            }, 2000);
          })
          .catch((_error) => {
            // Clipboard write failed
          });
    },

    goToLineupBuilder() {
      this.isOpen = false;
      this.$router.push('/lineup-builder/' + this.contestId);
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
  margin-top: 2px;
}

.stepContainer {
  display: flex;
  gap: 5px;
  padding: 10px;
}

.threeStepToShare {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--obcolor-background-3);
  padding: 10px;

  span {
    padding: 10px 15px;
    border-radius: 50%;
    background-color: var(--obcolor-background-2);
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.shareLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  p:nth-child(1) {
    background-color: var(--obcolor-background-2);
    padding: 8px;
    border-radius: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 520px;
  }
}

.shareSocials {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    border-radius: 4px;
    width: 110px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    color: var(--obcolor-white);
    &:hover {
      i {
        transform: scale(1.25);
      }
      span {
        font-weight: 700;
        color: var(--obcolor-white);
      }
    }
  }

  p {
    margin: 0;
  }

  i {
    transition: all 0.2s;
    padding-right: 8px;
  }
}

.shareOptions {
  display: flex;
  flex-direction: column;
  align-items: center;

  > p:nth-child(1) {
    margin: 10px 0 0 0;
  }

  > p:nth-of-type(2) {
    margin: 0;
  }
}

.errorMessage {
  text-align: center;
  margin-top: 50px;
}

.confirmFooter {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.loading {
  display: flex;
  justify-content: center;
}
</style>