<template>
  <div>
    <Modal v-if="isOpen" v-model="isOpen"
      containerStyle="width: 716px; overflow: hidden; padding: 0; border-width: 0; border-radius: 0 0 10px 10px;"
      bodyStyle="padding-bottom: 0;" :disableClickaway="true"
    >
      <div slot="body" class="confirmBody">
        <div class="redeemHeader">
          <div class="redeemText">
            <h3>Convert OwnersBucks To Cash</h3>
            <div class="redeemInfoContainer">
              As a VIP you have the ability to convert redeemable OwnersBucks to cash at a 1:1 rate.
            </div>
          </div>
          <div class="redeemableGraph">
            <RedeemableOBucks :ownersbucksTot="ownersbucksString" :ownersbucksRedeemable="redeemableString" />
          </div>
        </div>
        <div class="ob-box ticketInputContainer">
          <div class="ticketInputList">
            <div class="inputSection">
              <div class="inputTitle">OwnersBucks</div>
              <div class="ob-input-container">
                <img class="ownersbucksIcon" src="@/assets/icons/ownersbucks.png" alt="ownersbucks">
                <input class="ob-input" placeholder="Convertible Amount" v-model="convertAmount"
                  @input="setConvertibleToLimit"
                >
              </div>
            </div>
            <div class="inputSection">
              <div class="inputTitle">Cash</div>
              <div class="ob-input-container">
                <img src="@/assets/icons/real-money.png" class="dollarImg" alt="cash">
                <input disabled class="ob-input" placeholder="Cash Equivalent"
                  :value="convertAmount"
                >
              </div>
            </div>
          </div>
        </div>
        <ErrorPanel :message="convertError" />
        <div class="modalButtonContainer">
          <button @click="closeModal" class="ob-btn-grey">Cancel</button>
          <button v-if="!isConverting" @click="convertConfirm" class="ob-btn"
            :disabled="!canSubmit"
          >
            Convert
          </button>
          <button v-else class="ob-btn" disabled>
            <ObLoading customTheme="light" :size="50" class="btnLoadingIcon" />
            Converting
          </button>
        </div>
      </div>
    </Modal>
    <OwnersBucksConvertConfirmModal @confirm="convert" />
    <OwnersBucksConvertSuccessModal />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObAccountApi from '@/api/ObAccountApi';
import RedeemableOBucks from '@/components/charts/RedeemableOBucks';
import OwnersBucksConvertConfirmModal from './OwnersBucksConvertConfirmModal.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import ObLoading from '@/components/ObLoading.vue';
import OwnersBucksConvertSuccessModal from './OwnersBucksConvertSuccessModal.vue';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    RedeemableOBucks,
    OwnersBucksConvertConfirmModal,
    OwnersBucksConvertSuccessModal,
    ErrorPanel,
    ObLoading,
  },
  data() {
    return {
      isOpen: false,
      profile: null,
      convertAmount: null,
      convertError: '',

      isConverting: false,
      loadingProfile: false,

    };
  },
  created() {
    this.loadProfile();
    EventBus.$on('CONVERT_OWNERSBUCKS_TO_CASH_OPEN', this.openModal);
  },
  destroyed() {
    EventBus.$off('CONVERT_OWNERSBUCKS_TO_CASH_OPEN', this.openModal);
  },
  computed: {
    ...mapState(['ownersbucksValue']),

    canSubmit() {
      return this.convertAmount > 0;
    },

    ownersbucksString() {
      if (!this.ownersbucks && this.ownersbucks !== 0) {
        return null;
      }

      return this.ownersbucks.toFixed(2);
    },

    ownersbucks() {
      if (!this.ownersbucksValue) {
        return null;
      }

      return this.ownersbucksValue / 100;
    },

    redeemableString() {
      if (!this.redeemable && this.redeemable !== 0) {
        return null;
      }

      return this.redeemable.toFixed(2);
    },

    redeemable() {
      if (!this.profile?.convertible) {
        return null;
      }

      let redeemableVal = parseFloat(this.profile?.convertible.replace(/,/g, ''));

      if (redeemableVal > this.ownersbucks) {
        redeemableVal = this.ownersbucks;
      }

      return redeemableVal;
    },
  },

  watch: {
    convertAmount(newVal, oldVal) {
      if (newVal == null || newVal == '') {
        return;
      }

      if (newVal == '.') {
        this.convertAmount = '0.';
        return;
      }

      const roundedVal = Math.floor(parseFloat(newVal) * 100) / 100;
      const valNum = parseFloat(newVal);
      if (valNum != newVal) {
        this.convertAmount = oldVal;
      } else if (valNum < 0) {
        this.convertAmount = '';
      } else if (valNum > this.redeemable) {
        this.convertAmount = this.redeemable;
      } else if (valNum !== roundedVal) {
        this.convertAmount = roundedVal;
      }
    },
  },

  methods: {
    setConvertibleToLimit() {
      if (this.convertAmount <= 0) {
        return;
      }
      if (this.convertAmount > this.redeemable) {
        this.convertAmount = this.redeemable;
      }
    },

    resetData() {
      this.convertAmount = null;
      this.isConverting = false;
      this.loadingProfile = false;
    },

    openModal(profile) {
      this.resetData();
      this.profile = profile;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('cancel');
    },

    convertConfirm() {
      EventBus.$emit('OPEN_CONVERT_CONFIRM_MODAL', this.convertAmount);
    },

    loadProfile() {
      this.loadingProfile = true;
      ObAccountApi.getProfile(this.userId).then((response) => {
        this.profile = response;
        this.loadingProfile = false;
      }).catch((_e) => {
        this.loadingProfile = false;
      });
    },

    // Send conversion amount as a string
    convert() {
      this.isConverting = true;
      const convertString = String(this.convertAmount);
      ObAccountApi.convertOwnersbucks(convertString)
          .then(() => {
            EventBus.$emit('OPEN_CONVERT_SUCCESS_MODAL', this.convertAmount);
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('LOAD_USER_PROFILE');
            this.closeModal();
          })
          .catch((error) => {
            this.isConverting = false;
            if (error.response && error.response.data) {
              this.convertError = error.response.data;
            } else {
              this.convertError = 'Internal Server Error';
            }
          }).finally(() => {
            this.isConverting = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Modals/modals.scss';
@import '@/style/DesignSystem/inputs.scss';
@import '@/style/DesignSystem/buttons.scss';

.confirmBody {
  text-align: center;
  padding: 60px 40px 30px 40px;
}

.redeemHeader {
  display: flex;

  > div {
    flex: 1;
  }
}

.redeemText {
  padding-top: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;

  h3 {
    margin: 0;
    font-size: 20px;
  }
}

.ownersbucksIcon {
  height: 20px;
}

.ticketInputContainer {
  padding: 15px;
  background: var(--obcolor-background-6);
  border: 2px solid var(--obcolor-input-border);
  margin-bottom: 15px;

  .ticketInputList {
    display: flex;
    gap: 10px;

    .inputSection {
      flex: 1;
      min-width: 0;

      input {
        width: 100%;
      }
    }

    .inputTitle {
      margin-bottom: 10px;
      text-align: left;
    }
    .quantitySection {
      max-width: 120px;
    }

    .ownersbucksIcon {
      height: 16px;
    }
  }
}

.modalButtonContainer {
  display: flex;
  gap: 10px;
  margin-top: 15px;

  button {
    border-radius: 5px !important;
  }
}

.blueText {
  color: var(--obcolor-ob-blue);
  display: flex;
  align-items: center;
}

.btnLoadingIcon {
  margin-left: -20px;
  margin-right: -10px;
}
</style>