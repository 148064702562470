<template>
  <div class="tooltipContainer">
    <div v-if="isOpen" class="modal-mask" @click.stop="isOpen = false"></div>
    <div ref="ticketTrigger" class="clickableContainer" @click.stop="toggleTooltip">
      <slot name="trigger">
        <img :src="require('@/assets/icons/ticket.png')" class="ticketsIcon" alt="Tickets Icon">
        <div v-if="ticket === null" class="ticketText">{{ numTickets || "0" }}</div>
      </slot>
    </div>
    <div ref="ticketContent" :class="{isOpen: isOpen}" class="ticketContainer"
      :style="tooltipStyle"
      @click.stop=""
    >
      <div class="ticketsHeader" v-if="!ticket">
        <div>My Tickets ({{ numTickets }})</div>
        <div class="closeBtn" @click="closeTooltip">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="ticketsList ob-scroll">
        <TicketField v-if="ticket" :ticket="ticket"
          @ticketClicked="goToLink"
        />
        <template v-else-if="tickets && tickets.length > 0">
          <TicketField v-for="(t, index) in tickets" :key="index" :ticket="t"
            @ticketClicked="goToLink"
          />
        </template>
        <div v-else class="noTicketsText">
          <p>No Available Tickets.</p>
          <p>
            You can earn tickets by participating in promotions.
            You can view contests where you can use a ticket by looking for the <img :src="require('@/assets/icons/ticket.png')" class="ticketsIcon" alt="Tickets Icon"> icon
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TicketField from '@/components/tooltip/tickets/TicketField';
import ClickOutside from 'vue-click-outside';
import EventBus from '@/event-bus';

export default {
  props: {
    ticket: {type: Object, default: null},
  },

  directives: {
    ClickOutside,
  },

  components: {
    TicketField,
  },

  data() {
    return {
      isOpen: false,
      tooltipStyle: '',
    };
  },

  created() {
    EventBus.$on('CLOSE_DROPDOWNS', this.closeTooltip);
  },

  destroyed() {
    EventBus.$off('CLOSE_DROPDOWNS', this.closeTooltip);
  },

  computed: {
    ...mapState(['tickets', 'numTickets']),
  },

  watch: {},

  methods: {
    toggleTooltip() {
      if (!this.isOpen) {
        EventBus.$emit('CLOSE_DROPDOWNS');
        EventBus.$emit('LOAD_USER_TICKETS');
      }
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setPos();
        });
      }
    },

    closeTooltip() {
      this.isOpen = false;
    },

    // Sets where the dropdown box should be placed, not going past the width of the page
    setPos() {
      const SCREEN_PADDING = 10;
      let triggerRect = null;
      let contentRect = null;
      const screenWidth = document.body.clientWidth;

      if (this.$refs?.ticketTrigger && this.$refs?.ticketContent) {
        triggerRect = this.$refs.ticketTrigger.getBoundingClientRect();
        contentRect = this.$refs.ticketContent.getBoundingClientRect();
      } else {
        return;
      }

      if (triggerRect == null || contentRect == null) {
        return;
      }

      this.$nextTick(() => {
        if (contentRect.right > screenWidth - SCREEN_PADDING) {
          const diff = contentRect.right - screenWidth;
          const pos = -(contentRect.width/2 - diff - SCREEN_PADDING);
          this.tooltipStyle = 'right: ' + pos + 'px;';
        }
        if (contentRect.left < 0) {
          const diff = contentRect.left - screenWidth;
          const pos = -(contentRect.width/2 - diff - SCREEN_PADDING);
          this.tooltipStyle = 'left: ' + pos + 'px;';
        }
      });
    },

    closeTooltips() {
      EventBus.$emit('CLOSE_DROPDOWNS');
    },

    getTicketRouterLink(ticket) {
      let typeRouteName = 'All';
      let sportRouteName = '';

      if (ticket.contestType === 'LIGHTNING_LINEUPS') {
        if (ticket.sport !== '') {
          return '/lightning-lineups/builder/' + ticket.sport;
        }

        const relevantSport = this.$UserInfo.mostRelevantLLSport();
        return '/lightning-lineups/builder/' + relevantSport;
      }

      if (ticket.contestType === 'PROPS') {
        return '/player-picks/lobby';
      }

      if (ticket.contestType === 'SalaryCap') {
        typeRouteName = 'daily';
      }

      if (ticket.sport !== '') {
        sportRouteName = ticket.sport;
      } else {
        sportRouteName = 'All';
      }

      return '/lobby/' + typeRouteName + '/' + sportRouteName;
    },

    goToLink(ticket) {
      this.closeTooltips();
      const ticketRouterLink = this.getTicketRouterLink(ticket);
      // Don't duplicate navigation
      if (this.$route.path === ticketRouterLink) {
        return;
      }
      this.$router.push(ticketRouterLink);
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltipContainer {
  position: relative;
  text-align: center;
}

.clickableContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .ticketsIcon {
    margin-right: 6px;
    margin-top: -2px;
  }

  &:hover  {
    .ticketsIcon {
      filter: brightness(1.2);
    }
    .ticketText {
      text-decoration: underline;
    }
  }
}

.ticketContainer {
  z-index: 9999;
  width: 400px;
  position: absolute;
  top: 20px;
  right: -200px;

  background: var(--obcolor-background-4);
  color: var(--obcolor-font-primary);
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);

  &:not(.isOpen) {
    display: none;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.ticketsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  background: var(--obcolor-background-2);
  border-radius: 5px 5px 0 0;

  .closeBtn {
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: var(--obcolor-font-secondary);
    }
  }
}

.ticketsList {
  max-height: 500px;
  overflow: auto;
}

.ticketText {
  color: var(--obcolor-ticket);
}

.noTicketsText {
  font-size: 14px;
  padding: 0 10px;

  .ticketsIcon {
    margin-bottom: -1px;
  }
}

.ticketsIcon {
  height: 12px;
}
</style>