<template>
  <div>
    <div class="liveContainer" style="display: flex;">
      <img class="teamLogo" :src="getTeamLogo(firstTeamAlias)" :alt="firstTeamAlias">
      <div>
        <div class="gameTeams">
          <div class="infoText">{{ firstTeamAlias }}</div>
          <div class="infoText">{{ teamScore(player.team, false) }}</div>
          <div class="infoText">{{ isHomeGame ? 'vs' : '@' }}</div>
          <div class="infoText">{{ secondTeamAlias }}</div>
          <div class="infoText">{{ teamScore(game.opponent, true) }}</div>
        </div>
        <div v-if="!gameIsLive" class="gameTime">{{ gameTimeFinal }}</div>
        <div v-else-if="sport != 'MLB'" class="gameTime">{{ liveGame.live.gametime + ' ' + $SportInfo.getGamePeriod(sport, liveGame) }}</div>
        <template v-if="sport == 'MLB' && gameIsLive">
          <div class="gameDetails">
            <div class="detailsTextContainer" style="align-items: flex-end;">
              <div>{{ fieldState.ballsStrikes }}</div>
              <div>{{ fieldState.outsText }}</div>
            </div>

            <!-- Players on base -->
            <MlbOnBase class="onBase" :fieldState="fieldState" />

            <!-- Pitch count, inning -->
            <div class="detailsTextContainer" style="align-items: flex-start;">
              <div v-if="liveGame.liveStats && liveGame.liveStats.pbp && liveGame.liveStats.pbp.pitchCountTotal != null">
                {{ 'P: ' + liveGame.liveStats.pbp.pitchCountTotal }}
              </div>
              <div>{{ fieldState.inning }}</div>
            </div>
          </div>


          <div class="pitcherBatterName">
            <template v-if="fieldState.batter">
              <img :src="require('@/assets/icons/baseball-bat-orange.png')" class="battingIcon" style="margin-top: -4px;"
                alt="Orange Bat"
              >
              <div class="pitcherBatterText">{{ fieldState.batter.name.shortName() }}</div>
            </template>
            <template v-if="liveGame.live.pitcher">
              <div class="pitcherBatterText">vs</div>
              <div class="pitcherBatterText">{{ liveGame.live.pitcher.name.shortName() }}</div>
              <img :src="require('@/assets/icons/baseball-ball-orange.png')" class="battingIcon" style="margin-top: -2px;"
                alt="Orange Ball"
              >
            </template>
          </div>
        </template>
      </div>
      <img class="teamLogo" :src="getTeamLogo(secondTeamAlias)" :alt="secondTeamAlias">
    </div>
    <template v-if="sport == 'NFL' && gameIsLive">
      <div class="nflGameBarContainer">
        <div class="gameTextContainer">
          <div>{{ fieldState.downInfo }}</div>
          <div>Ball on {{ fieldState.aliasYardLine }}</div>
        </div>
        <div class="nflGameBar" :style="'justify-content: ' + justifyDirection">
          <template v-if="liveGame.live && liveGame.live.yardline">
            <div v-if="!fieldState.teamHasBall" class="colourArrow" :style="'border-right: 5px solid var(' + nflYardageColour + ')'"></div>
            <div class="nflYardLine" :style="'width: ' + nflYardageWidth + '%; background: var(' + nflYardageColour + ')'"></div>
            <div v-if="fieldState.teamHasBall" class="colourArrow" :style="'border-left: 5px solid var(' + nflYardageColour + ')'"></div>
          </template>
          <i v-if="fieldState.teamHasBall" class="fas fa-chevron-right redZoneLine rightLine"></i>
          <i v-else class="fas fa-chevron-left redZoneLine leftLine"></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MlbOnBase from '@/components/charts/MlbOnBase.vue';

export default {
  props: {
    contest: {type: Object, default: null},
    player: {type: Object, default: null},
    liveGame: {type: Object, default: null},
    fieldState: {type: Object, default: null},
  },

  components: {
    MlbOnBase,
  },

  computed: {
    sport() {
      return this.contest.sport;
    },

    game() {
      return this.player.game;
    },

    fspGame() {
      return this.player.fspGame;
    },

    gameTeamsText() {
      const team1 = this.$TeamInfo.getDisplayAlias(this.sport, this.player.teamAlias);
      const team2 = this.$TeamInfo.getDisplayAlias(this.sport, this.player.teamAlias);

      const homeAway = this.game.home ? ' vs ' : ' @ ';

      return team1 + homeAway + team2;
    },

    gameIsLive() {
      return this.liveGame && this.liveGame.live &&
        this.liveGame.live.status === 'mid-event' && this.fieldState != null;
    },

    gameTime() {
      if (!this.game) {
        return '';
      }
      const game = this.game;

      let gameTimestamp = 0;
      if (game.date) {
        gameTimestamp = game.date;
      } else if (game.date_utc) {
        gameTimestamp = game.date_utc;
      } else {
        gameTimestamp = game.timestamp;
      }
      return this.$moment(gameTimestamp).format('h:mma');
    },

    firstTeamAlias() {
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, this.player.teamAlias);
    },

    secondTeamAlias() {
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, this.game.oppAlias);
    },

    isHomeGame() {
      if (this.game.home != null) {
        return this.game.home === true;
      }
      return this.player.player.teamAlias === this.game.home_team.team_alias;
    },

    nflYardageColour() {
      if (!this.fieldState) {
        return '--obcolor-ob-blue';
      }
      // When the ball is in the redzone for either team, color should be red.
      if (this.fieldState.fieldside !== this.fieldState.possession && this.fieldState.yardline <= 20) {
        return '--obcolor-red';
      }
      return '--obcolor-ob-blue';
    },

    justifyDirection() {
      if (!this.fieldState) {
        return 'flex-start';
      }
      return this.fieldState.teamHasBall ? 'flex-start' : 'flex-end';
    },

    nflYardageWidth() {
      if (!this.fieldState || !this.liveGame || !this.liveGame.live) {
        return 50;
      }

      const homeAway = this.fieldState.teamHasBall ? this.homeAway(false) : this.homeAway(true);
      const yardLine = this.fieldState.yardline;
      // When ball is on player's side then use the yard line as the width.
      // When ball is on the opposing team's side, need to add the amount of yardage from the 50 yard to 50. This can be done with 100-yardage
      if (this.fieldState.fieldside == homeAway) {
        return yardLine;
      } else {
        return 100 - yardLine;
      }
    },

    gameTimeFinal() {
      // Check if the game is Final via the liveGame for games on the current day or fspGame for games in the past.
      if ((this.liveGame && this.liveGame.live && this.liveGame.live.status == 'post-event') || (this.fspGame && this.fspGame.result)) {
        return 'Final';
      }

      return this.$moment(this.game.date).format('ddd h:mma');
    },
  },

  methods: {
    getTeamLogo(alias) {
      const teamName = alias.toLowerCase();

      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ teamName +'.png');
    },

    teamScore(teamId, opponent) {
      if (this.liveGame && this.liveGame.live && this.liveGame.home_team && this.liveGame.live.hometeam && this.liveGame.live.awayteam) {
        if (this.liveGame.home_team.team_id == teamId) {
          return this.liveGame.live.hometeam.score;
        }

        return this.liveGame.live.awayteam.score;
      }

      const fspGame = this.fspGame;
      // Use the score from fspGame when the liveGame is not available, this is the case for contests in the past
      if (fspGame && fspGame.score && fspGame.score.trim() != '') {
        const scoreArr = fspGame.score.split('-');
        // Opponent score is second and player score is first in the array
        if (scoreArr.length == 2) {
          return opponent ? scoreArr[1] : scoreArr[0];
        }
      }
      return '';
    },

    homeAway(oppPlayer) {
      if (oppPlayer) {
        return this.isHomeGame ? 'away': 'home';
      }
      return this.isHomeGame ? 'home': 'away';
    },
  },
};
</script>

<style lang="scss" scoped>
.liveContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gameTeams {
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  .infoText {
    margin: 0 2px;
  }
}

.gameDetails {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsTextContainer {
  color: var(--obcolor-font-secondary);
  width: 40px;
  white-space: nowrap;

  // We want blank divs to still stay the same height so that it doesn't push things around
  > div {
    height: 1em;
  }
}

.onBase {
  margin: 0 10px;
}

.pitcherBatterName {
  max-width: 130px;
  overflow: visible;
  white-space: nowrap;
  margin-top: 5px;
  height: 12px;
  color: var(--obcolor-font-secondary);
  display: flex;
  align-items: center;
  justify-content: center;

  .pitcherBatterText {
    margin-right: 3px;
  }

  .battingIcon {
    height: 12px;
    margin-right: 3px;
  }
}

.teamLogo {
  height: 40px;
  margin: 0 10px;
}

.gameTime {
  font-size: 14px;
  color: var(--obcolor-font-secondary)
}

.nflGameBarContainer {
  .gameTextContainer {
    display: flex;
    justify-content: space-between;
    color: var(--obcolor-font-secondary);
    font-size: 14px;
    margin-bottom: 3px;
  }

  .nflGameBar {
    display: flex;
    position: relative;
    height: 10px;
    width: 100%;
    background: var(--obcolor-timeline);
    border-radius: 5px;
    overflow: hidden;
  }

  .nflYardLine {
    height: 100%;
  }

  .colourArrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  .redZoneLine {
    position: absolute;
    color: var(--obcolor-bg);
    font-size: 14px;
    top: -2px;

    &.leftLine {
      left: 20%;
      border-radius: 0 5px 5px 0;
    }

    &.rightLine {
      right: 20%;
      border-radius: 5px 0 0 5px;
    }
  }
}
</style>