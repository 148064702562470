import Api from '@/api/ObBaseApi';

export default {

  getNotifications() {
    return Api()
        .get('/fsp/notifications/getRecentNotifications')
        .then((response) => {
          return response.data;
        });
  },

  clearUnread() {
    return Api()
        .post('/fsp/notifications/clearUnread')
        .then((response) => {
          return response.data;
        });
  },

};