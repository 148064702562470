<template>
  <div>
    <div class="activityContainer">
      <div class="headshotContainer" style="min-height: 120px;">
        <div class="teamLogoContainer">
          <img v-if="availablePlayerInfo.team" :src="getTeamLogo()" :alt="this.availablePlayerInfo.team"
            class="teamLogo"
          >
        </div>
        <PlayerHeadshot class="headshot" size="90" :id="availablePlayerInfo.id"
          :sport="sport" :pos="availablePlayerInfo.position" :team="availablePlayerInfo.team"
        />
        <div class="teamName">{{ $TeamInfo.getDisplayAlias(sport, availablePlayerInfo.team) }}</div>
      </div>
      <div class="playerInfoContainer" style="margin-top: -15px;">
        <div class="playerName">
          {{ availablePlayerInfo.name.longName() }}
          {{ availablePlayerInfo.nameTag }}
        </div>
        <div class="playerInfo">
          <span>#{{ availablePlayerInfo.number }}</span>
          <span>{{ availablePlayerInfo.position }}</span>
          <span>{{ availablePlayerInfo.lineInfo }}</span>
          <span>{{ getPitchingHand(availablePlayerInfo) }}</span>
        </div>
        <div v-if="pStatus" class="playerStatus">
          <PlayerStatus height="14" :status="pStatus" :showText="true" />
        </div>
        <div v-if="injDetails && pStatus !== 'ACT'" class="playerStatusDetails" :style="getStatusTextColor()">
          <span>{{ injDetails.ext_desc }}</span>
          <span v-if="injDetails.type"> - {{ injDetails.type }}</span>
        </div>
        <div class="playerHeat" :class="getHeatClass(availablePlayerInfo.heat)">
          <i v-if="availablePlayerInfo.heat === 'Cold'" class="fas fa-icicles"></i>
          <i v-else-if="availablePlayerInfo.heat === 'Hot'" class="fab fa-hotjar"></i>
          <span v-if="playerSummary"> {{ playerSummary.heatInfo }}</span>
          <span v-else> {{ playerDetails.bio.extra_info ? playerDetails.bio.extra_info.hotcold_info : '' }}</span>
        </div>
      </div>
      <div class="draftInfo">
        <!-- Draft statistics -->
        <div class="draftSummary" v-if="playerSummary && playerDetails == null">
          <div class="statEntry" style="margin-right: 20px;">
            <div>AG</div>
            <div>{{ playerSummary.wStats[0].ag }}</div>
          </div>
          <div class="statEntry" style="margin-right: 20px;">
            <div>AVG</div>
            <div>{{ roundStat(playerSummary.wStats[0].avg) }}</div>
          </div>
          <div class="statEntry">
            <div>PROJ</div>
            <div>{{ roundStat(playerSummary.wStats[0].proj) }}</div>
          </div>
        </div>

        <!-- Stats when viewing outside a draft -->
        <div v-else>
          <table class="statsTable" aria-label="Statistics">
            <tr class="statHeader subHeader headerTop" v-if="scoringStats.length > 1">
              <template v-for="(category, index) in scoringStats">
                <td :colspan="category.stats.length" :key="index">
                  <div>
                    {{ category.label }}
                  </div>
                </td>
              </template>
            </tr>
            <tr class="statHeader headerBottom" :class="{ headerTop: scoringStats.length == 1 }">
              <template v-for="(category, index1) in scoringStats">
                <template v-for="(stat, index2) in category.stats">
                  <td :key="index1 + '_' + index2">
                    <div style="font-weight: bold;" :style="sport === 'NFL' ? 'margin-bottom: 5px;' : ''">
                      {{ stat.label }}
                    </div>
                  </td>
                </template>
              </template>
            </tr>
            <tr>
              <template v-for="(category, index1) in scoringStats">
                <template v-for="(stat, index2) in category.stats">
                  <td class="statEntry" :key="index1 + '_' + index2">
                    <div>{{ playerHasStat(stat.path) ? getStat(stat.path) || '-' : '-' }}</div>
                  </td>
                </template>
              </template>
            </tr>
          </table>
        </div>

        <!-- Draft and Queue buttons, only when viewing from a draft -->
        <div class="draftButtons" v-if="isDraft && playerSummary" :style="sport !== 'NFL' ? 'margin-top: -25px;' : 'margin-top: -15px;'">
          <DraftPlayerButton :draftState="draftState" :player="playerSummary" />
          <button v-if="isDraftable(playerSummary) && !isInQueue(playerSummary)" style="margin-left: 10px;" class="ob-btn"
            @click="selectPlayerForQueue()"
          >
            QUEUE <i class="fal fa-plus" style="margin-left: 4px;"></i>
          </button>
          <button v-else-if="isDraftable(playerSummary) && isInQueue(playerSummary)" style="margin-left: 10px;" class="ob-btn ob-btn-red"
            @click="selectPlayerForQueue()"
          >
            QUEUE <i class="fal fa-minus" style="margin-left: 4px;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import DraftPlayerButton from '@/views/Draft/DraftButtons/DraftPlayerButton';
import EventBus from '@/event-bus';

export default {

  components: {
    PlayerHeadshot,
    PlayerStatus,
    DraftPlayerButton,
  },

  props: {
    isDraft: Boolean,
    draftState: Object,
    playerSummary: Object,
    playerDetails: Object,
    sport: String,
  },

  computed: {

    availablePlayerInfo() {
      return this.playerSummary || this.playerDetails;
    },

    injDetails() {
      const pinfo = this.playerDetails;
      if (pinfo && pinfo.injuryDetails && pinfo.injuryDetails.ext_desc) {
        return pinfo.injuryDetails;
      }
      return null;
    },

    pStatus() {
      if (this.playerDetails && this.playerDetails.status) {
        return this.playerDetails.status;
      }
      return '';
    },

    fullPositions() {
      if (!this.draftState) {
        return [];
      }
      const tempFullPositions = {};
      this.draftState.roster.forEach((pos) => {
        tempFullPositions[pos.short] = pos.num <= pos.players.length;
      });
      const fPositions = tempFullPositions;
      return fPositions;
    },

  },

  data() {
    return {
      scoringStats: {},
    };
  },

  created() {
    this.scoringStats = this.$ScoringStats.getHeaderScoringStats(this.sport, this.availablePlayerInfo.position);
  },

  methods: {

    getStatusTextColor() {
      switch (this.pStatus) {
        case 'ACT': return 'color: #4EB959;';
        case 'AVA':
        case 'TBA':
        case 'BKUP': return 'color: #F2994A;';
        case 'OUT':
        case 'DNP':
        case 'SUSP':
        case 'INJ':
        case 'MIN': return 'color: #E5252C;';
      }
      return '';
    },

    getTeamLogo() {
      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ this.availablePlayerInfo.team.toLowerCase() +'.png');
    },

    getPitchingHand(player) {
      if (!player) {
        return '';
      }
      if (player.position != 'P' && player.position != 'SP') {
        return '';
      }
      if (player.bio && player.bio.extra_info) {
        const throwHand = player.bio.extra_info.throwing_hand;
        switch (throwHand) {
          case 'right': return 'RHP';
          case 'left': return 'LHP';
          case 'both': return 'SHP';
        }
      }
      return '';
    },

    roundStat(stat) {
      if (stat == null) {
        return '';
      }
      return stat.toFixed(0);
    },

    playerHasStat(statPath) {
      if (!statPath || !this.playerDetails.statistics) {
        return false;
      }

      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      if (playerRole === '' || !playerRole) {
        return false;
      }
      return this.playerDetails.statistics[playerRole];
    },

    getStat(statPath) {
      if (!statPath || !this.playerDetails.statistics) {
        return '';
      }

      const season = this.playerDetails.statistics;
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);
      if (!season[playerRole]) {
        return '0';
      }
      const stat = season[playerRole][statType];
      if (stat === null || stat === undefined) {
        return '0';
      }
      return stat;
    },

    getHeatClass(heat) {
      if (heat === 'Cold') {
        return 'coldHeat';
      }
      if (heat === 'Hot') {
        return 'hotHeat';
      }
      return '';
    },

    isDraftable(player) {
      return !(this.draftState.pickedPlayerIds[this.playerSummary.id] !== undefined ||
        this.fullPositions[this.playerSummary.draftPos] === true);
    },

    isInQueue() {
      return this.draftState.pickQueue.ids[this.playerSummary.id] !== undefined;
    },

    selectPlayerForQueue() {
      EventBus.$emit('QUEUE_PLAYER_SELECTED', this.playerSummary);
    },

    draftPlayer() {
      EventBus.$emit('PLAYER_DRAFTED', this.playerSummary);
    },

  },
};
</script>

<style lang="scss" scoped>

.activityContainer {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

.selectedPlayerContainer {
  display: flex;
}

.playerInfoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  white-space: nowrap;
}

.draftInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 30px;
  margin-left: 20px;
}

.draftSummary {
  display: flex;
  text-align: center;
  margin-right: 10px;
  font-weight: bold;
  z-index: 1;
}

.draftButtons {
  display: flex;
  margin-right: 2px;
  justify-content: flex-end;
  width: 100%;;
}

.headshotContainer {
  text-align: center;
  padding: 5px;
  margin-right: 15px;
  margin-left: 10px;
  position: relative;
}

.headshot {
  width: 75px;
  height: 75px;
  margin-top: 4px;
}

.teamName {
  margin-top: 2px;
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  text-transform: uppercase;
}

.teamLogoContainer {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 0;
  right: -330px;
}

.teamLogo {
  height: 120px;
  width: 120px;
  opacity: 0.5
}

.playerName {
  font-weight: bold;
  font-size: 18px;
  z-index: 1;
}

.playerInfo {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: -4px;
  color: var(--obcolor-font-secondary);
  z-index: 1;

  span {
    margin-right: 5px;
  }
}

.playerStatus {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.playerStatusDetails {
  margin-top: 6px;
  font-size: 14px;
  z-index: 1;
}

.playerHeat {
  margin-top: 6px;
  font-size: 14px;
  z-index: 1;
  display: flex;
  align-items: center;
  i {
    margin-right: 4px;
  }
}

.coldHeat {
  color: var(--obcolor-player-cold);
}

.hotHeat {
  color: var(--obcolor-player-hot);
}

// // Stats
.statEntry, .headerBottom {
  div:first-child {
    margin-bottom: 10px;
  }
}

.statEntry div{
  color: var(--obcolor-font-primary);
  height: 26px;
}

.statsTable td {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  text-align: center;

  div {
    background: var(--obcolor-input-box);
    padding: 5px 0;
    box-sizing: border-box;
    height: auto;
    min-height: 20px;
  }
}

.statsTable td:not(.subHeader) {
  width: 60px;
  box-sizing: border-box;
}

.subHeader div {
  padding: 0 !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.headerTop td:first-child div {
  border-top-left-radius: 20px;
}

.headerTop td:last-child div {
  border-top-right-radius: 20px;
}

.headerBottom td:first-child div {
  border-bottom-left-radius: 20px;
}

.headerBottom td:last-child div {
  border-bottom-right-radius: 20px;
}

.statEntry:first-child div {
  border-radius: 20px 0 0 20px;
}

.statEntry:last-child div {
  border-radius: 0 20px 20px 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  td {
    padding: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .draftSummary {
    display: none;
  }
}
</style>