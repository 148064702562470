<template>
  <div class="ob-page">
    <div class="leftSection">
      <img :src="themeLogo" class="obLogo" alt="OwnersBox logo">
      <h1>FOR THE LOVE<br><span>OF THE GAME</span></h1>
      <p class="contestText">Compete. Win. Play Daily Fantasy Sports</p>

      <button class="ob-btn" @click="signUp()">Sign up</button>
      <div class="hasAccount">Already have an account? <router-link to="/login" class="linkText">Login</router-link></div>
      <div class="helpTxt">If you or someone you know has a gambling problem, help is available. Call: 1-800-GAMBLER</div>
    </div>

    <div class="rightSection">
      <img class="rightBg" :src="require('@/assets/onboarding/bg2.png')" alt="background">
      <carousel :value="0"
        :perPage="1"
        :centerMode="true"
        paginationColor="var(--btn-grey-light)"
        paginationActiveColor="white"
        autoplay
        loop
        :autoplayTimeout="6000"
        :paginationSize="12"
      >
        <slide v-for="(slide, index) in slides" :key="index">
          <div class="containerBox">
            <img :src="require('@/assets/contestbadge/' + slide.icon + '.png')" :alt="slide.altText">
            <div class="title">{{ slide.title }}</div>
            <div class="description">{{ slide.description }}</div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import {mapState} from 'vuex';

export default {
  name: 'LoginView',

  components: {
    Carousel,
    Slide,
  },

  computed: {
    ...mapState(['theme']),

    themeLogo() {
      const themeColour = this.theme == 'dark' ? 'white' : 'black';
      return require('@/assets/logo_alt_' + themeColour + '.png');
    },
  },

  data() {
    return {
      slides: [
        {
          icon: 'player_picks',
          altText: 'Player Picks',
          title: 'QUICK PICKS, QUICK PAYOUTS',
          description: 'Select "More" or "Less" on your favorite players, and watch live to see if you win.',
        },
        {
          icon: 'salarycap',
          altText: 'Salary Cap',
          title: 'BUILD YOUR WAY TO VICTORY',
          description: 'Build your dream roster and compete against others for cash prizes.',
        },
        {
          icon: 'lightning_lineups',
          altText: 'Lightning Lineups',
          title: 'SPIN & WIN IN A FLASH',
          description: 'Spin, pick your line-up, and win up to 6x your entry. It’s that easy.',
        },
      ],
    };
  },

  methods: {
    signUp() {
      this.$router.push('/signup');
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

.ob-page {
  font-family: 'Archivo', sans-serif;
  display: flex;
  justify-content: space-around;
  gap: 90px;
  margin-top: -35px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  width: 520px;
  min-width: 475px;
  padding-top: 40px;

  .obLogo {
    width: 255px;
    padding-bottom: 140px;
  }

  h1 {
    font-size: 36px;
    font-weight: 900;
    padding-bottom: 40px;

    span {
      font-size: 64px;
      font-weight: 900;
      color: var(--obcolor-ob-blue);
    }
  }

  .contestText {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    padding-bottom: 40px;
  }

  button {
    margin-top: 20px;
    height: 50px;
  }

  .hasAccount {
    padding-top: 35px;
    text-align: center;

    .linkText {
      cursor: pointer;
      font-weight: 900;
      color: var(--obcolor-ob-blue);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.rightSection {
  max-width: 645px;
  max-height: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 150px;

  .rightBg {
    position: absolute;
    width: 100%;
    height: 750px;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  .containerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;
    text-align: center;
    color: white;
  }

  img {
    padding-bottom: 30px;
    width: 240px;
  }

  .description {
    max-width: 400px;
  }

  .title {
    font-size: 36px;
    font-weight: 900;
    line-height: 60px;
    color: var(--obcolor-ob-blue);
  }
}

::v-deep {
  .VueCarousel-dot:focus {
    outline: none !important;
  }
  .VueCarousel-dot--active {
    width: 25px !important;
    border-radius: 100px !important;
  }
}

.helpTxt {
  text-align: center;
  border-top: 1px var(--obcolor-font-secondary) solid;
  padding-top: 50px;
  margin-top: 50px;
}
</style>