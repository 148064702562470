<template>
  <div class="filterBtnContainer">
    <button class="btnFilter" :class="disabled ? 'btnDisabled' : selected ? 'btnSelected' : ''" @click="buttonClicked">
      <div style="display: flex; align-items: center;">
        <img v-if="sport && (!selected || disabled)" :src="require('@/assets/icons/sporticon_' + sport + '.png')" :alt="`${sport} Icon`"
          class="sportIcon"
        >
        <img v-else-if="sport && selected" :src="require('@/assets/icons/sporticon_' + sport + '_hl.png')" :alt="`${sport} Icon`"
          class="sportIcon"
        >

        <template v-for="(segment, index) in btnLabelSegments">
          <!-- OwnersBucks-->
          <img v-if="segment === '#OB#' && selected" :src="require('@/assets/icons/ownersbucks-inv.png')" class="ownersbucksIcon"
            :key="index" alt="OwnersBucks Icon"
          >
          <img v-else-if="segment === '#OB#'" :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon"
            :key="index" alt="OwnersBucks Icon"
          >

          <!-- Rookie Contests -->
          <img v-else-if="segment === '#RC#' && selected" :src="require('@/assets/icons/rookiecontest-inv.png')" class="rookieIcon"
            :key="index" alt="Rookie Icon"
          >
          <img v-else-if="segment === '#RC#'" :src="require('@/assets/icons/rookiecontest-'+ theme +'.png')" class="rookieIcon"
            :key="index" alt="Rookie Icon"
          >

          <span v-else :key="index">{{ segment }}</span>
        </template>
      </div>
    </button>
    <div v-if="bottomText" class="bottomText">{{ bottomText }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    selected: {type: Boolean, default: false},
    label: {type: String, default: ''},
    bottomText: {type: String, default: null},
    sport: {type: String, default: null},
    disabled: {type: Boolean, default: false},
  },

  computed: {
    ...mapState(['theme']),
    btnLabelSegments() {
      return this.label.split(/(#OB#|#RC)/g);
    },
  },

  methods: {
    buttonClicked(clickEvent) {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomText {
  margin-top: -2px;
  text-align: center;
  font-size: 11px;
  color: var(--obcolor-font-secondary);
}

.filterBtnContainer {
  width: 90px;
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.btnFilter.btnDisabled .rookieIcon {
  filter: grayscale(1);
}

.btnFilter {
  background-color: var(--obcolor-background-2);
  font-weight: bold;
  color: var(--obcolor-font-secondary);
  border: 1px solid var(--obcolor-background-2);
  border-radius: 10px;
  height: 40px;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.btnDisabled {
    cursor: not-allowed;
    background-color: var(--obcolor-background-6);
    .sportIcon, .rookieIcon, .ownersbucksIcon {
      filter: grayscale(1);
    }
  }
  &.btnDisabled:hover {
    background-color: var(--obcolor-background-6);
  }

  &.btnSelected {
    color: white;
    background-color: var(--obcolor-ob-blue);

    &:hover {
      background-color: var(--obcolor-ob-blue-tinted);
    }
    &:active {
      background-color: var(--obcolor-accent-hl);
    }
    .rookieIcon {
      filter: brightness(0) invert(1);
    }
  }
}

.btnFilter:hover {
  background-color: var(--obcolor-bg-hover);
}

.btnFilter:active {
  background-color: var(--obcolor-background-5);
}

.sportIcon {
  margin-left: -6px;
  margin-right: 6px;
  height: 25px;
}
.ownersbucksIcon {
  height: 14px;
  margin-right: 6px;
}
.rookieIcon {
  height: 18px;
}
</style>