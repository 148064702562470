<template>
  <div class="ob-page">
    <div class="ob-box" style="padding: 20px; font-family: Arial, sans-serif; font-size: 14px;">
      <PrivacyPolicy />
    </div>
  </div>
</template>

<script>
import PrivacyPolicy from '@/views/KnowledgeBase/PrivacyPolicy';

export default {
  components: {
    PrivacyPolicy,
  },
};
</script>
