<template>
  <span :style="style">
    {{ time }}
  </span>
</template>

<script>
// This component is similar to the Countdown Timer
// The format is different, it does not include seconds, #h #m
// Also, it allows for a second end value
// If the first end is past, it counts down to the second end value
export default {
  name: 'LobbyContestCountdown',

  props: {
    end: {type: Number, default: 0},
    end2: {type: Number, default: null},
    serverOffset: {type: Number, default: 0},
    tournament: {type: Boolean, default: false},
  },


  data() {
    return {
      time: 0,
      timeout: null,
      style: '',
    };
  },

  watch: {
    end() {
      this.getTime();
    },
    end2() {
      this.getTime();
    },
    serverOffset() {
      this.getTime();
    },
    tournament() {
      this.getTime();
    },
  },

  created() {
    this.getTime();
    this.loopGetTime();
  },

  destroyed() {
    clearTimeout(this.timeout);
  },

  methods: {
    getOffset() {
      return this.serverOffset ? this.serverOffset : 0;
    },

    getTime() {
      if (this.end) {
        let seconds = this.$moment(this.end + this.getOffset()).diff(this.$moment(), 'seconds');

        // If first end point is past, use 2nd end value
        if (seconds < this.end2) {
          seconds = this.$moment(this.end2 + this.getOffset()).diff(this.$moment(), 'seconds');
        }

        // If less than 15 minutes (900s) remaining, highlight red
        this.style = seconds < 900 ? 'color: red;' : '';

        this.formatContestTime(seconds);

        this.$emit('tick', seconds);
      }
    },

    formatContestTime(seconds) {
      // Format as date if show as date flag is set and the end time stamp is today
      const sDate = this.$moment(this.end).format('ddd MMM D,');
      const today = this.$moment().format('ddd MMM D,');

      const todayOfWeek = this.$moment().format('YYYY-MM-DD');
      const weekFromToday = this.$moment().add(7, 'days').format('YYYY-MM-DD');
      const contestDayOfWeek = this.$moment(this.end).format('YYYY-MM-DD');

      // [] indicates inclusion and () indicates exclusion. Bellow todayOfWeek is included while weekFromToday is not
      const isBetween = this.$moment(contestDayOfWeek).isBetween(todayOfWeek, weekFromToday, undefined, '[)');

      if (this.tournament && sDate === today) {
        this.time = this.sameDayContests(this.end);
      } else if (this.tournament && isBetween) {
        this.time = this.$moment(this.end).format('ddd h:mma');
      } else if (this.tournament && !isBetween) {
        this.time = this.$moment(this.end).format('MMM D h:mma');
      } else {
        this.time = this.formatTimeToClock(seconds);
      }
    },

    sameDayContests(time) {
      return 'Today ' + this.$moment(time).format('h:mma');
    },

    formatTimeToClock(seconds) {
      // If contest has started or start time has passed
      if (seconds < 0) {
        return '0';
      }

      // If negative, round minutes up, so we don't have -1 hours & minutes when 1 second has passed
      let minutes = seconds > 0 ? Math.floor(seconds/60) : Math.ceil(seconds/60);
      const hours = minutes > 0 ? Math.floor(minutes/60) : Math.ceil(minutes/60);
      minutes = minutes % 60;
      seconds = seconds % 60;

      if (hours == 0 && minutes == 0) {
        if (seconds <= 0) {
          return '0m 0s';
        }
        return '0m ' + seconds + 's';
      }
      if (hours >= 24) {
        const days = Math.floor(hours/24);
        const minutes = hours % 24;
        return days + 'd ' + minutes + 'h';
      }
      if (hours == 0) {
        return minutes + 'm ' + seconds + 's';
      }
      return hours + 'h ' + minutes + 'm';
    },

    // This timer only shows seconds, so updating every second is required
    loopGetTime() {
      const self = this;
      this.timeout = setTimeout(function() {
        self.getTime();
        self.loopGetTime(1000);
      }, 1000);
    },
  },
};
</script>