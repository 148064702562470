// @ts-check


/**
 * @param {unknown} a
 * @param {unknown} b
 * @return {boolean}
 */
export function deepEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (typeof a != typeof b) {
    return false;
  }

  if (a instanceof Array && b instanceof Array) {
    return arrayDeepEqual(a, b);
  }

  if (typeof a === 'object' && typeof b === 'object') {
    return objectDeepEqual(a, b);
  }

  return false;
}

/**
 * @param {unknown[]} a
 * @param {unknown[]} b
 * @return {boolean}
 */
function arrayDeepEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!deepEqual(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

/**
 * @param {object} a
 * @param {object} b
 * @return {boolean}
 */
function objectDeepEqual(a, b) {
  if (a === null || b === null) {
    return a === b;
  }

  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();

  if (!deepEqual(aKeys, bKeys)) {
    return false;
  }

  for (let i = 0; i < aKeys.length; i++) {
    if (!deepEqual(a[aKeys[i]], b[bKeys[i]])) {
      return false;
    }
  }

  return true;
}