<template>
  <div class="playerSummary">
    <div class="draftPos">
      <div>{{ entry.draftPos }}</div>

      <!-- Can't Swap -->
      <div v-if="showSwapBtn() && entry.dropped == null && swapStatus === 'invalid'" disabled class="ob-btn-grey swapBtn">
        <i class="fas fa-times" style="margin-top: 2px;"></i>
      </div>

      <!-- Waivers -->
      <div v-else-if="showSwapBtn() && entry.dropped == null && swapStatus === 'waivers'" class="ob-btn-grey swapBtn" @click.stop="openSwapComponent()"
        v-tooltip.bottom="{content: 'Create Waiver', class:'ObTooltipHeat'}"
      >
        <i class="fas fa-stopwatch" style="margin-top: 2px;"></i>
      </div>

      <!-- Swap -->
      <div v-else-if="showSwapBtn() && entry.dropped == null && swapStatus === 'swappable'" class="ob-btn-grey swapBtn" @click.stop="openSwapComponent()"
        v-tooltip.bottom="{content: 'Swap Player', class:'ObTooltipHeat'}"
      >
        <i v-if="swapStatus === 'swappable'" class="fas fa-random" style="margin-top: 2px; margin-left: 1px;"></i>
      </div>
    </div>

    <PlayerHeadshot :id="entry.id" :sport="league.sport" :team="entry.player.team"
      :showLogo="true"
    />

    <div class="playerInfoContainer" v-if="entry.dropped == null">
      <div class="playerNameStatus">
        <PlayerStatus :status="entry.player.status" style="display: inline-block; margin-top: -2px;" />
        <div class="playerName">
          {{ entry.player.name.shortName() }}
        </div>
        <!-- Need HeatInfo in JSON -->
        <i v-if="entry.player.heat === 'Cold'" class="fas fa-icicles heatIcon cold"
          v-tooltip.right="getHeatInfo(entry.player) != null ? {content: getHeatInfo(entry.player), class:'ObTooltipHeat'} : 'Cold'"
        ></i>
        <i v-else-if="entry.player.heat === 'Hot'" class="fab fa-hotjar heatIcon hot"
          v-tooltip.right="getHeatInfo(entry.player) != null ? {content: getHeatInfo(entry.player), class:'ObTooltipHeat'} : 'Hot'"
        ></i>
        <i v-if="league.sport == 'NFL' && nflFieldState.teamHasBall" class="fas fa-football-ball ballIcon"></i>
      </div>

      <!-- Live Game Info -->
      <div v-if="!isToday || !gameInfo" class="playerInfo">
        <span>No Game Today</span>
      </div>

      <div v-else class="playerInfo" :style="getLiveTextColour()">
        <span>{{ gameInfo }}</span>
      </div>
      <div v-if="league.sport == 'NFL' && nflFieldState.statLine" class="playerInfo" style="padding-top: 5px;">
        {{ nflFieldState.statLine }} <div v-if="nflFieldState.inRedZone" class="redZone">RZ</div>
      </div>
    </div>

    <div class="droppedPlayerInfoContainer" v-else>
      <div>{{ entry.player.name.shortName() }}</div>
      <div>Dropped</div>
      <div>
        <span style="margin-right: 3px;">{{ parseInt(entry.dropped) | moment("MMM D h:mma") }}</span>
        <span>W{{ entry.droppedWeek }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  props: {
    entry: Object,
    league: Object,
    isMyTeam: Boolean,
    rosterGR: Object,
    showSwap: Boolean,
    swapStatus: String,
  },

  computed: {
    ...mapState(['todaysGames']),
  },

  watch: {
    todaysGames(to, from) {
      this.setupGameData();
    },

    entry(to, from) {
      this.clearData();
      this.setupGameData();
    },
  },

  data() {
    return {
      nextGame: null,
      gameData: null,
      gameInfo: '',
      isToday: false,
      nflFieldState: {},
    };
  },

  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  created() {
    this.clearData();
    this.setupGameData();
    EventBus.$on('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  destroyed() {
    EventBus.$off('LIVE_GAME_UPDATE', this.onGameUpdate);
  },

  methods: {
    setupNflFieldState() {
      if (!this.league || this.league.sport != 'NFL' || !this.nextGame) {
        return;
      }

      this.nflFieldState = this.$SportInfo.getNflFieldState(this.entry, this.gameData);
    },

    onGameUpdate(gameId) {
      if (this.nextGame && gameId == this.nextGame.id) {
        this.setupGameData();
      }
    },

    clearData() {
      this.nextGame = null;
      this.gameData = null;
      this.gameInfo = '';
      this.isToday = false;
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.league.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    gameHasStarted() {
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      return liveJSON && (liveJSON.status === 'mid-event' || liveJSON.status === 'post-event');
    },

    gameIsLive() {
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      return liveJSON && (liveJSON.status === 'mid-event');
    },

    getLiveTextColour() {
      if (this.league.sport == 'NFL') {
        return 'color: var(--obcolor-font-secondary)';
      }
      return this.gameIsLive() ? 'color: var(--obcolor-accent);' : 'color: var(--obcolor-font-primary);';
    },

    setupGameData() {
      const playerJSON = this.entry.player;

      if (playerJSON) {
        const nextGames = playerJSON.next_games;
        // If game is in slot 0 it is being played today
        if (nextGames && nextGames[0] && nextGames[0].id) {
          this.nextGame = nextGames[0];
          this.isToday = true;
          this.gameData = this.todaysGames[this.nextGame.id];
        } else if (nextGames && nextGames[1] && nextGames[1].id) {
          this.nextGame = nextGames[1];
          this.isToday = false;
        }
      }

      this.gameInfo = this.$SportInfo.getGameSummary(this.league.sport, this.gameData, this.entry.player.teamId, this.$moment);
      this.setupNflFieldState();
    },

    getGameInfo() {
      if (!this.nextGame) {
        return '';
      }

      const gameJSON = this.gameData;
      const liveJSON = this.gameData && this.gameData.live ? this.gameData.live : null;
      const opp = this.nextGame.opponent;

      if (liveJSON && liveJSON.status === 'mid-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        if (this.league.sport === 'MLB') {
          if (liveJSON.inning_half && liveJSON.inning) {
            gameInfo += liveJSON.inning_half === 'top' ? 'Top ' : 'Bot ';
            gameInfo += this.addOrdinalSuffix(liveJSON.inning);
          }
          return gameInfo;
        }
        const gameTime = liveJSON.gametime === '00:00' ? 'End' : liveJSON.gametime;
        gameInfo += gameTime + ' ' + this.addOrdinalSuffix(liveJSON.period);
        return gameInfo;
      } else if (liveJSON && liveJSON.status === 'post-event') {
        let gameInfo = '';
        if (opp === gameJSON.home_team.team_alias) {
          gameInfo += liveJSON.awayteam.score + '-' + liveJSON.hometeam.score + ' ';
        } else {
          gameInfo += liveJSON.hometeam.score + '-' + liveJSON.awayteam.score + ' ';
        }
        gameInfo += ' Final';
        return gameInfo;
      }

      // Game is not live, show time or date
      const gameDate = this.$moment(this.nextGame.date).format('ddd MMM D');
      const today = this.$moment().format('ddd MMM D');

      if (gameDate === today) {
        return this.$moment(this.nextGame.date).format('h:mma');
      }
      if (this.league.sport === 'NFL') {
        // If game is within this week range, show time instead of date
        for (const g in this.entry.fspGames) {
          if (this.entry.fspGames[g].id === this.nextGame.id) {
            return this.$moment(this.nextGame.date).format('ddd h:mma');
          }
        }
      }
      return gameDate;
    },

    addOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    openSwapComponent() {
      const selectedTab = this.$route.params.selectedTab;
      this.$router.push('/contest/matchup/' + selectedTab + '/' + this.league.id + '/' + this.league.matchup.id + '/swap/'+ this.entry.id);
    },

    showSwapBtn() {
      return this.isMyTeam && this.showSwap && (this.league.matchup && this.league.matchup.week === this.league.curWeek);
    },

    // TODO - this could be changed on server to make it more consistent with other sections
    getHeatInfo(playerJSON) {
      if (playerJSON.bio && playerJSON.bio.extra_info && playerJSON.bio.extra_info.hotcold_info) {
        return playerJSON.bio.extra_info.hotcold_info;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.playerSummary {
  display: flex;
  align-items: center;
  height: 100%;
}
.draftPos {
  width: 38px;
  margin-right: 4px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  color: var(--obcolor-font-primary);
}
.playerInfoContainer {
  min-width: 0;
  margin-left: 10px;
  text-align: left;
  text-overflow: ellipsis;
  flex: 1;
}
.playerNameStatus {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--obcolor-font-primary);
}
.playerName {
  display: inline-block;
  margin-left: 5px;
  margin-top: -2px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.teamLogo {
  width: 20px;
  margin-left: 0px;
  margin-right: 3px;
}
.heatIcon {
  margin-left: 6px;
  margin-top: -3px;
}
.heatIcon.cold {
  color: var(--obcolor-player-cold);
}
.heatIcon.hot {
  color: var(--obcolor-player-hot);
}
.swapBtn {
  padding: 3px;
  margin-top: 5px;
  width: 18px;
}
.playerInfo {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: -5px;
  color: var(--obcolor-font-secondary);
  text-align: left;
  white-space: nowrap;
  margin-left: 15px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    margin-right: 5px;
    text-overflow: ellipsis;
  }
}

.droppedPlayerInfoContainer {
  margin-left: 25px;
  text-align: left;
  white-space: nowrap;
  width: auto;
  color: var(--obcolor-text-faded);
}

.ballIcon {
  margin-left: 6px;
  margin-top: -4px;
  color: var(--obcolor-NBA);
}

.redZone {
  display: inline-block;
  background: red;
  color: white;
  font-weight: bold;
  padding: 1px 2px;
  border-radius: 4px;
  margin-left: 6px;
  width: max-content;
}
</style>