<template>
  <div style="margin-top: 20px;">
    <GameModalMLBLiveStats v-if="sport === 'MLB'" :gameData="gameData" :sport="sport" />
    <GameModalScoreboard :gameData="gameData" :sport="sport" />
    <GameModalStartingPitcher v-if="sport === 'MLB'" :gameData="gameData" :sport="sport" />
    <GameModalStartingQuarterback v-if="sport === 'NFL'" :gameData="gameData" :sport="sport" />
    <GameModalStartingGoalie v-if="sport === 'NHL'" :gameData="gameData" :sport="sport" />
    <GameModalStartingLineup v-if="sport === 'NBA'" :gameData="gameData" :sport="sport" />
    <GameModalBattingOrder v-if="sport === 'MLB'" :gameData="gameData" :sport="sport" />
    <GameModalScoringSummary :gameData="gameData" :sport="sport" />
    <GameModalTopPerformers :gameData="gameData" :sport="sport" />
    <GameModalTeamStats :gameData="gameData" :sport="sport" />
  </div>
</template>
<script>
import GameModalMLBLiveStats from '@/components/modals/GameModal/GameModalMLBLiveStats.vue';
import GameModalScoreboard from '@/components/modals/GameModal/GameModalScoreboard.vue';
import GameModalTopPerformers from '@/components/modals/GameModal/GameModalTopPerformers.vue';
import GameModalStartingPitcher from '@/components/modals/GameModal/GameModalStartingPitcher.vue';
import GameModalStartingQuarterback from '@/components/modals/GameModal/GameModalStartingQuarterback.vue';
import GameModalStartingGoalie from './GameModalStartingGoalie.vue';
import GameModalStartingLineup from '@/components/modals/GameModal/GameModalStartingLineup.vue';
import GameModalBattingOrder from '@/components/modals/GameModal/GameModalBattingOrder.vue';
import GameModalTeamStats from '@/components/modals/GameModal/GameModalTeamStats.vue';
import GameModalScoringSummary from '@/components/modals/GameModal/GameModalScoringSummary.vue';

export default {
  components: {
    GameModalMLBLiveStats,
    GameModalScoreboard,
    GameModalStartingPitcher,
    GameModalStartingQuarterback,
    GameModalStartingGoalie,
    GameModalStartingLineup,
    GameModalBattingOrder,
    GameModalTopPerformers,
    GameModalTeamStats,
    GameModalScoringSummary,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .sectionHeader {
    text-align: center;
    font-size: 20px;
    margin: 0;
  }
  .playerStatus {
    padding-right: 5px;
  }
}
</style>