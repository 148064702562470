<template>
  <div class="ob-box">
    <div class="badgeContainer">
      <img :src="require('@/assets/contestbadge/' + gameSlate.sport + '.png')" :alt="gameSlate.sport">
    </div>
    <div style="flex: 1; display: flex; flex-direction: column;">
      <div class="contestHeaderContainer">
        {{ isEdit ? 'Edit' : 'Create' }} Lineup
      </div>
      <div class="infoContainer">
        <div>
          <div>{{ gameSlate.name }}</div>
          <div class="infoLabel">Game Slate</div>
        </div>
        <div>
          <div>{{ gameSlate.sport }}</div>
          <div class="infoLabel">Sport</div>
        </div>
        <div>
          <div>{{ gameSlate.rosterFormat }}</div>
          <div class="infoLabel">Roster Format</div>
        </div>
        <div style="text-align: center;">
          <div>{{ gameSlate.games.length }}</div>
          <div class="infoLabel">Games</div>
        </div>
        <div v-if="isEdit" style="text-align: center;">
          <div>{{ numContests }}</div>
          <div class="infoLabel">Contests</div>
        </div>
        <div v-if="isEdit" style="text-align: center;">
          <div>{{ numEntries }}</div>
          <div class="infoLabel">Entries</div>
        </div>
        <div v-if="!isEdit" style="text-align: right;">
          <div style="font-weight: bold;">{{ contestCountdownVal }}</div>
          <div class="infoLabel">{{ $moment(gameSlate.contestStart).format('ddd, MMM D @ h:mma') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    gameSlate: {type: Object, default: null},
    lineupInfo: {type: Object, default: null},
    isEdit: {type: Boolean, default: null},
  },

  data() {
    return {
      contestCountdownInterval: null,
      contestCountdownVal: '',
    };
  },

  computed: {
    numContests() {
      return this.lineupInfo?.numContests || 0;
    },

    numEntries() {
      return this.lineupInfo?.numEntries || 0;
    },
  },

  created() {
    this.getContestTimeRemaining();
    this.setCountdownInterval();
  },

  destroyed() {
    clearInterval(this.contestCountdownInterval);
  },

  methods: {
    setCountdownInterval() {
      clearInterval(this.contestCountdownInterval);
      this.contestCountdownInterval = setInterval(() => {
        this.getContestTimeRemaining();
      }, 1000);
    },

    // Gets hour, minute and second difference between now and contest start
    // Displays it in a nice format, if contest has already started just show 00:00:00
    getContestTimeRemaining() {
      if (!this.gameSlate?.contestStart) {
        return '';
      }

      const now = this.$moment();
      const cDate = this.$moment(this.gameSlate.contestStart);

      if (cDate.diff(now) < 0) {
        this.contestCountdownVal = 'Game Slate Started';
        return;
      }

      const hours = String(cDate.diff(now, 'hours'));
      let minutes = String(cDate.diff(now, 'minutes') % 60);
      let seconds = String(cDate.diff(now, 'seconds') % 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      this.contestCountdownVal = hours + ':' + minutes + ':' + seconds;
    },
  },

};
</script>

<style lang="scss" scoped>

.ob-box {
  height: 80px;
  margin-bottom: 15px;
  display: flex;
  padding: 15px;
}

.badgeContainer {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 90px;
  }
}

.contestHeaderContainer {
  font-size: 16px;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  margin-top: 5px;
  padding-right: 10px;
}

.infoLabel {
  color: var(--obcolor-text-secondary);
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: -12px;
}

</style>