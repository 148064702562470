<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 460px; padding: 0; border-radius: 5px;"
    bodyStyle="margin: 0; background: var(--obcolor-background-5);"
  >
    <div slot="header" class="header">
      Payout Structure
    </div>

    <div slot="body" class="body">
      <PayoutTable class="payout-table" name="Perfect Picks"
        :defaultMultipliers="defaultPayoutMultipliers"
        :payoutMultipliers="payoutMultipliers"
      />
      <PayoutTable class="payout-table" name="Protected Picks"
        :defaultMultipliers="settingsPayouts.protectedPayoutMultipliers"
        :payoutMultipliers="contest.protectedPayoutMultipliers"
      />
      <div class="payout-info">
        <PropsReducedPayout v-if="payoutReduced" class="payout-reduced" />
        <h3>Why do payouts get modified?</h3>
        <p>
          Payouts may change based on the specific combination of Player Picks
          selected to balance the risk and chance of winning, ensuring fairness
          and reflecting the likelihood of each combination occurring.
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import PayoutTable from '@/components/modals/Props/PayoutTable.vue';
import {deepEqual} from '@/utils/objects';
import PropsReducedPayout from '@/views/Props/LineupBuilder/PropsReducedPayout.vue';

export default {
  components: {
    Modal,
    PayoutTable,
    PropsReducedPayout,
  },

  data() {
    return {
      contest: null,
      isOpen: false,
    };
  },

  props: {
    propsSettings: {
      type: Object,
      default: undefined,
    },
  },

  created() {
    EventBus.$on('OPEN_PROPS_PAYOUT_STRUCTURE_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_PROPS_PAYOUT_STRUCTURE_MODAL', this.openModal);
  },

  computed: {
    settingsPayouts() {
      return this.propsSettings || this.contest;
    },
    defaultPayoutMultipliers() {
      return this.normalizeMultipliers(this.settingsPayouts.payoutMultipliers);
    },
    payoutMultipliers() {
      return this.normalizeMultipliers(this.contest.payoutMultipliers);
    },
    payoutReduced() {
      if (!this.propsSettings || !this.contest) {
        return false;
      }
      return !deepEqual(
          this.propsSettings.payoutMultipliers,
          this.contest.payoutMultipliers,
      ) ||
      !deepEqual(
          this.propsSettings.protectedPayoutMultipliers,
          this.contest.protectedPayoutMultipliers,
      );
    },
  },

  methods: {
    openModal(contest) {
      this.contest = contest;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    normalizeMultipliers(payoutMultipliers) {
      return Object.fromEntries(Object.entries(payoutMultipliers).map(([count, multiplier]) => [count, {0: multiplier}]));
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--obcolor-background-5);
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 15px;
}

.body {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 0px 15px 15px;

  .promo-code-name {
    font-weight: bold;
    color: var(--obcolor-yellow);
  }

  .fee {
    color: var(--obcolor-green);
    font-weight: bold;
  }
}

.footer {
  background: var(--obcolor-background-3);
  padding: 15px;

  button {
    height: 100%;
    width: 100%;
  }
}

.payout-table {
  margin-bottom: 8px;
}

.payout-info {
  text-align: left;
  color: var(--obcolor-font-primary);

  .payout-reduced {
    padding: 8px;
    background-color: rgba(var(--obcolor-ob-blue-rgb), 0.05);
    margin: 8px 0;
  }

  h3 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin: 4px 0;
  }

  p {
    font-weight: 400;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
}

</style>
