<template>
  <div class="container">
    <div class="arrowContainer">
      <a class="onboardingBackBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
    </div>
    <h1>Error Creating Account</h1>
    <p>Please address the following problems to continue creating your account</p>

    <!-- Username Errors -->
    <div v-if="usernameError" class="inputContainer">
      <div class="inputLabel">Username</div>
      <input v-model="username" placeholder="Enter your username" type="text"
        class="errorBorder"
      >
      <ErrorPanel :message="usernameError" />
    </div>

    <!-- Email Errors -->
    <div v-if="emailError" class="inputContainer">
      <div class="inputLabel">Email</div>
      <input v-model="email" placeholder="Enter your email" type="email"
        class="errorBorder"
      >
      <ErrorPanel :message="emailError" />
    </div>

    <!-- Password Errors -->
    <div v-if="passwordError" class="inputContainer">
      <div class="inputLabel">
        Password
        <Tooltip pos="top" textKey="Password_Requirements" />
      </div>
      <div class="passwordContainer">
        <input v-model="password" placeholder="Enter your password" :type="showPassword ? 'text' : 'password'"
          name="email" class="errorBorder"
        >
        <i v-if="showPassword" @click="toggleShowPassword" class="fas fa-eye"></i>
        <i v-else @click="toggleShowPassword" class="fas fa-eye-slash"></i>
      </div>
      <ErrorPanel :message="passwordError" />
    </div>

    <!-- Loading & Submit Button -->
    <button v-if="processing" class="ob-btn-grey" disabled>
      <i class="fad fa-spinner-third fa-spin"></i>
      Creating Account...
    </button>
    <button v-else class="ob-btn" :disabled="!canSubmit"
      @click="createAccount"
    >
      Create Account
    </button>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  components: {
    ErrorPanel,
    Tooltip,
  },

  props: {
    signupData: {type: Object, default: null},
  },

  data() {
    return {
      processing: false,
      username: '',
      email: '',
      password: '',
      showPassword: false,
    };
  },

  computed: {
    usernameError() {
      return this.signupData?.signupErrors?.username;
    },

    emailError() {
      return this.signupData?.signupErrors?.email;
    },

    passwordError() {
      return this.signupData?.signupErrors?.password;
    },

    canSubmit() {
      // We can only re-submit if the fields with errors have been changed
      if (this.usernameError && this.username == this.signupData?.userName) {
        return false;
      }
      if (this.emailError && this.email == this.signupData?.email) {
        return false;
      }
      if (this.passwordError && this.password == this.signupData?.password) {
        return false;
      }
      return true;
    },
  },

  watch: {
    'signupData.signupErrors'() {
      this.setInitialValues();
      this.processing = false;
    },
  },

  created() {
    this.setInitialValues();
  },

  methods: {
    goBack() {
      EventBus.$emit('ON_BOARDING_BACK');
    },

    setInitialValues() {
      this.username = this.signupData?.userName;
      this.email = this.signupData?.email;
      this.password = this.signupData?.password;
    },

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    createAccount() {
      EventBus.$emit('UPDATE_SIGNUP_DATA', 'userName', this.username);
      EventBus.$emit('UPDATE_SIGNUP_DATA', 'email', this.email);
      EventBus.$emit('UPDATE_SIGNUP_DATA', 'password', this.password);
      this.processing = true;
      EventBus.$emit('ON_BOARDING_CREATE_ACCOUNT');
    },
  },

};
</script>

<style lang="scss" scoped>
.inputContainer {
  margin-top: 25px;
}
.inputLabel {
  margin-bottom: 10px;
}
input {
  width: calc(100% - 35px);
}

.errorPanel {
  margin-top: 0;
}

.passwordContainer {
  position: relative;
  > i {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

button {
  margin-top: 50px;
  height: 60px;
}
</style>