<template>
  <div style="flex: 1; display: flex; flex-direction: column; min-height: 0;">
    <div class="ob-box ob-scroll" style="margin-bottom: 10px;">
      <table style="width: 100%;" aria-label="Team Swap">
        <!-- Headings -->
        <tr>
          <th style="color: var(--obcolor-font-primary); font-size: 14px; min-width: 390px;">
            <span v-if="isWaiverClaim">Waiver Claim</span>
            <span v-else>Swap Players</span>
          </th>
          <template v-if="selectedDataField == 'schedule'">
            <th v-if="leagueData.sport != 'NFL'">GP</th>
            <th>FPTS</th>
            <th v-if="leagueData.sport != 'NFL'">GR</th>
            <th>PROJ</th>
            <th class="endSection">sAVG</th>
            <th v-for="n in 7" :key="n">
              <div v-if="leagueData.roster.days != null">{{ [leagueData.roster.days[n-1], 'YYYY-MM-DD'] | moment("ddd") }}</div>
            </th>
          </template>
          <template v-else>
            <template v-for="(category, index) in getScoringStats(toDrop)">
              <th v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
                :class="{endSection: index1 == category.stats.length - 1 && index != getScoringStats(toDrop).length - 1}"
              >
                {{ stat.label }}
              </th>
            </template>
          </template>
          <!-- Width of scrollbar, makes it aligned with other table -->
          <th style="width: 7px; padding: 0;"></th>
        </tr>

        <!-- toDrop Player -->
        <PlayerSwapRow v-if="toDropPlayer != null"
          :player="toDropPlayer" :leagueData="leagueData" :statusMap="statusMap"
          :selectedDataField="selectedDataField"
          :selectedWeek="selectedWeek" :toDrop="toDrop" :showMinus="true"
          :addRightSpacer="true"
        />

        <!-- toAdd player -->
        <PlayerSwapRow v-if="toAdd != null"
          :player="toAdd" :leagueData="leagueData" :statusMap="statusMap"
          :selectedDataField="selectedDataField"
          :selectedWeek="selectedWeek" :toDrop="toDrop" :showPlus="true"
        />
      </table>
    </div>

    <div class="ob-box" style="flex: 1; display: flex; flex-direction: column;">
      <div class="ob-scroll" style="flex: 1; overflow: auto; min-height: 0;">
        <table style="min-width: max-content; width: 100%;" aria-label="League Data">
          <!-- Headings -->
          <tr v-if="selectedDataField == 'schedule'" class="headerRow categoryHeaderRow">
            <th class="unselectable" :colspan="leagueData.sport != 'NFL' ? '6' : '4'" scope="col"></th>
            <th class="unselectable" colspan="7" style="text-align: center; padding-bottom: 2px; padding-top: 6px;"
              scope="col"
            >
              <div style="display: flex; align-items: center; justify-content: center; height: 15px;">
                <div v-if="selectedWeek > 0" class="weekBtn" @click="decreaseWeek()"><i class="fa fa-chevron-left"></i></div>
                <div v-else style="width: 20px;"></div>
                <div style="margin-top: 2px; margin-left: 8px; margin-right: 8px;">Week {{ selectedWeek + 1 }}</div>
                <div v-if="selectedWeek < leagueData.numWeeks - 1" class="weekBtn" @click="increaseWeek()"><i class="fa fa-chevron-right"></i></div>
                <div v-else style="width: 20px;"></div>
              </div>
            </th>
          </tr>
          <tr v-else class="headerRow categoryHeaderRow">
            <th class="unselectable" scope="row"></th>
            <th style="text-align: center; padding-bottom: 0;"
              v-for="(category, index) in getScoringStats(toDrop)"
              :key="index" :colspan="category.stats.length" scope="row"
            >
              {{ category.label }}
            </th>
          </tr>
          <tr class="headerRow columnHeaderRow">
            <th class="unselectable" scope="row">
              <div class="searchInput" style="display: inline-block; margin-right: 5px;">
                <i class="fa fa-search"></i>
                <input placeholder="Search Player List..." v-model="searchPlayer">
              </div>

              <div class="toggleSwitchContainer" style="display: inline-block;">
                <div class="toggleSwitch" :class="{selected: selectedDataField == 'schedule'}" @click="() => selectedDataField = 'schedule'">SCHEDULE</div>
                <div class="toggleSwitch" :class="{selected: selectedDataField == 'stats'}" @click="() => selectedDataField = 'stats'">STATS</div>
              </div>
            </th>

            <!-- Schedule View Headers -->
            <template v-if="selectedDataField == 'schedule'">
              <th v-if="leagueData.sport != 'NFL'" @click="() => scheduleSort = 'pg'" :class="{selectedSort: scheduleSort == 'pg'}">GP</th>
              <th @click="() => scheduleSort = 'fpts'" :class="{selectedSort: scheduleSort == 'fpts'}">FPTS</th>
              <th v-if="leagueData.sport != 'NFL'" @click="() => scheduleSort = 'gr'" :class="{selectedSort: scheduleSort == 'gr'}">GR</th>
              <th @click="() => scheduleSort = 'proj'" :class="{selectedSort: scheduleSort == 'proj'}">PROJ</th>
              <th @click="() => scheduleSort = 'sAvg'" class="endSection" :class="{selectedSort: scheduleSort == 'sAvg'}">sAVG</th>
              <th v-for="n in 7" :key="n" @click="() => scheduleSort = 'day' + n"
                :class="{selectedSort: scheduleSort == 'day' + n}"
              >
                <div v-if="leagueData.roster.days != null">{{ [leagueData.roster.days[n-1], 'YYYY-MM-DD'] | moment("ddd") }}</div>
              </th>
            </template>

            <!-- Stats View Headers -->
            <template v-else>
              <template v-for="(category, index) in getScoringStats(toDrop)">
                <th v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
                  :class="{endSection: index1 == category.stats.length && index != getScoringStats(toDrop).length,
                           selectedSort: statsSort == stat.path}"
                  @click="() => statsSort = stat.path"
                >
                  {{ stat.label }}
                </th>
              </template>
            </template>
          </tr>

          <!-- Players -->
          <PlayerSwapRow v-for="(player, index) in sortedPlayerList" :key="index" @selectPlayer="selectToAdd"
            :player="player" :leagueData="leagueData" :statusMap="statusMap"
            :selectedDataField="selectedDataField"
            :selectedWeek="selectedWeek" :toDrop="toDrop"
          />
        </table>

        <!-- Loading Player List -->
        <div v-if="!playerList"><ObLoading /></div>
      </div>

      <!-- Footer -->
      <div style="padding: 10px 15px; display: flex; justify-content: space-between; align-items: center; background: var(--obcolor-background-4);">
        <div style="font-size: 14px;">
          <div style="font-weight: bold;">Moves Remaining: {{ leagueData.movesRem }}/{{ leagueData.movesMax }}</div>
          <div v-if="!countsTowardsMoves">
            This player is inactive and will not count towards your weekly move limit.
          </div>
        </div>

        <div style="display: flex; flex-direction: column;">
          <div v-if="swapError != null" class="swapErrorTooltip" v-tooltip.top.notrigger="{ content: swapError, class: 'ObTooltip ob-tooltip-fadeout', visible: true, }"></div>
          <div style="display: flex; align-items: center;">
            <button class="ob-btn-grey" style="margin-right: 10px;" @click="cancelSwap()">Cancel</button>

            <button v-if="processing == true" disabled class="ob-btn-grey">
              <i class="fad fa-spinner-third fa-spin"></i> Processing...
            </button>

            <!-- Missing Player - Should not happen -->
            <div v-else-if="toDrop == null" style="color: red;">Invalid Player to Drop</div>

            <!-- Not eligible to swap -->
            <div v-else-if="!isSwappable" style="text-align: center; margin-left: 10px;">
              <div style="font-size: 13px; color: var(--obcolor-font-secondary);">No available games</div>
              <div style="font-size: 14px;">Player cannot be dropped</div>
            </div>

            <!-- Waiver Claim -->
            <button v-else-if="isWaiverClaim && toAdd != null" class="ob-btn" @click="submitWaiverClaim()">Submit Waiver Claim</button>
            <button v-else-if="isWaiverClaim && toAdd == null" disabled class="ob-btn-grey">Submit Waiver Claim</button>

            <!-- Swap -->
            <button v-else-if="toAdd != null" class="ob-btn" @click="swapPlayers()">Swap</button>
            <button v-else disabled class="ob-btn-grey">Swap</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <ConfirmationModal v-if="toAdd != null && toDrop != null" :title="'Swapping Players'"
      :text="'Are you sure that you want to swap ' + toDrop.player.name.longName() + ' with ' + toAdd.name.longName() + '?'"
      :eventBusName="'OPEN_SWAP_CONFIRMATION_MODAL'" @confirm="confirmSwapPlayers()"
    />
  </div>
</template>

<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import EventBus from '@/event-bus';
import ObPlayersApi from '@/api/ObPlayersApi';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObWaiversApi from '@/api/ObWaiversApi';
import ObLoading from '@/components/ObLoading';
import PlayerSwapRow from './PlayerSwapRow';

export default {
  components: {
    ConfirmationModal,
    ObLoading,
    PlayerSwapRow,
  },

  props: {
    toDrop: Object,
    leagueData: Object,
    rosterPossibleGR: Object,
  },

  data() {
    return {
      selectedDataField: 'schedule',
      scheduleSort: 'proj',
      statsSort: null,
      scoringStats: null,
      playerList: null,
      statusMap: null,
      selectedWeek: 0,
      searchPlayer: '',

      toDropPlayer: null,
      toAdd: null,

      swapError: null,
      swapErrorTimeout: null,
      processing: false,

      isWaiverClaim: false,
      isSwappable: true,
    };
  },

  computed: {
    filteredPlayerList() {
      if (this.playerList == null) {
        return [];
      }

      const pList = this.playerList.slice();

      return pList.filter((player) => {
        if (this.searchPlayer !== '' && this.searchPlayer != null) {
          const pname = player.name.toLowerCase();
          const nameFilter = this.searchPlayer.toLowerCase();
          const nameFilterList = nameFilter.split(' ');

          for (const nameFilter of nameFilterList) {
            if (pname.indexOf(nameFilter) != 0 && pname.indexOf(', ' + nameFilter) == -1) {
              return false;
            }
          }
        }
        return true;
      });
    },

    scheduleSortedList() {
      if (this.selectedDataField != 'schedule') {
        return [];
      }
      const filteredList = this.filteredPlayerList.slice();
      return filteredList.sort((p1, p2) => {
        // Sort by select day (fpts or projection)
        const comparedScheduleDate = this.compareScheduleDate(p1, p2);
        if (comparedScheduleDate !== null) {
          return comparedScheduleDate;
        }

        // Sort by weekly stats
        const comparedWeeklyStats = this.compareWeeklyStats(p1, p2);
        if (comparedWeeklyStats !== null) {
          return comparedWeeklyStats;
        }

        // Default sort by proj
        if (p1.wPROJ[this.selectedWeek] > p2.wPROJ[this.selectedWeek]) {
          return -1;
        }
        if (p1.wPROJ[this.selectedWeek] < p2.wPROJ[this.selectedWeek]) {
          return 1;
        }
        return 0;
      });
    },

    statSortedList() {
      if (this.selectedDataField == 'schedule') {
        return [];
      }
      const filteredList = this.filteredPlayerList.slice();
      return filteredList.sort((p1, p2) => {
        // Sort By Season Stat
        const p1Stat = this.getStat(p1, this.statsSort);
        const p2Stat = this.getStat(p2, this.statsSort);

        if (p1Stat != null && p2Stat == null) {
          return -1;
        } else if (p1Stat == null && p2Stat != null) {
          return 1;
        } else if (p1Stat == null && p2Stat == null) {
          return 0;
        }

        if (this.statsSort == 'skater.TOI') {
          return this.compareTOI(p1Stat, p2Stat);
        }

        if (Number(p1Stat) > Number(p2Stat)) {
          return -1;
        } else if (Number(p1Stat) < Number(p2Stat)) {
          return 1;
        }
        return 0;
      });
    },

    sortedPlayerList() {
      if (this.playerList == null) {
        return [];
      }

      if (this.selectedDataField == 'schedule') {
        return this.scheduleSortedList.slice(0, 100);
      }

      return this.statSortedList.slice(0, 100);
    },

    countsTowardsMoves() {
      if (this.toDrop) {
        switch (this.toDrop.player.status) {
          case 'INJ':
          case 'SUSP':
          case 'OUT':
          case 'DNP':
          case 'MIN':
            return false;
        }
      }
      return true;
    },

  },

  watch: {

    swapError(newVal) {
      const self = this;
      if (newVal != null) {
        clearTimeout(this.swapErrorTimeout);
        this.swapErrorTimeout = setTimeout(function() {
          self.resetSwapError();
        }, 3000);
      }
    },

  },

  created() {
    // Set default selected week to the week of this matchup
    const matchId = this.$route.params.matchId;
    if (matchId && matchId.indexOf('w') == 0 && matchId.indexOf('m') != -1) {
      const week = matchId.substring(1, matchId.indexOf('m'));
      this.selectedWeek = parseInt(week) - 1;
    }

    this.loadPlayers();
    this.loadStatuses();

    // Determine if it should be a player swap or a waiver claim
    // If no available games to be played for this position, it is a waiver claim
    // If there are games, its a swap, unless the user has no moves left
    const avaGamesForPos = this.rosterPossibleGR[this.toDrop.draftPos];
    const hasWaivers = this.leagueData.matchup.hasWaivers;
    let isWaiver = false;

    if (avaGamesForPos === 0) {
      isWaiver = true;
    } else if (this.leagueData.movesRem === 0 && this.countsTowardsMoves) {
      // Has ava games, but has no moves, and the player to drop is not a free move (inactive)
      isWaiver = true;
    }

    // If its a waiver claim but this is the last week (no waivers) the player cannot be swapped
    this.isWaiverClaim = isWaiver;
    this.isSwappable = !(isWaiver && !hasWaivers);

    if (this.getScoringStats(this.toDrop) && this.getScoringStats(this.toDrop)[0] &&
      this.getScoringStats(this.toDrop)[0].stats && this.getScoringStats(this.toDrop)[0].stats[0].label != null
    ) {
      this.statsSort = this.getScoringStats(this.toDrop)[0].stats[0].path;
    }
  },

  methods: {

    cancelSwap() {
      EventBus.$emit('CANCEL_SWAPPING_TEAMS');
    },

    selectToAdd(player) {
      this.toAdd = player;
    },

    swapPlayers() {
      EventBus.$emit('OPEN_SWAP_CONFIRMATION_MODAL');
    },

    submitWaiverClaim() {
      if (this.toAdd == null || this.toDrop == null) {
        return;
      }
      this.processing = true;
      ObWaiversApi.submitClaim(this.leagueData.id, this.leagueData.matchup.week, this.toDrop.id, this.toAdd.id)
          .then((data) => {
          // Inform the user it was succesful, clear the "to add" player
          // Stay on this page in case the user wants to submit multiple waiver claims for this player
            this.processing = false;
            this.toAdd = null;
            this.swapError = 'Success';
          })
          .catch((error) =>{
            this.processing = false;
            this.swapError = error.response.data;
          });
    },

    confirmSwapPlayers() {
      if (this.toAdd == null || this.toDrop == null) {
        return;
      }
      this.processing = true;
      ObLeagueApi.dropAndAddPlayer(this.leagueData.id, this.toDrop.draftPos, this.toDrop.id, this.toAdd.id)
          .then((data) => {
          // Go back to matchup page
            EventBus.$emit('RELOAD_MATCHUP_PAGE');
          })
          .catch((error) =>{
            this.processing = false;
            this.swapError = 'Failed to add player';
          });
    },

    increaseWeek() {
      if (this.selectedWeek < this.leagueData.numWeeks - 1) {
        this.selectedWeek++;
      }
    },

    decreaseWeek() {
      if (this.selectedWeek > 0) {
        this.selectedWeek--;
      }
    },

    resetSwapError() {
      this.swapError = null;
    },

    loadPlayers() {
      const self = this;
      const id = this.leagueData.id;
      ObPlayersApi.getPlayerSwapList2(id)
          .then((data) => {
            const takenPlayers = data.taken;
            this.playerList = data.players.filter((player) => {
              if (player.id === self.toDrop.id) {
                self.toDropPlayer = player;
                return false;
              }
              if (takenPlayers[player.id] != null) {
                return false;
              }
              return player.draftPos == self.toDrop.draftPos;
            });
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    loadStatuses() {
      ObPlayersApi.getGlobalStatusMap(this.leagueData.sport)
          .then((data) => {
            this.statusMap = data;
          })
          .catch((_error) => {
            // Do Nothing
          });
    },

    getScoringStats(player) {
      if (!player || !player.player || !player.player.position) {
        return {};
      }
      return this.$ScoringStats.getSeasonStats(this.leagueData.sport, player.player.position);
    },

    getStat(player, path) {
      if (!player || !player.statistics || !path) {
        return null;
      }
      const statistics = player.statistics;
      const playerRole = path.substr(0, path.indexOf('.'));
      const statType = path.substr(path.indexOf('.') + 1);
      if (!statistics[playerRole]) {
        return null;
      }
      const stat = statistics[playerRole][statType];
      if (stat === null || stat === undefined) {
        return null;
      }
      return stat;
    },

    compareGameVal(p1Entry, p2Entry, val) {
      if (p1Entry[val] && !p2Entry[val]) {
        return p1Entry[val] > 0 ? -1 : 1;
      }
      if (!p1Entry[val] && p2Entry[val]) {
        return p2Entry[val] > 0 ? 1 : -1;
      }
      if (!p1Entry[val] && !p2Entry[val]) {
        return null;
      }

      return p2Entry[val] - p1Entry[val];
    },

    compareScheduleDate(p1, p2) {
      if (/day/.test(this.scheduleSort)) {
        const dayNum = Number(this.scheduleSort.charAt(this.scheduleSort.length - 1));
        const p1Entry = p1.gamesByWeek[this.selectedWeek][dayNum - 1];
        const p2Entry = p2.gamesByWeek[this.selectedWeek][dayNum - 1];
        const hasP1Entry = p1Entry && p1Entry.opp;
        const hasP2Entry = p2Entry && p2Entry.opp;

        if (hasP1Entry && !hasP2Entry) {
          return -1;
        }
        if (!hasP1Entry && hasP2Entry) {
          return 1;
        }

        if (this.compareGameVal(p1Entry, p2Entry, 'fpts')) {
          return this.compareGameVal(p1Entry, p2Entry, 'fpts');
        }

        if (this.compareGameVal(p1Entry, p2Entry, 'proj')) {
          return this.compareGameVal(p1Entry, p2Entry, 'proj');
        }

        return null;
      }

      return null;
    },

    compareWeeklyStats(p1, p2) {
      const scheduleMap = {
        pg: 'wGP',
        fpts: 'wFPTS',
        gr: 'wGR',
        proj: 'wPROJ',
        sAvg: 'sAvg',
      };

      const pStat = scheduleMap[this.scheduleSort];
      if (this.scheduleSort == 'sAvg') {
        if (p1.sAvg > p2.sAvg) {
          return -1;
        }
        if (p1.sAvg < p2.sAvg) {
          return 1;
        }
        return null;
      }
      if (this.scheduleSort != 'sAvg' && pStat) {
        if (p1[pStat][this.selectedWeek] > p2[pStat][this.selectedWeek]) {
          return -1;
        }
        if (p2[pStat][this.selectedWeek] > p1[pStat][this.selectedWeek]) {
          return 1;
        }
      }
      return null;
    },

    compareTOI(p1Stat, p2Stat) {
      const regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
      if (parseInt(p2Stat.replace(regExp, '$1$2$3')) > parseInt(p1Stat.replace(regExp, '$1$2$3'))) {
        return 1;
      } else if (parseInt(p2Stat.replace(regExp, '$1$2$3')) < parseInt(p1Stat.replace(regExp, '$1$2$3'))) {
        return -1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .ob-box {
    padding: 0;
    overflow: auto;
  }

  th, td {
    padding: 10px 15px;
    box-sizing: border-box;
  }

  tr th:not(:first-child) {
    text-align: center;
    width: 60px;
  }

  td:not(:first-child) {
    text-align: center;
    width: 60px;
  }

  th {
    color: var(--obcolor-font-secondary);
    background: var(--obcolor-navi-bar);
  }

  tr:not(:first-child) th:not(.unselectable) {
    cursor: pointer;
  }

  tr:not(:first-child) th:not(.unselectable):hover {
    background: var(--obcolor-background-2);
  }

  .playerListTable tr:not(.headerRow) {
    cursor: pointer;
  }
  .playerListTable tr:hover td {
    background: var(--obcolor-background-2) !important;
  }

  .categoryHeaderRow th {
    height: 23px;
    top: 0;
  }

  .columnHeaderRow th {
    height: 46px;
    top: 23px;
  }

  .playerListTable tr:nth-child(even) td {
    background: var(--obcolor-background-5);
  }

  .playerListTable tr:nth-child(odd) td {
    background: var(--obcolor-background-6);
  }

  .playerNameCol {
    display: flex;
    align-items: center;
  }

  .playerNameCol .fa {
    font-size: 18px;
    margin-right: 10px;
    &.fa-minus {
      color: var(--obcolor-red);
    }
    &.fa-plus {
      color: var(--obcolor-green);
    }
  }
  .playerNameCol .playerStatus {
    padding-right: 5px;
  }
  .playerNameCol .playerName {
    font-size: 14px;
  }

  .playerNameCol > div {
    justify-content: flex-start !important;
  }

  .playerInfo {
    font-size: 12px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
    span {
      margin-right: 5px;
    }
  }

  .endSection {
    border-right: 4px solid var(--obcolor-background-3);
  }

  .searchInput {
    border-radius: 50px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    background: var(--obcolor-input-box);
    color: var(--obcolor-font-secondary);

    i {
      margin-right: 5px;
    }

    input {
      background: none;
      padding: 0;
    }
  }

  .toggleSwitch {
    display: inline-block;
    color: var(--obcolor-font-secondary);
    background: var(--obcolor-btn-unselected);
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: var(--obcolor-hover-selection-light);
    }

    &:active {
      background: var(--obcolor-hover-selection-dark);
    }
  }

  .toggleSwitch.selected {
    color:white;
    background: var(--obcolor-btn-selected);

    &:hover {
      background: var(--obcolor-accent-light-hl);
    }

    &:active {
      background: var(--obcolor-accent-light);
    }
  }

  .toggleSwitch:first-child {
    border-radius: 5px 0 0 5px;
  }
  .toggleSwitch:last-child {
    border-radius: 0 5px 5px 0;
  }

  .swapErrorTooltip {
    width: 65px;
    margin-top: 20px;
    margin-bottom: -20px;
    align-self: flex-end;
  }

  .weekBtn {
    padding: 2px 5px;
    width: 10px;
    cursor: pointer;
    color: var(--obcolor-font-primary);
    i {
      margin-top: 4px;
    }
  }
  .weekBtn:hover {
    color: var(--obcolor-accent);
  }
</style>