import Api from '@/api/ObBaseApi';

export default {

  getUserLimits() {
    return Api()
        .get('/fsp/userLimit/allLimits')
        .then((response) => {
          return response.data;
        });
  },

  getDepositLimits() {
    return Api()
        .get('/fsp/userLimit/getDepositAlertAndLimit')
        .then((response) => {
          return response.data;
        });
  },

  // getContestLimits() {
  //   return Api()
  //   .get("/fsp/userLimit/getContestAlertAndLimit")
  //   .then(response => {
  //     return response.data;
  //   });
  // },

  updateUserDeposit(params) {
    return Api()
        .post('/fsp/userLimit/updateUserDeposit', params)
        .then((response) => {
          return response.data;
        });
  },

  updateEntryFeeLimit(params) {
    return Api()
        .post('/fsp/userLimit/updateEntryFeeLimit', params)
        .then((response) => {
          return response.data;
        });
  },

  updateContestEntry(params) {
    return Api()
        .post('/fsp/userLimit/updateContestEntry', params)
        .then((response) => {
          return response.data;
        });
  },

  setLockout(numDays) {
    const params = {
      lockoutDays: numDays,
    };
    return Api()
        .post('/fsp/account/setLockout', params)
        .then((response) => {
          return response.data;
        });
  },

};