<template>
  <div :class="`container ${getStatusClass()}`" :style="`--percent: ${barWidth}; --result-offset: ${resultOffset}`">
    <div class="progressBar">
      <div v-if="this.pick.game.state !== 'UPCOMING'" class="result" ref="result">{{ pick.result }}</div>
      <div class="bar" ref="bar">
        <div class="goalLine"></div>
        <div :class="`fill ${getStatusClass()}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pick: {type: Object, default: null},
    entryId: {type: Number, default: null},
  },

  data() {
    return {
      resultOffset: 0,
    };
  },

  mounted() {
    this.updateOffset();
  },

  watch: {
    barWidth() {
      this.updateOffset();
    },
  },

  computed: {
    barWidth() {
      if (this.pick.game.state == 'UPCOMING') {
        return 0;
      }
      const TARGET_PERCENT = 0.75;
      const maxBarLength = this.pick.balancedLine / TARGET_PERCENT;
      const progressionRatio = this.pick.result / maxBarLength * 100;
      return Math.max(Math.min(progressionRatio, 100), 3);
    },
  },

  methods: {
    getStatusClass() {
      switch (this.pick.status) {
        case 'CORRECT':
          return 'win';
        case 'INCORRECT':
          return 'loss';
        case 'PENDING':
          return 'progress';
      }
    },

    updateOffset() {
      if (this.$refs.result === undefined || this.$refs.bar === undefined) {
        return 0;
      }
      const resultRect = this.$refs.result.getBoundingClientRect();
      const barRect = this.$refs.bar.getBoundingClientRect();
      const newOffset = Math.max(resultRect.right - barRect.right, 0);
      if (newOffset !== this.resultOffset) {
        this.resultOffset = newOffset;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;

  --bar-height: 8px;
  --goal-line-height: 12px;
  --gap: 2px;
}
.win {
  --fill-text-color: var(--obcolor-green);
  --fill-color: var(--obcolor-green);
}
.loss{
  --fill-text-color: var(--obcolor-red);
  --fill-color: var(--obcolor-red);
}
.progress{
  --fill-text-color: var(--obcolor-font-secondary);
  --fill-color: var(--obcolor-game-possibility);
}

.progressBar {
  position: relative;
  width: 100%;
  font-size: 12px;
}

.result {
  position: absolute;
  left: calc(var(--percent) * 1% - var(--result-offset) * 1px);
  transform: translateX(-50%);
  height: calc(1em + var(--gap));
  color: var(--fill-text-color);
  font-weight: 700;

  transition: left 0.5s;

  &::before {
    position: absolute;
    display: block;
    content: " ";
    border-left: solid 1px var(--fill-color);
    height: var(--goal-line-height);
    left: calc(50% + var(--result-offset) * 1px);
    top: 100%;
    transform: translateX(-50%);
  }
}

.bar {
  background-color: var(--obcolor-background-2);
  height: var(--bar-height);
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 9999px;
  margin-top: calc(1em + var(--gap) + (var(--goal-line-height) - var(--bar-height)));
}

.fill {
  height: 100%;
  width: calc(var(--percent) * 1%);
  z-index: 1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: var(--fill-color);

  transition: width 0.5s;
}

.goalLine {
  background-color: var(--obcolor-font-secondary);
  height: var(--goal-line-height);
  width: 1px;
  position: absolute;
  left: 75%;
}

</style>