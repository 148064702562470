<template>
  <div class="teamContainer">
    <img class="teamImgAway" :src="getTeamLogo()" v-if="!isHome"
      alt="Team Logo"
    >
    <div class="timeouts" v-if="isHome && sport !== 'MLB'">
      <div class="timeout usedTimeout" v-for="index in usedTimeouts" :key="'used_timeouts_' + index">
      </div>
      <div class="timeout unusedTimeout" v-for="index in unusedTimeouts" :key="'unused_timeouts_' + index">
      </div>
    </div>
    <div class="teamNameContainer">
      <div class="teamName">
        <div v-if="isHome && homeTeamPowerplay" class="powerplay" style="margin-right: 7px;">PP</div>
        {{ getTeamName() }}
        <div v-if="!isHome && awayTeamPowerplay" class="powerplay" style="margin-left: 7px;">PP</div>
      </div>
      <div class="teamStats" v-if="stats">
        ({{ getLeagueH2h() }})
      </div>
    </div>
    <div class="timeouts" v-if="!isHome && sport !== 'MLB'">
      <div class="timeout usedTimeout" v-for="index in usedTimeouts" :key="'used_timeouts_' + index">
      </div>
      <div class="timeout unusedTimeout" v-for="index in unusedTimeouts" :key="'unused_timeouts_' + index">
      </div>
    </div>
    <img class="teamImgHome" :src="getTeamLogo()" v-if="isHome"
      alt="Team Logo"
    >
  </div>
</template>

<script>
export default {
  props: {
    gameSummary: Object,
    isHome: Boolean,
    sport: String,
    gameData: Object,
  },

  computed: {
    stats() {
      if (!this.getMatchupTeam() || !this.getMatchupTeam().stats) {
        return null;
      }
      return this.getMatchupTeam().stats;
    },
    timeouts() {
      return this.isHome ? this.gameData?.matchup?.liveStats?.hometeam?.Timeouts : this.gameData?.matchup?.liveStats?.awayteam?.Timeouts;
    },
    usedTimeouts() {
      return this.timeouts ? (3 - parseInt(this.timeouts)) : 0;
    },
    unusedTimeouts() {
      return this.timeouts ? parseInt(this.timeouts) : 0;
    },
    awayTeamPowerplay() {
      return this.gameSummary.liveStats?.powerplay === 'away';
    },
    homeTeamPowerplay() {
      return this.gameSummary.liveStats?.powerplay === 'home';
    },
  },

  methods: {
    getMatchupTeam() {
      let indexName = 'visitor_team';
      if (this.isHome) {
        indexName = 'home_team';
      }
      // Todays Games / Live Data
      if (this.gameSummary && this.gameSummary[indexName]) {
        return this.gameSummary[indexName];
      }

      // GameSummary might not be available, use matchup data instead
      if (this.gameData && this.gameData.matchup && this.gameData.matchup[indexName]) {
        return this.gameData.matchup[indexName];
      }
      return null;
    },

    // Concats the team's city and name
    getTeamName() {
      const team = this.getMatchupTeam();

      if (!team || !team.team_name || !team.team_city) {
        return null;
      }

      const teamName = team.team_city + ' ' + team.team_name;

      return teamName;
    },

    getTeamLogo() {
      const team = this.getMatchupTeam();

      if (!team || !team.team_alias) {
        return null;
      }

      const teamName = team.team_alias.toLowerCase();

      return require('@/assets/logos/hr_'+ this.sport.toLowerCase() +'_'+ teamName +'.png');
    },

    // Shows win-loss unless NHL, in which case it's win-loss-OTL
    getLeagueH2h() {
      if (this.sport == 'NHL') {
        return this.stats.W + '-' + this.stats.L + '-' + this.stats.OTL;
      }

      return this.stats.league_W + '-' + this.stats.league_L;
    },
  },
};
</script>

<style lang="scss" scoped>

  .teamContainer {
    display: flex;
    align-items: center;
    width: 300px;
    box-sizing: border-box;

    padding: 10px;
    position: relative;

    padding-bottom: 5px;
    border-bottom: 3px solid var(--obcolor-background-5);
  }

  .teamContainer:last-child {
    justify-content: flex-end;

    .teamNameContainer {
      margin-right: 50px;
      margin-left: 0;
    }

    .teamName {
      justify-content: flex-end;
      text-align: right;
    }

    .teamStats {
      justify-content: flex-end;
      margin-right: -5px;
      margin-left: 0;
    }
  }
  .teamImgAway {
    height: 60px;
    position: absolute;
    opacity: 0.8;
    left: -15px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  }
  .teamImgHome {
    height: 60px;
    position: absolute;
    opacity: 0.8;
    right: -15px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  }

  .teamNameContainer {
    margin: 0 10px;
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 50px;
  }

  .teamName {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .teamStats {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    margin-bottom: 8px;
    color: var(--obcolor-font-secondary);
    min-width: 50px;
  }

  .timeouts {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .timeout {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
  }

  .usedTimeout {
    background-color: var(--obcolor-text-secondary);
  }

  .unusedTimeout {
    background-color: var(--obcolor-ob-blue);
  }

  .powerplay {
    background-color: var(--obcolor-red);
    border-radius: 4px;
    padding: 3px;
    font-weight: 700;
    font-size: 12px;
  }
</style>