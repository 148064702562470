<template>
  <tr>
    <td class="logoCol"><UserLogo :id="userId" :sport="league.sport" :size="35" /></td>
    <td class="scoreCol">
      <div class="matchHeaderScore">{{ totalUntilDay(selectedDay) }}</div>
    </td>
    <template v-if="rosterScoringStats.length == 1">
      <td v-for="stat in rosterScoringStats[0].stats" :key="stat.path" style="min-width: 30px;">
        <template v-if="$moment().diff($moment(roster.days[selectedDay]), 'days') >= 0">
          <span>{{ (showFpts ? getFpts(stat) : getStat(stat)) || 0 }}</span>
          <span v-if="!showFpts && stat.denom">/{{ totals[stat.denom] }}</span>
        </template>
        <template v-else>-</template>
      </td>
    </template>
    <template v-else>
      <template v-for="(category, index) in rosterScoringStats">
        <td v-for="(stat, index1) in category.stats" :key="index + '_' + index1"
          :class="{endSection: index1 == category.stats.length - 1 && index != rosterScoringStats.length - 1}"
        >
          <template v-if="$moment().diff($moment(roster.days[selectedDay]), 'days') >= 0">
            <span>{{ (showFpts ? getFpts(stat) : getStat(stat)) || 0 }}</span>
            <span v-if="!showFpts && stat.denom">/{{ totals[stat.denom] }}</span>
          </template>
          <template v-else>-</template>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import Decimal from 'decimal.js';
import UserLogo from '@/components/UserLogo.vue';

export default {
  props: {
    league: Object,
    roster: Object,
    fpts: Object,
    totals: Object,
    selectedDay: Number,
    selectedFilterIndex: Number,
    showFpts: Boolean,
    userId: String,
  },

  components: {
    UserLogo,
  },

  computed: {
    rosterFilters() {
      return this.$ScoringStats.getMatchupFilters(this.league.sport);
    },

    rosterScoringStats() {
      if (this.rosterFilters) {
        return this.rosterFilters[this.selectedFilterIndex].categories;
      }
      return [];
    },
  },

  methods: {
    getStat(stat) {
      let totalStatVal = new Decimal(0);
      if (stat.sum) {
        const sumValue = this.totals[stat.sum] || 0;
        totalStatVal = totalStatVal.plus(new Decimal(sumValue));
      }
      const statValue = this.totals[stat.path] || 0;
      totalStatVal = totalStatVal.plus(new Decimal(statValue));
      return Number(totalStatVal);
    },

    getFpts(stat) {
      let totalStatVal = new Decimal(0);
      if (stat.sum) {
        const sumValue = this.fpts[stat.sum] || 0;
        totalStatVal = totalStatVal.plus(new Decimal(sumValue));
      }
      const statValue = this.fpts[stat.path] || 0;
      totalStatVal = totalStatVal.plus(new Decimal(statValue));
      return Number(totalStatVal);
    },

    totalUntilDay(day) {
      const totalPoints = this.getTotalPoints(true, day);
      return totalPoints;
    },

    getTotalPoints(getSum = false, day = this.selectedDay) {
      const gameLimit = this.league.gameLimits;

      // Create list of valid games (Played, not deactivated, not dropped)
      // Order by time, then projected points
      // {id:PLAYERID_GAMEID, ts: timestamp, pos: F, proj: 12.40 }
      const validGames = {};

      for (const pIndex in this.roster.players) {
        const player = this.roster.players[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
      }

      for (const pIndex in this.roster.dropped) {
        const player = this.roster.dropped[pIndex];
        // Setup validGames array
        this.addPlayerValidGames(player, validGames, getSum, day);
      }

      // Sort and Limit validGames
      for (const pos in validGames) {
        let gamesByPos = validGames[pos];
        const limit = gameLimit[pos];

        gamesByPos.sort(function(a, b) {
          if (a.ts > b.ts) {
            return 1;
          } else if (b.ts > a.ts) {
            return -1;
          }
          // Secondary sort by proj (highest takes proirity)
          if (a.proj > b.proj) {
            return -1;
          } else if (b.proj > a.proj) {
            return 1;
          }
          return 0;
        });

        gamesByPos = gamesByPos.slice(0, limit);
        validGames[pos] = gamesByPos;
      }

      // Calc total points
      let totalPoints = new Decimal(0);
      for (const pos in validGames) {
        const gamesByPos = validGames[pos];
        for (const games of gamesByPos) {
          totalPoints = totalPoints.plus(new Decimal(games.fpts));
        }
      }

      return totalPoints;
    },

    addPlayerValidGames(player, validGames, getSum = false, day = this.selectedDay) {
      const games = player.fspGames;

      if (day != null && getSum === false) {
        if (games[day - 1] != null) {
          const gJSON = games[day - 1];
          this.addGameIfValid(gJSON, validGames, player);
        }
        if (games[(day - 1) + 'DH'] != null) {
          const gJSON = games[(day - 1) + 'DH'];
          this.addGameIfValid(gJSON, validGames, player);
        }
      } else {
        for (const gameIndex in games) {
          if (day == null || gameIndex.charAt(0) < day) {
            const gJSON = games[gameIndex];
            this.addGameIfValid(gJSON, validGames, player);
          }
        }
      }
    },

    addGameIfValid(gJSON, validGames, player) {
      const pos = player.draftPos;
      if (gJSON.inRange &&
          gJSON.played &&
          !this.gameDeactivated(player, gJSON)) {
        const entry = {};
        entry.id = player.id + '_' + gJSON.id;
        entry.ts = parseInt(gJSON.timestamp);
        if (gJSON.ProjPoints) {
          entry.proj = gJSON.ProjPoints.total;
        }
        if (gJSON.ObPoints) {
          entry.fpts = gJSON.ObPoints.total;
        }

        entry.ObPoints = gJSON.ObPoints;
        entry.stats = gJSON.stats;

        if (validGames[pos] == null) {
          validGames[pos] = [];
        }
        validGames[pos].push(entry);
      }
    },

    gameDeactivated(player, game) {
      return player.deactGames[game.id] != null;
    },
  },
};
</script>

<style lang="scss" scoped>
.logoCol {
  max-width: 30px; width: 30px;
  box-sizing: border-box;
}

.scoreCol {
  text-align: left;
  font-size: 14px;
  max-width: 40px;
  width: 40px;
  box-sizing: border-box;
}

.matchHeaderScore {
  background: var(--obcolor-background-2);
  padding: 0 10px;
  border-radius: 6px;
  font-weight: bold;
  margin-right: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
}

.endSection {
  border-right: 1px solid var(--obcolor-background-2);
}
</style>