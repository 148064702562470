<template>
  <div class="ob-page">
    <div class="wfsTitleContainer">
      <img style="margin-right: 10px;" :src="require('@/assets/howtoplay/wfsbadge.png')" alt="Weekly Fantasy Sports Badge">
      <div>What is Weekly Fantasy Sports in 3 Steps</div>
    </div>

    <div class="typeButtons">
      <button class="ob-btn typeBtn" @click="setCType('tournament')" :class="cType != 'tournament' ? 'ob-btn-grey' : ''">TOURNAMENT</button>
      <button class="ob-btn typeBtn" @click="setCType('h2h')" :class="cType != 'h2h' ? 'ob-btn-grey' : ''">HEAD-2-HEAD</button>
    </div>

    <HowToPlayH2H v-if="cType == 'h2h'" />
    <HowToPlayTournament v-else-if="cType == 'tournament'" />

    <!-- Draft your team -->
    <div class="ob-box h2pBoxContainerVertical">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">
          <img style="height: 25px; margin-right: 5px;" :src="require('@/assets/obicon_' + ((theme == 'dark') ? 'white' : 'black') + '.png')" alt="OwnersBox Icon">
          <div v-if="cType == 'h2h'">Draft your team today!</div>
          <div v-else>Build your lineup today!</div>
        </div>
        <div>
          Jump into your first contest and start competing against your opponents!
        </div>
      </div>
      <div class="h2pBox" style="background: var(--obcolor-background-4);">
        <router-link class="ob-btn btnLarge" :to="'/lobby'">Lobby</router-link>
      </div>
    </div>

    <!-- Good to know -->
    <div class="ob-box h2pBoxContainerVertical">
      <div class="h2pBox h2pExplanation">
        <div class="h2pTitle">
          <i class="fas fa-question-circle" style="font-size: 27px; margin-right: 10px;"></i>
          <div>Good to Know</div>
        </div>
        <div>
          Want more detailed information on OwnersBox features?
        </div>
      </div>
      <div class="h2pBox" style="background: var(--obcolor-background-4);">
        <div class="flickityContainer">
          <div class="nextPrevContainer" @click="flickityPrev('goodToKnow')">
            <i :class="{ disabledNextPrev: flickityScrollValues.goodToKnow.scrolledLeft }" class="fas fa-chevron-left flickityNextPrev"></i>
          </div>
          <flickity class="h2pFlickity" ref="goodToKnow" :options="flickityOptions">
            <template v-for="(info, index) in goodToKnow">
              <div v-if="info.link == null" class="infoContainer" :key="index">
                <div class="infoImg">
                  <img v-if="theme == 'light' ? info.lightImg : info.darkImg"
                    :src="theme == 'light' ? info.lightImg : info.darkImg" alt="OwnersBox Icon"
                  >
                </div>
                <div class="infoTitle" v-if="info.title">{{ info.title }}</div>
                <div class="infoText" v-if="info.title">{{ info.text }}</div>
              </div>
              <a v-else :href="info.link" target="_blank"
                class="infoContainer" :key="index"
              >
                <div class="infoImg">
                  <img v-if="theme == 'light' ? info.lightImg : info.darkImg"
                    :src="theme == 'light' ? info.lightImg : info.darkImg" alt="OwnersBox Icon"
                  >
                </div>
                <div class="infoTitle" v-if="info.title">{{ info.title }}</div>
                <div class="infoText" v-if="info.title">{{ info.text }}</div>
              </a>
            </template>
          </flickity>
          <div class="nextPrevContainer" @click="flickityNext('goodToKnow')">
            <i :class="{ disabledNextPrev: flickityScrollValues.goodToKnow.scrolledRight }" class="fas fa-chevron-right flickityNextPrev"></i>
          </div>
        </div>
        <a class="ob-btn btnLarge" href="https://support.ownersbox.com/hc/en-us" target="_blank">Knowledge Base</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Flickity from 'vue-flickity';
import HowToPlayH2H from '@/views/HowToPlay/HowToPlayH2H';
import HowToPlayTournament from '@/views/HowToPlay/HowToPlayTournament';

export default {
  metaInfo: {
    title: 'Weekly Fantasy Sports Explained | OwnersBox Fantasy Sports',
    meta: [
      {name: 'description', content: 'Learn how to play and win on OwnersBox Fantasy Sports.'},
      {name: 'og:description', content: 'Learn how to play and win on OwnersBox Fantasy Sports.'},
    ],
  },
  components: {
    Flickity,
    HowToPlayH2H,
    HowToPlayTournament,
  },

  computed: {
    ...mapState(['theme']),
  },

  mounted() {
    this.rerenderFlickity();
  },

  data() {
    return {
      cType: 'tournament',
      goodToKnow: [
        {
          darkImg: require('@/assets/howtoplay/icons/dark/referrals.png'),
          lightImg: require('@/assets/howtoplay/icons/dark/referrals.png'),
          title: 'Refer a Friend',
          text: 'It’s always more fun to play with friends! Refer your friends to earn OwnersBucks which convert to REAL MONEY as you play or let you enter contests!',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360046650491-How-do-I-send-a-Referral-',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/groups.png'),
          lightImg: require('@/assets/howtoplay/icons/dark/groups.png'),
          title: 'Create Groups',
          text: 'OwnersBox has a built-in social platform that users can use to group friends and track performances! Compete to win the ultimate bragging rights!',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360043922912-How-do-I-create-a-group-',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/trophy.png'),
          lightImg: require('@/assets/howtoplay/icons/light/trophy.png'),
          title: 'Contests',
          text: 'How long do contests last for? How are prizes distributed? Learn all you need to know about contests here.',
          link: 'https://support.ownersbox.com/hc/en-us/sections/360009140091-Contest',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/scoreboard.png'),
          lightImg: require('@/assets/howtoplay/icons/light/scoreboard.png'),
          title: 'Game Opportunities',
          text: 'Game Opportunities are what gets you your Fantasy Points. Learn how you can maximize them to your benefit.',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360043924012-What-are-game-opportunities-',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/goat.png'),
          lightImg: require('@/assets/howtoplay/icons/light/goat.png'),
          title: 'Ranking System',
          text: 'The ranking system gives you greater rewards and bragging rights! Find out the perks of ranking up!',
          link: 'https://support.ownersbox.com/hc/en-us/sections/360009027012-Rankings',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/swap.png'),
          lightImg: require('@/assets/howtoplay/icons/light/swap.png'),
          title: 'Swapping Players',
          text: 'Underperforming or injured players? You have two swaps a week to use. Click here to learn all about the Add/Drop process.',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360044378771-How-do-I-add-drop-swap-players-from-my-lineup-',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/timer.png'),
          lightImg: require('@/assets/howtoplay/icons/light/timer.png'),
          title: 'Waiver Wire',
          text: 'You get two free swaps a week, afterwards you have to submit a waiver claim. Learn all about the waiver wire system.',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360044666471-Is-there-a-waiver-wire-How-does-it-work-',
        },
        {
          darkImg: require('@/assets/icons/ownersbucks.png'),
          lightImg: require('@/assets/icons/ownersbucks.png'),
          title: 'OwnersBucks',
          text: 'Our virutally currency converts into REAL MONEY and lets you to try the game for free. Find out how!',
          link: 'https://support.ownersbox.com/hc/en-us/sections/360009139611-OwnersBucks',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/draftqueue.png'),
          lightImg: require('@/assets/howtoplay/icons/light/draftqueue.png'),
          title: 'Draft Queue',
          text: 'Keep track of players you want to draft with the draft queue. Learn how to use it!',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360044378431-How-do-I-setup-my-Draft-Queue-',
        },
        {
          darkImg: require('@/assets/howtoplay/icons/dark/playercard.png'),
          lightImg: require('@/assets/howtoplay/icons/light/playercard.png'),
          title: 'Player Card',
          text: 'Information is key to winning. Learn about the in-depth stats we provide to keep you up-to-date on players.',
          link: 'https://support.ownersbox.com/hc/en-us/articles/360044378491-How-do-I-see-the-player-card-',
        },
      ],

      flickityOptions: {
        pageDots: false,
        cellAlign: 'left',
        selectedAttraction: 0.02,
        friction: 0.3,
        contain: true,
        prevNextButtons: false,
        draggable: false,
      },

      flickityScrollValues: {
        goodToKnow: {
          scrolledLeft: true,
          scrolledRight: false,
        },
      },
    };
  },

  methods: {
    setCType(type) {
      this.cType = type;
    },
    rerenderFlickity() {
      this.$nextTick(() => {
        this.$refs.goodToKnow.rerender();
      });
    },

    flickityNext(id) {
      if (this.flickityScrollValues[id].scrolledRight) {
        return;
      }

      this.$refs[id].next();
      this.$nextTick(() => {
        this.setScrollVals(id);
      });
    },

    flickityPrev(id) {
      if (this.flickityScrollValues[id].scrolledLeft) {
        return;
      }

      this.$refs[id].previous();
      this.$nextTick(() => {
        this.setScrollVals(id);
      });
    },

    setScrollVals(id) {
      if (!this.$refs[id]) {
        return;
      }

      const flickityData = this.$refs[id].data('flickity');
      const index = flickityData.selectedIndex;
      this.flickityScrollValues[id].scrolledLeft = index == 0;
      // If the selected cell's position is close to the width of the container, we have scrolled all the way to the right
      this.flickityScrollValues[id].scrolledRight = (flickityData.cells[index].target - flickityData.slidesWidth) > 10;
    },
  },
};
</script>

<style lang="scss" scoped>

.wfsTitleContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 18px;
  text-align: center;
}

.typeButtons {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  white-space: nowrap;
}

.typeBtn {
  height: 50px;
  width: 40%;
  border-radius: 0;
}

// Styling for tournament and H2H components
::v-deep {
  .h2pBoxContainer {
    display: flex;
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
    width: 100%;
  }

  .h2pBoxContainerVertical {
    display: flex;
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
  }

  .h2pBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
  }

  .h2pExplanation {
    background: var(--obcolor-background-6);
    box-sizing: border-box;
  }

  .h2pContainer .h2pExplanation {
    max-width: 720px;
  }

  .h2pContainer .h2pExample {
    max-width: 720px;
  }

  .explanationImage {
    margin-top: 15px;
    display: flex;
    align-items:center;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }

  .h2pExample {
    background: var(--obcolor-background-4);
    height: 450px;
    img {
      position: absolute;
      transition-duration: 900ms;
    }
  }

  .h2pTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .infoButtonList {
    display: flex;
    margin-top: 15px;
  }

  .infoButtonContainer {
    position: relative;
  }

  .infoButtonContainer:not(:last-child) {
    margin-right: 15px;
  }

  .infoButtonContainer .ob-btn {
    min-width: 130px;
  }

  .infoHoverShow {
    display: none;
    position: absolute;
    overflow: hidden;
    padding: 0;
    width: 265px;
    margin-top: 5px;
    border: 3px solid var(--obcolor-background-1);
  }

  .infoHoverTitle {
    background: var(--obcolor-background-1);
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  .infoHoverText {
    background: var(--obcolor-background-4);
    padding: 8px;
    font-size: 14px;
  }

  .infoButtonContainer:hover .infoHoverShow {
    display: block;
    z-index: 1;
  }

  .btnLarge {
    height: 50px;
    width: 180px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content:  center;
  }

  @media only screen and (max-width: 1320px) {
    .wfsTitleContainer {
      flex-direction: column;
      font-size: 24px;
    }

    .typeButtons {
      justify-content: space-between;
      font-size: 14px;
    }

    .typeBtn {
      height: 50px;
      width: 48%;
    }

    .h2pBoxContainer, .h2pBoxContainerVertical {
      margin-top: 0;
      max-width: none;
      width: 100%;
    }

    .h2pBoxContainer .h2pExample {
      display: none;
    }

    .h2pExplanation {
      padding: 15px;
      width: 100%;
    }

    .infoButtonList {
      flex-wrap: wrap;
      justify-content: center;
    }

    .infoButtonContainer {
      &:not(:last-child) {
        margin-right: 5px;
        margin-bottom: 5px;
      }

      .ob-btn {
        width: 90px;
        font-size: 14px;
      }
    }

    .h2pExample {
      padding: 10px;
    }

    .nextPrevContainer {
      height: 150px;
    }

    .nextPrevContainer:first-child .flickityNextPrev {
      margin-right: 10px;
    }

    .nextPrevContainer:last-child .flickityNextPrev {
      margin-left: 10px;
    }

    .h2pFlickity {
      height: 150px;
    }

    .infoContainer {
      padding: 8px;
      height: 150px;
      width: 200px;
      margin: 0 10px;
      justify-content: center;
    }

    .infoImg {
      display: none;
    }
  }
}

.flickityContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.nextPrevContainer {
  height: 220px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--obcolor-font-secondary);
  }
}

.nextPrevContainer:first-child .flickityNextPrev {
  margin-right: 20px;
}

.nextPrevContainer:last-child .flickityNextPrev {
  margin-left: 20px;
}

.flickityNextPrev {
  font-size: 30px;
  cursor: pointer;

  &.disabledNextPrev {
    color: var(--obcolor-text-faded);
    cursor: not-allowed;
  }
}

.h2pFlickity {
  height: 225px;
  width: 100%;
  margin-bottom: 15px;
}

.infoContainer {
  background: var(--obcolor-background-2);
  padding: 15px;
  height: 225px;
  width: 300px;
  box-sizing: border-box;
  margin: 0 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.infoContainer:hover {
  background: var(--obcolor-background-1)
}

.infoImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.infoTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.infoText {
  font-size: 14px;
  text-align: center;
}
</style>