<template>
  <div style="min-height: 214px; padding: 10px; width: 100%;">
    <!-- Team Name -->
    <div style="padding: 0 10px; margin-bottom: 7px; margin-top: -2px; display: flex; align-items: center;">
      <div style="font-weight: bold; flex: 1;">{{ teamName }}</div>
      <div v-if="contest.format == 'WEEKLY'" class="ob-link calendarBtn" v-tooltip.bottom="{content: 'Calendar View', class:'ObTooltipHeat'}"
        @click="openCalendar()"
      >
        <i class="far fa-calendar-alt" style="margin-top: -1px;"></i>
      </div>
    </div>

    <!-- Salary Info -->
    <div style="display: flex; margin-left: 10px; margin-right: 10px; justify-content: space-between; font-size: 14px;">
      <div>
        <div class="salaryLabel">Committed Salary</div>
        <div class="salaryValue">{{ committedSalary }}</div>
        <div v-if="contest.format == 'WEEKLY'" style="font-size: 12px; margin-top: 7px;">
          <span>{{ pendingFees.formatMoney() }}</span>
          <span style="color: var(--obcolor-font-secondary); margin-left: 4px;">Swap Fee</span>
        </div>
      </div>
      <div>
        <div class="salaryLabel">Remaining Salary</div>
        <div :class="remainingSalary >= 0 ? 'moneyText' : 'redText'" class="salaryValue">{{ remainingSalary.formatMoney() }}</div>
        <div style="font-size: 12px; margin-top: 7px; display: flex; align-items: center;">
          <div :class="pendingCost >= 0 ? 'moneyText' : 'redText'">{{ formattedCost() }}</div>
          <Tooltip pos="bottom" textKey="SalaryCap_Editor_SalaryRemaining" style="margin-top: -2px;" />
        </div>
      </div>
      <div>
        <div class="salaryLabel">Projected</div>
        <div class="salaryValue">{{ projPoints }}</div>
        <div style="font-size: 12px; margin-top: 7px;">{{ formatChange(projChange) }}</div>
      </div>
    </div>

    <!-- Game limits by Position -->
    <div style="display: flex; justify-content: space-around; margin: 10px 4px;">
      <div v-for="(entry, index) in rosterLimits" :key="index"
        style="flex: 1; text-align: center; margin-left: 2px; margin-right: 2px; max-width: 115px; height: 64px;"
        :style="'border: 1px solid ' + getGameLimitColor(entry.short)"
      >
        <div :style="'background: ' + getGameLimitColor(entry.short)" style="padding: 2px; font-weight: bold; font-size: 14px;">
          {{ entry.short }}
        </div>
        <div style="padding: 4px;">
          <div style="margin-bottom: 3px; font-size: 16px;">
            <span :style="'color: ' + getGameLimitColor(entry.short, true)">{{ getProjGamesByPos(entry.short) }}</span> /
            <span>{{ entry.maxGames }}</span>
          </div>
          <div style="font-weight: normal; font-size: 12px;">
            {{ formatChange(grByPosition[entry.short]) }}
          </div>
        </div>
      </div>
    </div>

    <!-- Game warnings, Reset and Preview -->
    <div style="font-size: 12px; padding: 0 8px; display: flex; align-items: center; height: 35px;">
      <div style="flex: 1;">
        <div v-if="showUnderGames" style="flex: 1; color: red; margin-left: 3px; font-size: 14px;">
          <span style="white-space: nowrap;">Unused Game Opportunities</span>
          <Tooltip pos="bottom" textKey="SalaryCap_UnusedOpps" />
        </div>
        <div v-if="showBestBall" style="color: orange; margin-left: 3px; font-size: 14px; margin-top: 2px;">
          <span style="white-space: nowrap;">Best Ball Activated</span>
          <Tooltip pos="bottom" textKey="SalaryCap_BestBall" />
        </div>
      </div>
      <div @click="resetSwaps()" class="ob-btn-grey" style="margin-right: 5px; padding: 2px 6px; height: 25px;">
        <div style="font-size: 11px;">Reset</div>
        <div><i class="fas fa-repeat-alt"></i></div>
      </div>
      <div v-if="overSalaryCap" class="ob-btn-grey" style="min-width: 95px;"
        disabled
      >
        Over Salary Cap
      </div>
      <div v-else-if="missingPlayers" class="ob-btn-grey" style="min-width: 95px;"
        disabled
      >
        Preview
      </div>
      <div v-else @click="openPreview()" class="ob-btn"
        style="min-width: 95px;"
      >
        Preview
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip';
import EventBus from '@/event-bus';

export default {

  components: {
    Tooltip,
  },

  props: {
    contest: {type: Object, default: null},
    roster: {type: Object, default: null},
    rosterCalc: {type: Object, default: null},
    pendingDrops: {type: Object, default: null},
    pendingSwaps: {type: Array, default: null},
    picks: {type: Object, default: null},
    customNames: {type: Object, default: null},
    statusMap: {type: Object, default: null},
  },

  data() {
    return {
      pendingFees: 0,
      pendingCost: 0,
      grByPosition: {},
      projChange: 0,
      showBestBall: false,
      showUnderGames: false,
    };
  },

  computed: {
    isWeeklyContest() {
      return this.contest && this.contest.format === 'WEEKLY';
    },

    overSalaryCap() {
      return this.remainingSalary < 0;
    },

    missingPlayers() {
      if (this.pendingSwaps.length == 0) {
        return true;
      }
      for (const entry of this.pendingSwaps) {
        if (!entry.add) {
          return true;
        }
        if (!entry.drop) {
          return true;
        }
      }
      return false;
    },

    teamName() {
      if (!this.roster) {
        return '';
      }
      const cName = this.customNames[this.roster.teamId];
      if (cName && cName != '') {
        return cName;
      }
      return this.roster.team + ' (' + this.roster.teamNum + ')';
    },

    committedSalary() {
      let salary = this.rosterCalc.salaryCommitted + this.rosterCalc.salarySpent;
      salary += this.roster.swapFees;
      salary -= this.pendingCost;
      return salary.formatMoney();
    },

    remainingSalary() {
      let remSalary = this.rosterCalc.salaryRemaining;
      remSalary += this.pendingCost;
      return remSalary;
    },

    projPoints() {
      const proj = this.roster.proj + this.projChange;
      return proj;
    },

    rosterLimits() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

  },

  created() {
    this.calcPendingSwaps();
    // Event Bus is easier than a watcher in this case
    // The inner json (add/drop) gets modified which won't trigger an update
    EventBus.$on('LINEUP_EDITOR_CALCULATE_SALARIES', this.calcPendingSwaps);
  },

  destroyed() {
    EventBus.$off('LINEUP_EDITOR_CALCULATE_SALARIES', this.calcPendingSwaps);
  },

  methods: {
    calcPendingSwaps() {
      let swapFees = 0;
      let refunds = 0;
      let addedSalary = 0;
      const grByPosition = {};
      let projChange = 0;

      for (const entry of this.pendingSwaps) {
        let gamesRemaining = grByPosition[entry.pos];
        if (gamesRemaining == null) {
          gamesRemaining = 0;
        }

        let swapCost = 0;

        if (entry.drop) {
          const proj = this.getProjRemaining(entry.drop);
          const gr = this.getGamesRemaining(entry.drop);
          gamesRemaining -= gr;
          projChange -= proj;

          // Dropped players have fee and refund calculated on the server
          const refund = entry.drop.swapInfo.refund;
          refunds += refund;
          const swapFee = entry.drop.swapInfo.fee;
          swapFees += swapFee;

          entry.drop.calcSalary = refund;
          entry.drop.calcFee = swapFee;
          entry.drop.calcGR = gr;
          entry.drop.calcProj = proj;
          swapCost = swapCost - swapFee + refund;
        }

        if (entry.add) {
          const proj = this.getProjRemaining(entry.add);
          const gr = this.getGamesRemaining(entry.add);
          const salary = gr * entry.add.salaryPG;
          addedSalary += salary;
          gamesRemaining += gr;
          projChange += proj;

          entry.add.calcSalary = salary;
          entry.add.calcGR = gr;
          entry.add.calcProj = proj;
          swapCost -= salary;
        }

        entry.calcCost = swapCost;
        grByPosition[entry.pos] = gamesRemaining;
      }

      this.pendingFees = swapFees;
      this.pendingCost = refunds - addedSalary - swapFees;
      this.grByPosition = grByPosition;
      this.projChange = projChange;
      this.checkForGameWarnings();
    },

    checkForGameWarnings() {
      let showBB = false;
      let showUnder = false;
      for (const entry of this.rosterLimits) {
        const pg = this.getProjGamesByPos(entry.short);
        if (pg > entry.maxGames) {
          showBB = true;
        } else if (pg < entry.maxGames) {
          showUnder = true;
        }
      }
      this.showBestBall = showBB;
      this.showUnderGames = showUnder;
    },

    getProjRemaining(player) {
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && player.canSwap;
      const now = new Date().getTime();
      let proj = 0;
      for (const game of player.games) {
        proj += this.getGameEntryProjection(game, isValidDailySwap, now);
      }
      return proj;
    },

    getGamesRemaining(player) {
      // In Daily contests, games past the start time are still valid if its marked as delayed
      const isValidDailySwap = !this.isWeeklyContest && player.canSwap;
      const now = new Date().getTime();
      let gr = 0;
      for (const game of player.games) {
        if ((isValidDailySwap || game.ts > now) && game.proj > 0) {
          gr++;
        }
        if (game.tsDH && (isValidDailySwap || game.tsDH > now) && game.projDH > 0) {
          gr++;
        }
      }
      return gr;
    },

    // Used for swap fees when player status is AVA or TBA Q/D
    getGamesRemainingExcludingToday(player) {
      const now = new Date().getTime();
      const today = this.$moment(now).format('YYYY-MM-DD');
      let gr = 0;
      for (const game of player.games) {
        if (game.ts > now && game.proj > 0) {
          const gDate = this.$moment(game.ts).format('YYYY-MM-DD');
          if (today !== gDate) {
            gr++;
          }
        }
        if (game.tsDH && game.tsDH > now && game.projDH > 0) {
          const gDate = this.$moment(game.tsDH).format('YYYY-MM-DD');
          if (today !== gDate) {
            gr++;
          }
        }
      }
      return gr;
    },

    getProjGamesByPos(pos) {
      const pg = this.rosterCalc.projGames[pos];
      const pgChange = this.grByPosition[pos] || 0;
      return pg + pgChange;
    },

    getGameLimitColor(pos, text = false) {
      const numGames = this.getProjGamesByPos(pos);
      const maxGames = this.rosterCalc.gameLimits[pos];
      if (numGames > maxGames) {
        return 'orange';
      }
      if (numGames < maxGames) {
        return 'var(--obcolor-red);';
      }
      return text ? 'var(--obcolor-font-primary)' : 'var(--obcolor-background-2)';
    },

    formattedCost() {
      const cost = this.pendingCost;
      const costFormatted = cost.formatMoney();
      if (cost >= 0) {
        return '+ ' + costFormatted;
      }
      return '- ' + costFormatted.replace('-', '');
    },

    formatChange(n) {
      if (n == null) {
        return '+ 0';
      }
      if (n >= 0) {
        return '+ ' + n;
      }
      return String(n).replace('-', '- ');
    },

    openCalendar() {
      EventBus.$emit('LINEUP_EDITOR_OPEN_CALENDAR', this.contest, this.picks, this.rosterCalc, this.pendingDrops, this.pendingSwaps);
    },

    openPreview() {
      // Pass required game limit data to the modal, so I don't need to duplicate all these methods
      const gameLimits = [];
      for (const entry of this.rosterLimits) {
        const gamelimit = {pos: entry.short};
        gamelimit.max = entry.maxGames;
        gamelimit.proj = this.getProjGamesByPos(entry.short);
        gamelimit.change = this.formatChange(this.grByPosition[entry.short]);
        gamelimit.color = this.getGameLimitColor(entry.short);
        gamelimit.text = this.getGameLimitColor(entry.short, true);
        gameLimits.push(gamelimit);
      }

      // Pass required salary information to the modal
      const salaryCalc = {remaining: this.remainingSalary, cost: this.pendingCost, proj: this.projPoints, projChange: this.projChange};

      // Open Preview Modal
      EventBus.$emit('LINEUP_EDITOR_OPEN_PREVIEW', this.contest, this.roster, this.rosterCalc, this.pendingSwaps, salaryCalc, gameLimits, this.statusMap);
    },

    resetSwaps() {
      EventBus.$emit('LINEUP_EDITOR_CLEAR_SWAPS');
    },

    getGameEntryProjection(game, isValidDailySwap, now) {
      if (!game) {
        return 0;
      }
      let proj = 0;
      if (game.ts && (isValidDailySwap || game.ts > now)) {
        proj += game.proj ? Math.round(game.proj) : 0;
      }
      if (game.tsDH && (isValidDailySwap || game.tsDH > now)) {
        proj += game.projDH ? Math.round(game.projDH) : 0;
      }
      return proj;
    },
  },

};
</script>

<style lang="scss" scoped>
.ob-btn {
  border-radius: 0;
}
.ob-btn-grey {
  border-radius: 0;
}

.salaryLabel {
  text-transform: uppercase;
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: bold;
}
.salaryValue {
  font-size: 16px;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
}

.calendarBtn {
  font-size: 18px;
  border: 1px solid var(--obcolor-background-1);
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--obcolor-font-secondary);
}
.calendarBtn:hover {
  background: var(--obcolor-background-2);
  color: var(--obcolor-font-primary);
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}
.moneyText {
  color: var(--obcolor-green) !important;
}
.redText {
  color: var(--obcolor-red) !important;
}
</style>
