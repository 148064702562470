<template>
  <div class="playerBoxesContainer">
    <div class="ob-box userListBox">
      <div class="boxTitle">
        <div>Invite</div>
      </div>
      <input v-model="friendSearchString" placeholder="Search Friends...">
      <div class="userListScroll ob-scroll">
        <div v-for="(user, index) in filteredFriendsList" :key="index + 'pending'" class="userRow"
          @click="addUserToList(user)"
        >
          <UserLogo class="userLogo" :size="30" :id="user.id"
            :sport="league.sport"
          />
          <div class="teamNameContainer">
            <div class="userTeam">{{ user.team }}</div>
            <div class="userName">{{ user.name }}</div>
          </div>
          <i class="far fa-plus-circle" style="color: var(--obcolor-font-secondary)"></i>
        </div>
        <div v-for="(user, index) in alreadyAddedFriends" :key="index + 'added'" class="userRow userAlreadyAdded">
          <UserLogo class="userLogo" :size="30" :id="user.id"
            :sport="league.sport"
          />
          <div class="teamNameContainer">
            <div class="userTeam">{{ user.team }}</div>
            <div class="userName">{{ user.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ob-box userListBox">
      <div class="boxTitle">Invited</div>
      <div class="userListScroll ob-scroll">
        <div v-for="(user, index) in sortedInvitedUsers" :key="index + 'pending'" class="userRow"
          @click="removeUserFromList(user)"
        >
          <UserLogo class="userLogo" :size="30" :id="user.id"
            :sport="league.sport"
          />
          <div class="teamNameContainer">
            <div class="userTeam">{{ user.team }}</div>
            <div class="userName">{{ user.name }}</div>
          </div>
          <i class="far fa-minus-circle" style="color: var(--obcolor-red)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';

export default {
  components: {
    UserLogo,
  },
  props: {
    league: Object,
    friendsList: Array,
    userAddingList: Array,
  },
  data() {
    return {
      friendSearchString: '',
    };
  },
  computed: {
    sortedInvitedUsers() {
      return this.userAddingList.slice().sort((friend1, friend2) => {
        if (friend1.name < friend2.name) {
          return -1;
        } else if (friend1.name > friend2.name) {
          return 1;
        }
        return 0;
      });
    },
    filteredFriendsList() {
      const self = this;
      return this.friendsList.slice().filter((friend) => {
        let nameMatch = false;
        if (friend.id == self.$store.getters.userId) {
          return false;
        }
        if (self.friendSearchString != '') {
          const sName = self.friendSearchString.toLowerCase();
          let name = friend.name.toLowerCase();
          if (name.indexOf(sName) == 0) {
            nameMatch = true;
          }

          name = friend.team.toLowerCase();
          if (name.indexOf(sName) == 0) {
            nameMatch = true;
          }
          if (!nameMatch) {
            return false;
          }
        }
        return self.userAddingList.find((user) => user.id == friend.id) == null &&
          self.league.users.find((userId) => userId == friend.id) == null &&
          self.league.invitedUsers.find((userId) => userId == friend.id) == null;
      }).sort((friend1, friend2) => {
        if (friend1.name < friend2.name) {
          return -1;
        } else if (friend1.name > friend2.name) {
          return 1;
        }
        return 0;
      });
    },
    alreadyAddedFriends() {
      const self = this;
      return this.friendsList.slice().filter((friend) => {
        let nameMatch = false;
        if (friend.id == self.$store.getters.userId) {
          return false;
        }
        if (self.friendSearchString != '') {
          const sName = self.friendSearchString.toLowerCase();
          let name = friend.name.toLowerCase();
          if (name.indexOf(sName) == 0) {
            nameMatch = true;
          }

          name = friend.team.toLowerCase();
          if (name.indexOf(sName) == 0) {
            nameMatch = true;
          }
          if (!nameMatch) {
            return false;
          }
        }
        return !(self.userAddingList.find((user) => user.id == friend.id) == null &&
          self.league.users.find((userId) => userId == friend.id) == null &&
          self.league.invitedUsers.find((userId) => userId == friend.id) == null);
      }).sort((friend1, friend2) => {
        if (friend1.name < friend2.name) {
          return -1;
        } else if (friend1.name > friend2.name) {
          return 1;
        }
        return 0;
      }); ;
    },
  },
  methods: {
    addUserToList(friend) {
      this.$emit('addInvitedUser', friend);
    },
    removeUserFromList(friend) {
      this.$emit('removeInvitedUser', friend);
    },
  },
};
</script>

<style lang="scss" scoped>
.referBtn {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 0;
}
.playerBoxesContainer {
  display: flex;
  height: 100%;
}
.userListBox {
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-3);
}
.userListBox:first-child {
  margin-right: 10px;
}
.userListScroll {
  flex: 1;
  overflow: auto;
  margin-top: 5px;
}

.boxTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.userRow {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  background: var(--obcolor-background-2);
  cursor: pointer;
}
.userRow:hover {
  background: var(--obcolor-background-4);
}
.userRow.userAlreadyAdded {
  background: var(--obcolor-background-7);
  color: var(--obcolor-font-secondary);
  cursor: unset;
}

.userLogo {
  margin-right: 10px;
}

.teamNameContainer {
  flex: 1;
  overflow: hidden;
}
.userTeam {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}
.userName {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: var(--obcolor-font-secondary);
}
</style>