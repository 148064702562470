<template>
  <div class="contestGraphContainer" v-if="league.weeks">
    <!-- Canvas to draw lines connecting the dots between days on the graph -->
    <!-- We make the canvas 32 days wide to render the day before and after the shown content. -->
    <canvas ref="graphCanvas" class="graphCanvas" :height="graphHeight"
      :width="dayWidth * 32" :style="'left: -' + dayWidth + 'px'"
    ></canvas>
    <!-- Make boxes for 30 days -->
    <div v-for="n in 30" :key="n" class="dayBox"
      :style="formatDay($moment()) === formatDay(day(n)) ? 'background: #7ad9ff33;' : ''"
    >
      <div class="dayContent">
        <!-- Dots for the graph -->
        <div v-if="(numDays(league.startDate, day(n)) >= 0) && (numDays(league.endDate, day(n)) <= 0) && lastPlayedDay().diff(day(n)) > 0"
          class="graphPoint"
          :style="'top: calc(' + getScorePercentage(n) + '% - 3px);'"
        ></div>

        <!-- Played games battery/numbers -->
        <!-- Show fade in transition only if it's the first time this data has been loaded -->
        <transition :name="firstLoad ? 'fade' : ''">
          <div class="batteryList"
            v-if="p1Game && p2Game && getWeek(day(n)) != null && isCurrentWeek(day(n))"
          >
            <div class="batteryContainer">
              <div class="batteryBackground" v-if="isBatteryShown(p1Game, day(n))">
                <div class="batteryProgress" :style="'background: ' + sportColour + '; height: ' + getPlayedPercent(p1Game, day(n)) + '%;'"></div>
              </div>
              <!-- Todays Battery -->
              <div v-if="isBatteryShown(p1Game, day(n))" class="batteryText" style="color: white;"
                :class="{ batteryTodayText: isToday(day(n)) }"
              >
                {{ getRemainingGames(p1Game, day(n)) }}
              </div>
              <!-- Future days projected games -->
              <div v-else class="batteryText" :class="{ batteryTodayText: isToday(day(n)) }">
                {{ getGameTot(p1Game, day(n)) }}
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="batteryContainer">
              <div class="batteryBackground" v-if="isBatteryShown(p2Game, day(n))">
                <div class="batteryProgress" :style="'background: ' + sportColour + '; height: ' + getPlayedPercent(p2Game, day(n)) + '%;'"></div>
              </div>
              <!-- Todays Battery -->
              <div v-if="isBatteryShown(p2Game, day(n))" class="batteryText" style="color: white;"
                :class="{ batteryTodayText: isToday(day(n)) }"
              >
                {{ getRemainingGames(p2Game, day(n)) }}
              </div>
              <!-- Future days projected games -->
              <div v-else class="batteryText" :class="{ batteryTodayText: isToday(day(n)) }">
                {{ getGameTot(p2Game, day(n)) }}
              </div>
            </div>
          </div>

          <!-- End of Week Battery -->
          <div class="batteryList"
            v-else-if="p1Game && p2Game && getWeek(day(n-1)) != null && isCurrentWeek(day(n-1)) && isEndOfWeek(day(n-1))"
          >
            <div class="batteryContainer weekEndBattery">
              <div class="batteryBackground" :style="'border: 1px solid ' + getEndBatteryColor(p1Game)"></div>
              <div class="batteryText">
                {{ restOfWeekProjected(p1Game) }}
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="batteryContainer weekEndBattery">
              <div class="batteryBackground" :style="'border: 1px solid ' + getEndBatteryColor(p2Game)"></div>
              <div class="batteryText">
                {{ restOfWeekProjected(p2Game) }}
              </div>
            </div>
          </div>
        </transition>

        <!-- Circle at the end of the week, has a checkmark or X if won or lost -->
        <!-- Background is either grey, the sport colour (if they won), or red (if they lost). -->
        <template v-if="isEndOfWeek(day(n))">
          <div class="weekEndCircle"
            :class="{ wonMatchup: weekResult(day(n)) == 'win', lostMachup: weekResult(day(n)) == 'loss' }"
            :style="getWeekEndBackground(day(n))"
          >
            <!-- Don't show the icons if it is the first or last day of the list because they will be cut off -->
            <template v-if="n !== 0 && n != 30">
              <i v-if="weekResult(day(n)) == 'win'" class="fa fa-check" style="margin-left: -1px;"></i>
              <i v-else-if="weekResult(day(n)) == 'loss'" class="fa fa-times" style="font-size: 14px;"></i>
            </template>
          </div>
        </template>

        <!-- Trophy at final week - Also shows payout if current week is not final week -->
        <template v-if="isEndOfWeek(day(n-1)) && getWeekIndex(day(n-1)) == league.weeks.length - 1">
          <div class="payoutContainer finalPayoutContainer" v-if="getWeek(day(n-1)).prize != null && n-1 !== 0 && n-1 != 30">
            <i class="fa fa-trophy payoutTrophy finalPayoutTrophy" v-if="getWeekIndex(day(n-1)) == league.weeks.length - 1"
              :style="'color: var(--obcolor-' + (league.weeks[league.weeks.length - 1].result == 'win' ? sportColour : 'font-secondary') + ')'"
              style="margin-top: -30px;"
            ></i>
            <!-- Box for winnings, background is either the sports background or grey -->
            <div v-if="!isCurrentWeek(day(n-1)) || !p1Game || !p2Game" class="winningsNumber" :style="'background: ' + sportColour + ';'">
              {{ getWeek(day(n-2)).prize.removeCentsIfZero() }}
            </div>
          </div>
        </template>

        <!-- Played game pie chart -->
        <!-- Show pie chart at end of week if data is set, and the week is currently in progress -->
        <transition :name="firstLoad ? 'fade' : ''">
          <div class="pieChartList" v-if="isEndOfWeek(day(n-2)) && p1Game && p2Game && isCurrentWeek(day(n-2))"
            :class="{ endOfContestChartList: getWeekIndex(day(n-2)) == league.weeks.length - 1 }"
          >
            <div class="pieChartContainer">
              <PieChart size="21" :slices="[{ color: sportColour, percent: 100 - totGamePercent(p1Game) }, { color: 'background', percent: totGamePercent(p1Game) }]" />
              <div class="pieChartText">
                <div>{{ getCalculatedGamesPlayed(p1Game) }}</div>
                <div style="margin-left: 1px; margin-right: 1px;">/</div>
                <div>{{ p1Game.totalGames }}</div>
              </div>
            </div>
            <div class="pieChartWinnings">
              <div v-if="getWeek(day(n-2)).prize != null" class="payoutContainer payoutContainerCenter" style="right: 0; left: 0;">
                <!-- Box for winnings, background is either the sports background or grey -->
                <div class="winningsNumber" :style="'background: ' + sportColour + ';'">
                  {{ getWeek(day(n-2)).prize.removeCentsIfZero() }}
                </div>
              </div>
            </div>
            <div class="pieChartContainer">
              <PieChart size="21" :slices="[{ color: sportColour, percent: 100 - totGamePercent(p2Game) }, { color: 'background', percent: totGamePercent(p2Game) }]" />
              <div class="pieChartText">
                <div>{{ getCalculatedGamesPlayed(p2Game) }}</div>
                <div style="margin-left: 1px; margin-right: 1px;">/</div>
                <div>{{ p2Game.totalGames }}</div>
              </div>
            </div>
          </div>
        </transition>
        <template v-if="showingCenterPayout(day(n-1))">
          <div class="payoutContainer payoutContainerCenter" v-if="getWeek(day(n-1)).prize != null && n !== 0 && n != 30">
            <!-- Box for winnings, background is either the sports background or grey -->
            <div class="winningsNumber" :style="'background: ' + sportColour + ';'">
              {{ getWeek(day(n-2)).prize.removeCentsIfZero() }}
            </div>
          </div>
        </template>
        <template v-else-if="showingPastWeekPayout(day(n))">
          <div class="payoutContainer payoutContainerCenter" style="right: 0; width: 100%;" v-if="getWeek(day(n)).prize != null && n !== 0 && n != 30">
            <!-- Box for winnings, background is either the sports background or grey -->
            <div class="winningsNumber" :style="'background: ' + (weekResult(day(n)) == 'win' ? sportColour : 'var(--obcolor-timeline)') + ';'">
              {{ getWeek(day(n)).prize.removeCentsIfZero() }}
            </div>
          </div>
        </template>
        <template v-if="n == 1 && isEndOfWeek(day(n-1))">
          <div class="weekEndCircle leftCircle"
            :class="{
              wonMatchup: weekResult(day(n-1)) == 'win',
              lostMachup: weekResult(day(n-1)) == 'loss'
            }"
            :style="weekResult(day(n-1)) == 'win' ? 'background: ' + sportColour + ';' : ''
              + weekResult(day(n-1)) == 'loss' ? 'background: var(--obcolor-red)' : ''"
          >
          </div>
        </template>
        <router-link :to="getMatchupLink(getWeek(day(n)))" v-if="isEndOfWeek(day(n)) && hasMatchup(getWeek(day(n)))"
          class="clickableWeek" :style="'width: calc(' + (getWeekLength(getWeek(day(n))) * 100) + '% + ' + getWeekLength(getWeek(day(n))) + 'px);'"
        />
      </div>
    </div>
    <!-- Bar spanning every day in the league. It's filled up to the current date. -->
    <div class="progressBar"
      :style="'width: ' + dayWidth * (numDays($moment(league.startDate), $moment(league.endDate)) + 1) + 'px;'
        + 'left: ' + dayWidth * numDays(startingDay, $moment(league.startDate)).toFixed(0) + 'px;'"
    >
      <div v-if="numDays($moment(league.startDate), $moment()) >= 0"
        class="progress"
        :style="'width: ' + dayWidth * (numDays($moment(league.startDate), lastPlayedDay())) + 'px;'
          + 'background: ' + sportColour + ';'"
      ></div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  components: {
    PieChart,
  },

  props: {
    league: Object,
    selectedDay: Object,
    startingDay: Object,
    gamesData: Object,
    gameFinishedStatus: Object,
  },

  data: function() {
    return {
      canvas: null,
      dayWidth: 32,
      graphHeight: 120,

      firstLoad: true,
    };
  },

  watch: {
    selectedDay(to, from) {
      if (this.p1Game && this.p2Game) {
        this.firstLoad = false;
      }

      this.buildGraph();
    },
  },

  mounted() {
    const c = this.$refs['graphCanvas'];
    if (c != undefined) {
      const ctx = c.getContext('2d');
      this.canvas = ctx;
      this.buildGraph();
    }
    EventBus.$on('GRAPH_DATA_UPDATE', this.graphDataUpdate);
  },

  destroyed() {
    EventBus.$off('GRAPH_DATA_UPDATE', this.graphDataUpdate);
  },

  computed: {
    ...mapState(['userId']),

    sportColour() {
      return 'var(--obcolor-sportscolour-' + this.league.sport.toLowerCase() + ')';
    },

    p1Game() {
      let gameId;
      if (this.league.curMatchup.myTeam == 1) {
        gameId = this.league.curMatchup.team1Id;
      } else {
        gameId = this.league.curMatchup.team2Id;
      }

      if (!gameId) {
        return null;
      }

      if (this.gamesData && this.gamesData[gameId]) {
        return this.gamesData[gameId];
      }

      return null;
    },

    p2Game() {
      let gameId;
      if (this.league.curMatchup.myTeam != 1) {
        gameId = this.league.curMatchup.team1Id;
      } else {
        gameId = this.league.curMatchup.team2Id;
      }

      if (!gameId) {
        return null;
      }

      if (this.gamesData && this.gamesData[gameId]) {
        return this.gamesData[gameId];
      }

      return null;
    },
  },

  methods: {
    graphDataUpdate(id) {
      if (this.league.id === id) {
        this.buildGraph();
      }
    },

    dayScore(dateObject) {
      return this.league.dayScores[this.formatDay(dateObject)];
    },

    formatDay(dateObject) {
      return dateObject.format('YYYY-MM-DD');
    },

    day(index) {
      return this.startingDay.clone().add(index - 1, 'days');
    },

    lastPlayedDay() {
      const today = this.$moment();
      for (const week of this.league.weeks) {
        if (week.result && week.result == 'loss') {
          return this.$moment(week.end).add(1, 'days').startOf('day');
        } else if (today.diff(this.$moment(week.end)) < 0) {
          return today;
        }
      }
      return today;
    },

    curUserWon(dateObject) {
      return this.dayScore(dateObject).myTotal > this.dayScore(dateObject).oppTotal;
    },

    getWeekIndex(dateObject) {
      for (const weekIndex in this.league.weeks) {
        if (this.$moment(this.league.weeks[weekIndex].start).diff(dateObject, 'days') <= 0 &&
          this.$moment(this.league.weeks[weekIndex].end).diff(dateObject, 'days') >= 0
        ) {
          return parseInt(weekIndex);
        }
      }

      return null;
    },

    getWeek(dateObject) {
      if (this.getWeekIndex(dateObject) != null) {
        return this.league.weeks[this.getWeekIndex(dateObject)];
      }

      return null;
    },

    isEndOfWeek(dateObject) {
      return this.getWeek(dateObject) && this.$moment(dateObject).diff(this.$moment(this.getWeek(dateObject).end), 'days') == 0;
    },

    isCurrentWeek(dateObject) {
      return this.league.curWeek == this.getWeekIndex(dateObject) + 1;
    },

    isPastWeek(dateObject) {
      return this.league.curWeek > this.getWeekIndex(dateObject)+1;
    },

    getWeekLength(weekObject) {
      if (!weekObject || !weekObject.start || !weekObject.end) {
        return 7;
      }
      const date1 = this.$moment(weekObject.end, 'YYYY-MM-DD');
      const date2 = this.$moment(weekObject.start, 'YYYY-MM-DD');
      return Math.ceil(date1.diff(date2, 'days')) + 1;
    },

    isToday(dateObject) {
      return Math.ceil(this.numDays(this.$moment(), dateObject)) == 0;
    },

    numDays(date1, date2) {
      // Don't get diff in days because then we lose the time difference. Divide by a day instead.
      return date2.diff(date1)/86400000;
    },

    weekResult(dateObject) {
      const week = this.getWeek(dateObject);

      if (!week) {
        return null;
      }

      return week.result;
    },

    getWeekEndBackground(dateObject) {
      const result = this.weekResult(dateObject);

      switch (result) {
        case 'win':
          return 'background: ' + this.sportColour;
        case 'loss':
          return 'background: var(--obcolor-red)';
        default:
          return '';
      }
    },

    daysSinceWeekStart(day) {
      const week = this.getWeek(day);
      if (week == null) {
        return null;
      }
      const weekStartDate = this.$moment(week.start, 'YYYY-MM-DD');
      return day.diff(weekStartDate, 'days');

      // Changed to days diff, this method could sometimes cause decimal places
      // IE. 6.0416 days would then not match on index lookup
      // return this.numDays(this.$moment(week.start, 'YYYY-MM-DD'), day);
    },

    getScorePercentage(dayIndex) {
      const dayScore = this.league.dayScores[this.formatDay(this.day(dayIndex))];
      if (!dayScore) {
        return 50;
      }
      const team1Score = dayScore.oppTotal;
      const team2Score = dayScore.myTotal;

      return this.$ScoringStats.getLeadPercentage(this.league.sport, team1Score, team2Score);
    },

    totGamePercent(gameData) {
      if (gameData.playedGameIds === null || gameData.totalGames === null) {
        return 0;
      }
      const totalGamesPlayed = this.getCalculatedGamesPlayed(gameData);
      return 100 - (totalGamesPlayed / gameData.totalGames) * 100;
    },

    getCalculatedGamesPlayed(gameData) {
      // For each played game id, check if game is over in the live-game data
      // If game is not over, we don't show the game as played since it will show in the battery
      // Only exclude game if the gameFinishedStatus is false (not null)
      // Games from previous days don't have live-game data, in this case we can safely assume it was played
      let totalGamesPlayed = 0;
      if (!gameData.playedGameIds) {
        return totalGamesPlayed;
      }
      for (const gameId of gameData.playedGameIds) {
        const finished = this.gameFinishedStatus[gameId];
        if (finished == null || finished === 1) {
          totalGamesPlayed++;
        }
      }
      return totalGamesPlayed;
    },

    getRemainingGames(gameData, dateObject) {
      const dayIndex = this.getWeekGameIndex(dateObject);
      const finishedGames = this.getBatteryGP(gameData, dateObject);
      const totalGames = (gameData.projectedGamesByDay[dayIndex] || 0) + (gameData.playedGamesByDay[dayIndex] || 0);
      return totalGames - finishedGames;
    },

    getPlayedPercent(gameData, dateObject) {
      if (!gameData.playedGamesByDay || !gameData.projectedGamesByDay) {
        return 100;
      }
      const dayIndex = this.getWeekGameIndex(dateObject);
      const finishedGames = this.playerBatteryFill(gameData, dateObject);
      const totalGames = (gameData.projectedGamesByDay[dayIndex] || 0) + (gameData.playedGamesByDay[dayIndex] || 0);
      const pct = 100 - (finishedGames / totalGames * 100);
      return pct;
    },

    getBatteryGP(gameData, dateObject) {
      let totalGamesPlayed = 0;
      const dayIndex = this.getWeekGameIndex(dateObject);

      if (!gameData.gamesIdByDay || !gameData.gamesIdByDay[dayIndex]) {
        return gameData.playedGamesByDay[dayIndex] || 0;
      }
      for (const gameId of gameData.gamesIdByDay[dayIndex]) {
        const finished = this.gameFinishedStatus[gameId];
        if (finished == null || finished === 1) {
          totalGamesPlayed++;
        }
      }
      return totalGamesPlayed;
    },

    playerBatteryFill(gameData, dateObject) {
      let tot = 0;
      const dayIndex = this.getWeekGameIndex(dateObject);

      if (!gameData.gamesIdByDay || !gameData.gamesIdByDay[dayIndex]) {
        return gameData.playedGamesByDay[dayIndex] || 0;
      }

      for (const gameId of gameData.gamesIdByDay[dayIndex]) {
        if (this.gameFinishedStatus[gameId] != null) {
          tot += this.gameFinishedStatus[gameId];
        }
      }

      return tot;
    },

    restOfWeekProjected(gameData) {
      let totProj = 0;
      const dayIndex = this.getWeekGameIndex(this.$moment());

      if (!gameData.projectedGamesByDay) {
        return 0;
      }

      // Total all games after today
      for (const key of Object.keys(gameData.projectedGamesByDay)) {
        if (key >= dayIndex) {
          totProj += gameData.projectedGamesByDay[key] || 0;
        }
      }

      for (const key of Object.keys(gameData.playedGamesByDay)) {
        if (key >= dayIndex) {
          totProj += gameData.playedGamesByDay[key] || 0;
        }
      }

      // Subtract games that have been played today
      totProj = totProj - this.getBatteryGP(gameData, this.$moment());
      return totProj;
    },

    isBatteryShown(gameData, dateObject) {
      const dayIndex = this.getWeekGameIndex(dateObject);
      let numGames = 0;
      if (gameData.projectedGamesByDay) {
        numGames += gameData.projectedGamesByDay[dayIndex] || 0;
      }
      if (gameData.playedGamesByDay) {
        numGames += gameData.playedGamesByDay[dayIndex] || 0;
      }
      return numGames !== 0 && Math.ceil(this.numDays(this.$moment(), dateObject)) == 0;
    },

    getEndBatteryColor(gameData) {
      const total = this.getCalculatedGamesPlayed(gameData) + this.restOfWeekProjected(gameData);

      if (total < gameData.totalGames) {
        return 'var(--obcolor-red)';
      }
      if (total > gameData.totalGames) {
        return 'var(--obcolor-sportscolour-nba)';
      }

      return 'var(--obcolor-background-1)';
    },

    showingCenterPayout(dateObject) {
      return this.isEndOfWeek(dateObject) &&
        this.getWeekIndex(dateObject) != this.league.weeks.length - 1 &&
        !this.isCurrentWeek(dateObject) &&
        !this.isPastWeek(dateObject);
    },

    showingPastWeekPayout(dateObject) {
      return this.daysSinceWeekStart(dateObject) == Math.floor(this.getWeekLength(this.getWeek(dateObject)) / 2) &&
        this.getWeekIndex(dateObject) != this.league.weeks.length - 1 && this.isPastWeek(dateObject);
    },

    getWeekGameIndex(dateObject) {
      const day = dateObject.format('YYYY-MM-DD');
      const keys = Object.keys(this.league.dayScores).filter((dIndex) => {
        return this.league.dayScores[dIndex] && this.league.dayScores[dIndex].week == this.league.curWeek;
      }).sort((d1, d2) => {
        const d1Obj = this.$moment(d1, 'YYYY-MM-DD');
        const d2Obj = this.$moment(d2, 'YYYY-MM-DD');

        return d1Obj.unix() - d2Obj.unix();
      });

      if (!keys) {
        return null;
      }

      for (const kIndex in keys) {
        if (day == keys[kIndex]) {
          return kIndex;
        }
      }

      return null;
    },

    getGameTot(gameData, dateObject) {
      const index = this.getWeekGameIndex(dateObject);
      const d = this.numDays(this.$moment(), dateObject);
      // For previous days show nothing instead of 0
      return d <= -1 ? '' : (gameData.projectedGamesByDay[index] || '0');
    },

    hasMatchup(week) {
      if (!week || !week.end || !week.start) {
        return false;
      }

      if (this.getMatchup(week) == null) {
        return false;
      }

      return this.league.curWeek >= this.getWeekIndex(this.$moment(week.start))+1;
    },

    getMatchup(week) {
      let weekIndex = null;
      for (const wIndex in this.league.weeks) {
        if (this.league.weeks[wIndex].start == week.start) {
          weekIndex = wIndex;
        }
      }

      if (weekIndex == null) {
        return;
      }

      for (const mIndex in this.league.bracket[weekIndex].matchups) {
        const matchup = this.league.bracket[weekIndex].matchups[mIndex];
        if (matchup.team1Id == this.userId || matchup.team2Id == this.userId) {
          return matchup;
        }
      }

      return null;
    },

    getMatchupLink(week) {
      if (this.getMatchup(week) != null) {
        return '/contest/matchup/summary/' + this.league.id + '/' + this.getMatchup(week).id;
      }
    },

    buildGraph() {
      const ctx = this.canvas;
      ctx.clearRect(0, 0, (this.dayWidth * 31), this.graphHeight);
      // We start 1 day behind so that the graph connects the first day on the screen with the day before it
      const day = this.startingDay.clone().subtract(1, 'days');
      const dWidth = this.dayWidth;
      const halfX = dWidth / 2;
      let curX = 0;

      let lastPointX = null;
      let lastPointY = null;

      let oldWeek = null;

      // Loop to 32 because we're rendering 30 days, and connecting them with the day before and after what is shown on the screen.
      for (let i=0; i<32; i++) {
        const date = this.formatDay(day);
        const dayScore = this.league.dayScores[date];

        if (dayScore != null) {
          // Draw point
          const pct = this.getScorePercentage(i);

          const newPointX = curX + halfX;
          const newPointY = ((this.graphHeight - 10) * pct / 100) + 5;

          // Draw the line from the previous day's point to the current day's point.
          if (lastPointX && lastPointY && (oldWeek == dayScore.week) && this.$moment().diff(day) > 0) {
            ctx.beginPath();
            ctx.moveTo(lastPointX, lastPointY);
            ctx.lineTo(newPointX, newPointY);
            ctx.strokeStyle = '#878998';
            ctx.stroke();
          }

          lastPointX = newPointX;
          lastPointY = newPointY;
          // Keep track of the previous week so that we don't connect spanning multiple weeks.
          if (dayScore != null) {
            oldWeek = dayScore.week;
          }
        }

        curX += dWidth;
        day.add(1, 'days');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contestGraphContainer {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

// This makes hovering on a week work overtop of the progress bar etc
// While having the hover colour behind these elements.
.graphCanvas, .graphPoint, .payoutContainer, .weekEndCircle, .progressBar, .batteryList, .pieChartList {
  pointer-events: none;
}

.graphCanvas {
  position: absolute;
  z-index: 60;
}

.dayBox {
  width: 32px;
  height: 100%;
  border-right: 1px solid var(--obcolor-background-page);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.dayContent {
  height: calc(100% - 10px);
  width: 100%;
  position: relative;
}

.graphPoint {
  height: 6px;
  width: 6px;
  background: var(--obcolor-font-secondary);
  border-radius: 50%;
  position: absolute;
  z-index: 60;
  margin-left: calc(50% - 2px);
}

.weekEndCircle {
  $size: 20px;
  position: absolute;
  top: calc(50% - (#{$size}/2));
  z-index: 60;
  right: -$size/2;
  height: $size;
  width: $size;
  border-radius: 50%;
  background: var(--obcolor-timeline);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  &.leftCircle {
    right: unset;
    left: -$size/2;
  }
}

.clickableWeek {
  position: absolute;
  right: 0;
  top: -5px;
  // This will be overwritten usually, but I'm keeping it as a fallback
  width: calc(700% + 7px);
  height: calc(100% + 10px);
  z-index: 0;
}

.clickableWeek:hover {
  background: var(--obcolor-ob-blue);
  opacity: 0.1;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  background: var(--obcolor-timeline);
  height: 6px;
  border-radius: 10px;
  overflow: hidden;
}
.progress {
  height: 100%;
  background: var(--obcolor-ob-blue);
}

.pieChartList {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  margin-left: 21px;
}

.pieChartContainer {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pieChart {
  height: 21px;
  width: 21px;
  border-radius: 50%;
}

.pieChartText {
  white-space: nowrap;
  font-size: 12px;
  height: 14px;
  font-weight: bold;
  display: flex;
  margin-left: 1px;
  text-align: center;
  justify-content: center;
}

.pieChartWinnings {
  flex: 1;
}

.batteryList {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.batteryContainer {
  width: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.batteryBackground {
  border-radius: 5px;
  position: absolute;
  height: 24px;
  width: 20px;
  background: var(--obcolor-timeline);
  border: 1px solid var(--obcolor-background-3);
  overflow: hidden;
  z-index: 61;
}

.weekEndBattery .batteryBackground {
  background: none;
}

.batteryProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.batteryText {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  z-index: 62;
  margin-top: 1px;
}

.batteryContainer:not(.weekEndBattery) .batteryText:not(.batteryTodayText) {
  color: var(--obcolor-font-secondary);
}

.payoutContainer {
  $width: 20px;
  bottom: calc(50% + 12px);
  right: -$width/2;
  width: $width;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 61;
}

.payoutContainer.payoutContainerCenter {
  bottom: calc(50% - 9px);
}

.payoutContainer.finalPayoutContainer {
  bottom: calc(50% - 18px);
}

.payoutTrophy {
  color: var(--obcolor-font-secondary);
}

.payoutContainer.finalPayoutContainer .payoutTrophy {
  font-size: 26px;
  margin-left: 1px;
}

.winningsNumber {
  background: var(--obcolor-ob-blue);
  padding: 2px 5px;
  margin-top: 2px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
}

.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
