<template>
  <div class="pickContainer">
    <div class="playerInfo" @click.stop="openPlayerCard(pick.playerId.toString())">
      <PlayerHeadshot class="headshot"
        :id="pick.playerId.toString()"
        size="50"
        :sport="pick.sport"
        :team="pick.player.teamAlias"
        :showLogo="true"
      />
      <div class="subHeader playerPosition">{{ this.pick.player.position }}</div>
      <div class="nameContainer">
        <PlayerStatus height="12" :status="getPlayerStatus()" />
        <div class="playerName">
          {{ pick.player.firstName + " " + pick.player.lastName }}
        </div>
        <PlayerStartingStatus class="startingStatus" :sport="pick.sport" :player="pick.player" />
        <DiscountTag v-if="pick.isDiscounted" class="discountTag" :pick="pick"
          rotate
        />
      </div>
    </div>
    <div v-if="pick.isRemoved" class="subHeader errorText"><i class="fas fa-exclamation-circle fa-sm error"></i>{{ getCancelledText() }}</div>
    <LivePropsProgressBar v-else class="progressBar" :result="getResult"
      :pick="pick"
    />
    <div class="bottomText spacedContent">
      <div class="subHeader">
        {{ playerGameText }}
      </div>
      <div class="subHeader">
        {{ getContestStart() }}
      </div>
    </div>
    <div class="pickInfoContainer">
      <div class="boldText"> {{ getMoreLess(pick) }}</div>
      <div class="balancedLine">
        <MarketLine :pick="pick" highlight />
      </div>
      <div class="subHeader subtitle centered">{{ pick.marketType.abbreviation }}</div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import PlayerStatus from '@/components/PlayerStatus.vue';
import PlayerStartingStatus from '@/components/PlayerPicks/PlayerStartingStatus.vue';
import LivePropsProgressBar from './LivePropsProgressBar.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import moment from 'moment';
import MarketLine from '@/components/PlayerPicks/MarketLine.vue';
import DiscountTag from '@/views/Props/LineupBuilder/DiscountTag.vue';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
    PlayerStartingStatus,
    LivePropsProgressBar,
    DiscountTag,
    MarketLine,
  },

  props: {
    pick: {type: Object, default: null},
    entry: {type: Object, default: null},
  },

  data() {
    return {
      game: null,
    };
  },

  created() {
    this.game = this.todaysGames[this.pick.gameId];
    EventBus.$on('LIVE_GAME_UPDATE', this.handleGameUpdate);
  },

  destroyed() {
    EventBus.$off('LIVE_GAME_UPDATE', this.handleGameUpdate);
  },

  computed: {
    ...mapState(['todaysGames']),

    getGameProperties() {
      return {
        homeTeam: this.pick?.game?.homeTeam?.alias,
        awayTeam: this.pick?.game?.awayTeam?.alias,
        homeScore: this.game?.live?.homeTeam?.score || this.pick?.game?.homeTeam.score,
        awayScore: this.game?.live?.awayTeam?.score || this.pick?.game?.awayTeam.score,
        gameTime: this.game?.live?.gametime || this.pick?.game?.gameTime,
        sport: this.pick?.sport,
        live: {
          inning: this.game?.live?.inning || this.pick?.game?.inning,
          inning_half: this.game?.live?.inning_half || this.pick?.game?.halfInning,
          outs: this.game?.live?.outs,
          period: this.game?.live?.period || this.pick?.game?.period,
        },
      };
    },

    playerGameText() {
      const game = this.getGameProperties;
      const isHome = this.pick?.player?.isHome;
      const homeTeamAlias = this.$TeamInfo.getDisplayAlias(this.pick.sport, game.homeTeam);
      const awayTeamAlias = this.$TeamInfo.getDisplayAlias(this.pick.sport, game.awayTeam);
      const pickTeam = (isHome ? homeTeamAlias : awayTeamAlias);
      const oppTeam = (isHome ? awayTeamAlias : homeTeamAlias);
      const pickScore = isHome ? game.homeScore : game.awayScore;
      const oppScore = isHome ? game.awayScore : game.homeScore;
      const versusText = isHome ? 'vs' : '@';

      if (this.pick?.game?.state === 'UPCOMING' || (!pickScore && pickScore !== 0) || (!oppScore && oppScore !== 0)) {
        return `${pickTeam} ${versusText} ${oppTeam}`;
      }
      return `${pickTeam} ${pickScore} ${versusText} ${oppTeam} ${oppScore}`;
    },

    contestCompleted() {
      return this.entry.state === 'COMPLETED';
    },

    getResult() {
      const result = this.pick?.result;
      const overUnder = this.pick?.selection;
      const balancedLine = this.pick?.balancedLine;

      if (!result && result !== 0) {
        return null;
      }
      switch (overUnder) {
        case 'OVER':
          if (!this.gameFinished && result < balancedLine) {
            return null;
          }
          return result > balancedLine;
        // only let under bets return true if the game has finished
        case 'UNDER':
          if (this.gameFinished) {
            return result < balancedLine;
          } else if (!this.gameFinished && result < balancedLine) {
            return null;
          } else {
            return false;
          }
        default:
          return null;
      }
    },

    gameFinished() {
      if (!this.pick.game?.state) {
        return null;
      }
      return this.pick.game?.state === 'COMPLETED';
    },
  },

  methods: {
    getMoreLess(pick) {
      if (pick.selection == 'OVER') {
        return 'More';
      } else {
        return 'Less';
      }
    },

    gameStarted(pick) {
      if (!this.entry) {
        return false;
      }
      return this.entry?.state === 'ACTIVE' || new Date().getTime() > pick.game.date;
    },

    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.pick.sport);
    },

    getStatusColor() {
      if (this.getResult === true) {
        return 'var(--obcolor-green)';
      } else if (this.getResult === false) {
        return 'var(--obcolor-red)';
      } else {
        return 'var(--obcolor-game-possibility)';
      }
    },

    handleGameUpdate(gameId) {
      if (this.pick.game.gameId === gameId) {
        this.game = this.todaysGames[gameId];
      }
    },

    getPlayerStatus() {
      return this.pick.player.status;
    },

    getContestStart() {
      if (this.pick.game.state == 'COMPLETED') {
        return 'Final';
      } else if (this.pick.game.state === 'ACTIVE') {
        return `${this.getLiveGameState()}`;
      }
      const dateCheckFormat = 'MMM D YYYY';
      const contestStart = this.pick.game.date;
      const contestDate = moment(contestStart).format(dateCheckFormat);
      const todaysDate = moment().format(dateCheckFormat);
      if (contestDate == todaysDate) {
        return moment(contestStart).format('h:mma');
      }
      return moment(contestStart).format('ddd, h:mma');
    },

    getLiveGameState() {
      const game = this.getGameProperties;

      if (!game?.gameTime) {
        return '';
      }

      if (this.game.sport === 'MLB') {
        const inning = this.$SportInfo.getGamePeriod(this.game.sport, this.game);
        if (inning !== '') {
          return inning;
        }
        return '';
      }

      const gameTime = game.gameTime === '00:00' ? 'End' : game.gameTime;
      const period = this.$SportInfo.getGamePeriod(this.game.sport, this.game);
      return (gameTime + ' ' + period);
    },

    getCancelledText() {
      let text = 'Entry Cancelled';

      if (this.pick.removedNote) {
        text += ' | ' + this.pick.removedNote;
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.pickContainer {
  display: grid;
  grid-template-areas:
    'headshot name       pick-info'
    'headshot bar        pick-info'
    'position bottomText pick-info';
  grid-template-columns: auto 1fr 110px;
  grid-template-rows: 31px auto auto;

  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: var(--obcolor-background-4);
  border-radius: 8px;
  box-sizing: border-box;
  row-gap: 0;
  column-gap: 16px;
}

.playerInfo {
  display: contents;
  cursor: pointer;
}

.contestInfo {
  position: relative;
  flex-grow: 1;
}

.discountTag {
  grid-area: discount;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.nameContainer:hover .playerName {
  text-decoration: underline;
}

.playerName {
  font-weight: 700;
  font-size: 16px;
  margin-left: 4px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.startingStatus {
  margin-left: 4px;
}

.subHeader {
  color: var(--obcolor-font-secondary);
}

.balancedLine {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-top: 2px;
  font-size: 16px;
  font-weight: 700;
}

.progressBar {
  grid-area: bar;
  margin: 4px 0;
}

.pickInfoContainer {
  grid-area: pick-info;
  background-color: var(--obcolor-background-2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.subtitle {
  font-size: 12px;
}

.spacedContent {
  justify-content: space-between;
}

.centered {
  display: flex;
  text-align: center;
}

.error {
  color: var(--obcolor-red);
  margin-right: 4px;
}

.errorText {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}

.playerPosition {
  grid-area: position;
  margin-top: 2px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.headshot {
  grid-area: headshot;
  cursor: pointer;
  align-self: start;
}

.boldText {
  font-weight: 700;
}

.fullHeight {
  height: 100%;
}

.bottomText {
  grid-area: bottomText;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
</style>
