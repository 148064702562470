// Matchup Summary Stats
// Controls the top overall summary for matchup page
// MLB has 2 category for for pitching and batting

export const MATCHUP_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'O', path: 'pitcher.IP_1', tooltip: 'Outs'},
      {label: 'ER', path: 'pitcher.ER', tooltip: 'Earned Runs Against'},
      {label: 'K', path: 'pitcher.K', tooltip: 'Strikeouts'},
      {label: 'BB', path: 'pitcher.BB_U', sum: 'pitcher.BB_I', tooltip: 'Batters Walked'},
      {label: 'H', path: 'pitcher.H', tooltip: 'Hits Allowed'},
      {label: 'HBP', path: 'pitcher.HBP', tooltip: 'Batters Hit by Pitch'},
      {label: 'W', path: 'pitcher.W', tooltip: 'Wins'},
      {label: 'SO', path: 'pitcher.SO', tooltip: 'Shut Outs'},
      {label: 'SV', path: 'pitcher.SV', tooltip: 'Saves'},
    ],
  },
];

export const MATCHUP_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: '1B', path: 'batter.1B', tooltip: 'Singles Hit'},
      {label: '2B', path: 'batter.2B', tooltip: 'Doubles Hit'},
      {label: '3B', path: 'batter.3B', tooltip: 'Triples Hit'},
      {label: 'HR', path: 'batter.HR', tooltip: 'Home Runs'},
      {label: 'R', path: 'batter.R', tooltip: 'Runs'},
      {label: 'RBI', path: 'batter.RBI', tooltip: 'Runs Batted In'},
      {label: 'K', path: 'batter.K', tooltip: 'Strikeouts'},
      {label: 'BB', path: 'batter.BB_U', sum: 'batter.BB_I', tooltip: 'Base on Balls'},
      {label: 'HBP', path: 'batter.HBP', tooltip: 'Hit By Pitch'},
      {label: 'SB', path: 'batter.SB', tooltip: 'Stolen Bases'},
      {label: 'CS', path: 'batter.CS', tooltip: 'Caught Stealing'},
      {label: 'PK', path: 'batter.PK', tooltip: 'Picked Off'},
    ],
  },
];
