<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 800px; height: 680px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-page);" :disableClickaway="true"
  >
    <div slot="header" class="header">
      <div class="headerField">
        <div class="headerText">Edit Lineups via CSV - {{ contest.gameSlateName }}</div>
        <div class="descriptionText">{{ getContestStartDate(contest.contestStart) }}</div>
      </div>
    </div>

    <div slot="body" style="height: 513px;">
      <EditLineupImport v-if="showInstructions" :contest="contest" :playerMap="playerMap" />
      <EditLineups v-else :contest="contest" :playerMap="playerMap"
        :lineups="importedLineups"
      />
    </div>

    <div slot="footer" class="modalFooter">
      <div class="footerField">
        <div class="contestTimeContainer">
          <div class="contestTime">{{ contestCountdownVal }}</div>
          <div class="descriptionText">
            {{ contestCountdownVal != '0:00:00' ? 'Contest Starts' : 'Contest Has Started' }}
          </div>
        </div>
        <ContestFillGraph :playersCurrent="contest.playersCurrent"
          :playersMax="contest.playersMax"
          :format="'Tournament'"
          :playerJoined="playerJoined"
          textPosition="bottom"
          style="margin-left: 40px; margin-right: 55px; width: 150px;"
        />
      </div>
      <div :class="showError ? 'submitError' : 'submitContainer'">
        <button class="ob-btn submitButton" :class="disableSubmit ? 'ob-btn-grey' : 'ob-btn'" :disabled="disableSubmit"
          @click="editLineups()" v-if="!showInstructions"
        >
          Submit Edit
        </button>
        <div v-if="showError" class="error">
          <i class="fas fa-exclamation-circle" style="color: red;"></i>
          Error(s) exist. Some lineups will not be edited.
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EditLineupImport from './EditLineupImport.vue';
import EditLineups from './EditLineups.vue';
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
    EditLineupImport,
    EditLineups,
    ContestFillGraph,
  },

  data() {
    return {
      playerMap: {},
      isOpen: false,
      showInstructions: true,
      submitCount: 0,
      contestCountdownInterval: null,
      contestCountdownVal: '',
      playerJoined: null,
      contest: null,
      numEntries: 0,
      showError: false,
      importedLineups: [],
    };
  },

  created() {
    EventBus.$on('OPEN_SALARY_CAP_EDIT_LINEUP_MODAL', this.openModal);
    EventBus.$on('CLOSE_SALARY_CAP_EDIT_LINEUP_MODAL', this.closeModal);
    EventBus.$on('CSV_EDIT_LINEUPS_IMPORTED', this.lineupsImported);
    EventBus.$on('TOGGLE_EDIT_LINEUP_SUBMIT', this.toggleSubmitLineup);
    EventBus.$on('TOGGLE_ALL_EDIT_LINEUP_SUBMIT', this.toggleSubmitAllLineups);
    EventBus.$on('BACK_TO_EDIT_LINEUPS_IMPORT', this.backToInstructions);

    this.getContestTimeRemaining();
    this.setCountdownInterval();
  },

  destroyed() {
    EventBus.$off('OPEN_SALARY_CAP_EDIT_LINEUP_MODAL', this.openModal);
    EventBus.$off('CLOSE_SALARY_CAP_EDIT_LINEUP_MODAL', this.closeModal);
    EventBus.$off('CSV_EDIT_LINEUPS_IMPORTED', this.lineupsImported);
    EventBus.$off('TOGGLE_EDIT_LINEUP_SUBMIT', this.toggleSubmitLineup);
    EventBus.$off('TOGGLE_ALL_EDIT_LINEUP_SUBMIT', this.toggleSubmitAllLineups);
    EventBus.$off('BACK_TO_EDIT_LINEUPS_IMPORT', this.backToInstructions);
  },

  computed: {
    entries() {
      if (!this.contest || !this.contest.myEntries) {
        return '';
      }

      return this.contest.myEntries;
    },

    disableSubmit() {
      return this.showInstructions || this.submitCount < 1;
    },
  },

  watch: {
    'contest.playersCurrent'(_to, from) {
      if (from !== undefined) {
        this.flashPlayerJoined(true);
      }
    },
  },

  methods: {
    openModal(contest) {
      this.isOpen = true;
      this.showInstructions = true;
      this.showError = false;
      this.contest = contest;
      this.getPlayers(contest.id);
      this.getContestTimeRemaining();
    },

    closeModal() {
      this.isOpen = false;
    },

    getPlayers(contestId) {
      ObSalaryCapApi.getContest(contestId, true)
          .then((response) => {
            this.playerList = response.players;
            this.createPlayerMap(this.playerList);
          });
    },

    lineupsImported(lineups, containsError) {
      this.importedLineups = lineups;
      this.showInstructions = false;
      this.submitCount = 0;
      this.showError = containsError;
    },

    toggleSubmitLineup(lineupId) {
      for (const lineupIndex in this.importedLineups) {
        const lineup = this.importedLineups[lineupIndex];
        if (lineup.teamId === lineupId && lineup.success) {
          lineup.submit = !lineup.submit;
          this.$set(this.importedLineups, lineupIndex, lineup);
          this.updateSubmitCount(lineup.submit);
          return;
        }
      }
    },

    toggleSubmitAllLineups(submit) {
      for (const lineupIndex in this.importedLineups) {
        const lineup = this.importedLineups[lineupIndex];
        if (lineup.success && lineup.submit != submit) {
          lineup.submit = submit;
          this.$set(this.importedLineups, lineupIndex, lineup);
          this.updateSubmitCount(submit);
        }
      }
    },

    editLineups() {
      const lineups = [];
      for (const lineup of this.importedLineups) {
        if (lineup.success && lineup.submit) {
          const lineupData = {
            teamId: lineup.teamId,
            contestId: lineup.contestId,
            row: lineup.row,
            players: lineup.players,
          };

          lineups.push(lineupData);
        }
      }

      const entryInfo = {
        lineups: lineups,
        contest: this.contest,
        playerMap: this.playerMap,
      };

      EventBus.$emit('OPEN_MULTIPLE_LINEUP_EDIT_MODAL', entryInfo);
    },

    createPlayerMap(playerList) {
      this.playerMap = {};
      for (const player of playerList) {
        this.$set(this.playerMap, player.id, player);
      }
    },

    setCountdownInterval() {
      clearInterval(this.contestCountdownInterval);
      const self = this;
      this.contestCountdownInterval = setInterval(() => {
        self.getContestTimeRemaining();
      }, 1000);
    },

    // Gets hour, minute and second difference between now and contest start
    // Displays it in a nice format, if contest has already started just show 00:00:00
    getContestTimeRemaining() {
      if (!this.contest || !this.contest.contestStart) {
        return '';
      }

      const now = this.$moment();
      const cDate = this.$moment(this.contest.contestStart);

      if (cDate.diff(now) < 0) {
        this.contestCountdownVal = '0:00:00';
        return;
      }

      const hours = String(cDate.diff(now, 'hours'));
      let minutes = String(cDate.diff(now, 'minutes') % 60);
      let seconds = String(cDate.diff(now, 'seconds') % 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      this.contestCountdownVal = hours + ':' + minutes + ':' + seconds;
    },

    flashPlayerJoined(change) {
      const self = this;
      this.playerJoined = change;
      setTimeout(() => {
        self.playerJoined = null;
      }, 4000);
    },

    backToInstructions() {
      this.showInstructions = true;
      this.showError = false;
    },

    getContestStartDate(startDate) {
      const sDate = this.$moment(startDate).format('dddd MMMM D,');
      const today = this.$moment().format('dddd MMMM D,');
      if (sDate === today) {
        return 'Today ' + this.$moment(startDate).format('h:mma');
      }
      return sDate + ' ' + this.$moment(startDate).format('h:mma');
    },

    updateSubmitCount(addSubmit) {
      if (addSubmit) {
        this.submitCount++;
      } else {
        this.submitCount--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .header {
    height: 86px;
    background: var(--obcolor-page-header);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .headerText {
    font-size: 18px;
    font-weight: bold;
    display: flex;
  }

  .descriptionText {
    font-size: 14px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
  }

  .headerField {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .contestTimeContainer {
    display: flex;
    flex-direction: column;
    width: 128px;
  }
  .contestTime {
    height: 18px;
  }

  .modalFooter {
    height: 81px;
    font-size: 14px;
    background: var(--obcolor-page-header);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  .footerField {
    display: flex;
    align-content: center;

    ::v-deep {
      .graphText {
        order: -1;
      }
    }
  }

  .submitError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px 0px 0px;
    gap: 5px;
  }

  .submitButton {
    width: 140px;
    font-size: 14px;
  }

  .error {
    font-size: 14px;
    color: var(--obcolor-red);
  }

  .entries {
    font-size: 16px;
    font-weight: bold;
    display: flex;
  }
</style>