<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 400px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 20px 20px;"
    :hideCloseButton="true" :disableClickaway="true"
  >
    <div slot="body" class="body">
      <div class="title">Lineup Submitted!</div>
      <template v-if="contest">
        <div v-if="atMaxEntries" class="paragraph">
          Your lineup has been submitted. You have entered the maximum number of lineups for this contest.
        </div>

        <div v-else class="paragraph">
          Your lineup has been submitted. To submit another lineup, it will cost you the
          <span class="contestText" :class="{ moneyText: contest.realMoney == true, blueText: contest.realMoney == false }">
            <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            {{ contest.fee.removeCentsIfZero() }}
          </span>
          entry fee.
        </div>
      </template>
    </div>

    <div slot="footer" class="footer" v-if="atMaxEntries">
      <button class="ob-btn-grey" style="width: 120px;" @click="goToLobby()">Lobby</button>
    </div>

    <div slot="footer" class="footer" v-else>
      <button class="ob-btn-grey" @click="newTeam()">Start Fresh</button>
      <button class="ob-btn-grey" @click="keepLineup()">Duplicate Lineup</button>
      <button class="ob-btn-grey" style="width: 120px;" @click="goToLobby()">Lobby</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isOpen: false,
      contest: null,
      atMaxEntries: false,
      promoInfo: {},
    };
  },

  created() {
    EventBus.$on('SALARY_OPEN_SUCCESS_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('SALARY_OPEN_SUCCESS_MODAL', this.openModal);
  },

  methods: {
    openModal(contest, promoInfo, atMaxEntries) {
      this.contest = contest;
      this.promoInfo = promoInfo;
      this.atMaxEntries = atMaxEntries;
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
      this.contest = null;
      this.atMaxEntries = false;
    },

    goToLobby() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER_COOKIE');
      this.$router.push('/lobby');
    },

    newTeam() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER');
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER_COOKIE');
      EventBus.$emit('SALARY_CAP_SET_PROMO_CODE', {code: '', state: 'NO_CODE'});
      this.closeModal();
    },

    keepLineup() {
      EventBus.$emit('SALARY_CAP_SET_PROMO_CODE', {code: '', state: 'NO_CODE'});
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .paragraph {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  .paragraph:not(:first-child) {
    margin-top: 15px;
  }
}

.footer {
  background: var(--obcolor-background-5);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 14px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.contestText {
  font-weight: bold;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.ownersBucksIcon {
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>