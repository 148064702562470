<template>
  <div v-if="showStartingGoalies">
    <h1 class="sectionHeader">Starting Goalies</h1>
    <div class="startingGoalies">
      <!-- away starting goalie -->
      <div class="startingGoalie">
        <PlayerHeadshot size="45" :id="awayStarter ? awayStarter.id : null" :sport="sport"
          :team="awayStarter ? awayStarter.team : null" :showLogo="true" :pos="awayStarter ? awayStarter.position : null"
        />
        <div v-if="awayStarter" class="startingGoalieStats">
          <div class="startingGoalieCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="awayStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(awayStarter.id)">{{ awayStarter.name.shortName() }}</div>
          </div>
          <p class="startingGoalieSubtext">{{ startingGoalieStats.away_starting_goalie }}</p>
        </div>
        <div v-else class="startingGoalieStats">
          <div style="margin-top: 14px;" class="startingGoalieCentered">
            TBD
          </div>
        </div>
      </div>
      <!-- home starting goalie -->
      <div class="startingGoalie">
        <div v-if="homeStarter" style="text-align: right;" class="startingGoalieStats">
          <div style="justify-content: right;" class="startingGoalieCentered">
            <span class="playerStatus">
              <PlayerStatus height="12" :status="homeStarter.gamestatus" />
            </span>
            <div class="ob-link" @click.stop="openPlayerCard(homeStarter.id)">{{ homeStarter.name.shortName() }}</div>
          </div>
          <p class="startingGoalieSubtext">{{ startingGoalieStats.home_starting_goalie }}</p>
        </div>
        <div v-else class="startingGoalieStats">
          <div style="margin-top: 14px;" class="startingGoalieCentered">
            TBD
          </div>
        </div>
        <PlayerHeadshot size="45" :id="homeStarter ? homeStarter.id : null" :sport="sport"
          :team="homeStarter ? homeStarter.team : null" :showLogo="true" :pos="homeStarter ? homeStarter.position : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';

export default {
  components: {
    PlayerHeadshot,
    PlayerStatus,
  },

  props: {
    gameData: {type: Object, default: null},
    sport: {type: String, default: ''},
  },

  computed: {
    startingGoalieStats() {
      const statResults = {away_starting_goalie: '', home_starting_goalie: ''};
      statResults.away_starting_goalie += this.awayGoalieStats?.goalie ? this.awayStartingGoalieStats() : 'No Stats Available';
      statResults.home_starting_goalie += this.homeGoalieStats?.goalie ? this.homeStartingGoalieStats() : 'No Stats Available';
      return statResults;
    },

    showStartingGoalies() {
      const liveMatchupData = this.gameData.matchup.live;
      return (!liveMatchupData || (liveMatchupData && liveMatchupData.status === 'pre-event'));
    },

    awayStarter() {
      return this.gameData?.matchup?.info?.away_starter;
    },

    homeStarter() {
      return this.gameData?.matchup?.info?.home_starter;
    },

    awayGoalieStats() {
      return this.awayStarter?.stats;
    },

    homeGoalieStats() {
      return this.homeStarter?.stats;
    },
  },

  methods: {
    openPlayerCard(pId) {
      EventBus.$emit('OPEN_PLAYER_CARD', pId, this.sport);
    },

    awayStartingGoalieStats() {
      return this.getGoalieStats(this.awayGoalieStats);
    },

    homeStartingGoalieStats() {
      return this.getGoalieStats(this.homeGoalieStats);
    },

    getGoalieStats(playerStats) {
      if (!playerStats) {
        return '';
      }

      const statsResult = [];
      const scoringStats = this.$ScoringStats.getPregameScoringStats(this.sport, 'G');
      for (const stat of scoringStats[0].stats) {
        if (stat.label === 'goalie.W_L_OTL' && playerStats?.goalie?.W && playerStats?.goalie?.L && playerStats?.goalie?.OTL) {
          statsResult.push(playerStats.goalie.W + '-' + playerStats.goalie.L + '-' + playerStats.goalie.OTL);
        } else if (this.$Helpers.parsePath(stat.label, playerStats) && this.$Helpers.parsePath(stat.label, playerStats) === '1') {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description.removePluralWords());
        } else if (parseFloat(this.$Helpers.parsePath(stat.label, playerStats)) !== 0) {
          statsResult.push(this.$Helpers.parsePath(stat.label, playerStats) + ' ' + stat.description);
        }
      }
      return statsResult.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.startingGoalies {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--obcolor-bg-hover);
}
.startingGoalie {
  display: flex;
}
.startingGoalieCentered {
  display: flex;
  align-items: center;
}
.startingGoalieStats {
  margin: 0 20px;
  max-width: 250px;
}
.startingGoalieSubtext {
  font-weight: initial;
  margin: 0;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  max-width: 300px;
}
</style>