<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 550px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);" :hideCloseButton="true"
  >
    <div slot="header" class="modalHeader">
      <div class="modalTitle">
        Edit Lineups
      </div>
    </div>
    <div slot="body" class="modalBody">
      <div v-if="errorMessage != ''" class="errorText">
        {{ errorMessage }}
      </div>
      <div v-else-if="confirmLineups">
        You are editing {{ lineups.length }} lineup(s) to {{ contest.contestName }} contest
      </div>
      <div v-else-if="submitting">
        Editing Lineup(s)...
        <ObLoading />
      </div>
      <div v-else>
        <div>
          Successfully edited {{ lineupsSubmitted.length }} lineup(s)
        </div>
        <div v-if="errorLineups.length > 0">
          <!-- Table of lineups with errors -->
          <table aria-labelledby="errorEditingLineups">
            <tr @click.stop="showErrors = !showErrors">
              <th id="errorEditingLineups" class="errorLineupsHeader">
                <i class="fas fa-exclamation-circle fa-lg errorIcon"></i>
                Error editing {{ errorLineups.length }} lineup(s)
              </th>
              <th class="iconColumn">
                <div>
                  <i v-if="!showErrors" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </div>
              </th>
            </tr>
            <template v-if="showErrors">
              <tr v-for="(errorLineup, index) in errorLineups" :key="index">
                <td>
                  <div class="lineupContainer">
                    <div v-for="(id, playerIndex) in errorLineup.playerIds" :key="playerIndex" class="player">
                      <div class="playerActive" @click.stop="openPlayerCard(id, sport, contestId)">
                        <span>{{ getPlayerName(id) }}</span>
                        <span v-if="playerIndex !== errorLineup.playerIds.length - 1">, </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="iconColumn">
                  <div v-tooltip.bottom="{content: errorLineup.error, class:'ObTooltip'}">
                    <i class="fas fa-exclamation-circle fa-lg errorIcon"></i>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <div v-if="!submitting" slot="footer" class="modalFooter">
      <!-- Submit Buttons -->
      <div class="btnList">
        <template v-if="errorMessage != ''">
          <button class="ob-btn" @click="closeModal()">Close</button>
        </template>
        <template v-else-if="confirmLineups">
          <button class="ob-btn-grey" @click="closeModal()">Cancel</button>
          <button class="ob-btn" @click="confirm()">Confirm</button>
        </template>
        <template v-else>
          <button class="ob-btn" @click="closeEditModal()">Close</button>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      lineups: [],
      resultLineups: [],
      contest: null,
      playerMap: null,
      confirmLineups: false,
      submitting: false,
      canSubmit: false,
      showErrors: false,
      errorMessage: '',
    };
  },

  created() {
    EventBus.$on('OPEN_MULTIPLE_LINEUP_EDIT_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_MULTIPLE_LINEUP_EDIT_MODAL', this.openModal);
  },

  computed: {

    lineupsSubmitted() {
      const lineups = [];
      for (const lineup of this.resultLineups) {
        if (lineup.success) {
          lineups.push(lineup);
        }
      }

      return lineups;
    },

    errorLineups() {
      const lineups = [];
      for (const lineup of this.resultLineups) {
        if (!lineup.success) {
          lineups.push(lineup);
        }
      }

      return lineups;
    },

    contestId() {
      if (!this.contest || !this.contest.id) {
        return 0;
      }
      return this.contest.id;
    },

    sport() {
      if (!this.contest || !this.contest.sport) {
        return '';
      }
      return this.contest.sport;
    },
  },

  methods: {
    openModal(entryInfo) {
      this.isOpen = true;

      if (!entryInfo) {
        return;
      }

      this.lineups = entryInfo.lineups;
      this.contest = entryInfo.contest;
      this.playerMap = entryInfo.playerMap;
      this.resetData();
    },

    closeModal() {
      this.isOpen = false;
    },

    closeEditModal() {
      EventBus.$emit('CLOSE_SALARY_CAP_EDIT_LINEUP_MODAL');
      this.closeModal();
    },

    resetData() {
      this.confirmLineups = true;
      this.canSubmit = true;
      this.submitting = false;
      this.showErrors = false;
      this.resultLineups = [];
      this.errorMessage = '';
    },

    getPlayerName(id) {
      if (!id) {
        return '';
      }

      const contestPlayer = this.playerMap[id];
      if (!contestPlayer) {
        return id;
      }

      return contestPlayer.name.shortName();
    },

    openPlayerCard(playerId, sport, contestId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId.toString(), sport, contestId, true);
    },

    confirm() {
      this.confirmLineups = false;
      this.submitting = true;

      ObSalaryCapApi.editLineups(this.contest.id, this.lineups, false)
          .then((response) => {
            this.submitting = false;
            this.resultLineups = response;
          })
          .catch((error) => {
            this.submitting = false;
            this.errorMessage = error.response.data;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  table {
    width: 506px;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }

  tr:not(:first-child) {
    border-top: 1px solid var(--obcolor-background-1);
  }

  th {
    font-size: 14px;
    line-height: 35px;
    padding: 3px 8px;
    border-top: 1px solid var(--obcolor-background-1);
    border-bottom: 1px solid var(--obcolor-background-1);
    color: var(--obcolor-text-primary);
  }

  td {
    padding: 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  .errorLineupsHeader {
    text-align: left;
  }

  .iconColumn{
    width: 40px;
  }

  .btnList {
    display: flex;
    justify-content: right;

    button:not(:last-child) {
      margin-right: 10px;
    }

    button {
      width: 100px;
      font-size: 14px;
    }
  }

  .errorText {
    color: var(--obcolor-red);
  }

  .errorIcon {
    color: red;
  }

  .player {
    font-weight: bold;
    font-size: 14px;

    margin-right: 7px;
  }

  .playerActive {
    color: var(--obcolor-text-primary);
  }

  .playerActive:hover {
    text-decoration: underline;
    text-decoration-color: var(--obcolor-text-primary);
    cursor: pointer;
  }

  .modalHeader {
    background: var(--obcolor-page-header);
  }

  .modalTitle {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    padding: 12px 20px;
  }

  .modalBody {
    max-height: 300px;
    padding: 20px;
  }

  .lineupContainer {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .modalFooter {
    background: var(--obcolor-background-5);
    padding: 10px 15px;
  }
</style>