<template>
  <div class="howToPlayContainer">
    <div class="howToPlayArrow"></div>
    <div class="howToPlayBox">
      <div class="howToPlayBody">
        <div class="headerText">Contest Details</div>
        <ul class="detailsList">
          <li v-if="isFromBuilder">
            You have the ability to select any entry fee from
            <span v-if="minEntryFee === null || maxEntryFee === null">a set range.</span>
            <span v-else>${{ minEntryFee }}-${{ maxEntryFee }}.</span>
            All entries you submit will be played against OwnersBox
          </li>
          <li>The prize pool amount is dependent on the entry fee and number of additional lineups added; with a multiplier being added to the initial entry fee</li>
          <li>Each lineup can contain any active player in all positions in each sport</li>
          <li>Each lineup can contain 2 or 3 players</li>
          <li>If you win the contest, you will receive the total prize pool, if you tie for first the entry fee is refunded</li>
          <li>
            If a player on an OwnersBox lineup is determined incapable of playing and that lineup loses, then it is removed from the contest and the payout schedule* is adjusted.
            <template v-if="this.contestPayout()">
              <p class="tableTitle">Payout Schedule:</p>
              <table aria-label="Payout Schedule" class="payoutSchedule">
                <tr>
                  <th scope="col">Number of Lineups</th>
                  <th scope="col">Payout</th>
                </tr>
                <tr v-for="(payout, idx, index) in contest.payouts" :key="index">
                  <td>
                    {{ idx }} Lineups
                    <span v-if="index == 0">*</span>
                  </td>
                  <td>{{ payout }}x Entry</td>
                </tr>
              </table>
              <p class="description">*only possible if a lineup gets cancelled</p>
            </template>
          </li>
          <li>If a player on a Participant’s lineup is determined incapable of playing, the contest will continue with a reduced roster to try to earn a payout and the Participant is refunded if their lineup loses</li>
          <li>If a game is cancelled that impacts any player in the contest, then the contest is cancelled and your entry fee is refunded</li>
          <li>The start time of the contest will occur when the first game starts for any player in your contest</li>
          <li>The end time of the contest is 30 min following the completion of the last game in the contest; this is when payouts occur</li>
          <li>By submitting an entry you are agreeing to the <router-link target="_blank" class="ob-hyperlink" to="/terms">OwnersBox Terms of Service</router-link> and Contest Rules</li>
        </ul>
      </div>
      <div class="howToPlayFooter">
        <div class="ob-btn closeBtn" @click="closePopup()">Close</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contest: {type: Object, default: null},
    isFromBuilder: {type: Boolean, default: true},
  },

  computed: {
    maxEntryFee() {
      if (!this.contest || this.contest.maxEntryFee == null) {
        return null;
      }

      return (this.contest.maxEntryFee / 100).toFixed(2).removeCentsIfZero();
    },

    minEntryFee() {
      if (!this.contest || this.contest.minEntryFee == null) {
        return null;
      }

      return (this.contest.minEntryFee / 100).toFixed(2).removeCentsIfZero();
    },
  },

  methods: {
    closePopup() {
      this.$emit('close');
    },

    contestPayout() {
      return this.contest && this.contest.payouts && Object.keys(this.contest.payouts).length !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.howToPlayContainer {
  position: relative;
  height: 0;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.payoutSchedule {
  width: 300px;
  margin: 0 auto;
}

td {
  max-width: 110px;
}

tr,
td,
th {
  border: 1px solid ;
  color: var(--obcolor-font-secondary);
  padding: 5px;
}

.tableTitle {
  width: 300px;
  margin: 10px auto 0 auto;
  padding: 5px 0;
}

.description {
  width: 300px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid;
  border-top: none;
  margin: 0 auto 15px auto;
}

.howToPlayArrow {
  position: absolute;
  top: 0;
  width: 1px;
  border-bottom: 8px solid var(--obcolor-background-6);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.howToPlayBox {
  position: absolute;
  box-sizing: border-box;
  top: 5px;
  z-index: 2;
  width: 400px;
  background: var(--obcolor-background-6);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.howToPlayBody {
  padding: 15px 15px;
}

.headerText {
  font-size: 14px;
  font-weight: bold;
}

.detailsList {
  margin: 0;
  padding-left: 20px;
  font-size: 12px;
  color: var(--obcolor-font-secondary);

  li {
    margin-top: 6px;
  }
}

.howToPlayFooter {
  padding: 15px 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background: var(--obcolor-background-4);

  .closeBtn {
    width: 100%;
    height: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>