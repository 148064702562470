<template>
  <div class="itemList">
    <div class="selectContainer" v-for="(privacy, index) in privacyOptions"
      :class="{selected: selected !== null && selected.value === privacy.value}"
      @click="selectPrivacy(privacy)" :key="index"
    >
      <div class="title">
        <div class="privacyIcon"><i class="fas" :class="privacy.value ? 'fa-user' : 'fa-users'"></i></div>
        {{ privacy.label }}
      </div>
      <div class="description">{{ privacy.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    privacyOptions: {type: Array, default: null},
    selected: {type: Object, default: null},
  },

  methods: {
    selectPrivacy(privacy) {
      this.$emit('select', privacy);
      this.$emit('nextStep');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.selectContainer {
  width: 320px;
  min-height: 60px;
}

.privacyIcon {
  font-size: 14px;
  margin-right: 5px;
  color: var(--obcolor-font-secondary);
}
</style>