<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 600px; min-height: 400px; max-height: 600px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-6); overflow: unset !important;"
  >
    <div slot="header" class="modalHeader">
      <div>Recent Notifications</div>
    </div>

    <div slot="body" class="modalBody ob-scroll" style="overflow: auto;">
      <template v-for="(entry, index) in notifications">
        <SportNotification v-if="entry.sport != null"
          :key="index" @click="onEntryClick(entry)"
          :entry="entry"
        />
        <GenericNotification v-else-if="entry.type == 'DEPOSIT_COMPLETE'" :key="index"
          :entry="entry" iconName="dollar-deposit" @click="onEntryClick(entry)"
        />
        <GenericNotification v-else-if="entry.type == 'WITHDRAWAL_COMPLETE'" :key="index"
          :entry="entry" iconName="dollar-withdraw" @click="onEntryClick(entry)"
        />
        <GenericNotification v-else-if="entry.type == 'REFERRAL_SIGNUP'" :key="index"
          :entry="entry" iconName="dollar-ownersbox-lg" @click="onEntryClick(entry)"
        />
        <GenericNotification v-else-if="entry.type == 'REFERRAL_BONUS'" :key="index"
          :entry="entry" iconName="dollar-ownersbox-lg" @click="onEntryClick(entry)"
        />
      </template>

      <div v-if="notifications.length == 0" style="text-align: center; padding: 10px; color: var(--obcolor-text-secondary);">
        You have no recent notifications
      </div>
    </div>

    <div slot="footer" class="modalFooter">
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import ObNotificationApi from '@/api/ObNotificationApi';
import SportNotification from '@/components/notifications/SportNotification';
import GenericNotification from '@/components/notifications/GenericNotification';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    SportNotification,
    GenericNotification,
  },

  data() {
    return {
      isOpen: false,
      notifications: [],
      socketRoom: null,
    };
  },

  computed: {
    ...mapState(['notificationCount', 'userId', 'socialContent']),
  },

  created() {
    EventBus.$on('SYSTEM_NOTIFICATIONS_OPEN', this.openModal);
    this.loadData();
    this.startSocketRoom();
  },

  watch: {
    userId(newVal, oldVal) {
      this.loadData();
      this.startSocketRoom();
    },
  },

  destroyed() {
    EventBus.$off('SYSTEM_NOTIFICATIONS_OPEN', this.openModal);
  },

  methods: {
    loadData() {
      // Don't try to load if user is not logged in
      if (!this.userId) {
        return [];
      }

      ObNotificationApi.getNotifications()
          .then((response) => {
            this.notifications = response.notifications.sort(function(a, b) {
              if (a.date > b.date) {
                return -1;
              } else if (b.date > a.date) {
                return 1;
              }
              return 0;
            });
            this.$store.commit('setNotificationCount', response.unread);
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    getGroup(groupId) {
      return this.socialContent.userGroupList.find((group) => group.conversationId == groupId);
    },

    sortNotifications() {
      this.notifications = this.notifications.sort(function(a, b) {
        if (a.date > b.date) {
          return -1;
        } else if (b.date > a.date) {
          return 1;
        }
        return 0;
      });
    },

    startSocketRoom() {
      this.stopSocketRoom();
      if (this.userId) {
        this.socketRoom = 'NOTIFICATIONS_' + this.userId;
        this.$SocketController.startRoom(this.socketRoom, this.onSocketBroadcast);
      }
    },

    stopSocketRoom() {
      if (this.socketRoom) {
        this.$SocketController.stopRoom(this.socketRoom, this.onSocketBroadcast);
      }
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'FSP_SYSTEM_NOTIFICATION' && data.json) {
        // Add item, or update if already exists, resort
        const newEntry = data.json;

        const itemIndex = this.notifications.findIndex(((entry) => entry.id === newEntry.id));

        if (itemIndex != -1) {
          // Update existing entry
          this.notifications.splice(itemIndex, 1, newEntry);
        } else {
          this.notifications.push(newEntry);
          this.sortNotifications();

          // If this is a draft starting notification, launch the modal
          if (newEntry.type == 'DRAFT_STARTING') {
            // TODO - don't pop up if we're already in this draft
            EventBus.$emit('DRAFT_MODAL_OPEN', newEntry);
          }

          if (this.isOpen) {
            // If the modal is open, mark as read
            this.clearUnreadNotifications();
          } else {
            // If modal is not open, update our count
            this.$store.commit('setNotificationCount', this.notificationCount + 1);
          }
        }
      }
    },

    onEntryClick(entry) {
      switch (entry.type) {
        case 'SALARYCAP_GAME_ADDED':
        case 'SALARYCAP_STARTING_SOON':
        case 'SALARYCAP_EXTRA_SALARY':
        case 'SALARYCAP_EXTRA_GAMEOPPS':
        case 'SALARYCAP_WINNER':
          const tid = this.userId + '_1';
          this.$router.push('/salarycontest/leaderboard/' + entry.leagueId + '/' + tid);
          this.closeModal();
          return;

        case 'CONTEST_INVITE':
          this.$router.push('/dashboard/invited/' + entry.leagueId);
          this.closeModal();
          return;

        case 'DEPOSIT_COMPLETE': case 'CONTEST_CANCELLED': case 'CONTEST_CUTOFF': case 'CONTEST_DISBAND':
          this.$router.push('/lobby');
          this.closeModal();
          return;

        case 'WITHDRAWAL_COMPLETE':
          this.$router.push('/transactions');
          this.closeModal();
          return;

        case 'REFERRAL_SIGNUP': case 'REFERRAL_BONUS':
          this.$router.push('/referfriend');
          this.closeModal();
          return;

        case 'SOCIAL_GROUP_ADD':
          this.$router.push('/groups/' + entry.socialGroupId);
          this.closeModal();
          return;

        case 'LL_LINEUP_REMOVED':
          this.$router.push('/lightning-lineups/live/' + entry.leagueId);
          this.closeModal();
          return;
      }

      if (entry.draftId) {
        this.$router.push('/draft/' + entry.draftId);
        this.closeModal();
      } else if (entry.leagueId) {
        this.$router.push('/contest/matchup/summary/' + entry.leagueId);
        this.closeModal();
      }
    },

    clearUnreadNotifications() {
      this.$store.commit('setNotificationCount', 0);
      ObNotificationApi.clearUnread()
          .then((_response) => {
            // Do nothing
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    openModal() {
      this.isOpen = true;
      if (this.notificationCount > 0) {
        this.clearUnreadNotifications();
      }
    },

    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  background: var(--obcolor-box-header);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}
.modalBody {
  flex: 1;
  padding: 0px;
  box-sizing: border-box;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.modalFooter {
  font-size: 13px;
  background: var(--obcolor-background-3);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.notificationEntry {
  display: flex;
  align-items: center;
  background: var(--obcolor-background-3);
  padding: 5px;
  padding-right: 20px;
  font-size: 15px;
  cursor: pointer;
}
.notificationEntry:hover {
  background: var(--obcolor-background-2);
}

.liveBtn {
  display: flex;
  align-items: center;
  color: white;
  background: red;
  padding: 2px 6px;
  border-radius: 5px;
  height: 15px;
  font-size: 12px;
  margin-left: 10px;
}
</style>