// Live Summary Stats
// Controls the top overall summary for live page

export const LIVE_MLB_BATTER = [
  {
    label: 'BATTING',
    stats: [
      {label: 'AB', path: 'batter.AB', tooltip: 'At Bats'},
      {label: '1B', path: 'batter.1B', tooltip: 'Single', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: '2B', path: 'batter.2B', tooltip: 'Double', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: '3B', path: 'batter.3B', tooltip: 'Triple', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'HR', path: 'batter.HR', tooltip: 'Home Runs', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'R', path: 'batter.R', tooltip: 'Runs', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'RBI', path: 'batter.RBI', tooltip: 'Runs Batted In', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'K', path: 'batter.K', tooltip: 'Strikeout', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
      {label: 'BB', path: 'batter.BB', tooltip: 'Walks', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'HBP', path: 'batter.HBP', tooltip: 'Hit By Pitch', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'SB', path: 'batter.SB', tooltip: 'Stolen Bases', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'CS', path: 'batter.CS', tooltip: 'Caught Stealing', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
      {label: 'PK', path: 'batter.PK', tooltip: 'Picked Off', thresholds: [
        {value: 0, heat: 0}, {value: 1, heat: -1}, {value: 2, heat: -2}, {value: 3, heat: -3},
      ]},
    ],
  },
];

export const LIVE_MLB_PITCHER = [
  {
    label: 'PITCHING',
    stats: [
      {label: 'IP', path: 'pitcher.IP', tooltip: 'Innings Pitched', thresholds: [
        {value: 7, heat: 3}, {value: 6, heat: 2}, {value: 5, heat: 1}, {value: 4, heat: 0},
        {value: 3, heat: -1}, {value: 2, heat: -2}, {value: 1, heat: -3},
      ]},
      {label: 'W', path: 'pitcher.W', tooltip: 'Wins', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'K', path: 'pitcher.K', tooltip: 'Strikeouts', thresholds: [
        {value: 7, heat: 3}, {value: 6, heat: 2}, {value: 5, heat: 1}, {value: 4, heat: 0},
        {value: 3, heat: -1}, {value: 2, heat: -2}, {value: 1, heat: -3},
      ]},
      {label: 'SO', path: 'pitcher.SO', tooltip: 'Shut Outs', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'BB', path: 'pitcher.BB_U', tooltip: 'Walks', thresholds: [
        {value: 0, heat: 2}, {value: 1, heat: 1}, {value: 2, heat: 0}, {value: 3, heat: -1},
        {value: 4, heat: -2}, {value: 5, heat: -3},
      ]},
      {label: 'HBP', path: 'pitcher.HBP', tooltip: 'Batters Hit By Pitch', thresholds: [
        {value: 3, heat: -3}, {value: 2, heat: -2}, {value: 1, heat: -1}, {value: 0, heat: 0},
      ]},
      {label: 'H', path: 'pitcher.H', tooltip: 'Hits Allowed', thresholds: [
        {value: 1, heat: 3}, {value: 2, heat: 2}, {value: 3, heat: 1}, {value: 4, heat: 0},
        {value: 5, heat: -1}, {value: 6, heat: -2}, {value: 7, heat: -3},
      ]},
      {label: 'ER', path: 'pitcher.ER', tooltip: 'Earned Runs', thresholds: [
        {value: 0, heat: 3}, {value: 1, heat: 2}, {value: 2, heat: 1}, {value: 3, heat: 0},
        {value: 4, heat: -1}, {value: 5, heat: -2}, {value: 6, heat: -3},
      ]},
      {label: 'SV', path: 'pitcher.SV', tooltip: 'Saves', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
    ],
  },
];
