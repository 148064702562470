<template>
  <div class="itemList">
    <div class="selectContainer" v-for="(sport, index) in allSports"
      :class="{selected: selected === sport, disabled: !activeSports[sport]}"
      @click="selectSport(sport)" :key="index"
    >
      <img :src="require('@/assets/contestbadge/' + sport + '.png')" :alt="sport">
      <div class="title">{{ sport }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sports: {type: Array, default: null},
    selected: {type: String, default: null},
  },

  computed: {
    allSports() {
      return ['NFL', 'MLB', 'NBA', 'NHL'];
    },

    activeSports() {
      const activeObject = {};
      for (const sport of this.allSports) {
        this.$set(activeObject, sport, false);
      }

      for (const sport of this.sports) {
        this.$set(activeObject, sport, true);
      }

      return activeObject;
    },
  },

  methods: {
    selectSport(sport) {
      if (this.activeSports[sport]) {
        this.$emit('select', sport);
        this.$emit('nextStep');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.selectContainer {
  height: 140px;
  width: 140px;
  align-items: center;
  justify-content: space-between;

  img {
    height: 100px;
  }
}
</style>