<template>
  <div class="liveContestHeader">
    <div v-if="!hideLabel" class="headerText">
      <div>
        <div class="contestState">{{ contestState }}</div>
        <div class="gameTypeLabel">{{ label }}</div>
      </div>
      <div class="swapBtnContainer">
        <div v-if="showGlobalSwap" class="ob-btn-grey swapBtn" @click="openGlobalSwapModal">
          <i class="fas fa-exchange"></i>Global Swap
        </div>
        <div v-if="showQuickSwap" class="ob-btn-grey swapBtn" @click="openQuickSwapModal">
          <i class="fas fa-exchange"></i>Quick Swap
        </div>
      </div>
      <div style="flex: 1;"></div>
      <div v-if="contestState == 'live'">
        Live Scoring
        <Tooltip pos="bottom" textKey="Dashboard_Live_Scoring" />
      </div>
      <div v-if="contestState == 'live'">
        <div class="liveScoringValue">{{ contestTotals.entries }}</div>
        <div class="liveScoringLabel">Entries</div>
      </div>
      <div v-if="contestState == 'live'">
        <div class="liveScoringValue">{{ contestTotals.placing }}</div>
        <div class="liveScoringLabel">Placing</div>
      </div>
      <div v-if="contestState == 'live'">
        <div class="liveScoringValue">{{ contestTotals.entryFees }}</div>
        <div class="liveScoringLabel">Entry Fees</div>
      </div>
      <div v-if="contestState == 'live'">
        <div class="liveScoringValue realMoney">
          <img class="moneyIcon" :src="require('@/assets/icons/moneybag.png')" alt="Real Money Winnings">
          <span>{{ contestTotals.winnings }}</span>
          <template v-if="contestTotals.tickets > 0">
            <span style="margin-left: 2px; margin-right: 2px;">+</span>
            <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
              v-tooltip.bottom="getTicketPrizeTooltip()"
            >
          </template>
        </div>
        <div class="liveScoringLabel">Winning</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';

export default {

  components: {
    Tooltip,
  },

  props: {
    hideLabel: {type: Boolean, default: false},
    showGlobalSwap: {type: Boolean, default: true},
    showQuickSwap: {type: Boolean, default: true},
    label: {type: String, default: 'Live Contests'},
    contests: {type: Array, default: null},
    contestState: {type: String, default: null},
  },

  computed: {
    contestTotals() {
      if (!this.contests) {
        return 0;
      }
      const now = new Date().getTime();
      let entries = 0;
      let placing = 0;
      let entryFees = 0;
      let winnings = 0;
      let tickets = 0;
      for (const contest of this.contests) {
        // Only include real money contests that have started
        if (!contest.realMoney || contest.contestStart > now) {
          continue;
        }
        if (contest.ctype === 'Tournament') {
          entries += contest.myEntries;
          entryFees += contest.myEntries * contest.feeValue;
          const entryData = this.getSalaryContestEntryData(contest);
          placing += entryData.placing;
          winnings += entryData.winnings;
          tickets += entryData.tickets;
        } else if (contest.ctype === 'LIGHTNING_LINEUPS') {
          entries += 1;
          placing += contest.userTeam.rank == 1 ? 1 : 0;
          entryFees += contest.feeValue;
          winnings += contest.userWinnings;
        }
      }
      const totals = {};
      totals.entries = entries;
      totals.placing = placing;
      totals.entryFees = '$' + this.formatCurrency(entryFees);
      totals.winnings = '$' + this.formatCurrency(winnings);
      totals.tickets = tickets;
      return totals;
    },
  },

  methods: {
    getSalaryContestEntryData(contest) {
      let winnings = 0;
      let placing = 0;
      let numTickets = 0;
      for (const entry of contest.leaderboard) {
        winnings += entry.prizeValue;
        placing += entry.rank <= contest.numPayouts;

        if (entry.ticketPrize || entry.splitTicketPrize) {
          numTickets += 1;
        }
      }
      return {winnings: winnings, placing: placing, tickets: numTickets};
    },

    formatCurrency(cents) {
      const dollars = cents.centsToDollars();
      return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(dollars).removeCentsIfZero();
    },

    openGlobalSwapModal() {
      EventBus.$emit('OPEN_GLOBAL_SWAP_MODAL');
    },

    openQuickSwapModal() {
      EventBus.$emit('OPEN_QUICK_SWAP_MODAL');
    },

    getTicketPrizeTooltip() {
      const numTickets = this.contestTotals.tickets;
      const tooltip = {};
      tooltip.content = numTickets + ' Ticket' + (numTickets > 1 ? 's' : '');
      tooltip.class = 'ObTooltip';
      return tooltip;
    },
  },
};
</script>

<style lang="scss" scoped>
.liveContestHeader {
  display: flex;
  flex-direction: column;
  background: var(--obcolor-background-6);
  margin-bottom: 4px;
  box-sizing: border-box;
  border-radius: 5px 5px 0 0;
  overflow: hidden
}

.contestState {
  text-transform: capitalize;
}

.headerText {
  padding: 13px 15px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 25px;
}

.gameTypeLabel {
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: -2px;
}

.swapBtnContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.swapBtn {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;

  .fas {
    margin-right: 5px;
  }
}

.liveScoringValue {
  font-size: 14px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.liveScoringLabel {
  font-size: 12px;
  color: var(--obcolor-font-secondary);
  text-align: right;
}

.realMoney {
  color: var(--obcolor-green);
}

.moneyIcon {
  height: 19px;
  margin-top: -3px;
  margin-right: 3px;
}

.ticketIcon {
  height: 10px;
  margin-bottom: 2px;
}
</style>
