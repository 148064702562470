<template>
  <div class="fillingContainer">
    <ContestFillGraph style="max-width: 100%; min-width: 50%;"
      :playersCurrent="league.playersCurrent"
      :playersMax="league.playersMax"
      :format="league.ctype == 'Tournament' ? 'Tournament' : 'H2H'"
      :playerJoined="playerJoined"
    />
    <div style="margin-top: 4px;">
      <span class="startTitle" style="margin-right: 8px;">Contest Start</span>
      <span class="startText">{{ $moment(league.contestStart).format('ddd MMM D @ h:mma') }}</span>
    </div>
  </div>
</template>

<script>
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';

export default {
  components: {
    ContestFillGraph,
  },

  props: {
    league: Object,
  },

  watch: {
    'league.playersCurrent'() {
      this.flashPlayerJoined(true);
    },
  },

  data() {
    return {
      playerJoined: null,
    };
  },

  methods: {
    flashPlayerJoined(change) {
      const self = this;
      this.playerJoined = change;
      setTimeout(() => {
        self.playerJoined = null;
      }, 4000);
    },
  },
};
</script>

<style lang="scss" scoped>
.fillingContainer {
  position: absolute;
  left: 0;
  right: 219px;
  top: 0;
  background: var(--obcolor-background-5);
  height: 100%;
  z-index: 60;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startTitle {
  font-weight: bold;
  font-size: 14px;
}

.startText {
  font-size: 12px;
}
</style>
