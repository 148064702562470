<template>
  <div>
    <div v-if="!rouletteCompleted" class="scene">
      <div class="carouselContainer" :style="carouselStyle.translateZ ? 'transform: ' + carouselStyle.translateZ: ''">
        <div class="carousel" :class="isSpinning ? 'spinning' : 'stopped'" ref="carousel"
          :style="(carouselStyle.rotateX ? 'transform: ' + carouselStyle.rotateX : '')
            + '; transition-timing-function: cubic-bezier(' + getRandomCubicBezier() + ');'"
        >
          <div class="carouselCell userInfoBox" v-for="(userId, index) in carouselUsers" :key="index"
            :style="cellData && cellData[index] ? 'opacity: ' + cellData[index].opacity
              + '; transform: ' + cellData[index].transform : ''"
          >
            <div style="margin-right: 10px;">
              <div class="userInfoName" style="font-weight: bold;">{{ users[userId].team }}</div>
              <div class="userInfoName" style="color: var(--obcolor-font-secondary); font-size: 14px;">{{ users[userId].name }} ({{ users[userId].h2hRecord }})</div>
            </div>
            <UserLogo style="margin-right: 5px;" :id="userId" :sport="draftJSON.sport"
              :rankColor="users[userId].rankColor"
              :rankNum="users[userId].rankNum" :showRank="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="userInfoBox">
      <div style="margin-right: 10px;">
        <div class="userInfoName" style="font-weight: bold;">{{ users[oppUser].team }}</div>
        <div class="userInfoName" style="color: var(--obcolor-font-secondary); font-size: 14px;">{{ users[oppUser].name }} ({{ users[oppUser].h2hRecord }})</div>
      </div>
      <UserLogo style="margin-right: 5px;" :id="oppUser" :sport="draftJSON.sport"
        :rankColor="users[oppUser].rankColor"
        :rankNum="users[oppUser].rankNum" :showRank="true"
      />
    </div>
  </div>
</template>

<script>
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';

export default {
  components: {
    UserLogo,
  },
  props: {
    draftJSON: Object,
    users: Object,
    curUser: String,
    oppUser: String,
    rouletteCompleted: Boolean,
  },
  data() {
    return {
      selectedCell: 0,
      cellHeight: '',
      carouselStyle: {},
      // Angle on carousel for the user's opponent
      theta: null,
      radius: null,
      cellData: null,
      isSpinning: false,

      spinTimeout: null,
    };
  },
  computed: {
    otherUsers() {
      // If there is only the current user and their opponent, put in their opponent twice since the roulette needs at least two people.
      if (this.draftJSON.users.length == 2) {
        return this.draftJSON.users.filter((user) => user != this.curUser).concat(this.draftJSON.users.filter((user) => user != this.curUser));
      }
      return this.draftJSON.users.filter((user) => user != this.curUser);
    },
    carouselUsers() {
      const userArray = [];
      for (let i = 0; i < 15; ++i) {
        userArray.push(this.otherUsers[i % this.otherUsers.length]);
      }
      // Don't show two of the same user in a row if we aren't working with a length divisible by 15 (Length of the carousel)
      // Instead, pick the middle user in the list and put another one at the end to even things up
      if (userArray[userArray.length - 1] == userArray[0]) {
        userArray[userArray.length - 1] = this.otherUsers[Math.ceil(this.otherUsers.length / 2)];
      }
      return userArray;
    },
    cellCount() {
      return this.carouselUsers.length;
    },
  },
  mounted() {
    if (!this.rouletteCompleted) {
      // Set up the wheel, and then select the users matchup and spin the wheel.
      this.initCarousel();
      this.spinWheel();
      this.spinTimeout = setTimeout(() => {
        EventBus.$emit('ROULETTE_COMPLETED');
      }, 5500);
    }
  },
  destroyed() {
    clearTimeout(this.spinTimeout);
  },
  methods: {
    findUserIndexById(userId) {
      for (let i = 0; i < this.otherUsers.length; ++i) {
        if (this.otherUsers[i] == userId) {
          return i;
        }
      }
      return -1;
    },
    spinWheel() {
      this.selectedCell = this.findUserIndexById(this.oppUser);
      // Spin the wheel 3 times, and end on the user's opponent.
      this.selectedCell = this.selectedCell + this.carouselUsers.length * 3;
      this.isSpinning = true;
      this.rotateCarousel();
    },
    initCarousel() {
      const tempCellData = [];
      this.cellHeight = this.$refs.carousel.offsetHeight;
      this.theta = 360 / this.cellCount;

      // Knowing the height of the div and number of options, calculate the radius of the carousel.
      this.radius = Math.round( ( this.cellHeight / 2) / Math.tan( Math.PI / this.cellCount ) );
      for ( let i=0; i < this.carouselUsers.length; i++ ) {
        tempCellData[i] = {};
        if ( i < this.cellCount ) {
          // If we haven't reached the max number of cells for the carousel's radius, we can show this cell.
          tempCellData[i].opacity = 1;
          // Get the angle that the selected user is at, and rotate/move its div to fit on the carousel.
          const cellAngle = this.theta * i;
          tempCellData[i].transform = 'rotateX(' + cellAngle + 'deg) translateZ(' + this.radius + 'px)';
        } else {
          // Don't show this cell if the carousel is already full.
          tempCellData[i].opacity = 0;
          tempCellData[i].transform = 'none';
        }
      }

      this.cellData = tempCellData;

      this.rotateCarousel();
    },
    rotateCarousel() {
      // Get the angle at which the selected user is on the carousel
      // Multiply by -1 to invert, which negates the reversed order done in the CSS
      const angle = this.theta * this.selectedCell * -1;
      // Move the carousel back equal to its radius, making the front "face" always appear on the same z-axis, no matter the size of the wheel
      this.carouselStyle.translateZ = 'translateZ(' + -this.radius + 'px) ';
      // Rotate the carousel to the selected user.
      this.carouselStyle.rotateX = 'rotateX(' + angle + 'deg)';
    },
    getRandomCubicBezier() {
      const rVal1X = ((Math.ceil(Math.random() * 10) + 5)/100).toFixed(2);
      const rVal1Y = ((Math.ceil(Math.random() * 30) + 80)/100).toFixed(2);
      const rVal2X = ((Math.ceil(Math.random() * 30) + 15)/100).toFixed(2);
      const rVal2Y = ((Math.ceil(Math.random() * 15) + 87)/100).toFixed(2);

      return rVal1X + ',' + rVal1Y + ',' + rVal2X + ',' + rVal2Y;
    },
  },
};
</script>

<style lang="scss" scoped>
.scene {
  width: 248px;
  height: 70px;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
}

.carouselContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
}
.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition-duration: 5s;

  // Slow bezier
  // transition-timing-function: cubic-bezier(.25,1,.27,.93);
  // Middle-length bezier
  // transition-timing-function: cubic-bezier(.29,.87,.24,.98);
  // Forward and back bezier
  // transition-timing-function: cubic-bezier(.27,1.27,.63,.97);
}

.carouselCell {
  position: absolute;
}
.userInfoBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background: var(--obcolor-background-2);
  border: 1px solid var(--obcolor-text-faded);
  width: 248px;
  height: 70px;
}
.userInfoName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>