<template>
  <div>
    <template v-if="hasScoutReport()">
      <div class="componentTitle">SCOUTING REPORT</div>
      <div class="scoutingReportEntry">
        <div class="scoutingReportCategory">
          <div class="scoutingReportIcon">
            <i class="fas fa-binoculars" style="color: var(--obcolor-font-secondary)"></i>
          </div>
          {{ playerDetails.scoutReport.potential }}
        </div>
        <div class="scoutingReportCategory">
          <div class="scoutingReportIcon" style="color: #33EAC0">
            <i class="fa fa-plus"></i>
          </div>
          {{ playerDetails.scoutReport.assets }}
        </div>
        <div class="scoutingReportCategory">
          <div class="scoutingReportIcon" style="color: #ED1F5D">
            <i class="fa fa-minus"></i>
          </div>
          {{ playerDetails.scoutReport.flaws }}
        </div>
      </div>
    </template>
    <div class="componentTitle">PLAYER INFORMATION</div>
    <div class="playerInfo">
      <div class="playerStat">
        <div class="statTitle">AGE</div>
        <div class="statValue">{{ playerDetails.bio.age }}</div>
      </div>
      <div class="playerStat">
        <div class="statTitle">HEIGHT</div>
        <div class="statValue">{{ playerDetails.bio.height }}</div>
      </div>
      <div class="playerStat">
        <div class="statTitle">WEIGHT</div>
        <div class="statValue">{{ playerDetails.bio.weight }}lb</div>
      </div>
      <div class="playerStat">
        <div class="statTitle">EXPERIENCE</div>
        <div class="statValue" v-if="playerDetails.bio.extra_info">
          {{ playerDetails.bio.extra_info.experience }} Years
        </div>
        <div class="statValue" v-else>-</div>
      </div>
      <div class="playerStat">
        <div class="statTitle">DRAFTED</div>
        <div class="statValue" v-if="playerDetails.bio.extra_info">
          {{ playerDetails.bio.extra_info.draft_info }}
        </div>
        <div class="statValue" v-else>-</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playerDetails: Object,
    sport: String,
  },

  methods: {
    hasScoutReport() {
      const sReport = this.playerDetails.scoutReport;
      return sReport != null && !(sReport.potential == '' && sReport.assets == '' && sReport.flaws == '');
    },
  },
};
</script>

<style lang="scss" scoped>
.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 12px 15px;
  background: var(--obcolor-background-2);
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
}

.scoutingReportEntry {
  padding: 10px 40px 10px 10px;
  font-size: 14px;
}

.scoutingReportCategory {
  display: flex;
  padding: 15px 0;
}

.scoutingReportIcon i {
  font-size: 24px;
  text-align: center;
  width: 70px;
  margin-right: 10px;
}

.playerInfo {
  display: flex;
  justify-content: space-around;
  padding: 18px 25px;
}

.playerStat {
  font-size: 14px;
  padding: 2px;
}

.statTitle {
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.statValue {
  padding-top: 5px;
  font-size: 14px;
}
</style>