<template>
  <Modal v-if="isOpen" v-model="isOpen" :disableClickaway="true"
    :containerStyle="swapStep > 0 ? containerStyleLarge : containerStyleSmall"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-page); overflow: auto !important;"
  >
    <div slot="header" class="modalHeader">
      Global Swap <span v-if="swapData && swapData.gameSlateName"> - {{ swapData.gameSlateName }}</span>
    </div>

    <GlobalSwapUploadDownload slot="body" class="modalBody" v-if="swapStep == 0" />
    <GlobalSwapContests slot="body" v-if="swapStep == 1"
      :loadProgress="swapLoadProgress" :loadMessage="swapLoadMessage" :swapsSubmitted="swapsSubmitted"
      :swapData="swapData" :selectedLineupsByContest="selectedLineupsByContest"
      @selectAllSwaps="selectAllSwaps" @selectAllSwapsByContest="selectAllSwapsByContest"
      @toggleSwap="toggleSwap"
    />
    <div slot="footer" class="modalFooter">
      <div>
        <div class="errorMessage" v-if="errorMessage"><i class="fas fa-exclamation-circle fa-lg errorIcon"></i>{{ errorMessage }}</div>
      </div>
      <div>
        <template v-if="swapStep == 0">
          <div class="controlBtn ob-btn-grey" v-if="csvSending" disabled>Validate Swaps <ObLoading class="loadingCsv" :size="35" /></div>
          <div class="controlBtn ob-btn" v-else-if="canSubmitCsv" @click="submitCsv">Validate Swaps</div>
          <div class="controlBtn ob-btn-grey" v-else disabled>Validate Swaps</div>
        </template>
        <template v-else-if="swapStep == 1 && swapsSubmitted">
          <div class="controlBtn ob-btn" v-if="swapData" @click="closeModal">Close</div>
          <div class="controlBtn ob-btn-grey" v-else disabled>Close</div>
        </template>
        <template v-else>
          <div v-if="canSubmitSwaps" class="controlBtn ob-btn" @click="submitSwaps">Submit Swaps</div>
          <div v-else class="controlBtn ob-btn-grey" disabled>Submit Swaps</div>
        </template>
      </div>

      <!-- Footer is always present while modal is open, so we will keep the modals here -->
      <ConfirmationModal :title="'Confirm Global Swap'"
        :text="'You are editing ' + numSelectedSwaps + ' lineup' + (numSelectedSwaps != 1 ? 's' : '') + '. Would you like to continue?'"
        :eventBusName="'OPEN_GLOBAL_SWAP_CONFIRMATION_MODAL'" @confirm="confirmSubmitSwaps()"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ConfirmationModal from '@/components/ConfirmationModal';
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import GlobalSwapUploadDownload from './GlobalSwapUploadDownload';
import GlobalSwapContests from './GlobalSwapContests';
import ObLoading from '@/components/ObLoading';

export default {
  components: {
    Modal,
    ConfirmationModal,
    GlobalSwapUploadDownload,
    GlobalSwapContests,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      loading: false,

      csvFile: null,
      csvSending: false,
      swapId: null,
      swapData: null,
      swapStep: 0,
      swapLoadDelay: 5000,
      swapLoadProgress: 0,
      swapsSubmitted: false,

      // Modal size is increased once csv is uploaded, to make it easier to view many lineups
      containerStyleSmall: 'width: 800px; height: 650px; padding: 0; overflow: hidden;',
      containerStyleLarge: 'width: 1350px; height: 950px; padding: 0; overflow: hidden;',

      // Object that gets filled with lineups by contest id
      selectedLineupsByContest: {},
      errorMessage: null,
    };
  },

  watch: {
    isOpen(newVal) {
      // Reset the data when modal is closed
      // Data is also reset when opening the modal, but that occurs before the isOpen value is set
      if (!newVal) {
        this.resetData();
      }
    },
  },

  created() {
    EventBus.$on('OPEN_GLOBAL_SWAP_MODAL', this.openModal);
    EventBus.$on('GLOBAL_SWAP_CSV_INPUTTED', this.setCsvFile);
    EventBus.$on('GLOBAL_SWAP_PREV_STEP', this.prevStep);
  },

  destroyed() {
    EventBus.$off('OPEN_GLOBAL_SWAP_MODAL', this.openModal);
    EventBus.$off('GLOBAL_SWAP_CSV_INPUTTED', this.setCsvFile);
    EventBus.$off('GLOBAL_SWAP_PREV_STEP', this.prevStep);
  },

  computed: {
    canSubmitCsv() {
      return this.csvFile != null;
    },

    canSubmitSwaps() {
      return this.swapData && this.numSelectedSwaps !== 0 && this.numSelectedSwaps !== null;
    },

    // List of swaps by contest, filtered by if they're selected
    filteredSwapsByContest() {
      if (!this.swapData || !this.swapData.contests) {
        return;
      }

      const filteredSwapsByContest = {};
      for (const contestSwapInfo of this.swapData.contests) {
        const contestId = contestSwapInfo.contest.id;
        const filteredSwaps = [];

        for (const swapIndex in contestSwapInfo.swaps) {
          if (this.selectedLineupsByContest[contestId][swapIndex]) {
            filteredSwaps.push(contestSwapInfo.swaps[swapIndex]);
          }
        }

        this.$set(filteredSwapsByContest, contestId, filteredSwaps);
      }

      return filteredSwapsByContest;
    },

    numSelectedSwaps() {
      if (!this.swapData || !this.swapData.contests) {
        return;
      }
      let tot = 0;
      for (const contestSwapInfo of this.swapData.contests) {
        const contestId = contestSwapInfo.contest.id;
        tot += this.numSelectedLineupsByContest[contestId];
      }

      return tot;
    },

    numSelectedLineupsByContest() {
      if (!this.swapData) {
        return {};
      }

      const numSelectedObject = {};
      for (const contestSwapInfo of this.swapData.contests) {
        const contestId = contestSwapInfo.contest.id;
        const tot = this.filteredSwapsByContest[contestId].length;

        this.$set(numSelectedObject, contestSwapInfo.contest.id, tot);
      }

      return numSelectedObject;
    },

    swapErrorsExist() {
      if (!this.swapData) {
        return false;
      }

      for (const contest of this.swapData.contests) {
        for (const swap of contest.swaps) {
          if (!swap.success) {
            return true;
          }
        }
      }

      return false;
    },

    contestMap() {
      if (!this.swapData || !this.swapData.contests) {
        return {};
      }

      const contestMap = {};
      for (const contest of this.swapData.contests) {
        this.$set(contestMap, contest.contest.id, contest);
      }

      return contestMap;
    },
  },

  methods: {
    resetData() {
      this.swapStep = 0;
      this.swapId = null;
      this.swapData = null;
      this.csvFile = null;
      this.csvSending = false;
      this.csvResponse = null;
      this.errorMessage = null;
      this.selectedLineupsByContest = {};
    },

    openModal() {
      this.resetData();
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    setCsvFile(csv) {
      this.errorMessage = null;
      this.csvFile = csv;
    },

    submitCsv() {
      this.csvSending = true;
      this.swapsSubmitted = false;
      this.errorMessage = null;
      this.swapData = null;
      this.swapLoadProgress = 0;
      this.swapLoadMessage = 'Validating Swaps';

      // Process CSV and start loading swap results using the id provided by the api
      ObSalaryCapApi.uploadGlobalSwapCSV(this.csvFile)
          .then((response) => {
            this.csvSending = false;
            this.swapId = response.swapId;
            this.swapStep = 1;
            this.loadGlobalSwapResults(this.swapLoadDelay, true);
          }).catch((error) => {
            if (error && error.response && error.response.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Could not process swaps, please ensure your CSV file is valid and try again';
            }
            this.csvSending = false;
          });
    },

    // Loading global swap results is used both for validation, and submission
    loadGlobalSwapResults(delayMs, validateOnly) {
      setTimeout(() => {
        // If swapId is null, stop loading
        // This will happen if the modal is closed while data is still processing
        if (!this.swapId) {
          return;
        }
        // Continue to load swap results until a response is given, or we hit an error
        ObSalaryCapApi.getGlobalSwapResults(this.swapId)
            .then((response) => {
              this.processSwapResults(response, validateOnly);
            }).catch((error) => {
              this.processSwapResultsError(error, validateOnly);
            });
      }, delayMs);
    },

    processSwapResults(response, validateOnly) {
      // Check if processing is complete, if not update loading bar progress
      if (response && response.status === 'processed') {
        this.swapData = response;
        if (this.swapErrorsExist) {
          if (validateOnly) {
            this.errorMessage = 'Errors exist. Some lineups will not be edited.';
          } else {
            this.errorMessage = 'Errors exist. Some lineups were not edited.';
          }
        }
        this.selectAllSwaps();
      } else {
        // Data is still processing, try again in a few seconds
        if (response && response.progress) {
          this.swapLoadProgress = response.progress;
        }
        this.loadGlobalSwapResults(this.swapLoadDelay, validateOnly);
      }
    },

    processSwapResultsError(error, validateOnly) {
      // If we fail to validate or process swaps, return to the upload screen
      this.prevStep();

      // Display error message from server, or a generic error if none is provided
      if (error && error.response && error.response.data) {
        this.errorMessage = error.response.data;
      } else {
        if (validateOnly) {
          this.errorMessage = 'Could not validate swaps, please ensure your CSV file is valid and try again';
        } else {
          this.errorMessage = 'Could not process swaps, please ensure your CSV file is valid and try again';
        }
      }
    },

    submitSwaps() {
      EventBus.$emit('OPEN_GLOBAL_SWAP_CONFIRMATION_MODAL');
    },

    confirmSubmitSwaps() {
      if (!this.swapData || !this.swapData.contests || this.numSelectedSwaps == 0) {
        this.errorMessage = 'Error: No valid swaps to process';
        return;
      }

      // Prepare data for REST api
      const swapsJSON = [];
      for (const contest of this.swapData.contests) {
        const swapEntry = {};
        swapEntry.contestId = contest.contest.id;
        swapEntry.teams = this.filteredSwapsByContest[swapEntry.contestId];
        if (swapEntry.teams.length > 0) {
          swapsJSON.push(swapEntry);
        }
      }

      if (swapsJSON.length == 0) {
        this.errorMessage = 'Error: No valid swaps to process';
        return;
      }

      // If REST call fails, we want to restore the current state and show the error
      const submittedSwapData = this.swapData;

      // Show processing swaps loading bar
      this.errorMessage = null;
      this.swapsSubmitted = true;
      this.swapData = null;
      this.swapLoadProgress = 0;
      this.swapLoadMessage = 'Processing Swaps';

      ObSalaryCapApi.processGlobalSwaps(swapsJSON)
          .then((response) => {
            this.swapId = response.swapId;
            EventBus.$emit('GLOBAL_SWAP_SUCCESS');
            this.loadGlobalSwapResults(this.swapLoadDelay, false);
          }).catch((error) => {
            if (error && error.response && error.response.data) {
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Could not process swaps, please ensure your CSV file is valid and try again';
            }
            this.swapsSubmitted = false;
            this.swapData = submittedSwapData;
          });
    },

    // Initializes selectedLineupsByContest object, also works for "select all"/"unselect all" button in header
    selectAllSwaps(val = true) {
      if (!this.swapData) {
        return;
      }
      for (const contestSwapInfo of this.swapData.contests) {
        this.selectAllSwapsByContest(contestSwapInfo, val);
      }
    },

    // Given a contest id, set all swaps as either selected or unselected
    selectAllSwapsByContest(contestSwapInfo, val = true) {
      if (!this.swapData) {
        return;
      }
      const selectedLineups = [];
      for (const swap of contestSwapInfo.swaps) {
        if (swap.success === false) {
          selectedLineups.push(false);
        } else {
          selectedLineups.push(val);
        }
      }
      this.$set(this.selectedLineupsByContest, contestSwapInfo.contest.id, selectedLineups);
    },

    toggleSwap(contestId, index) {
      this.selectSwap(contestId, index, !this.selectedLineupsByContest[contestId][index]);
    },

    selectSwap(contestId, index, val) {
      if (!this.contestMap[contestId] || !this.contestMap[contestId].swaps|| !this.contestMap[contestId].swaps[index]) {
        return;
      }

      // Always assert that contests without IDs are unselected
      if (this.contestMap[contestId].swaps[index].success === false) {
        this.$set(this.selectedLineupsByContest[contestId], index, false);
        return;
      }
      this.$set(this.selectedLineupsByContest[contestId], index, val);
    },

    prevStep() {
      if (this.swapStep == 1) {
        this.swapId = null;
        this.swapData = null;
        this.csvFile = null;
        this.errorMessage = null;
        this.swapStep--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modalHeader {
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
}

.modalBody {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.modalFooter {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlBtn {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;
  white-space: nowrap;

  .loadingCsv {
    margin-left: 0px;
    margin-right: -10px;
  }
}

.errorMessage {
  justify-self: flex-start;
  color: var(--obcolor-red);
  font-size: 14px;
  display: flex;
  align-items: center;
}

.errorIcon {
  margin-right: 5px;
  font-size: 12px;
  margin-top: -2px;
}

</style>