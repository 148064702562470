<template>
  <div v-if="!fspGame" class="noGamesText">No Game</div>
  <div v-else-if="statLine" style="display: flex; justify-content: center; align-items: center;">
    <i v-if="teamHasBall == true" class="fas fa-football-ball ballIcon"></i>
    <div>{{ statLine }}</div>
    <div v-if="inRedZone" class="redZone">RZ</div>
    <div v-if="isPowerPlay" class="powerPlay">PP</div>
  </div>
  <div v-else-if="game != null">
    {{ getHeaderText() }}
  </div>
  <div v-else>{{ getFspGameText() }}</div>
</template>

<script>
export default {
  props: {
    game: Object,
    fspGame: Object,
    position: String,
    player: Object,
    teamId: String,
    sport: String,
  },

  data() {
    return {
      statLine: null,
      scoringStats: {},

      // NFL Only
      teamHasBall: null,
      inRedZone: false,
    };
  },

  computed: {
    liveJSON() {
      if (!this.game || !this.game.live) {
        return null;
      }
      return this.game.live;
    },

    isPlayerHome() {
      if (!this.teamId || !this.game) {
        return null;
      }
      if (this.game?.home_team?.team_id == this.teamId) {
        return true;
      }
      if (this.game?.visitor_team?.team_id == this.teamId) {
        return false;
      }
      return null;
    },

    playerTeam() {
      if (this.isPlayerHome) {
        return this.game?.home_team;
      }
      if (!this.isPlayerHome) {
        return this.game?.visitor_team;
      }
      return null;
    },

    oppTeam() {
      if (this.isPlayerHome) {
        return this.game?.visitor_team;
      }
      if (!this.isPlayerHome) {
        return this.game?.home_team;
      }
      return null;
    },

    isPowerPlay() {
      if (this.sport !== 'NHL') {
        return false;
      }

      const game = this.fspGame;

      if (!game || !this.todayOrFspGame || !this.todayOrFspGame.live) {
        return false;
      }

      const homeAway = game.home === 'true' ? 'home' : 'away';
      const liveState = this.todayOrFspGame.liveStats;
      if (this.sport === 'NHL' && this.todayOrFspGame.live && this.todayOrFspGame.live.status === 'mid-event' && liveState != null) {
        return liveState.powerplay === homeAway;
      }

      return false;
    },

    todayOrFspGame() {
      if (this.game) {
        return this.game;
      }
      return this.fspGame;
    },
  },

  watch: {
    fspGame(to, from) {
      this.setupStatLine();
    },

    game(to, from) {
      this.setupStatLine();
    },
  },

  created() {
    this.setupStatLine();
  },

  methods: {

    setupStatLineNfl() {
      // If game is live, show the yardline info
      const live = this.liveJSON;
      const gameTime = live ? live.gametime : '';

      if (live && (live.status == 'pre-event' || live.status == 'Postponed')) {
        this.statLine = null;
        return;
      }

      // End of quarter
      if (live && live.period && (gameTime === '00:00' || gameTime === '0:00')) {
        const period = live.period ? live.period : '';
        if (period == '4') {
          this.teamHasBall = null;
          this.statLine = '';
          return;
        }
        this.statLine = period == '2' ? 'Halftime' : 'End of ' + period.addSuffix();
        return;
      }

      this.setupFieldStateNfl();
    },

    setupFieldStateNfl() {
      // Live yardline info. Example: 1st & 10 | IND 14
      const fieldState = this.$SportInfo.getNflFieldState(this.player, this.game);
      this.statLine = fieldState.statLine || null;
      this.teamHasBall = fieldState.teamHasBall || null;
      this.inRedZone = fieldState.inRedZone || null;
    },

    setupStatLineMlb() {
      // If game is live show Player Team vs Opponent Score
      const live = this.liveJSON;

      if (!this.playerTeam || !live || live.status === 'pre-event' || live.status == 'Postponed') {
        this.statLine = null;
        return;
      }

      const isHome = this.isPlayerHome;
      const playerTeam = this.$TeamInfo.getDisplayAlias(this.sport, this.playerTeam.team_alias);
      const oppText = (isHome ? 'vs ' : '@ ') + this.$TeamInfo.getDisplayAlias(this.sport, this.oppTeam.team_alias);
      const score = isHome ? live.hometeam.score + '-' + live.awayteam.score : live.awayteam.score + '-' + live.hometeam.score;
      this.statLine = playerTeam + ' ' + oppText + ' ' + score;
    },

    // NBA and NHL are handled the same way
    setupStatLineNhlNba() {
      if (!this.liveJSON) {
        return;
      }

      const live = this.liveJSON;
      const gameTime = live.gametime;
      if (live.status == 'pre-event' || live.status == 'Postponed') {
        this.statLine = null;
        return;
      }

      const period = this.$SportInfo.getGamePeriod(this.sport, this.todayOrFspGame);
      if (live.period && (gameTime === '00:00' || gameTime === '0:00')) {
        this.statLine = 'End of ' + period;
        return;
      }

      this.statLine = gameTime + ' ' + period;
    },

    getTeamAlias(home) {
      if (home) {
        return this.game.home_team.team_alias;
      }
      return this.game.visitor_team.team_alias;
    },

    setupStatLine() {
      if (this.sport === 'NFL') {
        this.setupStatLineNfl();
        return;
      }

      if (this.sport === 'MLB') {
        this.setupStatLineMlb();
        return;
      }

      if (!this.fspGame && !this.todayOrFspGame) {
        this.statLine = null;
        return;
      }

      this.setupStatLineNhlNba();
    },

    // Use FspGame data when not today, since todays games will not have the data
    getFspGameText() {
      if (!this.fspGame) {
        return '';
      }
      const date = this.$moment(parseInt(this.fspGame.timestamp));
      let oppText = this.fspGame.home === 'true' ? 'vs ' : '@ ';
      oppText += this.$TeamInfo.getDisplayAlias(this.sport, this.fspGame.opp_alias);
      return oppText + ' ' + date.format('h:mma');
    },

    getHeaderText() {
      if (!this.game || !this.teamId) {
        return '';
      }

      const oppText = (this.isPlayerHome ? 'vs ' : '@ ') + this.$TeamInfo.getDisplayAlias(this.sport, this.oppTeam.team_alias);
      const gameTime = this.$moment(this.game.date_utc).format('h:mma');
      const gameStatus = this.liveJSON ? this.liveJSON.status : '';

      switch (gameStatus) {
        case 'Postponed': return 'Postponed';
        case 'Canceled': return oppText + ' ' + gameTime + ' Canceled';
        case 'post-event': return oppText + ' ' + gameTime + ' Final';
      }
      return oppText + ' ' + gameTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.noGamesText {
  color: var(--obcolor-font-secondary);
}

.ballIcon {
  margin-right: 6px;
  color: var(--obcolor-NBA);
}

.redZone,
.powerPlay {
  background: red;
  color: white;
  font-weight: bold;
  padding: 1px 2px;
  border-radius: 4px;
  margin-left: 6px;
  margin-top: -1px;
}
</style>