<template>
  <div>
    <div v-if="!locationIsEnabled" class="container">
      <h1>Enable Location</h1>
      <p>We need your location in order to deliver you the best experience and games available in your area.</p>

      <ErrorPanel :message="errorMessage" />
      <button class="ob-btn" v-if="!errorMessage" :disabled="processing"
        @click="enableLocation()"
      >
        Enable Location
      </button>
      <button class="ob-btn" v-else @click="goNext()">Continue</button>
      <img :src="require('@/assets/onboarding/location.png')" alt="location map">
    </div>

    <div class="container" v-else>
      <h1>Enable Location</h1>
      <p>We need your location in order to deliver you the best experience and games available in your area.</p>
      <i class="fas fa-check-circle"></i>
      <p class="enabledText">Location access is enabled!</p>
      <button class="ob-btn" @click="goNext()">Continue</button>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    ErrorPanel,
  },

  data() {
    return {
      errorMessage: null,
      processing: false,
      enableLocationChecked: false,
      locationIsEnabled: false,
    };
  },

  created() {
    this.isLocationEnabled();
  },

  methods: {
    goNext() {
      EventBus.$emit('ON_BOARDING_NEXT');
    },

    isLocationEnabled() {
      navigator.permissions.query({name: 'geolocation'})
          .then((result) => {
            if (result.state === 'granted') {
              this.locationIsEnabled = true;
            }
          }).catch((_error) => {
            this.locationIsEnabled = false;
          });
    },

    enableLocation() {
      this.processing = true;
      // Request location permission and check the state of the permission
      navigator.geolocation.getCurrentPosition(() => {
        navigator.permissions.query({name: 'geolocation'})
            .then((result) => {
              if (result.state === 'granted') {
                EventBus.$emit('ON_BOARDING_NEXT');
              }
            })
            .catch((_error) => {
              this.processing = false;
              this.errorMessage = 'Error checking location.';
            })
            .finally(() => {
              this.processing = false;
              this.enableLocationChecked = true;
            });
      },
      (_error) => {
        this.errorMessage = 'Location access is blocked. You may enable location access via your browser settings for an enhanced experience.';
        this.processing = false;
        this.enableLocationChecked = true;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.container {
  .arrowContainer {
    height: 49px;
  }
  button {
    margin-top: 50px;
    height: 60px;
  }
  img {
    padding-top: 70px;
    width: 345px;
    margin: 0 auto;
  }
  .fa-check-circle {
    color: var(--obcolor-green);
    font-size: 60px;
    margin: 60px auto;
  }
  .enabledText {
    margin: 0 auto;
    font-weight: 700;
  }
  ::v-deep {
    .errorPanel {
      margin: 15px 0 -10px 0;
    }
  }
}
</style>