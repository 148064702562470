<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 800px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);" :disableClickaway="true"
  >
    <div slot="header" class="header">
      <div class="headerField">
        <div class="headerText">Import Lineup</div>
        <div class="descriptionText">Remaining Salary and Projections are live values</div>
      </div>
      <div style="flex: 1;"></div>
      <div class="contestTimeContainer">
        <div class="contestTime">{{ contestCountdownVal }}</div>
        <div class="descriptionText">
          {{ contestCountdownVal != '0:00:00' ? 'Contest Start' : 'Contest Has Started' }}
        </div>
      </div>
      <ContestFillGraph :playersCurrent="contest.playersCurrent"
        :playersMax="contest.playersMax"
        :format="'Tournament'"
        :playerJoined="playerJoined"
        textPosition="bottom"
        style="margin-left: 40px; margin-right: 55px; width: 150px;"
      />
    </div>

    <div slot="body">
      <div v-if="!showImportExistingLineup && !showImportCSV" class="importTypes">
        <div class="selectImportType" @click.stop="openImportExistingLineup()">
          <div class="importTypeText">
            Choose Existing Lineup
          </div>
          <img class="existingLineupImage" :src="require('@/assets/importlineups/OBLogo.png')" alt="Ownersbox Logo">
        </div>
        <div class="selectImportType" @click.stop="openImportCSV()">
          <div class="importTypeText">
            Import CSV
          </div>
          <img class="csvLineupImage" :src="require('@/assets/importlineups/importCSVLogo.png')" alt="Import CSV Image">
        </div>
      </div>
      <template v-else>
        <div v-if="showImportExistingLineup && isDailyContest" class="backBtn backExistingLineupBtn" @click.stop="closeImportExistingLineup()">
          <i class="fas fa-chevron-left"></i>
          <div class="backBtnText">Choose Existing Lineup</div>
        </div>
        <div v-if="showImportCSV" class="backBtn backImportBtn" @click.stop="closeImportCSV()">
          <i class="fas fa-chevron-left"></i>
          <div class="backBtnText">Import CSV</div>
        </div>
      </template>
      <ImportExistingLineup v-if="showImportExistingLineup" :contest="contest" :playerMap="playerMap" />
      <ImportCSVInstructions v-else-if="showCSVInstructions" :contest="contest" />
      <ImportCSV v-else-if="showCSVLineups" :contest="contest" :playerMap="playerMap"
        :lineups="lineups" :allLineupsAdded="allLineupsAdded"
      />
    </div>

    <div slot="footer" class="modalFooter">
      <template v-if="showImportCSV">
        <div class="footerFieldContainer">
          <div class="footerField">
            <div class="entries">{{ entries }}</div>
            <div class="descriptionText">Entries</div>
          </div>

          <div class="footerField">
            <div class="money">{{ contestEntryFee }}</div>
            <div class="descriptionText">Entry Fee</div>
          </div>

          <div class="footerField">
            <div class="money">{{ totalEntryFee }}</div>
            <div class="descriptionText">Total Entries</div>
          </div>
        </div>

        <div :class="maxLineups || showError ? 'submitError' : 'submit'">
          <button class="ob-btn submitButton" :class="disableSubmit ? 'ob-btn-grey' : 'ob-btn'" :disabled="disableSubmit"
            @click="submitLineups()"
          >
            Enter
          </button>
          <div v-if="maxLineups" class="warning">Max Entries Reached</div>
          <div v-else-if="showError" class="error">
            <i class="fas fa-exclamation-circle" style="color: red;"></i>
            Error(s) exist. Some lineups will not be imported.
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ImportExistingLineup from './ImportExistingLineup/ImportExistingLineup';
import ImportCSVInstructions from './ImportCSV/ImportCSVInstructions';
import ImportCSV from './ImportCSV/ImportCSV';
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';
import EventBus from '@/event-bus';

export default {
  components: {
    Modal,
    ImportExistingLineup,
    ImportCSVInstructions,
    ImportCSV,
    ContestFillGraph,
  },

  data() {
    return {
      playerMap: {},
      isOpen: false,
      showImportExistingLineup: false,
      showCSVInstructions: false,
      showCSVLineups: false,
      contestCountdownInterval: null,
      contestCountdownVal: '',
      playerJoined: null,
      contest: null,
      numEntries: 0,
      maxCSVEntries: 150,
      showError: false,
      lineups: [],
      allLineupsAdded: false,
    };
  },

  created() {
    EventBus.$on('OPEN_SALARY_CAP_IMPORT_MODAL', this.openModal);
    EventBus.$on('CLOSE_SALARY_CAP_IMPORT_MODAL', this.closeModal);
    EventBus.$on('CSV_LINEUPS_IMPORTED', this.csvLineupsImported);
    EventBus.$on('ADD_LINEUP_TO_SUBMIT', this.addLineup);
    EventBus.$on('ADD_ALL_LINEUPS_TO_SUBMIT', this.addAllLineups);
    EventBus.$on('CLOSE_IMPORT_MODAL', this.closeModal);

    this.getContestTimeRemaining();
    this.setCountdownInterval();
  },

  destroyed() {
    EventBus.$off('OPEN_SALARY_CAP_IMPORT_MODAL', this.openModal);
    EventBus.$off('CLOSE_SALARY_CAP_IMPORT_MODAL', this.closeModal);
    EventBus.$off('CSV_LINEUPS_IMPORTED', this.csvLineupsImported);
    EventBus.$off('ADD_LINEUP_TO_SUBMIT', this.addLineup);
    EventBus.$off('ADD_ALL_LINEUPS_TO_SUBMIT', this.addAllLineups);
    EventBus.$off('CLOSE_IMPORT_MODAL', this.closeModal);
  },

  watch: {
    'contest.playersCurrent'(_to, from) {
      if (from !== undefined) {
        this.flashPlayerJoined(true);
      }
    },
  },

  computed: {
    showImportCSV() {
      return this.showCSVInstructions || this.showCSVLineups;
    },

    maxEntries() {
      return Math.min(
          this.contest.playersMax - this.contest.playersCurrent,
          this.contest.entriesPerUser - this.contest.myEntries,
      );
    },

    entries() {
      if (!this.contest || !this.contest.entriesPerUser) {
        return '';
      }

      return this.numEntries + '/' + this.maxEntries;
    },

    maxLineups() {
      return this.numEntries >= this.maxEntries;
    },

    contestEntryFee() {
      if (!this.contest || !this.contest.fee) {
        return '';
      }

      return this.contest.fee;
    },

    totalEntryFee() {
      if (!this.contest) {
        return '';
      }

      if (!this.contest.feeValue) {
        return '$0';
      }

      return '$' + (this.numEntries * (this.contest.feeValue / 100));
    },

    disableSubmit() {
      return this.numEntries === 0;
    },

    isDailyContest() {
      return this.contest && this.contest.format == 'DAILY';
    },

    lineupsAvailable() {
      if (!this.contest) {
        return false;
      }

      let lineupsAvailable = Math.min(
          this.contest.playersMax - this.contest.playersCurrent,
          this.contest.entriesPerUser - this.contest.myEntries,
      );

      lineupsAvailable -= this.numEntries;
      return lineupsAvailable > 0;
    },
  },

  methods: {
    openModal(contest, playerList) {
      this.loading = true;
      this.isOpen = true;
      this.contest = contest;
      this.createPlayerMap(playerList);
      this.resetData();
      this.getContestTimeRemaining();

      if (!this.isDailyContest) {
        this.openImportExistingLineup();
      } else {
        this.closeImportCSV();
      }
    },

    closeModal() {
      this.isOpen = false;
    },

    openImportExistingLineup() {
      this.displayScreen(true, false, false);
    },

    closeImportExistingLineup() {
      this.displayScreen(false, false, false);
    },

    openImportCSV() {
      this.displayScreen(false, true, false);
    },

    closeImportCSV() {
      if (this.showCSVLineups) {
        // Open import CSV instructions if lineups are shown
        this.displayScreen(false, true, false);
        this.resetData();
      } else {
        // Show import selection page otherwise
        this.displayScreen(false, false, false);
      }
    },

    importLineups(lineups) {
      this.lineups = lineups;
      this.displayScreen(false, false, true);
      this.resetData();
    },

    displayScreen(showImportExistingLineup, showCSVInstructions, showCSVLineups) {
      this.showImportExistingLineup = showImportExistingLineup;
      this.showCSVInstructions = showCSVInstructions;
      this.showCSVLineups = showCSVLineups;
    },

    addLineup(row) {
      for (const lineupIndex in this.lineups) {
        const lineup = this.lineups[lineupIndex];
        if (lineup.row === row) {
          if (!lineup.submit && !this.lineupsAvailable) {
            break;
          }

          lineup.submit = !lineup.submit;
          this.$set(this.lineups, lineupIndex, lineup);
          this.updateSubmitCount(lineup.submit);

          if (!lineup.submit) {
            this.allLineupsAdded = false;
          }

          break;
        }
      }
    },

    addAllLineups() {
      this.allLineupsAdded = !this.allLineupsAdded;
      for (const lineup of this.lineups) {
        if (lineup.isValid && this.allLineupsAdded !== lineup.submit) {
          if (this.allLineupsAdded && !this.lineupsAvailable) {
            continue;
          }

          lineup.submit = this.allLineupsAdded;
          this.updateSubmitCount(lineup.submit);
        }
      }
    },

    csvLineupsImported(lineups, containsError) {
      this.showCSVInstructions = false;
      this.showCSVLineups = true;
      this.showError = containsError;
      this.lineups = lineups;
    },

    createPlayerMap(playerList) {
      for (const player of playerList) {
        this.$set(this.playerMap, player.id, player);
      }
    },

    setCountdownInterval() {
      clearInterval(this.contestCountdownInterval);
      const self = this;
      this.contestCountdownInterval = setInterval(() => {
        self.getContestTimeRemaining();
      }, 1000);
    },

    // Gets hour, minute and second difference between now and contest start
    // Displays it in a nice format, if contest has already started just show 00:00:00
    getContestTimeRemaining() {
      if (!this.contest || !this.contest.contestStart) {
        return '';
      }

      const now = this.$moment();
      const cDate = this.$moment(this.contest.contestStart);

      if (cDate.diff(now) < 0) {
        this.contestCountdownVal = '0:00:00';
        return;
      }

      const hours = String(cDate.diff(now, 'hours'));
      let minutes = String(cDate.diff(now, 'minutes') % 60);
      let seconds = String(cDate.diff(now, 'seconds') % 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      this.contestCountdownVal = hours + ':' + minutes + ':' + seconds;
    },

    flashPlayerJoined(change) {
      const self = this;
      this.playerJoined = change;
      setTimeout(() => {
        self.playerJoined = null;
      }, 4000);
    },

    resetData() {
      this.csvErrorMessage = '';
      this.allLineupsAdded = false;
      this.showError = false;
      this.numEntries = 0;
    },

    updateSubmitCount(addSubmit) {
      if (addSubmit) {
        this.numEntries++;
      } else {
        this.numEntries--;
      }
    },

    submitLineups() {
      const submitLineups = [];
      for (const lineup of this.lineups) {
        if (lineup.isValid && lineup.submit) {
          submitLineups.push(lineup);
        }
      }

      const entryInfo = {
        lineups: submitLineups,
        contest: this.contest,
        playerMap: this.playerMap,
      };

      EventBus.$emit('OPEN_MULTIPLE_LINEUP_CONFIRM_MODAL', entryInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
  .importTypes {
    display: flex;
    gap: 40px;
    padding: 16.5px 40px;
  }

  .selectImportType {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 30px;
    width: 280px;
    height: 397px;
    background: var(--obcolor-background-2);
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
  }

  .selectImportType:hover {
    background-color: var(--obcolor-background-1);
  }

  .importTypeText {
    font-size: 24px;
    width: 280px;
  }

  .existingLineupImage {
    width: 218px;
    margin-top: 196px;
    opacity: 0.4;
  }

  .csvLineupImage {
    width: 175px;
    margin-top: 196px;
    opacity: 0.4;
  }

  .importType {
    text-align: left;
    overflow: hidden;
    max-width: 550px;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 280px;
  }

  .headerText {
    font-size: 20px;
    font-weight: bold;
    display: flex;
  }

  .descriptionText {
    font-size: 14px;
    margin-top: 3px;
    color: var(--obcolor-font-secondary);
  }

  .headerField {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .footerField {
    display: flex;
    flex-direction: column;
  }

  .modalFooter {
    height: 81px;
    font-size: 14px;
    background: var(--obcolor-page-header);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  .money {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    color: #38C567;
  }

  .submit {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    font-size: 18px;
    padding-right: 30px;
  }

  .submitError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px 0px 0px;
    gap: 5px;
  }

  .submitButton {
    width: 100px;
    font-size: 14px;
  }

  .warning {
    font-size: 16px;
    margin-top: 3px;
    color: var(--obcolor-yellow);
  }

  .error {
    font-size: 14px;
    color: var(--obcolor-red);
  }

  .header {
    height: 86px;
    background: var(--obcolor-page-header);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .entries {
    font-size: 16px;
    font-weight: bold;
    display: flex;
  }

  .contestTimeContainer {
    display: flex;
    flex-direction: column;
    width: 128px;
  }
  .contestTime {
    height: 18px;
  }

  .footerFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
  }

  .backBtn {
    display: flex;
    flex: 1;
    font-weight: bold;
    color: var(--obcolor-text-primary);
    align-items: center;
    text-align: right;
    height: 50px;
    margin-left: 40px;
    cursor: pointer;
  }

  .backBtn:hover .backBtnText {
    text-decoration: underline;
  }

  .backImportBtn {
    width: 118px;
  }

  .backExistingLineupBtn {
    width: 200px;
  }

  .backBtnText {
    margin-left: 10px;
  }
</style>