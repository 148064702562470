import store from '@/store';

const UserInfo = {};

UserInfo.install = function(Vue, options) {
  const UserInfo = {
    mostRelevantLLSport() {
      let sports = ['NFL', 'MLB', 'NBA', 'NHL'];
      const sportNumContests = store.getters.sportNumContests;
      const leaguesCountBySport = store.getters.leaguesCountBySport;

      sports = sports.filter((s) => {
        return sportNumContests && sportNumContests[s].llContestOpen;
      }).sort((s1, s2) => {
        return leaguesCountBySport[s2] - leaguesCountBySport[s1];
      });

      return sports.length <= 0 ? 'NFL' : sports[0];
    },

    useableLLTicket(sport) {
      const tickets = store.getters.tickets;
      return tickets.find((t) => {
        // If the sport isn't "all" in the ticket and it isn't the same as the sport given, don't return this
        if (sport && t.sport != '' && t.sport != sport) {
          return false;
        }
        return t.contestType === 'LIGHTNING_LINEUPS';
      }) || null;
    },

    canUseTicket(contest) {
      const ticketFeeLookup = store.getters.ticketFeeLookup;
      const sport = contest.sport;
      const moneyTypeIndex = contest.realMoney ? 'realMoney' : 'ownersBucks';
      const feeValue = contest.feeValue;
      let contestType = '';
      switch (contest.ctype) {
        case 'Tournament':
          contestType = 'SalaryCap';
          break;
        case 'LIGHTNING_LINEUPS':
          contestType = 'LIGHTNING_LINEUPS';
          break;
        default:
          contestType = contest.ctype;
          break;
      }

      if (!sport || !moneyTypeIndex || !feeValue || !contestType || !ticketFeeLookup || !ticketFeeLookup[sport]) {
        return null;
      }

      const foundBySport = ticketFeeLookup[sport]?.[contestType]?.[moneyTypeIndex]?.[feeValue];
      if (foundBySport) {
        return foundBySport;
      }

      const foundAllBySport = ticketFeeLookup[sport]?.all?.[moneyTypeIndex]?.[feeValue];
      if (foundAllBySport) {
        return foundAllBySport;
      }

      let foundByAll = ticketFeeLookup.all?.[contestType]?.[moneyTypeIndex]?.[feeValue];
      if (!foundByAll) {
        foundByAll = ticketFeeLookup.all?.all?.[moneyTypeIndex]?.[feeValue];
      }
      return foundByAll || null;
    },
  };

  Vue.prototype.$UserInfo = UserInfo;
};

export default UserInfo;
