<template>
  <div class="gameFilterContainer">
    <button class="gameFilter" :class="{selected: gameFilter.length === 0}" @click="clearGameFilter()"
      data-testid="game-filter-all"
    >
      <div v-if="gameFilter.length === 0" class="allGames">
        All Games
      </div>
      <template v-else>
        <div class="allGames">
          {{ gameFilter.length }} Games
        </div>
        <div class="resetGames">
          Reset
        </div>
      </template>
    </button>
    <HorizontalScroller>
      <div class="activeGames">
        <button v-for="game of games" :key="game.id" class="gameFilter"
          :class="{selected: hasGameFilter(game.id)}"
          @click="toggleGameFilter(game.id)"
          data-testid="game-filter"
        >
          <LobbyContestCountdown class="gameTime" :end="game.date" tournament />
          <div class="teams">
            <div class="team homeTeam">
              <img class="teamLogo" :src="getTeamLogo(game.homeTeamAlias)" :alt="game.homeTeamAlias + ' logo'">
              {{ $TeamInfo.getDisplayAlias(sport, game.homeTeamAlias) }}
            </div>
            <div class="vs">vs</div>
            <div class="team awayTeam">
              {{ $TeamInfo.getDisplayAlias(sport, game.awayTeamAlias) }}
              <img class="teamLogo" :src="getTeamLogo(game.awayTeamAlias)" :alt="game.awayTeamAlias + ' logo'">
            </div>
          </div>
        </button>
      </div>
    </HorizontalScroller>
  </div>
</template>

<script>
// @ts-check
import Vue from 'vue';
import LobbyContestCountdown from '@/views/Lobby/LobbyContestCountdown.vue';
import HorizontalScroller from '@/components/HorizontalScroller.vue';
import {getTeamLogo} from '@/utils/TeamLogos';

export default Vue.extend({
  props: {
    games: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    sport: {
      type: String,
      required: true,
    },
  },
  components: {
    LobbyContestCountdown,
    HorizontalScroller,
  },

  computed: {
    gameFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    getTeamLogo(alias) {
      const displayAlias = this.$TeamInfo.getDisplayAlias(this.sport, alias);
      return getTeamLogo(this.sport, displayAlias);
    },

    hasGameFilter(gameID) {
      return this.gameFilter.includes(gameID);
    },

    toggleGameFilter(gameID) {
      if (this.hasGameFilter(gameID)) {
        this.gameFilter = this.gameFilter.filter((id) => id !== gameID);
      } else {
        this.gameFilter = this.gameFilter.concat([gameID]);
      }
    },

    clearGameFilter() {
      if (this.gameFilter.length === 0) {
        this.gameFilter = this.games.map((g) => g.id);
        return;
      }
      this.gameFilter = [];
    },
  },
});
</script>

<style lang="scss" scoped>
.gameFilterContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 140px 1fr;

  gap: 10px;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  .activeGames {
    display: flex;
    gap: 10px;
    min-height: 46px;
  }
  .gameFilter {
    all: inherit;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--obcolor-background-5);
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      background-color: var(--obcolor-background-3);
    }

    &.selected {
      border: solid 1px var(--obcolor-ob-blue);
      padding: 7px;
    }

    .allGames {
      display: flex;
      text-align: center;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
    .resetGames {
      text-align: center;
      background-color: var(--obcolor-background-2);
      padding: 4px;
      margin: -8px;
    }

    .gameTime {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 12px;
      color: var(--obcolor-font-secondary);
    }

    .teams {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .team {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 80px;
        text-transform: uppercase;

        &.awayTeam {
          justify-content: end;
        }
      }
    }
    .teamLogo {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }

  }
}
</style>