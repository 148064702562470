// Live Summary Stats
// Controls the top overall summary for live page

// Live Summary
export const LIVE_NHL_SKATER = [
  {
    label: 'SKATER',
    stats: [
      {label: 'TOI', path: 'skater.TOI', tooltip: 'Time on Ice'},
      {label: 'G', path: 'skater.G', tooltip: 'Goals', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'A', path: 'skater.A', tooltip: 'Assists', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: '+/-', path: 'skater.plus_minus', tooltip: 'Plus/Minus', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0}, {value: -1, heat: -1}, {value: -2, heat: -2}, {value: -3, heat: -3},
      ]},
      {label: 'BLK', path: 'skater.BK', tooltip: 'Blocked Shots', thresholds: [
        {value: 3, heat: 3}, {value: 2, heat: 2}, {value: 1, heat: 1}, {value: 0, heat: 0},
      ]},
      {label: 'SOG', path: 'skater.SOG', tooltip: 'Shots on Goal', thresholds: [
        {value: 4, heat: 3}, {value: 3, heat: 1}, {value: 2, heat: 1}, {value: 1, heat: 0}, {value: 0, heat: -1},
      ]},
    ],
  },
];

export const LIVE_NHL_GOALIE = [
  {
    label: 'GOALTENDER',
    stats: [
      {label: 'TOI', path: 'skater.TOI', tooltip: 'Time on Ice'},
      {label: 'W', path: 'goalie.G_W', tooltip: 'Wins', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'SO', path: 'goalie.SO', tooltip: 'Shutouts', thresholds: [
        {value: 1, heat: 3}, {value: 0, heat: 0},
      ]},
      {label: 'GA', path: 'goalie.GA', tooltip: 'Goals Against', thresholds: [
        {value: 0, heat: 3}, {value: 1, heat: 2}, {value: 2, heat: 0}, {value: 3, heat: -1}, {value: 4, heat: -2}, {value: 5, heat: -3},
      ]},
      {label: 'SV', path: 'goalie.SV', tooltip: 'Saves', thresholds: [
        {value: 43, heat: 3}, {value: 38, heat: 2}, {value: 33, heat: 1}, {value: 28, heat: 0}, {value: 23, heat: -1}, {value: 18, heat: -2}, {value: 13, heat: -3},
      ]},
    ],
  },
];
