import Api from '@/api/ObBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

export default {
  getDateOptions(sport) {
    if (!sport) {
      return;
    }
    return Api()
        .get('/lightninglineups/getDateOptions?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  getLineup(sport, dateId, isTestMode, lockedPlayerId) {
    if (!sport) {
      return;
    }
    let path = '/lightninglineups/getLineup?sport=' + sport;
    if (dateId) {
      path += '&dateId=' + dateId;
    }
    if (isTestMode) {
      path += '&testMode=true';
    }
    if (lockedPlayerId) {
      path += '&lockedPlayer=' + lockedPlayerId;
    }
    return Api()
        .get(path)
        .then((response) => {
          return response.data;
        });
  },

  getExistingLineup(existingLineup) {
    return Api()
        .get('/lightninglineups/getExistingLineup?id=' + existingLineup)
        .then((response) => {
          return response.data;
        });
  },

  getLobbyLineup(sport) {
    return Api()
        .get('/lightninglineups/getLobbyLineup?sport=' + sport)
        .then((response) => {
          return response.data;
        });
  },

  getActiveContests() {
    return Api()
        .get('/lightninglineups/getActiveContests')
        .then((response) => {
          return response.data;
        });
  },

  getContest(id) {
    return Api()
        .get('/lightninglineups/getContest?contestId=' + id)
        .then((response) => {
          return response.data;
        });
  },

  async submitEntry(params) {
    const reason = 'GamePlay';
    const gameType = 'LightningLineups';
    const config = await getGeopacketConfig(reason, gameType);
    return Api()
        .post('/lightninglineups/submitEntry?errorType=json', params, config)
        .then((response) => {
          return response.data;
        });
  },
};
