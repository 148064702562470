<template>
  <div class="onBaseContainer">
    <!-- Make an 'L' shape on the bases, then we can rotate 45 degrees in CSS -->
    <div v-tooltip.top="{content: '3rd Base', class:'ObTooltip'}" class="base thirdBase" :class="{onBase: fieldState.onBase && fieldState.onBase[2]}"></div>
    <div>
      <div v-tooltip.top="{content: '2nd Base', class:'ObTooltip'}" class="base secondBase" :class="{onBase: fieldState.onBase && fieldState.onBase[1]}"></div>
      <div v-tooltip.top="{content: '1st Base', class:'ObTooltip'}" class="base firstBase" :class="{onBase: fieldState.onBase && fieldState.onBase[0]}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldState: {type: Object, default: null},
  },
};
</script>

<style lang="scss" scoped>
.onBaseContainer {
  display: flex;
  justify-content: center;
  transform: rotate(-45deg);

  .base {
    width: 10px;
    height: 10px;
    background-color: var(--obcolor-timeline);
    margin-right: 3px;
    margin-top: 3px;
  }
  .base.onBase {
    background-color: var(--obcolor-ob-blue);
  }
}
</style>