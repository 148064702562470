<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 550px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; padding: 0; background: var(--obcolor-background-3); overflow-y: scroll;"
    :hideCloseButton="false"
  >
    <div slot="header" class="header">
      <div>Tickets ({{ numValidTickets }} Available)</div>
    </div>

    <div slot="body" class="body">
      <div class="ticketList" v-if="validTickets && validTickets.length > 0">
        <TicketField v-for="(ticket, index) in validTickets" :key="index"
          :ticket="ticket" :showButton="true"
          @ticketClicked="applyCodeFromTicket"
        />
      </div>
    </div>

    <div slot="footer" class="footer">
      <div class="applySection">
        <!-- Ticket Input -->
        <div class="inputContainer">
          <form @submit.prevent="applyCode()">
            <input ref="promoInput" v-model="promoCode" placeholder="Enter code">
          </form>
          <button v-if="state == 'SENDING'" disabled class="ob-btn-grey">
            Validating...
          </button>
          <button v-else-if="promoCode == null || promoCode == ''" disabled class="ob-btn-grey">Apply Ticket</button>
          <button v-else class="ob-btn" @click="applyCode()">Apply Ticket</button>
        </div>

        <!-- Error Messages -->
        <div v-if="state == 'INVALID'" class="errorMessage">
          <i class="fas fa-exclamation-circle"><div class="iconBg"></div></i> {{ message }}
        </div>

        <!-- Bottom Text -->
        <div class="noteText">{{ bottomNote }}</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import TicketField from '@/components/tooltip/tickets/TicketField';
import ObPromoCodeApi from '@/api/ObPromoCodeApi';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    TicketField,
  },

  props: {
    contestType: {type: String, default: null},
    bottomNote: {type: String, default: ''},
  },

  data() {
    return {
      isOpen: false,
      promoCode: '',
      state: 'NO_CODE',
      message: '',
      contest: null,
      sport: null,
    };
  },

  created() {
    EventBus.$on('OPEN_APPLY_TICKETS_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_APPLY_TICKETS_MODAL', this.openModal);
  },

  computed: {
    ...mapState(['tickets', 'numTickets']),

    numValidTickets() {
      return this.validTickets.length;
    },

    validTickets() {
      return this.tickets.slice().filter((t) => {
        const isContestType = t.contestType == null || this.contestType == null || t.contestType === this.contestType;
        const isSameSport = this.sport == null || t.sport == null || t.sport === '' || t.sport === this.sport;
        return isContestType && isSameSport;
      });
    },
  },

  methods: {
    openModal(promoCode, sport = null) {
      // Refresh ticket data
      EventBus.$emit('LOAD_USER_TICKETS');

      // PromoCode can be passed to have the modal prepopulated with an existing code
      // Sport can be null to show tickets from all sports
      this.isOpen = true;
      this.sport = sport;
      this.promoCode = promoCode;
      this.state = 'NO_CODE';
      this.message = '';

      // Auto select input box
      this.$nextTick(() => {
        this.$refs.promoInput.select();
      });
    },

    applyCodeFromTicket(ticket) {
      this.promoCode = ticket.id;
      EventBus.$emit('APPLY_TICKET', ticket);
      this.isOpen = false;
    },

    applyCode() {
      // Manually entered codes must be validated before it can be applied
      this.state = 'SENDING';
      if (this.contestType === 'PROPS') {
        this.validatePropsCode();
      } else if (this.contestType === 'LIGHTNING_LINEUPS') {
        this.validateLightningLineupsCode();
      }
    },

    validatePropsCode() {
      ObPromoCodeApi.validatePlayerPicks(this.promoCode)
          .then((response) => {
            this.handleValidationSuccess(response);
          }).catch((e) => {
            this.handleValidationError(e);
          });
    },

    validateLightningLineupsCode() {
      ObPromoCodeApi.validateLightningLineup(this.sport, this.promoCode)
          .then((response) => {
            this.handleValidationSuccess(response);
          }).catch((e) => {
            this.handleValidationError(e);
          });
    },

    handleValidationSuccess(response) {
      if (response.isValid && response.promo) {
        EventBus.$emit('APPLY_TICKET', response.promo);
        this.state = 'NO_CODE';
        this.isOpen = false;
      } else {
        this.state = 'INVALID';
        this.message = response.reason ? response.reason : 'Invalid ticket';
      }
    },

    handleValidationError(e) {
      let errorMsg = 'Invalid ticket';
      if (e && e.response && e.response.data) {
        errorMsg = e.response.data;
      }
      this.state = 'INVALID';
      this.message = errorMsg;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--obcolor-background-5);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}

.body {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  background: var(--obcolor-background-4);
  max-height: 500px;

  .ticketList {
    border-bottom: 1px solid var(--obcolor-background-2);
  }
}

.applySection {
  display: flex;
  flex-direction: column;
  padding: 10px 19px 10px 10px;

  .infoText {
    white-space: break-spaces;
    margin-bottom: 10px; text-align: left;
  }
}

.inputContainer {
  height: 30px;
  width: 100%;
  display: flex;

  form {
    flex: 1;
  }
  input {
    font-size: 14px;
    border-radius: 0;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
  }
  button {
    margin-left: 10px;
    margin-right: -1px;
    font-size: 14px;
    width: 110px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    white-space: nowrap;
  }
}

.errorMessage {
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  padding-left: 2px;
  color: red;

  i {
    margin-right: 5px;
  }
}

.iconBg {
  margin-top: -12px;
  margin-left: 1px;
  background: white;
  border-radius: 10px;
  height: 9px;
  width: 9px;
}

.noteText {
  text-align: center;
  margin-top: 10px;
  color: var(--obcolor-font-secondary);
  font-size: 14px;
  width: 100%;
  padding-left: 2px;
}

</style>