<template>
  <tr class="ddContainer">
    <td :colspan="oppPlayer == null ? 4 : 3">
      <div class="tableContainer">
        <table aria-label="Lightning Statistics">
          <tr class="statRow" v-for="(row, index) in statRows.myRowList" :key="index">
            <td class="statLabel">{{ row.label }}</td>
            <td></td>
            <td class="statText">{{ row.text }}</td>
            <td></td>
          </tr>
          <tr class="statRow" v-if="statRows.myRowList.length == 0">
            <td class="statLabel">No points earned</td>
            <td colspan="2"></td>
          </tr>
        </table>
      </div>
    </td>
    <template v-if="oppPlayer != null">
      <td></td>
      <td colspan="4">
        <div class="tableContainer">
          <table aria-label="Lightning Statistics">
            <tr class="statRow" v-for="(row, index) in statRows.oppRowList" :key="index">
              <td class="statTextOpp">{{ row.text }}</td>
              <td></td>
              <td class="statLabelOpp">{{ row.label }}</td>
              <td></td>
            </tr>
            <tr class="statRow" v-if="statRows.oppRowList.length == 0">
              <td class="statLabelOpp">No points earned</td>
            </tr>
          </table>
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    player: {type: Object, default: null},
    oppPlayer: {type: Object, default: null},
    contest: {type: Object, default: null},
  },

  computed: {
    statRows() {
      const scoringStats = this.$ScoringStats.getAllStats(this.contest.sport, true);
      const myRowList = [];
      const oppRowList = [];

      if (this.player && this.player.pctTaken != null) {
        myRowList.push({label: '% Owned', text: this.player.pctTaken + '%'});
      }
      if (this.oppPlayer && this.oppPlayer.pctTaken != null) {
        oppRowList.push({label: '% Owned', text: this.oppPlayer.pctTaken + '%'});
      }

      // Set the rows for each stat that isn't zero
      for (const stat of scoringStats) {
        const myStats = this.getStatString(false, stat);
        const oppStats = this.getStatString(true, stat);
        if (myStats != null) {
          myRowList.push(myStats);
        }
        if (oppStats != null) {
          oppRowList.push(oppStats);
        }
      }

      return {myRowList: myRowList, oppRowList: oppRowList};
    },
  },

  methods: {
    game(isOppPlayer = false) {
      const player = isOppPlayer ? this.oppPlayer : this.player;
      if (!player || !player.game) {
        return null;
      }
      return player.game;
    },

    getStatString(isOppTeam, stat, category) {
      if (this.getStat(stat.path, isOppTeam, true) != null && this.getStat(stat.path, isOppTeam, true) != 0) {
        // scoreNum + labelName gives "150 Passing Yards" for example
        const labelScoreNum = this.getStat(stat.path, isOppTeam, false);
        let labelName = stat.label;
        if (labelScoreNum == 1) {
          labelName = labelName.removePluralWords();
        } else {
          labelName = stat.label;
        }

        return {label: labelScoreNum + ' ' + labelName, text: this.getStat(stat.path, isOppTeam, true) + ' PTS'};
      }

      return null;
    },

    getStat(statPath, isOppTeam, isFpts = false) {
      const pathRoot = isFpts ? 'ObPoints' : 'stats';
      const game = this.game(isOppTeam);

      if (!game || !game[pathRoot] || !statPath) {
        return null;
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game[pathRoot][playerRole]) {
        return 0;
      }
      const stat = game[pathRoot][playerRole][statType];
      if (stat === null || stat === undefined) {
        return 0;
      }
      return stat;
    },
  },
};
</script>

<style lang="scss" scoped>
  .ddContainer {
    border-bottom: 1px solid var(--obcolor-background-page);
  }
  td {
    vertical-align: top;
  }
  .tableContainer {
    width: 100%;
    height: 100%;

    table {
      width: 100%;
    }
  }

  .statRow {
    font-size: 14px;
    height: 27px;
    border-bottom: 1px solid var(--obcolor-background-2);
  }
  .statRow:last-child {
    border-bottom: none;
  }

  .statLabel {
    padding: 5px 0 5px 15px;
    color: var(--obcolor-font-secondary);
  }
  .statText {
    padding: 5px 7px 5px 0;
    text-align: right;
  }

  .statLabelOpp {
    padding: 5px 15px 5px 0;
    color: var(--obcolor-font-secondary);
    text-align: right;
  }
  .statTextOpp {
    padding: 5px 0 5px 12px;
  }

  .middleField {
    background: var(--obcolor-background-6);
  }
</style>