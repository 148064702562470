<template>
  <div class="root">
    <div class="name">
      {{ name }}
    </div>
    <div class="payout-table-data">
      <div v-for="payout in payoutMultipliersSorted" :key="payout.count" class="row">
        <div class="payouts">
          <div v-for="multiplier in payout.multipliers" :key="multiplier.incorrect" class="payout">
            <div class="picks">
              <template v-if="multiplier.incorrect == 0">
                {{ payout.count }} Picks
              </template>
            </div>
            <div class="correct">{{ payout.count-multiplier.incorrect }}/{{ payout.count }} Correct</div>
            <div class="multipliers" :class="{payoutReduced: defaultMultipliers[payout.count][multiplier.incorrect] !== multiplier.multiplier}">
              <i class="payoutInfo far fa-info-circle"></i>
              <div class="pill">
                {{ multiplier.multiplier }}x
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-check
import Vue from 'vue';
export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    payoutMultipliers: {
      type: Object,
      required: true,
    },
    defaultMultipliers: {
      type: Object,
      required: true,
    },
  },
  computed: {
    payoutMultipliersSorted() {
      return this.payoutsToArray(this.payoutMultipliers);
    },
  },

  methods: {
    payoutsToArray(payouts) {
      return Object.entries(payouts).map(([count, multipliers])=> ({
        count: Number(count),
        multipliers: this.multipliersToArray(multipliers),
      })).sort((a, b) => b.count - a.count);
    },

    multipliersToArray(multipliers) {
      return Object.entries(multipliers).map(([incorrect, multiplier])=>({
        incorrect: Number(incorrect),
        multiplier: multiplier,
      })).sort((a, b)=> a.incorrect - b.incorrect);
    },
  },
});
</script>

<style lang="scss" scoped>
.root {
  border-radius: 5px;
  overflow: hidden;
  color: var(--obcolor-font-primary);
  border: var(--obcolor-background-1) solid 5px;
  line-height: 16px;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
.name {
  background-color: var(--obcolor-background-1);
  padding-bottom: 5px;
}
.payout-table-data {
  width: calc(100% - 16px);
  color: var(--obcolor-font-primary);
  margin: 8px;

  .row {
    display: flex;
    flex-direction: row;

    .picks {
      width: calc(100% / 3);
      text-align: left;
      color: var(--obcolor-font-secondary);
    }

    .payouts {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;
      padding: 3.5px 0;

      .payout {
        display: flex;
        flex-direction: row;
        align-items: center;
        > * {
          width: calc(100% / 3);
        }
        .multipliers {
          display: flex;
          gap: 8px;
          justify-content: right;
          align-items: center;

          .payoutInfo {
            display: none;
          }
          &.payoutReduced {
            color: var(--obcolor-ob-blue);
            .payoutInfo {
              display: inline;
            }
          }
        }
      }
    }
  }

  .row:not(:first-child) {
    border-top: solid 1px var(--obcolor-background-1);
  }
}

.pill {
  display: inline-block;
  background: var(--obcolor-background-1);
  border-radius: 4px;
  width: 42px;
  text-align: center;
  padding: 2px  4px;
  line-height: 16px;
}
</style>
