<template>
  <div class="notificationContainer">
    <div class="notificationList">
      <BattlePassNotification v-for="notification in currNotifications" :key="notification.id" :notification="notification"
        @animationEnded="removeNotification"
      />
    </div>
  </div>
</template>

<script>
import BattlePassNotification from '@/components/battlepass/notifications/BattlePassNotification';
import EventBus from '@/event-bus';
export default {
  components: {
    BattlePassNotification,
  },

  data() {
    return {
      notificationMap: {},
      notificationList: [],
    };
  },

  created() {
    EventBus.$on('BATTLE_PASS_CHALLENGE_NOTIFICATION', this.newNotification);
  },

  destroyed() {
    EventBus.$off('BATTLE_PASS_CHALLENGE_NOTIFICATION', this.newNotification);
  },

  computed: {
    currNotifications() {
      return this.notificationList.slice(0, 3);
    },
  },

  methods: {
    newNotification(challengeDiff) {
      if (!challengeDiff.old || !challengeDiff.new) {
        return;
      }

      const id = challengeDiff?.new?.id;

      // Don't duplicate the same notifications if somebody did multiple things in quick succession, the first notification is enough
      if (this.notificationMap[id]) {
        return;
      }

      const startTimestamp = this.$moment().valueOf();

      const notificationObject = {
        id: id,
        old: challengeDiff?.old,
        new: challengeDiff?.new,
        startTimestamp: startTimestamp,
      };

      this.$set(this.notificationMap, id, notificationObject);
      this.notificationList.push(notificationObject);
    },

    removeNotification(id) {
      this.$delete(this.notificationMap, id);
      this.notificationList = this.notificationList.slice().filter((n) => {
        return n?.id !== id;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notificationContainer {
  z-index: 10000;
  position: fixed;
  display: flex;
  right: 0;
  top: 30px;

  .notificationList {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>