<template>
  <div class="ob-box" style="width: 297px; margin-top: 5px; z-index: 9999; position: absolute;">
    <div class="obCalendarHeader">
      <div @click="prevMonth()" class="obDateArrow"><i class="fas fa-chevron-left"></i></div>
      <div style="flex-grow: 1; font-weight: bold;">{{ curMonth.format('MMM YYYY') }}</div>
      <div @click="nextMonth()" class="obDateArrow"><i class="fas fa-chevron-right"></i></div>
    </div>
    <div class="obCalendar">
      <!-- Headers -->
      <div v-for="n in 7" :key="'day' + n" class="weekdayHeader">
        <div style="text-align: center; margin-top: 5px;">{{ dayHeaders[n-1] }}</div>
      </div>

      <!-- Days -->
      <template v-for="(day, index) in calendar">
        <div :key="index" @click="selectDate(day)"
          :class="{obCalToday: day.isToday, obCalSelected: selectedDate.toString() === day.dateObj.toDate().toString(),
                   obCalendarDay: !day.faded, obCalendarNoDay: day.faded}"
        >
          {{ day.label }}
        </div>
      </template>

      <div class="ob-btn-grey" style="margin: 3px 0; width: 100%;" @click="selectToday()">Today</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    selectedDate: {
      type: Date,
      default: new Date(),
    },
  },

  data() {
    return {
      dayHeaders: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      calendar: [],
      curMonth: null,
    };
  },

  created() {
    this.setSelection(this.selectedDate);
    this.setupMonth(moment(this.selectedDate));
  },

  watch: {
    selectedDate(to, _from) {
      if (this.curMonth.month() != to.getMonth()) {
        this.setupMonth(moment(to));
      }
    },
  },

  methods: {
    selectDate(newDate) {
      this.$emit('date-change', newDate.dateObj.toDate());
      this.$emit('close-calendar');
    },

    selectToday() {
      const today = new Date();
      this.$emit('date-change', new Date(today.getFullYear(), today.getMonth(), today.getDate()));
      this.$emit('close-calendar');
    },

    setupMonth(date) {
      this.curMonth = date.clone();
      const today = moment(new Date()).format('YYYY-MM-DD');
      const calendarArray = [];
      const startOfMonth = this.curMonth.clone().startOf('month');
      const endOfMonth = this.curMonth.clone().endOf('month');
      const emptyDays = startOfMonth.day();

      const addDate = (newDate) => {
        calendarArray.push({
          label: newDate.date(),
          dateObj: newDate.clone(),
          date: newDate.format('YYYY-MM-DD'),
          isToday: newDate.format('YYYY-MM-DD') === today,
          faded: newDate.month() !== this.curMonth.month(),
        });
      };

      // Add days from previous month, if the month doesn't start on a sunday
      for (let i = 0; i < emptyDays; i++) {
        const prevDay = startOfMonth.clone().subtract(emptyDays - i, 'days');
        addDate(prevDay);
      }

      // Add all days for the selected month
      let currentDay = startOfMonth.clone();
      while (currentDay <= endOfMonth) {
        addDate(currentDay);
        currentDay = currentDay.add(1, 'days');
      }

      // Add days for the next month, if the month doesn't end on a saturday
      const maxDays = calendarArray.length <= 35 ? 35 : 42;
      while (calendarArray.length < maxDays) {
        addDate(currentDay);
        currentDay = currentDay.add(1, 'days');
      }

      this.calendar = calendarArray;
    },

    setSelection(date) {
      this.selection = date;
    },

    prevMonth() {
      this.setupMonth(this.curMonth.add(-1, 'months'));
    },

    nextMonth() {
      this.setupMonth(this.curMonth.add(1, 'months'));
    },
  },
};
</script>

<style lang="scss" scoped>
.obDateArrow {
  cursor: pointer;
  width: 30px;
  color: white;
  opacity: 0.75;
}

.obDateArrow:hover {
  opacity: 1;
}

.obCalendarDay, .obCalendarNoDay {
  cursor: pointer;
  width: 38px;
  height: 38px;
  margin: 0 1px 2px 1px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px transparent;
  border-radius: 4px;
}

.obCalendarDay {
  background: var(--obcolor-background-3);
}

.obCalendarNoDay {
  background: var(--obcolor-background-5);
}

.obCalendarDay:hover, .obCalendarNoDay:hover {
  background: var(--obcolor-background-2);
}

.obCalToday {
  border: 1px solid var(--obcolor-ob-blue);
}

.obCalSelected {
  background: var(--obcolor-background-1) !important;
}

.weekdayHeader {
  width: 38px;
  height: 28px;
  margin: 0 1px 2px 1px;
  font-weight: bold;
  border: solid 1px transparent;
}

.obCalendar {
  display: flex;
  flex-wrap: wrap;
  font-size: 9pt;
  text-align: center;
}

.obCalendarHeader {
  margin: auto;
  display: flex;
  margin-top: 2px;
  margin-bottom: 5px;
  text-align: center;
}
</style>