<template>
  <div class="ob-page battlePassPage">
    <div class="topSection">
      <BattlePassUserInfo class="containerBox" :profile="profile"
        :userInfo="battlePass ? battlePass.userInfo : null"
        :isLoading="loadingProfile" v-if="battlePass"
      />
      <div class="topRightSection loadingSection" v-if="isLoading">
        <ObLoading :size="80" />
      </div>
      <div class="topRightSection" v-else-if="battlePass">
        <BattlePassSummary class="containerBox" :battlePass="battlePass" />
        <BattlePassChallenges class="challengeBox containerBox" :battlePass="battlePass" />
      </div>
      <p class="noBattlePass" v-if="!firstBattlePassLoad && !battlePass">There is no active Battle Pass.</p>
    </div>
    <BattlePassLevels v-if="battlePass" class="containerBox" :battlePass="battlePass"
      :isLoading="isLoading"
    />
    <ConvertCurrencyModal />
  </div>
</template>

<script>
import BattlePassLevels from './BattlePassLevels';
import BattlePassUserInfo from './BattlePassUserInfo';
import BattlePassSummary from './BattlePassSummary';
import BattlePassChallenges from './BattlePassChallenges';
import ConvertCurrencyModal from '@/views/Profile/ConvertCurrencyModal';
import ObAccountApi from '@/api/ObAccountApi';
import ObLoading from '@/components/ObLoading';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';

export default {
  components: {
    BattlePassLevels,
    BattlePassUserInfo,
    BattlePassSummary,
    BattlePassChallenges,
    ConvertCurrencyModal,
    ObLoading,
  },

  data() {
    return {
      profile: null,
      loadingProfile: false,
    };
  },

  created() {
    this.loadProfile();
    EventBus.$emit('LOAD_BATTLE_PASS');
  },
  computed: {
    ...mapState(['userId', 'battlePass', 'loadingBattlePass', 'firstBattlePassLoad']),

    isLoading() {
      return this.loadingBattlePass && !this.battlePass;
    },
  },

  methods: {
    loadProfile() {
      this.loadingProfile = true;
      ObAccountApi.getProfile(this.userId).then((response) => {
        this.profile = response;
        this.loadingProfile = false;
      }).catch((_e) => {
        this.loadingProfile = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .boxHeader, .boxBody {
    width: 100%;
    box-sizing: border-box;
  }

  .boxHeader {
    padding: 10px;
    background: var(--obcolor-background-2);
  }
}
.battlePassPage {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topSection {
  display: flex;
  align-items: stretch;
  gap: 10px;
  height: 400px;
}

.topRightSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.loadingSection {
    justify-content: center;
    align-items: center;
  }

  > .containerBox {
    width: 100%;
  }
}

.containerBox {
  background: var(--obcolor-background-6);
  border-radius: 5px;
  overflow: hidden;
}

.challengeBox {
  flex: 1;
}

.noBattlePass {
  padding-top: 40px;
  margin: 0 auto;
}
</style>