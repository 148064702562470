<template>
  <div class="tabContainer">
    <div class="tabButton" :class="{ selected: selectedTab === 'matchup' }" @click="setSelectedTab('matchup')">
      Matchup
    </div>
    <div class="tabButton" :class="{ selected: selectedTab === 'stats' }" @click="setSelectedTab('stats')">
      Stats
    </div>
  </div>
</template>

<script>

import EventBus from '@/event-bus';
export default {
  props: {
    selectedTab: String,
  },
  methods: {
    setSelectedTab(tabName) {
      if (this.selectedTab !== tabName) {
        EventBus.$emit('GAME_MODAL_SELECT_TAB', tabName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.tabButton {
  float: left;
  width: 50%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--obcolor-text-secondary);
  cursor: pointer;
}

.tabContainer {
  width: 100%;
  background-color: var(--obcolor-background-page);
}

.tabContainer:after {
  content: "";
  display: table;
  clear: both;
  padding-bottom: 1px;
  color: var(--obcolor-background-page);
}

.tabButton:hover, .tabButton.selected {
  color: var(--obcolor-text);
  font-weight: bold;
  border-bottom: 3px solid var(--obcolor-ob-blue);
}

</style>