<template>
  <div>
    <div class="itemList">
      <div class="selectContainer" v-for="(slate, index) in gameSlates"
        :class="{selected: selected !== null && selected.id === slate.id}"
        @click="selectSlate(slate)" :key="index" @mouseover="showSlate(slate)"
      >
        <div class="title">
          {{ slate.name }}
        </div>
        <div class="description">
          <div>{{ $moment(slate.contestStart).format('dddd h:mma') }}</div>
          <div v-if="slate.games" class="numGames">{{ slate.games.length }} Games</div>
        </div>
      </div>
    </div>
    <div class="slateGames itemList">
      <template v-if="selected && !gameSlateData[selected.id]">
        <ObLoading :size="60" />
      </template>
      <div class="emptyText" v-else-if="!selected">
        Hover over a slate to view games
      </div>
      <template v-else>
        <div class="slateGame" v-for="(game, index) in gameSlateData[selected.id]" :key="index">
          <div class="slateName">{{ getGameName(game) }}</div>
          <div class="slateTime">{{ $moment(game.date_utc).format('MMM D, h:mma') }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ObLoading from '@/components/ObLoading';
export default {
  props: {
    sport: {type: String, default: null},
    gameSlates: {type: Array, default: null},
    gameSlateData: {type: Object, default: null},
    selected: {type: Object, default: null},
  },

  components: {
    ObLoading,
  },

  methods: {
    selectSlate(slate) {
      this.$emit('select', slate);
      this.$emit('nextStep');
    },

    showSlate(slate) {
      this.$emit('select', slate);
    },

    getGameName(game) {
      let homeName = game.home_team ? game.home_team.team_alias : null;
      let awayName = game.visitor_team ? game.visitor_team.team_alias : null;
      if (!homeName || !awayName) {
        return;
      }

      homeName = this.$TeamInfo.getDisplayAlias(this.sport, homeName);
      awayName = this.$TeamInfo.getDisplayAlias(this.sport, awayName);

      return awayName + ' @ ' + homeName;
    },

    getSlateDescription(slate) {
      return 'test';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Lobby/CreateContestModal/pages.scss';
.itemList.slateGames {
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  border-top: 4px solid var(--obcolor-background-4);
  padding: 10px 15px;
  box-sizing: border-box;

  .slateGame {
    background: var(--obcolor-background-6);
    border: 2px solid var(--obcolor-background-4);
    border-radius: 5px;
    padding: 5px 10px;
    width: 100px;
    margin: 5px;

    .slateName {
      font-size: 14px;
    }

    .slateTime {
      font-size: 14px;
      color: var(--obcolor-font-secondary);
    }
  }
}

.selectContainer .numGames {
    margin-top: 10px;
}

.emptyText {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--obcolor-font-secondary);
}
</style>