<template>
  <div slot="body">
    <div class="backBtn" @click.stop="backToImport()">
      <i class="fas fa-chevron-left"></i>
      <div class="backBtnText">Back</div>
    </div>
    <div v-if="lineups.length > 0" class="lineupsContainer">
      <table aria-labelledby="lineupTableHeader">
        <tr>
          <th></th>
          <th id="lineupTableHeader">Lineup</th>
          <th class="lineupHeader remainingSalary">Rem Salary</th>
          <th class="lineupHeader">Proj</th>
          <th class="checkboxContainer">
            <div class="ob-checkbox-container" @click="addAllLineups()">
              <input type="checkbox" :checked="allLineupsAdded">
              <div class="ob-checkbox"></div>
            </div>
          </th>
        </tr>
        <EditLineupEntry v-for="lineup in lineups" :key="lineup.row" :lineup="lineup"
          :contest="contest" :playerMap="playerMap" :submit="lineup.submit"
        />
      </table>
    </div>
  </div>
</template>

<script>
import EditLineupEntry from './EditLineupEntry';
import EventBus from '@/event-bus';

export default {
  components: {
    EditLineupEntry,
  },

  props: {
    contest: {type: Object, default: null},
    playerMap: {type: Object, default: null},
    lineups: {type: Array, default: null},
  },

  data() {
    return {
      allLineupsAdded: false,
    };
  },

  created() {
    EventBus.$on('TOGGLE_EDIT_ALL_LINEUPS', this.toggleAllEditLineupSubmit);
  },

  destroyed() {
    EventBus.$off('TOGGLE_EDIT_ALL_LINEUPS', this.toggleAllEditLineupSubmit);
  },

  methods: {
    addAllLineups() {
      this.allLineupsAdded = !this.allLineupsAdded;
      EventBus.$emit('TOGGLE_ALL_EDIT_LINEUP_SUBMIT', this.allLineupsAdded);
    },

    toggleAllEditLineupSubmit() {
      this.allLineupsAdded = false;
    },

    backToImport() {
      this.allLineupsAdded = false;
      EventBus.$emit('BACK_TO_EDIT_LINEUPS_IMPORT');
    },
  },
};
</script>

<style lang="scss" scoped>

  table {
    width: 100%;
    font-size: 14px;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px;
  }

  tr:not(:first-child) {
    border-top: 1px solid var(--obcolor-background-2);
  }

  th {
    font-weight: normal;
    font-size: 14px;
    line-height: 250%;
    color: var(--obcolor-text-primary);
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    min-width: 40px;
    text-align: left;
  }

  td {
    padding: 8px 4px 8px 4px;
    min-width: 50px;
    height: 50px;
  }

  .lineupsContainer {
    padding: 0px 40px 0px 40px;
  }

  input[type="file"] {
    display: none;
  }

  .lineupHeader {
    text-align: center;
  }

  .backBtn {
    width: 80px;
    display: flex;
    flex: 1;
    font-weight: bold;
    color: var(--obcolor-text-primary);
    align-items: center;
    text-align: right;
    height: 50px;
    margin-left: 40px;
    cursor: pointer;
  }

  .backBtn:hover .backBtnText {
    text-decoration: underline;
  }

  .backBtnText {
    margin-left: 10px;
  }

  .remainingSalary {
    min-width: 72px;
  }

  .checkboxContainer {
    padding-left: 15px;
  }
</style>