<template>
  <div class="challengesBox">
    <div class="boxHeader">
      <div>Challenges</div>
      <div v-if="challengeCategories">{{ totCompleted }}/{{ totChallenges }} Complete</div>
    </div>
    <BattlePassChallengeList class="boxBody" :battlePass="battlePass" />
  </div>
</template>

<script>
import BattlePassChallengeList from '@/views/BattlePass/BattlePassChallengeList.vue';

export default {
  props: {
    battlePass: {type: Object, default: null},
  },

  components: {
    BattlePassChallengeList,
  },


  computed: {
    challengeCategories() {
      if (!this.battlePass?.challengeCategories) {
        return [];
      }

      return this.battlePass?.challengeCategories;
    },

    totCompleted() {
      return this.totAndCompletedChallenges.complete;
    },

    totChallenges() {
      return this.totAndCompletedChallenges.tot;
    },

    totAndCompletedChallenges() {
      let numComplete = 0;
      let numChallenges = 0;

      for (const category of this.challengeCategories) {
        if (!category.challenges) {
          continue;
        }
        for (const challenge of category.challenges) {
          numChallenges++;
          if (challenge.isCompleted) {
            numComplete++;
          }
        }
      }

      return {complete: numComplete, tot: numChallenges};
    },
  },
};
</script>

<style lang="scss" scoped>
.challengesBox {
  display: flex;
  flex-direction: column;
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
</style>