<template>
  <div>
    <div class="boxHeader">
      <div class="boxTitle">Rewards</div>
      <div v-if="battlePass && battlePass.levels" class="pagingContainer">
        <div v-if="canClickPrev" class="controlBtn" @click="prevPage">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div v-else class="controlBtn notAllowedBtn">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="pageText">
          Page {{ curPage }} (Levels {{ curMinLevel + 1 }}-{{ curMaxLevel }})
        </div>
        <div v-if="canClickNext" class="controlBtn" @click="nextPage">
          <i class="fas fa-chevron-right"></i>
        </div>
        <div v-else class="controlBtn notAllowedBtn">
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>
      <div class="seasonEnd">
        <span>Season ends in </span><CountdownTimer class="countdownText" :end="battlePass.endDate" :min="0"
          :textClock="true" :clockFormat="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="levelsList loadingLevels">
      <ObLoading :size="80" />
    </div>
    <div v-else-if="battlePass && battlePass.levels" class="levelsList boxBody">
      <div class="levelBox" v-for="(level, index) in pageLevels" :key="index"
        :class="{ fadeLevel: userHasLevel(level.level) }"
      >
        <div class="levelNum">
          <LevelIcon :level="level.level + 1" :size="30" />
        </div>
        <template v-if="levelReward(level) && levelReward(level).labels && getRewardIcon(levelReward(level))">
          <div class="levelImg">
            <img v-if="getRewardIcon(levelReward(level))" :src="getRewardIcon(levelReward(level))" alt="Level reward icon">
            <div class="levelIconStatus">
              <i class="fas fa-lock" v-if="!userHasLevel(level.level)"></i>
              <i class="checkIcon fas fa-check" v-else></i>
            </div>
          </div>
          <div class="textListContainer">
            <div class="levelTextList" v-if="levelReward(level).labels">
              <div class="levelText" v-for="(rewardText, index1) in levelReward(level).labels" :key="index1">
                {{ rewardText }}<span v-if="index1 < levelReward(level).labels.length - 1">, </span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="levelImg noLevel">
            <img class="noLevelImg" :src="require('@/assets/obicon_' + (theme === 'dark' ? 'white' : 'black') + '.png')"
              alt="No reward icon"
            >
          </div>
        </template>
      </div>
    </div>
    <div v-else class="levelsList emptyLevels">
      No level rewards currently available.
    </div>
  </div>
</template>

<script>
import CountdownTimer from '@/components/CountdownTimer';
import LevelIcon from '@/components/battlepass/LevelIcon';
import ObLoading from '@/components/ObLoading';
import {getRewardIcon} from '@/utils/shared/BattlePassUtils';
import {mapState} from 'vuex';

export default {
  props: {
    battlePass: {type: Object, default: null},
    isLoading: {type: Boolean, default: null},
  },

  components: {
    CountdownTimer,
    LevelIcon,
    ObLoading,
  },

  data() {
    return {
      curPage: 1,
      levelsPerPage: 10,
    };
  },

  watch: {
    isLoading() {
      this.scrollToLevel();
    },
  },

  computed: {
    ...mapState(['theme']),

    levelsDisplayList() {
      const firstLevel = {
        level: 0,
      };

      if (!this.battlePass?.levels) {
        return null;
      }

      const levelsList = this.battlePass?.levels.slice();
      levelsList.unshift(firstLevel);

      return levelsList;
    },

    pageLevels() {
      if (!this.levelsDisplayList) {
        return [];
      }
      return this.levelsDisplayList.slice(this.curMinLevel, this.curMaxLevel);
    },

    maxPage() {
      return Math.ceil(this.levelsDisplayList.length / this.levelsPerPage);
    },

    curMinLevel() {
      return ((this.curPage - 1) * this.levelsPerPage);
    },

    curMaxLevel() {
      const maxLevel = this.curMinLevel + this.levelsPerPage;
      if (!this.battlePass || !this.levelsDisplayList) {
        return 1;
      }

      if (this.levelsDisplayList.length < maxLevel) {
        return this.levelsDisplayList.length;
      }
      return maxLevel;
    },

    userLevel() {
      return this.battlePass?.userInfo?.currentLevel;
    },

    canClickNext() {
      return this.curPage < this.maxPage;
    },

    canClickPrev() {
      return this.curPage > 1;
    },
  },

  methods: {
    nextPage() {
      if (this.canClickNext) {
        this.curPage = this.curPage + 1;
      }
    },

    prevPage() {
      if (this.canClickPrev) {
        this.curPage = this.curPage - 1;
      }
    },

    userHasLevel(levelNum) {
      return this.userLevel > levelNum;
    },

    scrollToLevel() {
      if (!this.battlePass?.levels || !this.userLevel) {
        return;
      }

      const pageToScroll = Math.ceil(this.userLevel / this.levelsPerPage);
      this.curPage = pageToScroll;
    },

    levelReward(level) {
      if (!this.battlePass?.levels) {
        return null;
      }

      const levelList = this.battlePass?.levels;
      const levelNum = level.level;

      return levelList[levelNum - 1]?.rewards;
    },

    getRewardIcon,
  },
};
</script>

<style lang="scss" scoped>
.boxHeader {
  display: flex;
  align-items: center;
  font-size: 14px;

  .boxTitle {
    font-size: 16px;
  }

  > div {
    flex: 1;
  }
}

.pagingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .pageText {
    margin-top: -2px;
  }

  .controlBtn {
    font-size: 18px;
    cursor: pointer;

    &.notAllowedBtn {
      color: var(--obcolor-font-secondary);
      cursor: not-allowed;
    }

    &:not(.notAllowedBtn):hover {
      color: var(--obcolor-font-secondary);
    }
  }
}

.editNameBtn {
  color: var(--obcolor-font-secondary);
  margin-left: 5px;
  cursor: pointer;
}

.editNameBtn:hover {
  color: var(--obcolor-font-primary);
}

.seasonEnd {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}

.levelsList {
  display: flex;
  height: 145px;
  box-sizing: border-box;

  &.emptyLevels {
    justify-content: center;
    padding-top: 10px;
    color: var(--obcolor-font-secondary);
  }

  &.loadingLevels {
    justify-content: center;
    align-items: center;
  }

  .levelBox {
    position: relative;
    flex: 1;
    max-width: 145px;
    box-sizing: border-box;
    height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    &:not(:nth-child(10)) {
      border-right: 4px solid var(--obcolor-background-2);
    }

    &.fadeLevel {
      filter: opacity(0.5);
      background: var(--obcolor-background-4);
    }

    .levelNum {
      height: 45px;
      margin-top: 5px;
    }

    .levelImg.noLevel {
      flex: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 0;

      .noLevelImg {
        margin-top: 10px;
        height: 50px;
        width: 70px;
        filter: grayscale(1) opacity(0.2);
      }
    }

    .levelImg {
      position: relative;
      width: 40px;
      margin-top: 10px;

      img {
        width: 100%;
      }

      .levelIconStatus {
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--obcolor-background-2);
        font-size: 10px;
        color: var(--obcolor-font-secondary);

        > i {
          margin-right: -1px;
          margin-top: -1px;
        }

        .checkIcon {
          color: var(--obcolor-green);
        }
      }
    }

    .textListContainer {
      font-size: 14px;
      height: 60px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .levelTextList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .levelText {
          display: flex;
        }
      }
    }
  }
}
</style>