<template>
  <div class="gameContainer" :class="getContainerClass()" @click.stop="openGameModal()">
    <div>
      <!-- Team selection tab -->
      <div>
        <span>{{ game.home === 'true' ? 'vs' : '@' }}</span>
        <img class="teamLogo" :src="getTeamLogo(game.opp_alias)" style="margin-right: 3px;"
          :alt="game.opp_alias"
        >
        <span v-if="game.result == null">{{ parseInt(game.timestamp) | moment("h:mma") }}</span>
        <span v-else>{{ game.score }}</span>
      </div>

      <!-- Game Projection -->
      <div class="projContainer" v-if="game.ProjPoints != null">
        <PlayerStatus class="playerStatus" v-if="game.status && game.status !== 'UNKNOWN'" :status="game.status" />
        <div>Proj {{ game.ProjPoints.total }}</div>
        <i v-if="game.ObPoints == null && gameOverSalaryCap()" class="fas fa-usd-circle" style="color: red; margin-left: 4px;"
          v-tooltip.bottom="{content: salaryWarning, class:'ObTooltipHeat'}"
        ></i>
      </div>

      <div v-if="showDNP()" style="margin-top: 5px;">DNP</div>

      <!-- Game Result Points -->
      <div v-else-if="game.ObPoints != null" style="margin-top: 5px;">
        <template v-if="gameWasPlayed() && !gameOverSalaryCap()">
          <span v-if="game.ObPoints.total > -1" style="color: var(--obcolor-ob-blue);">+{{ game.ObPoints.total }} PTS</span>
          <span v-else style="color: red;">{{ game.ObPoints.total }} PTS</span>
        </template>
        <template v-else>
          <span v-if="game.ObPoints.total > -1">+{{ game.ObPoints.total }} PTS</span>
          <span v-else>{{ game.ObPoints.total }} PTS</span>
        </template>

        <i v-if="gameOverSalaryCap()" class="fas fa-usd-circle" style="color: red; margin-left: 4px;"
          v-tooltip.bottom="{content: salaryWarning, class:'ObTooltipHeat'}"
        ></i>
      </div>

      <div v-if="showSalary()" class="salary" :style="gameOverSalaryCap() ? 'color: red;' : ''">
        {{ player.salaryPG.formatMoney() }}
      </div>

      <div>
        <div v-if="gameWasBenched()" style="margin-top: 2px;">
          <span class="benchedGame" style="font-weight: bold;">Benched</span>
        </div>

        <div v-if="!showDNP() && game.partial" style="margin-top: 2px;" v-tooltip.bottom="{content: partialGame, class:'ObTooltipHeat'}">
          <span style="font-weight: bold;">DNS</span>
        </div>

        <!-- Activate / Deact Switch -->
        <!-- Switch will only be active in some situations (proj gp differs from original) -->
        <div v-if="canDeactivate()"
          class="ob-btn-grey deactivateSliderContainer"
          :class="gameDeactivated() ? 'deactivatedGame' : ''"
          @click.stop="deactivateGame()"
          v-tooltip.bottom.notrigger="{ content: deactivateError, class: 'ObTooltip ob-tooltip-fadeout', visible: deactivateError }"
        >
          <div><i v-if="processing" class="loadingSlider fad fa-spinner-third fa-spin"></i></div>
          <div class="deactivateSlider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import EventBus from '@/event-bus';
import PlayerStatus from '@/components/PlayerStatus.vue';

export default {

  props: {
    leagueId: String,
    sport: String,
    player: Object,
    game: Object,
    playedGames: Object,
    overSalaryGames: Object,
    bestBallGames: Object,
    isMyTeam: Boolean,
    allowDeactivate: Boolean,
    teamId: String,
  },

  components: {
    PlayerStatus,
  },

  data: function() {
    return {
      processing: false,
      salaryWarning: 'This game does not count as it would put you over the salary cap',
      partialGame: 'Did Not Start',
      deactivateError: '',
    };
  },

  methods: {

    getContainerClass() {
      let classes = '';

      if (!this.showDNP() && this.game.partial) {
        classes += 'partialGame ';
      }

      if (!this.game.inRange) {
        classes += 'fadedGame ';
      }

      if (this.showBenched()) {
        classes += 'benchedGame ';
      }

      if (this.gameHasStarted()) {
        classes += 'selectableGame ';
      }

      const gameKey = this.player.id + '_' + this.game.id;
      if (this.bestBallGames[gameKey]) {
        classes += 'bestballGame ';
      }

      const curProj = this.game.ProjPoints.total;
      const origProj = this.player.projGames[this.game.id];
      if (curProj > 0 && origProj <= 0) {
        classes += 'addedGame ';
      }

      return classes;
    },

    openGameModal() {
      if (this.gameHasStarted()) {
        const setTeam = this.game.home === 'true' ? 'home' : 'away';
        EventBus.$emit('GAME_MODAL_OPEN', {game: this.game, sport: this.sport, setTeam: setTeam});
      }
    },

    canDeactivate() {
      if (!this.allowDeactivate) {
        return false;
      }
      if (this.player.dropped || this.gameHasStarted() || !this.isMyTeam) {
        return false;
      }
      // Can't deactivate games with 0 projection
      return this.game.ProjPoints && this.game.ProjPoints.total > 0;
    },

    showBenched() {
      return this.gameWasBenched() || this.gameOverSalaryCap() || this.gameDeactivated();
    },

    gameWasBenched() {
      return (!this.isMyTeam || this.gameHasStarted()) && this.gameDeactivated();
    },

    showDNP() {
      return this.gameHourAfterStart() && !this.game.played;
    },

    showSalary() {
      if (this.player.dropped) {
        return false;
      }
      if (this.gameHasStarted() || this.gameDeactivated()) {
        return false;
      }
      return this.game.ProjPoints && this.game.ProjPoints.total > 0;
    },

    getTeamLogo(alias) {
      return require('@/assets/logos/'+ this.sport.toLowerCase() +'_'+ alias.toLowerCase() +'.png');
    },

    deactivateGame() {
      this.processing = true;
      const game = this.game;
      const player = this.player;
      const teamId = this.teamId;

      const isActive = !this.gameDeactivated();
      ObSalaryCapApi.deactivatePlayer(this.leagueId, teamId, !isActive, player.draftPos, player.id, game.id)
          .then((data) => {
            if (data != null && data === 'success') {
              this.$set(player.deactGames, game.id, isActive ? 1 : null);
            }
            this.processing = false;
            EventBus.$emit('PLAYER_DEACTIVATED');
          })
          .catch((error) =>{
            this.processing = false;
            this.deactivateError = error.response.data;
            this.clearDeactivateError();
          });
    },

    clearDeactivateError() {
      setTimeout(() => {
        this.deactivateError = '';
      }, 3000);
    },

    gameDeactivated() {
      return this.player.deactGames[this.game.id] != null;
    },

    gameWasPlayed() {
      const gameKey = this.player.id + '_' + this.game.id;
      return this.playedGames[gameKey] && !this.game.partial;
    },

    gameOverSalaryCap() {
      const gameKey = this.player.id + '_' + this.game.id;
      return this.overSalaryGames[gameKey];
    },

    gameHasStarted() {
      const now = new Date().getTime();
      const gTime = parseInt(this.game.timestamp);
      return now > gTime;
    },

    gameHourAfterStart() {
      const now = new Date().getTime();
      const gTime = parseInt(this.game.timestamp);
      return now > (gTime + (3600000 * 6));
    },

    gameIsActive() {
      if (!this.game.inRange) {
        return false;
      }
      if (this.game.result == null) {
        // Game hasnt finished yet
        return true;
      }
      return this.game.played;
    },

  },

};
</script>

<style lang="scss" scoped>
.gameContainer {
  width: fit-content;
  margin: auto;
  min-width: 85px;
  min-height: 45px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projContainer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.playerStatus {
  display: inline-block;
  margin-right: 5px;
  margin-top: 1px;
}

.teamLogo {
  height: 22px;
  margin-bottom: -6px;
  margin-left: 2px;
  margin-right: 2px;
}

.deactivatedGame {
  background: red;
  color: #0a860a49;
}
.deactivatedGame:hover {
  background: #db0000 !important;
}

.deactivateSliderContainer {
  padding: 3px;
  width:40px;
  height: 12px;
  margin-top: 3px;
  position: relative;
}
.deactivateSlider {
  position: relative;
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  border-radius: 3px;
  left: 50%;
  background: var(--obcolor-background-page);
  transition: left 0.3s;
}
.deactivatedGame .deactivateSlider {
  left: 0;
}

.loadingSlider {
  box-sizing: border-box;
  position: absolute;
  margin-left: -38px;
  color: var(--obcolor-font-primary);
}

.salary {
  margin-top: 3px;
  font-weight: bold;
  color: var(--obcolor-green);
}

// Game Types : order matters, last entry will have priority

.selectableGame {
  cursor: pointer;
}
.selectableGame:hover {
  background: var(--obcolor-background-2);
}
.addedGame {
  color: var(--obcolor-green);
}
.fadedGame {
  opacity: 0.3;
}
.partialGame {
  color: var(--obcolor-text-faded);
}
.benchedGame {
  color: red;
}
.bestballGame {
  color: var(--obcolor-NBA);
}

</style>
