<template>
  <Modal v-if="isOpen" v-model="isOpen" containerStyle="width: 405px; padding: 0; overflow: hidden;"
    bodyStyle="margin: 0; background: var(--obcolor-background-3);"
    :hideCloseButton="true"
  >
    <div slot="body">
      <table class="salaryTable" aria-label="Salary Table">
        <tr>
          <td>
            <div class="salaryHeader">COMMITTED SALARY</div>
            <div class="salaryVal">{{ dollarFormat(totSpent) }}</div>
          </td>
          <td>
            <div class="salaryHeader">REMAINING SALARY</div>
            <div class="salaryVal" :class="isOverSalaryCap ? 'redText' : 'moneyText'">
              {{ dollarFormat(contest.salaryCap - totSpent) }}
            </div>
          </td>
          <td>
            <div class="salaryHeader">PROJECTED</div>
            <div class="salaryVal blueText">{{ $SportInfo.totalProjections(roster, picks) }}</div>
          </td>
        </tr>
        <tr v-if="contest.salaryCap && contest.format === 'WEEKLY'">
          <td>
            <span class="salaryVal" style="font-size: 14px">
              {{ totPicked > 0 ? dollarFormat(totSpent / totPicked) : '$0' }}
            </span>
            <span class="statTitle" style="font-size: 14px;">Average</span>
          </td>
          <td>
            <span class="salaryVal" style="font-size: 14px"
              :style="avgRemainingSalary < 0 ? 'color: red' : ''"
            >
              {{ dollarFormat(avgRemainingSalary) }}
            </span>
            <span class="statTitle" style="font-size: 14px">Average</span>
          </td>
          <td></td>
        </tr>
      </table>

      <!-- List of boxes showing games/cost per position -->
      <div class="posRow" v-if="contest.format === 'WEEKLY'">
        <div class="posField" v-for="(posInfo, index) in roster" :key="index"
          :style="'border: 1px solid ' + posStatusInfo(posInfo).bg"
        >
          <div class="posTitle" :style="'background: ' + posStatusInfo(posInfo).bg + '; color: ' + posStatusInfo(posInfo).fg">
            {{ posInfo.short }}
          </div>
          <div class="posVal">
            <span :style="posStatusInfo(posInfo).text ? 'color: ' + posStatusInfo(posInfo).bg : ''">{{ posFilledGames(posInfo.short) }}</span>
            <span> / {{ posInfo.maxGames }}</span>
          </div>
          <div class="posVal" style="font-size: 12px; color: var(--obcolor-font-secondary)">{{ totCostByPosition(posInfo.short) }}</div>
        </div>
      </div>
      <!-- List of picked players in roster -->
      <div class="playerList ob-scroll">
        <!-- Use preset groupings for positions -->
        <div class="playerRow" v-for="(posList, index) in displayGroups" :key="index">
          <!-- Go through each position in grouping array -->
          <template v-for="(pos, index1) in posList">
            <!-- For reduced rosters, some parts of the full roster might not be there -->
            <template v-if="rosterAtPos(pos)">
              <!-- Make a box for each player in that position -->
              <SalaryCapRosterPlayer v-for="(player, index2) in picks[pos]"
                :class="{ selectedPlayer: highlightSelectedPlayer(player.id) }"
                :key="index + '_' + index1 + '_' + index2"
                :player="player" :contest="contest" :pos="pos"
                :statusMap="statusMap"
                :style="changedPlayers[player.id] ? 'border: 2px solid red;' : 'border: 2px solid #00000000;'"
                :allowClick="false"
              />

              <SalaryCapRosterPlayer v-for="index3 in rosterAtPos(pos).num - picks[pos].length"
                :statusMap="statusMap"
                :key="index + '_' + index1 + '_' + index3 + '_empty'" :pos="pos"
                :allowClick="false"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <div slot="footer" style="background: var(--obcolor-background-5); padding: 10px 15px;">
      <!-- Edit Existing Lineup -->
      <template v-if="editMode">
        <div class="footerText" style="margin-top: 4px;">
          Click confirm to modify this lineup.<br>
          You can edit submitted lineups up until the contest starts.
        </div>
        <div v-if="failedText" class="failedText">{{ failedText }}</div>
        <div class="btnList">
          <button class="ob-btn-grey" @click="closeModal()">Cancel</button>
          <button disabled class="ob-btn-grey" style="display: flex; align-items: center; justify-content: center; height: 32px;"
            v-if="submitting == true"
          >
            <ObLoading :size="35" style="margin-left: -20px; margin-top: -2px;" />
            <span style="color: white;">Confirming</span>
          </button>
          <button v-else class="ob-btn" @click="confirmEdit()">Confirm Changes</button>
        </div>
      </template>

      <!-- Projected Games / Salary has Changed -->
      <template v-else-if="showInvalidLineup()">
        <div class="footerText">
          <div style="margin-top: -12px; margin-bottom: 8px;">Your team is invalid</div>
          <div>
            The highlighted players projected games have changed, resulting in your team being over the salary cap.
            Please adjust your team and re-submit.
          </div>
        </div>
        <div class="btnList">
          <button class="ob-btn-grey" @click="closeModal()">Continue</button>
        </div>
      </template>

      <!-- Submit New Lineup -->
      <template v-else>
        <div class="balanceContainer">
          <div style="display: flex; align-items: center;">
            <div class="balanceTitle">{{ contest.realMoney ? 'Balance' : 'OwnersBucks' }}:</div>
            <div class="balanceText" :class="contest.realMoney ? 'moneyText' : 'blueText'">
              <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              <span style="margin-left: 2px;">{{ contest.realMoney ? accountBalance.removeCentsIfZero() : ownersbucks.removeCentsIfZero() }}</span>
            </div>
          </div>
          <div style="display: flex; align-items: center;">
            <div class="balanceTitle">Entry Fee: </div>
            <div class="balanceText" :class="contest.realMoney ? 'moneyText' : 'blueText'">
              <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              <span style="margin-left: 2px;">{{ totEntryFee }}</span>
            </div>
          </div>
        </div>
        <template v-if="!isMultiCreate">
          <div v-if="promoInfo && promoInfo.state == 'VALID'" class="promoContainer">
            <div class="promoText" :class="contest.realMoney ? 'moneyText' : 'blueText'" @click="openPromoModal()">
              <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              <span v-else style="margin-left: 2px; margin-right: 5px;">{{ contest.fee.removeCentsIfZero() }} Ticket applied</span><i class="fas fa-check-circle"><div class="iconBg"></div></i>
            </div>
            <div class="removeText" @click="clearCode()">Remove</div>
          </div>
          <div v-else-if="!promoInfo || promoInfo.state == 'NO_CODE'" class="promoContainer">
            <div class="addCodeText" @click="openPromoModal()">Add Ticket</div>
          </div>
        </template>
        <div v-if="promoInfo && promoInfo.state == 'VALID' && !isMultiCreate" class="footerText">
          Click confirm to submit this lineup. No entry fee will be charged for this lineup.
        </div>
        <div v-else class="footerText">
          Click confirm to submit this lineup.
          The entry fee will be subtracted from your balance.
          You can edit submitted lineups up until the contest starts.
        </div>
        <div class="failedText" v-if="failedText">{{ failedText }}</div>

        <!-- Submit Buttons -->
        <div class="btnList">
          <button class="ob-btn-grey" @click="closeModal()">Cancel</button>

          <!-- Disabled when user fails verification -->
          <button v-if="!canSubmit" disabled class="ob-btn-grey disabledConfirm">
            <span>{{ confirmText }}</span>
          </button>

          <!-- Processing -->
          <button v-else-if="submitting == true" disabled class="ob-btn-grey disabledConfirm">
            <ObLoading :size="35" style="margin-left: -20px; margin-top: -2px;" />
            <span style="color: white;">Confirming</span>
          </button>

          <!-- Submit -->
          <button v-else class="ob-btn" @click="confirmPicks()">{{ confirmText }}</button>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import EventBus from '@/event-bus';
import SalaryCapRosterPlayer from '@/views/SalaryCapGame/SalaryCapRosterPlayer';
import ObLoading from '@/components/ObLoading';
import SalaryCapGameUtils from '@/utils/views/SalaryCapGameUtils';
import {mapState} from 'vuex';

export default {
  components: {
    Modal,
    SalaryCapRosterPlayer,
    ObLoading,
  },

  data() {
    return {
      isOpen: false,
      picks: null,
      contest: null,
      totSpent: null,
      selectedPlayer: null,
      statusMap: null,
      lineupName: null,

      playerSalaries: {},
      changedPlayers: {},

      entryInfo: null,
      promoInfo: {},

      submitting: false,
      failedText: null,
      editMode: true,

      canSubmit: true,
    };
  },

  created() {
    EventBus.$on('OPEN_SALARY_CAP_CONFIRM_MODAL', this.openModal);
    EventBus.$on('SALARY_CAP_MODAL_UPDATE', this.updatePicks);
    EventBus.$on('SALARY_CAP_SUBMIT_SUCCESS', this.submitSuccess);
    EventBus.$on('SALARY_CAP_EDIT_SUCCESS', this.submitSuccess);
    EventBus.$on('SALARY_CAP_SUBMIT_FAILED', this.submitFailed);
    EventBus.$on('SALARY_CAP_PREVENT_SUBMIT', this.preventSubmit);
  },

  destroyed() {
    EventBus.$off('OPEN_SALARY_CAP_CONFIRM_MODAL', this.openModal);
    EventBus.$off('SALARY_CAP_MODAL_UPDATE', this.updatePicks);
    EventBus.$off('SALARY_CAP_SUBMIT_SUCCESS', this.submitSuccess);
    EventBus.$off('SALARY_CAP_EDIT_SUCCESS', this.submitSuccess);
    EventBus.$off('SALARY_CAP_SUBMIT_FAILED', this.submitFailed);
    EventBus.$off('SALARY_CAP_PREVENT_SUBMIT', this.preventSubmit);
  },

  computed: {
    ...mapState(['accountBalance', 'ownersbucks']),

    isMultiCreate() {
      return SalaryCapGameUtils.isMultiCreate(this.contest);
    },

    multiCreateData() {
      return SalaryCapGameUtils.multiCreateData(this.contest);
    },

    multiCreateFees() {
      return SalaryCapGameUtils.multiCreateFees(this.contest);
    },

    multiCreatePrizes() {
      return SalaryCapGameUtils.multiCreatePrizes(this.contest);
    },

    displayGroups() {
      if (!this.contest) {
        return [];
      }
      return this.$SportInfo.getScDisplayGroups(this.contest.sport, this.contest.format == 'DAILY');
    },

    totEntryFee() {
      if (this.isMultiCreate) {
        return this.multiCreateFees.formatMoneyDecimals().removeCentsIfZero();
      }
      let entryCount = this.entryInfo.entryCount;
      if (this.promoInfo && this.promoInfo.state == 'VALID') {
        entryCount--;
      }

      const fee = (this.contest.feeValue * entryCount).dollarWithCentsFormat();
      return this.contest.realMoney ? '$' + fee : fee;
    },

    confirmText() {
      if (this.entryInfo.entryCount > 1) {
        return 'Confirm (' + this.entryInfo.entryCount + ')';
      }
      return 'Confirm';
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    isOverSalaryCap() {
      return this.contest.salaryCap - this.totSpent < 0;
    },

    totPicksRemaining() {
      let tot = 0;
      for (const posInfo of this.roster) {
        tot += posInfo.num;
      }
      return tot - this.totPicked;
    },

    avgRemainingSalary() {
      if (this.totPicksRemaining == 0) {
        return 0;
      }

      if (this.totSpent == null) {
        return this.contest.salaryCap / this.totPicksRemaining;
      }

      const remainingAvg = (this.contest.salaryCap - this.totSpent) / this.totPicksRemaining;
      return remainingAvg.toFixed(0);
    },

    totPicked() {
      let tot = 0;
      for (const posInfo of this.roster) {
        if (this.picks[posInfo.short]) {
          tot += this.picks[posInfo.short].length;
        }
      }

      return tot;
    },
  },

  methods: {
    openModal(entryInfo) {
      this.entryInfo = null;
      this.entryInfo = entryInfo;
      this.canSubmit = true;

      if (entryInfo != null) {
        this.changedPlayers = {};
        this.contest = entryInfo.contest;
        this.picks = entryInfo.picks;
        this.playerSalaries = this.buildSalariesJSON(entryInfo.picks);
        this.totSpent = entryInfo.totSpent;
        this.selectedPlayer = entryInfo.selectedPlayer;
        this.statusMap = entryInfo.statusMap;
        this.lineupName = entryInfo.lineupName;
        this.isOpen = true;
        this.editMode = entryInfo.editMode;
        this.promoInfo = entryInfo.promoInfo || {};
      }
    },

    openPromoModal() {
      EventBus.$emit('SALARY_CAP_OPEN_PROMO_CODE_MODAL', this.contest, this.promoInfo, {reopenOnCode: true, entryInfo: this.entryInfo});
      this.closeModal();
    },

    updatePicks(entryInfo) {
      // Detect salary changes to alert the user
      const newSalaries = this.buildSalariesJSON(entryInfo.picks);
      this.detectChangedSalaries(this.playerSalaries, newSalaries);
      this.playerSalaries = newSalaries;
      this.picks = null;
      this.picks = entryInfo.picks;
      this.totSpent = entryInfo.totSpent;
    },

    // Disable "selected player" background when daily contest (selected player isn't used in daily contests)
    highlightSelectedPlayer(playerId) {
      return (this.selectedPlayer.id == playerId) && this.contest.format != 'DAILY';
    },

    buildSalariesJSON(picksJSON) {
      const salaries = {};
      for (const pos in picksJSON) {
        const players = picksJSON[pos];
        for (const player of players) {
          salaries[player.id] = {salary: player.salary};
        }
      }
      return salaries;
    },

    detectChangedSalaries(prevData, newData) {
      const changed = {};
      for (const pid in prevData) {
        if (prevData[pid].salary !== newData[pid].salary) {
          changed[pid] = true;
        }
      }
      this.changedPlayers = changed;
    },

    showInvalidLineup() {
      return this.isOverSalaryCap && Object.keys(this.changedPlayers).length > 0;
    },

    clearCode() {
      this.promoInfo = {code: '', state: 'NO_CODE'};
      EventBus.$emit('SALARY_CAP_SET_PROMO_CODE', {code: '', state: 'NO_CODE'});
    },

    closeModal() {
      this.isOpen = false;
      this.resetData();
    },

    resetData() {
      this.contest = null;
      this.picks = null;
      this.totSpent = null;
      this.selectedPlayer = null;
      this.statusMap = null;
      this.lineupName = null;
      this.failedText = null;
      this.changedPlayers = {};
      this.playerSalaries = {};
      this.promoInfo = {};
    },

    confirmEdit() {
      this.submitting = true;
      EventBus.$emit('SALARY_CAP_CONFIRM_EDIT');
    },

    confirmPicks() {
      this.submitting = true;
      EventBus.$emit('SALARY_CAP_CONFIRM');
    },

    submitSuccess() {
      this.submitting = false;
      this.closeModal();
    },

    submitFailed(message) {
      this.submitting = false;
      this.failedText = message;
    },

    preventSubmit() {
      this.canSubmit = false;
    },

    rosterAtPos(pos) {
      return this.roster.find((r) => {
        return r.short == pos;
      });
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const d = Math.round(dollarAmount);
      return '$' + Intl.NumberFormat('en-US').format(d);
    },

    posStatusInfo(posInfo) {
      const pos = posInfo.short;
      const posPicks = this.picks[pos];
      const posFilledGames = this.posFilledGames(pos);

      if (posPicks.length == posInfo.num && posInfo.maxGames > posFilledGames) {
        return {bg: 'var(--obcolor-red)', fg: 'white', text: 'Unused Game Opportunities', order: 1};
      } else if (posFilledGames > posInfo.maxGames) {
        return {bg: 'var(--obcolor-NBA)', fg: 'black', text: 'Best Ball Activated', order: 2};
      }

      // bg: background colour (and text colour if used in messaging), fg: foreground colour if background colour is used
      // text: text for error message, order: order in which errors should be shown
      return {bg: 'var(--obcolor-background-2)', fg: 'var(--obcolor-font-secondary)', text: '', order: 3};
    },

    posFilledGames(pos) {
      let totGames = 0;
      for (const player of this.picks[pos]) {
        totGames += this.getStat(player, 'pg') || 0;
      }

      return totGames;
    },

    getStat(player, path) {
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }

      return null;
    },

    totCostByPosition(pos) {
      const posRoster = this.picks[pos];

      let tot = 0;
      for (const player of posRoster) {
        tot += player.salary;
      }

      return this.dollarFormat(tot);
    },

  },
};
</script>

<style lang="scss" scoped>
  .statTitle {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
  }

  .salaryTable, .posRow {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    box-sizing: border-box;
  }

  .salaryTable {
    margin-top: 10px;

    tr:not(:first-child) td {
      padding-top: 10px;
    }
  }

  .salaryCap {
    font-weight: normal;
    color: var(--obcolor-font-secondary);
    margin-left: 5px;
  }

  .salaryHeader {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    padding-bottom: 2px;
  }

  .salaryVal {
    font-size: 20px;
    font-weight: bold;
  }

  .posRow {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .posField {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    margin: 0 3px;
    border: 1px solid var(--obcolor-background-2);

    width: 120px;
  }

  .posVal {
    font-size: 16px;
    padding-top: 3px;
    width: 100%;

    box-sizing: border-box;
  }

  .posVal:last-child {
    padding-bottom: 3px;
  }

  .posTitle {
    font-size: 14px;
    font-weight: bold;
    color: var(--obcolor-font-secondary);
    background: var(--obcolor-background-2);
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
  }

  .moneyText {
    color: var(--obcolor-green) !important;
    font-weight: bold;
  }
  .redText {
    color: red !important;
    font-weight: bold;
  }

  .blueText {
    color: var(--obcolor-ob-blue) !important;
    font-weight: bold;
  }

  .playerList {
    width: calc(100%);
    margin-top: 10px;
    border-top: 2px solid var(--obcolor-background-2);
    padding-top: 8px;
    min-height: 0;
    overflow: auto;
  }

  .playerRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
  }

  .smallBtn {
    padding: 6px 12px;
    font-size: 14px;
  }

  .balanceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .balanceTitle {
    font-weight: bold;
    margin-right: 5px;
    font-size: 14px;
    white-space: nowrap;
  }

  .balanceText {
    font-size: 14px;
    background: var(--obcolor-background-2);
    color: white;
    border-radius: 5px;
    padding: 3px 5px 3px 8px;
    min-width: 70px;
    width: max-content;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .promoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: -5px;

    .promoText {
      padding: 5px 10px;
      background: var(--obcolor-background-2);
      border-radius: 5px;
      margin-right: 10px;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }

    .removeText {
      margin-left: 10px;
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }

    .addCodeText {
      color: var(--obcolor-yellow);
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }

    .iconBg {
      margin-top: -13px;
      margin-left: 2px;
      background: white;
      border-radius: 10px;
      height: 11px;
      width: 11px;
    }
  }

  .footerText {
    font-size: 14px;
    margin: 15px 0 15px 0;
    text-align: center;
  }

  .failedText {
    margin-top: -9px;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--obcolor-red);
    font-weight: bold;
    text-align: center;
  }

  .btnList {
    width: 100%;
    display: flex;
    justify-content: center;

    button:not(:last-child) {
      margin-right: 30px;
    }

    button {
      width: 160px;
      font-size: 14px;
    }
  }

  .disabledConfirm {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .ownersBucksIcon {
    height: 10px;
  }
</style>